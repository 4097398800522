import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColorUtil } from '@util/color-util';

@Component({
  selector: 'app-entity-details-side-panel-colorpicker',
  templateUrl: './entity-details-side-panel-colorpicker.component.html',
  styleUrls: ['./entity-details-side-panel-colorpicker.component.scss'],
})
export class EntityDetailsSidePanelColorpickerComponent implements OnChanges {
  @Input() entity;
  color;
  constructor() {}

  ngOnChanges(): void {
    if (this.entity) {
      this.color = `#` + this.entity.hex;
    }
  }

  updateColor(evt) {}
  colorChange(evt) {
    // const rgb =ColorUtil.hexToRgb(evt);
    // if (rgb) {
    //   this.color = evt;
    //   this.colorForm.controls['hex'].setValue(evt);
    //   this.colorForm.controls['rgb'].setValue({red: rgb.r, green: rgb.g, blue: rgb.b});
    // }
  }
}
