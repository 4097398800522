import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { clipboardReducers } from './clipboard.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ClipboardEffects } from './clipboard.effects';
import { ClipboardItemsEffects } from './clipboard-items/clipboard-items.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('clipboard', clipboardReducers),
    EffectsModule.forFeature([ClipboardEffects, ClipboardItemsEffects]),
  ],
})
export class ClipboardStoreModule {}
