import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { TypesModule } from '@common/types/types.module';
import { ContentModule } from '@common/content/content.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { EntityModalComponent } from './entity-modal/entity-modal.component';
import { EntityDetailsHeaderComponent } from './entity-details/entity-details-header/entity-details-header.component';
import { EntityPropertiesFormComponent } from './entity-properties-form/entity-properties-form.component';
import { EntityDetailsComponent } from './entity-details/entity-details.component';
import { EntityDetailsSidePanelComponent } from './entity-details-side-panel/entity-details-side-panel.component';
import { EntityDetailsSidePanelHeaderComponent } from './entity-details-side-panel/entity-details-side-panel-header.component';
import { EntityDetailsSidePanelVisualizationComponent } from './entity-details-side-panel/entity-details-side-panel-visualization/entity-details-side-panel-visualization.component';
import { ComponentsModule } from '@common/components/components.module';
import { EntityDetailsSidePanelColorpickerComponent } from './entity-details-side-panel/entity-details-side-panel-colorpicker/entity-details-side-panel-colorpicker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EntityPropertyChooserComponent } from './entity-property-chooser/entity-property-chooser.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ChooserModule } from '@common/chooser/chooser.module';

@NgModule({
  declarations: [
    EntityModalComponent,
    EntityDetailsComponent,
    EntityDetailsHeaderComponent,
    EntityPropertiesFormComponent,
    EntityDetailsSidePanelComponent,
    EntityDetailsSidePanelHeaderComponent,
    EntityDetailsSidePanelVisualizationComponent,
    EntityDetailsSidePanelColorpickerComponent,
    EntityPropertyChooserComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    TypesModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    ContentModule,
    ColorPickerModule,
    VirtualScrollerModule,
    ChooserModule,
  ],
  exports: [
    EntityModalComponent,
    EntityDetailsSidePanelComponent,
    EntityDetailsComponent,
    EntityPropertyChooserComponent,
    EntityPropertiesFormComponent,
  ],
})
export class EntityDetailsModule {}
