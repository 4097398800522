import { Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { IconTypes } from '@common/entity-details/icon-types';

@Component({
  selector: 'app-entity-details-side-panel-visualization',
  templateUrl: './entity-details-side-panel-visualization.component.html',
  styleUrls: ['./entity-details-side-panel-visualization.component.scss'],
})
export class EntityDetailsSidePanelVisualizationComponent implements OnChanges {
  @Input() entity;
  @Input() viewMode: 'side' | 'modal' = 'side';
  public url; // asset, board, showcase;
  // public icon; // plan, showroom, workspace
  iconTypes = IconTypes;

  constructor() {}

  ngOnChanges(): void {
    if (this.entity) {
      if (this.viewMode === 'side') {
        this.url =
          this.entity?.mediumViewableUrl || this.entity?.mediumViewableDownloadUrl || this.entity?.thumbnailUrl;
      } else if (this.viewMode === 'modal') {
        this.url =
          this.entity?.largeViewableDownloadUrl || this.entity?.mediumViewableDownloadUrl || this.entity?.thumbnailUrl;
      }
    }
  }

  handleFileSelection($event: any) {
    const files = $event?.target?.files;
    //TODO: @Brian
  }

  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      //TODO:
    }
  }
}
