import { ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { CropDefinition, DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { CropImageElementComponent } from './crop-image-element.component';
import { DocumentService } from '../../document.service';
import { BoardService } from '../../../board.service';

@Injectable({
  providedIn: 'root',
})
export class CropImageElementService {
  private destroy$ = new Subject();
  private cropImgOverlay: OverlayRef;
  private sourceEvent: any = null;

  private cropDefinition: CropDefinition;
  private opacity = null;

  constructor(
    private authService: AuthService,
    private documentService: DocumentService,
    private boardService: BoardService,
    private overlay: Overlay,
  ) {
    this.documentService.documentElementEvents.subscribe((event) => {
      if (!event) {
        return;
      }
      if (
        event.eventType === 'dblclick' &&
        event?.element &&
        !event.element.isLocked &&
        event.element.type === 'image'
      ) {
        // this.documentService.handleActionRequest(new ActionRequest('crop_image', event.element));
      }
    });

    this.documentService.actionRequests.subscribe((request) => {
      // if (request?.actionType === 'crop_image') {
      //   // const imageId = `img-${request?.sourceEvent?.id}`;
      //   this.sourceEvent = request?.sourceEvent;
      //   this.opacity = request?.sourceEvent?.style?.opacity ?? 1;
      //   this.cropDefinition = null;
      //   this.documentService.deselectAllElements();
      //   this.openCropImageOverlay();
      //   return;
      // } else if (request?.actionType === 'exit_crop_mode') {
      // }
    });
  }

  async openCropImageOverlay() {
    this.destroy$.next(true);
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    // const positionStrategy = this.overlay.position().global().top('100px').left('400px');
    this.cropImgOverlay = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      backdropClass: 'transparent-backdrop',
      panelClass: ['flex-center'],
      positionStrategy: positionStrategy,
      maxWidth: '70vw',
      maxHeight: '70vh',
      // width: '50vw',
      // height: '50vh'
    });
    const componentPortal = new ComponentPortal(CropImageElementComponent);
    let ref: ComponentRef<CropImageElementComponent>;
    ref = this.cropImgOverlay.attach(componentPortal);

    let initialCrop = null;
    if (this.sourceEvent?.cropDefinition?.width && this.sourceEvent?.cropDefinition?.height) {
      initialCrop = {
        x1: this.sourceEvent?.cropDefinition?.x1,
        x2: this.sourceEvent?.cropDefinition?.x2,
        y1: this.sourceEvent?.cropDefinition?.y1,
        y2: this.sourceEvent?.cropDefinition?.y2,
      };
    }
    ref.instance.initialCrop = initialCrop;
    // const rotate = this.sourceEvent?.rotate?.angle || 0
    // ref.instance.transform = `rotate(${rotate}deg)`;

    const urlToLoad = this.sourceEvent?.alternateUrls?.originalFile || this.sourceEvent.url;
    if (urlToLoad.includes('api.vibeiq.com') || urlToLoad.includes('api.dev.vibeiq.com')) {
      const authContext = await this.authService.getAuthContext();
      const blobRes = await fetch(urlToLoad, {
        headers: {
          'x-api-key': authContext.token,
          'x-api-org': authContext.currentOrg.orgSlug,
        },
      });

      ref.instance.imageBlob = await blobRes.blob();
      // const file = new File([blobFile], fileName, { type: 'image/png' });
    } else {
      const blobRes = await fetch(this.sourceEvent.url, {
        cache: 'no-cache',
      });
      ref.instance.imageBlob = await blobRes.blob();

      // load-image.service.ts / loadImageFromURL() has CORS error while loading some s3 bucket images.
      // ref.instance.imageURL = this.sourceEvent.url; // `https://picsum.photos/200`
    }

    ref.instance.imageReady$.pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        const cropper = document.getElementsByClassName('ngx-ic-cropper')[0];
        const rect = cropper?.getBoundingClientRect();
        if (rect) {
          this.updateViewportByImage(this.sourceEvent, rect);
          const changes = [{ id: this.sourceEvent.id, style: { opacity: 0 } }];
          this.documentService.documentRenderer.applyChanges(changes);
        }
      }, 0);
    });

    ref.instance.cropDefinition$.pipe(takeUntil(this.destroy$)).subscribe((crop) => {
      this.cropDefinition = crop;
    });

    this.cropImgOverlay
      ?.keydownEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        res?.preventDefault(); // PREVENT ENTER KEY _ it is focused on CROP in property-configurator-bar
        if (res?.code === 'Escape' || res?.code === 'Enter' || res?.code === 'NumpadEnter') {
          ref.instance.imageBlob = null;
          ref.instance.imageURL = null;
          const imgWidth = ref.instance.imgWidth;
          const imgHeight = ref.instance.imgHeight;
          if (res.code === 'Enter' || res?.code === 'NumpadEnter') {
            this.updateCropping({ imgWidth, imgHeight });
          }
          this.closeOverlay();
        }
      });

    this.cropImgOverlay
      ?.backdropClick()
      .pipe(take(1))
      .subscribe((evt) => {
        ref.instance.imageBlob = null;
        ref.instance.imageURL = null;
        const imgWidth = ref.instance.imgWidth;
        const imgHeight = ref.instance.imgHeight;
        this.updateCropping({ imgWidth, imgHeight });
        this.closeOverlay();
      });
  }

  closeOverlay() {
    const changes = [{ id: this.sourceEvent.id, style: { opacity: this.opacity } }];
    this.documentService.documentRenderer.applyChanges(changes);

    this.cropImgOverlay?.detach();
    this.cropImgOverlay?.dispose();
    this.overlay?.position().global().dispose();

    this.destroy$.next(null);
    this.destroy$.complete();
    this.sourceEvent = null;
    this.cropDefinition = null;
    this.documentService.deselectAllElements();
  }

  updateCropping({ imgWidth, imgHeight }) {
    if (this.cropDefinition) {
      const actions: Array<DocumentAction> = [];

      const prevCropDefinition = this.sourceEvent?.cropDefinition || null;
      const prevPosition = this.sourceEvent?.position;
      const diffX = this.cropDefinition.x1 - (prevCropDefinition?.x1 || 0);
      const diffY = this.cropDefinition.y1 - (prevCropDefinition?.y1 || 0);

      const prevSize = this.sourceEvent.size;
      const prevW = prevCropDefinition?.width || imgWidth;
      const prevH = prevCropDefinition?.height || imgHeight;
      const newW = this.cropDefinition?.width || imgWidth;
      const newH = this.cropDefinition?.height || imgHeight;
      const newSize = { width: prevSize.width * (newW / prevW), height: prevSize.height * (newH / prevH) };

      const newPosition = {
        x: prevPosition.x + (diffX * prevSize.width) / prevW,
        y: prevPosition.y + (diffY * prevSize.height) / prevH,
      };

      let changeElementData: DocumentElement = {
        id: this.sourceEvent.id,
        cropDefinition: this.cropDefinition,
        position: newPosition,
      };
      let undoChangeElementData: DocumentElement = {
        id: this.sourceEvent.id,
        cropDefinition: prevCropDefinition,
        position: prevPosition,
      };

      if (
        prevCropDefinition?.width !== this.cropDefinition.width ||
        prevCropDefinition?.height !== this.cropDefinition.height
      ) {
        changeElementData.size = newSize;
        undoChangeElementData.size = prevSize;
      }

      const documentAction = new DocumentAction(
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: this.sourceEvent.id,
          elementData: changeElementData,
        },
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: this.sourceEvent.id,
          elementData: undoChangeElementData,
        },
      );
      actions.push(documentAction);
      this.documentService.handleDocumentActions(actions);
    }
  }

  private updateViewportByImage(imageElement, rect) {
    const imageSize = imageElement?.size;
    const imagePos = imageElement?.position;
    this.boardService.zoomPanHandler.setZoomFactor(imageSize.width / rect.width);

    // zoomPanHandler updated
    const zoomFactor = this.boardService.zoomPanHandler.zoomFactor;
    const canvasSize = this.boardService.zoomPanHandler.canvasSize;
    const newViewBox: any = {
      width: canvasSize.width * zoomFactor,
      height: canvasSize.height * zoomFactor,
      x: imagePos.x - rect.left * zoomFactor,
      y: imagePos.y - rect.top * zoomFactor,
    };

    this.boardService.zoomPanHandler.setViewPort(newViewBox, false);
  }
}
