import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IContent } from '../content-holder-details/content-holder-details.component';
import { Entities } from '@contrail/sdk';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-content-history',
  template: `
    <div>
      <virtual-scroller
        *ngIf="!(loading$ | async)"
        [style.height]="resultsHeight"
        #scroll
        [items]="filteredHistory"
        class="narrow-scroll"
      >
        <div
          class="flex pl-5 pt-5 pb-5 history-row cursor-pointer"
          *ngFor="let historyItem of scroll.viewPortItems; let i = index"
          (click)="openHistoricalContent(historyItem)"
        >
          <div class="image-placeholder" *ngIf="!historyItem.entity?.mediumViewableUrl">
            <mat-icon>panorama</mat-icon>
          </div>
          <div *ngIf="historyItem.entity?.mediumViewableUrl" class="image-holder">
            <img
              [attr.src]="historyItem.entity.mediumViewableUrl | secureImage | async"
              class="w-full h-full object-contain"
            />
          </div>
          <div class="ml-2">
            <div class="text-[14px] font-medium mb-1">
              {{ historyItem.versionNumber || ' ' + (historyItem.createdOn | date: 'short') }}
            </div>
            <div *ngIf="historyItem.createdBy" class="flex items-center text-[12px]">
              <div *ngIf="historyItem.createdBy.email.indexOf('vibeapp') < 0" class="flex items-center">
                <app-avatar [user]="historyItem.createdBy" class="mr-2"></app-avatar>{{ historyItem.createdBy.email }}
              </div>
              <div *ngIf="historyItem.createdBy.email.indexOf('vibeapp') > -1">System Integration</div>
            </div>
          </div>
        </div>
      </virtual-scroller>
    </div>
  `,
  styles: [
    `
      $image-width: 120px;
      $image-height: 120px;
      .info-label-value {
        @apply text-black60 text-sm flex;
        p:first-child {
          @apply min-w-[140px] font-medium;
        }
      }
      .history-row {
        @apply border-b-[1px] border-gray-300 border-solid;
      }
      .image-holder {
        width: $image-width;
        min-width: $image-width;
        height: $image-height;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          object-fit: contain;
          max-height: $image-height;
          max-width: $image-width;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
      .image-placeholder {
        min-width: $image-width;
        width: $image-width;
        height: $image-height;
        display: flex;
        color: rgba(0, 0, 0, 0.2);
        .mat-icon {
          color: #ebebeb;
          font-size: 50px;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    `,
  ],
})
export class ContentHistoryComponent implements OnChanges {
  public is3D: boolean;
  @Output() selectHistory = new EventEmitter();
  @Input() content: IContent;
  public history: Array<any>;
  public filteredHistory: Array<any>;
  public loading$: Observable<boolean> = new Subject();
  @Input() public resultsHeight = '80vh';
  @Output() historySelected = new EventEmitter();

  constructor() {}

  async ngOnChanges() {
    if (this.content) {
      this.is3D = this.content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
      this.history = await new Entities().get({
        entityName: 'change-history',
        criteria: { entityReference: 'content:' + this.content.id },
        relations: ['createdBy'],
      });
      this.filteredHistory = this.history
        .filter((obj) => !!obj.entity.smallViewableUrl)
        .sort((o1, o2) => (o1.updatedOn > o2.updatedOn ? -1 : 1));
      console.log('filteredHistory: ', this.filteredHistory);
    }
  }

  openHistoricalContent(content) {
    this.historySelected.emit(content);
  }
}
