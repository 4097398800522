import { createSelector } from '@ngrx/store';
import { frameTemplatesEntityAdapter } from './frame-templates.state';
import { FrameTemplate } from '../frame-template';

export const { selectAll, selectEntities, selectIds, selectTotal } = frameTemplatesEntityAdapter.getSelectors();

const frameTemplatesEntities = (state: any) => state.frameTemplates.frameTemplates.entities;
export const frameTemplates = createSelector(
  frameTemplatesEntities,
  (state) => Object.values(state) as Array<FrameTemplate>,
);

export const getFrameTemplateById = (id) =>
  createSelector(frameTemplatesEntities, (frameTemplates) => {
    if (frameTemplates) {
      return frameTemplates[id];
    } else {
      return null;
    }
  });
