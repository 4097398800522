export class ImageUtils {
  static async fileToBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  static async getImageDimensions(imageStr: string): Promise<any> {
    let img = new Image();
    img.src = imageStr;

    return new Promise((resolve, reject) => {
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = (error) => reject(error);
    });
  }
}
