<div class="widget-tray" data-test="document-widget-tray">
  <ng-container *ngTemplateOutlet="leftBarTemplate; context: { ctx: 'main' }"></ng-container>

  <button
    [matMenuTriggerFor]="moreMenu"
    class="more-widget-btn"
    mat-icon-button
    matTooltip="More"
    matTooltipPosition="right"
    data-test="document-widget-tray-more"
  >
    <mat-icon class="text-black60">more_horiz</mat-icon>
  </button>
  <mat-menu #moreMenu="matMenu" xPosition="after" yPosition="above" class="more-widget-menu">
    <ng-container *ngTemplateOutlet="leftBarTemplate; context: { ctx: 'more' }"></ng-container>
  </mat-menu>
</div>

<ng-template #leftBarTemplate let-ctx="ctx">
  <div #mainLeftBar [ngClass]="ctx + '-widgets-list'">
    <button
      [attr.id]="ctx + 'WidgetTraySelect'"
      (click)="resetInteractionMode()"
      matTooltip="Select [V]"
      data-test="document-widget-tray-select"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      mat-icon-button
    >
      <mat-icon svgIcon="pointer_icon" [ngClass]="isActive() ? 'toggle-icon active' : 'toggle-icon'"></mat-icon>
    </button>
    <button
      *ngIf="itemAssignmentFeatureActive"
      [attr.id]="ctx + 'WidgetTrayAssignItem'"
      mat-icon-button
      matTooltip="Assign Item [A]"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      data-test="document-widget-tray-assign-item"
      (click)="setInteractionMode('assign_item')"
    >
      <mat-icon
        svgIcon="item-assignment-tool"
        [ngClass]="isAssignItemActive() ? 'assign-item-toggle-icon active' : 'assign-item-toggle-icon'"
      ></mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayToolMenu'"
      (click)="eventPrevent($event)"
      mat-button
      [matMenuTriggerFor]="toolMenu"
      style="display: none"
    ></button>
    <app-document-annotation-tool
      [attr.id]="ctx + 'WidgetTrayAnnotationTool'"
      (click)="eventPrevent($event)"
      [tooltipPosition]="ctx === 'main' ? 'right' : 'above'"
    />
    <button
      *ngIf="itemCreationFeatureActive"
      [attr.id]="ctx + 'WidgetTrayCreateItem'"
      mat-icon-button
      matTooltip="Item tool"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      [matMenuTriggerFor]="menuVertical"
      [matMenuTriggerData]="{ menu: itemMenus }"
      data-test="document-widget-tray-create-item"
      (click)="eventPrevent($event)"
    >
      <mat-icon svgIcon="tag" class="!w-[20px] !h-[20px]"></mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayTextMenu'"
      [ngClass]="{ 'active-menu': getActiveColor('text') || getActiveColor('create_text_element') }"
      mat-icon-button
      matTooltip="Text [T]"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{ menu: textMenus }"
      data-test="document-widget-tray-add-text-box"
      (click)="eventPrevent($event)"
    >
      <mat-icon svgIcon="app-text-create"></mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayImage'"
      mat-icon-button
      matTooltip="Insert image"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      (click)="uploadImage(); setInteractionMode('select')"
    >
      <mat-icon svgIcon="app-image-tool"></mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayPen'"
      [ngClass]="{ 'active-menu': getActiveColor('pen') || getActiveColor('highlighter') || getActiveColor('eraser') }"
      mat-icon-button
      matTooltip="Pen [P]"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      (click)="
        eventPrevent($event);
        getActiveColor('highlighter') ? setInteractionMode('highlighter') : setInteractionMode('pen');
        subscribeToDocumentClickToCloseMenu($event, ['pen', 'highlighter', 'eraser'], penMenuTrigger)
      "
      [matMenuTriggerFor]="penMenu"
      [matMenuTriggerData]="{ menu: penMenus }"
      #penMenuTrigger="matMenuTrigger"
      data-test="document-widget-tray-add-pen-tool"
    >
      <mat-icon
        *ngIf="getActiveColor('pen') || (!getActiveColor('highlighter') && !getActiveColor('eraser'))"
        class="icons material-symbols-outlined"
      >
        stylus
      </mat-icon>
      <mat-icon *ngIf="getActiveColor('highlighter')" class="icons material-symbols-outlined">ink_highlighter</mat-icon>
      <mat-icon *ngIf="getActiveColor('eraser')" class="icons material-symbols-outlined">ink_eraser</mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayShape'"
      [color]="getActiveColor('shape')"
      mat-icon-button
      matTooltip="Shapes"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{ menu: shapeMenus }"
      data-test="document-widget-tray-add-shapes"
      (click)="eventPrevent($event)"
    >
      <mat-icon *ngIf="!this.shapeSelected.shape" svgIcon="app-shape-tool"></mat-icon>
      <mat-icon
        *ngIf="this.shapeSelected.shape && this.shapeSelected.svgIcon"
        [svgIcon]="this.shapeSelected.icon"
        [class]="this.shapeSelected.class + ' selected-menu'"
      ></mat-icon>
      <mat-icon *ngIf="this.shapeSelected.shape && !this.shapeSelected.svgIcon" [class]="this.shapeSelected.class">
        {{ this.shapeSelected.icon }}
      </mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayLine'"
      [color]="getActiveColor('line')"
      mat-icon-button
      matTooltip="Lines"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{ menu: lineMenus }"
      data-test="document-widget-tray-add-lines"
      (click)="eventPrevent($event)"
    >
      <mat-icon *ngIf="!getActiveIcon(lineMenus)">horizontal_rule</mat-icon>
      <mat-icon *ngIf="getActiveIcon(lineMenus)">{{ getActiveIcon(lineMenus) }}</mat-icon>
    </button>
    <button
      [color]="getActiveColor('create_table')"
      (click)="setInteractionMode('create_table')"
      mat-icon-button
      matTooltip="Table"
      matTooltipPosition="right"
      data-test="document-widget-tray-create-table"
    >
      <mat-icon>grid_on</mat-icon>
    </button>
    <button
      [attr.id]="ctx + 'WidgetTrayStickyNote'"
      [color]="getActiveColor('create_sticky_note')"
      mat-icon-button
      matTooltip="Sticky note [S]"
      [matTooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      data-test="document-widget-tray-add-sticky-note"
      (click)="setInteractionMode('create_sticky_note')"
    >
      <mat-icon class="material-icons-outlined">sticky_note_2</mat-icon>
    </button>

    <app-document-add-frame
      [attr.id]="ctx + 'WidgetTrayFrame'"
      [tooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      (click)="eventPrevent($event)"
    />
    <app-document-add-iframe
      [attr.id]="ctx + 'WidgetTrayEmbeddedIframe'"
      [tooltipPosition]="ctx === 'main' ? 'right' : 'above'"
    />
    <app-document-add-advanced
      [attr.id]="ctx + 'WidgetTrayAdvancedMenu'"
      [tooltipPosition]="ctx === 'main' ? 'right' : 'above'"
      (click)="eventPrevent($event)"
    />
    <mat-divider class="divider" *ngIf="ctx === 'main'" />
    <app-paint-formatter
      [attr.id]="ctx + 'WidgetTrayPaintFormatter'"
      [tooltipPosition]="ctx === 'main' ? 'right' : 'above'"
    />
  </div>
</ng-template>

<mat-menu #toolMenu="matMenu" (close)="onToolMenuClosed($event)">
  <ng-template matMenuContent let-frame="frame">
    <app-document-tools (selectionCompleted)="closeToolMenu()"></app-document-tools>
  </ng-template>
</mat-menu>

<mat-menu #penMenu="matMenu" class="tool-menu-row tool-menu-row-vertical" [hasBackdrop]="false">
  <ng-template matMenuContent let-menu="menu">
    <ng-container
      [ngTemplateOutlet]="secondaryMenu"
      [ngTemplateOutletContext]="{ menu: menu, direction: 'vertical', keepMenuOpen: true }"
    ></ng-container>
    <ng-container *ngIf="['highlighter', 'pen'].indexOf(interactionMode) !== -1">
      <mat-divider class="divider"></mat-divider>
      <app-property-configurator-color-picker
        matTooltip="Line color"
        matTooltipPosition="right"
        (click)="eventPrevent($event)"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('border.color')"
        [matIconType]="'border_color'"
        [colorType]="'borderColor'"
        [closeOnSelect]="true"
        [colorChip]="true"
      />
      <app-property-configurator-border-size
        matTooltip="Line thickness"
        matTooltipPosition="right"
        (click)="eventPrevent($event)"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('border.width')"
        [borderSizes]="[2, 4, 6, 8, 10, 12, 14, 16, 24, 48, 96]"
        [keepParentMenuOpen]="true"
      />
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #menu="matMenu" class="tool-menu-row">
  <ng-template matMenuContent let-menu="menu">
    <ng-container [ngTemplateOutlet]="secondaryMenu" [ngTemplateOutletContext]="{ menu: menu }"> </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #menuVertical="matMenu" class="tool-menu-row tool-menu-row-vertical">
  <ng-template matMenuContent let-menu="menu">
    <ng-container [ngTemplateOutlet]="secondaryMenu" [ngTemplateOutletContext]="{ menu: menu, direction: 'vertical' }">
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template #secondaryMenu let-menu="menu" let-direction="direction" let-keepMenuOpen="keepMenuOpen">
  <button
    *ngFor="let m of menu"
    mat-menu-item
    [matTooltip]="m.tooltip ?? m.label"
    [matTooltipPosition]="direction === 'vertical' ? 'right' : 'above'"
    (click)="keepMenuOpen ? $event.stopPropagation() : null; setInteractionMode(m.id)"
    [ngClass]="{ 'active-menu': getActiveColor(m.id) }"
    [attr.data-test]="'document-widget-tray-add-' + m.id"
  >
    <mat-icon class="icons {{ m.class }}" [svgIcon]="m.svgIcon">{{ m.icon }}</mat-icon>
  </button>
</ng-template>
