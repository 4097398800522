import {
  ArcRotateCamera,
  ArcRotateCameraPointersInput,
  AssetContainer,
  GizmoManager,
  Observable,
  Quaternion,
  Scalar,
  TransformNode,
} from '@babylonjs/core';
import { Engine } from '@babylonjs/core/Engines/engine';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { Axis, Color4, Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Scene } from '@babylonjs/core/scene';
import '@babylonjs/loaders/glTF';
import { Entities } from '@contrail/sdk';
import { AssetWrapper } from '../asset/assetwrapper';
import { ShowroomContentConfig, ContentConfig } from '../showroom-content-config/showroom-content-config';
import { RotationPortable, VectorPortable } from '../util/transform-types';

export class WebGLContentConfigurator {
  canvas: HTMLCanvasElement;
  engine: Engine;
  scene: Scene;
  arcCam: ArcRotateCamera;
  arcCamStartingRadius: number;
  gizmoManager: GizmoManager;
  currentAssetUrl: string;

  currentGizmo = 'pos'; //pos, rot
  currentZoomControl = 0; // Amount to zoom during render update, controlled by zoomin/out buttons

  assetWrapper: AssetWrapper;

  fixture: Mesh;
  fixtureSlots = [];
  currentFixtureSlot = { slotType: 'focus', node: null };

  focusSlot: TransformNode;

  content: any;
  showroomContentConfig: ShowroomContentConfig;

  public onContentConfigUpdated = new Observable<ContentConfig>();
  public onPositionUpdated = new Observable<VectorPortable>();
  public onRotationUpdated = new Observable<RotationPortable>();

  public async init(canvas: HTMLCanvasElement, content: any) {
    this.canvas = canvas;

    this.engine = new Engine(this.canvas, true);
    this.scene = this.createScene(this.engine, this.canvas);

    this.engine.runRenderLoop(() => {
      if (this.currentZoomControl != 0) {
        this.zoomCamera((this.currentZoomControl * this.engine.getDeltaTime()) / 1000);
      }

      this.scene.render();
    });

    const _this = this;
    this.canvas.onresize = () => {
      _this.engine.resize();
    };
    window.onresize = () => {
      _this.engine.resize();
    };

    await this.setupAsset(content);
  }

  protected createScene(engine: Engine, canvas: HTMLCanvasElement): Scene {
    this.scene = new Scene(engine);
    this.scene.clearColor = new Color4(1, 1, 1, 1);

    this.arcCam = new ArcRotateCamera('slotCam', Math.PI / 2, Math.PI / 2.5, 0.5, Vector3.Zero(), this.scene);

    // Disable panning by removing any buttons other than left mouse
    const pointers: ArcRotateCameraPointersInput = this.arcCam.inputs.attached.pointers as ArcRotateCameraPointersInput;
    pointers.buttons = [0];

    this.arcCam.minZ = 0.1;
    this.arcCam.wheelDeltaPercentage = 0.01;
    this.arcCam.lowerRadiusLimit = 0.75;
    this.arcCam.upperRadiusLimit = 10;
    this.arcCamStartingRadius = this.arcCam.lowerRadiusLimit;

    this.arcCam.attachControl(this.canvas, false);

    // Light that reflects towards the negative Z direction (towards the camera), illumating things in the positive Z direction
    const light = new HemisphericLight('light', new Vector3(0, 1, -0.5), this.scene);

    const fixtureUrl = 'https://contrail-temp-images.s3.amazonaws.com/showroom-temp-models/shelf1_opt.glb';
    SceneLoader.LoadAssetContainerAsync(fixtureUrl, '', this.scene).then((assets) => {
      if (assets?.meshes?.length) {
        assets.addAllToScene();

        this.fixture = new Mesh('fixtureRoot', this.scene);

        assets.meshes[0].parent = this.fixture;
        assets.meshes[0].position = Vector3.Zero();
        assets.meshes[0].rotation = new Vector3(0, -Math.PI / 2, 0); // Hard coded rotation due to asset being rotated 90 degrees

        // Eliminate root level translation on all meshes
        for (let i = 1; i < assets.meshes.length; i++) {
          assets.meshes[i].setAbsolutePosition(Vector3.Zero());
          assets.meshes[i].rotation = Vector3.Zero();
          assets.meshes[i].rotationQuaternion = Quaternion.Zero();
        }

        this.fixture.rotateAround(this.fixture.position, Axis.Y, -Math.PI);
        this.fixture.position = Axis.Z.scale(4);
        this.fixture.position.y -= 1.25;

        const hangingSlot = new TransformNode('hangingSlot', this.scene);
        hangingSlot.parent = this.fixture;

        // Convert from UE4 to BabylonJS coordinates by swapping Y and Z and dividing by 100
        hangingSlot.position = new Vector3(-0.4525, 2.52, 0.07);

        this.fixtureSlots.push({ slotType: 'hanged', node: hangingSlot });

        const surfaceSlot = new TransformNode('surfaceSlot', this.scene);
        surfaceSlot.parent = this.fixture;
        surfaceSlot.position = new Vector3(0.25, 1.8725, 0);

        this.fixtureSlots.push({ slotType: 'surface', node: surfaceSlot });
        this.fixtureSlots.push({ slotType: 'shoe', node: surfaceSlot });

        this.focusSlot = new TransformNode('focusSlot', this.scene);
        this.focusSlot.position = Vector3.Zero();
        this.focusSlot.rotation = new Vector3(0, Math.PI, 0);
      }
    });

    return this.scene;
  }

  public async setupAsset(content: any) {
    this.content = content;
    const assetUrl = content.primaryFile.downloadUrl;

    this.assetWrapper?.dispose(false, true);
    this.assetWrapper = null;
    this.currentAssetUrl = assetUrl;

    let assetContainer: AssetContainer;
    await SceneLoader.LoadAssetContainerAsync(assetUrl, '', this.scene).then((loadedContainer) => {
      // When load finishes, make sure it's still the current URL and not a duplicate load
      if (assetUrl != this.currentAssetUrl || this.assetWrapper != null) {
        return;
      }

      assetContainer = loadedContainer;
    });

    this.assetWrapper = AssetWrapper.WrapAssetContainer(this.scene, assetContainer);

    this.assetWrapper.setPivotByPreset('center');

    const contentId = this.content.id;
    const contentConfigArray: Array<ShowroomContentConfig> = await new Entities().get({
      entityName: 'showroom-content-config',
      criteria: { contentId },
    });

    if (contentConfigArray.length) {
      this.setShowroomContentConfig(contentConfigArray[0]);
    } else {
      console.log('No showroom content config available');
      this.resetShowroomContentConfig();
    }
  }

  protected setShowroomContentConfig(showroomContentConfig: ShowroomContentConfig): void {
    this.showroomContentConfig = showroomContentConfig;

    this.onContentConfigUpdated.notifyObservers(this.showroomContentConfig.contentConfig, 1);

    let slotType: String = 'focus';
    if (
      this.showroomContentConfig.contentConfig.slotTypes.includes('Surface') ||
      this.showroomContentConfig.contentConfig.slotTypes.includes('Shoe')
    ) {
      slotType = 'surface';
    } else if (this.showroomContentConfig.contentConfig.slotTypes.includes('Hanged')) {
      slotType = 'hanged';
    }

    this.pickSlotType(slotType);
    this.pickBaseUnit(this.showroomContentConfig.contentConfig.baseUnit);
    this.setPivotPreset(this.showroomContentConfig.contentConfig.pivotPosition);

    this.setPositionOffsetDirectly(this.showroomContentConfig.contentConfig.offsetTransform.position);
    this.setRotationOffsetDirectly(this.showroomContentConfig.contentConfig.offsetTransform.rotation);
    this.setUniformScale(this.showroomContentConfig.contentConfig.uniformScale);
  }

  public resetShowroomContentConfig(): void {
    this.setShowroomContentConfig(new ShowroomContentConfig());
  }

  public pickSlotType(slotType: String): void {
    let targetPosition = Vector3.Zero();

    if (slotType == 'focus') {
      this.currentFixtureSlot = null;

      this.assetWrapper.setPosition(Vector3.Zero());
      this.attachAssetToSlot(this.focusSlot);

      this.enableGizmos(false);
    } else {
      for (let i = 0; i < this.fixtureSlots.length; i++) {
        if (this.fixtureSlots[i].slotType == slotType) {
          this.currentFixtureSlot = this.fixtureSlots[i];

          this.setPositionOffsetDirectly(this.showroomContentConfig.contentConfig.offsetTransform.position);
          this.attachAssetToSlot(this.currentFixtureSlot.node);

          targetPosition = this.currentFixtureSlot.node.getAbsolutePosition();

          this.enableGizmos(true);

          break;
        }
      }
    }

    this.arcCam.target = targetPosition;
    this.arcCam.position = targetPosition.subtract(Axis.Z.scale(this.arcCamStartingRadius));
  }

  protected attachAssetToSlot(slotNode: TransformNode): void {
    this.assetWrapper.setParent(slotNode);
  }

  public pickBaseUnit(baseUnit: string): void {
    this.showroomContentConfig.contentConfig.baseUnit = baseUnit;

    this.updateAssetScale();
  }

  public setPivotPreset(pivotPreset: string): void {
    this.showroomContentConfig.contentConfig.pivotPosition = pivotPreset;

    this.assetWrapper.setPivotByPreset(pivotPreset);
  }

  public updateSelectedGizmo(selectedGizmo): void {
    this.currentGizmo = selectedGizmo;

    // Only update gizmos if we're currently attached to fixture
    if (this.currentFixtureSlot) {
      if (selectedGizmo == 'pos') {
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = false;
      } else if (selectedGizmo == 'rot') {
        this.gizmoManager.positionGizmoEnabled = false;
        this.gizmoManager.rotationGizmoEnabled = true;
      }
    }
  }

  private initGizmoManager(): void {
    this.gizmoManager = new GizmoManager(this.scene);
    this.gizmoManager.usePointerToAttachGizmos = false;
    this.gizmoManager.clearGizmoOnEmptyPointerEvent = false;

    // Need to set these flags to true to spawn the other gizmos for the below settings
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;

    this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(this.handlePositionDragEnd, 1, false, this);

    this.gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.useObjectOrientationForDragging = false;
    this.gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.useObjectOrientationForDragging = false;
    this.gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.useObjectOrientationForDragging = false;

    this.gizmoManager.gizmos.positionGizmo.xGizmo.updateGizmoRotationToMatchAttachedMesh = false;
    this.gizmoManager.gizmos.positionGizmo.yGizmo.updateGizmoRotationToMatchAttachedMesh = false;
    this.gizmoManager.gizmos.positionGizmo.zGizmo.updateGizmoRotationToMatchAttachedMesh = false;

    this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(this.handleRotationDragEnd, 1, false, this);

    this.gizmoManager.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
    this.gizmoManager.gizmos.rotationGizmo.xGizmo.dragBehavior.useObjectOrientationForDragging = false;
    this.gizmoManager.gizmos.rotationGizmo.yGizmo.dragBehavior.useObjectOrientationForDragging = false;
    this.gizmoManager.gizmos.rotationGizmo.zGizmo.dragBehavior.useObjectOrientationForDragging = false;

    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
  }

  private enableGizmos(enable: boolean): void {
    if (!this.gizmoManager) {
      this.initGizmoManager();
    }

    if (this.currentGizmo == 'pos') {
      this.gizmoManager.positionGizmoEnabled = enable;
    } else if (this.currentGizmo == 'rot') {
      this.gizmoManager.rotationGizmoEnabled = enable;
    }

    if (enable) {
      this.gizmoManager.attachToNode(this.assetWrapper.rootNode);
    } else {
      this.gizmoManager.attachToNode(null);
    }
  }

  private handlePositionDragEnd(): void {
    this.showroomContentConfig.contentConfig.offsetTransform.position = VectorPortable.FromVector3(
      this.assetWrapper.getPosition(),
    );

    this.onPositionUpdated.notifyObservers(this.showroomContentConfig.contentConfig.offsetTransform.position, 1);
  }

  public setPositionOffsetDirectly(pos: VectorPortable): void {
    this.assetWrapper.setPosition(VectorPortable.ToVector3(pos));

    this.showroomContentConfig.contentConfig.offsetTransform.position = pos;
  }

  private handleRotationDragEnd(): void {
    const rotPortable: RotationPortable = RotationPortable.FromVector3(this.assetWrapper.getRotation());
    RotationPortable.ConvertToDegInPlace(rotPortable);

    this.showroomContentConfig.contentConfig.offsetTransform.rotation = rotPortable;

    this.onRotationUpdated.notifyObservers(this.showroomContentConfig.contentConfig.offsetTransform.rotation, 1);
  }

  public setRotationOffsetDirectly(rot: RotationPortable): void {
    this.showroomContentConfig.contentConfig.offsetTransform.rotation = rot;

    const rotRad: RotationPortable = RotationPortable.Copy(rot);
    RotationPortable.ConvertToRadInPlace(rotRad);

    this.assetWrapper.setRotation(RotationPortable.ToVector3(rotRad));
  }

  public setUniformScale(scale: number): void {
    this.showroomContentConfig.contentConfig.uniformScale = scale;

    this.updateAssetScale();
  }

  private updateAssetScale(): void {
    if (!this.assetWrapper) {
      return;
    }

    switch (this.showroomContentConfig.contentConfig.baseUnit) {
      case 'mm': {
        this.assetWrapper.setScaling(
          Vector3.One().scale(this.showroomContentConfig.contentConfig.uniformScale * 0.001),
        );
        break;
      }
      case 'cm': {
        this.assetWrapper.setScaling(Vector3.One().scale(this.showroomContentConfig.contentConfig.uniformScale * 0.01));
        break;
      }
      case 'm': {
        this.assetWrapper.setScaling(Vector3.One().scale(this.showroomContentConfig.contentConfig.uniformScale));
        break;
      }
      case 'km': {
        this.assetWrapper.setScaling(Vector3.One().scale(this.showroomContentConfig.contentConfig.uniformScale * 1000));
        break;
      }
    }
  }

  public updateSlotTypes(slotType: string, add: boolean): void {
    if (add) {
      this.showroomContentConfig.contentConfig.slotTypes.push(slotType);
    } else {
      let index = this.showroomContentConfig.contentConfig.slotTypes.indexOf(slotType);
      if (index >= 0) {
        this.showroomContentConfig.contentConfig.slotTypes.splice(index, 1);
      }
    }
  }

  public async updateShowroomContentConfig() {
    if (this.showroomContentConfig.id == null) {
      this.showroomContentConfig = await new Entities().create({
        entityName: 'showroom-content-config',
        object: { contentId: this.content.id, contentConfig: this.showroomContentConfig.contentConfig },
      });
    } else {
      new Entities().update({
        entityName: 'showroom-content-config',
        id: this.showroomContentConfig.id,
        object: { contentConfig: this.showroomContentConfig.contentConfig },
      });
    }
  }

  public deleteShowroomContentConfig(): void {
    if (this.showroomContentConfig.id != null) {
      new Entities().delete({ entityName: 'showroom-content-config', id: this.showroomContentConfig.id });
    }

    this.resetShowroomContentConfig();
  }

  private zoomCamera(zoomDelta: number) {
    let newRadius = this.arcCam.radius + zoomDelta;
    newRadius = Scalar.Clamp(newRadius, this.arcCam.lowerRadiusLimit, this.arcCam.upperRadiusLimit);
    this.arcCam.radius = newRadius;
  }
}
