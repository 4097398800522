<div *ngIf="currentBoard?.primaryContext" class="flex flex-col items-center">
  <!-- <app-menu name="" [isSVGIcon]="true" icon="app-item-icon">
    <app-board-context-panel [board]="currentBoard"></app-board-context-panel>
  </app-menu> -->
  <div class="widgets-list">
    <button mat-icon-button matTooltip="Item Details" matTooltipPosition="right" (click)="showContextDetails()">
      <mat-icon>info</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Item Content" matTooltipPosition="right" (click)="showContextContent()">
      <mat-icon svgIcon="app-image-tool"></mat-icon>
    </button>
    <button mat-icon-button matTooltip="Item Properties" matTooltipPosition="right" (click)="showContextProperties()">
      <mat-icon>text_fields</mat-icon>
    </button>
  </div>
</div>
