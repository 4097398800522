<button
  matTooltip="Advanced"
  [matTooltipPosition]="tooltipPosition"
  [matMenuTriggerFor]="advancedMenu"
  mat-icon-button
  data-test="document-add-advanced"
>
  <mat-icon
    svgIcon="magic"
    class="toggle-icon"
    [ngClass]="{ active: getInteractionMode() === 'generate_document' }"
  ></mat-icon>
</button>

<mat-menu #advancedMenu="matMenu" class="tool-menu-horizontal">
  <ng-template matMenuContent>
    <div class="advanced-menu">
      <button mat-menu-item (click)="createLineBoard()">
        <mat-icon class="material-icons-outlined">space_dashboard</mat-icon>Lineboard
      </button>
      <button mat-menu-item *ngFor="let appExtension of appExtensions" (click)="launchExtension(appExtension)">
        {{ appExtension.name }}
      </button>
    </div>
  </ng-template>
</mat-menu>
