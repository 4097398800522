import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-copy-rename-entity-modal',
  templateUrl: './copy-rename-entity-modal.component.html',
  styleUrls: ['./copy-rename-entity-modal.component.scss'],
})
export class CopyRenameEntityModalComponent implements OnInit {
  @Input() entityType: string;
  @Input() rename: boolean = false;
  @Input() entityId: string;
  @Input() entityName: string;
  @Output() renameEntity = new EventEmitter();
  @Output() copyEntity = new EventEmitter();

  public title: string;

  constructor(
    public dialogRef: MatDialogRef<CopyRenameEntityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityType = data.entityType;
    this.rename = data.rename;
    this.entityId = data.entityId;
    this.entityName = data.entityName;
  }

  ngOnInit(): void {
    this.title = `${this.rename ? 'Rename' : 'Copy'} ${this.entityType}`;
  }

  closeModal() {
    this.dialogRef.close();
  }

  emitCopy(data) {
    this.copyEntity.emit(data);
  }

  emitRename(data) {
    this.renameEntity.emit(data);
  }
}
