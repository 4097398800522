import { trigger, state, style } from '@angular/animations';
import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { DocumentActions } from '../document/document-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { ItemData } from '@common/item-data/item-data';
import { ItemChooserService } from './item-chooser.service';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { DocumentService } from '../document/document.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

@Component({
  selector: 'app-item-chooser',
  templateUrl: './item-chooser.component.html',
  styleUrls: ['./item-chooser.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
          'margin-right': 0,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          'margin-right': -1000,
        }),
      ),
      /*
      transition('open => closed', [
        animate('1s ease-out', style({ opacity: 0.5, 'margin-right': -1000 }))
      ]),
      transition('closed => open', [
        animate('0.6s ease-in-out', style({ 'margin-right': 0, opacity: 1 }))
      ]),*/
    ]),
  ],
})
export class ItemChooserComponent implements OnDestroy {
  private destroy$ = new Subject();
  @Input() editorMode: EditorMode = EditorMode.EDIT;
  @Input() targetSourceType: ChooserSourceOptionTypes;
  @Input() hideSource: boolean = false;
  @Input() title: string;
  existingItemIds: Set<string>;
  existingItemIds$: BehaviorSubject<any> = new BehaviorSubject(null);
  itemChooserLevelSelectionActive = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private itemChooserService: ItemChooserService,
    private documentService: DocumentService,
  ) {
    this.documentService.documentElements.pipe(takeUntil(this.destroy$)).subscribe((elements) => {
      const modelBindings = elements.map((e) => e.modelBindings);
      this.existingItemIds = new Set();
      modelBindings.forEach((m) => {
        if (m?.item) {
          const id = m.item.split(':')[1];
          this.existingItemIds.add(id);
          //console.log('Current Board Existing Ids', this.existingItemIds);
        }
      });
      this.existingItemIds$.next(this.existingItemIds);
    });

    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.ITEM_CHOOSER_LEVEL_SELECTION)) {
            this.itemChooserLevelSelectionActive = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  hideItemChooser() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addItem';
    overlay.showChooser = false;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  async addItemData(itemData: ItemData) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    this.itemChooserService.addItemToDocument(AssortmentUtil.convertItemData(itemData));
  }

  async addMultipleItemData(itemData: ItemData[]) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    this.itemChooserService.addItemsToDocument(itemData.map(AssortmentUtil.convertItemData));
  }
}
