import { ObjectUtil } from '@contrail/util';
import { DocumentDynamicTextUtil } from './document-dynamic-text-util';
import { CanvasUtil } from '../../../canvas/canvas-util';
import { DocumentAction, DocumentElement, DynamicTextDisplayFunction } from '@contrail/documents';
import { DocumentDynamicTextService } from './document-dynamic-text.service';

export class DocumentDynamicTextActionHandler {
  constructor(private dynamicTextService: DocumentDynamicTextService) {}

  shouldHandleDynamicTexts(actions) {
    if (!this.dynamicTextService.dynamicTextFeatureFlag) {
      return;
    }
    const filteredActions = actions.filter((a) =>
      ['MODIFY_ELEMENT', 'ADD_ELEMENT', 'DELETE_ELEMENT', 'REBIND_MODEL'].includes(a.changeDefinition.changeType),
    );
    if (
      filteredActions.length === 0 ||
      filteredActions.find(
        (a) =>
          a.changeDefinition.changeType === 'ADD_ELEMENT' &&
          DocumentDynamicTextUtil.isDynamicTextElement(a.changeDefinition.elementData),
      )
    ) {
      return false;
    }
    const elements = this.dynamicTextService.documentService.currentDocument.elements.filter((e) =>
      filteredActions.map((a) => a.changeDefinition.elementId).includes(e.id),
    );
    for (let a of filteredActions) {
      if (['MODIFY_ELEMENT', 'DELETE_ELEMENT', 'REBIND_MODEL'].includes(a.changeDefinition.changeType)) {
        const element = this.dynamicTextService.documentService.currentDocument.elements.find(
          (e) => e.id === a.changeDefinition.elementId,
        );
        if (
          element.type === 'component' ||
          element.type === 'frame' ||
          (this.isMovingAction(a) && DocumentDynamicTextUtil.isDynamicTextElement(element))
        ) {
          if (this.isMovingAction(a) && elements.find((e) => e.type === 'frame')) {
            // moving as part of a frame.. do not handle
            return false;
          }
          if (
            a.changeDefinition.changeType === 'MODIFY_ELEMENT' &&
            element.type === 'component' &&
            this.isResizeAction(a)
          ) {
            return false;
          }
          return true;
        }
      }
      if (
        a.changeDefinition.changeType === 'ADD_ELEMENT' &&
        !DocumentDynamicTextUtil.isDynamicTextElement(a.changeDefinition.elementData)
      ) {
        if (a.changeDefinition.elementData.type === 'component') {
          return true;
        }
      }
    }
    return false;
  }

  async handleDynamicTexts(actions) {
    const filteredActions = actions.filter(
      (a) => a.changeDefinition.elementData || a.changeDefinition.changeType === 'DELETE_ELEMENT',
    );
    if (filteredActions.length === 0) {
      return;
    }
    const changeDefinition = filteredActions[0].changeDefinition;
    const undoChangeDefinition = filteredActions[0].undoChangeDefinition;
    const updateDynamicTexts = [];
    if (changeDefinition.changeType === 'MODIFY_ELEMENT') {
      if (
        changeDefinition.elementData.position &&
        undoChangeDefinition &&
        Object.keys(ObjectUtil.cloneDeep(changeDefinition.elementData)).length === 2 // only position change
      ) {
        const additionalActions = await this.handleElementMove(filteredActions, updateDynamicTexts);
        if (additionalActions.length > 0) {
          actions.push(...additionalActions);
        }
      } else {
        const additionalActions = await this.handleElementModify(filteredActions, updateDynamicTexts);
        actions.push(...additionalActions);
      }
    } else if (changeDefinition.changeType === 'REBIND_MODEL') {
      const additionalActions = await this.handleElementModelRebind(filteredActions, updateDynamicTexts);
      actions.push(...additionalActions);
    } else if (['ADD_ELEMENT', 'DELETE_ELEMENT'].includes(changeDefinition.changeType) && undoChangeDefinition) {
      if (changeDefinition.changeType === 'ADD_ELEMENT' && changeDefinition.elementData.type !== 'component') {
        return;
      }
      if (changeDefinition.changeType === 'DELETE_ELEMENT' && undoChangeDefinition.elementData.type !== 'component') {
        return;
      }
      const additionalActions = await this.handleElementAddOrDelete(
        filteredActions,
        changeDefinition.changeType,
        updateDynamicTexts,
      );
      if (additionalActions.length > 0) {
        actions.push(...additionalActions);
      }
    }
  }

  private isMovingAction(a: any) {
    return (
      a.changeDefinition.changeType === 'MODIFY_ELEMENT' &&
      a.changeDefinition.elementData.position &&
      Object.keys(ObjectUtil.cloneDeep(a.changeDefinition.elementData)).length === 2
    );
  }

  private isResizeAction(a: any) {
    return (
      a.changeDefinition.changeType === 'MODIFY_ELEMENT' &&
      a.changeDefinition.elementData.position &&
      a.changeDefinition.elementData.size === null &&
      a.changeDefinition.elementData.elements &&
      Object.keys(ObjectUtil.cloneDeep(a.changeDefinition.elementData)).length === 4
    );
  }

  private async handleElementModify(actions: any, updateDynamicTexts: any[]) {
    const componentElements = [];
    const frameElements = [];
    for (const action of actions) {
      if (action.changeDefinition.elementData) {
        const element = this.dynamicTextService.documentService.currentDocument.elements.find(
          (e) => e.id === action.changeDefinition.elementData.id,
        );
        if (element.type === 'component' && action.undoChangeDefinition) {
          componentElements.push(element);
        }
        if (element.type === 'frame' && action.undoChangeDefinition) {
          frameElements.push(element);
        }
      }
    }
    let frame = null;
    componentElements.forEach((element) => {
      frame = this.getFrame(element.position);
      DocumentDynamicTextUtil.gatherDynamicTexts(frame, updateDynamicTexts);
    });

    frameElements.forEach((element) => {
      frame = this.dynamicTextService.documentService.documentRenderer.getFrames().get(element.id);
      DocumentDynamicTextUtil.gatherDynamicTexts(frame, updateDynamicTexts);
    });
    if (updateDynamicTexts.length === 0) {
      return [];
    }
    const additionalActions = await this.generateDynamicTextUpdates(updateDynamicTexts);
    return additionalActions;
  }

  private async handleElementModelRebind(actions: any, updateDynamicTexts: any[]) {
    const componentElements = [];
    for (const action of actions) {
      if (action.changeDefinition.elementData) {
        const element = this.dynamicTextService.documentService.currentDocument.elements.find(
          (e) => e.id === action.changeDefinition.elementData.id,
        );
        if (element.type === 'component' && action.undoChangeDefinition) {
          componentElements.push(element);
          element.modelBindings = action.changeDefinition.elementData.modelBindings;
        }
      }
    }
    let frame = null;
    componentElements.forEach((element) => {
      frame = this.getFrame(element.position);
      DocumentDynamicTextUtil.gatherDynamicTexts(frame, updateDynamicTexts);
    });
    if (updateDynamicTexts.length === 0) {
      return [];
    }
    const additionalActions = await this.generateDynamicTextUpdates(updateDynamicTexts, [], [], componentElements);
    return additionalActions;
  }

  private async handleElementAddOrDelete(actions: any, changeType: string, updateDynamicTexts: any[]) {
    const newElements = [];
    const deletedElementIds = [];
    for (const action of actions) {
      let frame = null;
      if (changeType === 'ADD_ELEMENT') {
        frame = this.getFrame(action.changeDefinition.elementData.position);
        newElements.push(action.changeDefinition.elementData);
      } else {
        const element = this.dynamicTextService.documentService.currentDocument.elements.find(
          (e) => e.id === action.changeDefinition.elementId,
        );
        if (element) {
          frame = this.dynamicTextService.documentService.documentRenderer.getFrameForElement(element);
        }
        deletedElementIds.push(action.changeDefinition.elementId);
      }
      if (frame) {
        const dynamicTextElements = frame.elements
          .filter(
            (e) =>
              e.canvasElement.elementDefinition.propertyBindings &&
              e.canvasElement.elementDefinition.type === 'text' &&
              e.canvasElement.elementDefinition.displayFunction !== 'label' &&
              e.canvasElement.elementDefinition.propertyBindings &&
              !e.canvasElement.elementDefinition.propertyBindings.text.includes('frame.name') &&
              !DocumentDynamicTextUtil.isBasedOnDocument(e.canvasElement.elementDefinition),
          )
          .map((e) => e.canvasElement.elementDefinition);
        updateDynamicTexts.push(...dynamicTextElements);
      }
    }
    const additionalActions = await this.generateDynamicTextUpdates(updateDynamicTexts, newElements, deletedElementIds);
    return additionalActions;
  }

  private async handleElementMove(actions: any, updateDynamicTexts: any[]) {
    for (const action of actions) {
      const element = this.dynamicTextService.documentService.currentDocument.elements.find(
        (e) => e.id === action.changeDefinition.elementData.id,
      );
      if (element.type === 'component' && action.undoChangeDefinition) {
        const previousFrame = this.getFrame(action.undoChangeDefinition.elementData.position);
        const currentFrame = this.getFrame(action.changeDefinition.elementData.position);
        if (currentFrame?.id !== previousFrame?.id) {
          if (previousFrame) {
            previousFrame.elements
              .filter(
                (e) =>
                  e.canvasElement.elementDefinition.propertyBindings &&
                  e.canvasElement.elementDefinition.type === 'text' &&
                  e.canvasElement.elementDefinition.displayFunction !== DynamicTextDisplayFunction.LABEL &&
                  e.canvasElement.elementDefinition.propertyBindings &&
                  !DocumentDynamicTextUtil.isBasedOnDocument(e.canvasElement.elementDefinition),
              )
              .map((e) => e.canvasElement.elementDefinition)
              .forEach((e) => {
                if (updateDynamicTexts.findIndex((d) => d.id === e.id) === -1) updateDynamicTexts.push(e);
              });
          }
          if (currentFrame) {
            currentFrame.elements
              .filter(
                (e) =>
                  e.canvasElement.elementDefinition.propertyBindings &&
                  e.canvasElement.elementDefinition.type === 'text' &&
                  e.canvasElement.elementDefinition.displayFunction !== DynamicTextDisplayFunction.LABEL &&
                  e.canvasElement.elementDefinition.propertyBindings &&
                  !DocumentDynamicTextUtil.isBasedOnDocument(e.canvasElement.elementDefinition),
              )
              .map((e) => e.canvasElement.elementDefinition)
              .forEach((e) => {
                if (updateDynamicTexts.findIndex((d) => d.id === e.id) === -1) updateDynamicTexts.push(e);
              });
          }
        }
      } else if (
        DocumentDynamicTextUtil.isDynamicTextElement(element) &&
        !DocumentDynamicTextUtil.isBasedOnDocument(element)
      ) {
        const origElement = { ...element };
        const previousFrame = this.getFrame(action.undoChangeDefinition.elementData.position);
        const currentFrame = this.getFrame(action.changeDefinition.elementData.position);
        if (currentFrame?.id !== previousFrame?.id) {
          const text = await this.dynamicTextService.getTextValue(element, currentFrame);
          this.dynamicTextService.adjustSizeAndStyling(element, text);
          action.changeDefinition.elementData.text = element.text;
          action.changeDefinition.elementData.size = element.size;
          if (action.undoChangeDefinition) {
            action.undoChangeDefinition.elementData.text = origElement.text;
            action.undoChangeDefinition.elementData.size = origElement.size;
          }
        }
      }
    }
    if (updateDynamicTexts.length === 0) {
      return [];
    }
    const additionalActions = await this.generateDynamicTextUpdates(updateDynamicTexts);
    return additionalActions;
  }

  /**
   * This method generates the additional actions for updating the dynamic text elements as a result of component element changes
   * @param updateDynamicTexts
   * @param newElements
   * @param deletedElementIds
   * @returns
   */
  private async generateDynamicTextUpdates(
    updateDynamicTexts: DocumentElement[],
    newElements?: any[],
    deletedElementIds?: string[],
    updatedComponentElements?: any[],
  ) {
    const documentId = this.dynamicTextService.documentService.getDocument().id;
    const actions: DocumentAction[] = [];
    for (const element of updateDynamicTexts) {
      const text = await this.dynamicTextService.getTextValue(
        element,
        this.dynamicTextService.documentService.documentRenderer.getFrameForElement(element),
        newElements,
        deletedElementIds,
        updatedComponentElements,
      );
      const origElement = { ...element };
      this.dynamicTextService.adjustSizeAndStyling(element, text);
      if (origElement.text !== element.text) {
        const action: DocumentAction = new DocumentAction(
          {
            changeType: 'MODIFY_ELEMENT',
            documentId: documentId,
            elementData: { id: element.id, text: element.text, size: element.size },
            elementId: element.id,
          },
          {
            changeType: 'MODIFY_ELEMENT',
            documentId: documentId,
            elementData: { id: element.id, text: origElement.text, size: origElement.size },
            elementId: element.id,
          },
        );
        actions.push(action);
      }
    }
    return actions;
  }

  private getFrame(position) {
    let frame = null;
    for (const f of this.dynamicTextService.documentService.documentRenderer.getFrames().values()) {
      if (CanvasUtil.isPositionInBox(position, f.box)) {
        frame = f;
        break;
      }
    }
    return frame;
  }
}
