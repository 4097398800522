import { PositionDefinition } from '@contrail/documents';
import { Table, TableColumn, TableRow } from '../table/table';
import { TableReorderRenderer } from './table-reorder-renderer';

export class TableReorderState {
  public targetArea: TableColumn | TableRow; // target column or row
  public selectedArea: TableColumn | TableRow;
  public currentMousePosition: PositionDefinition; // relative to table position
  private renderer: TableReorderRenderer;
  constructor(private table: Table) {
    this.renderer = new TableReorderRenderer(this.table, this);
  }

  public render(ctx, viewScale, params: { x; y; width; height }) {
    this.renderer.render(ctx, viewScale, params);
  }

  public isSet() {
    return this.selectedArea != null;
  }

  public clear() {
    this.targetArea = null;
    this.selectedArea = null;
    this.currentMousePosition = null;
  }

  public setSelectedArea(selectedArea: TableColumn | TableRow) {
    this.selectedArea = selectedArea;
  }

  public setTargetArea(direction: 'column' | 'row', px: number, py: number): TableColumn | TableRow {
    this.currentMousePosition = { x: px, y: py };
    if (this.targetArea != null) {
      const isColumn = 'x' in this.targetArea;
      let targetColumn, targetRow;
      if (isColumn) {
        targetColumn = this.targetArea as TableColumn;
      } else {
        targetRow = this.targetArea as TableRow;
      }
      if (
        (targetColumn &&
          ((px >= targetColumn.x && px <= targetColumn.x + targetColumn.width) ||
            px < 0 ||
            px >= this.table.size.width)) ||
        (targetRow &&
          ((py >= targetRow.y && py <= targetRow.y + targetRow.height) || py < 0 || py >= this.table.size.height))
      ) {
        return this.targetArea;
      }
    }

    let targetArea;
    if (direction === 'column') {
      if (px < 0) {
        targetArea = this.table.columns[0];
      } else if (px >= this.table.size.width) {
        targetArea = this.table.columns[this.table.columns.length - 1];
      }
      if (!targetArea) {
        this.table.columns.forEach((column) => {
          if (!targetArea && px >= column.x && px < column.x + column.width) {
            targetArea = column;
          }
        });
      }
    } else if (direction === 'row') {
      if (py < 0) {
        targetArea = this.table.rows[0];
      } else if (py >= this.table.size.height) {
        targetArea = this.table.rows[this.table.rows.length - 1];
      }
      if (!targetArea) {
        this.table.rows.forEach((row) => {
          if (!targetArea && py >= row.y && py < row.y + row.height) {
            targetArea = row;
          }
        });
      }
    }

    this.targetArea = targetArea;
    return this.targetArea;
  }
}
