import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-image-transparency',
  templateUrl: './property-configurator-image-transparency.component.html',
  styleUrls: ['./property-configurator-image-transparency.component.scss'],
})
export class PropertyConfiguratorImageTransparencyComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  public defaultValue = 1;
  public opacity = this.defaultValue;

  public showSlider = false;
  constructor() {}

  ngOnChanges(): void {
    this.opacity = this.currentValue != null ? this.currentValue : this.defaultValue;
  }

  setValue(opacityValue) {
    this.opacity = opacityValue;
    const changes = {
      style: {
        opacity: this.opacity,
      },
    };
    this.valueChange.emit(changes);
  }

  handleImageTransparencyChange(event) {
    this.setValue(event.value);
  }

  formatLabel(value: number) {
    return value;
  }
}
