import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrameTemplate } from '@common/frame-templates/frame-template';

@Component({
  selector: 'app-frame-template-card',
  template: `<app-list-card
    *ngIf="template"
    [title]="template.name"
    [imageUrl]="template.previewFile?.downloadUrl"
    (openMenu)="showMenu($event)"
    iconUrl="assets/images/template_icon_blue.svg"
    [iconUrl]="isOrgTemplate ? 'assets/images/group_icon.svg' : 'assets/images/person_icon.svg'"
    [showIconAfterTitle]="true"
    [showUpdatedOn]="false"
  >
  </app-list-card>`,
  styles: [],
})
export class FrameTemplateCardComponent implements OnInit {
  @Input() template: FrameTemplate;
  @Input() isOrgTemplate = false;
  @Output() openMenu = new EventEmitter();
  constructor() {}
  ngOnInit(): void {}
  public showMenu($event) {
    this.openMenu.emit($event);
  }
}
