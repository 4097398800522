import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-content-modal',
  template: `
    <div class="flex flex-col w-full h-full" *ngIf="content">
      <app-content-modal-header
        [contentType]="fileType"
        [fileName]="content?.fileName"
        (cancelView)="cancel()"
        (navigate)="setNavigation($event)"
      ></app-content-modal-header>

      <div class="body w-full" style="height: calc(100% - 60px);">
        <app-content-modal-2d *ngIf="fileType !== '3D'" [content]="content"></app-content-modal-2d>
        <app-content-modal-3d *ngIf="fileType === '3D'" [content]="content"></app-content-modal-3d>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply flex flex-col w-full h-full;
      }
      :host ::ng-deep {
        .mat-expansion-panel-header,
        .mat-expansion-panel-body {
          @apply px-3 pb-0;
        }
        .mat-expansion-panel-header {
          height: 36px !important;
        }
      }
    `,
  ],
})
export class ContentModalComponent implements OnInit {
  @Output() updated = new EventEmitter();
  public defaults: any;

  public contentId: string;
  public accessLevel = 'EDIT';
  public content;

  public fileType: '3D' | '2D' | 'FILE';
  currentNav = 'DETAILS';

  constructor(
    public dialogRef: MatDialogRef<ContentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contentId = data.contentId || data.content?.id;
    this.accessLevel = data.accessLevel;
  }

  async ngOnInit() {
    await this.loadContent();

    if (this.content?.contentType.includes('gltf-binary')) {
      this.fileType = '3D';
    } else if (ContentService.isNonViewableImage(this.content?.fileName)) {
      this.fileType = 'FILE';
    } else if (this.content?.contentType.includes('image')) {
      this.fileType = '2D';
    } else {
      alert('contentType is missing !!!');
    }
  }

  private async loadContent() {
    this.content = await new Entities().get({
      entityName: 'content',
      id: this.contentId,
      relations: [
        'primaryFile',
        'largeViewable',
        'mediumLargeViewable',
        'mediumViewable',
        'smallViewable',
        'tinyViewable',
      ],
    });
  }

  setNavigation(evt) {
    this.currentNav = evt;
  }

  complete(item) {
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close();
  }

  emitUpdate($event) {
    this.updated.emit($event);
  }
}
