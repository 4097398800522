import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasClipElement } from './canvas-clip-element';

export class CanvasCircleElement extends CanvasClipElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);

    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
      border: {
        width: this.DEFAULT_BORDER_SIZE,
        color: this.DEFAULT_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    ctx.beginPath();
    ctx.ellipse(0, 0, width * 0.5, height * 0.5, 0, 0, 2 * Math.PI);
    ctx.fillStyle = this.elementDefinition.style?.backgroundColor || 'rgba(0,0,0,0)';
    ctx.fill();
    this.stroke(ctx, options);
    ctx.closePath();

    return;
  }

  public addPath(ctx, { x, y, width, height }) {
    ctx.ellipse(0, 0, width * 0.5, height * 0.5, 0, 0, 2 * Math.PI);
  }

  public getPath({ x, y, width, height }): Path2D {
    const path = new Path2D();
    path.ellipse(x + width * 0.5, y + height * 0.5, width * 0.5, height * 0.5, 0, 0, 2 * Math.PI);
    return path;
  }

  public toSVG({ x, y, width, height }): HTMLElement {
    const element = document.createElement('ellipse');
    element.setAttribute('cx', `${x + width * 0.5}`);
    element.setAttribute('cy', `${y + height * 0.5}`);
    element.setAttribute('rx', `${width * 0.5}`);
    element.setAttribute('ry', `${height * 0.5}`);

    if (this.isMask) {
      const mask = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
      element.setAttribute('fill', '#000');
      mask.setAttribute('id', `mask_${this.elementDefinition.id}`);
      mask.setAttributeNS(null, 'clipPathUnits', 'userSpaceOnUse');
      mask.appendChild(element);
      return mask as unknown as HTMLElement;
    }

    this.setSVGFillAttribute(element);
    this.setSVGStrokeAttribute(element);

    return element;
  }
}
