import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { BoardService } from '../board.service';
import { BoardFrameService } from '../board-frames-list/board-frames.service';
import { DocumentSelectors } from '../document/document-store';
import { DocumentService } from '../document/document.service';
import { CoordinateBox } from '@contrail/svg';

@Component({
  selector: 'app-board-navigation-bar',
  templateUrl: './board-navigation-bar.component.html',
  styleUrls: ['./board-navigation-bar.component.scss'],
})
export class BoardZoomNavigationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Output() zoomOut = new EventEmitter();
  @Output() zoomIn = new EventEmitter();

  public isFullScreen$;
  public frames$: Observable<any>;
  public viewSize$: Observable<any>;

  selectedIdx$ = this.boardFrameService.selectedFrameIdx$;
  presentation = false;
  showMinimap = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private boardService: BoardService,
    private boardFrameService: BoardFrameService,
    private documentService: DocumentService,
  ) {
    this.frames$ = this.boardFrameService.frames$;
    this.isFullScreen$ = this.boardService.fullScreen$;
  }

  ngOnInit(): void {
    this.boardFrameService.presentationMode$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.presentation = res;
    });
    this.boardService.showMinimap$.pipe(takeUntil(this.destroy$)).subscribe((showMinimap) => {
      this.showMinimap = showMinimap;
    });
    this.viewSize$ = this.store.select(DocumentSelectors.viewSize);
  }

  ngOnDestroy(): void {
    this.boardFrameService.selectedFrameIdx$.next(-1);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setZoomScale(scale: number) {
    this.boardService.zoomPanHandler.zoomFactor = scale;
    this.boardService.zoomPanHandler.updateViewPort(null);
  }
  zoomToFit() {
    const scale = this.boardService.calculateZoomFactorForZoomToFit();
    if (!scale) {
      return;
    }
    const bound: CoordinateBox = this.documentService.getAllCommonBounds();
    if (bound.width === 0 && bound.height === 0) {
      // empty board
      return;
    }

    const renderer: any = this.documentService.documentRenderer;
    const canvasSize = renderer.state?.canvasDocument?.canvasDisplay?.canvasSize;

    this.boardService.zoomPanHandler.zoomFactor = scale;

    const focalPoint = {
      x: bound.left + bound.width / 2,
      y: bound.top + bound.height / 2,
    };
    const newViewBox = {
      width: canvasSize.width * scale,
      height: canvasSize.height * scale,
      x: focalPoint.x - (canvasSize.width * scale) / 2,
      y: focalPoint.y - (canvasSize.height * scale) / 2,
    };
    this.boardService.zoomPanHandler.setViewPort(newViewBox);
  }
  doZoomIn() {
    this.zoomIn.emit(null);
  }
  doZoomOut() {
    this.zoomOut.emit(null);
  }

  navigateToStartingLocation() {
    this.boardService.navigateToStartingLocation();
  }

  fullScreen() {
    this.boardService.fullScreen();
  }

  exitFullScreen() {
    this.boardService.exitFullScreen();
  }

  back(f, idx) {
    this.boardFrameService.onFrameSelection$.next(true);
    this.boardFrameService.selectedFrameIdx$.next(idx - 1);
    this.boardFrameService.updateViewportByFrame(f.frame);
  }
  next(f, idx) {
    this.boardFrameService.onFrameSelection$.next(true);
    this.boardFrameService.selectedFrameIdx$.next(idx + 1);
    this.boardFrameService.updateViewportByFrame(f.frame);
  }

  selectFrame(f, idx) {
    this.boardFrameService.selectedFrameIdx$.next(idx);
    this.boardFrameService.updateViewportByFrame(f.frame);
  }

  toggleMode(f) {
    // presentation mode false && frame side panel closed => false
    this.boardFrameService.onFrameSelection$.next(!this.presentation || this.boardFrameService.isFrameSideMenuVisible);
    if (!this.presentation) {
      this.boardFrameService.selectedFrameIdx$.next(0);
      this.boardFrameService.updateViewportByFrame(f.frame);
    }
    this.boardFrameService.presentationMode$.next(!this.presentation);
  }

  toggleMinimap() {
    this.boardService.showMinimap$.next(!this.showMinimap);
  }
}
