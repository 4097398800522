import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class LockDocumentElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'lock_elements') {
        const selected = this.documentService.getSelectedExpandedElements();
        if (selected?.length > 0) {
          const element = request.sourceEvent?.element || selected[0];
          const isLocked = !element.isLocked;
          const actions: Array<DocumentAction> = [];
          selected.forEach((el) => {
            actions.push(
              new DocumentAction(
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    isLocked,
                  },
                },
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    isLocked: el.isLocked,
                  },
                },
              ),
            );
          });
          this.documentService.handleDocumentActions(actions);

          // Trigger toolbar to get updated
          this.documentService.handleDocumentElementEvent({
            element,
            selectedElements: this.documentService.getSelectedExpandedElements(),
            eventType: 'selected',
          });
        }
      } else if (request?.actionType === 'unlock_all_elements') {
        const lockedElements = this.documentService.getDocument().elements.filter((e) => e.isLocked);
        if (lockedElements?.length > 0) {
          const actions: Array<DocumentAction> = [];
          lockedElements.forEach((el) => {
            actions.push(
              new DocumentAction(
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    isLocked: false,
                  },
                },
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    isLocked: el.isLocked,
                  },
                },
              ),
            );
          });
          this.documentService.handleDocumentActions(actions);
        }
      }
    });
  }
}
