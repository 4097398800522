import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasTableElement } from '../../../elements/table/canvas-table-element';
import { TableColumn, TableRow } from '../../../elements/table/table-manager/table/table';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class TableReorderHandler {
  private selectedTable: CanvasTableElement;
  private selectedArea: TableColumn | TableRow; // row or column
  private direction: 'column' | 'row';
  private target: DRAG_DIRECTIONS;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS; index? }) {
    this.clear();
    if (
      elementTarget?.element?.elementDefinition?.type === 'table' &&
      [DRAG_DIRECTIONS.DRAG_COLUMN, DRAG_DIRECTIONS.DRAG_ROW].indexOf(elementTarget?.target) !== -1 &&
      elementTarget?.index != null
    ) {
      const isColumn = elementTarget.target === DRAG_DIRECTIONS.DRAG_COLUMN;
      const selectedIndex = isColumn ? elementTarget.index.x : elementTarget.index.y;
      this.target = elementTarget.target;
      this.direction = isColumn ? 'column' : 'row';
      this.selectedTable = elementTarget.element as CanvasTableElement;
      this.selectedArea = isColumn
        ? this.selectedTable.table.column(selectedIndex)
        : this.selectedTable.table.row(selectedIndex);
      this.selectedTable.table.setReorderSelectedArea(this.selectedArea);
      this.selectedTable.table.handleSelectOnClick(elementTarget);
      this.canvasDocument.interactionHandler.setInteractionCursor('grabbing');
    }
  }

  public dragged(event) {
    if (!this.selectedTable) {
      return;
    }

    const documentPosition = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    this.selectedTable.table.setReorderTargetArea(
      this.direction,
      documentPosition.x - this.selectedTable.elementDefinition.position.x,
      documentPosition.y - this.selectedTable.elementDefinition.position.y,
    );
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.selectedTable) {
      return;
    }

    const documentPosition = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    const targetArea = this.selectedTable.table.setReorderTargetArea(
      this.direction,
      documentPosition.x - this.selectedTable.elementDefinition.position.x,
      documentPosition.y - this.selectedTable.elementDefinition.position.y,
    );
    this.canvasDocument.interactionHandler.setInteractionCursor('move');
    if (targetArea && targetArea?.index !== this.selectedArea.index) {
      this.selectedTable.table.handleSelectOnClick({
        element: this.selectedTable,
        target: this.target,
        index: this.target === DRAG_DIRECTIONS.DRAG_COLUMN ? { x: targetArea.index } : { y: targetArea.index },
      });
      this.canvasDocument.canvasTableService.move(
        this.direction,
        this.selectedTable,
        this.selectedArea.index,
        targetArea.index,
      );
    }

    this.clear();
    this.canvasDocument.draw();
  }

  private clear() {
    this.selectedTable?.table?.clearReorderData();
    this.selectedTable = null;
    this.selectedArea = null;
    this.direction = null;
    this.target = null;
  }
}
