import { PositionDefinition, SizeDefinition, StyleDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import {
  DEFAULT_PEN_BORDER_SIZE,
  DEFAULT_HIGHLIGHTER_BORDER_SIZE,
  DEFAULT_PEN_BORDER_COLOR,
  DEFAULT_HIGHLIGHTER_BORDER_COLOR,
  DEFAULT_HIGHLIGHTER_OPACITY,
} from '../../../constants';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { NormalizedEvent } from '../canvas-event-handlers';

export class PenDrawHandler {
  private holdShiftPosition: PositionDefinition = null;
  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event: NormalizedEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();
    const interactionMode = this.canvasDocument.interactionHandler.interactionMode;
    if (['pen', 'highlighter'].indexOf(interactionMode) === -1) return;

    const { x, y } = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    const isPen = interactionMode === 'pen';
    const lastAppliedStyle = this.canvasDocument.documentService.lastAppliedStyle.get(interactionMode);
    const defaultBorderWidth = isPen ? DEFAULT_PEN_BORDER_SIZE : DEFAULT_HIGHLIGHTER_BORDER_SIZE;
    const defaultBorderColor = isPen ? DEFAULT_PEN_BORDER_COLOR : DEFAULT_HIGHLIGHTER_BORDER_COLOR;
    this.canvasDocument.canvasRenderer.freeDrawRenderer.lineWidth =
      lastAppliedStyle?.border?.width || defaultBorderWidth;
    this.canvasDocument.canvasRenderer.freeDrawRenderer.strokeStyle =
      lastAppliedStyle?.border?.color || defaultBorderColor;
    this.canvasDocument.canvasRenderer.freeDrawRenderer.opacity = isPen ? null : DEFAULT_HIGHLIGHTER_OPACITY;
    this.canvasDocument.canvasRenderer.freeDrawRenderer.points.push([x, y]);
  }

  public dragged(event: NormalizedEvent) {
    if (!this.canvasDocument.canvasRenderer.freeDrawRenderer.points.length) {
      return;
    }

    let { x, y } = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);

    if (
      event.shiftKey &&
      !this.holdShiftPosition &&
      this.canvasDocument.canvasRenderer.freeDrawRenderer.points?.length > 1
    ) {
      this.calculateHoldShiftPosition(x, y);
    }
    if (this.holdShiftPosition) {
      if (event.shiftKey) {
        if (this.holdShiftPosition.x != null) {
          x = this.holdShiftPosition.x;
        } else if (this.holdShiftPosition.y != null) {
          y = this.holdShiftPosition.y;
        }
      } else {
        this.calculateHoldShiftPosition(x, y);
      }
    }

    this.canvasDocument.canvasRenderer.freeDrawRenderer.points.push([x, y]);
    this.canvasDocument.draw();
  }

  private calculateHoldShiftPosition(x, y) {
    const lastPosition =
      this.canvasDocument.canvasRenderer.freeDrawRenderer.points[
        this.canvasDocument.canvasRenderer.freeDrawRenderer.points.length - 1
      ];
    const x1 = lastPosition[0];
    const y1 = lastPosition[1];
    const angle = Math.abs((Math.atan2(y - y1, x - x1) * 180) / Math.PI);
    if (angle > 45 && angle < 135) {
      this.holdShiftPosition = { x: x1 };
    } else {
      this.holdShiftPosition = { y: y1 };
    }
  }

  public dragended(event: NormalizedEvent) {
    if (!this.canvasDocument.canvasRenderer.freeDrawRenderer.points.length) {
      return;
    }

    const [left, top, right, bottom] = CanvasUtil.getSvgPathBounds(
      this.canvasDocument.canvasRenderer.freeDrawRenderer.points,
    );

    const position = { x: left, y: top };
    const size = { width: right - left, height: bottom - top };
    const style: StyleDefinition = {
      border: {
        width: Number(this.canvasDocument.canvasRenderer.freeDrawRenderer.lineWidth),
        color: this.canvasDocument.canvasRenderer.freeDrawRenderer.strokeStyle,
      },
    };
    let points;
    if (size.height === 0 || size.width === 0) {
      points = [
        [0, 0],
        [100, 100],
      ];
    } else {
      points = this.canvasDocument.canvasRenderer.freeDrawRenderer.points.map(([x, y]) => [
        ((x - position.x) * 100) / size.width,
        ((y - position.y) * 100) / size.height,
      ]);
    }
    this.canvasDocument.actionsDispatcher.addNewElement(this.canvasDocument.interactionHandler.interactionMode, {
      position,
      size,
      points,
      style,
    });
    this.clear();
    this.canvasDocument.draw();
  }

  private clear() {
    this.canvasDocument.canvasRenderer.freeDrawRenderer.points = [];
    this.holdShiftPosition = null;
  }

  public static med(A: number[], B: number[]) {
    return [(A[0] + B[0]) / 2, (A[1] + B[1]) / 2];
  }

  public static percentageToFixed(
    points: number[][],
    size: SizeDefinition,
    position: PositionDefinition = { x: 0, y: 0 },
  ): number[][] {
    return points.map(([x, y]) => [position.x + (x * size.width) / 100, position.y + (y * size.height) / 100]);
  }

  public static getSvgPathFromPointsPercentage(points: number[][], size: SizeDefinition): string {
    return this.getSvgPathFromPoints(this.percentageToFixed(points, size));
  }

  public static getSvgPathFromPoints(points: number[][]): string {
    if (!points.length) {
      return '';
    }

    const max = points.length - 1;
    const TO_FIXED_PRECISION = /(\s?[A-Z]?,?-?[0-9]*\.[0-9]{0,2})(([0-9]|e|-)*)/g;

    return points
      .reduce(
        (acc, point, i, arr) => {
          if (i === max) {
            acc.push(point, point);
            // acc.push(point, this.med(point, arr[0]), "L", arr[0], "Z");
          } else {
            acc.push(point, this.med(point, arr[i + 1]));
          }
          return acc;
        },
        ['M', points[0], 'Q'],
      )
      .join(' ')
      .replace(TO_FIXED_PRECISION, '$1');
  }
}
