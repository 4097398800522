import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareAppComponent } from './share-app.component';
import { ShareAppModalComponent } from './share-app-modal.component';
import { ComponentsModule } from '../components/components.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LegacyLinksComponent } from './components/legacy-links/legacy-links.component';
import { WorkspacePrincipalsComponent } from './components/workspace-principals/workspace-principals.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [ShareAppModalComponent, ShareAppComponent, LegacyLinksComponent, WorkspacePrincipalsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ClipboardModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTableModule,
    PipesModule,
  ],
  exports: [ShareAppModalComponent, ShareAppComponent],
})
export class ShareAppModule {}
