<div class="header" *ngIf="showHeader">
  <div class="title">{{ title }}</div>
  <div (click)="handleClose()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<app-chooser-data-source-selector
  [style.display]="showSelectSource ? 'flex' : 'none'"
  [style.height]="showSelectSource ? 'calc(100% - 64px)' : '0px'"
  class="source-selector-container"
  [entityType]="entityType"
  (selectSource)="handleSourceChange($event)"
  (cancel)="toggleSourceSourceSelector(false)"
></app-chooser-data-source-selector>

<div tabindex="1" #dataPane [attr.class]="'chooser chooser-data-' + entityType" *ngIf="!showSelectSource">
  <div class="source-wrapper centered-vertically" *ngIf="allowSourceChange" (click)="toggleSourceSourceSelector(true)">
    <div class="centered-vertically">
      <img class="source-icon" [src]="chooserSourceOption$.value.icon" *ngIf="chooserSourceOption$.value?.icon" />
      <span
        [matTooltip]="chooserSourceOption$.value?.name"
        [matTooltipDisabled]="chooserSourceOption$.value?.name.length < 30"
      >
        {{ chooserSourceOption$.value?.name | shorten: 30 }}
      </span>
    </div>
    <div class="cursor-pointer text-accent">Change</div>
  </div>
  <div class="search-bar-wrapper">
    <app-search-bar #documentSearch [placeholder]="'Search'" #searchBar></app-search-bar>
  </div>
  <div class="menu-wrapper" *ngIf="showFilter">
    <div class="menu-wrapper-left">
      <div class="mr-3 min-w-[16px]">
        <mat-checkbox
          *ngIf="allowAddMultipleEntities && (dataSource?.results$ | async)?.length"
          color="primary"
          (change)="handleToggleSelectAll()"
          [checked]="selectAll"
          #selectItemCheckbox
          data-test="toggle-all-items"
          disableRipple="true"
        >
        </mat-checkbox>
      </div>
      <div class="sort-filter-menu" data-test="open-sort-panel">
        <app-menu
          name="Filter"
          icon="filter_list"
          position="left"
          [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
          data-test="open-filter-panel"
        >
          <app-filter-panel
            [equalsOnly]="dataSourceType === 'LIBRARY' ? true : false"
            [sourceValues]="dataSource?.assortmentItemData$ | async"
            [filterDefinition]="filterDefinition"
            (filterCriteriaChange)="setFilterCriteria($event)"
          >
          </app-filter-panel>
        </app-menu>

        <app-menu name="Sort" icon="sort" position="left" [highlight]="currentSorts?.length">
          <app-sort-panel
            data-test="sort-panel"
            [currentSorts]="currentSorts"
            [properties]="sortProperties"
            (performSort)="performSort($event)"
          >
          </app-sort-panel>
        </app-menu>
      </div>

      <!--<div class="clearAll cursor-pointer text-accent" (click)="clearFilters()">Clear</div>-->
      <!-- <ng-container *ngIf="allowAddMultipleEntities && (dataSource?.results$ | async)?.length">
      <div
        class="cursor-pointer text-accent select-all-link"
        data-test="select-all-items-link"
        *ngIf="numberOfEligibleResults !== selectedEntities.length"
        (click)="handleToggleSelectAll()"
      >
        Select all
      </div>
      <div
        class="cursor-pointer text-accent select-all-link"
        data-test="deselect-all-items-link"
        *ngIf="numberOfEligibleResults && numberOfEligibleResults === selectedEntities.length"
        (click)="handleToggleSelectAll()"
      >
        Deselect all
      </div>
    </ng-container> -->
    </div>
    <div class="show-all-control" *ngIf="showAllControl">
      <app-menu name="" icon="more_vert" position="left" [highlight]="dateFilter || !showAll">
        <div class="title">Advanced Options</div>
        <mat-divider></mat-divider>
        <div class="advanced-option">
          <mat-slide-toggle color="primary" (change)="toggleShowAll($event)" [checked]="false" labelPosition="before"
            >Eligible only</mat-slide-toggle
          >
          <app-date-filter
            *ngIf="chooserSourceOption$.value?.sourceType !== 'ITEM_LIBRARY'"
            [dateFilterAttribute]="dateFilterAttribute"
            (dateFilterChanged)="dateFilterChanged($event)"
          >
          </app-date-filter>
        </div>
      </app-menu>
      <div class="count">{{ ((dataSource?.results$ | async)?.length | number) || 0 }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="breadcrumb-container flex flex-wrap pt-[12px] pr-[12px] pl-[12px]" *ngIf="breadcrumbs?.length">
    <ng-container *ngTemplateOutlet="breadcrumb"></ng-container>
  </div>
  <div class="data-pane" *ngIf="(dataSource?.results$ | async)?.length && !(loading$ | async)">
    <virtual-scroller
      [style.height]="resultsHeight"
      #scroll
      [items]="dataSource?.results$ | async"
      class="narrow-scroll"
      (vsEnd)="handleScrollEnd($event)"
    >
      <div *ngFor="let entity of scroll.viewPortItems">
        <ng-container
          *ngIf="template"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ entity: entity, handleClick: handleEntityClicked.bind(this) }"
        ></ng-container>
        <app-chooser-entity-card
          *ngIf="!template"
          class="data-row"
          [ngClass]="activeEntityId === entity.id ? 'active-entity' : ''"
          [entityType]="entityType"
          (entityClicked)="handleEntityClicked($event)"
          [entity]="entity"
          data-test="chooser-card"
          [draggable]="draggable && entity?.entityType !== 'workspace'"
          [id]="entity.id"
          [allowAddMultiple]="this.allowAddMultipleEntities"
          [selectedEntityIndex]="getSelectedEntityIndex(entity)"
          (toggleSelection)="toggleSelection($event)"
          (dragstart)="startDrag($event)"
          (addEntity)="this.handleAddSelectedEntity($event)"
        ></app-chooser-entity-card>
      </div>
    </virtual-scroller>
  </div>
  <mat-divider
    *ngIf="allowAddMultipleEntities && (dataSource?.results$ | async)?.length && !(loading$ | async)"
  ></mat-divider>

  <div class="action" *ngIf="(dataSource?.results$ | async)?.length && !(loading$ | async) && allowAddEntity">
    <ng-container *ngIf="selectedEntities?.length && allowAddMultipleEntities">
      <button
        data-test="add-selected-items-button"
        mat-raised-button
        color="primary"
        class="add-selected"
        (click)="handleAddSelectedEntities()"
      >
        ADD SELECTED ({{ selectedEntities?.length }})
      </button>
    </ng-container>
  </div>
  <div class="loading-container" *ngIf="dataSource?.moreResultsLoading$ | async">
    <mat-progress-spinner diameter="30" mode="indeterminate" class="!-mt-32"></mat-progress-spinner>
    <div class="message">Searching...</div>
  </div>
</div>

<ng-template #breadcrumb>
  <ng-container *ngFor="let folder of breadcrumbs; let idx = index; let last = last">
    <div class="flex" (click)="!last && goToFolder(folder, idx)">
      <span
        class="text-gray-700 cursor-pointer"
        [ngClass]="{
          'hover:underline hover:text-primary hover:font-medium': !last,
          'pointer-events-none font-medium': last
        }"
      >
        {{ folder.name }}
      </span>
      <span class="mx-2" *ngIf="!last">/</span>
    </div>
  </ng-container>
</ng-template>
