import { UserSession } from '../user-session';
export interface State {
  userSessions: UserSession[];
  currentSessionId: string;
  syncingRemoteUsers: boolean;
}
export const initialState: State = {
  userSessions: [],
  currentSessionId: null,
  syncingRemoteUsers: false,
};
