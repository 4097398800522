<div class="container">
  <div class="label">Created:</div>
  <div class="timestamp">{{ item?.createdOn | date: 'medium' }}</div>
  <div class="actor">{{ getActorName(item?.createdBy) }}</div>
</div>

<div class="container">
  <div class="label">Updated:</div>
  <div class="value">{{ item?.updatedOn | date: 'medium' }}</div>
  <div class="actor">{{ getActorName(item?.updatedBy) }}</div>
</div>
<div class="container">
  <div class="label">Type:</div>
  <div class="value">{{ type?.label }}</div>
</div>
