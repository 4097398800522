import { Component, Input, OnInit } from '@angular/core';
import { ExportsService } from '../exports.service';
import { BehaviorSubject } from 'rxjs';
import { DownloadService } from '../download/download.service';
import { DocumentElement } from '@contrail/documents';

export interface PDFDownloadPayload {
  entityReference?: string;
  name?: string;
  frames?: DocumentElement[];
  selectedIds?: string[];
  restrictedViewablePropertySlugs?: any;
}

export interface PowerPointDownloadPayload {
  entityReference?: string;
  showWarnings?: boolean;
  selectedIds?: string[];
}

export interface ExcelDownloadPaylod {
  entityReference?: string;
}

export interface ExportDownloadOptions {
  powerpoint?: PowerPointDownloadPayload;
  pdf_snapshots?: PDFDownloadPayload;
  excel?: ExcelDownloadPaylod;
}

@Component({
  selector: 'app-export-widget',
  templateUrl: './export-widget.component.html',
  styleUrls: ['./export-widget.component.scss'],
})
export class ExportWidgetComponent implements OnInit {
  @Input() label = 'Export';
  @Input() options: ExportDownloadOptions;
  @Input() selectedIds: string[];
  @Input() buttonType = 'raised';
  @Input() buttonColor = 'primary';
  @Input() hideFrameOptions = false;

  frameOption: string;
  frameOptions = [
    {
      value: 'selected',
      label: 'Selected frames',
    },
    {
      value: 'all',
      label: 'All frames',
    },
  ];

  fileType: string;
  fileTypes = [];
  isOpen = false;
  downloading$: BehaviorSubject<boolean> = this.downloadService.downloading$;

  private readonly fileTypeLabels = {
    powerpoint: 'PowerPoint',
    pdf_snapshots: 'PDF',
    excel: 'Excel',
  };
  constructor(
    private service: ExportsService,
    private downloadService: DownloadService,
  ) {}

  ngOnInit(): void {
    if (this.options) {
      for (const option in this.options) {
        this.fileTypes.push({
          value: option,
          label: this.fileTypeLabels[option],
        });
      }
    }
  }

  initDownload() {
    if ((this.hideFrameOptions || this.frameOption) && this.fileType) {
      const options = this.options[this.fileType] || {};
      this.downloadService.downloading$.next(true);
      let payload: any = {
        ...options,
      };
      if (this.frameOption === 'selected' && this.selectedIds?.length) {
        payload.selectedIds = this.selectedIds;
      }

      this.service.initDownloadEntityReference(
        {
          reportType: this.fileType,
        },
        payload,
      );
    }
  }
}
