import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-line-marker',
  templateUrl: './property-configurator-line-marker.component.html',
  styleUrls: ['./property-configurator-line-marker.component.scss'],
})
export class PropertyConfiguratorLineMarkerComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentMarkerStart: any;
  @Input() currentMarkerEnd: any;

  public defaultValue = null;
  public markerStart = this.defaultValue;
  public markerEnd = this.defaultValue;
  public markers = [
    {
      value: null,
      label: 'None',
    },
    {
      value: 'arrow',
      icon: 'trending_flat',
    },
  ];

  constructor() {}

  ngOnChanges(): void {
    this.markerStart = this.currentMarkerStart || this.defaultValue;
    this.markerEnd = this.currentMarkerEnd || this.defaultValue;
  }

  setMarkerStart(sizeValue) {
    this.markerStart = sizeValue;
    const changes = {
      lineDefinition: {
        markerStart: this.markerStart,
      },
    };
    this.valueChange.emit(changes);
  }

  setMarkerEnd(sizeValue) {
    this.markerEnd = sizeValue;
    const changes = {
      lineDefinition: {
        markerEnd: this.markerEnd,
      },
    };
    this.valueChange.emit(changes);
  }

  swapMarkers() {
    const markerStart = this.markerStart;
    this.markerStart = this.markerEnd;
    this.markerEnd = markerStart;
    const changes = {
      lineDefinition: {
        markerStart: this.markerStart,
        markerEnd: this.markerEnd,
      },
    };
    this.valueChange.emit(changes);
  }

  getActiveIcon(marker) {
    return this.markers.find((m) => m.value == marker)?.icon;
  }

  isMatch(marker: string, currentMarker) {
    return marker === currentMarker ? true : false;
  }
}
