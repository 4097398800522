import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';

export class CanvasShapeElement extends CanvasElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
      border: {
        width: this.DEFAULT_BORDER_SIZE,
        color: this.DEFAULT_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    const points = this.getPoints({ x, y, width, height });
    const dx = x + width * 0.5;
    const dy = y + height * 0.5;
    ctx.beginPath();

    const firstPathCommand = this.getPathCommand(points[0]);
    if (firstPathCommand === 'C') {
      ctx.moveTo(points[0][0][0] - dx, points[0][0][1] - dy);
    } else {
      ctx.moveTo(points[0][0] - dx, points[0][1] - dy);
    }
    for (let i = 1; i < points.length; i++) {
      const point = points[i];
      const pathCommand = this.getPathCommand(point);
      if (pathCommand === 'C') {
        ctx.bezierCurveTo(
          point[0][0] - dx,
          point[0][1] - dy,
          point[1][0] - dx,
          point[1][1] - dy,
          point[2][0] - dx,
          point[2][1] - dy,
        );
      } else {
        ctx.lineTo(point[0] - dx, point[1] - dy);
      }
    }
    ctx.closePath();
    ctx.fillStyle = this.elementDefinition.style?.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
    ctx.fill();
    this.stroke(ctx, options);
    return;
  }

  public getPathCommand = (point) => (point?.length === 3 ? 'C' : 'L');

  public toSVG({ x, y, width, height }): HTMLElement {
    const element = document.createElement('path');
    const points = this.getPoints({ x, y, width, height });
    if (!points?.length) {
      return;
    }
    const max = points.length - 1;

    const firstPathCommand = this.getPathCommand(points[0]);

    element.setAttribute(
      'd',
      `${points
        .reduce(
          (acc, point, i) => {
            if (i > 0) {
              const pathCommand = this.getPathCommand(point);
              if (pathCommand === 'C') {
                acc.push(
                  `${pathCommand} ${point[0][0]},${point[0][1]} ${point[1][0]},${point[1][1]} ${point[2][0]},${point[2][1]}`,
                );
              } else {
                acc.push(`${pathCommand} ${point[0]},${point[1]}`);
              }
            }
            if (i === max) {
              acc.push('Z');
            }
            return acc;
          },
          ['M', firstPathCommand === 'C' ? `${points[0][0][0]},${points[0][0][1]}` : `${points[0][0]},${points[0][1]}`],
        )
        .join(' ')}`,
    );
    this.setSVGFillAttribute(element);
    this.setSVGStrokeAttribute(element);
    return element;
  }
}
