import { Injectable } from '@angular/core';
import { SessionMessage, SessionMessageHandler } from './session-message';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketMessageDispatcher {
  private handlerMap: Map<string, Array<SessionMessageHandler>> = new Map();
  constructor(private websocketService: WebSocketService) {
    console.log('WebSocketMessageDispatcher: constructor');
    this.init();
  }

  private init() {
    this.websocketService.messages.subscribe((message) => {
      let sessionMessage: SessionMessage = message as SessionMessage;
      let action = message.action;
      if ('SESSION_EVENT' === action) {
        action = message.event?.eventType;
      }
      // console.log('WebSocketMessageDispatcher: ', action)

      const handlers = this.handlerMap.get(action);
      if (handlers) {
        for (let handler of this.handlerMap.get(action)) {
          handler.handleMessage(sessionMessage);
        }
      }
    });
  }

  public registerHandler(action: string, handler: SessionMessageHandler) {
    const handlers = this.handlerMap.get(action) || [];
    handlers.push(handler);
    this.handlerMap.set(action, handlers);
  }
}
