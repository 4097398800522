export const IconTypes = {
  PROJECT: { name: 'Project', icon: 'assets/images/workspace_icon.svg' },
  project: { name: 'Project', icon: 'assets/images/workspace_icon.svg' },
  LIBRARY: { name: 'Library', icon: 'assets/images/library_icon_blue.svg' },
  library: { name: 'Library', icon: 'assets/images/library_icon_blue.svg' },
  FOLDER: { name: 'Folder', icon: 'assets/images/folder_icon.svg' },
  folder: { name: 'Folder', icon: 'assets/images/folder_icon.svg' },
  workspace: { name: 'Workspace', icon: 'assets/images/folder_icon.svg' },
  board: { name: 'Board', icon: 'assets/images/board_icon.svg' },
  plan: { name: 'Plan', icon: 'assets/images/plan_icon.svg' },
  showcase: { name: 'Showcase', icon: 'assets/images/showcase_icon.svg' },
  showroom: { name: 'Showroom', icon: 'assets/images/showroom_icon.svg' },
  color: { name: 'Color', matIcon: 'palette' },
  asset: { name: 'Asset', matIcon: 'photo_library' },
};
