import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { LoadingIndicatorSelectors } from './loading-indicator-store';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
  message$: Observable<string>;
  loading$: Observable<string>;
  constructor(private store: Store<RootStoreState.State>) {
    this.message$ = this.store.select(LoadingIndicatorSelectors.messageSelector);
    this.loading$ = this.store.select(LoadingIndicatorSelectors.loadingSelector);
  }
}
