import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

export interface ContextMenuAction {
  action: string;
  target: any;
}

export interface ContextMenuActionDefinition {
  icon?: string;
  label: string;
  actionName: string;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() actionDefinitions: Array<ContextMenuActionDefinition>;
  @Output() selected: EventEmitter<ContextMenuAction> = new EventEmitter();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  constructor() {}

  ngOnInit(): void {}

  select(action, target) {
    this.selected.emit({ action, target });
  }

  public show(event: MouseEvent, target: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { target };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
}
