import { CanvasDocument } from '../../../canvas-document';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasFrameElement } from '../../../elements/frame/canvas-frame-element';

export class FrameElementEventHandler {
  private element: CanvasFrameElement;

  constructor(private canvasDocument: CanvasDocument) {}

  public handleDblClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (elementTarget?.element?.elementDefinition.type === 'frame' && elementTarget?.target === DRAG_DIRECTIONS.EDIT) {
      console.log('FrameElementEventHandler.handleDblClick');
      this.element = elementTarget.element as CanvasFrameElement;
      this.element.frameInputElement.show();
    }
  }
}
