import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class ElementHighlightHandler {
  private startingElement: DocumentElement;
  private selectedElement: CanvasElement;
  private targetElement: CanvasElement;
  private targetElements: CanvasElement[] = [];

  private readonly SUPPORTED_ELEMENT_TYPES = ['image', 'svg'];

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (
      (!this.canvasDocument.interactionHandler.isSelect() &&
        !this.canvasDocument.interactionHandler.isAssignItemSelect()) ||
      (elementTarget?.element?.elementDefinition?.type === 'image' &&
        !this.canvasDocument?.interactionHandler?.componentAndImageInteractionActive) ||
      (elementTarget?.element?.isItemComponent() &&
        !this.canvasDocument?.interactionHandler?.assignItemToComponentActive)
    )
      return;

    const { element, target } = elementTarget;

    if (element && target === DRAG_DIRECTIONS.BODY) {
      const selectedElements: DocumentElement[] = this.canvasDocument.getSelectedExpandedElements();

      const isColorAssignment =
        this.canvasDocument.hasSvgRecolorFeatureFlag && element.isColorComponent() && selectedElements?.length === 1;
      const isItemAssignment = element.isItemComponent() && selectedElements?.length === 1;
      const isImageAssignment =
        selectedElements?.findIndex((e) => this.SUPPORTED_ELEMENT_TYPES.indexOf(e.type) !== -1) !== -1;

      if (selectedElements?.length > 0) {
        if (isColorAssignment || isImageAssignment || isItemAssignment) {
          this.selectedElement = element;
          this.startingElement = ObjectUtil.cloneDeep(element.elementDefinition);

          this.extractTargetElements();
        }
      }
    }
  }

  public dragged(event) {
    if (!this.selectedElement || this.targetElements?.length === 0) return;

    let targetElement = null;
    const documentPosition = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    for (let i = this.targetElements?.length - 1; i >= 0; i--) {
      const element: CanvasElement = this.targetElements[i];
      if (element.isPointOnElement(documentPosition.x, documentPosition.y)) {
        if (
          !this.selectedElement?.isItemComponent() ||
          (this.canvasDocument.interactionHandler.assignItemToComponentActive &&
            element.isAssignItemToComponentAllowed() &&
            this.selectedElement.isItemComponent() &&
            (this.canvasDocument.interactionHandler.isAssignItemSelect() || event.ctrlKey || event.metaKey))
        )
          targetElement = element;
        break;
      }
    }
    if (targetElement) {
      this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
      this.targetElement = null;
      if (!this.targetElement || this.targetElement.id !== targetElement.id) {
        this.targetElement = targetElement;
        this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = this.targetElement;
      }
    } else {
      this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
      this.targetElement = null;
    }
  }

  public dragended(event) {
    if (this.targetElement) {
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: this.targetElement.elementDefinition,
        selectedElements: [this.selectedElement.elementDefinition],
        eventType: 'interaction',
      });
    }

    this.clear();
  }

  private clear() {
    this.selectedElement = null;
    this.targetElement = null;
    this.targetElements = [];
    this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
  }

  private extractTargetElements() {
    const visibleElements = this.canvasDocument.state.getVisibleElements();
    this.targetElements = this.selectedElement.isColorComponent()
      ? visibleElements.filter(
          (canvasElement) =>
            !canvasElement.elementDefinition.isLocked &&
            canvasElement.id !== this.selectedElement.id &&
            canvasElement.hasSVGViewable(),
        )
      : visibleElements.filter(
          (canvasElement) =>
            !canvasElement.elementDefinition.isLocked &&
            canvasElement.id !== this.selectedElement.id &&
            canvasElement.isItemComponent(),
        );
  }
}
