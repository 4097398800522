import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class MatchSizeDocumentElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType.startsWith('match')) {
        const actionType = request.actionType;
        switch (actionType) {
          case 'match.height': {
            this.matchSize('height', 'width');
            break;
          }
          case 'match.width': {
            this.matchSize('width', 'height');
            break;
          }
        }
      }
    });
  }

  private matchSize(property, propertyToScale) {
    const selectedElements = this.documentService
      .getSelectedElementsByOrder()
      ?.filter((e) => ['image', 'svg'].indexOf(e.type) !== -1);
    console.log(selectedElements);
    if (!selectedElements?.length) {
      return;
    }
    const propertyValue = {};
    propertyValue[property] = selectedElements[0].size[property];
    console.log('selectedElements', selectedElements, propertyValue);
    const actions = selectedElements
      .filter((e) => !e.isLocked)
      .map((el, i) => {
        const undoElementData = ObjectUtil.cloneDeep(el);
        const size = Object.assign({}, propertyValue);
        size[propertyToScale] = el.size[propertyToScale] * (size[property] / el.size[property]);
        const elementData = {
          id: el.id,
          size,
        };
        const action = new DocumentAction(
          {
            elementId: el.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData,
          },
          {
            elementId: el.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: undoElementData,
          },
        );

        return action;
      });

    this.documentService.handleDocumentActions(actions);
  }
}
