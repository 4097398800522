import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { DocumentManagerActions } from '.';
import { withLatestFrom, tap } from 'rxjs/operators';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { DocumentManagerService } from '../document-manager.service';

@Injectable()
export class DocumentManagerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private documentManagerService: DocumentManagerService,
    private snackBar: MatSnackBar,
  ) {}

  updateDocumentEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentManagerActionTypes.UPDATE_DOCUMENT),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const document: any = ObjectUtil.cloneDeep(action.document);
          try {
            this.documentManagerService.updateDocument(action.id, document);
            this.snackBar.open(action?.message || 'Document updated.', '', { duration: 2000 });
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );
}
