import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { PositionDefinition } from '@contrail/documents';
import { ColorUtil } from '@util/color-util';
import { ViewBox } from '../canvas/viewbox';

@Component({
  selector: 'app-board-remote-mouse',
  template: `
    <div class="icon">
      <mat-icon svgIcon="mouse-cursor"></mat-icon>
    </div>
    {{ displayName }}
  `,
  styles: [
    `
      :host {
        @apply absolute font-medium max-w-fit flex flex-col text-[10px] leading-[12px];
        .mat-icon {
          @apply w-3 h-3;
        }
      }
    `,
  ],
})
export class BoardRemoteMouseComponent implements OnInit, OnChanges {
  @Input() remoteMouse;
  @Input() viewBox: ViewBox;
  @Input() zoomFactor;

  public displayName: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.displayName = this.getDisplayName();
    this.element.nativeElement.style.color = this.getColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.remoteMouse) {
      this.setPosition(true);
    } else if (changes.viewBox || changes.zoomFactor) {
      this.setPosition();
    }
  }

  private setPosition(animated = false) {
    const windowPosition = this.getPosition();
    this.element.nativeElement.style.transition = animated ? 'transform 300ms ease' : 'none';
    this.element.nativeElement.style.transform = `translate(${windowPosition.x}px, ${windowPosition.y}px)`;
  }

  private getPosition() {
    const windowPosition: PositionDefinition = {
      x: (this.remoteMouse.x - this.viewBox.x) / this.zoomFactor,
      y: (this.remoteMouse.y - this.viewBox.y) / this.zoomFactor,
    };
    return windowPosition;
  }

  private getDisplayName() {
    let displayName = 'Guest';
    if (this.remoteMouse) {
      if (this.remoteMouse.firstName || this.remoteMouse.lastName) {
        displayName = [this.remoteMouse.firstName, this.remoteMouse.lastName].join(' ');
      } else if (this.remoteMouse.email) {
        displayName = this.remoteMouse.email;
      }
    }
    return displayName;
  }

  private getColor() {
    return ColorUtil.stringToHslaColor(this.remoteMouse.clientId);
  }
}
