import { Component, OnInit } from '@angular/core';
import { DevToolsRenderingService } from '../board-dev-rendering.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dev-tools-rendering-panel',
  templateUrl: './dev-tools-rendering-panel.component.html',
  styleUrls: ['./dev-tools-rendering-panel.component.scss'],
})
export class DevToolsRenderingPanelComponent implements OnInit {
  public metrics;
  public renderCount$: Observable<number>;
  constructor(private metricsService: DevToolsRenderingService) {
    this.renderCount$ = this.metricsService.renderCount$;
  }

  ngOnInit(): void {
    this.metrics = this.metricsService.renderMetrics?.sort((m1, m2) => (m1.start > m2.start ? -1 : 1));
  }
}
