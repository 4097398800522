import { DocumentElement, DynamicTextDisplayFunction, DynamicTextUtil } from '@contrail/documents';
import { Entities } from '@contrail/sdk';
import { PropertyType, Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';

export const BASE_PROPERTIES = [
  { slug: 'name', propertyType: PropertyType.String, label: 'Name' },
  { slug: 'createdOn', propertyType: PropertyType.Date, label: 'Created On' },
  { slug: 'updatedOn', propertyType: PropertyType.Date, label: 'Updated On' },
  { slug: 'createdBy.email', propertyType: PropertyType.String, label: 'Created By' },
  { slug: 'updatedBy.email', propertyType: PropertyType.String, label: 'Updated By' },
];

export const DynamicTextDisplayFunctions = [
  {
    value: DynamicTextDisplayFunction.VALUE,
    label: 'Value',
    hintitem: "Display the property's value.  If multiple exist, value of the first item added to the frame is shown.",
    hint: "Display the property's value.",
    icon: 'warning-alert',
  },
  { value: DynamicTextDisplayFunction.LABEL, label: 'Label', hint: "Display the property's label." },
  {
    value: DynamicTextDisplayFunction.UNIQUE_ARRAY,
    label: 'Unique Array',
    hint: 'List all unique values across items in the frame.',
    icon: 'info-icon',
  },
  { value: DynamicTextDisplayFunction.FIRST, label: 'First' },
  { value: DynamicTextDisplayFunction.LAST, label: 'Last' },
  {
    value: DynamicTextDisplayFunction.MIN,
    label: 'Min',
    hint: 'Display the smallest numeric value from all items in the frame.',
    icon: 'info-icon',
  },
  {
    value: DynamicTextDisplayFunction.MAX,
    label: 'Max',
    hint: 'Display the largest numeric value from all items in the frame.',
    icon: 'info-icon',
  },
  {
    value: DynamicTextDisplayFunction.AVERAGE,
    label: 'Average',
    hint: 'Calculate the average of values from all items in the frame.',
    icon: 'info-icon',
  },
  {
    value: DynamicTextDisplayFunction.SUM,
    label: 'Sum',
    hint: 'Calculate the total of values from all items in the frame.',
    icon: 'info-icon',
  },
];

export const FRAME_PROPERTIES = [
  { slug: 'frame.name', propertyType: PropertyType.String, label: 'Name' },
  { slug: 'frame.count', propertyType: PropertyType.Number, label: 'Item Count' },
];

export const HARD_CODED_TEXT = {
  'project.name': 'Project name',
  'project.createdBy.email': 'Project created by',
  'project.updatedBy.email': 'Project updated by',
  'board.name': 'Board name',
  'board.createdBy.email': 'Board created by',
  'board.updatedBy.email': 'Board updated by',
  'showcase.name': 'Showcase name',
  'showcase.createdBy.email': 'Showcase created by',
  'showcase.updatedBy.email': 'Showcase updated by',
  'frame.name': 'Frame name',
  'frame.count': 'Count',
};

export const MOVE_TO_FRAME_TEXT = 'Move to a frame';
export const CLICK_TO_CONFIGURE = 'Click to configure';

export class DocumentDynamicTextUtil {
  private static itemDisplayFunctionMap: any;
  private static documentDisplayFunctionMap: any;

  public static getItemDisplayFunctionMap() {
    if (!this.itemDisplayFunctionMap) {
      this.initDisplayFunctions();
    }
    return this.itemDisplayFunctionMap;
  }

  public static getDocumentDisplayFunctionMap() {
    if (!this.documentDisplayFunctionMap) {
      this.initDisplayFunctions();
    }
    return this.documentDisplayFunctionMap;
  }

  public static getDisplayFunction(type: string) {
    return DynamicTextDisplayFunctions.find((f) => f.value === type);
  }

  private static initDisplayFunctions() {
    this.itemDisplayFunctionMap = {};
    this.documentDisplayFunctionMap = {};
    this.itemDisplayFunctionMap['default'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
      DynamicTextDisplayFunction.UNIQUE_ARRAY,
      //DynamicTextDisplayFunction.FIRST,
      //DynamicTextDisplayFunction.LAST,
    ]);
    this.itemDisplayFunctionMap['number'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
      DynamicTextDisplayFunction.AVERAGE,
      DynamicTextDisplayFunction.SUM,
      DynamicTextDisplayFunction.MIN,
      DynamicTextDisplayFunction.MAX,
      DynamicTextDisplayFunction.UNIQUE_ARRAY,
      //DynamicTextDisplayFunction.FIRST,
      //DynamicTextDisplayFunction.LAST,
    ]);
    this.itemDisplayFunctionMap['currency'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
      DynamicTextDisplayFunction.AVERAGE,
      DynamicTextDisplayFunction.SUM,
      DynamicTextDisplayFunction.MIN,
      DynamicTextDisplayFunction.MAX,
      DynamicTextDisplayFunction.UNIQUE_ARRAY,
      //DynamicTextDisplayFunction.FIRST,
      //DynamicTextDisplayFunction.LAST,
    ]);

    this.itemDisplayFunctionMap['boolean'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
      //DynamicTextDisplayFunction.FIRST,
      //DynamicTextDisplayFunction.LAST,
    ]);

    this.itemDisplayFunctionMap['date'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
    ]);

    this.itemDisplayFunctionMap['size_range'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
    ]);
    // only for document,project,etc
    this.documentDisplayFunctionMap['string'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
    ]);
    this.documentDisplayFunctionMap['date'] = this.getDisplayFunctions([
      DynamicTextDisplayFunction.VALUE,
      DynamicTextDisplayFunction.LABEL,
    ]);
  }

  public static isDynamicTextElement(element) {
    return (
      element?.type === 'text' &&
      element?.propertyBindings !== undefined &&
      element?.propertyBindingsMetaData !== undefined
    );
  }

  public static isBasedOnDocument(element: DocumentElement) {
    const propertyBindings = element.propertyBindings;
    if (!propertyBindings) {
      return false;
    }
    if (
      propertyBindings.text?.includes('board.') ||
      propertyBindings.text?.includes('showcase.') ||
      propertyBindings.text?.includes('project.')
    ) {
      return true;
    }
    return false;
  }

  public static getTextValueFromFrameMembers(
    componentElements: any[],
    bindingProperty: any,
    displayFunction: string,
    property: any,
    entityMap: Map<string, any>,
  ) {
    const frameMemberValues = [];
    componentElements.forEach((element) => {
      const elementModel = this.buildModel(element.modelBindings, entityMap);
      frameMemberValues.push(ObjectUtil.getByPath(elementModel, bindingProperty));
    });
    if (frameMemberValues.length === 0) {
      return '';
    }
    return DynamicTextUtil.getTextFromFrameMemberValues(displayFunction, property, frameMemberValues);
  }

  /**This method can be in the lib */

  public static buildModel(modelBindings: any, entityMap: Map<string, any>): any {
    const model = {};
    const keys = Object.keys(modelBindings);
    for (const key of keys) {
      const entity = { ...entityMap.get(modelBindings[key]) };
      if (!entity) {
        continue;
      }
      if (entity) {
        model[key] = entity;
      }
    }
    return model;
  }

  public static gatherDynamicTexts(frame: any, updateDynamicTexts: any[]) {
    if (frame) {
      frame.elements
        .filter(
          (e) =>
            this.isDynamicTextElement(e.canvasElement.elementDefinition) &&
            e.canvasElement.elementDefinition.propertyBindings.text &&
            e.canvasElement.elementDefinition.displayFunction !== DynamicTextDisplayFunction.LABEL &&
            !DocumentDynamicTextUtil.isBasedOnDocument(e.canvasElement.elementDefinition),
        )
        .map((e) => e.canvasElement.elementDefinition)
        .forEach((e) => {
          if (updateDynamicTexts.findIndex((d) => d.id === e.id) === -1) updateDynamicTexts.push(e);
        });
    }
  }

  public static getFrameElementsByEntityType(frame, entityType) {
    const componentElements = frame.elements.filter((e) => e.canvasElement.elementDefinition?.type === 'component');
    if (entityType === 'frame') {
      return componentElements.map((e) => e.canvasElement.elementDefinition);
    }
    return componentElements
      .filter((e) => Object.keys(e.canvasElement.elementDefinition.modelBindings).includes(entityType))
      .map((e) => e.canvasElement.elementDefinition);
  }

  public static async fetchEntities(
    entityTypeIdsMap: Map<string, string[]>,
    entityMap: Map<string, any>,
  ): Promise<any> {
    const promises = [];
    Array.from(entityTypeIdsMap.keys()).forEach((type) => {
      let ids = entityTypeIdsMap.get(type);
      ids = [...new Set(ids)]; // make unique array
      if (ids.length > 0) {
        const relations = [];
        if (type === 'item') {
          relations.push('content');
          relations.push('itemFamily');
        } else if (type === 'project-item') {
          relations.push('project');
        } else if (type === 'assortment-item') {
          relations.push('assortment');
          relations.push('projectItem');
        }
        promises.push(
          this.getFromAPI(type, ids, relations).then((entities) => {
            entities.forEach((entity) => {
              entityMap.set(type + ':' + entity.id, entity);
            });
          }),
        );
      }
    });
    if (promises.length > 0) {
      await Promise.all(promises);
    }
  }

  private static async getFromAPI(entityType, ids, relations) {
    const entities = await new Entities().get({ entityName: entityType, criteria: { ids }, relations });
    return entities;
  }

  private static getDisplayFunctions(functions: any[]) {
    return DynamicTextDisplayFunctions.filter((f) => functions.includes(f.value));
  }
}
