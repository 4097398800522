import { Injectable } from '@angular/core';
import { ImageElementCache } from '../canvas/cache/image-element-cache';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { DevToolsMainPanelComponent } from './dev-tools-main-panel/dev-tools-main-panel.component';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevToolsService {
  private devToolsVisibleSubject = new BehaviorSubject(false);
  public devToolsVisible$: Observable<boolean> = this.devToolsVisibleSubject.asObservable();
  private devToolsVisible = false;

  constructor(private matDialog: MatDialog) {}

  public launchDevToolPanel() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90vw';
    dialogConfig.panelClass = 'no-padding'; // defined in _dialog.scss

    const dialogRef = this.matDialog.open(DevToolsMainPanelComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  public toggleDevTools() {
    this.devToolsVisible = !this.devToolsVisible;
    this.devToolsVisibleSubject.next(this.devToolsVisible);
  }
}
