import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { STICKY_NOTE_BACKGROUND_COLORS } from '../../../canvas/constants';

@Component({
  selector: 'app-property-configurator-sticky-note-background',
  templateUrl: './property-configurator-sticky-note-background.component.html',
  styleUrls: ['./property-configurator-sticky-note-background.component.scss'],
})
export class PropertyConfiguratorStickyNoteBackgroundComponent implements OnChanges {
  @Input() selectedElement: any;
  @Input() currentValue: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public colorOptions = STICKY_NOTE_BACKGROUND_COLORS.map((c) => c.value);
  public backgroundColor: string;
  private defaultValue: string = '#FFFAB2';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.backgroundColor = this.currentValue || this.defaultValue;
  }

  public updateColor(event, color) {
    this.backgroundColor = color;
    const changes = { style: { backgroundColor: this.backgroundColor } };
    this.valueChange.emit(changes);
  }
}
