import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { Assortment } from '@common/assortments/assortment';
import { AssortmentHistoryItem } from './assortment-history.interfaces';
import { AssortmentHistoryService } from './assortment-history.service';

@Component({
  selector: 'app-assortment-history',
  templateUrl: './assortment-history.component.html',
  styleUrls: ['./assortment-history.component.scss'],
})
export class AssortmentHistoryComponent implements OnInit {
  public assortmentId: string;
  public assortment: Assortment;
  public history$: Observable<Array<AssortmentHistoryItem>>;
  public selectedHistoryItem: AssortmentHistoryItem;
  public view = 'publishHistory';
  public loading = true;
  private loadingHistoryId: string;

  constructor(
    private store: Store<RootStoreState.State>,
    private assortmentHistoryService: AssortmentHistoryService,
    public dialogRef: MatDialogRef<AssortmentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.assortmentId = data.assortmentId;
  }

  async ngOnInit() {
    this.history$ = from(
      this.assortmentHistoryService.getAssortmentHistoryItems(this.assortmentId, ['createdBy']),
    ).pipe(
      filter((history) => !!history),
      map((history) => {
        const sorted = history as Array<any>;
        sorted.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1));
        if (sorted.length) {
          this.selectHistoryItem(sorted[0]);
        } else {
          this.loading = false;
        }
        return sorted;
      }),
    );
    if (this.assortmentId) {
      this.assortment = await new Entities().get({ entityName: 'assortment', id: this.assortmentId });
    }
  }
  close() {
    this.dialogRef.close();
  }

  async selectHistoryItem(historyItem: AssortmentHistoryItem) {
    if (this.loading && this.loadingHistoryId === historyItem.id) {
      return;
    }

    this.loading = true;
    this.loadingHistoryId = historyItem.id;

    this.selectedHistoryItem = await this.assortmentHistoryService.getAssortmentHistoryItem(
      this.assortmentId,
      historyItem.id,
      ['createdBy'],
    );

    this.loadingHistoryId = null;
    this.loading = false;
  }

  public setView(view) {
    this.view = view;
  }
}
