<button
  mat-icon-button
  matTooltip="Add frame"
  [matTooltipPosition]="tooltipPosition"
  [matMenuTriggerFor]="frameMenu"
  data-test="document-add-frame"
>
  <mat-icon svgIcon="app-frame-icon"></mat-icon>
</button>

<mat-menu #frameMenu="matMenu" class="ml-14 -mt-10">
  <ng-container matMenuContent *ngFor="let f of frames">
    <button
      *ngIf="f.id !== 'templates'"
      mat-menu-item
      (click)="addNewFrame(f.size)"
      [attr.data-test]="'document-add-frame-' + f.id"
    >
      <div class="flex items-center justify-between w-40">
        <div>
          <mat-icon class="icons material-icons-outlined">{{ f.icon }}</mat-icon>
          <span class="text-black54">{{ f.label }}</span>
        </div>
        <span class="text-[10px] text-black24">{{ f.size.width }} x {{ f.size.height }}</span>
      </div>
    </button>
    <button
      *ngIf="f.id === 'templates'"
      mat-menu-item
      [attr.data-test]="'document-add-frame-' + f.id"
      [matMenuTriggerFor]="templateMenu"
    >
      <span class="text-black54">{{ f.label }}</span>
    </button>
  </ng-container>
</mat-menu>

<mat-menu #templateMenu="matMenu" class="ml-1 min-h[600px]">
  <app-frame-template-chooser
    [templates]="frameTemplates"
    (templateSelected)="createFrameFromTemplate($event)"
  ></app-frame-template-chooser>
</mat-menu>
