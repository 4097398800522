<div class="chooser">
  <app-item-data-chooser
    [allowAddMultipleEntities]="true"
    [allowAddEntity]="true"
    [allowAddDuplicate]="true"
    [targetSourceType]="targetSourceType"
    [existingItemIds]="existingItemIds$ | async"
    [hideSource]="hideSource"
    [chooserTitle]="title"
    [allowItemLevelSelection]="itemChooserLevelSelectionActive"
    [enableInfiniteScroll]="true"
    [shouldOnlyPersistDocumentSources]="true"
    [shouldHydrateAddedItemsRelativeToSource]="true"
    (addEntity)="addItemData($event)"
    (addEntities)="addMultipleItemData($event)"
    (close)="hideItemChooser()"
  ></app-item-data-chooser>
</div>
