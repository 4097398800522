<div
  *ngIf="object && objectName"
  class="object-chip"
  [style.cursor]="clickable ? 'pointer' : 'default'"
  (click)="viewDetails()"
>
  <span *ngIf="hasStatus" [ngClass]="{ concept: colorStage === 'concept' }" class="status-circle"></span>
  <span *ngIf="objectNumber">{{ objectNumber }}&nbsp;:&nbsp;</span>
  <span>{{ allowDisplayTextShortening ? (this.objectName | shorten: maxDisplayLength) : this.objectName }}</span>
  <mat-icon *ngIf="edit" class="remove" (click)="remove()">close</mat-icon>
</div>
