import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-replace',
  templateUrl: './search-replace.component.html',
  styleUrls: ['./search-replace.component.scss'],
})
export class SearchReplaceComponent implements OnDestroy, AfterViewInit {
  @ViewChild('inputField') inputField: ElementRef;
  @Input() searchReplaceElements: Array<any>;
  @Input() inputPlaceholder = 'Search';
  @Input() currentElementIndex = 0;
  @Output() searchHandler = new EventEmitter<string>();
  @Output() setActiveElement = new EventEmitter<any>();
  @Output() closePanel = new EventEmitter<boolean>();

  searchInput = new FormControl();
  private sub: Subscription;

  constructor() {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.sub = this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((text: string) => {
          this.searchHandler.emit(text);
        }),
      )
      .subscribe();
    this.inputField.nativeElement.focus();
  }

  close() {
    this.closePanel.emit();
    this.searchInput.setValue('');
  }

  previousElement() {
    this.currentElementIndex === 0
      ? (this.currentElementIndex = this.searchReplaceElements.length - 1)
      : this.currentElementIndex--;
    this.setActiveElement.emit(this.searchReplaceElements[this.currentElementIndex]);
  }

  nextElement() {
    this.currentElementIndex === this.searchReplaceElements.length - 1
      ? (this.currentElementIndex = 0)
      : this.currentElementIndex++;
    this.setActiveElement.emit(this.searchReplaceElements[this.currentElementIndex]);
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    if (this.searchReplaceElements.length > 0) {
      this.nextElement();
      event.stopPropagation();
    }
  }
}
