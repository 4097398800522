<div class="w-full">
  <div class="header">
    <span>Embed code</span>
    <div class="close-modal">
      <mat-icon (click)="handleClose()" title="Close">close</mat-icon>
    </div>
  </div>

  <div class="w-full">
    <!--
    <mat-form-field class="w-full">
      <mat-label>Label</mat-label>
      <input matInput #label type="text" [formControl]="labelControl"/>
      <mat-error *ngIf="error">{{error}}</mat-error>
    </mat-form-field>
  -->
    <mat-form-field class="w-full">
      <mat-label>Paste URL or embed code</mat-label>
      <input matInput #url type="url" [formControl]="urlControl" />
      <mat-error *ngIf="error">{{ error }}</mat-error>
    </mat-form-field>

    <div class="flex justify-end">
      <button mat-flat-button (click)="handleClose()">CANCEL</button>
      <button mat-flat-button [disabled]="createDisabled" color="primary" (click)="embed()">OK</button>
    </div>
  </div>
</div>
