<app-autocomplete
  *ngIf="spanWorkspaces"
  data-test="select-workspace"
  #workspaceSelector
  [label]="'Source Project'"
  [options]="workspaces$ | async"
  (valueSelected)="handleWorkspaceChange($event)"
  [displayProperty]="'name'"
  [value]="selectedWorkspace"
  [isRequired]="true"
>
</app-autocomplete>

<app-autocomplete
  data-test="select-workspace"
  data-test="select-source-assortment"
  [label]="'Source Assortment'"
  [options]="assortments$ | async"
  (valueSelected)="handleAssortmentSelection($event)"
  [displayProperty]="'name'"
  [value]="selectedAssortment"
  [isRequired]="true"
>
</app-autocomplete>

<div class="absolute left-1/2 mt-3">
  <mat-progress-spinner diameter="25" mode="indeterminate" *ngIf="areAssortmentsLoading"> </mat-progress-spinner>
</div>
