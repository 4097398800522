import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { FrameTemplate } from '../frame-template';

@Component({
  selector: 'app-frame-template-chooser',
  templateUrl: './frame-template-chooser.component.html',
  styleUrls: ['./frame-template-chooser.component.scss'],
})
export class FrameTemplateChooserComponent implements OnInit, OnChanges {
  @Input() templates: any[] = [];
  @Output() templateSelected = new EventEmitter();

  public templateTypes: any[] = [
    { value: 'private', label: 'Private' },
    { value: 'org', label: 'Organization' },
  ];

  public allFrameTemplates: any[] = [];
  public frameTemplates: any = { Private: [], Organization: [] };

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates) {
      this.allFrameTemplates = ObjectUtil.cloneDeep(this.templates).sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );
      this.frameTemplates.private = this.allFrameTemplates.filter((template) => template.private);
      this.frameTemplates.org = this.allFrameTemplates.filter((template) => !template.private);
    }
  }

  onTabChange(event) {
    // This is to work around the issue where the tab content can be empty https://github.com/angular/components/issues/26383
    let element = document.querySelector('.mat-tab-body-content[style*="visibility: hidden"]');
    if (element) {
      this.renderer.setStyle(element, 'visibility', 'visible');
      this.renderer.setStyle(element, 'display', 'block');
    }
  }

  handleTemplateClick(event) {
    event.stopPropagation();
  }

  handleFilterTemplateByName(name: string, templateType) {
    const filterValue = name.toLowerCase();
    if (templateType === 'private') {
      this.frameTemplates.private = this.allFrameTemplates.filter(
        (template) => template.private && template.name.toLowerCase().includes(filterValue),
      );
    } else {
      this.frameTemplates.org = this.allFrameTemplates.filter(
        (template) => !template.private && template.name.toLowerCase().includes(filterValue),
      );
    }
  }

  public selectTemplate(frameTemplate: FrameTemplate) {
    this.templateSelected.emit(frameTemplate);
  }
}
