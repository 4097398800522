import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Board } from '../board.service';
import { BoardsSelectors } from '../../boards-store';
import { DocumentElement, PositionDefinition, LineDefinition } from '@contrail/documents';
import { SVGViewBox, CoordinateHelper, CoordinateRectangle } from '@contrail/svg';
import { DocumentService } from '../document/document.service';
import { ObjectUtil } from '@contrail/util';
import { DocumentSelectors } from '../document/document-store';
import { EntityReference } from '@contrail/sdk';
import { CanvasElement } from '../canvas/elements/canvas-element';
import { ViewBox } from '../canvas/viewbox';
import { CanvasUtil } from '../canvas/canvas-util';
import { DocumentViewSize } from '../document/document-store/document.state';
import { Comment } from '@common/comments/comments.service';

@Injectable({
  providedIn: 'root',
})
export class AddPinnedCommentsService {
  private board: Board;
  private viewSize: DocumentViewSize;
  private lastKnownMousePosition: PositionDefinition;
  public commentOverlayPosition: PositionDefinition;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    this.store.select(BoardsSelectors.currentBoard).subscribe((board) => (this.board = board));
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
    });
    this.store
      .select(DocumentSelectors.lastKnownMousePosition)
      .subscribe((lastKnownMousePosition) => (this.lastKnownMousePosition = lastKnownMousePosition));
    this.store.select(CommentsSelectors.showCommentOverlay).subscribe((bool) => {
      if (!bool) {
        this.commentOverlayPosition = null;
      }
    });
  }

  public addCommentsFromWindowPosition(windowPosition, element?: DocumentElement) {
    const documentPosition = this.toDocumentPosition(windowPosition);
    this.addComments(documentPosition, windowPosition, element);
  }

  /**
   * Add comment overlay at a certain @windowPosition
   * @param windowPosition
   * @param element
   */
  public addComments(
    documentPosition: PositionDefinition,
    windowPosition: PositionDefinition,
    element?: DocumentElement,
  ) {
    let entityType = 'board';
    let id = this.board.id;

    this.commentOverlayPosition = {
      x: documentPosition.x,
      y: documentPosition.y,
    };

    if (element) {
      if (element?.modelBindings?.item) {
        const entityReference = new EntityReference(element.modelBindings.item);
        entityType = 'item';
        id = entityReference.id;
      }
      const { size, position } = this.documentService.getElementSizeAndPosition(element);
      if (
        !CanvasUtil.isPositionInBox(documentPosition, {
          x: position.x,
          y: position.y,
          width: size.width,
          height: size.height,
          top: position.y,
          bottom: position.y + size.height,
          left: position.x,
          right: position.x + size.width,
        })
      ) {
        // if trying to add comment to element but position does not overlap with element
        // add comment to middle left corner relative to element
        documentPosition.x = 50;
        documentPosition.y = size.height / 2;
        const newDocumentPosition = {
          x: position.x + documentPosition.x,
          y: position.y + documentPosition.y,
        };
        this.commentOverlayPosition = {
          x: newDocumentPosition.x,
          y: newDocumentPosition.y,
        };
        const newWindowPosition = this.toWindowPosition(newDocumentPosition);
        windowPosition.x = newWindowPosition.x;
        windowPosition.y = newWindowPosition.y;
      } else {
        documentPosition.x = documentPosition.x - position.x;
        documentPosition.y = documentPosition.y - position.y;
      }
    }

    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: {
          entityType,
          id,
          documentPosition,
          documentElementId: element?.id,
        },
        position: {
          x: windowPosition.x,
          y: windowPosition.y,
        },
      }),
    );
  }

  /**
   * Add comment overlay when Ctrl M is pressed at the current mouse position.
   */
  public addCommentsFromKeyEvent() {
    if (!this.lastKnownMousePosition) {
      return;
    }

    const windowPosition = this.lastKnownMousePosition;
    const documentPosition = this.toDocumentPosition(windowPosition);
    const selectedDocumentElements = this.documentService.getSelectedElements();

    // If no elements are selected comment on the board at the position
    if (selectedDocumentElements?.length === 0) {
      this.addCommentsFromWindowPosition(windowPosition);
      return;
    }

    // Filter from selected elements those that overlap with the mouse position
    const overlappedWithMousePosition = selectedDocumentElements.filter((el) => this.isInRange(documentPosition, el));
    if (overlappedWithMousePosition?.length >= 1) {
      this.addCommentsFromWindowPosition(windowPosition, overlappedWithMousePosition[0]);
    } else {
      this.addCommentsFromWindowPosition(windowPosition);
    }
  }

  public updateCommentsPosition(windowPosition: PositionDefinition, comments: Array<Comment>) {
    const documentPosition = this.toDocumentPosition(windowPosition);

    let overlappedWithMousePosition: DocumentElement;
    const elements = this.documentService.currentDocument?.elements;
    for (let i = elements?.length - 1; i >= 0; i--) {
      const element = elements[i];
      if (this.isInRange(documentPosition, element)) {
        overlappedWithMousePosition = element;
        break;
      }
    }

    let documentElementId = null;
    if (overlappedWithMousePosition) {
      const { size, position } = this.documentService.getElementSizeAndPosition(overlappedWithMousePosition);
      documentPosition.x = documentPosition.x - position.x;
      documentPosition.y = documentPosition.y - position.y;
      documentElementId = overlappedWithMousePosition.id;
    }

    const updateComments = [];
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];
      let updateComment = ObjectUtil.cloneDeep(comment);
      updateComment.documentPosition = documentPosition;
      if (documentElementId) {
        updateComment.documentElementId = documentElementId;
      } else {
        updateComment.documentElementId = null;
      }
      updateComments.push(updateComment);
    }

    this.store.dispatch(CommentsActions.updateCommentsSuccess({ comments: updateComments }));
    this.store.dispatch(CommentsActions.updateComments({ comments: updateComments }));
  }

  private toDocumentPosition(windowPosition) {
    if (this.viewSize) {
      return CanvasUtil.toDocumentPosition(
        windowPosition?.x,
        windowPosition?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  private toWindowPosition(position: PositionDefinition): PositionDefinition {
    if (this.viewSize) {
      return CanvasUtil.toWindowPosition(
        position?.x,
        position?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  private isInRange(position: PositionDefinition, documentElement: DocumentElement) {
    const clonedElement: DocumentElement = ObjectUtil.cloneDeep(documentElement);
    this.setElementSizeAndPosition(clonedElement);

    return (
      position.x >= clonedElement.position.x &&
      position.x <= clonedElement.position.x + clonedElement.size.width &&
      position.y >= clonedElement.position.y &&
      position.y <= clonedElement.position.y + clonedElement.size.height
    );
  }

  private setElementSizeAndPosition(element: DocumentElement) {
    const canvasElement: CanvasElement = this.documentService.documentRenderer.getCanvasElementById(element.id);
    const { x, y, width, height } = canvasElement.getBoundingClientRect();
    element.size = {
      width,
      height,
    };
    element.position = {
      x,
      y,
    };
  }
}
