import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ComponentsModule } from '@common/components/components.module';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatLegacyFormField as MatFormField,
  MatLegacyFormFieldModule as MatFormFieldModule,
} from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInput as MatInput, MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FolderBreadcrumbNavComponent } from './folder-breadcrumb-nav.component';

@NgModule({
  declarations: [FolderBreadcrumbNavComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    ComponentsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
  ],
  exports: [FolderBreadcrumbNavComponent],
})
export class WorkspacesModule {}
