import { CanvasDocument } from '../../canvas-document';
import { CoordinateBox } from '../../coordinate-box';
import { CanvasElement } from '../../elements/canvas-element';
import { DRAG_DIRECTIONS, SelectionWidgetRenderer } from './selection-widget-renderer';

export class GroupSelectionWidgetRenderer extends SelectionWidgetRenderer {
  public commonBounds: CoordinateBox = null;
  protected readonly STROKE_WIDTH = 1;
  protected readonly DRAG_HANDLE_WIDTH = 6;

  constructor(
    protected canvasDocument: CanvasDocument,
    protected canvasElement: CanvasElement,
  ) {
    super(canvasDocument);
  }

  public draw(ctx, drawHandles = true) {
    const selectedElements = this.canvasDocument.state.groupState.getAllElementsInGroup(this.canvasElement.id, true);
    if (selectedElements?.length <= 1) {
      return;
    }

    this.commonBounds = this.canvasDocument.state.getCommonBounds(selectedElements, { outerEdgeDimensions: true });
    let { x, y, width, height } = this.commonBounds;

    ctx.save();

    this.drawWidget(ctx, { x, y, width, height });
    if (drawHandles) {
      const dragHandleWidth = this.getHandleWidth();
      const dragHandlePositions = [
        [-width * 0.5, -height * 0.5],
        [width * 0.5, -height * 0.5],
        [width * 0.5, height * 0.5],
        [-width * 0.5, height * 0.5],
      ];

      for (let i = 0; i < dragHandlePositions?.length; i++) {
        const dragHandlePosition = dragHandlePositions[i];
        this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
      }
    }
    ctx.restore();
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    if (!this.commonBounds) {
      return;
    }
    let direction;
    let { x, y, width, height } = this.commonBounds;

    const top = this.isOnDragHandle(py, y);
    const left = this.isOnDragHandle(px, x);
    const right = this.isOnDragHandle(px, x + width);
    const bottom = this.isOnDragHandle(py, y + height);

    switch (true) {
      case left && top:
        direction = DRAG_DIRECTIONS.TOP_LEFT;
        break;
      case right && top:
        direction = DRAG_DIRECTIONS.TOP_RIGHT;
        break;
      case left && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_LEFT;
        break;
      case right && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_RIGHT;
        break;
      default:
        break;
    }

    return direction;
  }
}
