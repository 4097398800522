<div class="flex items-baseline">
  <mat-form-field floatLabel="never" class="mr-3 min-w-[250px]">
    <input
      data-test="frame-template-search-input"
      #searchFrameTemplateSearch
      type="text"
      placeholder="Template"
      matInput
      [formControl]="templateSearchFormControl"
      [matAutocomplete]="auto"
    />
    <button
      data-test="clear-template-search-input-button"
      *ngIf="templateSearchFormControl.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearTemplateSearchValue()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        *ngFor="let template of filteredFrameTemplates | async"
        [value]="template"
        [attr.data-test]="'frame-template-option-' + template.id"
      >
        {{ template.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div
    *ngIf="showTemplateBrowser"
    data-test="frame-template-chooser"
    class="text-[12px] text-primary cursor-pointer"
    (click)="toggleChooser($event)"
  >
    Browse
  </div>
</div>
<div class="chooser-container" *ngIf="chooserVisible" [@openClose] (click)="handleChooserClick($event)">
  <div class="flex mb-[15px] justify-between">
    <div class="pl-[5px] w-[300px]">
      <app-search-bar
        #chooserTemplateSearchInput
        [lgGray]="false"
        [placeholder]="'Search templates'"
        (valueChange)="handleChooserTemplateSearchChange($event)"
      ></app-search-bar>
    </div>
    <mat-slide-toggle
      data-test="recently-used-template-toggle"
      class="p-1"
      color="primary"
      labelPosition="after"
      (change)="toggleRecentUsedFrameTemplates($event)"
    >
      <div class="text-[12px] text-primary">Recently used</div>
    </mat-slide-toggle>
  </div>
  <div class="templates narrow-scroll" *ngIf="chooserVisible">
    <div class="template-container" *ngFor="let frameTemplates of filteredChooserFrameTemplateGroups">
      <div
        class="template"
        (click)="selectTemplate(frameTemplate.id)"
        [ngClass]="{ active: templateSearchFormControl.value.id === frameTemplate.id }"
        #template
        *ngFor="let frameTemplate of frameTemplates"
        [attr.id]="frameTemplate.id"
      >
        <div class="flex justify-center items-center w-[300px] h-[180px]">
          <img
            class="h-[140px]"
            *ngIf="frameTemplate.previewFile?.downloadUrl"
            [src]="frameTemplate.previewFile?.downloadUrl"
          />
          <div
            *ngIf="!frameTemplate.previewFile"
            class="w-[280px] h-[170px] flex items-center justify-center text-lg text-gray-400 border-solid border-gray-200 border-[1px] shadow-lg"
          >
            Default
          </div>
        </div>
        <div class="text-[12px] text-center text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap w-full">
          {{ frameTemplate.name }}
        </div>
      </div>
    </div>
  </div>
</div>
