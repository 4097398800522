<div class="widget-box">
  <app-assortment-summary-dashboard-widget
    [assortmentItemData]="assortmentItemData"
    *ngIf="dashboardWidgetConfiguration?.widgetIdentifier === 'assortment_summary'"
  >
  </app-assortment-summary-dashboard-widget>

  <app-assortment-annotation-dashboard-widget
    [assortmentItemData]="assortmentItemData"
    [annotationOptions]="annotationOptions"
    [appContext]="appContext"
    widgetName="assortment_annotation"
    *ngIf="dashboardWidgetConfiguration?.widgetIdentifier === 'assortment_annotation'"
  >
  </app-assortment-annotation-dashboard-widget>

  <app-assortment-summary-chart-dashboard-widget
    [assortmentItemData]="assortmentItemData"
    chartType="BarChart"
    *ngIf="dashboardWidgetConfiguration?.widgetIdentifier === 'assortment_summary_bar_chart'"
  >
  </app-assortment-summary-chart-dashboard-widget>

  <app-assortment-summary-chart-dashboard-widget
    [assortmentItemData]="assortmentItemData"
    chartType="PieChart"
    *ngIf="dashboardWidgetConfiguration?.widgetIdentifier === 'assortment_summary_pie_chart'"
  >
  </app-assortment-summary-chart-dashboard-widget>

  <app-extension-widget
    *ngIf="dashboardWidgetConfiguration?.widget?.extensionDefinition"
    [appContext]="appContext"
    [appDefinition]="dashboardWidgetConfiguration?.widget?.extensionDefinition"
    [assortmentItemData]="assortmentItemData"
  >
  </app-extension-widget>
</div>
