import { CanvasDocument } from '../../canvas-document';
import { LineDefinition, ViewBox, ViewShape } from '@contrail/documents';
import { CanvasUtil } from '../../canvas-util';
import { CanvasElementFactory } from '../../elements/canvas-element-factory';

export class DraggingBoxRenderer {
  public draggingBox: ViewBox;
  public draggingShape: ViewShape;
  public draggingLine: LineDefinition;

  public readonly STROKE_WIDTH = 1;
  public readonly STROKE_COLOR = '#616161';

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw selection boxes for selected elements
   */
  public draw() {
    const selectBox = this.draggingBox;
    const draggingLine = this.draggingLine;
    const draggingShape = this.draggingShape;
    if (selectBox) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const { x, y, width, height } = selectBox;
      const size = this.canvasDocument.toDocumentSize(width, height);
      const position = this.canvasDocument.toDocumentPosition(x, y);
      ctx.beginPath();
      ctx.rect(position.x, position.y, size.width, size.height);
      ctx.strokeStyle = this.STROKE_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.closePath();
    } else if (draggingLine) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const { x1, y1, x2, y2 } = draggingLine;
      const p1 = this.canvasDocument.toDocumentPosition(x1, y1);
      const p2 = this.canvasDocument.toDocumentPosition(x2, y2);

      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.strokeStyle = this.STROKE_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.closePath();
    } else if (draggingShape) {
      // Use Shape Element Factory to create the shape element
      this.drawDraggingShape(draggingShape);
    }
  }

  public drawDraggingShape(draggingShape) {
    const ctx = this.canvasDocument.canvasDisplay.context;
    const { x, y, width, height, type } = draggingShape;

    // Convert to document dimensions and positions
    const size = this.canvasDocument.toDocumentSize(width, height);
    const position = this.canvasDocument.toDocumentPosition(x, y);
    // Create the shape element using the factory
    const shapeElement = CanvasElementFactory.createCanvasElement(
      {
        type, // Pass the shape type (e.g., 'circle', 'triangle', etc.)
        position: { x: position.x, y: position.y },
        size: { width: size.width, height: size.height },
        style: {
          backgroundColor: 'rgba(0,0,0,0)',
          border: {
            color: this.STROKE_COLOR,
            width: this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH), // Calculates default border width based on zoom factor
          },
        },
      },
      this.canvasDocument,
      false, // Interactable is false for temporary drawing
    );
    // Use the shape's `drawElement` method
    shapeElement.drawElement(ctx);
  }
}
