import { Injectable } from '@angular/core';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { EntitySnapshot } from '@common/document-history/document-history-store/document-history.state';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { Store } from '@ngrx/store';
import { SessionMessage, SessionMessageHandler } from '../common/web-socket/session-message';
import { WebSocketMessageDispatcher } from '../common/web-socket/web-socket-mesage-dispatcher';
import { RootStoreState, UserSessionActions } from '../root-store';
import { BoardsBackingAssortmentService } from './board/backing-assortment/boards-backing-assortment-service';
import { BoardService } from './board/board.service';
import { BoardsActions } from './boards-store';
import { BoardFrameService } from './board/board-frames-list/board-frames.service';
import { BoardPropertyPoliciesService } from './board/board-property-policies/board-property-policies.service';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';

@Injectable({
  providedIn: 'root',
})
export class BoardsMessageHandler implements SessionMessageHandler {
  private entitySnapshot: EntitySnapshot;
  private sourceAssortmentChangeInProgress = false;
  private currentBoard: any;
  private backingAssortmentItems: any[];

  constructor(
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private boardService: BoardService,
    private boardFrameService: BoardFrameService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private backingAssortmentService: BoardsBackingAssortmentService,
  ) {
    this.init();
  }
  private init() {
    this.websocketMessageDispatcher.registerHandler('UPDATE_BOARD', this);
    this.websocketMessageDispatcher.registerHandler('DOCUMENT_ELEMENT_CHANGED', this);
    this.websocketMessageDispatcher.registerHandler('ADD_ELEMENTS_ON_FRAME', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_FRAME_SORT', this);
    this.websocketMessageDispatcher.registerHandler('REMOVE_ELEMENTS_FROM_FRAME', this);
    this.websocketMessageDispatcher.registerHandler('REMOTE_USER_MOUSE_MOVED', this);
    this.websocketMessageDispatcher.registerHandler('LEAVE_SESSION', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('ADD_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('REMOVE_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('RESTORE_BOARD_SNAPSHOT', this);
    this.websocketMessageDispatcher.registerHandler('ASSIGN_SOURCE_ASSORTMENT', this);
    this.websocketMessageDispatcher.registerHandler('CLEAR_SOURCE_ASSORTMENT', this);
    this.store
      .select(DocumentHistorySelectors.currentEntitySnapshot)
      .subscribe((snapshot) => (this.entitySnapshot = snapshot));
    this.boardService.currentBoard.subscribe((currentBoard) => {
      this.currentBoard = currentBoard;
    });
    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .subscribe((backingAssortmentItems) => (this.backingAssortmentItems = backingAssortmentItems));
  }

  async handleMessage(message: SessionMessage) {
    if (message?.action === 'LEAVE_SESSION') {
      this.store.dispatch(BoardsActions.removeRemoteUserFromMouseSession({ user: message.context.user }));
      return;
    }
    // Ignore all inbound events when viewing historical version.
    if (this.entitySnapshot) {
      return;
    }
    switch (message.event?.eventType) {
      case 'UPDATE_BOARD': {
        this.store.dispatch(
          BoardsActions.updateBoardSuccess({ id: message.event.changes.id, changes: message.event.changes.changes }),
        );
        break;
      }
      case 'DOCUMENT_ELEMENT_CHANGED': {
        this.boardPropertyPoliciesService.handleWebsocketMessage(
          message.event.changes,
          this.currentBoard,
          this.backingAssortmentItems,
        );
        this.boardService.handleDocumentElementsUpdated(message.event.changes.actions, true);
        break;
      }
      case 'ADD_ELEMENTS_ON_FRAME': {
        this.boardService.handleAddElementsOnFrame(message.event.changes?.id, message.event.changes?.selectedElements);
        break;
      }
      case 'UPDATE_FRAME_SORT': {
        this.boardFrameService.handleFrameSortSocketMessage(message?.event?.changes);
        break;
      }
      case 'REMOVE_ELEMENTS_FROM_FRAME': {
        this.boardService.handleRemoveElementsFromFrame(message.event.changes?.selectedElements);
        break;
      }
      case 'REMOTE_USER_MOUSE_MOVED': {
        this.store.dispatch(
          UserSessionActions.syncRemoteUsers({
            data: {
              sessionId: message.sessionId,
              context: message.context,
            },
          }),
        );
        this.store.dispatch(
          BoardsActions.remoteUserMouseMoved({
            data: {
              sessionId: message.sessionId,
              context: message.context,
              event: message.event.changes,
            },
          }),
        );
        break;
      }
      case 'UPDATE_ASSORTMENT_ITEMS': {
        this.boardPropertyPoliciesService.handleWebsocketMessage(
          message.event.changes,
          this.currentBoard,
          this.backingAssortmentItems,
        );
        this.backingAssortmentService.handleAssortmentItemChanges(message.event.changes);
        break;
      }
      case 'ADD_ASSORTMENT_ITEMS': {
        this.backingAssortmentService.handleAssortmentAddItems(message.event.changes);
        break;
      }
      case 'REMOVE_ASSORTMENT_ITEMS': {
        this.backingAssortmentService.handleAssortmentRemoveItems(message.event.changes);
        break;
      }
      case 'RESTORE_BOARD_SNAPSHOT': {
        const confirm = await this.confirmationBoxService.open(
          'New Version',
          'Another version of this board has been updated or restored. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.boardService.clearCurrentBoard();
          this.boardService.init(message.event.changes);
        }
        break;
      }
      case 'ASSIGN_SOURCE_ASSORTMENT': {
        if (this.sourceAssortmentChangeInProgress) {
          this.confirmationBoxService.close(); // close existing confirmation box if it's open
        }
        this.sourceAssortmentChangeInProgress = true;
        const confirm = await this.confirmationBoxService.open(
          'Updated Source Assortment',
          'A user has changed or removed the source assortment for this document. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.sourceAssortmentChangeInProgress = false;
          this.boardService.assignSourceAssortmentAndRefresh(message.event.changes);
        }
        break;
      }
      case 'CLEAR_SOURCE_ASSORTMENT': {
        if (this.sourceAssortmentChangeInProgress) {
          this.confirmationBoxService.close(); // close existing confirmation box if it's open
        }
        this.sourceAssortmentChangeInProgress = true;
        const confirm = await this.confirmationBoxService.open(
          'Removed Source Assortment',
          'A user has changed or removed the source assortment for this document. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.sourceAssortmentChangeInProgress = false;
          this.boardService.clearSourceAssortmentAndRefresh(message.event.changes);
        }
        break;
      }
    }
  }
}
