import { DocumentSVGElementEvent, DocumentNavigationEvent } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { SvgEditorContainer } from './svg-editor-container';
import { CanvasElement } from '../../elements/canvas-element';

export class SvgEditorHandler {
  svgEditorContainer: SvgEditorContainer;
  currentElement: CanvasElement;

  private isOpen: boolean = false;

  constructor(private canvasDocument: CanvasDocument) {
    this.svgEditorContainer = new SvgEditorContainer(this.canvasDocument);

    this.canvasDocument.documentService.documentSVGElementEvents.subscribe((event) => {
      this.handleSvgElementEvent(event);
    });
  }

  isSvgEditorOpen(): boolean {
    return this.isOpen;
  }

  async showSvgEditor(element: CanvasElement) {
    // Don't open the svg editor if more than 1 element is selected
    if (this.canvasDocument.getSelectedElements().length > 1) {
      return;
    }

    this.currentElement = element;

    this.isOpen = true;

    const svgRootElement: SVGElement = await this.svgEditorContainer.showSvgEditor(this.currentElement);
    if (!svgRootElement) {
      console.error('Could not open SVG editor for element:', element);
      this.hideSvgEditor();
      return;
    }

    this.currentElement.setEditingSelectionColor(true);

    this.canvasDocument.actionsDispatcher.handleDocumentSvgElementEvent({
      element: this.currentElement.elementDefinition,
      svgRootElement: svgRootElement,
      eventType: 'onSvgEditorShown',
    });
  }

  hideSvgEditor() {
    this.svgEditorContainer.hideSvgEditor();

    this.isOpen = false;

    if (this.currentElement) {
      this.currentElement.setEditingSelectionColor(false);
    }

    this.currentElement = null;
  }

  updateEditorTransform() {
    // Don't update transform if we're hidden
    if (this.svgEditorContainer.svgEditorContainerElement.style.visibility == 'visible') {
      this.svgEditorContainer.updateEditorTransform();
    }
  }

  clear() {
    this.svgEditorContainer.clear();

    this.isOpen = false;
  }

  private handleSvgElementEvent(event: DocumentSVGElementEvent) {
    const hideEvents = ['elementDeselected', 'contentEditorClosed'];

    if (hideEvents.includes(event?.eventType) && this.isOpen) {
      this.hideSvgEditor();
      return;
    }

    switch (event.eventType) {
      case 'showSvgEditor': {
        this.showSvgEditor(this.canvasDocument.getCanvasElementById(event.element.id));
        break;
      }
      case 'contentEditorRestore': {
        this.svgEditorContainer.resetSvgFromString(event.svgHtmlString);

        this.canvasDocument.actionsDispatcher.handleDocumentSvgElementEvent({
          element: this.currentElement.elementDefinition,
          eventType: 'onSvgReset',
          svgRootElement: this.svgEditorContainer.svgRootElement,
        });
        break;
      }
      case 'clearSelection': {
        this.svgEditorContainer.selectionManager.clearSelection();
        this.svgEditorContainer.selectionManager.clearHighlight();
        break;
      }
    }
  }

  /**
   * Set selection to nodes with ids @svgElementSelectionIds for SVG element with id @elementId
   * @param elementId
   * @param svgElementSelectionIds
   * @returns
   */
  public setSvgElementSelection(elementId: string, svgElementSelectionIds: string[]): SVGElement[] {
    if (this.isSvgEditorOpen && this.currentElement?.id === elementId) {
      const svgElements = this.svgEditorContainer.findSvgElementsIds(svgElementSelectionIds);
      if (svgElements?.length > 0) {
        const currentSvgElements = this.svgEditorContainer.selectionManager.selectedElements;
        this.svgEditorContainer.selectionManager.setSelection(svgElements);
        this.svgEditorContainer.overlayManager.handleSelectionStateAdded(svgElements);
        if (currentSvgElements?.length > 0) {
          this.svgEditorContainer.overlayManager.handleSelectionStateRemoved(currentSvgElements);
        }
      }
      return svgElements;
    }
    return null;
  }

  public setSvgElementHighlights(elementId: string, svgElementIds: string[]): SVGElement[] {
    if (this.isSvgEditorOpen && this.currentElement?.id === elementId) {
      const svgElements = this.svgEditorContainer.findSvgElementsIds(svgElementIds);

      if (svgElements?.length > 0) {
        this.svgEditorContainer.selectionManager.replaceHighlight(svgElements);
        return svgElements;
      }
    }

    return null;
  }

  /**
   * Clear selection fully for SVG element with id @elementId
   * @param elementId
   */
  public clearSvgElementSelection(elementId: string) {
    if (this.isSvgEditorOpen && this.currentElement?.id === elementId) {
      const currentSvgElements = this.svgEditorContainer.selectionManager.selectedElements;
      this.svgEditorContainer.selectionManager.setSelection([]);
      if (currentSvgElements?.length > 0) {
        this.svgEditorContainer.overlayManager.handleSelectionStateRemoved(currentSvgElements);
      }
    }
  }

  public clearSvgElementHighlights(elementId: string) {
    if (this.isSvgEditorOpen && this.currentElement?.id === elementId) {
      this.svgEditorContainer.selectionManager.clearHighlight();
    }
  }
}
