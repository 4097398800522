import { BorderStyleDefinition, PositionDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { Editor } from '../../../components/editor/editor';
import { EditorCalc } from '../../../components/editor/editor-calc';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';

export const editorId = 'text-editor';
export const placeholderId = 'text-placeholder';
export const TEXT_TOOL_PADDING = 0;
export const TEXT_BOX_PADDING = 10;
export const DEFAULT_TEXT_BORDER_SIZE = 1;
export const DEFAULT_TEXT_TOOL_BORDER_SIZE = 0;
export const TEXT_CAROT_WIDTH = 1;
export const TEXT_TOOL_PLACEHOLDER = 'Add text';
export const DYNAMIC_TEXT_PLACEHOLDER = 'Click to configure';
export const DEFAULT_TEXT_FONT_FAMILY = 'Roboto';
export const DEFAULT_TEXT_FONT_SIZE = 11;
export const DEFAULT_LINE_HEIGHT = 1.2;
export const DEFAULT_LETTER_SPACING = 0.25;
export const TEXT_VALIGN = 'top';
export const TEXT_ALIGN = 'left';
export const DEFAULT_TEXT_FONT_COLOR = '#000000';

export interface TextFormat {
  bold?: string;
  italic?: string;
  underline?: string;
  strikethrough?: string;
  textColor?: string;
  textBackgroundColor?: string;
  fontFamily?: string;
  fontSize?: string;
  listType?: string;
  textAlign?: string;
  textValign?: string;
  link?: string;
  linkMode?: string;
  position?: PositionDefinition;
  textType?: string;
}

export class TextEditor extends Editor {
  constructor(canvasDocument: CanvasDocument, manager: EditorManager) {
    super(canvasDocument, editorId, manager);
  }

  public setContentStyle() {
    if (!this.manager.element) {
      return;
    }
    if (this.manager.element) {
      if (this.manager.element.elementDefinition.isTextTool) {
        this.manager.editorContainer.editor.style.fontSize =
          (this.manager.element.elementDefinition?.style?.font?.size || DEFAULT_TEXT_FONT_SIZE) + 'pt';
      } else {
        this.manager.editorContainer.editor.style.fontSize = null;
      }

      const valign = this.manager.element.isPropertyValid('style.text.valign')
        ? this.manager.element.elementDefinition?.style?.text?.valign ?? TEXT_VALIGN
        : TEXT_VALIGN;
      const borderWidth = this.manager.element.isPropertyValid('style.border')
        ? this.manager.element.elementDefinition?.style?.border?.width ?? this.manager.element.DEFAULT_BORDER_SIZE
        : this.manager.element.DEFAULT_BORDER_SIZE;
      const transform = EditorCalc.getVerticalTransform(
        valign,
        this.manager.editorContainer.currentContainerHeight,
        this.manager.element.TEXT_PADDING,
        borderWidth,
        this.manager.editorContainer.editor.clientHeight,
      );
      if (transform) {
        this.manager.editorContainer.editor.style.transform = transform;
      }
    }
  }

  public getCurrentTextFormat(element?: CanvasElement): TextFormat {
    let elementDefinition = element?.elementDefinition;
    if (!elementDefinition) {
      elementDefinition = this.manager?.elementDefinition;
    }
    const editor = this.getEditor();
    if (!editor) {
      return;
    }
    let textAlign = 'left';
    if (editor.queryCommandState('JustifyCenter')) {
      textAlign = 'center';
    } else if (editor.queryCommandState('JustifyRight')) {
      textAlign = 'right';
    }
    let listType = '';
    if (editor.queryCommandState('InsertOrderedList')) {
      listType = 'decimal';
    } else if (editor.queryCommandState('InsertUnorderedList')) {
      listType = 'circle';
    }
    let position = null;
    let link = null;
    let linkMode = null;

    const range = this.getEditor().selection.getRng();
    const nodePosition = this.getEditor().selection?.getNode().getBoundingClientRect();
    if (nodePosition) {
      position = { x: nodePosition.left, y: nodePosition.bottom };
    }
    if (!range.collapsed) {
      const rangePosition = this.getEditor().selection?.getRng().getBoundingClientRect();
      position = { x: rangePosition.left, y: rangePosition.bottom };
    }
    link = this.getLink(this.getEditor().selection);
    if (link) {
      linkMode = 'edit';
    }

    const selectAll = !this.isFocused;
    if (selectAll) {
      // Select first text node when computing font color from entire editor.
      const root = this.getEditor().selection.getNode()?.firstChild;
      if (root) {
        const firstTextNode = CanvasUtil.getFirstTextNode(this.getEditor().selection.getNode().firstChild);
        if (firstTextNode) {
          const rng = new Range();
          rng.setStart(firstTextNode, 0);
          rng.setEnd(firstTextNode, 0);
          this.getEditor().selection.setRng(rng);
        }
      }
    }
    const textColor = this.getTextOrBgColor(editor.selection.getNode(), 'color');
    const textBackgroundColor = this.getTextOrBgColor(editor.selection.getNode(), 'backgroundColor');

    let fontSize = elementDefinition?.isTextTool
      ? elementDefinition?.style?.font?.size
        ? '' + elementDefinition?.style?.font?.size
        : '11'
      : this.toPt(editor.queryCommandValue('FontSize')).replace('pt', '').replace('px', '');
    if (fontSize && elementDefinition?.scale?.x != null) {
      // Scale font size value for display purpose
      fontSize = '' + Math.round(parseFloat(fontSize) * elementDefinition.scale.x);
    }
    let textType = 'text-box';
    if (elementDefinition?.isTextTool) {
      textType = 'text-tool';
    }
    const textFormat = {
      bold: editor.queryCommandState('Bold') ? 'bold' : 'normal',
      italic: editor.queryCommandState('Italic') ? 'italic' : 'normal',
      underline: editor.queryCommandState('Underline') ? 'underline' : 'normal',
      strikethrough: editor.queryCommandState('Strikethrough') ? 'strikethrough' : 'normal',
      textColor,
      textBackgroundColor,
      fontFamily: editor.queryCommandValue('FontName').split(',')[0],
      fontSize,
      listType,
      textAlign,
      textValign: elementDefinition?.style?.text?.valign,
      link,
      linkMode,
      position,
      textType,
    };

    return textFormat;
  }

  public emitTextEditorEvent(element?: CanvasElement) {
    const editor = this.getEditor();
    if (!editor) {
      return;
    }

    const textFormat = this.getCurrentTextFormat(element);

    if (
      this.manager?.elementDefinition?.text &&
      this.getEditor().getContent({ format: 'text' }) !== '' &&
      editor.selection.getContent() === '' &&
      this.isFocused
    ) {
      this.manager.options.setLastAppliedTextFormat &&
        this.canvasDocument.documentService.saveLastAppliedTextFormat(textFormat);
    }
    this.canvasDocument.actionsDispatcher.handleDocumentTextElementEvent({
      element: this.isFocused ? this.manager?.elementDefinition : null,
      textFormat,
    });
  }
}
