import { CanvasDocument } from '../../../canvas-document';
import { CanvasComponentElement } from '../../../elements/component/canvas-component-element';
import { CanvasIframeElement } from '../../../elements/iframe/canvas-iframe-element';
import { CanvasTextElement } from '../../../elements/text/canvas-text-element';

export class CursorHandler {
  private lastDocumentCursor: string;

  constructor(private canvasDocument: CanvasDocument) {}

  public mousemove(event: MouseEvent, elementTarget: any) {
    if (
      !this.canvasDocument.interactionHandler.isSelect() &&
      !this.canvasDocument.interactionHandler.isNewItemOption() &&
      !this.canvasDocument.interactionHandler.isAnnotate() &&
      !this.canvasDocument.interactionHandler.isPaintFormat() &&
      !this.canvasDocument.interactionHandler.isItemCopy()
    ) {
      return;
    }

    let documentCursor;
    if (elementTarget.element?.elementDefinition?.type === 'text' && !elementTarget.element?.isPartOfComponent) {
      const textElement: CanvasTextElement = elementTarget.element as CanvasTextElement;
      if (textElement.isMouseOnHyperlink(event as MouseEvent)) {
        documentCursor = 'pointer';
      } else {
        documentCursor = elementTarget?.target;
      }
    } else if (elementTarget.element?.elementDefinition?.type === 'iframe') {
      const iframeElement: CanvasIframeElement = elementTarget.element as CanvasIframeElement;
      if (iframeElement.isVideo && iframeElement.isMouseOnClickableArea(event as MouseEvent)) {
        documentCursor = 'pointer';
      } else {
        documentCursor = elementTarget?.target;
      }
    } else {
      documentCursor = elementTarget?.target;
      if (elementTarget.element?.elementDefinition?.type === 'component') {
        const componentElement: CanvasComponentElement = elementTarget.element as CanvasComponentElement;
        componentElement.handleMouseoverWarning(event as MouseEvent);
        if (
          componentElement.elementDefinition?.modelBindings?.item &&
          (this.canvasDocument.interactionHandler.isNewItemOption() ||
            this.canvasDocument.interactionHandler.isItemCopy())
        ) {
          this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = componentElement;
          this.canvasDocument.draw();
        }
      } else {
        this.canvasDocument.removeHighlightElement();
      }
    }

    if (this.lastDocumentCursor != documentCursor) {
      this.canvasDocument.interactionHandler.setInteractionCursor(documentCursor);
    }

    this.lastDocumentCursor = documentCursor;
  }
}
