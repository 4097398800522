import { CanvasDocument } from '../../canvas-document';
import { v4 as uuid } from 'uuid';
import { CanvasElement } from '../../elements/canvas-element';
import { TextStyle } from '../../elements/text/text-style';
import { EditorManager } from './editor-manager';
import { DEFAULT_LINE_HEIGHT } from '../../elements/text/editor/text-editor';

export interface ChildElementDetails {
  id: string;
  domRect: DOMRect;
  url: string;
}

export abstract class EditorPlaceholder {
  public textContainer: HTMLElement;
  public textPlaceholder: HTMLElement;
  public textLinkWidget: HTMLElement;

  constructor(
    private canvasDocument: CanvasDocument,
    private placeholderId,
    manager: EditorManager,
  ) {
    this.textContainer = document.createElement('div');
    this.textContainer.id = `${this.placeholderId}-container`;
    this.textContainer.style.position = 'absolute';
    this.textContainer.style.width = '100px';
    this.textContainer.style.height = '100px';
    this.textContainer.style.left = '0px';
    this.textContainer.style.top = '0px';
    this.textContainer.style.visibility = 'hidden';
    this.textContainer.style.lineHeight = 'normal';
    this.textContainer.style.fontStyle = 'normal!important';
    this.textContainer.style.fontWeight = 'normal!important';
    this.textContainer.style.fontKerning = 'auto';

    this.textPlaceholder = document.createElement('div');
    this.textPlaceholder.id = this.placeholderId;
    this.textPlaceholder.style.margin = '0';
    this.textPlaceholder.style.width = '100px';
    this.textPlaceholder.style.height = '100px';
    this.textPlaceholder.innerHTML = '';

    this.textContainer.appendChild(this.textPlaceholder);
    document.body.appendChild(this.textContainer);
  }

  setContent(element: CanvasElement, textStyle: TextStyle) {
    const dimensions = element.getDimensions();
    this.textContainer.style.width = `${dimensions.width}px`;
    this.textContainer.style.height = `${dimensions.height}px`;

    const styleString =
      textStyle.toStyleString() +
      `line-height:${DEFAULT_LINE_HEIGHT};display:block;padding:8px;width:100%;height:100%;font-size:11pt;`;
    this.textPlaceholder.style.cssText = styleString;
    this.textPlaceholder.innerHTML = element.elementDefinition.text;
    // Set the margin for <p> element to match with canvas and editor
    const s = this.textPlaceholder.getElementsByTagName('p');
    for (let i = 0; i < s.length; i++) {
      s[i].setAttribute('style', 'margin-top:0px;margin-bottom:0px');
    }
    // Get all of the link elements and store their positions
    const childElementDetails: Array<ChildElementDetails> = [];
    const links = document.getElementsByTagName('A');
    for (let i = 0; i < links.length; i++) {
      const clientRect = links[i].getBoundingClientRect();
      childElementDetails.push({
        id: uuid(),
        domRect: clientRect,
        url: links[i].getAttribute('href'),
      });
    }
    this.canvasDocument.state.setTextLinkElementChildren(element.elementDefinition.id, childElementDetails);
  }
}
