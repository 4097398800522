<div class="card-wrap px-4 pb-4 pt-2">
  <div class="flex items-center mb-2">
    <div class="mr-2">
      <mat-icon class="text-success-dark" *ngIf="historyEvent.changeType === 'assortment-addition'"
        >add_circle_outline</mat-icon
      >
      <mat-icon class="text-error-light" *ngIf="historyEvent.changeType === 'assortment-delete'"
        >remove_circle_outline</mat-icon
      >
      <mat-icon class="text-warn-dark" *ngIf="['item', 'project-item'].includes(historyEvent.changeType)"
        >change_history</mat-icon
      >
    </div>
    <div class="text-xs">{{ historyEvent.date | date: 'short' }}</div>
  </div>

  <ng-container *ngIf="historyEvent.changeType === 'assortment-delete'">
    <div class="change-type mb-2">
      <span class="font-bold"> {{ historyEvent.itemName }} {{ historyEvent?.itemOptionName ?? '' }} </span> was removed
      <ng-template *ngIf="historyEvent?.updatedBy" [ngTemplateOutlet]="updatedBy"></ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="historyEvent.changeType === 'assortment-addition'">
    <div class="change-type mb-2">
      {{ historyEvent.count }} <span> {{ historyEvent.count === 1 ? 'item was' : 'items were' }}</span> added
      <ng-template *ngIf="historyEvent?.updatedBy" [ngTemplateOutlet]="updatedBy"></ng-template>
    </div>

    <div *ngFor="let item of historyEvent.items">
      <span class="font-bold mb-2">
        {{ item.name }} <span *ngIf="item.optionName">({{ item.optionName }})</span>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="['item', 'project-item'].includes(historyEvent.changeType)">
    <div class="change-type mb-2">
      <span class="font-bold"> {{ historyEvent.itemName }} {{ historyEvent?.itemOptionName ?? '' }} </span> was modified
      <ng-template *ngIf="historyEvent?.updatedBy" [ngTemplateOutlet]="updatedBy"></ng-template>
    </div>

    <div class="flex flex-wrap items-center mb-2" *ngIf="historyEvent.changes?.smallViewableDownloadUrl">
      <span class="w-full underline my-2">Primary content</span>
      <app-source-change-history-image
        [value]="historyEvent.changes?.smallViewableDownloadUrl?.oldValue"
      ></app-source-change-history-image>
      <mat-icon class="mx-2">chevron_right</mat-icon>
      <app-source-change-history-image
        [value]="historyEvent.changes?.smallViewableDownloadUrl?.newValue"
        [new]="true"
      ></app-source-change-history-image>
    </div>
    <app-source-change-history-event-change-details
      *ngFor="let change of changes"
      class="block mb-2"
      [change]="change"
    ></app-source-change-history-event-change-details>
  </ng-container>
</div>

<mat-divider></mat-divider>

<ng-template #updatedBy>
  <span> by </span>
  <span class="font-medium cursor-pointer" [matTooltip]="historyEvent.updatedBy?.email" matTooltipPosition="right">
    {{ historyEvent.updatedBy | userName: 'fullEmail' }}
  </span>
</ng-template>
