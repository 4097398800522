import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DocumentService } from '../../document.service';
import { DocumentElement } from '@contrail/documents';
import { GroupElementService } from '../../action-dispatchers/group-element/group-element.service';

@Component({
  selector: 'app-property-configurator-group-element',
  templateUrl: './property-configurator-group-element.component.html',
  styleUrls: ['./property-configurator-group-element.component.scss'],
})
export class PropertyConfiguratorGroupElementComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() elements: DocumentElement[];

  public isGrouped = false;
  public isGroupingAllowed = false;
  public isUngroupingAllowed = false;
  public groupingCandidateElements = [];

  constructor(
    private documentService: DocumentService,
    private groupElementService: GroupElementService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const previousElemIds = changes.elements?.previousValue?.map((elem) => elem.id)?.join();
    const currentElemIds = changes.elements?.currentValue.map((elem) => elem.id).join();
    if (previousElemIds !== currentElemIds) {
      this.isGrouped = this.elements.length === 1 && this.elements[0].type === 'group';
      this.isGroupingAllowed = this.checkIfGroupingIsAllowed();
      this.isUngroupingAllowed = this.checkIfUngroupingIsAllowed();
    }
  }

  toggleGroup() {
    if (this.isGrouped) {
      this.ungroupObjects();
    } else {
      this.groupObjects();
    }
    this.isGrouped = !this.isGrouped;
  }

  checkIfGroupingIsAllowed() {
    const frameElements = this.documentService.getFrameElements();
    this.groupingCandidateElements = [];
    const selectedFrameElements = this.elements.filter((element) => element.type === 'frame');
    const selectedFrameElementIds = selectedFrameElements.map((frame) => frame.id);
    const selectedNonFrameElements = this.elements.filter((element) => element.type !== 'frame');
    selectedNonFrameElements.forEach((element) => {
      if (
        !frameElements.get(element.id) ||
        (frameElements.get(element.id) && !selectedFrameElementIds.includes(frameElements.get(element.id)))
      ) {
        this.groupingCandidateElements.push(element);
      }
    });
    this.groupingCandidateElements.push(...selectedFrameElements);
    return this.groupingCandidateElements.length > 1;
  }

  checkIfUngroupingIsAllowed() {
    return this.elements.length === 1 && this.elements[0].type === 'group';
  }

  groupObjects() {
    this.groupElementService.createGroupElement(this.elements);
  }

  ungroupObjects() {
    this.groupElementService.deleteGroupElement(this.elements);
  }
}
