import { CanvasDocument } from '../canvas-document';
import { CanvasElement } from '../elements/canvas-element';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';

export interface Mask {
  id: string;
  element: CanvasElement;
  elementIds: Array<string>;
}

export class CanvasMaskState {
  public masks: Map<string, Mask> = new Map(); // <maskId, Mask>
  public maskMembers: Map<string, string> = new Map(); // <elementId, maskId>

  constructor(public canvasDocument: CanvasDocument) {}

  public isEditingMask(id?: string) {
    const isEditing =
      this.canvasDocument?.interactionHandler?.canvasEventHandlers?.maskElementEventHandler?.isEditingMask;
    return id ? isEditing === id : !!isEditing;
  }

  public hideEditingMask() {
    const isEditingMask =
      this.canvasDocument?.interactionHandler?.canvasEventHandlers?.maskElementEventHandler?.isEditingMask;
    if (isEditingMask) {
      const selectedElements = this.canvasDocument?.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
      const stillEditingMask =
        selectedElements?.length > 0 &&
        selectedElements.filter((e) => {
          const mask = this.isMaskOrMaskMember(e.id);
          return !mask || !this.isEditingMask(mask.id);
        })?.length === 0;
      if (!stillEditingMask) {
        // Unselect mask and its members in case they are being multi selected with other elements while being edited
        const maskId = isEditingMask as string;
        const maskElement: Mask = this.getMask(maskId);
        if (maskElement?.element) {
          this.canvasDocument?.interactionHandler?.selectionHandler?.deselect(maskElement.element);
        }
        maskElement?.element?.elementDefinition?.elementIds?.forEach((id) => {
          const element = this.canvasDocument?.getCanvasElementById(id);
          if (element) {
            this.canvasDocument?.interactionHandler?.selectionHandler?.deselect(element);
          }
        });
        this.canvasDocument.interactionHandler.canvasEventHandlers.maskElementEventHandler.isEditingMask = false;
      }
    }
  }

  public static isMask(element: DocumentElement) {
    return ['rectangle', 'circle'].indexOf(element?.type) !== -1 && element?.elementIds?.length > 0;
  }

  public static isValidMaskMember(element: DocumentElement) {
    return ['image', 'svg'].indexOf(element?.type) !== -1;
  }

  public isMaskOrMaskMember(id: string): Mask {
    return this.getMask(id) || this.getMaskByMemberId(id);
  }

  public getMask(id: string): Mask {
    return this.masks.get(id);
  }

  public getMaskByMemberId(id: string): Mask {
    const maskId = this.maskMembers.get(id);
    return this.getMask(maskId);
  }

  public getMaskMembers(id: string): CanvasElement[] {
    const mask = this.getMask(id);
    if (mask) {
      return mask?.elementIds
        ?.map((memberId) => this.canvasDocument.getCanvasElementById(memberId))
        ?.filter((e) => !!e);
    }
    return null;
  }

  public addMask(element: CanvasElement) {
    if (CanvasMaskState.isMask(element?.elementDefinition)) {
      // console.log('Adding mask', element)
      element.isMask = true;
      this.masks.set(element.id, {
        id: element.id,
        element: element,
        elementIds: element.elementDefinition.elementIds,
      });
    }
  }

  private deleteMaskMembers(mask: Mask) {
    const isMaskOnFrame = this.canvasDocument?.state?.frameState?.frameElements?.get(mask.id);
    mask.elementIds?.forEach((elementId) => {
      const element = this.canvasDocument.getCanvasElementById(elementId);
      if (element) {
        element.isInMask = false;
        this.maskMembers.delete(element.id);
        if (isMaskOnFrame) {
          this.canvasDocument?.state?.frameState?.updateElement(element.elementDefinition);
        }
      }
    });
  }

  private deleteMask(element: CanvasElement) {
    const mask = this.getMask(element.elementDefinition.id);
    if (mask) {
      element.isMask = false;
      this.deleteMaskMembers(mask);
      this.masks.delete(element.id);
    }
  }

  private addMaskMembers(mask: Mask) {
    const isMaskOnFrame: string = this.canvasDocument.state?.frameState?.frameElements?.get(mask.id);
    mask.elementIds.forEach((elementId) => {
      const element = this.canvasDocument.getCanvasElementById(elementId);
      if (element) {
        if (CanvasMaskState.isValidMaskMember(element?.elementDefinition)) {
          // console.log('Adding mask member', element)
          element.isInMask = mask.id;
          this.canvasDocument?.interactionHandler?.selectionHandler?.setDeselected(element);
          this.maskMembers.set(element.id, mask.id);

          const isMaskMemberOnFrame: string = this.canvasDocument.state?.frameState?.frameElements?.get(element.id);
          // if mask is on a frame add mask members to frame
          if (isMaskMemberOnFrame && isMaskMemberOnFrame != isMaskOnFrame) {
            this.canvasDocument?.state?.frameState?.removeElementFromFrame(element);
          } else if (!isMaskMemberOnFrame && isMaskOnFrame) {
            const index = this.canvasDocument?.state?.getElementIndex(element.id);
            this.canvasDocument?.state?.frameState?.addFrameMember(isMaskOnFrame, element, index);
          }
        }
      }
    });
  }

  public addAllMaskMembers() {
    this.masks?.forEach((mask) => this.addMaskMembers(mask));
  }

  public updateMask(element: CanvasElement) {
    const mask = this.getMask(element.id);
    if (mask && CanvasMaskState.isMask(element.elementDefinition)) {
      this.deleteMaskMembers(mask);
      this.addMask(element);
      this.addMaskMembers(mask);
    }
  }

  public updateMaskElement(element: CanvasElement) {
    const mask = this.getMask(element.id);
    if (!mask) {
      // Add new mask or update existing mask
      // console.log('Adding new mask')
      this.addMask(element);
      this.addMaskMembers(this.getMask(element.id));
      if (element.isSelected) {
        this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
          element: element.elementDefinition,
          selectedElements: this.canvasDocument.getSelectedElements(),
          eventType: 'selected',
        });
      }
    } else {
      if (CanvasMaskState.isMask(element.elementDefinition)) {
        if (ObjectUtil.compareDeep(element.elementDefinition.elementIds, mask.elementIds, '').length) {
          // console.log('Updating existing mask')
          this.updateMask(element);
          if (element.isSelected) {
            this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
              element: element.elementDefinition,
              selectedElements: this.canvasDocument.getSelectedElements(),
              eventType: 'selected',
            });
          }
        }
      } else {
        // console.log('Removing existing mask')
        // Remove existing mask
        if (element.isSelected) {
          const maskMembers = this.getMaskMembers(element.id);
          maskMembers?.length &&
            this.canvasDocument.interactionHandler?.selectionHandler?.selectElements(maskMembers, true);
        }
        if (this.isEditingMask(element.id)) {
          this.canvasDocument.interactionHandler.canvasEventHandlers.maskElementEventHandler.isEditingMask = false;
        }
        this.deleteMask(element);
      }
    }
  }

  public deleteMaskElements(ids: Array<string>) {
    for (let id of ids) {
      const element = this.canvasDocument.getCanvasElementById(id);
      if (element) {
        this.deleteMask(element);
        if (this.isEditingMask(element.id)) {
          this.canvasDocument.interactionHandler.canvasEventHandlers.maskElementEventHandler.isEditingMask = false;
        }
      }
    }
  }
}
