import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';
import { CanvasIframeElement } from './canvas-iframe-element';

export const iframeElementContainerId = 'iframe-element-container';

export class IframelementContainer {
  public iframeElement: CanvasElement;
  public iframeContainer: HTMLElement;
  public borderContainer: HTMLElement;
  public iframe: HTMLIFrameElement;

  constructor(private canvasDocument: CanvasDocument) {
    this.iframeContainer = document.createElement('div');
    this.iframeContainer.id = iframeElementContainerId;
    this.iframeContainer.style.userSelect = 'none';
    this.iframeContainer.style.position = 'absolute';
    this.iframeContainer.style.width = '100px';
    this.iframeContainer.style.height = '100px';
    this.iframeContainer.style.left = '0px';
    this.iframeContainer.style.top = '0px';
    this.iframeContainer.style.visibility = 'hidden';
    this.iframeContainer.style.background = 'white';
    this.iframeContainer.style.zIndex = '200';
    this.borderContainer = document.createElement('div');
    this.borderContainer.id = 'iframe-element-border';
    this.borderContainer.style.width = '100%';
    this.borderContainer.style.height = '100%';
    this.borderContainer.style.padding = '10px';
    this.iframeContainer.appendChild(this.borderContainer);

    this.iframe = document.createElement('iframe');
    this.iframe.id = 'iframe-element';
    this.iframe.style.cursor = 'pointer';
    this.iframe.style.margin = '0';
    this.iframe.style.width = '100px';
    this.iframe.style.height = '100px';
    this.borderContainer.appendChild(this.iframe);
    document.getElementById(this.canvasDocument.elementId).appendChild(this.iframeContainer);
    this.iframeContainer.addEventListener('mouseout', (event) => {
      this.canvasDocument.state.elementTarget = { element: undefined, target: undefined };
    });
  }

  public showIframe(iframeElement: CanvasElement) {
    this.iframeElement = iframeElement;
    const element: CanvasIframeElement = this.iframeElement as CanvasIframeElement;
    const dimensions = this.iframeElement.getDimensions({ skipScale: true });
    const elementPosition = { x: dimensions.x, y: dimensions.y };
    const adjustedPositions = this.iframeElement.toWindowPosition(elementPosition, false);
    const scale = this.canvasDocument.getViewScale();
    this.iframeContainer.style.width = `${dimensions.width}px`;
    this.iframeContainer.style.height = `${dimensions.height}px`;
    this.iframeContainer.style.left = `${adjustedPositions.x}px`;
    this.iframeContainer.style.top = `${adjustedPositions.y}px`;
    this.iframeContainer.style.visibility = 'visible';
    this.iframeContainer.style.transform = `scale(${scale.x})`;
    this.iframeContainer.style.transformOrigin = 'top left';

    this.borderContainer.style.borderWidth = `${this.iframeElement.elementDefinition.style?.border?.width}px` || '0px';
    this.borderContainer.style.borderColor =
      this.iframeElement.elementDefinition.style?.border?.color || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.borderStyle = this.iframeElement.elementDefinition.style?.border?.style || 'solid';
    this.borderContainer.style.background =
      this.iframeElement.elementDefinition.style?.backgroundColor || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.transform = `rotate(${iframeElement.elementDefinition.rotate?.angle || 0}deg)`;
    this.iframe.style.width = '100%';
    this.iframe.style.height = '100%';
    this.iframeElement.isVisible = false;
    this.canvasDocument.draw();
    this.iframe.src = this.iframeElement.elementDefinition.url;
  }

  public hideIframe() {
    if (this.iframeElement) {
      this.iframeContainer.style.visibility = 'hidden';
      this.iframeElement.isVisible = true;
      this.canvasDocument.draw();
      this.iframe.src = 'about:blank';
      this.iframeElement = null;
    }
  }

  public getIframeElement() {
    return this.iframeElement;
  }

  public clear() {
    this.iframeContainer?.remove();
    this.iframeContainer = null;
    this.borderContainer = null;
    this.iframe = null;
  }
}
