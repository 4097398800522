import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Entities, EntityReference, SortOrderOptions } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';

import { ObjectUtil } from '@contrail/util';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';
import { WorkspaceEntity } from '@common/workspaces/workspaces-store/workspaces.state';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '@common/auth/auth.service';
import { ChooserSourceOption, ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { Assortment } from '@common/assortments/assortments-store/assortments.state';

@Component({
  selector: 'app-document-generator-source-selector',
  templateUrl: './document-generator-source-selector.component.html',
  styleUrls: ['./document-generator-source-selector.component.scss'],
})
export class DocumentGeneratorSourceSelectorComponent implements OnInit, OnChanges, AfterViewInit {
  private destroy$ = new Subject();
  public showSourceSelector = true;
  public chooserSourceOption$: BehaviorSubject<ChooserSourceOption> = new BehaviorSubject(null);

  public transformedEntities$: Observable<Array<any>>;
  public loading$: Observable<boolean>;
  public loadingRecent = false;

  @Output() selectSource: EventEmitter<ChooserSourceOption> = new EventEmitter(null);
  @Output() changeSource: EventEmitter<any> = new EventEmitter(null);
  @Input() currentWorkspaceId: string;

  private sourceAssortment: any;
  private assortmentsSubject: BehaviorSubject<Array<Assortment>> = new BehaviorSubject([]);
  public assortments$: Observable<Array<Assortment>> = this.assortmentsSubject.asObservable();

  public sourceWorkspaces: any[]; // all projects(workspaces) in org
  public selectedWorkspace: any; // selected workspace - initial value is current board workspace
  public selectedSource: ChooserSourceOption;

  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);

  public step = 'OPTION_TYPE';
  public optionTypesList: any;
  private currentOptionType: any;
  public isNext = false;
  public selectedEntityId = null;

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private authService: AuthService,
    private assortmentsService: AssortmentsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnChanges(): void {}

  async ngOnInit() {
    this.loading$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolderLoading);

    [this.sourceAssortment, this.sourceWorkspaces] = await Promise.all([
      firstValueFrom(this.store.select(AssortmentsSelectors.sourceAssortmentInfo)),
      firstValueFrom(this.store.select(WorkspacesSelectors.workspaces)),
    ]);
    this.initOptionsTypeList();

    if (this.sourceWorkspaces) {
      const workspace = this.sourceWorkspaces.find((sourceWorkspace) => this.currentWorkspaceId === sourceWorkspace.id);
      if (workspace) {
        this.sourceWorkspaceControl.setValue(workspace);
        this.initSourceAssortments(workspace.id);
        this.assortmentFormControl.enable();
      }
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private setIcon(entity) {
    this.workspaceEntitiesHelper.setIcon(entity);
  }
  private initOptionsTypeList() {
    this.optionTypesList = [
      {
        name: 'Browse documents',
        sourceType: ChooserSourceOptionTypes.DOCUMENT,
        img: 'assets/images/folder_icon.svg',
        description: 'Import items from any documents you have access to.',
        active: false,
      },
      {
        name: 'Recent documents',
        sourceType: ChooserSourceOptionTypes.RECENT_DOCUMENT,
        img: 'assets/images/recents_icon.svg',
        description: 'Import items from recently updated documents.',
        active: false,
      },
      {
        name: 'Assortment',
        sourceType: ChooserSourceOptionTypes.ASSORTMENT,
        img: 'assets/images/assortment_icon.svg',
        description: 'Import items from any other assortments.',
        active: false,
        class: 'opacity-25',
      },
    ];
    this.currentOptionType = this.optionTypesList[2];

    if (this.sourceAssortment) {
      this.optionTypesList.splice(2, 0, {
        name: 'Source assortment',
        sourceType: ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
        img: 'assets/images/linked_icon.svg',
        description: `Import items from this document's source assortment.`,
        active: false,
      });
    }

    this.optionTypesList.push({
      name: 'Clipboard',
      sourceType: ChooserSourceOptionTypes.CLIPBOARD,
      img: 'assets/images/clipboard.svg',
      description: 'Import items from clipboard.',
      active: false,
      class: 'opacity-50',
    });
  }

  gotoOptionType() {
    this.optionTypesList.forEach((opt) => (opt.active = false));
    this.step = 'OPTION_TYPE';
    this.isNext = false;
    this.selectedEntityId = null;
    this.assortmentFormControl.setValue(null);
    this.changeSource.emit();
  }

  nextInValid() {
    if (this.step === 'DOCUMENT_SELECT' || this.step === 'RECENT_DOCUMENT') {
      return !this.selectedEntityId;
    }
    return false;
  }

  nextStep() {
    if (this.step === 'WORKSPACE_SELECT') {
      this.selectWorkspace();
    } else if (this.step === 'OPTION_TYPE') {
      this.selectSourceOptionType(this.currentOptionType);
    }
  }

  activeSourceOptionType(option, idx) {
    this.currentOptionType = option;
    this.optionTypesList.forEach((opt) => (opt.active = false));
    this.optionTypesList[idx].active = true;
    this.selectSourceOptionType(option);
  }

  private async selectSourceOptionType(option) {
    this.currentOptionType = option;
    this.changeSource.emit();
    this.selectedEntityId = null;
    const sourceType = option.sourceType;

    switch (sourceType) {
      case ChooserSourceOptionTypes.ASSORTMENT:
        this.isNext = false;
        this.step = 'ASSORTMENT_SELECT';
        break;

      case ChooserSourceOptionTypes.DOCUMENT:
        this.isNext = true;
        this.step = 'WORKSPACE_SELECT';
        break;

      case ChooserSourceOptionTypes.RECENT_DOCUMENT:
        this.isNext = false;
        this.loadingRecent = true;
        this.step = 'RECENT_DOCUMENT';
        const user = await this.authService.getCurrentUser();
        let data = await new Entities().get({
          entityName: 'user-last-access',
          criteria: { userId: user.id },
          relations: ['entity', 'entity.workspace'],
          order: [{ order: SortOrderOptions.DESC, orderField: 'lastAccess' }],
        });
        let entities = [];
        data
          .filter((d) => d?.entity)
          .forEach((ent) => {
            this.setIcon(ent);
            entities.push(ent);
          });
        this.transformedEntities$ = of(entities);
        this.loadingRecent = false;
        break;

      case ChooserSourceOptionTypes.SOURCE_ASSORTMENT:
        this.isNext = false;
        this.selectSource.emit({
          sourceType: ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
          name: this.sourceAssortment?.name,
          workspaceId: this.sourceAssortment?.workspaceId,
          entityReference: 'assortment:' + this.sourceAssortment?.id,
          id: this.sourceAssortment?.id,
          icon: 'assets/images/linked_icon.svg',
        });
        break;

      case ChooserSourceOptionTypes.CLIPBOARD:
        this.isNext = false;
        this.selectSource.emit({
          sourceType: ChooserSourceOptionTypes.CLIPBOARD,
          name: 'Clipboard',
          icon: 'assets/images/clipboard.svg',
          class: 'opacity-50',
        });
        break;

      default:
        break;
    }
  }

  private async initSourceAssortments(workspaceId) {
    const assortments = await this.assortmentsService.getAllAssortmentsInWorkspace(workspaceId, {
      relations: ['sourceAssortments'],
    });
    this.assortmentsSubject.next(assortments);
  }
  handleWorkspaceChange(workspace) {
    this.changeSource.emit();
    if (workspace) {
      this.initSourceAssortments(workspace.id);
      this.assortmentFormControl.enable();
    } else {
      this.assortmentsSubject.next([]);
      this.assortmentFormControl.disable();
    }
  }

  selectWorkspace() {
    this.selectedWorkspace = this.sourceWorkspaceControl.value;
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: this.selectedWorkspace }));
    this.workspaceEntitiesHelper.rootWorkspace = this.selectedWorkspace;
    this.step = 'DOCUMENT_SELECT';

    this.transformedEntities$ = this.store.select(WorkspacesSelectors.workspaceFolderEntities).pipe(
      map((entities) => {
        const transformed = [];
        entities.forEach((e) => {
          const listItem: any = ObjectUtil.cloneDeep(e);
          this.setIcon(listItem);
          if (listItem.entityReference?.indexOf('workspace') > -1) {
            listItem.isDropTarget = true;
          }
          transformed.push(listItem);
        });
        transformed.sort((ent1, ent2) => {
          if (this.isFolder(ent1) && !this.isFolder(ent2)) {
            return -1;
          }
          if (this.isFolder(ent1) && this.isFolder(ent2)) {
            return ent1.name < ent2.name ? -1 : 1;
          }
          return ent2.name < ent1.name ? 1 : -1;
        });
        return transformed;
      }),
    );
  }

  private isFolder(workspaceEntity: WorkspaceEntity) {
    return workspaceEntity?.entityReference?.indexOf('workspace') > -1;
  }

  async selectEntity(item) {
    if (item?.entity?.workspaceType === 'FOLDER') {
      this.changeSource.emit();
      this.selectedEntityId = null;
      this.workspaceEntitiesHelper.goToEntity(item);
    } else {
      const ref = new EntityReference(item.entityReference);
      let assortmentId;
      if (ref.entityType === 'plan') {
        if (item.entity?.targetAssortmentId) {
          assortmentId = item.entity?.targetAssortmentId;
        } else {
          this.snackBar.open('Error - No targetAssortmentId', '⛌', { duration: 1500 });
          // const plan = await new Entities().get({ entityName: 'plan', id: item.entity.id, relations: ['targetAssortment', 'targetAssortment.sourceAssortments']});
          // console.log('no targetAssortmentId', plan);
          return false;
        }
      } else if (ref.entityType === 'showcase' || ref.entityType === 'board') {
        if (item.entity?.backingAssortmentId) {
          assortmentId = item.entity?.backingAssortmentId;
        } else {
          this.snackBar.open('Error - No backingAssortmentId', '⛌', { duration: 1500 });
          // const test = await new Entities().get({ entityName: ref.entityType, id: item.entity.id, relations: ['sourceAssortment']});
          // console.log('no assortment id', test);
          return false;
        }
      } else if (ref.entityType === 'showroom') {
        this.snackBar.open('Not supported yet', '⛌', { duration: 1500 });
        return false;
      }

      // ----------------------------------------------------------------

      this.isNext = false;
      this.selectedSource = {
        workspaceId: item?.workspaceId || item?.entity?.workspaceId,
        sourceType: ChooserSourceOptionTypes.ASSORTMENT,
        name: item?.name || item?.entity?.name,
        entityReference: 'assortment:' + assortmentId,
        id: assortmentId,
        icon: item.icon,
      };
      this.selectSource.emit(this.selectedSource);
      this.selectedEntityId = item?.id;
    }
  }

  handleAssortmentChange() {
    const assortment = this.assortmentFormControl.value;
    if (assortment) {
      this.selectedSource = {
        workspaceId: assortment.workspaceId,
        sourceType: ChooserSourceOptionTypes.ASSORTMENT,
        name: assortment.name,
        entityReference: 'assortment:' + assortment.id,
        id: assortment.id,
        icon: 'assets/images/assortment_icon.svg',
        class: 'opacity-25',
      };
      this.handleSelectSource();
    }
  }
  /** Finish the selection. */
  handleSelectSource() {
    if (!this.selectedSource) {
      return;
    }
    this.selectSource.emit(this.selectedSource);
  }

  toggleSourceSelector(value) {
    this.showSourceSelector = value;
    this.chooserSourceOption$.next(null);
  }
}
