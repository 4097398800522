import { Quaternion, Tools, Vector3 } from '@babylonjs/core';

// Type intended to store vector coordinates without the runtime overhead of babylon.Vector3
export class VectorPortable {
  x: number;
  y: number;
  z: number;

  constructor(x: number = 0, y: number = 0, z: number = 0) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  static FromVector3(vector3: Vector3): VectorPortable {
    return new VectorPortable(vector3.x, vector3.y, vector3.z);
  }

  static ToVector3(vecStorage: VectorPortable): Vector3 {
    return new Vector3(vecStorage.x, vecStorage.y, vecStorage.z);
  }

  static Copy(vecStorage: VectorPortable): VectorPortable {
    return new VectorPortable(vecStorage.x, vecStorage.y, vecStorage.z);
  }
}

// Type intended to store rotation coordinates without the runtime overhead of babylon.Vector3
export class RotationPortable {
  pitch: number;
  yaw: number;
  roll: number;
  unit: string; // rad, deg

  constructor(pitch: number = 0, yaw: number = 0, roll: number = 0, unit: string = 'deg') {
    this.pitch = pitch;
    this.yaw = yaw;
    this.roll = roll;
    this.unit = unit;
  }

  static FromVector3(vector3: Vector3, unit: string = 'rad'): RotationPortable {
    return new RotationPortable(vector3.x, vector3.y, vector3.z, unit);
  }

  static ToVector3(rotStorage: RotationPortable): Vector3 {
    return new Vector3(rotStorage.pitch, rotStorage.yaw, rotStorage.roll);
  }

  static FromQuaternion(quat: Quaternion): RotationPortable {
    return RotationPortable.FromVector3(quat.toEulerAngles());
  }

  static ToQuaternion(rotStorage: RotationPortable): Quaternion {
    return Quaternion.FromEulerAngles(rotStorage.pitch, rotStorage.yaw, rotStorage.roll);
  }

  static ConvertToRadInPlace(rotStorage: RotationPortable): void {
    if (rotStorage.unit == 'rad') {
      return;
    }

    rotStorage.pitch = Tools.ToRadians(rotStorage.pitch);
    rotStorage.yaw = Tools.ToRadians(rotStorage.yaw);
    rotStorage.roll = Tools.ToRadians(rotStorage.roll);

    rotStorage.unit = 'rad';
  }

  static ConvertToDegInPlace(rotStorage: RotationPortable): void {
    if (rotStorage.unit == 'deg') {
      return;
    }

    rotStorage.pitch = Tools.ToDegrees(rotStorage.pitch);
    rotStorage.yaw = Tools.ToDegrees(rotStorage.yaw);
    rotStorage.roll = Tools.ToDegrees(rotStorage.roll);

    rotStorage.unit = 'deg';
  }

  static Copy(rotStorage: RotationPortable): RotationPortable {
    return new RotationPortable(rotStorage.pitch, rotStorage.yaw, rotStorage.roll, rotStorage.unit);
  }
}

export class TransformPortable {
  position: VectorPortable;
  rotation: RotationPortable;

  constructor(position: VectorPortable = new VectorPortable(), rotation: RotationPortable = new RotationPortable()) {
    this.position = position;
    this.rotation = rotation;
  }

  static Copy(transformStorage: TransformPortable): TransformPortable {
    return new TransformPortable(transformStorage.position, transformStorage.rotation);
  }
}
