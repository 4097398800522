import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionStatusMessageService } from '../../board/board-side-menu/status-messages/collection-status-message.service';

@Injectable()
export class CollectionStatusMessagesEffects {
  constructor(
    private actions$: Actions,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
    private snackBar: MatSnackBar,
  ) {}
}
