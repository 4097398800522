<div class="flex items-center gap-3 px-2">
  <span
    class="material-icons text-black60 !text-[30px] cursor-pointer"
    (click)="showItemChooser(false)"
    matTooltip="Chooser"
    data-test="item-chooser-button"
  >
    control_point
  </span>
  <img
    src="/assets/images/item.svg"
    alt=""
    class="select-chooser"
    (click)="showItemChooser(true)"
    matTooltip="Item Library"
    data-test="document-widget-tray-add-items"
  />
  <img
    src="/assets/images/color.svg"
    alt=""
    class="select-chooser"
    (click)="showColorChooser()"
    matTooltip="Color Library"
    *ngIf="showAssetLibrary$ | async"
  />
  <img
    src="/assets/images/asset.svg"
    alt=""
    class="select-chooser"
    (click)="showAssetChooser()"
    matTooltip="Asset Library"
    data-test="open-asset-library"
    *ngIf="showAssetLibrary$ | async"
  />
</div>
