import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedElementInfo } from '../board-context-menu.component';
import { DocumentService } from '../../document/document.service';
import { BoardService } from '../../board.service';
import { DocumentAssetService } from '../../document/document-asset/document-asset.service';
import { DocumentElementEvent } from '@contrail/documents';

export class PromoteToAsssetMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected documentService: DocumentService,
    protected boardService: BoardService,
    protected documentAssetService: DocumentAssetService,
  ) {
    super(store, documentService, boardService);
    this.actionIcon = '';
    this.actionLabel = 'Promote to Asset';
    this.iconClass = '';
    this.testRef = 'app-menu-promote-to-assset';
    this.shortCutKey = '';
    this.init();
  }
  async init() {}

  async handleAction(sourceEvent: DocumentElementEvent, editorMode: string, selectedElementsInfo: SelectedElementInfo) {
    // Currently only works one at a time.
    if (!selectedElementsInfo?.documentElements.length) {
      return;
    }
    const element = selectedElementsInfo?.documentElements[0];
    // Create the asset.
    this.documentAssetService.convertImageElementToAsset(element);
  }

  canInvoke(editorMode: string, selectedElementsInfo: SelectedElementInfo = null) {
    if (!selectedElementsInfo?.documentElements.length) {
      return;
    }
    let result = true;
    for (let el of selectedElementsInfo.documentElements) {
      if (!el.modelBindings?.image || el.modelBindings?.asset) {
        result = false;
      }
    }
    return result;
  }
}
