import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementFactory,
  LineDefinition,
  PositionDefinition,
  ViewBox,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { SHAPE_ELEMENT_TYPES } from '../../../constants';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class ElementDrawHandler {
  private startingDraggingBox: ViewBox;
  private startingDraggingLine: LineDefinition;

  constructor(private canvasDocument: CanvasDocument) {}

  private clear() {
    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = null;
    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine = null;
    this.startingDraggingBox = null;
    this.startingDraggingLine = null;
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    const interactionMode = this.canvasDocument?.interactionHandler?.interactionMode;
    if (SHAPE_ELEMENT_TYPES.indexOf(interactionMode) === -1) return;

    const { x, y } = {
      x: event.clientX,
      y: event.clientY,
    };

    const { element, target } = elementTarget;
    // Allow to draw shapes on top of other shapes
    // if (!target) {
    //console.log('ElementDrawHandler.dragstarted', elementTarget, event, interactionMode);
    if (['line', 'arrow'].indexOf(interactionMode) === -1) {
      this.startingDraggingBox = { x, y, width: 0, height: 0 };
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = { x, y, width: 0, height: 0 };
    } else {
      this.startingDraggingLine = { x1: x, y1: y, x2: x, y2: y };
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine = { x1: x, y1: y, x2: x, y2: y };
    }
    // }
  }

  public dragged(event) {
    if (!this.startingDraggingBox && !this.startingDraggingLine) return;

    if (this.startingDraggingBox) {
      let distanceX = event.clientX - this.startingDraggingBox.x;
      let distanceY = event.clientY - this.startingDraggingBox.y;
      if (distanceX === 0 && distanceY === 0) {
        return;
      }

      let width = Math.abs(this.startingDraggingBox.width + distanceX);
      let height = Math.abs(this.startingDraggingBox.height + distanceY);
      let x = this.startingDraggingBox.x;
      let y = this.startingDraggingBox.y;

      if (distanceX < 0) {
        x = this.startingDraggingBox.x + distanceX;
      }
      if (distanceY < 0) {
        y = this.startingDraggingBox.y + distanceY;
      }

      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = {
        width,
        height,
        x,
        y,
      };

      this.canvasDocument.draw();
    } else if (this.startingDraggingLine) {
      const { x1, y1 } = this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine;
      let x2 = event.clientX;
      let y2 = event.clientY;

      if (event.shiftKey) {
        const angle = Math.abs((Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI);
        if (angle > 45 && angle < 135) {
          x2 = x1;
        } else {
          y2 = y1;
        }
      }
      //console.log(this.startingDraggingLine, this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine)

      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine = {
        x1,
        y1,
        x2,
        y2,
      };

      this.canvasDocument.draw();
    }
  }

  public dragended(event) {
    if (!this.startingDraggingBox && !this.startingDraggingLine) return;

    const hasChanged = ObjectUtil.compareDeep(
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox ||
        this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine,
      this.startingDraggingBox || this.startingDraggingLine,
      '',
    );
    if (!hasChanged?.length) {
      this.clear();
      return;
    }
    //console.log('ElementDrawHandler.dragended', event, this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox, this.startingDraggingBox)

    this.createNewElement();
    this.clear();
    this.canvasDocument.draw();
  }

  private createNewElement() {
    let type = this.canvasDocument?.interactionHandler?.interactionMode;
    if (SHAPE_ELEMENT_TYPES.indexOf(type) === -1) return;

    let params: DocumentElement;
    switch (type) {
      case 'line':
        params = this.getLineParams();
        break;
      case 'arrow':
        type = 'line';
        params = this.getLineParams();
        params.lineDefinition.markerEnd = 'arrow';
        break;
      case 'round_rectangle':
        type = 'rectangle';
        params = this.getElementParams();
        params.style = {
          border: {
            radius: 10,
          },
        };
        break;
      default:
        params = this.getElementParams();
        break;
    }

    this.canvasDocument.actionsDispatcher.addNewElement(type, params);
  }

  private getElementParams(): DocumentElement {
    const box = this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox;
    const { x, y } = this.canvasDocument.toDocumentPosition(box.x, box.y);
    const { width, height } = this.canvasDocument.toDocumentSize(box.width, box.height);

    const params: DocumentElement = Object.assign({
      position: {
        x,
        y,
      },
      size: {
        width,
        height,
      },
    });
    return params;
  }

  private getLineParams(): DocumentElement {
    const line = this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingLine;
    const p1 = this.canvasDocument.toDocumentPosition(line.x1, line.y1);
    const p2 = this.canvasDocument.toDocumentPosition(line.x2, line.y2);

    const params: DocumentElement = {
      lineDefinition: {
        x1: p1.x,
        y1: p1.y,
        x2: p2.x,
        y2: p2.y,
      },
    };
    return params;
  }
}
