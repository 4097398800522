<div class="workspace-selector" [matMenuTriggerFor]="aboveMenu">
  <img src="assets/images/workspace_icon.svg" class="mr-2 w-5 h-5 object-contain" alt="" />
  <span class="text-title-1 current-workspace"> {{ (selectedWorkspace$ | async)?.name }}</span>
  <mat-icon class="ml-auto">arrow_drop_down</mat-icon>
</div>
<mat-menu #aboveMenu="matMenu" yPosition="below" xPosition="before" class="menu-md">
  <button
    class="org-button"
    mat-menu-item
    *ngFor="let workspace of workspaces$ | async"
    (click)="selectWorkspace(workspace)"
  >
    {{ workspace.name }}
  </button>
</mat-menu>
