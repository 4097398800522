import { on } from '@ngrx/store';
import { State } from '../clipboard.state';
import { clipboardItemEntityAdapter } from './clipboard-items.state';
import * as ClipboardActions from './clipboard-items.actions';

const loadClipboardItems = (state: State, { clipboardItems }) => {
  return {
    ...state,
    clipboardItems: clipboardItemEntityAdapter.setAll(clipboardItems, state.clipboardItems),
  };
};

const addClipboardItems = (state: State, { clipboardItems }) => {
  return {
    ...state,
    clipboardItems: clipboardItemEntityAdapter.addMany(clipboardItems, state.clipboardItems),
  };
};

const removeClipboardItems = (state: State, { ids }) => {
  return {
    ...state,
    clipboardItems: clipboardItemEntityAdapter.removeMany(ids, state.clipboardItems),
  };
};

export const clipboardItemsReducers = [
  on(ClipboardActions.loadClipboardItemsSuccess, loadClipboardItems),
  on(ClipboardActions.addItemsToClipboardSuccess, addClipboardItems),
  on(ClipboardActions.removeItemsFromClipboardSuccess, removeClipboardItems),
];
