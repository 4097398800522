import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { FrameTemplatesService } from '../frame-templates.service';
import { FrameTemplatesActions } from '.';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FrameTemplate } from '../frame-template';

@Injectable()
export class FramesTemplatesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private frameTemplatesService: FrameTemplatesService,
  ) {}

  loadFrameTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameTemplatesActions.FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATES),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.frameTemplatesService.getFrameTemplates(action.templateTypes)).pipe(
          map((data) => FrameTemplatesActions.loadFrameTemplatesSuccess({ data })),
          catchError((error) => observableOf(FrameTemplatesActions.loadFrameTemplatesFailure({ error }))),
        );
      }),
    ),
  );

  loadFrameTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameTemplatesActions.FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.frameTemplatesService.getFrameTemplateById(action.frameTemplateId)).pipe(
          map((data) =>
            FrameTemplatesActions.loadFrameTemplateSuccess({ id: data.id, changes: { document: data.document } }),
          ),
          catchError((error) => observableOf(FrameTemplatesActions.loadFrameTemplateFailure({ error }))),
        );
      }),
    ),
  );

  createFrameTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameTemplatesActions.FrameTemplatesActionTypes.CREATE_FRAME_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const frameTemplate: FrameTemplate = { ...action.frameTemplate };
        return from(this.frameTemplatesService.createFrameTemplate(frameTemplate)).pipe(
          map((data) => {
            this.snackBar.open('Frame Template Created.', '', { duration: 2000 });
            return FrameTemplatesActions.createFrameTemplateSuccess({ frameTemplate: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FrameTemplatesActions.createFrameTemplateFailure({ error }));
          }),
        );
      }),
    ),
  );

  updateFrameTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameTemplatesActions.FrameTemplatesActionTypes.UPDATE_FRAME_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.frameTemplatesService.updateFrameTemplate(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Frame Template Updated.', '', { duration: 2000 });
            return FrameTemplatesActions.updateFrameTemplateSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FrameTemplatesActions.updateFrameTemplateFailure({ error }));
          }),
        );
      }),
    ),
  );

  deleteFrameTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrameTemplatesActions.FrameTemplatesActionTypes.DELETE_FRAME_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.frameTemplatesService.deleteFrameTemplate(action.frameTemplate)).pipe(
          map((data) => {
            this.snackBar.open('Frame Template Deleted.', '', { duration: 2000 });
            return FrameTemplatesActions.deleteFrameTemplateSuccess({ frameTemplate: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FrameTemplatesActions.deleteFrameTemplateFailure({ error }));
          }),
        );
      }),
    ),
  );
}
