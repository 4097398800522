<div
  class="cell"
  [ngClass]="{
    right: columnDefinition.alignment === 'right',
    left: columnDefinition.alignment === 'left',
    center: columnDefinition.alignment === 'center'
  }"
>
  <div class="value" #cellValue>
    <span *ngIf="!(columnDefinition?.propertyType === 'icon')">
      {{ display }}
    </span>
    <span *ngIf="columnDefinition?.propertyType === 'icon'">
      <img [src]="value || columnDefinition.constantValue" />
    </span>
  </div>
</div>
