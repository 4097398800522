import {
  Component,
  Input,
  OnChanges,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Vector3 } from '@babylonjs/core';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContentConfig } from '../showroom-content-config/showroom-content-config';
import { RotationPortable, VectorPortable } from '../util/transform-types';
import { WebGLContentConfigurator } from './webgl-content-configurator';

@Component({
  selector: 'app-webgl-content-configurator',
  templateUrl: './webgl-content-configurator.component.html',
  styleUrls: ['./webgl-content-configurator.component.scss'],
})
export class WebglContentConfiguratorComponent implements OnInit, OnChanges, OnDestroy {
  prod = environment.production;

  @Input() content: any; // VibeIQ content entity
  @Input() viewMode: 'preview' | 'full' | 'modal' = 'preview';
  @Input() contentModal = false;
  @Input() size = '675px';
  @Output() close = new EventEmitter();

  private destroy$ = new Subject();
  public assetForm: UntypedFormGroup = new UntypedFormGroup({});

  // PRESET -----------------------------
  @ViewChild('viewNameInput') viewNameInput: ElementRef;
  form: UntypedFormGroup = new UntypedFormGroup({
    viewName: new UntypedFormControl('', [Validators.required]),
    selectedViewDefTemplate: new UntypedFormControl('', []),
  });
  public editName = false;
  async onSelectedViewDefChange(event) {}
  startNameEdit() {
    this.editName = true;
    this.form.get('viewName').setValue('');
    setTimeout(() => {
      this.viewNameInput.nativeElement.focus();
    }, 100);
  }
  async endNameEdit() {
    this.editName = false;
  }
  // ------------ PRESET ------------- //

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: AppNotificationsService,
  ) {
    this.assetForm = this.fb.group({
      slotType: ['focus'],
      transform: ['pos'],
      pivot: ['center'],
      baseUnit: ['cm'],

      scale: [1, [Validators.required]],
      offsetX: [0, [Validators.required]],
      offsetY: [0, [Validators.required]],
      offsetZ: [0, [Validators.required]],

      rotX: [0, [Validators.required]],
      rotY: [0, [Validators.required]],
      rotZ: [0, [Validators.required]],

      compatibleFocusView: [false],
      compatibleHanged: [false],
      compatibleSurface: [false],
      compatibleShoe: [false],
    });
  }

  public configurator: WebGLContentConfigurator;

  ngOnInit(): void {
    this.configurator = new WebGLContentConfigurator();
    setTimeout(() => {
      const canvas = document.getElementById('renderCanvasConfig') as HTMLCanvasElement;
      this.configurator.init(canvas, this.content);
    }, 300);

    const engine = this.configurator?.engine;
    setTimeout(() => {
      engine?.resize();
    }, 10);

    this.assetForm.valueChanges.pipe(pairwise(), takeUntil(this.destroy$)).subscribe(([prev, next]) => {
      if (prev.slotType !== next.slotType) {
        this.configurator.pickSlotType(next.slotType);
      }
      if (prev.transform !== next.transform) {
        this.configurator.updateSelectedGizmo(next.transform);
      }
      if (prev.pivot !== next.pivot) {
        this.configurator.setPivotPreset(next.pivot);
      }
      if (prev.baseUnit !== next.baseUnit) {
        this.configurator.pickBaseUnit(next.baseUnit);
      }
      if (prev.scale !== next.scale) {
        this.configurator.setUniformScale(next.scale);
      }
      if (prev.offsetX !== next.offsetX || prev.offsetY !== next.offsetY || prev.offsetZ !== next.offsetZ) {
        this.configurator.setPositionOffsetDirectly(
          new VectorPortable(parseFloat(next.offsetX), parseFloat(next.offsetY), parseFloat(next.offsetZ)),
        );
      }
      if (prev.rotX !== next.rotX || prev.rotY !== next.rotY || prev.rotZ !== next.rotZ) {
        this.configurator.setRotationOffsetDirectly(
          new RotationPortable(parseFloat(next.rotX), parseFloat(next.rotY), parseFloat(next.rotZ)),
        );
      }
      if (prev.compatibleFocusView !== next.compatibleFocusView) {
        this.configurator.updateSlotTypes('FocusView', next.compatibleFocusView);
      }
      if (prev.compatibleHanged !== next.compatibleHanged) {
        this.configurator.updateSlotTypes('Hanged', next.compatibleHanged);
      }
      if (prev.compatibleSurface !== next.compatibleSurface) {
        this.configurator.updateSlotTypes('Surface', next.compatibleSurface);
      }
      if (prev.compatibleShoe !== next.compatibleShoe) {
        this.configurator.updateSlotTypes('Shoe', next.compatibleShoe);
      }
    });

    this.configurator.onContentConfigUpdated.add(this.handleContentConfigUpdated, 1, false, this);
    this.configurator.onPositionUpdated.add(this.handlePositionUpdated, 1, false, this);
    this.configurator.onRotationUpdated.add(this.handleRotationUpdated, 1, false, this);

    if (this.viewMode === 'modal') {
      document.getElementById('webglMove').addEventListener('click', (event) => this.move(), true);
      document.getElementById('webglMove').setAttribute('draggable', 'false');
      document.getElementById('webglRotate').addEventListener('click', (event) => this.rotate(), true);
      document.getElementById('webglRotate').setAttribute('draggable', 'false');
      document.getElementById('webglZoomIn').addEventListener('mousedown', (event) => this.startZoomIn(), true);
      document.getElementById('webglZoomIn').addEventListener('mouseup', (event) => this.stopZoom(), true);
      document.getElementById('webglZoomIn').addEventListener('mouseout', (event) => this.stopZoom(), true);
      document.getElementById('webglZoomIn').setAttribute('draggable', 'false');
      document.getElementById('webglZoomOut').addEventListener('mousedown', (event) => this.startZoomOut(), true);
      document.getElementById('webglZoomOut').addEventListener('mouseup', (event) => this.stopZoom(), true);
      document.getElementById('webglZoomOut').addEventListener('mouseout', (event) => this.stopZoom(), true);
      document.getElementById('webglZoomOut').setAttribute('draggable', 'false');
    }
  }

  ngOnChanges(): void {
    // Don't call this if viewer doesn't exist, it will be called in OnInit
    if (this.configurator) {
      this.configurator.setupAsset(this.content);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();

    this.configurator?.engine.dispose();
    if (this.viewMode === 'modal') {
      document.getElementById('webglMove')?.removeEventListener('click', this.move, true);
      document.getElementById('webglRotate')?.removeEventListener('click', this.rotate, true);
      document.getElementById('webglZoomIn')?.removeEventListener('mousedown', this.startZoomIn, true);
      document.getElementById('webglZoomIn')?.removeEventListener('mouseup', this.stopZoom, true);
      document.getElementById('webglZoomOut')?.removeEventListener('mousedown', this.startZoomOut, true);
      document.getElementById('webglZoomOut')?.removeEventListener('mouseup', this.stopZoom, true);
    }
  }

  public reset() {
    this.configurator.resetShowroomContentConfig();
    // Do we want to delete the content config when the reset button is pressed?
    // this.configurator.deleteShowroomContentConfig();
  }
  public save() {
    this.notificationService.showConfirmationMessage('Asset config update');
    this.configurator.updateShowroomContentConfig();
  }

  public handleContentConfigUpdated(contentConfig: ContentConfig): void {
    let slotType;
    if (contentConfig.slotTypes.includes('Hanged')) {
      slotType = 'hanged';
    } else if (contentConfig.slotTypes.includes('Surface') || contentConfig.slotTypes.includes('Shoe')) {
      slotType = 'surface';
    } else {
      slotType = 'focus';
    }
    this.assetForm.patchValue({
      offsetX: contentConfig.offsetTransform?.position?.x?.toFixed(2),
      offsetY: contentConfig.offsetTransform?.position?.y?.toFixed(2),
      offsetZ: contentConfig.offsetTransform?.position?.z?.toFixed(2),
      rotX: contentConfig.offsetTransform?.rotation?.pitch?.toFixed(2),
      rotY: contentConfig.offsetTransform?.rotation?.yaw?.toFixed(2),
      rotZ: contentConfig.offsetTransform?.rotation?.roll?.toFixed(2),
      scale: contentConfig.uniformScale,
      baseUnit: contentConfig.baseUnit,
      pivot: contentConfig.pivotPosition,
      slotType: slotType,
      compatibleFocusView: contentConfig.slotTypes.includes('FocusView'),
      compatibleHanged: contentConfig.slotTypes.includes('Hanged'),
      compatibleSurface: contentConfig.slotTypes.includes('Surface'),
      compatibleShoe: contentConfig.slotTypes.includes('Shoe'),
    });
  }

  public handlePositionUpdated(position: any): void {
    this.assetForm.patchValue({
      offsetX: position?.x?.toFixed(2),
      offsetY: position?.y?.toFixed(2),
      offsetZ: position?.z?.toFixed(2),
    });
  }

  public handleRotationUpdated(rotation: any): void {
    this.assetForm.patchValue({
      rotX: rotation.pitch?.toFixed(2),
      rotY: rotation.yaw?.toFixed(2),
      rotZ: rotation.roll?.toFixed(2),
    });
  }

  public modalClose() {
    this.close.emit();
  }

  public move() {
    this.configurator.updateSelectedGizmo('pos');
  }
  public rotate() {
    this.configurator.updateSelectedGizmo('rot');
  }
  public startZoomIn() {
    this.configurator.currentZoomControl = -1;
  }
  public startZoomOut() {
    this.configurator.currentZoomControl = 1;
  }
  public stopZoom() {
    this.configurator.currentZoomControl = 0;
  }
}
