import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasRightArrowElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }: { x: any; y: any; width: any; height: any }): any[] {
    let arrowPoint = width - 0.75 * height;
    if (arrowPoint / width <= 0.2) {
      arrowPoint = 0.2 * width;
    }
    const points = [
      [0 * width + x, 0.25 * height + y],
      [arrowPoint + x, 0.25 * height + y],
      [arrowPoint + x, 0 * height + y],
      [1 * width + x, 0.5 * height + y],
      [arrowPoint + x, 1 * height + y],
      [arrowPoint + x, 0.75 * height + y],
      [0 * width + x, 0.75 * height + y],
      [0 * width + x, 0.25 * height + y],
    ];
    return points;
  }
}
