import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-assortment-history-detail',
  templateUrl: './assortment-history-detail.component.html',
  styleUrls: ['./assortment-history-detail.component.scss'],
})
export class AssortmentHistoryDetailComponent implements OnInit, OnChanges {
  @Input() assortmentHistoryItem;
  @Input() assortment;
  public selectedChange;
  public selectedChangeType;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.selectedChange = null;
    this.selectedChangeType = null;
  }
  setSelectedChange(change, changeType) {
    this.selectedChange = change;
    this.selectedChangeType = changeType;
  }

  getName() {
    if (this.assortmentHistoryItem.createdBy?.firstName) {
      return this.assortmentHistoryItem.createdBy?.firstName + ' ' + this.assortmentHistoryItem.createdBy?.lastName;
    }
    return this.assortmentHistoryItem.createdBy?.email;
  }
}
