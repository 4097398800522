<div class="flex flex-col flex-1">
  <div class="border-b-2 mt-2">
    <div class="ml-4 text-[12px]">
      <mat-slide-toggle
        data-test="show-current-user-history-toggle"
        labelPosition="before"
        (change)="toggleMyUpdates($event)"
        >Only show my updates</mat-slide-toggle
      >
    </div>
    <div class="ml-4 mr-4 text-[12px]">
      <app-date-filter [dateFilterAttribute]="dateFilterAttribute" (dateFilterChanged)="dateFilterChanged($event)">
      </app-date-filter>
    </div>
  </div>

  <div class="flex flex-col flex-1">
    <virtual-scroller
      *ngIf="!(loading$ | async)"
      [style.height]="resultsHeight"
      #scroll
      [items]="filteredHistory"
      class="narrow-scroll"
    >
      <div
        [ngClass]="{ selected: currentEntitySnapshot?.id === historyItem.id }"
        class="flex justify-between pl-5 pt-5 pb-5 history-row cursor-pointer"
        (click)="loadHistorySnapshot(historyItem)"
        *ngFor="let historyItem of scroll.viewPortItems; let i = index"
      >
        <div>
          <div class="text-[14px] font-medium mb-1">
            V{{ historyItem.versionNumber || ' ' + (historyItem.createdOn | date: 'short') }}
          </div>
          <div class="mb-1">{{ historyItem.createdOn | date: 'medium' }}</div>
          <div *ngIf="historyItem.createdBy" class="flex items-center text-[12px]">
            <mat-icon class="text-gray-300 mr-1 ml-[-2px]">account_circle</mat-icon>{{ historyItem.createdBy.email }}
          </div>
        </div>

        <div *ngIf="allowEdit">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ historyItem }"
            (click)="handleClick($event)"
          >
            <mat-icon class="text-gray-600 text-[18px]">more_vert</mat-icon>
          </button>
        </div>
      </div>
    </virtual-scroller>
  </div>

  <div class="loading-container" *ngIf="loading$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
    <div class="message">Loading history...</div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-aliasMenuItems="historyItem">
    <button mat-menu-item (click)="restoreSnapshot(aliasMenuItems.id)">Restore</button>
  </ng-template>
</mat-menu>
