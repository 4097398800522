import { ViewDefinition } from '@contrail/client-views';
export const VIEWS: Array<ViewDefinition> = [
  {
    applicationViewSlug: 'showcase:item_details',
    viewType: 'properties_list',
    label: 'Showcase Item Details',
    id: 'wUheENmBsKK3fYCl',
    properties: [
      {
        slug: 'name',
        enabled: true,
        typeRootSlug: 'item',
      },
      {
        slug: 'optionName',
        enabled: true,
        typeRootSlug: 'item',
      },
      {
        slug: 'collection',
        enabled: true,
        typeRootSlug: 'item',
      },
      {
        slug: 'division',
        enabled: true,
        typeRootSlug: 'item',
      },
    ],
  },
  {
    applicationViewSlug: 'showcase:item_card',
    viewType: 'properties_list',
    label: 'Showcase Item Card',
    id: 'wUheENmBsKK3fYCl',
    properties: [
      {
        slug: 'planningLevel1',
        enabled: true,
        typeRootSlug: 'item',
      },
      {
        slug: 'targetRetail',
        enabled: true,
        typeRootSlug: 'assortment-item',
      },
    ],
  },
];
