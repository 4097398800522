import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { BoardsActions, BoardsSelectors } from '../../boards-store';
import { Board, BoardService } from '../board.service';
import { Subscription } from 'rxjs';
import { DocumentHistoryActions, DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { DocumentActions } from '../document/document-store';
import { BoardSnapshotService } from '../board-snapshot/board-snapshot.service';
import { AuthService } from '@common/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-board-title-bar',
  templateUrl: './board-title-bar.component.html',
  styleUrls: ['./board-title-bar.component.scss'],
})
export class BoardTitleBarComponent implements OnInit {
  public currentBoard: Board;
  editName = false;
  name: UntypedFormControl;
  @ViewChild('inputField') editNameInput: ElementRef;
  public editorMode: EditorMode;
  public allowEdit: boolean;
  entitySnapshot: any;
  isSnapshotRestoreAllowed = true;
  private subscriptions: Subscription = new Subscription();
  constructor(
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private boardSnapshotService: BoardSnapshotService,
    private boardService: BoardService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isSnapshotRestoreAllowed = !this.authService.isOnSharedLink();
    this.subscriptions.add(
      this.store.select(BoardsSelectors.currentBoard).subscribe((board) => {
        if (!board) {
          return;
        }
        this.currentBoard = board;
        this.name = new UntypedFormControl(board.name, Validators.required);
      }),
    );
    this.subscriptions.add(
      this.store
        .select(DocumentHistorySelectors.currentEntitySnapshot)
        .subscribe((entitySnapshot) => (this.entitySnapshot = entitySnapshot)),
    );
    this.subscriptions.add(this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m)));

    this.allowEdit = !this.authService.isOnSharedLink() && this.editorMode === 'EDIT';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  startEditName() {
    this.editName = true;
    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
    }, 100);
  }

  endEditName() {
    this.editName = false;

    if (!this.name.valid) {
      return;
    }
    if (this.name.value === this.currentBoard.name) {
      return;
    }
    const changes: Board = { name: this.name.value };

    this.store.dispatch(BoardsActions.updateBoard({ id: this.currentBoard.id, changes }));
  }

  public goHome() {
    this.hideItemChooser();

    if (this.editorMode === 'EDIT') {
      this.routingService.go('home');
    } else {
      const path = this.routingService.getRouterLink('');
      this.router.navigateByUrl(path);
    }
  }

  hideItemChooser() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addItem';
    overlay.showChooser = false;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  clearSnapshot() {
    this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
  }

  showHistoryPanel($event) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = $event;
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  restoreSnapshot() {
    this.store.dispatch(DocumentHistoryActions.restoreEntitySnapshot({ id: this.entitySnapshot.id }));
  }

  assignSourceAssortment(data?: any) {
    if (!data) {
      return;
    }
    this.boardService.assignSourceAssortment(data.sourceAssortmentId);
  }

  removeSourceAssorment() {
    this.boardService.clearSourceAssortment();
  }
}
