import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@common/content/content.service';
import { Feature } from '@common/feature-flags/feature-flag';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { WebglViewerComponent } from '@common/webgl/webgl-viewer/webgl-viewer.component';
import { Entities } from '@contrail/sdk';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContentHolderService } from '../content-holder.service';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-content-modal-3d',
  templateUrl: './content-modal-3d.component.html',
  styleUrls: ['./content-modal-3d.component.scss'],
})
export class ContentModal3DComponent implements OnInit, OnDestroy {
  private subs: Array<Subscription> = [];

  @Input() content;
  public contentToDisplay;
  public historicalContent;
  @ViewChild(WebglViewerComponent) webglView: WebglViewerComponent;
  public hasShowroom$: Observable<boolean>;

  sideNavOptions: any = [
    { label: 'details', icon: 'info' },
    { label: 'image', icon: 'image' },
    { label: 'pattern', icon: 'texture' },
    { label: 'recolor', icon: 'format_color_fill' },
    { label: 'history', icon: 'history' },
  ];
  currentSideNav = 'details';

  presetColors = [
    'rgba(0,0,0,0)',
    '#00B0FF',
    '#00C853',
    '#FFC000',
    '#BB6BD9',
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#009900',
    '#0000FF',
    '#FFFFFF',
    '#BDBDBD',
    '#616161',
    '#424242',
    '#000000',
  ];
  color = 'rgba(0,0,0,1)';

  get configNavTitle() {
    switch (this.currentSideNav) {
      case 'details':
        return 'Details';
      case 'config':
        return 'Showroom Configuration';
      case 'image':
        return 'Image Overlay';
      case 'pattern':
        return 'Pattern Overlay';
      case 'recolor':
        return 'Recolor';
      case 'history':
        return 'History';
    }
  }

  private enableCustomAssetViewer: boolean = false; // org managed - can update in ADMIN app.
  public useVibeViewer = true; // if enableCustomAssetViewer is false, useVibeViewer must be TRUE

  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private contentService: ContentService,
    private contentHolderService: ContentHolderService,
    private featureFlagService: FeatureFlagRegistryService,
  ) {
    this.enableCustomAssetViewer = this.authService.getCurrentOrg().orgConfig?.enableCustomAssetViewer;
  }

  ngOnInit(): void {
    this.useVibeViewer =
      this.enableCustomAssetViewer && this.content?.embedLink && !this.content?.useVibeViewer ? false : true;

    this.hasShowroom$ = this.featureFlagService.getFeatureObservable(Feature.SHOWROOM);
    this.subs.push(
      this.hasShowroom$
        .pipe(
          tap((val) => {
            if (val) {
              this.sideNavOptions.push({ label: 'config', svgIcon: 'showroom_icon' });
            }
          }),
        )
        .subscribe(),
    );
    this.contentToDisplay = this.content;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  colorChange(evt) {
    // console.log('color picker', evt)
    this.color = evt;
    this.webglView.recolorAsset(this.color);
  }

  async handleScreenShot(blobUrl: URL) {
    this.contentService.handleScreenCapture(this.content, blobUrl);
  }

  download() {
    this.contentService.downloadContent(this.content);
  }

  closeHistory() {
    delete this.historicalContent;
    this.contentToDisplay = this.content;
  }

  async loadHistoricalModel(historicalContent: any) {
    const content = historicalContent.entity;
    console.log('loading historical model: ', content);

    const primaryFile = await new Entities().get({ entityName: 'file', id: content.primaryFileId });
    content.primaryFile = primaryFile;
    this.historicalContent = historicalContent;
    this.contentToDisplay = content;
  }

  updateContent3DViewOption(content) {
    this.useVibeViewer = content?.useVibeViewer;
    this.contentHolderService.updateContent3DViewOption(content);
  }
}
