import { CanvasDocument } from '../../../canvas-document';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { StickyNoteEditor, STICKY_FONT_SIZE, STICKY_ALIGN, STICKY_FONT_FAMILY } from './sticky-note-editor';
import { StickyNoteEditorContainer } from './sticky-note-editor-container';
import { StickyNoteEditorPlaceholder } from './sticky-note-editor-placeholder';

export class StickyNoteEditorManager extends EditorManager {
  constructor(canvasDocument: CanvasDocument) {
    super(canvasDocument, { hideElement: false }, { paste_as_text: true });
    this.editorContainer = new StickyNoteEditorContainer(canvasDocument, this);
    this.editor = new StickyNoteEditor(canvasDocument, this);
    this.editorPlaceholder = new StickyNoteEditorPlaceholder(canvasDocument, this);
  }

  public getMaxFontSize(canvasElement: CanvasElement) {
    const target = this.canvasDocument.editorHandler.editorCalc.container as HTMLElement;
    if (!target) return null;
    const element = canvasElement.elementDefinition;
    const currentContainerHeight = element.size.height;
    const currentContainerWidth = element.size.width;
    target.style.width = `${element.size.width - canvasElement.TEXT_PADDING * 2}px`;
    target.style.height = `auto`;
    target.style.fontFamily = element?.style?.font?.family || STICKY_FONT_FAMILY;
    target.style.fontSize = (element?.style?.font?.size || STICKY_FONT_SIZE) + 'px';
    target.style.textAlign = element?.style?.text?.align || STICKY_ALIGN;
    target.style.overflowWrap = 'normal';
    target.style.wordBreak = 'normal';
    target.innerHTML = element.text;
    let newFontSize = StickyNoteEditorContainer.calculateNewFontSize(
      target,
      element.text,
      currentContainerHeight - canvasElement.TEXT_PADDING * 2,
      currentContainerWidth - canvasElement.TEXT_PADDING * 2,
    );
    return newFontSize;
  }
}
