export interface ChooserSourceOption {
  workspaceId?: string;
  sourceType: ChooserSourceOptionTypes;
  entityReference?: string;
  name: string;
  id?: string;
  icon?: string;
  class?: string;
}

export enum ChooserSourceOptionTypes {
  ITEM_LIBRARY = 'ITEM_LIBRARY',
  ASSORTMENT = 'ASSORTMENT',
  SOURCE_ASSORTMENT = 'SOURCE_ASSORTMENT',
  DOCUMENT = 'DOCUMENT',
  RECENT_DOCUMENT = 'RECENT_DOCUMENT',
  CLIPBOARD = 'CLIPBOARD',
}
