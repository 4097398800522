import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { editorModeReducers } from './editor-mode.reducers';
import { EffectsModule } from '@ngrx/effects';
import { EditorModeEffects } from './editor-mode.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('editorMode', editorModeReducers),
    EffectsModule.forFeature([EditorModeEffects]),
  ],
})
export class EditorModeStoreModule {}
