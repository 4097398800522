import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { RootStoreState } from 'src/app/root-store';
import { HotSpotService } from '../hot-spot.service';

@Component({
  selector: 'app-add-hot-spot-modal',
  templateUrl: './add-hot-spot-modal.component.html',
  styleUrls: ['./add-hot-spot-modal.component.scss'],
})
export class AddHotSpotModalComponent {
  constructor(
    private hotSpotService: HotSpotService,
    public dialogRef: MatDialogRef<AddHotSpotModalComponent>,
  ) {}

  select(entity: any) {
    this.hotSpotService.addHotSpot(entity);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  getImage(itemData) {
    return itemData.properties.thumbnail;
  }
}
