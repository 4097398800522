<div class="w-full h-full flex flex-col component-editor" data-test="component-editor-component">
  <div class="header !mb-0">
    <div class="title" *ngIf="selectedElements?.length as length">
      <!-- Item card | Multiple item cards (2) | Color card | Multiple color cards (2) -->
      <ng-container *ngIf="length === 1">
        {{ (currentComponentType | titlecase) + ' card' }}
      </ng-container>
      <ng-container *ngIf="length > 1">
        {{ 'Multiple ' + (currentComponentType | lowercase) + ' cards (' + length + ')' }}
      </ng-container>
    </div>
    <mat-icon (click)="hide()">close</mat-icon>
  </div>
  <!-- <app-component-editor-preview *ngIf="currentComponentElement.type==='component'" /> -->

  <mat-tab-group
    class="w-full h-full"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    [selectedIndex]="selectedTab"
    (selectedIndexChange)="selectedTab = $event"
  >
    <mat-tab label="Format options">
      <div *ngIf="currentComponentElement?.type === 'component'" class="overflow-x-hidden grow h-[calc(100%-120px)]">
        <mat-expansion-panel class="mat-elevation-z0" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title class="text-sm font-normal"> Presets </mat-panel-title>
          </mat-expansion-panel-header>
          <app-property-view-definition-builder
            *ngIf="viewDefinitionApplicationViewSlug"
            class="-mt-3 -mb-4"
            #viewDefinitionBuilder
            (propertiesChangedEvent)="handleComponentPropertyChange($event)"
            [viewId]="viewId"
            [viewDefinitionApplicationViewSlug]="viewDefinitionApplicationViewSlug"
            [selectedProperties]="elements"
            [componentStyle]="currentComponentElement.style"
          />
        </mat-expansion-panel>
        <mat-divider></mat-divider>

        <div class="properties">
          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-sm font-normal"> Thumbnail </mat-panel-title>
            </mat-expansion-panel-header>
            <app-component-editor-thumbnail
              (componentChangedEvent)="syncComponent($event)"
              (propertiesChangedEvent)="syncProperties($event)"
              [multipleElements]="multipleElements"
            />
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-sm font-normal"> Properties </mat-panel-title>
            </mat-expansion-panel-header>
            <app-component-editor-property-selector
              #propertySelector
              (addProp)="addProperty = true"
              (propertiesChangedEvent)="syncProperties($event, 'properties')"
            />
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-sm font-normal"> Font </mat-panel-title>
            </mat-expansion-panel-header>
            <app-component-editor-font
              (clear)="fontClear($event)"
              (fontChanged)="fontChanged($event)"
              (propertiesChangedEvent)="syncProperties($event)"
            />
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-sm font-normal"> Text Options </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-between-center text-black60">
              <span>Alignment</span>
              <app-property-configurator-text-alignment
                (valueChange)="updateTextProperties($event)"
                [currentValue]="getCurrentValue('style.text.align')"
                [layout]="'list'"
              />
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-sm font-normal"> Border </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="flex text-black60">
              <app-property-configurator-color-picker
                class="basis-1/6"
                matTooltip="Border color"
                (valueChange)="updateComponentBorder($event)"
                [currentValue]="currentComponentElement.style?.border?.color"
                [matIconType]="'border_color'"
                [colorType]="'borderColor'"
              />
              <app-property-configurator-border-size
                class="basis-1/6"
                matTooltip="Border weight"
                (valueChange)="updateComponentBorder($event)"
                [currentValue]="currentComponentElement.style?.border?.width"
              />
              <app-property-configurator-border-style
                class="basis-1/6"
                matTooltip="Border style"
                (valueChange)="updateComponentBorder($event)"
                [currentValue]="currentComponentElement.style?.border?.style"
              />
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="format-option-buttons">
        <button mat-raised-button color="primary" (click)="createPreset()" data-test="component-editor-save-preset">
          SAVE AS PRESET
        </button>
        <button mat-raised-button color="primary" (click)="applyChangesToAll()" data-test="component-editor-apply-all">
          APPLY TO ALL
        </button>
      </div>
    </mat-tab>

    <mat-tab label="Properties" *ngIf="editEntitiesFeatureFlag" [disabled]="selectedElements?.length > 50">
      <div class="h-full" *ngIf="currentComponentType">
        <app-component-entity-editor
          class="h-full"
          [selectedProperties]="elements"
          [componentType]="currentComponentType"
        />
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
