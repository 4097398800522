import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions } from '../comments-store';
import { Comment, CommentOwnerInfo, CommentsService } from '../comments.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-comments-card',
  templateUrl: './comments-card.component.html',
  styleUrls: ['./comments-card.component.scss'],
})
export class CommentsCardComponent implements OnChanges {
  constructor(
    private commentsService: CommentsService,
    private store: Store<RootStoreState.State>,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
  ) {}

  @Input() comment: Comment;
  @Input() showOwnerName: boolean;
  @Input() ownerInfo: CommentOwnerInfo;
  @Input() accessLevel = 'EDIT';
  @Input() disableShowComment: boolean = false;

  public editingComment = false;
  public deletingComment = false;
  public canUpdate = false;
  public canDelete = false;

  public allowApproveComment = false;
  public allowRejectComment = false;
  public markedAsResolved = false;

  public changeSuggestionSlugProperty: TypeProperty;

  public ownedByName: string;

  public formattedText: SafeHtml;

  async ngOnChanges() {
    if (this.comment?.ownedBy?.optionName) {
      this.ownedByName = `${this.comment.ownedBy.name} / ${this.comment.ownedBy.optionName}`;
    } else if (this.comment?.ownedBy) {
      this.ownedByName = this.comment?.ownedBy?.name;
    }

    if (this.comment?.changeSuggestion?.changeDetails?.propertySlug) {
      this.changeSuggestionSlugProperty = await this.getProperty(
        this.comment.changeSuggestion.changeDetails.propertySlug,
      );
    }
    this.canDelete = this.commentsService.canDeleteComment(this.comment, this.accessLevel);
    this.canUpdate = this.commentsService.canUpdateComment(this.comment, this.accessLevel);

    this.allowApproveComment = this.canApproveComment();
    this.allowRejectComment = this.canRejectComment();
    this.markedAsResolved = this.comment.status === 'closed' ? true : false;

    this.computeFormattedText();
  }

  private computeFormattedText() {
    if (!this.comment?.text) {
      this.formattedText = '';
    }
    const formatted = this.styleAllMentions(this.comment.text);
    this.formattedText = formatted;
  }

  private styleAllMentions(text: string) {
    if (!text) {
      return;
    }
    const mentionRegEx = /@([^\s]+)/g;
    const styleMentionReplace = '<span style="color:#2196f3">$&</span>';
    const formatted = text.replace(mentionRegEx, styleMentionReplace);
    // console.log('formatted text went from ', text, 'and is now:', formatted)
    return formatted;
  }

  cancelUpdate() {
    this.editingComment = false;
  }
  updateComment() {
    this.editingComment = true;
  }
  canApproveComment() {
    if (this.accessLevel !== 'EDIT') {
      return false;
    }
    return this.comment.status === 'open' || this.comment.changeSuggestion?.changeStatus === 'pending' ? true : false;
  }
  canRejectComment() {
    if (this.accessLevel !== 'EDIT') {
      return false;
    }
    return this.comment.changeSuggestion?.changeStatus === 'pending' ? true : false;
  }

  async reopen() {
    await this.store.dispatch(CommentsActions.reopenComment({ comment: this.comment }));
  }
  startDelete() {
    const that = this;
    setTimeout(() => {
      that.deletingComment = true;
    }, 100);
  }

  async delete() {
    await this.store.dispatch(CommentsActions.deleteComment({ comment: this.comment }));
    this.deletingComment = false;
  }

  // WARNING: This code was causing a serious performance issue on click in the app.
  // @HostListener('document:click', ['$event'])
  // clickout(event) {

  //   console.log('comments host listener...');
  //   if (!this.deletingComment) {
  //     return;
  //   }
  //   if (this.elementRef.nativeElement.contains(event.target)) {
  //   } else {
  //     // IF YOU CLICK OFF THE COMMENT, CLOSE THE CONFIRM BOX
  //     this.deletingComment = false;
  //   }
  // }

  async rejectComment() {
    console.log(`dispatching reject comment action`);
    this.store.dispatch(CommentsActions.rejectComment({ comment: this.comment }));
  }

  async approveComment() {
    console.log(`dispatching approve comment action`);
    this.store.dispatch(CommentsActions.acceptComment({ comment: this.comment }));
  }

  public async getProperty(propertySlug) {
    if (!propertySlug) {
      return;
    }
    return await this.commentsService.getProperty(this.comment, propertySlug);
  }

  handleClick(event) {
    event.stopPropagation();

    if (this.disableShowComment || this.comment.status === 'closed') {
      return;
    }
    const ignoreClickOnElementTags = ['BUTTON', 'MAT-ICON', 'TEXTAREA'];
    if (
      ignoreClickOnElementTags.indexOf(event.target.tagName) !== -1 ||
      ignoreClickOnElementTags.indexOf(event.target.parentElement.tagName) !== -1
    ) {
      return;
    }
    this.store.dispatch(CommentsActions.selectComment({ comment: this.comment }));
  }
}
