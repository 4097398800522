import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class EntitySnapshotService {
  public async getEntitySnapshotById(id: string) {
    const snapshot = await new Entities().get({ entityName: 'entity-snapshot', id });
    if (snapshot.snapshotDownloadURL) {
      const response = await fetch(snapshot.snapshotDownloadURL);
      const snapshotDetails = await response.json();
      snapshot.snapshot = snapshotDetails;
    }
    return snapshot;
  }

  public async getEntitySnapshotsByEntityReference(entityReference: string) {
    return new Entities().get({
      entityName: 'entity-snapshot',
      criteria: { entityReference },
      relations: ['createdBy'],
    });
  }
}
