import { CanvasDocument } from '../../canvas-document';
import { LineDefinition, ViewBox } from '@contrail/documents';
import { CanvasUtil } from '../../canvas-util';

export class DraggingBoxRenderer {
  public draggingBox: ViewBox;
  public draggingLine: LineDefinition;

  private readonly STROKE_WIDTH = 1;
  private readonly STROKE_COLOR = '#808080';

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw selection boxes for selected elements
   */
  public draw() {
    const selectBox = this.draggingBox;
    const draggingLine = this.draggingLine;
    if (selectBox) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const { x, y, width, height } = selectBox;
      const size = this.canvasDocument.toDocumentSize(width, height);
      const position = this.canvasDocument.toDocumentPosition(x, y);
      ctx.beginPath();
      ctx.rect(position.x, position.y, size.width, size.height);
      ctx.strokeStyle = this.STROKE_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.closePath();
    } else if (draggingLine) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const { x1, y1, x2, y2 } = draggingLine;
      const p1 = this.canvasDocument.toDocumentPosition(x1, y1);
      const p2 = this.canvasDocument.toDocumentPosition(x2, y2);

      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.strokeStyle = this.STROKE_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.closePath();
    }
  }
}
