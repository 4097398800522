import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { withLatestFrom, tap } from 'rxjs/operators';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DocumentItemService } from '../document-item.service';
import { DocumentColorService } from '../../document-color/document-color.service';
import { DocumentModelEntitiesActionTypes } from './document-model-entities.actions';
import * as DocumentModelEntitiesActions from './document-model-entities.actions';

@Injectable()
export class DocumentModelEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private documentItemService: DocumentItemService,
    private documentColorService: DocumentColorService,
    private snackBar: MatSnackBar,
  ) {}

  private readonly ERROR_MESSAGE =
    'Your request could not be completed. Please try again or refresh your browser to continue.';

  private handleError = (error) => {
    this.snackBar.open(this.ERROR_MESSAGE, '', { duration: 5000 });
  };

  handleUpdateDocumentModelEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentModelEntitiesActionTypes.BATCH_APPLY_DOCUMENT_MODEL_ENTITY_CHANGES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const documentModelEntities = [];
          // special handling for item-families
          const itemChanges = action.changes.filter((change) => change.changeDefinition.entityType === 'item');
          const projectItemChanges = action.changes.filter(
            (change) => change.changeDefinition.entityType === 'project-item',
          );
          const colorChanges = action.changes.filter((change) => change.changeDefinition.entityType === 'color');

          const entities = Object.values(store.document.documentModelEntities.entities);
          itemChanges.forEach((change) => {
            const item = store.document.documentModelEntities.entities[change.changeDefinition.entityId];
            if (store.document.documentModelEntities.ids.length > 0 && !item) {
              // this is an item-family update if item is not found
              entities
                .filter((e) => e.itemFamilyId === change.changeDefinition.entityId)
                .forEach((e) => {
                  documentModelEntities.push({
                    id: e.id,
                    changes: { itemFamily: Object.assign({}, e.itemFamily, change.changeDefinition.entityData) },
                  });
                });
            } else {
              documentModelEntities.push({
                id: change.changeDefinition.entityId,
                changes: change.changeDefinition.entityData,
              });
            }
          });
          projectItemChanges.forEach((change) => {
            documentModelEntities.push({
              id: change.changeDefinition.entityId,
              changes: change.changeDefinition.entityData,
            });
          });
          colorChanges.forEach((change) => {
            documentModelEntities.push({
              id: change.changeDefinition.entityId,
              changes: change.changeDefinition.entityData,
            });
          });
          this.store.dispatch(DocumentModelEntitiesActions.updateDocumentModelEntities({ documentModelEntities }));
          this.handleModifyEntityActions(action.changes);
        }),
      ),
    { dispatch: false },
  );

  private async handleModifyEntityActions(actions: any[]) {
    const itemChanges = actions.filter((a) => a.changeDefinition.entityType === 'item');
    const projectItemChanges = actions.filter((a) => a.changeDefinition.entityType === 'project-item');
    const assortmentItemChanges = actions.filter((a) => a.changeDefinition.entityType === 'assortment-item');
    let updateSuccessful = false;
    let updatedItems;
    if (itemChanges.length > 0) {
      updatedItems = await this.documentItemService
        .performComponentEntityUpdates(itemChanges, 'item')
        .catch((error) => {
          this.handleError(error);
        });
      if (updatedItems) {
        updateSuccessful = true;
      }
    }
    if (projectItemChanges.length > 0) {
      updatedItems = await this.documentItemService
        .performComponentEntityUpdates(projectItemChanges, 'project-item')
        .catch((error) => {
          this.handleError(error);
        });
      if (updatedItems) {
        updateSuccessful = true;
      }
    }
    if (assortmentItemChanges.length > 0) {
      updatedItems = await this.documentItemService
        .performComponentEntityUpdates(assortmentItemChanges, 'assortment-item')
        .catch((error) => {
          this.handleError(error);
        });
      if (updatedItems) {
        updateSuccessful = true;
      }
    }
    if (updateSuccessful) {
      this.snackBar.open(actions[0].changeDefinition.componentElementCount + ' item(s) updated', '', {
        duration: 5000,
      });
    }

    updateSuccessful = false;
    updatedItems = null;
    const colorChanges = actions.filter((a) => a.changeDefinition.entityType === 'color');
    if (colorChanges.length > 0) {
      const updatedColors = await this.documentColorService
        .performComponentEntityUpdates(colorChanges, 'color')
        .catch((error) => {
          this.handleError(error);
        });
      if (updatedColors) {
        updateSuccessful = true;
      }
    }
    if (updateSuccessful) {
      this.snackBar.open(actions[0].changeDefinition.componentElementCount + ' color(s) updated', '', {
        duration: 5000,
      });
    }
  }
}
