import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-source-change-history-filters',
  templateUrl: './source-change-history-filters.component.html',
  styleUrls: ['./source-change-history-filters.component.scss'],
})
export class SourceChangeHistoryFiltersComponent implements OnInit, OnDestroy {
  public typeFormControl = new UntypedFormControl();
  public typeFormControlSubscription: Subscription;
  public filterValues: any = {};
  @Output() public filterChanges = new EventEmitter();

  constructor() {}
  public dateFilterDefinition = {
    label: 'Date Range',
    attribute: 'dateRange',
  };
  ngOnInit(): void {
    this.typeFormControl.setValue('ALL');
    this.typeFormControlSubscription = this.typeFormControl.valueChanges.subscribe((value) => {
      console.log('type value change: ', value);
      this.filterValues.type = value;
      this.filterChanges.emit(this.filterValues);
    });
  }
  dateFilterChanged($event) {
    console.log('dateFilterChanged: ', $event);
    this.filterValues.dateRange = $event;
    this.filterChanges.emit(this.filterValues);
  }
  ngOnDestroy(): void {
    if (this.typeFormControlSubscription) {
      this.typeFormControlSubscription.unsubscribe();
    }
  }
}
