import { AuthSelectors } from '@common/auth/auth-store';
import { OrgMembership } from '@common/auth/auth.service';
import { GetContentPolicies } from '@common/content/content-policy-helper';
import { Entities, Files } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { nanoid } from 'nanoid';

export const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
export const SUPPORTED_FILE_TYPES = ['application/postscript', 'application/pdf'];
export const FILE_MAX_SIZE = 40000000; // 40mb
export const FILE_MAX_SIZE_ERROR_MESSAGE =
  'Image upload failed. Files must be smaller than 40 MB. Please try again with a smaller image.';

export class DocumentFileService {
  private currentOrg: OrgMembership;
  private ownerReferenceEntity;

  constructor(
    private store: Store<RootStoreState.State>,
    private ownerReference: string,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentOrg = authContext?.currentOrg;
    });
    this.loadOwnerReference();
  }

  private async loadOwnerReference() {
    if (this.ownerReference) {
      this.ownerReferenceEntity = await new Entities().get({
        reference: this.ownerReference,
      });
    }
  }

  public static async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  public async createFile(file: File, fileId?, ttl?) {
    let entityPolicyIds;
    if (this.ownerReferenceEntity) {
      entityPolicyIds = await GetContentPolicies(this.ownerReferenceEntity, this.currentOrg);
    }

    const object: any = {
      fileName: file.name,
      contentType: file.type,
      ownedByReference: this.ownerReference,
      entityPolicyIds,
    };
    if (fileId) {
      object.specifiedId = fileId;
    }
    if (ttl) {
      object.ttl = ttl;
    }
    return await new Entities().create({
      entityName: 'file',
      object,
    });
  }

  public async createContent(file: File, contentId?, owner?) {
    const entityPolicyIds = await GetContentPolicies(owner ?? this.ownerReferenceEntity, this.currentOrg);

    let fileType = file.type;
    if (!fileType && file?.name?.endsWith('.glb')) {
      fileType = 'model/gltf-binary';
    }
    const object: any = {
      fileName: file.name,
      contentType: fileType,
      contentHolderReference: this.ownerReference,
      entityPolicyIds,
    };
    if (contentId) {
      object.specifiedId = contentId;
    }
    return await new Entities().create({
      entityName: 'content',
      object,
    });
  }

  public async uploadFile(uploadPost, file: File) {
    return await new Files().uploadFile(uploadPost, file);
  }

  public async createAndUploadFile(file: File, fileId?, ttl?) {
    const fileEntity = await this.createFile(file, fileId, ttl);
    await this.uploadFile(fileEntity.uploadPost, file);
    return fileEntity;
  }

  public async createAndUploadContent(file: File, contentId?, owner?) {
    const contentEntity = await this.createContent(file, contentId, owner);
    await this.uploadFile(contentEntity.primaryFile.uploadPost, file);
    return contentEntity;
  }

  public async updateContent(id: string, file: File) {
    const fileId = nanoid(16);
    const fileEntity = await this.createAndUploadFile(file, fileId);

    await new Entities().update({
      entityName: 'content',
      id,
      object: {
        primaryFileId: fileId,
      },
    });

    return fileEntity;
  }

  public async createAndUploadJSONFile(jsonString: string, fileName, ttl?) {
    const blob = new Blob([jsonString], { type: 'application/json' });
    const file = new File([blob], `${fileName}.json`, {
      type: 'application/json',
    });
    return await this.createAndUploadFile(file, null, ttl);
  }

  public static isSupportedFile(file: File) {
    return (
      SUPPORTED_IMAGE_TYPES.indexOf(file.type) > -1 ||
      SUPPORTED_FILE_TYPES.indexOf(file.type) > -1 ||
      file.name.endsWith('.glb') ||
      file.name.endsWith('.gltf')
    );
  }

  public static getUnsupportedFilesMessage(filesCount, unsupportedFilesCount) {
    if (filesCount > 0 && unsupportedFilesCount > 0) {
      const message =
        filesCount === unsupportedFilesCount
          ? 'File type not supported, please convert it to .JPEG or .PNG and try again.'
          : 'Some of the file types are not supported, please convert to .JPEG or .PNG and try again.';
      return message;
    }
    return null;
  }
}
