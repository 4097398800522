export class TableRange {
  constructor(
    public startRow: number,
    public endRow: number,
    public startColumn: number,
    public endColumn: number,
  ) {}

  start(): TableRange {
    return new TableRange(this.startRow, this.startRow, this.startColumn, this.startColumn);
  }

  // count of rows contained in this range
  get rows(): number {
    return this.endRow - this.startRow;
  }

  // count of columns contained in this range
  get cols(): number {
    return this.endColumn - this.startColumn;
  }

  singular(): boolean {
    return this.cols === 0 && this.rows === 0;
  }

  eachRow(cb: (index: number) => void, max?: number): TableRange {
    let { endRow } = this;
    if (max && endRow > max) endRow = max;
    for (let row = this.startRow; row <= endRow; row += 1) {
      cb(row);
    }
    return this;
  }

  eachColumn(cb: (index: number) => void, max?: number): TableRange {
    let { endColumn } = this;
    if (max && endColumn > max) endColumn = max;
    for (let col = this.startColumn; col <= endColumn; col += 1) {
      cb(col);
    }
    return this;
  }

  each(cb: (rowIndex: number, columnIndex: number) => void): TableRange {
    this.eachRow((row) => {
      this.eachColumn((col) => cb(row, col));
    });
    return this;
  }

  union(other: TableRange): TableRange {
    return new TableRange(
      other.startRow < this.startRow ? other.startRow : this.startRow,
      other.endRow > this.endRow ? other.endRow : this.endRow,
      other.startColumn < this.startColumn ? other.startColumn : this.startColumn,
      other.endColumn > this.endColumn ? other.endColumn : this.endColumn,
    );
  }

  intersection(other: TableRange): TableRange {
    return new TableRange(
      other.startRow < this.startRow ? this.startRow : other.startRow,
      other.endRow > this.endRow ? this.endRow : other.endRow,
      other.startColumn < this.startColumn ? this.startColumn : other.startColumn,
      other.endColumn > this.endColumn ? this.endColumn : other.endColumn,
    );
  }

  touches(other: TableRange): boolean {
    return (
      (other.startRow === this.startRow &&
        other.endRow === this.endRow &&
        (other.endColumn + 1 === this.startColumn || this.endColumn + 1 === other.startColumn)) ||
      (other.startColumn === this.startColumn &&
        other.endColumn === this.endColumn &&
        (other.endRow + 1 === this.startRow || this.endRow + 1 === other.startRow))
    );
  }

  /**
   * check whether or not the range within the other range
   * @param {Range} other
   * @returns {boolean}
   */
  within(other: TableRange): boolean {
    return (
      this.startRow >= other.startRow &&
      this.endRow <= other.endRow &&
      this.startColumn >= other.startColumn &&
      this.endColumn <= other.endColumn
    );
  }

  equals(other: TableRange): boolean {
    return (
      this.startRow === other.startRow &&
      this.endRow === other.endRow &&
      this.startColumn === other.startColumn &&
      this.endColumn === other.endColumn
    );
  }

  difference(range: TableRange): TableRange[] {
    const ret: TableRange[] = [];
    if (!range.within(this)) return ret;
    const { startRow, endRow, startColumn, endColumn } = this;
    return [
      new TableRange(startRow, range.startRow - 1, startColumn, endColumn), // top
      new TableRange(range.endRow + 1, endRow, startColumn, endColumn), // bottom
      new TableRange(range.startRow, range.endRow, startColumn, range.startColumn - 1), // left
      new TableRange(range.startRow, range.endRow, range.endColumn + 1, endColumn), // right
    ].filter((it) => it.rows >= 0 && it.cols >= 0);
  }
}
