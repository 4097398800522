import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../canvas-document';
import { CanvasElement } from '../elements/canvas-element';
import { CanvasImageDrawableElement } from '../elements/image/canvas-image-drawable-element';

export class CanvasCropState {
  public isEditingCrop: string | boolean = false;
  private startingElement: DocumentElement;
  private selectedElement: CanvasElement;
  constructor(private canvasDocument: CanvasDocument) {}

  public startCrop(element: CanvasElement) {
    if (element?.isCropEnabled && !element?.elementDefinition?.isLocked && element.isSelected) {
      const imageElement = element as CanvasImageDrawableElement;
      if (!imageElement?.imageSize || imageElement?.isImageError) {
        return;
      }
      console.log('CanvasCropState.startCrop', element);
      this.isEditingCrop = element.id;
      this.startingElement = ObjectUtil.cloneDeep(element.elementDefinition);
      this.selectedElement = element;
      this.canvasDocument.draw();
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: element.elementDefinition,
        selectedElements: [this.selectedElement.elementDefinition],
        eventType: 'cropStarted',
      });
    }
  }

  public stopCrop() {
    if (this.isEditingCrop) {
      this.isEditingCrop = false;
      this.canvasDocument.draw();
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: null,
        eventType: 'cropEnded',
      });
      this.selectedElement = null;
      this.startingElement = null;
    }
  }

  public cancelCrop() {
    if (this.startingElement && this.selectedElement) {
      this.selectedElement.elementDefinition = ObjectUtil.cloneDeep(this.startingElement);
    }
    this.stopCrop();
  }

  public submitCrop() {
    if (this.isEditingCrop) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        [
          {
            id: this.selectedElement.id,
            cropDefinition: ObjectUtil.cloneDeep(this.selectedElement.elementDefinition.cropDefinition),
            position: ObjectUtil.cloneDeep(this.selectedElement.elementDefinition.position),
            size: ObjectUtil.cloneDeep(this.selectedElement.elementDefinition.size),
          },
        ],
        [
          {
            id: this.selectedElement.id,
            cropDefinition: ObjectUtil.cloneDeep(this.startingElement.cropDefinition),
            position: ObjectUtil.cloneDeep(this.startingElement.position),
            size: ObjectUtil.cloneDeep(this.startingElement.size),
          },
        ],
      );
      this.stopCrop();
    }
  }

  public isCropping(elementId: string) {
    return this.isEditingCrop && this.isEditingCrop === elementId;
  }
}
