import { PositionDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../../canvas-document';
import { CanvasUtil } from '../../../../canvas-util';
import { CanvasElement } from '../../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { Frame } from '../../../../state/canvas-frame-state';
import { DistanceGuidelineHandlerService as service, GuidelineDimension } from './distance-guideline-handler.service';
import { GuidelineHandlerService } from './guideline-handler.service';

export class DistanceGuidelineHandler {
  private guidelineService: GuidelineHandlerService;
  private selectedElements: CanvasElement[];
  private selectedElementsByFrame: CanvasElement[];
  private otherElementDimensions: GuidelineDimension[] = [];
  private snapPosition: PositionDefinition;
  private deltaPositions: Map<string, PositionDefinition> = new Map();
  private frames: Map<string, Frame>;
  private margin: number;
  public hasSnapped = false;

  constructor(private canvasDocument: CanvasDocument) {
    this.guidelineService = new GuidelineHandlerService(this.canvasDocument);
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect()) return;

    const { element, target } = elementTarget;
    if (element && [DRAG_DIRECTIONS.BODY, DRAG_DIRECTIONS.EDIT].indexOf(target) !== -1) {
      const selectedElements = this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedElementsForUpdate();
      if ((selectedElements?.length === 1 && !element.isRotated()) || selectedElements?.length > 1) {
        const onlyFramesInElements = this.canvasDocument?.onlyFramesInElements(
          selectedElements.map((e) => e.elementDefinition),
        );
        this.selectedElements = selectedElements;
        this.selectedElementsByFrame = this.canvasDocument.interactionHandler?.selectionHandler
          ?.getSelectedElementsByFrame()
          ?.filter((e) => !e.elementDefinition.isLocked);
        this.canvasDocument.canvasRenderer.guidelinesRenderer.distanceGuidelinesx = [];
        this.canvasDocument.canvasRenderer.guidelinesRenderer.distanceGuidelinesy = [];
        this.otherElementDimensions = this.guidelineService
          .getOtherElements(this.selectedElements)
          .filter((e) => (onlyFramesInElements ? e.elementDefinition.type === 'frame' : true))
          .map((element) =>
            element.getBoundingClientRectRotated({ maskedDimensions: true, outerEdgeDimensions: true }),
          );
        this.margin = this.canvasDocument.getScaledValue(5);
        this.frames = this.canvasDocument.state.frameState.frames;
        const box = this.getBox();
        this.selectedElements?.forEach((element) => {
          const position = element.getPosition();
          this.deltaPositions.set(element.elementDefinition.id, {
            x: position.x - box.x - element.PADDING_LEFT,
            y: position.y - box.y - element.PADDING_TOP,
          });
        });
      }
    }
  }

  public dragged(event) {
    if (!this.selectedElements?.length) return;
    this.hasSnapped = false;
    this.addGuidelines();
  }

  private addGuidelines() {
    this.addGuidelineByDirection('x');
    this.addGuidelineByDirection('y');
  }

  private addGuidelineByDirection(direction) {
    this.drawOrRemoveDistanceGuidelines(direction);
  }

  private getBox() {
    return this.selectedElementsByFrame.length === 1
      ? this.selectedElementsByFrame[0].getBoundingClientRect({ outerEdgeDimensions: true })
      : this.canvasDocument.state.getCommonBounds(this.selectedElementsByFrame, { outerEdgeDimensions: true });
  }

  private drawOrRemoveDistanceGuidelines(direction: 'x' | 'y') {
    this.canvasDocument.canvasRenderer.guidelinesRenderer['distanceGuidelines' + direction] = [];

    const targetElementDimensions = { ...this.getBox() };

    if (
      this.snapPosition &&
      (direction === 'x'
        ? CanvasUtil.isWithinMargin(targetElementDimensions.x, this.snapPosition.x, this.margin)
        : CanvasUtil.isWithinMargin(targetElementDimensions.y, this.snapPosition.y, this.margin))
    ) {
      targetElementDimensions.x = this.snapPosition.x;
      targetElementDimensions.y = this.snapPosition.y;
    }

    this.snapPosition = null;

    const { beforeElements, afterElements } = service.getSameAxisElementCoordinates(
      targetElementDimensions,
      this.otherElementDimensions,
      direction,
    );
    // console.log('elements', beforeElements, afterElements, targetElementDimensions)

    let sameDistance: GuidelineDimension[] = [];

    // Closest element before target
    const beforeElement = beforeElements[0];
    // Distance from target to closest element before target
    const distanceToBeforeElement =
      beforeElement && service.getDistance(targetElementDimensions, beforeElement, direction);
    // Distance from closest element before target to next closest element
    const beforeDistance =
      distanceToBeforeElement &&
      beforeElement?.distance &&
      CanvasUtil.isWithinMargin(distanceToBeforeElement, beforeElement?.distance, this.margin)
        ? beforeElement?.distance
        : null;

    const afterElement = afterElements[0];
    const distanceToAfterElement =
      afterElement && service.getDistance(targetElementDimensions, afterElement, direction);
    const afterDistance =
      distanceToAfterElement &&
      afterElement?.distance &&
      CanvasUtil.isWithinMargin(distanceToAfterElement, afterElement?.distance, this.margin)
        ? afterElement?.distance
        : null;

    let distance;
    // distanceToBeforeElement is the same as distance from closest element to next closest element
    // Show guides for distanceToBeforeElement and for all elements before target that have the same distance
    if (beforeDistance) {
      distance = beforeElement.distance;
      sameDistance = sameDistance.concat(
        beforeElements.filter((element: GuidelineDimension) => element?.distance === beforeElement.distance),
      );
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          beforeElement,
          targetElementDimensions,
          beforeElement.distance,
          direction,
          'before',
        ),
      });
    }

    if (
      afterDistance &&
      (!beforeDistance || (beforeElement?.distance != null && beforeElement?.distance === afterElement?.distance))
    ) {
      distance = afterElement.distance;
      sameDistance = sameDistance.concat(
        afterElements.filter((element: GuidelineDimension) => element?.distance === afterElement.distance),
      );
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          afterElement,
          targetElementDimensions,
          afterElement.distance,
          direction,
          'after',
        ),
      });
    }

    // distanceToBeforeElement is the same as distance from closest element to next closest element
    // and distanceToAfterElement is also the same
    if (
      beforeDistance &&
      !afterDistance &&
      distanceToAfterElement &&
      beforeElement.distance === distanceToAfterElement
    ) {
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          afterElement,
          targetElementDimensions,
          beforeElement.distance,
          direction,
          'after',
        ),
      });
    }

    if (
      afterDistance &&
      !beforeDistance &&
      distanceToBeforeElement &&
      afterElement.distance === distanceToBeforeElement
    ) {
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          beforeElement,
          targetElementDimensions,
          afterElement.distance,
          direction,
          'before',
        ),
      });
    }

    // Distance in between two elements
    let betweenDistance;
    if (
      !beforeDistance &&
      !afterDistance &&
      distanceToBeforeElement &&
      distanceToAfterElement &&
      CanvasUtil.isWithinMargin(distanceToBeforeElement, distanceToAfterElement, this.margin)
    ) {
      betweenDistance =
        direction === 'x'
          ? (afterElement.x - (beforeElement.x + beforeElement.width) - targetElementDimensions.width) * 0.5
          : (afterElement.y - (beforeElement.y + beforeElement.height) - targetElementDimensions.height) * 0.5;
      distance = betweenDistance;
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          beforeElement,
          targetElementDimensions,
          distance,
          direction,
          'before',
        ),
      });
      sameDistance.push({
        lineDefinition: service.getDistanceLineDefinition(
          afterElement,
          targetElementDimensions,
          distance,
          direction,
          'after',
        ),
      });
    }

    // console.log('before', beforeDistance, distanceToBeforeElement, beforeElement);
    // console.log('after', afterDistance, distanceToAfterElement, afterElement);
    // console.log('between', betweenDistance, distance);
    // console.log('same distance', sameDistance)

    if (distance) {
      const currentDimension = this.getBox();
      if (beforeDistance || betweenDistance) {
        this.snapPosition =
          direction === 'x'
            ? {
                x: beforeElement.x + beforeElement.width + distance,
                y: currentDimension.y,
              }
            : {
                x: currentDimension.x,
                y: beforeElement.y + beforeElement.height + distance,
              };
        this.snapToPosition(this.snapPosition);
      } else if (afterDistance) {
        this.snapPosition =
          direction === 'x'
            ? {
                x: afterElement.x - distance - currentDimension.width,
                y: currentDimension.y,
              }
            : {
                x: currentDimension.x,
                y: afterElement.y - distance - currentDimension.height,
              };
        this.snapToPosition(this.snapPosition);
      }
      this.canvasDocument.canvasRenderer.guidelinesRenderer['distanceGuidelines' + direction] = sameDistance?.map(
        (e) => ({
          lineDefinition: e.lineDefinition,
        }),
      );
      // console.log('guides', direction, this.canvasDocument.canvasRenderer.guidelinesRenderer['distanceGuidelines' + direction])
    }
  }

  private snapToPosition(snapPosition) {
    this.hasSnapped = true;
    this.guidelineService.setPositions(snapPosition, this.selectedElements, this.deltaPositions, this.frames);
  }

  public dragended(event) {
    this.clear();
  }

  private clear() {
    this.selectedElements = [];
    this.selectedElementsByFrame = [];
    this.canvasDocument.canvasRenderer.guidelinesRenderer.distanceGuidelinesx = [];
    this.canvasDocument.canvasRenderer.guidelinesRenderer.distanceGuidelinesy = [];
    this.otherElementDimensions = [];
    this.frames = null;
    this.snapPosition = null;
    this.margin = null;
    this.hasSnapped = false;
    this.deltaPositions = new Map();
  }
}
