import { CanvasDocument } from '../canvas-document';
import { CanvasElement } from '../elements/canvas-element';
import { CanvasGroupElement } from '../elements/group/canvas-group-element';
import { ObjectUtil } from '@contrail/util';
import { Frame } from './canvas-frame-state';
import { CanvasMaskState } from './canvas-mask-state';

export interface Group {
  id: string;
  element: CanvasElement;
  elementIds: Array<string>;
}

export class CanvasGroupState {
  public groups: Map<string, Group> = new Map(); // <groupId, Group>
  public groupElements: Map<string, string> = new Map(); // <elementId, groupId>

  constructor(public canvasDocument: CanvasDocument) {}

  public addGroupElement(groupElement: CanvasElement) {
    this.groups.set(groupElement.id, {
      id: groupElement.id,
      element: groupElement as CanvasGroupElement,
      elementIds: groupElement.elementDefinition.elementIds,
    });
    groupElement.elementDefinition.elementIds.forEach((elementId) => {
      this.groupElements.set(elementId, groupElement.id);
      const element = this.canvasDocument.state.getElementById(elementId);
      if (element) {
        element.isInGroup = groupElement.id;
      }
    });
  }

  public updateGroupElement(element: CanvasElement) {
    const group: Group = this.groups.get(element.id);
    group.element = element as CanvasGroupElement;
    const currentElementIds = ObjectUtil.cloneDeep(group.elementIds);
    const removedElementIds = currentElementIds.filter(
      (elementId) => !element.elementDefinition.elementIds.includes(elementId),
    );
    const addedElementIds = element.elementDefinition.elementIds.filter(
      (elementId) => !currentElementIds.includes(elementId),
    );
    group.elementIds = element.elementDefinition.elementIds;
    removedElementIds.forEach((removedElementId) => {
      const canvasElement = this.canvasDocument.state.getElementById(removedElementId);
      if (canvasElement) {
        canvasElement.isInGroup = false;
      }
      this.groupElements.delete(removedElementId);
    });
    addedElementIds.forEach((addedElementId) => {
      const canvasElement = this.canvasDocument.state.getElementById(addedElementId);
      if (canvasElement) {
        canvasElement.isInGroup = group.id;
      }
      this.groupElements.set(addedElementId, group.id);
    });
  }

  public deleteGroupElement(groupElement: CanvasElement) {
    const group: Group = this.groups.get(groupElement.id);
    group.elementIds.forEach((elementId) => {
      const element = this.canvasDocument.state.getElementById(elementId);
      if (element) {
        element.isInGroup = false;
        this.groupElements.delete(element.id);
      }
    });

    this.groups.delete(groupElement.id);
  }

  public removeElementInGroup(elementIds: string[]) {
    elementIds.forEach((id) => {
      if (this.groupElements.get(id)) {
        this.groupElements.delete(id);
      }
    });
  }

  /**
   * Get the group that an element belongs to.  It returns the top-level group by default
   * @param elementId
   * @returns
   */
  public getGroupByMemberElementId(elementId: string, topLevel = true): Group {
    let groupId = this.groupElements.get(elementId);
    if (topLevel) {
      groupId = this.getRootGroupId(groupId);
    }
    if (groupId) {
      return this.groups.get(groupId);
    }
    return null;
  }

  /**
   * Get all elements that are part of the group. If fromTopLevel=true, it will look for elements
   * from the top-level group down.
   * @param groupId
   * @param fromTopLevel
   * @returns
   */
  public getAllElementsInGroup(
    groupId: string,
    fromTopLevel = false,
    includeFrameElements = false,
    includeMaskElements = false,
  ): CanvasElement[] {
    const elements = [];
    if (fromTopLevel) {
      this.gatherElements(this.getRootGroupId(groupId), elements, includeFrameElements, includeMaskElements);
    } else {
      this.gatherElements(groupId, elements, includeFrameElements, includeMaskElements);
    }
    return elements;
  }

  public getGroupByMemberId(elementId: string): Group {
    let groupId = this.groupElements.get(elementId);
    return this.groups.get(groupId);
  }

  /**
   * Get the groupId of the top-level group that this group belongs to
   * @param groupId
   * @returns
   */
  private getRootGroupId(groupId: string): string {
    return this.getParentGroupId(groupId);
  }

  private getParentGroupId(groupId: string) {
    const parentGroupId = this.groupElements.get(groupId);
    if (parentGroupId) {
      return this.getParentGroupId(parentGroupId);
    }
    return groupId;
  }

  private gatherElements(
    groupId,
    elements: CanvasElement[],
    includeFrameElements: boolean,
    includeMaskElements: boolean,
  ) {
    const group: Group = this.groups.get(groupId);
    if (!group) {
      return;
    }
    for (let elementId of group.elementIds) {
      const element = this.canvasDocument.state.getElementById(elementId);
      if (!element) {
        continue;
      }
      if (element.elementDefinition.type === 'group') {
        this.gatherElements(element.id, elements, includeFrameElements, includeMaskElements);
      } else if (includeFrameElements && element.elementDefinition.type === 'frame') {
        const frame: Frame = this.canvasDocument.state.frameState.frames.get(element.id);
        if (frame) {
          for (let elementData of frame.elements) {
            if (elements?.findIndex((e) => e.id === elementData.canvasElement.id) === -1) {
              elements.push(elementData.canvasElement);
            }
          }
        }
      } else if (includeMaskElements && CanvasMaskState.isMask(element.elementDefinition)) {
        const maskMembers = this.canvasDocument.state.maskState.getMaskMembers(element.id);
        if (maskMembers?.length > 0) {
          for (let maskMember of maskMembers) {
            if (elements?.findIndex((e) => e.id === maskMember.id) === -1) {
              elements.push(maskMember);
            }
          }
        }
      }
      if (elements?.findIndex((e) => e.id === element.id) === -1) {
        elements.push(element);
      }
    }
  }
}
