import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { Type } from '@contrail/types';
import { RootStoreState } from '@rootstore';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { environment } from 'src/environments/environment';
import { Item } from '../../item';
import { AuthSelectors } from '@common/auth/auth-store';

export interface ItemNavigationOption {
  label: string;
  slug: string;
}

@Component({
  selector: 'app-item-details-header',
  templateUrl: './item-details-header.component.html',
  styleUrls: ['./item-details-header.component.scss'],
})
export class ItemDetailsHeaderComponent implements OnChanges {
  @Input() item: Item;
  @Input() itemFamily: any;
  @Input() itemOptions: any;
  public selectedNavigationOption: ItemNavigationOption;
  @Output() cancelView = new EventEmitter();
  @Output() navigate = new EventEmitter();
  @Output() itemClicked = new EventEmitter();
  @Input() navOptions: Array<ItemNavigationOption>;
  public isProd = environment.production;

  @Input() type: Type;
  public hasStatus = false;
  public statusHexColor: string;
  private itemStatusColors: Array<string>;

  constructor(
    private store: Store<RootStoreState.State>,
    private routerService: RoutingService,
  ) {
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          this.itemStatusColors = currentOrg?.orgConfig?.itemStatusHexColors;
        }),
      )
      .subscribe();
  }

  public lifecycleStage;
  ngOnChanges(): void {
    this.selectedNavigationOption = this.selectedNavigationOption ? this.selectedNavigationOption : this.navOptions[0];
    if (this.type) {
      const property = this.type.typeProperties.find((p) => p.slug === 'itemStatus' || p.slug === 'lifecycleStage');
      if (!property || !this.item[property.slug]) {
        this.hasStatus = false;
        return;
      }

      this.hasStatus = true;
      const indexOfStatus = property.options
        ? property.options.findIndex((option) => option.value === this.item[property.slug])
        : 0;
      this.statusHexColor = this.itemStatusColors[indexOfStatus] ?? this.itemStatusColors[0];
    }
  }
  cancel() {
    this.cancelView.emit();
  }

  setSelectedNavOption(option: ItemNavigationOption) {
    this.selectedNavigationOption = option;
    this.navigate.emit(option);
  }

  goToDetails() {
    this.cancelView.emit();
    this.routerService.go(`item/${this.item.id}`);
  }

  async setCurrentItem(item: Item) {
    this.itemClicked.emit(item);
  }
}
