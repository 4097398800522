import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  CollectionStatusMessagesState,
  collectionStatusMessagesInitialState,
} from './collection-status-messages/collection-status-messages.state';
import { Item } from '@common/assortments/item';

export interface Board {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  workspaceId?: string;
  previewFile?: any;
  document?: any;
  workspace?: any;
  sourceAssortment?: any;
  sourceAssortmentId?: any;
  createdBy?: any;
  updatedBy?: any;
  backingAssortmentId?: string;
  primaryContext?: any;
  primaryContextReference?: string;
}

interface BoardsState extends EntityState<Board> {}

export interface State {
  boards: BoardsState;
  currentBoard: Board;
  boardsLoaded: boolean;
  remoteUserMouseTracker: any;
  collectionStatusMessages: CollectionStatusMessagesState;
  itemsUsedInBoard: Array<Item>;
  showSourceAssortmentWarning: boolean;
  currentDocumentGenerationConfig: any;
}
export const boardsEntityAdapter: EntityAdapter<Board> = createEntityAdapter<Board>({});

export const boardsInitialState = {
  boards: boardsEntityAdapter.getInitialState({}),
  currentBoard: null,
  boardsLoaded: false,
  remoteUserMouseTracker: {},
  collectionStatusMessages: collectionStatusMessagesInitialState,
  itemsUsedInBoard: null,
  showSourceAssortmentWarning:
    JSON.parse(localStorage.getItem('_showSourceAssortmentWarnings')) === true ? true : false,
  currentDocumentGenerationConfig: null,
};
