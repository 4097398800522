import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EntityDetailsBaseComponent } from '../entity-details-base-component';
import { IconTypes } from '../icon-types';

@Component({
  selector: 'app-entity-details-side-panel',
  templateUrl: './entity-details-side-panel.component.html',
  styleUrls: ['./entity-details-side-panel.component.scss'],
})
export class EntityDetailsSidePanelComponent extends EntityDetailsBaseComponent implements OnChanges, AfterViewInit {
  @Input() visualSection = false;
  @Input() colorSection = false;
  @Input() detailSection = true;
  @Input() propertySection = false;
  @Input() trash: boolean = false;
  public iconTypes = IconTypes;

  constructor(
    protected ref: ChangeDetectorRef,
    protected store: Store<RootStoreState.State>,
  ) {
    super(ref, store);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  filterType(evt) {
    const key = evt?.trim().toLowerCase();
    if (key?.length) {
      const properties = this.type.typeProperties?.filter((prop) => prop?.label?.toLowerCase().includes(key));
      this.filteredType = { ...this.type, typeProperties: properties };
    } else {
      this.filteredType = this.type;
    }
  }

  get getHeader() {
    if (this.selectedNum === 0) {
      return `Details`;
    } else {
      return `${this.selectedNum} items selected`;
    }
  }
}
