import { ActionRequest } from '@contrail/actions';
import { DocumentElement, SizeDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { EditorContainer } from '../../../components/editor/editor-container';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { DEFAULT_TEXT_FONT_FAMILY, DEFAULT_TEXT_FONT_SIZE, editorId, TEXT_CAROT_WIDTH } from './text-editor';

export class TextEditorContainer extends EditorContainer {
  constructor(canvasDocument: CanvasDocument, manager: EditorManager) {
    super(canvasDocument, editorId, manager);
  }

  public setPlaceholder(element: CanvasElement, text: string) {
    if (element.TEXT_PLACEHOLDER != '' && (!text || text === '')) {
      this.editor.style.fontSize = (element?.elementDefinition?.style?.font?.size ?? 11) + 'pt';
      this.manager.editor.getEditor().getBody().setAttribute('data-mce-placeholder', element.TEXT_PLACEHOLDER);
    } else {
      if (element.isPropertyValid('style.font.size')) {
        this.editor.style.fontSize = (element?.elementDefinition?.style?.font?.size ?? 11) + 'pt';
      } else {
        this.editor.style.fontSize = null;
      }
      this.manager.editor.getEditor().getBody().setAttribute('data-mce-placeholder', '');
    }
  }

  public handleKeydownEvent(event) {
    if (event.code === 'Tab') {
      if (this.manager?.element?.elementDefinition?.isTextTool) {
        this.autoGrowTextTool(event);
      }
    } else if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey) && event.altKey) {
      event.preventDefault();
      this.canvasDocument.actionsDispatcher.handleActionRequest(new ActionRequest('copy_properties'));
    } else if (event.code === 'KeyV' && (event.ctrlKey || event.metaKey) && event.altKey) {
      event.preventDefault();
      this.canvasDocument.actionsDispatcher.handleActionRequest(new ActionRequest('paste_properties'));
    }
  }

  public handleInputEvent(event) {
    if (!this.manager.element || this.manager.element.elementDefinition.type !== 'text') {
      return;
    }

    if (!this.manager?.element?.elementDefinition?.isTextTool) {
      this.autoGrowTextBox(event);
      this.manager.editor.setContentStyle();
    } else {
      this.autoGrowTextTool(event);
    }
  }

  private autoGrowTextBox(event) {
    const target = this.editor as HTMLElement;
    const container = this.editorOverflow as HTMLElement;
    if (target.scrollHeight > container.clientHeight) {
      const height =
        target.scrollHeight +
        this.manager.element.TEXT_PADDING * 2 +
        (this.manager.element.elementDefinition?.style?.border?.width ?? this.manager.element.DEFAULT_BORDER_SIZE) * 2;
      this.manager.editorContainer.currentContainerHeight = height;
      this.manager.editorContainer.editorContainer.style.height = `${height}px`;
      this.manager.element.elementDefinition.size.height = height;
      this.canvasDocument.draw();
    }
  }

  private autoGrowTextTool(event) {
    const text = this.manager.editor?.isEmptyPlainText() ? '' : this.manager.getInnerHTML();
    const result = this.setTextToolEditorSize(this.manager.element, text);

    if (result?.draw) {
      this.canvasDocument.draw();
    }
  }

  /**
   * Calculate width and height of @text including border width.
   * Update editor size if @element is currently being edited.
   * @param element
   * @param text
   * @returns
   */
  public setTextToolEditorSize(element: CanvasElement, content): { size: SizeDefinition; draw?: boolean } {
    const target = this.editorContainer as HTMLElement;

    let text = content;

    // Set text to empty string to display text placeholder
    if (
      this.manager.isEditing(element) &&
      text != '' &&
      this.canvasDocument?.editorHandler?.editorCalc?.isEmptyTextContent(text)
    ) {
      text = '';
    }

    const size = this.getTextToolSize(element, text);
    const width = size?.width;
    const height = size?.height;

    if (!width || !height) {
      return;
    }

    if (!this.manager.isEditing(element)) {
      return { size };
    }

    let draw = false;
    if (element.TEXT_PLACEHOLDER !== '' && (!text || text === '')) {
      this.manager.editorContainer.currentContainerWidth = width;
      this.manager.editorContainer.editorContainer.style.width = `${width}px`;
      this.manager.element.elementDefinition.size.width = width;
      this.manager.editorContainer.currentContainerHeight = height;
      this.manager.editorContainer.editorContainer.style.height = `${height}px`;
      this.manager.element.elementDefinition.size.height = height;
      draw = true;
    } else {
      if (width !== target.clientWidth) {
        this.manager.editorContainer.currentContainerWidth = width;
        this.manager.editorContainer.editorContainer.style.width = `${width}px`;
        this.manager.element.elementDefinition.size.width = width;
        draw = true;
      }
      if (height !== target.clientHeight) {
        this.manager.editorContainer.currentContainerHeight = height;
        this.manager.editorContainer.editorContainer.style.height = `${height}px`;
        this.manager.element.elementDefinition.size.height = height;
        draw = true;
      }
    }

    if (draw) {
      this.setPlaceholder(element, text);
    }

    return { size, draw };
  }

  /**
   * Calculate width and height of @text including border width
   * @param element
   * @param text
   * @param options
   * @returns
   */
  public getTextToolSize(element: CanvasElement, text, options?: DocumentElement): SizeDefinition {
    const style = element.elementDefinition.style;
    const scale = element.elementDefinition.isTextTool ? element.elementDefinition?.scale?.x ?? 1 : 1;
    const fontFamily = `${style?.font?.family ?? DEFAULT_TEXT_FONT_FAMILY}`;
    const fontSize = style?.font?.size ?? DEFAULT_TEXT_FONT_SIZE;
    const fontSizeString = `${fontSize}pt`;
    const borderWidth = !element.isPropertyValid('style.border')
      ? 0
      : options?.style?.border?.width ?? style?.border?.width ?? element.DEFAULT_BORDER_SIZE;
    const totalBorderWidth = borderWidth * 2 * scale;
    const textCarotWidth = element.elementDefinition.isTextTool ? TEXT_CAROT_WIDTH * scale : 0;

    let width, height;
    if (element.TEXT_PLACEHOLDER !== '' && (!text || text === '')) {
      const placeholderSize = this.canvasDocument.editorHandler.editorCalc.getTextSize(
        element.TEXT_PLACEHOLDER,
        fontSizeString,
        DEFAULT_TEXT_FONT_FAMILY,
      );
      width = placeholderSize.width + totalBorderWidth + textCarotWidth;
      height = placeholderSize.height + totalBorderWidth;
    } else {
      const size = this.canvasDocument.editorHandler.editorCalc.getTextSize(text, fontSizeString, fontFamily);
      const minWidth = this.getMinWidth(fontSize);
      width = this.manager.isEditing(element)
        ? Math.max(minWidth, size.width + totalBorderWidth + textCarotWidth)
        : size.width + totalBorderWidth + textCarotWidth;
      height = size.height + totalBorderWidth;
    }

    return width != null && height != null ? { width, height } : null;
  }

  /**
   * Approximate minimum width to fit 3 characters at @fontSize
   * @param fontSize
   * @returns
   */
  private getMinWidth(fontSize: string | number = 11) {
    const size = typeof fontSize === 'string' ? Number(fontSize) : fontSize;
    return Math.round(size * 2.7);
  }
}
