import { Component, OnInit } from '@angular/core';
import { PageHeaderService, PageHeaderConfig } from './page-header.service';
import { Observable } from 'rxjs';
import { RoutingService } from 'src/app/routing/routing.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  pageHeaderConfig: Observable<PageHeaderConfig>;
  constructor(
    private pageHeaderService: PageHeaderService,
    private routingService: RoutingService,
  ) {}

  ngOnInit(): void {
    this.pageHeaderConfig = this.pageHeaderService.pageHeaderConfig;
  }
  public goHome() {
    this.routingService.go('home');
  }
}
