import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { FrameTemplatesActions } from '@common/frame-templates/frame-templates-store';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';

@Component({
  selector: 'app-frame-template-editor-modal',
  templateUrl: 'frame-template-editor-modal.component.html',
  styleUrls: ['frame-template-editor-modal.component.scss'],
})
export class FrameTemplateEditorModal implements OnInit, OnDestroy {
  public iframeData: any;
  public authToken: string;
  public authOrg: string;
  public authContext: any;
  public user;
  loading = true;
  currentFrameTemplate: FrameTemplate;
  frameContentWindow: any;
  title = '';
  private messageListener: any;

  constructor(
    public dialogRef: MatDialogRef<FrameTemplateEditorModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<RootStoreState.State>,
  ) {}

  ngOnInit() {
    this.messageListener = this.handleMessage.bind(this);
    window.addEventListener('message', this.messageListener);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.messageListener);
  }

  onLoad() {
    this.frameContentWindow = (document.getElementById('frameTemplateEditor') as any).contentWindow;
    const data = this.data;
    if (this.data.frameTemplate) {
      this.currentFrameTemplate = ObjectUtil.cloneDeep(this.data.frameTemplate);
      this.title = 'Edit template';
    } else {
      this.title = 'Save frame as template';
    }
    this.frameContentWindow.postMessage(data);
    this.loading = false;
  }

  private handleMessage(event) {
    if (event.data.templateData) {
      if (this.currentFrameTemplate) {
        const changes = {
          id: this.currentFrameTemplate.id,
          ...event.data.templateData,
        };
        this.store.dispatch(FrameTemplatesActions.updateFrameTemplate({ id: this.currentFrameTemplate.id, changes }));
      } else {
        this.store.dispatch(FrameTemplatesActions.createFrameTemplate({ frameTemplate: event.data.templateData }));
      }
    }
    if (event.data.closeDialog) {
      this.dialogRef.close();
    }
  }
}
