export const FONTS_FAMILY_PICKLIST = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Rounded MT Bold',
  'Avenir',
  'Bradley Hand',
  'Comic Sans MS',
  'Copperplate',
  'Courier',
  'Courier New',
  'fantasy',
  'Georgia',
  'Helvetica',
  'Impact',
  'Palatino',
  'Playfair Display',
  'Roboto',
  'sans-serif',
  'Snell Roundhand',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

export const CUSTOM_FONTS_FAMILY = {
  'yulia-zone-1': [
    'Avenir-Light',
    'Avenir Lt Std-Black',
    'Avenir Lt Std-Heavy',
    'Avenir Lt Std-Roman',
    'Gill Sans MT Std Bold',
    'Gill Sans MT Std Light',
    'Gill Sans MT Std Medium',
    'Helvetica Neue Std Medium',
    'Helvetica Neue Std Bold',
    'Helvetica Neue Std Light',
    'DiNMittelschriftStd',
    'Fenice Std Bold',
    'Fenice Std Light',
    'Fenice Std Regular',
    'Fenice Std Ultra',
    'Shelley',
    'Myriad Pro Bold',
    'Myriad Pro Light',
    'Myriad Pro Regular',
    'Alegre Sans',
    'SegoeUI Bold',
    'SegoeUI Light',
    'SegoeUI',
  ],
  // 'vadim-zone-1': ['AdihausDIN', 'AdihausDIN-Cn', 'AdiHaus', 'AdiHaus-CE', 'AdiHaus-ROM', 'AdiHaus-Turk', 'adineuePROCond-Rounded', 'adineuePROCondTT', 'adineueTEXT', 'AdiHausPS', 'AdiHausPSCon', 'adiNeue', 'adiNeue2013', 'adiNeueLogos', 'adineuePRO', 'adineuePRO-Beta', 'adineuePROCond', 'AdiTECH', 'Gotham', 'Didot', 'AlegreSans', 'DiNMittelschriftStd', 'FeniceStd', 'Shelley'],
  'brian-dev': ['Didot', 'Gotham'],
  // 'brian-zone': ['AdihausDIN', 'AdihausDIN-Cn', 'AdiHaus', 'AdiHaus-CE', 'AdiHaus-ROM', 'AdiHaus-Turk', 'adineuePROCond-Rounded', 'adineuePROCondTT', 'adineueTEXT', 'AdiHausPS', 'AdiHausPSCon', 'adiNeue', 'adiNeue2013', 'adiNeueLogos', 'adineuePRO', 'adineuePRO-Beta', 'adineuePROCond', 'AdiTECH', 'Gotham', 'Didot'],
  agron: [
    'Denton',
    'Franklin Gothic Condensed',
    'Franklin Gothic Book',
    'AdihausDIN',
    'AdihausDIN-Cn',
    'AdiHaus',
    'AdiHaus-CE',
    'AdiHaus-ROM',
    'AdiHaus-Turk',
    'adineuePROCond-Rounded',
    'adineuePROCondTT',
    'adineueTEXT',
    'AdiHausPS',
    'AdiHausPSCon',
    'adiNeue',
    'adiNeue2013',
    'adiNeueLogos',
    'adineuePRO',
    'adineuePRO-Beta',
    'adineuePROCond',
    'AdiTECH',
    'ITCFranklinGothicStd-BkCp',
    'ITCFranklinGothicStd-Book',
    'ITCFranklinGothicStd-DmCp',
  ],
  'new-balance-prod': ['Didot', 'Gotham'],
  'new-balance-training': ['Didot', 'Gotham'],
  'nb-legacy-prod': [
    'Arial Nova',
    'Arial Nova Light',
    'Arial Nova Cond',
    'Arial Nova Cond Light',
    'ITC Garamond Condensed Book',
  ],
  demo: ['Didot', 'Gotham'],
  ralph: [
    'Avenir-Light',
    'Avenir Lt Std-Black',
    'Avenir Lt Std-Heavy',
    'Avenir Lt Std-Roman',
    'Gill Sans MT Std Bold',
    'Gill Sans MT Std Light',
    'Gill Sans MT Std Medium',
    'Helvetica Neue Std Medium',
    'Helvetica Neue Std Bold',
    'Helvetica Neue Std Light',
    'DiNMittelschriftStd',
    'Fenice Std Bold',
    'Fenice Std Light',
    'Fenice Std Regular',
    'Fenice Std Ultra',
    'Shelley',
    'Myriad Pro Bold',
    'Myriad Pro Light',
    'Myriad Pro Regular',
    'Alegre Sans',
    'SegoeUI Bold',
    'SegoeUI Light',
    'SegoeUI',
  ],
  'michael-kors': ['Montserrat'],
};
