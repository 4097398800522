import { DocumentElement, DocumentTextElementEvent } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument, CopiedProperties } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { EditorFormatter } from '../../../components/editor/editor-formatter';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { TextEditor, TEXT_BOX_PADDING } from './text-editor';
import { TextEditorContainer } from './text-editor-container';
import { TextEditorPlaceholder } from './text-editor-placeholder';

export class TextEditorManager extends EditorManager {
  constructor(canvasDocument: CanvasDocument) {
    super(canvasDocument, { hideElement: true, setLastAppliedTextFormat: true }, {});
    this.editorContainer = new TextEditorContainer(canvasDocument, this);
    this.editor = new TextEditor(canvasDocument, this);
    this.editorPlaceholder = new TextEditorPlaceholder(canvasDocument, this);
  }

  public applyCopiedProperties(properties: CopiedProperties) {
    const textEventChanges = [];
    for (const type in properties?.textFormat) {
      if (EditorFormatter.FORMATS.indexOf(type) !== -1) {
        let value = properties.textFormat[type];
        if (['bold', 'italic', 'underline', 'strikethrough'].indexOf(type) !== -1) {
          value = properties.textFormat[type] === 'normal' ? false : true;
        }
        textEventChanges.push({
          element: null,
          textFormat: {
            type,
            value,
          },
        });
      }
    }

    console.log('TextEditorManager.applyCopiedProperties', textEventChanges);
    const elementChanges = {
      textChanges: [],
      textUndoChanges: [],
    };
    const selectedElements: Array<CanvasElement> = this.canvasDocument?.interactionHandler?.selectionHandler
      ?.getSelectedUnlockedCanvasElements()
      .filter((element) => element.elementDefinition.type === 'text');
    if (selectedElements?.length > 0 && textEventChanges?.length > 0) {
      const isFocused = this.element && this?.editor?.isFocused;
      for (let i = 0; i < selectedElements?.length; i++) {
        const element: CanvasElement = selectedElements[i];
        const undoElement = ObjectUtil.cloneDeep(element.elementDefinition);
        const updatedElement = this.assignTextEventChanges(element, textEventChanges, isFocused);
        elementChanges.textUndoChanges.push(undoElement);
        elementChanges.textChanges.push(
          ObjectUtil.mergeDeep(
            updatedElement ?? {
              id: element.elementDefinition.id,
            },
            properties?.element ? this.getValidStyleProperty(element, properties.element) : {},
          ),
        );
      }
    }

    if (elementChanges?.textChanges?.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        elementChanges.textChanges,
        elementChanges.textUndoChanges,
      );
    }
  }

  private getValidStyleProperty(element: CanvasElement, otherElement: DocumentElement): DocumentElement {
    const otherCanvasElement = this.canvasDocument.getCanvasElementById(otherElement?.id);
    if (element?.elementDefinition?.type === 'text' && otherCanvasElement) {
      const newElement = {};
      element?.VALID_PROPERTIES?.forEach((path) => {
        const isOtherValid = otherCanvasElement.isPropertyValid(path);
        if (isOtherValid) {
          let value = ObjectUtil.getByPath(otherElement, path);
          if (value && path === 'style.font.size' && otherElement?.scale?.x) {
            value = '' + Math.round(parseFloat(value) * otherElement.scale.x);
          }
          ObjectUtil.setByPath(newElement, path, value);
        }
      });
      return newElement;
    }
    return {};
  }

  /**
   * Switch text types to text tool or text box
   * @param element
   * @param changes
   * @param text
   * @returns
   */
  protected applyTextType(element: CanvasElement, changes: DocumentTextElementEvent, text): DocumentElement {
    if (changes?.textFormat?.type === 'textType' && changes?.textFormat?.value) {
      const textType = changes?.textFormat?.value;
      if (textType === 'text-box') {
        if (element.elementDefinition.type === 'text' && element.elementDefinition.isTextTool) {
          const newElement: DocumentElement = {
            id: element.id,
            isTextTool: false,
            scale: null,
            size: {
              width: element.elementDefinition.size.width + TEXT_BOX_PADDING * 2,
              height: element.elementDefinition.size.height + TEXT_BOX_PADDING * 2,
            },
            style: ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition?.style || {}), {
              font: {
                size: null,
              },
            }),
          };

          const fontSize = Math.round(
            (element.elementDefinition.style?.font?.size ?? 11) * (element?.elementDefinition?.scale?.x ?? 1),
          );

          element.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition), newElement);
          element.setDefaultValues();

          const data = this.editor.execCommand({ type: 'fontSize', value: fontSize });
          if (data?.textChanges?.length > 0 && data.textChanges[0].text) {
            newElement.text = data.textChanges[0].text;
            const size = this.canvasDocument.editorHandler.editorCalc.getTextSize(data.textChanges[0].text);
            if (size) {
              newElement.size = {
                width: size.width + TEXT_BOX_PADDING * 2 + 1,
                height: size.height + TEXT_BOX_PADDING * 2,
              };
            }
          }

          return newElement;
        }
      } else if (textType === 'text-tool') {
        if (element.elementDefinition.type === 'text' && !element.elementDefinition.isTextTool) {
          const fontSizeString = CanvasUtil.getFirstFontSize(element.elementDefinition.text);
          const fontSize = fontSizeString ? parseFloat(fontSizeString) : 11;
          const text = CanvasUtil.removeFontSize(element.elementDefinition.text);
          const newElement: DocumentElement = {
            id: element.id,
            isTextTool: true,
            scale: { x: 1, y: 1 },
            style: {
              text: {
                valign: null,
              },
              font: {
                size: fontSize,
              },
              border: {
                width: 0,
              },
            },
            text,
          };
          element.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition), newElement);
          element.setDefaultValues();

          return newElement;
        }
      }
    }
    return null;
  }
}
