<div class="entity-container" *ngIf="entity">
  <app-entity-details-header
    [entity]="entity"
    [navOptions]="navOptions"
    [type]="type"
    (navigate)="selectNavOption($event)"
    (cancelView)="cancel()"
  />
  <div class="body">
    <!-- DETAILS -->
    <div *ngIf="currentNavOption.slug === 'details'" class="details-wrap">
      <div class="left-column narrow-scroll">
        <ng-container *ngIf="hasStatus">
          <div class="status-container">
            <p class="text-title-2 mb-2">Status</p>
            <div class="flex items-center">
              <div class="status-circle" [class]="statusClass"></div>
              <span class="text-caption text-black80 ml-1">{{ statusValue }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
        </ng-container>

        <div class="meta-container">
          <div class="meta-option">
            <div class="label">Created:</div>
            <div class="value">{{ entity?.createdOn | date: 'medium' }}</div>
            <div class="user">{{ entity?.createdBy | userName: 'fullEmail' }}</div>
          </div>
          <div class="meta-option">
            <div class="label">Updated:</div>
            <div class="value">{{ entity?.updatedOn | date: 'medium' }}</div>
            <div class="user">{{ entity?.updatedBy | userName: 'fullEmail' }}</div>
          </div>
          <div class="meta-option">
            <div class="label">Type:</div>
            <div class="value">{{ type?.label }}</div>
          </div>
        </div>
      </div>

      <div class="center-column">
        <app-entity-properties-form
          [accessLevel]="accessLevel"
          [entity]="entity"
          [type]="type"
        ></app-entity-properties-form>
      </div>

      <div class="right-column">
        <app-entity-details-side-panel-visualization
          [entity]="entity"
          [viewMode]="'modal'"
          class="w-full h-full"
        ></app-entity-details-side-panel-visualization>
        <div
          *ngIf="entity?.entityType !== 'color'"
          class="entity-toolbar flex-center mt-3 mb-6 cursor-pointer text-black60 hover:text-black80"
          [class.action-disabled]="!entity?.largeViewableDownloadUrl && !entity?.mediumViewableDownloadUrl"
        >
          <div class="flex-center" (click)="downloadFile()">
            <mat-icon>download</mat-icon>
            <p class="text-title-2 mx-2">Download</p>
          </div>
        </div>
      </div>
    </div>

    <!-- CONTENT -->
    <div *ngIf="currentNavOption.slug === 'content'" class="content-wrap">
      <app-content-holder-details
        [editable]="contentEditable"
        [contentHolder]="entity"
        (primaryViewableChanged)="handlePrimaryViewableChange($event)"
      ></app-content-holder-details>
    </div>
  </div>
</div>

<div class="loading" *ngIf="entity === null">
  <mat-spinner diameter="40"></mat-spinner>
  <div class="message">Loading</div>
</div>
