import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasComponentElement } from '../../elements/component/canvas-component-element';

export class OutlineWidgetRenderer {
  protected readonly STROKE_WIDTH = 1;

  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {}

  public draw(ctx) {
    if (this.element.isOutlined) {
      const color = this.element.isOutlined;
      const { x, y } = this.element.getPosition();
      let size = this.element.getSize();
      if (
        this.element.elementDefinition.type === 'component' &&
        (this.element as CanvasComponentElement).documentSize
      ) {
        size = (this.element as CanvasComponentElement).documentSize;
      }
      if (!size) {
        return;
      }
      const { width, height } = size;

      ctx.save();

      ctx.beginPath();

      ctx.translate(x + width * 0.5, y + height * 0.5);
      ctx.rotate(CanvasUtil.getAngle(this.element.elementDefinition?.rotate?.angle ?? 0));

      ctx.rect(
        -width * 0.5 - this.element.PADDING_LEFT,
        -height * 0.5 - this.element.PADDING_TOP,
        width + (this.element.PADDING_LEFT + this.element.PADDING_RIGHT),
        height + (this.element.PADDING_TOP + this.element.PADDING_BOTTOM),
      );

      ctx.strokeStyle = color;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.closePath();

      ctx.restore();
    }
  }
}
