<div class="comments-count-container" (click)="showComments()">
  <div class="comments-icon">
    <mat-icon [svgIcon]="'contrail_comment'"></mat-icon>
    <mat-icon *ngIf="!(comments$ | async)?.length">add</mat-icon>
  </div>
  <div
    *ngIf="(comments$ | async)?.length"
    [ngClass]="{ disabled: (comments$ | async)?.length == 0 }"
    class="comments-count"
  >
    <span>{{ (comments$ | async)?.length }}</span>
  </div>
</div>
