import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@common/content/content.service';

@Component({
  selector: 'app-content-modal-2d',
  templateUrl: './content-modal-2d.component.html',
  styleUrls: ['./content-modal-2d.component.scss'],
})
export class ContentModal2DComponent implements OnInit {
  @Input() content;

  sideNavOptions: any = [{ label: 'details', icon: 'info' }];
  currentSideNav = 'details';

  get configNavTitle() {
    switch (this.currentSideNav) {
      case 'details':
        return 'Details';
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    private contentService: ContentService,
  ) {}

  ngOnInit(): void {}

  download() {
    this.contentService.downloadContent(this.content);
  }
}
