import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, from, throwError, BehaviorSubject } from 'rxjs';

import { switchMap, catchError, take, tap, filter } from 'rxjs/operators';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('sso-login/domain-check') || request.url.includes('auth/jwt_refresh')) {
      return next.handle(request);
    }
    return from(this.auth.getAuthContext()).pipe(
      switchMap((context) => {
        const requestClone = this.setHeaders(request, context.token, context.currentOrg?.orgSlug);
        return next.handle(requestClone).pipe(
          catchError((error) => {
            if (
              // only intercept on Unauthorized error and if this isn't the login page.
              (error instanceof HttpErrorResponse &&
                !request.url.includes('/login') &&
                [401, 403].includes(error.status)) ||
              // or if the JWT expired
              (error instanceof HttpErrorResponse && request.url.includes('/current') && [500].includes(error.status))
            ) {
              return this.handle4XXError(request, next);
            }
            return throwError(error);
          }),
        );
      }),
    );
  }

  /**
   * Attempts to refresh the JWT token and send the request again.
   * Heavily inspired by https://www.bezkoder.com/angular-12-refresh-token/
   */
  private handle4XXError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      // refresh jwt and set it in the app.
      return this.auth.requestRefreshedAccessToken().pipe(
        tap((response: any) => {
          this.auth.setAccessToken(response.accessToken);
          this.refreshTokenSubject.next(response.accessToken);
          return response;
        }),
        switchMap((response: any) => {
          // retry the request with the new access token
          return next.handle(this.setHeaders(request, response.accessToken, null));
        }),
        // if refresh fails, log the user out.
        catchError((err) => {
          this.isRefreshing = false;
          this.auth.logout();
          return throwError(err);
        }),
      );
    }

    // if this happens in the middle of refreshing.
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.setHeaders(request, token, null))),
    );
  }

  // takes in a request and returns a clone with the needed access key and org.
  private setHeaders(request: HttpRequest<any>, token: string, orgSlug: string): HttpRequest<any> {
    let headers = request.headers.set('X-Api-Key', token).set('X-Api-Org', 'NONE');
    if (orgSlug) {
      headers = headers.set('X-Api-Org', orgSlug);
    }
    return request.clone({ headers });
  }
}
