import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';
import { SvgCoordinateHelper } from '../helpers/svg-coordinate-helper';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasCloudElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }) {
    const elementSize = { width, height };
    const viewbox = { width: 24, height: 20 };
    const position = { x, y };
    const start = SvgCoordinateHelper.convertSvgCoordinate({ x: 19.35, y: 10.04 }, elementSize, viewbox, position);
    const c1 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 19.35, y: 3.0 },
      { x: 16.5, y: 0.0 },
      { x: 12.0, y: 0.0 },
      elementSize,
      viewbox,
      position,
    );
    const c2 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 10.0, y: 0.0 },
      { x: 5.35, y: 1.4 },
      { x: 5.35, y: 6.04 },
      elementSize,
      viewbox,
      position,
    );
    const c3 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 1.0, y: 7.04 },
      { x: 0.0, y: 10.91 },
      { x: 0.0, y: 14.0 },
      elementSize,
      viewbox,
      position,
    );
    const c4 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 0.0, y: 3.31 },
      { x: 2.69, y: 6.0 },
      { x: 6.0, y: 6.0 },
      elementSize,
      viewbox,
      c3.ep,
    );

    const h = SvgCoordinateHelper.convertSvgCoordinate({ x: 13.0, y: 0.0 }, elementSize, viewbox, c4.ep);

    const c5 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 2.76, y: 0.0 },
      { x: 5.0, y: -2.24 },
      { x: 5.0, y: -5.0 },
      elementSize,
      viewbox,
      h,
    );
    const c6 = SvgCoordinateHelper.convertSvgCurveArgs(
      { x: 0.0, y: -2.64 },
      { x: -2.05, y: -4.78 },
      { x: -4.65, y: -4.96 },
      elementSize,
      viewbox,
      c5.ep,
    );

    return [
      [start.x, start.y],
      [
        [c1.cp1.x, c1.cp1.y],
        [c1.cp2.x, c1.cp2.y],
        [c1.ep.x, c1.ep.y],
      ],
      [
        [c2.cp1.x, c2.cp1.y],
        [c2.cp2.x, c2.cp2.y],
        [c2.ep.x, c2.ep.y],
      ],
      [
        [c3.cp1.x, c3.cp1.y],
        [c3.cp2.x, c3.cp2.y],
        [c3.ep.x, c3.ep.y],
      ],
      [
        [c4.cp1.x, c4.cp1.y],
        [c4.cp2.x, c4.cp2.y],
        [c4.ep.x, c4.ep.y],
      ],
      [h.x, h.y],
      [
        [c5.cp1.x, c5.cp1.y],
        [c5.cp2.x, c5.cp2.y],
        [c5.ep.x, c5.ep.y],
      ],
      [
        [c6.cp1.x, c6.cp1.y],
        [c6.cp2.x, c6.cp2.y],
        [c6.ep.x, c6.ep.y],
      ],
    ];
  }
}
