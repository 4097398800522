<div class="map-wrap" [class.showChooser]="(toggleOverlay$ | async)?.showChooser">
  <div
    [attr.id]="elementId"
    class="overflow-hidden"
    [style.width]="canvasSize.canvasWidth * 0.5 + 'px'"
    [style.height]="canvasSize.canvasHeight * 0.5 + 'px'"
  ></div>
</div>

<app-board-minimap-zoom
  #zoomElement
  [elementId]="elementId"
  [canvasSize]="canvasSize"
  [map]="map"
></app-board-minimap-zoom>
