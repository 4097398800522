<div *ngIf="assortmentHistoryItem && assortment">
  <div class="header">
    <div class="version-name" *ngIf="assortmentHistoryItem.versionName">
      <div class="assortment-name">{{ assortmentHistoryItem.versionName }}</div>
    </div>
    <div class="comments" *ngIf="assortmentHistoryItem.versionComments">
      {{ assortmentHistoryItem.versionComments }}
    </div>
    <div class="header-meta">
      <div class="change-event-info">
        <div class="actor">{{ getName() }}</div>
        <div class="timestamp">{{ assortmentHistoryItem.createdOn | date: 'medium' }}</div>
      </div>
      <div class="changes">
        <span class="change add" *ngIf="assortmentHistoryItem.adds"
          ><mat-icon>add</mat-icon>{{ assortmentHistoryItem.adds }}</span
        >
        <span class="change drop" *ngIf="assortmentHistoryItem.deletes"
          ><mat-icon>remove_circle_outline</mat-icon>{{ assortmentHistoryItem.deletes }}</span
        >
        <span class="change modify" *ngIf="assortmentHistoryItem.updates"
          ><mat-icon>change_history</mat-icon>{{ assortmentHistoryItem.updates }}</span
        >
      </div>
    </div>
  </div>
  <div class="changes-body">
    <div class="item-changes">
      <app-assortment-history-item-change
        [change]="change"
        changeType="add"
        *ngFor="let change of assortmentHistoryItem?.detail?.adds"
        (click)="setSelectedChange(change, 'add')"
      ></app-assortment-history-item-change>
      <app-assortment-history-item-change
        [change]="change"
        changeType="drop"
        *ngFor="let change of assortmentHistoryItem?.detail?.deletes"
        (click)="setSelectedChange(change, 'drop')"
      ></app-assortment-history-item-change>
      <app-assortment-history-item-change
        [change]="change"
        changeType="modify"
        *ngFor="let change of assortmentHistoryItem?.detail?.updates"
        (click)="setSelectedChange(change, 'modify')"
      ></app-assortment-history-item-change>
    </div>
    <div class="item-change-detail" *ngIf="selectedChange">
      <app-assortment-history-change-item-details [changeType]="selectedChangeType" [change]="selectedChange">
      </app-assortment-history-change-item-details>
    </div>
  </div>
</div>
