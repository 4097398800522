import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { BoardService } from '../board.service';
import { debounceTime, Observable, Subscription } from 'rxjs';
import { DocumentService } from '../document/document.service';
import {
  ACCENT_COLOR,
  CANVAS_COMPONENT_PADDING_B,
  CANVAS_COMPONENT_PADDING_T,
  CANVAS_COMPONENT_PADDING_X,
} from '../canvas/constants';
import { CanvasUtil } from '../canvas/canvas-util';
import { CoordinateBox } from '../canvas/coordinate-box';
import { ScaleTransformation, DocumentElement } from '@contrail/documents';
import { BoardMinimapZoomComponent } from './board-minimap-zoom/board-minimap-zoom.component';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../document/document-store';

@Component({
  selector: 'app-board-minimap',
  templateUrl: './board-minimap.component.html',
  styleUrls: ['./board-minimap.component.scss'],
})
export class BoardMinimapComponent implements AfterViewInit, OnDestroy {
  public toggleOverlay$: Observable<any>;
  public elementId = 'minimapCanvas';

  public canvasSize = {
    canvasWidth: 616,
    canvasHeight: 320,
    padding: 4,
  };
  public map: {
    dimensions?: CoordinateBox;
    offset?: any;
    viewScale?: ScaleTransformation;
  };

  private mapElement: HTMLCanvasElement;
  private mapElementContext;

  private canvasAspectRatio = this.canvasSize.canvasWidth / this.canvasSize.canvasHeight;
  private mapWidth = this.canvasSize.canvasWidth - 2 * this.canvasSize.padding;
  private mapHeight = this.canvasSize.canvasHeight - 2 * this.canvasSize.padding;

  private subscription: Subscription = new Subscription();
  private currentBoard;
  private readonly IGNORE_ELEMENT_TYPES = ['arrow', 'line', 'group'];

  @ViewChild(BoardMinimapZoomComponent) zoomElement: BoardMinimapZoomComponent;

  constructor(
    private boardService: BoardService,
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.toggleOverlay$ = this.store.select(DocumentSelectors.toggleChooser);
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.boardService.currentBoard.subscribe((board) => {
        this.currentBoard = board;

        this.addMinimapCanvas();
        this.updateMinimapCanvas();
        this.drawElements(this.currentBoard.document.elements);
        setTimeout(() => {
          this.zoomElement.drawZoom();
        });
      }),
    );

    this.subscription.add(
      this.documentService.documentElements.pipe(debounceTime(1000)).subscribe((elements) => {
        this.updateMinimapCanvas();
        this.drawElements(elements);
      }),
    );

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.mapElement.width = this.mapElement.height = 0;
    this.mapElement = null;
    this.subscription.unsubscribe();
  }

  private addMinimapCanvas() {
    this.mapElement = document.createElement('canvas');
    this.mapElementContext = this.mapElement.getContext('2d');
    document.getElementById(this.elementId).appendChild(this.mapElement);

    this.zoomElement.addZoomCanvas();
  }

  private updateMinimapCanvas() {
    if (!this.currentBoard || !this.mapElement) {
      return;
    }

    const commonBounds = this.documentService.getAllCommonBounds();
    if (!commonBounds) {
      return;
    }
    if (!this.map) {
      this.map = {};
    }
    this.map.dimensions = commonBounds;

    const aspectRatio = commonBounds.width / commonBounds.height;

    this.mapElement.width = aspectRatio <= this.canvasAspectRatio ? this.mapHeight * aspectRatio : this.mapWidth;
    this.mapElement.height = aspectRatio <= this.canvasAspectRatio ? this.mapHeight : this.mapWidth / aspectRatio;

    this.map.viewScale = {
      x: this.mapElement.width / this.map.dimensions.width,
      y: this.mapElement.height / this.map.dimensions.height,
    };
    this.map.offset = {
      x: this.canvasSize.canvasWidth * 0.5 - this.mapElement.width * 0.5,
      y: this.canvasSize.canvasHeight * 0.5 - this.mapElement.height * 0.5,
    };

    this.mapElement.style.width = `${this.mapElement.width * 0.5}px`;
    this.mapElement.style.height = `${this.mapElement.height * 0.5}px`;
    this.mapElement.style.position = 'absolute';
    this.mapElement.style.top = `${Math.max(this.canvasSize.padding * 0.5, (this.canvasSize.canvasHeight * 0.5 - this.mapElement.height * 0.5) * 0.5)}px`;
    this.mapElement.style.left = `${Math.max(this.canvasSize.padding * 0.5, (this.canvasSize.canvasWidth * 0.5 - this.mapElement.width * 0.5) * 0.5)}px`;
  }

  private drawElements(elements: DocumentElement[]) {
    if (!elements?.length || !this.map || !this.mapElementContext) {
      return;
    }
    this.mapElementContext.clearRect(0, 0, this.mapElement.width, this.mapElement.height);
    this.mapElementContext.setTransform(1, 0, 0, 1, 0, 0);
    this.mapElementContext.globalAlpha = 0.5;
    this.mapElementContext.fillStyle = ACCENT_COLOR;

    for (let i = 0; i < elements?.length; i++) {
      const element = elements[i];
      if (this.IGNORE_ELEMENT_TYPES.indexOf(element.type) === -1) {
        let size = element?.size;
        let position = element?.position;
        if (element.type === 'component') {
          if (element.modelBindings?.item) {
            const imageElement = element.elements.find((e) => e.type === 'image');
            if (imageElement) {
              size = {
                width: imageElement.size.width + 2 * CANVAS_COMPONENT_PADDING_X,
                height:
                  element.elements.reduce((acc, e) => acc + (e?.isHidden ? 0 : e?.size?.height || 0), 0) +
                  CANVAS_COMPONENT_PADDING_T +
                  CANVAS_COMPONENT_PADDING_B,
              };
              position = {
                x: position.x - CANVAS_COMPONENT_PADDING_T,
                y: position.y - CANVAS_COMPONENT_PADDING_X,
              };
            }
          } else if (element.modelBindings?.color) {
            size = element.elements[0].size;
          }
        }
        if (position?.x != null && position?.y != null && size?.width != null && size?.height != null) {
          const { x, y } = CanvasUtil.toWindowPosition(
            position.x,
            position.y,
            this.map.dimensions,
            this.map.viewScale,
            null,
            false,
          );
          const { width, height } = CanvasUtil.toWindowSize(size.width, size.height, this.map.viewScale);
          const angle = CanvasUtil.getAngle(element.rotate?.angle ?? 0);

          const w = width,
            h = height,
            cos = Math.cos(angle),
            sin = Math.sin(angle);

          this.mapElementContext.setTransform(cos, sin, -sin, cos, x + w * 0.5, y + h * 0.5);
          this.mapElementContext.fillRect(-w * 0.5, -h * 0.5, w, h);
        }
      }
    }
  }
}
