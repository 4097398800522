import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@components/components.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FrameTemplateCanvas } from './frame-template-canvas/frame-template-canvas.component';
import { FrameTemplateEditor } from './frame-template-editor/frame-template-editor.component';
import { FrameTemplateEditorModal } from './frame-template-editor-modal/frame-template-editor-modal.component';
import { FrameTemplateLibrary } from './frame-template-library/frame-template-library.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContextMenuModule } from '@components/context-menu/context-menu.module';
import { ListModule } from '@components/list/list.module';
import { FrameTemplateCardComponent } from './frame-template-card/frame-template-card.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

@NgModule({
  declarations: [
    FrameTemplateLibrary,
    FrameTemplateCanvas,
    FrameTemplateEditor,
    FrameTemplateEditorModal,
    FrameTemplateCardComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatMenuModule,
    ContextMenuModule,
    ListModule,
    MatSelectModule,
  ],
  exports: [FrameTemplateLibrary, FrameTemplateCanvas, FrameTemplateEditor, FrameTemplateEditorModal],
})
export class DocumentTemplatesModule {}
