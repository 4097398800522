import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: any = '';
  @Input() options: any[];
  @Input() label: string;
  @Input() displayProperty = 'name';
  @Input() isRequired = false;
  @Input() isDisabled = false;
  @Input() allowAdhocOptions = false;
  @Input() allowEnterKeyToSelectOption = false;
  @Input() hint;
  @Output() valueSelected = new EventEmitter();

  public listFormControl: UntypedFormControl;
  public filteredOptions: { option: any; displayValue: string }[];
  private destroy$ = new Subject();
  private formattedOptions: { option: any; displayValue: string }[] = [];

  ngOnInit(): void {
    this.setFormattedOptions();

    this.listFormControl = new UntypedFormControl(
      { value: this.value, disabled: this.isDisabled },
      this.isRequired ? Validators.required : null,
    );
    this.listFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(''),
        map((value) => {
          const queryValue = typeof value === 'string' ? value : ObjectUtil.getByPath(value, this.displayProperty);
          this.filteredOptions = this.filter(queryValue || '');
          return this.filteredOptions;
        }),
      )
      .subscribe();

    this.listFormControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchPhrase) => {
        if (searchPhrase === '') {
          this.valueSelected.emit(null);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue === false) {
        this.listFormControl?.enable();
      } else {
        this.listFormControl?.disable();
      }
    }
    if (changes.value) {
      this.listFormControl?.setValue(changes.value.currentValue);
    }
    if (changes.options) {
      this.setFormattedOptions();
      const selectedValue = this.value ? ObjectUtil.cloneDeep(this.value) : null;
      this.listFormControl?.reset(selectedValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * Set display value instead of getting it from the template
   */
  private setFormattedOptions() {
    if (this.options) {
      this.formattedOptions = this.options.map((option) => ({
        option,
        displayValue: this.getOptionDisplay(option),
      }));
    } else {
      this.formattedOptions = [];
    }
  }

  handleOptionSelected(event) {
    this.valueSelected.emit(event.option);
    if (this.allowEnterKeyToSelectOption) {
      this.listFormControl.setValue('');
    }
  }

  displayFn(object: any) {
    if (this.displayProperty) {
      return ObjectUtil.getByPath(object, this.displayProperty);
    }
    return object;
  }

  // Avoid calling this from the HTML template because it is
  // triggered on every change detection cycle and cause slow down the page
  getOptionDisplay(option) {
    return ObjectUtil.getByPath(option, this.displayProperty);
  }

  clearValue() {
    this.listFormControl.setValue('');
    this.valueSelected.emit(null);
  }

  private filter(value: string) {
    const filterValue = value?.toLowerCase();
    const filteredOptions = this.formattedOptions?.filter(({ option, displayValue }) =>
      displayValue.toLowerCase().includes(filterValue),
    );

    if (this.allowAdhocOptions && filteredOptions.length === 0 && value !== '') {
      const adHocOption = { [this.displayProperty]: value };
      filteredOptions.push({ option: adHocOption, displayValue: this.getOptionDisplay(adHocOption) });
    }

    return filteredOptions;
  }

  onEnter() {
    if (this.allowEnterKeyToSelectOption) {
      const value = this.listFormControl.value;
      if (value) {
        this.valueSelected.emit(value);
        this.listFormControl.setValue('');
      }
    }
  }
}
