<table>
  <th
    [ngClass]="{
      right: column.alignment === 'right',
      left: column.alignment === 'left',
      center: column.alignment === 'center'
    }"
    *ngFor="let column of columnDefinitions"
  >
    <ng-container *ngIf="!sortBy">{{ column.label }}</ng-container>
    <ng-container *ngIf="sortBy">
      <span class="label" (debounceClick)="sort(column)" appDebounceClick="100">
        {{ column.label }}
        <span *ngIf="column?.label && column.label === sortBy?.label" class="material-icons z-40">
          {{ sortBy?.direction === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </span>
        <span *ngIf="column?.label" class="material-icons z-50 hover-icon opacity-0">
          {{ sortBy?.direction === 'desc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </span>
      </span>
    </ng-container>
  </th>
  <tr
    *ngFor="let itemData of listData"
    [class.selected]="itemData?.selected"
    (click)="handleClickRow(itemData)"
    (dblclick)="handleDoubleClickRow(itemData)"
    (contextmenu)="handleContextMenu($event, itemData)"
  >
    <td
      [style.width.px]="column.width"
      [ngClass]="{
        right: column.alignment === 'right',
        left: column.alignment === 'left',
        center: column.alignment === 'center'
      }"
      *ngFor="let column of columnDefinitions"
    >
      <app-list-item-cell
        class="prevent-select"
        [listItemData]="itemData"
        [columnDefinition]="column"
      ></app-list-item-cell>
    </td>
  </tr>
</table>
