import { CanvasAppContext } from './canvas-document';
const pako = require('pako');

export interface IFileDownloader {
  downloadFileAsText(url: string): Promise<string>;
}

export interface FileDownloadAuthContext {
  apiToken: string;
  orgSlug: string;
}

const MAX_RETRIES = 3;

/** Class that can handle various types of file downloads, includes secure file downloads
 * from the VibeIQ API
 */
export class FileDownloader {
  constructor(
    private authContext: FileDownloadAuthContext,
    private appContext: CanvasAppContext,
  ) {}
  public async downloadAndUnzipFile(url: string) {
    const response = await this.downloadFile(url);
    if (response) {
      let unzipped;
      const buffer = await response.arrayBuffer();
      try {
        unzipped = pako.inflate(buffer, { to: 'string' });
      } catch (e) {
        console.log('Error unzipping file', e, response);
      }
      if (!unzipped) {
        try {
          unzipped = new TextDecoder().decode(buffer);
        } catch (e) {}
      }
      return unzipped;
    }
    return null;
  }
  public async downloadFileAsText(url: string) {
    const response = await this.downloadFile(url);
    return response.text();
  }
  public async downloadFileAsBlob(url: string) {
    const response = await this.downloadFile(url);
    return response.blob();
  }

  private async downloadFile(url, retries = 0): Promise<Response> {
    let headers: any = {};
    if (url.indexOf('/files') > 1 && this.authContext) {
      headers = {
        'x-api-key': this.authContext.apiToken,
        'x-api-org': this.authContext.orgSlug,
      };
    }
    let convertedUrl = FileDownloader.convertImageUrlForOrigin(url, this.appContext.imageHost);

    // Append a basic version parameter for cache control
    convertedUrl += '?v1.1';
    try {
      let response = await fetch(convertedUrl, { headers });
      if (response.status >= 400 && response.status < 600) {
        console.log('Error fetching url', convertedUrl, response);
        throw new Error('Bad response from server');
      }
      return response;
    } catch (error) {
      console.error('Error fetching url', convertedUrl, error);
      if (retries >= MAX_RETRIES) {
        throw error;
      } else {
        return this.downloadFile(url, ++retries);
      }
    }
  }

  /** Alters links that use 'api.vibeiq.com' to leverage the origin (showcase.vibeiq.com)
   * cloudfront proxies that have been set up for API urls.
   * This prevents the CORS checks on all image downloads.
   *
   * Pass a host to override the default origin.
   */
  public static convertImageUrlForOrigin(url, host = null) {
    const convertedUrl = new URL(url);

    // spacial handling for localhost
    // If we intend on using the local API, we need to strip out
    // the /dev/api or /prod/api to replace it with 'api
    if (host === 'localhost') {
      convertedUrl.pathname = convertedUrl.pathname.substring(
        convertedUrl.pathname.indexOf('/api'),
        convertedUrl.pathname.length,
      );
      convertedUrl.host = host;
      convertedUrl.port = '3000';
      convertedUrl.protocol = 'http';
      return convertedUrl.toString();
    }

    if (convertedUrl.pathname.indexOf('/dev/api') > -1) {
      convertedUrl.pathname =
        '/api-dev' +
        convertedUrl.pathname.substring(convertedUrl.pathname.indexOf('/api') + 4, convertedUrl.pathname.length);
    } else if (convertedUrl.pathname.indexOf('/prod/api') > -1) {
      convertedUrl.pathname =
        '/api' +
        convertedUrl.pathname.substring(convertedUrl.pathname.indexOf('/api') + 4, convertedUrl.pathname.length);
    }
    convertedUrl.host = host ? host : window.location.host;

    return convertedUrl.toString();
  }
}
