import { CanvasDocument } from '../../../canvas-document';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasElement } from '../../../elements/canvas-element';

export class GroupElementEventHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Listens to double-click of an element in a group to allow editing
   * @param event
   * @param elementTarget
   */
  public handleDblClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (elementTarget?.element?.isInGroup) {
      const group = this.canvasDocument.state.groupState.getGroupByMemberElementId(elementTarget?.element.id);
      if (group.element.isSelected) {
        setTimeout(() => {
          this.canvasDocument?.interactionHandler?.selectionHandler.selectElementAndDraw(
            elementTarget?.element,
            event.shiftKey,
          );
        }, 1);
      }
    }
  }
}
