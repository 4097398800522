<div class="section-header">Item</div>
<div class="chip" (click)="selectItem(itemFamily)" [ngClass]="{ selected: itemFamily?.id === selectedItem?.id }">
  {{ itemFamily?.name }}
</div>

<div class="sub-header">Options:</div>
<div class="options">
  <div
    (click)="selectItem(option)"
    *ngFor="let option of itemOptions?.color"
    class="chip"
    [ngClass]="{ selected: option?.id === selectedItem?.id }"
  >
    {{ option.optionName }}
  </div>
</div>
