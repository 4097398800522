import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { EditorManager } from './editor-manager';

const YKEY = 'KeyY';
const ZKEY = 'KeyZ';

export abstract class EditorContainer {
  public editorContainer: HTMLElement;
  public borderContainer: HTMLElement;
  public editorOverflow: HTMLElement;
  public editor: HTMLElement;
  public currentContainerHeight = 100;
  public currentContainerWidth = 100;

  constructor(
    protected canvasDocument: CanvasDocument,
    private editorId,
    protected manager: EditorManager,
  ) {
    this.editorContainer = document.createElement('div');
    this.editorContainer.id = `${editorId}-container`;
    this.editorContainer.style.position = 'absolute';
    this.editorContainer.style.width = '100px';
    this.editorContainer.style.height = '100px';
    this.editorContainer.style.left = '0px';
    this.editorContainer.style.top = '0px';
    this.editorContainer.style.visibility = 'hidden';
    this.editorContainer.style.cursor = 'move';
    this.editorContainer.style.zIndex = '2';

    this.borderContainer = document.createElement('div');
    this.borderContainer.id = `${editorId}-border`;
    this.borderContainer.style.width = '100%';
    this.borderContainer.style.height = '100%';
    this.borderContainer.style.cursor = 'move';
    this.editorContainer.appendChild(this.borderContainer);

    // This container is to hide the carot on Enter while we are auto growing container height
    // or auto font sizing.
    this.editorOverflow = document.createElement('div');
    this.editorOverflow.style.overflow = 'hidden';
    this.editorOverflow.style.width = '100%';
    this.editorOverflow.style.height = '100%';
    this.borderContainer.appendChild(this.editorOverflow);

    // This div will hold the tinymce editor
    this.editor = document.createElement('div');
    this.editor.id = this.editorId;
    this.editor.style.cursor = 'text';
    this.editor.style.margin = '0';
    this.editor.style.width = '100px';
    this.editor.style.height = '100px';
    this.editor.innerHTML = '';
    this.addEventListeners();

    this.editorOverflow.appendChild(this.editor);
    document.getElementById(this.canvasDocument.elementId).appendChild(this.editorContainer);
  }

  // Show the text editor that contains the tinymce editor
  public showEditor() {
    const dimensions = this.manager.element.getDimensions({ skipScale: true });
    const adjustedPositions = this.manager.element.toWindowPosition({ x: dimensions.x, y: dimensions.y }, false);
    const scale = this.canvasDocument.getViewScale();
    this.editorContainer.style.width = `${dimensions.width}px`;
    this.editorContainer.style.height = `${dimensions.height}px`;
    this.currentContainerHeight = dimensions.height;
    this.currentContainerWidth = dimensions.width;
    this.editorContainer.style.left = `${adjustedPositions.x}px`;
    this.editorContainer.style.top = `${adjustedPositions.y}px`;
    this.editorContainer.style.visibility = 'visible';

    this.editorContainer.style.transform = `scale(${scale.x * (this.manager.element.elementDefinition?.scale?.x || 1)})`;
    this.editorContainer.style.transformOrigin = 'top left';

    this.borderContainer.style.padding = `${this.manager.element.TEXT_PADDING}px`;
    this.borderContainer.style.borderWidth = `${this.manager.element.isPropertyValid('style.border') ? this.manager.element.elementDefinition.style?.border?.width ?? this.manager.element.DEFAULT_BORDER_SIZE : 0}px`;
    this.borderContainer.style.borderColor =
      this.manager.element.elementDefinition.style?.border?.color || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.borderStyle = this.manager.element.elementDefinition.style?.border?.style || 'solid';
    this.borderContainer.style.background =
      (this.manager.element.elementDefinition.type !== 'sticky_note' &&
        this.manager.element.elementDefinition.style?.backgroundColor) ||
      'rgba(0, 0, 0, 0)';
    this.borderContainer.style.transform = `rotate(${this.manager.element.elementDefinition?.rotate?.angle || 0}deg)`;

    if (!this.manager.element?.elementDefinition?.style?.text?.valign) {
      this.editor.style.width = '100%';
      this.editor.style.height = '100%';
    } else {
      this.editor.style.width = 'auto';
      this.editor.style.height = 'auto';
    }

    this.editor.innerHTML = this.manager.element.elementDefinition.text?.replace(/<br\s*\/?>/gi, '<p> </p>'); // replace all br tags
    this.setPlaceholder(this.manager.element, this.manager.element.elementDefinition.text);
  }

  public setPlaceholder(element: CanvasElement, text: string) {}

  redrawEditor() {
    if (this.manager.element) {
      setTimeout(() => {
        const scale = this.canvasDocument.getViewScale();
        const dimensions = this.manager.element.getDimensions({ skipScale: true });
        const elementPosition = { x: dimensions.x, y: dimensions.y };
        const adjustedPositions = this.manager.element.toWindowPosition(elementPosition, false);
        this.editorContainer.style.left = `${adjustedPositions.x}px`;
        this.editorContainer.style.top = `${adjustedPositions.y}px`;
        this.editorContainer.style.transform = `scale(${scale.x * (this.manager.element.elementDefinition?.scale?.x || 1)})`;
      }, 1);
    }
  }

  hideEditor() {
    if (this.editorContainer.style.visibility === 'hidden') {
      return;
    }
    this.editorContainer.style.visibility = 'hidden';
  }

  public addEventListeners() {
    // This is to perform drag on a text element when its edges during a mousedown
    this.editorContainer.addEventListener('mousedown', (event) => {
      this.manager.hideEditor();
      this.canvasDocument.interactionHandler.setInteractionCursor('body');
      this.canvasDocument.canvasDisplay.canvasDisplayElement.dispatchEvent(new MouseEvent('mousedown', event));
    });

    this.editor.addEventListener('mousedown', (event) => {
      event.stopPropagation();
    });

    this.editor.addEventListener('keydown', (event) => {
      // allow controlY and controlZ
      const undoRedoKey = [YKEY, ZKEY].indexOf(event.code) !== -1 && (event.ctrlKey || event.metaKey);
      if (!undoRedoKey) {
        event.stopPropagation();
      }
    });

    this.editor.addEventListener('paste', (event) => {
      event.stopPropagation();
    });
  }

  public refreshElement(element: CanvasElement) {
    this.borderContainer.style.padding = `${this.manager.element.TEXT_PADDING}px`;
    this.borderContainer.style.borderWidth = `${this.manager.element.isPropertyValid('style.border') ? this.manager.element.elementDefinition.style?.border?.width ?? this.manager.element.DEFAULT_BORDER_SIZE : 0}px`;
    this.borderContainer.style.borderColor = element.elementDefinition.style?.border?.color || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.borderStyle = element.elementDefinition.style?.border?.style || 'solid';
    this.borderContainer.style.background =
      (this.manager.element.elementDefinition.type !== 'sticky_note' &&
        element.elementDefinition.style?.backgroundColor) ||
      'rgba(0, 0, 0, 0)';

    if (!element?.elementDefinition?.style?.text?.valign) {
      this.editor.style.width = '100%';
      this.editor.style.height = '100%';
    } else {
      this.editor.style.width = 'auto';
      this.editor.style.height = 'auto';
    }

    const scale = this.canvasDocument.getViewScale();
    const dimensions = this.manager.element.getDimensions({ skipScale: true });
    this.editorContainer.style.width = `${dimensions.width}px`;
    this.editorContainer.style.height = `${dimensions.height}px`;
    this.editorContainer.style.transform = `scale(${scale.x * (this.manager.element.elementDefinition?.scale?.x || 1)})`;
    this.currentContainerHeight = dimensions.height;
    this.currentContainerWidth = dimensions.width;
    this.manager.editor.setContentStyle();
  }

  public remove() {
    this.editorContainer?.remove();
    this.borderContainer = null;
    this.editorContainer = null;
    this.editorOverflow = null;
    this.editor = null;
  }

  public handleInputEvent(event) {}

  public handleKeydownEvent(event) {}

  public tempSetVisible() {
    const editorContainerVisibility = this.editorContainer.style.visibility;
    if (editorContainerVisibility === 'hidden') {
      // Make visible if hidden
      this.editorContainer.style.visibility = 'visible';
    }
    return editorContainerVisibility;
  }

  public tempSetHidden(editorContainerVisibility) {
    if (editorContainerVisibility === 'hidden') {
      // Hide only if previously hidden
      this.editorContainer.style.visibility = 'hidden';
      this.manager.element = null;
      this.manager.elementDefinition = null;
    }
  }
}
