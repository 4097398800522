import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';

export class FrameSelectionHandler {
  constructor(public canvasDocument: CanvasDocument) {}

  /**
   * Handle selecting elements that are on the frame.
   * For each selected 'frame' element type get all elements
   * that belong to that frame.
   * @param event
   * @returns
   */
  public handleElementSelected(event): Array<CanvasElement> {
    const selectedElementsInFrame: CanvasElement[] = [];

    if (
      event?.selectedElements &&
      event.selectedElements.findIndex((e) => e.elementDefinition.type === 'frame') !== -1 &&
      this.canvasDocument.state?.frameState?.frames?.size > 0
    ) {
      event.selectedElements.forEach((canvasElement) => {
        if (canvasElement.elementDefinition.type === 'frame') {
          const frame = this.canvasDocument.state?.frameState?.frames.get(canvasElement.id);
          if (frame && frame.elements?.length > 0) {
            selectedElementsInFrame.push(
              ...frame.elements.map((frameElement) => {
                frameElement.canvasElement.isInFrame = frame.id;
                return frameElement.canvasElement;
              }),
            );
          }
        }
      });
    }

    return selectedElementsInFrame;
  }

  /**
   * Checks if selected elements only includes one frame and its children elements
   * and returns that frame.
   * @returns frame CanvasElement
   */
  public isOnlyFrameSelected(): CanvasElement {
    const selectedCanvasElements =
      this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
    return this.isOnlyFrameInElements(selectedCanvasElements.map((e) => e.elementDefinition));
  }

  public isOnlyFrameInElements(elements: DocumentElement[]): CanvasElement {
    const selectedFrames = elements.filter((element) => element.type === 'frame') || [];
    if (selectedFrames.length === 0 || selectedFrames.length > 1) {
      return null;
    }

    let isOnlyFrameSelected = true;
    const frameElements: Map<string, string> = this.canvasDocument?.state?.frameState?.frameElements;
    const frameId = selectedFrames[0].id;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.type !== 'frame' && frameElements?.get(element.id) !== frameId) {
        isOnlyFrameSelected = false;
        break;
      }
    }

    return isOnlyFrameSelected ? this.canvasDocument.getCanvasElementById(frameId) : null;
  }

  public onlyFramesInElements(elements: DocumentElement[]): CanvasElement[] {
    const selectedFrames = elements.filter((element) => element.type === 'frame') || [];
    if (selectedFrames.length === 0) {
      return null;
    }

    const selectedFramesIds = selectedFrames.map((e) => e.id);
    let isOnlyFrameSelected = true;
    const frameElements: Map<string, string> = this.canvasDocument?.state?.frameState?.frameElements;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.type !== 'frame' && selectedFramesIds.indexOf(frameElements?.get(element.id)) === -1) {
        isOnlyFrameSelected = false;
        break;
      }
    }

    return isOnlyFrameSelected ? selectedFramesIds.map((id) => this.canvasDocument.getCanvasElementById(id)) : null;
  }

  public deselectFrameMembers(element: CanvasElement) {
    const frameMembers = this.canvasDocument.state?.frameState?.frames?.get(element.elementDefinition.id);
    if (frameMembers?.elements?.length > 0) {
      frameMembers.elements.forEach((frameMember) => {
        if (frameMember?.canvasElement) {
          this.canvasDocument?.interactionHandler?.selectionHandler.deselect(frameMember.canvasElement);
        }
      });
    }
  }
}
