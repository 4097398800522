import { DrawOptions } from '../../../../renderers/canvas-renderer';
import { Table } from '../table/table';
import { TableCellState } from './table-cell-state';

export class TableCellRenderer {
  constructor(
    private table: Table,
    private state: TableCellState,
  ) {}

  public render(ctx, { x, y, width, height }, options?: DrawOptions) {
    this.state.cellsMap.forEach((cell) => {
      const column = this.table.column(cell.columnIndex);
      const row = this.table.row(cell.rowIndex);
      if (column && row) {
        if (
          cell.elementDefinition.style?.backgroundColor &&
          cell.elementDefinition.style.backgroundColor !== 'rgba(0,0,0,0)'
        ) {
          ctx.beginPath();
          ctx.rect(x + column.x, y + row.y, column.width, row.height);
          ctx.fillStyle = cell.elementDefinition.style?.backgroundColor;
          ctx.fill();
          ctx.closePath();
        }
        const size = cell.getSize(options); // get scaled size
        cell.render(
          ctx,
          {
            x: x + column.x,
            y: y + row.y,
            width: size.width,
            height: size.height,
          },
          options,
        );
      }
    });
  }
}
