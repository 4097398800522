import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SharedLinkService } from '@common/share-app/share-app.service';
import { Entities } from '@contrail/sdk';
import { SharedLinkDefinition, ShareLink } from '@common/share-app/interfaces';

@Component({
  selector: 'app-legacy-links',
  templateUrl: './legacy-links.component.html',
  styleUrls: ['./legacy-links.component.scss'],
})
export class LegacyLinksComponent implements OnDestroy {
  public links: any[];
  private sharedLinkDefinition: SharedLinkDefinition;

  @Output() close = new EventEmitter();

  constructor(
    private shareLinkService: SharedLinkService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LegacyLinksComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      links: ShareLink[];
      sharedLinkDefinition: SharedLinkDefinition;
    },
  ) {
    this.sharedLinkDefinition = data.sharedLinkDefinition;
    this.links = data?.links?.map((l) => {
      l.shareUrl = this.getShareUrl(l);
      return l;
    });
  }

  getShareUrl(link) {
    if (!link) {
      return '';
    }
    return `${this.sharedLinkDefinition.baseUrl}?st=${link.id}`;
  }

  showClipboardMessage() {
    this.snackBar.open('Link copied to clipboard', '', { duration: 2000 });
  }

  async deleteLink(link) {
    if (!link?.id) {
      return;
    }
    await this.shareLinkService.deleteShareLink(link.id);
    this.links = this.links.filter((l) => l.id !== link.id);
    this.snackBar.open('Removed share link', '', { duration: 2000 });
  }

  async handleAccessLevelChange($event, link) {
    const accessLevel = $event.value;
    if (!link?.id) {
      return;
    }
    const updatedLink = await this.shareLinkService.updateShareLinkAccessLevel(link, accessLevel);
    this.links = this.links.map((l) => (l.id === updatedLink.id ? { ...l, ...updatedLink } : l));
    this.snackBar.open('Updated access level', '', { duration: 2000 });
  }

  ngOnDestroy(): void {
    this.close.emit(this.links);
  }
}
