<span class="underline mb-3">{{ change.typeProperty?.label }}</span>
<div class="flex items-center">
  <div class="flex items-center" *ngIf="change.oldValue">
    <span class="mr-2">{{ formatValue(change.typeProperty, change.oldValue) }}</span>
    <mat-icon class="mr-2">chevron_right</mat-icon>
  </div>
  <div *ngIf="change.newValue">
    <span class="modify">{{ formatValue(change.typeProperty, change.newValue) }}</span>
  </div>
</div>
