export const ZOOM_STOP_POINTS_AS_PERCENT_ASC = [
  2, 3, 5, 10, 15, 20, 33, 50, 75, 100, 125, 150, 200, 250, 300, 400, 800,
];
export const MIN_ZOOM_PERCENTAGE = ZOOM_STOP_POINTS_AS_PERCENT_ASC[0];
export const MAX_ZOOM_PERCENTAGE = ZOOM_STOP_POINTS_AS_PERCENT_ASC[ZOOM_STOP_POINTS_AS_PERCENT_ASC.length - 1];

// How much user has to scroll to traverse the entire zoom range.
// Anywhere from 250-350 appears to accurate - these are roughly one full flick of the mouse wheel and one full pinch of the trackpad.
// We chose 700 because wanted something that felt a bit slower.
export const VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE = 700;

/*
 * To avoid many re-draws of the canvas, we collect the zoom events and only redraw the canvas after a certain amount of time. The ZOOM_BUFFER_TIME_MS.
 *
 * The MAX_DELTA_PER_ZOOM_BUFFER is the maximum delta that we will allow to be added to the buffer. If the delta is larger than this, we will cap it at this value.
 * This avoids the zooming to be too fast, which can be an issue if users have very sensitive mouse settings. So far, it appears to be
 * less of an issue with trackpad pinches.
 *
 * A typical trackpad pinch is something around 7-10, but there are multiple events in a single pinch
 * A single click of a mouse might be much larger (>100), but there is only one event. The delta likely depends on
 * the user's settings.
 *
 * Either way, these constants cap the maximum zoom speed to a level that our system can currently handle.
 */
const MAX_DELTA_PER_MS = 1;
export const MAX_DELTA_PER_ZOOM_BUFFER = 20;
export const ZOOM_BUFFER_TIME_MS = MAX_DELTA_PER_ZOOM_BUFFER * MAX_DELTA_PER_MS;

export const PAN_DEBOUNCE_TIME_MS = 10;
