import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { Editor } from '../../../components/editor/editor';
import { EditorCalc } from '../../../components/editor/editor-calc';
import { CanvasElement } from '../../canvas-element';
import {
  DEFAULT_TEXT_FONT_FAMILY,
  DEFAULT_TEXT_FONT_SIZE,
  TEXT_ALIGN,
  TEXT_VALIGN,
} from '../../text/editor/text-editor';
import { CanvasCellElement } from '../canvas-cell-element';
import { CanvasTableElement } from '../canvas-table-element';
import { TableEditorManager } from './table-editor-manager';

export const tableEditorId = 'table-editor';
export const tablePlaceholderId = 'table-placeholder';
export const TABLE_TEXT_PADDING = 5;
export const TABLE_TEXT_PLACEHOLDER = '';
export const DEFAULT_TABLE_TEXT_BORDER_SIZE = 0;

export class TableEditor extends Editor {
  constructor(canvasDocument: CanvasDocument, manager: TableEditorManager) {
    super(canvasDocument, tableEditorId, manager);
  }

  public setContentStyle() {
    if (!this.manager.element) {
      return;
    }
    if (this.manager.element) {
      this.manager.editorContainer.editor.style.fontSize =
        (this.manager.element.elementDefinition?.style?.font?.size || DEFAULT_TEXT_FONT_SIZE) + 'pt';
      this.manager.editorContainer.editor.style.fontFamily =
        this.manager.element.elementDefinition?.style?.font?.family || DEFAULT_TEXT_FONT_FAMILY;
      this.manager.editorContainer.editor.style.textAlign =
        this.manager.elementDefinition?.style?.text?.align || TEXT_ALIGN;

      const valign = this.manager.element.isPropertyValid('style.text.valign')
        ? this.manager.element.elementDefinition?.style?.text?.valign ?? TEXT_VALIGN
        : TEXT_VALIGN;
      const borderWidth = this.manager.element.isPropertyValid('style.border')
        ? this.manager.element.elementDefinition?.style?.border?.width ?? this.manager.element.DEFAULT_BORDER_SIZE
        : this.manager.element.DEFAULT_BORDER_SIZE;

      const scale = this.canvasDocument.getViewScale();
      const cellElement = this.manager.element as CanvasCellElement;
      const containerHeight = cellElement.table.rows[cellElement.rowIndex].originalHeight;
      const transform = EditorCalc.getVerticalTransform(
        valign,
        containerHeight,
        this.manager.element.TEXT_PADDING,
        borderWidth,
        this.manager.editorContainer.editor.clientHeight,
      );

      this.manager.editorContainer.editorContainer.style.transform = `scale(${scale.x * (this.manager.element.getScale()?.x || 1)})`;
      if (transform) {
        this.manager.editorContainer.editorContainer.style.transform =
          this.manager.editorContainer.editorContainer.style.transform + transform;
      }
    }
  }

  public getSaveData(): { textChanges: DocumentElement[]; textUndoChanges: DocumentElement[] } {
    if (!this.manager.elementDefinition) {
      return null;
    }
    const latestElementDefinition = this.manager.element.elementDefinition;
    // const latestElementDefinition = this.canvasDocument.state.getElementById(
    //   this.manager.elementDefinition.id,
    // )?.elementDefinition;
    const undoChangeElement = ObjectUtil.cloneDeep(latestElementDefinition);
    const editor = this.getEditor();
    if (!editor) {
      return;
    }
    const content = editor?.getContent(); // replace all br tags;
    if (latestElementDefinition && content !== latestElementDefinition.text) {
      // only saves when content is different
      this.manager.elementDefinition = ObjectUtil.cloneDeep(latestElementDefinition);
      this.manager.elementDefinition.text = content;
      const result: { textChanges: DocumentElement[]; textUndoChanges: DocumentElement[] } = {
        textChanges: [
          {
            id: this.manager.elementDefinition.id,
            type: this.manager.elementDefinition.type,
            text: this.manager.elementDefinition.text,
            size: this.manager.elementDefinition.size,
          },
        ],
        textUndoChanges: [undoChangeElement],
      };

      const cellElement: CanvasCellElement = this.manager.element as CanvasCellElement;
      const tableElement: CanvasTableElement = cellElement.table.element;
      const table: DocumentElement = tableElement.elementDefinition;
      const row: DocumentElement = tableElement.getRowElement(cellElement.rowIndex);
      const tableManager = this.manager as TableEditorManager;
      if (row.size.height !== tableManager.rowElementDefinition.size.height) {
        result.textChanges.push(
          {
            id: table.id,
            type: table.type,
            tableId: table.id,
            size: { ...table.size },
          },
          {
            id: row.id,
            type: row.type,
            tableId: row.tableId,
            size: { ...row.size },
          },
        );
        result.textUndoChanges.push(
          {
            id: table.id,
            type: table.type,
            tableId: table.id,
            size: { ...tableManager.tableElementDefinition.size },
          },
          {
            id: row.id,
            type: row.type,
            tableId: row.tableId,
            size: { ...tableManager.rowElementDefinition.size },
          },
        );
        tableManager.tableElementDefinition.size.height = table.size.height;
        tableManager.rowElementDefinition.size.height = row.size.height;
      }

      return result;
    }

    return null;
  }

  public emitTextEditorEvent(
    element?: CanvasTableElement | CanvasCellElement | { elementDefinition: DocumentElement },
  ) {
    let elementDefinition = element?.elementDefinition;
    if (!elementDefinition) {
      elementDefinition = this.manager?.elementDefinition;
    }
    const editor = this.getEditor();
    if (!editor) {
      return;
    }

    let listType = '';
    if (editor?.queryCommandState('InsertOrderedList')) {
      listType = 'decimal';
    } else if (editor?.queryCommandState('InsertUnorderedList')) {
      listType = 'circle';
    }
    const shouldQueryEditorCommand = editor && elementDefinition.type === 'cell';
    const textColor = shouldQueryEditorCommand ? this.getTextOrBgColor(editor.selection.getNode(), 'color') : null;
    this.canvasDocument.actionsDispatcher.handleDocumentTextElementEvent({
      element: this.isFocused ? elementDefinition : null,
      textFormat: {
        bold: shouldQueryEditorCommand ? (editor.queryCommandState('Bold') ? 'bold' : 'normal') : null,
        italic: shouldQueryEditorCommand ? (editor.queryCommandState('Italic') ? 'italic' : 'normal') : null,
        underline: shouldQueryEditorCommand ? (editor.queryCommandState('Underline') ? 'underline' : 'normal') : null,
        strikethrough: shouldQueryEditorCommand
          ? editor.queryCommandState('Strikethrough')
            ? 'strikethrough'
            : 'normal'
          : null,
        textColor,
        listType,
        fontFamily: elementDefinition?.style?.font?.family,
        textAlign: elementDefinition?.style?.text?.align,
        textValign: elementDefinition?.style?.text?.valign,
        fontSize: elementDefinition?.style?.font?.size,
        backgroundColor: elementDefinition?.style?.backgroundColor,
      },
    });
  }

  /**
   * Temporarily set element text content to editor (if cell)
   * to emit current text style from table, row, column or cell.
   * @param element
   */
  public getCurrentStyle(
    element: CanvasTableElement | CanvasCellElement | { elementDefinition: DocumentElement },
  ): void {
    const editor = this.getEditor();
    if (editor) {
      let editorContainerVisibility;
      const cellElement = element.elementDefinition.type === 'cell' ? (element as CanvasCellElement) : null;
      if (cellElement) {
        editorContainerVisibility = this.tempSetElement(cellElement);
      }
      this.emitTextEditorEvent(element);
      if (cellElement) {
        this.tempRemoveElement(editorContainerVisibility);
      }
    }
  }
}
