import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementFactory,
  PositionDefinition,
} from '@contrail/documents';
import { DocumentService } from '../../document.service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { Subscription, tap } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { DocumentDynamicTextUtil } from '../../document-text/document-dynamic-text/document-dynamic-text-util';
import { DocumentDynamicTextService } from '../../document-text/document-dynamic-text/document-dynamic-text.service';
import { TableService } from '../../../canvas/elements/table/table-manager/table.service';
import { FrameTemplateService } from '../../../document-templates/frame-template.service';

@Component({
  selector: 'app-document-add-frame',
  templateUrl: './document-add-frame.component.html',
  styleUrls: ['./document-add-frame.component.scss'],
})
export class DocumentAddFrameComponent implements OnInit, OnDestroy {
  @Input() tooltipPosition = 'right';
  public frames: Array<any> = [
    { id: '16_9', size: { width: 1200, height: 675 }, icon: 'crop_16_9', label: '16:9' },
    { id: '4_3', size: { width: 1024, height: 768 }, icon: 'crop_5_4', label: '4:3' },
    { id: '1_1', size: { width: 1080, height: 1080 }, icon: 'crop_square', label: '1:1' },
    { id: 'a4', size: { width: 595, height: 842 }, icon: 'insert_drive_file', label: 'A4' },
    { id: 'letter', size: { width: 612, height: 792 }, icon: 'insert_drive_file', label: 'Letter' },
    { id: '11_17', size: { width: 792, height: 1224 }, icon: 'crop_portrait', label: '11:17' },
  ];

  public templateTypes: any[] = [
    { value: 'private', label: 'Private' },
    { value: 'org', label: 'Organization' },
  ];
  public allFrameTemplates: any[] = [];
  public frameTemplates: any[] = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    public documentService: DocumentService,
    private frameTemplatesService: FrameTemplatesService,
    private store: Store<RootStoreState.State>,
    private documentDynamicTextService: DocumentDynamicTextService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(FrameTemplatesSelectors.frameTemplates)
        .pipe(
          tap((frameTemplates: FrameTemplate[]) => {
            if (this.frames[this.frames.length - 1].id !== 'templates') {
              this.frames.push({ id: 'templates', label: 'Templates' });
            }
            this.frameTemplates = ObjectUtil.cloneDeep(frameTemplates);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addNewFrame(frameSize) {
    const { position, size } = this.documentService.getNewFrameDimensions(frameSize);
    const options: DocumentElement = {
      name: `Frame ${this.documentService.getNewFrameNumber()}`,
      size,
      position,
      style: {
        backgroundColor: '#fff',
      },
    };
    const frameElement = DocumentElementFactory.createElement('frame', options);
    this.documentService.deselectAllElements();
    this.documentService.handleDocumentActions([
      new DocumentAction(
        {
          elementId: frameElement.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: frameElement,
        },
        {
          elementId: frameElement.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: frameElement,
        },
      ),
    ]);
  }

  public async createFrameFromTemplate(frameTemplate: FrameTemplate) {
    let selectedFrameTemplate: FrameTemplate = frameTemplate;
    this.store
      .select(FrameTemplatesSelectors.getFrameTemplateById(frameTemplate.id))
      .pipe(
        tap((frameTemplate: any) => {
          selectedFrameTemplate = ObjectUtil.cloneDeep(frameTemplate);
        }),
      )
      .subscribe();

    if (!frameTemplate.document.elements) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      selectedFrameTemplate = await this.frameTemplatesService.getFrameTemplateById(frameTemplate.id);
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
    const { position, size } = this.documentService.getNewFrameDimensions(selectedFrameTemplate.document.size);
    const options: DocumentElement = {
      name: `Frame ${this.documentService.getNewFrameNumber()}`,
      size,
      position,
      style: ObjectUtil.cloneDeep(frameTemplate.document.style),
    };
    if (selectedFrameTemplate.document.clipContent) {
      options.clipContent = true;
    }
    const frameElement = DocumentElementFactory.createElement('frame', options);
    const documentActions: DocumentAction[] = await this.generateFrameTemplateElements(
      selectedFrameTemplate,
      position,
      options,
    );
    documentActions.unshift(
      new DocumentAction(
        {
          elementId: frameElement.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: frameElement,
        },
        {
          elementId: frameElement.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: frameElement,
        },
      ),
    );
    this.documentService.deselectAllElements();
    this.documentService.handleDocumentActions(documentActions);
  }

  private async generateFrameTemplateElements(
    frameTemplate: FrameTemplate,
    position: PositionDefinition,
    options: any = {},
  ) {
    const templateElements = ObjectUtil.cloneDeep(frameTemplate.document.elements);
    const documentElements = FrameTemplateService.generateTemplateElements(templateElements, position);
    for (let documentElement of documentElements) {
      // set dynamic text
      if (DocumentDynamicTextUtil.isDynamicTextElement(documentElement)) {
        const textValue = await this.documentDynamicTextService.getTextValue(documentElement, {
          elements: [],
          element: { elementDefinition: { name: options?.name } },
        });
        this.documentDynamicTextService.adjustSizeAndStyling(documentElement, textValue);
      }
    }
    return documentElements.map((documentElement) => {
      return new DocumentAction(
        {
          elementId: documentElement.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: documentElement,
        },
        {
          elementId: documentElement.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: documentElement,
        },
      );
    });
  }
}
