import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { searchReplaceReducers } from './search-replace.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('searchReplace', searchReplaceReducers)],
})
export class SearchReplaceStoreModule {}
