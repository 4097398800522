import { NgModule } from '@angular/core';
import { SearchReplaceComponent } from './search-replace.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SearchReplaceComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatIconModule, MatButtonModule],
  providers: [],
  exports: [SearchReplaceComponent],
})
export class SearchReplaceModule {
  constructor() {}
}
