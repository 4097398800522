import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { ContentService } from '../content.service';
import { ContentHolderService } from '../content-holder.service';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ContentModalComponent } from '../content-modal/content-modal.component';

@Component({
  selector: 'app-entity-content-chooser',
  templateUrl: './entity-content-chooser.component.html',
  styleUrls: ['./entity-content-chooser.component.scss'],
})
export class EntityContentChooserComponent implements OnInit, OnChanges {
  @Input() contentHolder: any;
  @Output() panelClosed = new EventEmitter();
  @Input() resultsHeight = '100%';
  public content$: Observable<Array<any>>;
  constructor(
    public contentHolderService: ContentHolderService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.contentHolder) {
      const reference = this.contentHolder.entityType + ':' + this.contentHolder.id;
      this.content$ = this.contentHolderService.contentHolderContent$;
      this.contentHolderService.loadContentHolder(this.contentHolder, reference);
    }
  }

  startDrag(event, content) {
    const id = event.target.id;

    console.log('dragging: ', event, id, content);
    content.entityType = 'content';
    event.dataTransfer.setData('entity', JSON.stringify(content));
    event.dataTransfer.setData('dataObject', JSON.stringify(content));
  }

  handleClose() {
    this.panelClosed.emit();
  }

  handleDetailsClicked(content) {
    const accessLevel = 'EDIT';
    const contentId = content.id;
    const config = {
      data: { contentId, accessLevel, content },
      panelClass: [`no-padding`],
      maxWidth: '100vw',
      width: '98vw',
      height: '98vh',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ContentModalComponent, config);
  }
}
