import { PropertyType } from '@contrail/types';

export enum SortDirection {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export interface SortDefinition {
  propertySlug: string;
  direction: SortDirection;
  propertyLabel?: string;
  propertyType: PropertyType;
}
