import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { FileDownloader } from '../../file-downloader';
import { SVGHelper } from '../../svg-helper';
import { SVGCombiner } from '../svg/svg-combiner';
import { CanvasImageDrawableElement } from './canvas-image-drawable-element';

export class CanvasSVGElement extends CanvasImageDrawableElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable, false);
    this.isAsync = true;
    // this.isCropEnabled = true;
  }

  public onDeselect(): void {
    this.canvasDocument.actionsDispatcher.handleDocumentSvgElementEvent({
      element: this.elementDefinition,
      eventType: 'svgEditorExit',
    });
  }

  public toSVG({ x, y, width, height, svgHtmlString }): HTMLElement {
    const element = SVGCombiner.elementFromHTML(svgHtmlString);
    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);
    return element;
  }

  public async loadAsSVG({ x, y, width, height }): Promise<HTMLElement> {
    const authContext = await this.canvasDocument.getUserContext();
    const fileDownloader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      this.canvasDocument.appContext,
    );
    const svgHtmlString = await SVGHelper.getSVGTextFromDocumentElement(this.elementDefinition, fileDownloader);
    return this.toSVG({ x, y, width, height, svgHtmlString });
  }
}
