import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-document-history-header-button',
  templateUrl: './document-history-header-button.component.html',
  styleUrls: ['./document-history-header-button.component.scss'],
})
export class DocumentHistoryHeaderButtonComponent implements OnInit {
  constructor() {}
  @Output() openPanel = new EventEmitter();
  ngOnInit(): void {}

  showVersionHistory($event) {
    this.openPanel.emit('versionHistory');
  }

  showSourceHistory($event) {
    this.openPanel.emit('sourceChangeHistory');
  }
}
