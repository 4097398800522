import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddIFrameElementModalComponent } from './add-iframe-element-modal/add-iframe-element-modal.component';
import { DocumentService } from '../document.service';

@Component({
  selector: 'app-document-add-iframe',
  templateUrl: './document-add-iframe.component.html',
  styleUrls: ['./document-add-iframe.component.scss'],
})
export class DocumentAddIframeComponent {
  @Input() tooltipPosition = 'right';
  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
  ) {}

  public addNewIframe() {
    this.documentService.setInteractionMode('select');
    const config = { autoFocus: false, disableClose: true, width: '500px' };
    this.dialog.open(AddIFrameElementModalComponent, config);
  }
}
