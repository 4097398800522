import { DocumentElement, DocumentElementFactory, ViewBox } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../canvas-document';
import { CanvasElement } from '../elements/canvas-element';
import { CanvasElementFactory } from '../elements/canvas-element-factory';
import { CanvasFrameElement } from '../elements/frame/canvas-frame-element';
import { CanvasFrameState } from './canvas-frame-state';
import { DRAG_DIRECTIONS } from '../renderers/selection-widget-renderer/selection-widget-renderer';
import { CoordinateBox } from '../coordinate-box';
import { ANNOTATION_IMG_SIZE, ANNOTATION_PADDING_Y, SHAPE_ELEMENT_TYPES } from '../constants';
import { ChildElementDetails } from '../components/editor/editor-placeholder';
import { CanvasGroupState } from './canvas-group-state';
import { CanvasMaskState } from './canvas-mask-state';
import { DrawOptions } from '../renderers/canvas-renderer';
import { CanvasUtil } from '../canvas-util';
import pLimit from 'p-limit';
import { CanvasCropState } from './canvas-crop-state';
const limit = pLimit(30);

export class CanvasState {
  public background: CanvasElement;
  public canvasElements: CanvasElement[] = [];
  public canvasElementsMap: Map<string, CanvasElement> = new Map();
  public textElementChildrenMap: Map<string, Array<ChildElementDetails>> = new Map();

  public elementTarget: {
    element: CanvasElement;
    target: DRAG_DIRECTIONS;
  };

  public frameState: CanvasFrameState;
  public groupState: CanvasGroupState;
  public maskState: CanvasMaskState;
  public cropState: CanvasCropState;

  constructor(
    private canvasDocument: CanvasDocument,
    private restrictedViewablePropertySlugs?: string[],
  ) {
    this.frameState = new CanvasFrameState(this.canvasDocument);
    this.groupState = new CanvasGroupState(this.canvasDocument);
    this.maskState = new CanvasMaskState(this.canvasDocument);
    this.cropState = new CanvasCropState(this.canvasDocument);
  }

  public clear() {
    this?.canvasElements?.forEach((e) => {
      e?.clear();
      e?.innerElements?.forEach((i) => {
        i?.clear();
      });
    });
  }

  public registerBackground(background: DocumentElement[]) {
    if (background?.length > 0) {
      const element = background[0];
      element.style = ObjectUtil.mergeDeep(
        {
          backgroundColor: 'rgba(0,0,0,0)',
          border: {
            width: 1,
            color: 'rgba(0,0,0,0)',
          },
        },
        element.style || {},
      );
      const canvasElement = CanvasElementFactory.createCanvasElement(element, this.canvasDocument, false);
      if (['image', 'svg'].indexOf(canvasElement?.elementDefinition?.type) !== -1) {
        if (canvasElement?.elementDefinition?.url) {
          this.background = canvasElement;
        }
      } else {
        this.background = canvasElement;
      }
    }
  }

  public addElement(element: CanvasElement) {
    this.canvasElements.push(element);
    this.canvasElementsMap.set(element.id, element);
  }

  public deleteElements(ids: Array<string>) {
    const groupElements = this.canvasElements.filter(
      (element) => ids.includes(element.id) && element.elementDefinition.type === 'group',
    );
    groupElements.forEach((groupElement) => {
      this.groupState.deleteGroupElement(groupElement);
    });
    const maskIds = ids?.filter((id) => this.maskState?.getMask(id));
    if (maskIds?.length > 0) {
      this.maskState.deleteMaskElements(maskIds);
    }
    const editingTextElements = this.canvasElements.filter(
      (element) =>
        ids.includes(element.id) && element.isTextEditable && this.canvasDocument?.editorHandler?.isEditing(element),
    );
    if (editingTextElements?.length > 0) {
      // Hide editor if deleted element was being edited
      this.canvasDocument?.editorHandler?.hideEditor();
    }
    this.canvasElements = this.canvasElements.filter((element) => !ids.includes(element.id));
    ids.forEach((id) => {
      this.canvasElementsMap.delete(id);
    });
  }

  public getElementById(id): CanvasElement {
    return this.canvasElementsMap.get(id);
  }

  public reorderElements(elements: Array<DocumentElement>) {
    const elementsOrderMap: Map<string, number> = elements.reduce((map, element, i) => {
      map.set(element.id, i);
      return map;
    }, new Map());
    this.canvasElements
      .sort((a, b) => elementsOrderMap.get(a.id) - elementsOrderMap.get(b.id))
      .map((element) => {
        element.elementDefinition = ObjectUtil.cloneDeep(element.elementDefinition); // for some reason need to clone deep element definition to avoid assigning to read-only property error
        return element;
      });
    this.canvasElementsMap.clear();
    this.canvasElements.forEach((canvasElement) => {
      this.canvasElementsMap.set(canvasElement.elementDefinition.id, canvasElement);
    });
    this.frameState?.reorderElements(elements);
  }

  public updateElement(id, element: DocumentElement) {
    const canvasElement = this.canvasElementsMap.get(id);
    if (canvasElement) {
      canvasElement.elementDefinition = ObjectUtil.mergeDeep(
        ObjectUtil.cloneDeep(canvasElement.elementDefinition),
        element,
      );
      canvasElement.setDefaultValues();
      canvasElement.onChange();
      if (canvasElement.isTextEditable) {
        if (canvasElement.isSelected) {
          const isEditing = this.canvasDocument?.editorHandler?.isEditing(canvasElement);
          if (!isEditing) {
            // Refresh property config bar, send new scaled font size
            console.log('CanvasState.updateElement refresh config bar', canvasElement, isEditing);
            this.canvasDocument?.editorHandler?.getCurrentStyle(canvasElement);
          }
        }

        if (element.isLocked) {
          this.canvasDocument?.editorHandler?.hideEditor();
        } else {
          this.canvasDocument?.editorHandler?.refreshElement(canvasElement);
        }
      }
      if (element.elements?.length > 0) {
        canvasElement.innerElements = this.registerInnerElements(canvasElement.elementDefinition);
      }
      if (canvasElement.elementDefinition.type === 'group') {
        this.groupState.updateGroupElement(canvasElement);
      } else if (CanvasMaskState.isMask(canvasElement.elementDefinition) || this.maskState.getMask(canvasElement.id)) {
        this.maskState.updateMaskElement(canvasElement);
      }
      this.canvasElementsMap.set(id, canvasElement);
    }
  }

  public updateFrameElement(id, element: DocumentElement) {
    const canvasElement = this.canvasElementsMap.get(id);
    if (canvasElement?.elementDefinition?.type === 'frame') {
      this.frameState?.updateFrameBox(canvasElement.elementDefinition);
      if (element.size) {
        this.frameState?.updateFrameElements(canvasElement.elementDefinition);
      }
      if (element?.hasOwnProperty('clipContent')) {
        this.frameState?.frames?.get(canvasElement.elementDefinition.id)?.elements?.forEach((frameMember) => {
          frameMember.canvasElement.isInFrameMask = element.clipContent ? canvasElement.elementDefinition.id : false;
        });
      }
    } else {
      if (element.size || element.position || element.lineDefinition) {
        const frameId = this.frameState?.frameElements.get(canvasElement?.elementDefinition?.id);
        if (frameId) {
          this.frameState?.updateElement(canvasElement.elementDefinition);
        } else {
          if (canvasElement) {
            this.frameState?.addElementOnFrame(
              canvasElement,
              this.canvasDocument.state.getElementIndex(canvasElement.elementDefinition.id),
            );
          }
        }
      }
    }
  }

  public getElementIndex(id): number {
    return this.canvasDocument.state.canvasElements.findIndex((e) => e.id === id);
  }

  public registerElements(documentElements: Array<DocumentElement>, select = false, initial = true) {
    const currentDocumentId = this.canvasDocument.documentDefinition.id
      ? ObjectUtil.cloneDeep(this.canvasDocument.documentDefinition.id)
      : null;

    for (let i = 0; i < documentElements?.length; i++) {
      if (currentDocumentId && currentDocumentId !== this.canvasDocument.documentDefinition.id) {
        break;
      }
      const documentElement = documentElements[i];
      // Check for bound text elements that could be restricted.
      if (
        this.restrictedViewablePropertySlugs &&
        documentElement.type === 'text' &&
        documentElement.propertyBindings &&
        !this.restrictedViewablePropertySlugs.includes(documentElement.propertyBindings.text) &&
        !['projectItem.project.name', 'assortmentItem.assortment.name'].includes(documentElement.propertyBindings.text)
      ) {
        continue;
      }
      const canvasElement = CanvasElementFactory.createCanvasElement(
        documentElement,
        this.canvasDocument,
        this.canvasDocument.mode !== 'PREVIEW',
      );
      if (documentElement.type === 'frame') {
        this.frameState?.addNewFrame(
          canvasElement,
          initial ? i : this.canvasDocument.documentDefinition.elements.length + i,
        );
      } else if (documentElement.type === 'group') {
        this.groupState?.addGroupElement(canvasElement);
      } else if (CanvasMaskState.isMask(documentElement)) {
        this.maskState.addMask(canvasElement);
      }
      if (canvasElement) {
        canvasElement.setDefaultValues();
        if (documentElement?.elements?.length > 0) {
          // register inner elements before addElementOnFrame which needs component size information which at first is calculated using inner elements.
          canvasElement.innerElements = this.registerInnerElements(documentElement);
        }
        const frame =
          documentElement.type !== 'frame'
            ? this.frameState?.addElementOnFrame(
                canvasElement,
                initial ? i : this.canvasDocument.documentDefinition.elements.length + i,
              )
            : null;
        if (select) {
          if (canvasElement.elementDefinition.type === 'group') {
            this.canvasDocument.deselectAll();
          }
          this.canvasDocument.interactionHandler.selectionHandler.setSelected(canvasElement);
          if (
            canvasElement.isTextEditable &&
            documentElements.length === 1 &&
            canvasElement.elementDefinition.text === ''
          ) {
            setTimeout(() => {
              this.canvasDocument?.editorHandler?.showEditor(canvasElement); // allow text editing right after creation
            }, 1);
          }
          if (canvasElement.elementDefinition.type !== 'frame') {
            if (frame && documentElements.findIndex((e) => e.id === frame.id) !== -1) {
              canvasElement.isInFrame = frame.id;
            }
          }
        }
        this.addElement(canvasElement);
      }
    }
    this.canvasElements
      .filter((canvasElement) => canvasElement.elementDefinition.type === 'group')
      .forEach((groupElement) => {
        groupElement.elementDefinition.elementIds.forEach((elementId) => {
          const element = this.canvasElements.find((element) => element.id === elementId);
          if (element) {
            element.isInGroup = groupElement.id;
            this.canvasElementsMap.set(element.elementDefinition.id, element);
          }
        });
      });
    this.maskState.addAllMaskMembers();

    if (select) {
      this.canvasDocument.interactionHandler?.selectionHandler?.sendDocumentElementEvent();
    }

    this.canvasDocument.elementsAdded$.next(true);
  }

  public registerInnerElements(documentElement: DocumentElement) {
    const innerElements = [];
    let elements = [...documentElement.elements];

    if (elements?.length > 0) {
      if (!this.hasAnnotationElement(documentElement) && this.isItemComponent(documentElement)) {
        // hard-code annotation to the top if not in the element definition
        const elementsWithAnnotation = [
          {
            type: 'annotation',
            size: { width: ANNOTATION_IMG_SIZE, height: ANNOTATION_IMG_SIZE },
          },
        ].concat(ObjectUtil.cloneDeep(documentElement?.elements));
        elements = this.canvasDocument.updateSizeAndPositionForPropertyElements(
          elementsWithAnnotation,
          documentElement,
        );
      }

      for (let i = 0; i < elements?.length; i++) {
        const innerElement = elements[i];
        // Checks for bound inner text elements that could be restricted.
        if (
          this.restrictedViewablePropertySlugs &&
          innerElement.type === 'text' &&
          innerElement.propertyBindings &&
          !this.restrictedViewablePropertySlugs.includes(innerElement.propertyBindings.text) &&
          !['projectItem.project.name', 'assortmentItem.assortment.name'].includes(innerElement.propertyBindings.text)
        ) {
          continue;
        }
        const canvasElement = CanvasElementFactory.createCanvasElement(innerElement, this.canvasDocument, false);
        if (canvasElement) {
          canvasElement.isPartOfComponent = documentElement.id;
          innerElements.push(canvasElement);
        }
      }
    }
    return innerElements;
  }

  public async preloadElements(options: DrawOptions) {
    const promises = [];
    if (this.background?.isAsync) {
      promises.push(await this.background.preload());
    }
    for (let i = 0; i < this.canvasElements?.length; i++) {
      const element = this.canvasElements[i];
      if (element?.isAsync) {
        const promise = limit(async () => {
          await element.preload(options);
        });
        promises.push(promise);
      }
    }
    return await Promise.all(promises);
  }

  public getCommonBounds(elements: CanvasElement[], options?: DrawOptions): CoordinateBox {
    if (!elements?.length) {
      return { x: 0, y: 0, width: 0, height: 0, top: 0, bottom: 0, right: 0, left: 0 };
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.isInMask && !this.canvasDocument?.state?.maskState?.isEditingMask(element.isInMask as string)) {
        // Do not use element if it's masked and the mask is currently not being edited
        continue;
      }

      const { x, y, width, height } = element.getBoundingClientRectRotated(options);
      if (CanvasUtil.isValidDimensions({ x, y, width, height })) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + width);
        maxY = Math.max(maxY, y + height);
      }
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      top: minY,
      bottom: maxY,
      right: maxX,
      left: minX,
    };
  }

  public getElementsInBox(box: ViewBox): CanvasElement[] {
    const boxPosition = this.canvasDocument.toDocumentPosition(box.x, box.y);
    const boxSize = this.canvasDocument.toDocumentSize(box.width, box.height);
    const boxLeft = boxPosition.x;
    const boxRight = boxPosition.x + boxSize.width;
    const boxTop = boxPosition.y;
    const boxBottom = boxPosition.y + boxSize.height;
    let lastFrameIndex = -1;
    const elementsInBox = [];
    const frameElementIds: string[] = [];
    const elementsOnFrameMap: Map<string, string> = new Map();
    this.canvasDocument.state.canvasElements.forEach((element, index) => {
      const { x, y, width, height } = element.getBoundingClientRectRotated({ maskedDimensions: true });
      const inBox =
        x != null &&
        y != null &&
        element?.interactable &&
        !element?.isInMask &&
        (element.elementDefinition.type === 'frame' || element.elementDefinition.isLocked
          ? boxBottom >= y + height && boxTop <= y && boxRight >= x + width && boxLeft <= x
          : !(boxBottom < y || boxTop > y + height || boxRight < x || boxLeft > x + width));
      if (inBox) {
        const elementFrameId = this.canvasDocument.state.frameState.frameElements.get(element.id);
        if (elementFrameId) {
          elementsOnFrameMap.set(element.id, elementFrameId);
        }
        if (element.elementDefinition.type === 'frame') {
          frameElementIds.push(element.id);
        }
        if (!element.isInGroup) {
          // An element could be in a group that is on a frame.  Do not select the element.
          if (
            !elementsOnFrameMap.get(element.id) ||
            (elementsOnFrameMap.get(element.id) &&
              !this.canvasDocument.state.frameState.frames.get(elementsOnFrameMap.get(element.id)).element.isInGroup)
          ) {
            elementsInBox.push({ element, index });
          }
        }
      }
      // If dragging to select on top of frame do not select elements behind the frame
      const isBoxOnFrame = element.elementDefinition.type === 'frame' && element.isPointOnElement(boxLeft, boxTop);
      if (isBoxOnFrame) {
        lastFrameIndex = index;
      }
    });
    return elementsInBox
      .filter(
        ({ element, index }) =>
          (lastFrameIndex === -1 ? element : index >= lastFrameIndex) &&
          // do not select elements on frame if the frame is already selected.
          !frameElementIds.includes(elementsOnFrameMap.get(element.id) || ''),
      )
      .map(({ element, index }) => element);
  }

  public getElementInPosition(x, y): CanvasElement {
    const elements: CanvasElement[] = this.canvasDocument.state.canvasElements;
    let lastElement;
    for (let i = elements?.length - 1; i >= 0; i--) {
      const element = elements[i];
      if (element.isPointOnElement(x, y)) {
        lastElement = element;
        break;
      }
    }
    return lastElement;
  }

  public getVisibleElements() {
    const viewBox = this.canvasDocument.getViewBox();
    return this.canvasElements.filter((canvasElement) => canvasElement.isVisibleElement(viewBox));
  }

  private static isTransparentShape(element: CanvasElement): boolean {
    return (
      SHAPE_ELEMENT_TYPES.filter((value) => ['text', 'line', 'arrow'].indexOf(value) === -1).indexOf(
        element.elementDefinition.type,
      ) !== -1 &&
      (element?.elementDefinition?.style?.backgroundColor === 'rgba(0,0,0,0)' ||
        !element?.elementDefinition?.style?.backgroundColor)
    );
  }

  public getElementTarget(posX, posY): { element: CanvasElement; target: DRAG_DIRECTIONS } {
    const { x, y } = this.canvasDocument.toDocumentPosition(posX, posY);
    const elements: CanvasElement[] = this.canvasDocument.state.canvasElements; //filter((e) => !e.isSelected);
    const selectedElements = this.canvasDocument.state.canvasElements.filter((e) => e.isSelected);
    const commonBounds = this.canvasDocument.canvasRenderer.multipleElementsSelectionWidgetRenderer.commonBounds;
    let target;
    let lastElement: CanvasElement;
    if (commonBounds) {
      const direction = this.canvasDocument.canvasRenderer.multipleElementsSelectionWidgetRenderer.getDragHandle(x, y);
      target = direction;
    }

    if (selectedElements?.length === 1) {
      const element = selectedElements[0];
      const isEditingCrop = this.canvasDocument.isCropping(element.id);
      if (isEditingCrop) {
        const direction = this.canvasDocument.canvasRenderer?.cropBoxRenderer?.getDragHandle(x, y);
        if (direction) {
          lastElement = element;
          target = direction;
        }
      }
      if (
        (!isEditingCrop || (isEditingCrop && !target)) &&
        element.isSelected &&
        !element.elementDefinition.isLocked &&
        !element.isImageError
      ) {
        const direction = element.getDragHandle(x, y, true);
        if (direction) {
          lastElement = element;
          target = direction;
        }
        if (isEditingCrop && !target) {
          if (element.isPointOnElement(x, y, 0, { uncroppedDimensions: true })) {
            lastElement = element;
            target = DRAG_DIRECTIONS.CROP_BODY;
          }
        }
      }
    }

    let transparentShape: CanvasElement;
    const elementsBehindTransparent: CanvasElement[] = [];

    if (!target) {
      for (let i = elements?.length - 1; i >= 0; i--) {
        const element = elements[i];
        if (element.elementDefinition.type === 'frame' && this.canvasDocument?.interactionHandler?.isSelect()) {
          const hit = (element as CanvasFrameElement).isPointOnFrame(x, y);
          if (hit) {
            lastElement = element;
            target = hit === 'frameName' ? DRAG_DIRECTIONS.EDIT : DRAG_DIRECTIONS.BODY;
            break;
          }
        }

        const maskedBy = this.canvasDocument.state.maskState.getMaskByMemberId(element.elementDefinition.id);
        // Skip element if it's masked and if the mask is not currently being edited
        if (maskedBy && !this.canvasDocument.state.maskState.isEditingMask(maskedBy.id)) {
          continue;
        }

        if (element.isSelected && !element.isInFrame && !element.elementDefinition.isLocked && !element.isImageError) {
          const direction = element.getDragHandle(x, y, selectedElements.length === 1);
          if (direction) {
            lastElement = element;
            target = direction;
            break;
          }
        }

        if (element.isPointOnElement(x, y) && element.isDragEnabled) {
          if (element.elementDefinition.type === 'frame') {
            break;
          } else {
            if (!transparentShape) {
              if (!CanvasState.isTransparentShape(element)) {
                lastElement = element;
                target = DRAG_DIRECTIONS.BODY;
                break;
              } else {
                transparentShape = element;
              }
            } else {
              elementsBehindTransparent.push(element);
            }
          }
        }
      }
    }

    if (!lastElement?.isSelected && transparentShape) {
      let smallestElement: CanvasElement;
      for (let i = 0; i < elementsBehindTransparent?.length; i++) {
        const element = elementsBehindTransparent[i];
        if (element.getArea() / transparentShape.getArea() < 0.9) {
          smallestElement = element;
        }
        if (!CanvasState.isTransparentShape(element)) {
          break;
        }
      }
      if (smallestElement) {
        lastElement = smallestElement;
      } else {
        lastElement = transparentShape;
      }
      target = DRAG_DIRECTIONS.BODY;
    }

    this.elementTarget = { element: lastElement, target };
    return this.elementTarget;
  }

  setTextLinkElementChildren(id: string, childElemDetails: Array<ChildElementDetails>) {
    this.textElementChildrenMap.set(id, childElemDetails);
  }

  getTextLinkElementChildren(id: string): Array<ChildElementDetails> {
    return this.textElementChildrenMap.get(id);
  }

  hasAnnotationElement(documentElement: DocumentElement) {
    return (
      documentElement.type === 'component' && documentElement.elements.find((element) => element.type === 'annotation')
    );
  }

  isItemComponent(documentElement: DocumentElement) {
    return documentElement.type === 'component' && documentElement.modelBindings.item;
  }
}
