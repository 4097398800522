import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { UserSession } from '../user-session';
import { UserSessionsService } from '../user-sessions-service';
import * as UserSessionActions from './user-sessions.actions';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';

@Injectable()
export class UserSessionsEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private userSessionService: UserSessionsService,
    private store: Store<RootStoreState.State>,
  ) {}

  joinUserSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSessionActions.UserSessionActionTypes.JOIN_USER_SESSION),
        tap((action: any) => this.webSocketService.joinSession(action.sessionId)),
      ),
    { dispatch: false },
  );

  loadUserSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSessionActions.UserSessionActionTypes.LOAD_REMOTE_USER_SESSIONS),
      switchMap((action: any) => {
        return from(this.userSessionService.loadUserSessions(action.sessionId)).pipe(
          map((data) => {
            this.store.dispatch(UserSessionActions.setSyncingRemoteUsers({ syncingRemoteUsers: false }));
            return UserSessionActions.loadRemoteUsersSuccess({ data });
          }),
          catchError((error) => observableOf(UserSessionActions.loadRemoteUsersFailure({ error }))),
        );
      }),
    ),
  );

  syncRemoteUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSessionActions.UserSessionActionTypes.SYNC_REMOTE_USER_SESSIONS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          // Sometimes there is race condition when users load boards
          // at the same time - some users do not join connection/
          // This is one safe guard to reload remote sessions which is triggered on remote mouse move.
          if (
            !store.userSessions.syncingRemoteUsers &&
            store?.userSessions?.currentSessionId === action.data?.sessionId &&
            !store?.userSessions?.userSessions?.find(
              (obj) => obj.user.clientId === action?.data?.context?.user?.clientId,
            )
          ) {
            console.log(
              'Syncing remote users',
              store.userSessions.syncingRemoteUsers,
              action.data,
              store.userSessions.currentSessionId,
              store.userSessions.userSessions,
            );
            this.store.dispatch(UserSessionActions.setSyncingRemoteUsers({ syncingRemoteUsers: true }));
            this.store.dispatch(UserSessionActions.loadRemoteUsers({ sessionId: store.userSessions.currentSessionId }));
          }
        }),
      ),
    { dispatch: false },
  );
}
