import { CanvasDocument } from '../../canvas-document';
import { Mask } from '../../state/canvas-mask-state';
import { DrawOptions } from '../canvas-renderer';

export class CanvasElementsRenderer {
  constructor(private canvasDocument: CanvasDocument) {}

  public draw(options?: DrawOptions) {
    const visibleElements = this.canvasDocument.state.getVisibleElements();
    const selectedElements =
      this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedUnlockedElementsByFrame();
    const ctx = this.canvasDocument.canvasDisplay.context;

    const multiSelected = selectedElements?.length > 1 ? true : false;
    visibleElements.forEach((element) => {
      if (element.isHighlighted) {
        element.drawHighlightWidget(ctx);
      }
      element.drawElement(ctx, options);
      // if multiple elements selected
      if (multiSelected && element.isSelected && !element.isInFrame) {
        element.drawSelectionWidget(ctx, false);
      }
      if (element.isOutlined) {
        element.drawOutlineWidget(ctx);
      }
    });

    if (selectedElements?.length === 1) {
      const element = selectedElements[0];
      this.canvasDocument.canvasRenderer.maskBoxRenderer.draw();
      if (!element.isInFrame) {
        element.drawSelectionWidget(ctx, selectedElements?.length === 1 && element.isResizeEnabled);
        if (element.isInGroup) {
          // draw selection widget without handle to mark the boundary of the group
          const mask: Mask = this.canvasDocument.state?.maskState?.isMaskOrMaskMember(element.id);
          // Do not draw group selection widget if we are editing mask inside the group
          if (!mask || !this.canvasDocument.state.maskState.isEditingMask(mask.id)) {
            const group = this.canvasDocument.state.groupState.getGroupByMemberElementId(element.id);
            group.element.drawSelectionWidget(ctx, false);
          }
        }
      }

      if (element.isOutlined) {
        element.drawOutlineWidget(ctx);
      }

      const isEditingCrop = this.canvasDocument.state.cropState.isEditingCrop;
      isEditingCrop && this.canvasDocument.canvasRenderer.cropBoxRenderer.draw();
      if (!isEditingCrop && element.isRotationEnabled && element.elementDefinition.id !== 'gridSpace') {
        element.drawRotationWidget(ctx);
      }
    }

    return visibleElements;
  }
}
