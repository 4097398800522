import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR, EDITING_COLOR } from '../../constants';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasComponentElement } from '../../elements/component/canvas-component-element';
import { RotationHelper } from '../rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS, SelectionWidgetRenderer } from './selection-widget-renderer';

export class ComponentSelectionWidgetRenderer extends SelectionWidgetRenderer {
  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {
    super(canvasDocument, element);
  }

  public draw(ctx, drawHandles = true) {
    const { x, y } = this.element.getPosition();
    const { width, height } = (this.element as CanvasComponentElement).documentSize || this.element.getSize();

    ctx.save();
    ctx.beginPath();

    ctx.translate(x + width * 0.5, y + height * 0.5);
    ctx.rotate(CanvasUtil.getAngle(this.element.elementDefinition?.rotate?.angle ?? 0));

    ctx.rect(
      -width * 0.5 - this.element.PADDING_LEFT,
      -height * 0.5 - this.element.PADDING_TOP,
      width + (this.element.PADDING_LEFT + this.element.PADDING_RIGHT),
      height + (this.element.PADDING_TOP + this.element.PADDING_BOTTOM),
    );

    ctx.strokeStyle = this.useEditingColor ? EDITING_COLOR : ACCENT_COLOR;
    ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
    ctx.stroke();
    ctx.closePath();

    if (drawHandles) {
      const dragHandleWidth = this.getHandleWidth();
      const dragHandlePositions = [
        [-width * 0.5 - this.element.PADDING_LEFT, -height * 0.5 - this.element.PADDING_TOP],
        [width * 0.5 + this.element.PADDING_LEFT, -height * 0.5 - this.element.PADDING_TOP],
        [width * 0.5 + this.element.PADDING_LEFT, height * 0.5 + this.element.PADDING_BOTTOM],
        [-width * 0.5 - this.element.PADDING_LEFT, height * 0.5 + this.element.PADDING_BOTTOM],
      ];

      for (let i = 0; i < dragHandlePositions?.length; i++) {
        const dragHandlePosition = dragHandlePositions[i];
        this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
      }
    }

    ctx.restore();
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    let direction;

    const position = this.element.getPosition();
    const size = (this.element as CanvasComponentElement)?.documentSize || this.element.getSize();
    if (!size) {
      return;
    }
    const x = position.x - this.element.PADDING_LEFT;
    const y = position.y - this.element.PADDING_TOP;
    const width = size.width + (this.element.PADDING_LEFT + this.element.PADDING_RIGHT);
    const height = size.height + (this.element.PADDING_TOP + this.element.PADDING_BOTTOM);

    if (this.element.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -this.element.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }

    const top = this.isOnDragHandle(py, y);
    const left = this.isOnDragHandle(px, x);
    const right = this.isOnDragHandle(px, x + width);
    const bottom = this.isOnDragHandle(py, y + height);
    const horizontalLeft = x < px && x + width * 0.5 >= px;
    const horizontalRight = x + width * 0.5 < px && x + width > px;
    const verticalTop = y < py && y + height * 0.5 >= py;
    const verticalBottom = y + height * 0.5 < py && y + height > py;

    switch (true) {
      case left && top:
      case left && verticalTop:
      case horizontalLeft && top:
        direction = DRAG_DIRECTIONS.TOP_LEFT;
        break;
      case right && top:
      case right && verticalTop:
      case horizontalRight && top:
        direction = DRAG_DIRECTIONS.TOP_RIGHT;
        break;
      case left && bottom:
      case left && verticalBottom:
      case horizontalLeft && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_LEFT;
        break;
      case right && bottom:
      case right && verticalBottom:
      case horizontalRight && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_RIGHT;
        break;

      default:
        break;
    }

    return direction;
  }
}
