import { Action } from '@contrail/actions';
import { DocumentContentChangeType } from './document-content-change';

export class DocumentContentAction extends Action {
  public changeDefinition: DocumentContentChangeType;
  public undoChangeDefinition: DocumentContentChangeType;
  constructor(actionType, change, undoChange = null) {
    super(actionType, change, undoChange);
  }
}
