import { DocumentElement, PositionDefinition, SizeDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';

export class CanvasLineElement extends CanvasElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
      border: {
        width: this.DEFAULT_BORDER_SIZE,
        color: this.DEFAULT_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    const line = this.elementDefinition.lineDefinition;
    if (!line) {
      return;
    }

    const scale = options?.scaleBy ?? 1;
    const x1 = line.x1 / scale - (x + width * 0.5);
    const x2 = line.x2 / scale - (x + width * 0.5);
    const y1 = line.y1 / scale - (y + height * 0.5);
    const y2 = line.y2 / scale - (y + height * 0.5);

    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);

    this.stroke(ctx, options);
    ctx.closePath();

    if (this.elementDefinition.lineDefinition?.markerEnd) {
      const lineAngle = Math.atan2(y2 - y1, x2 - x1);
      this.drawArrow(ctx, x2, y2, lineAngle);
    }
    if (this.elementDefinition.lineDefinition?.markerStart) {
      const lineAngle = Math.atan2(y1 - y2, x1 - x2);
      this.drawArrow(ctx, x1, y1, lineAngle);
    }
    return;
  }

  public getPosition(options?: DrawOptions): PositionDefinition {
    if (!this.elementDefinition.lineDefinition) {
      return { x: 0, y: 0 };
    }
    const position = {
      x: Math.min(this.elementDefinition.lineDefinition.x1, this.elementDefinition.lineDefinition.x2),
      y: Math.min(this.elementDefinition.lineDefinition.y1, this.elementDefinition.lineDefinition.y2),
    };
    return this.getMaskedPosition(options, position);
  }

  public getSize(options?: DrawOptions): SizeDefinition {
    if (!this.elementDefinition.lineDefinition) {
      return { width: 0, height: 0 };
    }
    const size = {
      width: Math.abs(this.elementDefinition.lineDefinition.x1 - this.elementDefinition.lineDefinition.x2),
      height: Math.abs(this.elementDefinition.lineDefinition.y1 - this.elementDefinition.lineDefinition.y2),
    };
    return this.getMaskedSize(options, size);
  }

  private d(x1, x2, y1, y2) {
    return CanvasUtil.distance(x1, x2, y1, y2);
  }

  private getLineDefinition(options?: DrawOptions) {
    let { x1, y1, x2, y2 } = this.elementDefinition.lineDefinition;
    if (
      options?.maskedDimensions &&
      (!this.isSelected || (this.isSelected && this.isInFrame)) &&
      this.isInFrameMask &&
      this.elementDefinition.type !== 'frame'
    ) {
      const position = this.getPosition(options);
      const size = this.getSize(options);
      x1 = position.x;
      y1 = position.y;
      x2 = position.x + size.width;
      y2 = position.y + size.height;
    }
    return { x1, y1, x2, y2 };
  }

  public isPointOnElement(x, y) {
    if (!this.elementDefinition.lineDefinition) {
      return false;
    }
    const { x1, y1, x2, y2 } = this.getLineDefinition({ maskedDimensions: true });
    const width = this.elementDefinition.style?.border?.width || this.DEFAULT_BORDER_SIZE;
    return this.d(x1, y1, x, y) + this.d(x2, y2, x, y) - this.d(x1, y1, x2, y2) <= width * 0.5;
  }

  private drawArrow(ctx, x, y, lineAngle) {
    if (
      this.elementDefinition?.style?.border?.color != 'rgba(0,0,0,0)' &&
      this.elementDefinition?.style?.border?.width != 0
    ) {
      const arrowHypotenuse = 6 * ctx.lineWidth;
      const arrowAngle = Math.PI / 7;
      ctx.beginPath();

      // start drawing from the arrow tip
      ctx.moveTo(x, y);

      // draw line to the bottom-right corner of the arrow
      ctx.lineTo(
        x - Math.cos(lineAngle - arrowAngle) * arrowHypotenuse,
        y - Math.sin(lineAngle - arrowAngle) * arrowHypotenuse,
      );

      // draw line to the bottom-left corner of the arrow
      ctx.lineTo(
        x - Math.cos(lineAngle + arrowAngle) * arrowHypotenuse,
        y - Math.sin(lineAngle + arrowAngle) * arrowHypotenuse,
      );

      ctx.closePath();
      ctx.fillStyle = ctx.strokeStyle;
      ctx.fill();
      ctx.stroke();
    }
  }

  public toSVG({ x1, y1, x2, y2 }): HTMLElement {
    const element = document.createElement('path');
    element.setAttribute('fill', 'none');
    element.setAttribute('d', `M ${x1},${y1} L ${x2},${y2}`);
    this.setSVGStrokeAttribute(element);

    const borderColor = this.elementDefinition.style?.border?.color;
    const borderWidth = this.elementDefinition.style?.border?.width;
    const isBorder = borderColor != null && borderColor !== 'rgba(0,0,0,0)' && borderWidth != 0;

    let marker;
    if (
      isBorder &&
      (this.elementDefinition.lineDefinition?.markerEnd || this.elementDefinition.lineDefinition?.markerStart)
    ) {
      marker = document.createElement('marker');
      marker.setAttribute('id', `marker_${this.elementDefinition.id}`);
      marker.setAttributeNS(null, 'viewBox', '0 0 10 10');
      marker.setAttributeNS(null, 'refX', '5');
      marker.setAttributeNS(null, 'refY', '5');
      marker.setAttributeNS(null, 'markerWidth', '6');
      marker.setAttributeNS(null, 'markerHeight', '6');
      marker.setAttribute('orient', 'auto-start-reverse');
      const markerPath = document.createElement('path');
      markerPath.setAttribute('d', `M 0,0 L 10,5 0,10 Z`);
      markerPath.setAttribute('fill', borderColor);
      marker.appendChild(markerPath);
    }

    if (marker) {
      const svg = document.createElement('svg');
      const defs = document.createElement('defs');
      if (this.elementDefinition.lineDefinition?.markerEnd) {
        element.setAttribute('marker-end', `url(#marker_${this.elementDefinition.id})`);
      }
      if (this.elementDefinition.lineDefinition?.markerStart) {
        element.setAttribute('marker-start', `url(#marker_${this.elementDefinition.id})`);
      }
      defs.appendChild(marker);
      svg.appendChild(defs);
      svg.appendChild(element);
      return svg;
    }

    return element;
  }
}
