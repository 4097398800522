<div class="menu">
  <app-menu [icon]="'settings'" position="left">
    <mat-form-field appearance="">
      <mat-label>Group By</mat-label>
      <mat-select [formControl]="propertiesFormControl" (ngModelChange)="handleGroupingPropertyChange($event)">
        <mat-option *ngFor="let property of groupingProperties" [value]="property">
          {{ property.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-menu>
</div>

<div class="flex flex-col items-center text-[15px] font-bold mb-2">{{ groupingProperty?.label }}</div>
<div class="summary-chart">
  <google-chart
    *ngIf="chartData?.length"
    [type]="chartType"
    [height]="height"
    [width]="width"
    [data]="chartData"
    [options]="chartOptions"
  >
    >
  </google-chart>
</div>
