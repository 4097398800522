import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../canvas-document';
import { CanvasComponentElement } from './component/canvas-component-element';
import { CanvasElement } from './canvas-element';
import { CanvasImageElement } from './image/canvas-image-element';
import { CanvasSVGElement } from './image/canvas-svg-element';
import { CanvasTextElement } from './text/canvas-text-element';
import { CanvasCircleElement } from './shapes/canvas-circle-element';
import { CanvasLineElement } from './shapes/canvas-line-element';
import { CanvasRectangleElement } from './shapes/canvas-rectangle-element';
import { CanvasTriangleElement } from './shapes/canvas-triangle-element';
import { CanvasDiamondElement } from './shapes/canvas-diamond-element';
import { CanvasStarElement } from './shapes/canvas-star-element';
import { CanvasHeartElement } from './shapes/canvas-heart-element';
import { CanvasRightArrowElement } from './shapes/canvas-right-arrow-element';
import { CanvasDoubleArrowElement } from './shapes/canvas-double-arrow-element';
import { CanvasRhombusElement } from './shapes/canvas-rhombus-element';
import { CanvasFrameElement } from './frame/canvas-frame-element';
import { CanvasIframeElement } from './iframe/canvas-iframe-element';
import { CanvasStickyNoteElement } from './sticky-note/canvas-sticky-note-element';
import { CanvasAnnotationElement } from './annotation/annotation-element';
import { CanvasGroupElement } from './group/canvas-group-element';
import { CanvasCloudElement } from './shapes/canvas-cloud-element';
import { CanvasPenElement } from './pen/canvas-pen-element';
import { CanvasHighlighterElement } from './pen/canvas-highlighter-element';

export class CanvasElementFactory {
  static createCanvasElement(
    documentElement: DocumentElement,
    canvasDocument: CanvasDocument,
    interactable = false,
  ): CanvasElement {
    let canvasElement;
    switch (documentElement.type) {
      case 'frame':
        canvasElement = new CanvasFrameElement(documentElement, canvasDocument, interactable);
        break;
      case 'text':
        canvasElement = new CanvasTextElement(documentElement, canvasDocument, interactable);
        break;
      case 'image':
        canvasElement = new CanvasImageElement(documentElement, canvasDocument, interactable);
        break;
      case 'component':
        canvasElement = new CanvasComponentElement(documentElement, canvasDocument, interactable);
        break;
      case 'rectangle':
        canvasElement = new CanvasRectangleElement(documentElement, canvasDocument, interactable);
        break;
      case 'circle':
        canvasElement = new CanvasCircleElement(documentElement, canvasDocument, interactable);
        break;
      case 'triangle':
        canvasElement = new CanvasTriangleElement(documentElement, canvasDocument, interactable);
        break;
      case 'diamond':
        canvasElement = new CanvasDiamondElement(documentElement, canvasDocument, interactable);
        break;
      case 'star':
        canvasElement = new CanvasStarElement(documentElement, canvasDocument, interactable);
        break;
      case 'heart':
        canvasElement = new CanvasHeartElement(documentElement, canvasDocument, interactable);
        break;
      case 'double_arrow':
        canvasElement = new CanvasDoubleArrowElement(documentElement, canvasDocument, interactable);
        break;
      case 'right_arrow':
        canvasElement = new CanvasRightArrowElement(documentElement, canvasDocument, interactable);
        break;
      case 'rhombus':
        canvasElement = new CanvasRhombusElement(documentElement, canvasDocument, interactable);
        break;
      case 'line':
        canvasElement = new CanvasLineElement(documentElement, canvasDocument, interactable);
        break;
      case 'hot_spot':
      //   svgDocumentElement = new SVGHotSpotElement(documentElement, rootDocument, parentSVGElement, parentContentGroup, interactable);
      //   break;
      case 'svg':
        canvasElement = new CanvasSVGElement(documentElement, canvasDocument, interactable);
        break;
      case 'iframe':
        canvasElement = new CanvasIframeElement(documentElement, canvasDocument, interactable);
        break;
      case 'sticky_note':
        canvasElement = new CanvasStickyNoteElement(documentElement, canvasDocument, interactable);
        break;
      case 'annotation':
        canvasElement = new CanvasAnnotationElement(documentElement, canvasDocument, interactable);
        break;
      case 'group':
        canvasElement = new CanvasGroupElement(documentElement, canvasDocument, interactable);
        break;
      case 'cloud':
        canvasElement = new CanvasCloudElement(documentElement, canvasDocument, interactable);
        break;
      case 'pen':
        canvasElement = new CanvasPenElement(documentElement, canvasDocument, interactable);
        break;
      case 'highlighter':
        canvasElement = new CanvasHighlighterElement(documentElement, canvasDocument, interactable);
        break;
    }
    return canvasElement;
  }
}
