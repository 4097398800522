import { Component } from '@angular/core';

@Component({
  selector: 'app-help-selector',
  template: `
    <button class="menu-button" mat-button (click)="helpCenter()">
      <mat-icon> help_outline </mat-icon>
    </button>
  `,
})
export class HelpSelectorComponent {
  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
