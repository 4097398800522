<app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>
<form [formGroup]="form" (ngSubmit)="onSubmit(form.value, entityId)">
  <mat-form-field style="width: 100%" appearance="fill">
    <input matInput type="text" placeholder="Showcase Name" formControlName="name" />
    <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
    <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>
  <div class="actions">
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">OK</button>
  </div>
</form>
