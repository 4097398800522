<div class="flex flex-row items-center content-center">
  <button mat-icon-button [matMenuTriggerFor]="borderRadiusMenu"><mat-icon>rounded_corner</mat-icon></button>
</div>

<mat-menu #borderRadiusMenu="matMenu">
  <div class="relative h-48 bg-white border-none">
    <ng-container *ngFor="let radiusValue of borderRadius">
      <button class="!text-black60" mat-menu-item (click)="setValue(radiusValue)">
        {{ radiusValue }}px <mat-icon class="!ml-1.5" *ngIf="isMatch(radiusValue)">done</mat-icon>
      </button>
    </ng-container>
  </div>
</mat-menu>
