<div class="card prevent-select">
  <div>
    <img class="preview" *ngIf="imageUrl" [src]="imageUrl" />
    <div class="placeholder" *ngIf="!imageUrl">
      <mat-icon>image</mat-icon>
    </div>
    <div *ngIf="badge" class="badge">
      <span>{{ badge.text }}</span>
    </div>
  </div>
  <div class="card-footer" [ngClass]="{ selected: selected }">
    <div class="meta">
      <div class="name">
        <img class="icon max-w-none" *ngIf="iconUrl && !showIconAfterTitle" [src]="iconUrl" />
        <span> {{ title | shorten: 25 }} </span>
        <img class="icon max-w-none ml-1" *ngIf="iconUrl && showIconAfterTitle" [src]="iconUrl" />
      </div>
      <div class="details" *ngIf="showUpdatedOn">Edited {{ updatedOn | dateAgo }}</div>
    </div>
    <div class="menu-toggle" (click)="showMenu($event)">
      <mat-icon>more_vert</mat-icon>
    </div>
  </div>
</div>
