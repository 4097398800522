import { Component, ElementRef, ViewChild } from '@angular/core';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { SizeRangeEditorComponent } from '@components/size-range-editor/size-range-editor.component';
import { PropertyType, PropertyValueFormatter, SizeRange } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-size-range',
  template: `
    <div class="pb-5 w-full relative">
      <div class="flex">
        <div>{{ propertyFormConfiguration.typeProperty.label }}</div>
        <div class="ml-1 cursor-pointer text-black/40 text-sm" (click)="toggleEditor($event)">
          <mat-icon *ngIf="editable">edit</mat-icon>
          <mat-icon *ngIf="!editable">info</mat-icon>
        </div>
      </div>
      <div class="pt-2 flex flex-wrap">
        <div class="size-chip" *ngFor="let size of sizes">{{ size }}</div>
      </div>
      <div *ngIf="errorMessage" class="w-full text-[75%] mt-1 border-t-2 border-red-500">
        <mat-error>{{ errorMessage }}</mat-error>
      </div>
    </div>

    <div class="pb-5 w-fit" *ngIf="!sizeRangeConstraint && sizes?.length === 0">
      <div>{{ propertyFormConfiguration.typeProperty.label }}</div>
      <div class="mt-[5px] text-black/50 text-[12px]">Please select a Size Range Template</div>
    </div>
    <div #sizeRangePopup *ngIf="showEditor" class="panel">
      <div class="flex justify-end ml-1 cursor-pointer text-black/40 text-sm" (click)="toggleEditor($event)">
        <mat-icon>close</mat-icon>
      </div>
      <app-size-range-editor
        #sizeRangeEditor
        [sizeRangeConstraint]="sizeRangeConstraint"
        [sizeRange]="value"
        [editMode]="editable"
        (dataChanged)="handleDataChange($event)"
      >
      </app-size-range-editor>
      <div class="flex justify-end mt-2 text-primary" *ngIf="editable">
        <div
          class="ml-1 cursor-pointer"
          (click)="save()"
          [ngClass]="{ action: isSaveEnabled, 'action-disabled': !isSaveEnabled }"
        >
          Save
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .size-chip {
        background: #ebebeb;
        border-radius: 3px;
        padding: 3px 5px 3px 8px;
        font-size: 0.9em;
        line-height: normal;
        display: flex;
        align-items: center;
        max-width: 100%;
        box-sizing: border-box;
        margin: 2px;
      }
      .panel {
        @apply flex flex-col justify-between fixed z-[300] mt-[24px] p-[15px] shadow-[0_10px_22px_rgba(0,0,0,0.15)] bg-white rounded-[8px];
      }
      .action {
        @apply text-primary;
      }
      .action-disabled {
        @apply text-black/40 cursor-auto;
      }
    `,
  ],
})
export class TypePropertyFormFieldSizeRangeComponent extends TypePropertyFormFieldBaseComponent {
  @ViewChild('sizeRangeEditor') sizeRangeEditor: SizeRangeEditorComponent;
  @ViewChild('sizeRangePopup') sizeRangeElem: ElementRef;

  panelTopPosition = 0;
  sizeRangeConstraint: SizeRange;
  sizes: Array<string> = [];
  showEditor = false;
  isSaveEnabled = false;
  editable = true;

  constructor(private elementRef: ElementRef) {
    super();
  }

  async initFormControl() {
    this.editable = !(await this.isDisabled());
    this.sizeRangeConstraint = SizeRangeHelper.getConstrainingSizeRange(this.entity);
    this.sizes = SizeRangeHelper.getSizeRangeSizesList(this.value);
  }

  handleDataChange(data) {
    this.isSaveEnabled = JSON.stringify(data) !== JSON.stringify(this.value);
  }

  save() {
    if (this.isSaveEnabled) {
      const sizeRange: SizeRange = this.sizeRangeEditor.getData();
      this.value = ObjectUtil.cloneDeep(sizeRange);
      this.isSaveEnabled = false;
      this.handleChange();
    }
  }

  toggleEditor(event) {
    this.showEditor = !this.showEditor;
    if (this.showEditor) {
      setTimeout(() => {
        this.setPosition();
      }, 1);
    }
  }

  setPosition() {
    if (!this.sizeRangeElem) {
      return;
    }
    const panelHeight = this.sizeRangeElem.nativeElement?.getBoundingClientRect().height || 50;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    this.panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;
    if (distanceFromBottom < 12) {
      this.panelTopPosition = this.panelTopPosition - panelHeight;
    }
    this.sizeRangeElem.nativeElement.style.top = `${this.panelTopPosition}px`;
  }
}
