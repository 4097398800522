<div class="">
  <div class="view-name">
    <form [formGroup]="form">
      <mat-form-field *ngIf="!editNameActive" class="!w-[180px]" [floatLabel]="'never'">
        <mat-select
          class="!w-full"
          placeholder="Select a preset"
          data-test="component-property-config"
          formControlName="selectedView"
          (selectionChange)="selectView($event)"
        >
          <mat-select-trigger>
            <div class="flex items-center justify-between">
              <span class="overflow-hidden whitespace-nowrap text-ellipsis">
                {{ selectedView?.label }}
              </span>
              <mat-icon
                *ngIf="selectedView?.admin"
                style="height: 16px; width: 16px; line-height: 16px; font-size: 16px"
                class="ml-2 material-icons"
              >
                group
              </mat-icon>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let view of availableViewDefinitions" [value]="view" class="flex justify-between">
            <div class="flex items-center justify-between">
              {{ view.label }}
              <mat-icon
                *ngIf="view.admin"
                style="height: 16px; width: 16px; line-height: 16px; font-size: 16px"
                class="ml-2 material-icons"
              >
                group
              </mat-icon>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="editNameActive">
        <input
          #viewNameInput
          data-test="component-property-config-name-edit-input"
          id="viewName"
          name="viewName"
          type="text"
          matInput
          maxlength="100"
          formControlName="viewName"
          (keydown.enter)="endNameEdit()"
          (blur)="endNameEdit()"
        />
      </mat-form-field>
    </form>

    <div class="actions flex">
      <button
        disableRipple="true"
        [disabled]="!selectedView || selectedView?.id === 'null' || (!isOrgAdmin && selectedView?.admin)"
        mat-button
        data-test="component-property-config-edit-button"
        matTooltip="Edit name"
        (click)="editName()"
      >
        <mat-icon> edit </mat-icon>
      </button>
      <button
        disableRipple="true"
        [disabled]="
          !selectedView || selectedView?.id === 'null' || (!isOrgAdmin && selectedView?.admin) || !changesDetected
        "
        mat-button
        data-test="component-property-config-save-button"
        matTooltip="Save preset"
        (click)="updateView()"
      >
        <mat-icon> save </mat-icon>
      </button>
      <button
        disableRipple="true"
        [disabled]="!selectedView || selectedView?.id === 'null' || !changesDetected"
        mat-button
        data-test="component-property-config-reset-button"
        matTooltip="Reset preset"
        (click)="resetView()"
      >
        <mat-icon> settings_backup_restore </mat-icon>
      </button>
      <button
        class="!ml-[-5px]"
        disableRipple="true"
        [disabled]="!selectedView || selectedView?.id === 'null' || (!isOrgAdmin && selectedView?.admin)"
        mat-button
        data-test="component-property-config-delete-button"
        matTooltip="Delete preset"
        (click)="deleteView()"
      >
        <mat-icon> delete </mat-icon>
      </button>
    </div>
  </div>
</div>
