import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type } from '@contrail/types';

@Component({
  selector: 'app-object-reference-chip',
  templateUrl: './object-reference-chip.component.html',
  styleUrls: ['./object-reference-chip.component.scss'],
})
export class ObjectReferenceChipComponent implements OnChanges {
  @Input() object: any;
  @Input() edit = false;
  @Input() maxDisplayLength = 16;
  @Input() allowDisplayTextShortening = true;
  @Input() clickable = true;
  @Output() clearValue = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public objectName: string;
  public objectNumber: string;
  public hasStatus: boolean;
  public colorStage: string;
  public type: Type;
  constructor() {}

  async ngOnChanges() {
    this.objectName = this.object.name;
    if (this.object?.typeId) {
      this.type = await new Types().getType({ id: this.object.typeId });
      if (this.type.typeProperties.find((prop) => prop.slug === 'itemStatus')) {
        this.hasStatus = true;
        if (this.object.itemStatus === 'concept' || !this.object.itemStatus) {
          this.colorStage = 'concept';
        } else {
          this.colorStage = null;
        }
      }
    }
    if (this.object.optionName) {
      this.objectName = this.object.optionName; // HACK FOR ITEMS
    }
    if (this.object.itemNumber) {
      this.objectNumber = 'ITEM-' + this.object.itemNumber;
    }
  }

  remove() {
    this.clearValue.emit();
  }

  viewDetails() {
    if (this.clickable) {
      this.clicked.emit();
    }
  }
}
