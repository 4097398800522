import { on } from '@ngrx/store';
import { documentModelEntityAdapter } from './document-model-entities.state';
import * as DocumentModelEntityActions from './document-model-entities.actions';
import { State } from '../../document-store/document.state';

const setDocumentModelEntities = (state: State, { documentModelEntities }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.setAll(documentModelEntities, state.documentModelEntities),
  };
};

const clearData = (state: State, {}) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.removeAll(state.documentModelEntities),
  };
};

const updateDocumentModelEntity = (state: State, { id, documentModelEntity }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.updateOne(
      { id, changes: documentModelEntity },
      state.documentModelEntities,
    ),
  };
};

const updateDocumentModelEntities = (state: State, { documentModelEntities }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.updateMany(
      documentModelEntities.map((e) => Object.assign({}, { id: e.id, changes: e.changes })),
      state.documentModelEntities,
    ),
  };
};

const removeDocumentModelEntity = (state: State, { id }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.removeOne(id, state.documentModelEntities),
  };
};

const removeDocumentModelEntities = (state: State, { ids }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.removeMany(ids, state.documentModelEntities),
  };
};

const addDocumentModelEntity = (state: State, { documentModelEntity }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.addOne(documentModelEntity, state.documentModelEntities),
  };
};

const addDocumentModelEntities = (state: State, { documentModelEntities }) => {
  return {
    ...state,
    documentModelEntities: documentModelEntityAdapter.addMany(documentModelEntities, state.documentModelEntities),
  };
};

export const documentModelEntitiesReducers = [
  on(DocumentModelEntityActions.setDocumentModelEntities, setDocumentModelEntities),
  on(DocumentModelEntityActions.clearDocumentModelEntities, clearData),
  on(DocumentModelEntityActions.updateDocumentModelEntity, updateDocumentModelEntity),
  on(DocumentModelEntityActions.updateDocumentModelEntities, updateDocumentModelEntities),
  on(DocumentModelEntityActions.removeDocumentModelEntity, removeDocumentModelEntity),
  on(DocumentModelEntityActions.removeDocumentModelEntities, removeDocumentModelEntities),
  on(DocumentModelEntityActions.addDocumentModelEntity, addDocumentModelEntity),
  on(DocumentModelEntityActions.addDocumentModelEntities, addDocumentModelEntities),
];
