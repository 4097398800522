import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-item-details-modal',
  template: `<app-item-details
    data-test="item-details-modal"
    [accessLevel]="accessLevel"
    [itemId]="itemId"
    (updated)="emitUpdate($event)"
    (createComplete)="complete($event)"
    (cancelView)="cancel()"
  ></app-item-details>`,
  styles: [``],
})
export class ItemDetailsModalComponent implements OnInit {
  @Output() updated = new EventEmitter();
  public defaults: any;
  public itemId: string;
  public accessLevel = 'EDIT';
  constructor(
    public dialogRef: MatDialogRef<ItemDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.itemId = data.itemId;
    this.accessLevel = data.accessLevel;
  }

  ngOnInit(): void {}
  complete(item) {
    this.dialogRef.close(item);
  }
  cancel() {
    this.dialogRef.close();
  }

  emitUpdate($event) {
    this.updated.emit($event);
  }
}
