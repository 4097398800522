<div class="w-full h-full flex flex-col" data-test="content-editor-component">
  <div class="header !mb-0">
    <div class="title">Content Options</div>
    <mat-icon (click)="hide()">close</mat-icon>
  </div>

  <div class="grid items-center justify-center h-full" *ngIf="loading$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
  </div>

  <div class="overflow-y-scroll overflow-x-hidden grow" *ngIf="!(loading$ | async)">
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Details </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="px-2">
        <app-content-details
          [content]="content"
          [showContentFileName]="true"
          [dimensions]="dimensions"
          [createdByName]="createdByName"
        ></app-content-details>
        <div class="py-2">
          <button mat-raised-button color="primary" (click)="download()" data-test="content-editor-download">
            <mat-icon>download</mat-icon> <span class="ml-2">DOWNLOAD</span>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Colors </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex flex-col" *ngIf="fills">
        <div
          class="color-edit group flex flex-row space-x-14 items-center text-black60"
          *ngFor="let c of fills; index as i"
        >
          <app-color-detail
            [name]="c.newColor?.name || c.newColor?.backgroundColor || c.color?.name || c.color?.backgroundColor"
            [backgroundColor]="c.newColor?.backgroundColor || c.color?.backgroundColor"
            (onClick)="editColor(c, $event)"
          ></app-color-detail>
          <mat-icon
            class="edit-color invisible group-hover:visible cursor-pointer !text-base"
            (click)="editColor(c, $event)"
            data-test="edit-color"
            >edit</mat-icon
          >
        </div>
      </div>
      <app-color-widget
        #colorWidget
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (delayedValueChange)="handleDelayedValueChange($event)"
        (valueChange)="handleValueChange($event)"
        [currentValue]="
          currentColor?.newColor?.backgroundColor || currentColor?.color?.backgroundColor || 'rgba(0,0,0,0)'
        "
        [ignoreTagNames]="['app-color-detail']"
        [ignoreClassNames]="['edit-color']"
      >
      </app-color-widget>
    </mat-expansion-panel>
  </div>
  <div class="border-t border-t-solid border-t-neutral-200 flex items-center justify-center p-3" #colorTarget>
    <button
      class="!mr-[18px] w-full"
      mat-raised-button
      color="primary"
      (click)="save()"
      data-test="content-editor-save"
      [disabled]="isSaveDisabled"
    >
      SAVE CHANGES
    </button>
    <button
      class="!mr-[18px] w-10/12"
      mat-stroked-button
      color="primary"
      (click)="restore()"
      data-test="content-editor-restore"
      [disabled]="isSaveDisabled"
    >
      CANCEL
    </button>
  </div>
</div>
