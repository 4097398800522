import {
  BackgroundSizeType,
  CropDefinition,
  DocumentElement,
  PositionDefinition,
  SizeDefinition,
} from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';
import {
  CanvasImageLoader,
  ImageLoaderResponse,
  PLACEHOLDER_IMAGE,
  PLACEHOLDER_ITEM_FAMILY_IMAGE,
  PLACEHOLDER_ITEM_OPTION_IMAGE,
  UNASSIGNED_PLACEHOLDER_IMAGE,
} from './canvas-image-loader';
import { FileDownloader } from '../../file-downloader';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { RotationHelper } from '../../renderers/rotation-widget-renderer/rotation-helper';
import { CropElementResizeHandler } from '../../interactions/canvas-event-handlers/crop-event-handlers/crop-element-resize-handler';
import { BLACK_24, BLACK_38, GREY_CHIP_LIGHT } from '../../constants';

export interface DrawImageParams {
  x: number;
  y: number;
  width: number;
  height: number;
  imageOpacity?: number;
  dx?: number; // crop parameters
  dy?: number;
  dw?: number;
  dh?: number;
}

export class CanvasImageDrawableElement extends CanvasElement {
  protected urlToLoad: string = null;
  protected currentUrl: string = null; // currently used and loaded url
  protected url: string = null; // actual blob url
  protected blob: Blob = null;
  public imageElement: HTMLImageElement = null;
  public canvasImageElement: HTMLCanvasElement | HTMLImageElement = null;
  public imageOpacity = null;
  public imageSize: SizeDefinition;
  public uncroppedUnrotatedPosition: PositionDefinition;

  protected isLoadingInProgress = false;
  protected isLocalBlob = false;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
    public drawToOffscreenCanvas = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.currentUrl = this.elementDefinition.url;
    this.urlToLoad = this.elementDefinition.url;
  }

  public draw(
    ctx: CanvasRenderingContext2D,
    { x, y, width, height, imageOpacity },
    options?: DrawOptions,
  ): { height: number; y: number } {
    if (!this.isVisible) {
      return;
    }
    const imageScreenRatio = this.getCoveredArea(options);
    this.urlToLoad = this.elementDefinition.url;
    let imageElement: HTMLImageElement | HTMLCanvasElement = this.imageElement;
    if (this.elementDefinition.size && this.elementDefinition?.alternateUrls) {
      const highRes =
        this.elementDefinition?.alternateUrls?.highResolution || this.elementDefinition?.alternateUrls?.originalFile;
      const lowRes = this.elementDefinition?.alternateUrls?.lowResolution;
      const originalFile = this.elementDefinition?.alternateUrls?.originalFile;
      if (this.canvasDocument.mode === 'SNAPSHOT') {
        this.urlToLoad = highRes || this.elementDefinition.url;
      } else if (imageScreenRatio > 40 && originalFile) {
        this.urlToLoad = originalFile;
      } else if (imageScreenRatio > 3 && highRes) {
        this.urlToLoad = highRes;
      } else if (imageScreenRatio < 0.3 && lowRes) {
        this.urlToLoad = lowRes;
      } else if (highRes) {
        this.urlToLoad = highRes;
      }
    }

    /** TODO: Temp fix to solve for image cropping issues related to
     * cropping dimensions being defined against a fixed resolution.
     */
    if (this.shouldLoadOriginal(options)) {
      const originalFile = this.elementDefinition.alternateUrls?.originalFile;
      if (originalFile) {
        this.urlToLoad = originalFile;
      }
    }

    if (this.canvasDocument.isCropping(this.elementDefinition.id)) {
      // Do not change url if currently cropping
      this.urlToLoad = this.currentUrl;
    }

    /** Use cached image that was drawn onto offscreen canvas if user is zoomed out */
    if (this.drawToOffscreenCanvas && this.canvasImageElement && this.getCoveredArea() < 50) {
      imageElement = this.canvasImageElement;
    }

    if (!imageElement || this.currentUrl !== this.urlToLoad) {
      if (this.canvasDocument.mode === 'SNAPSHOT') {
        console.log('Warning: generating image while making snapshot', options, this);
      }
      if (!this.isLoadingInProgress || this.currentUrl !== this.urlToLoad) {
        if (imageElement) {
          // Draw existing image while new one is in progress of loading to avoid image blinking
          this.drawImageElement(
            ctx,
            imageElement,
            {
              x,
              y,
              width,
              height,
              imageOpacity,
            },
            options,
          );
        }
        this.isLoadingInProgress = true;
        this.loadImage();
      }
    } else {
      this.drawImageElement(
        ctx,
        imageElement,
        {
          x,
          y,
          width,
          height,
          imageOpacity,
        },
        options,
      );
    }

    return;
  }

  private drawImageElement(ctx, imageElement, { x, y, width, height, imageOpacity }, options?: DrawOptions) {
    if (this.isPartOfComponent && this.imageSize) {
      const props = this.getImageProperties(
        this.elementDefinition?.style?.background?.size,
        this.imageSize,
        x,
        y,
        width,
        height,
      );
      let opacity;
      if (imageOpacity != null || this.imageOpacity != null) {
        opacity = this.imageOpacity ? this.imageOpacity : imageOpacity;
      }
      props.imageOpacity = opacity;
      this.drawImage(ctx, imageElement, props);
    } else {
      if (this.isImageError) {
        if (this.canvasDocument.mode !== 'PREVIEW') {
          this.drawLoadingContainer(
            ctx,
            { imageElement, imageSize: this.imageSize },
            { x: -width * 0.5, y: -height * 0.5, width, height },
            GREY_CHIP_LIGHT,
          );
        }
      } else {
        if (!options?.drawUncropped && this.isCropped()) {
          const crop = this.getCropDefinition();
          this.drawImage(ctx, imageElement, {
            x: crop.x1,
            y: crop.y1,
            width: crop.width,
            height: crop.height,
            dx: -width * 0.5,
            dy: -height * 0.5,
            dw: width,
            dh: height,
            imageOpacity: imageOpacity || this.elementDefinition?.style?.opacity,
          });
        } else {
          this.drawImage(ctx, imageElement, {
            x: -width * 0.5,
            y: -height * 0.5,
            width: width,
            height: height,
            imageOpacity: imageOpacity || this.elementDefinition?.style?.opacity,
          });

          if (this.isLocalBlob) {
            this.drawLoadingContainer(
              ctx,
              CanvasImageLoader.loadingImage,
              { x: -width * 0.5, y: -height * 0.5, width, height },
              BLACK_38,
            );
          }
        }

        if (!options?.drawUncropped) {
          this.drawBorderContainer(ctx, -width * 0.5, -height * 0.5, width, height, options);
        }
      }
    }
  }

  private drawLoadingContainer(ctx, loadingImage: ImageLoaderResponse, { x, y, width, height }, fillStyle) {
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = fillStyle;
    ctx.fill();
    ctx.closePath();

    if (
      loadingImage &&
      loadingImage.imageSize.width < this.elementDefinition.size.width &&
      loadingImage.imageSize.height < this.elementDefinition.size.height
    ) {
      this.drawImage(ctx, loadingImage.imageElement, {
        x: x + width * 0.5 - loadingImage.imageSize.width * 0.5,
        y: y + height * 0.5 - loadingImage.imageSize.height * 0.5,
        width: loadingImage.imageSize.width,
        height: loadingImage.imageSize.height,
      });
    }
  }

  public async preload(options?: DrawOptions, isAnonymous = false) {
    this.urlToLoad = this.shouldLoadOriginal(options)
      ? this.elementDefinition?.alternateUrls?.originalFile || this.elementDefinition?.url
      : this.elementDefinition?.alternateUrls?.highResolution || this.elementDefinition?.url;
    this.currentUrl = this.urlToLoad;
    const authContext = await this.canvasDocument.getUserContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      this.canvasDocument.appContext,
    );
    const componentElement = this.isPartOfComponent
      ? this.canvasDocument.getCanvasElementById(this.isPartOfComponent)?.elementDefinition
      : null;
    return await CanvasImageLoader.loadImage(
      this.urlToLoad,
      this.elementDefinition,
      fileDownLoader,
      componentElement,
      this.canvasDocument.isFirefox,
      this.drawToOffscreenCanvas,
      // canvas.toDataUrl throws Tainted Canvas errors when Image src was loaded from another origin (for ex. https://prod-contrail-file-content-bucket.s3.amazonaws.com/)
      isAnonymous || this.canvasDocument.mode === 'SNAPSHOT',
    )
      .then((response: ImageLoaderResponse) => {
        this.blob = response.imageBlob;
        this.url = response.imageUrl;
        this.imageElement = response.imageElement;
        this.canvasImageElement = response.canvasImageElement;
        this.imageSize = response.imageSize;
        this.isImageError = response.imageError;
        this.isLocalBlob = response.isLocalBlob;
        this.imageOpacity = response.imageOpacity;
        return this.imageElement;
      })
      .catch((error) => {
        console.log('Error loading image', this.elementDefinition);
        return error;
      });
  }

  private async loadImage() {
    this.currentUrl = this.urlToLoad;
    const isAnonymous = this.canvasDocument.mode === 'SNAPSHOT';
    const authContext = await this.canvasDocument.getUserContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      this.canvasDocument.appContext,
    );
    const componentElement = this.isPartOfComponent
      ? this.canvasDocument.getCanvasElementById(this.isPartOfComponent)?.elementDefinition
      : null;
    CanvasImageLoader.loadImage(
      this.urlToLoad,
      this.elementDefinition,
      fileDownLoader,
      componentElement,
      this.canvasDocument.isFirefox,
      this.drawToOffscreenCanvas,
      isAnonymous,
    )
      .then((response: ImageLoaderResponse) => {
        this.blob = response.imageBlob;
        this.url = response.imageUrl;
        this.imageElement = response.imageElement;
        this.canvasImageElement = response.canvasImageElement;
        this.imageSize = response.imageSize;
        this.isImageError = response.imageError;
        this.isLocalBlob = response.isLocalBlob;
        this.imageOpacity = response.imageOpacity;
        this.canvasDocument.queueDraw(); // redraw canvas after image is done loading
      })
      .catch((error) => {
        console.log('Error loading image', this.elementDefinition);
      });
  }

  public getBoundingClientRect(options?: DrawOptions): { x; y; width; height } {
    const { x, y } = this.getPosition(options);
    // const { width, height } = this.isImageError && this.imageSize ? this.imageSize : this.getSize(options);
    const { width, height } = this.getSize(options);
    return {
      x,
      y,
      width,
      height,
    };
  }

  public copyCanvasElementProperties(canvasElement: CanvasElement) {
    if (['image', 'svg'].indexOf(canvasElement?.elementDefinition?.type) !== -1) {
      const element = canvasElement as CanvasImageDrawableElement;
      element.imageElement = this.imageElement;
      element.imageSize = this.imageSize;
      element.imageOpacity = this.imageOpacity;
      element.isImageError = this.isImageError;
    }
  }

  private shouldLoadOriginal(options?: DrawOptions): boolean {
    return (
      options?.loadOriginal ||
      (this.elementDefinition.cropDefinition &&
        !this.elementDefinition.cropDefinition?.widthPercent &&
        !this.elementDefinition.cropDefinition?.heightPercent)
    );
  }

  public isCropped(): boolean {
    return (
      this.elementDefinition.cropDefinition &&
      (!!(this.elementDefinition?.cropDefinition?.width && this.elementDefinition?.cropDefinition?.height) ||
        !!(
          this.elementDefinition?.cropDefinition?.widthPercent && this.elementDefinition?.cropDefinition?.heightPercent
        ))
    );
  }

  public getCropDefinition(): CropDefinition {
    if (
      this.elementDefinition.cropDefinition?.widthPercent &&
      this.elementDefinition.cropDefinition?.heightPercent &&
      this.imageSize
    ) {
      const x1 = this.elementDefinition.cropDefinition.x1Percent * this.imageSize.width;
      const y1 = this.elementDefinition.cropDefinition.y1Percent * this.imageSize.height;
      const width = this.elementDefinition.cropDefinition.widthPercent * this.imageSize.width;
      const height = this.elementDefinition.cropDefinition.heightPercent * this.imageSize.height;
      return {
        x1,
        y1,
        width,
        height,
        x2: x1 + width,
        y2: y1 + height,
      };
    }
    if (
      !this.elementDefinition.cropDefinition?.width &&
      !this.elementDefinition.cropDefinition?.height &&
      this.imageSize
    ) {
      return {
        x1: 0,
        y1: 0,
        width: this.imageSize.width,
        height: this.imageSize.height,
        x2: this.imageSize.width,
        y2: this.imageSize.height,
      };
    }
    return { ...this.elementDefinition.cropDefinition };
  }

  protected getCropPercent(): CropDefinition {
    if (this.imageSize) {
      const imgWidth = this.imageSize.width;
      const imgHeight = this.imageSize.height;
      const x1 = this.elementDefinition.cropDefinition.x1;
      const y1 = this.elementDefinition.cropDefinition.y1;
      const width = this.elementDefinition.cropDefinition.width;
      const height = this.elementDefinition.cropDefinition.height;
      return CropElementResizeHandler.getCropPercent(x1, y1, width, height, imgWidth, imgHeight);
    }
    return null;
  }

  public getOriginalSizeScale() {
    if (!this.imageSize) {
      return 1;
    }
    const crop = this.getCropDefinition();
    const scale = Math.min(
      this.elementDefinition.size.width / crop.width,
      this.elementDefinition.size.height / crop.height,
    );
    const dpr = this.getDownloadResolution();
    return scale * dpr;
  }

  /**
   * Used when downloading image in the original size.
   * By default canvas scales everything by device pixel ratio (usually 2).
   * Divide by dpr on download to avoid having images twice as large as original size.
   * SVG is typically small in size, so we want to scale it by 4
   * @returns
   */
  public getDownloadResolution() {
    const dpr = this.elementDefinition.type === 'svg' ? 0.25 : this.canvasDocument.getDevicePixelRatio();
    return dpr;
  }

  public getPosition(options?: DrawOptions): PositionDefinition {
    let position = this.elementDefinition.position ? { ...this.elementDefinition.position } : { x: 0, y: 0 };

    if (options?.uncroppedDimensions && this.isCropped()) {
      if (this.elementDefinition?.rotate?.angle) {
        if (!this.uncroppedUnrotatedPosition) {
          this.uncroppedUnrotatedPosition = this.getUncroppedUnrotatedPosition();
        }
        position = { ...this.uncroppedUnrotatedPosition };
      } else {
        const crop = this.getCropDefinition();
        const scale = Math.min(
          this.elementDefinition.size.width / crop.width,
          this.elementDefinition.size.height / crop.height,
        );
        if (crop?.x1) {
          position.x = position.x - crop.x1 * scale;
        }
        if (crop?.y1) {
          position.y = position.y - crop.y1 * scale;
        }
      }
    }
    return this.getMaskedPosition(options, position);
  }

  public getSize(options?: DrawOptions): SizeDefinition {
    if (this.isPartOfComponent || !this.imageSize) {
      return super.getSize(options);
    }

    if (options?.uncroppedDimensions && this.isCropped()) {
      const crop = this.getCropDefinition();
      const scale = Math.min(
        this.elementDefinition.size.width / crop.width,
        this.elementDefinition.size.height / crop.height,
      );
      const size = {
        width: this.imageSize.width * scale,
        height: this.imageSize.height * scale,
      };
      return this.getMaskedSize(options, size);
    }

    if (this.isCropped()) {
      const crop = this.getCropDefinition();
      const scale = Math.min(
        this.elementDefinition.size.width / crop.width,
        this.elementDefinition.size.height / crop.height,
      );
      const size = {
        width: crop.width * scale,
        height: crop.height * scale,
      };
      return this.getMaskedSize(options, size);
    }

    if (this.isImageError) {
      return this.getMaskedSize(options);
    }

    const widthScale = this.elementDefinition.size.width
      ? this.elementDefinition.size.width / (this.imageSize?.width || 1)
      : 1;
    const heightScale = this.elementDefinition.size.height
      ? this.elementDefinition.size.height / (this.imageSize?.height || 1)
      : 1;
    const scale = Math.min(widthScale, heightScale);
    const size = {
      width: this.imageSize.width * scale,
      height: this.imageSize.height * scale,
    };
    return this.getMaskedSize(options, size);
  }

  public onChange() {
    if (this.isCropped()) {
      this.uncroppedUnrotatedPosition = this.getUncroppedUnrotatedPosition();
    }
  }

  public getUncroppedRotatedPosition() {
    if (!this.elementDefinition?.rotate?.angle) {
      return null;
    }
    const crop = this.getCropDefinition();
    const scale = Math.min(
      this.elementDefinition.size.width / crop.width,
      this.elementDefinition.size.height / crop.height,
    );
    const angle = this.elementDefinition.rotate?.angle;
    const size = this.getSize();
    const position = { ...this.elementDefinition.position };

    const center = {
      x: position.x + size.width * 0.5,
      y: position.y + size.height * 0.5,
    };
    const rotatedPosition = RotationHelper.rotate(position, angle, center);

    const x1 = crop.x1 * scale;
    const y1 = crop.y1 * scale;
    const rotatedCropDefinition = RotationHelper.rotate(
      {
        x: x1,
        y: y1,
      },
      angle,
    );

    const uncroppedRotatedPosition = {
      x: rotatedPosition.x - rotatedCropDefinition.x,
      y: rotatedPosition.y - rotatedCropDefinition.y,
    };
    return uncroppedRotatedPosition;
  }

  public getUncroppedUnrotatedPosition() {
    if (!this.elementDefinition?.rotate?.angle) {
      return null;
    }
    const crop = this.getCropDefinition();
    const scale = Math.min(
      this.elementDefinition.size.width / crop.width,
      this.elementDefinition.size.height / crop.height,
    );
    const uncroppedSize = this.getSize({ uncroppedDimensions: true });
    const angle = this.elementDefinition.rotate?.angle;
    const size = this.getSize();
    const position = { ...this.elementDefinition.position };

    const center = {
      x: position.x + size.width * 0.5,
      y: position.y + size.height * 0.5,
    };

    const x1 = crop.x1 * scale;
    const y1 = crop.y1 * scale;

    const uncroppedRotatedPosition = this.getUncroppedRotatedPosition();

    const rotatedUncroppedSize = RotationHelper.rotate(
      {
        x: x1 + size.width * 0.5 - uncroppedSize.width * 0.5,
        y: y1 + size.height * 0.5 - uncroppedSize.height * 0.5,
      },
      angle,
    );
    const newCenter = {
      x: center.x - rotatedUncroppedSize.x,
      y: center.y - rotatedUncroppedSize.y,
    };
    const uncroppedUnrotatedPosition = RotationHelper.rotate(uncroppedRotatedPosition, -angle, newCenter);
    return uncroppedUnrotatedPosition;
  }

  public drawImage(
    ctx: CanvasRenderingContext2D,
    imageElement: HTMLImageElement | HTMLCanvasElement,
    params: DrawImageParams,
  ) {
    if (params?.imageOpacity != null) {
      ctx.save();
      ctx.globalAlpha = params.imageOpacity;
    }

    if (params?.dw != null && params?.dh != null) {
      ctx.drawImage(
        imageElement,
        params.x,
        params.y,
        params.width,
        params.height,
        params.dx,
        params.dy,
        params.dw,
        params.dh,
      );
    } else {
      ctx.drawImage(imageElement, params.x, params.y, params.width, params.height);
    }

    if (params?.imageOpacity != null) {
      ctx.restore();
    }
  }

  /**
   * Get properties to crop the image to fit in @width and @height
   * according to @backgroundSize and relative to the image center.
   * @param imageSize
   * @param x
   * @param y
   * @param width
   * @param height
   * @returns
   */
  private getImageProperties(
    backgroundSize: BackgroundSizeType,
    imageSize: SizeDefinition,
    x,
    y,
    width,
    height,
  ): DrawImageParams {
    const imgW = imageSize.width;
    const imgH = imageSize.height;
    const scale = this.elementDefinition.scale?.x;
    let aspectRatio = Math.min(width / imgW, height / imgH);
    let newW = imgW * aspectRatio;
    let newH = imgH * aspectRatio;

    if (
      [
        PLACEHOLDER_IMAGE,
        PLACEHOLDER_ITEM_FAMILY_IMAGE,
        PLACEHOLDER_ITEM_OPTION_IMAGE,
        UNASSIGNED_PLACEHOLDER_IMAGE,
      ].indexOf(this.url) !== -1
    ) {
      return {
        x: x + (width - newW) * 0.5,
        y: y + (height - newH) * 0.5,
        width: newW,
        height: newH,
      };
    }

    aspectRatio = Math.min((width * (scale || 1)) / imgW, (height * (scale || 1)) / imgH);
    newW = imgW * aspectRatio;
    newH = imgH * aspectRatio;

    let cx,
      cy,
      cw,
      ch,
      ar = 1;

    if (!backgroundSize || backgroundSize === BackgroundSizeType.CONTAIN) {
      cw = width / aspectRatio;
      ch = height / aspectRatio;

      cx = (imgW - cw) * 0.5;
      cy = (imgH - ch) * 0.5;

      return {
        x: cx,
        y: cy,
        width: cw,
        height: ch,
        dx: x,
        dy: y,
        dw: width,
        dh: height,
      };
    }

    // decide which gap to fill
    if (newW < width) ar = (width * (scale || 1)) / newW;
    if (Math.abs(ar - 1) < 1e-14 && newH < height) ar = (height * (scale || 1)) / newH;

    newW *= ar;
    newH *= ar;

    // calc source rectangle
    cw = imgW / (newW / width);
    ch = imgH / (newH / height);

    cx = (imgW - cw) * 0.5;
    cy = (imgH - ch) * 0.5;

    // make sure source rectangle is valid
    if (scale == null) {
      if (cx < 0) cx = 0;
      if (cy < 0) cy = 0;
      if (cw > imgW) cw = imgW;
      if (ch > imgH) ch = imgH;
    }

    return {
      x: cx,
      y: cy,
      width: cw,
      height: ch,
      dx: x,
      dy: y,
      dw: width,
      dh: height,
    };
  }

  public clear() {
    this.canvasImageElement = null;
    this.imageElement = null;
  }

  public isMouseOnImage(event: MouseEvent) {
    const position = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    return this.isPointOnElement(position.x, position.y, 0);
  }

  public createSVGBorderContainer({ x, y, width, height }): HTMLElement {
    let borderElement;
    const border = this.elementDefinition.style?.border;
    const borderColor = border?.color;
    const borderWidth = border?.width;
    if (borderColor != null && borderColor !== 'rgba(0,0,0,0)' && borderWidth != 0) {
      borderElement = document.createElement('rect');
      borderElement.setAttribute('x', `${x}`);
      borderElement.setAttribute('y', `${y}`);
      borderElement.setAttribute('width', `${width}`);
      borderElement.setAttribute('height', `${height}`);
      borderElement.setAttribute('fill', 'none');
      this.setSVGStrokeAttribute(borderElement);
    }

    return borderElement;
  }
}
