<div class="flex items-center" cdkDrag cdkDragRootElement="app-property-configurator-bar">
  <mat-icon
    *ngIf="selectedElements?.length > 1"
    cdkDragHandle
    class="element-icon"
    svgIcon="multiple-elements"
  ></mat-icon>

  <ng-container *ngIf="selectedElements?.length === 1">
    <mat-icon
      *ngIf="elementType === 'frame' && !selectedElements[0]?.documentGenerationConfigId"
      cdkDragHandle
      class="element-icon"
      svgIcon="app-frame-icon"
    ></mat-icon>
    <mat-icon
      *ngIf="elementType === 'frame' && selectedElements[0].documentGenerationConfigId"
      cdkDragHandle
      class="element-icon"
      svgIcon="lineboard"
    ></mat-icon>
    <mat-icon
      *ngIf="shapes.includes(elementType)"
      cdkDragHandle
      class="element-icon"
      svgIcon="app-shape-tool"
    ></mat-icon>
    <mat-icon *ngIf="elementType === 'sticky_note'" cdkDragHandle class="element-icon icons material-icons-outlined">
      sticky_note_2
    </mat-icon>
    <mat-icon *ngIf="elementType === 'pen'" cdkDragHandle class="element-icon material-symbols-outlined">
      stylus
    </mat-icon>
    <mat-icon *ngIf="elementType === 'highlighter'" cdkDragHandle class="element-icon material-symbols-outlined">
      ink_highlighter
    </mat-icon>
    <mat-icon *ngIf="elementType === 'text'" cdkDragHandle class="element-icon" svgIcon="app-text-create"></mat-icon>
    <mat-icon *ngIf="elementType === 'line'" cdkDragHandle class="element-icon"> horizontal_rule </mat-icon>
    <mat-icon *ngIf="elementType === 'group'" cdkDragHandle class="element-icon" svgIcon="group-objects"></mat-icon>

    <!-- image, svg, color icons are in entity-details component -->
    <mat-icon
      *ngIf="['image', 'svg'].includes(elementType)"
      cdkDragHandle
      class="icons material-icons-outlined element-icon"
    >
      image
    </mat-icon>
    <mat-icon
      *ngIf="selectedElements[0]?.modelBindings?.color && elementType === 'component'"
      cdkDragHandle
      class="element-icon"
    >
      palette
    </mat-icon>
  </ng-container>

  <mat-divider vertical="true" class="divider"></mat-divider>
</div>
