import {
  AssetContainer,
  Mesh,
  MeshBuilder,
  Scene,
  SceneLoader,
  StandardMaterial,
  TransformNode,
  Vector3,
  Quaternion,
} from '@babylonjs/core';
import { EditableAssetMaterial } from '../material/editable-asset-material';
import { TextureTransform } from '../material/texture-transform';
import { BabylonHelpers } from '../util/babylon-helpers';

export class AssetWrapper {
  rootNode: TransformNode;

  private assetContainer: AssetContainer;
  private assetSize: Vector3;
  private boundingBoxOffset: Vector3;
  private boundingBox: Mesh;

  private editableMaterial: EditableAssetMaterial;

  constructor(scene) {
    this.rootNode = new TransformNode('root', scene);
    this.assetContainer = new AssetContainer(scene);
    this.assetSize = Vector3.Zero();
    this.boundingBoxOffset = Vector3.Zero();
    this.boundingBox = new Mesh('empty_bounding_box', scene);
  }

  static WrapAssetContainer(scene: Scene, assetContainer: AssetContainer): AssetWrapper {
    if (!assetContainer) {
      return;
    }

    const assetWrapper = new AssetWrapper(scene);
    assetWrapper.assetContainer = assetContainer;
    assetWrapper.assetContainer.addAllToScene();

    if (assetWrapper.assetContainer.meshes.length) {
      assetWrapper.assetContainer.meshes[0].setParent(assetWrapper.rootNode);

      // Calculate overall extents (root + all child meshes) for asset bounding box
      const min = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
      const max = new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE);
      BabylonHelpers.calcBoundForMeshAndChildren(assetWrapper.assetContainer.meshes[0] as Mesh, min, max);

      // Calculate diagonal size vector
      const size = max.subtract(min);
      assetWrapper.assetSize = size;

      // Use the size to calculate the worldspace midpoint of the bounding box
      assetWrapper.boundingBoxOffset = new Vector3(
        min.x + assetWrapper.assetSize.x / 2,
        min.y + assetWrapper.assetSize.y / 2,
        min.z + assetWrapper.assetSize.z / 2,
      );

      assetWrapper.boundingBox = MeshBuilder.CreateBox(
        'bounding_box',
        { width: assetWrapper.assetSize.x, height: assetWrapper.assetSize.y, depth: assetWrapper.assetSize.z },
        scene,
      );

      // Parent to root mesh instead of rootNode because we can offset using pivot presets
      assetWrapper.boundingBox.setParent(assetWrapper.assetContainer.meshes[0]);

      assetWrapper.boundingBox.position = assetWrapper.boundingBoxOffset;
      assetWrapper.boundingBox.material = new StandardMaterial('', scene);
      assetWrapper.boundingBox.material.wireframe = true;
      assetWrapper.boundingBox.isVisible = false;

      assetWrapper.assetContainer.meshes[0].position = assetWrapper.boundingBoxOffset.scale(-1);
    }

    return assetWrapper;
  }

  getAssetContainer(): AssetContainer {
    return this.assetContainer;
  }

  // Returns the root mesh of the asset, rather than the rootNode of the wrapper
  getRootMesh(): Mesh {
    return this.assetContainer.meshes[0] as Mesh;
  }

  getAssetSize(): Vector3 {
    return this.assetSize;
  }

  getBoundingBoxOffset(): Vector3 {
    return this.boundingBoxOffset;
  }

  setBoundsVisibility(newVisibility: boolean): void {
    this.boundingBox.isVisible = newVisibility;
  }

  // #region Convenience Functions
  getPosition(): Vector3 {
    return this.rootNode.position;
  }

  getAbsolutePosition(): Vector3 {
    return this.rootNode.absolutePosition;
  }

  setPosition(position: Vector3): void {
    this.rootNode.position = position;
  }

  setAbsolutePosition(absolutePosition: Vector3): void {
    this.rootNode.setAbsolutePosition(absolutePosition);
  }

  getRotation(): Vector3 {
    return this.rootNode.rotation;
  }

  setRotation(rotation: Vector3): void {
    this.rootNode.rotation = rotation;
  }

  getScaling(): Vector3 {
    return this.rootNode.scaling;
  }

  setScaling(scaling: Vector3): void {
    this.rootNode.scaling = scaling;
  }

  setParent(parent: TransformNode): void {
    this.rootNode.parent = parent; // Setting parent directly causes position values to not be recalculated, this causing relative transform to stay the same (vs using setParent which will keep world transform but recalc relative)
    this.rootNode.position._isDirty = true; // This is necessary to ensure position actually updates after setting parent
    this.rootNode.rotation._isDirty = true;
  }
  // #endregion

  dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
    this.rootNode.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  // Allows setting the asset pivot to bottom/top/center of bounding box
  // Moves root mesh and not rootNode because we're offsetting the mesh from the rootNode (aka the pivot)
  setPivotByPreset(presetName: string): void {
    switch (presetName) {
      case 'asset': {
        this.getRootMesh().position = Vector3.Zero();
        break;
      }
      case 'center': {
        this.getRootMesh().position = this.boundingBoxOffset.scale(-1);
        break;
      }
      case 'bottom': {
        this.getRootMesh().position = new Vector3(
          this.boundingBoxOffset.x,
          this.boundingBoxOffset.y - this.assetSize.y / 2,
          this.boundingBoxOffset.z,
        ).scale(-1);
        break;
      }
      case 'top': {
        this.getRootMesh().position = new Vector3(
          this.boundingBoxOffset.x,
          this.boundingBoxOffset.y + this.assetSize.y / 2,
          this.boundingBoxOffset.z,
        ).scale(-1);
        break;
      }
    }
  }

  async recolorAsset(colorHex: string): Promise<void> {
    if (!this.editableMaterial) {
      this.editableMaterial = new EditableAssetMaterial();
      await this.editableMaterial.initialize(this.assetContainer);
    }

    this.editableMaterial.setColorFromHex(colorHex);
  }

  resetTextures(): void {
    if (this.editableMaterial) {
      this.editableMaterial.resetTextures();
    }
  }

  async setTexture(textureData: string): Promise<void> {
    if (!this.editableMaterial) {
      this.editableMaterial = new EditableAssetMaterial();
      await this.editableMaterial.initialize(this.assetContainer);
    }

    this.editableMaterial.setTextureFromStr(textureData);
  }

  async setTextureTransform(textureTransform: TextureTransform): Promise<void> {
    if (!this.editableMaterial) {
      this.editableMaterial = new EditableAssetMaterial();
      await this.editableMaterial.initialize(this.assetContainer);
    }

    this.editableMaterial.setTextureTransform(textureTransform);
  }
}
