import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR } from '../../constants';
import { RotationHelper } from '../rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../selection-widget-renderer/selection-widget-renderer';

export class CropBoxRenderer {
  private readonly HANDLE_LENGTH = 14;
  public readonly HANDLE_WIDTH = 4;
  private readonly STROKE_WIDTH = 1;
  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw highlight box for target element
   */
  public draw() {
    const isEditingCrop = this.canvasDocument.state.cropState.isEditingCrop;
    if (isEditingCrop) {
      const elementId = isEditingCrop as string;
      const element = this.canvasDocument.getCanvasElementById(elementId);
      if (!element) {
        return;
      }

      const { x, y, width, height } = element.getDimensions();
      const handleLength = this.canvasDocument.getScaledValue(this.HANDLE_LENGTH);
      const lineWidth = this.getScaledValue(this.HANDLE_WIDTH);
      const halfHandleLength = handleLength * 0.5;
      const halfLineWidth = lineWidth * 0.5;
      const halfWidth = width * 0.5;
      const halfHeight = height * 0.5;

      const ctx = this.canvasDocument.canvasDisplay.context;

      ctx.save();

      ctx.translate(x + width * 0.5, y + height * 0.5);
      ctx.rotate(CanvasUtil.getAngle(element.elementDefinition?.rotate?.angle ?? 0));

      // ctx.beginPath();

      // ctx.rect(
      //   -width*0.5,
      //   -height*0.5,
      //   width,
      //   height
      // )

      // ctx.strokeStyle = ACCENT_COLOR;
      // ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      // ctx.stroke();
      // ctx.closePath();

      ctx.beginPath();

      const maxX1 =
        Math.abs(-halfWidth + halfLineWidth + handleLength) < halfWidth
          ? -halfWidth + halfLineWidth + handleLength
          : halfWidth * 0.5;
      const maxY1 =
        Math.abs(-halfHeight + handleLength + halfLineWidth) < halfHeight
          ? -halfHeight + handleLength + halfLineWidth
          : -halfHeight * 0.5;
      const maxX2 =
        Math.abs(halfWidth - handleLength - halfLineWidth) < halfWidth
          ? halfWidth - handleLength - halfLineWidth
          : -halfWidth * 0.5;
      const maxY2 =
        Math.abs(halfHeight - handleLength - halfLineWidth) < halfHeight
          ? halfHeight - handleLength - halfLineWidth
          : halfHeight * 0.5;
      const maxCX1 = Math.abs(-halfHandleLength) < halfWidth ? -halfHandleLength : halfWidth * 0.5;
      const maxCX2 = Math.abs(halfHandleLength) < halfWidth ? halfHandleLength : halfWidth * 0.5;
      const maxCY1 = Math.abs(-halfHandleLength) < halfHeight ? -halfHandleLength : -halfHeight * 0.5;
      const maxCY2 = Math.abs(halfHandleLength) < halfHeight ? halfHandleLength : halfHeight * 0.5;

      // top left
      ctx.moveTo(-halfWidth + halfLineWidth, maxY1);
      ctx.lineTo(-halfWidth + halfLineWidth, -halfHeight + halfLineWidth);
      ctx.lineTo(maxX1, -halfHeight + halfLineWidth);

      // top center
      ctx.moveTo(maxCX1, -halfHeight + halfLineWidth);
      ctx.lineTo(maxCX2, -halfHeight + halfLineWidth);

      // top right
      ctx.moveTo(maxX2, -halfHeight + halfLineWidth);
      ctx.lineTo(halfWidth - halfLineWidth, -halfHeight + halfLineWidth);
      ctx.lineTo(halfWidth - halfLineWidth, maxY1);

      // right middle
      ctx.moveTo(halfWidth - halfLineWidth, maxCY1);
      ctx.lineTo(halfWidth - halfLineWidth, maxCY2);

      // right bottom
      ctx.moveTo(halfWidth - halfLineWidth, maxY2);
      ctx.lineTo(halfWidth - halfLineWidth, halfHeight - halfLineWidth);
      ctx.lineTo(maxX2, halfHeight - halfLineWidth);

      // bottom center
      ctx.moveTo(maxCX1, halfHeight - halfLineWidth);
      ctx.lineTo(maxCX2, halfHeight - halfLineWidth);

      // bottom left
      ctx.moveTo(maxX1, halfHeight - halfLineWidth);
      ctx.lineTo(-halfWidth + halfLineWidth, halfHeight - halfLineWidth);
      ctx.lineTo(-halfWidth + halfLineWidth, maxY2);

      // left middle
      ctx.moveTo(-halfWidth + halfLineWidth, maxCY1);
      ctx.lineTo(-halfWidth + halfLineWidth, maxCY2);

      ctx.strokeStyle = ACCENT_COLOR;
      ctx.lineWidth = lineWidth;
      ctx.stroke();

      ctx.restore();
    }
  }

  public getScaledValue(v) {
    const viewScale = this.canvasDocument.getViewScale();
    return Math.round(v / viewScale.x);
  }

  protected isOnDragHandle(p1, p2) {
    const dragHandleWidthDelta = this.canvasDocument.getScaledValue(this.HANDLE_LENGTH);
    return Math.abs(p1 - p2) < dragHandleWidthDelta;
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    let direction;

    const isEditingCrop = this.canvasDocument.state.cropState.isEditingCrop;
    if (!isEditingCrop) {
      return;
    }

    const elementId = isEditingCrop as string;
    const element = this.canvasDocument.getCanvasElementById(elementId);
    if (!element) {
      return;
    }

    const { x, y, width, height } = element.getDimensions();
    if (element.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -element.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }

    const top = this.isOnDragHandle(py, y);
    const left = this.isOnDragHandle(px, x);
    const right = this.isOnDragHandle(px, x + width);
    const bottom = this.isOnDragHandle(py, y + height);
    const center = this.isOnDragHandle(px, x + width * 0.5);
    const mid = this.isOnDragHandle(py, y + height * 0.5);
    const body = py <= y + height && py >= y && px >= x && px <= x + width;

    switch (true) {
      case left && top:
        direction = DRAG_DIRECTIONS.CROP_TOP_LEFT;
        break;
      case right && top:
        direction = DRAG_DIRECTIONS.CROP_TOP_RIGHT;
        break;
      case left && bottom:
        direction = DRAG_DIRECTIONS.CROP_BOTTOM_LEFT;
        break;
      case right && bottom:
        direction = DRAG_DIRECTIONS.CROP_BOTTOM_RIGHT;
        break;
      case center && top:
        direction = DRAG_DIRECTIONS.CROP_TOP_CENTER;
        break;
      case center && bottom:
        direction = DRAG_DIRECTIONS.CROP_BOTTOM_CENTER;
        break;
      case right && mid:
        direction = DRAG_DIRECTIONS.CROP_MID_RIGHT;
        break;
      case left && mid:
        direction = DRAG_DIRECTIONS.CROP_MID_LEFT;
        break;
      // case body:
      //   direction = DRAG_DIRECTIONS.CROP_BODY;
      //   break;
      default:
        break;
    }

    return direction;
  }
}
