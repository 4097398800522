import {
  DocumentElement,
  PositionDefinition,
  ScaleTransformation,
  SizeDefinition,
  StyleDefinition,
  ViewBox,
} from '@contrail/documents';
import {
  DEFAULT_PEN_BORDER_SIZE,
  DEFAULT_HIGHLIGHTER_BORDER_SIZE,
  DEFAULT_PEN_BORDER_COLOR,
  DEFAULT_HIGHLIGHTER_BORDER_COLOR,
} from './constants';
import { CoordinateBox } from './coordinate-box';
import { rgb, keyword } from 'color-convert';

const req =
  (typeof requestAnimationFrame !== 'undefined' && requestAnimationFrame) ||
  function (f) {
    setTimeout(f, 60);
  };
let animQueue: Array<Function> = [];

export const CanvasUtil = {
  requestAnimFrame(callback: Function) {
    animQueue.push(callback);
    if (animQueue.length === 1) {
      req(function () {
        const queue = animQueue;
        animQueue = [];
        queue.forEach(function (cb) {
          cb();
        });
      });
    }
  },
  toDocumentPosition(
    x: number,
    y: number,
    viewBox: ViewBox,
    viewScale: ScaleTransformation,
    boundingClientRect: DOMRect,
  ): PositionDefinition {
    return {
      x: (x - boundingClientRect.x) / viewScale.x + viewBox.x,
      y: (y - boundingClientRect.y) / viewScale.y + viewBox.y,
    };
  },
  toDocumentSize(width: number, height: number, viewScale: ScaleTransformation): SizeDefinition {
    return {
      width: width / viewScale.x,
      height: height / viewScale.y,
    };
  },
  toWindowPosition(
    x: number,
    y: number,
    viewBox: ViewBox,
    viewScale: ScaleTransformation,
    boundingClientRect?: DOMRect,
    relative = true,
  ): PositionDefinition {
    return {
      x: (x - viewBox.x) * viewScale.x + (relative ? boundingClientRect.x : 0),
      y: (y - viewBox.y) * viewScale.y + (relative ? boundingClientRect.y : 0),
    };
  },
  toWindowSize(width: number, height: number, viewScale: ScaleTransformation): SizeDefinition {
    return {
      width: width * viewScale.x,
      height: height * viewScale.y,
    };
  },
  // to radians
  getAngle(angleDeg): number {
    return ((angleDeg ?? 0) * Math.PI) / 180;
  },
  /**
   * Check if @elementBox is in @box or fully covers the box.
   * @param box
   * @param elementBox
   * @returns
   */
  isInBox(box: CoordinateBox, elementBox: CoordinateBox) {
    let inBox = !(
      box.bottom < elementBox.bottom ||
      box.top > elementBox.top ||
      box.right < elementBox.right ||
      box.left > elementBox.left
    );
    return inBox;
  },
  overlap(boxA: ViewBox, boxB: ViewBox) {
    return !(
      boxA.y + boxA.height < boxB.y ||
      boxA.y > boxB.y + boxB.height ||
      boxA.x + boxA.width < boxB.x ||
      boxA.x > boxB.x + boxB.width
    );
  },
  isWithinMargin(a, b, m): boolean {
    return a >= b - m && a <= b + m;
  },
  isPositionInBox(position: PositionDefinition, box: CoordinateBox) {
    return position.y <= box.bottom && position.y >= box.top && position.x >= box.left && position.x <= box.right;
  },
  distance(x1: number, y1: number, x2: number, y2: number): number {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  },
  getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  },
  isFirefox() {
    // @ts-ignore
    return typeof InstallTrigger !== 'undefined';
  },
  getCoveredAreaPercentage(r1: SizeDefinition, r2: SizeDefinition) {
    return ((r1.width * r1.height) / (r2.width * r2.height)) * 100;
  },
  // Rounding, flooring, and ceiling is +/- 20% faster using bitwise operations.
  round(n) {
    return (n + (n < 0 ? -0.5 : 0.5)) >> 0;
  },
  ceil(n) {
    return (n + (n < 0 ? 0 : 1)) >> 0;
  },
  floor(n) {
    return (n + (n < 0 ? -1 : 0)) >> 0;
  },
  uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  },
  isPx(str) {
    return /[0-9.]+px$/.test(str);
  },
  toPt(px, precision = 0) {
    const factor = Math.pow(10, precision);
    return Math.round(((parseInt(px, 10) * 72) / 96) * factor) / factor;
  },
  toPx(pt, precision = 0) {
    const factor = Math.pow(10, precision);
    return Math.round((parseInt(pt, 10) / 72) * 96 * factor) / factor;
  },
  convertToPt(fontSize, precision = 0) {
    if (CanvasUtil.isPx(fontSize)) {
      return CanvasUtil.toPt(fontSize, precision) + 'pt';
    }
    return fontSize;
  },
  isNumber(n) {
    return typeof n === 'number';
  },
  isValidDimensions({ x, y, width, height }) {
    return (
      CanvasUtil.isNumber(x) && CanvasUtil.isNumber(y) && CanvasUtil.isNumber(width) && CanvasUtil.isNumber(height)
    );
  },
  removeFontSize(text: string): string {
    return text?.replace(/(?:\bfont-size:)\s*([^;]*)[;"](?=[^>]*>)/g, '');
  },
  getFirstFontSize(text: string, convert = true) {
    const regex = /(?:\bfont-size:)\s*([^;]*)[;"](?=[^>]*>)/;
    const found = text?.match(regex);
    if (found?.length > 1) {
      let fontSize = found[1];
      if (!convert) {
        return fontSize;
      }
      return CanvasUtil.convertToPt(fontSize).replace('pt', '').replace('px', '');
    }
    return null;
  },
  getFirstFontColor(text: string) {
    const regex = /(?:\bcolor:)\s*([^;]*)[;"](?=[^>]*>)/;
    const found = text?.match(regex);
    if (found?.length > 1) {
      return found[1];
    }
    return null;
  },
  isEmptyTextContent(innerHtml: string, plainText: string) {
    if (innerHtml?.indexOf('<li>') !== -1 || innerHtml?.indexOf('padding') !== -1) {
      return false;
    }
    return plainText == '' || plainText == '\n';
  },
  getFirstTextNode(root) {
    let firstTextNode = null;
    try {
      const iter = document.createNodeIterator(root, NodeFilter.SHOW_TEXT);
      firstTextNode = iter?.nextNode();
    } catch (e) {}
    return firstTextNode;
  },
  getSvgPathBounds(points: Array<[number, number]>): number[] {
    if (!points.length) return [0, 0, 0, 0];

    const bounds = [Infinity, Infinity, -Infinity, -Infinity];

    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const x = p[0];
      const y = p[1];

      if (x < bounds[0]) bounds[0] = x;
      if (y < bounds[1]) bounds[1] = y;
      if (x > bounds[2]) bounds[2] = x;
      if (y > bounds[3]) bounds[3] = y;
    }

    return bounds;
  },
  getDefaultStyle(key: string): StyleDefinition {
    let defaultStyle: StyleDefinition = {};
    if (['pen', 'highlighter'].indexOf(key) !== -1) {
      defaultStyle = {
        border: {
          width: key === 'pen' ? DEFAULT_PEN_BORDER_SIZE : DEFAULT_HIGHLIGHTER_BORDER_SIZE,
          color: key === 'pen' ? DEFAULT_PEN_BORDER_COLOR : DEFAULT_HIGHLIGHTER_BORDER_COLOR,
        },
      };
    }
    return defaultStyle;
  },
  toHex(color: string): { hex: string; a?: string; transparency?: number } {
    const rgbaRegEx =
      /^(rgb)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/;
    const hexRegEx = /[a-f0-9]{6}|[a-f0-9]{3}/i;
    if (rgbaRegEx.exec(color)) {
      const [, , , r, g, b, a] = rgbaRegEx.exec(color);
      let hex = rgb.hex(parseInt(r), parseInt(g), parseInt(b));
      let transparency;
      if (a) {
        transparency = 100 - parseFloat(a) * 100;
      }
      console.log('1', color, hex, a, transparency);
      return { hex, a, transparency };
    } else if (keyword.rgb(color)) {
      const [r, g, b] = keyword.rgb(color);
      let hex = rgb.hex(parseInt(r), parseInt(g), parseInt(b));
      console.log('2', color, hex);
      return { hex };
    } else {
      console.log('3', color, this.normalizeHex(color?.replace('#', '')));
      return { hex: this.normalizeHex(color?.replace('#', '')) };
    }
  },
  normalizeHex(hex) {
    return hex.length > 3 ? hex : hex.replaceAll(/(.{1})/g, '$1$1');
  },
  getCommonBounds(elements: DocumentElement[]): CoordinateBox {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    elements.forEach((element) => {
      minX = Math.min(minX, element.position.x);
      minY = Math.min(minY, element.position.y);
      maxX = Math.max(maxX, element.position.x + element.size.width);
      maxY = Math.max(maxY, element.position.y + element.size.height);
    });
    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      top: minY,
      bottom: maxY,
      right: maxX,
      left: minX,
    };
  },
};
