import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ComponentsModule } from '@components/components.module';
import { StatusMessagesIndicatorComponent } from './status-messages/status-messages-indicator/status-messages-indicator.component';
import { StatusMessagesListComponent } from './status-messages/status-messages-list/status-messages-list.component';

@NgModule({
  declarations: [StatusMessagesIndicatorComponent, StatusMessagesListComponent],
  imports: [CommonModule, MatIconModule, MatBadgeModule, MatSlideToggleModule, MatSnackBarModule, ComponentsModule],
  exports: [StatusMessagesIndicatorComponent, StatusMessagesListComponent],
})
export class CollectionStatusMessageModule {}
