import { Directive, HostListener } from '@angular/core';

@Directive()
export abstract class ComponentCanDeactivate {
  abstract showConfirmDialog(): any;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.showConfirmDialog()) {
      $event.returnValue = false;
      return false;
    }
  }
}
