import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { SelectionWidgetRenderer } from './selection-widget-renderer';

export class ElementSelectionWidgetRenderer extends SelectionWidgetRenderer {
  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {
    super(canvasDocument, element);
  }
}
