import { createSelector } from '@ngrx/store';
import { documentModelEntityAdapter } from './document-model-entities.state';
import { RootStoreState } from 'src/app/root-store';

export const { selectAll, selectEntities, selectIds, selectTotal } = documentModelEntityAdapter.getSelectors();

const documentModelEntities = (state: RootStoreState.State) => state.document.documentModelEntities.entities;

export const selectDocumentModelEntities = createSelector(documentModelEntities, (state) => Object.values(state));
export const selectDocumentModelEntity = (id: string) =>
  createSelector(documentModelEntities, (map) => {
    return map[id];
  });
