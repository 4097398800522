<div class="header">
  <div class="title">Properties</div>
  <div (click)="handleClose()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div tabindex="1" #dataPane class="chooser">
  <!-- <app-search-bar #documentSearch [placeholder]="'Search'"  #searchBar class="m-2"></app-search-bar> -->
  <div class="data-pane" *ngIf="properties?.length">
    <virtual-scroller [style.height]="resultsHeight" #scroll [items]="properties" class="narrow-scroll">
      <div
        class="data-row"
        [draggable]="true"
        [id]="entity.id"
        (dragstart)="startDrag($event, entity)"
        *ngFor="let entity of scroll.viewPortItems"
      >
        <div class="font-bold">{{ entity.label }}</div>
        <div *ngIf="entity.display" class="mt-1">{{ entity.display }}</div>
      </div>
    </virtual-scroller>
  </div>
</div>
