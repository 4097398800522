import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PipesModule } from '@common/pipes/pipes.module';
import { ComponentsModule } from '@components/components.module';
import { HeaderClipboardComponent } from './header-clipboard/header-clipboard.component';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [HeaderClipboardComponent],
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule, MatBadgeModule, PipesModule, ComponentsModule],
  exports: [HeaderClipboardComponent],
})
export class ClipboardModule {}
