<ng-container *ngIf="(frames$ | async)?.length && (frames$ | async) as frames">
  <button
    mat-button
    class="menu-button"
    [matTooltip]="presentation ? 'Stop Frame View' : 'Frame Presentation Mode'"
    data-test="frame-mode-btn"
    (click)="toggleMode(frames[0])"
  >
    <mat-icon class="material-icons-outlined">{{
      presentation ? 'stop_circle_outline' : 'play_circle_outline'
    }}</mat-icon>
  </button>

  <ng-container *ngIf="presentation">
    <button
      mat-button
      matTooltip="Prev"
      class="menu-button"
      (click)="back(frames[selectedIdx$.value - 1], selectedIdx$.value)"
      [disabled]="selectedIdx$.value < 1"
      data-test="prev-frame"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>

    <mat-select [value]="frames[selectedIdx$.value]?.id" #frameSelect class="!w-36 !mx-1">
      <mat-select-trigger>
        {{ frames[selectedIdx$.value]?.frame?.name }}
      </mat-select-trigger>
      <ng-container *ngFor="let f of frames; index as i">
        <mat-option [value]="f.id" (click)="selectFrame(f, i)">
          <span class="font-medium mr-1 text-grey">{{ i + 1 }}.</span> {{ f.frame.name | shorten: 17 }}
        </mat-option>
      </ng-container>
    </mat-select>

    <button
      mat-button
      matTooltip="Next"
      class="menu-button"
      (click)="next(frames[selectedIdx$.value + 1], selectedIdx$.value)"
      [disabled]="selectedIdx$.value === frames.length - 1"
      data-test="next-frame"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </ng-container>

  <mat-divider [vertical]="true" class="border-l h-6 !mx-1"></mat-divider>
</ng-container>

<button *ngIf="!(isFullScreen$ | async)" mat-button matTooltip="Full screen" class="menu-button" (click)="fullScreen()">
  <mat-icon>fullscreen</mat-icon>
</button>
<button
  *ngIf="isFullScreen$ | async"
  mat-button
  matTooltip="Exit full screen"
  class="menu-button"
  (click)="exitFullScreen()"
>
  <mat-icon>fullscreen_exit</mat-icon>
</button>

<button
  mat-button
  matTooltip="Move to starting location"
  (click)="navigateToStartingLocation()"
  class="menu-button"
  data-test="reset-to-starting-location"
>
  <mat-icon>settings_backup_restore</mat-icon>
</button>
<button
  mat-button
  matTooltip="Pin map"
  (click)="toggleMinimap()"
  class="menu-button"
  [ngClass]="{ active: showMinimap }"
>
  <mat-icon>map</mat-icon>
</button>

<mat-divider [vertical]="true" class="border-l h-6 !mx-1"></mat-divider>
<button mat-button matTooltip="Zoom out" (click)="doZoomOut()" class="menu-button">
  <mat-icon>zoom_out</mat-icon>
</button>
<button mat-button matTooltip="Zoom in" (click)="doZoomIn()" class="menu-button !-ml-2">
  <mat-icon>zoom_in</mat-icon>
</button>
<button class="opacity-80 !p-1" mat-button [matMenuTriggerFor]="contextMenu" data-test="zoom-menu-button">
  <span>{{ 100 * (viewSize$ | async)?.viewScale?.x | number: '1.0-0' }}%</span>
  <span class="material-icons"> arrow_drop_up </span>
</button>
<mat-menu #contextMenu="matMenu" xPosition="before" class="menu-md">
  <button mat-menu-item class="!px-5" (click)="setZoomScale(0.125)">Zoom to 800%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(0.25)">Zoom to 400%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(0.5)">Zoom to 200%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(1)" data-test="zoom-to-100-button">Zoom to 100%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(2)" data-test="zoom-to-50-button">Zoom to 50%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(4)">Zoom to 25%</button>
  <button mat-menu-item class="!px-5" (click)="zoomToFit()" data-test="zoom-to-fit-button">Zoom to fit</button>
</mat-menu>
