import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { TypeFormChange } from '@common/types/forms/type-form-change';
import { Entities, Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EntityPropertiesFormComponent } from './entity-properties-form/entity-properties-form.component';

@Component({ template: '' })
export abstract class EntityDetailsBaseComponent implements OnChanges, AfterViewInit {
  @ViewChildren(EntityPropertiesFormComponent) entityFormQuery: QueryList<EntityPropertiesFormComponent>;
  private propertyForm: EntityPropertiesFormComponent;

  public pws: any = {}; // parentWorkspace
  @Input() entityReference: string;
  @Input() entity: any;
  @Input() selectedNum: number;
  @Input() currentFolderId;
  @Output() cancelView = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() accessLevel = 'EDIT';

  public type: Type;
  public filteredType;
  public contentEditable = true;

  constructor(
    protected ref: ChangeDetectorRef,
    protected store: Store<RootStoreState.State>,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.currentFolderId?.currentValue) {
      this.entity = null;
      const id = changes.currentFolderId.currentValue;
      this.pws = await new Entities().get({ entityName: 'workspace', id, relations: ['updatedBy', 'createdBy'] });
    } else if (changes?.entityReference?.currentValue) {
      await this.loadEntity(changes?.entityReference?.currentValue);
    } else {
      // console.log('NO HANDLING   =========', this.entity);
    }
  }
  async loadEntity(entityReference: string) {
    if (!this.entity) {
      this.entity = await new Entities().get({ reference: entityReference, relations: ['updatedBy', 'createdBy'] });
    } else {
      this.entity = this.entity;
    }
    const id = this.entity?.entity?.typeId || this.entity?.typeId;
    if (id) {
      this.type = await new Types().getType({ id });
      this.filteredType = this.type;
    }
  }

  ngAfterViewInit() {
    if (this.entityFormQuery.toArray().length) {
      this.subscribeToForm(this.entityFormQuery.toArray()[0]);
    }
    this.entityFormQuery.changes.subscribe((formQuery: QueryList<EntityPropertiesFormComponent>) => {
      const results = formQuery.toArray();
      if (results.length > 0) {
        this.subscribeToForm(results[0]);
      }
    });

    // this.store.select(AuthSelectors.selectAuthContext)
    // .pipe(
    //   take(1),
    //   tap(context => {
    //     // TODO:  Short term rule for converse.
    //     // This could be later replaced with something that checks for
    //     // editability of a 'relationship' property definition on the item type.
    //     if (context.currentOrg.orgSlug.indexOf('converse') > -1) {
    //       this.contentEditable = false;
    //     }
    //   })
    // ).subscribe();
  }
  private subscribeToForm(form: EntityPropertiesFormComponent) {
    this.propertyForm = form;
    this.propertyForm.changes.subscribe((formChanges) => this.updateEntityValues(formChanges));
  }

  cancel() {
    this.cancelView.emit();
  }
  isValid() {}

  async updateEntityValues(formChanges: Array<TypeFormChange>) {
    console.log('EntityDetailComponent: updateEntityValues: ', formChanges);
    const changes = {};
    for (let change of formChanges) {
      changes[change.propertySlug] = change.value || null;
    }

    // APPLY CHANGES LOCALLY
    this.entity = Object.assign({}, this.entity, changes);
    this.entity.updatedOn = new Date();

    // EMIT CHANGES TO THE SOURCE APP
    const emittedChanges = ObjectUtil.cloneDeep(changes); // to void mutation...
    this.updated.emit({ entity: this.entity, changes: emittedChanges });

    // PERSIST THE CHANGES
    console.log('updating enity: ', JSON.stringify(changes));
    const updatedItem = await new Entities().update({ reference: this.entityReference, object: changes });
  }

  handlePrimaryViewableChange(content) {
    let changes;
    if (content) {
      changes = {
        mediumViewableDownloadUrl: content.mediumViewable.downloadUrl,
        smallViewableDownloadUrl: content.mediumViewable?.downloadUrl,
      };
    } else {
      changes = {
        mediumViewableDownloadUrl: null,
        smallViewableDownloadUrl: null,
      };
    }
    Object.assign(this.entity, changes);
    this.updated.emit({ object: this.entity, changes });
  }
}
