import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Item } from '../../item';

@Component({
  selector: 'app-item-type-selector',
  templateUrl: './item-type-selector.component.html',
  styleUrls: ['./item-type-selector.component.scss'],
})
export class ItemTypeSelectorComponent implements OnChanges {
  @Input() item: Item;
  public type: Type;
  constructor() {}

  async ngOnChanges() {
    if (this.item) {
      this.type = await new Types().getType({ id: this.item.typeId });
    }
  }
}
