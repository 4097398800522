<div class="flex flex-row items-center content-center">
  <button mat-icon-button [matMenuTriggerFor]="borderStyleMenu"><mat-icon>line_style</mat-icon></button>
</div>

<mat-menu #borderStyleMenu="matMenu">
  <div class="relative bg-white border-none">
    <ng-container *ngFor="let styleValue of borderStyles">
      <button mat-menu-item (click)="setValue(styleValue.style)">
        <mat-icon
          [svgIcon]="styleValue.svgIconClass"
          class="!mr-0"
          [ngClass]="isMatch(styleValue.style) ? '!text-primary' : '!text-black60'"
          >{{ styleValue.iconClass }}</mat-icon
        >
      </button>
    </ng-container>
  </div>
</mat-menu>
