import { State } from './clipboard.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as ClipboardActions from './clipboard.actions';
import { clipboardInitialState } from './clipboard.state';
import { clipboardItemsReducers } from './clipboard-items/clipboard-items.reducer';

const setClipboard = (state: State, { clipboard }) => {
  return {
    ...state,
    clipboard,
  };
};

export const clipboardReducers = createReducer(
  clipboardInitialState,
  ...clipboardItemsReducers,
  on(ClipboardActions.loadClipboardSuccess, setClipboard),
);

export function reducer(state: State | undefined, action: Action): any {
  return clipboardReducers(state, action);
}
