import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { commentsReducer } from './comments.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CommentsEffects } from './comments-effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('comments', commentsReducer),
    EffectsModule.forFeature([CommentsEffects]),
  ],
})
export class CommentsStoreModule {}
