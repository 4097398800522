import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { CommentsStoreState } from '../common/comments/comments-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { BoardsStoreState } from '../boards/boards-store';
import { DocumentStoreState } from '../boards/board/document/document-store';
import { AssortmentsStoreState } from '../common/assortments/assortments-store';
import { AppExtensionsStoreState } from '../common/app-extensions/app-extensions-store';
import { DocumentManagerStoreState } from '../boards/board/document-manager/document-manager-store';
import { WorkspacesStoreState } from '@common/workspaces/workspaces-store';
import { DocumentHistoryStoreState } from '@common/document-history/document-history-store';
import { EditorModeStoreState } from '@common/editor-mode/editor-mode-store';
import { SearchReplaceStoreState } from '@common/search-replace/search-replace-store';
import { FeatureFlagsStoreState } from '@common/feature-flags';
import { FrameTemplatesStoreState } from '@common/frame-templates/frame-templates-store';
import { ClipboardStoreState } from '@common/clipboard/clipboard-store';

export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  comments: CommentsStoreState.State;
  clipboard: ClipboardStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  boards: BoardsStoreState.State;
  assortments: AssortmentsStoreState.State;
  appExtensions: AppExtensionsStoreState.State;
  featureFlags: FeatureFlagsStoreState.State;
  document: DocumentStoreState.State;
  workspaces: WorkspacesStoreState.State;
  documentManager: DocumentManagerStoreState.State;
  documentHistory: DocumentHistoryStoreState.State;
  editorMode: EditorModeStoreState.State;
  searchReplace: SearchReplaceStoreState.State;
  frameTemplates: FrameTemplatesStoreState.State;
}
