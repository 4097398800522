import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesSelectors, WorkspacesActions } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';

@Component({
  selector: 'app-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
})
export class WorkspaceSelectorComponent implements OnInit {
  public workspaces$: Observable<Array<Workspace>>;
  public selectedWorkspace$: Observable<Workspace>;
  constructor(private store: Store<RootStoreState.State>) {
    this.workspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    this.selectedWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
  }

  ngOnInit(): void {}

  public selectWorkspace(workspace: Workspace) {
    this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({ id: workspace.id }));
  }
}
