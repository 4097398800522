import { clipboardItemEntityAdapter, ClipboardItemsState } from './clipboard-items/clipboard-items.state';
import { Clipboard } from '../clipboard.interfaces';

export interface State {
  clipboard: Clipboard;
  clipboardItems: ClipboardItemsState;
}

export const clipboardInitialState: State = {
  clipboard: null,
  clipboardItems: clipboardItemEntityAdapter.getInitialState({}),
};
