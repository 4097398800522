import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DocumentGenerationConfig } from '../../../document-generator/document-generator.interfaces';
import { DocumentActions } from '../../document-store';
import { SideMenuOverlay } from '../../document-store/document.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { GenerateDocumentUtil } from '../../../document-generator/generate-document-util';
import { BoardsActions, BoardsSelectors } from 'src/app/boards/boards-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-property-configurator-lineboard',
  templateUrl: './property-configurator-lineboard.component.html',
  styleUrls: ['./property-configurator-lineboard.component.scss'],
})
export class PropertyConfiguratorLineboardComponent implements OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();
  constructor(private store: Store<RootStoreState.State>) {
    this.subscription.add(
      this.store
        .select(BoardsSelectors.currentDocumentGenerationConfig)
        .subscribe(async (documentGenerationConfig: DocumentGenerationConfig) => {
          console.log('documentGenerationConfig: ', documentGenerationConfig);
          this.setUpDocumentGenerationConfig(documentGenerationConfig);
        }),
    );
  }

  @Input() selectedElement: any;
  @Input() visible = false;
  documentGenerationConfig: DocumentGenerationConfig;
  tooltipText = '';
  groupingProperties = '';
  secondaryGrouping = '';

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.groupingProperties = '';
    this.secondaryGrouping = '';
    if (
      ((changes.visible && changes.visible.currentValue) || this.selectedElement.documentGenerationConfigId) &&
      this.selectedElement.documentGenerationConfigId !== this.documentGenerationConfig?.id
    ) {
      this.store.dispatch(
        BoardsActions.loadCurrentDocumentGenerationConfig({ id: this.selectedElement.documentGenerationConfigId }),
      );
    }
  }

  public editLineboardConfig() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = 'lineboardEditor';
    overlay.showChooser = true;
    overlay.element = this.selectedElement;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  getTooltipText() {
    if (!this.documentGenerationConfig) {
      return '';
    }
    return (
      `Level: ${this.documentGenerationConfig.itemLevel === 'option' ? 'Option' : 'Family'}\n` +
      `Layout: ${this.documentGenerationConfig.gridLayoutDimensions.label} ${this.documentGenerationConfig.gridLayoutOrientation}\n` +
      `Primary Groupings: ${this.groupingProperties}\n` +
      `Secondary Grouping: ${this.secondaryGrouping}\n` +
      `Updated: ${this.documentGenerationConfig.updatedOn}\n` +
      `Updated By: ${this.documentGenerationConfig.updatedBy.email}`
    );
  }

  private async setUpDocumentGenerationConfig(documentGenerationConfig) {
    this.documentGenerationConfig = documentGenerationConfig;
    if (this.documentGenerationConfig) {
      const properties = await GenerateDocumentUtil.generateProperties();
      this.documentGenerationConfig.groupingProperties.forEach((property) => {
        const selectedProp = properties.find((prop) => prop.propertyDefinition.slug === property.slug);
        this.groupingProperties =
          this.groupingProperties +
          (this.groupingProperties !== '' ? ', ' : '') +
          selectedProp.propertyDefinition.label;
      });
      if (this.documentGenerationConfig.secondaryGroupingProperties.length > 0) {
        const selectedProp = properties.find(
          (prop) => prop.propertyDefinition.slug === this.documentGenerationConfig.secondaryGroupingProperties[0].slug,
        );
        this.secondaryGrouping = selectedProp.propertyDefinition.label;
      }
    }
  }
}
