import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-folder-breadcrumb-nav',
  template: `
    <div class="flex flex-wrap p-2">
      <div class="flex" *ngIf="!currentFolder?.parent">Documents</div>
      <ng-container *ngFor="let tempWS of tempArr; index as idx">
        <div class="flex" *ngIf="tempWS?.parent" (click)="goToFolder(tempWS.parent)">
          <span class="text-gray-700 cursor-pointer hover:underline hover:text-primary hover:font-medium">
            {{ tempWS.parent.workspaceType === 'PROJECT' ? 'Documents' : tempWS.parent.name }}
          </span>
          <span class="mx-2">/</span>
        </div>

        <div class="text-gray-800 font-medium" *ngIf="idx + 1 === tempLength">
          {{ tempWS.name }}
        </div>
      </ng-container>
    </div>
  `,
  styles: [],
})
export class FolderBreadcrumbNavComponent implements OnInit {
  @Input() rootFolder: Workspace;
  currentFolder: Workspace;
  tempArr = [];
  tempLength = 0;

  constructor(
    public store: Store<RootStoreState.State>,
    private folderEntityService: WorkspaceEntitiesHelperService,
  ) {}

  ngOnInit(): void {
    this.store.select(WorkspacesSelectors.currentWorkspaceFolder).subscribe((ws) => {
      this.currentFolder = ws;
      this.setTempArr();
    });
  }

  goToFolder(folder) {
    this.folderEntityService.goToFolder(folder);
  }

  setTempArr() {
    let tempWS = this.currentFolder;
    let length = 0;
    this.tempArr = [];
    const arr = [...Array(20).keys()];
    for (let idx = 0; idx < arr.length; idx++) {
      if (tempWS?.parent) {
        this.tempArr.push(tempWS);
        tempWS = tempWS.parent;
      } else {
        length = idx;
        break;
      }
    }
    this.tempLength = length;
    this.tempArr.reverse();
  }
}
