import { CommonModule } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PipesModule } from '@pipes/pipes.module';

@Component({
  standalone: true,
  imports: [CommonModule, PipesModule],
  selector: 'app-source-change-history-image',
  template: `
    <img
      *ngIf="value"
      [attr.src]="value | secureImage | async"
      class="thumbnail"
      [class.border-warn-light]="new"
      (mouseover)="handleHover($event)"
    />
    <div class="imageView" *ngIf="value && showHover" #imageView>
      <img [attr.src]="value | secureImage | async" />
    </div>
  `,
  styles: [
    `
      .thumbnail {
        @apply w-12 h-12 object-contain border cursor-pointer;
      }
      .imageView {
        @apply hidden absolute left-10 top-10 rounded bg-white p-2;
        z-index: 60;
        box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 70%);
        max-width: 240px;
        max-height: 240px;
        min-width: 50px;
        min-height: 50px;
        img {
          max-width: inherit;
          max-height: inherit;
          min-width: inherit;
          min-height: inherit;
        }
      }
      :host {
        display: flex;
        position: relative;
      }
      :host:hover .imageView {
        display: block;
      }
    `,
  ],
})
export class SourceChangeHistoryEventImageComponent implements OnInit {
  @Input() value;
  @Input() new = false;

  @ViewChild('imageView', { static: false }) imageView: ElementRef;
  public showHover = false;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  handleHover(event) {
    this.showHover = true;
    const maxDim = window.innerHeight * 0.25 > 200 ? 200 : window.innerHeight * 0.25;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - event.pageY)) / window.innerHeight;

    if (!this.imageView) {
      return;
    }
    this.renderer.setStyle(this.imageView.nativeElement, 'max-width', `${maxDim}px`);
    this.renderer.setStyle(this.imageView.nativeElement, 'max-height', `${maxDim}px`);

    if (distanceFromBottom < 10) {
      const top = 100 + Math.abs(distanceFromBottom);
      this.renderer.setStyle(this.imageView.nativeElement, 'top', `-${top}px`);
    } else {
      this.renderer.setStyle(this.imageView.nativeElement, 'top', `10px`);
    }
  }
}
