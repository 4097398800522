import { DocumentElement, DocumentElementFactory, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { nanoid } from 'nanoid';
import { OrderUtil } from '../../../../util/OrderUtil';
import { ROW_MIN_HEIGHT, TABLE_CELL_HEIGHT, TABLE_CELL_WIDTH, TRANSPARENT_COLOR } from '../../../constants';

export class TableService {
  constructor() {}

  public static createEmptyTableElement(position: PositionDefinition, rows = 4, columns = 3): Array<DocumentElement> {
    const rowElements: Array<DocumentElement> = [];
    const rowIds: Array<string> = [];
    const columnElements: Array<DocumentElement> = [];
    const columnIds: Array<string> = [];
    const cellElements: Array<DocumentElement> = [];
    const tableId = nanoid(16);

    for (let i = 0; i < rows; i++) {
      const rowId = nanoid(16);
      const row = {
        id: rowId,
        tableId,
        type: 'row',
        size: {
          width: 1,
          height: TABLE_CELL_HEIGHT,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      rowIds.push(rowId);
      rowElements.push(row);
    }

    for (let j = 0; j < columns; j++) {
      const columnId = nanoid(16);
      const column = {
        id: columnId,
        tableId,
        type: 'column',
        size: {
          width: TABLE_CELL_WIDTH,
          height: 1,
        },
        style: {
          backgroundColor: TRANSPARENT_COLOR,
        },
        position: { x: 0, y: 0 },
      };
      columnIds.push(columnId);
      columnElements.push(column);
    }

    for (let i = 0; i < rowIds.length; i++) {
      for (let j = 0; j < columnIds.length; j++) {
        const cell: DocumentElement = {
          id: nanoid(16),
          type: 'cell',
          rowId: rowIds[i],
          columnId: columnIds[j],
          tableId,
          text: '',
          size: {
            width: TABLE_CELL_WIDTH,
            height: ROW_MIN_HEIGHT,
          },
          style: {
            backgroundColor: TRANSPARENT_COLOR,
          },
          position: { x: 0, y: 0 },
        };
        cellElements.push(cell);
      }
    }

    const tableElement: DocumentElement = {
      type: 'table',
      id: tableId,
      position: {
        x: position.x - TABLE_CELL_WIDTH * 0.5,
        y: position.y - TABLE_CELL_HEIGHT * 0.5,
      },
      size: {
        width: columns * TABLE_CELL_WIDTH,
        height: rows * TABLE_CELL_HEIGHT,
      },
      style: {
        backgroundColor: TRANSPARENT_COLOR,
        border: {
          width: 1,
          color: '#616161',
        },
      },
      rowIds,
      columnIds,
    };

    return [tableElement, ...rowElements, ...columnElements, ...cellElements];
  }

  public static addColumn(
    tableElement: DocumentElement,
    index,
    width: number,
    prevColumnCells: DocumentElement[],
  ): DocumentElement[] {
    const columnId = nanoid(16);
    const column = {
      id: columnId,
      tableId: tableElement.id,
      type: 'column',
      size: {
        width,
        height: 1,
      },
      position: { x: 0, y: 0 },
    };
    tableElement.size.width = tableElement.size.width + width;
    tableElement.columnIds.splice(index, 0, columnId);

    const cellElements = [];
    for (let i = 0; i < tableElement.rowIds.length; i++) {
      const prevCell = prevColumnCells[i];
      const cell: DocumentElement = {
        id: nanoid(16),
        type: 'cell',
        rowId: tableElement.rowIds[i],
        columnId,
        tableId: tableElement.id,
        text: '',
        size: {
          width: width,
          height: ROW_MIN_HEIGHT,
        },
        position: { x: 0, y: 0 },
        style: prevCell.style,
      };
      cellElements.push(cell);
    }
    return [column, ...cellElements];
  }

  public static addRow(
    tableElement: DocumentElement,
    index,
    height: number,
    prevRowCells: DocumentElement[],
  ): DocumentElement[] {
    const rowId = nanoid(16);
    const row = {
      id: rowId,
      tableId: tableElement.id,
      type: 'row',
      size: {
        width: 1,
        height,
      },
      position: { x: 0, y: 0 },
    };
    tableElement.size.height = tableElement.size.height + height;
    tableElement.rowIds.splice(index, 0, rowId);

    const cellElements = [];
    for (let i = 0; i < tableElement.columnIds.length; i++) {
      const prevCell = prevRowCells[i];
      const cell: DocumentElement = {
        id: nanoid(16),
        type: 'cell',
        rowId,
        columnId: tableElement.columnIds[i],
        tableId: tableElement.id,
        text: '',
        size: {
          width: prevCell.size.width,
          height: ROW_MIN_HEIGHT,
        },
        position: { x: 0, y: 0 },
        style: prevCell.style,
      };
      cellElements.push(cell);
    }
    return [row, ...cellElements];
  }

  public static move(
    key: 'columnIds' | 'rowIds',
    tableElement: DocumentElement,
    from: number,
    to: number,
  ): DocumentElement {
    OrderUtil.move(tableElement[key], from, to);
    return tableElement;
  }

  public static copy(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
  ): { newTableElement: DocumentElement; newChildElements: DocumentElement[] } {
    const copiedChildElementIds: Map<string, string> = new Map();
    const childElementsMap: Map<string, DocumentElement> = new Map();
    childElements.forEach((element) => {
      childElementsMap.set(element.id, element);
    });

    const rowIds: Array<string> = [];
    const columnIds: Array<string> = [];

    const oldTableElement = ObjectUtil.cloneDeep(tableElement);
    delete oldTableElement.id;
    const newTableElement = DocumentElementFactory.createElement('table', oldTableElement);
    newTableElement.tableId = newTableElement.id;
    const newChildElements = [];

    for (let i = 0; i < tableElement.rowIds.length; i++) {
      const oldId = tableElement.rowIds[i];
      const oldElement = childElementsMap.get(oldId);
      if (!oldElement) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldElement);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      const newElement = DocumentElementFactory.createElement('row', oldElementCopy);
      copiedChildElementIds.set(oldId, newElement.id);
      rowIds.push(newElement.id);
      newChildElements.push(newElement);
    }

    for (let i = 0; i < tableElement.columnIds.length; i++) {
      const oldId = tableElement.columnIds[i];
      const oldElement = childElementsMap.get(oldId);
      if (!oldElement) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldElement);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      const newElement = DocumentElementFactory.createElement('column', oldElementCopy);
      copiedChildElementIds.set(oldId, newElement.id);
      columnIds.push(newElement.id);
      newChildElements.push(newElement);
    }

    const cellElements = childElements.filter((e) => e.type === 'cell');
    for (let i = 0; i < cellElements.length; i++) {
      const oldCell = cellElements[i];
      const newRowId = copiedChildElementIds.get(oldCell.rowId);
      const newColumnId = copiedChildElementIds.get(oldCell.columnId);
      if (!newRowId || !newColumnId) throw new Error('Invalid table element');
      const oldElementCopy = ObjectUtil.cloneDeep(oldCell);
      delete oldElementCopy.id;
      delete oldElementCopy.updatedOn;
      delete oldElementCopy.updatedById;
      delete oldElementCopy.createdOn;
      delete oldElementCopy.createdById;
      oldElementCopy.tableId = newTableElement.id;
      oldElementCopy.rowId = newRowId;
      oldElementCopy.columnId = newColumnId;
      const newElement = DocumentElementFactory.createElement('cell', oldElementCopy);
      newChildElements.push(newElement);
    }

    newTableElement.rowIds = rowIds;
    newTableElement.columnIds = columnIds;

    return { newTableElement, newChildElements };
  }
}
