import { CanvasDocument } from '../../canvas-document';
import { SelectDragHandler } from './drag-event-handlers/select-drag-handler';
import { ElementDragHandler } from './drag-event-handlers/element-drag-handler';
import { ElementSelectHandler } from './click-event-handlers/element-select-handler';
import { CursorHandler } from './mouse-event-handlers/cursor-handler';
import { ElementResizeHandler } from './drag-event-handlers/element-resize-handler';
import { ElementDrawHandler } from './drag-event-handlers/element-draw-handler';
import { ElementRotateHandler } from './drag-event-handlers/element-rotate-handler';
import { TextElementEventHandler } from './mouse-event-handlers/text-element-event-handler';
import { ElementClickHandler } from './click-event-handlers/element-click-handler';
import { FrameElementEventHandler } from './dblclick-event-handlers/frame-element-event-handler';
import { ElementContextMenuHandler } from './click-event-handlers/element-context-menu-handler';
import { MultipleElementsResizeHandler } from './drag-event-handlers/multiple-elements-resize-handler';
import { IframeElementEventHandler } from './mouse-event-handlers/iframe-event-handler';
import { PresentationInteractionHandler } from './mouse-event-handlers/presentation-interaction-handler';
import { DocumentElementEvent } from '@contrail/documents';
import { FileDragHandler } from './drag-event-handlers/file-drag-handler';
import { Subject } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { GroupElementEventHandler } from './dblclick-event-handlers/group-element-event-handler';
import { MaskElementEventHandler } from './dblclick-event-handlers/mask-element-event-handler';
import { SvgElementEventHandler } from './mouse-event-handlers/svg-event-handler';
import { CropElementDragHandler } from './crop-event-handlers/crop-element-drag-handler';
import { CropBoxResizeHandler } from './crop-event-handlers/crop-box-resize-handler';
import { CropElementClickHandler } from './crop-event-handlers/crop-element-click-handler';
import { CropElementResizeHandler } from './crop-event-handlers/crop-element-resize-handler';
import { PenDrawHandler } from './drag-event-handlers/pen-draw-handler';
import { EraserHandler } from './drag-event-handlers/eraser-handler';
import { ZoomDragHandler } from './drag-event-handlers/zoom-drag-handler';
import { ItemDragHandler } from './drag-event-handlers/item-drag-handler';
import { TouchEventHandler } from './touch-event-handlers/touch-event-handler';

export interface NormalizedEvent {
  clientX: number;
  clientY: number;
  shiftKey: boolean;
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  movementX?: number;
  movementY?: number;
  button?: number;
  buttons?: number;
}

export class CanvasEventHandlers {
  private selectDragHandler: SelectDragHandler;
  public elementDragHandler: ElementDragHandler;
  private elementResizeHandler: ElementResizeHandler;
  private multipleElementsResizeHandler: MultipleElementsResizeHandler;
  private elementRotateHandler: ElementRotateHandler;
  public elementSelectHandler: ElementSelectHandler;
  private elementDrawHandler: ElementDrawHandler;
  private elementContextMenuHandler: ElementContextMenuHandler;
  private cursorHandler: CursorHandler;
  private textElementEventHandler: TextElementEventHandler;
  private elementClickHandler: ElementClickHandler;
  private frameElementEventHandler: FrameElementEventHandler;
  private iframeElementEventHandler: IframeElementEventHandler;
  private svgElementEventHandler: SvgElementEventHandler;
  private presentationInteractionHandler: PresentationInteractionHandler;
  public fileDragHandler: FileDragHandler;
  public itemDragHandler: ItemDragHandler;
  private groupElementEventHandler: GroupElementEventHandler;
  public maskElementEventHandler: MaskElementEventHandler;
  private cropElementDragHandler: CropElementDragHandler;
  private cropElementResizeHandler: CropElementResizeHandler;
  private cropBoxResizeHandler: CropBoxResizeHandler;
  private cropElementClickHandler: CropElementClickHandler;
  public penDrawHandler: PenDrawHandler;
  public eraserHandler: EraserHandler;
  private zoomDragHandler: ZoomDragHandler;

  private touchEventHandler: TouchEventHandler;

  public static mousedownHandler;
  public static mousemoveHandler;
  public static mouseupHandler;

  private mouseWasMoved = false;
  private rightClick = false;
  private leftClick = false;

  private isMouseDown = false;
  private isDragging = false;
  private isImmediateDragging = false;
  private dragStartEvent: MouseEvent = null;

  constructor(private canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT') {
      this.selectDragHandler = new SelectDragHandler(this.canvasDocument);
      this.elementDragHandler = new ElementDragHandler(this.canvasDocument);
      this.elementResizeHandler = new ElementResizeHandler(this.canvasDocument);
      this.multipleElementsResizeHandler = new MultipleElementsResizeHandler(this.canvasDocument);
      this.elementRotateHandler = new ElementRotateHandler(this.canvasDocument);
      this.elementSelectHandler = new ElementSelectHandler(this.canvasDocument);
      this.elementDrawHandler = new ElementDrawHandler(this.canvasDocument);
      this.cursorHandler = new CursorHandler(this.canvasDocument);
      this.textElementEventHandler = new TextElementEventHandler(this.canvasDocument);
      this.elementClickHandler = new ElementClickHandler(this.canvasDocument);
      this.frameElementEventHandler = new FrameElementEventHandler(this.canvasDocument);
      this.iframeElementEventHandler = new IframeElementEventHandler(this.canvasDocument);
      this.svgElementEventHandler = new SvgElementEventHandler(this.canvasDocument);
      this.fileDragHandler = new FileDragHandler(this.canvasDocument);
      this.itemDragHandler = new ItemDragHandler(this.canvasDocument);
      this.groupElementEventHandler = new GroupElementEventHandler(this.canvasDocument);
      this.maskElementEventHandler = new MaskElementEventHandler(this.canvasDocument);
      this.cropElementDragHandler = new CropElementDragHandler(this.canvasDocument);
      this.cropElementResizeHandler = new CropElementResizeHandler(this.canvasDocument);
      this.cropBoxResizeHandler = new CropBoxResizeHandler(this.canvasDocument);
      this.cropElementClickHandler = new CropElementClickHandler(this.canvasDocument);
      this.presentationInteractionHandler = new PresentationInteractionHandler(this.canvasDocument);
      this.penDrawHandler = new PenDrawHandler(this.canvasDocument);
      this.eraserHandler = new EraserHandler(this.canvasDocument);
      this.zoomDragHandler = new ZoomDragHandler(this.canvasDocument);

      CanvasEventHandlers.mousedownHandler = this.mousedown.bind(this);
      CanvasEventHandlers.mousemoveHandler = this.mousemove.bind(this);
      CanvasEventHandlers.mouseupHandler = this.mouseup.bind(this);

      this.addEventListeners();
      this.initDraggedHandler();
    }

    if (['EDIT', 'COMMENT'].indexOf(this.canvasDocument.mode) !== -1) {
      this.elementContextMenuHandler = new ElementContextMenuHandler(this.canvasDocument);
      this.canvasDocument.canvasDisplay.canvasDisplayElement.addEventListener('contextmenu', (event) => {
        event.preventDefault();
        setTimeout(() => {
          if (!this.mouseWasMoved) {
            this.handleContextMenu(event);
          } else {
            this.mouseWasMoved = false;
          }
        }, 300);
      });
    }

    if (['PRESENT'].indexOf(this.canvasDocument.mode) !== -1) {
      this.presentationInteractionHandler = new PresentationInteractionHandler(this.canvasDocument);
      this.textElementEventHandler = new TextElementEventHandler(this.canvasDocument);
      this.canvasDocument.canvasDisplay.canvasDisplayElement.addEventListener(
        'mousemove',
        this.presentModeMouseMove.bind(this),
      );
      this.iframeElementEventHandler = new IframeElementEventHandler(this.canvasDocument);
      this.canvasDocument.canvasDisplay.canvasDisplayElement.addEventListener(
        'mousedown',
        this.presentModeMouseDown.bind(this),
      );
    }

    if (this.canvasDocument.supportsTouchEvents) {
      this.touchEventHandler = new TouchEventHandler(this.canvasDocument);
    }
  }

  private presentModeMouseMove(event) {
    const elementTarget = this.canvasDocument.state.getElementTarget(event.clientX, event.clientY);
    this.presentationInteractionHandler.mousemove(event, elementTarget);
    this.textElementEventHandler.handleMouseMove(event, elementTarget);
  }

  private presentModeMouseDown(event) {
    const elementTarget = this.canvasDocument.state.getElementTarget(event.clientX, event.clientY);
    this.iframeElementEventHandler.handleClick(event, elementTarget);
  }

  private addEventListeners(): void {
    document.addEventListener('mousedown', CanvasEventHandlers.mousedownHandler);
    document.addEventListener('mousemove', CanvasEventHandlers.mousemoveHandler);
    document.addEventListener('mouseup', CanvasEventHandlers.mouseupHandler);

    this.canvasDocument.canvasDisplay.canvasDisplayElement.addEventListener('click', this.handleClick.bind(this));
    this.canvasDocument.canvasDisplay.canvasDisplayElement.addEventListener('dblclick', this.dblclick.bind(this));
  }

  public sendEvent(event: DocumentElementEvent) {
    if (event?.eventType === 'file_drag_enter') {
      this.fileDragHandler.dragstarted(event.sourceMouseEvent);
    } else if (event?.eventType === 'file_drag_over') {
      this.fileDragHandler.dragged(event.sourceMouseEvent);
      this.canvasDocument.draw();
    } else if (event?.eventType === 'file_drag_leave') {
      this.fileDragHandler.dragended(event.sourceMouseEvent);
      this.canvasDocument.draw();
    } else if (event?.eventType === 'item_drag_enter') {
      this.itemDragHandler.dragstarted(event.sourceMouseEvent);
    } else if (event?.eventType === 'item_drag_over') {
      this.itemDragHandler.dragged(event.sourceMouseEvent);
      this.canvasDocument.draw();
    } else if (event?.eventType === 'item_drag_leave') {
      this.itemDragHandler.dragended(event.sourceMouseEvent);
      this.canvasDocument.draw();
    }
  }

  private handleClick(event: MouseEvent): void {
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: null,
      sourceMouseEvent: event,
      eventType: 'handleClick',
    });
    const elementTarget = this.canvasDocument.state.elementTarget;
    this.elementClickHandler.handleClick(event, elementTarget);
  }

  public handleContextMenu(event: MouseEvent): void {
    event.stopPropagation();
    const elementTarget = this.canvasDocument.state.getElementTarget(event.clientX, event.clientY);
    this.elementContextMenuHandler.handleContextmenu(event, elementTarget);
  }

  private dblclick(event: MouseEvent) {
    const elementTarget = this.canvasDocument.state.elementTarget;
    this.frameElementEventHandler.handleDblClick(event, elementTarget);
    this.groupElementEventHandler.handleDblClick(event, elementTarget);
    this.maskElementEventHandler.handleDblClick(event, elementTarget);
    this.svgElementEventHandler.handleDblClick(event, elementTarget);
    this.cropElementClickHandler.handleDblClick(event, elementTarget);
    if (elementTarget?.element) {
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: elementTarget.element.elementDefinition,
        eventType: 'dblclick',
      });
    }
  }

  public normalizeEvent(event: MouseEvent | TouchEvent, previousEvent?: MouseEvent | TouchEvent): NormalizedEvent[] {
    if (this.canvasDocument.supportsTouchEvents && event instanceof TouchEvent && event?.changedTouches?.length > 0) {
      const events = [];
      const isPreviousEvent =
        previousEvent &&
        previousEvent instanceof TouchEvent &&
        previousEvent?.changedTouches?.length === event.changedTouches.length;
      for (let i = 0; i < event.changedTouches.length; i++) {
        const touch = event.changedTouches[i];
        const e: NormalizedEvent = {
          clientX: touch.clientX,
          clientY: touch.clientY,
          shiftKey: event.shiftKey,
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          movementX: 0,
          movementY: 0,
        };
        // @ts-ignore
        if (typeof touch.button === 'undefined') e.button = 0;
        // @ts-ignore
        if (typeof touch.buttons === 'undefined') e.buttons = 1;
        if (isPreviousEvent) {
          e.movementX = touch.pageX - previousEvent.changedTouches[i].pageX;
          e.movementY = touch.pageY - previousEvent.changedTouches[i].pageY;
        }
        events.push(e);
      }
      return events;
    } else if (event instanceof MouseEvent) {
      return [
        {
          clientX: event.clientX,
          clientY: event.clientY,
          shiftKey: event.shiftKey,
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          movementX: event.movementX,
          movementY: event.movementY,
          button: event.button,
          buttons: event.buttons,
        },
      ];
    }
  }

  public mousedown(event: MouseEvent): void {
    if (event.target !== this.canvasDocument.canvasDisplay.canvasDisplayElement) {
      return;
    }

    this.rightClick = this.isRightClick(event);
    this.leftClick = this.isLeftClick(event);
    // this.isMouseDown = true;
    this.isDragging = false;
    this.isImmediateDragging = false;

    this.dragStartEvent = event;
    const elementTarget = this.canvasDocument.state.getElementTarget(event.clientX, event.clientY);
    if (this.canvasDocument.getSelectedElements().length === 1) {
      this.textElementEventHandler.handleClick(event, elementTarget);
      this.iframeElementEventHandler.handleClick(event, elementTarget);
    }
    this.cropElementClickHandler.handleClick(event, elementTarget);
    this.elementSelectHandler.handleClick(event, elementTarget);
  }

  private dragstartedAfterDragged(event: MouseEvent): void {
    this.canvasDocument?.editorHandler?.hideEditor();
    // const element = this.canvasDocument.getElementInPosition(x, y);
    const elementTarget = this.canvasDocument.state.elementTarget;
    if (elementTarget?.element && !elementTarget.element.isVisible) {
      return;
    }
    if (elementTarget && !elementTarget?.element?.isSelected) {
      this.elementSelectHandler.handleClick(event, elementTarget); // this is to make sure element is selected when being dragged (for example, to work around shiftKey)
    }

    this.elementDragHandler.dragstarted(event, elementTarget);
    this.elementResizeHandler.dragstarted(event, elementTarget);
    this.elementRotateHandler.dragstarted(event, elementTarget);
    this.elementDrawHandler.dragstarted(event, elementTarget);
    this.selectDragHandler.dragstarted(event, elementTarget);
    this.multipleElementsResizeHandler.dragstarted(event, elementTarget);
    this.cropElementDragHandler.dragstarted(event, elementTarget);
    this.cropBoxResizeHandler.dragstarted(event, elementTarget);
    this.cropElementResizeHandler.dragstarted(event, elementTarget);
    this.penDrawHandler.dragstarted(event, elementTarget);
    this.zoomDragHandler.dragstarted(event, elementTarget);
  }
  private dragEventObservable: Subject<any> = new Subject();

  private dragged(event: MouseEvent) {
    if (this.isRightClick(event)) {
      return;
    }
    if (!this.isImmediateDragging) {
      this.isImmediateDragging = true;
      this.eraserHandler.dragstarted(event);
    } else {
      this.eraserHandler.dragged(event);
    }
    this.dragEventObservable.next(event);
  }

  /** Handles the actual drag events by subscribing the the local dragEvent observable
   * and then throttling the events to create a more performant experience.
   */
  private initDraggedHandler() {
    this.dragEventObservable
      .pipe(
        throttleTime(25),
        tap((event) => {
          if (!this.isDragging && this.dragStartEvent) {
            this.isDragging = true;
            this.dragstartedAfterDragged(this.dragStartEvent);
            return;
          }

          this.isDragging = true;

          this.elementDragHandler.dragged(event);
          this.elementResizeHandler.dragged(event);
          this.elementRotateHandler.dragged(event);
          this.elementDrawHandler.dragged(event);
          this.selectDragHandler.dragged(event);
          this.multipleElementsResizeHandler.dragged(event);
          this.cropElementDragHandler.dragged(event);
          this.cropBoxResizeHandler.dragged(event);
          this.cropElementResizeHandler.dragged(event);
          this.penDrawHandler.dragged(event);
          this.zoomDragHandler.dragged(event);
        }),
      )
      .subscribe();
  }

  private mousemove(event: MouseEvent): void {
    if (this.leftClick && this.wasLeftClick(event)) {
      this.dragged(event);
    } else {
      if (this.rightClick) {
        this.mouseWasMoved = true;
      }

      if (event.target !== this.canvasDocument.canvasDisplay.canvasDisplayElement) {
        return;
      }
      const elementTarget = this.canvasDocument.state.getElementTarget(event.clientX, event.clientY);
      this.cursorHandler.mousemove(event, elementTarget);
      this.textElementEventHandler.handleMouseMove(event, elementTarget);
      if (this.canvasDocument.getInteractionMode() === 'item_inspector') {
        this.presentationInteractionHandler.mousemove(event, elementTarget);
      }
    }
  }

  private dragended(event: MouseEvent) {
    this.elementDragHandler.dragended(event);
    this.elementResizeHandler.dragended(event);
    this.elementRotateHandler.dragended(event);
    this.elementDrawHandler.dragended(event);
    this.selectDragHandler.dragended(event);
    this.multipleElementsResizeHandler.dragended(event);
    this.cropElementDragHandler.dragended(event);
    this.cropBoxResizeHandler.dragended(event);
    this.cropElementResizeHandler.dragended(event);
    this.penDrawHandler.dragended(event);
    this.eraserHandler.dragended(event);
    this.zoomDragHandler.dragended(event);
  }

  private mouseup(event: MouseEvent): void {
    if (this.isDragging) {
      this.dragended(event);
    }
    this.rightClick = false;
    this.leftClick = false;
    this.isMouseDown = false;
    this.isDragging = false;
    this.isImmediateDragging = false;
    this.dragStartEvent = null;
  }

  private isRightClick(event): boolean {
    return event instanceof MouseEvent && event.button === 2;
  }

  private isLeftClick(event): boolean {
    return event instanceof MouseEvent && event.button === 0;
  }

  private wasLeftClick(event): boolean {
    return event instanceof MouseEvent && event.buttons === 1;
  }

  public isResizing() {
    return this.elementResizeHandler?.isResizing() || this.multipleElementsResizeHandler?.isResizing();
  }
}
