import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FormControl } from '@angular/forms';
import { GenerateDocumentUtil } from '../generate-document-util';
import { firstValueFrom } from 'rxjs';
import { BoardService } from '../../board.service';
import { DocumentGeneratorSourceSelectorComponent } from '../document-generator-source-selector/document-generator-source-selector.component';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { ClipboardItemsService } from '@common/clipboard/clipboard-items.service';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-document-generator-source-modal',
  templateUrl: './document-generator-source-modal.component.html',
  styleUrls: ['./document-generator-source-modal.component.scss'],
})
export class DocumentGeneratorSourceModalComponent implements OnInit, OnDestroy {
  @ViewChild('sourceSelector') sourceSelector: DocumentGeneratorSourceSelectorComponent;
  public assortmentId;
  public itemLevel = 'option';
  public itemCount = 0;
  public frameCount = 0;
  public assortment: any;
  private assortmentItems: any = null;
  public loadingAssortmentItems = false;
  public currentWorkspaceId: string = null;
  valid1 = new FormControl('');

  // Feature Flags
  public itemChooserLevelSelectionActive = false;

  public loading = false;
  private userId: string;

  constructor(
    private boardService: BoardService,
    public dialogRef: MatDialogRef<DocumentGeneratorSourceModalComponent>,
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    public clipboardItemService: ClipboardItemsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {
    this.loading = true;
    const [featureFlags, currentBoard, user] = await Promise.all([
      firstValueFrom(this.store.select(FeatureFlagsSelectors.featureFlags)),
      firstValueFrom(this.boardService.currentBoard),
      this.authService.getCurrentUser(),
    ]);

    const flagNamesSet = new Set(featureFlags.map((f) => f.featureName));
    this.itemChooserLevelSelectionActive = flagNamesSet.has(Feature.ITEM_CHOOSER_LEVEL_SELECTION);
    this.currentWorkspaceId = currentBoard.workspaceId;
    this.userId = user.id;

    this.loading = false;
    this.setState(this.valid1, true);
  }

  ngOnDestroy(): void {}

  async handleSelectSource(evt) {
    // this.sourceSelector.chooserSourceOption$.next(evt);
    // this.sourceSelector.showSourceSelector = false;
    let assortmentId;
    if (evt?.sourceType === 'CLIPBOARD') {
      assortmentId = 'clipboard:' + this.userId;
    } else {
      assortmentId = evt?.id;
    }
    this.setAssortment(assortmentId);
  }

  public async setAssortment(assortmentId) {
    if (!assortmentId) {
      this.assortmentId = null;
      this.assortmentItems = [];
      this.frameCount = 0;
      this.itemCount = 0;
    } else {
      this.assortmentId = assortmentId;
      this.loadingAssortmentItems = true;
      if (assortmentId.includes('clipboard')) {
        const allClipboardItems = await this.clipboardItemService.getClipboardItems();
        let optionItemData = [];
        let familyItemData = [];
        allClipboardItems.forEach((c) => {
          if (c?.item?.roles.includes('option')) {
            optionItemData.push(c);
          } else if (c?.item?.roles.includes('family')) {
            familyItemData.push(c);
          }
        });
        this.assortment = {
          id: this.assortmentId, // clipboard
          optionItemData,
          familyItemData,
        };
      } else {
        this.assortment = await GenerateDocumentUtil.getAssortment(
          this.assortmentId,
          this.itemChooserLevelSelectionActive,
        );
      }
      if (
        this.itemChooserLevelSelectionActive &&
        this.assortment.optionItemData.length === 0 &&
        this.assortment.familyItemData.length > 0
      ) {
        // if no option-level item is found, use the family-level
        this.assortmentItems = this.assortment.familyItemData;
        this.itemLevel = 'family';
      } else {
        this.assortmentItems = this.assortment.optionItemData;
      }

      this.frameCount = 0;
      this.itemCount = this.assortmentItems.length;
      const invalid = this.itemCount < 1 ? true : false;
      this.setState(this.valid1, invalid);
      this.loadingAssortmentItems = false;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  setState(control: FormControl, invalid: boolean) {
    if (invalid) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }

  done() {
    this.dialogRef.close(this.assortmentId);
  }

  isSourceSelectorNext() {
    if (this.sourceSelector?.isNext) {
      return true;
    } else {
      return false;
    }
  }

  isValid() {
    if (this.loadingAssortmentItems || !this.assortmentId || !this.assortmentItems || this.valid1.invalid) {
      return false;
    }
    return true;
  }
}
