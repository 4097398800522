import { Output } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { SharedLinkService } from './share-app.service';
import { WorkspacePrincipalService } from './workspace-principal.service';
import { LegacyLinksComponent } from './components/legacy-links/legacy-links.component';
import { GroupsService } from './group.service';
import { UserOrgsService } from './user-orgs.service';
import { SharedLinkCollaboratorService } from './shared-link-collaborator.service';
import { UserNamePipe } from '@pipes/user-name.pipe';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { BoardsSelectors } from 'src/app/boards/boards-store';
import { combineLatest, from, map, Observable } from 'rxjs';
import { ColorUtil } from '@util/color-util';
import { WorkspacePrincipalsComponent } from './components/workspace-principals/workspace-principals.component';
import { ACCESS_LEVEL, SharedLinkDefinition, SharedLinkPrincipal, ShareLink, WorkspacePrincipal } from './interfaces';
import { Group, User, UserOrg, UserOrgRole } from '@contrail/entity-types';
import { sharedLink } from '../auth/auth-store/auth.selectors';
import { WorkspacesService } from '@common/workspaces/workspaces.service';

@Component({
  selector: 'app-share-app',
  templateUrl: './share-app.component.html',
  styleUrls: ['./share-app.component.scss'],
})
export class ShareAppComponent implements OnInit {
  @Input() sharedLinkDefinition: SharedLinkDefinition;
  @Output() close = new EventEmitter();

  public groups: Group[] = [];
  public users: User[] = [];
  public principals = [];
  public suggestions = [];
  public activeLink: ShareLink;
  public links;
  public workspacePrincipals: WorkspacePrincipal[] = [];
  public updatingActiveLink = false;
  public loadingLinks = false;
  public colloboratorAccessLevel = ACCESS_LEVEL.VIEW;
  public collaborators = [];
  public addingCollabs: boolean = false;
  public visitedCollaborators = {};

  public loadingCollabs: boolean = false;
  public userOrgs: UserOrg[] = [];

  public board: any;

  public accessLevels: string[] = Object.values(ACCESS_LEVEL);

  public accessLevelIcon: string;
  public accessLevelText: string;
  public isDocumentSharedPublically: boolean = false;

  constructor(
    private dialog: MatDialog,
    private shareLinkService: SharedLinkService,
    private snackBar: MatSnackBar,
    private workspacePrincipalService: WorkspacePrincipalService,
    private workspaceService: WorkspacesService,
    private groupService: GroupsService,
    private userOrgService: UserOrgsService,
    private sharedLinkCollaboratorService: SharedLinkCollaboratorService,
    private userNamePipe: UserNamePipe,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {}

  async ngOnInit() {
    this.store
      .select(BoardsSelectors.currentBoard)
      .pipe(
        map((board) => {
          this.board = board;
          return board;
        }),
      )
      .subscribe();

    combineLatest([this.groupService.getGroups(), this.userOrgService.getUserOrgs()])
      .pipe(
        map(([groups, userOrgs]) => {
          this.userOrgs = userOrgs;
          this.groups = groups;
          if (this.sharedLinkDefinition) {
            this.loadLinks();
          }
          this.loadCollaboratorSuggestions();
        }),
      )
      .subscribe();
  }

  async loadCollaborators(sharedLinkId) {
    this.loadingCollabs = true;
    const collaboratorPromise = this.sharedLinkCollaboratorService.loadCollaborators(sharedLinkId);
    const policyOwingWorkspace = await this.workspaceService.getPolicyOwnerForWorkspace(this.board?.workspaceId);
    const workspacePrincipalPromise = this.workspacePrincipalService.getWorkspacePrincipals(policyOwingWorkspace?.id);

    const [collaborators, workspacePrincipals] = await Promise.all([collaboratorPromise, workspacePrincipalPromise]);
    if (collaborators.length) {
      this.collaborators = this.buildCollaboratorsForDisplay(collaborators);
      this.setCollaboratorUsersAsVisited();
    }

    const adminUsers = this.userOrgs.filter((userOrg) => userOrg.role === 'ADMIN' && !userOrg?.user?.isAppUser);
    const orgAdminPrincipals = adminUsers.map((orgAdmin) => {
      const principal: any = {
        role: UserOrgRole.admin,
        id: orgAdmin.user.id, // different from workspacePrincipals, but disable row
        type: 'User',
        firstName: orgAdmin?.user?.firstName,
        lastName: orgAdmin?.user?.lastName,
        email: orgAdmin?.userEmail,
      };
      return { ...principal, principal };
    });

    const validWorkspacePrincipals = workspacePrincipals.filter((wp) => wp?.principal);
    const validOrgAdminPrincipals = orgAdminPrincipals.filter((wp) => wp?.principal);
    this.workspacePrincipals = [...validWorkspacePrincipals, ...validOrgAdminPrincipals].map((inputPrincipal) => {
      inputPrincipal.principal.backgroundColor = this.getBackgroundColor(inputPrincipal?.principal?.id);
      return inputPrincipal;
    });
    this.loadingCollabs = false;
  }

  buildCollaboratorsForDisplay(collaborators) {
    const validCollaborators = collaborators.filter((c) => c?.principal);
    return validCollaborators.map((c) => {
      if (c?.principalReference.includes('group')) {
        c.principal.firstName = c.principal.name;
        c.principal.lastName = '';
      }
      c.principal.displayName = this.userNamePipe.transform(c.principal, 'fullEmail');
      return c;
    });
  }

  setCollaboratorUsersAsVisited() {
    for (let i = 0; i < this.collaborators.length; i++) {
      this.visitedCollaborators[this.collaborators[i].principal.id] = true;
      if (this.collaborators[i]?.principal?.email) {
        this.visitedCollaborators[this.collaborators[i].principal.email] = true;
      }

      if (this.collaborators[i]?.principal?.name) {
        this.visitedCollaborators[this.collaborators[i].principal.name] = true;
      }
    }
  }

  async loadCollaboratorSuggestions() {
    this.users = this.userOrgs.map((userOrg) => userOrg.user);

    const suggestions = [];
    this.groups.forEach((group) => {
      suggestions.push({
        id: group.id,
        name: group.name,
        type: 'group',
        reference: `group:${group.id}`,
      });
    });

    this.users.forEach((userOrg) => {
      suggestions.push({
        id: userOrg.id,
        name: userOrg.email,
        type: 'user',
        reference: `user:${userOrg.id}`,
      });
    });

    this.suggestions = suggestions;
  }

  async addCollaborator(principal: any) {
    if (!principal) {
      return;
    }

    if (principal?.value) {
      if (principal.value?.id) {
        principal = principal.value;
      } else if (!principal.value.id && principal.value?.name) {
        principal = principal.value.name;
      }
    }

    let prinicipalRef = null;
    let username = null;
    if (typeof principal === 'string') {
      const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      if (!emailRegex.test(principal)) {
        this.snackBar.open('Invalid email', '', { duration: 2000 });
        return;
      }

      username = principal;
    } else {
      prinicipalRef = principal?.reference;
    }

    if (
      (principal?.email && this.visitedCollaborators[principal.email]) ||
      (principal?.name && this.visitedCollaborators[principal.name]) ||
      (principal?.id && this.visitedCollaborators[principal.id]) ||
      (username && this.visitedCollaborators[username])
    ) {
      this.snackBar.open('Collaborator already exists', '', { duration: 2000 });
      return;
    }

    this.addingCollabs = true;

    const sharedLinkprincipal: SharedLinkPrincipal = {
      sharedLinkId: this.activeLink.id,
      accessLevel: this.colloboratorAccessLevel,
      principalReference: prinicipalRef,
      userEmail: username,
    };
    let collaborator = await this.sharedLinkCollaboratorService.addCollaborator(sharedLinkprincipal);

    if (prinicipalRef) {
      collaborator.principal = await this.sharedLinkCollaboratorService.getPrincipalDetails(prinicipalRef);
      if (prinicipalRef.includes('group')) {
        collaborator.principal.firstName = collaborator.principal.name;
        collaborator.principal.lastName = '';
      }

      this.visitedCollaborators[collaborator.principal.id] = true;
    } else if (username) {
      collaborator.principal = {
        email: username,
        id: username,
      };
      this.visitedCollaborators[username] = true;
    } else {
      this.addingCollabs = false;
      console.error('Either principal reference or userEmail is neccessary');
      return;
    }

    collaborator.principal.displayName = this.userNamePipe.transform(collaborator.principal, 'fullEmail');

    this.collaborators.push(collaborator);
    this.addingCollabs = false;

    this.snackBar.open('Added collaborator', '', { duration: 2000 });
  }

  async loadLinks() {
    this.loadingLinks = true;
    this.loadingCollabs = true;
    this.links = await this.shareLinkService.getSharedLinks(this.sharedLinkDefinition);

    let activeLink = this.links.find((link) => !link.migration_prop_isLegacyShareLink) || null;

    if (activeLink) {
      this.links.forEach((link) => {
        if (!link.accessLevel || link.accessLevel === 'null') {
          link.accessLevel = ACCESS_LEVEL.RESTRICTED;
        }
      });
    } else {
      activeLink = await this.shareLinkService.createSharedLink(this.sharedLinkDefinition);
      this.links.push(activeLink);
    }

    activeLink.shareUrl = this.getShareUrl(activeLink);

    this.activeLink = activeLink;
    this.loadingLinks = false;
    this.loadingCollabs = false;
    this.accessLevelIcon = this.getAccessIcon(this.activeLink.accessLevel);
    this.accessLevelText = this.getAccessLevelText(this.activeLink.accessLevel);
    this.isDocumentSharedPublically = this.activeLink.accessLevel !== ACCESS_LEVEL.RESTRICTED;
    this.loadCollaborators(this.activeLink.id);
  }

  getShareUrl(link) {
    if (!link) {
      return '';
    }
    return `${this.sharedLinkDefinition.baseUrl}?st=${link.id}`;
  }

  showClipboardMessage() {
    this.snackBar.open('Link copied to clipboard', '', { duration: 2000 });
  }

  async handleAccessLevelChange($event, link) {
    const accessLevel = $event.value;
    this.updatingActiveLink = true;
    const updatedLink = await this.shareLinkService.updateShareLinkAccessLevel(link, accessLevel);

    if (this.activeLink.id === updatedLink.id) {
      this.activeLink = updatedLink;
    }

    this.activeLink.shareUrl = this.getShareUrl(this.activeLink);
    this.updatingActiveLink = false;

    this.accessLevelIcon = this.getAccessIcon(accessLevel);
    this.accessLevelText = this.getAccessLevelText(accessLevel);
    this.isDocumentSharedPublically = accessLevel !== ACCESS_LEVEL.RESTRICTED;
  }

  handleColloboratorAccessLevel(event) {
    this.colloboratorAccessLevel = event.value;
  }

  getAccessIcon(accessLevel: string) {
    switch (accessLevel) {
      case ACCESS_LEVEL.VIEW:
        return 'public';
      case ACCESS_LEVEL.EDIT:
        return 'edit';
      case ACCESS_LEVEL.COMMENT:
        return 'comment';
      default:
        return 'lock';
    }
  }

  getAccessLevelText(accessLevel: string) {
    switch (accessLevel) {
      case ACCESS_LEVEL.VIEW:
        return 'Anyone with the share link can view this document';
      case ACCESS_LEVEL.EDIT:
        return 'Anyone with the share link can edit this document';
      case ACCESS_LEVEL.COMMENT:
        return 'Anyone with the share link can comment on this document';
      default:
        return 'Only listed collaborators can access this document';
    }
  }

  manageLinks() {
    const dialogRef = this.dialog.open(LegacyLinksComponent, {
      data: {
        links: this.links.filter((l) => l.id !== this.activeLink.id),
        sharedLinkDefinition: this.sharedLinkDefinition,
      },
    });

    dialogRef.componentInstance.close.subscribe((links) => {
      this.links = [this.activeLink, ...links];
    });
  }

  async removeCollaborator(collaborator) {
    this.collaborators = this.collaborators?.filter((c) => c?.id !== collaborator?.id);
    await this.sharedLinkCollaboratorService.removeCollaborator(collaborator.id);
    delete this.visitedCollaborators[collaborator?.principal.id];

    if (collaborator?.principal?.email) {
      delete this.visitedCollaborators[collaborator.principal.email];
    }

    if (collaborator?.principal?.name) {
      delete this.visitedCollaborators[collaborator?.principal?.name];
    }

    this.snackBar.open('Removed collaborator', '', { duration: 2000 });
  }

  async updateCollaboratorAccess(event, collaborator) {
    await this.sharedLinkCollaboratorService.updateCollaborator(event.value, collaborator.id);
    this.collaborators.forEach((c) => {
      c.accessLevel = c.id === collaborator.id ? event.value : c.accessLevel;
    });

    this.snackBar.open('Updated collaborator access level', '', { duration: 2000 });
  }

  getBackgroundColor(id) {
    if (id) {
      return ColorUtil.stringToHslaColor(id);
    }
  }

  principalAdmin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      workspacePrincipals: this.workspacePrincipals,
    };
    this.matDialog.open(WorkspacePrincipalsComponent, dialogConfig);
  }
}
