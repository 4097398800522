import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { DevToolsService } from './board-dev-tools.service';
import { DevToolsRenderingService } from './dev-tools-rendering/board-dev-rendering.service';
import { DocumentSelectors } from '../document/document-store';

@Component({
  selector: 'app-board-dev-tools',
  templateUrl: './board-dev-tools.component.html',
  styleUrls: ['./board-dev-tools.component.scss'],
})
export class BoardDevToolsComponent implements OnInit {
  private destroy$ = new Subject();

  public activateDevTool$: Observable<boolean>;
  public latestRenderingMetrics$: Observable<any>;
  public renderCount$: Observable<any>;

  constructor(
    private store: Store<RootStoreState.State>,
    private devToolsService: DevToolsService,
    private devToolsRenderingService: DevToolsRenderingService,
    public featureFlagService: FeatureFlagRegistryService,
  ) {
    this.latestRenderingMetrics$ = this.devToolsRenderingService.latestMetrics$;
    this.renderCount$ = this.devToolsRenderingService.renderCount$;
  }

  ngOnInit(): void {
    this.activateDevTool$ = this.devToolsService.devToolsVisible$;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  openPanel() {
    this.devToolsService.launchDevToolPanel();
  }
}
