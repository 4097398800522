import { ViewBox } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { SHAPE_ELEMENT_TYPES } from '../../../constants';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class SelectDragHandler {
  private startingDraggingBox: ViewBox;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (
      !this.canvasDocument.interactionHandler.isSelect() &&
      !this.canvasDocument.interactionHandler.isAnnotate() &&
      !this.canvasDocument.interactionHandler.isPaintFormat()
    ) {
      return;
    }

    const { x, y } = {
      x: event.clientX,
      y: event.clientY,
    };

    const { element, target } = elementTarget;
    const isLockedElement = element?.isSelected && element?.elementDefinition?.isLocked;
    if (!target || isLockedElement) {
      if (isLockedElement) {
        this.canvasDocument.deselectAll();
      }
      this.startingDraggingBox = { x, y, width: 0, height: 0 };
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = { x, y, width: 0, height: 0 };
    }
  }

  public dragged(event) {
    if (!this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox || !this.startingDraggingBox) {
      return;
    }

    const distanceX = event.clientX - this.startingDraggingBox.x;
    const distanceY = event.clientY - this.startingDraggingBox.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    const width = Math.abs(this.startingDraggingBox.width + distanceX);
    const height = Math.abs(this.startingDraggingBox.height + distanceY);
    let x = this.startingDraggingBox.x;
    let y = this.startingDraggingBox.y;

    if (distanceX < 0) {
      x = this.startingDraggingBox.x + distanceX;
    }
    if (distanceY < 0) {
      y = this.startingDraggingBox.y + distanceY;
    }

    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = {
      width,
      height,
      x,
      y,
    };

    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox || !this.startingDraggingBox) {
      return;
    }
    const hasChanged = ObjectUtil.compareDeep(
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox,
      this.startingDraggingBox,
      '',
    );
    if (!hasChanged?.length) {
      this.startingDraggingBox = null;
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = null;
      return;
    }

    this.canvasDocument.interactionHandler.selectionHandler.selectElementsInBox(
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox,
      event.shiftKey,
    );
    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = null;
    this.startingDraggingBox = null;
    this.canvasDocument.draw();
  }
}
