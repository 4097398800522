<div class="w-full h-full flex">
  <div class="option-container">
    <div
      *ngFor="let nav of sideNavOptions"
      (click)="currentSideNav = nav.label"
      class="cursor-pointer flex-center w-10 h-10"
    >
      <mat-icon
        *ngIf="nav?.svgIcon"
        [svgIcon]="nav.svgIcon"
        class="nav-icon"
        [class.active]="currentSideNav === nav.label"
      >
      </mat-icon>
      <mat-icon *ngIf="nav?.icon" class="nav-icon" [class.active]="currentSideNav === nav.label">
        {{ nav.icon }}
      </mat-icon>
    </div>
  </div>

  <!-- Main Body -->
  <div class="placeholder-content flex-center">
    <div class="wrap w-[680px] h-[680px] border flex-center">
      <app-content-preview [content]="content" class="w-full h-full flex-center"></app-content-preview>
    </div>
  </div>

  <div class="config-container w-[340px]" *ngIf="currentSideNav !== 'full'">
    <div class="flex flex-col h-full">
      <h6 class="py-3 px-2">{{ configNavTitle }}</h6>
      <mat-divider></mat-divider>
      <ng-container [ngSwitch]="currentSideNav">
        <ng-template *ngSwitchCase="'details'" [ngTemplateOutlet]="details"></ng-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #details>
  <app-content-info-panel [content]="content" [downloadBtn]="true" (downloadContent)="download()">
  </app-content-info-panel>
</ng-template>
