import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-create-item-modal',
  template: '<app-create-item (createComplete)="complete($event)"></app-create-item>',
  styles: [``],
})
export class CreateItemModalComponent implements OnInit {
  public defaults: any;
  constructor(
    public dialogRef: MatDialogRef<CreateItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.defaults = data.defaults;
  }

  ngOnInit(): void {}
  complete(item) {
    this.dialogRef.close(item);
  }
  cancel() {
    this.dialogRef.close();
  }
}
