<div class="date-option">
  <div *ngIf="!toggle" class="mr-4">{{ dateFilterAttribute.label }}</div>
  <mat-slide-toggle
    data-test="date-range-filter-toggle"
    *ngIf="toggle"
    class="mr-4"
    color="primary"
    (change)="toggleFilterByDate($event)"
    [checked]="dateFilterActive"
    labelPosition="before"
    >{{ dateFilterAttribute.label }}</mat-slide-toggle
  >
  <mat-form-field>
    <mat-select
      [disabled]="!dateFilterActive && toggle"
      data-test="date-filter-search-option"
      (selectionChange)="setSearchOption($event)"
      [(value)]="selectedSearchOption"
    >
      <mat-option *ngFor="let option of searchOptions" [value]="option" data-test="date-filter-search-select">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="customRangeActive" class="date-range">
  <app-type-property-form-field
    appearance=""
    [propertyFormConfiguration]="fromDatePropertyDefinition"
    [value]="fromDateValue"
    (valueChange)="handleValueChange($event)"
    [errorMessage]="errorMessage"
  >
  </app-type-property-form-field>
  <app-type-property-form-field
    appearance=""
    [propertyFormConfiguration]="toDatePropertyDefinition"
    [value]="toDateValue"
    (valueChange)="handleValueChange($event)"
    [errorMessage]="errorMessage"
  >
  </app-type-property-form-field>
</div>
