import {
  NodeMaterial,
  InputBlock,
  Texture,
  TextureBlock,
  AssetContainer,
  Scene,
  Material,
  PBRMaterial,
  Color3,
} from '@babylonjs/core';
import { TextureTransform } from './texture-transform';

// Class wrapping material features including recoloring, adding/offsetting/scaling prints, decals
export class EditableAssetMaterial {
  private matCodeUrl: string = 'https://s3.amazonaws.com/contrail.public.testing/pbr_editable_mat_v0-6.json';

  //#region MaterialInputs
  private input_baseColor: string = 'BaseColor';
  private input_editColor: string = 'EditColor';
  private input_albedoTex: string = 'Albedo texture';

  private input_bumpTex: string = 'Bump texture';
  private input_bumpStr: string = 'Bump strength';

  private input_metallic: string = 'Metallic';
  private input_roughness: string = 'Roughness';
  private input_metallicRoughTex: string = 'MetallicRoughness texture';

  private input_opacityTex: string = 'Opacity texture';

  private input_refractionIndex: string = 'Refraction ior';

  private input_ambientColor: string = 'Ambient color';

  private input_aoTex: string = 'AO texture';
  private input_aoStr: string = 'AO intensity';

  private input_reflectionColor: string = 'Reflection color';
  //#endregion

  private nodeMatInstances: Array<NodeMaterial>;
  private cachedTextures: Array<Texture>;
  private cachedMats: Array<PBRMaterial>;

  private assetContainer: AssetContainer;

  async initialize(assetContainer: AssetContainer): Promise<void> {
    this.assetContainer = assetContainer;

    const nodeMat = await NodeMaterial.ParseFromFileAsync(
      'editableAssetMaterial',
      this.matCodeUrl,
      assetContainer.scene,
    );

    if (nodeMat) {
      this.nodeMatInstances = new Array<NodeMaterial>();
      this.cachedTextures = new Array<Texture>();
      this.cachedMats = new Array<PBRMaterial>();

      for (let i = 0; i < this.assetContainer.meshes.length; i++) {
        if (!this.assetContainer.meshes[i].material) {
          this.cachedTextures.push(null);
          this.nodeMatInstances.push(null);
          continue;
        }

        let nodeMatInst = nodeMat.clone(nodeMat.name + '_' + i);
        this.nodeMatInstances.push(nodeMatInst);

        // Set color to white to start
        let recolorInput = this.nodeMatInstances[i].getBlockByName(this.input_editColor) as InputBlock;
        recolorInput.value = new Color3(1.0, 1.0, 1.0);

        let pbrMat = this.assetContainer.meshes[i].material as PBRMaterial;
        this.cachedMats.push(pbrMat);

        this.cachedTextures.push(pbrMat.albedoTexture as Texture);

        this.CopyPropsFromPBRMat(nodeMatInst, pbrMat);

        // Set current material on mesh to be the node material
        this.assetContainer.meshes[i].material = this.nodeMatInstances[i];
      }
    }
  }

  private CopyPropsFromPBRMat(nodeMat: NodeMaterial, pbrMat: PBRMaterial) {
    if (pbrMat.albedoTexture) {
      let albedoTextureInput = nodeMat.getBlockByName(this.input_albedoTex) as TextureBlock;
      albedoTextureInput.texture = pbrMat.albedoTexture as Texture;
    }

    let albedoColorInput = nodeMat.getBlockByName(this.input_baseColor) as InputBlock;
    albedoColorInput.value = pbrMat.albedoColor;

    if (pbrMat.bumpTexture) {
      let bumpTextureInput = nodeMat.getBlockByName(this.input_bumpTex) as TextureBlock;
      bumpTextureInput.texture = pbrMat.bumpTexture as Texture;
    }

    let metallicInput = nodeMat.getBlockByName(this.input_metallic) as InputBlock;
    metallicInput.value = pbrMat.metallic;

    let roughnessInput = nodeMat.getBlockByName(this.input_roughness) as InputBlock;
    roughnessInput.value = pbrMat.roughness;

    if (pbrMat.metallicTexture) {
      let metalRoughTextureInput = nodeMat.getBlockByName(this.input_metallicRoughTex) as TextureBlock;
      metalRoughTextureInput.texture = pbrMat.metallicTexture as Texture;
    }

    if (pbrMat.opacityTexture) {
      let opacityTextureInput = nodeMat.getBlockByName(this.input_opacityTex) as TextureBlock;
      opacityTextureInput.texture = pbrMat.opacityTexture as Texture;
    }

    let iorInput = nodeMat.getBlockByName(this.input_refractionIndex) as InputBlock;
    iorInput.value = pbrMat.indexOfRefraction;

    let ambientColorInput = nodeMat.getBlockByName(this.input_ambientColor) as InputBlock;
    ambientColorInput.value = pbrMat.ambientColor;

    if (pbrMat.ambientTexture) {
      let aoTextureInput = nodeMat.getBlockByName(this.input_aoTex) as TextureBlock;
      aoTextureInput.texture = pbrMat.ambientTexture as Texture;
    }

    let aoStrInput = nodeMat.getBlockByName(this.input_aoStr) as InputBlock;
    aoStrInput.value = pbrMat.ambientTextureStrength;

    let reflectionColorInput = nodeMat.getBlockByName(this.input_reflectionColor) as InputBlock;
    reflectionColorInput.value = pbrMat.reflectionColor;

    nodeMat.backFaceCulling = pbrMat.backFaceCulling;
    nodeMat.cullBackFaces = pbrMat.cullBackFaces;
  }

  // Finds texture in active textures array by comparing to final term in metadata.gltf.pointer path
  // ex: baseColorTexture, metallicRoughnessTexture, normalTexture, occlusionTexture
  private getGltfTextureByName(mat: Material, textureName: string): Texture {
    let texture = null;

    for (let i = 0; i < mat.getActiveTextures().length; i++) {
      let path: string = mat.getActiveTextures()[i].metadata?.gltf?.pointers[0];

      if (!path) {
        continue;
      }

      let splits: string[] = path.split('/');
      let texName: string = splits[splits.length - 1];

      if (texName == textureName) {
        texture = mat.getActiveTextures()[i];
        break;
      }
    }

    return texture;
  }

  isEnabled(): boolean {
    return this.nodeMatInstances.length > 0;
  }

  // Sets either the node materials or the cached materials as the current materials on the mesh based on enabled parameter
  setEnabled(enabled: boolean): void {
    for (let i = 0; i < this.assetContainer.meshes.length; i++) {
      let activeMaterial = enabled ? this.nodeMatInstances[i] : this.cachedMats[i];
      this.assetContainer.meshes[i].material = activeMaterial;
    }
  }

  setColorFromHex(hex: string) {
    if (!this.nodeMatInstances) {
      return;
    }

    for (let i = 0; i < this.nodeMatInstances.length; i++) {
      let nodeMat = this.nodeMatInstances[i];

      if (!nodeMat) {
        continue;
      }

      let colorInput = nodeMat.getBlockByName(this.input_baseColor) as InputBlock;
      colorInput.value = Color3.FromHexString(hex);
    }
  }

  resetTextures(): void {
    if (!this.nodeMatInstances) {
      return;
    }

    for (let i = 0; i < this.nodeMatInstances.length; i++) {
      let nodeMat = this.nodeMatInstances[i];

      if (!nodeMat) {
        continue;
      }

      let albedoTextureInput = nodeMat.getBlockByName(this.input_albedoTex) as TextureBlock;
      albedoTextureInput.texture = this.cachedTextures[i];
    }
  }

  setTextureFromStr(textureData: string) {
    if (!this.nodeMatInstances) {
      return;
    }

    for (let i = 0; i < this.nodeMatInstances.length; i++) {
      let nodeMat = this.nodeMatInstances[i];

      if (!nodeMat) {
        continue;
      }

      let texture = new Texture(textureData, this.assetContainer.scene);

      let albedoTextureInput = nodeMat.getBlockByName(this.input_albedoTex) as TextureBlock;
      albedoTextureInput.texture = texture;
    }
  }

  setTextureTransform(textureTransform: TextureTransform) {
    if (!this.nodeMatInstances) {
      return;
    }

    for (let i = 0; i < this.nodeMatInstances.length; i++) {
      let nodeMat = this.nodeMatInstances[i];

      if (!nodeMat) {
        continue;
      }

      let albedoTextureInput = nodeMat.getBlockByName(this.input_albedoTex) as TextureBlock;

      albedoTextureInput.texture.uOffset = textureTransform.uOffset;
      albedoTextureInput.texture.vOffset = textureTransform.vOffset;

      albedoTextureInput.texture.wAng = textureTransform.wAngle;

      albedoTextureInput.texture.uScale = textureTransform.uScale;
      albedoTextureInput.texture.vScale = textureTransform.vScale;
    }
  }
}
