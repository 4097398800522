<div class="history-info-panel" *ngIf="historicalContent">
  <button
    class="flex items-center justify-center w-10 h-10 min-w-0 text-black60 px-3.5 py-1.5"
    (click)="closeHistory()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div class="text-lg font-medium p-1.5">{{ historicalContent.updatedOn | date: 'medium' }}</div>
</div>

<div class="w-full h-full flex">
  <div class="option-container" *ngIf="!historicalContent">
    <div
      *ngFor="let nav of sideNavOptions"
      (click)="currentSideNav = nav.label"
      class="cursor-pointer flex-center w-10 h-10"
    >
      <mat-icon
        *ngIf="nav?.svgIcon"
        [svgIcon]="nav.svgIcon"
        class="nav-icon"
        [class.active]="currentSideNav === nav.label"
      >
      </mat-icon>
      <mat-icon *ngIf="nav?.icon" class="nav-icon" [class.active]="currentSideNav === nav.label">
        {{ nav.icon }}
      </mat-icon>
    </div>
  </div>

  <!-- Main Body -->
  <div class="placeholder-content flex-center">
    <div class="wrap w-full h-full flex-center">
      <app-webgl-viewer
        *ngIf="useVibeViewer"
        [content]="contentToDisplay"
        [allowScreenCapture]="true"
        style="width: 100%; height: 100%"
        [ngClass]="!contentToDisplay || currentSideNav === 'config' ? 'opacity-0' : 'opacity-100'"
        [size]="'100%'"
        (screenShots)="handleScreenShot($event)"
      ></app-webgl-viewer>

      <iframe
        *ngIf="!useVibeViewer"
        [src]="content?.embedLink | safe: 'resourceUrl'"
        class="w-full h-full"
        frameborder="0"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>

      <div class="flex-center absolute" *ngIf="currentSideNav === 'config'">
        <canvas id="renderCanvasConfig" class="w-full h-full"></canvas>
        <div class="canvas-buttons flex-center">
          <img src="/assets/images/3d_coordinates.svg" id="webglMove" matTooltip="Offset" matTooltipPosition="above" />
          <img src="/assets/images/3d_rotate.svg" id="webglRotate" matTooltip="Rotation" matTooltipPosition="above" />
          <img src="/assets/images/zoom_in.svg" id="webglZoomIn" matTooltip="Zoom in" matTooltipPosition="above" />
          <img src="/assets/images/zoom_out.svg" id="webglZoomOut" matTooltip="Zoom out" matTooltipPosition="above" />
        </div>
      </div>
    </div>
  </div>

  <div class="config-container w-[340px]" *ngIf="currentSideNav !== 'full'">
    <div class="flex flex-col h-full">
      <h6 class="py-3 px-2">{{ configNavTitle }}</h6>
      <mat-divider></mat-divider>
      <ng-container [ngSwitch]="currentSideNav">
        <ng-template *ngSwitchCase="'details'" [ngTemplateOutlet]="details"></ng-template>
        <ng-template *ngSwitchCase="'history'" [ngTemplateOutlet]="history"></ng-template>
        <ng-template *ngSwitchCase="'config'" [ngTemplateOutlet]="showroomConfig"></ng-template>
        <ng-template *ngSwitchCase="'image'" [ngTemplateOutlet]="overlay"></ng-template>
        <ng-template *ngSwitchCase="'pattern'" [ngTemplateOutlet]="overlay"></ng-template>
        <ng-template *ngSwitchCase="'recolor'" [ngTemplateOutlet]="recolorConfig"></ng-template>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #details>
  <app-content-info-panel
    [content]="content"
    [downloadBtn]="true"
    (downloadContent)="download()"
    (updateContent)="updateContent3DViewOption($event)"
  >
  </app-content-info-panel>
</ng-template>
<ng-template #history>
  <app-content-history [content]="content" (historySelected)="loadHistoricalModel($event)"> </app-content-history>
</ng-template>
<ng-template #showroomConfig>
  <app-webgl-content-configurator
    [content]="content"
    [size]="'339px'"
    [viewMode]="'modal'"
    [contentModal]="true"
  ></app-webgl-content-configurator>
</ng-template>
<ng-template #overlay>
  <app-webgl-overlay-configurator
    [content]="content"
    [type]="currentSideNav"
    [webglViewer]="webglView?.viewer"
  ></app-webgl-overlay-configurator>
</ng-template>
<ng-template #recolorConfig>
  <div class="mt-1">
    <span
      [style.background]="color"
      [cpWidth]="'320px'"
      autocomplete="off"
      [(colorPicker)]="color"
      [cpSaveClickOutside]="true"
      [cpPresetColors]="presetColors"
      [cpPositionOffset]="'10%'"
      [cpAlphaChannel]="'disabled'"
      [cpPresetColorsClass]="'preset-colors'"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      (colorPickerChange)="colorChange($event)"
      placeholder=""
    >
    </span>
  </div>
</ng-template>
