import { createSelector } from '@ngrx/store';
import { Board, boardsEntityAdapter } from './boards.state';
import { RemoteMouseTracker } from '../board/board-mouse-tracker/board-mouse-tracker.component';

export const { selectAll, selectEntities, selectIds, selectTotal } = boardsEntityAdapter.getSelectors();

const boardEntities = (state: any) => state.boards.boards.entities;
export const boards = createSelector(boardEntities, (state) => Object.values(state) as Array<Board>);
export const boardsLoaded = (state: any) => state.boards.boardsLoaded;
export const currentBoard = (state: any) => state.boards.currentBoard;
export const remoteUserMouseTracker = (state: any) =>
  Object.values(state.boards.remoteUserMouseTracker) as Array<RemoteMouseTracker>;
export const itemsUsedInBoard = (state: any) => state.boards.itemsUsedInBoard;
export const showSourceAssortmentWarning = (state: any) => state.boards.showSourceAssortmentWarning;
export const currentDocumentGenerationConfig = (state: any) => state.boards.currentDocumentGenerationConfig;
