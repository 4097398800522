import { ValidationError } from '@contrail/type-validation';
import { SizeRange, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';

export const ILLEGAL_SIZES_IN_SIZE_RANGE = 'This size range contains illegal sizes.';

export class SizeRangeHelper {
  public static sizeRangeTemplateProperty = 'sizeRangeTemplate';

  public static getConstrainingSizeRange(data: any) {
    let sizeRangeTemplate;
    if (data[this.sizeRangeTemplateProperty]) {
      sizeRangeTemplate = data[this.sizeRangeTemplateProperty].sizeRange;
    }
    if (data.itemOption) {
      if (data[this.sizeRangeTemplateProperty]) {
        sizeRangeTemplate = data[this.sizeRangeTemplateProperty].sizeRange;
      } else {
        if (data.itemFamily[this.sizeRangeTemplateProperty]) {
          sizeRangeTemplate = data.itemFamily[this.sizeRangeTemplateProperty].sizeRange;
        }
      }
    }

    if (!sizeRangeTemplate) {
      // get it from other objects?
    }

    if (sizeRangeTemplate) {
      return sizeRangeTemplate;
    }
    return null;
  }

  /**
   * Returns errors if sizes are illegal.  Used for cell validation.
   * @param entity
   * @param property
   * @returns
   */
  public static validateSizeRange(entity: any, property: TypeProperty): ValidationError[] {
    const constrainingSizeRange = this.getConstrainingSizeRange(entity);
    const validationErrors: ValidationError[] = [];
    if (entity[property.slug] && !this.isSizeRangeValid(constrainingSizeRange, entity[property.slug])) {
      validationErrors.push({
        message: ILLEGAL_SIZES_IN_SIZE_RANGE,
        slug: property.slug,
      });
    }
    return validationErrors;
  }

  /**
   * Determines whether all sizes in the size range is in the size range template
   * @param sizeRangeTemplate
   * @param sizeRange
   * @returns
   */
  public static isSizeRangeValid(sizeRangeTemplate: SizeRange, sizeRange: SizeRange): boolean {
    if (
      (Array.isArray(sizeRangeTemplate?.sizes) && !Array.isArray(sizeRange?.sizes)) ||
      (!Array.isArray(sizeRangeTemplate?.sizes) && Array.isArray(sizeRange?.sizes))
    ) {
      return false;
    }
    let valid = true;
    if (Array.isArray(sizeRange.sizes)) {
      const templateSizes: Array<string> = ObjectUtil.cloneDeep(sizeRangeTemplate.sizes);
      sizeRange.sizes.forEach((size) => {
        if (!templateSizes.includes(size)) {
          valid = false;
        }
      });
    } else {
      Object.keys(sizeRangeTemplate.sizes).forEach((dimension2Size) => {
        sizeRange.sizes[dimension2Size]?.forEach((size) => {
          if (!sizeRangeTemplate.sizes[dimension2Size].includes(size)) {
            valid = false;
          }
        });
      });
    }
    return valid;
  }

  /**
   * Returns an array of sizes based on the selected sizes
   * @param sizeRange
   * @returns
   */
  public static getSizeRangeSizesList(sizeRange: SizeRange): Array<string> {
    if (!sizeRange) {
      return [];
    }
    if (Array.isArray(sizeRange.sizes)) {
      return sizeRange.sizes;
    } else {
      const sizes: Array<string> = [];
      Object.keys(sizeRange.sizes).forEach((dimension2Size) => {
        sizeRange.sizes[dimension2Size]?.forEach((size) => {
          sizes.push(dimension2Size + ' x ' + size);
        });
      });
      return sizes;
    }
  }
}
