import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription, debounceTime, tap } from 'rxjs';
import { Types } from '@contrail/sdk';
import { EntityPropertiesFormComponent } from '@common/entity-details/entity-properties-form/entity-properties-form.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { Item } from '@contrail/entity-types';
import { ValidationError, ValidatorFunctionProcessor, TypeConstraintsHelper } from '@contrail/type-validation';

export const MULTIPLE_VALUES_DETECTED = 'Multiple values detected. Changes will override all.';
@Component({
  selector: 'app-component-entity-property-editor',
  templateUrl: './component-entity-property-editor.component.html',
  styleUrls: ['./component-entity-property-editor.component.scss'],
})
export class ComponentEntityPropertyEditorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(EntityPropertiesFormComponent) entityPropertyForm: EntityPropertiesFormComponent;
  @Input() selectedPropertySlugs: string[] = [];
  @Input() entity: any;
  @Input() entities: any[]; // used for update multiple entities
  @Input() searchTerm: string;
  @Input() ignoreAllLevelProps = false;
  @Input() accessLevel = 'EDIT';
  @Input() warningStyle: any = {
    fontSize: '10px',
    color: 'red',
  };
  warnings: any = {}; // used to display warnings for multiple entities, such as multiple values detected
  @Output() updated = new EventEmitter();
  entityReference: string;
  type: Type;
  errors: any = {};
  private allTypeProperties: TypeProperty[];
  private subscriptions = new Subscription();
  editable = true;
  public changesForUpdate: any = {};

  constructor(private snackBar: MatSnackBar) {}

  async ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.entityPropertyForm) {
      this.subscribeToPropertyForm();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      (changes.selectedPropertySlugs && this.type) ||
      changes.ignoreAllLevelProps ||
      (changes.searchTerm && this.allTypeProperties)
    ) {
      this.type = ObjectUtil.cloneDeep(this.type);
      if (this.allTypeProperties) {
        if (this.selectedPropertySlugs.length) {
          this.type.typeProperties = this.allTypeProperties.filter(
            (prop) =>
              this.selectedPropertySlugs.includes(prop.slug) &&
              prop.label.toLowerCase().includes(this.searchTerm.toLowerCase()),
          );
        } else {
          this.type.typeProperties = this.allTypeProperties.filter((prop) =>
            prop.label.toLowerCase().includes(this.searchTerm.toLowerCase()),
          );
        }
      }
    }
    if (changes.entity?.currentValue || changes.ignoreAllLevelProps) {
      if (this.entity) {
        this.initProperties();
        this.entityReference = this.entity?.entityType + ':' + this.entity.id;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscribeToPropertyForm() {
    this.entityPropertyForm.changes
      .pipe(
        debounceTime(500),
        tap((formChanges) => this.updateEntityValues(formChanges)),
      )
      .subscribe();
  }

  private async initProperties() {
    this.warnings = {};
    this.type = ObjectUtil.cloneDeep(await new Types().getType({ id: this.entity.typeId }));
    await this.validatePropertyValues(this.type, this.entity, null);
    if (['item', 'project-item'].includes(this.entity.entityType)) {
      const clonedProperties = [...this.type.typeProperties].filter(
        (prop) => !['updatedOn', 'createdOn'].includes(prop.slug),
      );
      if (this.entity.roles?.includes('family')) {
        if (this.ignoreAllLevelProps) {
          // do not include properties can be overridden in the option level
          this.type.typeProperties = clonedProperties.filter(
            (prop) => ['family', undefined, null].includes(prop.propertyLevel) && prop.slug !== 'optionName',
          );
        } else {
          this.type.typeProperties = clonedProperties.filter((prop) =>
            ['family', 'overridable', 'all', undefined].includes(prop.propertyLevel),
          );
        }
        if (this.entity.entityType === 'item') {
          const nameProperty = clonedProperties.find((prop) => prop?.slug === 'name');
          this.type.typeProperties = [nameProperty, ...this.type.typeProperties];
        }
      } else if (this.entity.roles?.includes('option')) {
        this.type.typeProperties = clonedProperties.filter((prop) =>
          ['option', 'all', 'overridable'].includes(prop.propertyLevel),
        );
        if (this.entity.entityType === 'item') {
          const optionNameProperty = clonedProperties.find((prop) => prop?.slug === 'optionName');
          this.type.typeProperties = [optionNameProperty, ...this.type.typeProperties];
        }
      }
    }
    this.allTypeProperties = ObjectUtil.cloneDeep(this.type.typeProperties);
    if (this.selectedPropertySlugs.length) {
      this.type.typeProperties = this.allTypeProperties.filter((prop) =>
        this.selectedPropertySlugs.includes(prop?.slug),
      );
    }
    if (this.entities.length > 1) {
      // multiple entities detected for update
      const entities = this.entities.filter((e) => e.entityType === this.entity.entityType);
      for (const prop of this.allTypeProperties) {
        const isSameValue = entities.every((e) => {
          return entities[0][prop.slug] === e[prop.slug];
        });
        if (!isSameValue) {
          this.warnings[prop.slug] = MULTIPLE_VALUES_DETECTED;
        }
      }
    }
  }

  async updateEntityValues(formChange: any) {
    console.log('EntityDetailComponent: updateEntityValues: ', formChange);
    const changes = {};
    changes[formChange.propertySlug] = formChange.value || null;

    if (
      formChange.value &&
      typeof formChange.value === 'object' &&
      formChange.value.hasOwnProperty(formChange.propertySlug + 'Id')
    ) {
      changes[formChange.propertySlug + 'Id'] = formChange.value[formChange.propertySlug + 'Id'];
    }
    this.changesForUpdate = Object.assign({}, this.changesForUpdate, changes);
    await this.validatePropertyValues(this.type, Object.assign({}, this.entity, this.changesForUpdate), null);
    if (Object.keys(this.errors).length === 0 || Object.values(this.errors).every((x) => x === null)) {
      console.log('EntityDetailComponent: changes: ', this.changesForUpdate);
      const origEntity = ObjectUtil.cloneDeep(this.entity);
      this.entity = Object.assign({}, this.entity, this.changesForUpdate);

      // EMIT CHANGES TO THE SOURCE APP
      this.updated.emit({ object: origEntity, changes: this.changesForUpdate });
      this.changesForUpdate = {};
    } else {
      this.snackBar.open('Item was not updated because of errors. Please fix errors to continue.', '', {
        duration: 4000,
      });
    }
  }

  async validatePropertyValues(type: Type, item: Item, context: any) {
    this.errors = ObjectUtil.cloneDeep(this.errors);
    for (const property of type.typeProperties) {
      let allValidationErrors: ValidationError[] = [];
      if (property.validationFunction) {
        const validatorErrors: ValidationError[] = await ValidatorFunctionProcessor.processValidatorFunction(
          property.validationFunction,
          item,
          context,
        );
        allValidationErrors = validatorErrors ?? [];
      }
      const validationErrors: ValidationError[] = await TypeConstraintsHelper.isValueLegalForEntity(
        type,
        property,
        item,
        item[property.slug],
      );
      allValidationErrors = allValidationErrors.concat(validationErrors);

      // validate size range if it exists
      if (property.propertyType === PropertyType.SizeRange) {
        const sizeRangeValidationErrors: ValidationError[] = SizeRangeHelper.validateSizeRange(item, property);
        if (sizeRangeValidationErrors.length > 0) {
          allValidationErrors = allValidationErrors.concat(sizeRangeValidationErrors);
        }
      }
      if (allValidationErrors.length > 0) {
        this.errors[property.slug] = allValidationErrors[0].message; // just return the first error
      } else if (this.errors[property.slug]) {
        this.errors[property.slug] = null;
      }
    }
  }
}
