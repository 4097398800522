<div class="item">
  <div class="version-name">{{ assortmentHistoryItem.versionName }}</div>
  <div class="timestamp">{{ assortmentHistoryItem.createdOn | date: 'medium' }}</div>
  <div class="actor">{{ getName() }}</div>
  <div class="changes">
    <span class="change add" *ngIf="assortmentHistoryItem.adds"
      ><mat-icon>add</mat-icon>{{ assortmentHistoryItem.adds }}</span
    >
    <span class="change drop" *ngIf="assortmentHistoryItem.deletes"
      ><mat-icon>remove_circle_outline</mat-icon>{{ assortmentHistoryItem.deletes }}</span
    >
    <span class="change modify" *ngIf="assortmentHistoryItem.updates"
      ><mat-icon>change_history</mat-icon>{{ assortmentHistoryItem.updates }}</span
    >
  </div>
</div>
