import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-iframe-element-modal',
  templateUrl: './view-iframe-element-modal.component.html',
  styleUrls: ['./view-iframe-element-modal.component.scss'],
})
export class ViewIFrameElementModalComponent implements OnInit {
  url = null;
  constructor(
    public dialogRef: MatDialogRef<ViewIFrameElementModalComponent>,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.element.url);
  }

  closeModal(obj = {}) {
    this.dialogRef.close(obj);
  }

  handleClose() {
    this.closeModal();
  }

  openNewTab() {
    window.open(this.data.element.url, '_blank');
  }
}
