import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Type } from '@contrail/types';
import { Item } from '../../item';

@Component({
  selector: 'app-item-details-meta',
  templateUrl: './item-details-meta.component.html',
  styleUrls: ['./item-details-meta.component.scss'],
})
export class ItemDetailsMetaComponent {
  @Input() item: Item;
  @Input() type: Type;
  constructor() {}

  getActorName(actor) {
    if (actor?.firstName) {
      return actor?.firstName + ' ' + actor?.lastName;
    }
    return actor?.email;
  }
}
