import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Type } from '@contrail/types';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  TypePropertyFormComponent,
  TypePropertyFormConfiguration,
} from '../../types/forms/type-property-form/type-property-form.component';
import { Item } from '../item';
import { AccessManagedType } from '@contrail/sdk';

@Component({
  selector: 'app-item-properties-form',
  templateUrl: './item-properties-form.component.html',
  styleUrls: ['./item-properties-form.component.scss'],
})
export class ItemPropertiesFormComponent implements OnChanges, AfterViewInit {
  @Input() errors: any;
  @Input() type: AccessManagedType;
  @Input() item: Item;
  @Input() accessLevel = 'EDIT';
  @Input() editable = true;
  @ViewChild(TypePropertyFormComponent) propertyForm: TypePropertyFormComponent;
  public itemChanges: Subject<Item> = new Subject();
  public propertyConfigurations: Array<TypePropertyFormConfiguration>;
  constructor() {}

  ngOnChanges(): void {
    if (!this.type) {
      this.propertyConfigurations = null;
      return;
    }
    const canEdit = this.accessLevel === 'EDIT' && this.editable && this.type.canUpdate !== false;
    const propertieConfigurations: Array<TypePropertyFormConfiguration> = [];

    // Special handling for name properties.
    const nameProperty = this.type.typeProperties.find((prop) => prop.slug === 'name');
    if (nameProperty) {
      const editable = canEdit && this.item.roles?.includes('family');
      propertieConfigurations.push({ typeProperty: nameProperty, editable });
    }
    const optionNameProperty = this.type.typeProperties.find((prop) => prop.slug === 'optionName');
    if (optionNameProperty) {
      const editable = canEdit && this.item.roles?.includes('option');
      propertieConfigurations.push({ typeProperty: optionNameProperty, editable });
    }

    // Level based properties
    let remaining: any = this.type.typeProperties.filter(
      (prop) => !['name', 'optionName', 'updatedOn', 'createdOn'].includes(prop.slug),
    );

    if (this.item.roles?.includes('family')) {
      remaining = remaining.map((prop) => {
        return {
          typeProperty: prop,
          editable: canEdit && [null, 'family', 'overridable', 'all', undefined].includes(prop.propertyLevel),
        };
      });
    } else if (this.item.roles?.includes('option')) {
      remaining = remaining.map((prop) => {
        return {
          typeProperty: prop,
          editable: canEdit && ['option', 'all', 'overridable'].includes(prop.propertyLevel),
        };
      });
    }
    remaining.sort((t1, t2) => (t1.typeProperty?.label > t2.typeProperty?.label ? 1 : -1));
    this.propertyConfigurations = propertieConfigurations.concat(remaining);
  }
  ngAfterViewInit() {
    this.propertyForm.formChanges
      .pipe(
        tap((change) => {
          this.itemChanges.next(change as Item);
        }),
      )
      .subscribe();
  }
}
