<div
  *ngIf="textHyperlinkDetails"
  class="text-hyperlink"
  [ngClass]="{ 'text-hyperlink-visible': showOverlay }"
  [ngStyle]="{
    top: textHyperlinkDetails?.position?.y + yPositionAdjustment + 'px',
    left: textHyperlinkDetails?.position?.x + 'px'
  }"
>
  <div class="flex" *ngIf="mode === 'edit'">
    <div class="flex items-center">
      <mat-icon class="mr-3">link</mat-icon>
      <mat-form-field class="w-[230px]">
        <input matInput #url type="url" [formControl]="urlControl" />
        <mat-error *ngIf="urlControl.hasError('pattern')"> Please enter a valid URL </mat-error>
      </mat-form-field>
    </div>
    <div class="flex mx-2 items-center text-xs">
      <span
        [ngClass]="{ 'cursor-pointer text-primary': valueChanged, 'text-gray-500': !valueChanged }"
        (click)="updateLink()"
        >Apply</span
      >
      <span class="mx-1 text-primary">|</span>
      <span
        [ngClass]="{ 'cursor-pointer text-primary': currentValue, 'text-gray-500': !currentValue }"
        (click)="removeLink($event)"
        >Remove</span
      >
    </div>
  </div>
  <div class="flex py-[18px]" *ngIf="mode === 'view'">
    <div class="flex items-center">
      <mat-icon class="mr-3">link</mat-icon>
      <a [href]="textHyperlinkDetails.url" target="_blank">{{ textHyperlinkDetails.url }}</a>
    </div>
  </div>
</div>
