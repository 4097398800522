import { PositionDefinition } from '@contrail/documents';
import {
  DRAG_DIRECTIONS,
  MouseTarget,
} from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { Table, TableCell, TableColumn, TableRow } from '../table/table';
import { TableHoverRenderer } from './table-hover-renderer';

export interface TableHoverMouseTarget {
  x: number;
  y: number;
  width: number;
  height: number;
  mouseTarget: MouseTarget;
}

export class TableHoverState {
  public row: TableRow;
  public column: TableColumn;
  private hoverHandleCache: TableHoverMouseTarget;
  private renderer: TableHoverRenderer;

  constructor(private table: Table) {
    this.renderer = new TableHoverRenderer(this.table, this);
  }

  public render(ctx, viewScale, params: { x; y; width; height }) {
    this.renderer.render(ctx, viewScale, params);
  }

  public set(cell?: TableCell) {
    if (!cell) {
      this.column = null;
      this.row = null;
      return;
    }
    this.column = cell?.columnIndex >= 0 ? this.table.column(cell.columnIndex) : null;
    this.row = cell?.rowIndex >= 0 ? this.table.row(cell.rowIndex) : null;
  }

  /**
   * Check if @cell is same as hovered @column and @row at cache.
   * @column or @row are null when mouse is hovered above
   * columns or to the left of rows.
   * @param cell
   * @returns
   */
  public isSame(cell: TableCell) {
    return (
      ((!this.column && cell?.columnIndex === -1) || this.column?.index === cell?.columnIndex) &&
      ((!this.row && cell?.rowIndex === -1) || this.row?.index === cell?.rowIndex)
    );
  }

  public isSet() {
    return this.column != null || this.row != null;
  }

  public getHoverHandle(px: number, py: number, position: PositionDefinition, viewScale): MouseTarget {
    if (!this.table.tableHoverState.isSet()) {
      this.hoverHandleCache = null;
      return null;
    }
    if (this.hoverHandleCache != null) {
      if (
        px >= this.hoverHandleCache.x &&
        px <= this.hoverHandleCache.x + this.hoverHandleCache.width &&
        py >= this.hoverHandleCache.y &&
        py <= this.hoverHandleCache.y + this.hoverHandleCache.height
      ) {
        return this.hoverHandleCache.mouseTarget;
      }
    }
    let hoverHandle: TableHoverMouseTarget;
    if (this.column) {
      const dragHandleDimensions = TableHoverRenderer.getColumnDragHandleDimensions(this.column, position, viewScale);
      if (
        dragHandleDimensions &&
        px >= dragHandleDimensions.x &&
        px <= dragHandleDimensions.x + dragHandleDimensions.width &&
        py >= dragHandleDimensions.y &&
        py <= dragHandleDimensions.y + dragHandleDimensions.height
      ) {
        hoverHandle = {
          x: dragHandleDimensions.x,
          y: dragHandleDimensions.y,
          width: dragHandleDimensions.width,
          height: dragHandleDimensions.height,
          mouseTarget: {
            direction: DRAG_DIRECTIONS.DRAG_COLUMN,
            index: { x: this.column.index },
          },
        };
      }
      if (!hoverHandle) {
        const addHandleDimensions = this.renderer.getColumnAddHandleDimensions(position, viewScale);
        if (addHandleDimensions) {
          if (
            px >= addHandleDimensions.x1 &&
            px <= addHandleDimensions.x1 + addHandleDimensions.width &&
            py >= addHandleDimensions.y &&
            py <= addHandleDimensions.y + addHandleDimensions.width
          ) {
            hoverHandle = {
              x: addHandleDimensions.x1,
              y: addHandleDimensions.y,
              width: addHandleDimensions.width,
              height: addHandleDimensions.width,
              mouseTarget: {
                direction: DRAG_DIRECTIONS.ADD_COLUMN,
                index: { x: this.column.index },
              },
            };
          } else if (
            px >= addHandleDimensions.x2 &&
            px <= addHandleDimensions.x2 + addHandleDimensions.width &&
            py >= addHandleDimensions.y &&
            py <= addHandleDimensions.y + addHandleDimensions.width
          ) {
            hoverHandle = {
              x: addHandleDimensions.x2,
              y: addHandleDimensions.y,
              width: addHandleDimensions.width,
              height: addHandleDimensions.width,
              mouseTarget: {
                direction: DRAG_DIRECTIONS.ADD_COLUMN,
                index: { x: this.column.index + 1 },
              },
            };
          }
        }
      }
    }
    if (!hoverHandle && this.row) {
      const dragHandleDimensions = TableHoverRenderer.getRowDragHandleDimensions(this.row, position, viewScale);
      if (
        dragHandleDimensions &&
        px >= dragHandleDimensions.x &&
        px <= dragHandleDimensions.x + dragHandleDimensions.width &&
        py >= dragHandleDimensions.y &&
        py <= dragHandleDimensions.y + dragHandleDimensions.height
      ) {
        hoverHandle = {
          x: dragHandleDimensions.x,
          y: dragHandleDimensions.y,
          width: dragHandleDimensions.width,
          height: dragHandleDimensions.height,
          mouseTarget: {
            direction: DRAG_DIRECTIONS.DRAG_ROW,
            index: { y: this.row.index },
          },
        };
      }
      if (!hoverHandle) {
        const addHandleDimensions = this.renderer.getRowAddHandleDimensions(position, viewScale);
        if (addHandleDimensions) {
          if (
            px >= addHandleDimensions.x &&
            px <= addHandleDimensions.x + addHandleDimensions.width &&
            py >= addHandleDimensions.y1 &&
            py <= addHandleDimensions.y1 + addHandleDimensions.width
          ) {
            hoverHandle = {
              x: addHandleDimensions.x,
              y: addHandleDimensions.y1,
              width: addHandleDimensions.width,
              height: addHandleDimensions.width,
              mouseTarget: {
                direction: DRAG_DIRECTIONS.ADD_ROW,
                index: { y: this.row.index },
              },
            };
          } else if (
            px >= addHandleDimensions.x &&
            px <= addHandleDimensions.x + addHandleDimensions.width &&
            py >= addHandleDimensions.y2 &&
            py <= addHandleDimensions.y2 + addHandleDimensions.width
          ) {
            hoverHandle = {
              x: addHandleDimensions.x,
              y: addHandleDimensions.y2,
              width: addHandleDimensions.width,
              height: addHandleDimensions.width,
              mouseTarget: {
                direction: DRAG_DIRECTIONS.ADD_ROW,
                index: { y: this.row.index + 1 },
              },
            };
          }
        }
      }
    }
    this.hoverHandleCache = hoverHandle;
    return hoverHandle?.mouseTarget;
  }
}
