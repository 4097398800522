import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ComponentsModule } from '@common/components/components.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { EntityDetailsModule } from '@common/entity-details/entity-details.module';
import { DirectivesModule } from '@directives/directives.module';

import { ComponentEditorComponent } from './component-editor.component';
import { ComponentEditorPreviewComponent } from './component-editor-preview/component-editor-preview.component';
import { ComponentEditorThumbnailComponent } from './component-editor-thumbnail/component-editor-thumbnail.component';
import { ComponentEditorPropertySelectorComponent } from './component-editor-property-selector/component-editor-property-selector.component';
import { ComponentEditorFontComponent } from './component-editor-font/component-editor-font.component';
import { ComponentEditorPropertyConfiguratorComponent } from './component-editor-property-configurator/component-editor-property-configurator.component';
import { ComponentEditorAnnotationComponent } from './component-editor-annotation/component-editor-annotation.component';
import { ComponentEntityEditorComponent } from './component-entity-editor/component-entity-editor.component';
import { ComponentEntityPropertyEditorComponent } from './component-entity-editor/component-entity-property-editor/component-entity-property-editor.component';

import { PropertyViewDefinitionBuilderComponent } from './property-view-definition-builder/property-view-definition-builder.component';
import { PropertyCreateViewDefinitionComponent } from './property-view-definition-builder/create-view-definition/create-view-definition.component';

import { PropertyConfiguratorModule } from '../property-configurator/property-configurator.module';

@NgModule({
  declarations: [
    ComponentEditorComponent,
    ComponentEditorPreviewComponent,
    ComponentEditorThumbnailComponent,
    ComponentEditorPropertySelectorComponent,
    ComponentEditorFontComponent,
    ComponentEditorPropertyConfiguratorComponent,
    ComponentEditorAnnotationComponent,
    PropertyViewDefinitionBuilderComponent,
    PropertyCreateViewDefinitionComponent,
    ComponentEntityEditorComponent,
    ComponentEntityPropertyEditorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatTabsModule,
    MatButtonToggleModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    EntityDetailsModule,
    PropertyConfiguratorModule,
  ],
  exports: [ComponentEditorComponent],
})
export class ComponentEditorModule {}
