import { createAction, props } from '@ngrx/store';
import { Board } from './boards.state';
import { UserSession } from 'src/app/common/user-sessions/user-session';
import { Item } from 'src/app/common/assortments/item';

export enum BoardsActionTypes {
  LOAD_BOARDS = '[Boards] Load Boards',
  LOAD_BOARDS_SUCCESS = '[Boards] Load Boards Success',
  LOAD_BOARDS_FAILURE = '[Boards] Load Boards Failure',

  CLEAR_BOARDS = '[Boards] Clear Boards',
  CLEAR_BOARD = '[Boards] Clear Board',

  CREATE_BOARD = '[Boards] Create Board',
  CREATE_BOARD_SUCCESS = '[Boards] Create Board Success',
  CREATE_BOARD_FAILURE = '[Boards] Create Board Failure',

  DELETE_BOARD = '[Boards] Delete Board',
  DELETE_BOARD_SUCCESS = '[Boards] Delete Board Success',
  DELETE_BOARD_FAILURE = '[Boards] Delete Board Failure',

  UPDATE_BOARD = '[Boards] Update Board',
  UPDATE_BOARD_SUCCESS = '[Boards] Update Board Success',
  UPDATE_BOARD_FAILURE = '[Boards] Update Board Failure',

  LOAD_CURRENT_BOARD = '[Boards] Load Current Board',
  LOAD_CURRENT_BOARD_SUCCESS = '[Boards] Load Current Board Success',
  LOAD_CURRENT_BOARD_FAILURE = '[Boards] Load Current Board Failure',

  REMOTE_USER_MOUSE_MOVED = '[Boards] Remote User Mouse Moved',
  REMOVE_REMOTE_USER_FROM_MOUSE_SESSION = '[Boards] Remove Remote User from Mouse Session',

  SET_ITEMS_USED_IN_BOARD = '[Boards] Load Item ids added to the board',
  CLEAR_ITEMS_USED_IN_BOARD = '[Boards] Clear items used in the board',

  SET_SHOW_SOURCE_ASSORTMENT_WARNING = '[Boards] Set Show Source Assortment Warning',

  ASSIGN_BOARD_SOURCE_ASSORTMENT = '[Showcases] Assign Board Source Assortment',
  ASSIGN_BOARD_SOURCE_ASSORTMENT_SUCCESS = '[Showcases] Assign Board Source Assortment Success',
  CLEAR_BOARD_SOURCE_ASSORTMENT = '[Showcases] Clear Board Source Assortment',
  CLEAR_BOARD_SOURCE_ASSORTMENT_SUCCESS = '[Showcases] Clear Board Source Assortment Success',

  COPY_BOARD = '[Board] Copy Board',
  COPY_BOARD_SUCCESS = '[Board] Copy Board Success',
  COPY_BOARD_FAILURE = '[Board] Copy Board Failure',

  ASYNC_COPY_BOARD = '[Board] Async Copy Board',
  ASYNC_COPY_BOARD_SUCCESS = '[Board] Async Copy Board Success',

  LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG = '[Boards] Load Current Document Generation Config',
  LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG_SUCCESS = '[Boards] Load Current Document Generation Config Success',
  LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG_FAILURE = '[Boards] Load Current Document Generation Config Failure',
  UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG = '[Boards] Update Current Document Generation Config',
  UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG_SUCCESS = '[Boards] Update Current Document Generation Config Success',
  UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG_FAILURE = '[Boards] Update Current Document Generation Config Failure',
  CLEAR_CURRENT_DOCUMENT_GENERATION_CONFIG = '[Boards] Clear Current Document Generation Config',
}

////////////////////////////////////////////////////////////////////////////////
export const clearBoards = createAction(BoardsActionTypes.CLEAR_BOARDS);
export const clearBoard = createAction(BoardsActionTypes.CLEAR_BOARD);
export const loadBoards = createAction(BoardsActionTypes.LOAD_BOARDS);

export const loadBoardsSuccess = createAction(BoardsActionTypes.LOAD_BOARDS_SUCCESS, props<{ data: Array<any> }>());

export const loadBoardsFailure = createAction(BoardsActionTypes.LOAD_BOARDS_FAILURE, props<{ error: any }>());

export const createBoard = createAction(BoardsActionTypes.CREATE_BOARD, props<{ board: Board }>());

export const createBoardSuccess = createAction(BoardsActionTypes.CREATE_BOARD_SUCCESS, props<{ board: Board }>());

export const createBoardFailure = createAction(BoardsActionTypes.CREATE_BOARD_FAILURE, props<{ error: any }>());

export const updateBoard = createAction(BoardsActionTypes.UPDATE_BOARD, props<{ id: string; changes: Board }>());

export const updateBoardSuccess = createAction(
  BoardsActionTypes.UPDATE_BOARD_SUCCESS,
  props<{ id: string; changes: Board }>(),
);

export const updateBoardFailure = createAction(BoardsActionTypes.UPDATE_BOARD_FAILURE, props<{ error: any }>());

export const deleteBoard = createAction(BoardsActionTypes.DELETE_BOARD, props<{ board: Board }>());

export const deleteBoardSuccess = createAction(BoardsActionTypes.DELETE_BOARD_SUCCESS, props<{ board: Board }>());

export const deleteBoardFailure = createAction(BoardsActionTypes.DELETE_BOARD_FAILURE, props<{ error: any }>());

export const loadCurrentBoard = createAction(BoardsActionTypes.LOAD_CURRENT_BOARD, props<{ id: string }>());

export const loadCurrentBoardSuccess = createAction(
  BoardsActionTypes.LOAD_CURRENT_BOARD_SUCCESS,
  props<{ board: Board }>(),
);

export const loadCurrentBoardFailure = createAction(
  BoardsActionTypes.LOAD_CURRENT_BOARD_FAILURE,
  props<{ error: any }>(),
);

export const remoteUserMouseMoved = createAction(BoardsActionTypes.REMOTE_USER_MOUSE_MOVED, props<{ data: any }>());

export const removeRemoteUserFromMouseSession = createAction(
  BoardsActionTypes.REMOVE_REMOTE_USER_FROM_MOUSE_SESSION,
  (userSession: UserSession) => ({ userSession }),
);

export const setItemsUsedInBoard = createAction(
  BoardsActionTypes.SET_ITEMS_USED_IN_BOARD,
  props<{ itemsUsedInBoard: Array<Item> }>(),
);

export const clearItemsUsedInBoard = createAction(BoardsActionTypes.CLEAR_ITEMS_USED_IN_BOARD);

export const setShowSourceAssortmentWarning = createAction(
  BoardsActionTypes.SET_SHOW_SOURCE_ASSORTMENT_WARNING,
  props<{ showSourceAssortmentWarning: boolean }>(),
);

export const assignBoardAssortment = createAction(
  BoardsActionTypes.ASSIGN_BOARD_SOURCE_ASSORTMENT,
  props<{ id: string; sourceAssortmentId: string }>(),
);

export const assignBoardAssortmentSuccess = createAction(
  BoardsActionTypes.ASSIGN_BOARD_SOURCE_ASSORTMENT_SUCCESS,
  props<{ board: any }>(),
);

export const clearBoardSourceAssortment = createAction(
  BoardsActionTypes.CLEAR_BOARD_SOURCE_ASSORTMENT,
  props<{ id: string }>(),
);

export const clearBoardSourceAssortmentSuccess = createAction(
  BoardsActionTypes.CLEAR_BOARD_SOURCE_ASSORTMENT_SUCCESS,
  props<{ board: any }>(),
);

export const copyBoard = createAction(BoardsActionTypes.COPY_BOARD, props<{ name: string; sourceId: string }>());

export const copyBoardSuccess = createAction(BoardsActionTypes.COPY_BOARD_SUCCESS, props<{ board: Board }>());

export const copyBoardFailure = createAction(BoardsActionTypes.COPY_BOARD_FAILURE, props<{ error: any }>());

export const asyncCopyBoard = createAction(
  BoardsActionTypes.ASYNC_COPY_BOARD,
  props<{ name: string; sourceId: string }>(),
);

export const asyncCopyBoardSuccess = createAction(
  BoardsActionTypes.ASYNC_COPY_BOARD_SUCCESS,
  props<{ jobId: string; path: string }>(),
);

export const loadCurrentDocumentGenerationConfig = createAction(
  BoardsActionTypes.LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG,
  props<{ id: string }>(),
);

export const loadCurrentDocumentGenerationConfigSuccess = createAction(
  BoardsActionTypes.LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG_SUCCESS,
  props<{ documentGenerationConfig: any }>(),
);

export const loadCurrentDocumentGenerationConfigFailure = createAction(
  BoardsActionTypes.LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG_FAILURE,
  props<{ error: any }>(),
);

export const updateCurrentDocumentGenerationConfig = createAction(
  BoardsActionTypes.UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG,
  props<{ changes: any }>(),
);

export const updateCurrentDocumentGenerationConfigSuccess = createAction(
  BoardsActionTypes.UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG_SUCCESS,
  props<{ documentGenerationConfig: any }>(),
);

export const updateCurrentDocumentGenerationConfigFailure = createAction(
  BoardsActionTypes.UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentDocumentGenerationConfig = createAction(
  BoardsActionTypes.CLEAR_CURRENT_DOCUMENT_GENERATION_CONFIG,
);
