import { Component, Input, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { ViewIFrameElementModalComponent } from '../../document-iframe/view-iframe-element-modal/view-iframe-element-modal.component';

@Component({
  selector: 'app-property-configurator-iframe',
  templateUrl: './property-configurator-iframe.component.html',
  styleUrls: ['./property-configurator-iframe.component.scss'],
})
export class PropertyConfiguratorIframeComponent {
  public elementType;
  @Input() selectedElement: any;
  @Input() resetDrag: any;
  @ViewChild(CdkDrag) cdkDrag: CdkDrag;

  constructor(private matDialog: MatDialog) {}

  openIframe() {
    if (this.selectedElement.embedInfo.isIframe) {
      const config = {
        data: { element: this.selectedElement },
        maxWidth: '95vw',
        width: '1350px',
        height: '800px',
        autoFocus: true,
      };
      this.matDialog.open(ViewIFrameElementModalComponent, config);
    } else {
      window.open(this.selectedElement.url, '_blank');
    }
  }
}
