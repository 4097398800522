import { Injectable } from '@angular/core';
import { Entities, EntityReference, Types } from '@contrail/sdk';
import { tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { ProjectItemService } from '@common/projects/project-item.service';
import { DocumentElement, DocumentElementPropertyBindingHandler, StyleDefinition } from '@contrail/documents';
import { ItemComponentBuilder } from '../document-component/item-component-builder';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { ObjectUtil } from '@contrail/util';
import { ItemService } from '@common/items/item.service';
import { TypeManagerService } from '@common/types/type-manager.service';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../document-store';
import { ANNOTATION_IMG_SIZE, ANNOTATION_PADDING_Y } from '../document-annotation/document-annotation-service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { AssortmentsActions } from '@common/assortments/assortments-store';

@Injectable({
  providedIn: 'root',
})

/**
 * This service is a helper service for items and component document elements.
 * Important: do not inject DocumentService or orther service that injects DocumentService
 * to avoid circular dependency.
 */
export class DocumentItemService {
  private annotatedElements: any[];
  private currentProjectId;
  private itemContextFeatureActive = false;
  private assignItemToComponentConditions: any[] = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private projectItemService: ProjectItemService,
    private itemService: ItemService,
    private typeManagerService: TypeManagerService,
  ) {
    this.store.select(DocumentSelectors.annotatedElements).subscribe((annotatedElements) => {
      this.annotatedElements = annotatedElements;
    });
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags: FeatureFlag[]) => {
      if (flags.map((x) => x.featureName).includes(Feature.ITEM_CONTEXT)) {
        this.itemContextFeatureActive = true;
      }
    });
    this.store
      .select(DocumentSelectors.assignItemToComponentConditions)
      .subscribe((assignItemToComponentConditions) => {
        this.assignItemToComponentConditions = assignItemToComponentConditions;
      });
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();
  }

  /**
   * Rebind model bindings for component @elements relative to the current source assortment.
   * If @element is in source assortment - use source assortment item data.
   * If @element is not in source assortment - rebind to not have assortment-item model binding.
   * If @element is in current project - use current project item data.
   * If @element is in current project - rebind to not have project-item model binding.
   * @param elements
   * @returns
   */
  public async setComponentDataRelativeToSource(elements: DocumentElement[]): Promise<DocumentElement[]> {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));

    const itemEntityMap: Map<string, EntityReference> = new Map(); // element id: item entity
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const item = new EntityReference(element?.modelBindings?.item);
      if (item) {
        itemEntityMap.set(element.id, item);
      }
    }
    const itemIds = [...new Set(Array.from(itemEntityMap.values()).map((item) => item.id))];
    const itemDataFromSource = await this.itemService.getItemDataAndSetRelativeToSource(itemIds);

    const newElements = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const item = itemEntityMap.get(element.id);
      const itemData = itemDataFromSource.find((s) => s.id === item.id);
      if (itemData) {
        const entity = AssortmentUtil.convertItemData(itemData);
        newElements.push(await this.buildAndFormatComponentElement(entity, element, element));
      }
    }

    this.store.dispatch(setLoading({ loading: false, message: '' }));
    return newElements;
  }

  /**
   * Format data.
   * Build component.
   * Apply properties and options from @fromElement
   * @param entity
   * @param options
   * @param fromElement
   * @returns
   */
  public async buildAndFormatComponentElement(
    entity: any,
    options?: DocumentElement,
    fromElement?: DocumentElement,
    skipProjectItemAssignment?: boolean,
  ): Promise<DocumentElement> {
    if (entity?.entityType === 'item' && this.itemContextFeatureActive && !skipProjectItemAssignment) {
      if (!entity.projectItem?.id && !entity.item?.projectItem?.id) {
        // tries to find project-item for the current project
        const projectItem = await this.projectItemService.getProjectItem(entity.id);
        if (projectItem && !projectItem.isInactive) {
          if (entity.item) {
            entity.item.projectItem = projectItem;
          }
          entity.projectItem = projectItem;
        }
      }
    }
    if (entity) {
      await this.formatData(entity);
    }
    this.addPropertyBindingsForComponentInteraction(options);
    let element;
    if (!entity) {
      element = await ItemComponentBuilder.buildEmptyComponent(options);
    } else if (entity.entityType === 'assortment-item') {
      element = await ItemComponentBuilder.buildComponent(
        entity,
        this.projectItemService,
        options,
        !this.itemContextFeatureActive,
      );
    } else {
      element = await ItemComponentBuilder.buildComponent(
        entity.item || entity,
        this.projectItemService,
        options,
        !this.itemContextFeatureActive,
      );
    }
    this.bindPropertiesByDocumentComponent(element, fromElement, entity, entity?.item || entity);
    return element;
  }

  /**
   * Format data in @entity
   * Entity can be ItemData or stand alone entity
   * @param entity
   */
  public async formatData(entity: any) {
    // Format data based on type. Type Definition should already be cached at this point by the component editor.
    if (entity?.item?.typeId) {
      await this.typeManagerService.formatObjectByType(entity.item);
    }
    if (entity?.projectItem?.typeId) {
      await this.typeManagerService.formatObjectByType(entity.projectItem);
    }
    if (entity.typeId) {
      // check for assortmentItem because board might not have this
      await this.typeManagerService.formatObjectByType(entity);
    }
  }

  /**
   * Bind properties from @fromElement to @newElement
   * @param newElement
   * @param fromElement
   * @param entity
   * @param viewable
   * @returns
   */
  private bindPropertiesByDocumentComponent(
    newElement: DocumentElement,
    fromElement: DocumentElement,
    entity: any,
    viewable: any,
  ): DocumentElement {
    if (fromElement) {
      const templateElements = ObjectUtil.cloneDeep(fromElement.elements);
      if (entity) {
        let model: any = {};
        if (entity.entityType === 'assortment-item') {
          model = { viewable, item: entity.item, assortmentItem: { ...entity } };
          if (entity.projectItem?.id) {
            model.projectItem = entity.projectItem;
          }
        } else if (entity.entityType === 'item') {
          model = { viewable, item: entity.item || entity };
          if (entity.projectItem?.id) {
            model.projectItem = entity.projectItem;
          }
        }

        const newImageElement = newElement.elements.find((el) => el.type === 'image');
        const templateImageElement = templateElements.find((el) => el.type === 'image');
        if (newImageElement && templateImageElement) {
          templateImageElement.propertyBindings = newImageElement.propertyBindings;
        }
        DocumentElementPropertyBindingHandler.bindPropertiesToElement(newElement, model);
        DocumentElementPropertyBindingHandler.bindPropertiesToElements(templateElements, model);
      }

      newElement.elements = templateElements;
      newElement.style = ObjectUtil.cloneDeep(fromElement.style);
      newElement.size = ObjectUtil.cloneDeep(fromElement.size);
      return newElement;
    }
    return newElement;
  }

  public async createEmptyItemFamilies(quantity: number = 1) {
    const itemsToCreate = [];
    const type = await new Types().getByRootAndPath({ root: 'item', path: 'item' });
    for (let i = 0; i < quantity; i++) {
      let itemFamilyToCreate = {
        typeId: type.id,
        name: `Item ${uuid()}`,
      };
      itemsToCreate.push(itemFamilyToCreate);
    }
    let itemFamilies = await new Entities().batchCreate({ entityName: 'item', objects: itemsToCreate });
    const updateNames = itemFamilies.map((itemFamily) => ({
      id: itemFamily.id,
      changes: { name: `Item ${itemFamily.itemNumber}` },
    }));

    itemFamilies = await new Entities().batchUpdate({ entityName: 'item', objects: updateNames });
    const projectItemsToUpsert = [];
    itemFamilies.forEach((item) => {
      projectItemsToUpsert.push({ id: item.id, changes: {} });
    });
    const projectItems = await this.projectItemService.batchUpsert(projectItemsToUpsert);
    itemFamilies.forEach((itemFamily) => {
      itemFamily.projectItem = projectItems.find((projectItem) => projectItem.itemId === itemFamily.id);
      itemFamily.entityType = 'item';
    });
    return itemFamilies;
  }

  public async createEmptyItemFamily() {
    const itemFamilies = await this.createEmptyItemFamilies(1);
    return itemFamilies[0];
  }

  public async createEmptyItemOptions(quantity: number = 1, itemFamily: any = null, optionData: any = {}) {
    const itemsToCreate = [];
    const type = await new Types().getByRootAndPath({ root: 'item', path: 'item' });
    for (let i = 0; i < quantity; i++) {
      let itemOptionToCreate = {
        optionGroup: 'color',
        typeId: type.id,
        name: `Item ${uuid()}`,
        optionName: optionData.optionName || `Option ${uuid()}`,
      };
      if (itemFamily) {
        itemOptionToCreate = Object.assign(itemOptionToCreate, { itemFamilyId: itemFamily.itemFamilyId });
      }
      itemsToCreate.push(itemOptionToCreate);
    }

    let items = await new Entities().batchCreate({ entityName: 'item', objects: itemsToCreate });
    let itemFamilies = items.filter((item) => item.roles.includes('family'));
    let itemOptions = items.filter((item) => item.roles.includes('option'));
    let updateNames = itemOptions.map((itemOption) => ({
      id: itemOption.id,
      changes: { optionName: `Option ${itemOption.itemNumber}` },
    }));
    updateNames = updateNames.concat(
      itemFamilies.map((family) => ({ id: family.id, changes: { name: `Item ${family.itemNumber}` } })),
    );

    items = await new Entities().batchUpdate({ entityName: 'item', objects: updateNames });
    itemFamilies = items.filter((item) => item.roles.includes('family'));
    itemOptions = items.filter((item) => item.roles.includes('option'));

    const projectItemsToUpsert = [];
    itemOptions.forEach((item) => {
      projectItemsToUpsert.push({ id: item.id, changes: {} });
    });
    const projectItems = await this.projectItemService.batchUpsert(projectItemsToUpsert);
    itemOptions.forEach((itemOption) => {
      itemOption.projectItem = projectItems.find((projectItem) => projectItem.itemId === itemOption.id);
      itemOption.entityType = 'item';
      let itemFamily = itemFamilies.find((family) => family.id === itemOption.itemFamilyId);
      if (itemFamily) {
        itemOption.itemFamily = itemFamily;
        itemOption.name = itemOption.itemFamily.name;
      }
    });
    return itemOptions;
  }

  public async createEmptyItemOption(itemFamily: any = null, optionData: any = {}) {
    const itemOptions = await this.createEmptyItemOptions(1, itemFamily, optionData);
    return itemOptions[0];
  }

  /**
   * Builds the set of document elements that should go into
   * an edited component, based on what elements are currently
   * enabled/selected in the component editor.
   * Handles spacing of text elements based on font size.
   * @param propertyElements: The list of property elements available for selection
   */
  public updateSizeAndPositionForPropertyElements(propertyElements: any[], componentElement: DocumentElement): any {
    const newElements = [];
    let lastYPosition = 0;
    let width = 200;
    const imageElements = propertyElements.filter((element) => {
      return element.type === 'image';
    });
    if (imageElements.length > 0) {
      if (imageElements[0].size) {
        width = imageElements[0].size.width;
      } else {
        imageElements[0].size = { width: 200, height: 200 };
      }
    }
    propertyElements.forEach((element, index) => {
      // Skip if not enabled/selected
      if (element.enabled === false) {
        return;
      }
      element.position = {
        x: 0,
        y: lastYPosition,
      };
      lastYPosition = lastYPosition + this.getYMargin(element);
      if (element.type === 'text') {
        if (!element.style?.font) {
          element.style = {
            ...element.style,
            font: { size: 8 },
          };
        }
        if (!element?.size) {
          element.size = {};
        }

        element.size.height = element.style.font.size + 6;
      } else if (element.type === 'annotation') {
        if (!element.style?.font) {
          element.style = {
            ...element.style,
            font: { size: ANNOTATION_IMG_SIZE },
          };
        }
        if (index === 0) {
          element.position = {
            x: 0,
            y: -15,
          };
          lastYPosition = element.position.y;
        }
        const annotationHeight = this.calculateAnnotationHeight(element, componentElement, width);
        lastYPosition = lastYPosition + annotationHeight;
        if (annotationHeight === 0 && index === 0) {
          lastYPosition = lastYPosition + ANNOTATION_IMG_SIZE;
        }
      }
      element.size.width = width;
      newElements.push(element);
    });
    return newElements;
  }

  private getYMargin(element: DocumentElement) {
    let marginY = 11;
    if (element.type === 'image') {
      marginY = element.size.height;
    } else if (element.type === 'text') {
      if (element.size && element.size.height) {
        marginY = element.size.height;
      } else if (element.style && element.style.font) {
        marginY = element.style.font.size + 6;
      }
    }
    return marginY;
  }

  public getDefaultTextFormat(element) {
    let style: any = {};
    if (element.type === 'text') {
      style = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: element?.propertyBindings?.text === 'item.optionName' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.8)',
        font: { family: 'Roboto', size: 8, weight: 'normal', style: 'normal' },
        text: { align: 'left', decoration: 'none' },
      };
    }
    return style;
  }

  public clearTextFormat(propertyElements: any[]) {
    for (let i = 0; i < propertyElements?.length; i++) {
      const element = propertyElements[i];
      element.style = this.getDefaultTextFormat(element);
    }
    return propertyElements;
  }

  public static isItemComponet(element: DocumentElement): boolean {
    return element?.type === 'component' && element?.modelBindings?.item;
  }

  private calculateAnnotationHeight(
    element: DocumentElement,
    componentElement: DocumentElement,
    width: number,
  ): number {
    const annotationSize = element.style?.font?.size || ANNOTATION_IMG_SIZE;
    let height = annotationSize + ANNOTATION_PADDING_Y;
    let xPos = 0;
    const annotatedElement = this.annotatedElements.find((elem) => elem.id === componentElement.id);
    if (!annotatedElement || element.isHidden) {
      return height;
    }
    const annotations = annotatedElement.annotations.filter((annotation) => annotation.category === 'property');

    if (annotations.length > 0) {
      let count = 0;
      annotations?.forEach((annotation) => {
        if ((count + 1) * annotationSize * 1.25 > width) {
          count = 0;
          xPos = -width * 0.5;
          height = height + annotationSize + ANNOTATION_PADDING_Y;
        }
        xPos = xPos + annotationSize * 1.25;
        count++;
      });
    }
    return height;
  }

  public async copyDocumentItem(element: DocumentElement) {
    if (!element?.modelBindings?.item) {
      return;
    }

    const modelBindingOverrides = this.getCopyDocumentItemModelBindingOverrides(element);

    try {
      const copyOfElement = await new Entities().batchCreate({
        entityName: 'document-element',
        objects: [{ copyId: element.id, modelBindings: modelBindingOverrides }],
        suffix: 'deep-copy',
      });

      return copyOfElement?.[0];
    } catch (error) {
      console.error(`Error creating copy of document-element ${element.id}`, error);
    }
  }

  getCopyDocumentItemModelBindingOverrides(element: DocumentElement): {
    project?: string;
    assortmentItem?: string;
    assortment?: string;
  } {
    const modelBindingOverrides: any = {};

    const projectId = element?.modelBindings?.projectItem
      ? element?.modelBindings?.projectItem?.split('project-item:')?.[1]?.split(':')?.[0]
      : null;

    if (projectId && projectId !== this.currentProjectId) {
      modelBindingOverrides.project = `project:${this.currentProjectId}`;
      modelBindingOverrides.assortment = null;
      modelBindingOverrides.assortmentItem = null;
    }

    if (!projectId && (element?.modelBindings?.assortmentItem || element?.modelBindings?.assortment)) {
      modelBindingOverrides.assortment = null;
      modelBindingOverrides.assortmentItem = null;
    }

    return modelBindingOverrides;
  }

  public performComponentEntityUpdates(itemChanges: any[], entityType: string = 'item') {
    const map = new Map();
    itemChanges.forEach((itemChange) => {
      map.set(itemChange.changeDefinition.entityId, {
        id: itemChange.changeDefinition.updateEntityId || itemChange.changeDefinition.entityId,
        changes: itemChange.changeDefinition.entityData,
      });
      this.store.dispatch(
        AssortmentsActions.syncBackingAssortmentItems({
          id: itemChange.changeDefinition.entityId,
          changes: itemChange.changeDefinition.entityData,
        }),
      );
    });
    return new Entities().batchUpdate({ entityName: entityType, objects: [...map.values()] });
  }

  private addPropertyBindingsForComponentInteraction(element: DocumentElement) {
    if (this.assignItemToComponentConditions.length > 0) {
      if (!element.propertyBindings) {
        element.propertyBindings = {};
      }
      this.assignItemToComponentConditions.forEach((condition) => {
        element.propertyBindings[`entityData.${condition.property}`] = `item.${condition.property}`;
      });
    }
  }
}
