import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-property-configurator-element-icon',
  templateUrl: './property-configurator-element-icon.component.html',
  styleUrls: ['./property-configurator-element-icon.component.scss'],
})
export class PropertyConfiguratorElementIconComponent implements OnChanges {
  public isTextTool = false;
  public isDynamicText = false;
  @Input() elementType: any;
  @Input() selectedElements: any;
  @Input() resetDrag: any;
  @ViewChild(CdkDrag) cdkDrag: CdkDrag;
  shapes = [
    'rectangle',
    'circle',
    'triangle',
    'diamond',
    'star',
    'heart',
    'double_arrow',
    'right_arrow',
    'rhombus',
    'cloud',
  ];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetDrag && !changes.resetDrag.firstChange) {
      this.cdkDrag?.reset();
    }
    this.isTextTool = false;
    this.isDynamicText = false;
    if (this.selectedElements.length === 1 && this.selectedElements[0].type === 'text') {
      if (this.selectedElements[0].propertyBindings && this.selectedElements[0].propertyBindingsMetaData) {
        this.isDynamicText = true;
      } else if (this.selectedElements[0].isTextTool) {
        this.isTextTool = true;
      }
    }
  }
}
