<div class="header">
  <div class="title">Content</div>
  <div (click)="handleClose()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div tabindex="1" #dataPane class="chooser">
  <!-- <app-search-bar #documentSearch [placeholder]="'Search'"  #searchBar class="m-2"></app-search-bar> -->
  <div class="data-pane" *ngIf="(content$ | async)?.length">
    <virtual-scroller [style.height]="resultsHeight" #scroll [items]="content$ | async" class="narrow-scroll">
      <app-chooser-entity-card
        class="data-row"
        [entityType]="'content'"
        *ngFor="let entity of scroll.viewPortItems"
        [entity]="entity"
        data-test="chooser-card"
        [draggable]="true"
        [showDetailIcon]="true"
        (detailsClicked)="handleDetailsClicked($event)"
        [id]="entity.id"
        (dragstart)="startDrag($event, entity)"
      ></app-chooser-entity-card>
    </virtual-scroller>
  </div>
</div>
