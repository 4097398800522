import { DashboardWidgetDefinition } from './dashboard-interfaces';

export const ASSORTMENT_SUMMARY_DASHBOARD_WIDGET: DashboardWidgetDefinition = {
  identifier: 'assortment_summary',
  isNative: true,
  name: 'Assortment Summary',
};

export const ASSORTMENT_SUMMARY_CHART_DASHBOARD_WIDGET: DashboardWidgetDefinition = {
  identifier: 'assortment_summary_chart',
  isNative: true,
  name: 'Assortment Summary Chart',
};

export const ASSORTMENT_ANNOTATION_DASHBOARD_WIDGET: DashboardWidgetDefinition = {
  identifier: 'assortment_annotation',
  isNative: true,
  name: 'Assortment Annotation',
};

export const REGISTRY: Array<DashboardWidgetDefinition> = [ASSORTMENT_SUMMARY_DASHBOARD_WIDGET];
