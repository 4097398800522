import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DocumentCreateItemsService } from '../document-create-items.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-create-items',
  templateUrl: './create-items.component.html',
  styleUrls: ['./create-items.component.scss'],
  animations: [
    trigger('slideInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [animate('200ms ease-out', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class DocumentCreateItemsComponent implements OnInit {
  @Input() minValue = 1;
  @Input() maxValue = 10;
  @Input() defaultValue = 1;
  @Input() show = false;
  countInput: UntypedFormControl;
  error = false;
  isFocused = false;

  constructor(private documentCreateItemsService: DocumentCreateItemsService) {}

  ngOnInit(): void {
    this.countInput = new UntypedFormControl(this.defaultValue);
    this.countInput.valueChanges.subscribe((value) => {
      if (value < this.minValue || value > this.maxValue) {
        this.error = true;
      } else {
        this.error = false;
      }
      if (!this.error) {
        this.documentCreateItemsService.setCreateItemCount(this.countInput.value);
      } else {
        this.documentCreateItemsService.setCreateItemCount(0);
      }
    });
  }

  handleIncreaseCount() {
    this.countInput.setValue(this.countInput.value + 1);
  }

  handleDecreaseCount() {
    this.countInput.setValue(this.countInput.value - 1);
  }

  setValue(value: number) {
    this.countInput.setValue(value);
  }
}
