import {
  DocumentElement,
  DocumentElementFactory,
  PositionDefinition,
  ScaleTransformation,
  SizeDefinition,
  ViewBox,
} from '@contrail/documents';
import { CanvasDocument } from '../canvas-document';
import { v4 as uuid } from 'uuid';
import {
  DRAG_DIRECTIONS,
  MouseTarget,
  SelectionWidgetRenderer,
} from '../renderers/selection-widget-renderer/selection-widget-renderer';
import { ElementSelectionWidgetRenderer } from '../renderers/selection-widget-renderer/element-selection-widget-renderer';
import { LineSelectionWidgetRenderer } from '../renderers/selection-widget-renderer/line-selection-widget-renderer';
import { ComponentSelectionWidgetRenderer } from '../renderers/selection-widget-renderer/component-selection-widget-renderer';
import { RotationWidgetRenderer } from '../renderers/rotation-widget-renderer/rotation-widget-renderer';
import { RotationHelper } from '../renderers/rotation-widget-renderer/rotation-helper';
import { CanvasUtil } from '../canvas-util';
import { ObjectUtil } from '@contrail/util';
import { FrameSelectionWidgetRenderer } from '../renderers/selection-widget-renderer/frame-selection-widget-renderer';
import { DrawOptions, DrawParams } from '../renderers/canvas-renderer';
import { HighlightWidgetRenderer } from '../renderers/highlight-widget-renderer/highlight-widget-renderer';
import { WARNING_OPACITY } from './image/canvas-image-loader';
import { CanvasElementFactory } from './canvas-element-factory';
import { GroupSelectionWidgetRenderer } from '../renderers/selection-widget-renderer/group-selection-widget-renderer';
import { OutlineWidgetRenderer } from '../renderers/outline-widget-renderer/outline-widget-renderer';
import { ImageElement } from '../components/image-element/image-element';
import { EditorFormatter } from '../components/editor/editor-formatter';

export abstract class CanvasElement {
  public id; // UNIQUE ID (UUID)

  public isSelected = false;
  public isDeleted = false;
  public isVisible = true;
  public isHighlighted: boolean | string = false; // false or color used to highlight
  public isOutlined: boolean | string = false; // false or stroke color
  public isAsync = false;

  public innerElements?: Array<CanvasElement> = null;
  public annotations: any[] = [];
  public componentType: 'item' | 'color' | null = null;

  public isInFrame: string | boolean = false; // frame id if frame member and frame is selected or false
  public isInFrameMask: string | boolean = false; // frame id or false
  public isInGroup: string | boolean = false; // group id or false
  public isInMask: string | boolean = false;
  public isMask: boolean = false;

  public isRotationEnabled = true;
  public isResizeEnabled = true;
  public isDragEnabled = true;
  public isCropEnabled = false;

  public selectionWidgetRenderer: SelectionWidgetRenderer;
  public rotationWidgetRenderer: RotationWidgetRenderer;
  private highlightWidgetRenderer: HighlightWidgetRenderer;
  private outlineWidgetRenderer: OutlineWidgetRenderer;

  public isPartOfComponent: string | boolean = false; // component element id
  public isImageError = false;
  public isTextEditable = false;

  public TEXT_PADDING = 0;
  public TEXT_PLACEHOLDER = '';
  public PADDING_LEFT = 0;
  public PADDING_RIGHT = 0;
  public PADDING_TOP = 0;
  public PADDING_BOTTOM = 0;

  public DEFAULT_BACKGROUND_COLOR = 'rgba(0,0,0,0)';
  public DEFAULT_BORDER_SIZE = 1;
  public DEFAULT_BORDER_COLOR = '#616161';
  public DEFAULT_BORDER_RADIUS = 0;
  public DEFAULT_FONT_SIZE = 11;
  public VALID_PROPERTIES = ['style.backgroundColor', 'style.border'];
  public EDITOR_INLINE_STYLE_COMMANDS = [];
  public EDITOR_STYLE_COMMANDS = [];
  public USE_OUTER_EDGE = false;

  public onSelect() {}
  public onDeselect() {}
  public onChange() {}
  public onResizeStart() {}
  public onResize() {
    this.onChange();
  }

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    this.id = this.elementDefinition.id || uuid();
    this.elementDefinition.id = this.id;
    if (this.interactable) {
      this.highlightWidgetRenderer = new HighlightWidgetRenderer(this.canvasDocument, this);
      this.outlineWidgetRenderer = new OutlineWidgetRenderer(this.canvasDocument, this);
      this.rotationWidgetRenderer = new RotationWidgetRenderer(this.canvasDocument, this);
      if (this.elementDefinition.type === 'line') {
        this.selectionWidgetRenderer = new LineSelectionWidgetRenderer(this.canvasDocument, this);
      } else if (this.elementDefinition.type === 'component') {
        this.selectionWidgetRenderer = new ComponentSelectionWidgetRenderer(this.canvasDocument, this);
      } else if (this.elementDefinition.type === 'frame') {
        this.selectionWidgetRenderer = new FrameSelectionWidgetRenderer(this.canvasDocument, this);
      } else if (this.elementDefinition.type === 'group') {
        this.selectionWidgetRenderer = new GroupSelectionWidgetRenderer(this.canvasDocument, this);
      } else {
        this.selectionWidgetRenderer = new ElementSelectionWidgetRenderer(this.canvasDocument, this);
      }
    }
  }

  /**
   * Draw elements in the coordinate system relative to its center -
   * where center is 0,0.
   * @param ctx
   * @param opts
   */
  protected abstract draw(ctx, opts: DrawParams, options?: DrawOptions): { height: number };
  public drawClip(ctx, opts) {}
  public clip(ctx, opts) {}
  public addPath(ctx, opts) {}
  public getPath(opts): Path2D {
    return;
  }
  public getPoints({ x, y, width, height }): any[] {
    return;
  }
  public preload(options?: DrawOptions): Promise<ImageElement | ImageElement[]> {
    return;
  }

  /**
   * Auto adjust size after text style is applied
   * @param param0
   * @returns
   */
  public autoAdjustSize({ force, text }: { force?: boolean; text?: string } = { force: false }): DocumentElement {
    return null;
  }

  public isPropertyValid(property) {
    return this.VALID_PROPERTIES.indexOf(property) !== -1;
  }

  public applyClip(ctx, { x, y, width, height }, options?: DrawOptions) {
    const maskElement = this.canvasDocument.state.maskState.getMask(this.isInMask as string)?.element;
    // Mask image if the mask is currently not being edited
    if (maskElement) {
      if (this.canvasDocument?.state?.maskState?.isEditingMask(this.isInMask as string)) {
        ctx.save();
        this.transform(ctx, { x, y, width, height });
        this.draw(ctx, { x, y, width, height, imageOpacity: 0.5 });
        ctx.restore();
      }
      const dimensions = maskElement.getDimensions(options);
      maskElement.clip(ctx, dimensions);
    }
  }

  public applyFrameClip(ctx, { x, y, width, height }, options?: DrawOptions) {
    const frameElement = this.canvasDocument.getCanvasElementById(this.isInFrameMask as string);
    if (frameElement && this.elementDefinition.type !== 'frame') {
      const frameBox = frameElement.getBoundingClientRect();
      const elementBox = this.getBoundingClientRectRotated();
      if (
        elementBox.x < frameBox.x ||
        elementBox.y < frameBox.y ||
        elementBox.x + elementBox.width > frameBox.x + frameBox.width ||
        elementBox.y + elementBox.height > frameBox.y + frameBox.height
      ) {
        const dimensions = frameElement.getDimensions(options);
        frameElement.clip(ctx, dimensions);
      }
    }
  }

  public drawUncropped(ctx) {
    ctx.save();
    const uncroppedDimensions = this.getDimensions({ uncroppedDimensions: true });
    this.transform(ctx, uncroppedDimensions);
    this.draw(ctx, { ...uncroppedDimensions, imageOpacity: 0.5 }, { drawUncropped: true });
    ctx.restore();
  }

  public getRenderedHeight(ctx?, width?, options?: DrawOptions): number {
    return this.getSize(options)?.height;
  }

  public handlesScaleInternally() {
    return this.elementDefinition.isTextTool || this.elementDefinition.type === 'table';
  }

  public drawElement(ctx, options?: DrawOptions) {
    options = Object.assign(options || {}, { skipScale: this.handlesScaleInternally() ? false : true });
    if (this.isItemComponent() && this?.innerElements?.length > 0) {
      const propertyAnnotations = this.annotations?.filter((annotation) => annotation.category === 'property');
      const annotationElement = this.innerElements.find((element) => element.elementDefinition.type === 'annotation');
      if (annotationElement) {
        annotationElement.setAnnotations(propertyAnnotations);
      }
    }
    const opts = this.getDimensions(options);
    ctx.save();

    const isCropping = this.canvasDocument.isCropping(this.elementDefinition.id);
    if (isCropping) {
      this.drawUncropped(ctx);
    }

    if (this.isInMask) {
      // Clip before transforming the image
      this.applyClip(ctx, opts, options);
    }

    if (this.isInFrameMask) {
      // Clip content to frame
      this.applyFrameClip(ctx, opts, options);
    }

    this.transform(ctx, opts);

    if (this?.innerElements?.length > 0) {
      let lastY = null;
      let hiddenImageHeight = 0;
      for (let i = 0; i < this.innerElements.length; i++) {
        const innerElement = this.innerElements[i];
        const innerOpts = innerElement.getDimensions(options);
        if (
          this.componentType === 'color' &&
          innerElement.elementDefinition.type === 'rectangle' &&
          !innerElement.elementDefinition?.propertyBindings
        ) {
          innerOpts.width = opts.width;
          innerOpts.height = opts.height;
        }
        if (innerElement.elementDefinition.type === 'annotation') {
          innerOpts.width = opts.width;
        }
        if (innerElement?.elementDefinition?.isHidden) {
          if (innerElement.elementDefinition.type === 'image') {
            hiddenImageHeight = innerOpts.height;
          }
        } else {
          if (this.hasWarning()) {
            innerOpts.imageOpacity = WARNING_OPACITY;
          }
          innerOpts.y = lastY == null ? innerOpts.y - opts.height * 0.5 : lastY;
          innerOpts.x = innerOpts.x - opts.width * 0.5;
          if (hiddenImageHeight && lastY == null) {
            innerOpts.y = innerOpts.y - hiddenImageHeight;
          }
          const result = innerElement.draw(ctx, innerOpts, options);
          if (['text', 'annotation'].includes(innerElement.elementDefinition.type) || lastY != null) {
            if (innerElement.elementDefinition.type === 'annotation' && i === 0) {
              lastY = null;
            } else {
              let yDelta = result?.height;
              yDelta = yDelta === undefined ? innerOpts.height : yDelta;
              lastY = innerOpts.y + yDelta;
            }
          }
        }
      }
    }

    const params = Object.assign(opts, options);
    if (this.isMask) {
      if (this.canvasDocument?.state?.maskState?.isEditingMask(this.elementDefinition.id)) {
        this.drawClip(ctx, params);
      }
    } else {
      this.draw(ctx, opts, options);
    }
    ctx.restore();
  }

  protected drawBorderContainer(ctx, x, y, width, height, options?: DrawOptions) {
    const borderColor = this.elementDefinition.style?.border?.color;
    const borderWidth = this.elementDefinition.style?.border?.width;
    if (borderColor != null && borderColor !== 'rgba(0,0,0,0)' && borderWidth != 0) {
      ctx.beginPath();
      ctx.rect(x, y, width, height);
      this.stroke(ctx, options);
      ctx.closePath();
    }
  }

  /**
   * Apply element transformations.
   * First translate the element to its center position, and then rotate.
   * This is how rotation around element center is applied.
   * Later, element are drawn at position relative to its center: -w*0.5 and -h*0.5
   * @param ctx
   * @param param1
   */
  public transform(ctx, { x, y, width, height }) {
    ctx.translate(x, y);
    if (
      !this.handlesScaleInternally() &&
      this.elementDefinition?.scale?.x != null &&
      this.elementDefinition?.scale?.y != null
    ) {
      ctx.scale(this.elementDefinition.scale.x, this.elementDefinition.scale.y);
    }
    ctx.translate(width * 0.5, height * 0.5);
    ctx.rotate(CanvasUtil.getAngle(this.elementDefinition.rotate?.angle ?? 0));
  }

  public drawHighlightWidget(ctx) {
    this.highlightWidgetRenderer?.draw(ctx);
  }

  public drawOutlineWidget(ctx) {
    this.outlineWidgetRenderer?.draw(ctx);
  }

  public drawRotationWidget(ctx): void {
    this.rotationWidgetRenderer?.draw(ctx);
  }

  public drawSelectionWidget(ctx, drawHandles = true): void {
    this.selectionWidgetRenderer?.draw(ctx, drawHandles);
  }

  public getDragHandle(x, y, selectedElementsCount): MouseTarget {
    const isEditingCrop = this.canvasDocument.isCropping(this.elementDefinition.id);
    let checkRotationHandle = selectedElementsCount === 1;
    if (isEditingCrop) {
      checkRotationHandle = false;
    }
    const selectionHandle = this.isResizeEnabled ? this.selectionWidgetRenderer.getDragHandle(x, y) : null;
    const rotationHandle = this.isRotationEnabled
      ? checkRotationHandle && this.rotationWidgetRenderer.getDragHandle(x, y)
      : null;
    if (selectionHandle) return { direction: selectionHandle };
    if (rotationHandle) return { direction: rotationHandle };
    return null;
  }

  public getPointOnElement(px: number, py: number): DRAG_DIRECTIONS {
    const { x, y, width, height } = this.getBoundingClientRect();
    if (this.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -this.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }
    if (this.isPointOnBorder(px, py, { x, y, width, height })) {
      return DRAG_DIRECTIONS.BORDER;
    } else if (this.isPointOnBody(px, py, 0, 0, { x, y, width, height })) {
      return DRAG_DIRECTIONS.BODY;
    }
    return null;
  }

  public isPointOnBorder(px: number, py: number, { x, y, width, height }): boolean {
    return (
      (Math.abs(px - x) < Math.max(4, this.elementDefinition?.style?.border?.width) && py <= y + height && py >= y) ||
      (Math.abs(px - (x + width)) < Math.max(4, this.elementDefinition?.style?.border?.width) &&
        py <= y + height &&
        py >= y) ||
      (Math.abs(py - y) < Math.max(4, this.elementDefinition?.style?.border?.width) && px <= x + width && px >= x) ||
      (Math.abs(py - (y + height)) < Math.max(4, this.elementDefinition?.style?.border?.width) &&
        px <= x + width &&
        px >= x)
    );
  }

  public isPointOnBody(
    px: number,
    py: number,
    margin: number = 0,
    padding: { pl?; pt?; pr?; pb? } | number = { pl: 0, pt: 0, pr: 0, pb: 0 },
    { x, y, width, height },
  ): boolean {
    let p = typeof padding === 'number' ? { pl: padding, pt: padding, pr: padding, pb: padding } : padding;
    return (
      py <= y + height - margin + (p?.pb ?? 0) &&
      py >= y + margin - (p?.pt ?? 0) &&
      px >= x + margin - (p?.pl ?? 0) &&
      px <= x + width - margin + (p?.pr ?? 0)
    );
  }

  public isPointOnElement(
    px: number,
    py: number,
    margin: number = 0,
    padding: { pl?; pt?; pr?; pb? } | number = { pl: 0, pt: 0, pr: 0, pb: 0 },
    options: DrawOptions = {},
  ) {
    const { x, y, width, height } = this.getBoundingClientRect(
      Object.assign({}, options, { maskedDimensions: true, outerEdgeDimensions: true }),
    );
    if (this.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -this.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }

    return this.isPointOnBody(px, py, margin, padding, { x, y, width, height });
  }

  public isVisibleElement(viewBox: ViewBox) {
    const { x, y, width, height } = this.getBoundingClientRectRotated();
    return (
      viewBox.x <= x + width &&
      viewBox.y <= y + height &&
      viewBox.x + viewBox.width >= x &&
      viewBox.y + viewBox.height >= y
    );
  }

  public getArea() {
    const rect = this.getBoundingClientRectRotated();
    return rect.width * rect.height;
  }

  public getBoundingClientRect(options?: DrawOptions): { x; y; width; height } {
    const { x, y } = this.getPosition(options);
    const { width, height } = this.getSize(options);
    return {
      x,
      y,
      width,
      height,
    };
  }

  public getBoundingClientRectRotated(options?: DrawOptions): { x; y; width; height } {
    let { x, y, width, height } = this.getBoundingClientRect(options);
    if (this?.elementDefinition?.rotate?.angle) {
      // const angle = (this.elementDefinition.rotate.angle * Math.PI) / 180;
      const angle = CanvasUtil.getAngle(this.elementDefinition.rotate.angle);
      const sin = Math.abs(Math.sin(angle));
      const cos = Math.abs(Math.cos(angle));
      const rotatedWidth = height * sin + width * cos;
      const rotatedHeight = height * cos + width * sin;

      // const cx = x + (width*0.5)*Math.cos(angle) - (height*0.5)*Math.sin(angle);
      // const cy = y + (width*0.5)*Math.sin(angle) + (height*0.5)*Math.cos(angle);

      // const rotatedX = cx - rotatedWidth*0.5;
      // const rotatedY = cy - rotatedHeight*0.5;

      const rotatedCorners = [
        RotationHelper.rotate({ x, y }, this.elementDefinition.rotate.angle, {
          x: x + width * 0.5,
          y: y + height * 0.5,
        }),
        RotationHelper.rotate({ x: x + width, y }, this.elementDefinition.rotate.angle, {
          x: x + width * 0.5,
          y: y + height * 0.5,
        }),
        RotationHelper.rotate({ x: x + width, y: y + height }, this.elementDefinition.rotate.angle, {
          x: x + width * 0.5,
          y: y + height * 0.5,
        }),
        RotationHelper.rotate({ x, y: y + height }, this.elementDefinition.rotate.angle, {
          x: x + width * 0.5,
          y: y + height * 0.5,
        }),
      ];

      width = rotatedWidth;
      height = rotatedHeight;
      x = Math.min(...rotatedCorners.map((p) => p.x));
      y = Math.min(...rotatedCorners.map((p) => p.y));
    }
    return {
      x,
      y,
      width,
      height,
    };
  }

  public getCenter(): PositionDefinition {
    const coordRect = this.getBoundingClientRect();
    return {
      x: coordRect.x + coordRect.width * 0.5,
      y: coordRect.y + coordRect.height * 0.5,
    };
  }

  public getScale(): ScaleTransformation {
    return this.elementDefinition.scale;
  }

  public getDimensions(options?: DrawOptions): any {
    const { x, y } = this.getPosition(options);
    const { width, height } = this.getSize(options);
    return { x, y, width, height };
  }

  /**
   * Get element position
   *
   * if options?.maskedDimensions is true - get position if the element is clipped by another shape/frame
   * Element position changes if mask position is bigger than element position.
   * @param options
   * @returns
   */
  public getPosition(options?: DrawOptions): PositionDefinition {
    let position = this.elementDefinition.position ? { ...this.elementDefinition.position } : { x: 0, y: 0 };
    return this.getMaskedPosition(options, position);
  }

  /**
   * Get element size
   *
   * if options?.maskedDimensions is true - get size if the element is clipped by another shape/frame
   * Element size changes if mask size is smaller than element size.
   * @param options
   * @returns
   */
  public getSize(options?: DrawOptions): SizeDefinition {
    return this.getMaskedSize(options);
  }

  public getMaskedPosition(options?: DrawOptions, elementPosition?: PositionDefinition) {
    let position = elementPosition ? { ...elementPosition } : { ...this.elementDefinition.position };
    if (
      options?.maskedDimensions &&
      (!this.isSelected || (this.isSelected && this.isInFrame)) &&
      this.isInFrameMask &&
      this.elementDefinition.type !== 'frame'
    ) {
      const frame = this.canvasDocument.getCanvasElementById(this.isInFrameMask as string);
      const framePosition = frame.getPosition();
      position.x = Math.max(framePosition.x, position.x);
      position.y = Math.max(framePosition.y, position.y);
    }

    if (options?.scaleBy) {
      const scale = options.scaleBy;
      position = {
        x: (elementPosition?.x ?? this.elementDefinition.position.x) / scale,
        y: (elementPosition?.y ?? this.elementDefinition.position.y) / scale,
      };
    }

    if (options?.forceOuterEdgeDimensions || (this.USE_OUTER_EDGE && options?.outerEdgeDimensions)) {
      let lineWidth = Number(this.elementDefinition?.style?.border?.width ?? 0);
      if (options?.scaleBy) {
        lineWidth = lineWidth / options.scaleBy;
      }
      if (
        (options?.forceOuterEdgeDimensions && this.elementDefinition?.style?.border?.color === 'rgba(0,0,0,0)') ||
        this.isMask
      ) {
        lineWidth = 0;
      }
      if (this.elementDefinition.type === 'line') {
        const { x1, y1, x2, y2 } = this.elementDefinition.lineDefinition;
        const lineAngle = Math.atan2(Math.abs(y2 - y1), Math.abs(x2 - x1));
        const dx = lineWidth * 0.5 * Math.sin(lineAngle);
        const dy = lineWidth * 0.5 * Math.cos(lineAngle);
        position = {
          x: position.x - dx,
          y: position.y - dy,
        };
      } else {
        position = {
          x: position.x - lineWidth * 0.5,
          y: position.y - lineWidth * 0.5,
        };
      }
    }

    return position;
  }

  public getMaskedSize(options?: DrawOptions, elementSize?: SizeDefinition) {
    let size = elementSize ? { ...elementSize } : { ...this.elementDefinition.size };

    const scale = this.getScale();
    if (!options?.skipScale && scale?.x != null && scale?.y != null) {
      size.width = this.elementDefinition.size.width * scale.x;
      size.height = this.elementDefinition.size.height * scale.y;
    }

    if (
      options?.maskedDimensions &&
      (!this.isSelected || (this.isSelected && this.isInFrame)) &&
      this.isInFrameMask &&
      this.elementDefinition.type !== 'frame'
    ) {
      const position = this.getPosition();
      const maskedPosition = this.getPosition(options);
      const frame = this.canvasDocument.getCanvasElementById(this.isInFrameMask as string);
      const frameSize = frame.getSize();
      const framePosition = frame.getPosition();
      size.width = Math.min(
        framePosition.x + frameSize.width - maskedPosition.x,
        position.x + size.width - maskedPosition.x,
      );
      size.height = Math.min(
        framePosition.y + frameSize.height - maskedPosition.y,
        position.y + size.height - maskedPosition.y,
      );
    }

    if (options?.scaleBy) {
      const scaleBy = options.scaleBy;
      const scale = this.getScale();
      const elementScale = !options?.skipScale && scale?.x != null && scale?.y != null ? scale : { x: 1, y: 1 };
      size = {
        width: ((this.elementDefinition?.size?.width || elementSize?.width) * elementScale.x) / scaleBy,
        height: ((this.elementDefinition?.size?.height || elementSize?.height) * elementScale.y) / scaleBy,
      };
    }

    if (options?.forceOuterEdgeDimensions || (this.USE_OUTER_EDGE && options?.outerEdgeDimensions)) {
      let lineWidth = Number(this.elementDefinition?.style?.border?.width ?? 0);
      if (options?.scaleBy) {
        lineWidth = lineWidth / options.scaleBy;
      }
      if (
        (options?.forceOuterEdgeDimensions && this.elementDefinition?.style?.border?.color === 'rgba(0,0,0,0)') ||
        this.isMask
      ) {
        lineWidth = 0;
      }
      if (this.elementDefinition.type === 'line') {
        const { x1, y1, x2, y2 } = this.elementDefinition.lineDefinition;
        const lineAngle = Math.atan2(Math.abs(y2 - y1), Math.abs(x2 - x1));
        const dx = lineWidth * Math.sin(lineAngle);
        const dy = lineWidth * Math.cos(lineAngle);
        size = {
          width: size.width + dx,
          height: size.height + dy,
        };
      } else {
        size = {
          width: size.width + lineWidth,
          height: size.height + lineWidth,
        };
      }
    }
    return size;
  }

  public getOriginalSizeScale() {
    return 1;
  }

  public toWindowPosition(position: PositionDefinition, relative = true): PositionDefinition {
    return this.canvasDocument.toWindowPosition(position.x, position.y, relative);
  }

  public toWindowSize(size: SizeDefinition): SizeDefinition {
    return this.canvasDocument.toWindowSize(size.width, size.height);
  }

  public getScaledValue(n, viewScale) {
    return Math.max(n, Math.round(n / viewScale.x));
  }

  protected stroke(ctx, options?: DrawOptions) {
    const borderColor = this.elementDefinition.style?.border?.color;
    let borderWidth = this.elementDefinition.style?.border?.width;
    if (borderColor != null && borderColor !== 'rgba(0,0,0,0)' && borderWidth != 0) {
      if (options?.scaleBy) {
        const scale = options.scaleBy;
        borderWidth = borderWidth / scale;
      }
      ctx.strokeStyle = this.elementDefinition.style?.border?.color || 'rgba(0,0,0,0)';
      ctx.lineWidth = borderWidth;
      const type = this.elementDefinition.style?.border?.style;
      switch (type) {
        case 'dotted': {
          ctx.lineCap = 'round';
          ctx.setLineDash([0.5, ctx.lineWidth * 5]);
          break;
        }
        case 'dashed': {
          ctx.setLineDash([ctx.lineWidth * 4, ctx.lineWidth * 5]);
          break;
        }
        default: {
          ctx.setLineDash([]);
        }
      }
      ctx.stroke();
      ctx.setLineDash([]);
      ctx.lineCap = 'butt';
    }
  }

  protected setSVGFillAttribute(element) {
    const backgroundColor = this.elementDefinition.style?.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
    let fill, fillOpacity;
    if (backgroundColor === 'rgba(0,0,0,0)') {
      fill = 'none';
    } else {
      let { hex, a } = CanvasUtil.toHex(backgroundColor);
      fill = `#${hex}`;
      fillOpacity = a;
    }

    element.setAttribute('fill', fill);
    if (fillOpacity) element.setAttribute('fill-opacity', fillOpacity);
  }

  protected setSVGStrokeAttribute(element: HTMLElement) {
    const borderColor = this.elementDefinition.style?.border?.color;
    const borderWidth = this.elementDefinition.style?.border?.width;
    const borderStyle = this.elementDefinition.style?.border?.style;
    if (borderColor != null && borderColor !== 'rgba(0,0,0,0)' && borderWidth != 0) {
      element.setAttribute('stroke', borderColor);
      element.setAttribute('stroke-width', `${borderWidth}px`);
      switch (borderStyle) {
        case 'dotted': {
          element.setAttribute('stroke-linecap', 'round');
          element.setAttribute('stroke-dasharray', `0.5 ${borderWidth * 5}`);
          break;
        }
        case 'dashed': {
          element.setAttribute('stroke-linecap', 'butt');
          element.setAttribute('stroke-dasharray', `${borderWidth * 4} ${borderWidth * 5}`);
          break;
        }
        default: {
          element.setAttribute('stroke-linecap', 'butt');
          break;
        }
      }
    }
  }

  public setAnnotations(annotations: any[]): boolean {
    if (ObjectUtil.compareDeep({ annotations: this.annotations }, { annotations }, '').length === 0) {
      return false;
    }
    this.annotations = annotations;
    return true;
  }

  /**
   * Copies some properties from @this to @canvasElement
   * @param canvasElement
   */
  public copyCanvasElementProperties(canvasElement: CanvasElement) {}
  public setDefaultValues() {}

  /**
   * Creates a copy of @this element
   * @returns CanvasElement
   */
  public copy(): CanvasElement {
    let copiedElement = ObjectUtil.mergeDeep({}, this.elementDefinition);
    delete copiedElement.id;
    if (copiedElement?.elements?.length > 0) {
      for (const i of copiedElement.elements) {
        delete i.id;
      }
    }

    const documentElement = DocumentElementFactory.createElement(this.elementDefinition.type, copiedElement);
    const canvasElement = CanvasElementFactory.createCanvasElement(
      documentElement,
      this.canvasDocument,
      this.canvasDocument.mode !== 'PREVIEW',
    );

    this.copyCanvasElementProperties(canvasElement);

    return canvasElement;
  }

  public isRotated() {
    return (
      this.elementDefinition?.rotate?.angle &&
      this.elementDefinition.rotate.angle !== 0 &&
      this.elementDefinition.rotate.angle !== 360
    );
  }

  public isItemComponent() {
    return this.elementDefinition.type === 'component' && this.componentType === 'item';
  }

  public hasSVGViewable() {
    if (this.elementDefinition.type === 'svg' && !this.isImageError) return true;
    if (this.isItemComponent()) {
      const imageElement = this.elementDefinition.elements?.find((e) => e.type === 'image');
      const canvasImageElement = this.innerElements.find((e) => e.id === imageElement?.id);
      return imageElement.alternateUrls?.originalFile?.indexOf('.svg') > -1 && !canvasImageElement?.isImageError;
    }
    return false;
  }

  public isAssignItemToComponentAllowed() {
    if (!this.elementDefinition.entityData) {
      return false;
    }
    const assignItemToComponentConditions = this.canvasDocument.interactionHandler.assignItemToComponentConditions;
    let valid = true;
    if (assignItemToComponentConditions) {
      assignItemToComponentConditions.forEach((condition) => {
        if (!condition.values.includes(this.elementDefinition.entityData[condition.property])) {
          valid = false;
        }
      });
    }
    return this.isItemComponent() && !this.elementDefinition.isLocked && valid;
  }

  public isColorComponent() {
    return this.elementDefinition.type === 'component' && this.componentType === 'color';
  }
  public hasWarning() {
    return this.annotations?.filter((annotation) => annotation.type === 'WARNING').length > 0;
  }
  public clear() {}

  /** Computes the % of viewable screen that this element covers. */
  public getCoveredArea(options?: DrawOptions) {
    const size = this.getSize(options);
    return CanvasUtil.getCoveredAreaPercentage(
      this.canvasDocument.toWindowSize(size.width, size.height),
      this.canvasDocument.mode === 'SNAPSHOT'
        ? this.canvasDocument.getCanvasSize()
        : { width: window.innerWidth, height: window.innerHeight },
    );
  }

  public setEditingSelectionColor(useEditingColor: boolean) {
    this.selectionWidgetRenderer.useEditingColor = useEditingColor;
    this.canvasDocument.draw();
  }

  public async toImageElement(options?: DrawOptions): Promise<ImageElement> {
    return;
  }

  public createSVGBorderContainer({ x, y, width, height }): HTMLElement {
    return;
  }

  public toSVG(params: { x?; y?; width?; height?; x1?; y1?; x2?; y2?; href?; svgHtmlString? }): HTMLElement {
    return;
  }

  public async loadAsSVG(params): Promise<HTMLElement> {
    return;
  }
}
