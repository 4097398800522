import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevToolsRenderingService {
  public renderMetrics = [];

  private renderCountValue = 0;
  private renderCountSubject = new Subject<number>();
  public renderCount$: Observable<any> = this.renderCountSubject.asObservable();

  private MAX_RENDER_QUEUE_LENGTH = 200;
  private latestMetricsSubject = new Subject<any>();
  public latestMetrics$: Observable<any> = this.latestMetricsSubject.asObservable();

  constructor() {}

  public recordMetrics(metric) {
    this.renderCountValue++;
    this.renderCountSubject.next(this.renderCountValue);
    this.latestMetricsSubject.next(metric);
    this.renderMetrics.push(metric);
    if (this.renderMetrics.length > this.MAX_RENDER_QUEUE_LENGTH) {
      this.renderMetrics.shift();
    }
  }
}
