<app-modal [title]="title" [dialogRef]="dialogRef">
  <app-copy-rename-entity-form
    [rename]="rename"
    [entityType]="entityType"
    (renameEntity)="emitRename($event)"
    (copyEntity)="emitCopy($event)"
    (onFormSubmitted)="closeModal()"
  >
  </app-copy-rename-entity-form>
</app-modal>
