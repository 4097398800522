import { Injectable } from '@angular/core';
import { DocumentService } from '../document.service';
import { DocumentElement, DocumentElementEvent } from '@contrail/documents';
import { DocumentComponentService } from '../document-component/document-component-service';
import { DocumentItemService } from '../document-item/document-item.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentItemInspectorService {
  private hoveredElements: DocumentElement[];

  constructor(
    private documentService: DocumentService,
    private documentComponentService: DocumentComponentService,
  ) {
    this.documentService.documentElementEvents.subscribe((event) => {
      const interactionMode = this.documentService.getInteractionMode();
      if (interactionMode === 'item_inspector') {
        if (event?.eventType === 'mouseenter') {
          this.handleMouseEnter(event?.element);
        } else if (event?.eventType === 'mouseleave') {
          this.handleMouseLeave(event);
        } else if (event?.eventType === 'click') {
          this.handleClick(event);
        }
      } else {
        if (event?.eventType === 'interactionMode') {
          this.handleMouseLeave(event);
        }
      }
    });
  }

  private async handleMouseEnter(element: DocumentElement) {
    if (!element) {
      return;
    }
    const elements = await this.documentComponentService.getSameFamilyComponentElements(element);
    if (!elements?.length) {
      return;
    }
    this.documentService.outlineElements(
      elements.map((e) => e.id),
      '#FFA000',
    );
    this.hoveredElements = elements;
  }

  private handleMouseLeave(event: DocumentElementEvent) {
    if (!this.hoveredElements?.length) {
      return;
    }
    this.documentService.removeOutlineElements(this.hoveredElements.map((e) => e.id));
  }

  private handleClick(event: DocumentElementEvent) {
    if (!this.hoveredElements?.length) {
      return;
    }
    if (!event?.element || !DocumentItemService.isItemComponet(event.element)) {
      return;
    }
    let elementsToSelect = [];
    let elementsToDeselect = [];
    if (!event?.sourceMouseEvent?.shiftKey) {
      this.documentService.deselectAllElements();
      elementsToSelect = this.hoveredElements;
    } else {
      const selectedElementIds = this.documentService.getSelectedElements()?.map((e) => e.id);
      const isTargetElementSelected = selectedElementIds?.indexOf(event.element.id) !== -1;
      if (isTargetElementSelected) {
        elementsToDeselect = this.hoveredElements;
      } else {
        elementsToSelect = this.hoveredElements;
      }
    }
    this.documentService.deselectElements(elementsToDeselect);
    this.documentService.selectElements(elementsToSelect, event?.sourceMouseEvent?.shiftKey);
    this.documentService.setInteractionMode('select');
  }

  public showTempItemInspector() {
    const interactionMode = this.documentService.getInteractionMode();
    if (['new_item_family', 'new_item_option', 'item_copy'].indexOf(interactionMode) !== -1) {
      const highlightElement = this.documentService.getHighlightElement();
      this.documentService.setInteractionMode('item_inspector');
      this.handleMouseEnter(highlightElement);
      this.documentService.removeHighlightElement();
    }
  }

  public hideTempItemInspector() {
    const interactionMode = this.documentService.getInteractionMode();
    const oldInteractionMode = this.documentService.getOldInteractionMode();
    if (
      interactionMode === 'item_inspector' &&
      ['new_item_family', 'new_item_option', 'item_copy'].indexOf(oldInteractionMode) !== -1
    ) {
      this.documentService.setInteractionMode(oldInteractionMode);
    }
  }
}
