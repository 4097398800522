import { Entities, EntityReference, SortOrderOptions } from '@contrail/sdk';
import { SortDefinition, SortDirection } from '../../components/sort/sort-definition';
import { combineLatest, Observable, Subject } from 'rxjs';
import { switchMap, debounceTime, filter } from 'rxjs/operators';
import { FilterObjects } from '@contrail/filters';
import { ChooserDataSource, ChooserFilterConfig } from './chooser-data-source';

export class DocumentChooserDataSource extends ChooserDataSource {
  private documentEntities$: Subject<Array<any>> = new Subject();
  constructor(
    protected entityType: string,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    private documentEntityReference: string,
  ) {
    super(entityType, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);
    this.initFilteredDataObservable();
    this.initResultsObservable();
    this.loadDocumentData();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([this.filterConfigSubject, this.sortConfigSubject, this.documentEntities$]).pipe(
      debounceTime(50),
      filter(([filterConfig, sortConfig, documentEntities]) => {
        return !!filterConfig && !!sortConfig && !!documentEntities;
      }),
      switchMap(async ([filterConfig, sortConfig, documentEntities]) => {
        if (!filterConfig || !sortConfig) {
          return;
        }
        let data = documentEntities;
        let searchTerm = filterConfig?.searchTerm?.trim() || '';
        data = this.filterLocalData({ searchTerm }, data, this.searchableProperties);
        const filterDefinition = filterConfig?.filterDefinition;
        if (filterDefinition) {
          data = FilterObjects.filter(data, filterDefinition?.filterCriteria);
        }
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  private async loadDocumentData() {
    let data;
    let documentEntityType = this.entityType;
    // if(documentEntityType === 'asset'){
    //   documentEntityType = 'content'; // Short term hack, since we are not adding assets to boards as assets.
    // }

    this.loadingSubject.next(true);
    const ref = new EntityReference(this.documentEntityReference);
    data = await new Entities().get({
      entityName: ref.entityType,
      relation: 'entities',
      id: ref.id,
      criteria: { entityTypes: [documentEntityType] },
    });
    this.documentEntities$.next(data);
    this.loadingSubject.next(false);
  }
}
