import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyConfiguratorModule } from './property-configurator/property-configurator.module';
import { DocumentWidgetTrayComponent } from './document-widget-tray/document-widget-tray.component';
import { UploadImageFileModalComponent } from './document-widget-tray/upload-image-file-modal/upload-image-file-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PropertyConfiguratorBarComponent } from './property-configurator/property-configurator-bar/property-configurator-bar.component';
import { DocumentToolsComponent } from './document-tools/document-tools.component';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { AddHotSpotModalComponent } from './action-dispatchers/hot-spot/add-hot-spot-modal/add-hot-spot-modal.component';
import { ItemDataChooserModule } from 'src/app/common/item-data-chooser/item-data-chooser.module';
import { PaintFormatComponent } from './document-paint-formatter/paint-formatter.component';
import { DocumentAnnotationToolComponent } from './document-annotation/document-annotation-tool/document-annotation-tool.component';
import { DocumentAddFrameComponent } from './document-frame/document-add-frame/document-add-frame.component';
import { AnnotationModule } from '@common/annotation/annotation.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { ImageAssignmentOptionsComponent } from './action-dispatchers/document-element-interaction/image-assignment-options.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ContentModule } from '@common/content/content.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ImageCropperModule } from './action-dispatchers/crop-image-element/lib/image-cropper.module';
import { CropImageElementComponent } from './action-dispatchers/crop-image-element/crop-image-element.component';
import { TextHyperlinkComponent } from './document-text/text-hyperlink/text-hyperlink.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PropertyConfiguratorBorderRadiusComponent } from './property-configurator/property-configurator-border-radius/property-configurator-border-radius.component';
import { PropertyConfiguratorColorPickerComponent } from './property-configurator/property-configurator-color-picker/property-configurator-color-picker.component';
import { PropertyConfiguratorTextDecoratorComponent } from './property-configurator/property-configurator-text-decorator/property-configurator-text-decorator.component';
import { PropertyConfiguratorFontSizeComponent } from './property-configurator/property-configurator-font-size/property-configurator-font-size.component';
import { PropertyConfiguratorFontFamilySelectorComponent } from './property-configurator/property-configurator-font-family-selector/property-configurator-font-family-selector.component';
import { PropertyConfiguratorTextAlignmentComponent } from './property-configurator/property-configurator-text-alignment/property-configurator-text-alignment.component';
import { AddIFrameElementModalComponent } from './document-iframe/add-iframe-element-modal/add-iframe-element-modal.component';
import { DocumentAddIframeComponent } from './document-iframe/document-add-iframe.component';
import { ViewIFrameElementModalComponent } from './document-iframe/view-iframe-element-modal/view-iframe-element-modal.component';
import { DocumentAddAdvancedComponent } from './document-advanced-features/document-add-advanced/document-add-advanced.component';
import { DocumentContentEditorModule } from './document-content-editor/document-content-editor.module';
import { DocumentContentEditorComponent } from './document-content-editor/document-content-editor-component/document-content-editor.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { FrameTemplatesModule } from '@common/frame-templates/frame-templates.module';
import { DocumentContentSvgEditorComponent } from './document-content-editor/document-content-svg-editor-component/document-content-svg-editor.component';
import { DocumentCreateItemsComponent } from './document-item/document-create-item/create-item-component/create-items.component';

@NgModule({
  declarations: [
    DocumentWidgetTrayComponent,
    DocumentToolsComponent,
    AddHotSpotModalComponent,
    PaintFormatComponent,
    UploadImageFileModalComponent,
    DocumentAnnotationToolComponent,
    DocumentAddFrameComponent,
    ImageAssignmentOptionsComponent,
    CropImageElementComponent,
    TextHyperlinkComponent,
    DocumentAddIframeComponent,
    AddIFrameElementModalComponent,
    ViewIFrameElementModalComponent,
    DocumentAddAdvancedComponent,
    DocumentCreateItemsComponent,
  ],
  imports: [
    CommonModule,
    PropertyConfiguratorModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    NgxDropzoneModule,
    ComponentsModule,
    DocumentContentEditorModule,
    ItemDataChooserModule,
    AnnotationModule,
    MatDialogModule,
    MatRadioModule,
    PipesModule,
    ContentModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    ImageCropperModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    FrameTemplatesModule,
  ],
  exports: [
    DocumentWidgetTrayComponent,
    PropertyConfiguratorBarComponent,
    DocumentContentEditorComponent,
    DocumentContentSvgEditorComponent,
    AddHotSpotModalComponent,
    PaintFormatComponent,
    UploadImageFileModalComponent,
    DocumentAnnotationToolComponent,
    DocumentAddFrameComponent,
    CropImageElementComponent,
    TextHyperlinkComponent,
    PropertyConfiguratorBorderRadiusComponent,
    PropertyConfiguratorColorPickerComponent,
    PropertyConfiguratorTextDecoratorComponent,
    PropertyConfiguratorFontSizeComponent,
    PropertyConfiguratorFontFamilySelectorComponent,
    PropertyConfiguratorTextAlignmentComponent,
    DocumentAddIframeComponent,
    DocumentCreateItemsComponent,
  ],
})
export class DocumentModule {}
