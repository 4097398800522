<div *ngIf="!editMode">
  <div class="flex items-center">
    <mat-form-field class="mb-2 mr-3" style="min-width: 50px">
      <mat-label>Select a preset</mat-label>
      <mat-select (selectionChange)="selectTemplate($event)" [formControl]="templateFormControl">
        <mat-option></mat-option>
        <mat-option *ngFor="let v of availableViewDefinitions" [value]="v">{{ v.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex items-center">
      <mat-icon
        class="mr-2 cursor-pointer"
        *ngIf="!selectedTemplate && allowTemplateEdit"
        matTooltip="Create New Preset"
        (click)="startCreateNewTemplate()"
        >save</mat-icon
      >
      <mat-icon
        class="mr-2 cursor-pointer"
        *ngIf="selectedTemplate && allowTemplateEdit"
        matTooltip="Edit Name"
        (click)="startUpdateTemplate()"
        >edit</mat-icon
      >
      <mat-icon
        class="mr-2 cursor-pointer"
        *ngIf="selectedTemplate && allowTemplateEdit"
        matTooltip="Save"
        (click)="updateTemplate()"
        >save</mat-icon
      >
      <mat-icon
        class="mr-2 cursor-pointer"
        *ngIf="selectedTemplate && allowTemplateEdit"
        matTooltip="Copy"
        (click)="startCopyTemplate()"
        >content_copy</mat-icon
      >
      <mat-icon
        class="mr-2 cursor-pointer"
        *ngIf="selectedTemplate && allowTemplateEdit"
        matTooltip="Delete"
        (click)="startDeleteTemplate()"
        >delete</mat-icon
      >
    </div>
  </div>
</div>
<div class="p-3" *ngIf="editMode && editMode !== 'DELETE'">
  <div class="mb-2 font-bold">
    <span *ngIf="editMode === 'CREATE'">Create new view preset</span>
    <span *ngIf="editMode === 'COPY'">Copy view preset</span>
    <span *ngIf="editMode === 'UPDATE'">Update preset name</span>
  </div>
  <div>
    <mat-form-field>
      <input matInput [formControl]="nameFormControl" id="nameInput" size="40" />
    </mat-form-field>
    <button
      style="margin-left: 0.75em"
      mat-stroked-button
      (click)="completeForm()"
      [disabled]="!this.nameFormControl.valid"
    >
      Save
    </button>
    <button style="margin-left: 0.75em" mat-stroked-button (click)="cancelForm()">Cancel</button>
  </div>
</div>
<div class="p-3" *ngIf="editMode === 'DELETE'">
  Delete view preset?
  <button style="margin-left: 0.75em" mat-stroked-button (click)="cancelForm()">Cancel</button>
  <button style="margin-left: 0.75em" mat-stroked-button (click)="deleteTemplate()" color="warn">Confirm</button>
</div>
<div class="p3">
  <app-property-view-builder
    #propertyViewBuilder
    [selectedPropertiesList]="properties || []"
    [defaultPropertyStyle]="defaultPropertyStyle"
    [availableProperties]="availableViewProperties"
    [viewDefinitionApplicationViewSlug]="viewDefinitionApplicationViewSlug"
    (propertiesChangedEvent)="handlePropertyViewChange($event)"
  >
  </app-property-view-builder>
</div>
