<div class="comments-button">
  <button class="menu-button mat-button mat-button-base" (click)="toggleSideBar()" mat-button>
    <mat-icon svgIcon="contrail_comment" *ngIf="!(comments$ | async).length"></mat-icon>
    <mat-icon
      [matBadgeSize]="'small'"
      svgIcon="contrail_comment"
      matBadge="{{ (comments$ | async).length }}"
      matBadgeColor="primary"
      *ngIf="(comments$ | async).length"
    ></mat-icon>
  </button>
</div>
