<div class="search" [class.lg-gray]="lgGray">
  <mat-icon>search</mat-icon>
  <input
    #inputField
    spellcheck="false"
    type="input"
    [formControl]="searchInput"
    [placeholder]="placeholder"
    [class.text-sm]="!lgGray || textSmall"
    [class.text-title-1]="lgGray && !textSmall"
    (keydown)="preventKeyPropagation($event)"
  />
</div>
