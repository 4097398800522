import { CanvasDocument } from '../../../canvas-document';

export class TouchEventHandler {
  public static touchstartHandler;
  public static touchmoveHandler;
  public static touchendHandler;

  private previousEvent = null;

  constructor(private canvasDocument: CanvasDocument) {
    TouchEventHandler.touchstartHandler = this.touchstart.bind(this);
    TouchEventHandler.touchmoveHandler = this.touchmove.bind(this);
    TouchEventHandler.touchendHandler = this.touchend.bind(this);
    document.addEventListener('touchstart', TouchEventHandler.touchstartHandler, { passive: false });
    document.addEventListener('touchmove', TouchEventHandler.touchmoveHandler, { passive: false });
    document.addEventListener('touchend', TouchEventHandler.touchendHandler, { passive: false });
  }

  public removeEventHandlers() {
    document.removeEventListener('touchstart', TouchEventHandler.touchstartHandler);
    document.removeEventListener('touchmove', TouchEventHandler.touchmoveHandler);
    document.removeEventListener('touchend', TouchEventHandler.touchendHandler);
  }

  private touchstart(event): void {
    if (event.target !== this.canvasDocument.canvasDisplay.canvasDisplayElement) {
      return;
    }

    event.preventDefault();
    if (event.changedTouches.length === 1) {
      const interactionMode = this.canvasDocument.interactionHandler.interactionMode;
      if (
        event.changedTouches[0].touchType === 'stylus' &&
        ['pen', 'highlighter', 'eraser'].indexOf(interactionMode) === -1
      ) {
        this.canvasDocument.interactionHandler.setInteractionMode('pen');
      }

      const normalizedEvents = this.canvasDocument.interactionHandler.canvasEventHandlers.normalizeEvent(event);
      const normalizedEvent = normalizedEvents[0];
      const elementTarget = this.canvasDocument.state.getElementTarget(
        normalizedEvent.clientX,
        normalizedEvent.clientY,
      );

      this.canvasDocument.interactionHandler.canvasEventHandlers.elementSelectHandler.handleClick(
        normalizedEvent,
        elementTarget,
      );

      // this.canvasDocument.interactionHandler.canvasEventHandlers.elementDragHandler.dragstarted(
      //   normalizedEvent,
      //   elementTarget,
      // );
      this.canvasDocument.interactionHandler.canvasEventHandlers.penDrawHandler.dragstarted(
        normalizedEvent,
        elementTarget,
      );
      this.canvasDocument.interactionHandler.canvasEventHandlers.eraserHandler.dragstarted(normalizedEvent);
    }
  }

  private touchmove(event): void {
    if (event.target !== this.canvasDocument.canvasDisplay.canvasDisplayElement) {
      return;
    }

    event.preventDefault();
    if (event.changedTouches.length === 1) {
      const normalizedEvents = this.canvasDocument.interactionHandler.canvasEventHandlers.normalizeEvent(
        event,
        this.previousEvent,
      );
      const normalizedEvent = normalizedEvents[0];
      // this.canvasDocument.interactionHandler.canvasEventHandlers.elementDragHandler.dragged(normalizedEvent);
      this.canvasDocument.interactionHandler.canvasEventHandlers.penDrawHandler.dragged(normalizedEvent);
      this.canvasDocument.interactionHandler.canvasEventHandlers.eraserHandler.dragged(normalizedEvent);
      this.previousEvent = event;
    }
  }

  private touchend(event): void {
    if (event.target !== this.canvasDocument.canvasDisplay.canvasDisplayElement) {
      return;
    }

    event.preventDefault();
    if (event.changedTouches.length === 1) {
      const normalizedEvents = this.canvasDocument.interactionHandler.canvasEventHandlers.normalizeEvent(event);
      const normalizedEvent = normalizedEvents[0];
      // this.canvasDocument.interactionHandler.canvasEventHandlers.elementDragHandler.dragended(normalizedEvent);
      this.canvasDocument.interactionHandler.canvasEventHandlers.penDrawHandler.dragended(normalizedEvent);
      this.canvasDocument.interactionHandler.canvasEventHandlers.eraserHandler.dragended(normalizedEvent);
    }
    this.previousEvent = null;
  }
}
