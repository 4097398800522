import { Injectable } from '@angular/core';
import { DocumentHistoryActions } from '@common/document-history/document-history-store';
import { DocumentHistoryActionTypes } from '@common/document-history/document-history-store/document-history.actions';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { tap } from 'rxjs/operators';
import { DocumentActions } from '../document/document-store';
import { Request } from '@contrail/sdk';
import { BoardsSelectors } from '../../boards-store';
import { BoardService } from '../board.service';

@Injectable({
  providedIn: 'root',
})
export class BoardSnapshotService {
  private board: any;

  constructor(
    private confirmationBoxService: ConfirmationBoxService,
    private actions$: Actions,
    private boardService: BoardService,
    private webSocketService: WebSocketService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(BoardsSelectors.currentBoard).subscribe((currentBoard) => (this.board = currentBoard));
    // listens to the RESTORE_ENTITY_SNAPSHOT action from the store
    this.actions$
      .pipe(
        ofType(DocumentHistoryActionTypes.RESTORE_ENTITY_SNAPSHOT),
        tap((action: any) => {
          this.restoreSnapshot(action.id);
        }),
      )
      .subscribe();
  }

  async performRestoreSnapshot(boardId: string, snapshotId: string) {
    const requestUrl = '/boards/' + boardId + '/restore/' + snapshotId;
    await Request.request(requestUrl, {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  async restoreSnapshot(snapshotId: string) {
    const confirm = await this.confirmationBoxService.open(
      'Confirmation',
      'This action will save the current state of the board and replace it with this version. Do you want to proceed?',
    );
    if (confirm) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      await this.performRestoreSnapshot(this.board.id, snapshotId);
      this.webSocketService.sendSessionEvent({
        eventType: 'RESTORE_BOARD_SNAPSHOT',
        changes: this.board.id,
      });
      this.clearSnapshot();
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
  }

  private clearSnapshot() {
    // revert to the latest board version
    const boardId = this.board.id;
    this.boardService.clearCurrentBoard();
    this.boardService.init(boardId);
    this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
    // close the snapshots panel
    this.store.dispatch(
      DocumentActions.toggleChooser({
        overlay: {
          showChooser: false,
        },
      }),
    );
  }
}
