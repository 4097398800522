import { ImageElement } from '../components/image-element/image-element';
import { TextImageGenerator } from '../elements/text/text-image-generator';
import { TextStyle } from '../elements/text/text-style';
import { Cache, CacheValue } from './cache';

export class TextElementCache extends Cache {
  constructor() {
    super();
  }

  /**
   * Caches resolved image element requests for text based on text value, formatting, dimensions.  This will reduce the amount of image generation
   * that occurs for text in templated / copy/pasted frames.
   */
  public static async generateTextImage(
    text: string,
    options,
    isTextTool: boolean,
    textStyle: TextStyle,
    editable = false,
    scale = 0,
  ): Promise<ImageElement> {
    let cacheKey = this.getCacheKey(text, options.width, options.height, isTextTool, textStyle, editable, scale);
    const promise: ImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      text,
      options,
      textStyle,
      editable,
      scale,
    );
    this.set(cacheKey, { resolvedPromise: promise, promise: null });
    return promise;
  }

  public static async generateTextImageByCacheKey(
    text: string,
    cacheKey,
    options,
    textStyle: TextStyle,
    editable = false,
    scale = 0,
  ): Promise<ImageElement> {
    const promise: ImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      text,
      options,
      textStyle,
      editable,
      scale,
    );
    this.set(cacheKey, { resolvedPromise: promise, promise: null });
    return promise;
  }

  public static getCacheKey(
    text: string,
    width,
    height,
    isTextTool: boolean,
    textStyle: TextStyle,
    editable = false,
    scale = 0,
  ) {
    let cacheKey = `${text}_${width}_${height}_${isTextTool}_${JSON.stringify(textStyle?.elementDefinition?.style)}_${editable}_${scale}`;
    return cacheKey;
  }

  public static clear() {
    if (this.cacheMap) {
      for (let [key, value] of this.cacheMap) {
        if (value.resolvedPromise) {
          value.resolvedPromise.img = null;
          value.resolvedPromise.canvasImg = null;
          value.resolvedPromise = null;
        }
      }
    }
    super.clear();
  }
}
