import { ViewBox } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';

export class ZoomDragHandler {
  private startingDraggingBox: ViewBox;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget) {
    if (this.canvasDocument.interactionHandler.interactionMode !== 'zoom_in') {
      return;
    }

    this.clear();
    const { x, y } = {
      x: event.clientX,
      y: event.clientY,
    };

    this.startingDraggingBox = { x, y, width: 0, height: 0 };
    this.canvasDocument.canvasRenderer.blueBoxRenderer.box = { x, y, width: 0, height: 0 };
  }

  public dragged(event) {
    if (!this.canvasDocument.canvasRenderer.blueBoxRenderer.box) {
      return;
    }

    const distanceX = event.clientX - this.startingDraggingBox.x;
    const distanceY = event.clientY - this.startingDraggingBox.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    const width = Math.abs(this.startingDraggingBox.width + distanceX);
    const height = Math.abs(this.startingDraggingBox.height + distanceY);
    let x = this.startingDraggingBox.x;
    let y = this.startingDraggingBox.y;

    if (distanceX < 0) {
      x = this.startingDraggingBox.x + distanceX;
    }
    if (distanceY < 0) {
      y = this.startingDraggingBox.y + distanceY;
    }

    this.canvasDocument.canvasRenderer.blueBoxRenderer.box = {
      width,
      height,
      x,
      y,
    };

    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (this.canvasDocument.canvasRenderer.blueBoxRenderer.box) {
      this.setLocation();
    }
    this.clear();
    this.canvasDocument.draw();
  }

  private clear() {
    this.canvasDocument.canvasRenderer.blueBoxRenderer.box = null;
    this.startingDraggingBox = null;
  }

  private setLocation() {
    const { x, y, width, height } = this.canvasDocument.canvasRenderer.blueBoxRenderer.box;
    const size = this.canvasDocument.toDocumentSize(width, height);
    const position = this.canvasDocument.toDocumentPosition(x, y);
    const zoomFactor = Math.max(size.width / window.innerWidth, size.height / window.innerHeight);
    this.canvasDocument.actionsDispatcher.handleDocumentNavigationEvent({
      eventType: 'setLocation',
      data: {
        position: {
          x: position.x,
          y: position.y,
        },
        zoomFactor,
      },
    });
  }
}
