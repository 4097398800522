<h3>Images</h3>
<mat-tab-group>
  <mat-tab label="Cache">
    <pre>
      {{ cacheMetrics }}
    </pre>
  </mat-tab>
  <mat-tab label="Images">
    <div class="p-3 my-1 border-b border-gray-400 flex">
      <div class="mx-2" style="width: 65px"></div>
      <div class="font-bold mx-2" style="width: 150px">Image Binding</div>
      <div class="font-bold mx-2" style="width: 150px">Element Type</div>
      <div class="font-bold mx-2" style="width: 150px; text-align: right">Source File Size</div>
      <div class="font-bold mx-2" style="width: 300px">Primary Reference</div>
    </div>
    <div style="width: '100%'">
      <virtual-scroller [style.height]="'60vh'" #scroll [items]="imageInfo" class="narrow-scroll">
        <div class="p-3 my-1 border-b border-gray-400 flex" *ngFor="let image of scroll.viewPortItems">
          <div class="mx-2" style="width: 65px">
            <div class="image-holder" *ngIf="image.thumbnailUrl">
              <img [attr.src]="image.thumbnailUrl | secureImage | async" />
            </div>
          </div>
          <div class="mx-2" style="width: 150px">{{ image.bindingType }}</div>
          <div class="mx-2" style="width: 150px">{{ image.element.type }}</div>
          <div class="mx-2" style="width: 150px; text-align: right">
            <span *ngIf="image.sourceFileSize">{{ image.sourceFileSize / 1000 | number: '1.0-0' }} KB</span>
          </div>
          <div class="mx-2" style="width: 300px">{{ image.primaryReference }}</div>
          <div class="mx-2" style="width: 300px">
            <button mat-button (click)="goToElement(image)"><mat-icon>chevron_right</mat-icon></button>
          </div>
        </div>
      </virtual-scroller>
    </div>
  </mat-tab>
  <mat-tab label="Components">Components</mat-tab>
</mat-tab-group>
