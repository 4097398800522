import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@common/pipes/pipes.module';
import { DocumentContentEditorComponent } from './document-content-editor-component/document-content-editor.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PropertyConfiguratorModule } from '../property-configurator/property-configurator.module';
import { ContentModule } from '@common/content/content.module';
import { ColorModule } from '@common/color/color.module';
import { ColorWidgetComponent } from '@common/color/color-widget/color-widget.component';
import { DocumentContentSvgEditorComponent } from './document-content-svg-editor-component/document-content-svg-editor.component';

@NgModule({
  declarations: [DocumentContentEditorComponent, DocumentContentSvgEditorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ComponentsModule,
    MatExpansionModule,
    MatDividerModule,
    MatTooltipModule,
    PipesModule,
    MatProgressSpinnerModule,
    PropertyConfiguratorModule,
    ContentModule,
    ColorModule,
  ],
  exports: [DocumentContentEditorComponent, DocumentContentSvgEditorComponent, ColorWidgetComponent],
})
export class DocumentContentEditorModule {}
