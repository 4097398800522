import {
  DocumentElement,
  LineDefinition,
  PositionDefinition,
  SizeDefinition,
  TranslateTransformation,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../../canvas-document';
import { CanvasUtil } from '../../../../canvas-util';
import { CanvasElement } from '../../../../elements/canvas-element';
import { RotationHelper } from '../../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasMaskState } from '../../../../state/canvas-mask-state';
import { CROP_RESIZE_DRAG_DIRECTIONS } from '../../crop-event-handlers/crop-box-resize-handler';
import { DRAG_DIRECTIONS_TRANSFORM, RESIZE_DRAG_DIRECTIONS } from '../element-resize-handler';
import { GuidelineCoordinate, GuidelineHandlerService, GUIDELINE_MARGIN } from './guideline-handler.service';

export interface SnapDimensions {
  position: PositionDefinition;
  size: SizeDefinition;
}

export class ResizeGuidelineHandler {
  private guidelineService: GuidelineHandlerService;
  private selectedElements: CanvasElement[];
  private startingElements: Map<string, DocumentElement> = new Map();
  private startingBox: { x: number; y: number; width: number; height: number };
  private box: { x: number; y: number; width: number; height: number };
  private angle: number;
  private otherElementCoordinatesX: any[] = [];
  private otherElementCoordinatesY: any[] = [];
  public hasSnapped = false;
  private snapDimensions: SnapDimensions;
  private snapLineDefinition: LineDefinition;
  private keepAspectRatio = false;
  private transform: TranslateTransformation;
  private margin: number;
  private isMask = false;
  private readonly supportedCombinations = [
    'top:top',
    'bottom:bottom',
    'top:bottom',
    'bottom:top',
    'top:middle',
    'bottom:middle',
    'left:left',
    'left:right',
    'left:center',
    'right:left',
    'right:right',
    'right:center',
  ];

  constructor(
    private canvasDocument: CanvasDocument,
    private resizeHandler,
  ) {
    this.guidelineService = new GuidelineHandlerService(this.canvasDocument);
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect()) return;

    const { element, target } = elementTarget;
    if (
      target &&
      (RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1 ||
        (element &&
          CROP_RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1 &&
          element.isCropEnabled &&
          this.canvasDocument.isCropping(element.id)))
    ) {
      const selectedElements =
        this.canvasDocument.interactionHandler.selectionHandler.getSelectedUnlockedElementsByFrame();
      if (selectedElements?.length > 0) {
        this.selectedElements = selectedElements;
        this.selectedElements.map((canvasElement) => {
          this.startingElements.set(canvasElement.id, ObjectUtil.cloneDeep(canvasElement.elementDefinition));
        });
        this.keepAspectRatio = this.resizeHandler.keepAspectRatio(
          this.selectedElements[0].elementDefinition,
          event.shiftKey,
        );
        this.box =
          this.selectedElements.length === 1
            ? this.selectedElements[0].getBoundingClientRectRotated({ outerEdgeDimensions: true })
            : this.canvasDocument.state.getCommonBounds(this.selectedElements, { outerEdgeDimensions: true });
        this.startingBox = ObjectUtil.cloneDeep(this.box);
        this.transform = DRAG_DIRECTIONS_TRANSFORM[target];
        this.angle =
          this.selectedElements?.length === 1 ? this.selectedElements[0].elementDefinition?.rotate?.angle : null;
        this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines = [];
        const { otherElementCoordinatesX, otherElementCoordinatesY } =
          this.guidelineService.extractOtherElementCoordinates(this.selectedElements);
        this.otherElementCoordinatesX = this.otherElementCoordinatesX.concat(otherElementCoordinatesX);
        this.otherElementCoordinatesY = this.otherElementCoordinatesY.concat(otherElementCoordinatesY);
        this.margin = this.canvasDocument.getScaledValue(GUIDELINE_MARGIN);
        this.isMask =
          CanvasMaskState.isMask(elementTarget?.element?.elementDefinition) &&
          !this.canvasDocument.state.maskState.isEditingMask(elementTarget?.element?.elementDefinition?.id);
      }
    }
  }

  public dragged(event) {
    if (!this.box) return;
    this.box =
      this.selectedElements.length === 1
        ? this.selectedElements[0].getBoundingClientRectRotated({ calcSize: true, outerEdgeDimensions: true })
        : this.canvasDocument.state.getCommonBounds(this.selectedElements, { outerEdgeDimensions: true });
    this.hasSnapped = false;
    this.addGuidelines();
  }

  private addGuidelines() {
    this.snapDimensions = null;
    this.snapLineDefinition = null;
    this.addGuidelineByDirection('x');
    this.addGuidelineByDirection('y');
  }

  private addGuidelineByDirection(direction) {
    const elementCoordinates = this.guidelineService.getBoxCoordinates(
      this.selectedElements.map((e) => e.elementDefinition.id).join('-'),
      this.box,
      direction,
    );
    const elementDimensions = { ...this.box };

    // We check either x coords or y coords for the other elements based on the direction being passed.
    const otherElementCoordinates = direction === 'x' ? this.otherElementCoordinatesX : this.otherElementCoordinatesY;

    // Outer loop is for each of top/bottom/middle or right/left/center for the element being dragged.
    for (let i = 0; i < elementCoordinates?.length; i++) {
      for (let j = 0; j < otherElementCoordinates?.length; j++) {
        this.drawOrRemoveGuidelinesBetweenElements(
          elementDimensions,
          elementCoordinates[i],
          otherElementCoordinates[j],
          direction,
        );
      }
    }
  }

  private drawOrRemoveGuidelinesBetweenElements(
    targetElementDimensions,
    targetElementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    direction: 'x' | 'y',
  ) {
    const currentCombination = `${targetElementCoordinates.position}:${otherElementCoordinates.position}`;
    if (!this.supportedCombinations.includes(currentCombination)) {
      return;
    }
    const id = this.guidelineService.generateId('line', targetElementCoordinates, otherElementCoordinates, null);
    if (!this.guidelineService.isInRange(otherElementCoordinates, targetElementCoordinates, direction)) {
      this.removeGuideline(id);
      return;
    }

    // targetElementDimensions is position and size that changes on each drag event in element-drag-handler
    // and it doesn't take into account that it was snapped to make sure user can drag the element
    // outside of snap position. this causes incorrect calculations because if the element is snapped
    // it's real dimensions are @snapDimensions and not @targetElementDimensions
    if (
      this.snapDimensions &&
      CanvasUtil.isWithinMargin(targetElementDimensions.x, this.snapDimensions.position.x, this.margin) &&
      CanvasUtil.isWithinMargin(targetElementDimensions.y, this.snapDimensions.position.y, this.margin) &&
      CanvasUtil.isWithinMargin(targetElementDimensions.width, this.snapDimensions.size.width, this.margin) &&
      CanvasUtil.isWithinMargin(targetElementDimensions.height, this.snapDimensions.size.height, this.margin)
    ) {
      targetElementDimensions.x = this.snapDimensions.position.x;
      targetElementDimensions.y = this.snapDimensions.position.y;
      targetElementDimensions.width = this.snapDimensions.size.width;
      targetElementDimensions.height = this.snapDimensions.size.height;
    }

    if (
      this.selectedElements?.length === 1 &&
      this.selectedElements[0].elementDefinition.type === 'line' &&
      this.selectedElements[0].elementDefinition.lineDefinition
    ) {
      const lineDefinition = this.selectedElements[0].elementDefinition.lineDefinition;
      this.snapLineDefinition = this.calculateSnapLineDefinition(
        lineDefinition,
        targetElementCoordinates,
        otherElementCoordinates,
        direction,
      );
    } else {
      this.snapDimensions = this.calculateSnapPosition(
        this.angle,
        targetElementDimensions,
        targetElementCoordinates,
        otherElementCoordinates,
        direction,
      );
    }

    if (this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.findIndex((g) => g.id === id) === -1) {
      this.snapToPosition(this.snapDimensions, this.snapLineDefinition);
      this.drawGuideline(id, direction, targetElementCoordinates, otherElementCoordinates);
    } else {
      this.snapToPosition(this.snapDimensions, this.snapLineDefinition);
    }

    // Remove all other guidelines on the same position as guideline with id
    this.removeGuidelineByPosition(id, [targetElementCoordinates.position, otherElementCoordinates.position]);
  }

  private calculateSnapLineDefinition(
    targetLineDefinition: LineDefinition,
    targetElementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    direction: 'x' | 'y',
  ): LineDefinition {
    let { x1, y1, x2, y2 } = { ...targetLineDefinition };
    switch (targetElementCoordinates.position) {
      case 'left':
        if (x1 < x2) {
          x1 = otherElementCoordinates.x;
        } else {
          x2 = otherElementCoordinates.x;
        }
        break;
      case 'right':
        if (x2 > x1) {
          x2 = otherElementCoordinates.x;
        } else {
          x1 = otherElementCoordinates.x;
        }
        break;
      case 'top':
        if (y1 < y2) {
          y1 = otherElementCoordinates.y;
        } else {
          y2 = otherElementCoordinates.y;
        }
        break;
      case 'bottom':
        if (y2 > y1) {
          y2 = otherElementCoordinates.y;
        } else {
          y1 = otherElementCoordinates.y;
        }
        break;
    }
    return { x1, y1, x2, y2 };
  }

  public calculateSnapPosition(
    angle,
    targetElementDimensions,
    targetElementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    direction: 'x' | 'y',
  ): SnapDimensions {
    let position, size, width, height;
    switch (targetElementCoordinates.position) {
      case 'left':
        width = targetElementDimensions.x + targetElementDimensions.width - otherElementCoordinates.x;
        height = this.keepAspectRatio
          ? targetElementDimensions.height * (width / targetElementDimensions.width)
          : targetElementDimensions.height;
        size = { width, height };
        position = { x: otherElementCoordinates.x, y: targetElementDimensions.y };
        break;
      case 'right':
        width = otherElementCoordinates.x - targetElementDimensions.x;
        height = this.keepAspectRatio
          ? targetElementDimensions.height * (width / targetElementDimensions.width)
          : targetElementDimensions.height;
        size = { width, height };
        position = { x: targetElementDimensions.x, y: targetElementDimensions.y };
        break;
      case 'top':
        height = targetElementDimensions.y + targetElementDimensions.height - otherElementCoordinates.y;
        width = this.keepAspectRatio
          ? targetElementDimensions.width * (height / targetElementDimensions.height)
          : targetElementDimensions.width;
        size = { width, height };
        position = { x: targetElementDimensions.x, y: otherElementCoordinates.y };
        break;
      case 'bottom':
        height = otherElementCoordinates.y - targetElementDimensions.y;
        width = this.keepAspectRatio
          ? targetElementDimensions.width * (height / targetElementDimensions.height)
          : targetElementDimensions.width;
        position = { x: targetElementDimensions.x, y: targetElementDimensions.y };
        size = { width, height };
        break;
    }

    if (angle && ['center', 'middle'].indexOf(targetElementCoordinates.position) === -1) {
      const center = {
        x: position.x + size.width * 0.5,
        y: position.y + size.height * 0.5,
      };

      let quadrant = 360;
      if (angle <= 90) {
        quadrant = 90;
      } else if (angle <= 180) {
        quadrant = 180;
      } else if (angle <= 270) {
        quadrant = 270;
      }

      const theta = ((quadrant - angle) * Math.PI) / 180;

      size = RotationHelper.getRotatedSize(quadrant - angle, size);

      const rotatedPosition =
        direction === 'x'
          ? {
              x: position.x,
              y: position.y + size.width * Math.sin(theta),
            }
          : {
              x: position.x + size.width * Math.cos(theta),
              y: position.y,
            };

      // Flip after calculating rotatedPosition!
      if ((angle >= 0 && angle <= 90) || (angle > 180 && angle <= 270)) {
        const w = size.width;
        const h = size.height;
        size.width = h;
        size.height = w;
      }

      const elementPosition = RotationHelper.rotate(rotatedPosition, -angle, center);

      if (direction === 'x') {
        position.x = elementPosition.x - (angle > 90 && angle <= 270 ? size.width : 0);
        position.y = elementPosition.y - (angle <= 180 ? size.height : 0);
      } else {
        position.x = elementPosition.x - (angle > 180 ? size.width : 0);
        position.y = elementPosition.y - (angle > 90 && angle <= 270 ? size.height : 0);
      }
    }

    return {
      position,
      size,
    };
  }

  private removeGuideline(id: string) {
    const index = this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.findIndex((g) => g.id === id);
    if (index > -1) {
      this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.splice(index, 1);
    }
  }

  /**
   * Remove guidelines by @positions except for guideline with @id
   * @param id
   * @param positions
   */
  private removeGuidelineByPosition(id: string, positions: string[]) {
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.forEach((guideline) => {
      positions.forEach((position) => {
        if (guideline.id !== id && guideline.id.includes(position)) {
          this.removeGuideline(guideline.id);
        }
      });
    });
  }

  private drawGuideline(
    id: string,
    direction: string,
    elementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
  ) {
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.push({
      id,
      lineDefinition: this.guidelineService.calculateGuidelineDefinition(
        id,
        direction,
        elementCoordinates,
        otherElementCoordinates,
      ),
    });
  }

  private snapToPosition(snapDimensions: SnapDimensions, snapLineDefinition: LineDefinition) {
    this.hasSnapped = true;
    if (this.selectedElements.length === 1 && !this.isMask) {
      const element: CanvasElement = this.selectedElements[0];
      const oldElement: DocumentElement = this.startingElements.get(element.id);
      if (element.elementDefinition.type === 'line') {
        if (snapLineDefinition) {
          const lineDefinition: any = {};
          lineDefinition.x1 = snapLineDefinition.x1;
          lineDefinition.y1 = snapLineDefinition.y1;
          lineDefinition.x2 = snapLineDefinition.x2;
          lineDefinition.y2 = snapLineDefinition.y2;
          this.resizeHandler.setElementSize(element, oldElement, { lineDefinition });
        }
      } else if (element.isItemComponent()) {
        const width = Math.round(snapDimensions.size.width - (element.PADDING_LEFT + element.PADDING_RIGHT));
        this.resizeHandler.setElementSize(element, oldElement, {
          size: { width },
          position: {
            x: snapDimensions.position.x + element.PADDING_LEFT,
            y: snapDimensions.position.y + element.PADDING_TOP,
          },
        });
      } else if (element.isColorComponent()) {
        const width = Math.round(snapDimensions.size.width) - 10;
        const height = width;
        this.resizeHandler.setElementSize(element, oldElement, {
          size: { width, height },
          position: { x: snapDimensions.position.x, y: snapDimensions.position.y },
        });
      } else {
        let position = { ...snapDimensions.position };
        let size = { ...snapDimensions.size };
        if (element.USE_OUTER_EDGE) {
          let lineWidth = Number(element.elementDefinition?.style?.border?.width ?? 0);
          position = {
            x: position.x + lineWidth * 0.5,
            y: position.y + lineWidth * 0.5,
          };
          size = {
            width: size.width - lineWidth,
            height: size.height - lineWidth,
          };
        }

        this.resizeHandler.setElementSize(element, oldElement, { size, position });
      }
    } else {
      let scaleX = snapDimensions.size.width / this.startingBox.width;
      let scaleY = snapDimensions.size.height / this.startingBox.height;

      if (this.keepAspectRatio) {
        // shiftKey
        const scale = Math.max(scaleX, scaleY);
        scaleX = scale;
        scaleY = scale;
      }

      let anchorX = snapDimensions.position.x + (this.transform.x === -1 ? snapDimensions.size.width : 0);
      let anchorY = snapDimensions.position.y + (this.transform.y === -1 ? snapDimensions.size.height : 0);

      for (let i = 0; i < this.selectedElements?.length; i++) {
        const element: CanvasElement = this.selectedElements[i];
        const oldElement: DocumentElement = this.startingElements.get(element.id);
        this.resizeHandler.setElementSize(element, oldElement, anchorX, anchorY, scaleX, scaleY);
      }
    }
  }

  public dragended(event) {
    this.clear();
  }

  private clear() {
    this.keepAspectRatio = false;
    this.startingBox = null;
    this.box = null;
    this.margin = null;
    this.snapDimensions = null;
    this.snapLineDefinition = null;
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines = [];
  }
}
