import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';
import {
  DEFAULT_LETTER_SPACING,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_TEXT_BORDER_SIZE,
  DEFAULT_TEXT_FONT_FAMILY,
} from './editor/text-editor';

const genericFontFamilies = ['serif', 'sans-serif', 'monospace', 'cursive', 'fantasy', 'system-ui'];

const styleFormatMap = {
  textBackgroundColor: 'backgroundColor',
  textColor: 'color',
  fontSize: 'fontSize',
  fontFamily: 'fontFamily',
};

export type TextStyleAlign = 'left' | 'center' | 'right' | 'justify';
export type TextStyleFontStyle = 'normal' | 'italic' | 'oblique';
export type TextStyleFontVariant = 'normal' | 'small-caps';
export type TextStyleFontWeight =
  | 'normal'
  | 'bold'
  | 'bolder'
  | 'lighter'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
export type TextStyleDecoration = 'underline' | 'none';
export type BorderStyle = 'solid' | 'dotted' | 'dashed';

export class TextStyle {
  private fontStyle: TextStyleFontStyle = 'normal';
  private fontSize: number | string;
  private fontSizeUnit = 'pt';
  public fontFamily: string | string[] = DEFAULT_TEXT_FONT_FAMILY;
  private fontVariant: TextStyleFontVariant = 'normal';
  private fontWeight: TextStyleFontWeight = 'normal';
  private fontColor = '#000000';
  public fontDecoration: TextStyleDecoration = 'none';
  public borderWidth;
  public numericBorderWidth;
  private borderColor = 'rgba(0, 0, 0, 0)';
  private borderStyle: BorderStyle = 'solid';
  private backgroundColor = 'rgba(0, 0, 0, 0)';
  public letterSpacing = 0;
  public wordWrapWidth;
  public textAlign = 'left';
  public textVAlign = 'top';
  public elementDefinition: DocumentElement;

  constructor(public element: CanvasElement | any) {
    this.elementDefinition = this.element.elementDefinition;
    if (this.element.elementDefinition.style?.font?.style === 'italic') {
      this.fontStyle = 'italic';
    }
    if (this.element.elementDefinition.style?.font?.size) {
      this.fontSize = this.element.elementDefinition.style?.font?.size;
    }
    if (this.element.elementDefinition.type === 'sticky_note') {
      this.fontSizeUnit = 'px';
    }
    if (this.element.elementDefinition.style?.font?.family) {
      this.fontFamily = this.element.elementDefinition.style?.font?.family;
    }
    if (this.element.elementDefinition.style?.font?.weight) {
      this.fontWeight = this.element.elementDefinition.style.font.weight as TextStyleFontWeight;
    }
    if (this.element.elementDefinition.style?.color) {
      this.fontColor = this.element.elementDefinition.style.color;
    }
    if (this.element.elementDefinition.style?.text?.decoration) {
      this.fontDecoration = this.element.elementDefinition.style.text.decoration as TextStyleDecoration;
    }
    if (this.element?.isPropertyValid('style.border') && this.element.elementDefinition.style?.border?.width != null) {
      this.borderWidth = `${this.element.elementDefinition.style.border.width}px`;
      this.numericBorderWidth = this.element.elementDefinition.style.border.width;
    } else {
      this.borderWidth = (this.element.DEFAULT_BORDER_SIZE ?? 1) + 'px';
      this.numericBorderWidth = this.element.DEFAULT_BORDER_SIZE ?? 1;
    }
    if (this.element?.isPropertyValid('style.border') && this.element.elementDefinition.style?.border?.color) {
      this.borderColor = this.element.elementDefinition.style.border.color;
    }
    if (this.element?.isPropertyValid('style.border') && this.element.elementDefinition.style?.border?.style) {
      this.borderStyle = this.element.elementDefinition.style.border.style as BorderStyle;
    }
    if (
      this.element?.isPropertyValid('style.backgroundColor') &&
      this.element.elementDefinition.style?.backgroundColor
    ) {
      this.backgroundColor = this.element.elementDefinition.style.backgroundColor;
    }
    if (this.element.elementDefinition.style?.text?.align) {
      this.textAlign = this.element.elementDefinition.style.text.align;
    }
    if (this.element?.isPropertyValid('style.text.valign') && this.element.elementDefinition.style?.text?.valign) {
      this.textVAlign = this.element.elementDefinition.style.text.valign;
    }
    this.wordWrapWidth = this.element.elementDefinition.size.width;
  }

  public static setTextStyle(text: string, format: any) {
    const htmlObject = document.createElement('div');
    htmlObject.innerHTML = text;
    const spanElems = htmlObject.getElementsByTagName('span');

    if (styleFormatMap[format.type]) {
      for (let i = 1; i < spanElems.length; i++) {
        if (spanElems[i].style[styleFormatMap[format.type]]) {
          spanElems[i].style[styleFormatMap[format.type]] = format.value + (format.type === 'fontSize' ? 'pt' : '');
        }
      }
      spanElems[0].style[styleFormatMap[format.type]] = format.value + (format.type === 'fontSize' ? 'pt' : '');
    } else if (['strikethrough', 'underline'].includes(format.type)) {
      for (let i = 1; i < spanElems.length; i++) {
        if (spanElems[i].style[styleFormatMap[format.type]]) {
          spanElems[i].style[styleFormatMap[format.type]] = format.value + (format.type === 'fontSize' ? 'pt' : '');
        }
      }
    }

    return htmlObject.innerHTML;
  }

  public toFontString(): string {
    const fontSizeString = `${this.fontSize || 11}px`;

    let fontFamilies: string | string[] = this.fontFamily;
    if (!Array.isArray(this.fontFamily)) {
      fontFamilies = this.fontFamily.split(',');
    }

    for (let i = fontFamilies.length - 1; i >= 0; i--) {
      let fontFamily = fontFamilies[i].trim();
      if (!/([\"\'])[^\'\"]+\1/.test(fontFamily) && !genericFontFamilies.includes(fontFamily)) {
        fontFamily = `"${fontFamily}"`;
      }
      (fontFamilies as string[])[i] = fontFamily;
    }

    return `${this.fontStyle} ${this.fontVariant} ${this.fontWeight} ${fontSizeString} ${(fontFamilies as string[]).join(',')}`;
  }

  public toStyleString(): string {
    const fontSizeString = this.fontSize ? `${this.fontSize}${this.fontSizeUnit}` : '11pt';

    let fontFamilies: string | string[] = this.fontFamily;

    if (!Array.isArray(this.fontFamily)) {
      fontFamilies = this.fontFamily.split(',');
    }

    for (let i = fontFamilies.length - 1; i >= 0; i--) {
      let fontFamily = fontFamilies[i].trim();
      if (!/([\"\'])[^\'\"]+\1/.test(fontFamily) && !genericFontFamilies.includes(fontFamily)) {
        fontFamily = `${fontFamily}`;
      }
      (fontFamilies as string[])[i] = fontFamily;
    }
    return `background-color:${this.backgroundColor}; border-style:${this.borderStyle}; border-color:${this.borderColor}; border-width:${this.borderWidth}; line-height: ${DEFAULT_LINE_HEIGHT}; letter-spacing: ${DEFAULT_LETTER_SPACING}px; font-style:${this.fontStyle}; font-weight:${this.fontWeight}; color:${this.fontColor}; font-size:${fontSizeString}; text-decoration:${this.fontDecoration}; font-family:${(fontFamilies as string[]).join(',')}; text-align:${this.textAlign || 'left'};`;
  }
}
