import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Clipboard } from './clipboard.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor() {}

  public async getClipboard({ relations }: { relations?: string[] } = { relations: [] }): Promise<Clipboard> {
    const userClipboards = await new Entities().get({ entityName: 'clipboard', relations });
    if (userClipboards.length !== 1) {
      return;
    }

    return userClipboards[0];
  }
}
