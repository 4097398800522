import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { FileDownloader } from '../../file-downloader';
import { SVGCombiner } from '../svg/svg-combiner';
import { CanvasImageDrawableElement } from './canvas-image-drawable-element';

export class CanvasImageElement extends CanvasImageDrawableElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.currentUrl = this.elementDefinition.url;
    this.isAsync = true;
    this.isCropEnabled = true;
  }

  public toSVG({ x, y, width, height, href }): HTMLElement {
    const element = document.createElement('image');
    element.setAttributeNS('w3.org/1999/xlink', 'xlink:href', href);
    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);

    if (this.elementDefinition?.style?.opacity) {
      element.style.opacity = `${this.elementDefinition.style.opacity}`;
    }

    return element;
  }

  public async loadAsSVG({ x, y, width, height }): Promise<HTMLElement> {
    const img: HTMLImageElement = await this.preload(null, true);
    if (!this.blob) {
      return;
    }
    let href = await SVGCombiner.fileToBase64(this.blob);

    if (this.isCropped() && this.imageSize) {
      let canvas = document.createElement('canvas');
      const uncroppedSize = this.getSize({ uncroppedDimensions: true });
      const { x1Percent, y1Percent } = this.getCropPercent();
      const dpr = this.canvasDocument.getDevicePixelRatio();
      const ctx = canvas.getContext('2d');
      const scale = Math.min(uncroppedSize.width / this.imageSize.width, uncroppedSize.height / this.imageSize.height);
      const sx = (x1Percent * uncroppedSize.width) / scale;
      const sy = (y1Percent * uncroppedSize.height) / scale;
      const sw = width / scale;
      const sh = height / scale;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(img, sx, sy, sw, sh, 0, 0, width * dpr, height * dpr);
      href = canvas.toDataURL('image/png', 1.0);
      canvas.width = canvas.height = 0;
      canvas = null;
    }

    return this.toSVG({ x, y, width, height, href });
  }
}
