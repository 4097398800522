export interface EntitySnapshot {
  id?: string;
  createdOn?: Date;
  snapshot: any;
}
export interface State {
  currentEntitySnapshot: EntitySnapshot;
}

export const documentHistoryInitialState: State = {
  currentEntitySnapshot: null,
};
