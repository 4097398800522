import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/common/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFontsLoader {
  public static customFonts: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
  ) {
    CustomFontsLoader.init(this.authService, this.document);
  }

  private static init(authService, document) {
    const currentOrg = authService.getCurrentOrg();
    if (currentOrg?.orgSlug) {
      let fileName = `${currentOrg.orgSlug}.css`;
      let fileName2;
      if (currentOrg?.orgSlug?.indexOf('ralph') !== -1) {
        fileName = 'ralph.css';
        fileName2 = `${currentOrg.orgSlug}.css`;
      } else if (currentOrg?.orgSlug?.indexOf('michael-kors') !== -1) {
        fileName = 'michael-kors.css';
      } else if (currentOrg?.orgSlug?.indexOf('agron') !== -1) {
        fileName = 'agron.css';
      }
      this.loadStyle(fileName, document);
      if (fileName2) {
        this.loadStyle(fileName2, document);
      }
    }
  }

  /**
   * Appends file with @styleName as <style> to the document
   * @param styleName
   * @param document
   */
  private static loadStyle(styleName: string, document: Document) {
    const head = document.getElementsByTagName('head')[0];

    let themeLink = document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}
