import { Component, Input, OnChanges } from '@angular/core';
import { User } from '../../auth/auth.service';
import * as moment from 'moment';
@Component({
  selector: 'app-comment-user-header',
  template: `
    <app-avatar [user]="user" class="mr-2"></app-avatar>
    <div class="flex flex-col w-full">
      <div class="text-black80 text-sm">
        {{ name }}
      </div>
      <div class="text-black54 tracking-wide text-xs">
        {{ dateDisplay }}
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class CommentUserHeader implements OnChanges {
  @Input() user: User;
  @Input() date: Date;
  name: string;
  dateDisplay;
  ngOnChanges() {
    if (this.user) {
      this.name = this.user.firstName ? [this.user.firstName, this.user?.lastName].join(' ') : this.user.email;
    }
    if (this.date) {
      const createdOn = moment(this.date);
      if (createdOn.isSame(new Date(), 'day')) {
        this.dateDisplay = createdOn.format('h:mm a') + ' Today';
      } else if (createdOn.isSame(new Date(), 'year')) {
        this.dateDisplay = createdOn.format('h:mm a MMM Do');
      } else {
        this.dateDisplay = createdOn.format('h:mm a MMM Do YYYY');
      }
    }
  }
}
