import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DocumentService } from '../../document.service';
import { ActionRequest } from '@contrail/actions';
import { DocumentElement } from '@contrail/documents';

@Component({
  selector: 'app-property-configurator-locked-element',
  templateUrl: './property-configurator-locked-element.component.html',
  styleUrls: ['./property-configurator-locked-element.component.scss'],
})
export class PropertyConfiguratorLockedElementComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() element: DocumentElement;

  public isLocked = false;

  constructor(private documentService: DocumentService) {}

  ngOnChanges(): void {
    this.isLocked = this.currentValue;
  }

  toggleLocked() {
    this.isLocked = !this.isLocked;
    this.setValue();
  }

  setValue() {
    this.documentService.handleActionRequest(
      new ActionRequest('lock_elements', {
        element: this.element,
      }),
    );
  }
}
