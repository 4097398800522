import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CanvasDocument, CANVAS_MODE } from 'src/app/boards/board/canvas/canvas-document';
import { Document, DocumentAction, PositionDefinition, SizeDefinition, ViewBox } from '@contrail/documents';
import { environment } from 'src/environments/environment';
import { DocumentTemplatesService } from '../document-templates.service';
import { GridSpaceDefinition } from '@contrail/document-generation';
import { Subscription } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';
import { customFonts } from '@common/custom-fonts/custom-fonts-store/custom-fonts.selectors';

@Component({
  selector: 'app-frame-template-canvas',
  templateUrl: './frame-template-canvas.component.html',
  styleUrls: ['./frame-template-canvas.component.scss'],
})
export class FrameTemplateCanvas implements OnInit, OnDestroy {
  @Input() authContext: any;
  @Input() document: Document;
  @Input() ownerReference: string;
  @Output() gridSpaceDefinitionSet = new EventEmitter();
  public canvasDocument: CanvasDocument;
  subscriptions: Subscription = new Subscription();
  documentTemplatesService: DocumentTemplatesService;
  maxWidth = 820;
  maxHeight = 460;
  documentWidth = '900px';
  gridSpaceDefinition: GridSpaceDefinition;
  gridSpaceDefinitionId = 'gridSpace';
  private customFonts: string;

  constructor(private store: Store<RootStoreState.State>) {
    this.documentTemplatesService = new DocumentTemplatesService();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.documentTemplatesService.documentActions.subscribe((actions) => {
        this.handleActions(actions);
      }),
    );
    this.subscriptions.add(
      this.store.select(CustomFontsSelectors.customFonts).subscribe((customFonts) => {
        this.customFonts = customFonts;
      }),
    );
    this.loadCanvas();
  }

  ngOnDestroy(): void {
    this.canvasDocument.clear();
    this.subscriptions.unsubscribe();
    this.documentTemplatesService = null;
  }

  private async loadCanvas() {
    this.canvasDocument = new CanvasDocument(
      'frameTemplateEditorCanvas',
      this.document,
      this.documentTemplatesService,
      CANVAS_MODE.EDIT,
      this.authContext,
      { imageHost: environment.imageHost },
      [],
      this.customFonts,
    );

    const canvasViewBox: ViewBox = {
      width: this.document.size.width,
      height: this.document.size.height,
      x: 0,
      y: 0,
    };

    let height = (this.maxWidth / this.document.size.width) * this.document.size.height;
    let documentViewBox: ViewBox = { width: this.maxWidth, height, x: 0, y: 0 };
    let canvasSize = { width: this.maxWidth, height };

    if (height > this.maxHeight) {
      let width = (this.maxHeight / this.document.size.height) * this.document.size.width;
      width = Math.min(this.maxWidth, width);
      documentViewBox = { width, height: this.maxHeight, x: 0, y: 0 };
      canvasSize = { width, height: this.maxHeight };
    }
    this.documentWidth = `${canvasSize.width + 2}px`;
    this.documentTemplatesService.setRenderer(this.canvasDocument);
    this.documentTemplatesService.init(this.document, this.ownerReference);
    this.canvasDocument.canvasDisplay.setSize(this.document.size, documentViewBox, canvasSize, canvasViewBox);
    this.canvasDocument.draw();
  }

  private handleActions(actions: DocumentAction[]) {
    if (!actions?.length) {
      return;
    }
    if (actions[0].changeDefinition.changeType === 'MODIFY_ELEMENT') {
      const changes = [];
      let elementIndex = this.document.elements.findIndex(
        (element) => element.id === actions[0].changeDefinition.elementId,
      );
      this.document.elements[elementIndex] = ObjectUtil.mergeDeep(
        ObjectUtil.cloneDeep(this.document.elements[elementIndex]),
        actions[0].changeDefinition.elementData,
      );
      changes.push(actions[0].changeDefinition.elementData);
      if (actions[0].changeDefinition.elementId === this.gridSpaceDefinitionId) {
        this.gridSpaceDefinition = {
          size: this.document.elements[elementIndex].size,
          position: this.document.elements[elementIndex].position,
        };
        this.canvasDocument.applyChanges(changes);
        this.gridSpaceDefinitionSet.emit(this.gridSpaceDefinition);
      }
    }
  }

  public getDocumentData(): Document {
    const elements = this.document.elements.filter(
      (element) => element.type !== 'frame' && element.id !== this.gridSpaceDefinitionId,
    );
    elements.forEach((element) => {
      delete element.isLocked;
    });
    return {
      size: this.document.size,
      style: this.document.style,
      clipContent: this.document.clipContent,
      elements,
    };
  }

  public getCanvas(): Promise<HTMLCanvasElement> {
    if (this.gridSpaceDefinition) {
      this.canvasDocument.removeElements([this.gridSpaceDefinitionId]);
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(document.getElementById('frameTemplateEditorCanvas').childNodes[0] as HTMLCanvasElement);
      }, 1000); // delay by 1 sec to make sure that the gridSpace is removed from the canvas.
    });
  }

  public addGridSpace(gridSpaceDefinition?: GridSpaceDefinition) {
    let size: SizeDefinition = { width: this.document.size.width - 150, height: this.document.size.height - 150 };
    let position: PositionDefinition = { x: 75, y: 75 };
    if (gridSpaceDefinition) {
      size = gridSpaceDefinition.size;
      position = gridSpaceDefinition.position;
    }
    const gridSpaceElement = {
      id: this.gridSpaceDefinitionId,
      position,
      size,
      type: 'rectangle',
      style: {
        backgroundColor: 'rgba(255, 255, 185, 0.4)',
        border: { width: 2, color: '#2196F3', style: 'dotted' },
      },
    };
    this.documentTemplatesService.setInteractionMode('select');
    this.canvasDocument.addElements([gridSpaceElement]);
    this.gridSpaceDefinition = {
      size,
      position,
    };
    this.document.elements.push(gridSpaceElement);
    this.gridSpaceDefinitionSet.emit(this.gridSpaceDefinition);
  }

  public removeGridSpace() {
    this.gridSpaceDefinition = null;
    this.canvasDocument.removeElements([this.gridSpaceDefinitionId]);
  }
}
