import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BackgroundSizeType, DocumentElement } from '@contrail/documents';
import { ComponentEditorService } from '../component-editor.service';
import { Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectUtil } from '@contrail/util';

export enum THUMBNAIL_RATIO {
  SQUARE = '1x1',
  HORIZONTAL = '4x3',
  VERTICAL = '3x4',
}

@Component({
  selector: 'app-component-editor-thumbnail',
  templateUrl: './component-editor-thumbnail.component.html',
  styleUrls: ['./component-editor-thumbnail.component.scss'],
})
export class ComponentEditorThumbnailComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public componentType: string; // 'ITEM' | 'COLOR'
  @Input() multipleElements: boolean;
  @Output() propertiesChangedEvent = new EventEmitter();
  @Output() componentChangedEvent = new EventEmitter();

  public content: any[];
  public primaryViewableId: string;
  public imageWidth = 74;
  public translateValue = 0;
  public selectedIndex = 0;
  public firstVisibleIndex = 0;
  public thumbnailBaseUrl = environment.thumbnailBaseUrl;
  public imageLoaded = false;
  public thumbnailRatio: THUMBNAIL_RATIO;
  public thumbnailScale: number = 1;
  public thumbnailScaleMin = 1;
  public thumbnailScaleMax = 300;
  public ratios = [
    { label: 'Square (1:1)', key: THUMBNAIL_RATIO.SQUARE },
    { label: 'Horizontal (4:3)', key: THUMBNAIL_RATIO.HORIZONTAL },
    { label: 'Vertical (3:4)', key: THUMBNAIL_RATIO.VERTICAL },
  ];
  public fillImage;

  private loadedComponentElementId = null;
  private item;

  private elements: DocumentElement[] = [];
  public thumbnail: DocumentElement;

  constructor(private componentEditorService: ComponentEditorService) {}

  ngOnInit(): void {
    this.subscribeToDocumentElementDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  subscribeToDocumentElementDetails() {
    this.componentEditorService.componentObjectDetailsObs
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((componentObjectDetails) => {
        if (componentObjectDetails?.entity?.item) {
          this.componentType = 'ITEM';

          this.item = componentObjectDetails.entity?.item;
          this.primaryViewableId = componentObjectDetails.entity?.item?.primaryViewableId;
          this.content = (componentObjectDetails.entity.item?.content || componentObjectDetails.entity.content)?.sort(
            (c1, c2) => (c1.createdOn < c2.createdOn ? -1 : 1),
          );
          if (this.content.length) {
            if (
              this.loadedComponentElementId == null ||
              this.loadedComponentElementId !== componentObjectDetails?.entity?.id
            ) {
              // Selected content is viewable on the component (not necessarily primary viewable)
              this.selectedIndex = this.content.findIndex((c) => {
                return (
                  componentObjectDetails.entity?.viewable?.primaryViewableId === c.id ||
                  (c.primaryFileUrl && c.primaryFileUrl === componentObjectDetails.entity?.viewable?.primaryFileUrl)
                );
              });
            }
            // make sure that the current selected thumbnail is always visible in the carousel
            this.firstVisibleIndex = Math.max(0, this.selectedIndex - 2);
            this.translateValue = -1 * this.imageWidth * this.firstVisibleIndex;
          }
          this.loadedComponentElementId = componentObjectDetails?.entity?.id;
          this.elements = ObjectUtil.cloneDeep(componentObjectDetails.elements).filter((property) => property.enabled);
          const thumbnailProps = this.elements.filter((property) => property.type === 'image');
          this.thumbnail = thumbnailProps.length > 0 ? thumbnailProps[0] : null;
          if (this.thumbnail) {
            const ratio = this.thumbnail.size.width / this.thumbnail.size.height;
            if (ratio === 1) {
              this.thumbnailRatio = THUMBNAIL_RATIO.SQUARE;
            } else if (ratio > 1) {
              this.thumbnailRatio = THUMBNAIL_RATIO.HORIZONTAL;
            } else {
              this.thumbnailRatio = THUMBNAIL_RATIO.VERTICAL;
            }
            this.fillImage = this.thumbnail.style?.background?.size === BackgroundSizeType.COVER;
            this.thumbnailScale = Math.round((this.thumbnail?.scale?.x || 1) * 100);
          }
        } else if (componentObjectDetails?.entity?.color) {
          this.componentType = 'COLOR';
          this.elements = ObjectUtil.cloneDeep(componentObjectDetails.elements).filter((property) => property.enabled);
          const colorRect = this.elements?.filter((e) => e?.type === 'rectangle' && e?.propertyBindings);
          this.thumbnail = colorRect?.length > 0 ? colorRect[0] : null;
          this.thumbnailRatio = THUMBNAIL_RATIO.SQUARE;
        } else {
          this.componentType = null;
          this.thumbnail = null;
        }
      });
  }

  handleImageSizeChange(event) {
    this.thumbnail.size.width = event.value;
    this.updateThumbnailRatio();
  }

  handleImageZoomChange(event) {
    const value = Math.min(Math.max(event.target.valueAsNumber || 1, this.thumbnailScaleMin), this.thumbnailScaleMax);
    const scale = value / 100;
    this.thumbnail.scale = { x: scale, y: scale };
    this.thumbnailScale = Math.round((this.thumbnail?.scale?.x || 1) * 100);
    this.propertiesChangedEvent.emit(this.elements);
  }

  chooseContent(index) {
    this.selectedIndex = index;
    const content = ObjectUtil.cloneDeep(this.content[this.selectedIndex]);
    if (!content.mediumViewableUrl && content.primaryFileUrl) {
      content.mediumViewableUrl = content.primaryFileUrl;
    }
    const updatedComponent = this.componentEditorService.updateLoadedComponentViewable(content, this.item);
    this.componentChangedEvent.emit(updatedComponent);
    this.propertiesChangedEvent.emit();
  }

  slideRight() {
    if (this.content.length - this.firstVisibleIndex > 3) {
      this.firstVisibleIndex = this.firstVisibleIndex + 1;
      this.translateValue = -1 * this.imageWidth * this.firstVisibleIndex;
    }
  }

  slideLeft() {
    if (this.firstVisibleIndex !== 0) {
      this.firstVisibleIndex = this.firstVisibleIndex - 1;
      this.translateValue = this.translateValue + this.imageWidth;
    }
  }

  setThumbnailRatio(ratio) {
    this.thumbnailRatio = ratio.value;
    this.updateThumbnailRatio();
  }

  updateThumbnailRatio() {
    if (this.thumbnailRatio === THUMBNAIL_RATIO.SQUARE) {
      this.thumbnail.size.height = this.thumbnail.size.width;
    } else if (this.thumbnailRatio === THUMBNAIL_RATIO.HORIZONTAL) {
      this.thumbnail.size.height = Math.ceil(this.thumbnail.size.width * (3 / 4));
    } else {
      this.thumbnail.size.height = Math.ceil(this.thumbnail.size.width * (4 / 3));
    }
    this.propertiesChangedEvent.emit(this.elements);
  }

  setBackgroundSize(backgroundSize) {
    if (!this.thumbnail.style) this.thumbnail.style = {};
    if (!this.thumbnail.style.background) this.thumbnail.style.background = {};
    if (backgroundSize.checked) {
      this.thumbnail.style.background.size = BackgroundSizeType.COVER;
    } else {
      this.thumbnail.style.background.size = BackgroundSizeType.CONTAIN;
    }
    this.propertiesChangedEvent.emit(this.elements);
  }
}
