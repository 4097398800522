<button
  *ngIf="isMaskAllowed || isRemoveMaskAllowed"
  mat-icon-button
  class="toggle-icon"
  (click)="toggleMask()"
  data-test="toggle-mask"
  [matTooltip]="isMaskAllowed ? 'Create Mask' : 'Remove Mask'"
  matTooltipPosition="above"
>
  <mat-icon [ngClass]="{ active: isRemoveMaskAllowed }">tonality</mat-icon>
</button>
