import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { PropertyType } from '@contrail/types';
import { DocumentPropertyDefinition, PanelPropertyTemplate } from '@contrail/document-generation';
import { AuthService } from '@common/auth/auth.service';
import { DocumentGenerationConfig, GridTemplateDimensionsDefinition } from '../document-generator.interfaces';
import { FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { GenerateDocumentUtil } from '../generate-document-util';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { firstValueFrom, Subscription, tap } from 'rxjs';
import { BoardService } from '../../board.service';
import { FilterConditionType } from '@common/types/filters/filter-condition-types';
import { GeneratorFrameTemplateChooserComponent } from '../generator-frame-template-chooser/generator-frame-template-chooser.component';
import { DocumentGeneratorSourceSelectorComponent } from '../document-generator-source-selector/document-generator-source-selector.component';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { ObjectUtil } from '@contrail/util';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { PropertyViewTemplateBuilderComponent } from '../property-view-template-builder/property-view-template-builder.component';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { FilterDefinition, FilterPropertyDefinition, FilterPropertyCriteria } from '@contrail/filters';
import { ClipboardItemsService } from '@common/clipboard/clipboard-items.service';
import { AuthSelectors } from '@common/auth/auth-store';
import { AssortmentsService } from '@common/assortments/assortments.service';

@Component({
  selector: 'app-document-generator-config',
  templateUrl: './document-generator-config.component.html',
  styleUrls: ['./document-generator-config.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class DocumentGeneratorConfigComponent implements OnInit, OnDestroy {
  // when we update STEP 1 (source selector), we need to update document-generator-source-modal.component.ts
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('sourceSelector') sourceSelector: DocumentGeneratorSourceSelectorComponent;
  @ViewChild('templateChooser') templateChooser: GeneratorFrameTemplateChooserComponent;
  @ViewChild('itemCardViewDefinitionBuilder') itemCardViewDefinitionBuilder: PropertyViewTemplateBuilderComponent;
  @ViewChild('framePanelViewDefinitionBuilder') framePanelViewDefinitionBuilder: PropertyViewTemplateBuilderComponent;
  public assortmentId;
  public availableProperties: Array<any>;
  public selectedProperties = new Map();
  public availablePropertiesForGroup = new Map();
  public selectedSecondaryProperties = [];
  public gridLayoutOrientation = 'HORIZONTAL';
  public itemLevel = 'option';
  public filterDefinition: FilterDefinition;
  public sortProperties: Array<any>;
  public availableViewProperties: Array<DocumentPropertyDefinition>;
  public availablePropertyPanelProperties: Array<DocumentPropertyDefinition>;
  public frameTemplates: Array<any> = [];
  public selectedFrameTemplate: any;
  public propertyTypeDefaultFilterConditions: any = {};
  private propertyMap = {};

  public layoutOptionHeight = 500;
  // Properties to show on comonent/cards
  public selectedComponentProperties: Array<DocumentPropertyDefinition>;
  // Sort Definition
  public sortDefinitions: Array<SortDefinition> = [
    {
      direction: SortDirection.ASCENDING,
      propertyLabel: 'Name',
      propertySlug: 'name',
      propertyType: PropertyType.String,
    },
  ];
  // Frame Panel Config
  public includeFramePanel = false;
  public panelPropertyTemplate: PanelPropertyTemplate;
  public defaultComponentPropertyStyle = GenerateDocumentUtil.DEFAULT_COMPONENT_PROPERTY_STYLE;
  public defaultPanelPropertyStyle = GenerateDocumentUtil.DEFAULT_PANEL_PROPERTY_STYLE;
  public gridTemplateOptions = GenerateDocumentUtil.gridTemplateOptions;

  public gridTemplateDimensions: GridTemplateDimensionsDefinition = this.gridTemplateOptions[0];
  public groupMultiSelectInSeparateFrame = false;
  public isOrgAdmin = false;
  public itemCount = 0;
  public frameCount = 0;
  public loadingFrameCounts = false;
  public loadingItemCounts = false;
  public assortment: any;
  private assortmentPromise: Promise<any>;
  private assortmentItems: any = null;
  public loadingAssortmentItems = false;
  public currentWorkspaceId: string = null;
  public groupings = [];
  private subscriptions: Subscription = new Subscription();
  valid1 = new FormControl('');
  valid2 = new FormControl('');
  sourceValues = [];

  // Feature Flags
  public itemChooserLevelSelectionActive = false;

  public loading = false;
  private userId: string;

  constructor(
    private boardService: BoardService,
    public dialogRef: MatDialogRef<DocumentGeneratorConfigComponent>,
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private frameTemplatesService: FrameTemplatesService,
    public clipboardItemService: ClipboardItemsService,
    private assortmentService: AssortmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {
    this.loading = true;
    const [featureFlags, currentBoard, user] = await Promise.all([
      firstValueFrom(this.store.select(FeatureFlagsSelectors.featureFlags)),
      firstValueFrom(this.boardService.currentBoard),
      this.authService.getCurrentUser(),
    ]);

    const flagNamesSet = new Set(featureFlags.map((f) => f.featureName));
    this.itemChooserLevelSelectionActive = flagNamesSet.has(Feature.ITEM_CHOOSER_LEVEL_SELECTION);
    this.currentWorkspaceId = currentBoard.workspaceId;
    this.userId = user.id;

    this.loading = false;
    this.setState(this.valid1, true);
    this.setState(this.valid2, true);

    this.isOrgAdmin = this.authService.isAdmin();
    this.availableProperties = await GenerateDocumentUtil.generateProperties();

    this.availableViewProperties = this.availableProperties.map((p) => {
      return {
        typeRootSlug: p.typeRootSlug,
        propertyDefinition: p.propertyDefinition,
        slug: p.slug === 'project.name' ? 'projectItem.' + p.propertyDefinition.slug : p.propertyDefinition.slug,
        includeLabel: false,
      };
    });

    this.availablePropertyPanelProperties = this.availableProperties
      .filter((p) => p.propertyDefinition?.propertyLevel !== 'option' && p.propertyDefinition?.slug !== 'optionName')
      .map((p) => {
        return {
          typeRootSlug: p.typeRootSlug,
          propertyDefinition: p.propertyDefinition,
          slug: p.slug === 'project.name' ? 'projectItem.' + p.propertyDefinition.slug : p.propertyDefinition.slug,
          includeLabel: false,
        };
      });

    await this.initFilterDefinition();
    this.initDefaultPropertyComponentDefinitions();
    this.subscriptions.add(
      this.store
        .select(FrameTemplatesSelectors.frameTemplates)
        .pipe(
          tap((frameTemplates: FrameTemplate[]) => {
            if (frameTemplates.length > 0) {
              // Filter out templates without gridSpaceDefinition
              this.frameTemplates = ObjectUtil.cloneDeep(frameTemplates).filter(
                (template) => template.gridSpaceDefinition,
              );
              this.frameTemplates.unshift({ id: 'default', name: 'Default' });
            }
          }),
        )
        .subscribe(),
    );
    this.groupings.push(0);
    this.availablePropertiesForGroup.set(-1, ObjectUtil.cloneDeep(this.availableProperties));
    this.availablePropertiesForGroup.set(0, ObjectUtil.cloneDeep(this.availableProperties));
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initDefaultPropertyComponentDefinitions() {
    this.selectedComponentProperties = await GenerateDocumentUtil.getDefaultPropertyComponentDefinitions();
  }

  async initFilterDefinition() {
    const definitions = await GenerateDocumentUtil.initFilterAndSortDefinition();
    this.propertyMap = definitions.propertyMap;
    this.filterDefinition = definitions.filterDefinition;
    this.sortProperties = definitions.sortProperties;
  }

  async handleSelectSource(evt) {
    // this.sourceSelector.chooserSourceOption$.next(evt);
    // this.sourceSelector.showSourceSelector = false;
    let assortmentId;
    if (evt?.sourceType === 'CLIPBOARD') {
      assortmentId = 'clipboard:' + this.userId;
    } else {
      assortmentId = evt?.id;
    }
    this.setAssortment(assortmentId);
  }

  public async setAssortment(assortmentId) {
    if (!assortmentId) {
      this.assortmentId = null;
      this.assortmentItems = [];
      this.frameCount = 0;
      this.itemCount = 0;
    } else {
      this.assortmentId = assortmentId;
      this.loadingAssortmentItems = true;

      const selectedPropertiesSize = this.selectedProperties.size;

      if (assortmentId.includes('clipboard')) {
        const allClipboardItems = await this.clipboardItemService.getClipboardItems();
        let optionItemData = [];
        let familyItemData = [];
        allClipboardItems.forEach((c) => {
          if (c?.item?.roles.includes('option')) {
            optionItemData.push(c);
          } else if (c?.item?.roles.includes('family')) {
            familyItemData.push(c);
          }
        });
        this.assortment = {
          id: this.assortmentId, // clipboard:userId
          optionItemData,
          familyItemData,
        };
      } else {
        // reset previous assortment
        this.assortment = null;
        this.assortmentItems = [];
        this.frameCount = 0;
        this.itemCount = 0;

        // start loading assortment and count at the same time
        this.assortmentPromise = GenerateDocumentUtil.getAssortment(
          this.assortmentId,
          this.itemChooserLevelSelectionActive,
        );

        // do not wait for assortment to load and show item count
        this.itemCount = await this.assortmentService.getAssortmentItemsCount(assortmentId);
        const invalid = this.itemCount < 1 ? true : false;
        this.setState(this.valid1, invalid);

        // set loading if groupings were set previously to let the user know we are loading data
        if (selectedPropertiesSize > 0) {
          this.loadingFrameCounts = true;
          if (this.filterDefinition.filterCriteria.propertyCriteria.filter((v) => v.criteriaValue).length > 0) {
            this.loadingItemCounts = true;
          }
        }

        this.loadingAssortmentItems = false;

        // wait for assortment to load, set assortmentItems and get counts below
        const assortment = await this.assortmentPromise;

        // return if assortment was changed while promise was still running
        if (assortmentId !== this.assortmentId) return;

        // set assortment and run the code below to set items and counts
        this.assortment = assortment;
      }

      if (
        this.itemChooserLevelSelectionActive &&
        this.assortment.optionItemData.length === 0 &&
        this.assortment.familyItemData.length > 0
      ) {
        // if no option-level item is found, use the family-level
        this.assortmentItems = this.assortment.familyItemData;
        this.itemLevel = 'family';
      } else {
        this.assortmentItems = this.assortment.optionItemData;
        this.itemLevel = 'option';
      }

      this.itemCount = this.assortmentItems.length;

      if (selectedPropertiesSize > 0) {
        this.getFrameItemCounts(true);
      }

      const invalid = this.itemCount < 1 ? true : false;
      this.setState(this.valid1, invalid);
      this.loadingAssortmentItems = false;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  setState(control: FormControl, invalid: boolean) {
    if (invalid) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }

  next() {
    this.stepper.next();
  }

  back() {
    this.stepper.previous();
  }

  canGoBack() {
    return this.stepper?.selectedIndex > 0;
  }

  canGoForward() {
    return this.stepper?.selectedIndex < 4;
  }

  canComplete() {
    return [4].includes(this.stepper?.selectedIndex);
  }

  isSourceSelectorNext() {
    if (this.stepper?.selectedIndex === 0 && this.sourceSelector?.isNext) {
      return true;
    } else {
      return false;
    }
  }

  isValid() {
    if (
      this.stepper.selectedIndex === 0 &&
      (this.loadingAssortmentItems || !this.assortmentId || !this.assortmentItems || this.valid1.invalid)
    ) {
      return false;
    }
    if (
      this.stepper.selectedIndex === 1 &&
      (this.selectedProperties.size === 0 || this.itemCount === 0 || this.frameCount === 0)
    ) {
      return false;
    }
    if (this.stepper.selectedIndex === 2 && (this.itemCount === 0 || this.frameCount === 0)) {
      return false;
    }
    return true;
  }

  async done() {
    const generationOptions: any = await this.getGenerationOptions();
    const groupingProperties = Array.from(this.selectedProperties.values()).map((prop) => {
      return { slug: prop.propertyDefinition.slug };
    });
    const secondaryGroupingProperties = this.selectedSecondaryProperties.map((prop) => {
      return { slug: prop.propertyDefinition.slug };
    });

    const generationConfig: DocumentGenerationConfig = await GenerateDocumentUtil.getGenerationConfig(
      this.assortmentId,
      groupingProperties,
      secondaryGroupingProperties,
      this.filterDefinition,
      this.sortDefinitions,
      this.selectedComponentProperties,
      this.gridLayoutOrientation,
      this.gridTemplateDimensions,
      this.groupMultiSelectInSeparateFrame,
      this.includeFramePanel,
      this.panelPropertyTemplate,
      this.selectedFrameTemplate,
      this.itemLevel,
    );
    const data = {
      generationOptions,
      generationConfig,
    };
    this.dialogRef.close(data);
  }

  async selectGroupingProperty(option, index) {
    if (option) {
      this.selectedProperties.set(index, option?.value);
    } else {
      this.selectedProperties.delete(index);
    }
    this.setAvailableProperties();
    this.setState(this.valid2, false);
    this.getFrameItemCounts(true);
    this.resetFilters();
  }

  async selectSecondaryGroupingProperty(option) {
    if (option) {
      this.selectedSecondaryProperties = [];
      const property = ObjectUtil.cloneDeep(option?.value);
      property.isSecondaryGroup = true;
      this.selectedSecondaryProperties.push(property);
    } else {
      this.selectedSecondaryProperties = [];
    }
    this.setAvailableProperties();
    this.getFrameItemCounts(true);
    this.resetFilters();
  }

  private assortmentPromiseCount = 0;
  public async getFrameItemCounts(refreshSourceValues = false) {
    if (!this.assortment && this.assortmentPromise) {
      if (this.assortmentPromiseCount > 0) return; // avoid calling it multiple times
      this.assortmentPromiseCount++;
      // wait for assortment promise to complete in background
      this.loadingFrameCounts = true;
      console.warn('Resolving assortment promise');
      await this.assortmentPromise;
    }
    this.assortmentPromiseCount = 0;
    if (this.itemLevel === 'family') {
      this.assortmentItems = this.assortment.familyItemData;
    } else {
      this.assortmentItems = this.assortment.optionItemData;
    }
    if (this.selectedProperties.size === 0) {
      this.frameCount = 0;
      this.itemCount = this.assortmentItems.length;
    } else {
      this.loadingFrameCounts = true;
      const results = await GenerateDocumentUtil.generateDocumentElements(
        await this.getGenerationOptions(),
        {
          x: 0,
          y: 0,
        },
        { getCountsOnly: true },
      );
      if (refreshSourceValues) {
        // Clone deep slows down CPU for large assortments
        this.sourceValues = results.filteredData; // ObjectUtil.cloneDeep(results.filteredData);
      }
      this.itemCount = results.itemCount;
      this.frameCount = results.frameCount;
      // this.itemCount = results.documentElements.filter((de) => de.type === 'component').length;
      // this.frameCount = results.documentElements.filter((de) => de.type === 'frame').length;
    }
    this.loadingFrameCounts = false;
    this.loadingItemCounts = false;
  }

  setAvailableProperties() {
    let selectedPropertyIds = Array.from(this.selectedProperties.values()).map((prop) => prop.propertyDefinition.id);
    const selectedSecondaryProperty =
      this.selectedSecondaryProperties.length > 0 ? this.selectedSecondaryProperties[0] : null;
    if (selectedSecondaryProperty) {
      selectedPropertyIds.push(selectedSecondaryProperty.propertyDefinition.id);
    }
    this.availablePropertiesForGroup.forEach((props, key) => {
      const selectedProperties = this.selectedProperties.get(key)
        ? selectedPropertyIds.filter((id) => this.selectedProperties.get(key).propertyDefinition.id !== id)
        : selectedPropertyIds;
      this.availablePropertiesForGroup.set(
        key,
        this.availableProperties?.filter((prop) => !selectedProperties.includes(prop.propertyDefinition.id)),
      );
    });

    const selectedProperties = selectedSecondaryProperty
      ? selectedPropertyIds.filter((id) => selectedSecondaryProperty.propertyDefinition.id !== id)
      : selectedPropertyIds;
    this.availablePropertiesForGroup.set(
      -1,
      this.availableProperties?.filter((prop) => !selectedProperties.includes(prop.propertyDefinition.id)),
    );
  }

  public addGrouping() {
    this.groupings.push(this.groupings.length);
    this.availablePropertiesForGroup.set(this.groupings.length - 1, []);
    this.setAvailableProperties();
  }

  public removeGrouping(index) {
    if (this.selectedProperties.get(index)) {
      this.selectedProperties.delete(index);
      // reposition the groupings
      const newSelectedProperties = new Map();
      let i = 0;
      this.selectedProperties.forEach((value, key) => {
        newSelectedProperties.set(i, value);
        i++;
      });
      this.selectedProperties = newSelectedProperties;
      this.getFrameItemCounts(true);
    }
    this.groupings.splice(index, 1);
    this.setAvailableProperties();
    this.resetFilters();
  }

  private resetFilters() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    Array.from(this.selectedProperties.values())
      .concat(this.selectedSecondaryProperties)
      .forEach((prop) => {
        const filterPropertyDefinition: FilterPropertyDefinition = this.propertyMap[prop.propertyDefinition.slug];
        if (
          !this.filterDefinition.filterCriteria.propertyCriteria.find(
            (filterProp) => filterProp.filterPropertyDefinition.slug === prop.propertyDefinition.slug,
          )
        ) {
          const propertyCriteria: FilterPropertyCriteria = {
            filterPropertyDefinition,
            filterConditionType: [PropertyType.SingleSelect, PropertyType.String].includes(
              filterPropertyDefinition.propertyType,
            )
              ? FilterConditionType.IS_ANY_OF
              : FilterConditionType.EQUALS,
          };
          if (filterPropertyDefinition.propertyType === PropertyType.SingleSelect) {
            propertyCriteria.criteriaValue = filterPropertyDefinition.options.map((prop) => prop.value);
          }
          this.filterDefinition.filterCriteria.propertyCriteria.push(propertyCriteria);
        }
      });
  }

  private async getGenerationOptions() {
    const properties = Array.from(this.selectedProperties.values()).concat(this.selectedSecondaryProperties);
    const generationOptions: any = {
      assortmentItems: this.assortmentItems,
      groupingProperties: properties,
      gridLayoutOrientation: this.gridLayoutOrientation,
      filterDefinition: this.filterDefinition,
      sortProperties: this.sortDefinitions,
      itemComponentProperties: this.selectedComponentProperties,
      gridLayoutDimensions: this.gridTemplateDimensions,
      groupMultiSelectInSeparateFrame: this.groupMultiSelectInSeparateFrame,
    };
    if (this.includeFramePanel) {
      generationOptions.panelPropertyTemplate = this.panelPropertyTemplate;
    }
    if (this.selectedFrameTemplate && this.selectedFrameTemplate.id !== 'default') {
      this.store
        .select(FrameTemplatesSelectors.getFrameTemplateById(this.selectedFrameTemplate.id))
        .pipe(
          tap((frameTemplate: any) => {
            generationOptions.frameTemplate = ObjectUtil.cloneDeep(frameTemplate);
          }),
        )
        .subscribe();
      if (!generationOptions.frameTemplate?.document?.elements) {
        generationOptions.frameTemplate = await this.frameTemplatesService.getFrameTemplateById(
          this.selectedFrameTemplate.id,
        );
      }
    }
    return generationOptions;
  }

  handleFilterChange(changes: any) {
    this.filterDefinition.filterCriteria.propertyCriteria = changes.propertyCriteria;
    this.getFrameItemCounts();
  }

  handleSortChange(sortDefinition: any) {
    this.sortDefinitions = sortDefinition.sorts;
  }

  handlePanelPropertyChange(properties) {
    if (properties?.length) {
      this.panelPropertyTemplate = {
        properties,
      };
    } else {
      this.panelPropertyTemplate = null;
    }
  }

  handleItemCardPropertyChange(properties) {
    if (properties?.length) {
      this.selectedComponentProperties = properties;
    } else {
      this.selectedComponentProperties = [];
    }
  }

  handleClick() {
    this.templateChooser?.closeChooser();
  }

  handleSetFrameTemplate(template) {
    this.selectedFrameTemplate = template;
    this.getFrameItemCounts();
  }

  handlePropertiesStepClick() {
    this.itemCardViewDefinitionBuilder?.hideFormatBar();
    this.framePanelViewDefinitionBuilder?.hideFormatBar();
  }
}
