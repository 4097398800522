import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-border-style',
  templateUrl: './property-configurator-border-style.component.html',
  styleUrls: ['./property-configurator-border-style.component.scss'],
})
export class PropertyConfiguratorBorderStyleComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  public defaultValue = 'solid';
  public borderStyle = this.defaultValue;
  public borderStyles = [
    {
      style: 'dotted',
      svgIconClass: 'border-style-dotted',
    },
    {
      style: 'dashed',
      svgIconClass: 'border-style-dashed',
    },
    {
      style: 'solid',
      iconClass: 'horizontal_rule',
    },
  ];

  constructor() {}

  ngOnChanges(): void {
    this.borderStyle = this.currentValue || this.defaultValue;
  }

  setValue(styleValue) {
    this.borderStyle = styleValue;
    const changes = {
      style: {
        border: {
          style: this.borderStyle,
        },
      },
    };
    this.valueChange.emit(changes);
  }

  isMatch(styleValue: string) {
    return styleValue == this.borderStyle || (styleValue === 'solid' && this.borderStyle == null) ? true : false;
  }
}
