import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  take,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { escapeRegExp } from 'lodash';
import { PropertyType } from '@contrail/types';
import { Project } from '@contrail/entity-types';
import { SortOrderOptions } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { RootStoreState } from '@rootstore';
import { ListColumnDefinition } from '@components/list/list-column-definition';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { SortObjects } from '@components/sort/sort-objects';
import { ListSortBy } from '@components/list/list.component';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { AuthSelectors } from '@common/auth/auth-store';
import { Item, ItemDocument } from '../../item';

interface ItemDocumentListItem extends ItemDocument {
  name: string;
  entityType: string;
  projectName: string;
  numberOfOptions: number;
  updatedByEmail: string;
  updatedOn: Date;
}

@Component({
  selector: 'app-item-documents-list',
  templateUrl: './item-documents-list.component.html',
  styleUrls: ['./item-documents-list.component.scss'],
})
export class ItemDocumentsListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() allDocuments: ItemDocument[];
  @Input() projects: Project[];
  @Input() itemFamily: Item;
  @Input() selectedItem: Item;
  @Input() isLoading: boolean;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  private itemDocumentsSubject: Subject<ItemDocumentListItem[]> = new BehaviorSubject([]);
  public itemDocuments$: Observable<ItemDocumentListItem[]> = this.itemDocumentsSubject.asObservable();

  private documentTypeFilterSubject: Subject<string[]> = new BehaviorSubject(null);
  private documentTypeFilter$: Observable<string[]> = this.documentTypeFilterSubject.asObservable();

  private projectFilterSubject: Subject<string[]> = new BehaviorSubject(null);
  private projectFilter$: Observable<string[]> = this.projectFilterSubject.asObservable();

  public filteredItemDocuments$: Observable<ItemDocumentListItem[]>;
  public columnDefinitions: ListColumnDefinition[] = [
    {
      index: 'icon',
      label: 'Type',
      alignment: 'left',
      propertyType: 'icon',
      width: 60,
    },
    { index: 'name', label: 'Name', alignment: 'left', propertyType: PropertyType.String },
    { index: 'projectName', label: 'Project', alignment: 'left', propertyType: PropertyType.String },
    {
      index: 'numberOfOptions',
      label: 'Option Count',
      alignment: 'center',
      width: 150,
      propertyType: PropertyType.Number,
    },
    { index: 'updatedByEmail', label: 'Updated By', alignment: 'right', propertyType: PropertyType.String },
    { index: 'updatedOn', label: 'Date Modified', alignment: 'right', propertyType: PropertyType.Date },
  ];

  public documentTypeFilterDefinition: TypePropertyFormConfiguration = {
    typeProperty: {
      slug: 'type',
      label: 'Type',
      options: [
        { display: 'Plan', value: 'plan' },
        { display: 'Showcase', value: 'showcase' },
        { display: 'Boards', value: 'board' },
      ],
    },
    isFilter: true,
  };

  public projectFilterDefinition: TypePropertyFormConfiguration = {
    typeProperty: {
      slug: 'project',
      label: 'Project',
      options: [],
    },
    isFilter: true,
  };

  public showCurrentUserDocumentsOnlyControl = new UntypedFormControl(false);

  private currentUserId: string;
  private readonly sortOptions: ListSortBy[] = [
    { label: 'Name', direction: SortOrderOptions.ASC },
    { label: 'Type', direction: SortOrderOptions.ASC },
    { label: 'Project', direction: SortOrderOptions.ASC },
    { label: 'Option Count', direction: SortOrderOptions.ASC },
    { label: 'Updated By', direction: SortOrderOptions.ASC },
    { label: 'Date Modified', direction: SortOrderOptions.ASC },
  ];
  private defaultSortBy = this.sortOptions[0];
  public sortByControl = new UntypedFormControl(this.defaultSortBy);

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(WorkspacesSelectors.workspaces)
      .pipe(
        filter((workspaces) => !!workspaces),
        take(1),
      )
      .subscribe((workspaces) => {
        this.projectFilterDefinition.typeProperty.options = workspaces.map((workspace: Workspace) => ({
          display: workspace.name,
          value: workspace.projectId,
        }));
      });

    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        filter((authContext) => !!authContext?.user?.id),
        take(1),
      )
      .subscribe((authContext) => {
        this.currentUserId = authContext.user.id;
      });
  }

  ngAfterViewInit() {
    this.filteredItemDocuments$ = combineLatest([
      this.itemDocuments$,
      this.searchBar?.valueChange.pipe(startWith(''), debounceTime(200), distinctUntilChanged()),
      this.documentTypeFilter$,
      this.projectFilter$,
      this.showCurrentUserDocumentsOnlyControl.valueChanges.pipe(startWith(false)),
      this.sortByControl.valueChanges.pipe(startWith(this.defaultSortBy)),
    ]).pipe(
      map(
        ([
          itemDocuments,
          searchTerm,
          selectedDocumentTypes,
          selectedProjectIds,
          showCurrentUserDocumentsOnly,
          sortBy,
        ]) => {
          const filteredResults = this.filterItemDocuments(itemDocuments, {
            searchTerm,
            selectedDocumentTypes,
            selectedProjectIds,
            showCurrentUserDocumentsOnly,
          });

          const sortedResults = this.sortItemDocuments(filteredResults, sortBy);
          return sortedResults;
        },
      ),
    );
  }

  ngOnChanges(): void {
    if (this.isLoading) {
      return;
    }

    this.buildItemDocuments();
  }

  buildItemDocuments() {
    if (!this.allDocuments || !this.selectedItem || !this.itemFamily) {
      return;
    }

    const isFamilySelected = this.itemFamily.id === this.selectedItem.id;
    const documents = isFamilySelected
      ? this.allDocuments
      : this.allDocuments.filter((document) => document.itemIds.includes(this.selectedItem.id));

    const itemDocuments = documents.map((itemDocument) => {
      const icon = this.workspaceEntitiesHelper.getDocumentIconPath(itemDocument.document.entityType);
      const numberOfOptions = itemDocument.itemIds.filter((id) => id !== this.itemFamily.id).length;
      return {
        ...itemDocument,
        id: itemDocument.document.id,
        icon,
        entityType: itemDocument.document.entityType,
        name: itemDocument.document.name,
        projectName: itemDocument.project.name,
        numberOfOptions,
        updatedByEmail: itemDocument.document.updatedBy.email,
        updatedOn: itemDocument.document.updatedOn,
      };
    });

    this.itemDocumentsSubject.next(itemDocuments);
  }

  filterItemDocuments(
    itemDocuments: ItemDocumentListItem[],
    filterOptions: {
      searchTerm: string;
      selectedDocumentTypes: string[];
      selectedProjectIds: string[];
      showCurrentUserDocumentsOnly: boolean;
    },
  ) {
    const { searchTerm, selectedDocumentTypes, selectedProjectIds, showCurrentUserDocumentsOnly } = filterOptions;
    return itemDocuments.filter((itemDocument) => {
      if (searchTerm?.length) {
        const searchOptions = ['document.name', 'project.name', 'document.updatedBy.email'];
        const matchesSearchTerm = searchOptions.some((key) =>
          new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(itemDocument, key.trim())),
        );
        if (!matchesSearchTerm) return false;
      }

      const isDocumentTypeExcluded = selectedDocumentTypes && !selectedDocumentTypes.includes(itemDocument.entityType);
      if (isDocumentTypeExcluded) {
        return false;
      }

      const isProjectExcluded = selectedProjectIds && !selectedProjectIds.includes(itemDocument.project.id);
      if (isProjectExcluded) {
        return false;
      }

      const isCreatedByUserExcluded =
        showCurrentUserDocumentsOnly && itemDocument.document.createdById !== this.currentUserId;
      if (isCreatedByUserExcluded) {
        return false;
      }

      return true;
    });
  }

  private sortItemDocuments(itemDocuments: ItemDocumentListItem[], sortBy: ListSortBy) {
    const columnDefinition = this.columnDefinitions.find((column) => column.label === sortBy.label);
    const slug = columnDefinition.index === 'icon' ? 'entityType' : columnDefinition.index;
    const sortOrder: SortDefinition[] = [
      {
        propertySlug: slug,
        propertyType: columnDefinition.propertyType as PropertyType,
        direction: sortBy.direction === SortOrderOptions.ASC ? SortDirection.ASCENDING : SortDirection.DESCENDING,
      },
    ];

    const sortedItemDocuments = SortObjects.sort(itemDocuments, sortOrder);
    return sortedItemDocuments;
  }

  clickHeaderColumn(column: { label: string }) {
    const selectedSortBy = this.getSortOptionByLabel(column.label);
    this.sortByControl.setValue(selectedSortBy);
  }

  private getSortOptionByLabel(label: string) {
    const selectedSortBy = this.sortOptions.find((sortOption) => sortOption.label === label);
    const currentSortBy = this.sortByControl.value;
    const isDirectionDescending = Boolean(
      currentSortBy.label === selectedSortBy.label && currentSortBy.direction === SortOrderOptions.ASC,
    );

    const sortDirection = isDirectionDescending ? SortOrderOptions.DESC : SortOrderOptions.ASC;
    return { ...selectedSortBy, direction: sortDirection };
  }

  toggleCreatedByMe(toggle: { checked: boolean }) {
    this.showCurrentUserDocumentsOnlyControl.setValue(toggle.checked);
  }

  goToDocument(document) {
    this.workspaceEntitiesHelper.goToDocumentEntity(document);
  }

  handleProjectFilterChange(filterChange: { value: string[] }) {
    const projectNames = filterChange.value;
    if (!projectNames?.length) {
      this.projectFilterSubject.next(null);
    } else {
      this.projectFilterSubject.next(projectNames);
    }
  }

  handleDocumentTypeFilterChange(filterChange: { value: string[] }) {
    const documentTypes = filterChange.value;
    if (!documentTypes?.length) {
      this.documentTypeFilterSubject.next(null);
    } else {
      this.documentTypeFilterSubject.next(documentTypes);
    }
  }
}
