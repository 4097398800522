<app-modal title="Project Collaborators" [dialogRef]="dialogRef" data-test="workspace-principal-dialog">
  <div class="modal-content overflow-y-hidden max-h-[65vh]">
    <div class="principals-section narrow-scroll">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" class="w-full">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <app-avatar *ngIf="element?.type === 'User'" [user]="element?.user" class="mr-4"></app-avatar>
              <div
                *ngIf="element?.type === 'Group'"
                class="w-8 h-8 rounded-2xl flex-center mr-4"
                [ngStyle]="{ 'background-color': element?.backgroundColor }"
              >
                <span class="material-icons text-white text-xl"> people_alt </span>
              </div>
              <span class="!w-36 line-ellipsis">
                {{ element?.user | userName }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email address</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <span *ngIf="element?.type === 'User'" class="!w-64 line-ellipsis">
                {{ element?.user?.email }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let element" class="min-w-[70px]">
            {{ element?.role | titlecase }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="cursor-pointer"
          [class.opacity-70]="row?.role === 'ORG_ADMIN'"
          [class.pointer-events-none]="row?.role === 'ORG_ADMIN'"
          data-test="workspace-principal-row"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No collaborators</td>
        </tr>
      </table>
    </div>
  </div>
</app-modal>
