import { ProjectItemService } from '@common/projects/project-item.service';
import { DocumentElement, DocumentElementFactory } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';

export class ItemComponentBuilder {
  public static async buildComponent(
    entity: any,
    projectItemService: ProjectItemService,
    options: DocumentElement = {},
    upsertProjectItem = false,
  ): Promise<DocumentElement> {
    let clonedEntity = ObjectUtil.cloneDeep(entity);
    let element;
    let model;
    let modelBindings;
    let viewable;
    let itemId;
    options.propertyBindings = Object.assign(options.propertyBindings || {}, {
      'entityData.roles': 'item.roles',
    });
    if (clonedEntity.entityType === 'item') {
      viewable = entity;
      itemId = clonedEntity.id;
      model = {
        item: clonedEntity,
        viewable: entity,
      };
      modelBindings = {
        item: `item:${clonedEntity.id}`,
        viewable: `item:${clonedEntity.id}`,
      };
      if (clonedEntity.projectItem?.id) {
        modelBindings.projectItem = `project-item:${clonedEntity.projectItem.id}`;
        model.projectItem = clonedEntity.projectItem;
      }
    } else if (clonedEntity.entityType === 'assortment-item') {
      viewable = entity.item;
      itemId = clonedEntity.item.id;
      model = {
        item: clonedEntity.item,
        viewable: entity.item,
      };
      modelBindings = {
        item: `item:${clonedEntity.item.id}`,
        viewable: `item:${clonedEntity.item.id}`,
      };
      if (clonedEntity.projectItem?.id) {
        modelBindings.projectItem = `project-item:${clonedEntity.projectItem.id}`;
        model.projectItem = clonedEntity.projectItem;
      }
      model = Object.assign(model, { assortmentItem: clonedEntity.id });

      if (clonedEntity.assortmentItem) {
        modelBindings = Object.assign(modelBindings, {
          assortmentItem: `assortment-item:${clonedEntity.assortmentItem.id}`,
        });
      } else {
        modelBindings = Object.assign(modelBindings, {
          assortmentItem: `assortment-item:${clonedEntity.id}`,
        });
      }

      clonedEntity = Object.assign(clonedEntity, {
        assortmentItem: ObjectUtil.cloneDeep(clonedEntity),
      });
    }
    // Fetch (with upsert) project item, so that even if we pull
    // from the item library, we will get a project item binding.

    if (upsertProjectItem && !modelBindings.projectItem) {
      const projectItem = await projectItemService.upsertProjectItem(itemId, {});
      if (projectItem) {
        modelBindings.projectItem = `project-item:${projectItem.id}`;
        model.projectItem = projectItem;
      }
    }

    if (clonedEntity.assortmentId) {
      modelBindings.assortment = `assortment:${clonedEntity.assortmentId}`;
    }
    element = DocumentElementFactory.createComponent('item-view-v2', model, modelBindings, options);
    return element;
  }

  public static async buildEmptyComponent(options: DocumentElement = {}): Promise<DocumentElement> {
    let element;
    let model = {};
    let modelBindings = {};
    options.propertyBindings = Object.assign(options.propertyBindings || {}, {
      'entityData.roles': 'item.roles',
    });

    element = DocumentElementFactory.createComponent('item-view-v2', model, modelBindings, options);
    return element;
  }
}
