<app-modal [title]="'Select Assortment'" [dialogRef]="dialogRef">
  <app-assortment-selector
    [spanWorkspaces]="true"
    [defaultCurrentWorkspaceId]="defaultCurrentWorkspaceId"
    [defaultSourceAssortmentId]="defaultSourceAssortmentId"
    (selectedAssortmentEvent)="setAssortment($event)"
  >
  </app-assortment-selector>

  <div class="flex justify-end">
    <button mat-flat-button (click)="closeModal()">Cancel</button>
    <button mat-flat-button color="primary" (click)="assignSourceAssortment()">Ok</button>
  </div>
</app-modal>
