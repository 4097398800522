import { Observable, tap } from 'rxjs';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasImageDrawableElement } from '../../../elements/image/canvas-image-drawable-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class SvgElementEventHandler {
  svgRecolorFeatureFlag$: Observable<boolean>;

  constructor(private canvasDocument: CanvasDocument) {}

  public handleDblClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (!this.canvasDocument.hasSvgRecolorFeatureFlag) return;

    if (
      elementTarget.element?.elementDefinition?.type === 'svg' &&
      !elementTarget.element?.elementDefinition?.isLocked
    ) {
      const svgElement = elementTarget.element as CanvasImageDrawableElement;

      const mouseEvent = event as MouseEvent;
      // clicking the rotation widget should not activate the editor
      if (!svgElement.isMouseOnImage(mouseEvent) || mouseEvent.button === 2) {
        return;
      }

      if (svgElement.isSelected) {
        const mouseEvent = event as MouseEvent;
        mouseEvent.stopPropagation();

        this.canvasDocument.svgEditorHandler.showSvgEditor(svgElement);
      }
    }
  }
}
