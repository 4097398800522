import { Component, Input, OnInit } from '@angular/core';
import { AssortmentHistoryItem } from '../assortment-history.interfaces';

@Component({
  selector: 'app-assortment-history-item',
  templateUrl: './assortment-history-item.component.html',
  styleUrls: ['./assortment-history-item.component.scss'],
})
export class AssortmentHistoryItemComponent implements OnInit {
  @Input() assortmentHistoryItem: AssortmentHistoryItem;
  constructor() {}

  ngOnInit(): void {}

  getName() {
    if (this.assortmentHistoryItem.createdBy?.firstName) {
      return this.assortmentHistoryItem.createdBy?.firstName + ' ' + this.assortmentHistoryItem.createdBy?.lastName;
    }
    return this.assortmentHistoryItem.createdBy?.email;
  }
}
