import { Component, Input } from '@angular/core';
import { EntityReference, Entities } from '@contrail/sdk';

@Component({
  selector: 'app-property-configurator-download',
  templateUrl: './property-configurator-download.component.html',
  styleUrls: ['./property-configurator-download.component.scss'],
})
export class PropertyConfiguratorDownloadComponent {
  @Input() element: any;

  constructor() {}

  async download() {
    let fileUrl;
    if (this.element?.modelBindings?.content) {
      const entity = new EntityReference(this.element?.modelBindings?.content);
      const content = await new Entities().get({ entityName: 'content', id: entity.id, relations: ['primaryFile'] });
      fileUrl = content?.primaryFile?.downloadUrl;
    } else if (this.element?.modelBindings?.image) {
      const entity = new EntityReference(this.element?.modelBindings?.image);
      const file = await new Entities().get({ entityName: 'file', id: entity.id });
      fileUrl = file?.downloadUrl;
    } else if (this.element?.modelBindings?.viewable) {
      const entity = new EntityReference(this.element?.modelBindings?.viewable);
      if (entity?.entityType === 'content') {
        const content = await new Entities().get({ entityName: 'content', id: entity.id, relations: ['primaryFile'] });
        fileUrl = content?.primaryFile?.downloadUrl;
      } else if (entity?.entityType === 'item') {
        const item = await new Entities().get({
          entityName: 'item',
          id: entity.id,
          relations: ['primaryViewable', 'primaryViewable.primaryFile'],
        });
        fileUrl = item?.primaryViewable?.primaryFile?.downloadUrl;
      }
    }

    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }
}
