const text = {
  slug: 'text',
  properties: [
    'background-color',
    'font-color',
    'font-bold',
    'font-italic',
    'font-family',
    'font-size',
    'font-underline',
    'text-alignment',
    'text-valignment',
    'font-strikethrough',
    'border-size',
    'border-color',
    'border-style',
    'highlight-color',
    'clear-format',
    'paint-format',
    'list-type',
    'indentation',
    'link',
  ],
};

const text_tool = {
  slug: 'text_tool',
  properties: [
    'background-color',
    'font-color',
    'font-bold',
    'font-italic',
    'font-family',
    'font-size',
    'font-underline',
    'text-alignment',
    'font-strikethrough',
    'highlight-color',
    'clear-format',
    'paint-format',
    'list-type',
    'indentation',
    'link',
  ],
};

const image = {
  slug: 'image',
  properties: ['border-size', 'border-color', 'border-style', 'image-transparency', 'download', 'crop'],
};

const svg = {
  slug: 'svg',
  properties: [
    'border-size',
    'border-color',
    'border-style',
    'download',
    'recolor-svg',
    // 'crop'
  ],
};

const component = {
  slug: 'component',
  properties: ['border-size', 'border-color', 'border-style', 'component-editor', 'entity-details'],
};

const circle = {
  slug: 'circle',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const rectangle = {
  slug: 'rectangle',
  properties: ['background-color', 'border-size', 'border-color', 'border-radius', 'border-style'],
};

const triangle = {
  slug: 'triangle',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const diamond = {
  slug: 'diamond',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const star = {
  slug: 'star',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const heart = {
  slug: 'heart',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const cloud = {
  slug: 'cloud',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const double_arrow = {
  slug: 'double_arrow',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const right_arrow = {
  slug: 'right_arrow',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const rhombus = {
  slug: 'rhombus',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const line = {
  slug: 'line',
  properties: ['border-size', 'border-color', 'border-style', 'line-marker'],
};

const frame = {
  slug: 'frame',
  properties: ['edit-name', 'background-color', 'clip-content', 'size'],
};

const iframe = {
  slug: 'iframe',
  properties: ['border-size', 'border-color', 'border-style'],
};

const group = {
  slug: 'group',
  properties: ['background-color', 'border-size', 'border-color', 'border-style'],
};

const sticky_note = {
  slug: 'sticky_note',
  properties: [
    'sticky-note-size',
    'sticky-note-background',
    'sticky-note-font-family',
    'sticky-note-font-size',
    'font-color',
    'font-bold',
    'font-italic',
    'font-underline',
    'text-alignment',
    'text-valignment',
    'font-strikethrough',
    // 'list-type'
  ],
};

const pen = {
  slug: 'pen',
  properties: ['pen-border-color', 'pen-border-size'],
};

const highlighter = {
  slug: 'highlighter',
  properties: ['pen-border-color', 'pen-border-size'],
};

export const PROPERTY_MAP = {
  text,
  text_tool,
  image,
  svg,
  component,
  circle,
  rectangle,
  triangle,
  diamond,
  star,
  heart,
  cloud,
  double_arrow,
  right_arrow,
  rhombus,
  line,
  frame,
  iframe,
  group,
  sticky_note,
  pen,
  highlighter,
};
