import { PositionDefinition, SizeDefinition } from '@contrail/documents';
import { BezierCurveArgDefinition } from './drawing-arg-types';

export class SvgCoordinateHelper {
  /**
   * Converts svg coordinate into CanvasElement local space
   * @param svgPoint Svg coordinate
   * @param elementSize Size of CanvasElement
   * @param viewboxSize Size of svg viewbox
   * @param relativeTo Optional offset coordinate
   */
  static convertSvgCoordinate(
    svgPoint: PositionDefinition,
    elementSize: SizeDefinition,
    viewboxSize: SizeDefinition,
    relativeTo: PositionDefinition = {},
  ): PositionDefinition {
    const offsetX = relativeTo?.x != null ? relativeTo.x : 0;
    const offsetY = relativeTo?.y != null ? relativeTo.y : 0;
    const x = (svgPoint.x / viewboxSize.width) * elementSize.width + offsetX;
    const y = (svgPoint.y / viewboxSize.height) * elementSize.height + offsetY;

    return { x, y };
  }

  /**
   * Converts svg curve arguments into CanvasElement local space for use with bezierCurveTo
   * @param svgCp1 Coordinates for control point 1
   * @param svgCp2 Coordinates for control point 2
   * @param svgEp Coordinates for the end point
   * @param elementSize Size of CanvasElement
   * @param viewboxSize Size of svg viewbox
   * @param relativeTo Optional offset coordinate
   */
  static convertSvgCurveArgs(
    svgCp1: PositionDefinition,
    svgCp2: PositionDefinition,
    svgEp: PositionDefinition,
    elementSize: SizeDefinition,
    viewboxSize: SizeDefinition,
    relativeTo: PositionDefinition = {},
  ): BezierCurveArgDefinition {
    const cp1 = SvgCoordinateHelper.convertSvgCoordinate(svgCp1, elementSize, viewboxSize, relativeTo);
    const cp2 = SvgCoordinateHelper.convertSvgCoordinate(svgCp2, elementSize, viewboxSize, relativeTo);
    const ep = SvgCoordinateHelper.convertSvgCoordinate(svgEp, elementSize, viewboxSize, relativeTo);

    return { cp1, cp2, ep };
  }

  static drawBezierCurve(ctx, args: BezierCurveArgDefinition) {
    ctx.bezierCurveTo(args.cp1.x, args.cp1.y, args.cp2.x, args.cp2.y, args.ep.x, args.ep.y);
  }
}
