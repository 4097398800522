import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasDoubleArrowElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }): number[][] {
    let arrowPoint = 0.45 * width;
    if (arrowPoint / height >= 0.5) {
      arrowPoint = 0.5 * height;
    }
    const points = [
      [0.5 * width + x, 0 * height + y],
      [1 * width + x, arrowPoint + y],
      [0.8 * width + x, arrowPoint + y],
      [0.8 * width + x, height - arrowPoint + y],
      [1 * width + x, height - arrowPoint + y],
      [0.5 * width + x, 1 * height + y],
      [0 * width + x, height - arrowPoint + y],
      [0.2 * width + x, height - arrowPoint + y],
      [0.2 * width + x, arrowPoint + y],
      [0 * width + x, arrowPoint + y],
      [0.5 * width + x, 0 * height + y],
    ];
    return points;
  }
}
