import { PositionDefinition, SizeDefinition, TranslateTransformation } from '@contrail/documents';
import { CoordinateBox } from '../../coordinate-box';

export class RotationHelper {
  /**
   * Translates position of an element according to the new center
   * @param position Position that needs to be translated
   * @param angle Element rotate angle
   * @param center Original element center position
   * @param newCenter New element center position
   */
  static translatePosition(
    position: PositionDefinition,
    angle: number,
    center: PositionDefinition,
    newCenter: PositionDefinition,
  ) {
    // Rotated position of the corner that does not change
    const rotatedFixedPosition = this.rotate(
      {
        x: position.x,
        y: position.y,
      },
      angle,
      center,
    );

    // Rotate fixed position by the reverse angle around the new center to get new element position
    const newPosition = this.rotate(rotatedFixedPosition, -angle, newCenter);

    return newPosition;
  }

  /**
   * Gets rotated position in the coordinate system rotated by @angle around @center
   * @param position Element position
   * @param center Element center
   * @param angle Clockwise rotated angle
   */
  static rotate(position: PositionDefinition, angle: number, center: PositionDefinition = { x: 0, y: 0 }) {
    const theta = (angle * Math.PI) / 180;
    // calculates the rotated handle position considering the current center as
    // pivot for rotation
    const dx = position.x - center.x;
    const dy = position.y - center.y;

    return {
      x: dx * Math.cos(theta) - dy * Math.sin(theta) + center.x,
      y: dx * Math.sin(theta) + dy * Math.cos(theta) + center.y,
    };
  }

  /**
   * Get size of rotated rectangle give its bounding box @size
   *
   * https://stackoverflow.com/questions/9971230/calculate-rotated-rectangle-size-from-known-bounding-box-coordinates?rq=4
   * @param angle
   * @param size
   * @returns
   */
  static getRotatedSize(angle, size): SizeDefinition {
    const theta = (angle * Math.PI) / 180;
    const cos = Math.cos(theta);
    const sin = Math.sin(theta);
    const cos2 = cos * cos;
    const sin2 = sin * sin;
    const d = cos2 - sin2;
    return {
      width: (size.width * cos - size.height * sin) / d,
      height: (-size.width * sin + size.height * cos) / d,
    };
  }

  /**
   * Translate element according to @angle
   * @param currentSize
   * @param currentPosition
   * @param angle
   * @param dx
   * @param dy
   * @param transform
   * @param keepAspectRatio
   * @returns
   */
  static translateElement(
    currentSize: SizeDefinition,
    currentPosition: PositionDefinition,
    angle: number = 0,
    dx,
    dy,
    transform: TranslateTransformation,
    keepAspectRatio,
    maxSize?: SizeDefinition,
  ): { size: SizeDefinition; position: PositionDefinition } {
    let changex = dx;
    let changey = dy;

    // Get dx and dy in rotated coordinate system
    if (angle) {
      const rotatedChangePosition = RotationHelper.rotate(
        {
          x: dx,
          y: dy,
        },
        360 - angle,
      );
      changex = rotatedChangePosition.x;
      changey = rotatedChangePosition.y;
    }

    let size: SizeDefinition = {
      width: currentSize.width + transform.x * changex,
      height: currentSize.height + transform.y * changey,
    };

    if (keepAspectRatio) {
      const aspectRatio = Math.max(size.width / currentSize.width, size.height / currentSize.height);
      size = {
        width: currentSize.width * aspectRatio,
        height: currentSize.height * aspectRatio,
      };
      const diffWidth = size.width - currentSize.width;
      const diffHeight = size.height - currentSize.height;
      changex = transform.x === 0 ? diffWidth : transform.x * diffWidth;
      changey = transform.y === 0 ? diffHeight : transform.y * diffHeight;
    }

    if (maxSize?.width) {
      size.width = Math.min(size.width, maxSize.width);
    }
    if (maxSize?.height) {
      size.height = Math.min(size.height, maxSize.height);
    }

    // Calculate new position taking into account element rotation:
    // 1. Get newCenter position by adding distancex/2 and distancey/2 to center
    // 2. Rotate position that stayed unmoved when element was resized around newCenter = newElemenetPosition
    const center = {
      x: currentPosition.x + currentSize.width * 0.5,
      y: currentPosition.y + currentSize.height * 0.5,
    };

    if (transform.x === 0 || transform.y === 0) {
      changex = !keepAspectRatio && transform.x === 0 ? 0 : changex;
      changey = !keepAspectRatio && transform.y === 0 ? 0 : changey;
    }

    // const rotatedChange = RotationHelper.rotate({
    //   x: changex,
    //   y: changey
    // }, angle);
    // dx = rotatedChange.x;
    // dy = rotatedChange.y
    const rotatedChange = RotationHelper.rotate(
      {
        x: transform.x * (size.width - currentSize.width),
        y: transform.y * (size.height - currentSize.height),
      },
      angle,
    );

    // Get new center
    const newCenter = {
      x: center.x + rotatedChange.x * 0.5,
      y: center.y + rotatedChange.y * 0.5,
    };

    // Get new element position by rotating position that stayed unmoved around new center.
    // Unmoved position is calculated as follows:
    // If transform was 1,1 (dragging bottom right), umoved position was top left corner (position.x, position.y)
    // If transform was 1,-1 (dragging top right), unmoved position was bottom left corner (position.x, position.y + height)
    // If transform was -1,1 (dragging bottom left), unmoved position was top right corner (position.x + width, position.y)
    // If transform was -1,-1 (dragging top left), unmoved position was bottom right corner (position.x + width, position.y + height)
    const newElementPosition = RotationHelper.translatePosition(
      {
        x: currentPosition.x + (transform.x === -1 ? currentSize.width : 0),
        y: currentPosition.y + (transform.y === -1 ? currentSize.height : 0),
      },
      angle,
      center,
      newCenter,
    );

    // To get element starting position:
    // If unmoved position was top right or bottom right corner, subtract width
    // If unmoved position was bottom left or bottom right corner, subtract height
    const position: PositionDefinition = {
      x: newElementPosition.x - (transform.x === -1 ? size.width : 0),
      y: newElementPosition.y - (transform.y === -1 ? size.height : 0),
    };

    return { position, size };
  }
}
