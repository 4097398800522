<div [ngClass]="{ 'filter-panel': filterDefinition?.filterCriteria?.propertyCriteria?.length > 0 }">
  <div id="empty-state" class="message" *ngIf="!filterDefinition?.filterCriteria?.propertyCriteria?.length">
    No filters have been applied
  </div>
  <div class="filters" *ngIf="filterDefinition?.filterCriteria?.propertyCriteria?.length > 0">
    <app-filter-details
      [attr.data-test]="'filter-details-' + filterPropertyCriteria.filterPropertyDefinition?.slug"
      *ngFor="let filterPropertyCriteria of filterDefinition.filterCriteria?.propertyCriteria"
      [filterDefinition]="filterDefinition"
      [equalsOnly]="equalsOnly"
      [filterPropertyDefinition]="filterPropertyCriteria.filterPropertyDefinition"
      [filterPropertyCriteria]="filterPropertyCriteria"
      [sourceValues]="sourceValues"
      [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
      (remove)="removeCriteria($event)"
      (criteriaChange)="handleCriteriaChange($event)"
    >
    </app-filter-details>
  </div>
  <div class="actions">
    <div data-test="add-filter-button" class="action" id="add-filter-link" (click)="addCriteria()">+ Add filter</div>

    <div
      class="action-right"
      *ngIf="filterDefinition?.filterCriteria?.propertyCriteria.length > 0 && !hideApplyOptions"
    >
      <div>
        <mat-slide-toggle
          color="primary"
          data-test="auto-filter-button"
          [checked]="autoFilter"
          (change)="toggleAutoFilter($event)"
          >Auto filter</mat-slide-toggle
        >
      </div>
      <div data-test="clear-filter-button" (click)="clearAllFilterProperties()">Clear all</div>
      <div
        data-test="apply-filter-button"
        [ngClass]="{ action: isFilterActive(), 'action-disabled': !isApplyActive() }"
        (click)="applyFilter(true)"
      >
        Apply
      </div>
    </div>
  </div>
</div>
