import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AuthSelectors } from '../auth/auth-store';
import { AuthContext } from '../auth/auth.service';
import { WebSocketService } from '../web-socket/web-socket.service';
@Injectable({
  providedIn: 'root',
})
export class UserSessionsService {
  private authContext: AuthContext;
  constructor(
    private store: Store<RootStoreState.State>,
    private websocketSevice: WebSocketService,
  ) {}

  public async loadUserSessions(sessionId: string) {
    let sessions = await new Entities().get({ entityName: 'session', criteria: { sessionId } });
    const filtered = sessions.filter((userSession) => userSession.clientId !== this.websocketSevice.getClientId());
    return filtered;
  }
}
