import { Injectable } from '@angular/core';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from '../document/document.service';
import { DocumentContentEditorService } from '../document/document-content-editor/document-content-editor.service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { BoardsActions } from '../../boards-store';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { interval, take } from 'rxjs';
import { LoadingIndicatorSelectors } from '@common/loading-indicator/loading-indicator-store';

@Injectable({
  providedIn: 'root',
})
export class UndoRedoHandler {
  isLoading = false;
  constructor(
    private undoRedoService: UndoRedoService,
    private documentService: DocumentService,
    private documentContentEditorService: DocumentContentEditorService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(LoadingIndicatorSelectors.loadingSelector).subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  public undoActions() {
    if (this.isLoading) {
      return;
    }
    this.undoRedoService.getNextUndo().subscribe((nextUndo) => {
      if (nextUndo) {
        if (nextUndo[0].actionType === 'document') {
          const documentGenerationUndo = ObjectUtil.cloneDeep(nextUndo).filter(
            (n) => n.undoChangeDefinition.documentGenerationConfig,
          );
          let timeout = 0;
          if (documentGenerationUndo.length > 0) {
            timeout = 1;
            this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
          }

          interval(timeout) // wait to ensure that the loading indicator is displayed for document generation undo
            .pipe(take(1))
            .subscribe(() => {
              let actions = ObjectUtil.cloneDeep(nextUndo)
                .filter((n) => n.undoChangeDefinition.elementId)
                .map((undo) => {
                  const action = new DocumentAction({
                    documentId: undo.undoChangeDefinition.documentId,
                    elementId: undo.undoChangeDefinition.elementId,
                    changeType: undo.undoChangeDefinition.changeType,
                    elementData: undo.undoChangeDefinition.elementData,
                  });
                  if (undo.undoChangeDefinition.backingAssortmentItemData) {
                    action.changeDefinition.backingAssortmentItemData =
                      undo.undoChangeDefinition.backingAssortmentItemData;
                  }
                  return action;
                });
              const entityActions = ObjectUtil.cloneDeep(nextUndo)
                .filter((n) => n.undoChangeDefinition.entityId)
                .map((undo) => {
                  const action = new DocumentAction({
                    entityId: undo.undoChangeDefinition.entityId,
                    changeType: undo.undoChangeDefinition.changeType,
                    entityType: undo.undoChangeDefinition.entityType,
                    entityData: undo.undoChangeDefinition.entityData,
                    componentElementCount: undo.undoChangeDefinition.componentElementCount,
                    updateEntityId: undo.undoChangeDefinition.updateEntityId,
                  });
                  return action;
                });
              actions = actions.concat(entityActions);
              this.updateDocumentElements(actions);
            });

          if (documentGenerationUndo.length > 0) {
            this.store.dispatch(
              BoardsActions.updateCurrentDocumentGenerationConfig({
                changes: documentGenerationUndo[0].undoChangeDefinition.documentGenerationConfig,
              }),
            );
          }
        } else if (nextUndo[0].actionType === 'content') {
          this.documentContentEditorService.handleContentAction(nextUndo[0].undoChangeDefinition);
        }
      }
    });
  }

  public redoActions() {
    if (this.isLoading) {
      return;
    }
    this.undoRedoService.getNextRedo().subscribe((nextRedo) => {
      if (nextRedo) {
        if (nextRedo[0].actionType === 'document') {
          let timeout = 0;
          const documentGenerationRedo = ObjectUtil.cloneDeep(nextRedo).filter(
            (n) => n.changeDefinition.documentGenerationConfig,
          );
          if (documentGenerationRedo.length > 0) {
            timeout = 1;
            this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
          }
          interval(timeout) // wait to ensure that the loading indicator is displayed for document generation redo
            .pipe(take(1))
            .subscribe(() => {
              let actions = ObjectUtil.cloneDeep(nextRedo)
                .filter((n) => n.changeDefinition.elementId)
                .map((redo) => {
                  const action = new DocumentAction({
                    documentId: redo.undoChangeDefinition.documentId,
                    elementId: redo.changeDefinition.elementId,
                    changeType: redo.changeDefinition.changeType,
                    elementData: redo.changeDefinition.elementData,
                  });
                  if (redo.changeDefinition.backingAssortmentItemData) {
                    action.changeDefinition.backingAssortmentItemData = redo.changeDefinition.backingAssortmentItemData;
                  }
                  return action;
                });
              const entityActions = ObjectUtil.cloneDeep(nextRedo)
                .filter((n) => n.undoChangeDefinition.entityId)
                .map((redo) => {
                  const action = new DocumentAction({
                    entityId: redo.changeDefinition.entityId,
                    changeType: redo.changeDefinition.changeType,
                    entityType: redo.changeDefinition.entityType,
                    entityData: redo.changeDefinition.entityData,
                    componentElementCount: redo.changeDefinition.componentElementCount,
                    updateEntityId: redo.changeDefinition.updateEntityId,
                  });
                  return action;
                });
              actions = actions.concat(entityActions);
              if (documentGenerationRedo.length > 0) {
                this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
              }
              this.updateDocumentElements(actions);
            });

          if (documentGenerationRedo.length > 0) {
            this.store.dispatch(
              BoardsActions.updateCurrentDocumentGenerationConfig({
                changes: documentGenerationRedo[0].changeDefinition.documentGenerationConfig,
              }),
            );
          }
        } else if (nextRedo[0].actionType === 'content') {
          this.documentContentEditorService.handleContentAction(nextRedo[0].changeDefinition);
        }
      }
    });
  }

  private updateDocumentElements(actions) {
    const selectedElements = this.documentService.getSelectedExpandedElements();
    let sendSelectedEvent = false;
    if (selectedElements?.length > 0 && actions?.length > 0) {
      for (let i = 0; i < actions?.length; i++) {
        const action = actions[i];
        const element = action.changeDefinition.elementData;
        const currentElement = selectedElements.find((e) => e.id === action.changeDefinition.elementId);
        if (
          action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT &&
          element?.isLocked != null &&
          element?.isLocked !== currentElement?.isLocked
        ) {
          sendSelectedEvent = true;
          break;
        }
      }
    }

    this.documentService.handleDocumentActions(actions);
    if (sendSelectedEvent) {
      const selectedElements = this.documentService.getSelectedExpandedElements(); // get selected elements again so they have latest element data
      // Trigger toolbar to get updated
      this.documentService.handleDocumentElementEvent({
        element: selectedElements[0],
        selectedElements,
        eventType: 'selected',
      });
    }
  }
}
