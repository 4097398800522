import { Injectable } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Entities, Types } from '@contrail/sdk';
import { AuthContext, AuthService } from '../auth/auth.service';
import { VIEWS } from './view-definitions/views';

@Injectable({
  providedIn: 'root',
})
export class ViewManagerService {
  private viewMap: Map<string, any> = new Map();
  constructor(private authService: AuthService) {
    this.authService.authContext.subscribe((ctx) => {
      this.initViewMap();
    });
  }

  async initViewMap() {
    // Defaults
    await this.loadViewMap(VIEWS);

    // Saved views
    const viewTypes = ['showcase:item_details', 'common:item_chooser_card'];
    viewTypes.forEach((applicationViewSlug) => {
      this.loadViewDefinitions(applicationViewSlug);
    });
  }

  async hydrateViewDefinitions(views: Array<ViewDefinition>) {
    views.forEach(async (view) => {
      if (view.hydrated) {
        return;
      }
      view.properties.forEach(async (viewProp) => {
        const root = viewProp.typeRootSlug;
        let path = root;
        if (root && view.typePath?.startsWith(root)) {
          path = view.typePath;
        }
        const type = await new Types().getType({ root, path });
        const property = type.typeProperties.find((p) => p.slug === viewProp.slug);
        viewProp.propertyDefinition = property;
      });
      view.hydrated = true;
    });
  }
  async getView(viewSlug: string, typeId = null) {
    let view;
    if (typeId) {
      const type = await new Types().getType({ id: typeId });
      view = this.viewMap.get(viewSlug + '_' + type.typePath);
    }
    // Fall back, or no type
    if (!view) {
      view = this.viewMap.get(viewSlug);
    }
    return view;
  }

  async loadViewDefinitions(applicationViewSlug: string) {
    const views = await this.getViewDefinitions({ applicationViewSlug });
    await this.loadViewMap(views);
  }

  async loadViewMap(views: Array<ViewDefinition>) {
    await this.hydrateViewDefinitions(views);
    views.forEach((view) => {
      let key = view.applicationViewSlug;
      if (view.typePath) {
        key += '_' + view.typePath;
      }
      this.viewMap.set(key, view);
    });
  }

  async getViewDefinitions(criteria): Promise<ViewDefinition[]> {
    const viewDefinition = await new Entities().get({ entityName: 'view-definition', criteria });
    let views = [];

    if (Array.isArray(viewDefinition)) {
      views = viewDefinition;
    } else if (viewDefinition?.viewDefinitions) {
      views = viewDefinition.viewDefinitions;
    } else if (viewDefinition?.viewDefinitionsURL) {
      const response = await fetch(viewDefinition.viewDefinitionsURL);
      const viewDefinitions = await response.json();
      views = viewDefinitions;
    }

    return views;
  }
}
