import { Injectable } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Entities } from '@contrail/sdk';
import { FileDownloader } from 'src/app/boards/board/canvas/file-downloader';
import { environment } from 'src/environments/environment';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { CustomFontsActions, CustomFontsSelectors } from './custom-fonts-store';
import { take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomFontsService {
  constructor(
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
  ) {}

  public async loadCustomFonts(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let loaded = false;
        this.store
          .select(CustomFontsSelectors.customFontsLoaded)
          .pipe(
            take(1),
            tap((l) => {
              loaded = l;
            }),
          )
          .subscribe();

        if (!loaded) {
          const result = await this.getCustomFont();
          if (
            result?.customFonts &&
            typeof result?.customFonts === 'string' &&
            result?.customFontFamilies?.length > 0
          ) {
            this.loadStyle(result.customFonts, document);
            this.store.dispatch(
              CustomFontsActions.setCustomFonts({
                customFonts: result.customFonts,
                customFontFamilies: result.customFontFamilies,
              }),
            );
          } else {
            this.store.dispatch(CustomFontsActions.setCustomFonts({ customFonts: null, customFontFamilies: null }));
          }
        }

        resolve('loaded');
      } catch (error) {
        this.store.dispatch(CustomFontsActions.setCustomFonts({ customFonts: null, customFontFamilies: null }));
        resolve('loaded');
      }
    });
  }

  private async getCustomFont(): Promise<{ customFonts: string; customFontFamilies: Array<String> }> {
    const customFonts = await new Entities().get({ entityName: 'custom-font' });
    if (!customFonts?.length) {
      return null;
    }
    const customFontFamilies = await new Entities().get({ entityName: 'custom-font-familie' });
    if (!customFontFamilies?.length) {
      return null;
    }
    const customFont = customFonts[0];
    if (customFont?.fileUrl) {
      const authContext = await this.authService.getAuthContext();
      const fileDownLoader = new FileDownloader(
        { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
        { imageHost: environment.imageHost },
      );
      const file = await fileDownLoader.downloadAndUnzipFile(customFont.fileUrl);
      return {
        customFontFamilies: customFontFamilies[0]?.fontFamilies,
        customFonts: file,
      };
    }
    return null;
  }

  /**
   * Appends file with @styleName as <style> to the document
   * @param styleName
   * @param document
   */
  private loadStyle(fontFaces: string, document: Document) {
    if (fontFaces && typeof fontFaces == 'string') {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.innerHTML = fontFaces;
      head.appendChild(style);
    }
  }
}
