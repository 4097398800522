<button mat-icon-button disableRipple="true" [matMenuTriggerFor]="menu">
  <div class="flex items-center justify-center">
    <div class="color-chip" [style.background-color]="backgroundColor"></div>
  </div>
</button>
<mat-menu #menu="matMenu" class="color-chip-menu">
  <ng-template matMenuContent>
    <div
      class="color-chip cursor-pointer"
      [style.background-color]="hex"
      *ngFor="let hex of colorOptions"
      (click)="updateColor($event, hex)"
    ></div>
  </ng-template>
</mat-menu>
