import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CdkConnectedOverlay, ConnectionPositionPair } from '@angular/cdk/overlay';
import { COLORS_PICKLIST } from '../../../boards/board/document/property-configurator/property-configurator-color-picker/property-configurator-preset-colors';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';

export interface ColorDetail {
  backgroundColor?: string;
  name?: string;
}

@Component({
  selector: 'app-color-widget',
  templateUrl: './color-widget.component.html',
  styleUrls: ['./color-widget.component.scss'],
})
export class ColorWidgetComponent implements OnChanges, OnInit, OnDestroy {
  public presetColors = COLORS_PICKLIST;
  public defaultColor = 'rgba(0,0,0,0)';
  public color = this.defaultColor;
  public currentColor: string;
  public trigger: ElementRef;
  public isOpened = false;
  public positions: ConnectionPositionPair[];

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;

  @Output() delayedValueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() onMouseEnter: EventEmitter<any> = new EventEmitter();
  @Output() onMouseLeave: EventEmitter<any> = new EventEmitter();
  @Output() onHidden: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() draggable = false;
  @Input() ignoreTagNames = [];
  @Input() ignoreClassNames = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public toggleDisplay(trigger, positions?: ConnectionPositionPair[]) {
    if (positions) {
      this.positions = positions;
    } else {
      this.positions = [];
    }
    this.trigger = trigger;

    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      const destroy$ = new Subject();
      this.cdkConnectedOverlay?.overlayOutsideClick.pipe(takeUntil(destroy$)).subscribe((evt: any) => {
        for (const el of evt?.composedPath()) {
          if (
            el?.tagName === 'COLOR-PICKER' ||
            this.ignoreTagNames.indexOf(el?.tagName?.toLowerCase()) !== -1 ||
            this.ignoreClassNames.filter((c) => el?.classList?.contains(c))?.length > 0
          ) {
            return;
          }
          if (el?.tagName === 'APP-PROPERTY-CONFIGURATOR-BAR' || el?.tagName === 'APP-COMPONENT-EDITOR') {
            break;
          }
        }
        destroy$.next(null);
        destroy$.complete;
        this.isOpened = false;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.color = this.currentValue || this.defaultColor;
    this.currentColor = this.color;
    if (changes.resetPos && !changes.resetPos.firstChange) {
      this.isOpened = false;
    }
  }

  handleChange(color: string) {
    this.color = color;
    setTimeout(() => {
      if (this.color.startsWith('rgba') && this.currentColor === this.defaultColor) {
        // if the current color is the default color 'rgba(0,0,0,0)', set the opacity to 1 when a different color is picked.
        this.color = this.color.replace(/[^,]+(?=\))/, '1');
      }
      this.setDelayedValue({ backgroundColor: this.color, name: null });
      this.currentColor = this.color;
    });
  }

  public selectColorEntity(color: ColorDetail) {
    this.color = color.backgroundColor;
    this.setValue(color);
    this.currentColor = this.color;
  }

  async setValue(color: ColorDetail) {
    this.valueChange.emit(color);
  }

  async setDelayedValue(color: ColorDetail) {
    this.delayedValueChange.emit(color);
  }

  public hide() {
    // NOTE(achester): commented below out because it was causing an error if picker was hidden and then re-shown.  Is it needed?
    // this.cdkConnectedOverlay?.overlayOutsideClick?.unsubscribe();
    this.isOpened = false;
    this.onHidden.emit();
  }

  public handleMouseEnter() {
    this.onMouseEnter.emit();
  }

  public handleMouseLeave() {
    this.onMouseLeave.emit();
  }
}
