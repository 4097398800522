import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardDevToolsComponent } from './board-dev-tools.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { DevToolsMainPanelComponent } from './dev-tools-main-panel/dev-tools-main-panel.component';
import { DevToolsImagesPanelComponent } from './dev-tools-images-panel/dev-tools-images-panel.component';
import { DevToolsSummaryPanelComponent } from './dev-tools-summary-panel/dev-tools-summary-panel.component';
import { DevToolsRenderingPanelComponent } from './dev-tools-rendering/dev-tools-rendering-panel/dev-tools-rendering-panel.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { PipesModule } from '@pipes/pipes.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

@NgModule({
  declarations: [
    BoardDevToolsComponent,
    DevToolsMainPanelComponent,
    DevToolsImagesPanelComponent,
    DevToolsSummaryPanelComponent,
    DevToolsRenderingPanelComponent,
  ],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTabsModule, PipesModule, VirtualScrollerModule],
  exports: [BoardDevToolsComponent],
})
export class BoardDevToolsModule {}
