<div class="flex-center p-3">
  <span
    [(colorPicker)]="color"
    [style.background]="color"
    [cpWidth]="'300px'"
    [cpSaveClickOutside]="true"
    [cpAlphaChannel]="'disabled'"
    [cpOutputFormat]="'hex'"
    [cpToggle]="true"
    [cpDialogDisplay]="'inline'"
    [cpSaveClickOutside]="false"
    [cpOKButton]="true"
    [cpOKButtonText]="'Update'"
    [cpCancelButton]="true"
    [cpCancelButtonText]="'Cancel'"
    (colorPickerChange)="colorChange($event)"
    (colorPickerSelect)="updateColor($event)"
    autocomplete="off"
  >
  </span>
</div>
