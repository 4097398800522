import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AppNotificationsService {
  constructor(private snackBar: MatSnackBar) {}
  showConfirmationMessage(message: string, action: string = '') {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
