import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-annotation-tool',
  templateUrl: './annotation-tool.component.html',
  styleUrls: ['./annotation-tool.component.scss'],
})
export class AnnotationToolComponent {
  @ViewChild(MatMenuTrigger, { static: false }) menu: MatMenuTrigger;
  @Input() annotationOptions: any[];
  @Input() annotationType;
  @Input() tooltipPosition = 'below';
  @Output() annotationTypeChange = new EventEmitter();

  constructor() {}

  getActiveColor() {
    return this.annotationType ? 'accent' : '';
  }

  setInteractionMode(annotationType) {
    this.annotationTypeChange.emit(annotationType);
  }
}
