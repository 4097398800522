import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { BoardComponent } from './board/board.component';
import { BoardTitleBarComponent } from './board/board-title-bar/board-title-bar.component';
import { BoardZoomNavigationComponent } from './board/board-navigation-bar/board-navigation-bar.component';
import { BoardGeneralToolsBarComponent } from './board/board-general-tools-bar/board-general-tools-bar.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { DocumentModule } from './board/document/document.module';
import { ActionsModule } from './board/document/action-dispatchers/actions.module';
import { BoardContextMenuComponent } from './board/board-context-menu/board-context-menu.component';
import { ItemChooserComponent } from './board/item-chooser/item-chooser.component';
import { ComponentsModule } from '../common/components/components.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainModule } from '../main/main.module';
import { BoardsHomeComponent } from './boards-home/boards-home.component';
import { ListModule } from '../common/components/list/list.module';
import { BoardsListComponent } from './boards-list/boards-list.component';
import { BoardCardComponent } from './board-card/board-card.component';
import { CreateBoardComponent } from './create-board/create-board.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { UserSessionsModule } from '../common/user-sessions/user-sessions.module';
import { MatDividerModule } from '@angular/material/divider';
import { BoardSideMenuComponent } from './board/board-side-menu/board-side-menu.component';
import { CommentsModule } from '../common/comments/comments.module';
import { ShareAppModule } from '../common/share-app/share-app.module';
import { BoardsMessageHandler } from './boards-message-handler';
import { UndoRedoModule } from '../common/undo-redo/undo-redo-module';
import { ItemDataChooserModule } from '../common/item-data-chooser/item-data-chooser.module';
import { ContextMenuModule } from '../common/components/context-menu/context-menu.module';
import { AssortmentsModule } from '../common/assortments/assortments.module';
import { BoardConfigurationComponent } from './board/board-configuration/board-configuration.component';
import { BoardMouseTrackerComponent } from './board/board-mouse-tracker/board-mouse-tracker.component';
import { BoardRemoteMouseComponent } from './board/board-mouse-tracker/board-remote-mouse.component';
import { BoardPinnedComments } from './board/board-pinned-comments/board-pinned-comments.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AssortmentHistoryModule } from '../common/assortment-history/assortment-history.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DocumentHistoryModule } from '@common/document-history/document-history.module';
import { AnnotationModule } from '@common/annotation/annotation.module';
import { ChooserModule } from '@common/chooser/chooser.module';
import { BoardAddEntitiesBarComponent } from './board/board-add-entities-bar/board-add-entities-bar.component';
import { DocumentStatusMessageComponent } from './board/document/document-status/document-status-message-component/document-status-message.component';
import { SearchReplaceModule } from '@common/search-replace/search-replace.module';
import { SourceAssortmentModule } from '@common/source-assortment/source-assortment.module';
import { CollectionStatusMessageModule } from '@common/collection-status-message/collection-status-message.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { NoConnectionOverlayComponent } from '@common/web-socket/no-connection-overlay.component';
import { BoardCommentsListComponent } from './board/board-comments/board-comments-list.component';
import { DocumentGeneratorModule } from './board/document-generator/document-generator.module';
import { ObjectReferenceModule } from '@common/object-reference/object-reference.module';
import { BoardFramesListComponent } from './board/board-frames-list/board-frames-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';
import { ExportsModule } from '@common/exports/exports.module';
import { BoardContextBarComponent } from './board/board-context-bar/board-context-bar.component';
import { ContentModule } from '@common/content/content.module';
import { EntityDetailsModule } from '@common/entity-details/entity-details.module';
import { BoardDevToolsModule } from './board/board-dev-tools/board-dev-tools.module';
import { ColorModule } from '@common/color/color.module';
import { BoardMinimapComponent } from './board/board-minimap/board-minimap.component';
import { BoardMinimapZoomComponent } from './board/board-minimap/board-minimap-zoom/board-minimap-zoom.component';
import { DocumentTemplatesModule } from './board/document-templates/document-templates.module';
import { BoardDashboardPanelComponent } from './board/board-dashboard-panel/board-dashboard-panel.component';
import { DashboardModule } from '@common/dashboard/dashboard.module';
import { QuickCreateItemOptionComponent } from './board/board-context-menu/quick-create-item-option.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BoardExportComponent } from './board/board-export/board-export.component';
import { CustomFontsModule } from '@common/custom-fonts/custom-fonts.module';
import { ClipboardModule } from '@common/clipboard/clipboard.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComponentEditorModule } from './board/document/component-editor/component-editor.module';

@NgModule({
  declarations: [
    BoardComponent,
    BoardTitleBarComponent,
    BoardZoomNavigationComponent,
    BoardGeneralToolsBarComponent,
    BoardContextMenuComponent,
    ItemChooserComponent,
    BoardsHomeComponent,
    BoardsListComponent,
    BoardCardComponent,
    CreateBoardComponent,
    BoardSideMenuComponent,
    BoardConfigurationComponent,
    BoardMouseTrackerComponent,
    BoardRemoteMouseComponent,
    BoardPinnedComments,
    BoardAddEntitiesBarComponent,
    DocumentStatusMessageComponent,
    BoardCommentsListComponent,
    BoardFramesListComponent,
    BoardContextBarComponent,
    BoardMinimapComponent,
    BoardMinimapZoomComponent,
    BoardDashboardPanelComponent,
    BoardExportComponent,
    QuickCreateItemOptionComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatDividerModule,
    MatDialogModule,
    MatSlideToggleModule,
    DocumentModule,
    ComponentEditorModule,
    ActionsModule,
    ComponentsModule,
    VirtualScrollerModule,
    BrowserAnimationsModule,
    MainModule,
    ListModule,
    UserSessionsModule,
    CustomFontsModule,
    CommentsModule,
    ShareAppModule,
    UndoRedoModule,
    ItemDataChooserModule,
    ContextMenuModule,
    AssortmentsModule,
    MatTooltipModule,
    MatRadioModule,
    AssortmentHistoryModule,
    DocumentHistoryModule,
    AnnotationModule,
    ChooserModule,
    ContentModule,
    SearchReplaceModule,
    CollectionStatusMessageModule,
    SourceAssortmentModule,
    AssortmentsModule,
    NoConnectionOverlayComponent,
    DocumentGeneratorModule,
    ObjectReferenceModule,
    DragDropModule,
    MatSelectModule,
    ExportsModule,
    EntityDetailsModule,
    DocumentGeneratorModule,
    BoardDevToolsModule,
    ColorModule,
    DocumentTemplatesModule,
    DashboardModule,
    ClipboardModule,
    OverlayModule,
  ],
  exports: [BoardCardComponent],
})
export class BoardsModule {
  constructor(private boardsMessageHandler: BoardsMessageHandler) {}
}
