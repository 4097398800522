import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { RootStoreState } from '@rootstore';
import { PropertyValueFormatter, Type } from '@contrail/types';
import { AuthSelectors } from '@common/auth/auth-store';
import { Item } from '../../item';
import { Types } from '@contrail/sdk';

@Component({
  selector: 'app-item-details-status',
  templateUrl: './item-details-status.component.html',
  styleUrls: ['./item-details-status.component.scss'],
})
export class ItemDetailsStatusComponent implements OnChanges {
  @Input() item: Item;
  @Input() type: Type;
  @Input() iconOnly = false;
  public displayValue;
  public hasStatus = false;
  public statusHexColor: string;
  private itemStatusColors: Array<string>;

  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          this.itemStatusColors = currentOrg?.orgConfig?.itemStatusHexColors;
        }),
      )
      .subscribe();
  }

  async ngOnChanges(): Promise<void> {
    if (!this.type) {
      this.type = await new Types().getType({ id: this.item.typeId });
    }
    const property = this.type.typeProperties.find((p) => p.slug === 'lifecycleStage' || p.slug === 'itemStatus');
    if (!property || !this.item[property.slug]) {
      this.hasStatus = false;
      return;
    }

    this.hasStatus = true;
    const itemStatus = this.item[property.slug];
    const indexOfStatus = property.options ? property.options.findIndex((option) => option.value === itemStatus) : 0;
    this.statusHexColor = this.itemStatusColors[indexOfStatus] ?? this.itemStatusColors[0];
    this.displayValue = new PropertyValueFormatter().formatValueForProperty(itemStatus, property);
  }
}
