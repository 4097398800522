import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { BoardService } from '../../board.service';
import { Subscription } from 'rxjs';
import { DocumentViewSize } from '../../document/document-store/document.state';
import { DocumentSelectors } from '../../document/document-store';
import { CanvasUtil } from '../../canvas/canvas-util';
import { CoordinateBox } from '../../canvas/coordinate-box';
import { PositionDefinition, ScaleTransformation } from '@contrail/documents';
import { ViewBox } from '../../canvas/viewbox';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-board-minimap-zoom',
  template: ``,
  styles: [],
})
export class BoardMinimapZoomComponent implements AfterViewInit, OnDestroy {
  @Input() elementId: string;
  @Input() canvasSize: {
    canvasWidth: number;
    canvasHeight: number;
    padding: number;
  };
  @Input() map: {
    dimensions: CoordinateBox;
    offset: any;
    viewScale: ScaleTransformation;
  };

  private viewSize: DocumentViewSize;
  private zoomElement: HTMLCanvasElement;
  private zoomElementContext;
  private pixelRatio = 2;

  private dragStartEvent;
  private startingViewBox;

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<RootStoreState.State>,
    private boardService: BoardService,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
        this.viewSize = viewSize;
        this.drawZoom();
      }),
    );
  }

  ngOnDestroy(): void {
    this.zoomElement.width = this.zoomElement.height = 0;
    this.zoomElement = null;
    this.subscription.unsubscribe();
  }

  public addZoomCanvas() {
    this.zoomElement = document.createElement('canvas');
    this.zoomElement.width = this.canvasSize.canvasWidth;
    this.zoomElement.height = this.canvasSize.canvasHeight;
    this.zoomElement.style.width = `${this.canvasSize.canvasWidth * 0.5}px`;
    this.zoomElement.style.height = `${this.canvasSize.canvasHeight * 0.5}px`;
    this.zoomElement.style.position = 'absolute';
    this.zoomElement.style.left = '0';
    this.zoomElement.style.top = '0';
    this.zoomElement.addEventListener('mousedown', this.mousedown.bind(this));
    this.zoomElement.addEventListener('mousemove', this.mousemove.bind(this));
    this.zoomElement.addEventListener('mouseup', this.mouseup.bind(this));
    this.zoomElement.addEventListener('mouseleave', this.mouseup.bind(this));
    this.zoomElement.addEventListener('DOMMouseScroll', this.onWheel.bind(this));
    this.zoomElement.addEventListener('mousewheel', this.onWheel.bind(this));
    this.zoomElement.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    this.zoomElementContext = this.zoomElement.getContext('2d');
    document.getElementById(this.elementId).appendChild(this.zoomElement);
  }

  private mousedown(event) {
    if (!this.viewSize || !this.map) {
      return;
    }
    const clientRect: any = {
      x: this.map.offset.x + this.canvasSize.padding,
      y: this.map.offset.y + this.canvasSize.padding,
    };
    const position = CanvasUtil.toDocumentPosition(
      event.offsetX * this.pixelRatio,
      event.offsetY * this.pixelRatio,
      this.map.dimensions,
      this.map.viewScale,
      clientRect,
    );
    if (!this.isPositionInViewBox(position, this.viewSize.viewBox)) {
      const viewBox: ViewBox = {
        width: this.viewSize.viewBox.width,
        height: this.viewSize.viewBox.height,
        x: position.x - this.viewSize.viewBox.width / 2,
        y: position.y - this.viewSize.viewBox.height / 2,
      };
      this.boardService.setViewPort(viewBox);
    }
    this.startingViewBox = ObjectUtil.cloneDeep(this.viewSize.viewBox);
    this.dragStartEvent = event;
  }

  private mousemove(event) {
    if (this.dragStartEvent && event?.buttons === 1) {
      const distanceX = ((event.offsetX - this.dragStartEvent.offsetX) * this.pixelRatio) / this.map.viewScale.x;
      const distanceY = ((event.offsetY - this.dragStartEvent.offsetY) * this.pixelRatio) / this.map.viewScale.y;
      const viewBox: ViewBox = {
        width: this.viewSize.viewBox.width,
        height: this.viewSize.viewBox.height,
        x: this.startingViewBox.x + distanceX,
        y: this.startingViewBox.y + distanceY,
      };
      this.boardService.setViewPort(viewBox);
    }
  }

  private mouseup(event) {
    this.dragStartEvent = null;
    this.startingViewBox = null;
  }

  private getZoomSettings(zoomFactor, p) {
    zoomFactor = Math.min(zoomFactor, this.boardService.zoomPanHandler.MAX_ZOOM_FACTOR);
    zoomFactor = Math.max(zoomFactor, this.boardService.zoomPanHandler.MIN_ZOOM_FACTOR);
    const scale = zoomFactor / this.boardService.zoomPanHandler.zoomFactor;
    return {
      zoomFactor: zoomFactor,
      dx: -(p.x - this.viewSize.viewBox.x) * (scale - 1),
      dy: -(p.y - this.viewSize.viewBox.y) * (scale - 1),
    };
  }

  private getNewZoom(zoomFactor, event) {
    const zoomScale = 1.01;
    const newZoomFactor = event.deltaY < 0 ? zoomFactor / zoomScale : zoomFactor * zoomScale;
    return newZoomFactor;
  }

  private onWheel(event) {
    event.preventDefault();
    const zoomFactor = this.boardService.zoomPanHandler?.zoomFactor;
    const canvasSize = this.boardService.zoomPanHandler?.canvasSize;
    if (zoomFactor == null || !canvasSize || !this.map) {
      return;
    }
    const focalPoint = {
      x: event.offsetX * this.pixelRatio,
      y: event.offsetY * this.pixelRatio,
    };
    const clientRect: any = {
      x: this.map.offset.x + this.canvasSize.padding,
      y: this.map.offset.y + this.canvasSize.padding,
    };
    const focalPointDocCoords = CanvasUtil.toDocumentPosition(
      focalPoint.x,
      focalPoint.y,
      this.map.dimensions,
      this.map.viewScale,
      clientRect,
    );
    const newZoomFactor = this.getNewZoom(zoomFactor, event);
    const settings = this.getZoomSettings(newZoomFactor, focalPointDocCoords);
    this.boardService.setZoomFactor(settings.zoomFactor);
    this.boardService.setViewPort({
      width: canvasSize.width * settings.zoomFactor,
      height: canvasSize.height * settings.zoomFactor,
      x: this.viewSize.viewBox.x + settings.dx,
      y: this.viewSize.viewBox.y + settings.dy,
    });
  }

  private isPositionInViewBox(position: PositionDefinition, viewSize: ViewBox) {
    return (
      position.y <= viewSize.y + viewSize.height &&
      position.y >= viewSize.y &&
      position.x >= viewSize.x &&
      position.x <= viewSize.x + viewSize.width
    );
  }

  public drawZoom() {
    if (!this.viewSize || !this.zoomElement || !this.map) {
      return;
    }
    this.zoomElementContext.clearRect(0, 0, this.zoomElement.width, this.zoomElement.height);
    const { x, y } = CanvasUtil.toWindowPosition(
      this.viewSize.viewBox.x,
      this.viewSize.viewBox.y,
      this.map.dimensions,
      this.map.viewScale,
      this.map.offset,
      true,
    );
    const { width, height } = CanvasUtil.toWindowSize(
      this.viewSize.viewBox.width,
      this.viewSize.viewBox.height,
      this.map.viewScale,
    );
    this.zoomElementContext.beginPath();
    this.zoomElementContext.moveTo(x, y);
    this.zoomElementContext.lineTo(x + width, y);
    this.zoomElementContext.lineTo(x + width, y + height);
    this.zoomElementContext.lineTo(x, y + height);
    this.zoomElementContext.closePath();
    this.zoomElementContext.strokeStyle = '#00000099';
    this.zoomElementContext.lineWidth = 2;
    this.zoomElementContext.stroke();
  }
}
