import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { ShareAppModalLauncher } from 'src/app/common/share-app/share-app-modal-launcher';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { environment } from 'src/environments/environment';
import { Board } from '../board.service';
import { BoardsSelectors } from '../../boards-store';
import { Observable } from 'rxjs';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { map } from 'rxjs/operators';
import { SearchReplaceActions } from '@common/search-replace/search-replace-store';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { CollectionStatusMessageService } from '../board-side-menu/status-messages/collection-status-message.service';
import { AuthService } from '@common/auth/auth.service';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { DocumentActions } from '../document/document-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { UserSession } from '@common/user-sessions/user-session';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-board-general-tools-bar',
  templateUrl: './board-general-tools-bar.component.html',
  styleUrls: ['./board-general-tools-bar.component.scss'],
})
export class BoardGeneralToolsBarComponent implements OnInit {
  public itemCount$: Observable<number>;
  orgSlug = '';
  allowShare = true;
  public currentBoard: Board;
  public dashboardFeatureFlag = false;
  @Input() editorMode!: EditorMode;
  messages$: Observable<Array<CollectionStatusMessage>>;

  constructor(
    private store: Store<RootStoreState.State>,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private shareAppModalLauncher: ShareAppModalLauncher,
    private authService: AuthService,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      if (this.authService.isOnSharedLink() || authContext?.currentOrg?.role === 'GUEST') {
        this.allowShare = false;
      }
      this.orgSlug = authContext?.currentOrg?.orgSlug;
    });

    this.itemCount$ = this.store.select(AssortmentsSelectors.backingAssortmentItems).pipe(map((ais) => ais?.length));
  }

  ngOnInit(): void {
    this.store.select(BoardsSelectors.currentBoard).subscribe((board) => {
      if (!board) {
        return;
      }
      this.currentBoard = board;
    });
    this.messages$ = this.collectionStatusMessageService.messages$;
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.dashboardFeatureFlag = !!flags.find((x) => x.featureName === Feature.DASHBOARD);
    });
  }

  public share() {
    const baseUrl = `${environment.boardAppHost}/org/${this.orgSlug}/board/${this.currentBoard.id}`;
    this.shareAppModalLauncher.openModal({ contextId: this.currentBoard.id, contextType: 'board', baseUrl });
  }

  toggleSearch() {
    this.store.dispatch(SearchReplaceActions.toggleSearch());
  }

  toggleFrames() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Frames';
    overlay.slug = 'frames';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  toggleDashboard() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Dashboard';
    overlay.slug = 'dashboard';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  onShowAlertMessages(event) {
    this.collectionStatusMessageService.showAlertMessages();
  }

  onShowClipboard() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.title = 'Clipboard';
    overlay.slug = 'addItem';
    overlay.hideSource = true;
    overlay.showChooser = true;
    overlay.targetSourceType = ChooserSourceOptionTypes.CLIPBOARD;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  navigateToUserLocation(session: UserSession) {
    this.store
      .select(BoardsSelectors.remoteUserMouseTracker)
      .pipe(take(1))
      .subscribe((remoteUsers) => {
        const remoteUser = remoteUsers.find((user) => user.clientId === session.user.clientId);
        if (remoteUser) {
          this.store.dispatch(
            DocumentActions.navigateToPosition({
              position: {
                x: remoteUser.x,
                y: remoteUser.y,
              },
            }),
          );
        } else {
          console.error('Remote user not found');
        }
      });
  }
}
