<div class="flex flex-col flex-1 h-full">
  <div class="header">
    <div class="title">{{ historyType === 'sourceChangeHistory' ? 'Source Change History' : 'Version History' }}</div>
    <div (click)="handleClose()" class="cursor-pointer flex-center">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <app-source-change-history-panel *ngIf="historyType === 'sourceChangeHistory'" [ownerReference]="documentReference">
  </app-source-change-history-panel>

  <app-document-history-panel
    *ngIf="historyType === 'versionHistory'"
    [document]="document"
    [documentReference]="documentReference"
  >
  </app-document-history-panel>
</div>
