import { DocumentService } from '../document/document.service';
import { Injectable } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';

@Injectable({
  providedIn: 'root',
})
export class PasteHandler {
  public editorMode: EditorMode;
  constructor(
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m));
    this.init();
  }
  private init() {
    // Currently, the only place we handle a paste event (vs CTRL-V, etc)
    // Maybe we could copy this pattern for copy? is there an oncopy event?
    // document.onpaste = this.handlePasteEvent.bind(this);
  }

  public async handlePasteEvent(pasteEvent, options?: DocumentElement, shiftKey = false) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    this.documentService.documentClipboard.pasteCopiedElements(pasteEvent, options, shiftKey);
  }
}
