import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Board } from './boards-store/boards.state';

@Injectable({
  providedIn: 'root',
})
export class BoardsService {
  constructor() {}

  public async getBoards(workspaceId: string) {
    return new Entities().get({
      entityName: 'board',
      criteria: { rootWorkspaceId: workspaceId, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'previewFile'],
    });
  }
  public async getBoardById(id: string) {
    const board = await new Entities().get({
      entityName: 'board',
      id,
      relations: [
        'updatedBy',
        'createdBy',
        'workspace',
        'workspace.project',
        'sourceAssortment',
        'document',
        'document.elements',
        'document.documentElementSortOrder',
        'primaryContext',
      ],
    });

    const document = board.document;
    if (document.elementsDownloadURL) {
      const response = await fetch(document.elementsDownloadURL);
      const elements = await response.json();
      document.elements = elements;
    }
    return board;
  }
  public async createBoard(board: Board) {
    return new Entities().create({ entityName: 'board', object: board });
  }
  public async copyBoard(name, id) {
    return await new Entities().create({ entityName: 'board', object: { name }, id, relation: 'copy' });
  }
  public async asyncCopyBoard(name, id) {
    return await new Entities().create({
      entityName: 'board',
      object: { name, boardId: id },
      relation: 'copy',
      suffix: 'async',
    });
  }
  public async deleteBoard(board: Board) {
    await new Entities().delete({ entityName: 'board', id: board.id });
    return board;
  }
  public async updateBoard(id: string, changes: Board) {
    return new Entities().update({ entityName: 'board', id, object: changes });
  }
}
