import { PositionDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { ChildElementDetails } from '../../../components/editor/editor-placeholder';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasStickyNoteElement } from '../../../elements/sticky-note/canvas-sticky-note-element';
import { CanvasTextElement } from '../../../elements/text/canvas-text-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class TextElementEventHandler {
  private childElementDetails: ChildElementDetails;

  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.canvasDocument?.editorHandler?.hideEditor();
    if (
      elementTarget.element?.isTextEditable &&
      !elementTarget.element?.isPartOfComponent &&
      !elementTarget.element?.elementDefinition?.isLocked
    ) {
      const textElement =
        elementTarget.element?.elementDefinition?.type === 'text'
          ? (elementTarget.element as CanvasTextElement)
          : (elementTarget.element as CanvasStickyNoteElement);
      const mouseEvent = event as MouseEvent;
      const isMouseOnEditableArea = textElement.isMouseOnEditableArea(event as MouseEvent);
      // clicking on the edges of the text element should not activate the editor
      if (!isMouseOnEditableArea || mouseEvent.button === 2) {
        return;
      }
      if (textElement.isSelected) {
        // Only activate the editor if the text element has already been selected
        this.canvasDocument.editorHandler.showEditor(
          textElement.elementDefinition?.type === 'text'
            ? (textElement as CanvasTextElement)
            : (textElement as CanvasStickyNoteElement),
        );
        const mouseEvent = event as MouseEvent;
        mouseEvent.stopPropagation();
      }
    }
  }

  public handleMouseMove(event: MouseEvent | Touch, elementTarget: any) {
    if (elementTarget?.element?.elementDefinition.type === 'text') {
      const childElements = this.canvasDocument.state.getTextLinkElementChildren(
        elementTarget.element.elementDefinition.id,
      );
      const mouseEvent: MouseEvent = event as MouseEvent;
      const elementDimensions: any = elementTarget.element.getDimensions();
      let isWithinChildElement = false;
      childElements?.forEach((elementDetails) => {
        if (this.isWithinChildElem({ x: mouseEvent.x, y: mouseEvent.y }, elementDimensions, elementDetails.domRect)) {
          this.canvasDocument.interactionHandler.setInteractionCursor('pointer');
          isWithinChildElement = true;
          if (this.childElementDetails?.id !== elementDetails.id) {
            this.childElementDetails = elementDetails;
            this.canvasDocument?.editorHandler?.handleFocusHyperlink(
              this.childElementDetails,
              elementTarget.element.elementDefinition,
              this.getLinkStartPosition(elementDimensions, elementDetails.domRect),
            );
          }
        }
      });
      if (!isWithinChildElement && this.childElementDetails) {
        this.childElementDetails = null;
        this.canvasDocument?.editorHandler?.handleBlurHyperlink(this.childElementDetails);
      }
    } else if (this.childElementDetails) {
      this.childElementDetails = null;
      this.canvasDocument?.editorHandler?.handleBlurHyperlink();
    }
  }

  private isWithinChildElem(mousePosition: PositionDefinition, textElemDimension, domRect: DOMRect): boolean {
    const { x, y } = this.canvasDocument.toDocumentPosition(mousePosition.x, mousePosition.y);
    const boundaries = {
      top: textElemDimension.y + domRect.top,
      left: textElemDimension.x + domRect.left,
      bottom: textElemDimension.y + domRect.top + domRect.height,
      right: textElemDimension.x + domRect.left + domRect.width,
    };
    if (x < boundaries.right && x > boundaries.left && y < boundaries.bottom && y > boundaries.top) {
      return true;
    }
    return false;
  }

  private getLinkStartPosition(textElemDimension, domRect: DOMRect): PositionDefinition {
    return this.canvasDocument.toWindowPosition(
      textElemDimension.x + domRect.left,
      textElemDimension.y + domRect.top + domRect.height,
    );
  }
}
