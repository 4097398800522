export interface CollectionStatusMessage {
  id: string;
  type: string;
  message: string;
  collectionElementId: string;
  entityName: string;
}

export enum CollectionStatusMessageTypes {
  WARNING = 'WARNING',
  NOT_IN_SOURCE_ASSORTMENT = 'NOT_IN_SOURCE_ASSORTMENT',
}
