import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TypePropertyFormFieldComponent } from '@common/types/forms/type-property-form-field/type-property-form-field.component';
import { DateFilterComponent } from '@components/date-filter/date-filter.component';
import { PropertyType } from '@contrail/types';
import { FilterConditionType } from '@contrail/filters';
import { FilterCriteria, FilterPropertyCriteria } from '@contrail/filters';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';

@Component({
  selector: 'app-comments-filter-panel',
  templateUrl: './comments-filter-panel.component.html',
  styleUrls: ['./comments-filter-panel.component.scss'],
})
export class CommentsFilterPanelComponent implements OnInit, OnChanges {
  @Input() filterDefinition: FilterDefinition;
  @Input() equalsOnly = false;
  @Input() sourceValues: Array<any>;
  @Output() filterCriteriaChange: EventEmitter<FilterCriteria> = new EventEmitter();
  @ViewChildren('formField') formElements: QueryList<TypePropertyFormFieldComponent>;
  @ViewChild('dateFilter') dateFilter: DateFilterComponent;
  autoFilter = true;

  public criteriaValues: { string?: FilterPropertyCriteria } = {};
  public emptyCriteriaValues = true;

  public dateFilterAttribute = {
    label: 'Date created',
    attribute: 'createdOn',
  };

  constructor() {}

  ngOnInit(): void {
    if (!this.filterDefinition) {
      return;
    }
  }

  ngOnChanges(changes): void {
    if (changes) {
      this.criteriaValues = this.filterDefinition.filterPropertyDefinitions.reduce(
        (criteriaValuesMap, filterPropertyDefinition) => {
          const criteria = this.filterDefinition?.filterCriteria?.propertyCriteria?.find((criteria) => {
            return criteria?.filterPropertyDefinition?.slug === filterPropertyDefinition.slug;
          });
          if (Array.isArray(criteria?.criteriaValue)) {
            criteriaValuesMap[filterPropertyDefinition.slug] =
              criteria?.criteriaValue?.length > 0 ? criteria.criteriaValue : null;
          } else {
            criteriaValuesMap[filterPropertyDefinition.slug] = criteria?.criteriaValue;
          }
          return criteriaValuesMap;
        },
        {},
      );
      this.emptyCriteriaValues = Object.values(this.criteriaValues)?.filter((c) => c)?.length === 0;
    }
  }

  handleValueChange(change, filterPropertyDefinition) {
    const criteria = this.filterDefinition?.filterCriteria?.propertyCriteria?.find((criteria) => {
      return criteria?.filterPropertyDefinition?.slug === filterPropertyDefinition.slug;
    });
    if (criteria) {
      criteria.criteriaValue = change.value;
    } else {
      this.filterDefinition.filterCriteria.propertyCriteria.push({
        filterPropertyDefinition,
        filterConditionType:
          filterPropertyDefinition.propertyType === PropertyType.MultiSelect
            ? FilterConditionType.IS_ANY_OF
            : FilterConditionType.EQUALS,
        criteriaValue: change.value,
      });
    }

    this.handleCriteriaChange();
  }

  dateFilterChanged(dateFilter: FilterDefinition) {
    this.filterDefinition.filterCriteria.propertyCriteria =
      this.filterDefinition?.filterCriteria?.propertyCriteria.filter((criteria) => {
        return criteria?.filterPropertyDefinition?.slug !== 'createdOn';
      });
    this.filterDefinition.filterCriteria.propertyCriteria =
      this.filterDefinition.filterCriteria.propertyCriteria.concat(dateFilter?.filterCriteria?.propertyCriteria || []);
    this.handleCriteriaChange();
  }

  removeFilter(filterPropertyDefinition) {
    this.formElements
      ?.toArray()
      ?.find((formField) => {
        return formField?.property?.slug === filterPropertyDefinition.slug;
      })
      ?.clearValue();
    const index = this.filterDefinition.filterCriteria.propertyCriteria.findIndex(
      (criteria) => criteria?.filterPropertyDefinition?.slug === filterPropertyDefinition.slug,
    );
    this.filterDefinition.filterCriteria.propertyCriteria.splice(index, 1);
    this.handleCriteriaChange();
  }

  handleCriteriaChange() {
    this.applyFilter(false);
  }

  emitUpdatedCriteria() {
    this.filterCriteriaChange.emit(this.filterDefinition.filterCriteria);
  }

  applyFilter(skipCheck) {
    if (this.autoFilter || skipCheck) {
      this.emitUpdatedCriteria();
    }
  }

  clearAllFilterProperties() {
    this.formElements?.toArray()?.forEach((formField) => {
      formField?.clearValue();
    });
    this.dateFilter?.clearValue();
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.applyFilter(true);
  }
}
