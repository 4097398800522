import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../root-store/root-state';
import { CollectionStatusMessage } from 'src/app/common/collection-status-message/collection-status-message';
import { ObjectUtil } from '@contrail/util';
import { DocumentAnnotationService } from '../document/document-annotation/document-annotation-service';
import { AnnotatedElement, AnnotationOption } from '../document/document-annotation/document-annotation-options';
import { DocumentManagerSelectors } from '../document-manager/document-manager-store';
import { DocumentElement } from '@contrail/documents';
import { BoardsSelectors } from '../../boards-store';

@Injectable({
  providedIn: 'root',
})
export class BoardAnnotationService {
  private documentElements: DocumentElement[];
  private elementAnnotationsAlreadySet = false;
  showSourceAssortmentWarning = true;

  private existingElementAnnotations: {
    string?: AnnotationOption[];
  } = {};
  constructor(
    private store: Store<State>,
    private documentAnnotationService: DocumentAnnotationService,
  ) {
    this.store.select(DocumentManagerSelectors.selectDocumentElements).subscribe((documentElements) => {
      if (!documentElements) {
        return;
      }
      this.documentElements = documentElements;
    });
    this.store
      .select(BoardsSelectors.showSourceAssortmentWarning)
      .subscribe((showSourceAssortmentWarning) => (this.showSourceAssortmentWarning = showSourceAssortmentWarning));
  }

  public clear() {
    this.documentAnnotationService.annotatedElementsSubject.next([]);
    this.existingElementAnnotations = {};
    this.elementAnnotationsAlreadySet = false;
  }

  public setElementAnnotations(statusMessages: CollectionStatusMessage[]) {
    if ((statusMessages.length > 0 && !this.elementAnnotationsAlreadySet) || this.elementAnnotationsAlreadySet) {
      this.elementAnnotationsAlreadySet = true;
      this.addAnnotation(statusMessages);
    }
  }

  private addAnnotation(statusMessages: CollectionStatusMessage[] = null, annotationItemMap: any = null) {
    const annotatedElements: AnnotatedElement[] = [];
    if (this.documentElements) {
      this.documentElements.forEach((element) => {
        if (element && element.type === 'component') {
          const item = element.modelBindings.item;
          if (item) {
            let annotations = this.existingElementAnnotations.hasOwnProperty(element.id)
              ? ObjectUtil.cloneDeep(this.existingElementAnnotations[element.id])
              : [];
            // statusMessages only
            if (statusMessages) {
              const existingStatusAnnotationIndex = annotations.findIndex(
                (annotation) => annotation.category === 'status',
              );
              if (existingStatusAnnotationIndex > -1) {
                annotations.splice(existingStatusAnnotationIndex, 1);
              }
            }
            if (this.showSourceAssortmentWarning) {
              statusMessages?.forEach((statusMessage) => {
                if ('item:' + statusMessage.collectionElementId === item) {
                  annotations.push({
                    type: statusMessage.type,
                    category: 'status',
                    detail: { messageId: statusMessage.id },
                  });
                }
              });
            }

            // assortmentItems only
            if (annotationItemMap) {
              annotations = annotations.filter((annotation) => annotation.category === 'status');
              if (annotationItemMap[element.modelBindings.item]) {
                annotations = annotations.concat(
                  annotationItemMap[element.modelBindings.item].map((annotationType) => {
                    const annotationOption = this.documentAnnotationService
                      .getAnnotationOptions()
                      .find((annotation) => annotation.type === annotationType);
                    return {
                      category: annotationOption.category,
                      type: annotationType,
                      detail: { itemId: element.modelBindings.item },
                    };
                  }),
                );
              }
            }

            annotatedElements.push({
              id: element.id,
              annotations,
            });
            this.existingElementAnnotations[element.id] = annotations;
          }
        }
      });
    }
    if (annotatedElements.length > 0) {
      this.documentAnnotationService.annotatedElementsSubject.next(annotatedElements);
      this.documentAnnotationService.setAnnotatedElements(annotatedElements);
    }
  }

  public setElementAnnotationByAssortmentItems(backingAssortmentItems: any[]) {
    const annotationItemMap = {};
    this.documentAnnotationService.getAnnotationOptions()?.forEach((annotationOption) => {
      const affectedAssortmentItems = backingAssortmentItems.filter((item) => item[annotationOption.property] === true);
      affectedAssortmentItems.forEach((assortmentItem) => {
        const itemId = 'item:' + assortmentItem.itemId;
        if (!annotationItemMap[itemId]) {
          annotationItemMap[itemId] = [];
        }
        annotationItemMap[itemId].push(annotationOption.type);
      });
    });
    this.addAnnotation(null, annotationItemMap);
  }
}
