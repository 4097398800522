<app-modal title="Legacy Share Links" data-test="legacy-link-modal" [dialogRef]="dialogRef">
  <div *ngIf="links" class="links">
    <div *ngFor="let link of links" class="link-row" data-test="legacy-link-row">
      <div class="link-input-wrapper">
        <input class="link-input" type="text" [value]="link.shareUrl" readonly />
      </div>
      <div
        class="link cursor-pointer clipboard-link"
        data-test="legacy-link-copy"
        [matTooltip]="'Copy to clipboard'"
        [cdkCopyToClipboard]="link.shareUrl"
        (cdkCopyToClipboardCopied)="showClipboardMessage()"
      >
        Copy Link
      </div>

      <mat-select
        [value]="link.accessLevel"
        (selectionChange)="handleAccessLevelChange($event, link)"
        class="access-levels"
        data-test="legacy-link-access-select"
      >
        <mat-option value="VIEW" data-test="legacy-link-access-option">View</mat-option>
        <mat-option value="COMMENT" data-test="legacy-link-access-option">Comment</mat-option>
        <mat-option value="EDIT" data-test="legacy-link-access-option">Edit</mat-option>
      </mat-select>

      <mat-icon
        class="material-icons-outlined"
        matTooltip="Delete link"
        data-test="legacy-link-delete"
        (click)="deleteLink(link)"
        >close</mat-icon
      >
    </div>
  </div>

  <div class="legacy-links rounded-md p-3 mb-4">
    This document has multiple legacy share links.<br />Legacy links will be automatically deleted on 11/09/2024.
  </div>
</app-modal>
