import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subject, debounceTime, pairwise, startWith, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentService } from '../document/document.service';
import { BoardFrameService } from './board-frames.service';
import { BoardService } from '../board.service';
import { BoardsSelectors } from '../../boards-store';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { cloneDeep } from '@contrail/util/lib/object-util/cloneDeep/cloneDeep';
import { ObjectUtil } from '@contrail/util';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';

@Component({
  selector: 'app-board-frames-list',
  templateUrl: './board-frames-list.component.html',
  styleUrls: ['./board-frames-list.component.scss'],
})
export class BoardFramesListComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  @Output() panelClosed = new EventEmitter();
  @ViewChild('renameInput') editNameInput: ElementRef;
  private clickListener: () => void;
  dragging = false;

  public sortForm = this.boardFrameService.sortFormGroup;
  public sortByControl = this.sortForm.get('sortBy');
  public keepSortedControl = this.sortForm.get('keepSorted');
  get sortBy() {
    return this.sortByControl.value;
  }
  get keepSorted() {
    return this.keepSortedControl.value;
  }
  sortOptions = [
    { label: 'Left to right', property: 'horizontal' },
    { label: 'Top to bottom', property: 'vertical' },
    { label: 'Name', property: 'name' },
    { label: 'Date created', property: 'createdOn' },
  ];
  setSortByControl(option) {
    this.sortByControl.setValue(option);
  }

  renameForm = null;
  searchKey: string;
  public frames$: Observable<any>;
  public frames = null;
  public sortOrderIds = [];
  public selectedIdx$ = this.boardFrameService.selectedFrameIdx$; // current view frame's index
  public selectedFrameIds = []; // multiple selection
  public editMode$: Observable<any>;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private boardService: BoardService,
    private boardFrameService: BoardFrameService,
    private confirmationBoxService: ConfirmationBoxService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.frames$ = this.boardFrameService.frames$;
    this.editMode$ = this.store.select(EditorModeSelectors.editorMode);
  }

  ngOnInit(): void {
    this.listenForClickAway(); //Listen for user clicking outside of component to trigger a save and exit edit mode
    this.boardFrameService.onFrameSelection$.next(true);
    this.frames$.pipe(takeUntil(this.destroy$)).subscribe((frames) => {
      if (this.keepSorted) {
        let prevFrames, currFrames;
        switch (this.sortBy.property) {
          case 'horizontal':
          case 'vertical':
            prevFrames = this.frames.map((f) => {
              return { id: f.id, position: f.frame.position };
            });
            currFrames = frames.map((f) => {
              return { id: f.id, position: f.frame.position };
            });
            break;
          case 'name':
            prevFrames = this.frames.map((f) => {
              return { id: f.id, name: f.frame.name };
            });
            currFrames = frames.map((f) => {
              return { id: f.id, name: f.frame.name };
            });
            break;
          case 'createdOn':
            prevFrames = this.frames.map((f) => {
              return { id: f.id, createdOn: f.frame.createdOn };
            });
            currFrames = frames.map((f) => {
              return { id: f.id, createdOn: f.frame.createdOn };
            });
            break;
        }
        const changed = ObjectUtil.compareDeep(prevFrames, currFrames, '').length;
        if (changed) {
          this.sortApply(frames);
        }
      } else {
        this.frames = frames;
      }
      this.sortOrderIds = this.frames.map((f) => f.id);
    });
  }

  ngAfterViewInit() {
    this.sortForm.valueChanges
      .pipe(
        debounceTime(900),
        startWith({ sortBy: this.sortBy, keepSorted: this.keepSorted }),
        pairwise(),
        takeUntil(this.destroy$),
      )
      .subscribe(async ([prev, curr]) => {
        const socketChanges = this.boardFrameService.socketChanges;

        if (socketChanges) {
          if (prev.keepSorted !== curr.keepSorted || prev.sortBy.property !== curr.sortBy.property) {
            await this.sortApply(this.frames);
          }
          this.boardFrameService.socketChanges = false;
        } else {
          if (prev.keepSorted === curr.keepSorted && prev.sortBy.property === curr.sortBy.property) return;

          const toggleJustTurnedOn = curr.keepSorted && !prev.keepSorted;
          const sortByChangesWhileKeepSorted = curr.keepSorted && prev.sortBy.property !== curr.sortBy.property;
          if (toggleJustTurnedOn || sortByChangesWhileKeepSorted) {
            const res = await this.sortApply(this.frames);
            if (res) {
              this.sendSocketMessage();
            }
            if (toggleJustTurnedOn && !res) {
              // launched sort confirmation modal and clicked `Cancel`
              this.keepSortedControl.setValue(false);
            }
          }
          if (!curr.keepSorted && prev.keepSorted) {
            // toggle off
            this.sendSocketMessage();
          }
        }
      });

    this.boardFrameService.selectedFrameIds$.pipe(takeUntil(this.destroy$)).subscribe((ids) => {
      this.selectedFrameIds = ids;
    });
  }

  handleClose() {
    // this.boardFrameService.selectedFrameIdx$.next(0);
    this.panelClosed.emit();
  }

  private sendSocketMessage() {
    const sortEvt = { sortBy: this.sortBy, keepSorted: this.keepSorted };
    this.boardFrameService.sendFrameSortSocketMessage(sortEvt);
  }
  private async showWarningModal() {
    if (this.frames?.length < 10) return true; // continue
    const confirm = await this.confirmationBoxService.open(
      'Frame Order',
      'Any changes to sort will override the current frame panel order for all users, are you sure you want to continue?',
      'Cancel',
      'Continue',
      true,
    );
    if (confirm) {
      this.boardFrameService.sortWarningShowed = true;
      return true; // continue
    } else {
      return false; // cancel
    }
  }

  async applyChanges() {
    const res = await this.sortApply(this.frames);
    if (res) {
      this.sendSocketMessage();
    }
  }

  async sortApply(frames) {
    if (!this.boardFrameService.sortWarningShowed && !this.boardFrameService.socketChanges) {
      const confirm = await this.showWarningModal();
      if (!confirm) return false;
    }
    const undoElements = cloneDeep(frames.map((f) => f.frame));
    const sortBy = this.sortByControl.value.property;
    switch (sortBy) {
      case 'horizontal': // left > right
        frames.sort((a, b) => {
          const ax = a.frame.position.x;
          const ay = a.frame.position.y;
          const bx = b.frame.position.x;
          const by = b.frame.position.y;
          if (Math.abs(ay - by) < 20) {
            return ax - bx + 20;
          }
          return ay - by;
        });
        break;
      case 'vertical': // top > bottom
        frames.sort((a, b) => {
          const ax = a.frame.position.x;
          const ay = a.frame.position.y;
          const bx = b.frame.position.x;
          const by = b.frame.position.y;
          if (Math.abs(ax - bx) < 20) {
            return ay - by + 20;
          }
          return ax - bx;
        });
        break;
      case 'name':
        frames.sort((f1, f2) => {
          const a = f1.frame.name;
          const b = f2.frame.name;
          let regex = /(\d+)|(\D+)/g;

          let listA = a.match(regex);
          let listB = b.match(regex);

          for (let i = 0; i < Math.min(listA.length, listB.length); i++) {
            if (!isNaN(listA[i]) && !isNaN(listB[i])) {
              let numA = parseInt(listA[i], 10);
              let numB = parseInt(listB[i], 10);

              if (numA !== numB) {
                return numA - numB;
              }
            } else if (!isNaN(listA[i])) {
              return -1;
            } else if (!isNaN(listB[i])) {
              return 1;
            } else if (listA[i] !== listB[i]) {
              return listA[i].localeCompare(listB[i]);
            }
          }

          return listA.length - listB.length;
        });
        break;
      case 'createdOn':
        frames.sort((a, b) => {
          return new Date(a.frame.createdOn).getTime() - new Date(b.frame.createdOn).getTime();
        });
        break;
      default:
        break;
    }

    const elements = cloneDeep(frames.map((f) => f.frame));
    this.frames = frames;
    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: elements,
      },
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: undoElements,
      },
    );
    this.documentService.handleDocumentActions([action]);
    return true;
  }

  renameFrameMode(frame) {
    this.renameForm = { id: frame.id, name: frame?.name, frame: frame };
    setTimeout(() => {
      this.editNameInput?.nativeElement?.focus();
    }, 50);
  }
  updateName(evt?: KeyboardEvent) {
    if (evt && evt.key === 'Escape') {
      this.renameForm = null;
      return;
    }
    if (evt && evt.key !== 'Enter') {
      return;
    }
    if (this.renameForm?.name?.length && this.renameForm?.name !== this.renameForm?.frame?.name) {
      const action = new DocumentAction(
        {
          elementId: this.renameForm.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: { name: this.renameForm?.name, id: this.renameForm.id },
        },
        {
          elementId: this.renameForm.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: { name: this.renameForm?.frame?.name, id: this.renameForm.id },
        },
      );
      this.renameForm.frame.name = this.renameForm.name;
      this.documentService.handleDocumentActions([action], this.documentService.currentDocument);
    }
    if (evt && evt.key === 'Enter') {
      this.renameForm = null;
    }
  }

  deleteFrame(frame) {
    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.DELETE_ELEMENT,
        elementId: frame.id,
      },
      {
        changeType: DocumentChangeType.ADD_ELEMENT,
        elementId: frame.id,
        elementData: frame,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event?.previousIndex === event?.currentIndex) {
      return;
    }

    const prevIdx = event.previousIndex;
    const currIdx = event.currentIndex;
    const selectedIds = this.selectedFrameIds.sort((a, b) => {
      return this.sortOrderIds.indexOf(a) - this.sortOrderIds.indexOf(b);
    });

    if (selectedIds.length === 1) {
      if (prevIdx === currIdx) return;
      this.boardFrameService.updateOneFrameOrder(prevIdx, currIdx);
      moveItemInArray(this.frames, prevIdx, currIdx);
      this.boardFrameService.selectedFrameIdx$.next(currIdx);
    } else if (selectedIds.length > 1) {
      const targetId = this.sortOrderIds[currIdx];
      if (selectedIds.includes(targetId)) {
        let selected = [];
        let front = [];
        let back = [];
        this.frames.forEach((f, idx) => {
          if (selectedIds.includes(f?.id)) {
            selected.push(f);
          } else {
            idx > currIdx ? back.push(f) : front.push(f);
          }
        });
        const newFrames = [...front, ...selected, ...back];
        this.frames = newFrames;
        this.sortOrderIds = newFrames.map((f) => f.id);
      } else {
        let selected = [];
        let nonSelected = [];
        this.frames.forEach((f) => {
          selectedIds.includes(f?.id) ? selected.push(f) : nonSelected.push(f);
        });
        let targetIdx = nonSelected.map((f) => f.id).indexOf(targetId);
        if (prevIdx < currIdx) {
          targetIdx++;
        }
        nonSelected.splice(targetIdx, 0, ...selected);
        this.frames = nonSelected;
        this.sortOrderIds = nonSelected.map((f) => f.id);
      }
      this.boardFrameService.updateMultiFramesOrder(selectedIds, this.sortOrderIds);
    }
  }

  presentFrame(frames) {
    this.boardService.fullScreen();
    // this.boardFrameService.selectedFrameIdx$.next(0);
    this.handleClose();
    this.boardFrameService.presentationMode$.next(true);
    setTimeout(() => {
      const f = frames[this.boardFrameService.selectedFrameIdx$.value];
      this.boardFrameService.updateViewportByFrame(f.frame);
      this.boardFrameService.onFrameSelection$.next(true);
    }, 100);
  }

  trackByFrame(index, f) {
    return f.id && f.frame.name;
  }

  selectFrame(f, idx, mouseEvt: MouseEvent) {
    // update canvas viewport
    if (this.renameForm?.id !== f?.frame?.id) {
      this.renameForm = null;
    }
    this.boardFrameService.onFrameSelection$.next(true);

    let key = 'NONE';
    if (mouseEvt.ctrlKey || mouseEvt.metaKey) {
      key = 'CTRL';
    } else if (mouseEvt.shiftKey) {
      key = 'SHIFT';
    }

    switch (key) {
      case 'CTRL':
        this.ctrlSelect(f.frame, idx);
        break;
      case 'SHIFT':
        this.shiftSelect(f.frame, idx);
        break;
      default: // refresh multiple selection
        this.selectedFrameIds = [f.id];
        this.loadFrame(f.frame, idx);
        break;
    }

    const allElements = this.documentService.getDocument().elements;
    const frames = allElements.filter((ele) => this.selectedFrameIds.includes(ele.id));
    this.documentService.selectElements(frames, false);
  }
  private loadFrame(frame, idx) {
    this.boardFrameService.selectedFrameIdx$.next(idx);
    this.boardFrameService.updateViewportByFrame(frame);
  }

  private ctrlSelect(frame, idx) {
    const index = this.selectedFrameIds.indexOf(frame.id);
    if (index > -1) {
      // Already selected
      if (idx === this.selectedIdx$.value) {
        // click the current selected view frame
        if (this.selectedFrameIds.length > 1) {
          // ignore when only one selected frame and user clicks it.
          this.selectedFrameIds.splice(index, 1);
          const newFrameId = this.selectedFrameIds[0];
          const f = this.frames.find((f, index) => {
            idx = index;
            return f.id === newFrameId ? true : false;
          });
          this.loadFrame(f.frame, idx);
        }
      } else {
        this.selectedFrameIds.splice(index, 1); // remove from selectedIds
      }
    } else {
      // Add to selectedIds
      this.selectedFrameIds.push(frame.id);
      this.loadFrame(frame, idx);
    }
  }

  private shiftSelect(frame, idx) {
    const orderIds = this.frames.map((f) => f.id);
    const targetId = frame.id;
    const targetIdx = orderIds.indexOf(targetId);

    let firstIdx = -1;
    orderIds.find((id, idx) => {
      if (this.selectedFrameIds.includes(id)) {
        firstIdx = idx;
        return id;
      }
    });

    let from = firstIdx;
    let to = targetIdx;
    if (firstIdx > targetIdx) {
      from = targetIdx;
      to = firstIdx;
    }
    this.selectedFrameIds = orderIds.slice(from, to + 1);
    this.loadFrame(frame, idx);
  }

  cdkDragStarted(evt, f, idx) {
    if (!this.selectedFrameIds.includes(f.frame.id)) {
      this.selectedFrameIds = [f.frame.id];
      this.loadFrame(f.frame, idx);
    }
    const preview = document.getElementById('cdkDragPreviewDiv');
    const truncate = f.frame.name.length > 20 ? `${f.frame.name.substring(0, 20)}...` : f.frame.name;
    const nameNode = document.createTextNode(truncate);
    preview.appendChild(nameNode);
    this.dragging = true;
  }

  cdkDragEnded(evt) {
    this.dragging = false;
  }

  listenForClickAway() {
    this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
      // Check if the click target is outside the frames list element or contextmenu
      if (this.elementRef && this.elementRef.nativeElement && this.renameForm?.id) {
        const clickedInside = this.elementRef.nativeElement.querySelector('.frames-list').contains(event.target);
        const contextMenuElement = document.querySelector('.menu-sm');
        if (!clickedInside && !contextMenuElement) {
          this.saveAndExitEditMode(); // Handle the click outside to save and exit editmode
        }
      }
    });
  }

  saveAndExitEditMode() {
    if (
      this.renameForm?.id &&
      this.renameForm?.name?.length &&
      this.renameForm?.name !== this.renameForm?.frame?.name
    ) {
      this.updateName();
    }
    this.renameForm = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.boardFrameService.socketChanges = false;
    // presentation mode false && frame side panel closed => false
    this.boardFrameService.onFrameSelection$.next(false || this.boardFrameService.presentationMode$.value);
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
