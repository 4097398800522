import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ShareAppModalComponent } from './share-app-modal.component';
import { SharedLinkDefinition } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShareAppModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(data: SharedLinkDefinition, autoFocus = false): void {
    const dialogRef = this.dialog.open(ShareAppModalComponent, { data, autoFocus });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
