import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { Board } from '../board.service';
import { BoardsSelectors } from '../../boards-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { DocumentActions } from '../document/document-store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ItemDetailsModalComponent } from '@common/items/item-details-modal/item-details-modal.component';
import { BoardItemService } from '../board-item/board-item.service';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

@Component({
  selector: 'app-board-context-bar',
  templateUrl: './board-context-bar.component.html',
  styleUrls: ['./board-context-bar.component.scss'],
})
export class BoardContextBarComponent implements OnInit, OnDestroy {
  public currentBoard: Board;
  private subscriptions: Array<Subscription> = [];
  constructor(
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private boardItemService: BoardItemService,
  ) {
    this.subscriptions.push(
      this.store.select(BoardsSelectors.currentBoard).subscribe((board) => {
        if (!board) {
          return;
        }
        this.currentBoard = board;
      }),
    );
  }
  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe();
  }
  unsubscribe() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  showContextProperties() {
    this.showChooser('addContextProperties', 'Properties');
  }

  showContextContent() {
    this.showChooser('addContextContent', 'Content');
  }
  showChooser(slug, label, itemLib = false) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = label;
    overlay.slug = slug;
    overlay.showChooser = true;
    overlay.targetSourceType = itemLib ? ChooserSourceOptionTypes.ITEM_LIBRARY : null;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showContextDetails() {
    /** BL NOTE:  Should be consolidating this logic into some sort of 'contextualEntity' service for document elements.  */
    const id = this.currentBoard.primaryContext?.id;
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId: id, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((result) => {
      this.boardItemService.syncElements(result);
    });
  }
}
