<div>
  <div class="title">Source assortment</div>
  <div class="mt-1 flex items-center justify-between" *ngIf="sourceAssortment">
    <mat-icon class="opacity-30" svgIcon="app-assortment"></mat-icon>
    <span class="ml-2 text-base" data-test="source-assortment-name">{{ sourceAssortment?.name }}</span>
    <button
      class="menu-button mat-button mat-button-base"
      (click)="viewHistory()"
      [matTooltip]="'Assortment History'"
      data-test="assortment-history"
    >
      <mat-icon>history</mat-icon>
    </button>
    <div class="mx-4 text-primary text-xs" *ngIf="allowEdit">
      <span class="cursor-pointer" data-test="source-assortment-change-link" (click)="assign()">Change</span> |
      <span class="cursor-pointer" data-test="source-assortment-remove-link" (click)="remove()">Remove</span>
    </div>
  </div>
  <div class="mt-1 flex items-center justify-between" *ngIf="!sourceAssortment">
    <span class="mx-2 text-base" data-test="source-assortment-name">N/A</span>
    <div class="mx-2 text-primary text-xs">
      <span class="cursor-pointer" data-test="source-assortment-select-link" (click)="assign()">Select assortment</span>
    </div>
  </div>
</div>
