import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DownloadContentSnackComponent } from './download/download-content-snack/download-content-snack.component';
import { AnalyticsModule } from '../analytics/analytics.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { ExportComponent } from './export/export.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule } from '@angular/forms';
import { ExportWidgetComponent } from './export-widget/export-widget.component';

@NgModule({
  declarations: [DownloadContentSnackComponent, ExportComponent, ExportWidgetComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    AnalyticsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FormsModule,
    OverlayModule,
  ],
  exports: [DownloadContentSnackComponent, ExportComponent, ExportWidgetComponent],
})
export class ExportsModule {}
