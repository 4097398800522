import { DocumentElement, DocumentTextElementEvent, PositionDefinition } from '@contrail/documents';
import { CanvasDocument, CopiedProperties } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { StickyNoteEditorManager } from '../../elements/sticky-note/editor/sticky-note-editor-manager';
import { TextFormat } from '../../elements/text/editor/text-editor';
import { TextEditorManager } from '../../elements/text/editor/text-editor-manager';
import { TextStyle } from '../../elements/text/text-style';
import { EditorCalc } from './editor-calc';
import { EditorFormatter } from './editor-formatter';
import { EditorManager } from './editor-manager';
import { ChildElementDetails } from './editor-placeholder';

export interface TextChanges {
  textChanges?: DocumentElement;
  textUndoChanges?: DocumentElement;
}

export class EditorHandler {
  public stickyNoteEditorManager: StickyNoteEditorManager;
  public textEditorManager: TextEditorManager;
  public editorCalc: EditorCalc;

  constructor(private canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT') {
      this.stickyNoteEditorManager = new StickyNoteEditorManager(canvasDocument);
      this.textEditorManager = new TextEditorManager(canvasDocument);
      this.editorCalc = new EditorCalc(canvasDocument);
    }
  }

  private getManager(element: CanvasElement): EditorManager {
    return element?.elementDefinition?.type === 'sticky_note' ? this.stickyNoteEditorManager : this.textEditorManager;
  }

  public showEditor(element: CanvasElement) {
    this.getManager(element)?.showEditor(element);
  }

  public hideEditor() {
    this.stickyNoteEditorManager?.hideEditor();
    this.textEditorManager?.hideEditor();
  }

  public redrawEditor() {
    this.stickyNoteEditorManager?.redrawEditor();
    this.textEditorManager?.redrawEditor();
  }

  public applyDocumentTextElementEvent(change: DocumentTextElementEvent) {
    const stickyNoteEventSupported =
      ['bold', 'italic', 'underline', 'strikethrough', 'textColor', 'textAlign', 'textValign'].indexOf(
        change?.textFormat?.type,
      ) !== -1;
    const selectedElements: Array<CanvasElement> = this.canvasDocument?.interactionHandler?.selectionHandler
      ?.getSelectedUnlockedCanvasElements()
      .filter((element) =>
        stickyNoteEventSupported
          ? element.elementDefinition.type === 'sticky_note' || element.elementDefinition.type === 'text'
          : element.elementDefinition.type === 'text',
      );

    console.log('EditorHandler.applyDocumentTextElementEvent', change, selectedElements);
    if (
      change?.textFormat?.type &&
      (selectedElements?.findIndex((e) => e.elementDefinition.type === 'text') !== -1 ||
        change?.element?.type === 'text') &&
      this.textEditorManager?.options?.setLastAppliedTextFormat
    ) {
      const textFormat = {};
      if (['bold', 'italic', 'underline', 'strikethrough'].indexOf(change.textFormat.type) !== -1) {
        textFormat[change.textFormat.type] = change.textFormat.value ? change.textFormat.type : 'normal';
      } else {
        textFormat[change.textFormat.type] = change.textFormat.value;
      }
      this.canvasDocument.documentService.saveLastAppliedTextFormat(textFormat);
    }

    const elementChanges = {
      textChanges: [],
      textUndoChanges: [],
    };

    if (!change?.element || selectedElements?.length > 1) {
      for (let i = 0; i < selectedElements?.length; i++) {
        const element: CanvasElement = selectedElements[i];
        const textChanges = {
          textChanges: [],
          textUndoChanges: [],
        };
        this.getManager(element).assignTextEventChanges(element, [change], textChanges, false);
        elementChanges.textChanges = elementChanges.textChanges.concat(textChanges.textChanges);
        elementChanges.textUndoChanges = elementChanges.textUndoChanges.concat(textChanges.textUndoChanges);
      }
    } else if (change?.element || selectedElements?.length === 1) {
      const element: CanvasElement = change?.element
        ? this.canvasDocument.getCanvasElementById(change.element.id)
        : selectedElements[0];
      this.getManager(element).assignTextEventChanges(element, [change], elementChanges);
    }
    if (elementChanges?.textChanges.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        elementChanges.textChanges,
        elementChanges.textUndoChanges,
      );
    }
  }

  public applyDocumentStickyNoteElementEvent(changes: DocumentTextElementEvent) {
    this.stickyNoteEditorManager.applyDocumentStickyNoteElementEvent(changes);
  }

  public removeEditor() {
    this.stickyNoteEditorManager?.removeEditor();
    this.textEditorManager?.removeEditor();
    this.editorCalc?.remove();
  }

  public refreshElement(element: CanvasElement) {
    this.getManager(element)?.refreshElement(element);
  }

  public getCurrentStyle(element: CanvasElement) {
    this.getManager(element)?.getCurrentStyle(element);
  }

  public calculateTextChildElemPositions(element: CanvasElement, textStyle: TextStyle) {
    this.getManager(element)?.editorPlaceholder?.setContent(element, textStyle);
  }

  public handleFocusHyperlink(details: ChildElementDetails, element: DocumentElement, position: PositionDefinition) {
    this.textEditorManager.handleFocusHyperlink(details, element, position);
  }

  public handleBlurHyperlink(details: ChildElementDetails = null) {
    this.textEditorManager.handleBlurHyperlink(details);
  }

  public isEditing(element: CanvasElement) {
    return this.getManager(element)?.isEditing(element);
  }

  public copySelectedTextElementStyle(element: CanvasElement): TextFormat {
    return this.textEditorManager?.editor?.copySelectedTextElementStyle(element);
  }

  public applyCopiedProperties(properties: CopiedProperties) {
    this.textEditorManager?.applyCopiedProperties(properties);
  }
}
