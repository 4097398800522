import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemData } from '@common/item-data/item-data';
import { DashboardDefinition, DashboardWidgetConfiguration } from '../dashboard-interfaces';
import { ASSORTMENT_SUMMARY_DASHBOARD_WIDGET } from '../dashboard-widget-registry';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor() {}

  @Input() assortmentItemData: Array<ItemData>;
  @Input() public dashboardDefinition: DashboardDefinition;
  @Input() appContext: any;
  @Input() annotationOptions: any[];

  ngOnInit(): void {}
}
