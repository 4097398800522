import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasComponentElement } from '../../../elements/component/canvas-component-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class PresentationInteractionHandler {
  private lastDocumentCursor: string;
  private lastElementTarget: CanvasElement;

  constructor(private canvasDocument: CanvasDocument) {}

  public mousemove(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    let documentCursor;

    if (elementTarget.element?.elementDefinition?.type === 'component') {
      documentCursor = 'pointer';
      const element: CanvasComponentElement = elementTarget.element as CanvasComponentElement;
      if (element?.interactable) {
        if (this.lastElementTarget?.elementDefinition?.id !== element.elementDefinition.id) {
          if (this.lastElementTarget?.elementDefinition) {
            this.handleMouseLeave(this.lastElementTarget);
          }
          this.handleMouseEnter(element);
        }
      }
    } else {
      if (this.lastElementTarget?.elementDefinition?.type === 'component') {
        this.handleMouseLeave(this.lastElementTarget);
      }
    }

    if (this.lastDocumentCursor != documentCursor) {
      this.canvasDocument.interactionHandler.setInteractionCursor(documentCursor);
    }

    this.lastDocumentCursor = documentCursor;
    if (this.lastElementTarget?.elementDefinition?.id !== elementTarget?.element?.elementDefinition?.id) {
      this.lastElementTarget = elementTarget.element;
    }
  }

  private handleMouseLeave(element: CanvasElement) {
    const clientRect = element.getBoundingClientRect();
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: element.elementDefinition,
      eventType: 'mouseleave',
      renderedElementPosition: element.toWindowPosition(clientRect),
      renderedElementSize: element.toWindowSize(clientRect),
    });
  }

  private handleMouseEnter(element: CanvasElement) {
    const clientRect = element.getBoundingClientRect();
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: element.elementDefinition,
      eventType: 'mouseenter',
      renderedElementPosition: element.toWindowPosition(clientRect),
      renderedElementSize: element.toWindowSize(clientRect),
    });
  }
}
