import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-tools-main-panel',
  templateUrl: './dev-tools-main-panel.component.html',
  styleUrls: ['./dev-tools-main-panel.component.scss'],
})
export class DevToolsMainPanelComponent implements OnInit {
  public view: string = 'summary';
  constructor() {}

  ngOnInit(): void {}
}
