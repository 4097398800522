import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsSelectors } from '../comments-store';
import { CommentOwnerInfo } from '../comments.service';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss'],
})
export class CommentsModalComponent implements OnInit {
  private ownerInfo: CommentOwnerInfo;
  private ownedByReference: string;
  comments$: Observable<Array<Comment>>;
  modalTitle = '';
  constructor(
    public dialogRef: MatDialogRef<CommentsModalComponent>,
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data && data.ownerInfo) {
      this.ownerInfo = data.ownerInfo;
      this.ownedByReference = this.ownerInfo.entityType + ':' + this.ownerInfo.id;
      this.modalTitle = data.ownerInfo.modalTitle;
    }
  }

  ngOnInit(): void {
    this.comments$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments: any) => {
        return comments.filter((obj) => this.ownedByReference && this.ownedByReference === obj.ownedByReference);
      }),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
