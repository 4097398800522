import {
  Mesh,
  MeshBuilder,
  StandardMaterial,
  NodeMaterial,
  Scene,
  TextureBlock,
  Texture,
  Vector3,
  Tools,
  Matrix,
  PointerInfo,
  Color4,
  GizmoManager,
} from '@babylonjs/core';
import { AssetWrapper } from '../asset/assetwrapper';
import { ImageUtils } from '../util/image-utils';
import { PointerEventTypes } from '../input/pointer-event-types';
import { InputHandlerInterface } from '../input/input-handler';

export class DecalManager implements InputHandlerInterface {
  isPlacing = false;
  isLMBDown = false;
  isDetectingDrag = false;
  isDraggingGizmo = false;

  private scene: Scene;
  private assetWrapper: AssetWrapper; // Caching this so we can filter only relevant meshes for projection
  private decalMat: NodeMaterial;
  private matUrl = 'https://s3.amazonaws.com/contrail.public.testing/pbr_editable_mat%2Balpha_v0-6.json';

  private gizmoManager: GizmoManager;

  private currentDecal: Mesh;

  // TODO: should move these into decal wrapper object?
  private decalProjector: Mesh;
  private decalHeightRatio: number; // Height ratio obtained by decal texture size
  private decalMatInst: NodeMaterial;
  private decalAngle: number = 0;
  private decalBaseSize: Vector3; // Size of decal relative to asset, not considering adjusted scale or height ratio

  private lastPickResult; // Used to reproject decal in place without additional mouse input

  hasExclusiveControl: boolean = false;
  highlightedMesh: Mesh;
  selectedMesh: Mesh;

  async init(scene: Scene, assetWrapper: AssetWrapper) {
    this.scene = scene;
    this.assetWrapper = assetWrapper;

    let decalLength = assetWrapper.getAssetSize().length() * 0.1;
    this.decalBaseSize = new Vector3(decalLength, decalLength, decalLength);

    this.decalProjector = MeshBuilder.CreateBox(
      'decalProjector',
      { width: decalLength, height: decalLength, depth: decalLength },
      scene,
    );
    this.decalProjector.material = new StandardMaterial('projectorMat', this.scene);
    this.decalProjector.material.alpha = 0;
    this.decalProjector.enableEdgesRendering(0.99);
    this.decalProjector.edgesWidth = decalLength;
    this.decalProjector.edgesColor = Color4.FromHexString('#2196f3');
    this.decalProjector.visibility = 0;
    this.decalProjector.setEnabled(false);

    this.decalMat = await NodeMaterial.ParseFromFileAsync('decalMat', this.matUrl, scene);

    this.initGizmoManager();
  }

  updateLoop(): void {
    if (this.isPlacing) {
      this.updateDecalPlacement();
    }

    if (this.isDraggingGizmo) {
      this.projectDecal(this.lastPickResult);
    }
  }

  handlePointerInput(pointerInfo: PointerInfo): boolean {
    const event = pointerInfo.event;

    if (!this.decalMatInst) {
      return;
    }

    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN: {
        if (this.highlightedMesh) {
          if (pointerInfo.event.button == 0) {
            this.isLMBDown = true;
          }

          if (!this.isPlacing && !this.isDetectingDrag) {
            this.isDetectingDrag = true;
          }

          this.hasExclusiveControl = true;

          return true;
        }

        break;
      }
      case PointerEventTypes.POINTERUP: {
        if (pointerInfo.event.button == 0) {
          this.isLMBDown = false;
        }

        this.isDetectingDrag = false;
        this.hasExclusiveControl = false;

        if (this.isPlacing) {
          this.stopDecalPlacement();

          event.preventDefault();
          return true;
        } else if (this.selectedMesh) {
          this.deselectMesh();
        } else if (this.highlightedMesh) {
          this.selectedMesh = this.highlightedMesh;

          this.enableGizmos(true);

          return true;
        }

        break;
      }
      case PointerEventTypes.POINTERMOVE: {
        if (this.isPlacing) {
          break;
        }

        if (this.isDetectingDrag && this.isLMBDown && this.highlightedMesh) {
          this.highlightedMesh.parent = null;

          this.isPlacing = true;
        }

        let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
          if (mesh == this.decalProjector) return true;

          return false;
        });

        if (pickResult.hit) {
          this.highlightedMesh = this.decalProjector;

          this.updateMeshVisibility(this.decalProjector);

          return true;
        } else if (this.highlightedMesh) {
          this.highlightedMesh = null;

          this.updateMeshVisibility(this.decalProjector);
        }

        break;
      }
    }

    return false;
  }

  async addDecal(textureData: string): Promise<void> {
    let imgDimensions = await ImageUtils.getImageDimensions(textureData);
    let height = imgDimensions.height;
    let width = imgDimensions.width;

    this.decalHeightRatio = height / width;

    if (this.currentDecal) {
      this.currentDecal.dispose();
      this.currentDecal = null;
    }

    if (this.decalMatInst) {
      this.decalMatInst.dispose();
      this.decalMatInst = null;
    }

    this.decalMatInst = this.decalMat.clone('cloneMat');
    this.decalMatInst.zOffset = -2;

    let texture = new Texture(textureData, this.scene);
    texture.hasAlpha = true;

    let albedoTextureInput = this.decalMatInst.getBlockByName('Albedo texture') as TextureBlock;
    albedoTextureInput.texture = texture;

    let opacTextureInput = this.decalMatInst.getBlockByName('Opacity texture') as TextureBlock;
    opacTextureInput.texture = texture;

    this.decalProjector.parent = null;

    this.isPlacing = true;
    this.hasExclusiveControl = true;
  }

  updateDecalPlacement() {
    let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => {
      for (let i = 0; i < this.assetWrapper.getAssetContainer().meshes.length; i++) {
        if (mesh == this.assetWrapper.getAssetContainer().meshes[i]) return true;
      }

      return false;
    });

    if (pickResult.hit) {
      const normal = this.scene.activeCamera.getForwardRay().direction.negateInPlace().normalize();

      // Update decal projector position rotation
      this.decalProjector.position.copyFrom(pickResult.pickedPoint);

      const yaw = -Math.atan2(normal.z, normal.x) - Math.PI / 2;
      const len = Math.sqrt(normal.x * normal.x + normal.z * normal.z);
      const pitch = Math.atan2(normal.y, len);

      this.decalProjector.rotation.set(pitch, yaw, this.decalProjector.rotation.z);

      // Create a new decal
      this.projectDecal(pickResult);

      // Cache input info
      this.lastPickResult = pickResult;
    } else {
      if (this.currentDecal) {
        this.currentDecal.dispose();
        this.currentDecal = null;
      }
    }
  }

  private projectDecal(pickResult): void {
    if (!pickResult) {
      return;
    }

    if (!this.decalMatInst) {
      return;
    }

    if (this.currentDecal) {
      this.currentDecal.dispose();
      this.currentDecal = null;
    }

    let size = this.decalBaseSize.scale(this.decalProjector.scaling.x);
    size.y *= this.decalHeightRatio;

    const position = this.decalProjector.absolutePosition;

    this.currentDecal = MeshBuilder.CreateDecal('decal', pickResult.pickedMesh, {
      position,
      normal: this.decalProjector.forward.scale(-1),
      angle: this.decalProjector.rotation.z,
      size,
    });
    this.currentDecal.material = this.decalMatInst;
    this.currentDecal.setParent(this.assetWrapper.rootNode);
  }

  updateDecalAngle(angleInDegrees: number): void {
    if (!this.currentDecal) {
      return;
    }

    this.decalAngle = Tools.ToRadians(angleInDegrees);

    this.decalProjector.rotation.z = this.decalAngle;

    this.projectDecal(this.lastPickResult);
  }

  updateDecalScale(newScale: number): void {
    if (!this.currentDecal) {
      return;
    }

    this.decalProjector.scaling = Vector3.One().scale(newScale);

    this.projectDecal(this.lastPickResult);
  }

  stopDecalPlacement() {
    this.isPlacing = false;
    this.hasExclusiveControl = false;

    this.decalProjector.setParent(this.assetWrapper.rootNode);
  }

  deleteDecal() {
    if (this.currentDecal) {
      this.currentDecal.dispose();
      this.currentDecal = null;
    }

    if (this.decalMatInst) {
      this.decalMatInst.dispose();
      this.decalMatInst = null;
    }

    if (this.selectedMesh) {
      this.deselectMesh();
    }

    if (this.isPlacing) {
      this.stopDecalPlacement();
    }

    this.highlightedMesh = null;

    this.updateMeshVisibility(this.decalProjector);
  }

  deselectMesh() {
    this.enableGizmos(false);

    this.selectedMesh = null;

    this.updateMeshVisibility(this.decalProjector);
  }

  updateMeshVisibility(mesh: Mesh): void {
    if (mesh != null && (this.highlightedMesh == mesh || this.selectedMesh == mesh)) {
      mesh.visibility = 1;
      mesh.setEnabled(true);
    } else if (mesh) {
      mesh.visibility = 0;
      mesh.setEnabled(false);
    }
  }

  private initGizmoManager(): void {
    this.gizmoManager = new GizmoManager(this.scene);
    this.gizmoManager.usePointerToAttachGizmos = false;
    this.gizmoManager.clearGizmoOnEmptyPointerEvent = false;

    // Need to set these flags to true to spawn the other gizmos for the below settings
    this.gizmoManager.scaleGizmoEnabled = true;
    this.gizmoManager.gizmos.scaleGizmo.sensitivity = 10;
    this.gizmoManager.gizmos.scaleGizmo.zGizmo.isEnabled = false;
    this.gizmoManager.gizmos.scaleGizmo.xGizmo.isEnabled = false;
    this.gizmoManager.gizmos.scaleGizmo.yGizmo.isEnabled = false;
    this.gizmoManager.gizmos.scaleGizmo.uniformScaleGizmo.isEnabled = true;

    this.gizmoManager.rotationGizmoEnabled = true;
    this.gizmoManager.gizmos.rotationGizmo.yGizmo.isEnabled = false;
    this.gizmoManager.gizmos.rotationGizmo.xGizmo.isEnabled = false;

    this.gizmoManager.gizmos.scaleGizmo.onDragStartObservable.add(this.handleGizmoDragStart, 1, false, this);
    this.gizmoManager.gizmos.scaleGizmo.onDragEndObservable.add(this.handleGizmoDragEnd, 1, false, this);
    this.gizmoManager.gizmos.rotationGizmo.onDragStartObservable.add(this.handleGizmoDragStart, 1, false, this);
    this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(this.handleGizmoDragEnd, 1, false, this);

    this.gizmoManager.scaleGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
  }

  private handleGizmoDragStart(): void {
    this.isDraggingGizmo = true;
  }

  private handleGizmoDragEnd(): void {
    this.isDraggingGizmo = false;

    this.projectDecal(this.lastPickResult);
  }

  private enableGizmos(enable: boolean): void {
    if (!this.gizmoManager) {
      this.initGizmoManager();
    }

    this.gizmoManager.scaleGizmoEnabled = enable;
    this.gizmoManager.rotationGizmoEnabled = enable;

    if (enable) {
      this.gizmoManager.attachToNode(this.decalProjector);
    } else {
      this.gizmoManager.attachToNode(null);
    }
  }
}
