import { PositionDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR } from '../../constants';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasComponentElement } from '../../elements/component/canvas-component-element';
import { DRAG_DIRECTIONS } from '../selection-widget-renderer/selection-widget-renderer';
import { RotationHelper } from './rotation-helper';

export class RotationWidgetRenderer {
  protected readonly ROTATION_HANDLE_RADIUS = 10;
  private scaledHandleSize;

  private center: PositionDefinition;
  public angleCoordinates: PositionDefinition;

  private path: Path2D = new Path2D(
    'M3.22185 13.1311L4.75195 10.4393L6.3104 7.77573H4.35526C4.66695 6.52898 5.29033 5.4239 6.16872 4.57385C7.38714 3.32709 9.08726 2.56204 10.9574 2.56204C12.8275 2.56204 14.5276 3.32709 15.7461 4.57385C16.9928 5.79226 17.7295 7.49238 17.7295 9.36251C17.7295 11.2326 16.9928 12.9328 15.7461 14.1512C14.5276 15.3696 12.8275 16.1346 10.9574 16.1346C10.419 16.1346 9.99398 16.5597 9.99398 17.098C9.99398 17.6364 10.419 18.0614 10.9574 18.0614C13.3659 18.0614 15.5477 17.098 17.1061 15.5113C18.6929 13.9245 19.6563 11.771 19.6563 9.36251C19.6563 6.95401 18.6929 4.77219 17.1061 3.21375C15.5477 1.62697 13.3659 0.663574 10.9574 0.663574C8.54888 0.663574 6.36707 1.62697 4.80863 3.21375C3.59021 4.43217 2.71181 6.01895 2.40012 7.77573H0.133301L1.66341 10.4393L3.22185 13.1311Z',
  );
  private readonly SUPPORTED_ELEMENT_TYPES = [
    'text',
    'image',
    'rectangle',
    'circle',
    'triangle',
    'diamond',
    'star',
    'heart',
    'double_arrow',
    'right_arrow',
    'rhombus',
    'cloud',
    'component',
    'svg',
    'iframe',
    'pen',
    'highlighter',
  ];

  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {}

  public draw(ctx) {
    if (
      this.SUPPORTED_ELEMENT_TYPES.indexOf(this.element.elementDefinition.type) === -1 ||
      this.element.elementDefinition.isLocked ||
      this.element.isImageError ||
      (this.element.isMask && !this.canvasDocument.state?.maskState?.isEditingMask(this.element.id))
    )
      return;
    const isComponent = this.element.elementDefinition.type === 'component';
    let { x, y } = this.element.getPosition({ outerEdgeDimensions: true });
    x = x - this.element.PADDING_LEFT;
    y = y - this.element.PADDING_TOP;
    let { width, height } =
      (isComponent && (this.element as CanvasComponentElement).documentSize) ||
      this.element.getSize({ outerEdgeDimensions: true });
    width = width + this.element.PADDING_LEFT + this.element.PADDING_RIGHT;
    height = height + this.element.PADDING_TOP + this.element.PADDING_BOTTOM;

    const viewScale = this.canvasDocument.getViewScale();
    // const handleWidth = this.getHandleWidth();
    const handleWidth = Math.max(
      this.ROTATION_HANDLE_RADIUS,
      Math.round((this.ROTATION_HANDLE_RADIUS * 2) / viewScale.x),
    );
    this.scaledHandleSize = handleWidth;

    if (this.angleCoordinates) {
      ctx.save();
      const fontSize = Math.round(16 / viewScale.x);
      const fontString = `${fontSize}px Roboto`;
      ctx.font = fontString;
      ctx.fillStyle = ACCENT_COLOR;
      const angle = this.element?.elementDefinition?.rotate?.angle.toString() + '°';
      ctx.fillText(angle, this.angleCoordinates.x, this.angleCoordinates.y);
      ctx.restore();
    }

    ctx.save();

    ctx.translate(x + width * 0.5, y + height * 0.5);
    ctx.rotate(CanvasUtil.getAngle(this.element.elementDefinition?.rotate?.angle ?? 0));
    ctx.translate(-width * 0.5 - handleWidth, height * 0.5 + handleWidth * 0.5);
    const scaleX = Math.max(0.5, 1 / viewScale.x);
    const scaleY = Math.max(0.5, 1 / viewScale.y);
    ctx.scale(scaleX, scaleY);
    this.center = { x: x - handleWidth * 0.5, y: y + height + handleWidth };
    ctx.fillStyle = ACCENT_COLOR;
    ctx.fill(this.path);

    ctx.restore();
  }

  public getCenter() {
    return this.center;
  }

  protected isOnDragHandle(p1, p2) {
    return Math.abs(p1 - p2) < this.scaledHandleSize * 0.5;
    // const dragHandleWidth = this.getHandleWidth();
    // return Math.abs(p1 - p2) < dragHandleWidth * 0.5;
  }

  protected getHandleWidth() {
    return this.canvasDocument.getScaledValue(this.ROTATION_HANDLE_RADIUS * 2);
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    if (this.SUPPORTED_ELEMENT_TYPES.indexOf(this.element.elementDefinition.type) === -1 || !this.center) return;

    let direction;
    const isComponent = this.element.elementDefinition.type === 'component';
    let { x, y } = this.element.getPosition();
    x = x - this.element.PADDING_LEFT;
    y = y - this.element.PADDING_TOP;
    let { width, height } =
      (isComponent && (this.element as CanvasComponentElement).documentSize) || this.element.getSize();
    width = width + this.element.PADDING_LEFT + this.element.PADDING_RIGHT;
    height = height + this.element.PADDING_TOP + this.element.PADDING_BOTTOM;

    if (this.element.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -this.element.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }
    if (this.isOnDragHandle(px, this.center.x) && this.isOnDragHandle(py, this.center.y)) {
      direction = DRAG_DIRECTIONS.ROTATE;
    }
    return direction;
  }
}
