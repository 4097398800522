import { CanvasDocument } from '../../canvas-document';
import { LineDefinition } from '@contrail/documents';

export class GuidelinesRenderer {
  public guidelines: { id: string; lineDefinition: LineDefinition }[] = [];
  public distanceGuidelinesx: { lineDefinition: LineDefinition }[] = [];
  public distanceGuidelinesy: { lineDefinition: LineDefinition }[] = [];

  private readonly STROKE_WIDTH = 1;
  private readonly STROKE_COLOR = '#ffad00';

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw guidelines
   */
  public draw() {
    if (this.guidelines?.length > 0) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      for (let i = 0; i < this.guidelines.length; i++) {
        const guideline = this.guidelines[i];
        const { x1, y1, x2, y2 } = guideline.lineDefinition;
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = this.STROKE_COLOR;
        ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
        ctx.setLineDash([ctx.lineWidth * 4, ctx.lineWidth * 5]);
        ctx.lineCap = 'butt';
        ctx.stroke();
        ctx.closePath();
      }
    }

    if (this.distanceGuidelinesx?.length > 0) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      for (let i = 0; i < this.distanceGuidelinesx.length; i++) {
        const guideline = this.distanceGuidelinesx[i];
        const { x1, y1, x2, y2 } = guideline.lineDefinition;
        const d = Math.min(20, this.canvasDocument.getScaledValue(5));
        const offset = Math.min(Math.round((x2 - x1) * 0.25), d);
        ctx.beginPath();
        ctx.moveTo(x1 + offset, y1 - d);
        ctx.lineTo(x1 + offset, y1 + d);

        ctx.moveTo(x1 + offset, y1);
        ctx.lineTo(x2 - offset, y2);

        ctx.moveTo(x2 - offset, y1 - d);
        ctx.lineTo(x2 - offset, y1 + d);

        ctx.strokeStyle = this.STROKE_COLOR;
        ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
        ctx.setLineDash([]);
        ctx.stroke();
        ctx.closePath();
      }
    }

    if (this.distanceGuidelinesy?.length > 0) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      for (let i = 0; i < this.distanceGuidelinesy.length; i++) {
        const guideline = this.distanceGuidelinesy[i];
        const { x1, y1, x2, y2 } = guideline.lineDefinition;
        const d = Math.min(20, this.canvasDocument.getScaledValue(5));
        const offset = Math.min(Math.round((y2 - y1) * 0.25), d);
        ctx.beginPath();
        ctx.moveTo(x1 - d, y1 + offset);
        ctx.lineTo(x1 + d, y1 + offset);

        ctx.moveTo(x1, y1 + offset);
        ctx.lineTo(x2, y2 - offset);

        ctx.moveTo(x2 - d, y2 - offset);
        ctx.lineTo(x2 + d, y2 - offset);

        ctx.strokeStyle = this.STROKE_COLOR;
        ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
        ctx.setLineDash([]);
        ctx.stroke();
        ctx.closePath();
      }
    }
  }
}
