import { CanvasDocument } from '../../canvas-document';
import { ACCENT_COLOR } from '../../constants';
import { CoordinateBox } from '../../coordinate-box';
import { DRAG_DIRECTIONS, SelectionWidgetRenderer } from './selection-widget-renderer';

export class MultipleElementsSelectionWidgetRenderer extends SelectionWidgetRenderer {
  public commonBounds: CoordinateBox = null;
  protected readonly STROKE_WIDTH = 1;
  protected readonly DRAG_HANDLE_WIDTH = 6;

  constructor(protected canvasDocument: CanvasDocument) {
    super(canvasDocument);
  }

  public draw() {
    const selectedElements =
      this.canvasDocument.interactionHandler.selectionHandler.getSelectedUnlockedElementsByFrame();
    if (selectedElements?.length <= 1) {
      return;
    }

    this.commonBounds = this.canvasDocument.state.getCommonBounds(selectedElements, { outerEdgeDimensions: true });
    const ctx = this.canvasDocument.canvasDisplay.context;
    let { x, y, width, height } = this.commonBounds;

    ctx.save();

    this.drawWidget(ctx, { x, y, width, height });

    const dragHandleWidth = this.getHandleWidth();
    const dragHandlePositions = [
      [-width * 0.5, -height * 0.5],
      // [0, -height*0.5],
      [width * 0.5, -height * 0.5],
      // [width*0.5, 0],
      [width * 0.5, height * 0.5],
      // [0, height*0.5],
      [-width * 0.5, height * 0.5],
      // [-width*0.5, 0]
    ];

    for (let i = 0; i < dragHandlePositions?.length; i++) {
      const dragHandlePosition = dragHandlePositions[i];
      this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
    }

    ctx.restore();
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    if (!this.commonBounds) {
      return;
    }
    let direction;
    let { x, y, width, height } = this.commonBounds;

    const top = this.isOnDragHandle(py, y);
    const left = this.isOnDragHandle(px, x);
    const right = this.isOnDragHandle(px, x + width);
    const bottom = this.isOnDragHandle(py, y + height);
    const horizontal = x < px && x + width > px;
    const vertical = y < py && y + height > py;
    // const center = this.isOnDragHandle(px, x + width*0.5);
    // const mid = this.isOnDragHandle(py, y + height*0.5);

    switch (true) {
      case left && top:
        direction = DRAG_DIRECTIONS.TOP_LEFT;
        break;
      case right && top:
        direction = DRAG_DIRECTIONS.TOP_RIGHT;
        break;
      case left && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_LEFT;
        break;
      case right && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_RIGHT;
        break;

      // case top && horizontal:
      //   direction = DRAG_DIRECTIONS.TOP_CENTER;
      //   break;
      // case bottom && horizontal:
      //   direction = DRAG_DIRECTIONS.BOTTOM_CENTER;
      //   break;
      // case right && vertical:
      //   direction = DRAG_DIRECTIONS.MID_RIGHT;
      //   break;
      // case left && vertical:
      //   direction = DRAG_DIRECTIONS.MID_LEFT;
      //   break;

      // case center && top:
      //   direction = DRAG_DIRECTIONS.TOP_CENTER;
      //   break;
      // case center && bottom:
      //   direction = DRAG_DIRECTIONS.BOTTOM_CENTER;
      //   break;
      // case right && mid:
      //   direction = DRAG_DIRECTIONS.MID_RIGHT;
      //   break;
      // case left && mid:
      //   direction = DRAG_DIRECTIONS.MID_LEFT;
      //   break;
      default:
        break;
    }

    return direction;
  }
}
