import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasClipElement } from './canvas-clip-element';

export class CanvasRectangleElement extends CanvasClipElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);

    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
      border: {
        width: this.DEFAULT_BORDER_SIZE,
        color: this.DEFAULT_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    ctx.beginPath();

    if (this.isPartOfComponent) {
      this.addPath(ctx, { x, y, width, height });
    } else {
      this.addPath(ctx, { x: -width * 0.5, y: -height * 0.5, width, height });
    }

    ctx.fillStyle = this.elementDefinition.style?.backgroundColor || 'rgba(0,0,0,0)';
    ctx.fill();
    this.stroke(ctx, options);
    ctx.closePath();

    return;
  }

  public addPath(ctx, { x, y, width, height }) {
    const lineWidth = this.elementDefinition.style?.border?.width || this.DEFAULT_BORDER_SIZE;
    let radius = this.elementDefinition.style?.border?.radius || this.DEFAULT_BORDER_RADIUS;

    if (!ctx.roundRect) {
      if (width < 2 * radius) radius = width * 0.5;
      if (height < 2 * radius) radius = height * 0.5;

      ctx.moveTo(x + radius - lineWidth * 0.5, y);
      ctx.arcTo(x + width, y, x + width, y + height, radius);
      ctx.arcTo(x + width, y + height, x, y + height, radius);
      ctx.arcTo(x, y + height, x, y, radius);
      ctx.arcTo(x, y, x + width, y, radius);
    } else {
      ctx.roundRect(x, y, width, height, radius);
    }
  }

  public getPath({ x, y, width, height }): Path2D {
    let radius = this.elementDefinition.style?.border?.radius || this.DEFAULT_BORDER_RADIUS;
    const lineWidth = this.elementDefinition.style?.border?.width || this.DEFAULT_BORDER_SIZE;
    const path = new Path2D();
    if (radius && radius > 0) {
      if (width < 2 * radius) radius = width * 0.5;
      if (height < 2 * radius) radius = height * 0.5;

      path.moveTo(x + radius - lineWidth * 0.5, y);
      path.arcTo(x + width, y, x + width, y + height, radius);
      path.arcTo(x + width, y + height, x, y + height, radius);
      path.arcTo(x, y + height, x, y, radius);
      path.arcTo(x, y, x + width, y, radius);
    } else {
      path.rect(x, y, width, height);
    }
    return path;
  }

  public toSVG({ x, y, width, height }, options?: DrawOptions): HTMLElement {
    const element = document.createElement('rect');
    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);

    let radius = this.elementDefinition.style?.border?.radius || this.DEFAULT_BORDER_RADIUS;
    if (radius && radius > 0) {
      if (width < 2 * radius) radius = width * 0.5;
      if (height < 2 * radius) radius = height * 0.5;
      element.setAttribute('rx', `${radius}`);
      element.setAttribute('ry', `${radius}`);
    }

    if (this.isMask) {
      const mask = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
      element.setAttribute('fill', '#000');
      mask.setAttribute('id', `mask_${this.elementDefinition.id}`);
      mask.setAttributeNS(null, 'clipPathUnits', 'userSpaceOnUse');
      mask.appendChild(element);
      return mask as unknown as HTMLElement;
    }

    this.setSVGFillAttribute(element);
    this.setSVGStrokeAttribute(element);

    return element;
  }
}
