import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Type } from '@contrail/types';

@Component({
  selector: 'app-entity-details-header',
  templateUrl: './entity-details-header.component.html',
  styleUrls: ['./entity-details-header.component.scss'],
})
export class EntityDetailsHeaderComponent implements OnInit {
  @Input() type: Type;
  @Input() entity: any;
  @Input() navOptions: Array<any>;
  @Output() cancelView = new EventEmitter();
  @Output() navigate = new EventEmitter();

  public selectedNavigationOption: any;
  public hasStatus = false;
  public statusClass = '';

  constructor() {}

  ngOnInit(): void {
    this.selectedNavigationOption = this.navOptions[0];

    if (this.type) {
      const statusProperty = this.type.typeProperties.find(
        (p) => p?.slug === 'colorStatus' || p?.slug === 'assetStatus',
      ); //TODO: check the admin app types
      this.hasStatus = statusProperty ? true : false;
      const status = this.entity['colorStatus'] || this.entity['assetStatus']; //TODO: Admin app types
      if (statusProperty) {
        if (status === 'released') {
          this.statusClass = 'bg-success-dark';
        } else if (status === 'development') {
          this.statusClass = 'bg-accent';
        } else {
          // 'concept'
          this.statusClass = 'bg-warn-light';
        }
      }
    }
  }
  cancel() {
    this.cancelView.emit();
  }

  setSelectedNavOption(option: any) {
    this.selectedNavigationOption = option;
    this.navigate.emit(option);
  }
}
