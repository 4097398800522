import { editorModeInitialState, State } from './editor-mode.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as EditorModeActions from './editor-mode.actions';

const setEditorMode = (state: State, { editorMode }) => {
  return {
    ...state,
    editorMode: editorMode,
  };
};

export const editorModeReducers = createReducer(
  editorModeInitialState,
  on(EditorModeActions.setEditorMode, setEditorMode),
);

export function reducer(state: State | undefined, action: Action): any {
  return editorModeReducers(state, action);
}
