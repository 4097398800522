import { Component, Input, OnInit } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyType, Type } from '@contrail/types';
import { Assortment } from '../../assortments/assortment';
import { AssortmentHistoryItem } from '../assortment-history.interfaces';

@Component({
  selector: 'app-assortment-aggregate-history',
  templateUrl: './assortment-aggregate-history.component.html',
  styleUrls: ['./assortment-aggregate-history.component.scss'],
})
export class AssortmentAggregateHistoryComponent implements OnInit {
  @Input() assortment: Assortment;
  @Input() history: Array<AssortmentHistoryItem>;
  public chartProperty;
  public chartAggregate = 'total';
  public typeDef: Type;
  public properties = [];
  constructor() {}

  async ngOnInit() {
    this.typeDef = await new Types().getType({ root: 'assortment-item', path: 'assortment-item' });
    this.properties = this.typeDef.typeProperties
      .filter((prop) => [PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(prop.propertyType))
      .sort((p1, p2) => (p1.label > p2.label ? 1 : -1));
    this.chartProperty = this.properties?.length ? this.properties[0] : null;
  }

  changeProperty($event) {
    this.chartProperty = $event.value;
  }
  changeAggregate($event) {
    this.chartAggregate = $event.value;
  }
}
