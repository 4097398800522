import { Injectable } from '@angular/core';
import { SideMenuOverlay } from '../../document-store/document.state';
import { Store } from '@ngrx/store';
import { DocumentActions } from '../../document-store';
import { EntityReference } from '@contrail/sdk';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { DocumentAction, DocumentChangeType, DocumentElement, DynamicTextDisplayFunction } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentTextElementUtil } from '@contrail/document-generation';
import { DocumentService } from '../../document.service';
import { Observable } from 'rxjs';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { CanvasDocument } from '../../../canvas/canvas-document';
import {
  CLICK_TO_CONFIGURE,
  DocumentDynamicTextUtil,
  HARD_CODED_TEXT,
  MOVE_TO_FRAME_TEXT,
} from './document-dynamic-text-util';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { CanvasTextElement } from '../../../canvas/elements/text/canvas-text-element';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { selectDocumentModelEntities } from '../../document-item/document-model-entities/document-model-entities.selectors';
import { DocumentDynamicTextActionHandler } from './document-dynamic-text-action-handler';
import { DocumentDynamicTextState } from './document-dynamic-text-state';
import { DocumentDynamicTextClipboardHandler } from './document-dynamic-text-clipboard-handler';

@Injectable({
  providedIn: 'root',
})
export class DocumentDynamicTextService {
  public documentService: DocumentService;
  public dynamicTextFeatureFlag = false;
  public dynamicTextActionHandler: DocumentDynamicTextActionHandler;
  public dynamicTextState: DocumentDynamicTextState;
  public dynamicTextClipboardHandler: DocumentDynamicTextClipboardHandler;
  documentElements: DocumentElement[];

  constructor(private store: Store) {
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.dynamicTextFeatureFlag = !!flags.find((x) => x.featureName === Feature.DYNAMIC_TEXT);
    });
  }

  async init(documentService: DocumentService, ownerReferenceObjObs: Observable<any>) {
    this.dynamicTextActionHandler = new DocumentDynamicTextActionHandler(this);

    this.documentService = documentService;
    this.dynamicTextState = new DocumentDynamicTextState();
    this.dynamicTextClipboardHandler = new DocumentDynamicTextClipboardHandler(this);
    if (!this.dynamicTextFeatureFlag) {
      return;
    }
    ownerReferenceObjObs.subscribe(async (ownerReferenceObj) => {
      if (ownerReferenceObj) {
        if (!this.dynamicTextState.baseEntityMap[ownerReferenceObj.entityType]) {
          await this.dynamicTextState.init(ownerReferenceObj);
        } else {
          this.dynamicTextState.baseEntityMap[ownerReferenceObj.entityType] = ownerReferenceObj;
          const textElements = this.documentElements.filter(
            (e) =>
              DocumentDynamicTextUtil.isDynamicTextElement(e) &&
              e.propertyBindings.text.includes(ownerReferenceObj.entityType),
          );
          this.updateDynamicTextElements(textElements);
        }
      }
    });

    this.documentService.documentElementEvents.subscribe((event) => {
      if (!event) {
        return;
      }
      if (event.element) {
        if (
          !event.element.isLocked &&
          DocumentDynamicTextUtil.isDynamicTextElement(event.element) &&
          event.eventType === 'dblclick'
        ) {
          this.showDynamicTextEditor();
        }
      }
    });
    this.store.select(selectDocumentModelEntities).subscribe((entities) => {
      entities
        .filter((e) => ['project-item'].includes(e.entityType)) // only update project-items because item changes are taken care of by the assortment-items
        .forEach((e) => {
          this.dynamicTextState.entityMap.set(e.entityType + ':' + e.id, e);
        });
    });
    this.store.select(AssortmentsSelectors.backingAssortmentItems).subscribe((assortmentItems: any[]) => {
      let initialLoad = false;
      if (this.dynamicTextState.entityMap.size === 0) {
        initialLoad = true;
      }
      assortmentItems.forEach((obj) => {
        this.dynamicTextState.entityMap.set(obj.item.entityType + ':' + obj.item.id, obj.item);
        this.dynamicTextState.entityMap.set(obj.entityType + ':' + obj.id, obj);
        if (obj.projectItem && initialLoad) {
          // only load project-items initially as their values could change.
          this.dynamicTextState.entityMap.set(obj.projectItem.entityType + ':' + obj.projectItem.id, obj.projectItem);
        }
      });
    });
    this.documentService.documentElements.subscribe((elements) => (this.documentElements = elements));
  }

  showDynamicTextEditor() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Dynamic text';
    overlay.slug = 'dynamicTextEditor';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  public async getTextValue(
    element: DocumentElement,
    frame?: any,
    newElements?: any[],
    deletedElementIds?: string[],
    updatedComponentElements?: any[],
  ) {
    if (!element.propertyBindings.text) {
      return CLICK_TO_CONFIGURE;
    }
    const formatter = new PropertyValueFormatter();
    const displayFunction = element.propertyBindingsMetaData?.displayFunction || DynamicTextDisplayFunction.VALUE;
    const displayLabel = element.propertyBindingsMetaData?.displayLabel;
    const typeId = element.propertyBindingsMetaData?.typeId || this.dynamicTextState.itemTypeId; //legacy dynamic text that doesn't have typeId
    const bindingProperty = element.propertyBindings.text; // item.name
    const entityType = bindingProperty.split('.')[0]; // board or showcase or project, etc
    const propertySlug = bindingProperty.split('.')[1];
    const type = await this.dynamicTextState.getType(typeId);
    let property = type.typeProperties.find((p) => p.slug === propertySlug);
    if (['frame.name', 'frame.count'].includes(bindingProperty)) {
      property = null;
    }
    let text = '';
    if (displayFunction === DynamicTextDisplayFunction.LABEL) {
      if (['showcase', 'board', 'project', 'frame'].includes(entityType)) {
        text = HARD_CODED_TEXT[bindingProperty] || property?.label;
      } else if (!frame) {
        text = MOVE_TO_FRAME_TEXT;
      } else if (frame) {
        text = property?.label;
      }
    } else {
      const modelBindingsArray = [];
      let frameMemberComponentElements = [];
      if (frame) {
        frameMemberComponentElements = DocumentDynamicTextUtil.getFrameElementsByEntityType(frame, entityType);
        frameMemberComponentElements.forEach((componentElement) => {
          if (!deletedElementIds || !deletedElementIds.includes(componentElement.id)) {
            if (updatedComponentElements) {
              const updatedElement = updatedComponentElements.find((e) => e.id === componentElement.id);
              if (updatedElement) {
                componentElement.modelBindings = updatedElement.modelBindings;
              }
            }
            modelBindingsArray.push(componentElement.modelBindings);
          }
        });
        if (newElements?.length > 0) {
          modelBindingsArray.push(...newElements.map((e) => e.modelBindings));
        }
      }
      if (modelBindingsArray.length > 0) {
        const entityTypeIdsMap = new Map(this.getEntityTypeIdsMap(modelBindingsArray)); // group ids by type for bulk query of objects
        this.store.dispatch(setLoading({ loading: true }));
        await DocumentDynamicTextUtil.fetchEntities(entityTypeIdsMap, this.dynamicTextState.entityMap);
        this.store.dispatch(setLoading({ loading: false }));
      }
      let model = null;

      // based on the document, project
      if (['showcase', 'board', 'project'].includes(entityType)) {
        model = this.dynamicTextState.baseEntityMap;
        text = ObjectUtil.getByPath(model, bindingProperty);
        text = formatter.formatValue(text, property?.propertyType || PropertyType.String);
      } else {
        if (!frame) {
          text = MOVE_TO_FRAME_TEXT;
        } else {
          if (newElements?.length > 0) {
            frameMemberComponentElements.push(...newElements);
          }
          if (deletedElementIds?.length > 0) {
            frameMemberComponentElements = frameMemberComponentElements.filter(
              (e) => !deletedElementIds.includes(e.id),
            );
          }
          if (entityType === 'frame') {
            if (propertySlug === 'count') {
              text = frameMemberComponentElements.length.toString();
            } else {
              text = frame.element.elementDefinition.name;
            }
          } else {
            text = DocumentDynamicTextUtil.getTextValueFromFrameMembers(
              frameMemberComponentElements,
              bindingProperty,
              displayFunction,
              property,
              this.dynamicTextState.entityMap,
            );
          }
        }
      }
    }

    if (text === undefined || text === null || text === '') {
      text = 'n/a';
    }
    if (
      displayLabel &&
      ![MOVE_TO_FRAME_TEXT, CLICK_TO_CONFIGURE].includes(text) &&
      displayFunction !== DynamicTextDisplayFunction.LABEL &&
      text !== bindingProperty
    ) {
      text = `${HARD_CODED_TEXT[bindingProperty] || property?.label || ''}: ${text}`;
    }
    return text;
  }

  private getEntityTypeIdsMap(modelBindings: any[]): Map<string, string[]> {
    const ids: Map<string, string[]> = new Map(); // entity type: entity ids[], for ex. { item: [123, 456, 789], projectItem: [111] }
    modelBindings.forEach((binding) => {
      Object.keys(binding).forEach((type) => {
        if (!['assortment', 'viewable'].includes(type)) {
          if (binding[type]) {
            const entityReference = new EntityReference(binding[type]);
            if (
              entityReference &&
              !this.dynamicTextState.entityMap.get(entityReference.entityType + ':' + entityReference.id)
            ) {
              const entityType = entityReference.entityType;
              ids.set(entityType, [...new Set([...(ids.get(entityType) || []), entityReference.id])]); // push id and make unique array
            }
          }
        }
      });
    });
    return ids;
  }

  async updateDynamicTextElements(elements: DocumentElement[], frameForElement?: any) {
    const actions = [];
    for (let element of elements) {
      const el = { ...element };
      const text = await this.getTextValue(element, frameForElement);
      this.adjustSizeAndStyling(el, text);
      actions.push(
        new DocumentAction(
          {
            elementId: element.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: el,
          },
          {
            elementId: element.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: { ...element },
          },
        ),
      );
    }
    this.documentService.handleDocumentActions(actions);
  }

  public adjustSizeAndStyling(element: DocumentElement, text: string) {
    if (element.text?.toString().indexOf('</') > -1) {
      const styleDefinition = DocumentTextElementUtil.extractStyleFromInline(element.text);
      element.text = DocumentTextElementUtil.applyInLineStyling(text, styleDefinition);
    } else {
      element.text = `<p>${text}</p>`;
    }
    const textToolSizeChanges = this.adjustSize({ ...element }, element.text);
    if (textToolSizeChanges?.size) {
      element.size = textToolSizeChanges.size;
    }
  }

  public adjustSize(element: DocumentElement, text: string) {
    const canvasDocument = this.documentService.documentRenderer as CanvasDocument;
    let canvasElement = this.documentService.getCanvasElementById(element.id);
    if (!canvasElement) {
      canvasElement = new CanvasTextElement({ ...element }, canvasDocument);
    }
    canvasElement.elementDefinition.isTextTool = element.isTextTool;
    canvasElement.elementDefinition.size = element.size;
    canvasElement.elementDefinition.propertyBindingsMetaData = element.propertyBindingsMetaData;
    canvasElement.setDefaultValues();
    return canvasElement.autoAdjustSize({ force: true, text });
  }
}
