<div class="font-size">
  <div class="select-editable">
    <mat-select *ngIf="fontFamily" [(value)]="fontFamily" (selectionChange)="setFontFamily($event)">
      <mat-option
        class="tinymce-format-button"
        *ngFor="let fontValue of fontFamilyPickList"
        [value]="fontValue"
        [ngStyle]="{ 'font-family': fontValue }"
        [attr.data-test]="'font-family-selector-' + fontValue"
      >
        {{ fontValue }}
      </mat-option>
    </mat-select>
  </div>
</div>
