import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { ClipboardSelectors } from '../clipboard-store';
import { ClipboardItem } from '../clipboard.interfaces';

@Component({
  selector: 'app-header-clipboard',
  templateUrl: './header-clipboard.component.html',
  styleUrls: ['./header-clipboard.component.scss'],
})
export class HeaderClipboardComponent {
  @Output() showClipboard = new EventEmitter();

  clipboardItems$: Observable<Array<ClipboardItem>>;

  constructor(private store: Store<RootStoreState.State>) {
    this.clipboardItems$ = this.store.select(ClipboardSelectors.clipboardItems);
  }

  openClipboardInSidebar() {
    this.showClipboard.emit();
  }
}
