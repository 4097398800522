import { PositionDefinition } from '@contrail/documents';
import {
  TABLE_DRAG_HANDLE_ARROW_LINE_WIDTH,
  TABLE_DRAG_HANDLE_HEIGHT,
  TABLE_DRAG_HANDLE_WIDTH,
  TABLE_DRAG_HANDLE_ARROW_LENGTH,
  TABLE_DRAG_HANDLE_ARROW_X,
  TABLE_DRAG_HANDLE_ARROW_Y,
  WHITE_COLOR,
  TABLE_DRAG_HANDLE_RADIUS,
  TABLE_DRAG_HANDLE_PADDING,
  BLACK_20,
} from '../../../../constants';
import { Table, TableColumn, TableRow } from '../table/table';
import { TableHoverState } from './table-hover-state';

export class TableHoverRenderer {
  constructor(
    private table: Table,
    private state: TableHoverState,
  ) {}

  public render(ctx, viewScale, params: { x; y; width; height }) {
    if (viewScale.x < 0.3) return;
    if (this.state.column) {
      TableHoverRenderer.renderDragHandle(ctx, viewScale, {
        x: params.x + this.state.column.x + this.state.column.width * 0.5,
        y: params.y,
      });
      if (!this.state.row && this.state.column.width > 55) {
        const scale = TableHoverRenderer.getScale(viewScale);
        const { x1, x2, y, width } = this.getColumnAddHandleDimensions(params, viewScale);
        const lineWidth = TABLE_DRAG_HANDLE_ARROW_LINE_WIDTH * scale;
        this.renderAddHandle(ctx, {
          x: x1,
          y,
          width,
          lineWidth,
        });
        this.renderAddHandle(ctx, {
          x: x2,
          y,
          width,
          lineWidth,
        });
      }
    }

    if (this.state.row) {
      TableHoverRenderer.renderDragHandle(
        ctx,
        viewScale,
        { x: params.x, y: params.y + this.state.row.y + this.state.row.height * 0.5 },
        false,
      );
      if (!this.state.column && this.state.row.height > 55) {
        const scale = TableHoverRenderer.getScale(viewScale);
        const { y1, y2, x, width } = this.getRowAddHandleDimensions(params, viewScale);
        const lineWidth = TABLE_DRAG_HANDLE_ARROW_LINE_WIDTH * scale;
        this.renderAddHandle(ctx, {
          x,
          y: y1,
          width,
          lineWidth,
        });
        this.renderAddHandle(ctx, {
          x,
          y: y2,
          width,
          lineWidth,
        });
      }
    }
  }

  public getAddHandleWidth(viewScale) {
    const scale = TableHoverRenderer.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_HEIGHT * scale;
    return width;
  }

  public static renderDragHandle(ctx, viewScale, position: PositionDefinition, horizontal = true, fillStyle?) {
    const scale = TableHoverRenderer.getScale(viewScale);
    let x, y, width, height;
    if (horizontal) {
      width = TABLE_DRAG_HANDLE_WIDTH * scale;
      height = TABLE_DRAG_HANDLE_HEIGHT * scale;
      x = position.x - width * 0.5;
      y = position.y - TableHoverRenderer.getPadding(viewScale);
    } else {
      width = TABLE_DRAG_HANDLE_HEIGHT * scale;
      height = TABLE_DRAG_HANDLE_WIDTH * scale;
      x = position.x - TableHoverRenderer.getPadding(viewScale);
      y = position.y - height * 0.5;
    }
    this.renderHandle(ctx, {
      x,
      y,
      width,
      height,
      fillStyle,
    });

    const length = TABLE_DRAG_HANDLE_ARROW_LENGTH * scale;
    const arrowX = TABLE_DRAG_HANDLE_ARROW_X * scale;
    const arrowY = TABLE_DRAG_HANDLE_ARROW_Y * scale;
    const lineWidth = TABLE_DRAG_HANDLE_ARROW_LINE_WIDTH * scale;
    this.renderArrow(
      ctx,
      {
        x,
        y,
        width,
        height,
        length: -length,
        arrowX: -arrowX,
        arrowY,
        lineWidth,
      },
      horizontal,
    );
    this.renderArrow(
      ctx,
      {
        x,
        y,
        width,
        height,
        length,
        arrowX,
        arrowY,
        lineWidth,
      },
      horizontal,
    );
  }

  private renderAddHandle(ctx, { x, y, width, lineWidth }) {
    const radius = width * 0.5;
    const lineLength = width * 0.25;
    ctx.beginPath();
    ctx.ellipse(x + radius, y + radius, radius, radius, 0, 0, 2 * Math.PI);
    ctx.fillStyle = BLACK_20;
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.moveTo(x + radius, y + lineLength);
    ctx.lineTo(x + radius, y + width - lineLength);
    ctx.moveTo(x + lineLength, y + radius);
    ctx.lineTo(x + width - lineLength, y + radius);
    ctx.strokeStyle = WHITE_COLOR;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.closePath();
  }

  private static renderHandle(ctx, { x, y, width, height, fillStyle = BLACK_20 }) {
    ctx.beginPath();
    ctx.roundRect(x, y, width, height, TABLE_DRAG_HANDLE_RADIUS);
    ctx.fillStyle = fillStyle;
    ctx.fill();
    ctx.closePath();
  }

  private static renderArrow(ctx, { x, y, width, height, length, arrowX, arrowY, lineWidth }, horizontal = true) {
    ctx.beginPath();
    if (horizontal) {
      ctx.moveTo(x + width * 0.5 + arrowX, y + height * 0.5 - arrowY);
      ctx.lineTo(x + width * 0.5 + length, y + height * 0.5);
      ctx.lineTo(x + width * 0.5 + arrowX, y + height * 0.5 + arrowY);
    } else {
      ctx.moveTo(x + width * 0.5 - arrowY, y + height * 0.5 + arrowX);
      ctx.lineTo(x + width * 0.5, y + height * 0.5 + length);
      ctx.lineTo(x + width * 0.5 + arrowY, y + height * 0.5 + arrowX);
    }
    ctx.strokeStyle = WHITE_COLOR;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.closePath();
  }

  public static getColumnDragHandleDimensions(column: TableColumn, position: PositionDefinition, viewScale) {
    const scale = TableHoverRenderer.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_WIDTH * scale;
    const height = TABLE_DRAG_HANDLE_HEIGHT * scale;
    const x = position.x + column.x + (column.width * 0.5 - width * 0.5);
    const y = position.y - TableHoverRenderer.getPadding(viewScale);
    return { x, y, width, height };
  }

  public static getRowDragHandleDimensions(row: TableRow, position: PositionDefinition, viewScale) {
    const scale = TableHoverRenderer.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_HEIGHT * scale;
    const height = TABLE_DRAG_HANDLE_WIDTH * scale;
    const x = position.x - TableHoverRenderer.getPadding(viewScale);
    const y = position.y + row.y + (row.height * 0.5 - height * 0.5);
    return { x, y, width, height };
  }

  public getColumnAddHandleDimensions(position: PositionDefinition, viewScale) {
    const scale = TableHoverRenderer.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_HEIGHT * scale;
    const x1 = position.x + this.state.column.x - width * 0.5;
    const x2 = position.x + this.state.column.x + this.state.column.width - width * 0.5;
    const y = position.y - TableHoverRenderer.getPadding(viewScale);
    return { x1, x2, y, width };
  }

  public getRowAddHandleDimensions(position: PositionDefinition, viewScale) {
    const scale = TableHoverRenderer.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_HEIGHT * scale;
    const x = position.x - TableHoverRenderer.getPadding(viewScale);
    const y1 = position.y + this.state.row.y - width * 0.5;
    const y2 = position.y + this.state.row.y + this.state.row.height - width * 0.5;
    return { y1, y2, x, width };
  }

  public static getScale(viewScale) {
    return Math.min(1 / viewScale.x, 1.4);
  }

  public static getPadding(viewScale) {
    return Math.min(50, Math.max(30, Math.round(TABLE_DRAG_HANDLE_PADDING / viewScale.x)));
  }

  public static getAddHandleWidth(viewScale) {
    const scale = this.getScale(viewScale);
    const width = TABLE_DRAG_HANDLE_HEIGHT * scale;
    return width;
  }
}
