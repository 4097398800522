import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { SharedLinkDefinition } from './interfaces';

@Component({
  selector: 'app-share-app-modal',
  template: `<app-modal [title]="title" [dialogRef]="dialogRef" data-test="share-modal">
    <app-share-app [sharedLinkDefinition]="sharedLinkDefinition" (close)="closeModal()"></app-share-app>
  </app-modal>`,
  styleUrls: [],
})
export class ShareAppModalComponent {
  public title;
  public sharedLinkDefinition: SharedLinkDefinition;

  constructor(
    public dialogRef: MatDialogRef<ShareAppModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SharedLinkDefinition,
  ) {
    this.title = this.toTitleCase(`share ${data.contextType}`);
    this.sharedLinkDefinition = data;
  }

  closeModal() {
    this.dialogRef.close();
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
