import { CanvasDocument } from '../../../canvas-document';
import { Editor } from '../../../components/editor/editor';
import { EditorCalc } from '../../../components/editor/editor-calc';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasStickyNoteElement } from '../canvas-sticky-note-element';
import { StickyNoteEditorManager } from './sticky-note-editor-manager';

export const stickyEditorId = 'sticky-note-editor';
export const stickyPlaceholderId = 'sticky-note-placeholder';
export const STICKY_FONT_FAMILY = 'Roboto';
export const STICKY_FONT_SIZE = 64;
export const STICKY_MIN_FONT_SIZE = 4;
export const STICKY_MAX_FONT_SIZE = 64;
export const STICKY_FONT_SIZES = [4, 8, 10, 12, 14, 18, 24, 32, 48, 64];
export const STICKY_VALIGN = 'middle';
export const STICKY_ALIGN = 'center';
export const STICKY_COLOR = '#000000cc';
export const STICKY_PADDING = 15;
export const STICKY_WIDTH = 200;
export const STICKY_HEIGHT = 200;
export const STICKY_BG_COLOR = '#FFFAB2';
export const STICKY_BORDER_SIZE = 0;
export const STICKY_WORD_WRAP = 'normal';

export class StickyNoteEditor extends Editor {
  constructor(canvasDocument: CanvasDocument, manager: EditorManager) {
    super(canvasDocument, stickyEditorId, manager);
  }

  public setContentStyle() {
    this.manager.editorContainer.editor.style.fontFamily =
      this.manager.elementDefinition?.style?.font?.family || STICKY_FONT_FAMILY;
    this.manager.editorContainer.editor.style.fontSize =
      (this.manager.elementDefinition?.style?.font?.size || STICKY_FONT_SIZE) + 'px';

    const valign = this.manager.elementDefinition?.style?.text?.valign ?? STICKY_VALIGN;
    const transform = EditorCalc.getVerticalTransform(
      valign,
      this.manager.editorContainer.currentContainerHeight,
      STICKY_PADDING,
      STICKY_BORDER_SIZE,
      this.manager.editorContainer.editor.clientHeight,
    );
    if (transform) {
      this.manager.editorContainer.editor.style.transform = transform;
    }
    this.manager.editorContainer.editor.style.textAlign =
      this.manager.elementDefinition?.style?.text?.align || STICKY_ALIGN;
  }

  public getCurrentStyle(element: CanvasStickyNoteElement): void {
    element.maxFontSize = (this.manager as StickyNoteEditorManager).getMaxFontSize(element);
    this.emitTextEditorEvent(element);
  }

  public emitTextEditorEvent(element?: CanvasStickyNoteElement) {
    let elementDefinition = element?.elementDefinition;
    if (!elementDefinition) {
      elementDefinition = this.manager?.elementDefinition;
    }
    const editor = this.getEditor();
    let listType = '';
    if (editor?.queryCommandState('InsertOrderedList')) {
      listType = 'decimal';
    } else if (editor?.queryCommandState('InsertUnorderedList')) {
      listType = 'circle';
    }
    const textColor = editor ? this.getTextOrBgColor(editor.selection.getNode(), 'color') : null;
    this.canvasDocument.actionsDispatcher.handleDocumentTextElementEvent({
      element: this.isFocused ? elementDefinition : null,
      textFormat: {
        bold: editor ? (editor.queryCommandState('Bold') ? 'bold' : 'normal') : null,
        italic: editor ? (editor.queryCommandState('Italic') ? 'italic' : 'normal') : null,
        underline: editor ? (editor.queryCommandState('Underline') ? 'underline' : 'normal') : null,
        strikethrough: editor ? (editor.queryCommandState('Strikethrough') ? 'strikethrough' : 'normal') : null,
        textColor,
        listType,
        fontFamily: elementDefinition?.style?.font?.family,
        textAlign: elementDefinition?.style?.text?.align,
        textValign: elementDefinition?.style?.text?.valign,
        fontSize: elementDefinition?.style?.font?.size,
        fontSizeMode: elementDefinition?.style?.font?.sizeMode,
        maxFontSize: element?.maxFontSize || (this.manager?.element as CanvasStickyNoteElement)?.maxFontSize,
      },
    });
  }
}
