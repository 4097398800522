import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { ListItemCellComponent } from './list-item-cell/list-item-cell.component';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [ListComponent, ListItemCellComponent],
  imports: [CommonModule, PipesModule, DirectivesModule],
  exports: [ListComponent],
})
export class ListModule {}
