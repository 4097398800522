<div class="comments-info">
  <div class="header flex-between-center">
    <div class="flex justify-start">
      <div class="title">Comments</div>
    </div>
    <div class="flex space-x-2">
      <app-pinned-comments-toggle></app-pinned-comments-toggle>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="m-2">
    <app-search-bar [placeholder]="'Search comments'"></app-search-bar>
  </div>
  <div class="m-2 flex items-center justify-between">
    <div class="flex items-center">
      <div *ngIf="filterDefinition">
        <app-menu
          name="Filter"
          tooltipDisabled="true"
          icon="filter_list"
          position="left"
          [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
        >
          <app-comments-filter-panel
            [sourceValues]="comments$ | async"
            [filterDefinition]="filterDefinition"
            (filterCriteriaChange)="setFilterCriteria($event)"
          >
          </app-comments-filter-panel>
        </app-menu>
      </div>
      <div *ngIf="sortProperties">
        <app-menu name="Sort" tooltipDisabled="true" icon="sort" position="left" [highlight]="currentSorts?.length">
          <app-sort-panel [currentSorts]="currentSorts" [properties]="sortProperties" (performSort)="setSort($event)">
          </app-sort-panel>
        </app-menu>
      </div>
    </div>

    <div class="flex items-center space-x-2">
      <div *ngIf="selectedContextReferenceLabel && (contextReferenceTypesObservable | async)?.length > 1">
        <mat-form-field class="w-[5.25rem] plain-select">
          <mat-select
            [(ngModel)]="selectedContextReferenceLabel"
            (selectionChange)="setContextReference(selectedContextReferenceLabel)"
            disableRipple
          >
            <mat-option *ngFor="let type of contextReferenceTypesObservable | async" [value]="type.label">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="text-white bg-gray-700 rounded-[10px] font-bold w-[35px] h-[20px] flex justify-center items-center text-xs"
      >
        {{ (comments$ | async)?.length }}
      </div>
    </div>
  </div>
  <div class="comments-list">
    <app-list-comments
      [accessLevel]="accessLevel"
      [comments]="comments$ | async"
      [showOwnerName]="true"
      [ownerInfo]="ownerInfo"
    ></app-list-comments>
  </div>
</div>
