export const selectContextComments = (state: any) => state.comments.contextComments;
export const selectCommmentsContextReference = (state: any) => state.comments.contextReference;
export const showCommentsSideBar = (state: any) => state.comments.showCommentsSideBar;
export const showCommentOverlay = (state: any) => state.comments.showCommentOverlay;
export const commentOverlayPosition = (state: any) => state.comments.commentOverlayPosition;
export const commentProperty = (state: any) => state.comments.commentProperty;
export const commentOwnerInfo = (state: any) => state.comments.commentOwnerInfo;
export const acceptedComment = (state: any) => state.comments.currentComment;
export const commentsAccessLevel = (state: any) => state.comments.commentsAccessLevel;
export const selectedComment = (state: any) => state.comments.selectedComment;
export const showPinnedComments = (state: any) => state.comments.showPinnedComments;
