import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasDiamondElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }): number[][] {
    const top = [x + width * 0.5, y];
    const right = [x + width, y + height * 0.5];
    const bottom = [x + width * 0.5, y + height];
    const left = [x, y + height * 0.5];
    return [top, right, bottom, left];
  }
}
