import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { workspacesReducers } from './workspaces.reducers';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacesEffects } from './workspaces.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('workspaces', workspacesReducers),
    EffectsModule.forFeature([WorkspacesEffects]),
  ],
})
export class WorkspacesStoreModule {}
