<button
  data-test="history-button"
  [matMenuTriggerFor]="historyMenu"
  class="menu-button mat-button mat-button-base"
  mat-button
>
  <mat-icon>history</mat-icon>
</button>

<mat-menu #historyMenu="matMenu" yPosition="below" xPosition="before">
  <button data-test="version-history" mat-menu-item (click)="showVersionHistory($event)">Version History</button>
  <button data-test="source-history" mat-menu-item (click)="showSourceHistory($event)">Source Change History</button>
</mat-menu>
