import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';
import { TextMetrics } from '../text/text-metrics';
import { ACCENT_COLOR } from '../../constants';
import { FrameInputElement } from './frame-input-element';
import { DrawOptions, DrawParams } from '../../renderers/canvas-renderer';

export const FRAME_TEXTAREA_PREFIX = 'frame-textarea';
export const FRAME_NAME_PREFIX = 'frame-name';

export class CanvasFrameElement extends CanvasElement {
  public readonly FONT_SIZE = 14;
  private readonly FONT_FAMILY = 'Roboto';
  private readonly FONT_COLOR = '#00000099';
  private readonly INNER_MARGIN = 5;
  private readonly SHADOW_COLOR = 'rgba(0,0,0,0.25)';
  private readonly SHADOW_BLUR = 8;
  private readonly SHADOW_OFFSET_X = 0;
  private readonly SHADOW_OFFSET_Y = 4;

  public textWidth: number;
  private fontString: string;
  private truncatedFrameName: {
    initialText: string;
    text: string;
    width: number;
    textWidth: number;
    textHeight: number;
  };

  public highlightBox = false;
  public documentGenerationHighlight = false;
  public frameInputElement: FrameInputElement;
  public frameNameVisible = true;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);

    this.DEFAULT_BACKGROUND_COLOR = '#fff';
    const defaultStyle = {
      backgroundColor: this.DEFAULT_BACKGROUND_COLOR,
      border: {
        width: this.DEFAULT_BORDER_SIZE,
        color: this.DEFAULT_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
    this.frameInputElement = new FrameInputElement(this, canvasDocument);
  }

  public draw(ctx, opts: DrawParams, options?: DrawOptions): { height: number; y: number } {
    if (this.frameNameVisible) {
      this.drawFrameName(ctx, opts, options);
    }
    this.drawContainer(ctx, opts);
    if (this.frameInputElement.inputElement.isEditing) {
      this.frameInputElement.setPosition();
    }
    return;
  }

  private drawFrameName(ctx, { x, y, width, height }, options?: DrawOptions) {
    const viewScale = this.canvasDocument.getViewScale();
    const fontSize = this.getScaledValue(this.FONT_SIZE, viewScale);
    const fontString = `${fontSize}px ${this.FONT_FAMILY}`;
    ctx.font = fontString;
    ctx.fillStyle = this.highlightBox ? ACCENT_COLOR : this.FONT_COLOR;
    if (
      !options?.skipRecalculate &&
      (!this.truncatedFrameName ||
        this.elementDefinition.name !== this.truncatedFrameName?.initialText ||
        width !== this.truncatedFrameName?.width ||
        fontString !== this.fontString)
    ) {
      this.truncatedFrameName = TextMetrics.truncate(ctx, this.elementDefinition.name?.toString(), width, fontString);
    }
    this.fontString = fontString;

    const scale = 1 / Math.min(viewScale?.x, viewScale?.y);
    const text = this.isSelected || this.highlightBox || scale < 3 ? this.truncatedFrameName.text : '';
    ctx.fillText(text, -width * 0.5, -height * 0.5 - this.getScaledValue(this.INNER_MARGIN, viewScale));
  }

  private drawContainer(ctx, { x, y, width, height }) {
    const scale = this.canvasDocument.getDevicePixelRatio();
    ctx.save();
    ctx.beginPath();

    ctx.rect(-width * 0.5, -height * 0.5, width, height);

    ctx.fillStyle = this.elementDefinition.style?.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
    ctx.shadowColor = this.SHADOW_COLOR;
    ctx.shadowBlur = this.SHADOW_BLUR * scale;
    ctx.shadowOffsetX = this.SHADOW_OFFSET_X * scale;
    ctx.shadowOffsetY = this.SHADOW_OFFSET_Y * scale;
    ctx.fill();
    ctx.closePath();
    ctx.restore();

    if (this.highlightBox || this.documentGenerationHighlight) {
      ctx.beginPath();

      const lineWidth = this.canvasDocument.getStrokeWidth(1);
      ctx.rect(-width * 0.5 - lineWidth * 0.5, -height * 0.5 - lineWidth * 0.5, width + lineWidth, height + lineWidth);

      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = ACCENT_COLOR;
      ctx.stroke();
      ctx.closePath();
    }
  }

  public isPointOnFrame(px: number, py: number): boolean | string {
    let hit: any = false;
    if (this.truncatedFrameName) {
      const height = this.truncatedFrameName.textHeight;
      const width = this.truncatedFrameName.textWidth;
      const x = this.elementDefinition.position.x;
      const y = this.elementDefinition.position.y - height;
      const isMouseOverFrameName = py <= y + height && py >= y && px >= x && px <= x + width;
      hit = isMouseOverFrameName ? 'frameName' : false;
    }

    if (!hit) {
      const x = this.elementDefinition.position.x;
      const y = this.elementDefinition.position.y;
      const width = this.elementDefinition.size.width;
      const height = this.elementDefinition.size.height;
      const lineWidth = this.canvasDocument.getStrokeWidth(1) * 2;
      const handleWidth = this.isSelected ? this.selectionWidgetRenderer?.getHandleWidth() || 6 : 0;
      const isMouseOverFrameEdge =
        (px >= x - lineWidth && px <= x + lineWidth && py >= y + handleWidth && py <= y + height - handleWidth) ||
        (px >= x + width - lineWidth &&
          px <= x + width + lineWidth &&
          py >= y + handleWidth &&
          py <= y + height - handleWidth) ||
        (px >= x + handleWidth && px <= x + width - handleWidth && py >= y - lineWidth && py <= y + lineWidth) ||
        (px >= x + handleWidth &&
          px <= x + width - handleWidth &&
          py >= y + height - lineWidth &&
          py <= y + height + lineWidth);
      hit = isMouseOverFrameEdge ? 'frameEdge' : false;
    }

    if (hit && !this.highlightBox) {
      this.highlightBox = true;

      const frames = this.canvasDocument.state.canvasElements?.filter((e) => e.elementDefinition.type === 'frame');
      frames.map((e) => {
        // Remove highlightbox from other frames
        const frame = e as CanvasFrameElement;
        if (frame.elementDefinition.id !== this.elementDefinition.id && frame.highlightBox) {
          frame.highlightBox = false;
        }
      });

      this.canvasDocument.draw();
    } else if (!hit && this.highlightBox) {
      this.highlightBox = false;
      this.canvasDocument.draw();
    }

    return hit;
  }

  public addPath(ctx, { x, y, width, height }) {
    ctx.rect(x, y, width, height);
  }

  public clip(ctx, { x, y, width, height }) {
    ctx.save();
    this.transform(ctx, { x, y, width, height });
    ctx.beginPath();
    this.addPath(ctx, { x: -width * 0.5, y: -height * 0.5, width, height });
    ctx.closePath();
    ctx.restore();
    ctx.clip();
  }
}
