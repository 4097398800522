export const ACCENT_COLOR = '#00B0FF';
export const ACCENT_COLOR_25 = '#00aeff40';
export const EDITING_COLOR = '#FFA000';
export const HIGHLIGHT_COLOR = '#dbe9f9';
export const GREY_COLOR = '#9E9E9E';
export const WHITE_COLOR = '#ffffff';
export const TRANSPARENT_COLOR = 'rgba(0,0,0,0)';
export const BLACK_38 = 'rgba(0, 0, 0, 0.38)';
export const BLACK_24 = 'rgba(0, 0, 0, 0.24)';
export const BLACK_20 = 'rgba(0, 0, 0, 0.2)';
export const BLACK_05 = 'rgba(0, 0, 0, .05)';
export const BLACK_04 = 'rgba(0, 0, 0, 0.04)';
export const GREY_CHIP_LIGHT = '#EBEBEB';
export const SHAPE_ELEMENT_TYPES = [
  'text',
  'line',
  'arrow',
  'circle',
  'rectangle',
  'round_rectangle',
  'triangle',
  'diamond',
  'star',
  'heart',
  'double_arrow',
  'right_arrow',
  'rhombus',
  'cloud',
];
export const CANVAS_COMPONENT_PADDING_X = 8;
export const CANVAS_COMPONENT_PADDING_T = 18;
export const CANVAS_COMPONENT_PADDING_B = 8;

export const DEFAULT_PEN_BORDER_SIZE = 2;
export const DEFAULT_PEN_BORDER_COLOR = '#000000';
export const DEFAULT_HIGHLIGHTER_BORDER_SIZE = 24;
export const DEFAULT_HIGHLIGHTER_BORDER_COLOR = '#FFFC38';
export const DEFAULT_HIGHLIGHTER_OPACITY = 0.7;
export const ANNOTATION_IMG_SIZE = 15;
export const ANNOTATION_PADDING_Y = 8;

export const ROW_MIN_HEIGHT = 28;
export const COLUMN_MIN_WIDTH = 28;
export const TABLE_DRAG_HANDLE_WIDTH = 40;
export const TABLE_DRAG_HANDLE_HEIGHT = 20;
export const TABLE_DRAG_HANDLE_PADDING = 40;
export const TABLE_DRAG_HANDLE_RADIUS = 20;
export const TABLE_DRAG_HANDLE_ARROW_X = 3;
export const TABLE_DRAG_HANDLE_ARROW_Y = 4;
export const TABLE_DRAG_HANDLE_ARROW_LENGTH = 7;
export const TABLE_DRAG_HANDLE_ARROW_LINE_WIDTH = 2;

export const TABLE_CELL_WIDTH = 200;
export const TABLE_CELL_HEIGHT = 120;

export const STICKY_NOTE_BACKGROUND_COLORS = [
  { value: '#FFFAB2', display: 'Yellow' },
  { value: '#C4ECFF', display: 'Blue' },
  { value: '#FFD4E0', display: 'Pink' },
  { value: '#C2F4F0', display: 'Turq' },
  { value: '#FFE0CD', display: 'Orange' },
  { value: '#CEF5D1', display: 'Green' },
  { value: '#FBD1FC', display: 'Purple' },
  { value: '#E0DAFD', display: 'Red' },
  { value: '#E5E9F0', display: 'Gray' },
];
