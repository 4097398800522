import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ItemData } from '@common/item-data/item-data';
import { AggregateHelper } from '@contrail/aggregates';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-assortment-summary-chart-dashboard-widget',
  templateUrl: './assortment-summary-chart-dashboard-widget.component.html',
  styleUrls: ['./assortment-summary-chart-dashboard-widget.component.scss'],
})
export class AssortmentSummaryChartDashboardWidgetComponent implements OnInit {
  @Input() assortmentItemData: Array<ItemData>;
  @Input() chartType = 'BarChart';
  @Input() groupingPropertySlug;
  constructor() {}

  public chartOptions = {
    chart: {},
    colors: ['#9bcffa', '#2085ec', '#72b4eb', '#0a417a', '#8464a0', '#cea9bc'],
    pieHole: 0.4,
    legend: 'none',
    backgroundColor: { fill: 'none' },
    chartArea: { width: '80%' },
    hAxis: {
      minValue: 0,
    },
    vAxis: {
      minValue: 0,
      textPosition: 'in',
    },
  };

  public chartData: Array<any>;
  public groupingProperty: TypeProperty;
  public groupingProperties: Array<TypeProperty>;
  public propertiesFormControl = new UntypedFormControl({ value: null, disabled: false });
  public width = 330;
  public height = 425;

  async ngOnInit() {
    const itemType = await new Types().getType({ path: 'item' });
    this.groupingProperties = itemType.typeProperties
      .filter((p) => p.options || p.propertyType === PropertyType.String)
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
    if (!this.groupingPropertySlug) {
      this.groupingPropertySlug = this.groupingProperties[0].slug;
    }
    this.groupingProperty = itemType.typeProperties.find((p) => p.slug === this.groupingPropertySlug);
    this.propertiesFormControl.setValue(this.groupingProperty);
    this.setChartData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartType !== 'BarChart') {
      this.width = 330;
      this.height = 330;
      this.chartOptions.chartArea = { width: '90%' };
    }
    if (changes.assortmentItemData) {
      this.setChartData();
    }
  }

  public handleGroupingPropertyChange(property) {
    if (!property) {
      return;
    }
    this.groupingProperty = property;
    this.setChartData();
  }

  async setChartData() {
    // console.log("this.aggregateGroupProperty: ", this.groupingProperty);
    // console.log("itemData: ", this.assortmentItemData)

    if (this.groupingProperty) {
      const sourceData = this.assortmentItemData
        .filter((ai) => ai.assortmentItem.isDropped !== true)
        .map((ai) => ai.item);
      const groupingValues = this.groupingProperty.options
        ? this.groupingProperty.options.map((opt) => opt.value)
        : sourceData
            .filter(
              (d) =>
                d[this.groupingProperty.slug] !== null &&
                d[this.groupingProperty.slug] !== '' &&
                d[this.groupingProperty.slug] !== undefined,
            )
            .map((d) => d[this.groupingProperty.slug]);
      const totals = AggregateHelper.getValueCounts(sourceData, this.groupingProperty.slug, groupingValues);
      // console.log('totals: ', totals);
      // totals = Object.entries(totals).filter(([key, value]) => value > 0 );
      const data = [];
      Object.entries(totals).map(([key, value]: [string, number]) => {
        if (value > 0) {
          if (this.groupingProperty.options) {
            const option = this.groupingProperty.options.find((opt) => opt.value === key);
            data.push([option.display, value]);
          } else {
            data.push([key, value]);
          }
        }
      });
      data.sort((v1, v2) => (v1[1] > v2[1] ? 1 : -1)); // sort by value
      this.chartData = data;
      // console.log("chartData: ", this.chartData)
    }
  }
}
