<div
  class="floating-menu"
  *ngIf="toggleOverlay$ | async as toggleOverlay"
  [ngClass]="{ 'display-overlay': toggleOverlay?.showChooser, 'hide-overlay': !toggleOverlay }"
  [ngStyle]="toggleOverlay?.showChooser ? { display: 'block' } : { display: 'none' }"
  [@openClose]="toggleOverlay ? 'open' : 'closed'"
>
  <app-board-frames-list
    class="h-full w-full"
    *ngIf="toggleOverlay?.showChooser && 'frames' === toggleOverlay?.slug"
    (panelClosed)="handleClose()"
  ></app-board-frames-list>

  <app-status-messages-list
    class="h-full w-full"
    *ngIf="toggleOverlay?.showChooser && 'messages' === toggleOverlay?.slug"
    [messages]="messages$ | async"
    [showWarning]="showSourceAssortmentWarning$ | async"
    (onClose)="handleClose()"
    (onMessageClick)="handleMessageClick($event)"
    (onToggleShowWarning)="handleToggleShowWarning($event)"
  ></app-status-messages-list>

  <app-board-dashboard-panel
    *ngIf="dashboardFeatureFlag"
    [style.display]="toggleOverlay?.showChooser && 'dashboard' === toggleOverlay?.slug ? 'block' : 'none'"
    (panelClosed)="handleClose()"
  ></app-board-dashboard-panel>

  <app-document-history
    class="h-full"
    *ngIf="['sourceChangeHistory', 'versionHistory'].includes(toggleOverlay?.slug)"
    [document]="board$ | async"
    [documentReference]="'board:' + (board$ | async)?.id"
    [historyType]="toggleOverlay?.slug"
    (panelClosed)="handleClose()"
  ></app-document-history>

  <app-entity-content-chooser
    *ngIf="['addContextContent'].includes(toggleOverlay?.slug)"
    [contentHolder]="(board$ | async)?.primaryContext"
    (panelClosed)="handleClose()"
  ></app-entity-content-chooser>

  <app-entity-property-chooser
    *ngIf="['addContextProperties'].includes(toggleOverlay?.slug)"
    [entities]="[(board$ | async)?.primaryContext]"
    (panelClosed)="handleClose()"
  ></app-entity-property-chooser>

  <app-item-chooser
    class="w-full h-full"
    *ngIf="itemSourceChooserLoaded"
    [editorMode]="editorMode$ | async"
    [style.display]="
      toggleOverlay?.showChooser && 'addItem' === toggleOverlay?.slug && !toggleOverlay?.targetSourceType
        ? 'block'
        : 'none'
    "
    [style.height]="
      toggleOverlay?.showChooser && 'addItem' === toggleOverlay?.slug && !toggleOverlay?.targetSourceType
        ? '100%'
        : '0px'
    "
  ></app-item-chooser>

  <app-item-chooser
    class="w-full h-full"
    *ngIf="itemChooserLoaded"
    [editorMode]="editorMode$ | async"
    [style.display]="
      toggleOverlay?.showChooser && 'addItem' === toggleOverlay?.slug && toggleOverlay?.targetSourceType
        ? 'block'
        : 'none'
    "
    [style.height]="
      toggleOverlay?.showChooser && 'addItem' === toggleOverlay?.slug && toggleOverlay?.targetSourceType
        ? '100%'
        : '0px'
    "
    [hideSource]="toggleOverlay?.hideSource"
    [title]="toggleOverlay?.title"
    [targetSourceType]="toggleOverlay?.targetSourceType"
  ></app-item-chooser>

  <app-chooser
    *ngIf="colorChooserLoaded"
    class="w-full h-full"
    [style.display]="toggleOverlay?.showChooser && 'addColor' === toggleOverlay?.slug ? 'block' : 'none'"
    [style.height]="toggleOverlay?.showChooser && 'addColor' === toggleOverlay?.slug ? '100%' : '0px'"
    [entityType]="'color'"
    [title]="'Color Chooser'"
    [allowSourceChange]="true"
    [allowAddMultipleEntities]="true"
    [allowAddEntity]="true"
    [draggable]="true"
    [enableInfiniteScroll]="true"
    (close)="handleClose()"
    (addEntities)="this.handleAddEntities($event)"
  ></app-chooser>

  <app-chooser
    class="w-full h-full"
    *ngIf="assetChooserLoaded"
    [style.display]="toggleOverlay?.showChooser && 'addAsset' === toggleOverlay?.slug ? 'block' : 'none'"
    [style.height]="toggleOverlay?.showChooser && 'addAsset' === toggleOverlay?.slug ? '100%' : '0px'"
    [entityType]="'asset'"
    [title]="'Asset Chooser'"
    [allowSourceChange]="true"
    [draggable]="true"
    [enableInfiniteScroll]="true"
    (close)="handleClose()"
  ></app-chooser>

  <app-component-editor
    class="w-full h-full"
    *ngIf="toggleOverlay?.showChooser && 'componentEditor' === toggleOverlay?.slug"
  ></app-component-editor>

  <app-document-content-editor
    class="w-full h-full"
    *ngIf="!svgRecoloringFeatureFlag && toggleOverlay?.showChooser && 'contentEditor' === toggleOverlay?.slug"
  ></app-document-content-editor>

  <app-document-content-svg-editor
    class="w-full h-full"
    *ngIf="svgRecoloringFeatureFlag && toggleOverlay?.showChooser && 'contentEditor' === toggleOverlay?.slug"
  ></app-document-content-svg-editor>

  <div class="w-full h-full" *ngIf="toggleOverlay?.showChooser && 'showComments' === toggleOverlay?.slug">
    <app-board-comments-list class="w-full h-full" (panelClosed)="handleClose()"></app-board-comments-list>
  </div>

  <app-document-generator-config-editor
    *ngIf="'lineboardEditor' === toggleOverlay?.slug"
    (panelClosed)="handleClose()"
    [selectedElement]="selectedElement"
  ></app-document-generator-config-editor>
</div>
