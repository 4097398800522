export const ACCENT_COLOR = '#00B0FF';
export const EDITING_COLOR = '#FFA000';
export const HIGHLIGHT_COLOR = '#dbe9f9';
export const GREY_COLOR = '#9E9E9E';
export const TRANSPARENT_COLOR = 'rgba(0,0,0,0)';
export const BLACK_38 = 'rgba(0, 0, 0, 0.38)';
export const BLACK_24 = 'rgba(0, 0, 0, 0.24)';
export const GREY_CHIP_LIGHT = '#EBEBEB';
export const SHAPE_ELEMENT_TYPES = [
  'text',
  'line',
  'arrow',
  'circle',
  'rectangle',
  'round_rectangle',
  'triangle',
  'diamond',
  'star',
  'heart',
  'double_arrow',
  'right_arrow',
  'rhombus',
  'cloud',
];
export const CANVAS_COMPONENT_PADDING_X = 8;
export const CANVAS_COMPONENT_PADDING_T = 18;
export const CANVAS_COMPONENT_PADDING_B = 8;

export const DEFAULT_PEN_BORDER_SIZE = 2;
export const DEFAULT_PEN_BORDER_COLOR = '#000000';
export const DEFAULT_HIGHLIGHTER_BORDER_SIZE = 24;
export const DEFAULT_HIGHLIGHTER_BORDER_COLOR = '#FFFC38';
export const DEFAULT_HIGHLIGHTER_OPACITY = 0.7;
export const ANNOTATION_IMG_SIZE = 15;
export const ANNOTATION_PADDING_Y = 8;
