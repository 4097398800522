import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { CompletedJob, DownloadService } from '../download.service';

@Component({
  selector: 'app-download-content-snack',
  template: `
    <div class="flex-center bg-white h-12 w-32 rounded shadow-md shadow-gray-700" *ngIf="downloading$ | async">
      <mat-spinner [diameter]="24"></mat-spinner>
      <span class="text-black80 ml-2">{{ label }}</span>
    </div>
  `,
  styles: [],
})
export class DownloadContentSnackComponent implements OnInit, OnDestroy {
  public downloading$ = this.downloadService.downloading$;

  private snackBarRef;
  private destroy$ = new Subject();

  @Input() label = 'Exporting ...';
  @Input() errorMessage = 'Error while exporting data. Please try again.';
  @Input() useOnDownloadSuccess = false;
  @Output() onDownloadSuccess = new EventEmitter();

  constructor(
    private downloadService: DownloadService,
    private snackBar: MatSnackBar,
  ) {
    this.downloadService.resetJobQueue();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.downloadService.pendingJobsObservable.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data) {
        return;
      }
      console.log(`Download service has received ${JSON.stringify(data)}  - ${data?.length} pending job ids`);
    });

    this.downloadService.completedJobsObservable$.pipe(takeUntil(this.destroy$)).subscribe((data: CompletedJob) => {
      if (!data) {
        return;
      } else {
        if (this.useOnDownloadSuccess) {
          this.onDownloadSuccess.emit(data);
          return;
        }
        const path = data.path;
        if (path instanceof Blob) {
          const url = URL.createObjectURL(path);
          this.download(url, data.name);
          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 1000);
        } else {
          this.openInNewTab(path);
        }
      }
      console.log(`Download service has received completed job ${JSON.stringify(data)}`);
      this.snackBarRef?.dismiss();
    });

    this.downloadService.errorObservable$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.openSnackBar(this.errorMessage, 'Error');
      }
    });
  }

  private download(data, name) {
    const link = document.createElement('a');
    link.download = `${name ?? 'export'}.pdf`;
    link.href = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private openInNewTab(data) {
    window.open(data, '_blank');
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBarRef = this.snackBar.open(message, action, {
      duration,
    });
  }
}
