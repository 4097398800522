import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private authService: AuthService,
  ) {}
}
