<div class="wrapper relative">
  <div class="wrap w-[550px] h-[550px] border flex-center">
    <app-content-preview
      *ngIf="(content$ | async)?.length"
      [size]="'548px'"
      [content]="selectedContent$ | async"
    ></app-content-preview>
    <h6 *ngIf="!(content$ | async)?.length && !(loading$ | async)">No content has been assigned to this item.</h6>
  </div>
  <div class="flex items-center flex-col my-5">
    <app-content-tray
      *ngIf="(content$ | async)?.length"
      class="max-w-[520px] overflow-x-auto square-scroll"
      (select)="selectContent($event)"
      [contentList]="content$ | async"
      [contextMenu]="contextMenu"
      [primaryViewableId]="contentHolder.primaryViewableId"
    >
    </app-content-tray>
    <div class="message py-2" *ngIf="editable">
      Drag files here or
      <span class="underline cursor-pointer" (click)="fileUpload.click()">select.</span>
    </div>
  </div>

  <app-context-menu [actionDefinitions]="contextMenuActions" (selected)="handleMenuAction($event)"></app-context-menu>

  <div
    class="uploading bg-white rounded flex-center flex-col absolute w-full h-full right-0 top-0 bottom-0 left-0 m-auto z-[501]"
    *ngIf="uploading$ | async"
  >
    <mat-spinner diameter="40"> </mat-spinner>
    <h6>Files Uploading...</h6>
  </div>
  <input
    #fileUpload
    type="file"
    style="display: none"
    (change)="handleFileSelection($event)"
    multiple
    accept="image/*,application/postscript,application/pdf"
  />

  <div
    class="loading bg-white rounded flex-center flex-col absolute w-full h-full right-0 top-0 bottom-0 left-0 m-auto"
    *ngIf="loading$ | async"
  >
    <mat-spinner diameter="40"> </mat-spinner>
    <h6>Loading</h6>
  </div>
</div>
