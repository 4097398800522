import { Injectable } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Request, Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { DocumentExportService } from 'src/app/boards/board/document/document-export/document-export.service';
import { RootStoreState } from 'src/app/root-store';
import { v4 as uuid } from 'uuid';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthSelectors } from '../auth/auth-store';
import { DownloadService } from './download/download.service';

export enum ExportReportType {
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
}

export enum ExportableEntityType {
  CUSTOM_ENTITY = 'custom-entity',
}

export interface ExportEntitiesOptions {
  entityType: ExportableEntityType;
  typePath?: string;
  criteria?: {
    [key: string]: any;
  };
}

export interface ExportRequestEntity {
  ownerId: string;
  ownerName: string;
  entityName: string;
  criteria: any;
  relations: Array<string>;
  viewId?: string;
  viewName?: string;
  reportType: string;
  fileName?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ExportsService {
  authContext: any = {};

  private readonly REPORT_TYPES = {
    pdf_snapshots: {
      eventName: 'PDF_SNAPSHOT_EXPORT',
    },
    pdf: {
      eventName: 'PDF_EXPORT',
      requestUrl: '/exports',
    },
    excel: {
      eventName: 'EXCEL_EXPORT',
      requestUrl: '/excel-exports',
    },
    powerpoint: {
      eventName: 'POWERPOINT_EXPORT',
      requestUrl: '/exports/pptx',
    },
  };

  constructor(
    private analyticsService: AnalyticsService,
    private documentExportService: DocumentExportService,
    private downloadService: DownloadService,
    store: Store<RootStoreState.State>,
  ) {
    store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => (this.authContext = authContext));
  }

  /**
   *
   * @param options
   * @param payload What goes in the object of the create request
   */
  async initDownloadEntityReference(options, payload) {
    let eventName = this.REPORT_TYPES[options.reportType]?.eventName;
    this.analyticsService.emitEvent({
      eventName: eventName,
      eventCategory: EVENT_CATEGORY.EXPORT,
      eventLabel: options.ownerName,
      eventTarget: payload.entityReference,
    });

    const id = options.ownerId || payload.entityReference;
    const jobId = await this.getJobId(id, options.reportType);

    console.log(`input request ${JSON.stringify(options)}
    entityReference ${payload?.entityReference}
    jobId ${jobId}
    `);

    if (eventName === 'PDF_SNAPSHOT_EXPORT') {
      this.documentExportService.exportFramesAsPdf(jobId, payload);
      return;
    }

    this.checkDownloadProgress(jobId);

    const requestUrl = this.REPORT_TYPES[options.reportType].requestUrl;
    payload.jobId = jobId;

    await Request.request(requestUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return jobId;
  }

  async initDownloadAllEntities(reportType: ExportReportType, options: ExportEntitiesOptions) {
    if (reportType !== ExportReportType.CSV) {
      throw new Error('Only CSV export is supported when exporting all entities.');
    }

    const jobId = await this.getJobId(options.entityType, reportType);
    this.checkDownloadProgress(jobId);

    await new Entities().create({
      entityName: 'export',
      object: {
        ...options,
        jobId,
      },
      suffix: 'csv',
    });

    return jobId;
  }

  public checkDownloadProgress(jobId: string) {
    this.downloadService.initDownloadPolling(jobId);
  }

  async getJobId(id: string, reportType: string) {
    return `${id}:${await uuid()}:${reportType}:${this.authContext.user.id}:${this.authContext.currentOrg.orgSlug}`;
  }
}
