import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppExtension, AppExtensionType, AppExtensionUserApps } from '@contrail/entity-types';
import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { DocumentElementEvent } from '@contrail/documents';
import { DocumentService } from '../document/document.service';
import { ActionRequest } from '@contrail/actions';
import { AddPinnedCommentsService } from '../board-pinned-comments/add-pinned-comments-service';
import { BoardService } from '../board.service';
import { PromoteToAsssetMenuAction } from './context-menu-actions/promote-to-asset-menu.action';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ContextMenuAction } from './context-menu-actions/context-menu-action';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { DocumentAssetService } from '../document/document-asset/document-asset.service';
import { DocumentComponentService } from '../document/document-component/document-component-service';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { GenerateDocumentMenuAction } from './context-menu-actions/generate-document-menu.action';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ItemDetailsModalComponent } from '@common/items/item-details-modal/item-details-modal.component';
import { BoardItemService } from '../board-item/board-item.service';
import { ContextualEntityHelper } from '../document/contextual-entity-helper';
import { Entities, EntityReference } from '@contrail/sdk';
import { RoutingService } from '@common/routing/routing.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { QuickCreateItemOptionService } from './quick-create-item-option.component';
import { ObjectUtil } from '@contrail/util';
import { FrameTemplateEditorModal } from '../document-templates/frame-template-editor-modal/frame-template-editor-modal.component';
import { AuthService } from '@common/auth/auth.service';
import { AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { AppExtensionService } from '@common/app-extensions/app-extension.service';
import { BoardsWebSDKMessageHandler } from '../web-sdk/board-web-sdk-message-handler';
import { DocumentPropertiesService } from '../document/document-properties/document-properties.service';
import { BoardContext, VibeIQAppType } from '@contrail/extensions-sdk';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { DocumentCreateItemsService } from '../document/document-item/document-create-item/document-create-items.service';
import { DocumentTextElementUtil } from '@contrail/document-generation';
import { DocumentDynamicTextService } from '../document/document-text/document-dynamic-text/document-dynamic-text.service';
import { DocumentDynamicTextUtil } from '../document/document-text/document-dynamic-text/document-dynamic-text-util';

export interface SelectedElementInfo {
  documentElements: Array<DocumentElement>;
}

@Component({
  selector: 'app-board-context-menu',
  templateUrl: './board-context-menu.component.html',
  styleUrls: ['./board-context-menu.component.scss'],
})
export class BoardContextMenuComponent implements AfterViewInit, OnDestroy {
  constructor(
    private documentService: DocumentService,
    private documentComponentService: DocumentComponentService,
    private store: Store<RootStoreState.State>,
    private boardItemService: BoardItemService,
    private routingService: RoutingService,
    private contextualEntityHelper: ContextualEntityHelper,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private documentAssetService: DocumentAssetService,
    protected confirmationBoxService: ConfirmationBoxService,
    private quickCreateItemOptionService: QuickCreateItemOptionService,
    private matDialog: MatDialog,
    private featureFlagService: FeatureFlagRegistryService,
    private authService: AuthService,
    private boardService: BoardService,
    private appExtensionService: AppExtensionService,
    private boardsWebSDKMessageHandler: BoardsWebSDKMessageHandler,
    private documentPropertiesService: DocumentPropertiesService,
    private documentCreateItemsService: DocumentCreateItemsService,
    private documentDynamicTextService: DocumentDynamicTextService,
  ) {}

  @ViewChild(MatMenuTrigger) contextMenuTrigger: MatMenuTrigger;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  @ViewChild('copyContextMenu', { read: ElementRef }) copyContextMenu: ElementRef;

  public actions: Array<any>;
  public element: DocumentElement;
  public selectedItemElements: Array<DocumentElement> = [];
  public selectedFrameElements: Array<DocumentElement> = [];
  public sourceEvent: DocumentElementEvent;
  public selectedGroupElements: DocumentElement[];
  public selectedGroupElementsMatchSizeEligible: DocumentElement[];
  public editorMode: EditorMode;
  private subscriptions: Array<Subscription> = [];
  public itemBoardsFeatureFlag = false;
  public itemContextFeatureActive = false;
  public itemCreationFeatureActive = false;
  public isMaskAllowed = false;
  public isRemoveMaskAllowed = false;
  public isDeleteRowAllowed = false;
  public isDeleteColumnAllowed = false;
  public singleSelectedTable = null;
  public copiedProperties;

  public contextualEntity;
  public contextualEntityReference: EntityReference;
  public contextualAppExtensions: Array<AppExtension> = [];
  public canViewDetails = false;
  public elementIsInGroup = false;
  public name;
  ngAfterViewInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.subscriptions.push(this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m)));
    this.subscriptions.push(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }
        if (event.eventType === 'contextmenu') {
          this.selectedGroupElements = null;
          this.selectedGroupElementsMatchSizeEligible = null;
          this.isMaskAllowed = false;
          this.isRemoveMaskAllowed = false;
          this.selectedFrameElements = [];
          console.log('ComposerContextMenuComponent: event ', event);
          this.showMenu(event);
        } else if (
          this.documentService.getInteractionMode() === 'generate_document' &&
          event.eventType === 'handleClick'
        ) {
          new GenerateDocumentMenuAction(
            this.store,
            this.documentService,
            this.boardService,
            this.documentAssetService,
            this.matDialog,
            this.confirmationBoxService,
          ).handleAction(event, null, null);
          this.documentService.setInteractionMode('select');
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
        this.itemBoardsFeatureFlag = !!flags.find((x) => x.featureName === Feature.ITEM_BOARDS);
        this.itemContextFeatureActive = !!flags.find((x) => x.featureName === Feature.ITEM_CONTEXT);
        this.itemCreationFeatureActive = !!flags.find((x) => x.featureName === Feature.ITEM_CREATION);
        const areExtensionsEnabled = !!flags.find((x) => x.featureName === Feature.EXTENSIONS);
        if (areExtensionsEnabled) {
          this.subscribeToAppExtensions();
        }
      }),
    );
  }

  subscribeToAppExtensions() {
    this.subscriptions.push(
      this.store.select(AppExtensionsSelectors.appExtensions).subscribe((extensions) => {
        if (!extensions) {
          this.contextualAppExtensions = [];
          return;
        }

        this.contextualAppExtensions = extensions.filter(
          (extension) =>
            extension.extensionType === AppExtensionType.CONTEXTUAL_ACTION &&
            extension.userApps.some((appType) =>
              [AppExtensionUserApps.BOARDS, AppExtensionUserApps.ALL].includes(appType),
            ),
        );
      }),
    );
  }

  unsubscribe() {
    this.selectedGroupElements = null;
    this.selectedGroupElementsMatchSizeEligible = null;
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  showMenu(event: DocumentElementEvent) {
    // console.log('showMenu: ', event.element);
    this.initActions();
    const selectedElements = this.documentService.getSelectedElements();
    this.selectedGroupElements = this.documentService.getSelectedGroupElements();
    this.selectedGroupElementsMatchSizeEligible = this.selectedGroupElements?.filter(
      (e) => ['image', 'svg'].indexOf(e.type) !== -1,
    );
    this.isMaskAllowed = this.documentService.isMaskAllowed(this.selectedGroupElements);
    this.isRemoveMaskAllowed = this.documentService.isRemoveMaskAllowed(this.selectedGroupElements);
    this.isDeleteRowAllowed = this.documentService.documentRenderer?.getValidRowsToDelete()?.length > 0;
    this.isDeleteColumnAllowed = this.documentService.documentRenderer?.getValidColumnsToDelete()?.length > 0;
    this.singleSelectedTable = this.documentService.documentRenderer?.getSingleSelectedTable();
    this.copiedProperties = this.documentPropertiesService.getCopiedProperties();
    this.sourceEvent = event;
    this.element =
      this.editorMode === EditorMode.COMMENT
        ? event.element
        : event.element && selectedElements.findIndex((element) => element.id === event.element.id) !== -1
          ? event.element
          : null;
    if (this.element) {
      const groupElement: DocumentElement = this.documentService.getGroupByMemberId(this.element.id);
      this.elementIsInGroup = false;
      if (groupElement) {
        this.elementIsInGroup = true;
      }
    }
    this.selectedFrameElements = selectedElements.filter((element) => element.type === 'frame');
    this.deriveSelectedItems(selectedElements);

    const top = event.sourceMouseEvent.clientY;
    const left = event.sourceMouseEvent.clientX;
    this.contextMenu.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.contextMenu.nativeElement.classList.add('visible');
    this.contextMenuTrigger.openMenu();

    // Needed to get further up the call stack, for browser security.
    this.copyContextMenu && this.copyContextMenu.nativeElement.addEventListener('click', this.copy.bind(this));
    this.deriveSelectedEntity();
  }

  initActions() {
    this.actions = [];
    this.actions.push(
      new PromoteToAsssetMenuAction(this.store, this.documentService, this.boardService, this.documentAssetService),
    );
    // this.actions.push(new GenerateDocumentMenuAction(this.store, this.documentService, this.boardService, this.documentAssetService, this.matDialog, this.confirmationBoxService));
  }

  private async deriveSelectedItems(selectedElements: DocumentElement[]) {
    const areItemsAllowedToBeSelected = this.editorMode === EditorMode.EDIT && selectedElements?.length;
    if (!areItemsAllowedToBeSelected) {
      this.selectedItemElements = [];
      return;
    }

    this.selectedItemElements = selectedElements.filter((element) => !!element?.modelBindings?.item);
  }

  private async deriveSelectedEntity() {
    const contextualEntityInformation = await this.contextualEntityHelper.getContextualEntityFromDocumentElement(
      this.element,
    );
    if (!contextualEntityInformation) {
      this.contextualEntityReference = null;
    }
    this.contextualEntityReference = contextualEntityInformation.reference;
    this.name = contextualEntityInformation.name;
    this.contextualEntity = contextualEntityInformation.entity;

    if (['item', 'content'].includes(this.contextualEntityReference?.entityType)) {
      this.canViewDetails = true;
    } else {
      this.canViewDetails = false;
    }
  }

  private async getContextualInformationForItemElement(
    itemElement: DocumentElement,
  ): Promise<{ contextualEntityReference: EntityReference; contextualEntity: any }> {
    if (this.contextualEntityReference?.entityType === 'item') {
      return { contextualEntityReference: this.contextualEntityReference, contextualEntity: this.contextualEntity };
    }

    const contextualEntityInformation =
      await this.contextualEntityHelper.getContextualEntityFromDocumentElement(itemElement);
    return {
      contextualEntityReference: contextualEntityInformation.reference,
      contextualEntity: contextualEntityInformation.entity,
    };
  }

  public showComments(event) {
    // We need source mouse event - where user clicked first to open the context menu (vs when the user clicked on the Comment menu)
    this.addPinnedCommentsService.addCommentsFromWindowPosition(
      {
        x: this.sourceEvent.sourceMouseEvent.clientX,
        y: this.sourceEvent.sourceMouseEvent.clientY,
      },
      this.element,
    );
  }

  public setStartingLocation(event) {
    this.boardService.setStartingLocation();
  }

  public async createNewItem(event) {
    this.store.dispatch(setLoading({ loading: true, message: 'Creating new item.' }));
    await this.documentCreateItemsService.createAndAddNewItemFamily({
      position: this.boardService.zoomPanHandler.computeDocumentPositionForMouseEvent(event),
    });
    this.store.dispatch(setLoading({ loading: false }));
  }

  public async createItemBoard() {
    const itemElement = this.selectedItemElements[0];
    const { contextualEntityReference, contextualEntity } =
      await this.getContextualInformationForItemElement(itemElement);

    // Create board:
    const currentBoard = this.getCurrentBoard();

    const object = {
      name: 'Board for ' + contextualEntity.name,
      primaryContextReference: contextualEntityReference.reference,
      workspaceId: currentBoard.workspaceId,
      rootWorkspaceId: currentBoard.rootWorkspaceId,
    };
    const board = await new Entities().create({ entityName: 'board', object });
    console.log('created board: ', board);
    this.routingService.openApp('boards', `board/${board.id}`);
  }

  launchItemDetails() {
    /** BL NOTE:  Should be consolidating this logic into some sort of 'contextualEntity' service for document elements.  */
    const itemElement = this.selectedItemElements[0];
    const itemId = itemElement.modelBindings.item.substring(5);
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '900px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((result) => {
      this.boardItemService.syncElements(result);
    });
  }

  async createNewOption() {
    const itemElement = this.selectedItemElements[0];
    const reference = itemElement.modelBindings.item;
    const item = await new Entities().get({ reference, relations: ['itemFamily'] });
    const itemFamily = item.itemFamily;
    const newOptionData = await this.quickCreateItemOptionService.open(itemFamily);
    console.log('New Item Option Data: ', newOptionData);
    if (!newOptionData) {
      return;
    }
    this.store.dispatch(setLoading({ loading: true, message: 'Creating new item.' }));
    const newPosition: PositionDefinition = ObjectUtil.cloneDeep(this.element.position);
    newPosition.x = newPosition.x + 200;
    await this.documentCreateItemsService.createAndAddNewItemOption(
      {
        position: newPosition,
      },
      itemFamily,
      newOptionData,
    );
    this.store.dispatch(setLoading({ loading: false }));
  }

  async addItemsToClipboard() {
    const clipboardItems = [];
    for (const itemElement of this.selectedItemElements) {
      const itemId = itemElement?.modelBindings?.item?.split('item:')[1];
      const projectItemId = itemElement?.modelBindings?.projectItem?.split('project-item:')[1];
      clipboardItems.push({ itemId, projectItemId });
    }

    this.store.dispatch(ClipboardActions.addItemsToClipboard({ clipboardItems }));
  }

  copy(event) {
    this.documentService.handleActionRequest(new ActionRequest('copy_elements', event));
  }

  emitActionRequest(actionType: string) {
    this.documentService.handleActionRequest(new ActionRequest(actionType, this.sourceEvent));
  }
  async invoke(action: ContextMenuAction) {
    action.handleAction(this.sourceEvent, this.editorMode, this.getSelectedElementInfo());
  }
  canInvoke(action: ContextMenuAction) {
    const canInvoke = action.canInvoke(this.editorMode, this.getSelectedElementInfo());
    return canInvoke;
  }

  removeElementFromGroup() {
    this.documentService.handleActionRequest(new ActionRequest('remove_element_from_group', event));
  }

  async saveAsTemplate() {
    const elements = [];
    this.selectedGroupElements.forEach((groupElement) => {
      elements.push(ObjectUtil.cloneDeep(groupElement));
      if (groupElement.type === 'table') {
        elements.push(
          ...this.documentService?.documentRenderer
            ?.getTableChildElements(groupElement)
            ?.map((e) => ObjectUtil.cloneDeep(e)),
        );
      }
    });
    const frame = ObjectUtil.cloneDeep(this.element);
    const authContext = await this.authService.getAuthContext();
    const isOrgAdmin = this.authService.isAdmin();
    const bindingProperties = this.documentDynamicTextService.dynamicTextState.getFlatBindingProperties();
    elements
      .filter((e) => DocumentDynamicTextUtil.isDynamicTextElement(e))
      .forEach((element) => {
        delete element.id;
        let textValue = bindingProperties[element.propertyBindings.text]?.label;
        this.documentDynamicTextService.adjustSizeAndStyling(element, textValue);
      });
    const config = {
      data: { authContext, isOrgAdmin, elements, frame, ownerReference: this.documentService.ownerReference },
      panelClass: 'frame-template-editor',
      minWidth: '1200px',
    };
    this.matDialog.open(FrameTemplateEditorModal, config);
  }

  private getSelectedElementInfo(): SelectedElementInfo {
    let documentElements = this.selectedGroupElements || [];
    return {
      documentElements,
    };
  }

  launchExtension(extension: AppExtension) {
    const board = this.getCurrentBoard();
    const boardContext: BoardContext = {
      id: board.id,
      name: board.name,
      orgId: board.orgId,
      backingAssortmentId: board.backingAssortmentId,
      documentId: board.documentId,
      previewFileId: board.previewFileId,
      rootWorkspaceId: board.rootWorkspaceId,
      typeId: board.typeId,
      workspaceId: board.workspaceId,
    };

    const document: any = this.documentService.getDocument();
    if (document) {
      boardContext.document = {
        id: document.id,
        name: document.name,
        orgId: document.orgId,
        ownedByReference: document.ownedByReference,
        size: document.size,
        elements: document.elements,
        documentElementSortOrder: document.documentElementSortOrder,
        modelBindings: document.modelBindings,
        workspaceId: document.workspaceId,
      };
    }

    const selectedElements = this.documentService.getSelectedElements();

    const context = {
      board: boardContext,
      vibeIQApp: VibeIQAppType.BOARDS,
      selectedElements,
    };

    this.appExtensionService.launchExtensionModal(extension, this.boardsWebSDKMessageHandler, context);
  }

  private getCurrentBoard() {
    let currentBoard;
    this.boardService.currentBoard
      .pipe(
        take(1),
        tap((board) => (currentBoard = board)),
      )
      .subscribe();

    return currentBoard;
  }

  async addItemsToProject() {
    this.boardItemService.addItemsToProject();
  }
}
