import { State } from './document.state';
import { Action, createReducer, on } from '@ngrx/store';
import { documentInitialState } from './document.state';
import * as DocumentActions from './document.actions';
import { documentModelEntitiesReducers } from '../document-item/document-model-entities/document-model-entities.reducers';

const toggleChooser = (state: State, { overlay }) => {
  return {
    ...state,
    toggleChooser: { ...overlay },
  };
};

const setViewSize = (state: State, viewSize) => {
  return {
    ...state,
    viewSize,
  };
};

const setLastKnownMousePosition = (state: State, { lastKnownMousePosition }) => {
  return {
    ...state,
    lastKnownMousePosition,
  };
};

const navigateToPosition = (state: State, { position }) => {
  return {
    ...state,
    positionInViewBox: position,
  };
};

const setAnnotatedElements = (state: State, { annotatedElements }) => {
  return {
    ...state,
    annotatedElements,
  };
};

const setDesignMode = (state: State, { designMode }) => {
  return {
    ...state,
    designMode,
  };
};

const setAssignItemToComponentConditions = (state: State, { assignItemToComponentConditions }) => {
  return {
    ...state,
    assignItemToComponentConditions,
  };
};

export const documentReducers = createReducer(
  documentInitialState,
  ...documentModelEntitiesReducers,
  on(DocumentActions.toggleChooser, toggleChooser),
  on(DocumentActions.setViewSize, setViewSize),
  on(DocumentActions.setLastKnownMousePosition, setLastKnownMousePosition),
  on(DocumentActions.navigateToPosition, navigateToPosition),
  on(DocumentActions.setAnnotatedElements, setAnnotatedElements),
  on(DocumentActions.setDesignMode, setDesignMode),
  on(DocumentActions.setAssignItemToComponentConditions, setAssignItemToComponentConditions),
);

export function reducer(state: State | undefined, action: Action): any {
  return documentReducers(state, action);
}
