import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { ComponentEditorService } from '../component-editor.service';
import { Subject, Subscription } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { DocumentItemService } from '../../document-item/document-item.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DocumentComponentUtils } from '../../document-component/document-component.utils';

@Component({
  selector: 'app-component-editor-font',
  templateUrl: './component-editor-font.component.html',
  styleUrls: ['./component-editor-font.component.scss'],
})
export class ComponentEditorFontComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Output() propertiesChangedEvent = new EventEmitter();
  @Output() valuesChanged = new EventEmitter<any>();
  @Output() fontChanged = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();
  private selectedProperties: DocumentElement[];
  private subject: Subject<any> = new Subject();
  public sizes: string[];

  constructor(
    private componentEditorService: ComponentEditorService,
    private documentItemService: DocumentItemService,
  ) {
    this.sizes = ComponentEditorService.getPtOptions();
  }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe((values) => {
      if (values && values?.type === 'fontSize' && values?.value) {
        values.value = ComponentEditorService.toPx(values.value);
      }
      this.fontChanged.emit(values);
      this.updateTextProperties(values);
    });
    this.subscribeToDocumentElementDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private subscribeToDocumentElementDetails() {
    this.componentEditorService.componentObjectDetailsObs
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((componentObjectDetails) => {
        if (componentObjectDetails) {
          this.selectedProperties = ObjectUtil.cloneDeep(componentObjectDetails.elements).filter(
            (property) => property.enabled,
          );
        }
      });
  }

  public handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  private updateTextProperties(values) {
    const changes = DocumentComponentUtils.convertToTextStyle(values);

    for (let i = 0; i < this.selectedProperties?.length; i++) {
      const element = this.selectedProperties[i];
      if (element?.type === 'text') {
        ObjectUtil.mergeDeep(this.selectedProperties[i], changes);
      }
    }

    this.propertiesChangedEvent.emit(this.selectedProperties);
  }

  public getCurrentValue(attr) {
    let value = DocumentComponentUtils.getCurrentStyleValue(this.selectedProperties, attr);
    if (value && attr === 'style.font.size') {
      value = ComponentEditorService.toPt(value);
    }
    return value;
  }

  public clearFormat(event) {
    this.clear.emit(true);
    this.selectedProperties = this.documentItemService.clearTextFormat(this.selectedProperties);
    this.propertiesChangedEvent.emit(this.selectedProperties);
  }
}
