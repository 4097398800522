import {
  DocumentAction,
  DocumentChangeType,
  DocumentElementFactory,
  SizeDefinition,
  DocumentElement,
} from '@contrail/documents';
import { DocumentService } from '../document.service';
import { nanoid } from 'nanoid';
import { take, map } from 'rxjs/operators';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Content, Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { SVGHelper } from '../../canvas/svg-helper';
import pLimit from 'p-limit';
import { FileDownloader } from '../../canvas/file-downloader';
import { environment } from 'src/environments/environment';
import { DocumentItemService } from '../document-item/document-item.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import {
  DocumentFileService,
  FILE_MAX_SIZE,
  FILE_MAX_SIZE_ERROR_MESSAGE,
  SUPPORTED_IMAGE_TYPES,
} from './document-file.service';
import { timer } from 'rxjs';

const limit = pLimit(10);

const POLLING_INTERVAL = 1000;
const POLLING_RETRIES = 30000 / POLLING_INTERVAL;

export const DEFAULT_WIDTH = 300;

export class DocumentFileHandler {
  private readonly LOADING_IMAGE_ICON = '/assets/images/image_upload.svg';

  public uploading: boolean = false;

  public fileService: DocumentFileService;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private ownerReference: string,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
    this.fileService = new DocumentFileService(this.store, this.ownerReference);
  }

  public showConfirmDialog() {
    return this.uploading
      ? 'Content is still uploading in the background. If you leave, your changes will be lost!'
      : false;
  }

  /**
   * Create a File, and returns a promise that contains a document action to updates the
   * bindings of the element with the correct URLs after upload has completed.
   * This is used elsewhere to swap the local blob URL with the correct download link.
   * @param element
   * @param file
   * @returns
   */
  private async updateModelBindingAfterFileUpload(element: DocumentElement, file: File, fileId?): Promise<any> {
    return await this.fileService.createAndUploadFile(file, fileId).then((fileEntity) => {
      return new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData: {
          id: element.id,
          url: fileEntity.fileUrl,
        },
        elementId: element.id,
      });
    });
  }

  /**
   * Create a Content entity, and returns a promise that contains a document action to updates the
   * bindings of the element with the correct URLs after upload has completed.
   * This is used elsewhere to swap the local blob URL with the correct download link.
   * @param element
   * @param file
   * @returns
   */
  public async updateModelBindingAfterContentUpload(element: DocumentElement, file: File, contentId?): Promise<any> {
    const documentId = this.documentService.currentDocument.id;
    return await this.fileService
      .createAndUploadContent(file, contentId)
      .then((contentEntity) => {
        return new DocumentAction({
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: element.id,
            alternateUrls: {
              originalFile: contentEntity.primaryFile.fileUrl,
            },
          },
          elementId: element.id,
          documentId,
        });
      })
      .catch((error) => {
        console.log('DocumentFileHandler.updateModelBindingAfterContentUpload error:', element, error);
        return null;
      });
  }

  public async getSVGTextFromDocumentElement(element: DocumentElement) {
    const authContext = await this.documentService.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    return await SVGHelper.getSVGTextFromDocumentElement(element, fileDownLoader);
  }

  public async addImageElementsFromFileUrl(
    primaryFileUrl: string,
    fileName: string,
    type: string,
    options: DocumentElement,
  ) {
    const authContext = await this.documentService.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    const blob = await fileDownLoader.downloadFileAsBlob(primaryFileUrl);
    this.documentService.fileHandler.addImageElementsFromFiles([new File([blob], fileName, { type })], options);
  }

  public async addImageToElementFromFileURL(primaryFileUrl: string, fileName: string, type: string, item: any) {
    const authContext = await this.documentService.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    const blob = await fileDownLoader.downloadFileAsBlob(primaryFileUrl);
    const file = new File([blob], fileName, { type });
    const contentArray = await this.documentService.fileHandler.createContentsFromFiles([file], item);
    const content = contentArray[0];
    await new Entities().create({
      entityName: 'content-holder-content',
      object: {
        contentHolderReference: `item:${item.id}`,
        contentId: content.id,
      },
    });
    const object = {
      primaryViewableId: content.id,
      largeViewableDownloadUrl: content.primaryFileUrl,
      mediumLargeViewableDownloadUrl: content.primaryFileUrl,
      mediumViewableDownloadUrl: content.primaryFileUrl,
      smallViewableDownloadUrl: content.primaryFileUrl,
      tinyViewableDownloadUrl: content.primaryFileUrl,
      primaryFileUrl: content.primaryFileUrl,
      fileName: content.fileName,
      contentType: content.contentType,
    };
    return object;
  }

  /**
   * Adds image (including PDF and AI files) or svg elements to the document in batch.
   * @param files
   * @param options
   */
  public async addImageElementsFromFiles(
    files: File[],
    options: DocumentElement = {},
    targetElement?: DocumentElement,
  ): Promise<DocumentElement[]> {
    this.uploading = true;
    const promises = [];

    if (targetElement?.type === 'component' && targetElement?.modelBindings?.item) {
      let allFilesValid = true;
      for (const file of files) {
        if (SUPPORTED_IMAGE_TYPES.indexOf(file.type) === -1) {
          allFilesValid = false;
          break;
        }
      }
      if (allFilesValid) {
        this.uploading = false;
        await this.uploadFilesAndHandleImageItemAssignment(files, targetElement);
        return;
      }
    }

    this.documentService.toggleLoading(true, 'Uploading. Please wait....');
    let unsupportedFilesCount = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSupported = DocumentFileService.isSupportedFile(file);
      if (fileSupported) {
        const promise = limit(async () => {
          return await this.createElementFromFile(file, {});
        });
        promises.push(promise);
      } else {
        unsupportedFilesCount++;
      }
    }

    let elements = [];
    const updateModelBindingsPromises = [];
    await Promise.all(promises)
      .then((results) => {
        results.forEach(
          ({
            element,
            updateModelBindingPromise,
          }: {
            element: DocumentElement;
            updateModelBindingPromise?: Promise<any>;
          }) => {
            if (element) {
              elements.push(element);
            }
            if (updateModelBindingPromise) {
              updateModelBindingsPromises.push(updateModelBindingPromise);
            }
          },
        );
      })
      .catch(() => {
        this.uploading = false;
      });
    this.documentService.toggleLoading(false, '');

    const unsupportedFilesMessage = DocumentFileService.getUnsupportedFilesMessage(files.length, unsupportedFilesCount);
    if (unsupportedFilesMessage) {
      this.documentService.snackBar.open(unsupportedFilesMessage, '', { duration: 5000 });
    }

    elements = this.placeElementsInRows(elements, options);
    if (elements?.length > 0) {
      this.documentService.deselectAllElements();
      const actions = elements.map((element) => {
        return new DocumentAction(
          {
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementData: element,
            elementId: element.id,
          },
          {
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementId: element.id,
            elementData: ObjectUtil.cloneDeep(element),
          },
        );
      });
      this.documentService.handleDocumentActions(actions);
    } else {
      this.uploading = false;
    }

    /**
     * Wait for all promises to complete, then send the updates to the element that
     * will include the updated download URLs, usable by other browsers.
     *
     * Wait at least 1s before updating elements to ensure that they were successfully
     * added first by remote sessions. Otherwise update won't happen since
     * the element won't exist yet.
     */
    if (updateModelBindingsPromises?.length > 0) {
      const delayToAddElements = this.documentService.getTotalSessionEventDelay(elements.length);
      timer(delayToAddElements + this.UPDATE_MODEL_BINDING_MIN_DELAY).subscribe(() => {
        Promise.all(updateModelBindingsPromises.map(this.applyUpdateModelBindingPromise.bind(this))).finally(() => {
          this.uploading = false;
        });
      });
    } else {
      this.uploading = false;
    }

    return elements;
  }
  private UPDATE_MODEL_BINDING_MIN_DELAY = 1000;
  private MAX_ELEMENTS_PER_ROW = 10;
  private ELEMENT_OFFSET = 50;

  private placeElementsInRows(elements: DocumentElement[], options: DocumentElement): DocumentElement[] {
    if (elements.length === 0) return elements;
    const position = {
      x: options.position.x - (elements[0].size?.width ?? DEFAULT_WIDTH) * 0.5,
      y: options.position.y - (elements[0].size?.height ?? DEFAULT_WIDTH) * 0.5,
    };
    let maxRowHeight = elements[0].size.height ?? DEFAULT_WIDTH;
    return elements.map((element, index) => {
      if (options?.position) {
        element.position.x = position.x;
        element.position.y = position.y;
        position.x = element.position.x + (element.size?.width ?? DEFAULT_WIDTH) + this.ELEMENT_OFFSET;
        maxRowHeight = Math.max(maxRowHeight, element.size?.height ?? DEFAULT_WIDTH);
        if ((index + 1) % this.MAX_ELEMENTS_PER_ROW === 0) {
          position.x = elements[0].position.x;
          position.y = element.position.y + maxRowHeight + this.ELEMENT_OFFSET;
          maxRowHeight = 0;
        }
      }
      return element;
    });
  }

  private async applyUpdateModelBindingPromise(updateModelBindingPromise) {
    return new Promise(async (resolve) => {
      const action: DocumentAction = await updateModelBindingPromise;
      if (action) {
        // Set file url to current session
        this.documentService.applyDocumentActions([action], true);
        // Send file url to remote sessions
        this.documentService.sendSessionEvent([action], { id: action.changeDefinition.documentId });
      }
      resolve(null);
    });
  }

  /**
   * Upload @files as Content and assign as viewables to @element
   * @param files
   * @param element
   */
  public async uploadFilesAndHandleImageItemAssignment(files: File[], element: DocumentElement) {
    this.documentService.handleDocumentElementEvent({
      element: element,
      data: files,
      eventType: 'file_drag',
    });
  }

  public createFileObjectFromSvgString(svgText: string, fileName?: string) {
    const prefix = nanoid(16);
    const file = new File([svgText], !fileName || fileName == '' ? `svg-${prefix}.svg` : fileName, {
      type: 'image/svg+xml',
    });
    return file;
  }

  /**
   * Creates an SVG document element.
   * Prefixes ids and classes in the svg string with a unique id.
   * @param svgText
   * @param options
   * @param fileName
   * @returns
   */
  public async createSvgElementFromString(
    svgText: string,
    options?: DocumentElement,
    fileName?: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    const size = SVGHelper.getSize(svgText);
    if (!size) return { element: null };

    // Upload rasterized SVG as a File entity so it can be passed to remote sessions, and also used on element copy/paste.
    // This is temp URL which will be automatically replaced by API once SVG viewables are generated.
    const { canvasBlob } = await SVGHelper.getCanvasBlob(svgText);
    const fileEntity = await this.fileService.createAndUploadFile(
      new File([canvasBlob], `canvasImage.png`, {
        type: 'image/png',
      }),
    );

    const file = this.createFileObjectFromSvgString(svgText, fileName);
    const contentId = nanoid(16);
    const elementOptions: DocumentElement = {
      modelBindings: { content: `content:${contentId}` },
      position: {
        x: 0,
        y: 0,
      },
      size,
      propertyBindings: { url: 'content.largeViewableUrl' },
      url: fileEntity.fileUrl,
      alternateUrls: {
        originalFile: fileEntity.fileUrl,
        highResolution: fileEntity.fileUrl,
        lowResolution: fileEntity.fileUrl,
      },
    };

    if (options?.position && elementOptions.size) {
      elementOptions.position = {
        x: options.position.x - elementOptions.size.width / 2,
        y: options.position.y - elementOptions.size.height / 2,
      };
    }

    const element = DocumentElementFactory.createElement('svg', elementOptions);
    const updateModelBindingPromise = this.updateModelBindingAfterContentUpload(element, file, contentId);

    return {
      element,
      updateModelBindingPromise,
    };
  }

  /**
   * @param files
   * @returns
   */
  public async createContentsFromFiles(files: File[], owner?: any): Promise<any> {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const promise = limit(async () => {
        return await this.createContentFromFile(file, owner);
      });
      if (promise) {
        promises.push(promise);
      }
    }
    return await Promise.all(promises);
  }

  /**
   * Create and upload Content from uploaded @file
   * @param file
   * @returns
   */
  public async createContentFromFile(file: File, owner?: any): Promise<Content> {
    try {
      const isSvgImageFileType = file.type.indexOf('image/svg+xml') !== -1;
      const isImageFileType = file.type.indexOf('image') > -1;
      const isGeneratableViewFileType =
        ['application/postscript', 'application/pdf'].includes(file.type) ||
        file.name.endsWith('.glb') ||
        file.name.endsWith('.gltf');

      let content;
      if (isSvgImageFileType) {
        const svgText = await file.text();
        content = await this.fileService.createAndUploadContent(
          this.createFileObjectFromSvgString(svgText, file.name),
          null,
          owner,
        );
      } else if (isImageFileType) {
        content = await this.fileService.createAndUploadContent(file, null, owner);
      } else if (isGeneratableViewFileType) {
        content = await this.fileService.createAndUploadContent(file, null, owner);
      }

      return content;
    } catch (e) {
      console.log('Error creating content: ', e);
      return null;
    }
  }

  private fileTypeToElementType(file: File): string {
    if (file.type?.indexOf('image/svg+xml') !== -1) {
      return 'svg';
    } else if (file.type?.indexOf('image') !== -1) {
      return 'image';
    } else if (
      ['application/postscript', 'application/pdf'].includes(file.type) ||
      file.name.endsWith('.glb') ||
      file.name.endsWith('.gltf')
    ) {
      return 'generated_image';
    }
    return null;
  }

  private async createImageElementFromFile(
    file: File,
    options?: DocumentElement,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    const fileAsDataURL = URL.createObjectURL(file); // local blob
    const imageDimensions = await this.getImageDimensionsFromUrl(fileAsDataURL);
    if (!imageDimensions) return { element: null };

    const contentId = nanoid(16);
    const elementOptions: DocumentElement = {
      modelBindings: { content: `content:${contentId}` },
      size: { ...imageDimensions },
      propertyBindings: { url: 'content.largeViewableUrl' },
      url: fileAsDataURL, // this is a temporary URL that will automatically be replaced once file is done uploading
    };
    if (options?.position) {
      elementOptions.position = {
        x: options.position.x - elementOptions.size.width / 2,
        y: options.position.y - elementOptions.size.height / 2,
      };
    }
    const element = DocumentElementFactory.createImageElement(elementOptions);
    const updateModelBindingPromise = this.updateModelBindingAfterContentUpload(element, file, contentId);
    return { element, updateModelBindingPromise };
  }

  private async createGeneratedImageElementFromFile(
    file: File,
    options?: DocumentElement,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    const contentEntity = await this.fileService.createAndUploadContent(file);
    const elementOptions: DocumentElement = {
      modelBindings: { content: `content:${contentEntity.id}` },
      size: {
        height: DEFAULT_WIDTH,
        width: DEFAULT_WIDTH,
      },
      propertyBindings: { url: 'content.largeViewableUrl' },
      url: 'blob:', // Temp image while generating viewable
    };
    if (options?.position) {
      elementOptions.position = {
        x: options.position.x - elementOptions.size.width / 2,
        y: options.position.y - elementOptions.size.height / 2,
      };
    }
    const element = DocumentElementFactory.createImageElement(elementOptions);
    const documentId = this.documentService.currentDocument.id;

    // Poll for
    setTimeout(() => {
      this.pollForViewables(contentEntity.id, element, 0, documentId);
    }, POLLING_INTERVAL);

    return { element };
  }

  /**
   * Creates image document element.
   * Generates viewable from PDF and AI files.
   * @param file
   * @param options
   * @returns
   */
  public async createElementFromFile(
    file: File,
    options?: DocumentElement,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    let element: DocumentElement;
    let updateModelBindingPromise: Promise<DocumentAction>;
    const type = this.fileTypeToElementType(file);
    if (type === 'svg') {
      const response = await this.createSvgElementFromString(await file.text(), options, file.name);
      element = response.element;
      updateModelBindingPromise = response.updateModelBindingPromise;
    } else if (type === 'image') {
      const response = await this.createImageElementFromFile(file, options);
      element = response.element;
      updateModelBindingPromise = response.updateModelBindingPromise;
    } else if (type === 'generated_image') {
      const response = await this.createGeneratedImageElementFromFile(file, options);
      element = response.element;
    }
    return { element, updateModelBindingPromise };
  }

  private async getImageDimensionsFromFile(file: File): Promise<SizeDefinition> {
    const fileAsDataURL = URL.createObjectURL(file);
    return this.getImageDimensionsFromUrl(fileAsDataURL);
  }

  private async getImageDimensionsFromUrl(url: string): Promise<SizeDefinition> {
    return new Promise((resolve) => {
      this.http
        .get(url, { responseType: 'blob' })
        .pipe(
          take(1),
          map((blob) => {
            return this.sanitizer.sanitize(
              SecurityContext.RESOURCE_URL,
              this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)),
            );
          }),
        )
        .subscribe({
          next: (url) => {
            const img = new Image();
            img.onload = () => {
              resolve({
                height: img.height,
                width: img.width,
              });
            };
            img.onerror = (error) => {
              console.error('DocumentFileHandler.getImageDimensionsFromUrl: image on load error', url, error);
              resolve(null);
            };
            img.src = url;
          },
          error: (error) => {
            console.error('DocumentFileHandler.getImageDimensionsFromUrl: http error', url, error);
            resolve(null);
          },
        });
    });
  }

  private getAdjustedImageSize(size: SizeDefinition): SizeDefinition {
    const width = DEFAULT_WIDTH;
    const height = (DEFAULT_WIDTH / size.width) * size.height;
    return {
      height,
      width,
    };
  }

  private async pollForViewables(contentId: any, imageElement: DocumentElement, count = 0, documentId: string) {
    const content = await new Entities().get({
      entityName: 'content',
      id: contentId,
    });

    if (content?.largeViewableId) {
      // adjust the sizing
      const imageDimensions = await this.getImageDimensionsFromUrl(imageElement.url);
      // const adjustedDimensions = this.getAdjustedImageSize(imageDimensions);

      const action = new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData: {
          url: content.largeViewableUrl,
          size: imageDimensions ?? { width: DEFAULT_WIDTH, height: DEFAULT_WIDTH },
          id: imageElement.id,
        },
        elementId: imageElement.id,
        documentId,
      });

      this.documentService.handleDocumentActions([action]);
    } else if (count < POLLING_RETRIES) {
      setTimeout(() => {
        this.pollForViewables(contentId, imageElement, ++count, documentId);
      }, POLLING_INTERVAL);
    }
  }

  /**
   * Update @element with new @svgHtmlString
   * Apply rasterized SVG to current board for better performance.
   * @param element
   * @param svgHtmlString
   * @param contentId
   * @param fileName
   */
  public async updateContentElement(
    element: DocumentElement,
    svgHtmlString: string,
    contentId: string,
    fileName?: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    if (SVGHelper.isSvg(element)) {
      return await this.updateSvgElement(ObjectUtil.cloneDeep(element), svgHtmlString, fileName);
    } else if (DocumentItemService.isItemComponet(element)) {
      return await this.updateSvgElementInComponent(ObjectUtil.cloneDeep(element), contentId, svgHtmlString, fileName);
    }
    return;
  }

  /**
   * Update @element with new @svgHtmlString
   * Apply rasterized SVG to current board for better performance.
   * @param element
   * @param svgHtmlString
   * @param contentId
   * @param fileName
   */
  public async handleUpdateContentElement(
    element: DocumentElement,
    svgHtmlString: string,
    contentId: string,
    fileName?: string,
  ): Promise<DocumentElement> {
    this.documentService.toggleLoading(true, 'Uploading. Please wait....');

    const oldElement = ObjectUtil.cloneDeep(element);
    const result: { element: DocumentElement; updateModelBindingPromise?: Promise<any> } =
      await this.updateContentElement(element, svgHtmlString, contentId, fileName);
    if (result?.element) {
      this.documentService.handleDocumentActions([
        new DocumentAction(
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: result.element,
            elementId: element.id,
          },
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementId: element.id,
            elementData: oldElement,
          },
        ),
      ]);
      if (result?.updateModelBindingPromise) {
        Promise.all([result.updateModelBindingPromise])
          .then((actions) => {
            if (actions?.length > 0) {
              // Set file url to current session
              this.documentService.applyDocumentActions(ObjectUtil.cloneDeep(actions), true);
              // Send file url to remote sessions
              this.documentService.splitActionsAndSendSessionEvent(actions);
            }
          })
          .finally(() => {});
      }
    }
    this.documentService.toggleLoading(false, '');
    return result?.element;
  }

  /**
   * Update SVG image in component @element
   * This keeps existing content, and updates it with new primaryFileId
   * 1. Create new File entity
   * 2. Update existing Content with new @primaryFileId by @contentId
   * @param element
   * @param contentId
   * @param svgHtmlString
   * @param fileName
   * @returns
   */
  public async updateSvgElementInComponent(
    element: DocumentElement,
    contentId: string,
    svgHtmlString: string,
    fileName?: string,
  ) {
    // Update existing Content entity with new File entity for the SVG
    const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    const newElement: DocumentElement = {
      id: element.id,
      elements: ObjectUtil.cloneDeep(element.elements),
      modelBindings: ObjectUtil.cloneDeep(element.modelBindings),
    };

    const [tempRasterizedSVGFileEntity, fileEntityFromContent] = await Promise.all([
      (async function (documentService) {
        const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
        return await documentService.fileHandler.fileService.createAndUploadFile(
          new File([canvasBlob], `canvasImage.png`, {
            type: 'image/png',
          }),
        );
      })(this.documentService),
      await this.documentService.fileHandler.fileService.updateContent(contentId, file),
    ]);

    const imageElement = newElement.elements.find((e) => e.type === 'image');
    const originalFile = fileEntityFromContent.fileUrl;
    const highResolution = tempRasterizedSVGFileEntity.fileUrl;
    const lowResolution = tempRasterizedSVGFileEntity.fileUrl;
    imageElement.url = highResolution;
    imageElement.alternateUrls = {
      lowResolution,
      highResolution,
      originalFile,
    };

    return { element: newElement };
  }

  /**
   * Update SVG @element with new @svgHtmlString
   * Create new Content entity and set it to element's modelBindings
   * @param element
   * @param svgHtmlString
   * @param fileName
   * @returns
   */
  public async updateSvgElement(
    element: DocumentElement,
    svgHtmlString: string,
    fileName: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    /**
     * tempRasterizedSVGFileEntity and tempSVGFileEntity are temporary files. tempRasterizedSVGFileEntity is rasterized
     * version of the new SVG which helps with rendering performance of large SVGs.
     * tempSVGFileEntity creates a URL that can be passed to remote sessions and also fetched to get
     * SVG HTML on second recolor.
     */
    const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    const [tempRasterizedSVGFileEntity, tempSVGFileEntity] = await Promise.all([
      (async function (documentService) {
        const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
        return await documentService.fileHandler.fileService.createAndUploadFile(
          new File([canvasBlob], `canvasImage.png`, {
            type: 'image/png',
          }),
        );
      })(this.documentService),
      this.documentService.fileHandler.fileService.createAndUploadFile(file),
    ]);

    const contentId = nanoid(16);
    const updateModelBindingPromise = this.updateModelBindingAfterContentUpload(element, file, contentId);

    // Upload rasterized SVG as a File entity so it can be passed to remote sessions, and also used on element copy/paste.
    // This is temp URL which will be automatically replaces by API once SVG viewables are generated.
    // const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
    // const tempRasterizedSVGFileEntity = await this.documentService.fileHandler.createAndUploadFile(
    //   new File([canvasBlob], `canvasImage.png`, {
    //     type: 'image/png',
    //   }),
    // );

    // // Create new content for the SVG
    // const contentId = nanoid(16);
    // const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    // const contentEntity = await this.documentService.fileHandler.createAndUploadContent(file, contentId);

    // const originalFile = contentEntity.primaryFile.fileUrl;
    const originalFile = tempSVGFileEntity.fileUrl;
    const highResolution = tempRasterizedSVGFileEntity.fileUrl;
    const lowResolution = tempRasterizedSVGFileEntity.fileUrl;
    return {
      element: {
        id: element.id,
        modelBindings: {
          content: 'content:' + contentId,
        },
        propertyBindings: {
          url: 'content.largeViewableUrl',
        },
        url: highResolution,
        alternateUrls: {
          highResolution: highResolution,
          lowResolution: lowResolution,
          originalFile: originalFile,
        },
      },
      updateModelBindingPromise,
    };
  }
}
