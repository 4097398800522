import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PropertyConfiguratorService } from '../property-configurator.service';

@Component({
  selector: 'app-property-configurator-text-decorator',
  templateUrl: './property-configurator-text-decorator.component.html',
  styleUrls: ['./property-configurator-text-decorator.component.scss'],
})
export class PropertyConfiguratorTextDecoratorComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() decoratorType: string;
  @Input() matIconType: string;

  public isActive = false;

  constructor(private propertyConfiguratorService: PropertyConfiguratorService) {
    this.propertyConfiguratorService.documentTextElementKeyEvents.subscribe((textFormat) => {
      if (textFormat && this.decoratorType === textFormat.decoratorType) {
        this.toggleDecorator(null);
      }
    });
  }

  ngOnChanges(): void {
    this.isActive = this.currentValue === this.decoratorType;
  }

  toggleDecorator(event) {
    console.log('isActive: ', this.isActive);
    this.isActive = !this.isActive;
    this.setValue();
    if (event) {
      event.stopPropagation();
    }
  }

  setValue() {
    const changes = {
      type: this.decoratorType,
      value: this.isActive,
    };
    this.valueChange.emit(changes);
  }
}
