<div class="flex items-center gap-1">
  <div class="flex items-center" *ngIf="entitySnapshot">
    <button
      class="flex items-center justify-center w-10 h-10 min-w-0 text-black60 px-3.5 py-1.5"
      (click)="clearSnapshot()"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="text-lg font-medium p-1.5">{{ entitySnapshot?.createdOn | date: 'medium' }}</div>
    <div class="ml-5">
      <button data-test="snapshot-version" color="primary" disabled="true" mat-stroked-button>
        V{{ entitySnapshot.versionNumber || ' ' + (entitySnapshot.createdOn | date: 'short') }}
      </button>
    </div>
    <div class="ml-5" *ngIf="isSnapshotRestoreAllowed">
      <button data-test="restore-snapshot-button" color="primary" mat-stroked-button (click)="restoreSnapshot()">
        RESTORE VERSION
      </button>
    </div>
  </div>

  <ng-container *ngIf="!entitySnapshot">
    <img
      (click)="goHome()"
      class="object-cover w-10 cursor-pointer"
      alt="Board Icon"
      src="assets/images/board_icon.svg"
    />
    <mat-icon svgIcon="app-separator"></mat-icon>
    <div class="flex items-center m-0 text-lg font-medium" *ngIf="!editName">
      <div>{{ currentBoard?.name }}</div>
      <mat-icon
        data-test="edit-board-button"
        class="ml-4 cursor-pointer text-black60"
        (click)="startEditName()"
        *ngIf="allowEdit"
        >edit</mat-icon
      >
    </div>
  </ng-container>

  <div class="flex items-center font-medium text-lg mx-auto -mt-6 mb-[-30px]" *ngIf="editName">
    <div>
      <mat-form-field>
        <input
          matInput
          #inputField
          [formControl]="name"
          id="inputField"
          (blur)="endEditName()"
          [size]="currentBoard.name.length"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-icon class="ml-[0.78rem] leading-8 cursor-pointer text-black60" (click)="editName = false">cancel</mat-icon>
    </div>
  </div>

  <app-menu name="" icon="settings" *ngIf="!entitySnapshot">
    <app-board-configuration [board]="currentBoard"></app-board-configuration>
  </app-menu>
  <app-menu
    name=""
    [icon]="currentBoard.sourceAssortment ? 'link' : 'link_off'"
    data-test="source-assortment-button"
    *ngIf="editorMode === 'EDIT'"
  >
    <app-source-assortment
      data-test="app-source-assortment"
      (assignSourceAssortment)="assignSourceAssortment($event)"
      (removeSourceAssortment)="removeSourceAssorment()"
      [sourceAssortment]="currentBoard.sourceAssortment"
      [workspaceId]="currentBoard.workspaceId"
    >
    </app-source-assortment>
  </app-menu>
  <app-document-history-header-button
    matTooltip="History"
    (openPanel)="showHistoryPanel($event)"
    *ngIf="editorMode === 'EDIT'"
  ></app-document-history-header-button>
</div>
