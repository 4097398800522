import { DocumentElement } from '@contrail/documents';
import { SVGDocument } from '@contrail/svg';
import { SVGDocumentElementFactory } from '@contrail/svg';

export class SVGPreviewContainer extends SVGDocument {
  public async addPreview(element: DocumentElement) {
    element.position = {
      x: 12,
      y: 12,
    };
    const svgDocumentElement = await SVGDocumentElementFactory.createSVGDocumentElement(
      element,
      this,
      this,
      this.getBackgroundGroup(),
      false,
    );
    if (svgDocumentElement) {
      await svgDocumentElement.renderContent();
      this.registerElement(svgDocumentElement);
      this.optimizeSize();
    }
  }

  private optimizeSize() {
    const bbox = this.getBackgroundGroup().node().getBBox();
    const size = {
      width: bbox.width,
      height: bbox.height,
    };
    this.setSize(size, size);
  }
}
