import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { documentHistoryReducers } from './document-history.reducers';
import { EffectsModule } from '@ngrx/effects';
import { DocumentHistoryEffects } from './document-history.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('documentHistory', documentHistoryReducers),
    EffectsModule.forFeature([DocumentHistoryEffects]),
  ],
})
export class DocumentHistoryStoreModule {}
