import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';

export class CanvasClipElement extends CanvasElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
    public drawToOffscreenCanvas = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public draw(ctx, { x, y, width, height }): { height: number; y: number } {
    return;
  }

  public clip(ctx, { x, y, width, height }) {
    ctx.save();
    this.transform(ctx, { x, y, width, height });
    ctx.beginPath();
    this.addPath(ctx, { x: -width * 0.5, y: -height * 0.5, width, height });
    ctx.closePath();
    ctx.restore();
    ctx.clip();
  }

  public drawClip(ctx, { x, y, width, height }): { height: number; y: number } {
    ctx.beginPath();
    this.addPath(ctx, { x: -width * 0.5, y: -height * 0.5, width, height });
    ctx.fillStyle = this.DEFAULT_BACKGROUND_COLOR;
    ctx.strokeStyle = this.DEFAULT_BORDER_COLOR;
    ctx.lineWidth = this.DEFAULT_BORDER_SIZE;
    ctx.lineCap = 'butt';
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    return;
  }
}
