import { Injectable } from '@angular/core';
import { API_VERSION, Entities, EntityReference, Request } from '@contrail/sdk';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { tap } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ObjectUtil, PromiseUtil } from '@contrail/util';
import { Assortment, AssortmentType } from './assortments-store/assortments.state';
import { PaginatedSearchResult } from '@common/entities/entities.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  static includeFamilyLevelItem = false;

  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.ITEM_CHOOSER_LEVEL_SELECTION)) {
            AssortmentsService.includeFamilyLevelItem = true;
          }
        }),
      )
      .subscribe();
  }

  public static async getAssortment(id: string) {
    let assortment: any;
    const params: any = {
      entityName: 'assortment',
      id,
      relations: ['assortmentItems', 'assortmentItems.item', 'assortmentItems.projectItem', 'workspace'],
      skipHydrateRelationships: true,
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    // used when assigning value to the component element for 'Project' and 'Assortment'.
    assortment.assortmentItems.forEach((assortmentItem) => {
      if (assortment.workspace?.workspaceType === 'PROJECT' && assortmentItem.projectItem) {
        assortmentItem.projectItem.project = assortment.workspace;
      }
      assortmentItem.assortment = { name: assortment.name, id: assortment.id };
    });
    return assortment;
  }

  public static async getAssortmentFamilyItems(assortment: any) {
    const clonedAssortment = ObjectUtil.cloneDeep(assortment);
    const familyProjectItemMap = new Map();
    if (AssortmentsService.includeFamilyLevelItem) {
      const ids = [];
      const familyLevelOnlyAssortmentItems = [];
      clonedAssortment.assortmentItems.forEach((assortmentItem) => {
        if (assortmentItem.item?.roles.includes('family')) {
          familyLevelOnlyAssortmentItems.push(assortmentItem);
        } else {
          if (assortmentItem.item?.itemFamilyId && !ids.includes(assortmentItem.item.itemFamilyId)) {
            familyProjectItemMap.set(
              assortmentItem.item.itemFamilyId,
              ObjectUtil.cloneDeep(assortmentItem.familyProjectItem),
            );
            ids.push(assortmentItem.item.itemFamilyId);
          }
        }
      });
      let familyLevelItems = [];
      if (ids.length > 0) {
        const entityReferences = [];
        for (let id of ids) {
          entityReferences.push(new EntityReference(`item:${id}`));
        }
        const entityMap: Map<string, any> = await new Entities().getAllReferences(entityReferences, [
          'item',
          'primaryViewable',
          'primaryViewable.mediumViewable',
          'primaryViewable.primaryFile',
        ]);
        familyLevelItems = Array.from(entityMap.values());
      }
      clonedAssortment.familyLevelItems = familyLevelItems.concat(familyLevelOnlyAssortmentItems);
      clonedAssortment.familyLevelItems.forEach((item) => {
        item.assortmentId = clonedAssortment.id;
        if (familyProjectItemMap.get(item.id)) {
          item.projectItem = familyProjectItemMap.get(item.id);
        }
      });
    }
    return clonedAssortment;
  }

  public async getAssortmentsInWorkspaceByTypes(workspaceId: string, types: AssortmentType[]): Promise<Assortment[]> {
    const assortmentRequests = types.map((type) => this.getAssortmentsInWorkspaceByType(workspaceId, type));
    const results = await Promise.all(assortmentRequests);
    const assortments = results.flat();
    return this.sortAssortmentsByName(assortments);
  }

  public async getAssortmentsInWorkspaceByType(workspaceId: string, type: AssortmentType): Promise<Assortment[]> {
    const criteria = { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false, assortmentType: type };

    const assortments = await this.fetchAllPaginatedResults<Assortment>((nextPageKey) =>
      this.getNonBackingAssortmentsPaginated({ criteria, nextPageKey }),
    );

    return this.sortAssortmentsByName(assortments);
  }

  public async getAllAssortmentsInWorkspace(
    workspaceId: string,
    options: {
      relations?: string[];
    },
  ): Promise<Assortment[]> {
    const { relations } = options;
    const criteria = { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false };

    const assortments = await this.fetchAllPaginatedResults<Assortment>((nextPageKey) =>
      this.getNonBackingAssortmentsPaginated({ criteria, relations, nextPageKey }),
    );

    return this.sortAssortmentsByName(assortments);
  }

  public async getNonBackingAssortmentsPaginated(options: {
    criteria: { [key: string]: any };
    relations?: string[];
    nextPageKey?: string;
  }): Promise<PaginatedSearchResult<Assortment>> {
    const { criteria, relations, nextPageKey } = options;

    const numberOfResultsPerPage = relations?.length ? 100 : 500;
    const data: PaginatedSearchResult<Assortment> = await new Entities().get({
      entityName: 'assortment',
      criteria,
      relations,
      take: numberOfResultsPerPage,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    const filteredResults = data.results?.length
      ? data.results
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .filter((a) => a?.assortmentType !== 'BACKING' && a?.assortmentType !== 'SHOWCASE')
          .filter((a) => a?.isTrashed !== true)
      : [];

    return { results: filteredResults, nextPageKey: data.nextPageKey };
  }

  public static async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }

  public async getAssortmentItemsCount(id: string): Promise<number> {
    // return new Entities().get({ entityName: 'assortment', id, relation: 'assortment-item', suffix: 'count' });
    return await Request.request(`/assortments/${id}/assortment-item/count`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  public async addItemsToAssortment(assortmentId, itemIds, changes?: any[]) {
    return await PromiseUtil.resolveInChunksAndLimit(itemIds, 1000, 10, async (itemIdsChunk) => {
      return await new Entities().create({
        entityName: 'assortment',
        id: assortmentId,
        relation: 'items',
        object: { itemIds: itemIdsChunk },
      });
    })
      .then(async (assortmentItems) => {
        assortmentItems = assortmentItems.filter((i) => i);
        // changes are backingAssortmentItem data that is stored in the undo stack for undo/redo.
        if (changes?.length > 0) {
          const actions: any[] = [];
          for (let ai of assortmentItems) {
            let change = changes.find((c) => c.itemId === ai.itemId);
            if (change) {
              change = { ...change, ...ai }; // this should only set the annotation-related properties
              delete change.familyProjectItem;
              delete change.item;
              delete change.projectItem;
              actions.push({ id: ai.id, changes: change });
            }
          }
          if (actions.length > 0) {
            assortmentItems = await this.updateItems(actions);
          }
        }
        return assortmentItems;
      })
      .catch((error) => {
        console.error('Could not add items to backing assortment', assortmentId, error);
        throw error;
      });
  }

  public async removeItemsFromAssortment(assortmentItemIds) {
    await new Entities().batchDelete({ entityName: 'assortment-item', ids: assortmentItemIds });
    return assortmentItemIds;
  }

  public async updateItems(changes) {
    return new Entities().batchUpdate({ entityName: 'assortment-item', objects: changes });
  }

  private sortAssortmentsByName(assortments: Assortment[]): Assortment[] {
    return assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
  }

  private async fetchAllPaginatedResults<T>(
    fetchPage: (nextPageKey?: string) => Promise<{ results: T[]; nextPageKey?: string }>,
  ): Promise<T[]> {
    const results: T[] = [];
    let nextPageKey: string | undefined;

    do {
      const { results: pageResults, nextPageKey: newNextPageKey } = await fetchPage(nextPageKey);
      results.push(...pageResults);
      nextPageKey = newNextPageKey;
    } while (nextPageKey);

    return results;
  }
}
