<button
  class="toggle-icon"
  [color]="getActiveColor()"
  matTooltip="Annotate"
  [matTooltipPosition]="tooltipPosition"
  [matMenuTriggerFor]="annotationMenu"
  mat-icon-button
  data-test="add-annotation"
>
  <mat-icon>flag</mat-icon>
</button>

<mat-menu #annotationMenu="matMenu" class="menu-row">
  <ng-template matMenuContent>
    <ng-container *ngFor="let menu of annotationOptions">
      <button
        *ngIf="!menu.disabled"
        [matTooltip]="menu.label"
        matTooltipShowDelay="1000"
        mat-menu-item
        (click)="setInteractionMode(menu.type)"
        [attr.data-test]="'add-' + menu.type"
      >
        <mat-icon class="icons material-icons-outlined {{ menu.class }}" [svgIcon]="menu.svgIcon">{{
          menu.icon
        }}</mat-icon>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
