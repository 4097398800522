import { CanvasDocument } from '../../canvas-document';
import { CanvasIframeElement } from './canvas-iframe-element';
import { IframelementContainer } from './iframe-element-container';

const icons = [
  { name: 'play', src: '/assets/images/play-circle.svg' },
  { name: 'iframe', src: '/assets/images/iframe.svg' },
  { name: 'board', src: '/assets/images/board_icon.svg' },
  { name: 'plan', src: '/assets/images/plan_icon.svg' },
  { name: 'showcase', src: '/assets/images/showcase_icon.svg' },
];

export class IframeElementHelper {
  iframeElementContainer: IframelementContainer;
  public static iconList = {};
  constructor(private canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT' || this.canvasDocument.mode === 'PRESENT') {
      this.iframeElementContainer = new IframelementContainer(this.canvasDocument);
    }
  }

  static {
    // Icons will be loaded once, and they can be used for all instances of CanvasDocument.
    // They will stay here even when user switches between showcases or board. No need to clear them.
    this.iconList = this.loadImages(icons, this.handleAllIconsLoaded);
  }

  private static loadImages(icons, onAllLoaded) {
    let i = 0,
      numLoading = icons.length;
    const onload = () => --numLoading === 0 && onAllLoaded();
    const loadedImages = {};
    while (i < icons.length) {
      const img = (loadedImages[icons[i].name] = document.createElement('img'));
      img.src = icons[i++].src;
      img.onload = onload;
    }
    return loadedImages;
  }

  private static handleAllIconsLoaded() {
    //console.log(icons)
  }

  showIframe(element: CanvasIframeElement) {
    this.iframeElementContainer?.showIframe(element);
  }

  hideIframe() {
    this.iframeElementContainer?.hideIframe();
  }

  getIframeElement() {
    return this.iframeElementContainer?.getIframeElement();
  }

  /**
   * All detached HTML elements need to be cleaned up manually
   * so they are not kept in memory
   */
  public clear() {
    this.iframeElementContainer?.clear();
  }
}
