import { createAction, props } from '@ngrx/store';
import { FrameTemplate } from '../frame-template';

export enum FrameTemplatesActionTypes {
  LOAD_FRAME_TEMPLATES = '[FrameTemplates] Load Frame Templates',
  LOAD_FRAME_TEMPLATES_SUCCESS = '[FrameTemplates] Load Frame Templates Success',
  LOAD_FRAME_TEMPLATES_FAILURE = '[FrameTemplates] Load Frame Templates Failure',

  LOAD_FRAME_TEMPLATE = '[FrameTemplates] Load Frame Template',
  LOAD_FRAME_TEMPLATE_SUCCESS = '[FrameTemplates] Load Frame Template Success',
  LOAD_FRAME_TEMPLATE_FAILURE = '[FrameTemplates] Load Frame Template Failure',

  CREATE_FRAME_TEMPLATE = '[FrameTemplates] Create Frame Template',
  CREATE_FRAME_TEMPLATE_SUCCESS = '[FrameTemplates] Create Frame Template Success',
  CREATE_FRAME_TEMPLATE_FAILURE = '[FrameTemplates] Create Frame Template Failure',

  UPDATE_FRAME_TEMPLATE = '[FrameTemplates] Update Frame Template',
  UPDATE_FRAME_TEMPLATE_SUCCESS = '[FrameTemplates] Update Frame Template Success',
  UPDATE_FRAME_TEMPLATE_FAILURE = '[FrameTemplates] Update Frame Template Failure',

  DELETE_FRAME_TEMPLATE = '[FrameTemplates] Delete Frame Template',
  DELETE_FRAME_TEMPLATE_SUCCESS = '[FrameTemplates] Delete Frame Template Success',
  DELETE_FRAME_TEMPLATE_FAILURE = '[FrameTemplates] Delete Frame Template Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadFrameTemplates = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATES,
  props<{ templateTypes: string[] }>(),
);

export const loadFrameTemplatesSuccess = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATES_SUCCESS,
  props<{ data: Array<FrameTemplate> }>(),
);

export const loadFrameTemplatesFailure = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATES_FAILURE,
  props<{ error: any }>(),
);

export const loadFrameTemplate = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATE,
  props<{ frameTemplateId: string }>(),
);

export const loadFrameTemplateSuccess = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATE_SUCCESS,
  props<{ id: string; changes: any }>(),
);

export const loadFrameTemplateFailure = createAction(
  FrameTemplatesActionTypes.LOAD_FRAME_TEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const createFrameTemplate = createAction(
  FrameTemplatesActionTypes.CREATE_FRAME_TEMPLATE,
  props<{ frameTemplate: any }>(),
);

export const createFrameTemplateSuccess = createAction(
  FrameTemplatesActionTypes.CREATE_FRAME_TEMPLATE_SUCCESS,
  props<{ frameTemplate: any }>(),
);

export const createFrameTemplateFailure = createAction(
  FrameTemplatesActionTypes.CREATE_FRAME_TEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const updateFrameTemplate = createAction(
  FrameTemplatesActionTypes.UPDATE_FRAME_TEMPLATE,
  props<{ id: string; changes: any }>(),
);

export const updateFrameTemplateSuccess = createAction(
  FrameTemplatesActionTypes.UPDATE_FRAME_TEMPLATE_SUCCESS,
  props<{ id: string; changes: any }>(),
);

export const updateFrameTemplateFailure = createAction(
  FrameTemplatesActionTypes.UPDATE_FRAME_TEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const deleteFrameTemplate = createAction(
  FrameTemplatesActionTypes.DELETE_FRAME_TEMPLATE,
  props<{ frameTemplate: any }>(),
);

export const deleteFrameTemplateSuccess = createAction(
  FrameTemplatesActionTypes.DELETE_FRAME_TEMPLATE_SUCCESS,
  props<{ frameTemplate: any }>(),
);

export const deleteFrameTemplateFailure = createAction(
  FrameTemplatesActionTypes.DELETE_FRAME_TEMPLATE_FAILURE,
  props<{ error: any }>(),
);
