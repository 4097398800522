import { CanvasDocument } from './canvas/canvas-document';
import { CanvasUtil } from './canvas/canvas-util';
import { ZoomPanHandler } from './zoom-pan-handler';

export class TouchEventsHandler {
  private lastX;
  private lastY;
  private lastCenter;
  private lastDist = 0;
  private initDist = 0;

  constructor(
    private canvasDocument: CanvasDocument,
    private zoomPanHandler: ZoomPanHandler,
  ) {}

  public onTouchStart(event) {
    if (event.target.nodeName !== 'CANVAS') {
      return;
    }
    event.preventDefault();
  }

  public onTouchMove(event) {
    if (event.target.nodeName !== 'CANVAS') {
      return;
    }
    event.preventDefault();

    const interactionMode = this.canvasDocument.getInteractionMode();
    if (
      event.touches.length === 1 &&
      event.touches[0].touchType === 'direct' &&
      ['pen', 'highlighter', 'eraser'].indexOf(interactionMode) === -1
    ) {
      const touch1 = event.touches[0];
      if (this.lastX && this.lastY) {
        const dx = touch1.clientX - this.lastX;
        const dy = touch1.clientY - this.lastY;
        this.zoomPanHandler.pan(-dx * this.zoomPanHandler.zoomFactor, -dy * this.zoomPanHandler.zoomFactor);
      }
      this.lastX = touch1.clientX;
      this.lastY = touch1.clientY;
    } else if (event.touches.length > 1 && event.touches[0].touchType === 'direct') {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];

      if (touch1 && touch2) {
        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
          this.lastCenter = CanvasUtil.getCenter(p1, p2);
          return;
        }
        const newCenter = CanvasUtil.getCenter(p1, p2);
        const dist = CanvasUtil.distance(p1.x, p1.y, p2.x, p2.y);

        if (!this.lastDist) {
          this.lastDist = dist;
          this.initDist = dist;
        }

        if (Math.abs(this.initDist - dist) > 20) {
          const scale = this.zoomPanHandler.zoomFactor / (dist / this.lastDist);
          this.zoomPanHandler.setZoomFactor(scale);
        }

        const dx = newCenter.x - this.lastCenter.x;
        const dy = newCenter.y - this.lastCenter.y;
        this.zoomPanHandler.updateViewPort(
          {
            x: newCenter.x,
            y: newCenter.y,
          },
          -dx * this.zoomPanHandler.zoomFactor,
          -dy * this.zoomPanHandler.zoomFactor,
        );

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }
    }
  }

  public onTouchEnd(event) {
    if (event.target.nodeName !== 'CANVAS') {
      return;
    }
    event.preventDefault();
    this.lastX = null;
    this.lastY = null;
    this.lastCenter = null;
    this.lastDist = 0;
    this.initDist = 0;
  }
}
