import { createAction, props } from '@ngrx/store';
import { ClipboardItem } from '../../clipboard.interfaces';

export enum ClipboardItemsActionTypes {
  LOAD_CLIPBOARD_ITEMS = '[Clipboard Items] Load Clipboard Items',
  LOAD_CLIPBOARD_ITEMS_SUCCESS = '[Clipboard Items] Load Clipboard Items Success',
  LOAD_CLIPBOARD_ITEMS_FAILURE = '[Clipboard Items] Load Clipboard Items Failure',

  ADD_ITEMS_TO_CLIPBOARD = '[Clipboard Items] Add Items to Clipboard',
  ADD_ITEMS_TO_CLIPBOARD_SUCCESS = '[Clipboard Items] Add Items to Clipboard Success',
  ADD_ITEMS_TO_CLIPBOARD_FAILURE = '[Clipboard Items] Add Items to Clipboard Failure',

  REMOVE_ITEMS_FROM_CLIPBOARD = '[Clipboard Items] Remove Items from Clipboard',
  REMOVE_ITEMS_FROM_CLIPBOARD_SUCCESS = '[Clipboard Items] Remove Items from Clipboard Success',
  REMOVE_ITEMS_FROM_CLIPBOARD_FAILURE = '[Clipboard Items] Remove Items from Clipboard Failure',
}

export const loadClipboardItems = createAction(ClipboardItemsActionTypes.LOAD_CLIPBOARD_ITEMS);

export const loadClipboardItemsSuccess = createAction(
  ClipboardItemsActionTypes.LOAD_CLIPBOARD_ITEMS_SUCCESS,
  props<{ clipboardItems: ClipboardItem[] }>(),
);

export const loadClipboardItemsFailure = createAction(
  ClipboardItemsActionTypes.LOAD_CLIPBOARD_ITEMS_FAILURE,
  props<{ error: any }>(),
);

export const addItemsToClipboard = createAction(
  ClipboardItemsActionTypes.ADD_ITEMS_TO_CLIPBOARD,
  props<{ clipboardItems: { itemId: string; projectItemId: string }[] }>(),
);

export const addItemsToClipboardSuccess = createAction(
  ClipboardItemsActionTypes.ADD_ITEMS_TO_CLIPBOARD_SUCCESS,
  props<{ clipboardItems: ClipboardItem[] }>(),
);

export const addItemsToClipboardFailure = createAction(
  ClipboardItemsActionTypes.ADD_ITEMS_TO_CLIPBOARD_FAILURE,
  props<{ error: any }>(),
);

export const removeItemsFromClipboard = createAction(
  ClipboardItemsActionTypes.REMOVE_ITEMS_FROM_CLIPBOARD,
  props<{ ids: Array<string> }>(),
);

export const removeItemsFromClipboardSuccess = createAction(
  ClipboardItemsActionTypes.REMOVE_ITEMS_FROM_CLIPBOARD_SUCCESS,
  props<{ ids: Array<string> }>(),
);

export const removeItemsFromClipboardFailure = createAction(
  ClipboardItemsActionTypes.REMOVE_ITEMS_FROM_CLIPBOARD_FAILURE,
  props<{ error: any }>(),
);
