import * as DocumentActions from './document.actions';
import * as DocumentSelectors from './document.selectors';
import * as DocumentStoreState from './document.state';
import * as DocumentModelEntitiesActions from '../document-item/document-model-entities/document-model-entities.actions';
import * as DocumentModelEntitiesSelectors from '../document-item/document-model-entities/document-model-entities.selectors';

export { DocumentStoreModule } from './document-store.module';

const actions = {
  ...DocumentActions,
  ...DocumentModelEntitiesActions,
};
const selectors = {
  ...DocumentSelectors,
  ...DocumentModelEntitiesSelectors,
};

export { actions as DocumentActions, selectors as DocumentSelectors, DocumentStoreState };
