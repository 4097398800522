import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SortDefinition } from '../sort-definition';

@Component({
  selector: 'app-sort-option',
  templateUrl: './sort-option.component.html',
  styleUrls: ['./sort-option.component.scss'],
})
export class SortOptionComponent implements OnInit {
  @Input() properties: Array<SortDefinition>;
  @Input() sort: SortDefinition;
  @Input() sortIndex: number;
  @Output() removeSort = new EventEmitter();
  @Output() applySort = new EventEmitter();
  @ViewChild('autoCompleteField') autoCompleteField: ElementRef;

  formControl = new UntypedFormControl();
  filteredProperties: Observable<SortDefinition[]>;

  constructor() {}

  ngOnInit(): void {
    this.filteredProperties = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.propertyLabel)),
      map((propertyLabel) =>
        propertyLabel && propertyLabel !== '' ? this._filter(propertyLabel) : this.properties.slice(),
      ),
    );
    console.log(this.sort, this.properties);
    if (this.sort?.propertySlug) {
      // sets the selected property from the column header menu
      const currentSort = ObjectUtil.cloneDeep(this.sort);
      currentSort.propertyLabel = this.properties.find(
        (property) => property.propertySlug === this.sort.propertySlug,
      ).propertyLabel;
      this.formControl.setValue(currentSort);
    }
  }

  remove() {
    this.removeSort.emit(this.sortIndex);
  }

  setSortProperty(event, sort) {
    const currentSort = ObjectUtil.cloneDeep(sort);
    Object.assign(sort, event.option.value);
    sort.direction = currentSort.direction;
    this.applySort.emit(false);
  }

  displayFn(property: SortDefinition): string {
    return property && property.propertyLabel ? property.propertyLabel : '';
  }

  private _filter(propertyLabel: string): SortDefinition[] {
    const filterValue = propertyLabel.toLowerCase();
    return this.properties.filter((property) => property.propertyLabel.toLowerCase().includes(filterValue));
  }

  clearAutoCompleteValue() {
    this.formControl.setValue('');
    setTimeout(() => {
      this.autoCompleteField.nativeElement.blur();
      this.autoCompleteField.nativeElement.focus();
    }, 100);
  }

  apply() {
    this.applySort.emit(false);
  }
}
