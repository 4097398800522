<div class="w-full h-full flex flex-col" data-test="dynamic-text-editor">
  <div class="header !mb-0">
    <div class="title">Dynamic text</div>
    <mat-icon (click)="hide()">close</mat-icon>
  </div>
  <div class="p-4">
    <mat-form-field appearance="fill" class="!w-full mb-4">
      <mat-label>Data source</mat-label>
      <mat-select class="!w-full" data-test="dynamic-text-entity-type" [formControl]="entityTypeFormControl">
        <mat-option></mat-option>
        <mat-option *ngFor="let v of selectableEntityTypes" [value]="v.slug">{{ v.label }}</mat-option>
      </mat-select>
      <mat-hint>Select data source for dynamic text</mat-hint>
    </mat-form-field>
    <app-autocomplete
      data-test="dynamic-text-property"
      [value]="selectedProperty"
      [label]="'Property'"
      [isRequired]="true"
      [isDisabled]="entityTypeFormControl.value === null"
      [options]="bindingProperties"
      (valueSelected)="selectProperty($event)"
      [displayProperty]="'label'"
      [hint]="'Choose the property you want to display'"
    >
    </app-autocomplete>
    <div class="mt-5 mb-5">
      <mat-form-field appearance="fill" class="!w-full">
        <mat-label>Display option</mat-label>
        <mat-select
          class="!w-full"
          placeholder="Display option"
          data-test="dynamic-text-display-function"
          [formControl]="displayFunctionFormControl"
          (selectionChange)="selectDisplayFunction($event)"
        >
          <mat-option></mat-option>
          <mat-option *ngFor="let v of displayFunctions" [value]="v.value">{{ v.label }}</mat-option>
        </mat-select>
        <mat-hint>{{ displayFunctionHint }}</mat-hint>
      </mat-form-field>
    </div>
    <div class="w-full">
      <mat-slide-toggle
        *ngIf="displayFunctionFormControl.value !== 'label'"
        class="fill-image-toggle text-black60"
        color="primary"
        labelPosition="before"
        [formControl]="displayLabelFormControl"
        (change)="toggleDisplayLabel($event)"
        data-test="dynamic-text-display-label"
      >
        Display label
      </mat-slide-toggle>
    </div>

    <div class="flex justify-between mt-4 items-center">
      <div class="text-black60" [ngClass]="selectedProperty ? 'opacity-100' : 'opacity-[0.38]'" class="text-black60">
        Text mode
      </div>
      <div class="text-tool-type-container">
        <mat-button-toggle-group
          color="primary"
          [value]="textToolType"
          (change)="toggleTextToolType($event)"
          [disabled]="!selectedProperty"
        >
          <mat-button-toggle value="text-tool" data-test="dynamic-text-text-tool" matTooltip="Text tool">
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="app-text-tool-create"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="text-box" data-test="dynamic-text-text-box" matTooltip="Text box">
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="app-text-box-create"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</div>
