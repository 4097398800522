import { DocumentElement } from '@contrail/documents';
import { TextStyle } from '../text/text-style';
import { TextMetrics } from '../text/text-metrics';
import { CanvasElement } from '../canvas-element';

export class TextMeasureService {
  public static getRenderedTextMetrics(canvasElement: CanvasElement, width: number, context?): TextMetrics {
    if (document || context) {
      let ctx = context;
      if (!ctx) {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        ctx = canvas.getContext('2d');
      }

      const textStyle = new TextStyle(canvasElement);
      const fontString = textStyle.toFontString();

      // It's important to set font here before we measure text because font gets
      // taken into account when measuring.
      ctx.font = fontString;
      ctx.fillStyle = canvasElement?.elementDefinition?.style?.color || '#000';

      const text = TextMeasureService.getText(canvasElement?.elementDefinition);
      const lines = TextMetrics.wrapLabel(ctx, text, width, fontString);
      const textMetrics = TextMetrics.measureText(ctx, text, lines, width);

      return textMetrics;
    }
  }

  public static getText(element: DocumentElement) {
    let text = element.text;
    if (text === undefined || text === null) {
      text = '';
    }
    const label = element?.label ? `${element.label}:` : null;
    return label ? `${label} ${text}` : `${text}`;
  }
}
