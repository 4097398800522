import { Component, Input, OnChanges, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { WebGLViewer } from './webgl-viewer';

@Component({
  selector: 'app-webgl-viewer',
  templateUrl: './webgl-viewer.component.html',
  styleUrls: ['./webgl-viewer.component.scss'],
})
export class WebglViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() content: any; // VibeIQ content entity
  @Input() size = '100%';
  @Input() allowScreenCapture = false;
  @Output() screenShots = new EventEmitter();
  viewerLoading: boolean = true;
  canvasId = 'webgl-viewer-canvas';
  constructor() {}

  public viewer: WebGLViewer;

  ngOnInit(): void {
    this.viewer = new WebGLViewer();
    this.canvasId = 'webgl-viewer-canvas_' + this.content?.id;

    setTimeout(() => {
      const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
      this.viewer?.setupScene(canvas);
      this.viewer?.setupAsset(this.content.primaryFile.downloadUrl);
    }, 300);
    const engine = this.viewer?.engine;
    setTimeout(() => {
      engine?.resize();
    }, 10);

    this.viewer.onAssetLoaded.add(() => {
      this.viewerLoading = false;
    });
  }

  ngOnChanges(): void {
    // Don't call this if viewer doesn't exist, it will be called in OnInit
    if (this.viewer && this.viewer.scene) {
      this.viewer.setupAsset(this.content.primaryFile.downloadUrl);
    }
  }

  ngOnDestroy(): void {
    this.viewer?.engine?.dispose();
  }

  reset() {
    this.viewer.resetAssetTransform();
  }

  startRotate() {
    this.viewer.currentRotateControl = 1;
  }

  stopRotate() {
    this.viewer.currentRotateControl = 0;
  }

  startZoomIn() {
    this.viewer.currentZoomControl = -7;
  }

  startZoomOut() {
    this.viewer.currentZoomControl = 7;
  }

  stopZoom() {
    this.viewer.currentZoomControl = 0;
  }

  recolorAsset(colorHex: string): void {
    this.viewer.recolorAsset(colorHex);
  }

  async takeScreen() {
    return await this.viewer.takeScreenshot();
  }

  saveScreen() {
    this.viewer.saveScreenshot();
  }

  async exportScreenShot() {
    const screenShot = await this.viewer.takeScreenshot();
    this.screenShots.emit(screenShot);
  }
}
