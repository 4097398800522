import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { SharedLinkDefinition, ShareLink } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class SharedLinkService {
  constructor() {}

  createSharedLink(entity: SharedLinkDefinition): Promise<ShareLink> {
    return new Entities().create({
      entityName: 'shared-link',
      object: { contextType: entity?.contextType, contextId: entity?.contextId },
    });
  }

  getSharedLinks(entity: SharedLinkDefinition): Promise<ShareLink[]> {
    return new Entities().get({
      entityName: 'shared-link',
      criteria: { contextReference: `${entity?.contextType}:${entity?.contextId}` },
      relations: ['principal'],
    });
  }

  updateShareLinkAccessLevel(link, accessLevel): Promise<ShareLink> {
    return new Entities().update({ entityName: 'shared-link', id: link?.id, object: { accessLevel } });
  }

  deleteShareLink(id: string) {
    return new Entities().delete({ entityName: 'shared-link', id });
  }
}
