import { Component, Input, OnChanges } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type, TypeProperty } from '@contrail/types';
import { ItemHistoryEvent } from '../item-history-event';

interface ChangeSummary {
  typeProperty: TypeProperty;
  oldValue: any;
  newValue: any;
}

@Component({
  selector: 'app-source-change-history-event',
  templateUrl: './source-change-history-event.component.html',
  styleUrls: ['./source-change-history-event.component.scss'],
})
export class SourceChangeHistoryEventComponent implements OnChanges {
  @Input() historyEvent: ItemHistoryEvent;
  public changes: Array<ChangeSummary>;

  constructor() {}

  async ngOnChanges() {
    if (!this.historyEvent?.changes || this.historyEvent.changes?.smallViewableDownloadUrl) {
      return;
    }
    this.changes = [];

    let type: Type;
    if (this.historyEvent.changeType === 'item') {
      type = await new Types().getByRootAndPath({ root: 'item', path: 'item' });
    } else if (this.historyEvent.changeType === 'project-item') {
      type = await new Types().getByRootAndPath({ root: 'project-item', path: 'project-item' });
    }
    console.log('changes: ', Object.values(this.historyEvent.changes));
    for (let change of Object.values(this.historyEvent.changes)) {
      if (['updatedOn', 'updatedById'].includes(change['propertyName'])) {
        return;
      }
      let property = type.typeProperties.find((p) => p.slug === change['propertyName']);
      this.changes.push({
        typeProperty: property,
        oldValue: change['oldValue'],
        newValue: change['newValue'],
      });
    }
  }
}
