export class ImageElement {
  public static DEFAULT_SCALE = 2;
  public img: HTMLImageElement = null;
  public canvasImg: HTMLImageElement | HTMLCanvasElement = null;

  constructor(img, canvasImg?) {
    this.img = img;
    if (canvasImg) {
      this.canvasImg = canvasImg;
    }
  }

  public static fromURL(url: string, revoke = true): Promise<ImageElement> {
    return new Promise(async (resolve, reject) => {
      const img = document.createElement('img');
      img.onload = () => {
        // do not revoke if url is local blob - it might be needed for cropping immediatelly after upload
        if (revoke) {
          URL.revokeObjectURL(url);
        }
        resolve(new ImageElement(img));
      };
      img.onerror = (error) => {
        reject(error);
      };
      // img.crossOrigin = 'Anonymous';
      img.src = url;
    });
  }

  /**
   * Creates ImageElement that consits of:
   * 1. HTMLImageElement created from @url
   * 2. HTMLImageElement created from in-memory canvas that has first HTMLImageElement
   * drawn on it and which was converted to blob.
   * This method removes reference to in-memory canvas and instead creates a blob
   * image which seem that take less space in browser memory.
   * @param url
   * @param type
   * @returns
   */
  public static toCanvasBlobImage(url: string, type, quality, scale, revoke): Promise<ImageElement> {
    return ImageElement.fromURL(url, revoke).then((image) => {
      return this.toCanvasBlob(image, type, quality, scale);
    });
  }

  public static toCanvasBlobObject(url: string, type, quality, scale, revoke): Promise<Blob> {
    return ImageElement.fromURL(url, revoke).then((image) => {
      return this.getCanvasBlob(image, type, quality, scale);
    });
  }

  public static async toCanvasDataUrl(url: string, type, quality, scale, revoke): Promise<string> {
    const image = await ImageElement.fromURL(url, revoke);
    return this.toCanvasDataUrlFromImageElement(image, type, quality, scale);
  }

  public static async toCanvasDataUrlFromImageElement(image: ImageElement, type, quality, scale): Promise<string> {
    let canvas = this.drawToCanvas(image, scale);
    const base64 = canvas.toDataURL(type, quality);
    canvas.width = canvas.height = 0;
    canvas = null;
    return base64;
  }

  /**
   * Create ImageElement that consists of:
   * 1. HTMLImageElement created from @url
   * 2. HTMLCanvasElement that has first HTMLImageElement drawn on it and that was created in memory
   * Reference to created in-memory canvas is kept so it can be drawn on the
   * original canvas
   * @param url
   * @returns
   */
  public static toCanvas(url: string, scale = ImageElement.DEFAULT_SCALE): Promise<ImageElement> {
    return ImageElement.fromURL(url).then((image) => {
      const canvas = this.drawToCanvas(image, scale);
      if (canvas) {
        image.canvasImg = canvas;
      }
      return image;
    });
  }

  /**
   * Draw @image onto in-memory canvas;
   * @param image
   * @returns
   */
  private static drawToCanvas(image: ImageElement, scale = ImageElement.DEFAULT_SCALE): HTMLCanvasElement {
    try {
      let canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = image.img;
      const width = img.width;
      const height = img.height;
      canvas.width = width * scale;
      canvas.height = height * scale;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      /*
      When rendering SVG to the canvas you should turn off image smoothing as 
      that can reduce the quality of the svg image.
      SVG is rendered internally and does not need additional smoothing when the internal copy is rendered onto the canvas.
      */
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(img, 0, 0, width * scale, height * scale);
      return canvas;
    } catch (e) {
      console.log('Error drawing image onto in-memory canvas', e);
      return null;
    }
  }

  /**
   * Creates an HTMLCanvasElement @canvas from HTMLImageElement stored
   * in @image . Converts created canvas element to blob and sets it
   * to given @image as canvasImg. Created canvas is removed from memory
   * by setting width = height = 0 to avoid memory overload.
   * @param image
   * @returns
   */
  private static toCanvasBlob(image: ImageElement, type = 'image/png', quality = 1, scale): Promise<ImageElement> {
    return new Promise(async (resolve, reject) => {
      const canvas = this.drawToCanvas(image, scale);
      canvas.toBlob(
        (blob) => {
          canvas.width = canvas.height = 0;
          ImageElement.fromURL(URL.createObjectURL(blob)).then((canvasImage) => {
            image.canvasImg = canvasImage.img;
            resolve(image);
          });
        },
        type,
        quality,
      );
    });
  }

  private static getCanvasBlob(image: ImageElement, type = 'image/png', quality = 1, scale): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      const canvas = this.drawToCanvas(image, scale);
      canvas.toBlob(
        (blob) => {
          canvas.width = canvas.height = 0;
          resolve(blob);
        },
        type,
        quality,
      );
    });
  }
}
