<app-modal [dialogRef]="dialogRef">
  <app-document-generator-source-selector
    *ngIf="!loading"
    #sourceSelector
    class="-mt-[72px]"
    [currentWorkspaceId]="currentWorkspaceId"
    (selectSource)="handleSelectSource($event)"
    (changeSource)="setAssortment(null)"
  ></app-document-generator-source-selector>

  <!-- ACTIONS / NAVIGATION -->
  <div class="actions mt-3 px-[10px]">
    <div class="flex">
      <div class="count" matTooltip="Frame count" matTooltipPosition="above" data-test="create-lineboard-frame-count">
        <mat-icon svgIcon="app-frame-icon"></mat-icon>
        {{ frameCount }}
      </div>
      <div
        class="count ml-2"
        matTooltip="Item count"
        matTooltipPosition="above"
        data-test="create-lineboard-item-count"
      >
        <mat-icon svgIcon="tag"></mat-icon>
        {{ itemCount }}
      </div>
    </div>
    <div class="flex gap-2">
      <button mat-stroked-button type="button" (click)="cancel()">CANCEL</button>

      <button
        *ngIf="isSourceSelectorNext()"
        mat-flat-button
        color="primary"
        (click)="sourceSelector.nextStep()"
        [disabled]="sourceSelector.nextInValid()"
      >
        NEXT
      </button>

      <button
        *ngIf="!isSourceSelectorNext()"
        mat-flat-button
        color="primary"
        data-test="create-lineboard-next-button"
        (click)="done()"
        [disabled]="!isValid()"
      >
        {{ !loadingAssortmentItems ? 'OK' : '' }}
        <div *ngIf="loadingAssortmentItems" class="w-[40px] flex justify-center">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
      </button>
    </div>
  </div>
</app-modal>
