import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { MainModule } from './main/main.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BoardsModule } from './boards/boards.module';
import { RootStoreModule } from './root-store';
import { ItemsModule } from '@common/items/items.module';
import { storeDevTools } from './build-specifics';
import { AnalyticsModule } from '@common/analytics/analytics.module';
import { CanDeactivateGuard } from '@common/can-deactivate/can-deactivate.guard';
import { AppExtensionsModule } from '@common/app-extensions/app-extensions.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MainModule,
    MatIconModule,
    MatProgressSpinnerModule,
    BoardsModule,
    ItemsModule,
    RootStoreModule,
    AnalyticsModule,
    AppExtensionsModule,
    //storeDevTools,
  ],
  providers: [CanDeactivateGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
