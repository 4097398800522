const DELETE_KEY = 'Delete';
const BACKSPACE_KEY = 'Backspace';
const ESCAPE_KEY = 'Escape';
const SPACE_KEY = 'Space';
const ALT_LEFT_KEY = 'AltLeft';
const ALT_RIGHT_KEY = 'AltRight';
const SHIFT_LEFT_KEY = 'ShiftLeft';
const SHIFT_RIGHT_KEY = 'ShiftRight';
const AKEY = 'KeyA';
const BKEY = 'KeyB';
const CKEY = 'KeyC';
const DKEY = 'KeyD';
const FKEY = 'KeyF';
const GKEY = 'KeyG';
const IKEY = 'KeyI';
const KKey = 'KeyK';
const LKey = 'KeyL';
const MKey = 'KeyM';
const OKey = 'KeyO';
const PKey = 'KeyP';
const SKey = 'KeyS';
const TKey = 'KeyT';
const UKEY = 'KeyU';
const VKEY = 'KeyV';
const XKEY = 'KeyX';
const YKEY = 'KeyY';
const ZKEY = 'KeyZ';
const UP_KEY = 'ArrowUp';
const DOWN_KEY = 'ArrowDown';
const LEFT_KEY = 'ArrowLeft';
const RIGHT_KEY = 'ArrowRight';
const EQUAL = 'Equal';
const MINUS = 'Minus';
const ADD = 'NumpadAdd';
const SUBTRACT = 'NumpadSubtract';
const PgUp_KEY = 'PageUp';
const PgDn_KEY = 'PageDown';
const ENTER_KEY = 'Enter';

const KEY_EVENT_IGNORE_PATHS = [
  'app-property-configurator-color-picker',
  'app-comment-overlay',
  'app-item-data-chooser',
  'app-item-details-modal',
  'textarea',
  'input',
  'mat-dialog-container',
];

import { Injectable } from '@angular/core';
import { Action, ActionRequest } from '@contrail/actions';
import { DocumentService } from '../document/document.service';
import { UndoRedoHandler } from './undo-redo-handler';
import { AddPinnedCommentsService } from '../board-pinned-comments/add-pinned-comments-service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { SearchReplaceActions } from '@common/search-replace/search-replace-store';
import { BoardService } from '../board.service';
import { BoardFrameService } from '../board-frames-list/board-frames.service';
import { DevToolsService } from '../board-dev-tools/board-dev-tools.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { tap } from 'rxjs';
import { DocumentItemInspectorService } from '../document/document-item-inspector/document-item-inspector.service';

@Injectable({
  providedIn: 'root',
})
export class KeyboardHandler {
  public editorMode: EditorMode;
  public isLoading: boolean;
  itemCreationFeatureActive = false;
  assignItemToComponentActive = false;
  constructor(
    private documentService: DocumentService,
    private documentItemInspectorService: DocumentItemInspectorService,
    private boardService: BoardService,
    private undoRedoHandler: UndoRedoHandler,
    private devToolsService: DevToolsService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private boardFrameService: BoardFrameService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m));
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.ITEM_CREATION) && !this.boardService.isSharedLinkUser) {
            this.itemCreationFeatureActive = true;
          }
          if (flags.map((x) => x.featureName).includes(Feature.ASSIGN_ITEM_TO_COMPONENT)) {
            this.assignItemToComponentActive = true;
          }
        }),
      )
      .subscribe();

    this.init();
  }
  private init() {
    document.onkeydown = this.handleKeyDown.bind(this);
    document.onkeyup = this.handleKeyUp.bind(this);
  }

  handleKeyUp(event: KeyboardEvent) {
    if (!this.isEventAllowed(event)) {
      return;
    }
    switch (event.code) {
      case SPACE_KEY:
        this.boardService?.zoomPanHandler?.stopReadyToGrabMode(event);
        event.preventDefault();
        break;
      case SHIFT_LEFT_KEY:
      case SHIFT_RIGHT_KEY:
        this.documentItemInspectorService.hideTempItemInspector();
        break;
      case ZKEY: {
        if (
          this.documentService.getInteractionMode() === 'zoom_in' &&
          !event.ctrlKey &&
          !event.shiftKey &&
          !event.metaKey
        ) {
          this.documentService.setInteractionMode('select');
        }
        break;
      }
      default:
        break;
    }
  }

  handleKeyDown(event) {
    if (!this.isEventAllowed(event)) {
      return;
    }
    // console.log('KeyboardHandler: handleKeyDown: ', event);
    switch (event.code) {
      case BACKSPACE_KEY:
      case DELETE_KEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        this.documentService.handleActionRequest(new ActionRequest('delete_element'));
        break;
      }
      case ALT_LEFT_KEY:
      case ALT_RIGHT_KEY:
        this.boardService?.zoomPanHandler?.stopGrabbingMode(event);
        break;
      case ESCAPE_KEY:
        this.documentService.cancelCrop();
        if (this.documentService.getSelectedElements()?.length > 0) {
          this.documentService.deselectAllElements();
        }
        this.documentService.setInteractionMode('select');
        this.boardService?.zoomPanHandler?.handleMouseUp(event);
        break;
      case SPACE_KEY:
        if (!event.repeat) {
          this.boardService?.zoomPanHandler?.startReadyToGrabMode(event);
          event.preventDefault();
        }
        break;
      case SHIFT_LEFT_KEY:
      case SHIFT_RIGHT_KEY:
        this.documentItemInspectorService.showTempItemInspector();
        break;
      case AKEY:
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.selectAllElements();
          this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
        } else if (this.assignItemToComponentActive) {
          this.documentService.setInteractionMode('assign_item');
        }
        break;
      case BKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'bold' });
        }
        break;
      }
      case CKEY: {
        if (event.ctrlKey || event.metaKey) {
          if (event.altKey) {
            event.preventDefault();
            this.documentService.handleActionRequest(new ActionRequest('copy_properties'));
          } else {
            this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
          }
        }
        break;
      }
      case DKEY: {
        if ((event.ctrlKey || event.metaKey) && event.shiftKey) {
          this.devToolsService.toggleDevTools();
          event.preventDefault();
        } else if (event.ctrlKey || event.metaKey) {
          this.documentService.handleActionRequest(new ActionRequest('duplicate_elements', event));
          event.preventDefault();
        }
        break;
      }
      case FKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.submitCrop();
          this.store.dispatch(SearchReplaceActions.toggleSearch());
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      }
      case GKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.handleActionRequest(new ActionRequest('toggle_group_elements', event));
          event.preventDefault();
        }
        break;
      }
      case IKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'italic' });
        } else if (this.itemCreationFeatureActive) {
          this.documentService.setInteractionMode('new_item_family');
        }
        break;
      }
      case KKey: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('toggle_mask_elements'));
        }
        break;
      }
      case LKey: {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.submitCrop();
          this.documentService.handleActionRequest(new ActionRequest('lock_elements'));
        }
        break;
      }
      case MKey: // add comments
        if (this.editorMode === EditorMode.VIEW) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          this.addPinnedCommentsService.addCommentsFromKeyEvent();
          event.preventDefault();
        }
        break;
      case OKey: {
        if (this.editorMode === EditorMode.VIEW) {
          return;
        }
        if (this.itemCreationFeatureActive) {
          this.documentService.setInteractionMode('new_item_option');
        }
        break;
      }
      case PKey: {
        if (this.editorMode === EditorMode.VIEW) {
          return;
        }
        this.documentService.setInteractionMode('pen');
        break;
      }
      case SKey:
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        this.documentService.setInteractionMode('create_sticky_note');
        break;
      case TKey:
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        this.documentService.setInteractionMode('create_text_element');
        break;
      case UKEY: {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'underline' });
        }
        break;
      }
      case VKEY: {
        if (!event.ctrlKey && !event.metaKey) {
          if (this.documentService.getSelectedElements()?.length > 0) {
            this.documentService.deselectAllElements();
          }
          this.documentService.setInteractionMode('select');
          this.boardService?.zoomPanHandler?.handleMouseUp(event);
        } else if (event.ctrlKey || event.metaKey) {
          if (event.altKey) {
            this.documentService.handleActionRequest(new ActionRequest('paste_properties'));
          }
        }
        break;
      }
      case XKEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
          this.documentService.handleActionRequest(new ActionRequest('delete_element'));
        }
        break;
      }
      case YKEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.redoActions();
          event.preventDefault();
        }
        break;
      }
      case ZKEY: {
        if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
          this.documentService.setInteractionMode('zoom_in');
          return;
        }
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if ((event.ctrlKey && event.shiftKey) || (event.metaKey && event.shiftKey)) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.redoActions();
        } else if (event.ctrlKey || event.metaKey) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.undoActions();
        }
        break;
      }
      case PgUp_KEY: {
        const sourceEvent = { selectedElements: this.documentService.getSelectedElements() };
        if (event.shiftKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('order.bring_forward', sourceEvent));
        } else {
          this.documentService.handleActionRequest(new ActionRequest('order.bring_to_front', sourceEvent));
        }
        break;
      }
      case PgDn_KEY: {
        const sourceEvent = { selectedElements: this.documentService.getSelectedElements() };
        if (event.shiftKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('order.send_backward', sourceEvent));
        } else {
          this.documentService.handleActionRequest(new ActionRequest('order.send_to_back', sourceEvent));
        }
        break;
      }
      case UP_KEY:
      case DOWN_KEY:
      case RIGHT_KEY:
      case LEFT_KEY:
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        this.documentService.submitCrop();
        const actionType = this.boardFrameService.onFrameSelection$.value ? 'select_frame' : 'move_element';
        this.documentService.handleActionRequest(new ActionRequest(actionType, event));
        break;
      case EQUAL:
      case ADD:
        if (!event.ctrlKey) {
          event.preventDefault();
          this.boardService?.zoomPanHandler?.zoomIn();
        }
        break;
      case MINUS:
      case SUBTRACT:
        if (!event.ctrlKey) {
          event.preventDefault();
          this.boardService?.zoomPanHandler?.zoomOut();
        }
        break;
      case ENTER_KEY:
        const selectedElements = this.documentService.getSelectedElements();
        if (selectedElements.length) {
          this.documentService.handleDocumentSvgElementEvent({
            eventType: 'svgEditorExit',
            element: selectedElements[0],
          });
        }

        this.documentService.submitCrop();
        break;
    }
  }

  isEventAllowed(event: any) {
    for (const el of event.composedPath()) {
      // CHECK IF ORIGINATING FROM A MODAL
      if (
        KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase()) ||
        (el.tagName?.toLowerCase() === 'app-chooser' && !el.classList.contains('color-widget-chooser'))
      ) {
        return false;
      }
    }
    return true;
  }
}
