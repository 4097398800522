<div class="header">
  <div
    class="color-indicator"
    [ngClass]="{ add: changeType === 'add', modify: changeType === 'modify', drop: changeType === 'drop' }"
  ></div>
  <div class="image-placeholder" *ngIf="!thumbnailUrl">
    <mat-icon>insert_photo</mat-icon>
  </div>
  <div *ngIf="thumbnailUrl" class="image-holder">
    <img [attr.src]="thumbnailUrl | secureImage | async" />
  </div>
  <div class="item-header-info">
    <div class="item-name">{{ change?.item?.name }}</div>
    <div class="option-name">{{ change?.item?.optionName }}</div>
  </div>
</div>
<div class="body">
  <div class="change-summary">
    <div *ngIf="changeType === 'add'">Item added</div>
    <div *ngIf="changeType === 'drop'">Item removed.</div>
    <div *ngIf="changeType === 'modify' && change?.diffs?.length">{{ change?.diffs?.length }} changes made.</div>
  </div>
  <div class="change-detail-list">
    <div *ngFor="let diff of change.diffs" class="change-detail">
      <div class="property-label">{{ getProperty(diff.propertyName)?.label }}:</div>
      <div class="property-change">
        <app-property-value
          *ngIf="diff.oldValue"
          [property]="getProperty(diff.propertyName)"
          [value]="diff.oldValue"
        ></app-property-value>
        <mat-icon *ngIf="diff.oldValue">chevron_right</mat-icon>
        <app-property-value
          *ngIf="diff.newValue !== null"
          [property]="getProperty(diff.propertyName)"
          [value]="diff.newValue"
        ></app-property-value>
        <div *ngIf="diff.newValue === null">(No Value)</div>
      </div>
    </div>
  </div>
</div>
