import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-text-type',
  templateUrl: './property-configurator-text-type.component.html',
  styleUrls: ['./property-configurator-text-type.component.scss'],
})
export class PropertyConfiguratorTextTypeComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  public textType = '';

  constructor() {}

  ngOnChanges(): void {
    this.textType = this.currentValue;
  }

  setValue() {
    this.textType = this.textType === 'text-tool' ? 'text-box' : 'text-tool';
    const changes = { type: 'textType', value: this.textType };
    this.valueChange.emit(changes);
  }
}
