import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import {
  PropertyViewBuilderComponent,
  ViewPropertyDefinition,
} from '../property-view-builder/property-view-builder.component';
import { Entities } from '@contrail/sdk';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { StyleDefinition } from '@contrail/documents';
import { ViewManagerService } from '@common/views/view-manager.service';

@Component({
  selector: 'app-property-view-template-builder',
  templateUrl: './property-view-template-builder.component.html',
  styleUrls: ['./property-view-template-builder.component.scss'],
})
export class PropertyViewTemplateBuilderComponent implements OnInit {
  @Input() public viewDefinitionApplicationViewSlug: string;
  @Input() public availableViewProperties: Array<any>;
  @Input() public allowTemplateEdit = false;
  @Input() public selectedPropertiesList: Array<ViewPropertyDefinition> = []; // List of selected properties

  public availableViewDefinitions: Array<ViewDefinition>;
  public selectedTemplate;
  public properties: Array<ViewPropertyDefinition>;
  @Input() defaultPropertyStyle: StyleDefinition;
  public nameFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]);
  public templateFormControl = new UntypedFormControl();
  public editMode = null;

  @Output() propertiesChangedEvent = new EventEmitter();
  @ViewChild('propertyViewBuilder') propertyViewBuilder: PropertyViewBuilderComponent;

  constructor(
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private viewsService: ViewManagerService,
  ) {}

  async ngOnInit() {
    if (this.viewDefinitionApplicationViewSlug) {
      const views = await this.viewsService.getViewDefinitions({
        applicationViewSlug: this.viewDefinitionApplicationViewSlug, //'common:frame_panel'
        includePrivate: true,
      });

      this.availableViewDefinitions = views;
      this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
      this.properties = this.selectedPropertiesList;
      /*
      if (this.viewDefinitionApplicationViewSlug === 'common:item_component') {
        this.properties.push(
          {
            enabled: true,
            slug: 'thumbnail',
            typeRootSlug: 'item'
          });
      }*/
    }
    this.determineSelectedViews();
  }

  selectTemplate(change) {
    const viewDefinition = change.value;
    console.log('Selected Panel Definition: ', viewDefinition);
    this.selectedTemplate = viewDefinition;
    if (viewDefinition) {
      this.properties = viewDefinition.properties;
    } else {
      this.properties = [];
    }
    this.propertiesChangedEvent.emit(this.properties);
  }

  startCopyTemplate() {
    this.editMode = 'COPY';
    this.nameFormControl.setValue('Copy of ' + this.selectedTemplate.label);
    setTimeout(() => {
      this.renderer.selectRootElement('#nameInput').focus();
    }, 100);
  }

  startUpdateTemplate() {
    this.editMode = 'UPDATE';
    this.nameFormControl.setValue(this.selectedTemplate.label);
    setTimeout(() => {
      this.renderer.selectRootElement('#nameInput').focus();
    }, 100);
  }

  async completeForm() {
    if (!this.nameFormControl.valid) {
      return;
    }

    switch (this.editMode) {
      case 'CREATE': {
        await this.createTemplate();
        break;
      }
      case 'COPY': {
        await this.copyTemplate();
        break;
      }
      case 'UPDATE': {
        await this.updateTemplate(this.nameFormControl.value);
        break;
      }
    }
    this.editMode = null;
  }

  cancelForm() {
    this.editMode = null;
    if (this.selectedTemplate) {
      this.templateFormControl.setValue(this.selectedTemplate);
    }
  }
  startDeleteTemplate() {
    this.editMode = 'DELETE';
  }
  async deleteTemplate() {
    if (!this.selectedTemplate) {
      return;
    }

    const deleted = await new Entities().delete({ entityName: 'view-definition', id: this.selectedTemplate.id });
    this.selectedTemplate = null;
    this.properties = [];
    this.availableViewDefinitions = this.availableViewDefinitions.filter((t) => t.id !== deleted.id);
    this.templateFormControl.setValue(null);
    this.editMode = null;
    const message = 'View preset deleted.';
    this.snackBar.open(message, '', { duration: 2000 });
  }

  startCreateNewTemplate() {
    this.editMode = 'CREATE';
    this.nameFormControl.setValue('');
    setTimeout(() => {
      this.renderer.selectRootElement('#nameInput').focus();
    }, 100);
  }

  async createTemplate(copy = false) {
    const viewDefinition = {
      label: this.nameFormControl.value,
      properties: this.properties,
      applicationViewSlug: this.viewDefinitionApplicationViewSlug,
      admin: true, // hardcoding to admin for now
      viewType: 'properties_list',
    };

    const newTemplate = await new Entities().create({ entityName: 'view-definition', object: viewDefinition });

    this.selectedTemplate = newTemplate;
    this.properties = ObjectUtil.cloneDeep(this.properties);
    this.selectedTemplate.properties = this.properties;
    this.availableViewDefinitions.push(this.selectedTemplate);
    this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
    this.templateFormControl.setValue(this.selectedTemplate);
    const message = copy ? 'View preset copied' : 'View preset created';
    this.snackBar.open(message, '', { duration: 2000 });
  }

  async copyTemplate() {
    await this.createTemplate(true);
  }

  async updateTemplate(label = null) {
    if (!this.selectTemplate) {
      return;
    }
    const changes: any = {
      properties: this.properties,
    };

    if (label) {
      changes.label = label;
    }

    // Apply current properties state to the template and save

    const updatedTemplate = await new Entities().update({
      entityName: 'view-definition',
      id: this.selectedTemplate.id,
      object: changes,
    });

    this.selectedTemplate = updatedTemplate;
    this.selectedTemplate.properties = this.properties;
    this.availableViewDefinitions = this.availableViewDefinitions.filter((t) => t.id !== this.selectedTemplate.id);
    this.availableViewDefinitions.push(this.selectedTemplate);
    this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
    this.templateFormControl.setValue(this.selectedTemplate);

    this.snackBar.open('View preset updated', '', { duration: 2000 });
  }

  handlePropertyViewChange(properties) {
    if (properties?.length) {
      this.properties = properties;
    } else {
      this.properties = [];
    }
    this.propertiesChangedEvent.emit(this.properties);
  }

  hideFormatBar() {
    this.propertyViewBuilder.hideFormatBar();
  }

  determineSelectedViews() {
    if (!this.availableViewDefinitions) {
      return;
    }
    for (let viewDef of this.availableViewDefinitions) {
      if (!this.compareViewWithLocalProperties(viewDef)) {
        this.selectedTemplate = viewDef;
        this.templateFormControl.setValue(viewDef);
        break;
      }
    }
  }

  compareViewWithLocalProperties(view: ViewDefinition): boolean {
    const viewProperties = view?.properties || [];
    let propertiesChanged = viewProperties?.length !== this.properties?.length;
    if (propertiesChanged) {
      return propertiesChanged;
    }

    for (let i = 0; i < this.properties.length; i++) {
      const property = this.formatProperty(this.properties[i]);
      const viewProperty = viewProperties[i];
      if (
        property.slug !== viewProperty.slug ||
        ObjectUtil.compareDeep(property.style, viewProperty.style, '').length > 0 ||
        property.includeLabel !== viewProperty.includeLabel ||
        property.isHidden !== viewProperty.isHidden ||
        (property?.scale?.x || 1) !== (viewProperty?.scale?.x || 1)
      ) {
        propertiesChanged = true;
        break;
      }
    }
    return propertiesChanged;
  }

  formatProperty(prop: any) {
    const formattedProp: ViewPropertyDefinition = {
      enabled: prop.enabled || true,
      includeLabel: prop.hasOwnProperty('label') || (prop.includeLabel ? true : false),
      slug: prop.slug,
      style: prop.style,
      size: prop.size,
      typeRootSlug: prop.typeRootSlug,
    };
    if (prop.propertyDefinition) {
      // this is not an image
      formattedProp.propertyDefinition = ObjectUtil.cloneDeep(prop.propertyDefinition);
    } else if (prop.isHidden) {
      formattedProp.isHidden = prop.isHidden;
    }
    if (prop.scale) {
      formattedProp.scale = prop.scale;
    }
    return formattedProp;
  }
}
