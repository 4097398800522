<div class="px-5" style="font-size: 12px">
  <div class="flex items-center">
    <div class="mr-3">Type</div>
    <mat-form-field style="width: 100px">
      <mat-select [formControl]="typeFormControl">
        <mat-option value="ALL">All</mat-option>
        <mat-option value="ADDS">Adds</mat-option>
        <mat-option value="DROPS">Drops</mat-option>
        <mat-option value="CHANGES">Changes</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-date-filter
    style="margin-top: -15px; display: block"
    [toggle]="false"
    [dateFilterAttribute]="dateFilterDefinition"
    (dateFilterChanged)="dateFilterChanged($event)"
  >
  </app-date-filter>
</div>
