import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { AuthService } from '@common/auth/auth.service';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-generator-frame-template-chooser',
  templateUrl: './generator-frame-template-chooser.component.html',
  styleUrls: ['./generator-frame-template-chooser.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-out', style({ transform: 'translateY(0)' })),
      ]),
    ]),
  ],
})
export class GeneratorFrameTemplateChooserComponent implements OnInit {
  private storageItemName = 'showcaseRecentLineboardFrameTemplates';

  constructor(private authService: AuthService) {
    const orgId = this.authService.getCurrentOrg().orgId;
    if (this.storageItemName.indexOf(orgId) === -1) {
      this.storageItemName += '-' + orgId;
    }
  }

  @ViewChildren('template') templates: QueryList<ElementRef>;
  @ViewChild('searchFrameTemplateSearch') searchFrameTemplateField: ElementRef;
  @ViewChild('chooserTemplateSearchInput') chooserSearchTemplateInput: SearchBarComponent;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @Input() frameTemplates: any[];
  @Input() selectedFrameTemplateId: string;
  @Input() showTemplateBrowser = true;
  @Output() setFrameTemplate = new EventEmitter();
  filteredFrameTemplates: Observable<any[]>;
  filteredChooserFrameTemplateGroups: any[] = [[]];
  recentlyUsedTemplateobjs: any[] = [];
  recentlyUsedTemplates: any[] = [];
  templateSearchFormControl = new UntypedFormControl();
  chooserVisible = false;
  showRecentlyUsed = false;

  ngOnInit(): void {
    this.filteredChooserFrameTemplateGroups = this.groupTemplatesIntoTwoColumns(this.frameTemplates);
    this.filteredFrameTemplates = this.templateSearchFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name && name !== '' && name !== this.templateSearchFormControl.value.name
          ? this.filterByName(name)
          : this.frameTemplates.slice(),
      ),
    );
    if (this.selectedFrameTemplateId) {
      const selectedFrameTemplate = this.frameTemplates.find((f) => f.id === this.selectedFrameTemplateId);
      this.templateSearchFormControl.setValue(selectedFrameTemplate);
    } else {
      this.templateSearchFormControl.setValue(this.frameTemplates[0]);
    }
    this.templateSearchFormControl.valueChanges.subscribe((changedValue) => {
      this.updateRecentUsedTemplates();
      this.setFrameTemplate.emit(changedValue);
    });
  }

  goToTemplate(template: any) {
    if (template.id !== 'default') {
      const templateElement = this.templates.find((div: ElementRef) => div.nativeElement.id === template.id);
      templateElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  toggleChooser(event) {
    this.chooserSearchTemplateInput?.clear();
    this.handleChooserTemplateSearchChange('');
    this.autocomplete.closePanel();
    event.stopPropagation();
    this.chooserVisible = !this.chooserVisible;
    if (this.chooserVisible) {
      setTimeout(() => {
        this.goToTemplate(this.templateSearchFormControl.value);
      }, 500);
    }
  }

  closeChooser() {
    this.chooserVisible = false;
    this.showRecentlyUsed = false;
  }

  handleChooserClick(event) {
    event.stopPropagation();
  }

  selectTemplate(id: string) {
    this.templateSearchFormControl.setValue(this.frameTemplates.find((template) => template.id === id));
    this.updateRecentUsedTemplates();
    this.closeChooser();
  }

  clearTemplateSearchValue() {
    this.templateSearchFormControl.setValue('');
    setTimeout(() => {
      this.searchFrameTemplateField.nativeElement.blur();
      this.searchFrameTemplateField.nativeElement.focus();
    }, 100);
  }

  displayFn(template: any): string {
    return template && template.name ? template.name : '';
  }

  handleChooserTemplateSearchChange(text) {
    this.filteredChooserFrameTemplateGroups = this.groupTemplatesIntoTwoColumns(
      text && text !== ''
        ? this.filterChooserTemplateByName(text)
        : this.showRecentlyUsed
          ? this.recentlyUsedTemplates.slice()
          : this.frameTemplates.slice(),
    );
  }

  private filterByName(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.frameTemplates.filter((template) => template.name.toLowerCase().includes(filterValue));
  }

  private filterChooserTemplateByName(name: string): any[] {
    const filterValue = name.toLowerCase();
    return (this.showRecentlyUsed ? this.recentlyUsedTemplates : this.frameTemplates).filter((template) =>
      template.name.toLowerCase().includes(filterValue),
    );
  }

  private groupTemplatesIntoTwoColumns(templates: any[]) {
    return templates.reduce((r, s, index) => {
      if (index % 2 === 0) {
        r.push([s]);
      } else {
        r[r.length - 1].push(s);
      }
      return r;
    }, []);
  }

  toggleRecentUsedFrameTemplates(event) {
    this.showRecentlyUsed = event.checked;
    this.chooserSearchTemplateInput?.clear();
    if (this.showRecentlyUsed) {
      this.getStoredRecentUsedFrames();
      this.filteredChooserFrameTemplateGroups = this.groupTemplatesIntoTwoColumns(this.recentlyUsedTemplates);
    } else {
      this.filteredChooserFrameTemplateGroups = this.groupTemplatesIntoTwoColumns(this.frameTemplates);
    }
  }

  updateRecentUsedTemplates() {
    const selectedTemplateId = this.templateSearchFormControl.value.id;
    if (selectedTemplateId !== 'default') {
      const storedTemplate = this.recentlyUsedTemplateobjs.find((template) => template.id === selectedTemplateId);
      if (!storedTemplate) {
        this.recentlyUsedTemplateobjs.push({
          id: selectedTemplateId,
          date: new Date(),
        });
      } else {
        storedTemplate.date = new Date();
      }
      localStorage.setItem(this.storageItemName, JSON.stringify(this.recentlyUsedTemplateobjs));
    }
  }

  private getStoredRecentUsedFrames() {
    const storedData = localStorage.getItem(this.storageItemName);
    this.recentlyUsedTemplateobjs = storedData ? JSON.parse(storedData) : [];
    this.recentlyUsedTemplates = this.frameTemplates.filter((template) =>
      this.recentlyUsedTemplateobjs.map((t) => t.id).includes(template.id),
    );
    const recentUsedTemplateLength = this.recentlyUsedTemplates.length;
    if (this.recentlyUsedTemplates.length > 0) {
      this.recentlyUsedTemplateobjs = this.recentlyUsedTemplateobjs.filter((template) => {
        return (new Date().getTime() - new Date(template.date).getTime()) / (1000 * 60 * 60 * 24) <= 30;
      });
      if (recentUsedTemplateLength !== this.recentlyUsedTemplateobjs.length) {
        localStorage.setItem(this.storageItemName, JSON.stringify(this.recentlyUsedTemplateobjs));
      }
    }
  }
}
