import { DocumentElement, PositionDefinition, ScaleTransformation, TranslateTransformation } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { ROW_MIN_HEIGHT, COLUMN_MIN_WIDTH } from '../../../constants';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasTableElement } from '../../../elements/table/canvas-table-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { DRAG_DIRECTIONS_TRANSFORM } from '../drag-event-handlers/element-resize-handler';

export class TableResizeHandler {
  private startingPosition: PositionDefinition;
  private selectedTable: CanvasTableElement;
  private startingTable: DocumentElement;
  private selectedAreas: DocumentElement[]; // rows or columns
  private startingAreas: DocumentElement[];
  private selectedCells: DocumentElement[];
  private startingCells: DocumentElement[];
  private selectedTableScale: number;
  private minValues: number[];
  private selectedIndex: number;
  private target: DRAG_DIRECTIONS;
  private transform: TranslateTransformation;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS; index? }) {
    this.clear();
    if (elementTarget?.element?.elementDefinition?.type === 'table') {
      if (
        elementTarget?.index != null &&
        [DRAG_DIRECTIONS.RESIZE_ROW, DRAG_DIRECTIONS.RESIZE_COLUMN].indexOf(elementTarget.target) !== -1
      ) {
        const isResizeColumn = elementTarget.target === DRAG_DIRECTIONS.RESIZE_COLUMN;
        this.target = elementTarget.target;
        this.selectedIndex = isResizeColumn ? elementTarget.index.x : elementTarget.index.y;
        this.startingPosition = { x: event.clientX, y: event.clientY };
        this.selectedTable = elementTarget.element as CanvasTableElement;
        this.startingTable = ObjectUtil.cloneDeep(this.selectedTable.elementDefinition);
        this.selectedAreas = isResizeColumn
          ? [this.selectedTable.getColumnElement(this.selectedIndex)]
          : [this.selectedTable.getRowElement(this.selectedIndex)];
        this.startingAreas = this.selectedAreas.map((e) => ObjectUtil.cloneDeep(e));
        this.minValues = isResizeColumn ? [COLUMN_MIN_WIDTH] : [this.selectedTable.getMinHeight(this.selectedIndex)];
        this.selectedCells = isResizeColumn ? this.selectedTable.getCellsAtColumn(this.selectedIndex) : [];
        this.startingCells = this.selectedCells.map((e) => ObjectUtil.cloneDeep(e));
        this.selectedTableScale = this.selectedTable.getScale()?.x ?? 1;
      } else if (
        [
          DRAG_DIRECTIONS.BOTTOM_CENTER,
          DRAG_DIRECTIONS.TOP_CENTER,
          DRAG_DIRECTIONS.MID_LEFT,
          DRAG_DIRECTIONS.MID_RIGHT,
        ].indexOf(elementTarget.target) !== -1
      ) {
        const isResizeColumn =
          [DRAG_DIRECTIONS.MID_LEFT, DRAG_DIRECTIONS.MID_RIGHT].indexOf(elementTarget.target) !== -1;
        this.transform = DRAG_DIRECTIONS_TRANSFORM[elementTarget.target];

        this.target = elementTarget.target;
        this.startingPosition = { x: event.clientX, y: event.clientY };
        this.selectedTable = elementTarget.element as CanvasTableElement;
        this.startingTable = ObjectUtil.cloneDeep(this.selectedTable.elementDefinition);
        this.selectedAreas = isResizeColumn
          ? this.selectedTable.getColumnElements()
          : this.selectedTable.getRowElements();
        this.startingAreas = this.selectedAreas.map((e) => ObjectUtil.cloneDeep(e));
        this.minValues = isResizeColumn
          ? this.selectedAreas.map((e) => COLUMN_MIN_WIDTH)
          : this.selectedTable.getMinHeights();
        this.selectedCells = isResizeColumn ? this.selectedTable.getAllCells() : [];
        this.startingCells = this.selectedCells.map((e) => ObjectUtil.cloneDeep(e));
        this.selectedTableScale = this.selectedTable.getScale()?.x ?? 1;
      }
    }
  }

  public dragged(event) {
    if (!this.selectedTable) {
      return;
    }

    const scale = this.canvasDocument.getViewScale();
    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.resizeColumnsOrRows(distanceX / scale.x, distanceY / scale.y);
    this.canvasDocument.draw({ skipRecalculate: true });
  }

  public dragended(event) {
    if (!this.selectedTable) {
      return;
    }

    const scale = this.canvasDocument.getViewScale();
    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.resizeColumnsOrRows(distanceX / scale.x, distanceY / scale.y);
    const resizedRows = this.resizeRowCellHeight();
    this.canvasDocument.actionsDispatcher.handleUndoableChanges(
      [
        {
          id: this.selectedTable.id,
          size: { ...this.selectedTable.elementDefinition.size },
          position: { ...this.selectedTable.elementDefinition.position },
          type: this.selectedTable.elementDefinition.type,
          tableId: this.selectedTable.id,
        },
        ...this.selectedAreas.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
        ...resizedRows.elements.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
        ...this.selectedCells.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
      ],
      [
        {
          id: this.startingTable.id,
          size: { ...this.startingTable.size },
          position: { ...this.startingTable.position },
          type: this.startingTable.type,
          tableId: this.startingTable.id,
        },
        ...this.startingAreas.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
        ...resizedRows.undoElements.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
        ...this.startingCells.map((e) => ({
          id: e.id,
          size: { ...e.size },
          type: e.type,
          tableId: e.tableId,
        })),
      ],
    );

    this.canvasDocument.draw();
    this.clear();
  }

  private resizeRowCellHeight(): { elements: DocumentElement[]; undoElements: DocumentElement[] } {
    if (
      [DRAG_DIRECTIONS.MID_LEFT, DRAG_DIRECTIONS.MID_RIGHT, DRAG_DIRECTIONS.RESIZE_COLUMN].indexOf(this.target) !== -1
    ) {
      return this.selectedTable.table.resizeRowCellHeight(this.selectedIndex);
    }
    return { elements: [], undoElements: [] };
  }

  private resizeColumnsOrRows(dx, dy) {
    dx = dx / this.selectedTableScale;
    dy = dy / this.selectedTableScale;
    if (this.target === DRAG_DIRECTIONS.RESIZE_COLUMN) {
      const newWidth = this.startingAreas[0].size.width + dx;
      const minValue = this.minValues[0];
      if (newWidth > minValue) {
        this.selectedTable.table.resizeColumn(this.selectedIndex, newWidth);
      } else if (newWidth != minValue) {
        this.selectedTable.table.resizeColumn(this.selectedIndex, minValue);
      }
    } else if (this.target === DRAG_DIRECTIONS.RESIZE_ROW) {
      const newHeight = this.startingAreas[0].size.height + dy;
      const minValue = this.minValues[0];
      if (newHeight > minValue) {
        this.selectedTable.table.resizeRow(this.selectedIndex, newHeight);
      } else if (newHeight != minValue) {
        this.selectedTable.table.resizeRow(this.selectedIndex, minValue);
      }
    } else if ([DRAG_DIRECTIONS.BOTTOM_CENTER, DRAG_DIRECTIONS.TOP_CENTER].indexOf(this.target) !== -1) {
      // const filtered = this.selectedRowsOrColumns.filter((row) => row.size.height > ROW_MIN_HEIGHT);
      // console.log(filtered);
      // const dyRow = (this.transform.y * dy) / (filtered.length || this.startingRowsOrColumns.length);
      const dyRow = (this.transform.y * dy) / this.startingAreas.length;
      const initValue: { [id: string]: number } = {};
      const heights: { [id: string]: number } = this.startingAreas.reduce((acc, row, index) => {
        const height = row.size.height + dyRow;
        const minValue = this.minValues[index];
        if (height > minValue) {
          acc[row.id] = height;
        } else if (height != minValue) {
          acc[row.id] = minValue;
        }
        return acc;
      }, initValue);
      if (Object.keys(heights).length > 0) {
        this.selectedTable.table.resizeRows(heights);
        if (this.transform.y === -1) {
          this.selectedTable.elementDefinition.position.y =
            this.startingTable.position.y -
            (this.selectedTable.elementDefinition.size.height - this.startingTable.size.height);
        }
      }
    } else if ([DRAG_DIRECTIONS.MID_LEFT, DRAG_DIRECTIONS.MID_RIGHT].indexOf(this.target) !== -1) {
      const dxCol = (this.transform.x * dx) / this.startingAreas.length;
      const initValue: { [id: string]: number } = {};
      const widths: { [id: string]: number } = this.startingAreas.reduce((acc, row, index) => {
        const width = row.size.width + dxCol;
        const minValue = this.minValues[index];
        if (width > minValue) {
          acc[row.id] = width;
        } else if (width != minValue) {
          acc[row.id] = minValue;
        }
        return acc;
      }, initValue);
      if (Object.keys(widths).length > 0) {
        this.selectedTable.table.resizeColumns(widths);
        if (this.transform.x === -1) {
          this.selectedTable.elementDefinition.position.x =
            this.startingTable.position.x -
            (this.selectedTable.elementDefinition.size.width - this.startingTable.size.width);
        }
      }
    }
  }

  private clear() {
    this.selectedTable = null;
    this.startingTable = null;
    this.selectedAreas = null;
    this.startingAreas = null;
    this.startingPosition = null;
    this.selectedIndex = null;
    this.target = null;
    this.selectedTableScale = null;
  }
}
