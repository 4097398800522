import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../common/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private authContext;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private privateAuthService: AuthService,
  ) {
    this.privateAuthService.authContext.subscribe((authContext) => {
      this.authContext = authContext;
    });
  }

  public go(path, params = {}) {
    this.router.navigate([`/org/${this.authContext?.currentOrg?.orgSlug}/${path}`], {
      queryParams: params,
    });
  }
}
