import { Types } from '@contrail/sdk/lib/types';
import { EditableCheck, TypeConstraintsHelper } from '@contrail/type-validation/lib/constraints';
import { TypePropertyFormConfiguration } from '../../type-property-form/type-property-form.component';

export class TypePropertyFormHelper {
  public static async isDisabled(propertyFormConfiguration: TypePropertyFormConfiguration, entity?: any) {
    if (propertyFormConfiguration.isFilter) {
      return false;
    }
    let disabled = false;
    let editableCheck: EditableCheck;
    if (entity) {
      const type = await new Types().getType({ id: entity.typeId });
      editableCheck = await TypeConstraintsHelper.isPropertyEditable(
        type,
        propertyFormConfiguration.typeProperty,
        entity,
      );
    }
    disabled = propertyFormConfiguration.editable === false || editableCheck?.editable === false;
    return disabled;
  }

  /**
   * Checks to see if a value should be visible.  For example, item-family with option-level property values should not be shown.
   * @param propertyFormConfiguration
   * @param entity
   * @returns
   */
  public static async isValueVisible(propertyFormConfiguration: TypePropertyFormConfiguration, entity?: any) {
    if (propertyFormConfiguration.typeProperty.propertyLevel && entity?.roles) {
      if (entity.roles?.includes('family') && propertyFormConfiguration.typeProperty?.propertyLevel === 'option') {
        return false;
      }
    }
    return true;
  }
}
