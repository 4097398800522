import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasIframeElement } from '../../../elements/iframe/canvas-iframe-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class IframeElementEventHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.canvasDocument.iframeElementHelper.hideIframe();
    if (
      elementTarget.element?.elementDefinition?.type === 'iframe' &&
      !elementTarget.element?.elementDefinition?.isLocked
    ) {
      const iframeElement = elementTarget.element as CanvasIframeElement;
      const mouseEvent = event as MouseEvent;
      mouseEvent.stopPropagation();
      if (
        !iframeElement.isVideo ||
        !iframeElement.isMouseOnClickableArea(event as MouseEvent) ||
        mouseEvent.button === 2
      ) {
        return;
      }
      this.canvasDocument.iframeElementHelper.showIframe(iframeElement);
    }
  }
}
