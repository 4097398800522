import { Component, Input, OnInit } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { DocumentService } from '../../document.service';
import { DocumentAnnotationService } from '../document-annotation-service';

@Component({
  selector: 'app-document-annotation-tool',
  template: `<app-annotation-tool
    [tooltipPosition]="tooltipPosition"
    [annotationOptions]="getAnnotationOptions()"
    [annotationType]="getAnnotationType()"
    (annotationTypeChange)="setInteractionMode($event)"
  ></app-annotation-tool>`,
})
export class DocumentAnnotationToolComponent implements OnInit {
  @Input() tooltipPosition = 'below';
  constructor(
    private documentService: DocumentService,
    private documentAnnotationService: DocumentAnnotationService,
  ) {}

  ngOnInit(): void {}

  getAnnotationOptions() {
    return this.documentAnnotationService.getAnnotationOptions();
  }

  getAnnotationType() {
    const interactionMode = this.documentService.getInteractionMode();
    const annotationOptions = this.documentAnnotationService.getAnnotationOptions();
    if (
      annotationOptions &&
      annotationOptions.map((annotationOption) => annotationOption.type).includes(interactionMode)
    ) {
      return interactionMode;
    }
    return null;
  }

  setInteractionMode(mode) {
    if (!mode) {
      this.documentService.setInteractionMode('root');
      this.documentService.handleActionRequest(new ActionRequest('root'));
    } else {
      this.documentService.setInteractionMode(mode);
    }
    this.documentService.deselectAllElements();
  }
}
