import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';
@Injectable({
  providedIn: 'root',
})
export class CommentsModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(params): void {
    const dialogRef = this.dialog.open(CommentsModalComponent, {
      width: '550px',
      height: '650px',
      disableClose: false,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: params,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
