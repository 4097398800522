import { PositionDefinition, SizeDefinition } from '@contrail/documents';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { DocumentActions, DocumentSelectors } from './document/document-store';
import { CanvasDocument } from './canvas/canvas-document';
import { ViewBox } from './canvas/viewbox';
import { CanvasUtil } from './canvas/canvas-util';
import { Subject, tap, bufferTime, filter, throttleTime } from 'rxjs';
import {
  WheelEventAccumulator,
  calculateMouseZoomRateOfChange,
  calculateNewZoomFactorFromScroll,
  clamp,
  convertZoomPercentageToZoomFactor,
  getNextZoomButtonStopPointAsZoomFactor,
  isClickToPanButtonDownOnMouseEvent,
  isMouseButtonPressed,
  isWheelEventZoomEvent,
  MOUSE_BUTTON_CODE,
  ZoomDirection,
  getAverageEventPosition,
  isWheelEventPanEvent,
} from './zoom-pan-handler.utils';
import {
  MAX_DELTA_PER_ZOOM_BUFFER,
  MAX_ZOOM_PERCENTAGE,
  MIN_ZOOM_PERCENTAGE,
  PAN_DEBOUNCE_TIME_MS,
  VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE,
  ZOOM_BUFFER_TIME_MS,
} from './zoom-pan-handler.constants';

export class ZoomPanHandler {
  private readonly AUTO_PAN_PADDING = 50; // Distance of the document edge to start panning the document (in px).

  // Zoom
  public readonly MAX_ZOOM_FACTOR = convertZoomPercentageToZoomFactor(MAX_ZOOM_PERCENTAGE);
  public readonly MIN_ZOOM_FACTOR = convertZoomPercentageToZoomFactor(MIN_ZOOM_PERCENTAGE);
  public zoomFactor = 1.0;
  private zoomAccumulator = new WheelEventAccumulator();
  public scrollZoomRate = calculateMouseZoomRateOfChange({
    verticalScrollPerFullZoomRange: VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE,
    maxZoomAsPercentage: MAX_ZOOM_PERCENTAGE,
    minZoomAsPercentage: MIN_ZOOM_PERCENTAGE,
  });

  private startingDragCoord: PositionDefinition;
  public viewBox: ViewBox = {
    width: window.innerWidth,
    height: window.innerHeight,
    x: 0,
    y: 0,
  };
  public canvasSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  public lastKnownMousePosition: PositionDefinition = null;

  public mouseWheelEventSubjectZoom = new Subject<WheelEvent>();
  public mouseWheelEventSubjectPan = new Subject<WheelEvent>();

  constructor(
    private svgDocument: CanvasDocument,
    private currentBoard,
    private store: Store<RootStoreState.State>,
  ) {
    this.setViewPort(this.viewBox);
    this.setZoomFactor(this.zoomFactor);
    this.store
      .select(DocumentSelectors.navigateToPosition)
      .subscribe((position) => position && this.placePositionInCenterViewBox(position));

    this.mouseWheelEventSubjectPan
      .pipe(
        filter((event) => isWheelEventPanEvent(event)),
        throttleTime(PAN_DEBOUNCE_TIME_MS),
        tap((event: WheelEvent) => {
          this.updatePan({
            deltaX: event.deltaX,
            deltaY: event.deltaY,
            shouldPanAsHorizontalScroll: event.shiftKey,
          });
        }),
      )
      .subscribe();

    this.mouseWheelEventSubjectZoom
      .pipe(
        filter((event) => isWheelEventZoomEvent(event)),
        tap((event: WheelEvent) => {
          event.preventDefault();
          this.zoomAccumulator.addWheelEvent(event);
        }),
        bufferTime(ZOOM_BUFFER_TIME_MS),
        filter((events) => events.length > 0),
        tap((events) => {
          if (this.zoomAccumulator.hasDelta()) {
            const zoomDelta = this.zoomAccumulator.getClampedDelta(MAX_DELTA_PER_ZOOM_BUFFER);
            const centerOfEvents = getAverageEventPosition(events);

            this.setZoomFromScroll({
              scrollDeltaY: zoomDelta.deltaY,
              centerPoint: centerOfEvents,
            });
            this.zoomAccumulator.reset();
          }
        }),
      )
      .subscribe();
  }

  public optimizeSize() {
    const targetDims = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.canvasSize = targetDims;
    const viewBox = {
      x: this.viewBox.x,
      y: this.viewBox.y,
      width: this.canvasSize.width * this.zoomFactor,
      height: this.canvasSize.height * this.zoomFactor,
    };
    this.setViewPort(viewBox);
  }

  zoomIn() {
    this.setZoomToNextButtonStopPoint(ZoomDirection.ZOOM_IN);
  }
  zoomOut() {
    this.setZoomToNextButtonStopPoint(ZoomDirection.ZOOM_OUT);
  }
  setMinZoomFactor(zoomFactor: number) {
    const newZoomFactor = Math.max(convertZoomPercentageToZoomFactor(MIN_ZOOM_PERCENTAGE), zoomFactor);
    (this as any).MIN_ZOOM_FACTOR = newZoomFactor;
  }

  private setZoomToNextButtonStopPoint(direction: ZoomDirection) {
    const nextZoomFactor = getNextZoomButtonStopPointAsZoomFactor(this.zoomFactor, direction);
    this.setZoomFactor(nextZoomFactor);
    this.updateViewPort();
  }

  private setZoomFromScroll(options: { scrollDeltaY: number; centerPoint: { x: number; y: number } }) {
    const { scrollDeltaY, centerPoint } = options;

    const newZoomFactor = calculateNewZoomFactorFromScroll({
      currentZoomFactor: this.zoomFactor,
      scrollDelta: scrollDeltaY,
      scrollZoomRate: this.scrollZoomRate,
    });

    if (isNaN(newZoomFactor)) {
      // This shouldn't happen, but it's a fallback in case of unexpected behavior.
      console.error('Unexpected NaN value for newZoomFactor', newZoomFactor);
      return;
    }

    this.setZoomFactor(this.clampZoomFactor(newZoomFactor));
    this.updateViewPort({ x: centerPoint.x, y: centerPoint.y });
  }

  pan(distanceX: number, distanceY: number) {
    if (isNaN(distanceX) || isNaN(distanceY)) {
      return;
    }
    const viewBox = {
      x: this.viewBox.x + distanceX,
      y: this.viewBox.y + distanceY,
      width: this.viewBox.width,
      height: this.viewBox.height,
    };
    this.setViewPort(viewBox);
  }

  public updateViewPort(centerPoint?: { x: number; y: number }, dx = 0, dy = 0) {
    const focalPoint = centerPoint ?? this.getCanvasCenterPosition();
    const focalPointDocCoords = this.computeDocumentPositionForCoordinates(focalPoint);

    const newViewBox: ViewBox = {
      width: this.canvasSize.width * this.zoomFactor,
      height: this.canvasSize.height * this.zoomFactor,
      x: this.viewBox.x,
      y: this.viewBox.y,
    };

    // Set ViewBox coords such that the mouseDocCoords are still at the same windowPosition.
    // This keeps the mouse over the same part of the document as you zoom.
    const newViewPortX = focalPointDocCoords.x - focalPoint.x * this.zoomFactor + dx;
    const newViewPortY = focalPointDocCoords.y - focalPoint.y * this.zoomFactor + dy;
    newViewBox.x = newViewPortX;
    newViewBox.y = newViewPortY;

    this.setViewPort(newViewBox);
  }

  setViewPort(viewBox: ViewBox, animate: boolean = false) {
    this.viewBox = viewBox;
    this.svgDocument.syncState(this.currentBoard.document.size, this.viewBox, this.canvasSize, this.viewBox);
    this.store.dispatch(
      DocumentActions.setViewSize({
        viewBox: { ...this.viewBox },
        viewScale: {
          x: this.canvasSize.width / this.viewBox.width,
          y: this.canvasSize.height / this.viewBox.height,
        },
        boundingClientRect: this.svgDocument.getBoundingClientRect(),
      }),
    );
  }

  public clampZoomFactor(zoomFactor: number) {
    return clamp(zoomFactor, this.MAX_ZOOM_FACTOR, this.MIN_ZOOM_FACTOR);
  }

  setZoomFactor(zoomFactor: number): void {
    this.zoomFactor = this.clampZoomFactor(zoomFactor);
  }

  updatePan(options: { deltaX: number; deltaY: number; shouldPanAsHorizontalScroll?: boolean }) {
    const { deltaX, deltaY, shouldPanAsHorizontalScroll } = options;
    const ADJUSTMENT = 1.5; // Play with this and debounce time...
    if (Math.abs(deltaX) < 150 && Math.abs(deltaY) < 150) {
      let x = deltaX * this.zoomFactor * ADJUSTMENT;
      let y = deltaY * this.zoomFactor * ADJUSTMENT;
      if (shouldPanAsHorizontalScroll) {
        x = y;
        y = 0;
      }
      this.pan(x, y);
    }
  }

  /**
   * Sets the view box coordinates such that @position is in the center
   * @param position
   */
  private placePositionInCenterViewBox(position: PositionDefinition) {
    const viewBox: ViewBox = {
      width: this.viewBox.width,
      height: this.viewBox.height,
      x: position.x - this.viewBox.width / 2,
      y: position.y - this.viewBox.height / 2,
    };

    this.setViewPort(viewBox);
  }

  /**
   * Sets zoom and view port such that rectangle of @size and @position fits
   * on the screen
   * @param size
   * @param position
   * @param padding
   */
  public placeRectInCenter(size: SizeDefinition, position: PositionDefinition, padding = 0) {
    const rectWidth = Math.min(size.width, this.canvasSize.width * this.MAX_ZOOM_FACTOR);
    const rectHeight = Math.min(size.height, this.canvasSize.height * this.MAX_ZOOM_FACTOR);
    const zoomFactor = Math.max(
      rectWidth / (this.canvasSize.width - padding * 2),
      rectHeight / (this.canvasSize.height - padding * 2),
    );
    this.setZoomFactor(zoomFactor);
    const width = this.canvasSize.width * this.zoomFactor;
    const height = this.canvasSize.height * this.zoomFactor;
    const viewBox = {
      x: position.x - (width - rectWidth) * 0.5 - padding * this.zoomFactor,
      y: position.y - (height - rectHeight) * 0.5 - padding * this.zoomFactor,
      width: width + padding * 2 * this.zoomFactor,
      height: height + padding * 2 * this.zoomFactor,
    };
    this.setViewPort(viewBox, false);
  }

  /**
   * Grab mode starts when user presses space but doesn't pan yet
   * @param event
   */
  public startReadyToGrabMode(_event: KeyboardEvent) {
    this.svgDocument.interactionHandler.setGrabbingMode({ isGrabbing: true, isReadyToGrab: true });
  }

  /**
   * Grab mode stops when user releases space key
   * @param event
   */
  public stopReadyToGrabMode(_event: KeyboardEvent) {
    setTimeout(
      () => this.svgDocument.interactionHandler.setGrabbingMode({ isGrabbing: false, isReadyToGrab: false }),
      5,
    );
  }

  private isReadyToGrab() {
    return this.svgDocument.interactionHandler.isReadyToGrabMode();
  }

  private isGrabbing() {
    return this.svgDocument.interactionHandler.getInteractionMode() === 'grabbing';
  }

  private isPrimaryButtonPanEvent(event: MouseEvent) {
    const isInModeThatSupportsPrimaryButtonPanning = this.isReadyToGrab() || this.isGrabbing();
    return isMouseButtonPressed(event, MOUSE_BUTTON_CODE.PRIMARY) && isInModeThatSupportsPrimaryButtonPanning;
  }

  /**
   * Grabbing mode starts when user pans with right click mouse or left click mouse+space
   * @param event
   */
  public startGrabbingMode(_event: MouseEvent) {
    this.svgDocument.interactionHandler.setGrabbingMode({ isGrabbing: true });
  }

  public stopGrabbingMode(event: MouseEvent | KeyboardEvent) {
    if (event instanceof MouseEvent) {
      event.preventDefault();
      // event.stopPropagation(); - do not want to stop propagation so this event is available in the property-configurator-bar
    }
    this.resetDragPositionStart();
    this.svgDocument.interactionHandler.setGrabbingMode({ isGrabbing: false });
  }

  public handleMouseDown(event: MouseEvent) {
    const isPanButtonDown = isClickToPanButtonDownOnMouseEvent(event);
    const isPanningEvent = isPanButtonDown || this.isPrimaryButtonPanEvent(event);
    const isRightClick = isMouseButtonPressed(event, MOUSE_BUTTON_CODE.SECONDARY);

    if (isPanningEvent) {
      this.markDragPositionStart({ x: event.x, y: event.y });

      if (!isRightClick) {
        // We don't want to start grabbing on every right click.
        // If we are clicking right and move the most, then we will start grabbing.
        this.startGrabbingMode(event);
      }

      event.preventDefault();
    }
  }

  private markDragPositionStart(position: Pick<PositionDefinition, 'x' | 'y'>) {
    this.startingDragCoord = position;
  }

  private resetDragPositionStart() {
    this.startingDragCoord = null;
  }

  public handleMouseMove(event: MouseEvent) {
    const isPanButtonDown = isClickToPanButtonDownOnMouseEvent(event);
    const isPanningEvent = isPanButtonDown || this.isPrimaryButtonPanEvent(event);

    if (!isPanningEvent && this.isGrabbing()) {
      // No button that controls panning is pressed, but svg document is still in mode "grabbing" - so we should exit panning mode.
      // It's likely that we missed the mouse-up event that caused grabbing to exit, so we can process it now.
      this.handleMouseUp(event);
    }

    if (isPanningEvent) {
      if (!this.isGrabbing()) {
        this.startGrabbingMode(event);
      }

      if (!this.startingDragCoord) {
        this.markDragPositionStart({ x: event.x, y: event.y });
      } else {
        const distanceMoved = {
          x: (event.x - this.startingDragCoord.x) * this.zoomFactor,
          y: (event.y - this.startingDragCoord.y) * this.zoomFactor,
        };
        this.startingDragCoord = { x: event.x, y: event.y };
        this.pan(-distanceMoved.x, -distanceMoved.y);
      }
    }
  }

  public handleMouseUp(event: MouseEvent) {
    if (!isClickToPanButtonDownOnMouseEvent(event)) {
      this.svgDocument.interactionHandler.setGrabbingMode({ isGrabbing: false });
      this.stopGrabbingMode(event);
    }
  }

  public setLastKnownMousePosition(position: PositionDefinition) {
    this.lastKnownMousePosition = position;
    this.store.dispatch(
      DocumentActions.setLastKnownMousePosition({ lastKnownMousePosition: this.lastKnownMousePosition }),
    );
  }

  public autoPan(pos: PositionDefinition) {
    const mouseDocCoords = this.computeDocumentPositionForCoordinates(pos);
    const autoPanPadding = Math.max(this.AUTO_PAN_PADDING, this.AUTO_PAN_PADDING * this.zoomFactor);
    const viewBox = {
      minX: this.viewBox.x + autoPanPadding,
      maxX: this.viewBox.x + this.viewBox.width - autoPanPadding,
      minY: this.viewBox.y + autoPanPadding,
      maxY: this.viewBox.y + this.viewBox.height - autoPanPadding,
    };
    const isInViewPort = this.isPointInViewPort(mouseDocCoords, viewBox);
    if (!isInViewPort) {
      const movement: PositionDefinition = {
        x:
          Math.min(viewBox.minX, mouseDocCoords.x) -
          viewBox.minX +
          (Math.max(viewBox.maxX, mouseDocCoords.x) - viewBox.maxX),
        y:
          Math.min(viewBox.minY, mouseDocCoords.y) -
          viewBox.minY +
          (Math.max(viewBox.maxY, mouseDocCoords.y) - viewBox.maxY),
      };
      this.pan(movement.x * 0.4, movement.y * 0.4);
    }
  }

  isPointInViewPort(
    pos: PositionDefinition,
    viewBox = {
      minX: this.viewBox.x,
      maxX: this.viewBox.x + this.viewBox.width,
      minY: this.viewBox.y,
      maxY: this.viewBox.y + this.viewBox.height,
    },
  ): Boolean {
    return pos.x >= viewBox.minX && pos.x <= viewBox.maxX && pos.y >= viewBox.minY && pos.y <= viewBox.maxY;
  }

  getCanvasCenterPosition() {
    return {
      x: this.canvasSize.width / 2,
      y: this.canvasSize.height / 2,
    };
  }

  getNewZoomFactorAfterWheel(zoomFactor, event) {
    const speed = 7.5;
    const delta = event.wheelDelta ? event.wheelDelta : event.deltaY ? -event.deltaY * speed : -event.deltaX * speed;
    let scale = 1 + 0.001 * Math.abs(delta);
    return delta >= 0 && (scale = 1 / scale), zoomFactor * scale;
  }

  computeDocumentPositionForCanvasCenter() {
    return this.computeDocumentPositionForCoordinates(this.getCanvasCenterPosition());
  }

  computeDocumentPositionForMouseEvent(event: MouseEvent) {
    return this.computeDocumentPositionForCoordinates({ x: event.x, y: event.y });
  }

  computeDocumentPositionForCoordinates(pos: PositionDefinition) {
    return CanvasUtil.toDocumentPosition(
      pos.x,
      pos.y,
      this.svgDocument.getViewBox(),
      this.svgDocument.getViewScale(),
      this.svgDocument.getBoundingClientRect(),
    );
  }
}
