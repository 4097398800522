import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-summary-metric-panel',
  templateUrl: './summary-metric-panel.component.html',
  styleUrls: ['./summary-metric-panel.component.scss'],
})
export class SummaryMetricPanelComponent implements OnChanges {
  @Input() label: string;
  @Input() value: number;
  @Input() typeProperty: TypeProperty;
  @Input() allowGoal = false;
  @Input() goalValue;
  @Input() filterActive;
  public editingGoal = false;
  public progressPercent;
  public slug = '';

  constructor() {}

  ngOnChanges(): void {
    this.goalValue; // = this.allowGoal ? this.goalValue : null;
    this.value = this.value || 0;
    this.slug = this.label.replace(' ', '');
    this.computeGoalProgress();
  }
  computeGoalProgress() {
    if (!this.value) {
      this.progressPercent = 0;
      return;
    }

    if (this.value && this.goalValue) {
      const percentage = this.value / this.goalValue;
      this.progressPercent = percentage > 1 ? 100 : percentage * 100;
    }
  }

  handleValueChange(value) {
    this.computeGoalProgress();
    this.editingGoal = false;
    this.goalValue = value;
    this.computeGoalProgress();
  }
  startEditGoal() {
    if (this.allowGoal) {
      this.editingGoal = true;
    }
  }
}
