import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorDetail } from '../color-widget/color-widget.component';

@Component({
  selector: 'app-color-detail',
  template: `
    <div *ngIf="!folder" class="flex items-center p-1.5 text-black60" (click)="handleClick()">
      <div
        class="rounded-full w-4 h-4 border border-solid border-black05"
        [style.backgroundColor]="backgroundColor"
      ></div>
      <div class="select-text ml-4">{{ name }}</div>
    </div>

    <div *ngIf="folder" class="flex items-center p-1.5 text-black60">
      <span class="material-icons text-[16px]"> folder </span>
      <div class="select-text ml-4 mr-auto">{{ name }}</div>
      <span class="material-icons text-[16px]"> chevron_right </span>
    </div>
  `,
  styles: [``],
})
export class ColorDetailComponent {
  @Input() folder: boolean = false;
  @Input() name: string;
  @Input() backgroundColor: string;
  @Output() onClick? = new EventEmitter<ColorDetail>();

  constructor() {}

  public handleClick() {
    this.onClick?.emit({
      name: this.name,
      backgroundColor: this.backgroundColor,
    });
  }
}
