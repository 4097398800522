import { Component, Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Item } from '@common/items/item';
import { Entities } from '@contrail/sdk';

interface ImageAssignmentOptionsData {
  item: Item;
}

@Injectable({
  providedIn: 'root',
})
export class QuickCreateItemOptionService {
  constructor(public dialog: MatDialog) {}

  public open(item: Item): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(QuickCreateItemOptionComponent, {
        data: { item },
      });
      dialogRef.afterClosed().subscribe((value) => {
        resolve(value);
      });
    });
  }

  public close() {
    this.dialog?.closeAll();
  }
}

@Component({
  template: `
    <div class="modal-container mb-2 flex flex-col" style="min-width: 400px;">
      <div class="flex justify-between">
        <h6 data-test="app-confirmation-box-title">Add Option</h6>
        <mat-icon [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
      </div>

      <div class="my-5">
        <div class="font-bold">Create new option for item: {{ item.name }}</div>
      </div>

      <mat-form-field class="w-full">
        <mat-label>Option Name</mat-label>
        <input matInput #url type="url" [(ngModel)]="optionName" />
      </mat-form-field>

      <div mat-dialog-actions class="flex items-center justify-end mt-3">
        <button
          data-test="app-confirmation-box-cancel-button"
          mat-stroked-button
          color="primary"
          [mat-dialog-close]="false"
          class="!mr-3"
        >
          Cancel
        </button>
        <button
          data-test="app-confirmation-box-ok-button"
          mat-raised-button
          color="primary"
          (click)="complete()"
          [disabled]="!optionName"
          cdkFocusInitial
        >
          OK
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .modal-container {
        max-width: 600px;
      }
    `,
  ],
})
export class QuickCreateItemOptionComponent {
  public item;
  public content;
  public selectedContent;
  public assignmentOption = new UntypedFormControl('replacePrimary');
  public optionName;
  constructor(
    public dialogRef: MatDialogRef<QuickCreateItemOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageAssignmentOptionsData,
  ) {
    this.item = data.item;
    this.init();
  }

  async init() {
    const promises = [];
    promises.push(this.setItem());
    await Promise.all(promises);
  }

  public async setItem() {
    this.item = await new Entities().get({
      entityName: 'item',
      id: this.item.id,
    });
  }

  public complete() {
    const event = {
      optionName: this.optionName,
    };
    this.dialogRef.close(event);
  }
}
