import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LibrariesService {
  public libraries = [];

  private currentEntityId;
  public libChooserCriteria$: BehaviorSubject<any> = new BehaviorSubject(null); // {asset: {}, color: {}}

  constructor() {}

  public async getLibraries() {
    const libraries = await new Entities().get({
      entityName: 'workspace',
      criteria: { workspaceType: 'LIBRARY' },
      // relations: ['updatedBy', 'createdBy'],
    });
    // libraries.sort((ws1, ws2) => (ws1.name > ws2.name ? 1 : -1));
    this.libraries = libraries.filter((lib) => lib.name !== 'item');
    return this.libraries;
  }

  public getLibraryWorkspaceId(name) {
    // asset, color, item, etc
    return this.libraries.find((lib) => lib.name === name).id;
  }

  public async createLibraryEntity(data: any) {
    return new Entities().create({ entityName: data.entityName, object: data.object });
  }

  public checkLibChooserCriteria(id) {
    this.currentEntityId = id;
    const key = `libChooserCriteria_` + id;
    const chooserCriteria = JSON.parse(localStorage.getItem(key)) || null;
    this.libChooserCriteria$.next(chooserCriteria);
  }

  public saveLibChooserCriteria(chooserCriteria) {
    this.libChooserCriteria$.next(chooserCriteria);
    const key = `libChooserCriteria_` + this.currentEntityId;
    localStorage.setItem(key, JSON.stringify(chooserCriteria));
  }
}
