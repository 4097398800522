import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AssortmentsActions } from 'src/app/common/assortments/assortments-store';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { BoardsActions } from '.';
import { BoardsService } from '../boards.service';
import { Board } from './boards.state';
import { DocumentManagerActions } from 'src/app/boards/board/document-manager/document-manager-store';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { ObjectUtil } from '@contrail/util';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { DocumentGeneratorService } from '../board/document-generator/document-generator.service';
import { DownloadService } from '@common/exports/download/download.service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';

@Injectable()
export class BoardsEffects {
  constructor(
    private actions$: Actions,
    private boardService: BoardsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
    private analyticsService: AnalyticsService,
    private documentGeneratorService: DocumentGeneratorService,
    private downloadService: DownloadService,
  ) {}
  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(BoardsActions.clearBoards());
          this.store.dispatch(BoardsActions.loadBoards());
        }),
      ),
    { dispatch: false },
  );
  loadBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.LOAD_BOARDS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.getBoards(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => BoardsActions.loadBoardsSuccess({ data })),
          catchError((error) => observableOf(BoardsActions.loadBoardsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.LOAD_CURRENT_BOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        this.store.dispatch(BoardsActions.clearBoard());
        this.store.dispatch(BoardsActions.clearStatusMessages());
        this.store.dispatch(BoardsActions.clearItemsUsedInBoard());
        this.store.dispatch(AssortmentsActions.clearSourceAssortment());
        this.store.dispatch(DocumentManagerActions.clearDocumentElements());
        return from(this.boardService.getBoardById(action.id)).pipe(
          tap((data) => {
            this.store.dispatch(DocumentManagerActions.setDocumentId({ documentId: data.document.id }));
            this.store.dispatch(
              DocumentManagerActions.setDocumentElements({ documentElements: data.document.elements }),
            );
            this.store.dispatch(
              DocumentManagerActions.setDocumentElementSortOrder({
                documentElementSortOrder: data.document.documentElementSortOrder,
              }),
            );
            this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({ id: data.workspace.rootWorkspaceId })); // Ensure workspace is this board workspace

            // Remove unbound component elements that could have been created by mass creation of items
            // The mass creation of items involve creating unbound component elements first being binding them to items later.
            const unboundComponentElements = data.document.elements.filter(
              (element) => element.type === 'component' && Object.keys(element.modelBindings).length === 0,
            );
            const unboundComponentElementsIds = unboundComponentElements.map((e) => e.id);
            if (unboundComponentElements.length > 0) {
              const actions: DocumentAction[] = [];
              unboundComponentElements.forEach((element) => {
                actions.push(
                  new DocumentAction({
                    changeType: DocumentChangeType.DELETE_ELEMENT,
                    elementId: element.id,
                  }),
                );
              });
              this.store.dispatch(DocumentManagerActions.handleDocumentElementActions({ actions }));
              // filter out unbound component elements
              data.document.elements = data.document.elements.filter(
                (e) => !unboundComponentElementsIds.includes(e.id),
              );
            }
          }),
          map((data) => BoardsActions.loadCurrentBoardSuccess({ board: data })),
          tap(() => this.store.dispatch(DocumentManagerActions.setDocumentElementsLoaded({ loaded: true }))),
          catchError((error) => observableOf(BoardsActions.loadCurrentBoardFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.LOAD_CURRENT_BOARD_SUCCESS),
        tap((action: any) => {
          if (action.board?.sourceAssortmentId) {
            this.store.dispatch(
              AssortmentsActions.loadSourceAssortment({ sourceAssortmentId: action.board?.sourceAssortmentId }),
            );
          } else {
            this.store.dispatch(AssortmentsActions.loadSourceAssortmentInfoSuccess({ sourceAssortment: null }));
          }
          this.analyticsService.emitEvent({
            eventName: 'BOARD_ACCESS',
            eventCategory: EVENT_CATEGORY.ACCESS,
            eventContextProjectId: action.board.rootWorkspaceId,
            eventContextWorkspaceId: action.board.workspaceId,
            eventLabel: `Board ${action.board.name}`,
            eventTarget: `board:${action.board.id}`,
            eventContext: `board:${action.board.id}`,
          });
        }),
      ),
    { dispatch: false },
  );
  createBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.CREATE_BOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const board: Board = { ...action.board };
        board.workspaceId = store.workspaces.currentWorkspace.id;
        return from(this.boardService.createBoard(board)).pipe(
          map((data) => {
            this.snackBar.open('Board Created.', '', { duration: 2000 });
            // this.store.dispatch(BoardsActions.setCurrentBoard({ currentBoard: data }));
            return BoardsActions.createBoardSuccess({ board: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.createBoardFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.DELETE_BOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.deleteBoard(action.board)).pipe(
          map((data) => {
            this.snackBar.open('Board Deleted.', '', { duration: 2000 });
            // this.store.dispatch(BoardsActions.setCurrentBoard({ currentBoard: null }));
            return BoardsActions.deleteBoardSuccess({ board: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.deleteBoardFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.UPDATE_BOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.updateBoard(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Board Updated.', '', { duration: 2000 });
            return BoardsActions.updateBoardSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.updateBoardFailure({ error }));
          }),
        );
      }),
    ),
  );

  clearBoardSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.CLEAR_BOARD_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.boardService.updateBoard(action.id, { sourceAssortmentId: null, sourceAssortment: null }),
        ).pipe(
          map((board) => BoardsActions.clearBoardSourceAssortmentSuccess({ board })),
          tap((boardAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'CLEAR_SOURCE_ASSORTMENT',
              changes: ObjectUtil.cloneDeep(boardAction.board),
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.updateBoardFailure({ error }));
          }),
        );
      }),
    ),
  );

  clearShowcaseSourceAssortmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.CLEAR_BOARD_SOURCE_ASSORTMENT_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(AssortmentsActions.loadSourceAssortmentInfoSuccess({ sourceAssortment: null }));

          const updatedBoard = ObjectUtil.cloneDeep(store.boards.currentBoard);
          updatedBoard.sourceAssortment = null;
          updatedBoard.sourceAssortmentId = null;
          // replace current board in the store with the updated one
          this.store.dispatch(
            BoardsActions.updateBoardSuccess({
              id: updatedBoard.id,
              changes: {
                sourceAssortmentId: null,
                sourceAssortment: null,
              },
            }),
          );
          this.store.dispatch(AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment: null }));
          this.store.dispatch(BoardsActions.loadCurrentBoardSuccess({ board: updatedBoard }));
        }),
      ),
    { dispatch: false },
  );

  assignShowcaseSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.ASSIGN_BOARD_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.updateBoard(action.id, { sourceAssortmentId: action.sourceAssortmentId })).pipe(
          map((board) => BoardsActions.assignBoardAssortmentSuccess({ board })),
          tap((boardAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'ASSIGN_SOURCE_ASSORTMENT',
              changes: ObjectUtil.cloneDeep(boardAction.board),
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.updateBoardFailure({ error }));
          }),
        );
      }),
    ),
  );

  assignShowcaseSourceAssortmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.ASSIGN_BOARD_SOURCE_ASSORTMENT_SUCCESS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        // query for the newly assigned source assortment
        this.store.dispatch(
          AssortmentsActions.loadSourceAssortmentInfo({ sourceAssortmentId: action.board.sourceAssortmentId }),
        );
        return from(AssortmentsService.getAssortment(action.board.sourceAssortmentId)).pipe(
          map((newSourceAssortment) => {
            const updatedBoard = ObjectUtil.cloneDeep(store.boards.currentBoard);
            updatedBoard.sourceAssortment = newSourceAssortment;
            updatedBoard.sourceAssortmentId = newSourceAssortment.id;
            // replace current board in the store with the updated one
            this.store.dispatch(
              BoardsActions.updateBoardSuccess({
                id: updatedBoard.id,
                changes: {
                  sourceAssortmentId: updatedBoard.sourceAssortmentId,
                  sourceAssortment: updatedBoard.sourceAssortment,
                },
              }),
            );
            // replace the current sourceAssortment in the store with the new one
            this.store.dispatch(
              AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment: updatedBoard.sourceAssortment }),
            );
            return BoardsActions.loadCurrentBoardSuccess({ board: updatedBoard });
          }),
        );
      }),
    ),
  );

  copyBoard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.COPY_BOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.copyBoard(action.name, action.sourceId)).pipe(
          map((data) => {
            this.snackBar.open('Board Copied.', '', { duration: 2000 });
            return BoardsActions.copyBoardSuccess({ board: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.copyBoardFailure({ error }));
          }),
        );
      }),
    ),
  );

  asyncCopyBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.ASYNC_COPY_BOARD),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.boardService.asyncCopyBoard(action.name, action.sourceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/boards/copy/${data.jobId}`);
              return data;
            }),
            catchError((error) => {
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(BoardsActions.copyBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  asyncCopyBoardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.ASYNC_COPY_BOARD_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (action.path) {
            const response = await fetch(action.path);
            const board = await response.json();
            this.snackBar.open('Board Copied.', '', { duration: 2000 });
            this.store.dispatch(BoardsActions.copyBoardSuccess({ board }));
          }
        }),
      ),
    { dispatch: false },
  );

  loadCurrentDocumentGenerationConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.LOAD_CURRENT_DOCUMENT_GENERATION_CONFIG),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        this.store.dispatch(BoardsActions.clearCurrentDocumentGenerationConfig());
        return from(this.documentGeneratorService.getDocumentGenerationConfigById(action.id)).pipe(
          map((data) => {
            return BoardsActions.loadCurrentDocumentGenerationConfigSuccess({ documentGenerationConfig: data });
          }),
          catchError((error) => observableOf(BoardsActions.loadCurrentDocumentGenerationConfigFailure({ error }))),
        );
      }),
    ),
  );

  updateDocumentGenerationConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.UPDATE_CURRENT_DOCUMENT_GENERATION_CONFIG),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.documentGeneratorService.updateDocumentGenerationConfig(action.changes)).pipe(
          map((data) => {
            return BoardsActions.updateCurrentDocumentGenerationConfigSuccess({ documentGenerationConfig: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(BoardsActions.updateCurrentDocumentGenerationConfigFailure({ error }));
          }),
        );
      }),
    ),
  );
}
