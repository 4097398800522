import { DocumentElement, DocumentTextElementEvent, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument, CopiedProperties } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { StickyNoteEditorManager } from '../../elements/sticky-note/editor/sticky-note-editor-manager';
import { CanvasTableElement } from '../../elements/table/canvas-table-element';
import { TableEditorManager } from '../../elements/table/table-editor/table-editor-manager';
import { TextFormat } from '../../elements/text/editor/text-editor';
import { TextEditorManager } from '../../elements/text/editor/text-editor-manager';
import { TextStyle } from '../../elements/text/text-style';
import { EditorCalc } from './editor-calc';
import { EditorManager } from './editor-manager';
import { ChildElementDetails } from './editor-placeholder';

export interface TextChanges {
  textChanges?: DocumentElement;
  textUndoChanges?: DocumentElement;
}

export class EditorHandler {
  public stickyNoteEditorManager: StickyNoteEditorManager;
  public textEditorManager: TextEditorManager;
  public tableEditorManager: TableEditorManager;
  public editorCalc: EditorCalc;

  constructor(private canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT') {
      this.stickyNoteEditorManager = new StickyNoteEditorManager(canvasDocument);
      this.textEditorManager = new TextEditorManager(canvasDocument);
      this.tableEditorManager = new TableEditorManager(canvasDocument);
      this.editorCalc = new EditorCalc(canvasDocument);
    }
  }

  public getManager(element: CanvasElement): EditorManager {
    if (element?.elementDefinition?.type === 'sticky_note') {
      return this.stickyNoteEditorManager;
    } else if (element?.elementDefinition?.type === 'cell') {
      return this.tableEditorManager;
    } else if (element?.elementDefinition?.type === 'text') {
      return this.textEditorManager;
    }
    return null;
  }

  public showEditor(element: CanvasElement) {
    this.getManager(element)?.showEditor(element);
  }

  public hideEditor() {
    this.stickyNoteEditorManager?.hideEditor();
    this.textEditorManager?.hideEditor();
    this.tableEditorManager?.hideEditor();
  }

  public redrawEditor() {
    this.stickyNoteEditorManager?.redrawEditor();
    this.textEditorManager?.redrawEditor();
    this.tableEditorManager?.hideEditor();
  }

  public applyDocumentTextElementEvent(change: DocumentTextElementEvent) {
    const selectedElements =
      this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedUnlockedCanvasElements();
    if (selectedElements?.length === 1 && selectedElements[0].elementDefinition.type === 'table') {
      this.tableEditorManager.applyDocumentTextElementEvent(selectedElements[0] as CanvasTableElement, change);
      return;
    }

    const selectedTextElements: Array<CanvasElement> = selectedElements.filter((element) => element.isTextEditable);

    console.log('EditorHandler.applyDocumentTextElementEvent', change, selectedTextElements);
    if (
      change?.textFormat?.type &&
      (selectedTextElements?.findIndex((e) => e.elementDefinition.type === 'text') !== -1 ||
        change?.element?.type === 'text') &&
      this.textEditorManager?.options?.setLastAppliedTextFormat
    ) {
      const textFormat = {};
      if (['bold', 'italic', 'underline', 'strikethrough'].indexOf(change.textFormat.type) !== -1) {
        textFormat[change.textFormat.type] = change.textFormat.value ? change.textFormat.type : 'normal';
      } else {
        textFormat[change.textFormat.type] = change.textFormat.value;
      }
      this.canvasDocument.documentService.saveLastAppliedTextFormat(textFormat);
    }

    const elementChanges = {
      textChanges: [],
      textUndoChanges: [],
    };

    const isFocused = change?.element && selectedTextElements?.length === 1;
    for (let i = 0; i < selectedTextElements?.length; i++) {
      const element: CanvasElement = selectedTextElements[i];
      const undoElement = ObjectUtil.cloneDeep(element.elementDefinition);
      const updatedElement = this.getManager(element).assignTextEventChanges(element, [change], isFocused);
      if (updatedElement) {
        elementChanges.textChanges.push(updatedElement);
        elementChanges.textUndoChanges.push(undoElement);
      }
    }

    if (elementChanges?.textChanges.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        elementChanges.textChanges,
        elementChanges.textUndoChanges,
      );
    }
  }

  public removeEditor() {
    this.stickyNoteEditorManager?.removeEditor();
    this.textEditorManager?.removeEditor();
    this.tableEditorManager?.removeEditor();
    this.editorCalc?.remove();
  }

  public refreshElement(element: CanvasElement) {
    this.getManager(element)?.refreshElement(element);
  }

  public getCurrentStyle(element: CanvasElement) {
    this.getManager(element)?.getCurrentStyle(element);
  }

  public calculateTextChildElemPositions(element: CanvasElement, textStyle: TextStyle) {
    this.getManager(element)?.editorPlaceholder?.setContent(element, textStyle);
  }

  public handleFocusHyperlink(details: ChildElementDetails, element: DocumentElement, position: PositionDefinition) {
    this.textEditorManager.handleFocusHyperlink(details, element, position);
  }

  public handleBlurHyperlink(details: ChildElementDetails = null) {
    this.textEditorManager.handleBlurHyperlink(details);
  }

  public isEditing(element: CanvasElement) {
    return this.getManager(element)?.isEditing(element);
  }

  public copySelectedTextElementStyle(element: CanvasElement): TextFormat {
    return this.textEditorManager?.editor?.copySelectedTextElementStyle(element);
  }

  public applyCopiedProperties(properties: CopiedProperties) {
    this.textEditorManager?.applyCopiedProperties(properties);
  }
}
