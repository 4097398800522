import { ImageLoaderResponse } from '../../image/canvas-image-loader';
import { STICKY_NOTES } from './sticky-note-background';

export class StickyNoteLoaderService {
  public static images: Map<string, ImageLoaderResponse> = new Map();

  constructor() {}

  static {
    for (const key in STICKY_NOTES) {
      const img = document.createElement('img');
      img.id = key;
      img.src = STICKY_NOTES[key];
      this.images.set(key, {
        imageElement: img,
      });
    }
  }
}
