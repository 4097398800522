<div class="w-full h-full">
  <div class="flex justify-end w-full mt-[-5px]">
    <mat-icon
      class="cursor-pointer mr-[5px]"
      (click)="openNewTab()"
      matTooltip="Open in new tab"
      data-test="iframe-modal-new-tab"
      >open_in_new</mat-icon
    >
    <mat-icon class="cursor-pointer" (click)="handleClose()" data-test="iframe-modal-close">close</mat-icon>
  </div>
  <div class="w-full h-full p-[10px]">
    <iframe height="100%" width="100%" *ngIf="url" [src]="url"></iframe>
  </div>
</div>
