import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { DRAG_DIRECTIONS, SelectionWidgetRenderer } from './selection-widget-renderer';

export class LineSelectionWidgetRenderer extends SelectionWidgetRenderer {
  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {
    super(canvasDocument, element);
  }

  public draw(ctx, drawHandles = true) {
    if (!this.element.elementDefinition.lineDefinition) {
      return;
    }
    const { x1, y1, x2, y2 } = this.element.elementDefinition.lineDefinition;

    if (drawHandles) {
      const dragHandleWidth = this.getHandleWidth();
      const dragHandlePositions = [
        [x1, y1],
        [x2, y2],
      ];
      for (let i = 0; i < dragHandlePositions?.length; i++) {
        const dragHandlePosition = dragHandlePositions[i];
        this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
      }
    }
  }

  public getDragHandle(posX, posY): DRAG_DIRECTIONS {
    let direction;
    if (!this.element.elementDefinition.lineDefinition) {
      return direction;
    }
    const { x1, y1, x2, y2 } = this.element.elementDefinition.lineDefinition;
    if (this.isOnDragHandle(posX, x1) && this.isOnDragHandle(posY, y1)) {
      direction = DRAG_DIRECTIONS.TOP_LINE;
    } else if (this.isOnDragHandle(posX, x2) && this.isOnDragHandle(posY, y2)) {
      direction = DRAG_DIRECTIONS.BOTTOM_LINE;
    }
    return direction;
  }
}
