<app-modal [title]="title" [dialogRef]="dialogRef">
  <mat-form-field class="w-[300px] mt-[-20px]">
    <mat-label>Preset Name</mat-label>
    <input [formControl]="nameFormControl" matInput data-test="new-view-name" />
  </mat-form-field>
  <div>
    <mat-radio-group class="flex flex-col" [formControl]="viewTypeFormControl">
      <mat-radio-button class="mb-2" value="private">
        <div class="flex">
          <mat-icon class="mx-[5px] text-[20px]">person</mat-icon>
          <span class="font-medium">Private</span>
        </div>
      </mat-radio-button>
      <mat-radio-button [disabled]="!isOrgAdmin" class="flex" value="admin">
        <div class="flex">
          <mat-icon class="mx-[5px] text-[20px]">group</mat-icon>
          <span class="font-medium">Organization</span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="actions">
    <div class="cursor-pointer text-accent" (click)="cancel()" *ngIf="!isAlreadySubmitted" data-test="cancel-button">
      Cancel
    </div>
    <button
      class="small-button"
      [disabled]="!isValid() || isAlreadySubmitted"
      mat-raised-button
      (click)="create()"
      color="primary"
      data-test="submit-button"
    >
      Ok
    </button>
  </div>
</app-modal>
