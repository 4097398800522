import { DocumentElement, StyleDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { ImageElement } from '../../components/image-element/image-element';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';
import { ImageLoaderResponse } from '../image/canvas-image-loader';
import { TextImageGenerator } from '../text/text-image-generator';
import { TextStyle } from '../text/text-style';
import { StickyNoteLoaderService } from './background/sticky-note-loader.service';
import {
  STICKY_ALIGN,
  STICKY_BG_COLOR,
  STICKY_COLOR,
  STICKY_FONT_FAMILY,
  STICKY_FONT_SIZE,
  STICKY_PADDING,
  STICKY_VALIGN,
  STICKY_WORD_WRAP,
} from './editor/sticky-note-editor';
import { StickyNoteEditorManager } from './editor/sticky-note-editor-manager';

export class CanvasStickyNoteElement extends CanvasElement {
  private backgroundImage: ImageLoaderResponse = null;
  private textImageElement: ImageElement = null;
  private currentElementDefinition: DocumentElement = null;
  private textStyle: TextStyle;
  private correctedFontSize = false;
  protected isLoadingInProgress = false;
  public maxFontSize: number;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    elementDefinition.style = ObjectUtil.mergeDeep(elementDefinition?.style || {}, {
      backgroundColor: elementDefinition?.style?.backgroundColor || STICKY_BG_COLOR,
      border: {
        width: 0,
      },
      font: {
        family: elementDefinition?.style?.font?.family || STICKY_FONT_FAMILY,
        size: elementDefinition?.style?.font?.size || STICKY_FONT_SIZE,
      },
      text: {
        align: elementDefinition?.style?.text?.align || STICKY_ALIGN,
        valign: elementDefinition?.style?.text?.valign || STICKY_VALIGN,
      },
      color: elementDefinition?.style?.color || STICKY_COLOR,
    });
    if (!elementDefinition?.scale) {
      elementDefinition.scale = { x: 1, y: 1 };
    }
    if (!elementDefinition.text) {
      elementDefinition.text = '';
    }
    super(elementDefinition, canvasDocument, interactable);
    this.isTextEditable = true;
    this.isAsync = true;
    this.DEFAULT_BORDER_SIZE = 0;
    this.TEXT_PADDING = STICKY_PADDING;
    this.VALID_PROPERTIES = ['style.text.valign'];
    this.EDITOR_INLINE_STYLE_COMMANDS = ['textColor', 'bold', 'italic', 'underline', 'strikethrough'];
    this.EDITOR_STYLE_COMMANDS = [
      'textValign',
      'textAlign',
      'autoFontSize',
      'fontFamily',
      'backgroundColor',
      'stickyNoteSize',
    ];
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    this.drawBackground(ctx, { x, y, width, height });

    if (this.isVisible) {
      this.drawText(ctx, { x, y, width, height }, options);
    }

    return;
  }

  private drawText(ctx, { x, y, width, height }, options?: DrawOptions) {
    if (width === 0 || height === 0) {
      return;
    }
    this.textStyle = new TextStyle(this);
    if (!this.textImageElement || this.hasChanged()) {
      if (!this.isLoadingInProgress) {
        if (this.canvasDocument.mode === 'EDIT' && !this.correctedFontSize) {
          this.correctFontSize();
          this.correctedFontSize = true;
        }
        if (this.canvasDocument.mode === 'SNAPSHOT') {
          console.log('Warning: generating text image while making snapshot', this);
        }
        this.textImageElement = null;
        this.generateTextImage(ctx, { x, y, width, height });
      }
    } else {
      if (this.textImageElement) {
        let imgW = this.textImageElement?.img?.width;
        let imgH = this.textImageElement?.img?.height;
        if (options?.scaleBy) {
          imgW = imgW / options.scaleBy;
          imgH = imgH / options.scaleBy;
        }
        ctx.drawImage(this.textImageElement.img, -width * 0.5, -height * 0.5, imgW, imgH);
      }
    }
  }

  /**
   * Make sure text fits on a sticky note on load
   */
  private correctFontSize() {
    const newFontSize = this.canvasDocument?.editorHandler?.stickyNoteEditorManager?.getMaxFontSize(this);
    if (newFontSize && newFontSize < this.elementDefinition?.style?.font?.size) {
      console.log('Correcting font size');
      const style: StyleDefinition = {
        font: {
          size: newFontSize,
          sizeMode: 'auto',
        },
      };
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        [
          {
            id: this.elementDefinition.id,
            style,
          },
        ],
        [],
      );
      this.elementDefinition.style = ObjectUtil.mergeDeep(this.elementDefinition?.style || {}, style);
      this.textStyle = new TextStyle(this);
    }
  }

  public autoAdjustSize({ force, text }: { force?: boolean; text?: string } = { force: false }): DocumentElement {
    if (force) {
      const editorManager = this.canvasDocument.editorHandler.getManager(this) as StickyNoteEditorManager;
      let newFontSize = editorManager.getMaxFontSize(this);
      let sizeMode = this?.elementDefinition?.style?.font?.sizeMode;
      this.maxFontSize = newFontSize; // update max font size for element
      if (newFontSize && this.elementDefinition?.style?.font?.sizeMode === 'custom') {
        if (newFontSize < this.elementDefinition.style.font.size) {
          sizeMode = 'auto';
        } else {
          newFontSize = this.elementDefinition.style.font.size;
        }
        newFontSize = Math.min(newFontSize, this.elementDefinition.style.font.size);
      }
      if (newFontSize) {
        const style: DocumentElement = {
          style: {
            font: {
              size: newFontSize,
              sizeMode,
            },
          },
        };
        return style;
      }
      editorManager.editor.emitTextEditorEvent(this); // emit new max font size for sticky note
    }
    return;
  }

  private async generateTextImage(ctx, { x, y, width, height }) {
    this.currentElementDefinition = ObjectUtil.cloneDeep(this.elementDefinition);
    this.textImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      this.elementDefinition.text,
      { width, height, padding: STICKY_PADDING, wordWrap: STICKY_WORD_WRAP },
      this.textStyle,
      true,
      null,
      false,
    );
    this.canvasDocument.debounceDraw();
    this.isLoadingInProgress = false;
  }

  private hasChanged() {
    const oldData = ObjectUtil.cloneDeep(this.currentElementDefinition) || {};
    delete oldData.position; // no need to redraw if position changes
    delete oldData.rotate; // no need to redraw if rotation changes
    delete oldData.scale;
    delete oldData.isLocked;
    const newData = ObjectUtil.cloneDeep(this.elementDefinition);
    delete newData.position;
    delete newData.rotate;
    delete newData.scale;
    delete newData.isLocked;
    return Object.keys(oldData).length > 0 && JSON.stringify(oldData) !== JSON.stringify(newData);
  }

  private drawBackground(ctx, { x, y, width, height }) {
    const backgroundId = `${this.elementDefinition?.style?.backgroundColor || STICKY_BG_COLOR}${this.elementDefinition.size.width / this.elementDefinition.size.height === 1 ? '' : '_r'}`;
    if (
      !this.backgroundImage ||
      (this.backgroundImage?.imageElement?.id && this.backgroundImage?.imageElement?.id !== backgroundId)
    ) {
      this.backgroundImage = StickyNoteLoaderService.images.get(backgroundId);
    }
    if (this.backgroundImage?.imageElement) {
      ctx.drawImage(this.backgroundImage.imageElement, -width * 0.5, -height * 0.5, width, height);
    }
  }

  public isMouseOnEditableArea(event: MouseEvent) {
    const position = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    return this.isPointOnElement(position.x, position.y, STICKY_PADDING); // includes margin when determining editable area
  }

  public onSelect() {
    if (!this.isSelected) {
      this.canvasDocument?.editorHandler?.getCurrentStyle(this);
    }
  }

  public async preload(options?: DrawOptions) {
    const { width, height } = this.getSize(Object.assign({}, options, { skipScale: true }));
    this.textStyle = new TextStyle(this);
    this.textImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      this.elementDefinition.text,
      { width, height, padding: STICKY_PADDING, wordWrap: STICKY_WORD_WRAP },
      this.textStyle,
      true,
      null,
      false,
    );
    return this.textImageElement;
  }

  public toSVG({ x, y, width, height, href }): HTMLElement {
    const element = document.createElement('image');
    element.setAttributeNS('w3.org/1999/xlink', 'xlink:href', href);
    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);
    return element;
  }

  public async loadAsSVG({ x, y, width, height }): Promise<HTMLElement> {
    const backgroundId = `${this.elementDefinition?.style?.backgroundColor || STICKY_BG_COLOR}${this.elementDefinition.size.width / this.elementDefinition.size.height === 1 ? '' : '_r'}`;
    const backgroundImage = StickyNoteLoaderService.images.get(backgroundId);
    const textImageElement = await this.preload();

    const parentSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    parentSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    parentSvg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    parentSvg.setAttribute('viewBox', `0 0 ${width} ${height}`);

    const backgroundElement = document.createElement('image');
    backgroundElement.setAttributeNS('w3.org/1999/xlink', 'xlink:href', backgroundImage.imageElement.src);
    backgroundElement.setAttribute('x', '0');
    backgroundElement.setAttribute('y', '0');
    backgroundElement.setAttribute('width', `${width}px`);
    backgroundElement.setAttribute('height', `${height}px`);

    const textElement = document.createElement('image');
    textElement.setAttributeNS('w3.org/1999/xlink', 'xlink:href', textImageElement.img.src);
    textElement.setAttribute('x', '0');
    textElement.setAttribute('y', '0');
    textElement.setAttribute('width', `${width}px`);
    textElement.setAttribute('height', `${height}px`);

    parentSvg.appendChild(backgroundElement);
    parentSvg.append(textElement);

    const data = 'data:image/svg+xml,' + encodeURIComponent(parentSvg.outerHTML);
    const dpr = this.canvasDocument.getDevicePixelRatio();
    const base64 = await ImageElement.toCanvasDataUrl(data, 'image/png', 1.0, dpr, true);

    return this.toSVG({
      x,
      y,
      width,
      height,
      href: base64,
    });
  }
}
