import { PositionDefinition, SizeDefinition, StyleDefinition } from '@contrail/documents';
import { DrawOptions } from '../../../../renderers/canvas-renderer';
import { Table, TableCell } from './table';
import { TableRange } from './table-range';

export class TableRenderer {
  private tableRange: TableRange;

  constructor(private table: Table) {
    this.tableRange = new TableRange(0, this.table.rows.length - 1, 0, this.table.columns.length - 1);
  }

  public render(ctx, params: { x; y; width; height }, options?: DrawOptions) {
    this.renderLines(ctx, params, options);
  }

  private renderLines(ctx, params: { x; y; width; height }, options?: DrawOptions) {
    this.renderRange(ctx, params, this.tableRange, {
      strokeStyle: this.table.style.border.color,
    });
  }

  private height(index) {
    return this.table.rows[index].height;
  }

  private width(index) {
    return this.table.columns[index].width;
  }

  private y(index) {
    return this.table.rows[index].y;
  }

  private x(index) {
    return this.table.columns[index].x;
  }

  /**
   * Render @range withing @this table
   * @param ctx
   * @param param1
   * @param range
   * @param param3
   */
  public renderRange(
    ctx,
    { x, y, width, height },
    range: TableRange,
    { strokeStyle, fillStyle }: { strokeStyle; fillStyle? },
  ) {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.rect(0, 0, width, height);

    let prevY = 0;
    range.eachRow((index) => {
      const rowHeight = prevY + this.height(index);
      ctx.moveTo(0, rowHeight);
      ctx.lineTo(width, rowHeight);
      prevY = rowHeight;
    });

    let prevX = 0;
    range.eachColumn((index) => {
      const columnWidth = prevX + this.width(index);
      ctx.moveTo(columnWidth, 0);
      ctx.lineTo(columnWidth, height);
      prevX = columnWidth;
    });

    if (fillStyle) {
      ctx.fillStyle = fillStyle;
      ctx.fill();
    }

    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.restore();
  }

  /**
   * Get cell at mouse position @px and @py
   * Set cell's position and size.
   * Set cell's row and column. Row and column can be -1
   * if @px or @py is outside of table dimensions.
   * @param px
   * @param py
   * @param param2
   * @returns
   */
  public getCellAt(px, py, { x, y }: PositionDefinition): TableCell {
    const cell = {
      rowIndex: 0,
      columnIndex: 0,
      x,
      y,
      width: 0,
      height: 0,
    };

    // row
    while (cell.y < py && cell.rowIndex < this.table.rows.length) {
      const h = this.height(cell.rowIndex++);
      cell.y += h;
      cell.height = h;
    }
    cell.y -= cell.height;
    cell.rowIndex--;

    while (cell.x < px && cell.columnIndex < this.table.columns.length) {
      const w = this.width(cell.columnIndex++);
      cell.x += w;
      cell.width = w;
    }
    cell.x -= cell.width;
    cell.columnIndex--;

    return cell;
  }

  /**
   * Range position and size relative to table position
   * @param range
   * @returns
   */
  public getRangeDimensions(range: TableRange): { position: PositionDefinition; size: SizeDefinition } {
    let width = 0,
      height = 0;

    const position = {
      x: this.x(range.startColumn),
      y: this.y(range.startRow),
    };

    range.eachColumn((index) => {
      width += this.width(index);
    });

    range.eachRow((index) => {
      height += this.height(index);
    });

    return { position, size: { width, height } };
  }
}
