import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { EditorContainer } from '../../../components/editor/editor-container';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasStickyNoteElement } from '../canvas-sticky-note-element';
import { stickyEditorId, STICKY_FONT_SIZES, STICKY_MAX_FONT_SIZE, STICKY_MIN_FONT_SIZE } from './sticky-note-editor';

export const MAX_LENGTH = 2436;
export const LINE_HEIGHT = 1.2;

export class StickyNoteEditorContainer extends EditorContainer {
  constructor(canvasDocument: CanvasDocument, manager: EditorManager) {
    super(canvasDocument, stickyEditorId, manager);
  }

  public handleKeydownEvent(event) {
    if (
      event.code !== 'Delete' &&
      event.code !== 'Backspace' &&
      this.manager.editor.getEditor().getContent({ format: 'text' })?.length >= MAX_LENGTH
    ) {
      event.preventDefault();
    }
  }

  public handleInputEvent(event: any): void {
    this.autoFontSize();
  }

  public autoFontSize() {
    const container = this.editorContainer as HTMLElement;
    const target = this.editor as HTMLElement;
    container.style.visibility = 'hidden';
    let newFontSize = StickyNoteEditorContainer.calculateNewFontSize(
      target,
      this.manager.editor.getEditor()?.getContent({ format: 'text' }),
      this.currentContainerHeight - this.manager.element.TEXT_PADDING * 2,
      this.currentContainerWidth - this.manager.element.TEXT_PADDING * 2,
    );
    if (
      newFontSize &&
      (this.manager.elementDefinition?.style?.font?.sizeMode !== 'custom' ||
        newFontSize < this.manager.elementDefinition?.style?.font?.size)
    ) {
      const font = {
        font: {
          size: newFontSize,
          sizeMode: 'auto',
        },
      };
      this.manager.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(this.manager.elementDefinition), {
        style: font,
      });
      this.manager.element.elementDefinition.style = ObjectUtil.mergeDeep(
        this.manager.element.elementDefinition.style || {},
        font,
      );
    }
    (this.manager.element as CanvasStickyNoteElement).maxFontSize = newFontSize;
    this.manager.editor.setContentStyle();
    container.style.visibility = 'visible';
  }

  public static calculateNewFontSize(target: HTMLElement, text: string, height: number, width: number) {
    let newFontSize;
    for (let i = STICKY_MAX_FONT_SIZE; i >= STICKY_MIN_FONT_SIZE; i -= 2) {
      const fontSize = i;
      target.style.fontSize = fontSize + 'px';
      if (
        target.clientHeight <= height &&
        target.scrollWidth <= width
        // fit sentence that consists of one long word on one line
        // && ((text?.length < 17 && text?.indexOf(' ') === -1 && (text.match(/\n/g)||[]).length === 0) ? target.clientHeight <= Math.round(fontSize * LINE_HEIGHT) : true)
      ) {
        newFontSize = fontSize;
        break;
      }
    }
    return newFontSize;
  }
}
