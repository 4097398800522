<app-type-property-form
  [propertyConfigurations]="propertyConfigurations"
  [values]="entity"
  [hints]="hints"
  [hintStyle]="hintStyle"
  [hintIcon]="hintIcon"
  [hintIconType]="hintIconType"
  [errors]="errors"
>
</app-type-property-form>
