import {
  CropDefinition,
  DocumentElement,
  PositionDefinition,
  ScaleTransformation,
  SizeDefinition,
  TranslateTransformation,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasImageDrawableElement } from '../../../elements/image/canvas-image-drawable-element';
import { RotationHelper } from '../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { DRAG_DIRECTIONS_TRANSFORM, RESIZE_DRAG_DIRECTIONS } from '../drag-event-handlers/element-resize-handler';

export class CropElementResizeHandler {
  private startingPosition: PositionDefinition;
  private startingCropDefinition: CropDefinition;
  private selectedElement: CanvasImageDrawableElement;
  private startingElement: DocumentElement;
  private transform: TranslateTransformation;
  private scale: number;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    const { element, target } = elementTarget;

    if (
      element &&
      this.canvasDocument.isCropping(element.id) &&
      elementTarget &&
      RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1 &&
      element.isCropEnabled &&
      this.canvasDocument.isCropping(element.id)
    ) {
      const imageElement = element as CanvasImageDrawableElement;
      if (!imageElement.imageSize) {
        console.error('No original image size', imageElement);
        this.clear();
        this.canvasDocument.cancelCrop();
      }
      this.transform = DRAG_DIRECTIONS_TRANSFORM[target];
      const viewBox = this.canvasDocument.getViewBox();
      const scale = this.canvasDocument.getViewScale();
      this.startingPosition = { x: event.clientX + viewBox.x * scale.x, y: event.clientY + viewBox.y * scale.y };
      this.selectedElement = imageElement;
      this.startingElement = ObjectUtil.cloneDeep(element.elementDefinition);

      const size = this.selectedElement.getSize();
      this.startingCropDefinition = this.selectedElement.getCropDefinition();
      this.scale = Math.min(
        size.width / (this.startingCropDefinition?.width ?? this.selectedElement.imageSize.width),
        size.height / (this.startingCropDefinition?.height ?? this.selectedElement.imageSize.height),
      );
    }
  }

  public dragged(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.canvasDocument.draw();
    this.clear();
  }

  private clear() {
    this.startingElement = null;
    this.selectedElement = null;
    this.startingPosition = null;
    this.startingCropDefinition = null;
    this.transform = null;
  }

  private updateSelectedElementCropPosition(dx, dy, shiftKey): boolean {
    dx = dx / this.scale;
    dy = dy / this.scale;

    // Get dx and dy in rotated coordinate system
    const angle = this.startingElement?.rotate?.angle;
    if (angle) {
      const rotatedChangePosition = RotationHelper.rotate(
        {
          x: dx,
          y: dy,
        },
        360 - angle,
      );
      dx = rotatedChangePosition.x;
      dy = rotatedChangePosition.y;
    }

    const imgWidth = this.selectedElement.imageSize.width;
    const imgHeight = this.selectedElement.imageSize.height;
    const x1 = this.startingCropDefinition?.x1 ?? 0;
    const y1 = this.startingCropDefinition?.y1 ?? 0;
    const x2 = this.startingCropDefinition?.x2 ?? imgWidth;
    const y2 = this.startingCropDefinition?.y2 ?? imgHeight;
    const aspectRatio = Math.max(
      (imgWidth + this.transform.x * dx) / imgWidth,
      (imgHeight + this.transform.y * dy) / imgHeight,
    );
    const size = {
      width: imgWidth * aspectRatio,
      height: imgHeight * aspectRatio,
    };

    size.width = Math.max(size.width, this.transform.x >= 0 ? x2 : imgWidth - x1);
    size.height = Math.max(size.height, this.transform.y >= 0 ? y2 : imgHeight - y1);

    const scaleDiff = Math.max(size.width / imgWidth, size.height / imgHeight);
    const cropWidth = this.startingCropDefinition?.width ?? imgWidth;
    const cropHeight = this.startingCropDefinition?.height ?? imgHeight;
    const newCropWidth = cropWidth / scaleDiff;
    const newCropHeight = cropHeight / scaleDiff;
    const newCropX = this.transform.x >= 0 ? x1 / scaleDiff : imgWidth - (imgWidth - x2) / scaleDiff - newCropWidth;
    const newCropY = this.transform.y >= 0 ? y1 / scaleDiff : imgHeight - (imgHeight - y2) / scaleDiff - newCropHeight;
    this.selectedElement.elementDefinition.cropDefinition = CropElementResizeHandler.limitAndGetCrop(
      newCropX,
      newCropY,
      newCropWidth,
      newCropHeight,
      imgWidth,
      imgHeight,
    );
    this.selectedElement.uncroppedUnrotatedPosition = this.selectedElement.getUncroppedUnrotatedPosition();
    return true;
  }

  public static round(v) {
    return Math.round(v * 10000) / 10000;
  }

  public static limitCrop(
    x1,
    y1,
    width,
    height,
    imgWidth,
    imgHeight,
  ): { x1: number; y1: number; width: number; height: number } {
    const round = this.round;
    x1 = Math.min(Math.max(0, x1), imgWidth - width);
    y1 = Math.min(Math.max(0, y1), imgHeight - height);
    x1 = round(x1);
    y1 = round(y1);
    width = round(width);
    height = round(height);
    return { x1, y1, width, height };
  }

  public static limitAndGetCrop(newCropX, newCropY, newCropWidth, newCropHeight, imgWidth, imgHeight): CropDefinition {
    const { x1, y1, width, height } = CropElementResizeHandler.limitCrop(
      newCropX,
      newCropY,
      newCropWidth,
      newCropHeight,
      imgWidth,
      imgHeight,
    );
    return this.getCropPercent(x1, y1, width, height, imgWidth, imgHeight);
  }

  public static getCropPercent(x1, y1, width, height, imgWidth, imgHeight): CropDefinition {
    const x2 = x1 + width;
    const y2 = y1 + height;
    const x1Percent = x1 / imgWidth;
    const widthPercent = width / imgWidth;
    const x2Percent = x1Percent + widthPercent;
    const y1Percent = y1 / imgHeight;
    const heightPercent = height / imgHeight;
    const y2Percent = y1Percent + heightPercent;
    return {
      x1,
      y1,
      x2,
      y2,
      width,
      height,
      x1Percent,
      y1Percent,
      x2Percent,
      y2Percent,
      widthPercent,
      heightPercent,
    };
  }
}
