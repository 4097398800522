import { Component, Input, OnInit } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { DocumentService } from '../document.service';
import { PaintFormatterAction, PaintFormatterActionService } from './paint-formatter-action.service';

@Component({
  selector: 'app-paint-formatter',
  templateUrl: './paint-formatter.component.html',
  styleUrls: ['./paint-formatter.component.scss'],
})
export class PaintFormatComponent implements OnInit {
  @Input() tooltipPosition = 'right';
  constructor(
    private documentService: DocumentService,
    private paintFormatActionService: PaintFormatterActionService,
  ) {}

  ngOnInit(): void {}

  paint() {
    const interactionMode = this.documentService.getInteractionMode();
    if (interactionMode === PaintFormatterAction.PAINT_FORMAT_HOLD) {
      this.documentService.setInteractionMode('root');
      this.documentService.handleActionRequest(new ActionRequest('root'));
    } else {
      this.documentService.setInteractionMode(PaintFormatterAction.PAINT_FORMAT);
      this.documentService.handleActionRequest(new ActionRequest(PaintFormatterAction.PAINT_FORMAT));
    }
  }

  holdPaint() {
    // double click to hold the paint button
    this.documentService.setInteractionMode(PaintFormatterAction.PAINT_FORMAT_HOLD);
    this.documentService.handleActionRequest(new ActionRequest(PaintFormatterAction.PAINT_FORMAT_HOLD));
  }

  getActiveColor() {
    const interactionMode = this.documentService.getInteractionMode();
    return PaintFormatterAction.PAINT_FORMAT === interactionMode ||
      PaintFormatterAction.PAINT_FORMAT_HOLD === interactionMode
      ? 'accent'
      : '';
  }

  isDisabled() {
    const interactionMode = this.documentService.getInteractionMode();
    const selectedElements = this.documentService.getSelectedElements();
    return (
      selectedElements &&
      selectedElements.length !== 1 &&
      PaintFormatterAction.PAINT_FORMAT !== interactionMode &&
      PaintFormatterAction.PAINT_FORMAT_HOLD !== interactionMode
    );
  }
}
