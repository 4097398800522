<div class="header">
  <mat-icon (click)="handleCancel()">arrow_back_ios_new</mat-icon>
  <ng-container *ngIf="step === 'OPTION_TYPE'"> Select source </ng-container>
  <ng-container *ngIf="step === 'ASSORTMENT_SELECT'"> Select assortment </ng-container>
  <ng-container *ngIf="step === 'WORKSPACE_SELECT'"> Select project </ng-container>
  <ng-container *ngIf="step === 'DOCUMENT_SELECT'"> Select document </ng-container>
</div>

<div *ngIf="step === 'OPTION_TYPE'" class="source-type-selector">
  <div
    class="source-type-option"
    [ngClass]="{ disabled: option.disabled }"
    (click)="selectSourceOptionType(option)"
    *ngFor="let option of optionTypesList"
  >
    <img *ngIf="option.icon" [src]="option.icon" class="w-10 h-10 object-contain my-auto mx-2" />
    <div class="option-description">
      <div class="name">{{ option.name | titlecase }}</div>
      <div class="description">{{ option.description }}</div>
    </div>
  </div>
</div>

<div *ngIf="step === 'WORKSPACE_SELECT'" class="source-selector">
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Project</mat-label>
    <mat-select [formControl]="sourceWorkspaceControl" data-test="item-data-source-selector-update-workspace">
      <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
        {{ workspace.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="selectionButton">
    <button
      mat-raised-button
      color="primary"
      (click)="selectWorkspace()"
      data-test="item-data-source-selector-select-workspace"
    >
      Select
    </button>
  </div>
</div>

<div *ngIf="step === 'DOCUMENT_SELECT'" class="source-selector h-full overflow-auto narrow-scroll">
  <div class="flex-center w-full h-96" *ngIf="loading$ | async; else loaded">
    <app-mat-spinner [message]="'Loading Document'" [overlay]="false" [loading]="true"></app-mat-spinner>
  </div>

  <ng-template #loaded>
    <app-folder-breadcrumb-nav [rootFolder]="selectedWorkspace"></app-folder-breadcrumb-nav>

    <div class="entity-container" data-test="data-source-selector-document">
      <div
        *ngFor="let entity of transformedEntities$ | async"
        class="entity-item flex items-center"
        (click)="selectDocumentEntity(entity)"
      >
        <mat-icon class="!text-blue-300" *ngIf="entity.matIcon">{{ entity.matIcon }}</mat-icon>
        <img *ngIf="!entity.matIcon" [src]="entity.icon" class="w-6 h-6 object-contain" />
        <span [matTooltip]="entity?.name" [matTooltipDisabled]="entity?.name.length < 30">{{
          entity.name | shorten: 30
        }}</span>
      </div>

      <ng-container *ngIf="!(transformedEntities$ | async)?.length">
        <span class="py-10 w-full flex-center">No Entities</span>
      </ng-container>
    </div>
  </ng-template>
</div>

<!-- <div *ngIf="step === 'ASSORTMENT_SELECT'" class="source-selector">
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Source Workspace</mat-label>
    <mat-select [formControl]="sourceWorkspaceControl" (ngModelChange)="handleWorkspaceChange($event)" data-test="select-source-workspace">
      <mat-option *ngFor="let workspace of (sourceWorkspaces$ | async)" [value]="workspace">
        {{ workspace.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-form-field  *ngIf="(sourceAssortments$ | async)?.length"  class="w-full" appearance="fill">
      <mat-label>Assortment</mat-label>
      <mat-select [formControl]="assortmentFormControl" (ngModelChange)="handleAssortmentChange()" data-test="select-source-assortment">
        <mat-option *ngFor="let assortment of (sourceAssortments$ | async)" [value]="assortment">
          {{assortment.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="warning" *ngIf="(sourceAssortments$ | async)?.length === 0">
      The selected workspace does not contain an eligible assortment.
    </div>
  </div>
  <div class="selectionButton" *ngIf="selectedSource">
    <button mat-raised-button color="primary" (click)="handleSelectSource()">Ok</button>
  </div>
</div> -->
