import { Component, Inject, Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

interface ConfirmationData {
  message: string;
  title: string;
  skipCancel: boolean;
  cancel?: string;
  icon?: boolean;
  okBtnLabel?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationBoxService {
  constructor(public dialog: MatDialog) {}

  public open(
    title: string,
    message: string,
    cancel?: string,
    okBtnLabel: string = 'Ok',
    icon: boolean = true,
    skipCancel = false,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
        data: { message, title, cancel, icon, okBtnLabel, skipCancel },
        disableClose: skipCancel,
      });
      dialogRef.afterClosed().subscribe((value) => {
        resolve(value);
      });
    });
  }

  public close() {
    this.dialog?.closeAll();
  }
}

@Component({
  template: `
    <div class="modal-container mb-2 flex flex-col">
      <div class="flex justify-between">
        <h6 data-test="app-confirmation-box-title">{{ data.title }}</h6>
        <mat-icon *ngIf="!data.skipCancel" [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
      </div>
      <div class="flex items-center mt-5">
        <span *ngIf="data.icon" class="material-icons text-warn-light text-5xl mr-5">warning</span>
        <div data-test="app-confirmation-box-message" class="text-sm" [innerHTML]="data.message"></div>
      </div>
      <div mat-dialog-actions class="flex items-center justify-end mt-3">
        <button
          *ngIf="!data.skipCancel"
          data-test="app-confirmation-box-cancel-button"
          mat-stroked-button
          color="primary"
          [mat-dialog-close]="false"
          class="!mr-3"
        >
          {{ data.cancel || 'Cancel' }}
        </button>
        <button
          data-test="app-confirmation-box-ok-button"
          mat-raised-button
          color="primary"
          [mat-dialog-close]="true"
          cdkFocusInitial
        >
          {{ data.okBtnLabel }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .modal-container {
        max-width: 600px;
      }
    `,
  ],
})
export class ConfirmationBoxComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData,
  ) {}
}
