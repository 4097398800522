<h3>Canvas Rendering</h3>
<div>Render Count: {{ renderCount$ | async }}</div>
<div class="flex">
  <div class="font-bold" style="width: 300px">Time</div>
  <div class="font-bold" style="width: 200px">Render Duration</div>
  <div class="font-bold" style="width: 200px">Visible Elements</div>
</div>
<div style="max-height: 60vh; overflow-y: scroll">
  <div class="flex" *ngFor="let metric of metrics">
    <div style="width: 300px">{{ metric.start | date: 'hh:mm:ss   SSS' }}</div>
    <div style="width: 200px">{{ metric.time }}</div>
    <div style="width: 200px">{{ metric.visibleElementCount }}</div>
  </div>
</div>
