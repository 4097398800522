import { DocumentElement, DocumentTextElementEvent } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { constants } from 'fs';
import { CanvasDocument, CopiedProperties } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { EditorFormatter } from '../../../components/editor/editor-formatter';
import { TextChanges } from '../../../components/editor/editor-handler';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { TextEditor, TEXT_BOX_PADDING } from './text-editor';
import { TextEditorContainer } from './text-editor-container';
import { TextEditorPlaceholder } from './text-editor-placeholder';

export class TextEditorManager extends EditorManager {
  constructor(canvasDocument: CanvasDocument) {
    super(canvasDocument, { hideElement: true, setLastAppliedTextFormat: true }, {});
    this.editorContainer = new TextEditorContainer(canvasDocument, this);
    this.editor = new TextEditor(canvasDocument, this);
    this.editorPlaceholder = new TextEditorPlaceholder(canvasDocument, this);
  }

  public applyCopiedProperties(properties: CopiedProperties) {
    const textEventChanges = [];
    for (const type in properties?.textFormat) {
      if (EditorFormatter.FORMATS.indexOf(type) !== -1) {
        let value = properties.textFormat[type];
        if (['bold', 'italic', 'underline', 'strikethrough'].indexOf(type) !== -1) {
          value = properties.textFormat[type] === 'normal' ? false : true;
        }
        textEventChanges.push({
          element: null,
          textFormat: {
            type,
            value,
          },
        });
      }
    }

    console.log('TextEditorManager.applyCopiedProperties', textEventChanges);
    const elementChanges = {
      textChanges: [],
      textUndoChanges: [],
    };
    if (this?.editor?.isFocused && this.element) {
      this.assignTextEventChanges(this.element, textEventChanges, elementChanges);
      // if (properties?.element) {
      //   elementChanges.textChanges = [ObjectUtil.mergeDeep((elementChanges.textChanges[0] || {}), this.getValidStyleProperty(this.element, properties.element))];
      // }
    } else {
      const selectedElements: Array<CanvasElement> = this.canvasDocument?.interactionHandler?.selectionHandler
        ?.getSelectedUnlockedCanvasElements()
        .filter((element) => element.elementDefinition.type === 'text');
      if (selectedElements?.length > 0 && textEventChanges?.length > 0) {
        for (let i = 0; i < selectedElements?.length; i++) {
          const element: CanvasElement = selectedElements[i];
          const textChanges = {
            textChanges: [],
            textUndoChanges: [],
          };
          this.assignTextEventChanges(element, textEventChanges, textChanges, false);
          if (properties?.element) {
            textChanges.textChanges = [
              ObjectUtil.mergeDeep(
                textChanges.textChanges[0] || {},
                this.getValidStyleProperty(element, properties.element),
              ),
            ];
          }
          elementChanges.textChanges = elementChanges.textChanges.concat(textChanges.textChanges);
          elementChanges.textUndoChanges = elementChanges.textUndoChanges.concat(textChanges.textUndoChanges);
        }
      }
    }

    if (elementChanges?.textChanges?.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        elementChanges.textChanges,
        elementChanges.textUndoChanges,
      );
    }
  }

  private getValidStyleProperty(element: CanvasElement, otherElement: DocumentElement): DocumentElement {
    const otherCanvasElement = this.canvasDocument.getCanvasElementById(otherElement?.id);
    if (element?.elementDefinition?.type === 'text' && otherCanvasElement) {
      const newElement = {};
      element?.VALID_PROPERTIES?.forEach((path) => {
        const isOtherValid = otherCanvasElement.isPropertyValid(path);
        if (isOtherValid) {
          let value = ObjectUtil.getByPath(otherElement, path);
          if (value && path === 'style.font.size' && otherElement?.scale?.x) {
            value = '' + Math.round(parseFloat(value) * otherElement.scale.x);
          }
          ObjectUtil.setByPath(newElement, path, value);
        }
      });
      return newElement;
    }
    return {};
  }

  public applyStyleProperty(element: CanvasElement, changes: DocumentTextElementEvent): TextChanges {
    const data = {
      textChanges: {},
      textUndoChanges: {},
    };
    if (
      element.elementDefinition.type === 'text' &&
      !element.elementDefinition.isTextTool &&
      changes.textFormat?.type === 'textValign' &&
      changes.textFormat.value
    ) {
      data.textChanges = {
        id: element.id,
        style: { text: { valign: changes.textFormat.value } },
      };
      data.textUndoChanges = {
        id: element.id,
        style: { text: { valign: element?.elementDefinition?.style?.text?.valign } },
      };
    } else if (
      element.elementDefinition.type === 'text' &&
      element.elementDefinition.isTextTool &&
      changes.textFormat?.type === 'fontSize' &&
      changes.textFormat.value
    ) {
      data.textUndoChanges = {
        id: element.id,
        style: { font: { size: element?.elementDefinition?.style?.font?.size ?? 11 } },
        scale: { x: 1, y: 1 },
        text: element.elementDefinition.text,
      };
      data.textChanges = {
        id: element.id,
        style: { font: { size: changes.textFormat.value } },
        scale: { x: 1, y: 1 },
        text: CanvasUtil.removeFontSize(element.elementDefinition.text),
      };
    }
    return data;
  }

  /**
   * Switch text types to text tool or text box
   * @param element
   * @param changes
   * @param text
   * @returns
   */
  protected applyTextType(element: CanvasElement, changes: DocumentTextElementEvent, text): TextChanges {
    const textChanges = {
      textChanges: {},
      textUndoChanges: {},
    };
    if (changes?.textFormat?.type === 'textType' && changes?.textFormat?.value) {
      const textType = changes?.textFormat?.value;
      if (textType === 'text-box') {
        if (element.elementDefinition.type === 'text' && element.elementDefinition.isTextTool) {
          const undoElement: DocumentElement = {
            id: element.id,
            isTextTool: element.elementDefinition.isTextTool,
            style: { ...element.elementDefinition.style },
            scale: { ...element.elementDefinition.scale },
            size: { ...element.elementDefinition.size },
            text: element.elementDefinition.text,
          };

          const newElement: DocumentElement = {
            id: element.id,
            isTextTool: false,
            scale: null,
            size: {
              width: element.elementDefinition.size.width + TEXT_BOX_PADDING * 2,
              height: element.elementDefinition.size.height + TEXT_BOX_PADDING * 2,
            },
            style: ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition?.style || {}), {
              font: {
                size: null,
              },
            }),
          };

          const fontSize = Math.round(
            (element.elementDefinition.style?.font?.size ?? 11) * (element?.elementDefinition?.scale?.x ?? 1),
          );

          element.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition), newElement);
          element.setDefaultValues();

          const data = this.editor.execCommand({ type: 'fontSize', value: fontSize });
          if (data?.changes?.text) {
            newElement.text = data.changes.text;
            const size = this.canvasDocument.editorHandler.editorCalc.getTextSize(data.changes.text);
            if (size) {
              newElement.size = {
                width: size.width + TEXT_BOX_PADDING * 2 + 1,
                height: size.height + TEXT_BOX_PADDING * 2,
              };
            }
          }

          textChanges.textChanges = newElement;
          textChanges.textUndoChanges = undoElement;
        }
      } else if (textType === 'text-tool') {
        if (element.elementDefinition.type === 'text' && !element.elementDefinition.isTextTool) {
          const undoElement = {
            id: element.id,
            isTextTool: element.elementDefinition.isTextTool,
            scale: null,
            size: { ...element.elementDefinition.size },
            style: {
              text: {
                valign: element.elementDefinition?.style?.text?.valign,
              },
            },
            text: element.elementDefinition.text,
          };

          const fontSizeString = CanvasUtil.getFirstFontSize(element.elementDefinition.text);
          const fontSize = fontSizeString ? parseFloat(fontSizeString) : 11;
          const text = CanvasUtil.removeFontSize(element.elementDefinition.text);
          const newElement: DocumentElement = {
            id: element.id,
            isTextTool: true,
            scale: { x: 1, y: 1 },
            style: {
              text: {
                valign: null,
              },
              font: {
                size: fontSize,
              },
              border: {
                width: 0,
              },
            },
            text,
          };
          element.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition), newElement);
          element.setDefaultValues();

          textChanges.textChanges = newElement;
          textChanges.textUndoChanges = undoElement;
        }
      }
    }
    return textChanges;
  }
}
