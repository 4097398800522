import { StyleDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';

export class DocumentComponentUtils {
  public static getCurrentStyleValue(elements, attr) {
    let resultedValue;
    for (const element of elements || []) {
      if (element?.type === 'text') {
        const value = ObjectUtil.getByPath(element, attr);
        if (resultedValue === undefined) {
          resultedValue = value;
        } else if (resultedValue !== value) {
          return null;
        }
      }
    }
    return resultedValue;
  }

  public static convertToTextStyle(values): { style: StyleDefinition } {
    const { type, value } = values;
    const styleMap = {
      fontFamily: { style: { font: { family: value } } },
      fontSize: { style: { font: { size: value } } },
      textColor: { style: { color: value } },
      textBackgroundColor: { style: { backgroundColor: value } },
      bold: { style: { font: { weight: value ? 'bold' : 'normal' } } },
      italic: { style: { font: { style: value ? 'italic' : 'normal' } } },
      underline: { style: { text: { decoration: value ? 'underline' : 'none' } } },
      textAlign: { style: { text: { align: value } } },
    };

    return styleMap[type] || {};
  }
}
