import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ContextMenuActionDefinition,
  ContextMenuComponent,
} from '@common/components/context-menu/context-menu.component';
import { IContent } from '../content-holder-details/content-holder-details.component';
import { ContentHolderService } from '../content-holder.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-content-holder',
  templateUrl: './content-holder.component.html',
  styleUrls: ['./content-holder.component.scss'],
})
export class ContentHolderComponent implements OnChanges, OnDestroy {
  // @Input() itemId: string;
  currentId: string;
  @Input() contentHolder: any;
  @Input() editable = true;
  @Output() primaryViewableChanged = new EventEmitter();

  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
  public contextMenuActions: Array<ContextMenuActionDefinition> = [];

  public content$: Observable<Array<IContent>>;
  public selectedContent$: Observable<IContent>;
  public loading$: Observable<boolean>;
  public uploading$: Observable<boolean>;
  private subscriptions: Array<Subscription> = [];
  constructor(private contentHolderService: ContentHolderService) {
    this.content$ = this.contentHolderService.contentHolderContent$;
    this.selectedContent$ = this.contentHolderService.selectedContent$;
    this.loading$ = this.contentHolderService.contentLoading$;
    this.uploading$ = this.contentHolderService.contentUploading$;
    this.subscriptions.push(
      this.contentHolderService.primaryViewableChanged.subscribe((content) => {
        this.primaryViewableChanged.emit(content);
      }),
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.currentId !== this.contentHolder.id) {
      this.currentId = this.contentHolder.id;
      await this.contentHolderService.loadContentHolder(this.contentHolder, 'item:' + this.currentId);
    }
    this.contextMenuActions = [];
    if (this.editable) {
      this.contextMenuActions.push({ actionName: 'make_primary', label: 'Mark as primary' });
      this.contextMenuActions.push({ actionName: 'delete', label: 'Remove' });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }

  public selectContent(content) {
    this.contentHolderService.selectContent(content);
  }
  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      this.contentHolderService.addContentToContentHolder(files);
    }
  }
  handleFileSelection($event: any) {
    const files = $event?.target?.files;
    console.log('files: ', files);
    this.contentHolderService.addContentToContentHolder(files);
  }

  handleContextMenu($event) {
    console.log('handleContextMenu: ', $event);
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }

  handleMenuAction($event) {
    switch ($event.action) {
      case 'delete':
        this.deleteContent($event.target);
        break;
      case 'make_primary':
        this.makePrimaryViewable($event.target);
        break;
    }
    console.log('handleMenuAction: ', $event);
  }

  private async makePrimaryViewable(content) {
    if (!this.editable) {
      return;
    }
    this.contentHolderService.makePrimaryViewable(content);
  }

  private async deleteContent(content) {
    if (!this.editable) {
      return;
    }
    this.contentHolderService.deleteContent(content);
  }

  isPrimaryViewable(content) {
    return this.contentHolderService.isPrimaryViewable(content);
  }
}
