import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentGeneratorConfigComponent } from './document-generator-config/document-generator-config.component';
import { AssortmentsModule } from '@common/assortments/assortments.module';
import { ComponentsModule } from '@components/components.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterModule } from '@components/filter/filter.module';
import { SortModule } from '@components/sort/sort.module';
import { PropertyViewBuilderComponent } from './property-view-builder/property-view-builder.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { DocumentModule } from '../document/document.module';
import { PropertyViewBuilderFormatBarComponent } from './property-view-builder/property-view-builder-format-bar/property-view-builder-format-bar.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { PropertyViewTemplateBuilderComponent } from './property-view-template-builder/property-view-template-builder.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PropertyViewBuilderPreviewComponent } from './property-view-builder/property-view-builder-preview/property-view-builder-preview.component';
import { GeneratorFrameTemplateChooserComponent } from './generator-frame-template-chooser/generator-frame-template-chooser.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DocumentGeneratorConfigEditorComponent } from './document-generator-config-editor/document-generator-config-editor.component';
import { DocumentGeneratorSourceSelectorComponent } from './document-generator-source-selector/document-generator-source-selector.component';
import { DocumentGeneratorSourceModalComponent } from './document-generator-source-modal/document-generator-source-modal.component';
import { SourceSelectorBreadcrumbComponent } from './document-generator-source-selector/source-selector-breadcrumb.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [
    DocumentGeneratorConfigComponent,
    PropertyViewBuilderComponent,
    PropertyViewBuilderFormatBarComponent,
    PropertyViewTemplateBuilderComponent,
    PropertyViewBuilderPreviewComponent,
    GeneratorFrameTemplateChooserComponent,
    DocumentGeneratorConfigEditorComponent,
    DocumentGeneratorSourceSelectorComponent,
    DocumentGeneratorSourceModalComponent,
    SourceSelectorBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AssortmentsModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    FilterModule,
    SortModule,
    MatTooltipModule,
    MatIconModule,
    MatAutocompleteModule,
    DocumentModule,
    MatInputModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    DragDropModule,
    MatDividerModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatExpansionModule,
    PipesModule,
    DirectivesModule,
  ],
  exports: [DocumentGeneratorConfigEditorComponent],
})
export class DocumentGeneratorModule {}
