import { createAction, props } from '@ngrx/store';
import { DocumentModelEntity } from './document-model-entities.state';

export enum DocumentModelEntitiesActionTypes {
  CLEAR_DOCUMENT_MODEL_ENTITIES = '[DocumentManager] Clear Document Model Entities',
  SET_DOCUMENT_MODEL_ENTITIES = '[DocumentManager] Set Document Model Entities',

  BATCH_APPLY_DOCUMENT_MODEL_ENTITY_CHANGES = '[DocumentManager] Batch Apply Document Model Entity Changes',

  UPDATE_DOCUMENT_MODEL_ENTITY = '[DocumentManager] Update Document Model Entity',
  UPDATE_DOCUMENT_MODEL_ENTITIES = '[DocumentManager] Update Document Model Entities',

  REMOVE_DOCUMENT_MODEL_ENTITY = '[DocumentManager] Remove Document Model Entity',
  REMOVE_DOCUMENT_MODEL_ENTITIES = '[DocumentManager] Remove Document Model Entities',

  ADD_DOCUMENT_MODEL_ENTITY = '[DocumentManager] Add Document Model Entity',
  ADD_DOCUMENT_MODEL_ENTITIES = '[DocumentManager] Add Document Model Entities',
}

////////////////////////////////////////////////////////////////////////////////

export const clearDocumentModelEntities = createAction(DocumentModelEntitiesActionTypes.CLEAR_DOCUMENT_MODEL_ENTITIES);
export const setDocumentModelEntities = createAction(
  DocumentModelEntitiesActionTypes.SET_DOCUMENT_MODEL_ENTITIES,
  props<{ documentModelEntities: Array<any> }>(),
);

export const updateDocumentModelEntity = createAction(
  DocumentModelEntitiesActionTypes.UPDATE_DOCUMENT_MODEL_ENTITY,
  props<{ id: string; documentModelEntity: DocumentModelEntity }>(),
);

export const updateDocumentModelEntities = createAction(
  DocumentModelEntitiesActionTypes.UPDATE_DOCUMENT_MODEL_ENTITIES,
  props<{ documentModelEntities: DocumentModelEntity[] }>(),
);

export const batchApplyDocumentModelEntityChanges = createAction(
  DocumentModelEntitiesActionTypes.BATCH_APPLY_DOCUMENT_MODEL_ENTITY_CHANGES,
  props<{ changes: any[] }>(),
);

export const removeDocumentModelEntity = createAction(
  DocumentModelEntitiesActionTypes.REMOVE_DOCUMENT_MODEL_ENTITY,
  props<{ id: string }>(),
);

export const removeDocumentModelEntities = createAction(
  DocumentModelEntitiesActionTypes.REMOVE_DOCUMENT_MODEL_ENTITIES,
  props<{ ids: string[] }>(),
);

export const addDocumentModelEntity = createAction(
  DocumentModelEntitiesActionTypes.ADD_DOCUMENT_MODEL_ENTITY,
  props<{ documentModelEntity: DocumentModelEntity }>(),
);

export const addDocumentModelEntities = createAction(
  DocumentModelEntitiesActionTypes.ADD_DOCUMENT_MODEL_ENTITIES,
  props<{ documentModelEntities: DocumentModelEntity[] }>(),
);
