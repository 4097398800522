<div class="header !-mt-3">
  <mat-icon (click)="handleCancel()" data-test="arrow-back">arrow_back_ios_new</mat-icon>
  <span class="font-medium text-base">
    <ng-container *ngIf="step === 'OPTION_TYPE'"> Select source </ng-container>
    <ng-container *ngIf="step === 'ASSORTMENT_SELECT'"> Select assortment </ng-container>
    <ng-container *ngIf="step === 'WORKSPACE_SELECT'"> Select project </ng-container>
    <ng-container *ngIf="step === 'DOCUMENT_SELECT' || step === 'RECENT_DOCUMENT'"> Select document </ng-container>
  </span>
</div>

<div *ngIf="step === 'OPTION_TYPE'" class="source-type-selector">
  <div
    class="source-type-option"
    [ngClass]="{ disabled: option.disabled }"
    (click)="selectSourceOptionType(option)"
    *ngFor="let option of optionTypesList"
  >
    <img *ngIf="option.img" [src]="option.img" class="w-10 h-10 object-contain my-auto mx-2 {{ option.class || '' }}" />
    <div class="option-description">
      <div class="name">{{ option.name }}</div>
      <div class="description">{{ option.description }}</div>
    </div>
  </div>
</div>

<div *ngIf="step === 'ASSORTMENT_SELECT'" class="source-selector">
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Source Project</mat-label>
    <mat-select
      [formControl]="sourceWorkspaceControl"
      (ngModelChange)="handleWorkspaceChange($event)"
      data-test="select-source-workspace"
    >
      <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
        {{ workspace.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-form-field *ngIf="(sourceAssortments$ | async)?.length" class="w-full" appearance="fill">
      <mat-label>Assortment</mat-label>
      <mat-select
        [formControl]="assortmentFormControl"
        (ngModelChange)="handleAssortmentChange()"
        data-test="select-source-assortment"
      >
        <mat-select-trigger
          class="assortment-name-trigger"
          appOverflowTooltip
          matTooltip=""
          [matTooltipShowDelay]="300"
          matTooltipPosition="left"
        >
          {{ assortmentFormControl.value?.name }}
        </mat-select-trigger>
        <ng-container *ngFor="let assortment of sourceAssortments$ | async">
          <mat-option
            [value]="assortment"
            appOverflowTooltip
            [toolTipElement]="'.mat-option-text'"
            matTooltip=""
            matTooltipPosition="left"
          >
            <!-- mat-option uses `<span class="mat-option-text"></span>` to render value -->
            {{ assortment.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="warning" *ngIf="(sourceAssortments$ | async)?.length === 0">
      The selected project does not contain an eligible assortment.
    </div>
  </div>
  <div class="selectionButton" *ngIf="selectedSource">
    <button mat-raised-button color="primary" (click)="handleSelectSource()">Ok</button>
  </div>
</div>

<div *ngIf="step === 'WORKSPACE_SELECT'" class="source-selector">
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Project</mat-label>
    <mat-select
      [formControl]="sourceWorkspaceControl"
      (ngModelChange)="handleWorkspaceChange($event)"
      data-test="item-data-source-selector-update-workspace"
    >
      <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
        {{ workspace.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="selectionButton">
    <button
      mat-raised-button
      color="primary"
      (click)="selectWorkspace()"
      data-test="item-data-source-selector-select-workspace"
    >
      Select
    </button>
  </div>
</div>

<div *ngIf="step === 'DOCUMENT_SELECT'" class="source-selector h-full overflow-auto narrow-scroll">
  <div class="flex-center w-full h-96" *ngIf="loading$ | async; else loaded">
    <app-mat-spinner [message]="'Loading Document'" [overlay]="false" [loading]="true"></app-mat-spinner>
  </div>

  <ng-template #loaded>
    <app-folder-breadcrumb-nav [rootFolder]="selectedWorkspace"></app-folder-breadcrumb-nav>

    <div class="entity-container" data-test="data-source-selector-document">
      <div
        *ngFor="let item of transformedEntities$ | async"
        class="entity-item flex items-center"
        (click)="selectEntity(item)"
      >
        <mat-icon class="!text-blue-300" *ngIf="item.matIcon">{{ item.matIcon }}</mat-icon>
        <img *ngIf="!item.matIcon" [src]="item.icon" class="w-6 h-6 object-contain" />
        <span [matTooltip]="item?.name" [matTooltipDisabled]="item?.name.length < 30">{{
          item.name | shorten: 30
        }}</span>
      </div>

      <ng-container *ngIf="!(transformedEntities$ | async)?.length">
        <span class="py-10 w-full flex-center">No Entities</span>
      </ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="step === 'RECENT_DOCUMENT'" class="source-selector h-full overflow-auto narrow-scroll">
  <div class="flex-center w-full h-96" *ngIf="loadingRecent; else loadRecent">
    <app-mat-spinner [message]="'Loading Recent'" [overlay]="false" [loading]="true"></app-mat-spinner>
  </div>

  <ng-template #loadRecent>
    <div class="entity-container" data-test="data-source-selector-recent">
      <div
        *ngFor="let item of transformedEntities$ | async"
        class="entity-item flex items-center"
        (click)="selectEntity(item)"
      >
        <img [src]="item?.icon" class="w-6 h-6 object-contain" />
        <span [matTooltip]="item?.entity?.name" [matTooltipDisabled]="item?.entity?.name?.length < 30">
          {{ item?.entity?.name | shorten: 30 }}
        </span>
      </div>

      <ng-container *ngIf="!(transformedEntities$ | async)?.length">
        <span class="py-10 w-full flex-center">No Entities</span>
      </ng-container>
    </div>
  </ng-template>
</div>
