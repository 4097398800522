import { ActionRequest } from '@contrail/actions';
import { Frame } from '../../../state/canvas-frame-state';
import { CanvasDocument } from '../../../canvas-document';
import { CoordinateBox } from '../../../coordinate-box';
import { CanvasUtil } from '../../../canvas-util';
import { CanvasElement } from '../../../elements/canvas-element';

export class FrameDragHandler {
  private overlappedFrame: Frame = null;

  constructor(public canvasDocument: CanvasDocument) {}

  /**
   * Highlight a frame if dragged element is at least half on top of a frame.
   * @param event
   */
  public handleElementDragged(event) {
    this.setOverlappedFrame(event);
  }

  /**
   * Handle element 'dragEnded' event. When an element is finished
   * dragging, determine if it was placed on a frame and move it up in order
   * so it's on top of the frame.
   * @param event
   */
  public handleElementDragEnded(event) {
    this.setOverlappedFrame(event);
    if (event?.selectedElements?.length > 0 && this.overlappedFrame) {
      // Reorder dragged elements that are not already on the frame
      let index =
        this.overlappedFrame.elements?.length > 0
          ? this.overlappedFrame.elements.sort((a, b) => a.index - b.index)[this.overlappedFrame.elements.length - 1]
              .index
          : this.overlappedFrame.index;
      if (this.overlappedFrame.index > index) {
        index = this.overlappedFrame.index;
      }
      let indexIncrement = 0;
      const elementsIndexMap = {};
      let elementsToReorder = [];
      const elementsToFront = [];
      const elementsToBack = [];
      event.selectedElements
        .filter(
          (element) =>
            this.overlappedFrame.elements.findIndex(({ canvasElement, index }) => element.id === canvasElement.id) ===
            -1,
        )
        .map((element) => {
          const elementIndex = this.canvasDocument.state.getElementIndex(element.id);
          // console.log('Current element index', element.id, elementIndex, index);
          // Elements that are higher in order than the frame need to be sent back in reversed order.
          if (index <= elementIndex) {
            indexIncrement = 1;
            elementsIndexMap[element.id] = index + indexIncrement;
            elementsToBack.push(element);
          } else {
            elementsIndexMap[element.id] = index;
            elementsToFront.push(element);
          }
          return element;
        });

      elementsToReorder = [...elementsToFront, ...elementsToBack.reverse()];

      // console.log('Move on frame: ', this.overlappedFrame, ', elements: ', elementsToReorder, ', to index: ', index, indexIncrement, elementsIndexMap, this.canvasDocument.state?.frameState?.frames);

      if (elementsToReorder.length > 0) {
        for (const [i, canvasElement] of elementsToReorder.entries()) {
          this.canvasDocument.state?.frameState?.removeElementFromFrame(canvasElement);
          this.canvasDocument.state?.frameState?.addElementOnFrame(canvasElement, index + 1 + i, this.overlappedFrame);
        }

        this.canvasDocument.actionsDispatcher.handleActionRequest(
          new ActionRequest('order.to_index', {
            elementsIndexMap,
            selectedElements: elementsToReorder,
            index: index + indexIncrement,
            undoable: false,
          }),
        );

        // Send event that elements were added to a particular frame so remote sessions could be updated
        this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
          element: { id: this.overlappedFrame.id },
          selectedElements: elementsToReorder.map((canvasElement) => canvasElement.elementDefinition),
          eventType: 'addOnFrame',
        });
      }
    } else if (
      event?.selectedElements?.length > 0 &&
      !this.overlappedFrame &&
      this.canvasDocument.state?.frameState?.frames?.size > 0 &&
      event?.element.elementDefinition.type !== 'frame'
    ) {
      const elementsToRemove = [];
      for (const canvasElement of event.selectedElements) {
        const removed = this.canvasDocument.state?.frameState?.updateElement(canvasElement);
        if (removed) {
          elementsToRemove.push(canvasElement);
        }
      }
      if (elementsToRemove.length > 0) {
        // Send event that elements were removed from a particular frame so remote sessions could be updated
        this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
          element: elementsToRemove[0].elementDefinition,
          selectedElements: elementsToRemove.map((canvasElement) => canvasElement.elementDefinition),
          eventType: 'removeFromFrame',
        });
      }
    }
    this.clear();
  }

  private setOverlappedFrame(event) {
    if (
      event?.element &&
      event.element?.elementDefinition?.type !== 'frame' &&
      this.canvasDocument.state?.frameState?.frames?.size > 0 &&
      !this.canvasDocument?.state?.maskState?.isEditingMask()
    ) {
      const canvasElement: CanvasElement = event.element;
      let overlappedFrame: Frame = null;
      this.canvasDocument.state?.frameState?.frames.forEach((frame, id) => {
        if (frame.id !== canvasElement.id && this.isInFrame(frame.box, canvasElement)) {
          overlappedFrame = frame;
        }
      });

      event?.selectedElements?.forEach((element: CanvasElement) => {
        element.isInFrameMask =
          overlappedFrame &&
          overlappedFrame?.element?.elementDefinition?.clipContent &&
          overlappedFrame.id !== element.id &&
          (element?.isInMask ? true : this.isInFrame(overlappedFrame.box, element))
            ? overlappedFrame.id
            : false;
      });

      if (this.overlappedFrame?.element?.highlightBox) {
        this.overlappedFrame.element.highlightBox = false;
      }
      if (overlappedFrame) {
        this.overlappedFrame = overlappedFrame;
        this.overlappedFrame.element.highlightBox = true;
      } else {
        this.overlappedFrame = overlappedFrame;
      }
    }
  }

  private isInFrame(frameBox: CoordinateBox, element: CanvasElement) {
    const { x, y, width, height } = element.getBoundingClientRect();
    const elementBox: CoordinateBox = {
      width,
      height,
      x,
      y,
      left: x + width * 0.5,
      right: x + width * 0.5,
      top: y + height * 0.5,
      bottom: y + height * 0.5,
    };
    return CanvasUtil.isInBox(frameBox, elementBox);
  }

  public clear() {
    if (this.overlappedFrame?.element?.highlightBox) {
      this.overlappedFrame.element.highlightBox = false;
    }
    this.overlappedFrame = null;
  }
}
