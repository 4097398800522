import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommentsContextReferenceFilter } from '@common/comments/context-comments-list/context-comments-list.component';
import { DocumentElement } from '@contrail/documents';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { BoardsSelectors } from '../../boards-store';
import { BoardService } from '../board.service';
import { DocumentService } from '../document/document.service';

@Component({
  selector: 'app-board-comments-list',
  templateUrl: './board-comments-list.component.html',
  styleUrls: ['./board-comments-list.component.scss'],
})
export class BoardCommentsListComponent implements OnInit, OnDestroy {
  @Output() panelClosed = new EventEmitter();
  public contextReferenceTypes$: Observable<Array<CommentsContextReferenceFilter>>;
  public commentTypeOptions = [
    { value: 'board', display: 'Board' },
    { value: 'frame', display: 'Frame' },
    { value: 'item', display: 'Item' },
    { value: 'other', display: 'Other' },
  ];
  private selectedContextReference: string;
  private subscription: Subscription = new Subscription();
  private elements: DocumentElement[];

  constructor(
    private store: Store<RootStoreState.State>,
    private boardService: BoardService,
    private documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.contextReferenceTypes$ = combineLatest([this.store.select(BoardsSelectors.currentBoard)]).pipe(
      switchMap(async ([board]) => {
        if (board) {
          return [
            {
              key: 'contextReference',
              value: `board:${board?.id}`,
              label: 'Board',
              selected: true,
              ownerInfo: {
                entityType: 'board',
                id: board?.id,
              },
            },
          ];
        }
      }),
    );
    this.subscription.add(this.documentService.documentElements.subscribe((elements) => (this.elements = elements)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleClose() {
    this.panelClosed.emit();
  }

  public getCommentType(comment) {
    const isOwnedByItem = comment?.ownedByReference?.indexOf('item') !== -1;
    let commentType;
    if (isOwnedByItem) {
      commentType = 'item';
    } else if (comment?.documentElementId) {
      if (this.elements?.find((e) => e?.id === comment?.documentElementId && e.type === 'frame')) {
        commentType = 'frame';
      } else {
        commentType = 'other';
      }
    } else if (!comment.subContextReference) {
      commentType = 'board';
    }
    return commentType;
  }

  public updateSelectedContextReference(selectedContextReference) {
    this.selectedContextReference = selectedContextReference;
  }
}
