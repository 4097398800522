import { Action, createReducer, on } from '@ngrx/store';
import { State, featureFlagsEntityAdapter, featureFlagsInitialState } from './feature-flags.state';
import * as AppsActions from './feature-flags.actions';

const setData = (state: State, { data }) => {
  return {
    ...state,
    featureFlags: featureFlagsEntityAdapter.setAll(data, state.featureFlags),
  };
};

export const featureFlagsReducers = createReducer(
  featureFlagsInitialState,
  on(AppsActions.loadFeatureFlagsSuccess, setData),
);

export function reducer(state: State | undefined, action: Action): any {
  return featureFlagsReducers(state, action);
}
