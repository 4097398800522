import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Frame } from '../../../canvas/state/canvas-frame-state';
import { Mask } from '../../../canvas/state/canvas-mask-state';
import { OrderUtil } from '../../../util/OrderUtil';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDocumentElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType.startsWith('order')) {
        //console.log('OrderDocumentElementService: selected elements: ', request?.sourceEvent.selectedElements, request?.sourceEvent?.index);
        this.orderAllSelected(
          request?.sourceEvent.selectedElements,
          request.actionType,
          request?.sourceEvent?.undoable,
          request?.sourceEvent?.index,
          request?.sourceEvent?.elementsIndexMap,
        );
      }
    });
  }

  public getLastIndex(frame: Frame) {
    return frame.elements?.length > 0
      ? frame.elements.sort((a, b) => a.index - b.index)[frame.elements.length - 1].index
      : frame.index;
  }
  private getFirstIndexAfter(frame: Frame, index) {
    return frame.elements?.length > 0
      ? (frame.elements.sort((a, b) => a.index - b.index).find((element) => element.index > index) || { index }).index
      : frame.index;
  }
  private getFirstIndexBefore(frame: Frame, index) {
    return frame.elements?.length > 0
      ? ([...frame.elements].sort((a, b) => b.index - a.index).find((element) => element.index < index) || { index })
          .index
      : frame.index;
  }

  private orderAllSelected(
    selectedElements: Array<DocumentElement>,
    orderType: string,
    undoable = true,
    index?: number,
    elementsIndexMap?: { string: number },
  ) {
    const undoElements = ObjectUtil.cloneDeep(this.documentService.getDocument().elements);
    let adjustedSelectedElements: DocumentElement[] = [];
    selectedElements.forEach((selectedElement) => {
      const mask: Mask = this.documentService?.documentRenderer?.isMaskOrMaskMember(selectedElement.id);
      if (selectedElement.type === 'group') {
        adjustedSelectedElements = adjustedSelectedElements.concat(
          this.documentService
            .getAllElementsInGroup(selectedElement.id)
            ?.filter((m) => selectedElements?.findIndex((e) => e.id === m.id) === -1),
        );
        adjustedSelectedElements.push(selectedElement);
      } else if (mask) {
        // If ordering mask or its members - reorder mask and its members
        adjustedSelectedElements = adjustedSelectedElements.concat(
          this.documentService.documentRenderer.getMaskMembers(mask.id)?.map((e) => e.elementDefinition),
        );
        adjustedSelectedElements.push(mask.element.elementDefinition);
      } else {
        adjustedSelectedElements.push(selectedElement);
      }
    });

    let elementsToReorder =
      ['order.send_backward', 'order.send_to_back'].indexOf(orderType) !== -1
        ? adjustedSelectedElements.reverse()
        : adjustedSelectedElements;
    for (const element of elementsToReorder) {
      const documentElements = this.documentService.getDocument().elements;
      const from = documentElements.findIndex((el) => el.id === element.id);
      const frame = this.documentService.getFrameForElement(element); // Is this element on a frame or this element is the frame.
      const reorderEntireFrame = frame ? elementsToReorder.findIndex((e) => e.id === frame.id) !== -1 : false; // Are we reordering entire frame and all elements on it.
      switch (orderType) {
        case 'order.bring_forward': {
          let to = from + 1;
          // If reordering entire frame and all elements on it, bring it forward after the last element on the frame.
          if (reorderEntireFrame) {
            to = this.getLastIndex(frame) + 1;
            // If it's an element on the frame, bring it forward after next element on the frame.
          } else if (frame) {
            to = Math.min(this.getFirstIndexAfter(frame, from), this.getLastIndex(frame));
          }
          this.move(from, to);
          break;
        }
        case 'order.bring_to_front': {
          let to = documentElements.length - 1;
          // If reordering an element on the frame, bring it to the top but not higher than last element on the frame.
          if (!reorderEntireFrame && frame) {
            to = Math.min(this.getLastIndex(frame), to);
          }
          this.move(from, to);
          break;
        }
        case 'order.send_backward': {
          let to = from - 1;
          // If reordering entire frame and all elements on it, bring it backward before the frame index.
          if (reorderEntireFrame) {
            to = frame.index - 1;
            // If reordering an element on the frame, bring it backward by one but not lower than the frame index.
          } else if (frame) {
            to = Math.max(frame.index + 1, this.getFirstIndexBefore(frame, from));
          }
          this.move(from, to);
          break;
        }
        case 'order.send_to_back': {
          let to = 0;
          // If reordering an element on the frame, bring it back not lower than the frame index.
          if (!reorderEntireFrame && frame) {
            to = Math.max(frame.index + 1, to);
          }
          this.move(from, to);
          break;
        }
        case 'order.to_index': {
          let to = index;
          if (elementsIndexMap && elementsIndexMap.hasOwnProperty(element.id)) {
            to = elementsIndexMap[element.id];
          }
          this.move(from, to);
          break;
        }
      }
    }

    const action = undoable
      ? new DocumentAction(
          {
            changeType: DocumentChangeType.REORDER_ELEMENT,
            elementData: this.documentService.getDocument().elements,
          },
          {
            changeType: DocumentChangeType.REORDER_ELEMENT,
            elementData: undoElements,
          },
        )
      : new DocumentAction({
          changeType: DocumentChangeType.REORDER_ELEMENT,
          elementData: this.documentService.getDocument().elements,
        });
    this.documentService.handleDocumentActions([action]);
  }

  private move(from, to) {
    OrderUtil.move(this.documentService.getDocument().elements, from, to);
  }
}
