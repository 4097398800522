import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { BoardsActions } from '../boards-store';

const TITLE_OPTIONS = ['Create Showcase', 'Connect to assortment'];
@Component({
  selector: 'app-create-board',
  templateUrl: './create-board.component.html',
  styleUrls: ['./create-board.component.scss'],
})
export class CreateBoardComponent implements OnInit {
  title = 'Create Board';
  currentPage = 0;
  private sourceAssortmentId: string;
  public form: UntypedFormGroup;
  sourceAssortmentRequired = true;

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreateBoardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const formConfig: any = {
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
    };
    formConfig.template = new UntypedFormControl('');
    formConfig.assortmentOption = new UntypedFormControl('useAssortment');
    this.form = new UntypedFormGroup(formConfig);
  }

  ngOnInit(): void {}

  setAssortment(assortmentId) {
    this.sourceAssortmentId = assortmentId;
  }

  cancel() {
    this.dialogRef.close();
  }

  async onSubmit(data) {
    const board: any = {
      name: data.name,
    };
    if (this.sourceAssortmentId) {
      board.sourceAssortmentId = this.sourceAssortmentId;
    }
    this.store.dispatch(BoardsActions.createBoard({ board }));
    this.dialogRef.close();
  }

  isValid() {
    if (this.currentPage === 1 && this.sourceAssortmentRequired && !this.sourceAssortmentId) {
      return false;
    }
    return this.form.valid;
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  public selectAssortmentOption(assortmentOption) {
    if (assortmentOption.value === 'doNotUseAssortment') {
      this.sourceAssortmentId = null;
      this.sourceAssortmentRequired = false;
    } else {
      this.sourceAssortmentRequired = true;
    }
  }

  public next() {
    this.currentPage = 1;
    this.title = TITLE_OPTIONS[this.currentPage];
  }

  public back() {
    this.currentPage = 0;
    this.title = TITLE_OPTIONS[this.currentPage];
  }
}
