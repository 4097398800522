<app-modal title="Settings" [dialogRef]="dialogRef" *ngIf="currentUser">
  <form [formGroup]="userForm" class="flex flex-col rounded-lg mb-10 p-6 bg-gray-100">
    <div class="flex justify-center gap-x-6">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
      </mat-form-field>
    </div>
    <button
      class="w-24 !ml-auto"
      mat-flat-button
      color="primary"
      [disabled]="userForm.invalid"
      (click)="updateProfile()"
    >
      SAVE
    </button>
  </form>

  <!-- <div class="flex">
    <button mat-raised-button color="primary" class="h-12 w-64 !mr-8">
      <mat-icon svgIcon="slack"></mat-icon> <span class="ml-3 text-lg">Connect to Slack</span>
    </button>
    <button mat-raised-button color="primary" class="h-12 w-64">
      <mat-icon svgIcon="msteams"></mat-icon> <span class="ml-3 text-lg">Connect to Teams</span>
    </button>
  </div>

  <div class="w-fit flex items-center cursor-pointer my-6" [matMenuTriggerFor]="orgMenu">
    <p class="text-sm font-medium mr-2">Org:</p>
    <p class="text-sm text-primary">{{currentOrg?.orgName}}</p>
    <mat-icon class="ml-1">arrow_drop_down</mat-icon>
  </div>
  <mat-menu #orgMenu="matMenu" yPosition="below" class="menu-md org-menu">
    <button mat-menu-item *ngFor="let org of orgs" (click)="selectOrg(org)">
      {{ org?.orgName }}
    </button>
  </mat-menu> -->

  <ng-container *ngFor="let app of apps">
    <form [formGroup]="app.form">
      <div class="flex items-center icons mt-6">
        <mat-icon class="material-icons-outlined">email</mat-icon>
        <!-- <mat-icon svgIcon="slack"></mat-icon> -->
        <!-- <mat-icon svgIcon="msteams"></mat-icon> -->
        <img [src]="app.icon" />
        <span class="text-lg font-medium">{{ app.name | titlecase }}</span>
      </div>

      <div class="check-list my-3" formGroupName="MENTION">
        <mat-checkbox formControlName="EMAIL"></mat-checkbox>
        <!-- <mat-checkbox formControlName="SLACK"></mat-checkbox> -->
        <!-- <mat-checkbox formControlName="TEAMS"></mat-checkbox> -->
        <span>When &#64;mentioned in a comment or reply</span>
      </div>

      <div class="check-list my-3" formGroupName="COMMENT_ON_OWNED">
        <mat-checkbox formControlName="EMAIL"></mat-checkbox>
        <!-- <mat-checkbox formControlName="SLACK"></mat-checkbox> -->
        <!-- <mat-checkbox formControlName="TEAMS"></mat-checkbox> -->
        <span>When someone comments on your {{ app.name }}</span>
      </div>
    </form>
  </ng-container>
</app-modal>

<!-- <div class="flex flex-col items-center mr-12 cursor-pointer">
  <app-avatar [size]="'96px'" [user]="currentUser"></app-avatar>
  <p class="mt-2">Upload</p>
</div> -->
