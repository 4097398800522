import { CanvasDocument } from '../../../canvas-document';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasElement } from '../../../elements/canvas-element';

export class MaskElementEventHandler {
  public isEditingMask: string | boolean = false;
  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Start editing mask on double click if the following conditions are met:
   * - the element is a mask
   * - the element is not locked
   * - if the element is in group allowing editing mask if the element is already selected
   * @param event
   * @param elementTarget
   */
  public handleDblClick(event: MouseEvent, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (elementTarget?.element) {
      if (
        !event?.shiftKey &&
        elementTarget.element.isMask &&
        !elementTarget.element.elementDefinition.isLocked &&
        (!elementTarget.element.isInGroup || elementTarget.element.isSelected)
      ) {
        this.isEditingMask = elementTarget.element.id;
        this.canvasDocument.draw();
      }
    }
  }
}
