<div class="pr-6 pl-6 pb-3 h-full">
  <div class="item-documents-header pr-2 pl-2">
    <app-search-bar
      class="w-80 mr-10"
      data-test="item-documents-search"
      [disabled]="isLoading"
      [textSmall]="true"
      [lgGray]="true"
      [placeholder]="'Search documents'"
    >
    </app-search-bar>

    <app-type-property-form-field-multi-select
      class="!w-44 mr-12"
      data-test="document-project-filter"
      appearance=""
      [propertyFormConfiguration]="projectFilterDefinition"
      (valueChange)="handleProjectFilterChange($event)"
      [showSearchBar]="true"
      [showSelectAll]="true"
    ></app-type-property-form-field-multi-select>

    <app-type-property-form-field-multi-select
      class="!w-44 mr-auto"
      data-test="document-type-filter"
      appearance=""
      [propertyFormConfiguration]="documentTypeFilterDefinition"
      (valueChange)="handleDocumentTypeFilterChange($event)"
      [showSearchBar]="true"
      [showSelectAll]="true"
    ></app-type-property-form-field-multi-select>

    <mat-slide-toggle
      labelPosition="before"
      class="my-2"
      color="primary"
      data-test="created-by-me-toggle"
      [checked]="showCurrentUserDocumentsOnlyControl.value"
      (change)="toggleCreatedByMe($event)"
    >
      <p class="text-sm">Created by me</p>
    </mat-slide-toggle>
  </div>
  <div *ngIf="isLoading" class="loading pr-2 pl-2">
    <mat-spinner diameter="40"> </mat-spinner>
    <div class="message">Loading</div>
  </div>
  <div class="item-documents-list-container pt-3 pr-2 pl-2 square-scroll" *ngIf="!isLoading">
    <app-list
      [listData]="filteredItemDocuments$ | async"
      [columnDefinitions]="columnDefinitions"
      [sortBy]="sortByControl.value"
      (doubleClickRow)="goToDocument($event)"
      (clickHeaderColumn)="clickHeaderColumn($event)"
    ></app-list>
  </div>
</div>
