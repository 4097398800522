import { LineDefinition, PositionDefinition, SizeDefinition, ViewBox } from '@contrail/documents';
import { CanvasDocument } from '../../../../canvas-document';
import { CanvasUtil } from '../../../../canvas-util';
import { CanvasElement } from '../../../../elements/canvas-element';
import { DrawOptions } from '../../../../renderers/canvas-renderer';
import { RotationHelper } from '../../../../renderers/rotation-widget-renderer/rotation-helper';
import { Frame } from '../../../../state/canvas-frame-state';

export interface GuidelineCoordinate {
  id: string;
  position: string;
  x: number;
  y: number;
  height?: number;
  width?: number;
}

export const GUIDELINE_MARGIN = 3;
export const ADJACENT_OBJECT_MARGIN = 3000;
export const VIEWPORT_GUIDELINE_MARGIN = 10;

export class GuidelineHandlerService {
  constructor(private canvasDocument: CanvasDocument) {}

  public getOtherElements(elements: CanvasElement[]) {
    const frameElements: Map<string, string> = this.canvasDocument.state?.frameState?.frameElements;
    const visibleElements: CanvasElement[] = this.canvasDocument.state
      ?.getVisibleElements()
      ?.filter((e) => !e.elementDefinition.isLocked);
    const idsToExclude = elements.map((element) => element.elementDefinition.id);
    const otherCanvasElements = [...visibleElements].filter((canvasElement) => {
      return (
        idsToExclude.indexOf(canvasElement.id) === -1 &&
        idsToExclude.indexOf(frameElements.get(canvasElement.id)) === -1
      );
    });
    return otherCanvasElements;
  }

  public extractOtherElementCoordinates(elements: CanvasElement[]): {
    otherElementCoordinatesX: GuidelineCoordinate[];
    otherElementCoordinatesY: GuidelineCoordinate[];
  } {
    const otherCanvasElements = this.getOtherElements(elements);
    let otherElementCoordinatesX = [];
    let otherElementCoordinatesY = [];
    otherCanvasElements?.forEach((element) => {
      otherElementCoordinatesX = otherElementCoordinatesX.concat(
        this.getElementCoordinates(element, 'x', { maskedDimensions: true, outerEdgeDimensions: true }),
      );
      otherElementCoordinatesY = otherElementCoordinatesY.concat(
        this.getElementCoordinates(element, 'y', { maskedDimensions: true, outerEdgeDimensions: true }),
      );
    });
    return { otherElementCoordinatesX, otherElementCoordinatesY };
  }

  /**
   * Builds an array GuidelineCoorindate object from a document element, for the correct direction (x,y)
   * Each element produces a GuidelineCoordinate for its left, right, center (x), or top, bottom, middle (y)
   * @param element
   * @param direction
   * @returns
   */
  public getElementCoordinates(
    element: CanvasElement,
    direction: string,
    options?: DrawOptions,
  ): GuidelineCoordinate[] {
    const coordRect = element.getBoundingClientRectRotated(options);
    return this.getBoxCoordinates(element.id, coordRect, direction);
  }

  public getBoxCoordinates(
    id,
    box: { x: number; y: number; width: number; height: number },
    direction: string,
  ): GuidelineCoordinate[] {
    const x = Math.round(box.x);
    let y = Math.round(box.y);
    const width = Math.round(box.width);
    let height = Math.round(box.height);

    const coordinates: GuidelineCoordinate[] = [];
    if (direction === 'x') {
      // center
      coordinates.push({ id, position: 'center', x: x + width * 0.5, y: y, height, width });
      // left
      coordinates.push({ id, position: 'left', x: x, y: y, height, width });
      // right
      coordinates.push({ id, position: 'right', x: x + width, y: y, height, width });
    } else {
      // middle
      coordinates.push({ id, position: 'middle', x: x, y: y + height * 0.5, height, width });
      // top
      coordinates.push({ id, position: 'top', x: x, y: y, height, width });
      // bottom
      coordinates.push({ id, position: 'bottom', x: x, y: y + height, height, width });
    }
    return coordinates;
  }

  public generateId(
    key: string,
    guideline1: GuidelineCoordinate,
    guideline2: GuidelineCoordinate,
    fixedPosition: string,
  ): string {
    if (fixedPosition) {
      return key + '_' + guideline1.id + '_' + fixedPosition + '_' + guideline2.id + '_' + fixedPosition;
    }
    return key + '_' + guideline1.id + '_' + guideline1.position + '_' + guideline2.id + '_' + guideline2.position;
  }

  public isInRange(object1: GuidelineCoordinate, object2: GuidelineCoordinate, direction: string) {
    const adjacentMargin = this.canvasDocument.getScaledValue(ADJACENT_OBJECT_MARGIN);
    const margin = this.canvasDocument.getScaledValue(GUIDELINE_MARGIN);
    const viewportMargin = this.canvasDocument.getScaledValue(VIEWPORT_GUIDELINE_MARGIN);

    if (object1.id === 'viewport') {
      const viewportObject = direction === 'y' ? object2.y : object2.x;
      const maxValue = (direction === 'y' ? object1.y : object1.x) + viewportMargin;
      const minValue = (direction === 'y' ? object1.y : object1.x) - viewportMargin;
      return viewportObject <= maxValue && viewportObject >= minValue;
    } else {
      let guidelineValue1 = object1.x;
      let guidelineValue2 = object2.x;
      let adjacentObjectValue1 = object1.y + object1.height < object2.y ? object1.y + object1.height : object1.y;
      let adjacentObjectValue2 = object1.y > object2.y ? object2.y + object2.height : object2.y;
      if (direction === 'y') {
        guidelineValue1 = object1.y;
        guidelineValue2 = object2.y;
        adjacentObjectValue1 = object1.x + object1.width < object2.x ? object1.x + object1.width : object1.x;
        adjacentObjectValue2 = object1.x > object2.x ? object2.x + object2.width : object2.x;
      }

      let isAdjacent = false;
      let isWithinMargin = false;

      if (
        adjacentObjectValue2 >= adjacentObjectValue1 - adjacentMargin &&
        adjacentObjectValue2 <= adjacentObjectValue1 + adjacentMargin
      ) {
        isAdjacent = true;
      }

      if (guidelineValue2 >= guidelineValue1 - margin && guidelineValue2 <= guidelineValue1 + margin) {
        isWithinMargin = true;
      }

      return isAdjacent && isWithinMargin;
    }
  }

  public calculateSnapPosition(
    angle,
    targetElementDimensions,
    targetElementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    direction: 'x' | 'y',
  ): PositionDefinition {
    let snapPosition =
      direction === 'x'
        ? { x: otherElementCoordinates.x, y: targetElementDimensions.y }
        : { x: targetElementDimensions.x, y: otherElementCoordinates.y };
    switch (targetElementCoordinates.position) {
      case 'center':
        snapPosition = {
          x: otherElementCoordinates.x - targetElementDimensions.width * 0.5,
          y: targetElementDimensions.y,
        };
        break;
      case 'middle':
        snapPosition = {
          x: targetElementDimensions.x,
          y: otherElementCoordinates.y - targetElementDimensions.height * 0.5,
        };
        break;
      case 'right':
        snapPosition = { x: otherElementCoordinates.x - targetElementCoordinates.width, y: targetElementDimensions.y };
        break;
      case 'bottom':
        snapPosition = { x: targetElementDimensions.x, y: otherElementCoordinates.y - targetElementCoordinates.height };
        break;
    }

    if (angle && ['center', 'middle'].indexOf(targetElementCoordinates.position) === -1) {
      /**
       * Guidelines for rotated elements.
       * Detailed info: https://lucid.app/lucidchart/4e1d5cca-895e-4fd1-9c25-9d0d98083f07/edit?invitationId=inv_0a0b0e67-79a2-4dbf-a526-3526b82fffed
       *
       * For rotated elements, snapPosition x (for X direction) or y (for Y direction) is coordinate
       * of the rotated corner. We need to find what's the position of that rotated corner
       * when it's not rotated.
       */

      // Get current element center
      const center = {
        x: targetElementDimensions.x + targetElementDimensions.width * 0.5,
        y: targetElementDimensions.y + targetElementDimensions.height * 0.5,
      };

      // Edging position is element position x, y - point that touches the guideline.
      // It might be differenet depending on direction and angle.
      const edgingPosition = {
        x: targetElementDimensions.x,
        y: targetElementDimensions.y,
      };

      // Depending on the angle, touching point is not top left anymore.
      // For example if object is flipped (180 degrees rotation) -
      // bottom right corner becomes top left.
      if (direction === 'y') {
        if (angle >= 270) {
          edgingPosition.x = edgingPosition.x + targetElementDimensions.width; // top right
        } else if (angle >= 180) {
          edgingPosition.x = edgingPosition.x + targetElementDimensions.width; // bottom right
          edgingPosition.y = edgingPosition.y + targetElementDimensions.height;
        } else if (angle >= 90) {
          edgingPosition.y = edgingPosition.y + targetElementDimensions.height; // bottom left
        }
      }

      if (direction === 'x') {
        if (angle <= 90) {
          edgingPosition.y = edgingPosition.y + targetElementDimensions.height; // bottom left
        } else if (angle <= 180) {
          edgingPosition.x = edgingPosition.x + targetElementDimensions.width; // bottom right
          edgingPosition.y = edgingPosition.y + targetElementDimensions.height;
        } else if (angle <= 270) {
          edgingPosition.x = edgingPosition.x + targetElementDimensions.width; // top right
        }
      }

      // Rotate element position to get its rotated coordinates.
      // Here, we need to get it's y (for X direction) or x (for Y direction) coordinate.
      const rotatedPosition = RotationHelper.rotate(edgingPosition, angle, center);

      // Then we need to rotate rotatedPosition backwards but
      // replace x with snapPosition.x (for X direction) or
      // y with snapPositon.y (for Y direction), and also
      // rotate around new center x (for X direction) or y
      // (for Y direction)
      if (direction === 'x') {
        const elementPosition = RotationHelper.rotate(
          {
            x: snapPosition.x,
            y: rotatedPosition.y,
          },
          -angle,
          {
            x: snapPosition.x + targetElementCoordinates.width * 0.5,
            y: center.y,
          },
        );
        snapPosition.x = elementPosition.x - (angle > 90 && angle <= 270 ? targetElementDimensions.width : 0);
      } else {
        const elementPosition = RotationHelper.rotate(
          {
            x: rotatedPosition.x,
            y: snapPosition.y,
          },
          -angle,
          {
            x: center.x,
            y: snapPosition.y + targetElementCoordinates.height * 0.5,
          },
        );
        snapPosition.y = elementPosition.y - (angle >= 90 && angle < 270 ? targetElementDimensions.height : 0);
      }
    }

    return snapPosition;
  }

  public setPositions(
    snapPosition: PositionDefinition,
    selectedElements: CanvasElement[],
    deltaPositions: Map<string, PositionDefinition>,
    frames: Map<string, Frame>,
  ) {
    selectedElements?.forEach((element) => {
      const { x, y } = element.getPosition();
      const deltaPosition = deltaPositions.get(element.elementDefinition.id);
      this.setPosition(element, {
        x: snapPosition.x + (deltaPosition?.x || 0),
        y: snapPosition.y + (deltaPosition?.y || 0),
      });

      if (element.elementDefinition.type === 'frame') {
        const deltaX = snapPosition.x - x;
        const deltaY = snapPosition.y - y;
        frames.get(element.elementDefinition.id)?.elements?.forEach(({ canvasElement }) => {
          const { x, y } = canvasElement.getPosition();
          this.setPosition(canvasElement, {
            x: x - canvasElement.PADDING_LEFT + deltaX,
            y: y - canvasElement.PADDING_TOP + deltaY,
          });
        });
      }
    });
  }

  public setPosition(element: CanvasElement, position: PositionDefinition) {
    if (element.elementDefinition.type === 'line') {
      if (element.elementDefinition.lineDefinition) {
        const currentPosition = element.getPosition();
        const dx = position.x - currentPosition.x;
        const dy = position.y - currentPosition.y;
        let tx = dx,
          ty = dy;
        element.elementDefinition.lineDefinition.x1 = element.elementDefinition.lineDefinition.x1 + dx;
        element.elementDefinition.lineDefinition.y1 = element.elementDefinition.lineDefinition.y1 + dy;
        element.elementDefinition.lineDefinition.x2 = element.elementDefinition.lineDefinition.x2 + tx;
        element.elementDefinition.lineDefinition.y2 = element.elementDefinition.lineDefinition.y2 + ty;
      }
    } else {
      element.elementDefinition.position = {
        x: position.x + element.PADDING_LEFT,
        y: position.y + element.PADDING_TOP,
      };
    }
  }

  public calculateGuidelineDefinition(
    id: string,
    direction: string,
    elementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    documentSize?: ViewBox,
  ): LineDefinition {
    let lineDefinition: LineDefinition;
    if (direction === 'x') {
      lineDefinition = {
        x1: otherElementCoordinates.x,
        y1:
          elementCoordinates.y > otherElementCoordinates.y
            ? elementCoordinates.y + elementCoordinates.height
            : elementCoordinates.y,
        x2: otherElementCoordinates.x,
        y2:
          elementCoordinates.y < otherElementCoordinates.y
            ? otherElementCoordinates.y + otherElementCoordinates.height
            : otherElementCoordinates.y,
      };
      if (id.indexOf('viewport') > -1 && documentSize) {
        lineDefinition.y1 = documentSize.y;
        lineDefinition.y2 = documentSize.y + documentSize.height;
      }
    } else {
      lineDefinition = {
        x1:
          elementCoordinates.x > otherElementCoordinates.x
            ? elementCoordinates.x + elementCoordinates.width
            : elementCoordinates.x,
        y1: otherElementCoordinates.y,
        x2:
          elementCoordinates.x < otherElementCoordinates.x
            ? otherElementCoordinates.x + otherElementCoordinates.width
            : otherElementCoordinates.x,
        y2: otherElementCoordinates.y,
      };
      if (id.indexOf('viewport') > -1 && documentSize) {
        lineDefinition.x1 = documentSize.x;
        lineDefinition.x2 = documentSize.x + documentSize.width;
      }
    }
    return lineDefinition;
  }
}
