import { Injectable } from '@angular/core';
import { DocumentComponentService } from '../document/document-component/document-component-service';
import { ItemData } from '@common/item-data/item-data';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../document/document-store';
import { PositionDefinition } from '@contrail/documents';
import { ViewBox } from '../canvas/viewbox';
import { CanvasUtil } from '../canvas/canvas-util';
import { DocumentViewSize } from '../document/document-store/document.state';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';

@Injectable({
  providedIn: 'root',
})
export class ItemChooserService {
  private viewSize: DocumentViewSize;
  private lastComponentPosition: PositionDefinition = null;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentComponentService: DocumentComponentService,
  ) {
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
      this.lastComponentPosition = null;
    });
  }

  /**
   * Add @items to the document and distribute them on the canvas.
   * @param items
   */
  public async addItemsToDocument(items: ItemData[]) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));

    const startingDocumentPosition = CanvasUtil.toDocumentPosition(
      120,
      120,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
    await this.documentComponentService.addItemsToDocument(
      items,
      (window.innerWidth - 120) / this.viewSize.viewScale.x,
      null,
      startingDocumentPosition,
    );

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  /**
   * Add @item to the document and place it after last know component position on the document.
   * @param item
   */
  public async addItemToDocument(item: any) {
    let position = CanvasUtil.toDocumentPosition(
      120,
      120,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
    if (this.lastComponentPosition) {
      position = this.lastComponentPosition;
    }

    this.documentComponentService
      .addComponentElement(item, {
        position: Object.assign({}, position),
      })
      .then((element) => {
        const elementSize = {
          width: 20 + element.elements[0].size.width,
          height: element.elements.reduce((height, element) => height + element.size.height, 12),
        };
        const nextPosition = { x: position.x + elementSize.width + 5, y: position.y };
        const mousePosition = CanvasUtil.toWindowPosition(
          position.x,
          position.y,
          this.viewSize.viewBox,
          this.viewSize.viewScale,
          this.viewSize.boundingClientRect,
        );
        if (mousePosition.x + elementSize.width > window.innerWidth - 120) {
          const startingPosition = CanvasUtil.toDocumentPosition(
            120,
            120,
            this.viewSize.viewBox,
            this.viewSize.viewScale,
            this.viewSize.boundingClientRect,
          );
          nextPosition.x = startingPosition.x;
          nextPosition.y = nextPosition.y + elementSize.height + 5;
        }
        this.lastComponentPosition = nextPosition;
      });
  }
}
