import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentElementEvent } from '@contrail/documents';
import { Subscription } from 'rxjs';
import { DocumentElement } from '@contrail/documents';
import { DocumentService } from '../../document.service';
import { DocumentStatusMessageService } from '../document-status-message.service';

@Component({
  selector: 'document-status-message',
  templateUrl: './document-status-message.component.html',
  styleUrls: ['./document-status-message.component.scss'],
})
export class DocumentStatusMessageComponent implements OnInit, OnDestroy {
  constructor(
    private service: DocumentService,
    private statusMessageService: DocumentStatusMessageService,
  ) {}

  public sourceEvent: DocumentElementEvent;
  public elements: Array<DocumentElement>;
  public annotationMessage: string;
  visible = false;
  top = 0;
  left = 0;
  width = 0;
  height = 0;

  private eventSub: Subscription;

  ngOnInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.eventSub = this.service.documentElementEvents.subscribe((event) => {
      if (!event) {
        return;
      }
      if (event.element) {
        if (event.eventType === 'showAnnotation') {
          this.visible = true;
          this.show(event);
        } else if (event.eventType === 'hideAnnotation') {
          this.visible = false;
        }
      }
    });
  }
  unsubscribe() {
    this.eventSub.unsubscribe();
  }

  show(event: DocumentElementEvent) {
    this.annotationMessage = this.statusMessageService.getMessage(event.element);
    if (this.annotationMessage) {
      this.sourceEvent = event;
      this.left = event.renderedElementPosition.x;
      this.top = event.renderedElementPosition.y;
      this.width = event.renderedElementSize.width;
      this.height = event.renderedElementSize.height;
    }
  }
}
