import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { StickyNoteEditor, STICKY_FONT_SIZE, STICKY_ALIGN, STICKY_FONT_FAMILY } from './sticky-note-editor';
import { StickyNoteEditorContainer } from './sticky-note-editor-container';
import { StickyNoteEditorPlaceholder } from './sticky-note-editor-placeholder';
import { DocumentTextElementEvent } from '@contrail/documents';
import { CanvasStickyNoteElement } from '../canvas-sticky-note-element';
import { TextChanges } from '../../../components/editor/editor-handler';

export class StickyNoteEditorManager extends EditorManager {
  constructor(canvasDocument: CanvasDocument) {
    super(canvasDocument, { hideElement: false }, { paste_as_text: true });
    this.editorContainer = new StickyNoteEditorContainer(canvasDocument, this);
    this.editor = new StickyNoteEditor(canvasDocument, this);
    this.editorPlaceholder = new StickyNoteEditorPlaceholder(canvasDocument, this);
  }

  public getMaxFontSize(canvasElement: CanvasElement) {
    const target = this.canvasDocument.editorHandler.editorCalc.container as HTMLElement;
    if (!target) return null;
    const element = canvasElement.elementDefinition;
    const currentContainerHeight = element.size.height;
    const currentContainerWidth = element.size.width;
    target.style.width = `${element.size.width - canvasElement.TEXT_PADDING * 2}px`;
    target.style.height = `auto`;
    target.style.fontFamily = element?.style?.font?.family || STICKY_FONT_FAMILY;
    target.style.fontSize = (element?.style?.font?.size || STICKY_FONT_SIZE) + 'px';
    target.style.textAlign = element?.style?.text?.align || STICKY_ALIGN;
    target.style.overflowWrap = 'normal';
    target.style.wordBreak = 'normal';
    target.innerHTML = element.text;
    let newFontSize = StickyNoteEditorContainer.calculateNewFontSize(
      target,
      element.text,
      currentContainerHeight - canvasElement.TEXT_PADDING * 2,
      currentContainerWidth - canvasElement.TEXT_PADDING * 2,
    );
    return newFontSize;
  }

  public applySize(element: CanvasStickyNoteElement, properties: any) {
    if (!properties) {
      return;
    }
    element.elementDefinition = ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element.elementDefinition), properties);
    let newFontSize = this.getMaxFontSize(element);
    let sizeMode = element?.elementDefinition?.style?.font?.sizeMode;
    element.maxFontSize = newFontSize; // update max font size for element
    if (newFontSize && element.elementDefinition?.style?.font?.sizeMode === 'custom') {
      if (newFontSize < element.elementDefinition.style.font.size) {
        sizeMode = 'auto';
      } else {
        newFontSize = element.elementDefinition.style.font.size;
      }
      newFontSize = Math.min(newFontSize, element.elementDefinition.style.font.size);
    }
    let style = {};
    if (newFontSize) {
      style = {
        style: {
          font: {
            size: newFontSize,
            sizeMode,
          },
        },
      };
    }
    this.editor.emitTextEditorEvent(element);
    return ObjectUtil.mergeDeep(
      {
        id: element.elementDefinition.id,
      },
      properties,
      style,
    );
  }

  private applyFormatForElements(elements: CanvasElement[], properties: any, applyFn: any) {
    const textChanges = [];
    const textUndoChanges = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.elementDefinition.type === 'sticky_note') {
        const undoElement = ObjectUtil.cloneDeep(element.elementDefinition);
        const changeElement = applyFn(element, properties);
        if (changeElement) {
          textChanges.push(changeElement);
          textUndoChanges.push(undoElement);
        }
      }
    }
    return { textChanges, textUndoChanges };
  }

  public applyStyleProperty(element: CanvasElement, changes: DocumentTextElementEvent): TextChanges {
    const { textChanges, textUndoChanges } = this.applyStyleProperties(changes, [element]);
    return {
      textChanges: textChanges[0] || {},
      textUndoChanges: textUndoChanges[0] || {},
    };
  }

  public applyStyleProperties(changes: DocumentTextElementEvent, elements: CanvasElement[]) {
    if (!elements?.length) {
      return { textChanges: [], textUndoChanges: [] };
    }
    if (changes.textFormat?.size) {
      return this.applyFormatForElements(elements, { size: changes.textFormat.size }, this.applySize.bind(this));
    } else if (changes.textFormat?.type === 'fontFamily') {
      return this.applyFormatForElements(
        elements,
        { style: { font: { family: changes.textFormat.value } } },
        this.applySize.bind(this),
      );
    } else if (changes.textFormat?.type === 'fontSize') {
      return this.applyFormatForElements(
        elements,
        changes.textFormat.value === 'Auto'
          ? { style: { font: { sizeMode: 'auto' } } }
          : { style: { font: { size: changes.textFormat.value, sizeMode: 'custom' } } },
        this.applySize.bind(this),
      );
    } else if (changes.textFormat?.type === 'textAlign') {
      return this.applyFormatForElements(
        elements,
        { style: { text: { align: changes.textFormat.value } } },
        this.applySize.bind(this),
      );
    } else if (changes.textFormat?.type === 'textValign') {
      return this.applyFormatForElements(
        elements,
        { style: { text: { valign: changes.textFormat.value } } },
        this.applySize.bind(this),
      );
    }
    return { textChanges: [], textUndoChanges: [] };
  }

  public applyDocumentStickyNoteElementEvent(changes: DocumentTextElementEvent) {
    const elements: Array<CanvasElement> = this.canvasDocument?.interactionHandler?.selectionHandler
      ?.getSelectedUnlockedCanvasElements()
      .filter((element) => element.elementDefinition.type === 'sticky_note');
    const { textChanges, textUndoChanges } = this.applyStyleProperties(changes, elements);

    if (textChanges?.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(textChanges, textUndoChanges);
    }
  }
}
