import { Injectable } from '@angular/core';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentEventsService {
  constructor(
    private documentService: DocumentService,
    private webSocketService: WebSocketService,
  ) {
    this.documentService.documentElementEvents.subscribe((event) => {
      if (event?.eventType === 'addOnFrame') {
        this.webSocketService.sendSessionEvent({
          eventType: 'ADD_ELEMENTS_ON_FRAME',
          changes: {
            id: event.element.id,
            selectedElements: event.selectedElements,
          },
        });
      } else if (event?.eventType === 'removeFromFrame') {
        this.webSocketService.sendSessionEvent({
          eventType: 'REMOVE_ELEMENTS_FROM_FRAME',
          changes: {
            selectedElements: event.selectedElements,
          },
        });
      }
    });
  }
}
