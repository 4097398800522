import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription, debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs';
import { ComponentEditorService } from '../component-editor.service';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../../document-store';
import { BoardPropertyPoliciesService } from '../../../board-property-policies/board-property-policies.service';
import { EntityPropertiesFormComponent } from '@common/entity-details/entity-properties-form/entity-properties-form.component';
import { ObjectUtil } from '@contrail/util';
import { Entities } from '@contrail/sdk';
import { ColorUtil } from '@util/color-util';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-component-entity-editor',
  templateUrl: './component-entity-editor.component.html',
  styleUrls: ['./component-entity-editor.component.scss'],
})
export class ComponentEntityEditorComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();
  @Input() selectedProperties: any[] = [];
  @Input() componentType: string; // 'ITEM' | 'COLOR'
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  //Color specific
  @ViewChild('colorPropertyForm') entityPropertyForm: EntityPropertiesFormComponent;
  private colorPropertySubscription: Subscription = new Subscription();

  selectedPropertySlugs: string[] = [];
  accessLevel = 'EDIT';
  editable = true;
  errors: any = {};
  entities: any[]; // all entities that belong in the selected component elements
  allItemOptions: any[];
  allItemFamilies: any[];
  allProjectItems: any[];
  selectedItemFamily: any;
  selectedItemOption: any;
  selectedProjectItem: any;
  selectedAssortmentItem: any;
  contextTypes = ['family', 'option', 'projectItem', 'assortmentItem'];
  showAllProperties = false;
  selectedProjectName = '';
  selectedAssortmentName = '';
  searchTerm: string = '';

  private exceptionProperties = ['projectItem.project.name', 'assortmentItem.assortment.name'];
  public colorType;

  constructor(
    private componentEditorService: ComponentEditorService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.subscribeToComponentObjectDetails();
  }

  ngOnInit() {
    this.colorType = this.boardPropertyPoliciesService.colorType;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProperties) {
      this.selectedPropertySlugs = this.selectedProperties
        .filter((prop) => prop.propertyDefinition && !this.exceptionProperties.includes(prop.propertyDefinition.id))
        .map((prop) => prop.propertyDefinition.slug);
    }

    if (changes.componentType) {
      if (this.componentType === 'COLOR') {
        setTimeout(() => {
          this.colorPropertySubscription.add(
            this.entityPropertyForm.changes.pipe(debounceTime(500)).subscribe((formChange) => {
              this.updateColorComponents(formChange);
            }),
          );
        }, 100);
      } else {
        this.colorPropertySubscription.unsubscribe();
      }
    }
  }

  ngAfterViewInit(): void {
    this.searchBar.valueChange
      .pipe(startWith(''), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchTerm) => {
        this.searchTerm = searchTerm;
        if (this.componentType === 'COLOR') {
          const colorType = this.boardPropertyPoliciesService.colorType;
          const filteredTypeProperties = colorType.typeProperties.filter((prop) =>
            prop.label.toLowerCase().includes(searchTerm.toLowerCase()),
          );
          this.colorType = { ...colorType, typeProperties: filteredTypeProperties };
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.colorPropertySubscription.unsubscribe();
  }

  subscribeToComponentObjectDetails() {
    this.store
      .select(DocumentSelectors.selectDocumentModelEntities)
      .pipe(takeUntil(this.destroy$))
      .subscribe((entities) => {
        this.entities = entities;
      });

    this.componentEditorService.componentObjectDetailsObs
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(async (componentObjectDetails) => {
        if (!componentObjectDetails || this.componentType === 'COLOR') {
          return;
        }

        // ITEM SPECIFIC
        this.allItemFamilies = [];
        this.allItemOptions = [];
        this.allProjectItems = [];

        console.log('componentObjectDetails', componentObjectDetails);
        this.selectedAssortmentItem = null;
        this.selectedItemOption = null;
        this.selectedItemFamily = null;
        if (componentObjectDetails?.entity?.projectItem) {
          const projectItemId = componentObjectDetails.entity.projectItem.id;
          this.selectedProjectItem = Object.assign(
            {},
            this.entities.find((e) => e.id === projectItemId),
          );
          this.selectedProjectName = this.selectedProjectItem?.project?.name;
        }

        if (this.entities) {
          this.entities.forEach((e) => {
            if (e?.entityType === 'item') {
              if (e?.roles.includes('option')) {
                this.allItemOptions.push(e);
                this.allItemFamilies.push(e.itemFamily);
              } else {
                this.allItemFamilies.push(e);
              }
            } else if (e?.entityType === 'project-item') {
              this.allProjectItems.push(e);
            }
          });
        }

        if (componentObjectDetails.entity?.item?.roles.includes('option')) {
          this.selectedItemOption = this.entities.find((e) => e.id === componentObjectDetails.entity.item.id);
          this.selectedItemFamily = this.selectedItemOption.itemFamily;
        } else {
          this.selectedItemFamily = this.entities.find((e) => e.id === componentObjectDetails.entity.item.id);
        }

        if (componentObjectDetails.entity?.assortmentItem) {
          this.selectedAssortmentItem = this.entities.find(
            (e) => e.id === componentObjectDetails.entity.assortmentItem.id,
          );
          this.selectedAssortmentName = this.selectedAssortmentItem.assortment?.name;
        }
      });
  }

  toggleContextType(event) {
    this.contextTypes = event.value;
  }

  handleEntityItemUpdate(event) {
    this.componentEditorService.saveEntitiesAndSyncElements(event);
    if (this.selectedItemOption) {
      this.selectedItemOption = { ...this.selectedItemOption, ...event.changes };
    }
  }

  private async updateColorComponents(formChange) {
    // update color entity
    let changes = {};
    changes[formChange.propertySlug] = formChange.value || null;
    const colorSlugs = ['red', 'green', 'blue', 'rgb', 'rgba', 'hexCode', 'hex'];
    if (colorSlugs.includes(formChange.propertySlug)) {
      const currentColorEntity = ObjectUtil.cloneDeep(this.entities[0]);
      try {
        changes = ColorUtil.syncColorValueProperties(currentColorEntity, formChange);
      } catch (error) {
        this.entities[0] = Object.assign({}, this.entities[0], changes);
        this.snackBar.open(`Error - ${error.message}`, '⛌', { duration: 1500 });
        // REVERT CHANGES LOCALLY
        const originalValue = currentColorEntity[formChange.propertySlug];
        const originalChanges = {};
        originalChanges[formChange.propertySlug] = originalValue;
        setTimeout(() => {
          this.entities[0] = Object.assign({}, this.entities[0], originalChanges);
        }, 0);
        return;
      }
    }

    const entity = Object.assign({}, this.entities[0], changes);
    entity.updatedOn = new Date();
    const entityReference = entity?.entityType + ':' + entity.id;
    await new Entities()
      .update({ reference: entityReference, object: changes })
      .then((res) => {})
      .catch((err) => {
        console.error('typeProperty value - updating failed', err);
      });

    // update canvas elements
    const object = ObjectUtil.cloneDeep(this.entities[0]);
    const event = { changes, object };
    this.componentEditorService.saveEntitiesAndSyncElements(event);
  }
}
