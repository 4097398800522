import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ContextMenuComponent],
  imports: [CommonModule, MatMenuModule, MatIconModule],
  exports: [ContextMenuComponent],
})
export class ContextMenuModule {}
