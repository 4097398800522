<div class="flex items-center">
  <div
    *ngIf="element.propertyBindings && element.propertyBindingsMetaData"
    class="h-[40px] ml-2 mr-1 flex items-center"
    [matTooltip]="getTooltipText()"
    matTooltipClass="multiline-tooltip"
  >
    <mat-icon data-test="dynamic-text-info">info_outline</mat-icon>
  </div>
  <button
    mat-icon-button
    matTooltip="Configure dynamic text"
    matTooltipPosition="above"
    (click)="showDynamicTextEditor()"
    data-test="configure-dynamic-text"
  >
    <mat-icon>tune</mat-icon>
  </button>
</div>
