import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Comment, CommentsService } from '../comments.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from '../comments-store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-side-nav-comments',
  templateUrl: './side-nav-comments.component.html',
  styleUrls: ['./side-nav-comments.component.scss'],
})
export class SideNavCommentsComponent {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  showCommentsSideBar$: Observable<boolean>;
  constructor(private store: Store<RootStoreState.State>) {
    this.showCommentsSideBar$ = this.store.select(CommentsSelectors.showCommentsSideBar).pipe(
      tap((val) => {
        this.handleCommentListToggle(val);
      }),
    );
  }
  public toggleSideBar() {
    this.store.dispatch(CommentsActions.toggleCommentsSideBar());
  }
  private handleCommentListToggle(val) {
    if (val) {
      this.sidenav?.open();
    } else {
      this.sidenav?.close();
    }
  }
}
