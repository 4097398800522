import { Directive, HostListener, Input } from '@angular/core';

import { Intercom } from '../intercom/intercom';

/* tslint:disable:directive-selector */
@Directive({
  selector: '[intercomHide]',
})
export class IntercomHideDirective {
  @Input() intercomHide: boolean;

  constructor(private intercom: Intercom) {}

  @HostListener('click')
  public onClick(): void {
    if (this.intercomHide !== false) {
      this.intercom.hide();
    }
  }
}
