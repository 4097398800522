import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EntityModalComponent } from '@common/entity-details/entity-modal/entity-modal.component';
import { Subscription } from 'rxjs';
import { IconTypes } from '../icon-types';

@Component({
  selector: 'app-entity-details-side-panel-header',
  template: `
    <div class="header">
      <img *ngIf="iconType?.icon" [src]="iconType.icon" class="w-6 h-6 object-contain mr-2" />
      <!-- <span *ngIf="iconType?.matIcon" class="material-icons text-primary">{{iconType.matIcon}}</span> -->
      <h6 class="mr-auto" [matTooltip]="name" [matTooltipDisabled]="name?.length < 20">
        {{ name | shorten: 20 }}
      </h6>

      <mat-icon (click)="expand()" *ngIf="false">zoom_out_map</mat-icon>
      <mat-icon (click)="close.emit()">close</mat-icon>
    </div>
  `,
  styles: [
    `
      .header {
        @apply h-12 border-b px-4 flex items-center;
      }
      mat-icon {
        @apply cursor-pointer ml-2;
      }
    `,
  ],
})
export class EntityDetailsSidePanelHeaderComponent implements OnChanges {
  @Input() entity: any;
  @Input() entityReference: any;
  @Output() close = new EventEmitter();
  @Output() updateEntity = new EventEmitter<any>();
  public name;
  iconType: any;
  constructor(private matDialog: MatDialog) {}

  ngOnChanges(): void {
    if (this.entity) {
      this.name = this.entity?.name || this.entity?.fileName; //TODO: @BTP Asset entity uses fileName, name should be mandatory.
      this.iconType = IconTypes[this.entity?.entityType];
    }
  }

  expand() {
    const config = {
      data: { entityReference: this.entityReference, entity: this.entity, accessLevel: 'EDIT' },
      panelClass: [`no-padding`, `entity-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(EntityModalComponent, config);
    const entityModalComponent: EntityModalComponent = dialogRef.componentInstance;
    const sub: Subscription = entityModalComponent.updated.subscribe((result) => {
      this.entity = { ...result.entity, ...result.changes };
      this.updateEntity.emit(this.entity);
    });

    entityModalComponent.dialogRef.afterClosed().subscribe(() => {
      // unsubscribe automatically
      sub.unsubscribe();
    });
  }
}
