import { Action, createReducer, on } from '@ngrx/store';
import * as SearchReplaceActions from './search-replace.actions';
import { searchReplaceInitialState, State } from './search-replace.state';

const toggleSearch = (state: State) => {
  return {
    ...state,
    searchActive: !state.searchActive,
    activeSearchResultElement: null,
    searchResults: [],
  };
};

const setSearchResults = (state: State, { searchResults }) => {
  return {
    ...state,
    searchResults,
  };
};

const setActiveSearchResultElement = (state: State, { activeSearchResultElement }) => {
  return {
    ...state,
    activeSearchResultElement,
  };
};

export const searchReplaceReducers = createReducer(
  searchReplaceInitialState,
  on(SearchReplaceActions.toggleSearch, toggleSearch),
  on(SearchReplaceActions.setSearchResults, setSearchResults),
  on(SearchReplaceActions.setActiveSearchResultElement, setActiveSearchResultElement),
);

export function reducer(state: State | undefined, action: Action): any {
  return searchReplaceReducers(state, action);
}
