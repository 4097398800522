<div class="header">
  <app-search-bar [lgGray]="true" [placeholder]="'Search'" class="w-72"></app-search-bar>
  <app-list-view-toggle (toggle)="setViewMode($event)" [mode]="viewMode" class="mr-auto"></app-list-view-toggle>

  <mat-select class="!w-[120px] !mr-12" [formControl]="templateTypeControl" data-test="template-library-template-type">
    <mat-option *ngFor="let templateType of templateTypes" [value]="templateType.key">
      {{ templateType.label }}
    </mat-option>
  </mat-select>

  <mat-slide-toggle
    labelPosition="before"
    class="mr-12 my-2"
    color="primary"
    [checked]="myTemplatesOnly.value"
    [formControl]="myTemplatesOnly"
    name="myTemplatesOnly"
  >
    <p class="text-sm">Created by me</p>
  </mat-slide-toggle>

  <div
    class="sort-by flex items-center cursor-pointer my-2"
    [matMenuTriggerFor]="sortByMenu"
    data-test="template-library-sort-by"
  >
    <p class="text-sm mr-2">Sort by:</p>
    <p class="text-sm text-primary">{{ sortByControl.value?.label }}</p>
    <mat-icon class="ml-1">arrow_drop_down</mat-icon>
  </div>
  <mat-menu #sortByMenu="matMenu" yPosition="below" xPosition="before" class="menu-sm">
    <button
      mat-menu-item
      *ngFor="let option of sortOptions"
      [attr.data-test]="'template-library-sort-by-' + option.property"
      (click)="sortBy(option)"
    >
      {{ option?.label }}
    </button>
  </mat-menu>
</div>

<div class="p-3">
  <div *ngIf="viewMode === 'cards'" class="cards">
    <app-frame-template-card
      *ngFor="let template of templates$ | async"
      [template]="template"
      (click)="update(template)"
      (openMenu)="showMenu({ mouseEvent: $event, listItem: template })"
      [isOrgTemplate]="template.admin"
      (contextmenu)="handleContextMenu({ mouseEvent: $event, listItem: template })"
    >
    </app-frame-template-card>
  </div>
  <app-list
    *ngIf="(templates$ | async)?.length && viewMode === 'list'"
    [listData]="templates$ | async"
    (clickRow)="update($event)"
    [columnDefinitions]="columnDefinitions"
    (contextMenu)="handleContextMenu($event)"
  >
  </app-list>
</div>
<app-context-menu [actionDefinitions]="contextMenuActions" (selected)="handleMenuAction($event)"></app-context-menu>
