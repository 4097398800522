import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasPenElement } from '../../../elements/pen/canvas-pen-element';
import { NormalizedEvent } from '../canvas-event-handlers';

export class EraserHandler {
  private visiblePenElements: Array<CanvasElement> = [];
  private inProcessOfDeleting: Array<string> = [];

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event: NormalizedEvent) {
    this.clear();
    const interactionMode = this.canvasDocument.interactionHandler.interactionMode;
    if (['eraser'].indexOf(interactionMode) === -1) return;

    this.visiblePenElements = this.canvasDocument
      .getVisibleElements()
      .filter((e) => !e.elementDefinition.isLocked && ['pen', 'highlighter'].indexOf(e.elementDefinition.type) !== -1);
    if (this.visiblePenElements?.length > 0) {
      this.getElementIntersect(event);
    }
  }

  public dragged(event: NormalizedEvent) {
    if (!this.visiblePenElements?.length) {
      return;
    }
    this.getElementIntersect(event);
  }

  public dragended(event: NormalizedEvent) {
    this.clear();
  }

  private getElementIntersect(event: NormalizedEvent) {
    const checkInBetweenPoints = true;
    let penElement;
    for (let i = this.visiblePenElements?.length - 1; i >= 0; i--) {
      const element = this.visiblePenElements[i] as CanvasPenElement;
      if (element.isPointInStroke(event, checkInBetweenPoints)) {
        penElement = element;
        break;
      }
    }

    if (!penElement) {
      return;
    }

    if (this.inProcessOfDeleting.indexOf(penElement.elementDefinition.id) === -1) {
      this.inProcessOfDeleting.push(penElement.elementDefinition.id);
      this.canvasDocument.actionsDispatcher.handleDocumentActions([
        new DocumentAction(
          {
            elementId: penElement.elementDefinition.id,
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementData: penElement.elementDefinition,
          },
          {
            elementId: penElement.elementDefinition.id,
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementData: penElement.elementDefinition,
          },
        ),
      ]);
    }
  }

  private clear() {
    this.visiblePenElements = [];
    this.inProcessOfDeleting = [];
  }
}
