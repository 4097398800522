import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CommentsSelectors, CommentsActions } from '@common/comments/comments-store';
import { Comment, CommentsService } from '@common/comments/comments.service';
import { Board } from '../board.service';
import { DocumentService } from '../document/document.service';
import { BoardsSelectors } from '../../boards-store';
import {
  DocumentElement,
  PositionDefinition,
  DocumentElementEvent,
  DocumentChangeType,
  SizeDefinition,
} from '@contrail/documents';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DocumentActions, DocumentSelectors } from '../document/document-store';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { AddPinnedCommentsService } from './add-pinned-comments-service';
import { CanvasUtil } from '../canvas/canvas-util';
import { DocumentViewSize } from '../document/document-store/document.state';

interface PinnedComment {
  groupKey: string; // key by which comments are grouped, for example 'board:300:400', 'elementId:100:50'
  count: number;
  firstComment: Comment;
  comments: Array<Comment>;
  hidden: boolean;
  disableDrag: boolean;
  documentElement?: {
    id: string;
    position: PositionDefinition;
  };
}

@Component({
  selector: 'app-board-pinned-comments',
  templateUrl: './board-pinned-comments.component.html',
  styleUrls: ['./board-pinned-comments.component.scss'],
})
export class BoardPinnedComments implements OnInit {
  private subscription: Subscription = new Subscription();
  private wheelEvent$: Subscription;
  private board: Board;
  private elements: Array<DocumentElement>;
  private accessLevel: string = 'EDIT';

  public viewSize: DocumentViewSize;
  public pinnedComments$: Observable<Array<PinnedComment>>;
  public showPinnedComments: boolean;
  public isDragging: boolean = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private snackBar: MatSnackBar,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private commentsService: CommentsService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.store.select(BoardsSelectors.currentBoard).subscribe((board) => (this.board = board)));
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
    });
    this.subscription.add(this.documentService.documentElements.subscribe((elements) => (this.elements = elements))); // Need to subscribe to document elements because board.document.elements is note updated on changes
    this.subscription.add(
      this.store
        .select(CommentsSelectors.selectedComment)
        .subscribe((selectedComment) => this.showSelectedComment(selectedComment)),
    );
    this.subscription.add(
      this.store
        .select(CommentsSelectors.commentsAccessLevel)
        .subscribe((accessLevel) => (this.accessLevel = accessLevel)),
    );

    this.subscription.add(
      this.documentService.documentElementEvents
        .pipe(filter((event) => !event || event?.eventType === 'dragStarted'))
        .subscribe((event) => {
          this.initPinnedComments(event);
        }),
    );

    this.subscription.add(
      this.documentService.documentElementEvents
        .pipe(filter((event) => !event || event?.eventType === 'dragEnded'))
        .subscribe((event) => {
          this.initPinnedComments(event);
        }),
    );

    this.subscription.add(
      this.documentService.documentActions
        .pipe(
          map(
            (actions) =>
              !actions?.length ||
              actions.filter(
                (action) =>
                  action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT ||
                  action.changeDefinition.changeType === DocumentChangeType.ADD_ELEMENT,
              ),
          ),
        )
        .subscribe(() => {
          this.initPinnedComments();
        }),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        this.showPinnedComments = showPinnedComments;
      }),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showCommentOverlay).subscribe((bool) => {
        if (!bool) {
          this.wheelEvent$?.unsubscribe();
        } else {
          this.subscribeToWheelEvent();
        }
      }),
    );

    this.initPinnedComments();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.wheelEvent$?.unsubscribe();
  }

  // Pinned comments - combine latest:
  // - all comments
  // - when elements are dragged (need to change comments position)
  // - when elements are deleted (need to hide elements from the board)
  // Filter comments that have either documentElementId or documentPosition
  // Group comments by each position on the screen or element
  // board:500:400, elementId:100:200, etc
  private initPinnedComments(event?: DocumentElementEvent) {
    this.pinnedComments$ = combineLatest([
      this.store.select(DocumentHistorySelectors.currentEntitySnapshot),
      this.store.select(CommentsSelectors.selectContextComments).pipe(filter((comments) => !!comments?.length)),
    ]).pipe(
      map(([entitySnapshot, comments]) => {
        if (entitySnapshot) {
          return [];
        }
        return comments;
      }),
      map((comments) =>
        comments.filter(
          (comment) => comment.status !== 'closed' && (comment.documentElementId || comment.documentPosition),
        ),
      ),
      map((comments) => Object.values(this.groupByDocumentElement(comments, event))),
    );
  }

  /**
   * Group a list of @comments into buckets of each comment group. For example:
   * 'board:300:500': {},
   * 'board:100:500': {},
   * 'elementId123:50:300': {},
   * 'elementId123:150:200': {},
   * 'elementId456:100:20': {}
   * @param comments
   * @param event
   */
  private groupByDocumentElement(comments: Array<Comment>, event?: DocumentElementEvent) {
    return comments.reduce((counter, comment) => {
      const groupKey = `${comment.documentElementId || 'board'}:${comment.documentPosition.x},${comment.documentPosition.y}`;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].comments.push(comment);
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        const element = this.getDocumentElement(comment.documentElementId);
        if (comment.documentElementId && element === undefined) {
          // if element was deleted do not show the comment on the board
          return counter;
        }
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
          comments: [comment],
          hidden:
            event &&
            event.selectedElements?.length > 0 &&
            event.selectedElements.findIndex((e) => e.id === element?.id) !== -1,
          disableDrag: !this.commentsService.canUpdateComment(comment, this.accessLevel),
          documentElement: element
            ? {
                id: element.id,
                position: element.position,
                size: element.size,
              }
            : null,
        };
      }
      return counter;
    }, {});
  }

  private getDocumentElement(documentElementId?: string): DocumentElement {
    return documentElementId ? this.elements.find((element) => element.id === documentElementId) : null;
  }

  private toWindowPosition(position: PositionDefinition): PositionDefinition {
    if (this.viewSize) {
      return CanvasUtil.toWindowPosition(
        position?.x,
        position?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  /**
   * Navigate to the @selectedComment
   * Possible options:
   * - comment on the document element
   * - comment at a document position
   * - comment on a deleted element
   * - board specific comment
   * @param selectedComment
   */
  private showSelectedComment(selectedComment: Comment) {
    if (selectedComment && this.elements?.length > 0) {
      if (selectedComment.documentElementId) {
        const element = this.getDocumentElement(selectedComment.documentElementId);
        if (!element) {
          this.snackBar.open('Element no longer exists.', '', { duration: 4000 });
        } else {
          this.navigateToSelectedComment(selectedComment, element);
        }
      } else if (selectedComment.documentPosition) {
        this.navigateToSelectedComment(selectedComment);
      } else {
        this.snackBar.open('Board specific comment.', '', { duration: 5000 });
      }
    }
  }

  /**
   * Place the screen such that @selectedComment is in the middle of the screen
   * and show comments overlay
   * @param selectedComment
   * @param documentElement
   */
  private navigateToSelectedComment(selectedComment: Comment, documentElement?: DocumentElement) {
    this.store.dispatch(
      DocumentActions.navigateToPosition({
        position: {
          x: selectedComment.documentPosition.x + (documentElement ? documentElement.position.x : 0) + 180, // add half of the width of the comments sidebar
          y: selectedComment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
        },
      }),
    );
    setTimeout(
      () =>
        this.showComments(
          selectedComment,
          documentElement
            ? { id: documentElement.id, position: documentElement.position, size: documentElement.size }
            : null,
        ),
      200,
    );
  }

  /**
   * If comment overlay is open adjust it's position when window position is changed
   */
  private adjustCommentOverlayPosition() {
    const commentOverlayPosition = this.addPinnedCommentsService.commentOverlayPosition;
    if (!commentOverlayPosition) {
      return;
    }
    const windowPosition: PositionDefinition = this.toWindowPosition(commentOverlayPosition);
    this.store.dispatch(
      CommentsActions.updateCommentOverlayPosition({
        position: {
          x: windowPosition.x,
          y: windowPosition.y,
        },
      }),
    );
  }

  // Adjust comment overlay position on mouse move if it's open
  // @HostListener('wheel', ['$event'])
  private onMouseWheel(event) {
    this.adjustCommentOverlayPosition();
  }

  private subscribeToWheelEvent() {
    if (!this.wheelEvent$ || this.wheelEvent$.closed) {
      this.wheelEvent$ = fromEvent(window, 'wheel', { passive: true })
        .pipe(
          tap((event: any) => {
            this.onMouseWheel(event);
          }),
        )
        .subscribe();
    }
  }

  /**
   * Get top and left style for the comment bubble
   * @param comment
   * @param documentElement
   */
  public getStyle(
    comment: Comment,
    documentElement?: { id: string; position: PositionDefinition; size: SizeDefinition },
  ) {
    const position = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };
    const windowPosition: PositionDefinition = this.toWindowPosition(position);
    return {
      top: `${windowPosition.y - 34}px`,
      left: `${windowPosition.x}px`,
    };
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   * @param documentElement
   */
  public showComments(
    comment: Comment,
    documentElement?: { id: string; position: PositionDefinition; size: SizeDefinition },
  ) {
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
    const position = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };
    const windowPosition: PositionDefinition = this.toWindowPosition(position);
    this.addPinnedCommentsService.addComments(position, windowPosition, documentElement);
  }

  public trackByGroupKey(index, item) {
    return item.groupKey;
  }

  public cdkDragStarted($event) {
    this.isDragging = true;
    this.store.dispatch(CommentsActions.hideCommentOverlay());
  }

  public cdkDragEnded($event, comments: Array<Comment>) {
    const commentBubbleRect = $event.source.element.nativeElement.getBoundingClientRect();
    this.addPinnedCommentsService.updateCommentsPosition(
      {
        x: commentBubbleRect.x,
        y: commentBubbleRect.y + 34,
      },
      comments,
    );
    this.isDragging = false;
    this.initPinnedComments();
  }
}
