import { DocumentElement, DocumentTextElementEvent, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { CanvasElement } from '../../elements/canvas-element';
import { TEXT_BOX_PADDING } from '../../elements/text/editor/text-editor';
import { TextEditorContainer } from '../../elements/text/editor/text-editor-container';
import { Editor } from './editor';
import { EditorContainer } from './editor-container';
import { EditorFormatter } from './editor-formatter';
import { TextChanges } from './editor-handler';
import { ChildElementDetails, EditorPlaceholder } from './editor-placeholder';

export interface EditorOptions {
  hideElement?: boolean;
  autoSize?: 'font' | 'height';
  setLastAppliedTextFormat?: boolean;
}

export abstract class EditorManager {
  private prevElementId: string;
  public element: CanvasElement;
  public elementDefinition: DocumentElement;
  public editor: Editor;
  public editorContainer: EditorContainer;
  public editorPlaceholder: EditorPlaceholder;

  constructor(
    public canvasDocument: CanvasDocument,
    public options: EditorOptions,
    public editorOptions,
  ) {}

  public showEditor(element: CanvasElement) {
    if (this.prevElementId && this.prevElementId !== element?.id) {
      // Clear all undo levels so other element's content changes do not get applied to new element
      this.editor?.getEditor()?.undoManager?.clear();
    }
    this.prevElementId = element.id;
    this.element = element;
    this.elementDefinition = ObjectUtil.cloneDeep(element.elementDefinition);
    this.editorContainer.showEditor();
    this.editor.setElement(true);
    this.element.isVisible = false;
    this.editor.focus();
    this.canvasDocument.draw();
  }

  public hideEditor(save = true) {
    this.editorContainer?.hideEditor();
    this.editor?.removeFocus(save);
    if (this.element) {
      this.element.isVisible = true;
      this.canvasDocument.draw();
    }
    this.element = null;
    this.elementDefinition = null;
  }

  public redrawEditor() {
    this.editorContainer?.redrawEditor();
  }

  public removeEditor() {
    this.editorContainer?.remove();
  }

  public refreshElement(element: CanvasElement) {
    if (element.elementDefinition.id !== this.elementDefinition?.id) {
      return;
    }
    this.element = element;
    this.elementDefinition = ObjectUtil.cloneDeep(element.elementDefinition);
    this.editorContainer?.refreshElement(element);
  }

  /**
   * Replace all inline font sizes with font size value multipled by scale
   * @param element
   * @param text
   * @returns
   */
  public scaleFontSizes(element: CanvasElement, text: string): string {
    if (text && text != null && element?.elementDefinition?.scale?.x != null) {
      const regex = /(?:\bfont-size:)\s*([^;]*)[;"](?=[^>]*>)/g;
      text = text.replace(regex, (match, fontSizeString) => {
        let fontSize = this.editor.toPt(fontSizeString).replace('pt', '').replace('px', '');
        fontSize = '' + Math.round(parseFloat(fontSize) * element.elementDefinition.scale.x);
        return `font-size: ${fontSize}pt;`;
      });
    }
    return text;
  }

  /**
   * Assign new element data to @elementChanges
   * @param element
   * @param change
   * @param elementChanges
   * @param isFocused
   * @returns
   */
  public assignTextEventChanges(
    element: CanvasElement,
    changes: DocumentTextElementEvent[],
    elementChanges,
    isFocused = true,
  ) {
    const shouldApplyTextType = changes.find(
      (change) => change?.textFormat?.type === 'textType' && change?.textFormat?.value,
    );

    if (shouldApplyTextType) {
      // Hide editor if changing text type
      this.hideEditor(false);
    }

    let editorContainerVisibility;
    if (!isFocused || shouldApplyTextType) {
      editorContainerVisibility = this.tempSetElement(element);
    }

    const data = this.applyTextEventChanges(element, changes);

    if ((!isFocused || shouldApplyTextType) && editorContainerVisibility) {
      this.tempRemoveElement(editorContainerVisibility);
    }

    if (data?.textChanges && Object.keys(data.textChanges)?.length > 0) {
      elementChanges.textChanges = [ObjectUtil.mergeDeep(elementChanges.textChanges[0] || {}, data.textChanges)];
      elementChanges.textUndoChanges = [
        ObjectUtil.mergeDeep(elementChanges.textUndoChanges[0] || {}, data.textUndoChanges),
      ];
    }

    return elementChanges;
  }

  private applyTextEventChanges(element: CanvasElement, changes: DocumentTextElementEvent[]) {
    let data: TextChanges = {
      textUndoChanges: ObjectUtil.cloneDeep(element.elementDefinition),
    };
    changes.forEach((change) => {
      const shouldApplyFormat =
        element.elementDefinition.type === 'text' && element.elementDefinition.isTextTool
          ? EditorFormatter.FORMATS.filter((s) => s !== 'fontSize').indexOf(change.textFormat.type) !== -1
          : EditorFormatter.FORMATS.indexOf(change.textFormat.type) !== -1;
      const shouldApplyStyle =
        change.textFormat?.type === 'textValign' ||
        change.textFormat?.type === 'backgroundColor' ||
        (element.elementDefinition.type === 'sticky_note' && change.textFormat.type === 'textAlign') ||
        (element.elementDefinition.type === 'text' &&
          element.elementDefinition.isTextTool &&
          change.textFormat.type === 'fontSize');
      const shouldApplyTextType = change?.textFormat?.type === 'textType' && change?.textFormat?.value;

      let textChanges: TextChanges = {};
      if (shouldApplyStyle) {
        textChanges = this.applyStyleProperty(element, change);
        if (textChanges.textChanges) {
          element.elementDefinition = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(element.elementDefinition),
            textChanges.textChanges,
          );
        }
      } else if (shouldApplyFormat) {
        textChanges = this.applyFormat(element, change);
      } else if (shouldApplyTextType) {
        textChanges = this.applyTextType(element, change, element.elementDefinition.text);
      }

      if (textChanges.textChanges) {
        data.textChanges = ObjectUtil.mergeDeep(data.textChanges || {}, textChanges.textChanges);
      }
    });

    if (data?.textChanges) {
      element.elementDefinition = ObjectUtil.mergeDeep(
        ObjectUtil.cloneDeep(element.elementDefinition),
        data.textChanges,
      );
      const sizeData = this.applyTextToolSize(element, data?.textChanges?.text || element.elementDefinition.text);

      data.textChanges = ObjectUtil.mergeDeep(data.textChanges, sizeData.textChanges);
    }

    return data;
  }

  /**
   * Apply text @changes to @element's style property
   * @param element
   * @param changes
   * @returns
   */
  protected applyStyleProperty(element: CanvasElement, changes: DocumentTextElementEvent): TextChanges {
    return;
  }
  protected applyTextType(element: CanvasElement, changes: DocumentTextElementEvent, text): TextChanges {
    return;
  }

  /**
   * Format text according to @changes
   * Set editor inner html if not @isFocused
   * @param element
   * @param change
   * @param isFocused
   * @returns
   */
  private applyFormat(element: CanvasElement, change: DocumentTextElementEvent): TextChanges {
    let data = this.editor.execCommand(change.textFormat);

    if (change?.textFormat?.type === 'clearFormat' && element?.elementDefinition?.type === 'text') {
      if (!data) {
        data = {
          changes: {
            id: element?.elementDefinition?.id,
          },
          undoChanges: ObjectUtil.cloneDeep(element?.elementDefinition),
        };
      }
      data.changes = ObjectUtil.mergeDeep(
        ObjectUtil.cloneDeep(data?.changes || {}),
        element?.VALID_PROPERTIES?.reduce((obj, path) => {
          let value;
          if (path === 'style.backgroundColor') {
            value = element.DEFAULT_BACKGROUND_COLOR;
          } else if (path === 'style.border') {
            value = {
              width: element.DEFAULT_BORDER_SIZE,
              color: element.DEFAULT_BORDER_COLOR,
            };
          } else if (path === 'style.font.size') {
            value = element.DEFAULT_FONT_SIZE;
          } else if (path === 'style.text.valign') {
            value = 'top';
          }
          if (value) {
            ObjectUtil.setByPath(obj, path, value);
          }
          return obj;
        }, {}),
      );
    }

    return {
      textChanges: data?.changes,
      textUndoChanges: data?.undoChanges,
    };
  }

  /**
   * Temporarily set editor html to @element's text so format can be applied.
   * After format is appllied @hideTemporaryElement should be called.
   * @param element
   * @returns
   */
  private tempSetElement(element: CanvasElement): string {
    const editorContainerVisibility = this.editorContainer.tempSetVisible();
    this.editorContainer.editor.innerHTML = element.elementDefinition.text;
    this.element = element;
    this.elementDefinition = ObjectUtil.cloneDeep(element.elementDefinition);
    this.editor.setElement();
    return editorContainerVisibility;
  }

  private tempRemoveElement(editorContainerVisibility) {
    console.log('EditorManager.tempRemoveElement', editorContainerVisibility);
    this.editorContainer.tempSetHidden(editorContainerVisibility);
  }

  /**
   * Get size changes for a text tool element
   * @param element
   * @param text
   * @returns
   */
  protected applyTextToolSize(element: CanvasElement, text: string): TextChanges {
    const data = { textChanges: {}, textUndoChanges: {} };
    if (element.elementDefinition.isTextTool && text != null) {
      const isEditing = this.isEditing(element);
      text = text === '' || !isEditing ? text : this.getInnerHTML();
      const undoSize = { ...element.elementDefinition.size };
      const sizeChanges = (this.editorContainer as TextEditorContainer).setTextToolEditorSize(element, text);
      if (
        sizeChanges?.size &&
        (sizeChanges.size.width !== undoSize.width || sizeChanges.size.height !== undoSize.height)
      ) {
        data.textChanges = {
          size: sizeChanges.size,
          id: element.id,
        };
        data.textUndoChanges = {
          size: undoSize,
          id: element.id,
        };
      }
    }
    return data;
  }

  public getCurrentStyle(element: CanvasElement) {
    this.editor.getCurrentStyle(element);
  }

  public handleFocusHyperlink(details: ChildElementDetails, element: DocumentElement, position: PositionDefinition) {
    const textFormat = {
      link: details.url,
      linkMode: 'view',
      position,
    };
    this.canvasDocument.actionsDispatcher.handleDocumentTextElementEvent({ element, textFormat });
  }

  public handleBlurHyperlink(details: ChildElementDetails = null) {
    const textFormat = {
      linkMode: 'remove',
    };
    this.canvasDocument.actionsDispatcher.handleDocumentTextElementEvent({
      element: this.editor.isFocused ? this.elementDefinition : null,
      textFormat,
    });
  }

  public isEditing(element: CanvasElement) {
    return this.element?.id === element.id && this.editor.isFocused;
  }

  public getInnerHTML() {
    return this.editorContainer?.editor?.innerHTML;
  }
}
