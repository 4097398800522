import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService, User } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserOrgResolver {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    const promise = this.authService.getCurrentUser().then(async (user) => {
      //      console.log('UserOrgResolver resolving org: ', user);
      const orgSlug = route.paramMap.get('org');

      let orgMembership = user?.orgs?.filter((org) => org.orgSlug === orgSlug).shift();
      const shareToken = route.queryParamMap.get('st');
      this.authService.setShareToken(shareToken);

      //      console.log('UserOrgResolver:  shareToken: ', shareToken);
      // User is not a member of this org
      if (!orgMembership) {
        if (!shareToken) {
          this.router.navigate(['/no-org-found']);
        } else {
          //          console.log('UserOrgResolver:  GUEST/SHARED LINK');
          orgMembership = { orgId: null, orgSlug, role: 'GUEST' };
        }
      }
      await this.authService.setCurrentOrg(orgMembership);

      // Refreshes cognito token, and creates API token with concatenated string (if shared token)
      this.authService.loadToken();

      if (shareToken) {
        await this.authService.loadShareLink();
      }

      return user;
    });
    return from(promise);
  }
}
