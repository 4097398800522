export class PointerEventTypes {
  /**
   * The pointerdown event is fired when a pointer becomes active. For mouse, it is fired when the device transitions from no buttons depressed to at least one button depressed. For touch, it is fired when physical contact is made with the digitizer. For pen, it is fired when the stylus makes physical contact with the digitizer.
   */
  static readonly POINTERDOWN = 1;
  /**
   * The pointerup event is fired when a pointer is no longer active.
   */
  static readonly POINTERUP = 2;
  /**
   * The pointermove event is fired when a pointer changes coordinates.
   */
  static readonly POINTERMOVE = 4;
  /**
   * The pointerwheel event is fired when a mouse wheel has been rotated.
   */
  static readonly POINTERWHEEL = 8;
  /**
   * The pointerpick event is fired when a mesh or sprite has been picked by the pointer.
   */
  static readonly POINTERPICK = 16;
  /**
   * The pointertap event is fired when a the object has been touched and released without drag.
   */
  static readonly POINTERTAP = 32;
  /**
   * The pointerdoubletap event is fired when a the object has been touched and released twice without drag.
   */
  static readonly POINTERDOUBLETAP = 64;
}
