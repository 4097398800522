import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssortmentHistoryComponent } from './assortment-history.component';
import { AssortmentHistoryItemComponent } from './assortment-history-item/assortment-history-item.component';
import { ComponentsModule } from '../components/components.module';
import { MatIconModule } from '@angular/material/icon';
import { AssortmentHistoryDetailComponent } from './assortment-history-detail/assortment-history-detail.component';
import { AssortmentHistoryItemChangeComponent } from './assortment-history-detail/assortment-history-item-change/assortment-history-item-change.component';
import { AssortmentHistoryChangeItemDetailsComponent } from './assortment-history-detail/assortment-history-change-item-details/assortment-history-change-item-details.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AssortmentAggregateHistoryComponent } from './assortment-aggregate-history/assortment-aggregate-history.component';
import { AssortmentAggregateHistoryChartComponent } from './assortment-aggregate-history/assortment-aggregate-history-chart/assortment-aggregate-history-chart.component';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TypesModule } from '../types/types.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PipesModule } from '@common/pipes/pipes.module';

@NgModule({
  declarations: [
    AssortmentHistoryComponent,
    AssortmentHistoryItemComponent,
    AssortmentHistoryDetailComponent,
    AssortmentHistoryItemChangeComponent,
    AssortmentHistoryChangeItemDetailsComponent,
    AssortmentAggregateHistoryComponent,
    AssortmentAggregateHistoryChartComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatIconModule,
    MatButtonModule,
    GoogleChartsModule,
    MatTooltipModule,
    TypesModule,
    MatFormFieldModule,
    MatSelectModule,
    PipesModule,
  ],
  exports: [
    AssortmentHistoryComponent,
    AssortmentHistoryItemComponent,
    AssortmentHistoryItemChangeComponent,
    AssortmentHistoryDetailComponent,
    AssortmentHistoryChangeItemDetailsComponent,
    AssortmentAggregateHistoryChartComponent,
  ],
})
export class AssortmentHistoryModule {}
