import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectReferenceChooserComponent } from './object-reference-chooser/object-reference-chooser.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ChooserModule } from '@common/chooser/chooser.module';

@NgModule({
  declarations: [ObjectReferenceChooserComponent],
  imports: [CommonModule, VirtualScrollerModule, ChooserModule],
  exports: [ObjectReferenceChooserComponent],
})
export class ObjectReferenceModule {}
