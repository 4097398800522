import { on } from '@ngrx/store';
import { State } from '../document-manager.state';
import { documentElementEntityAdapter } from './document-elements.state';
import * as DocumentElementActions from './document-elements.actions';

const setDocumentElements = (state: State, { documentElements }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.setAll(documentElements, state.documentElements),
  };
};

const clearData = (state: State, {}) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.removeAll(state.documentElements),
    documentElementsLoaded: false,
    documentElementSortOrder: null,
  };
};

const updateDocumentElementEntity = (state: State, { id, documentElement }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.updateOne({ id, changes: documentElement }, state.documentElements),
  };
};

const updateDocumentElementEntities = (state: State, { documentElements }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.updateMany(
      documentElements.map((e) => Object.assign({}, { id: e.id, changes: e.documentElement })),
      state.documentElements,
    ),
  };
};

const removeDocumentElementEntity = (state: State, { id }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.removeOne(id, state.documentElements),
  };
};

const removeDocumentElementEntities = (state: State, { ids }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.removeMany(ids, state.documentElements),
  };
};

const addDocumentElementEntity = (state: State, { documentElement }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.addOne(documentElement, state.documentElements),
  };
};

const addDocumentElementEntities = (state: State, { documentElements }) => {
  return {
    ...state,
    documentElements: documentElementEntityAdapter.addMany(documentElements, state.documentElements),
  };
};

export const documentElementReducers = [
  on(DocumentElementActions.setDocumentElements, setDocumentElements),
  on(DocumentElementActions.clearDocumentElements, clearData),
  on(DocumentElementActions.applyDocumentElementEntityChanges, updateDocumentElementEntity),
  on(DocumentElementActions.batchApplyDocumentElementEntityChanges, updateDocumentElementEntities),
  on(DocumentElementActions.removeDocumentElementEntity, removeDocumentElementEntity),
  on(DocumentElementActions.removeDocumentElementEntities, removeDocumentElementEntities),
  on(DocumentElementActions.addDocumentElementEntity, addDocumentElementEntity),
  on(DocumentElementActions.addDocumentElementEntities, addDocumentElementEntities),
];
