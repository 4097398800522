import { PositionDefinition, SizeDefinition, ViewBox } from '@contrail/documents';
import { CanvasElement } from '../canvas-element';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CoordinateBox } from '../../coordinate-box';

export class CanvasGroupElement extends CanvasElement {
  protected draw(ctx: any, opts: any): { height: number } {
    // Group element is not visible. Nothing is drawn.
    return null;
  }

  public getBoundingClientRect(options?: DrawOptions): { x: any; y: any; width: any; height: any } {
    return this.getDimensions(options);
  }

  public isVisibleElement(viewBox: ViewBox) {
    // Group element is not visible. Always return false to avoid getting its dimensions repeatedly.
    return false;
  }

  public isPointOnElement(px: number, py: number, margin: number = 0) {
    // Group element is not interactable directly unless selected.
    if (!this.isSelected || this.isInFrame) {
      return false;
    }
    const allMemberElements = this.canvasDocument.state.groupState.getAllElementsInGroup(this.id, true);
    for (let element of allMemberElements) {
      // if click happens on a member element, use the member element for editing purposes.
      if (element.isPointOnElement(px, py, margin)) {
        return false;
      }
    }
    return super.isPointOnElement(px, py, margin);
  }

  public getDimensions(options?: DrawOptions): CoordinateBox {
    const selectedElements = this.canvasDocument.state.groupState.getAllElementsInGroup(this.id);
    const commonBounds = this.canvasDocument.state.getCommonBounds(selectedElements, options);
    return commonBounds;
  }

  public getSize(options?: DrawOptions): SizeDefinition {
    const dimensions = this.getDimensions(options);
    return { width: dimensions.width, height: dimensions.height };
  }

  public getPosition(options?: DrawOptions): PositionDefinition {
    const dimensions = this.getDimensions(options);
    return { x: dimensions.x, y: dimensions.y };
  }
}
