import { NgModule } from '@angular/core';
import { UndoRedoService } from './undo-redo-service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [UndoRedoService],
  exports: [],
})
export class UndoRedoModule {
  constructor() {}
}
