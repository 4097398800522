import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { DocumentService } from '../../document.service';
import { ObjectUtil } from '@contrail/util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CoordinateHelper } from '@contrail/svg';

@Injectable({
  providedIn: 'root',
})
export class MoveDocumentElementService {
  private subject: Subject<DocumentAction[]> = new Subject();
  private readonly STEP = 7;
  private readonly TINY_STEP = 2;

  constructor(private documentService: DocumentService) {
    this.subject.pipe(debounceTime(500)).subscribe((actions) => {
      this.handleDocumentActions(actions);
    });

    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'move_element') {
        this.handleArrowKey(request.sourceEvent);
      }
    });
  }

  private handleArrowKey(event) {
    const selected = this.documentService.getSelectedExpandedElements();
    const changes = [];
    const actions = selected
      .filter((e) => !e.isLocked)
      .map((el, i) => {
        const undoElementData = ObjectUtil.cloneDeep(el);
        const elementData = this.updateElementPosition(event, el);
        const canvasElement = this.documentService.documentRenderer.getCanvasElementById(el.id);

        changes.push(elementData);

        this.documentService.handleDocumentElementEvent({
          element: canvasElement.elementDefinition,
          eventType: 'dragStarted',
        });

        const action = new DocumentAction(
          {
            elementId: el.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData,
          },
          {
            elementId: el.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: undoElementData,
          },
        );

        return action;
      });

    this.documentService.documentRenderer.applyChanges(changes);

    this.subject.next(actions);
  }

  private updateElementPosition(event, el: DocumentElement): DocumentElement {
    const elementData: DocumentElement = {
      id: el.id,
    };

    switch (event.code) {
      case 'ArrowUp':
        if (el.type === 'line') {
          elementData.lineDefinition = ObjectUtil.cloneDeep(el.lineDefinition);
          elementData.lineDefinition = {
            x1: el.lineDefinition.x1,
            y1: el.lineDefinition.y1 - (event.shiftKey ? this.TINY_STEP : this.STEP),
            x2: el.lineDefinition.x2,
            y2: el.lineDefinition.y2 - (event.shiftKey ? this.TINY_STEP : this.STEP),
          };
        } else {
          elementData.position = {
            x: el.position.x,
            y: el.position.y - (event.shiftKey ? this.TINY_STEP : this.STEP),
          };
        }
        break;
      case 'ArrowDown':
        if (el.type === 'line') {
          elementData.lineDefinition = ObjectUtil.cloneDeep(el.lineDefinition);
          elementData.lineDefinition = {
            x1: el.lineDefinition.x1,
            y1: el.lineDefinition.y1 + (event.shiftKey ? this.TINY_STEP : this.STEP),
            x2: el.lineDefinition.x2,
            y2: el.lineDefinition.y2 + (event.shiftKey ? this.TINY_STEP : this.STEP),
          };
        } else {
          elementData.position = {
            x: el.position.x,
            y: el.position.y + (event.shiftKey ? this.TINY_STEP : this.STEP),
          };
        }
        break;
      case 'ArrowLeft':
        if (el.type === 'line') {
          elementData.lineDefinition = ObjectUtil.cloneDeep(el.lineDefinition);
          elementData.lineDefinition = {
            x1: el.lineDefinition.x1 - (event.shiftKey ? this.TINY_STEP : this.STEP),
            y1: el.lineDefinition.y1,
            x2: el.lineDefinition.x2 - (event.shiftKey ? this.TINY_STEP : this.STEP),
            y2: el.lineDefinition.y2,
          };
        } else {
          elementData.position = {
            x: el.position.x - (event.shiftKey ? this.TINY_STEP : this.STEP),
            y: el.position.y,
          };
        }
        break;
      case 'ArrowRight':
        if (el.type === 'line') {
          elementData.lineDefinition = ObjectUtil.cloneDeep(el.lineDefinition);
          elementData.lineDefinition = {
            x1: el.lineDefinition.x1 + (event.shiftKey ? this.TINY_STEP : this.STEP),
            y1: el.lineDefinition.y1,
            x2: el.lineDefinition.x2 + (event.shiftKey ? this.TINY_STEP : this.STEP),
            y2: el.lineDefinition.y2,
          };
        } else {
          elementData.position = {
            x: el.position.x + (event.shiftKey ? this.TINY_STEP : this.STEP),
            y: el.position.y,
          };
        }
        break;
    }

    return elementData;
  }

  private handleDocumentActions(actions: DocumentAction[]) {
    actions.forEach((action) => {
      const canvasElement = this.documentService.documentRenderer.getCanvasElementById(
        action.changeDefinition.elementId,
      );
      const coordRect = canvasElement.getBoundingClientRect();
      this.documentService.handleDocumentElementEvent({
        element: canvasElement.elementDefinition,
        eventType: 'dragEnded',
        renderedElementPosition: { x: coordRect.x, y: coordRect.y },
        renderedElementSize: { width: coordRect.width, height: coordRect.height },
      });
    });

    this.documentService.handleDocumentActions(actions);
  }
}
