<div class="flex items-center px-1" cdkDrag cdkDragRootElement="app-property-configurator-bar">
  <mat-icon
    cdkDragHandle
    class="icons material-icons-outlined mx-1 !w-[22px] !h-[22px] opacity-50 !text-[22px] !text-black60"
    svgIcon="iframe"
  ></mat-icon>
  <button (click)="openIframe()" mat-icon-button matTooltip="Open" matTooltipPosition="above" data-test="open-iframe">
    <mat-icon>launch</mat-icon>
  </button>
  <mat-divider vertical="true" class="divider"></mat-divider>
</div>
