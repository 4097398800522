import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-content-thumbnail',
  template: `
    <div class="content-thumbnail min-w-[44px]">
      <img *ngIf="previewUrl" [src]="previewUrl | secureImage | async" />
      <mat-icon *ngIf="!previewUrl && is3d">3d_rotation</mat-icon>
      <div *ngIf="!previewUrl && !is3d">{{ extension }}</div>
    </div>
  `,
  styles: [
    `
      .content-thumbnail {
        @apply w-11 h-11 border flex items-center justify-center mr-2;
        img {
          @apply w-11 h-11 object-contain min-w-[44px];
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    `,
  ],
})
export class ContentThumbnailComponent implements OnChanges {
  @Input() content: any;

  public isImage = false;
  public is3d = false;
  public previewUrl: string;
  public noPreview: boolean;
  public extension: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.previewUrl = null;

    const isImage = ContentService.isImage(this.content);
    const isAsyncViewable = ContentService.isAsyncViewableContentType(this.content);
    if (isImage) {
      this.previewUrl = this.content?.smallViewableUrl || this.content?.primaryFile?.downloadUrl;
    } else if (isAsyncViewable) {
      this.previewUrl = this.content?.smallViewableUrl;
    }

    const fileName = this.content?.primaryFile?.fileName;
    this.extension = fileName?.split('.')?.pop();
    //TODO: @Brian we always return content.contenType?
    this.is3d = this.content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
  }
}
