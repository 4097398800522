<div *ngIf="layout === 'menu'">
  <div class="widgets-list">
    <button class="tinymce-format-button" mat-icon-button [matMenuTriggerFor]="alignmentMenu" data-test="valign-menu">
      <mat-icon class="text-black60">{{
        'vertical_align_' + (textAlign === 'middle' ? 'center' : textAlign)
      }}</mat-icon>
    </button>
  </div>

  <mat-menu #alignmentMenu="matMenu">
    <ng-template matMenuContent let-frame="frame">
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('top')" data-test="valign-top">
        <mat-icon class="icons" [ngClass]="textAlign === 'top' ? '!text-accent' : '!text-black60'"
          >vertical_align_top</mat-icon
        >
        <span>Top</span>
      </button>
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('middle')" data-test="valign-middle">
        <mat-icon class="icons" [ngClass]="textAlign === 'middle' ? '!text-accent' : '!text-black60'"
          >vertical_align_center</mat-icon
        >
        <span>Center</span>
      </button>
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('bottom')" data-test="valign-bottom">
        <mat-icon class="icons" [ngClass]="textAlign === 'bottom' ? '!text-accent' : '!text-black60'"
          >vertical_align_bottom</mat-icon
        >
        <span>Bottom</span>
      </button>
    </ng-template>
  </mat-menu>
</div>

<div *ngIf="layout === 'list'">
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'top' ? 'accent' : ''"
    (click)="setValue('top')"
    mat-icon-button
  >
    <mat-icon class="icons">vertical_align_top</mat-icon>
  </button>
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'middle' ? 'accent' : ''"
    (click)="setValue('middle')"
    mat-icon-button
  >
    <mat-icon class="icons">vertical_align_center</mat-icon>
  </button>
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'bottom' ? 'accent' : ''"
    (click)="setValue('bottom')"
    mat-icon-button
  >
    <mat-icon class="icons">vertical_align_bottom</mat-icon>
  </button>
</div>
