import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { SortDefinition, SortDirection } from '../sort-definition';

@Component({
  selector: 'app-sort-panel',
  templateUrl: './sort-panel.component.html',
  styleUrls: ['./sort-panel.component.scss'],
})
export class SortPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() properties: Array<SortDefinition>;
  @Input() currentSorts: Array<SortDefinition> = [];
  @Input() hideApplyOptions = false; // Adds the 'apply' section & logic. Otherwise always emits updates
  @Output() performSort = new EventEmitter();
  @Output() sortChange = new EventEmitter();

  sorts: Array<SortDefinition> = [];
  keepSorted = false;

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currentSorts) {
      this.sorts = ObjectUtil.cloneDeep(changes.currentSorts.currentValue);
    }
  }

  addSort(event) {
    this.sorts.push({
      propertySlug: '',
      propertyLabel: 'Select Property',
      propertyType: PropertyType.Text,
      direction: SortDirection.ASCENDING,
    });
  }

  removeSort(index) {
    this.sorts.splice(index, 1);
    this.applySort(this.sorts.length === 0);
  }

  applySort(skipCheck) {
    if (this.keepSorted || skipCheck || this.hideApplyOptions) {
      const sortsClone = ObjectUtil.cloneDeep(this.sorts);
      this.performSort.emit({ sorts: sortsClone });
    }
  }

  clearAllSortProperties() {
    this.sorts = [];
    this.applySort(true);
  }

  toggleKeepSorted(event) {
    this.keepSorted = !this.keepSorted;
  }

  drop(event: CdkDragDrop<SortDefinition[]>) {
    moveItemInArray(this.sorts, event.previousIndex, event.currentIndex);
    this.applySort(false);
  }

  isApplyActive() {
    if (!this.keepSorted && this.sorts.length > 0 && this.sorts.findIndex((sort) => sort.propertySlug === '') === -1) {
      return true;
    }
    return false;
  }
}
