import { createAction, props } from '@ngrx/store';

export enum CustomFontsActionTypes {
  SET_CUSTOM_FONTS = '[CustomFonts] Set Custom Fonts',
  LOAD_CUSTOM_FONTS = '[CustomFonts] Load Custom Fonts',
}

export const setCustomFonts = createAction(
  CustomFontsActionTypes.SET_CUSTOM_FONTS,
  props<{ customFonts: string; customFontFamilies: Array<String> }>(),
);

export const loadCustomFonts = createAction(CustomFontsActionTypes.LOAD_CUSTOM_FONTS);
