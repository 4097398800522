import { ACCENT_COLOR, BLACK_04 } from '../../../../constants';
import { Table } from '../table/table';
import { TableSelectState } from './table-select-state';

export class TableSelectRenderer {
  constructor(
    private table: Table,
    private state: TableSelectState,
  ) {}

  public render(ctx, { x, y, width, height }) {
    if (!this.state.selectedRanges.length) return;

    for (let i = 0; i < this.state.selectedRanges.length; i++) {
      const range = this.state.selectedRanges[i];
      const { position, size } = this.table.tableRenderer.getRangeDimensions(range);

      this.table.tableRenderer.renderRange(
        ctx,
        { x: x + position.x, y: y + position.y, width: size.width, height: size.height },
        range,
        {
          strokeStyle: ACCENT_COLOR,
          fillStyle: BLACK_04,
        },
      );
    }
  }
}
