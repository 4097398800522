import { Injectable } from '@angular/core';

import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    const confirmMessage = component.showConfirmDialog();
    if (confirmMessage) {
      if (confirm(confirmMessage)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
