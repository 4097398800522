import { Injectable, Pipe, PipeTransform } from '@angular/core';

interface User {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  firstName?: string;
  lastName?: string;

  email?: string;
  orgs?: any;
}

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  transform(user: User, ...args: unknown[]): string {
    if (user?.firstName || user?.lastName) {
      return user?.firstName + ' ' + user?.lastName;
    } else if (user?.email) {
      if (args && args[0] === 'fullEmail') {
        return user?.email;
      } else {
        return user?.email.split('@')[0];
      }
    } else {
      return 'N/A';
    }
  }
}
