import * as DocumentManagerActions from './document-manager.actions';
import * as DocumentManagerSelectors from './document-manager.selectors';
import * as DocumentManagerStoreState from './document-manager.state';
import * as DocumentElementsActions from './document-elements/document-elements.actions';
import * as DocumentElementsSelectors from './document-elements/document-elements.selectors';

export { DocumentManagerStoreModule } from './document-manager.module';

const actions = {
  ...DocumentManagerActions,
  ...DocumentElementsActions,
};
const selectors = {
  ...DocumentManagerSelectors,
  ...DocumentElementsSelectors,
};
export {
  actions as DocumentManagerActions,
  selectors as DocumentManagerSelectors,
  DocumentManagerStoreState as DocumentManagerStoreState,
};
