import { ActionRequest } from '@contrail/actions';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementEvent,
  DocumentElementFactory,
  DocumentNavigationEvent,
  DocumentSVGElementEvent,
  DocumentTextElementEvent,
} from '@contrail/documents';
import { CanvasDocument } from './canvas-document';

export class DocumentActionsDispatcher {
  constructor(private canvasDocument: CanvasDocument) {}

  public addNewElement(type, params: DocumentElement): DocumentElement {
    let element: DocumentElement;
    switch (type) {
      case 'text':
        element = DocumentElementFactory.createTextElement('', params);
        break;
      case 'item-family':
      case 'item-option':
        element = DocumentElementFactory.initNewElement(type);
        element.position = params.position;
        if (params.modelBindings) {
          element.modelBindings = params.modelBindings;
        }
        break;
      default:
        element = DocumentElementFactory.createShapeElement(type, params);
        break;
    }

    const action = new DocumentAction(
      {
        elementId: element.id,
        changeType: DocumentChangeType.ADD_ELEMENT,
        elementData: element,
      },
      {
        elementId: element.id,
        changeType: DocumentChangeType.DELETE_ELEMENT,
        elementData: element,
      },
    );
    this.handleDocumentActions([action]);
    return element;
  }

  public handleDocumentElementEvent(event: DocumentElementEvent) {
    if (event.element?.type === 'image' && event.eventType === 'dblclick') {
      const canvasElement = this.canvasDocument.state.getElementById(event.element.id);
      // if an element in a group is not selected, do not allow cropping
      if (canvasElement.isInGroup && !canvasElement.isSelected) {
        return;
      }
    }
    this.canvasDocument?.documentService?.handleDocumentElementEvent(event);
  }

  public handleUndoableAdds(elements: Array<DocumentElement>) {
    const actions = elements.map((el, i) => {
      const action = new DocumentAction(
        {
          elementId: el.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: el,
        },
        {
          elementId: el.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: el,
        },
      );
      return action;
    });
    return this.handleDocumentActions(actions);
  }

  public handleUndoableChanges(elements: Array<DocumentElement>, undoElements?: Array<DocumentElement>) {
    const actions = elements.map((el, i) => {
      const action = new DocumentAction(
        {
          elementId: el.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: el,
        },
        undoElements
          ? {
              elementId: el.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: undoElements[i],
            }
          : null,
      );
      return action;
    });
    return this.handleDocumentActions(actions);
  }

  public async handleDocumentActions(actions: Array<DocumentAction>) {
    this.canvasDocument?.documentService?.handleDocumentActions(actions);
    return;
  }

  public handleActionRequest(request: ActionRequest) {
    this.canvasDocument?.documentService?.handleActionRequest(request);
  }

  public handleDocumentTextElementEvent(event: DocumentTextElementEvent) {
    this.canvasDocument?.documentService?.handleDocumentTextElementEvent(event);
  }

  public handleDocumentSvgElementEvent(event: DocumentSVGElementEvent) {
    this.canvasDocument?.documentService?.handleDocumentSvgElementEvent(event);
  }

  public handleDocumentNavigationEvent(event: DocumentNavigationEvent) {
    this.canvasDocument?.documentService?.handleDocumentNavigationEvent(event);
  }
}
