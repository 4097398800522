import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { DocumentManagerActions } from '..';
import { withLatestFrom, tap } from 'rxjs/operators';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { DocumentManagerService } from '../../document-manager.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from '../../../document/document.service';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { DocumentEventsService } from '../../../document/document-events.service';
import { DocumentActions } from '../../../document/document-store';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';

@Injectable()
export class DocumentElementEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private documentManagerService: DocumentManagerService,
    private documentService: DocumentService,
    private undoRedoService: UndoRedoService,
    private documentEventsService: DocumentEventsService,
    private snackBar: MatSnackBar,
  ) {}

  private readonly ERROR_MESSAGE =
    'Your request could not be completed. Please try again or refresh your browser to continue.';

  private handleError = (error) => {
    this.snackBar.open(this.ERROR_MESSAGE, '', { duration: 5000 });
  };

  handleDocumentElementActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.HANDLE_DOCUMENT_ELEMENT_ACTIONS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const actions = action.actions;
          if (actions?.length === 0) {
            return;
          }

          const documentActions = ObjectUtil.cloneDeep(actions);
          for (const action of documentActions) {
            if (action.changeDefinition.elementData) {
              delete action.changeDefinition.elementData.createdById;
              delete action.changeDefinition.elementData.createdOn;
              delete action.changeDefinition.elementData.documentId;
              delete action.changeDefinition.elementData.specifiedId;
              delete action.changeDefinition.elementData.orgId;
              delete action.changeDefinition.elementData.updatedById;
              delete action.changeDefinition.elementData.updatedOn;
              delete action.changeDefinition.elementData.annotations;

              if (
                action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT &&
                ((action.changeDefinition?.elementData?.isTextTool &&
                  action.changeDefinition?.elementData?.style?.border?.width != null) ||
                  (action.changeDefinition?.elementData?.type === 'sticky_note' &&
                    action.changeDefinition?.elementData?.style?.border?.width != null))
              ) {
                action.changeDefinition.elementData.style.border.width = 0;
              }
            }
          }
          const filteredActions = documentActions.filter(
            (a) => ![DocumentChangeType.REGENERATE_LINEBOARD, 'MODIFY_ENTITY'].includes(a.changeDefinition.changeType),
          );
          const entitiesChanges = actions.filter((a) => a.changeDefinition.changeType === 'MODIFY_ENTITY');
          if (entitiesChanges.length > 0) {
            this.store.dispatch(DocumentActions.batchApplyDocumentModelEntityChanges({ changes: entitiesChanges }));
          }
          this.documentService.applyDocumentActions(filteredActions);

          if (actions[0].undoChangeDefinition) {
            // only add change with undoChangeDefinition to the undo stack
            this.undoRedoService.addUndo(ObjectUtil.cloneDeep(actions));
          }
          if (actions[actions.length - 1].changeDefinition.changeType !== DocumentChangeType.REGENERATE_LINEBOARD) {
            this.documentService.splitActionsAndSendSessionEvent(
              actions.filter(
                (a) =>
                  ![DocumentChangeType.REGENERATE_LINEBOARD, 'MODIFY_ENTITY'].includes(a.changeDefinition.changeType),
              ),
            );
          }

          const clonedActions = ObjectUtil.cloneDeep(filteredActions);

          if (clonedActions.length === 1) {
            const entity = {
              id: clonedActions[0].changeDefinition.elementId,
              documentElement: clonedActions[0].changeDefinition.elementData,
            };
            const changeType = clonedActions[0].changeDefinition.changeType;
            if ([DocumentChangeType.MODIFY_ELEMENT, DocumentChangeType.REBIND_MODEL].includes(changeType)) {
              this.store.dispatch(DocumentManagerActions.updateDocumentElementEntity(entity));
            } else if (changeType === DocumentChangeType.DELETE_ELEMENT) {
              this.store.dispatch(DocumentManagerActions.deleteDocumentElementEntity({ id: entity.id }));
            } else if (changeType === DocumentChangeType.ADD_ELEMENT) {
              this.store.dispatch(DocumentManagerActions.createDocumentElementEntity(entity));
            } else if (changeType === DocumentChangeType.REORDER_ELEMENT) {
              this.store.dispatch(
                DocumentManagerActions.reorderDocumentElementEntities({ documentElements: entity.documentElement }),
              );
            }
          } else {
            // groups based on changeType
            const actionGroups = clonedActions.reduce((x, y) => {
              (x[y.changeDefinition.changeType] = x[y.changeDefinition.changeType] || []).push(y);
              return x;
            }, {});
            const actionTypes = Object.keys(actionGroups);
            if (
              actionTypes.includes(DocumentChangeType.DELETE_ELEMENT) &&
              actionTypes.includes(DocumentChangeType.ADD_ELEMENT)
            ) {
              this.store.dispatch(
                DocumentManagerActions.createAndDeleteDocumentElementEntities({
                  documentElements: actionGroups[DocumentChangeType.ADD_ELEMENT].map((action) => {
                    return Object.assign(
                      {
                        id: action.changeDefinition.elementId,
                      },
                      action.changeDefinition.elementData,
                    );
                  }),
                  deleteDocumentElements: actionGroups[DocumentChangeType.DELETE_ELEMENT].map((action) => {
                    return Object.assign(
                      {
                        id: action.changeDefinition.elementId,
                      },
                      action.changeDefinition.elementData,
                    );
                  }),
                  actions: clonedActions,
                }),
              );
            } else {
              Object.keys(actionGroups).forEach((changeType) => {
                this.performBatchEntityAction(changeType, actionGroups[changeType]);
              });
            }
          }
        }),
      ),
    { dispatch: false },
  );

  private performBatchEntityAction(changeType: string, actionGroups: DocumentAction[]) {
    const entities = {
      documentElements: actionGroups.map((action) => {
        return Object.assign(
          {
            id: action.changeDefinition.elementId,
          },
          action.changeDefinition.elementData,
        );
      }),
    };
    switch (changeType) {
      case DocumentChangeType.MODIFY_ELEMENT:
      case DocumentChangeType.REBIND_MODEL:
        this.store.dispatch(DocumentManagerActions.updateDocumentElementEntities(entities));
        break;
      case DocumentChangeType.DELETE_ELEMENT:
        this.store.dispatch(DocumentManagerActions.deleteDocumentElementEntities(entities));
        break;
      default:
        this.store.dispatch(DocumentManagerActions.createDocumentElementEntities(entities));
    }
  }

  updateDocumentElementStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_STORE),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const actions = action.actions;
          const clonedActions = ObjectUtil.cloneDeep(actions);
          const changeType = clonedActions[0].changeDefinition.changeType;
          if (changeType === DocumentChangeType.REORDER_ELEMENT) {
            const documentElements = clonedActions[0].changeDefinition.elementData;
            const sortIds = documentElements.map((el, index) => {
              return {
                id: el.id,
                index,
              };
            });
            this.store.dispatch(
              DocumentManagerActions.updateDocumentElementSortOrder({
                sortIds,
              }),
            );
          } else {
            // Group actions by changeType and apply them in batches
            const actionGroups = clonedActions.reduce((x, y) => {
              (x[y.changeDefinition.changeType] = x[y.changeDefinition.changeType] || []).push(y);
              return x;
            }, {});
            Object.keys(actionGroups).forEach((changeType) => {
              const entities = actionGroups[changeType].map((action) => {
                return Object.assign(
                  {
                    id: action.changeDefinition.elementId,
                  },
                  action.changeDefinition.elementData,
                );
              });
              if (changeType === DocumentChangeType.MODIFY_ELEMENT) {
                this.store.dispatch(
                  DocumentManagerActions.batchApplyDocumentElementEntityChanges({
                    documentElements: entities.map((entity) => ({
                      id: entity.id,
                      documentElement: entity,
                    })),
                  }),
                );
              } else if (changeType === DocumentChangeType.DELETE_ELEMENT) {
                const ids = entities.map((entity) => entity.id);
                this.store.dispatch(DocumentManagerActions.removeDocumentElementEntities({ ids }));
                this.store.dispatch(
                  DocumentManagerActions.updateDocumentElementSortOrder({
                    sortIds: ids,
                    targetSortIndex: -1,
                  }),
                );
              } else {
                const documentElements = entities.map((entity) => {
                  return Object.assign(
                    {
                      specifiedId: entity.id,
                      documentId: store.documentManager.documentId,
                    },
                    entity,
                  );
                });
                this.store.dispatch(DocumentManagerActions.addDocumentElementEntities({ documentElements }));

                const targetSortIndex = store.documentManager.documentElementSortOrder.sortOrder.length;
                const sortIds = documentElements.map((el) => el.id);
                this.store.dispatch(
                  DocumentManagerActions.updateDocumentElementSortOrder({
                    sortIds,
                    targetSortIndex,
                  }),
                );
              }
            });
          }
        }),
      ),
    { dispatch: false },
  );

  updateDocumentElementEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_ENTITY),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const documentElement: any = ObjectUtil.cloneDeep(action.documentElement);
          this.store.dispatch(
            DocumentManagerActions.applyDocumentElementEntityChanges({ id: action.id, documentElement }),
          );
          this.documentManagerService.updateDocumentElement(action.id, documentElement).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  updateDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          let documentElements: any = ObjectUtil.cloneDeep(action.documentElements);
          const changes = documentElements.map((entity) => ({
            id: entity.id,
            changes: entity,
          }));
          documentElements = documentElements.map((entity) => ({
            id: entity.id,
            documentElement: entity,
          }));
          this.store.dispatch(DocumentManagerActions.batchApplyDocumentElementEntityChanges({ documentElements }));
          this.documentManagerService.updateDocumentElements(changes).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  deleteDocumentElementEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.DELETE_DOCUMENT_ELEMENT_ENTITY),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(DocumentManagerActions.removeDocumentElementEntity({ id: action.id }));
          this.store.dispatch(
            DocumentManagerActions.reorderDocumentElementEntities({
              documentElements: [
                {
                  id: action.id,
                },
              ],
              targetSortIndex: -1,
            }),
          );
          this.documentManagerService.deleteDocumentElement(action.id).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  deleteDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.DELETE_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const ids = action.documentElements.map((entity) => entity.id);
          this.store.dispatch(DocumentManagerActions.removeDocumentElementEntities({ ids }));
          this.store.dispatch(
            DocumentManagerActions.reorderDocumentElementEntities({
              documentElements: action.documentElements,
              targetSortIndex: -1,
            }),
          );
          this.documentManagerService.deleteDocumentElements(ids).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  createDocumentElementEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.CREATE_DOCUMENT_ELEMENT_ENTITY),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const documentElement = Object.assign(
            {
              specifiedId: action.documentElement.id,
              documentId: store.documentManager.documentId,
            },
            action.documentElement,
          );
          this.store.dispatch(DocumentManagerActions.addDocumentElementEntity({ documentElement }));
          this.store.dispatch(
            DocumentManagerActions.reorderDocumentElementEntities({
              documentElements: [documentElement],
              targetSortIndex: store.documentManager.documentElementSortOrder.sortOrder.length,
            }),
          );
          this.documentManagerService.createDocumentElement(documentElement).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  createDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.CREATE_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const documentElements = action.documentElements.map((entity) => {
            return Object.assign(
              {
                specifiedId: entity.id,
                documentId: store.documentManager.documentId,
              },
              entity,
            );
          });
          this.store.dispatch(DocumentManagerActions.addDocumentElementEntities({ documentElements }));
          this.store.dispatch(
            DocumentManagerActions.reorderDocumentElementEntities({
              documentElements,
              targetSortIndex: store.documentManager.documentElementSortOrder.sortOrder.length,
            }),
          );
          this.documentManagerService.createDocumentElements(documentElements).catch((error) => {
            this.handleError(error);
          });
        }),
      ),
    { dispatch: false },
  );

  createAndDeleteDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.CREATE_AND_DELETE_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const documentElements = action.documentElements.map((entity) => {
            return Object.assign(
              {
                specifiedId: entity.id,
                documentId: store.documentManager.documentId,
              },
              entity,
            );
          });
          this.store.dispatch(
            setLoading({
              loading: true,
              message: 'Updates are in progress. Please do not refresh the page or press the back button.',
            }),
          );

          this.store.dispatch(DocumentManagerActions.addDocumentElementEntities({ documentElements }));
          const ids = action.deleteDocumentElements.map((entity) => entity.id);
          this.store.dispatch(DocumentManagerActions.removeDocumentElementEntities({ ids }));

          let sorts = [
            {
              sortIds: documentElements.map((d) => d.id),
              targetSortIndex: store.documentManager.documentElementSortOrder.sortOrder.length,
            },
          ];
          sorts = sorts.concat([{ sortIds: ids, targetSortIndex: -1 }]);

          this.store.dispatch(DocumentManagerActions.batchReorderDocumentElementEntities({ sorts }));
          const promises = [];
          promises.push(
            this.documentManagerService.syncDeleteDocumentElements(ids).catch((error) => {
              this.handleError(error);
            }),
          );
          promises.push(
            this.documentManagerService.syncCreateDocumentElements(documentElements).catch((error) => {
              this.handleError(error);
            }),
          );
          await Promise.all(promises);
          this.documentService.splitActionsAndSendSessionEvent(action.actions);
          this.store.dispatch(setLoading({ loading: false }));
        }),
      ),
    { dispatch: false },
  );

  updateDocumentElementSortOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_SORT_ORDER),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          // New sort order for all document elements
          let newSortOrder: string[];
          if (action?.targetSortIndex === -1) {
            newSortOrder = ObjectUtil.cloneDeep(store.documentManager.documentElementSortOrder.sortOrder);
            for (const id of action.sortIds) {
              if (newSortOrder.indexOf(id) !== -1) {
                newSortOrder.splice(newSortOrder.indexOf(id), 1);
              }
            }
          } else if (action?.targetSortIndex >= 0) {
            newSortOrder = ObjectUtil.cloneDeep(store.documentManager.documentElementSortOrder.sortOrder).concat(
              action.sortIds,
            );
          } else {
            newSortOrder = action.sortIds.map((sortOrder) => sortOrder.id);
          }
          this.store.dispatch(
            DocumentManagerActions.setDocumentElementSortOrder({
              documentElementSortOrder: {
                id: store.documentManager.documentElementSortOrder.id,
                sortOrder: newSortOrder,
              },
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  reorderDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.REORDER_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          // Ids of elements for which the order will be updated
          const sortIds = action.documentElements.map((el, index) => {
            if (action.targetSortIndex == null) {
              return {
                id: el.id,
                index,
              };
            } else {
              return el.id;
            }
          });

          this.store.dispatch(
            DocumentManagerActions.updateDocumentElementSortOrder({
              sortIds,
              targetSortIndex: action.targetSortIndex,
            }),
          );

          this.documentManagerService
            .updateDocumentElementSortOrder(
              store.documentManager.documentElementSortOrder.id,
              sortIds,
              action.targetSortIndex,
            )
            .catch((error) => {
              this.handleError(error);
            });
        }),
      ),
    { dispatch: false },
  );

  batchReorderDocumentElementEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentManagerActions.DocumentElementsActionTypes.BATCH_REORDER_DOCUMENT_ELEMENT_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          action.sorts.forEach((sort) => {
            this.store.dispatch(
              DocumentManagerActions.updateDocumentElementSortOrder({
                sortIds: sort.sortIds,
                targetSortIndex: sort.targetSortIndex,
              }),
            );
          });

          this.documentManagerService
            .batchUpdateDocumentElementSortOrder(store.documentManager.documentElementSortOrder.id, action.sorts)
            .catch((error) => {
              this.handleError(error);
            });
        }),
      ),
    { dispatch: false },
  );
}
