export const COLORS_PICKLIST = [
  'rgba(0,0,0,0)',
  '#00B0FF',
  '#00C853',
  '#FFC000',
  '#BB6BD9',
  '#FF0000',
  '#FFA500',
  '#FFFF00',
  '#009900',
  '#0000FF',
  '#FFFFFF',
  '#BDBDBD',
  '#616161',
  '#424242',
  '#000000',
];
