import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
import { TypePropertyUserListService } from '../../../../user-list/user-list.service';

@Component({
  selector: 'app-type-property-form-field-user-list',
  template: `
    <mat-form-field [appearance]="appearance" *ngIf="formControl">
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option></mat-option>
        <ng-container *ngFor="let option of options">
          <mat-option [attr.data-test]="'property-form-option-' + option.value" [value]="option">
            {{ option.display }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        min-width: 80px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldUserListComponent extends TypePropertyFormFieldBaseComponent {
  options: TypePropertyOption[];

  constructor(private userListService: TypePropertyUserListService) {
    super();
  }

  async initFormControl() {
    if (!this.propertyFormConfiguration?.typeProperty) {
      return;
    }
    this.options = await this.getOptions();
    const option = this.options.find((opt) => opt.value === this.value?.id);
    const disabled = await this.isDisabled();
    const isValueVisible = await this.isValueVisible();
    this.formControl = new UntypedFormControl({ value: isValueVisible ? option : null, disabled });
    this.formControl.valueChanges.subscribe(async (opt) => {
      if (!opt) {
        this.value = null;
      } else {
        this.value = { id: opt.value, email: opt.display };
        this.value[this.propertyFormConfiguration.typeProperty.slug + 'Id'] = this.value.id;
      }

      this.handleChange();
    });
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    let eligibleOptions = await this.userListService.getTypePropertyUserList(
      this.propertyFormConfiguration.typeProperty.typePropertyUserListId,
    );
    return ObjectUtil.cloneDeep(eligibleOptions);
  }
}
