import { PositionDefinition, DocumentElement, DocumentElementFactory } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { TableService } from '../canvas/elements/table/table-manager/table.service';

export class FrameTemplateService {
  constructor() {}

  public static generateTemplateElements(
    elements: Array<any>,
    position: PositionDefinition,
    options: DocumentElement = {},
  ): Array<DocumentElement> {
    const createdElements: Array<DocumentElement> = [];
    const elementMapping = {};
    for (let element of elements) {
      if (['row', 'column', 'cell'].indexOf(element.type) !== -1) continue;

      const origElementId = ObjectUtil.cloneDeep(element.id);
      delete element.id;
      delete element.specifiedId;
      delete element.updatedOn;
      delete element.updatedById;
      delete element.createdOn;
      delete element.createdById;

      let documentElement: DocumentElement;
      if (element.type === 'table') {
        const { newTableElement, newChildElements } = TableService.copy(
          element,
          elements.filter((e) => e.tableId === origElementId && ['cell', 'column', 'row'].indexOf(e.type) !== -1),
        );
        documentElement = newTableElement;
        createdElements.push(...newChildElements);
      } else {
        documentElement = DocumentElementFactory.createElement(element.type, element);
      }

      documentElement = ObjectUtil.mergeDeep(documentElement, options);
      elementMapping[origElementId] = documentElement.id;
      if (documentElement.type === 'line') {
        documentElement.lineDefinition.x1 += position.x;
        documentElement.lineDefinition.x2 += position.x;
        documentElement.lineDefinition.y1 += position.y;
        documentElement.lineDefinition.y2 += position.y;
      } else {
        documentElement.position.x += position.x;
        documentElement.position.y += position.y;
      }
      if (documentElement.elementIds) {
        documentElement.elementIds = [elementMapping[documentElement.elementIds[0]]];
      }
      createdElements.push(documentElement);
    }
    return createdElements;
  }
}
