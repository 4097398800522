import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DocumentService } from '../../document.service';

@Component({
  selector: 'app-upload-image-file-modal',
  templateUrl: './upload-image-file-modal.component.html',
  styleUrls: ['./upload-image-file-modal.component.scss'],
})
export class UploadImageFileModalComponent {
  message = `Uploading your file. Please wait.`;
  uploadInProgress = false;

  constructor(
    public dialogRef: MatDialogRef<UploadImageFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public documentService: DocumentService,
  ) {}

  validFileSelected = false;
  uploadError = false;
  files: File[] = [];

  onSelect(event) {
    if (event.rejectedFiles.length > 0) {
      this.uploadError = true;
      this.validFileSelected = false;
    } else {
      this.uploadError = false;
      this.validFileSelected = true;
      this.files.push(...event.addedFiles);
      this.closeModal({
        files: this.files,
      });
    }
  }

  closeModal(obj = {}) {
    this.dialogRef.close(obj);
  }

  handleClose() {
    this.closeModal();
  }
}
