import { documentHistoryInitialState, State } from './document-history.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as DocumentHistoryActions from './document-history.actions';

const setCurrentEntitySnapshot = (state: State, { entitySnapshot }) => {
  return {
    ...state,
    currentEntitySnapshot: entitySnapshot,
  };
};

const clearCurrentEntitySnapshot = (state: State) => {
  return {
    ...state,
    currentEntitySnapshot: null,
  };
};

export const documentHistoryReducers = createReducer(
  documentHistoryInitialState,
  on(DocumentHistoryActions.clearCurrentEntitySnapshot, clearCurrentEntitySnapshot),
  on(DocumentHistoryActions.setCurrentEntitySnapshot, setCurrentEntitySnapshot),
  on(DocumentHistoryActions.loadCurrentShowcaseSuccess, setCurrentEntitySnapshot),
);

export function reducer(state: State | undefined, action: Action): any {
  return documentHistoryReducers(state, action);
}
