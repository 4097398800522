import { Injectable } from '@angular/core';
import { Group } from '@contrail/entity-types';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor() {}

  public async getGroups(): Promise<Group[]> {
    return new Entities().get({ entityName: 'group', relations: ['groupPrincipals'] });
  }
}
