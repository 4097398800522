import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { PropertyConfiguratorService } from '../property-configurator.service';
import { COLORS_PICKLIST } from './property-configurator-preset-colors';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-property-configurator-color-picker',
  templateUrl: './property-configurator-color-picker.component.html',
  styleUrls: ['./property-configurator-color-picker.component.scss'],
})
export class PropertyConfiguratorColorPickerComponent implements OnChanges, OnInit, OnDestroy {
  private eventSub: Subscription;
  @Input() matIconType: string;
  @Input() svgIconType: string;
  @Input() colorType: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() resetPos: any = false;
  @Input() draggable = false;
  @Input() closeOnSelect = false;
  @Input() colorChip = false;
  presetColors = COLORS_PICKLIST;
  defaultColor = 'rgba(0,0,0,0)';
  color = this.defaultColor;
  currentColor: string;

  isOpened = false;
  fg: UntypedFormGroup;
  @ViewChild(CdkOverlayOrigin) cdkOverlayOrigin: CdkOverlayOrigin;
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  public overlayPositions: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];
  private overlayHeight = 457;
  private overlayWidth = 230;

  constructor(
    private fb: UntypedFormBuilder,
    private service: PropertyConfiguratorService,
  ) {
    this.fg = this.fb.group({
      color: [this.color, Validators.required],
    });
  }
  ngOnInit(): void {
    this.eventSub = this.service.documentElementEvents.subscribe((event) => {
      if (event?.eventType === 'deselect') {
        // Close color picker dialog when deselecting an element
        this.isOpened = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.eventSub.unsubscribe();
  }
  public toggleDisplay() {
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      this.setOverlayPositions();
      const destroy$ = new Subject();
      this.cdkConnectedOverlay?.overlayOutsideClick.pipe(takeUntil(destroy$)).subscribe((evt: any) => {
        for (const el of evt?.composedPath()) {
          if (el?.tagName === 'COLOR-PICKER') {
            return;
          }
          if (el?.tagName === 'APP-PROPERTY-CONFIGURATOR-BAR' || el?.tagName === 'APP-COMPONENT-EDITOR') {
            break;
          }
        }
        destroy$.next(null);
        destroy$.complete;
        this.isOpened = false;
      });
    }
  }
  private setOverlayPositions() {
    let overlayPosition: ConnectionPositionPair = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    }; // position below and to the left of origin
    if (this.cdkOverlayOrigin?.elementRef?.nativeElement) {
      const originPosition = this.cdkOverlayOrigin?.elementRef?.nativeElement?.getBoundingClientRect();
      const offsetPlusHeight = originPosition.y + originPosition.height + this.overlayHeight;
      const heightOverflow = window.innerHeight - offsetPlusHeight;
      const offsetPlusWidth = originPosition.x + originPosition.width + this.overlayWidth;
      const widthOverflow = window.innerWidth - offsetPlusWidth;
      if (heightOverflow < 0) {
        // position above origin
        overlayPosition.originY = 'top';
        overlayPosition.overlayY = 'bottom';
        const topOverflow = originPosition.y - this.overlayHeight;
        overlayPosition.offsetY = topOverflow < 0 ? -topOverflow : 0;
      }
      if (widthOverflow < 0) {
        // position to the right of origin
        overlayPosition.originX = 'end';
        overlayPosition.overlayX = 'end';
      }
    }
    this.overlayPositions = [overlayPosition];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.color = this.currentValue || this.defaultColor;
    this.currentColor = this.color;
    if (changes.resetPos && !changes.resetPos.firstChange) {
      this.isOpened = false;
    }
  }
  handleChange(color: string) {
    this.color = color;
    setTimeout(() => {
      if (this.color.startsWith('rgba') && this.currentColor === this.defaultColor) {
        // if the current color is the default color 'rgba(0,0,0,0)', set the opacity to 1 when a different color is picked.
        this.color = this.color.replace(/[^,]+(?=\))/, '1');
      }
      this.setValue();
      this.currentColor = this.color;
    });
  }

  async setValue() {
    let changes;
    if (this.colorType === 'fontColor') {
      changes = { type: 'textColor', value: this.color };
    } else if (this.colorType === 'textBackgroundColor') {
      changes = { type: 'textBackgroundColor', value: this.color };
    } else if (this.colorType === 'backgroundColor') {
      changes = { style: { backgroundColor: this.color } };
    } else if (this.colorType === 'borderColor') {
      changes = { style: { border: { color: this.color } } };
    }
    this.valueChange.emit(changes);
    if (this.closeOnSelect) {
      this.isOpened = false;
    }
  }

  @HostListener('document:keyup.esc', ['$event']) handleEsc(event: any): void {
    if (this.isOpened) {
      this.toggleDisplay();
    }
  }
}
