export interface ShareLink {
  orgSlug: string;
  accessLevel: ACCESS_LEVEL;
  contextType: string;
  contextId: string;
  contextReference: string;
  updatedOn: string;
  id: string;
  updatedById: string;
  createdOn: string;
  createdById: string;
  orgId: string;
  shareUrl?: string;
}

export enum ACCESS_LEVEL {
  RESTRICTED = 'RESTRICTED',
  VIEW = 'VIEW',
  COMMENT = 'COMMENT',
  EDIT = 'EDIT',
}

export interface SharedLinkDefinition {
  contextId: string;
  contextType: string;
  baseUrl: string; // pass the env stuff here
}

export interface WorkspacePrincipal {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  entityType?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  typePath?: string;
  orgId?: string;

  workspaceId?: string;
  workspaceType?: WorkspaceType;

  principalReference?: string;
  principal?: Principal;

  role?: PrincipalRole;
}

export interface Collaborator extends WorkspacePrincipal {
  orgSlug: string;
  accessLevel: ACCESS_LEVEL;
  shareLinkId: string;
}

export class Principal {
  id?: string;
  createdOn?: Date;
  createdById?: string;
  updatedOn?: Date;
  updatedById?: string;
  email?: string;
  isSsoUser?: boolean;
  backgroundColor?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  displayName?: string;
}

export enum PrincipalRole {
  admin = 'ADMIN',
  member = 'MEMBER',
  viewer = 'VIEWER',
}

export enum WorkspaceType {
  project = 'PROJECT',
  folder = 'FOLDER',
  library = 'LIBRARY',
}

export interface SharedLinkPrincipal {
  sharedLinkId: string;
  accessLevel: ACCESS_LEVEL;
  principalReference?: string;
  userEmail?: string;
}
