import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ClipboardItemsService } from '../../clipboard-items.service';
import { ClipboardItemsActionTypes } from './clipboard-items.actions';
import { ClipboardActions } from '..';

@Injectable()
export class ClipboardItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private clipboardItemsService: ClipboardItemsService,
  ) {}

  loadClipboardItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClipboardItemsActionTypes.LOAD_CLIPBOARD_ITEMS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.clipboardItemsService.getClipboardItems()).pipe(
          map((clipboardItems) => ClipboardActions.loadClipboardItemsSuccess({ clipboardItems: clipboardItems ?? [] })),
          catchError((error) => observableOf(ClipboardActions.loadClipboardItemsFailure({ error }))),
        );
      }),
    ),
  );

  addItemsToClipboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClipboardItemsActionTypes.ADD_ITEMS_TO_CLIPBOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.clipboardItemsService.addItemsToClipboard(action.clipboardItems)).pipe(
          map((clipboardItems) => {
            if (clipboardItems) {
              const addedClipboardItemIds = clipboardItems.map((clipboardItem) => clipboardItem.id);
              const existingClipboardItemIdsToReplace = Object.values(store.clipboard.clipboardItems.entities)
                .filter((clipboardItem) => addedClipboardItemIds.includes(clipboardItem.id))
                .map((clipboardItem) => clipboardItem.id);

              if (existingClipboardItemIdsToReplace?.length) {
                this.store.dispatch(
                  ClipboardActions.removeItemsFromClipboardSuccess({ ids: existingClipboardItemIdsToReplace }),
                );
              }

              return ClipboardActions.addItemsToClipboardSuccess({ clipboardItems: clipboardItems });
            } else {
              return ClipboardActions.addItemsToClipboardFailure({ error: new Error('No clipboard items added.') });
            }
          }),
          catchError((error) => observableOf(ClipboardActions.addItemsToClipboardFailure({ error }))),
        );
      }),
    ),
  );

  removeItemsFromClipboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClipboardItemsActionTypes.REMOVE_ITEMS_FROM_CLIPBOARD),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const idsToRemove = action.ids;
          const clipboardItemsToRemove = Object.values(store.clipboard.clipboardItems.entities).filter(
            (clipboardItem) => idsToRemove.includes(clipboardItem.id),
          );

          this.store.dispatch(ClipboardActions.removeItemsFromClipboardSuccess({ ids: idsToRemove }));

          try {
            await this.clipboardItemsService.removeItemsFromClipboard(idsToRemove);
          } catch (error) {
            this.store.dispatch(
              ClipboardActions.addItemsToClipboardSuccess({ clipboardItems: clipboardItemsToRemove }),
            );
            this.store.dispatch(ClipboardActions.removeItemsFromClipboardFailure({ error }));
          }
        }),
      ),
    { dispatch: false },
  );
}
