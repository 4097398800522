import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
  Inject,
  ElementRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { debounceTime, filter, fromEvent, Subscription, tap } from 'rxjs';

import { RootStoreState } from 'src/app/root-store';
import { DocumentActions } from '../document-store';
import { SideMenuOverlay } from '../document-store/document.state';
import { DocumentService } from '../document.service';
import { UploadImageFileModalComponent } from './upload-image-file-modal/upload-image-file-modal.component';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';
import { ActionRequest } from '@contrail/actions';
import { ObjectUtil } from '@contrail/util';
import { BoardService } from '../../board.service';

export interface MenuItem {
  id: string;
  icon?: string;
  svgIcon?: string;
  class?: string;
  label: string;
}

@Component({
  selector: 'app-document-widget-tray',
  templateUrl: './document-widget-tray.component.html',
  styleUrls: ['./document-widget-tray.component.scss'],
})
export class DocumentWidgetTrayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() addImageElementFromFile = new EventEmitter();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('penMenuTrigger') penMenuTrigger: MatMenuTrigger;

  private subscriptions: Subscription = new Subscription();
  private documentClickSubscription: Subscription;
  private documentElementEventSubscription: Subscription;

  public interactionMode: string;

  shapeMenus: Array<MenuItem> = [
    { id: 'rectangle', icon: 'square', label: 'Rectangle', class: 'material-icons-outlined' },
    {
      id: 'round_rectangle',
      icon: 'check_box_outline_blank',
      label: 'Round Rectangle',
      class: 'material-icons-outlined',
    },
    { id: 'circle', icon: 'circle', label: 'Circle', class: 'material-icons-outlined' },
    { id: 'triangle', icon: 'change_history', label: 'Triangle', class: 'material-icons-outlined' },
    { id: 'diamond', icon: 'square', class: 'rotate-45 scale-90 material-icons-outlined', label: 'Diamond' },
    { id: 'rhombus', svgIcon: 'app-rhombus-shape', label: 'Rhombus', class: 'material-icons-outlined' },
    { id: 'double_arrow', svgIcon: 'app-double-arrow-shape', label: 'Double Arrow', class: 'material-icons-outlined' },
    { id: 'right_arrow', svgIcon: 'app-right-arrow-shape', label: 'Right Arrow', class: 'material-icons-outlined' },
    { id: 'star', icon: 'grade', label: 'Star', class: 'material-icons-outlined' },
    { id: 'heart', icon: 'favorite_border', label: 'Heart', class: 'material-icons-outlined' },
    { id: 'cloud', icon: 'cloud', label: 'Cloud', class: 'material-icons-outlined' },
  ];

  lineMenus: Array<MenuItem> = [
    { id: 'line', icon: 'horizontal_rule', label: 'Line' },
    { id: 'arrow', icon: 'trending_flat', label: 'Arrow' },
  ];

  textMenus: Array<MenuItem> = [
    { id: 'create_text_element', svgIcon: 'app-text-tool-create', label: 'Text tool' },
    { id: 'text', svgIcon: 'app-text-box-create', label: 'Text box' },
  ];

  penMenus: Array<MenuItem> = [
    { id: 'pen', icon: 'stylus', label: 'Pen', class: 'material-symbols-outlined' },
    { id: 'highlighter', icon: 'ink_highlighter', label: 'Highlighter', class: 'material-symbols-outlined' },
    { id: 'eraser', icon: 'ink_eraser', label: 'Eraser', class: 'material-symbols-outlined' },
  ];

  itemMenus: Array<MenuItem> = [
    { id: 'new_item_family', svgIcon: 'new-item-family', label: 'New item [I]' },
    { id: 'new_item_option', svgIcon: 'new-item-option', label: 'New option [O]' },
    { id: 'item_copy', svgIcon: 'item-copy', label: 'Quick item copy' },
    { id: 'item_inspector', svgIcon: 'item-inspector', label: 'Item inspector' },
  ];

  public elements;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  itemCreationFeatureActive = false;
  itemAssignmentFeatureActive = false;

  constructor(
    public documentService: DocumentService,
    private featureFlagService: FeatureFlagRegistryService,
    private store: Store<RootStoreState.State>,
    private dialog: MatDialog,
    private boardService: BoardService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (event?.eventType === 'interactionMode' && event?.data?.interactionMode) {
          this.interactionMode = event?.data?.interactionMode;
        }
      }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(FeatureFlagsSelectors.featureFlags)
        .pipe(
          tap((flags: FeatureFlag[]) => {
            if (
              flags.map((x) => x.featureName).includes(Feature.ITEM_CREATION) &&
              !this.boardService.isSharedLinkUser
            ) {
              this.itemCreationFeatureActive = true;
            }
            if (flags.map((x) => x.featureName).includes(Feature.ASSIGN_ITEM_TO_COMPONENT)) {
              this.itemAssignmentFeatureActive = true;
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.documentClickSubscription?.unsubscribe();
    this.documentElementEventSubscription?.unsubscribe();
  }

  ngAfterViewInit() {}

  setInteractionMode(mode) {
    this.documentService.setInteractionMode(mode);
  }

  getActiveIcon(menus) {
    return menus
      .filter((menu) => {
        return menu.id === this.documentService.getInteractionMode();
      })
      .map((menu) => {
        return menu.icon;
      })[0];
  }

  getActiveColor(mode) {
    return mode === this.documentService.getInteractionMode() ||
      (mode === 'shape' && this.getActiveIcon(this.shapeMenus))
      ? 'accent'
      : '';
  }

  async onContextMenu(event: MouseEvent) {
    const menu = document.getElementById('mainWidgetTrayToolMenu');
    menu.style.display = '';
    menu.style.position = 'fixed';
    menu.style.left = '80px';
    menu.style.top = event.pageY - 55 + 'px';

    this.menuTrigger.openMenu();
  }

  closeToolMenu() {
    this.menuTrigger.closeMenu();
  }
  onToolMenuClosed(event): void {
    const menu = document.getElementById('mainWidgetTrayToolMenu');
    if (menu) {
      menu.style.display = 'none';
    }
  }

  showComments() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Comments';
    overlay.slug = 'showComments';
    overlay.showChooser = true;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showItemChooser() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addItem';
    overlay.showChooser = true;
    overlay.targetSourceType = null;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadImageFileModalComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.files) {
        this.addImageElementFromFile.emit(result.files);
      }
    });
  }

  resetInteractionMode() {
    this.documentService.setInteractionMode('root');
    this.documentService.handleActionRequest(new ActionRequest('root'));
  }

  isActive() {
    return this.documentService.getInteractionMode() === 'select';
  }

  isAssignItemActive() {
    return this.documentService.getInteractionMode() === 'assign_item';
  }

  getCurrentValue(path) {
    const lastAppliedStyle = this.documentService.getLastAppliedStyle(this.documentService.getInteractionMode());
    const value = ObjectUtil.getByPath(lastAppliedStyle || {}, path);
    return value;
  }

  updateValues(value) {
    if (value?.style) {
      this.documentService.setLastAppliedStyle(this.documentService.getInteractionMode(), value.style);
    }
  }

  subscribeToDocumentClickToCloseMenu($event: MouseEvent, interactionModes, matMenuTrigger) {
    const target = $event.target as HTMLElement;
    if (this.documentClickSubscription && !this.documentClickSubscription.closed) {
      this.documentClickSubscription.unsubscribe();
    }
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          return (event.target as HTMLElement)?.tagName !== 'CANVAS' && !target?.contains(event.target as HTMLElement);
        }),
      )
      .subscribe(() => {
        this.closeMenu(matMenuTrigger);
      });

    if (!this.documentElementEventSubscription || this.documentElementEventSubscription.closed) {
      this.documentElementEventSubscription = this.documentService.documentElementEvents.subscribe((event) => {
        if (event?.eventType === 'interactionMode' && interactionModes.indexOf(event?.data?.interactionMode) === -1) {
          this.closeMenu(matMenuTrigger);
        }
      });
    }
  }

  private closeMenu(matMenuTrigger) {
    this.documentClickSubscription.unsubscribe();
    this.documentElementEventSubscription.unsubscribe();
    matMenuTrigger.closeMenu();
    this.resetInteractionMode();
  }

  public eventPrevent(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}
