import { DocumentDynamicTextUtil } from './document-dynamic-text-util';
import { CanvasUtil } from '../../../canvas/canvas-util';
import { DocumentElement } from '@contrail/documents';
import { DocumentDynamicTextService } from './document-dynamic-text.service';
import { DocumentItemService } from '../../document-item/document-item.service';
import { CoordinateBox } from '../../../canvas/coordinate-box';

export class DocumentDynamicTextClipboardHandler {
  constructor(private dynamicTextService: DocumentDynamicTextService) {}

  public async handleCopiedDynamicTexts(
    elements: DocumentElement[],
    sourceCopiedMap: Map<string, string>,
    newElements: any[],
  ) {
    const copiedFromFrameIds = elements.filter((e) => e.type === 'frame').map((e) => e.id);
    const copiedToFrameIds = [];
    const frameMap = this.dynamicTextService.documentService.documentRenderer.getFrames();
    const ignoreGenerateDynamicTexts = [];
    copiedFromFrameIds.forEach((id) => {
      const copiedFromFrame = frameMap.get(id);
      if (copiedFromFrame) {
        // copied from the same document
        const newFrameId = sourceCopiedMap.get(id);
        const newFrame = newElements.find((e) => e.id === newFrameId);
        copiedFromFrame.elements.forEach((element) => {
          const newElementId = sourceCopiedMap.get(element.canvasElement.id);
          const newElement = newElements.find((e) => e.id === newElementId);
          if (DocumentDynamicTextUtil.isDynamicTextElement(newElement)) {
            if (newElement.propertyBindings.text === 'frame.name') {
              this.dynamicTextService.adjustSizeAndStyling(newElement, newFrame.name);
            }
            ignoreGenerateDynamicTexts.push(newElement.id);
          }
        });
      } else {
        // copied from another document
        copiedToFrameIds.push(id);
      }
    });
    // ignoreGenerateDynamicTexts is used to prevent generating dynamic texts for the elements that are
    // copied as part of a frame in the same document
    const dynamicTextElements = newElements.filter(
      (e) => DocumentDynamicTextUtil.isDynamicTextElement(e) && !ignoreGenerateDynamicTexts.includes(e.id),
    );
    const newFrameElements = newElements
      .filter((e) => e.type === 'frame' && !copiedToFrameIds.includes(e.id))
      .map((e) => {
        return {
          box: this.getBox(e),
          elements: [],
          element: { elementDefinition: e },
        };
      });
    const componentElements = newElements.filter((e) => DocumentItemService.isItemComponet(e));
    for (let e of dynamicTextElements) {
      let onFrame = null;
      // get it from the new frames if it is in one of them
      for (const frame of newFrameElements) {
        if (CanvasUtil.isPositionInBox(e.position, frame.box)) {
          onFrame = frame;
          break;
        }
      }
      // get it from existing frames
      if (!onFrame) {
        for (const frame of frameMap.values()) {
          if (CanvasUtil.isPositionInBox(e.position, frame.box)) {
            onFrame = frame;
            break;
          }
        }
      }
      const affectedComponentElements = [];
      if (onFrame) {
        for (let componentElement of componentElements) {
          if (CanvasUtil.isPositionInBox(componentElement.position, onFrame.box)) {
            affectedComponentElements.push(componentElement);
          }
        }
      }
      const text = await this.dynamicTextService.getTextValue(e, onFrame, affectedComponentElements);
      this.dynamicTextService.adjustSizeAndStyling(e, text);
    }
  }

  private getBox(documentElement): CoordinateBox {
    const box: CoordinateBox = {
      x: documentElement.position.x,
      y: documentElement.position.y,
      width: documentElement.size.width,
      height: documentElement.size.height,
      left: documentElement.position.x,
      right: documentElement.position.x + documentElement.size.width,
      top: documentElement.position.y,
      bottom: documentElement.position.y + documentElement.size.height,
    };
    return box;
  }
}
