<div
  class="flex flex-col p-2 m-2 border rounded-md min-h-[180px] w-[160px]"
  [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : 'white' }"
>
  <ng-container *ngFor="let property of properties">
    <div class="flex justify-center m-1">
      <mat-icon
        *ngIf="property.slug === 'thumbnail' && !property.isHidden"
        class="!h-[90px] !w-[90px] !text-[90px] text-gray-300"
        svgIcon="tag"
      ></mat-icon>
    </div>
    <div [style]="getStyle(property)" *ngIf="property.slug !== 'thumbnail'">
      {{ property.propertyDefinition?.label }}
    </div>
  </ng-container>
</div>
