import { createAction, props } from '@ngrx/store';

export enum SearchReplaceActionTypes {
  SET_SEARCH_ACTIVE = '[Search Replace] Set Search Active',
  SET_SEARCH_RESULTS = '[Search Replace] Set Search Results',
  SET_ACTIVE_SEARCH_RESULT_ELEMENT = '[Search Replace] Set Active Search Result Element',
  TOGGLE_SEARCH = '[Search Replace] Toggle Search',
}

////////////////////////////////////////////////////////////////////////////////

export const setSearchActive = createAction(
  SearchReplaceActionTypes.SET_SEARCH_ACTIVE,
  props<{ searchActive: boolean }>(),
);

export const setSearchResults = createAction(
  SearchReplaceActionTypes.SET_SEARCH_RESULTS,
  props<{ searchResults: Array<any> }>(),
);

export const setActiveSearchResultElement = createAction(
  SearchReplaceActionTypes.SET_ACTIVE_SEARCH_RESULT_ELEMENT,
  props<{ activeSearchResultElement: any }>(),
);

export const toggleSearch = createAction(SearchReplaceActionTypes.TOGGLE_SEARCH);
