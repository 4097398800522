<div #widgetTray class="widget-tray z-9999" (click)="handleClick($event)">
  <div class="widgets-list pr-[7px]" *ngIf="sourceEvent">
    <app-property-configurator-font-family-selector
      matTooltip="Font"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.font.family')"
    >
    </app-property-configurator-font-family-selector>
    <app-property-configurator-font-size
      matTooltip="Font size"
      (valueChange)="handleDelayedValueChange($event)"
      [sizes]="sizes"
      [minValue]="4"
      [maxValue]="72"
      [currentValue]="getCurrentValue('style.font.size')"
    >
    </app-property-configurator-font-size>
    <app-property-configurator-color-picker
      matTooltip="Text color"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.color')"
      [matIconType]="'format_color_text'"
      [colorType]="'fontColor'"
    >
    </app-property-configurator-color-picker>
    <app-property-configurator-text-decorator
      data-test="text-bold"
      matTooltip="Bold"
      decoratorType="bold"
      [matIconType]="'format_bold'"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.font.weight')"
    >
    </app-property-configurator-text-decorator>
    <app-property-configurator-text-decorator
      data-test="text-italic"
      matTooltip="Italic"
      decoratorType="italic"
      [matIconType]="'format_italic'"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.font.style')"
    >
    </app-property-configurator-text-decorator>
    <app-property-configurator-text-decorator
      data-test="text-underline"
      matTooltip="Underlined"
      decoratorType="underline"
      [matIconType]="'format_underline'"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.text.decoration')"
    >
    </app-property-configurator-text-decorator>
    <app-property-configurator-color-picker
      data-test="highlight-color"
      matTooltip="Highlight text"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.backgroundColor')"
      [svgIconType]="'highlight'"
      [colorType]="'textBackgroundColor'"
    >
    </app-property-configurator-color-picker>
    <app-property-configurator-text-alignment
      data-test="text-align"
      matTooltip="Text align"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="getCurrentValue('style.text.align')"
    >
    </app-property-configurator-text-alignment>
    <button (click)="clearFormat($event)" matTooltip="Clear format" mat-icon-button>
      <mat-icon class="toggle-icon">format_clear</mat-icon>
    </button>
  </div>
</div>
