import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { DEFAULT_HIGHLIGHTER_OPACITY, DEFAULT_PEN_BORDER_COLOR, DEFAULT_PEN_BORDER_SIZE } from '../../constants';
import { PenDrawHandler } from '../../interactions/canvas-event-handlers/drag-event-handlers/pen-draw-handler';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { RotationHelper } from '../../renderers/rotation-widget-renderer/rotation-helper';
import { CanvasElement } from '../canvas-element';

export class CanvasPenElement extends CanvasElement {
  private svgPathData: string;
  private pointsFixed: number[][] = [];
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.USE_OUTER_EDGE = true;
    this.onChange();

    const defaultStyle = {
      border: {
        width: DEFAULT_PEN_BORDER_SIZE,
        color: DEFAULT_PEN_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }

  public draw(ctx, { x, y, width, height }, options?: DrawOptions): { height: number; y: number } {
    let lineWidth = this.elementDefinition.style?.border?.width;
    const strokeStyle = this.elementDefinition.style?.border?.color;

    if (!this.svgPathData || options?.scaleBy) {
      this.onChange(options);
    }
    if (options?.scaleBy) {
      lineWidth = lineWidth / options.scaleBy;
    }
    const path = new Path2D(this.svgPathData);

    if (this.elementDefinition.type === 'highlighter') {
      ctx.save();
      ctx.globalAlpha = DEFAULT_HIGHLIGHTER_OPACITY;
    }
    ctx.translate(-width * 0.5, -height * 0.5);
    ctx.lineDashOffset = 0;
    ctx.setLineDash([]);
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = strokeStyle;
    ctx.stroke(path);

    if (this.elementDefinition.type === 'highlighter') {
      ctx.restore();
    }

    return;
  }

  public onChange(options?: DrawOptions) {
    this.pointsFixed = PenDrawHandler.percentageToFixed(this.elementDefinition.points, this.getSize(options));
    this.svgPathData = PenDrawHandler.getSvgPathFromPoints(this.pointsFixed);
  }

  public isPointInStroke(event, checkInBetweenPoints = false) {
    if (!this.svgPathData) {
      this.onChange();
    }

    if (!this.pointsFixed?.length) {
      return false;
    }

    const point = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    const { x, y, width, height } = this.getBoundingClientRect({ maskedDimensions: true, outerEdgeDimensions: true });
    if (this.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: point.x, y: point.y }, -this.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      point.x = rotatedPosition.x;
      point.y = rotatedPosition.y;
    }

    if (!this.isPointOnBody(point.x, point.y, 0, { x, y, width, height })) {
      return false;
    }

    const ctx: CanvasRenderingContext2D = this.canvasDocument.canvasDisplay.context;
    const path = new Path2D(this.svgPathData);
    const lineWidth = this.elementDefinition.style?.border?.width;

    ctx.lineWidth = lineWidth;
    let isPointInStroke = ctx.isPointInStroke(
      path,
      point.x - this.elementDefinition.position.x,
      point.y - this.elementDefinition.position.y,
    );

    if (checkInBetweenPoints && !isPointInStroke) {
      const movementX = event.movementX;
      const movementY = event.movementY;
      const movementHypot = Math.hypot(Math.abs(event.movementX), Math.abs(event.movementY));
      if (movementHypot >= lineWidth) {
        const inBetweenPoints = [];
        const max = Math.ceil(movementHypot / lineWidth);
        for (let i = 1; i <= max; i++) {
          inBetweenPoints.push([event.clientX - (i * movementX) / max, event.clientY - (i * movementY) / max]);
        }
        for (let i = 0; i < inBetweenPoints.length; i++) {
          const p = inBetweenPoints[i];
          const point = this.canvasDocument.toDocumentPosition(p[0], p[1]);
          if (
            ctx.isPointInStroke(
              path,
              point.x - this.elementDefinition.position.x,
              point.y - this.elementDefinition.position.y,
            )
          ) {
            isPointInStroke = true;
            break;
          }
        }
      }
    }
    return isPointInStroke;
  }

  public toSVG({ x, y, width, height }): HTMLElement {
    const element = document.createElement('path');
    const pointsFixed = PenDrawHandler.percentageToFixed(this.elementDefinition.points, { width, height }, { x, y });
    const svgPathData = PenDrawHandler.getSvgPathFromPoints(pointsFixed);

    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);
    element.setAttribute('fill', 'none');
    element.setAttribute('d', svgPathData);
    element.setAttribute('stroke', this.elementDefinition.style?.border?.color);
    element.setAttribute('stroke-width', `${this.elementDefinition.style?.border?.width}px`);
    element.setAttribute('stroke-linecap', 'round');
    element.setAttribute('stroke-linejoin', 'round');
    if (this.elementDefinition.type === 'highlighter') {
      element.setAttribute('stroke-opacity', `${DEFAULT_HIGHLIGHTER_OPACITY}`);
    }

    return element;
  }
}
