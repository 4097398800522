import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppExtensionService } from '@common/app-extensions/app-extension.service';
import { BoardsWebSDKMessageHandler } from '../../../web-sdk/board-web-sdk-message-handler';
import { DocumentService } from '../../document.service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import { BoardsSelectors } from 'src/app/boards/boards-store';
import { AppExtensionsActions, AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { AppExtension, AppExtensionDefinition, AppExtensionType, AppExtensionUserApps } from '@contrail/entity-types';
import { FeatureFlagActions, FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';
import { BoardContext, VibeIQAppType } from '@contrail/extensions-sdk';

@Component({
  selector: 'app-document-add-advanced',
  templateUrl: './document-add-advanced.component.html',
  styleUrls: ['./document-add-advanced.component.scss'],
})
export class DocumentAddAdvancedComponent implements OnInit, OnDestroy {
  public appExtensions: Array<AppExtension> = [];
  private subs: Array<Subscription> = [];
  @Input() tooltipPosition = 'right';
  constructor(
    private store: Store<RootStoreState.State>,
    private boardsWebSDKMessageHandler: BoardsWebSDKMessageHandler,
    private documentService: DocumentService,
    private appExtensionService: AppExtensionService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(AppExtensionsActions.loadAppExtensions());
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.EXTENSIONS)) {
            this.subs.push(
              this.store.select(AppExtensionsSelectors.appExtensions).subscribe((extensions) => {
                this.appExtensions = extensions.filter((extension) =>
                  extension.userApps.some((appType) =>
                    [AppExtensionUserApps.BOARDS, AppExtensionUserApps.ALL].includes(appType),
                  ),
                );
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  addAdvancedFeature(feature: string) {
    switch (feature) {
      case 'assortment-grid': {
        this.createLineBoard();
        break;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  registerExtensions(extensions: Array<AppExtensionDefinition>) {
    console.log('registering extensions:', extensions);
    this.appExtensions = extensions.filter((x) => x.userApps.includes(AppExtensionUserApps.BOARDS));
  }

  createLineBoard() {
    this.documentService.deselectAllElements();
    this.documentService.setInteractionMode('generate_document');
  }

  launchExtension(app: AppExtension) {
    let board;
    this.store
      .select(BoardsSelectors.currentBoard)
      .pipe(
        take(1),
        tap((b) => {
          board = b;
        }),
      )
      .subscribe();

    const boardContext: BoardContext = {
      id: board.id,
      name: board.name,
      orgId: board.orgId,
      backingAssortmentId: board.backingAssortmentId,
      documentId: board.documentId,
      previewFileId: board.previewFileId,
      rootWorkspaceId: board.rootWorkspaceId,
      typeId: board.typeId,
      workspaceId: board.workspaceId,
    };

    const document: any = this.documentService.getDocument();
    if (document) {
      boardContext.document = {
        id: document.id,
        name: document.name,
        orgId: document.orgId,
        ownedByReference: document.ownedByReference,
        size: document.size,
        elements: document.elements,
        documentElementSortOrder: document.documentElementSortOrder,
        modelBindings: document.modelBindings,
        workspaceId: document.workspaceId,
      };
    }

    const selectedElements =
      app.extensionType === AppExtensionType.CONTEXTUAL_ACTION ? this.documentService.getSelectedElements() : undefined;

    const context = {
      board: boardContext,
      vibeIQApp: VibeIQAppType.BOARDS,
      selectedElements,
    };
    this.appExtensionService.launchExtensionModal(app, this.boardsWebSDKMessageHandler, context);
  }

  getInteractionMode() {
    return this.documentService.getInteractionMode();
  }
}
