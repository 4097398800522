import { ObjectUtil } from '@contrail/util';
import { ItemData } from '../item-data/item-data';
import { AssortmentItem } from './assortment-item';
export class AssortmentUtil {
  /** Takes a collection if item data and coverts each item into ItemData (without hiearchy) */
  public static convertAssortmentItemsToItemData(assortmentItems: Array<AssortmentItem>) {
    return assortmentItems.filter((ai) => !!ai.item).map((ai) => new ItemData(ai));
  }

  /** Takes a collection of assortment items and creates an ItemData hiearchy */
  public static convertAssortmentItemsFamilyItemData(assortmentItems: Array<AssortmentItem>): ItemData[] {
    const familyMap: Map<string, ItemData> = new Map();

    // MAP Families.
    for (const assortmentItem of assortmentItems) {
      const item = assortmentItem.item;
      if (!item) {
        continue;
      }

      const familyId = item.itemFamilyId;
      if (familyMap.get(familyId)) {
        continue;
      }
      const familyItem = { ...assortmentItem, item: { ...item, id: familyId } };
      const familyItemData = new ItemData(familyItem);
      const optionAssortentItems = this.getAssortmentItemOptionsForFamily(familyId, assortmentItems);
      familyItemData.options = this.convertAssortmentItemsToItemData(optionAssortentItems);
      familyMap.set(familyId, familyItemData);
    }

    return [...familyMap.values()];
  }

  /** Takes a collection of items and creates an family-level only ItemData */
  public static convertItemsToFamilyItemData(items: Array<any>): ItemData[] {
    const familyMap: Map<string, ItemData> = new Map();
    for (const item of items) {
      let familyId = item.itemFamilyId;
      if (item.entityType === 'assortment-item') {
        familyId = item.item.itemFamilyId;
      }
      if (familyMap.get(familyId)) {
        continue;
      }
      let familyItem;
      if (item.entityType === 'assortment-item') {
        familyItem = item;
      } else {
        familyItem = { ...item, item: { ...item, id: familyId }, projectItem: ObjectUtil.cloneDeep(item.projectItem) };
      }
      const familyItemData = new ItemData(familyItem);
      familyMap.set(familyId, familyItemData);
    }
    return [...familyMap.values()];
  }

  private static getAssortmentItemOptionsForFamily(
    familyId: string,
    assortmentItems: Array<AssortmentItem>,
  ): Array<AssortmentItem> {
    return assortmentItems
      .filter((ai) => ai.item?.itemFamilyId === familyId)
      .filter((ai) => ai.item?.roles?.includes('option'));
  }
  public static convertItemData(itemData) {
    let entity;
    if (itemData.assortmentItem) {
      entity = {
        entityType: 'assortment-item',
        id: itemData.id,
        item: {
          ...itemData.item,
          thumbnail: itemData.thumbnail,
        },
        projectItem: {
          ...itemData.projectItem,
        },
        ...itemData.assortmentItem,
      };
    } else {
      entity = {
        entityType: 'item',
        id: itemData.id,
        ...itemData.item,
        thumbnail: itemData.thumbnail,
        projectItem: {
          ...itemData.projectItem,
        },
      };
    }
    return entity;
  }
}
