import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-source-selector-breadcrumb',
  template: `
    <div class="flex flex-wrap p-2">
      <span class="name" (click)="gotoOptionType()"> All sources </span>
      <span class="mx-2">/</span>
      <span class="text-gray-700">
        <ng-container *ngIf="step === 'ASSORTMENT_SELECT'">Assortment</ng-container>
        <ng-container *ngIf="step === 'RECENT_DOCUMENT'">Recent documents</ng-container>
        <ng-container *ngIf="step === 'WORKSPACE_SELECT'">Browse documents</ng-container>
        <ng-container *ngIf="step === 'DOCUMENT_SELECT' && !tempArr?.length">Browse documents</ng-container>
      </span>
      <ng-container *ngIf="step === 'DOCUMENT_SELECT'">
        <ng-container *ngFor="let tempWS of tempArr; index as idx">
          <div class="flex" *ngIf="tempWS?.parent" (click)="goToFolder(tempWS.parent)">
            <span class="name">
              {{ tempWS.parent.workspaceType === 'PROJECT' ? 'Browse documents' : tempWS.parent.name }}
            </span>
            <span class="mx-2">/</span>
          </div>

          <div class="text-gray-800 font-medium" *ngIf="idx + 1 === tempLength">
            {{ tempWS.name }}
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .name {
        @apply text-gray-700 cursor-pointer hover:underline hover:text-primary hover:font-medium;
      }
    `,
  ],
})
export class SourceSelectorBreadcrumbComponent implements OnInit {
  @Input() step: string;
  @Output() optionType = new EventEmitter();
  currentFolder: Workspace;
  tempArr = [];
  tempLength = 0;

  constructor(
    public store: Store<RootStoreState.State>,
    private folderEntityService: WorkspaceEntitiesHelperService,
  ) {}

  ngOnInit(): void {
    this.store.select(WorkspacesSelectors.currentWorkspaceFolder).subscribe((ws) => {
      this.currentFolder = ws;
      this.setTempArr();
    });
  }

  gotoOptionType() {
    this.optionType.emit();
  }

  goToFolder(folder) {
    this.folderEntityService.goToFolder(folder);
  }

  setTempArr() {
    let tempWS = this.currentFolder;
    let length = 0;
    this.tempArr = [];
    const arr = [...Array(20).keys()];
    for (let idx = 0; idx < arr.length; idx++) {
      if (tempWS?.parent) {
        this.tempArr.push(tempWS);
        tempWS = tempWS.parent;
      } else {
        length = idx;
        break;
      }
    }
    this.tempLength = length;
    this.tempArr.reverse();
  }
}
