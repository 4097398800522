import { Injectable } from '@angular/core';
import { UserOrg } from '@contrail/entity-types';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class UserOrgsService {
  constructor() {}

  public getUserOrgs(): Promise<UserOrg[]> {
    return new Entities().get({ entityName: 'user-org', relations: ['groupPrincipals', 'group', 'principal'] });
  }

  public getUserByEmail(email: String): Promise<UserOrg> {
    return new Entities().get({ entityName: 'user', criteria: { email } });
  }
}
