import { Component, Input, OnInit } from '@angular/core';
import { Comment, CommentOwnerInfo } from '../comments.service';

@Component({
  selector: 'app-list-comments',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss'],
})
export class ListCommentsComponent implements OnInit {
  @Input() context;
  @Input() comments: Array<Comment>;
  @Input() showOwnerName;
  @Input() ownerInfo: CommentOwnerInfo;
  @Input() accessLevel = 'EDIT';

  constructor() {}
  ngOnInit(): void {}
}
