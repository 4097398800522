<div class="item-container" *ngIf="selectedItem">
  <app-item-details-header
    [item]="selectedItem"
    [itemFamily]="itemFamily"
    [itemOptions]="itemOptions"
    [navOptions]="navOptions"
    [type]="type"
    (navigate)="selectNavOption($event)"
    (cancelView)="cancel()"
    (itemClicked)="setCurrentItem($event)"
  >
  </app-item-details-header>
  <div class="body">
    <!-- DETAILS -->
    <div *ngIf="currentNavOption.slug === 'details'" class="details-wrap">
      <div class="left-column narrow-scroll">
        <app-item-family
          [selectedItem]="selectedItem"
          [itemFamily]="itemFamily"
          [itemOptions]="itemOptions"
          (itemClicked)="setCurrentItem($event)"
        ></app-item-family>
        <mat-divider></mat-divider>
        <app-item-details-status [item]="selectedItem" [type]="type"></app-item-details-status>
        <mat-divider></mat-divider>
        <app-item-details-meta [item]="selectedItem" [type]="type"></app-item-details-meta>
      </div>

      <div class="center-column">
        <app-item-properties-form
          [accessLevel]="accessLevel"
          [item]="selectedItem"
          [type]="type"
          [errors]="errors"
          [editable]="propertyEditable"
        ></app-item-properties-form>
      </div>

      <div class="right-column flex-center flex-col w-[675px]">
        <app-content-holder
          [editable]="contentEditable"
          [contentHolder]="selectedItem"
          (primaryViewableChanged)="handlePrimaryViewableChange($event)"
        ></app-content-holder>
      </div>
    </div>

    <!-- CONTENT -->
    <div *ngIf="currentNavOption.slug === 'content'" class="content-wrap">
      <app-content-holder-details
        [editable]="contentEditable"
        [contentHolder]="selectedItem"
        (primaryViewableChanged)="handlePrimaryViewableChange($event)"
      ></app-content-holder-details>
    </div>

    <!-- DOCUMENTS -->
    <div *ngIf="currentNavOption.slug === 'documents'" class="content-wrap">
      <app-item-documents-list
        [allDocuments]="allDocumentsUnderFamily"
        [selectedItem]="selectedItem"
        [itemFamily]="itemFamily"
        [isLoading]="isLoadingDocuments"
      ></app-item-documents-list>
    </div>
  </div>
</div>

<div class="loading" *ngIf="selectedItem === null">
  <mat-spinner diameter="40"> </mat-spinner>
  <div class="message">Loading</div>
</div>
