import { createAction, props } from '@ngrx/store';
import { DocumentElementEntity } from '../../document-manager.service';
import { DocumentAction } from '@contrail/documents';

export enum DocumentElementsActionTypes {
  CLEAR_DOCUMENT_ELEMENTS = '[DocumentManager] Clear Document Elements',
  SET_DOCUMENT_ELEMENTS = '[DocumentManager] Set Document Elements',

  HANDLE_DOCUMENT_ELEMENT_ACTIONS = '[DocumentManager] Handle Document Element Actions',
  UPDATE_DOCUMENT_ELEMENT_STORE = '[DocumentManager] Update Document Element Store',

  UPDATE_DOCUMENT_ELEMENT_ENTITY = '[DocumentManager] Update Document Element Entity',
  UPDATE_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Update Document Element Entities',

  DELETE_DOCUMENT_ELEMENT_ENTITY = '[DocumentManager] Delete Document Element Entity',
  DELETE_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Delete Document Element Entities',

  APPLY_DOCUMENT_ELEMENT_ENTITY_CHANGES = '[DocumentManager] Apply Document Element Entity Changes',
  BATCH_APPLY_DOCUMENT_ELEMENT_ENTITY_CHANGES = '[DocumentManager] Batch Apply Document Element Entity Changes',

  REMOVE_DOCUMENT_ELEMENT_ENTITY = '[DocumentManager] Remove Document Element Entity',
  REMOVE_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Remove Document Element Entities',

  CREATE_DOCUMENT_ELEMENT_ENTITY = '[DocumentManager] Create Document Element Entity',
  CREATE_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Create Document Element Entities',

  ADD_DOCUMENT_ELEMENT_ENTITY = '[DocumentManager] Add Document Element Entity',
  ADD_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Add Document Element Entities',

  CREATE_AND_DELETE_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Create And Delete Document Element Entities',

  REORDER_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Reorder Document Element Entities',
  BATCH_REORDER_DOCUMENT_ELEMENT_ENTITIES = '[DocumentManager] Batch Reorder Document Element Entities',
  UPDATE_DOCUMENT_ELEMENT_SORT_ORDER = '[DocumentManager] Update Document Element Sort Order',
  BATCH_UPDATE_DOCUMENT_ELEMENT_SORT_ORDER = '[DocumentManager] Batch Update Document Element Sort Order',
}

////////////////////////////////////////////////////////////////////////////////

export const clearDocumentElements = createAction(DocumentElementsActionTypes.CLEAR_DOCUMENT_ELEMENTS);
export const setDocumentElements = createAction(
  DocumentElementsActionTypes.SET_DOCUMENT_ELEMENTS,
  props<{ documentElements: Array<any> }>(),
);

export const handleDocumentElementActions = createAction(
  DocumentElementsActionTypes.HANDLE_DOCUMENT_ELEMENT_ACTIONS,
  props<{ actions: DocumentAction[] }>(),
);
export const updateDocumentElementStore = createAction(
  DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_STORE,
  props<{ actions: DocumentAction[] }>(),
);

export const updateDocumentElementEntity = createAction(
  DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_ENTITY,
  props<{ id: string; documentElement: DocumentElementEntity }>(),
);

export const updateDocumentElementEntities = createAction(
  DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_ENTITIES,
  props<{ documentElements: DocumentElementEntity[] }>(),
);

export const applyDocumentElementEntityChanges = createAction(
  DocumentElementsActionTypes.APPLY_DOCUMENT_ELEMENT_ENTITY_CHANGES,
  props<{ id; documentElement: DocumentElementEntity }>(),
);

export const batchApplyDocumentElementEntityChanges = createAction(
  DocumentElementsActionTypes.BATCH_APPLY_DOCUMENT_ELEMENT_ENTITY_CHANGES,
  props<{ documentElements: DocumentElementEntity[] }>(),
);

export const deleteDocumentElementEntity = createAction(
  DocumentElementsActionTypes.DELETE_DOCUMENT_ELEMENT_ENTITY,
  props<{ id: string }>(),
);

export const deleteDocumentElementEntities = createAction(
  DocumentElementsActionTypes.DELETE_DOCUMENT_ELEMENT_ENTITIES,
  props<{ documentElements: DocumentElementEntity[] }>(),
);

export const removeDocumentElementEntity = createAction(
  DocumentElementsActionTypes.REMOVE_DOCUMENT_ELEMENT_ENTITY,
  props<{ id: string }>(),
);

export const removeDocumentElementEntities = createAction(
  DocumentElementsActionTypes.REMOVE_DOCUMENT_ELEMENT_ENTITIES,
  props<{ ids: string[] }>(),
);

export const createDocumentElementEntity = createAction(
  DocumentElementsActionTypes.CREATE_DOCUMENT_ELEMENT_ENTITY,
  props<{ id; documentElement: DocumentElementEntity }>(),
);

export const createDocumentElementEntities = createAction(
  DocumentElementsActionTypes.CREATE_DOCUMENT_ELEMENT_ENTITIES,
  props<{ documentElements: DocumentElementEntity[] }>(),
);

export const createAndDeleteDocumentElementEntities = createAction(
  DocumentElementsActionTypes.CREATE_AND_DELETE_DOCUMENT_ELEMENT_ENTITIES,
  props<{
    documentElements: DocumentElementEntity[];
    deleteDocumentElements: DocumentElementEntity[];
    actions: any[];
  }>(),
);

export const addDocumentElementEntity = createAction(
  DocumentElementsActionTypes.ADD_DOCUMENT_ELEMENT_ENTITY,
  props<{ documentElement: DocumentElementEntity }>(),
);

export const addDocumentElementEntities = createAction(
  DocumentElementsActionTypes.ADD_DOCUMENT_ELEMENT_ENTITIES,
  props<{ documentElements: DocumentElementEntity[] }>(),
);

export const reorderDocumentElementEntities = createAction(
  DocumentElementsActionTypes.REORDER_DOCUMENT_ELEMENT_ENTITIES,
  props<{ documentElements: DocumentElementEntity[]; targetSortIndex?: number }>(),
);

export const batchReorderDocumentElementEntities = createAction(
  DocumentElementsActionTypes.BATCH_REORDER_DOCUMENT_ELEMENT_ENTITIES,
  props<{ sorts: Array<any> }>(),
);

export const updateDocumentElementSortOrder = createAction(
  DocumentElementsActionTypes.UPDATE_DOCUMENT_ELEMENT_SORT_ORDER,
  props<{ sortIds: string | { id: string; index: number }; targetSortIndex?: number }>(),
);
