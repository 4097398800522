import { CanvasDocument } from '../../../canvas-document';
import { EditorManager } from '../../../components/editor/editor-manager';
import { EditorPlaceholder } from '../../../components/editor/editor-placeholder';
import { placeholderId } from './text-editor';

export class TextEditorPlaceholder extends EditorPlaceholder {
  constructor(canvasDocument: CanvasDocument, manager: EditorManager) {
    super(canvasDocument, placeholderId, manager);
  }
}
