<div class="narrow-scroll overflow-y-scroll overflow-x-hidden h-full block pb-3">
  <app-dashboard-widget
    *ngFor="let widgetConfiguration of dashboardDefinition.dashboardWidgetConfigurations"
    [assortmentItemData]="assortmentItemData"
    [appContext]="appContext"
    [annotationOptions]="annotationOptions"
    [dashboardWidgetConfiguration]="widgetConfiguration"
  >
  </app-dashboard-widget>
</div>
