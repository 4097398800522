import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { Types } from '@contrail/sdk';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-color-chip',
  template: `<div *ngIf="formGroup">
    <mat-chip-list [formGroup]="formGroup">
      <ng-container *ngFor="let option of propertyFormConfiguration.typeProperty.options">
        <mat-chip
          class="!cursor-pointer !text-black60 !w-8 !h-8 !px-1 !border !border-solid"
          (click)="onChange(option.value)"
          [ngStyle]="{
            'background-color': option.value,
            'border-color': option.value == '#ffffff' ? 'rgba(0, 0, 0, 0.24)' : option.value
          }"
        >
          <mat-icon *ngIf="isSelected(option.value)" class="!w-6 !h-6 !text-[24px] !leading-[24px]">done</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldColorChipComponent extends TypePropertyFormFieldBaseComponent {
  options: TypePropertyOption[];

  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  async initFormControl() {
    if (!this.propertyFormConfiguration?.typeProperty) {
      return;
    }
    this.options = await this.getOptions();
    const options = this.propertyFormConfiguration.typeProperty.options?.filter((opt) =>
      this.value?.includes(opt.value),
    );
    if (options) {
      this.formGroup = this.formBuilder.group({
        values: this.formBuilder.array([]),
      });
    }
  }

  isSelected(value) {
    const values = (<FormArray>this.formGroup.get('values')) as FormArray;
    return values.controls.findIndex((x) => x.value === value) !== -1;
  }

  removeOption(value) {
    const values = (<FormArray>this.formGroup.get('values')) as FormArray;
    const i = values.controls.findIndex((x) => x.value === value);
    values.removeAt(i);
  }

  clearValue() {
    this.formGroup = this.formBuilder.group({
      values: this.formBuilder.array([]),
    });
  }

  onChange(value) {
    const values = (<FormArray>this.formGroup.get('values')) as FormArray;
    const isSelected = this.isSelected(value);
    if (isSelected) {
      const i = values.controls.findIndex((x) => x.value === value);
      values.removeAt(i);
    } else {
      values.push(new FormControl(value));
    }

    this.value = this.formGroup.value.values;
    this.handleChange();
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    if (!this.entity?.typeId) {
      return ObjectUtil.cloneDeep(this.propertyFormConfiguration.typeProperty.options);
    }
    const type = await new Types().getType({ id: this.entity.typeId });
    const eligibleOptions = await TypeConstraintsHelper.getValidOptionSets(
      type,
      this.propertyFormConfiguration.typeProperty,
      this.entity,
    );
    return ObjectUtil.cloneDeep(eligibleOptions);
  }
}
