import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable } from 'rxjs';
import { BoardsSelectors } from '../../boards-store';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { take, tap } from 'rxjs/operators';
import { DocumentService } from '../document/document.service';
import { EntityReference } from '@contrail/sdk';

@Component({
  selector: 'app-board-dashboard-panel',
  templateUrl: './board-dashboard-panel.component.html',
  styleUrls: ['./board-dashboard-panel.component.scss'],
})
export class BoardDashboardPanelComponent implements OnInit {
  @Output() panelClosed = new EventEmitter();
  public appContext: any;
  public assortmentItemData$: Observable<any>;
  public annotationOptions: any[];
  public assortmentItemData: any[];
  public selectedAssortmentItemData: any[];
  public selectedComponentElementIds: any[];

  constructor(
    public store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    this.assortmentItemData$ = this.store.select(AssortmentsSelectors.backingAssortmentItemData);
    this.annotationOptions = this.documentService.annotationOptions.filter(
      (annotation) => !annotation.dashboardDisabled,
    );
  }

  ngOnInit(): void {
    let board;
    this.store
      .select(BoardsSelectors.currentBoard)
      .pipe(
        take(1),
        tap((b) => {
          board = b;
        }),
      )
      .subscribe();
    const context = {
      board,
      ownerReferenceId: board.id,
      vibeIQApp: 'boards',
    };
    this.appContext = context;
    this.assortmentItemData$.subscribe((data) => {
      this.assortmentItemData = data;
      this.filterAssortmentItemsBySelection();
    });
    this.documentService.documentElementEvents.subscribe((event) => {
      if (event.eventType === 'selected') {
        this.selectedComponentElementIds = this.documentService
          .getSelectedElements()
          .filter((element) => element.type === 'component' && element?.modelBindings?.item)
          .map((element) => {
            const entityReference = new EntityReference(element.modelBindings.item);
            return entityReference.id;
          });
        this.filterAssortmentItemsBySelection();
      } else if (event.eventType === 'deselect') {
        this.selectedAssortmentItemData = null;
      }
    });
  }

  private filterAssortmentItemsBySelection() {
    if (this.selectedComponentElementIds?.length > 1) {
      // only change dashboard data for multiple selected items
      const filteredAssormentItems = this.assortmentItemData.filter((bai) =>
        this.selectedComponentElementIds.includes(bai.item?.id),
      );
      this.selectedAssortmentItemData = filteredAssormentItems;
    } else {
      this.selectedAssortmentItemData = null;
    }
  }

  handleClose() {
    this.panelClosed.emit();
  }
}
