import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-indentation',
  templateUrl: './property-configurator-indentation.component.html',
  styleUrls: ['./property-configurator-indentation.component.scss'],
})
export class PropertyConfiguratorIndentationComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  constructor() {}

  ngOnChanges(): void {}

  setValue(value) {
    const changes = { type: value, value: '' };
    this.valueChange.emit(changes);
  }
}
