import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { DRAG_DIRECTIONS, MouseTarget } from '../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasElement } from '../canvas-element';
import { Table, TableCell } from './table-manager/table/table';
import { TableHoverRenderer } from './table-manager/table-hover/table-hover-renderer';
import { ROW_MIN_HEIGHT } from '../../constants';
import { ImageElement } from '../../components/image-element/image-element';

export class CanvasTableElement extends CanvasElement {
  public table: Table;
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.selectionWidgetRenderer.DRAG_HANDLES = ['top_left', 'top_right', 'bottom_right', 'bottom_left'];
    this.isAsync = true;
    this.isRotationEnabled = false;
    this.table = new Table(this.canvasDocument, this);
  }

  protected draw(ctx, params, options?: DrawOptions): { height: number } {
    this.table.render(
      ctx,
      { x: -params.width * 0.5, y: -params.height * 0.5, width: params.width, height: params.height },
      options,
    );
    return;
  }

  public getDragHandle(px, py, selectedElementsCount): MouseTarget {
    const selectionHandle = this.selectionWidgetRenderer.getDragHandle(px, py);
    if (selectionHandle) {
      if (
        [DRAG_DIRECTIONS.BOTTOM_CENTER, DRAG_DIRECTIONS.MID_RIGHT].indexOf(selectionHandle) === -1 ||
        !this.table.isEditing
      ) {
        return { direction: selectionHandle };
      }
    }
    if (selectedElementsCount !== 1) return;
    const viewScale = this.canvasDocument.getViewScale();
    const lineWidth = Math.max(3, Math.round(3 / viewScale.x));
    const { cell, isPointOnElement } = this.getCellAtAndHandleHoverState(px, py);
    if (!isPointOnElement) return;
    let direction, index;
    if (cell.rowIndex >= 0 && cell.columnIndex >= 0) {
      const top = Math.abs(py - cell.y) < lineWidth;
      const bottom = Math.abs(py - (cell.y + cell.height)) < lineWidth;
      const left = Math.abs(px - cell.x) < lineWidth;
      const right = Math.abs(px - (cell.x + cell.width)) < lineWidth;

      if (top || bottom) {
        direction = DRAG_DIRECTIONS.RESIZE_ROW;
        index = { x: cell.columnIndex, y: top ? Math.max(0, cell.rowIndex - 1) : cell.rowIndex };
      } else if (left || right) {
        direction = DRAG_DIRECTIONS.RESIZE_COLUMN;
        index = { x: left ? Math.max(0, cell.columnIndex - 1) : cell.columnIndex, y: cell.rowIndex };
      } else {
        direction = DRAG_DIRECTIONS.CELL;
        index = { x: cell.columnIndex, y: cell.rowIndex };
      }
    } else if (cell.rowIndex === -1 && cell.columnIndex >= 0) {
      direction = DRAG_DIRECTIONS.BODY;
      index = { x: cell.columnIndex };
    } else if (cell.columnIndex === -1 && cell.rowIndex >= 0) {
      direction = DRAG_DIRECTIONS.BODY;
      index = { y: cell.rowIndex };
    }

    if ((cell?.rowIndex === -1 && cell?.columnIndex >= 0) || (cell?.columnIndex === -1 && cell?.rowIndex >= 0)) {
      const hoverHandle: MouseTarget = this.table.tableHoverState.getHoverHandle(
        px,
        py,
        { ...this.elementDefinition.position },
        viewScale,
      );
      if (hoverHandle) {
        direction = hoverHandle.direction;
        index = hoverHandle.index;
      }
    }
    return direction ? { direction, index } : null;
  }

  /**
   * Get cell at mouse position @px and @py
   * Handle hover state for this cell
   * @param px
   * @param py
   * @returns
   */
  public getCellAtAndHandleHoverState(px, py): { cell: TableCell; isPointOnElement: boolean } {
    const viewScale = this.canvasDocument.getViewScale();
    const tablePadding = TableHoverRenderer.getPadding(viewScale);
    const addHandleRadius = TableHoverRenderer.getAddHandleWidth(viewScale) * 0.5;
    const isPointOnElement = this.isPointOnElement(px, py, 0, {
      pl: tablePadding,
      pt: tablePadding,
      pr: addHandleRadius,
      pb: addHandleRadius,
    });
    const cellAt: TableCell = this.table.getCellAt(px, py);
    if (!isPointOnElement) {
      if (this.table?.tableHoverState?.isSet()) {
        this.table.tableHoverState.set();
        this.canvasDocument.draw();
      }
      return { cell: cellAt, isPointOnElement };
    }
    if (
      this.table &&
      this.table.tableHoverState &&
      (!this.table.tableHoverState.isSet() || !this.table.tableHoverState.isSame(cellAt))
    ) {
      this.table.tableHoverState.set(cellAt);
      this.canvasDocument.draw();
    }

    return { cell: cellAt, isPointOnElement };
  }

  public getColumnElement(index): DocumentElement {
    const id = this.table.columns[index].id;
    return this.canvasDocument.documentDefinition.elements.find((e) => e.id === id);
  }

  public getRowElement(index): DocumentElement {
    const id = this.table.rows[index]?.id;
    return this.canvasDocument.documentDefinition.elements.find((e) => e.id === id);
  }

  public getRowElements(): DocumentElement[] {
    return Array.from(this.table.rows.values()).map((row, index) => this.getRowElement(index));
  }

  public getColumnElements(): DocumentElement[] {
    return Array.from(this.table.columns.values()).map((column, index) => this.getColumnElement(index));
  }

  public getMinHeight(rowIndex): number {
    let minHeight = ROW_MIN_HEIGHT;
    this.table.tableCellState.cells[rowIndex].forEach((cellId) => {
      minHeight = Math.max(minHeight, this.table.tableCellState.findCell(cellId).elementDefinition.size.height);
    });
    return minHeight;
  }

  public getMinHeights(): number[] {
    const minHeights = [];
    this.table.tableCellState.cells.forEach((cells, rowIndex) => {
      minHeights.push(this.getMinHeight(rowIndex));
    });
    return minHeights;
  }

  public getCellsAtColumn(columnIndex): DocumentElement[] {
    return this.table.tableCellState.getColumnsCells([columnIndex]).map((e) => e.elementDefinition);
  }

  public getCellsAtRow(rowIndex): DocumentElement[] {
    return this.table.tableCellState.getRowsCells([rowIndex]).map((e) => e.elementDefinition);
  }

  public getAllCells(): DocumentElement[] {
    if (!this.table) return [];
    return this.table.getAllCells().map((e) => e.elementDefinition);
  }

  public onSelect() {
    if (!this.isSelected) {
      this.canvasDocument?.editorHandler?.tableEditorManager?.getCurrentStyle(this);
    }
  }

  public onDeselect(): void {
    this.table?.clear();
  }

  public onResizeStart(): void {
    this.table.tableHoverState.set();
  }

  public onResize(): void {
    this.table.setRows();
    this.table.setColumns();
  }

  public updateTableState() {
    this.table?.updateTableState();
  }

  /**
   * Get rows, columns, cells document elements for @this table
   * @returns
   */
  public getTableChildElements(): DocumentElement[] {
    return this.canvasDocument.documentDefinition.elements.filter(
      (e) => e.tableId === this.elementDefinition.id && ['cell', 'column', 'row'].indexOf(e.type) !== -1,
    );
  }

  public async preload(options?: DrawOptions): Promise<ImageElement[]> {
    if (!this.table.isInitialized) return;
    return this.table.tableCellState.preload(options);
  }
}
