import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasStickyNoteElement } from '../../../elements/sticky-note/canvas-sticky-note-element';
import {
  STICKY_ALIGN,
  STICKY_COLOR,
  STICKY_FONT_FAMILY,
  STICKY_FONT_SIZE,
  STICKY_HEIGHT,
  STICKY_VALIGN,
  STICKY_WIDTH,
} from '../../../elements/sticky-note/editor/sticky-note-editor';
import { CanvasTextElement } from '../../../elements/text/canvas-text-element';
import {
  DEFAULT_TEXT_FONT_FAMILY,
  TEXT_TOOL_PLACEHOLDER,
  TEXT_CAROT_WIDTH,
  DEFAULT_TEXT_TOOL_BORDER_SIZE,
  DYNAMIC_TEXT_PLACEHOLDER,
  DEFAULT_TEXT_BORDER_SIZE,
} from '../../../elements/text/editor/text-editor';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { ObjectUtil } from '@contrail/util';
import { TableService } from '../../../elements/table/table-manager/table.service';

export class ElementClickHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    const interactionMode = this.canvasDocument.getInteractionMode();
    const viewBox = this.canvasDocument.getViewBox();
    const viewScale = this.canvasDocument.getViewScale();
    const boundingClientRect = this.canvasDocument.getBoundingClientRect();
    const { x, y } = CanvasUtil.toDocumentPosition(
      event.clientX,
      event.clientY,
      viewBox,
      viewScale,
      boundingClientRect,
    );
    if (interactionMode === 'create_text_element') {
      this.canvasDocument.deselectAll();
      const lastAppliedFormats = this.canvasDocument?.documentService?.lastAppliedTextFormat;
      const fontSize = lastAppliedFormats?.fontSize ?? 11;
      const fontSizeString = fontSize + 'pt';
      const fontFamily = DEFAULT_TEXT_FONT_FAMILY;
      const placeholderSize = this.canvasDocument.editorHandler.editorCalc.getTextSize(
        TEXT_TOOL_PLACEHOLDER,
        fontSizeString,
        fontFamily,
      );
      const width = placeholderSize.width + DEFAULT_TEXT_TOOL_BORDER_SIZE * 2 + TEXT_CAROT_WIDTH;
      const height = placeholderSize.height + DEFAULT_TEXT_TOOL_BORDER_SIZE * 2;
      const documentElement = this.canvasDocument.actionsDispatcher.addNewElement('text', {
        isTextTool: true,
        position: {
          x: x - 10,
          y: y - 18,
        },
        size: {
          width,
          height,
        },
        style: {
          font: {
            size: fontSize,
          },
        },
      });
      const canvasElement = this.canvasDocument.getCanvasElementById(documentElement.id);
      canvasElement && this.canvasDocument.editorHandler.showEditor(canvasElement as CanvasTextElement);
      return;
    } else if (interactionMode === 'dynamic_text') {
      this.canvasDocument.deselectAll();
      const lastAppliedFormats = this.canvasDocument?.documentService?.lastAppliedTextFormat;
      const fontSize = lastAppliedFormats?.fontSize ?? 11;
      const fontSizeString = fontSize + 'pt';
      const fontFamily = DEFAULT_TEXT_FONT_FAMILY;
      const viewBox = this.canvasDocument.getViewBox();
      const viewScale = this.canvasDocument.getViewScale();
      const boundingClientRect = this.canvasDocument.getBoundingClientRect();
      const { x, y } = CanvasUtil.toDocumentPosition(
        event.clientX,
        event.clientY,
        viewBox,
        viewScale,
        boundingClientRect,
      );
      const placeholderSize = this.canvasDocument.editorHandler.editorCalc.getTextSize(
        DYNAMIC_TEXT_PLACEHOLDER,
        fontSizeString,
        fontFamily,
      );
      const width = placeholderSize.width + DEFAULT_TEXT_BORDER_SIZE * 2 + 22;
      const height = placeholderSize.height + DEFAULT_TEXT_BORDER_SIZE * 2;
      this.canvasDocument.actionsDispatcher.addNewElement('text', {
        isTextTool: true,
        propertyBindings: {},
        propertyBindingsMetaData: {
          displayFunction: null,
          propertyType: null,
        },
        position: {
          x: x - 10,
          y: y - 18,
        },
        size: {
          width,
          height,
        },
        style: {
          font: {
            size: fontSize,
          },
        },
        text: '<p>Click to configure</p>',
      });
      return;
    } else if (interactionMode === 'create_sticky_note') {
      this.canvasDocument.deselectAll();
      const documentElement = this.canvasDocument.actionsDispatcher.addNewElement('sticky_note', {
        text: '',
        position: {
          x: x - STICKY_WIDTH * 0.5,
          y: y - STICKY_HEIGHT * 0.5,
        },
        size: {
          width: STICKY_WIDTH,
          height: STICKY_HEIGHT,
        },
        style: {
          font: {
            family: STICKY_FONT_FAMILY,
            size: STICKY_FONT_SIZE,
          },
          text: {
            align: STICKY_ALIGN,
            valign: STICKY_VALIGN,
          },
          color: STICKY_COLOR,
        },
      });
      const canvasElement = this.canvasDocument.getCanvasElementById(documentElement.id);
      canvasElement && this.canvasDocument.editorHandler.showEditor(canvasElement as CanvasStickyNoteElement);
      return;
    } else if (['new_item_family', 'new_item_option', 'item_copy'].includes(interactionMode)) {
      this.canvasDocument.deselectAll();
      let params: DocumentElement = {
        position: {
          x,
          y,
        },
      };

      const isTargetElementAnItem = Boolean(
        elementTarget?.element?.elementDefinition?.type === 'component' &&
          elementTarget?.element?.elementDefinition?.modelBindings?.item,
      );

      if (interactionMode === 'item_copy') {
        if (!isTargetElementAnItem) {
          return;
        }

        params = {
          id: elementTarget.element.elementDefinition.id,
          modelBindings: elementTarget.element.elementDefinition.modelBindings,
        };
      }

      if (interactionMode === 'new_item_option' && elementTarget?.element) {
        if (!isTargetElementAnItem) {
          return;
        }
        const elementDefinition = ObjectUtil.cloneDeep(elementTarget.element.elementDefinition);
        params = {
          position: elementDefinition.position,
          elements: ObjectUtil.cloneDeep(elementDefinition.elements),
          modelBindings: elementTarget.element.elementDefinition.modelBindings,
          style: ObjectUtil.cloneDeep(elementTarget.element.elementDefinition.style),
        };
        params.position.x = params.position.x + elementDefinition.elements[0].size.width + 50;
      }
      this.canvasDocument.actionsDispatcher.addNewElement(interactionMode, params);
    } else if (interactionMode === 'create_table') {
      this.canvasDocument.deselectAll();
      this.canvasDocument.actionsDispatcher.handleUndoableAdds(TableService.createEmptyTableElement({ x, y }));
      return;
    }

    if (!elementTarget?.target || !elementTarget?.element) {
      return;
    }
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: elementTarget.element.elementDefinition,
      eventType: event.button === 0 ? 'click' : 'right-click',
      sourceMouseEvent: event,
    });
  }
}
