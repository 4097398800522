import { ObjectUtil } from '@contrail/util';
import { Action, createReducer, on } from '@ngrx/store';
import * as UndoRedoActions from './undo-redo.actions';
import { State } from './undo-redo.state';

export const initialState = {
  undoActions: [],
  redoActions: [],
};

const addUndo = (state: State, { changeDefinition }) => {
  return {
    ...state,
    undoActions: [...state.undoActions, ObjectUtil.cloneDeep(changeDefinition)],
  };
};

const addRedo = (state: State, { changeDefinition }) => {
  return {
    ...state,
    redoActions: [...state.redoActions, ObjectUtil.cloneDeep(changeDefinition)],
  };
};

const removeAllRedos = (state: State) => {
  return {
    ...state,
    redoActions: [],
  };
};

const moveLatestUndoToRedo = (state: State) => {
  if (state.undoActions.length === 0) {
    return state;
  }
  return {
    ...state,
    redoActions: [...state.redoActions, ObjectUtil.cloneDeep(state.undoActions[state.undoActions.length - 1])],
    undoActions: state.undoActions.filter((_, i) => i !== state.undoActions.length - 1),
  };
};

const moveLatestRedoToUndo = (state: State) => {
  if (state.redoActions.length === 0) {
    return state;
  }
  return {
    ...state,
    undoActions: [...state.undoActions, ObjectUtil.cloneDeep(state.redoActions[state.redoActions.length - 1])],
    redoActions: state.redoActions.filter((_, i) => i !== state.redoActions.length - 1),
  };
};

export const undoRedoReducers = createReducer(
  initialState,
  on(UndoRedoActions.addUndo, addUndo),
  on(UndoRedoActions.addRedo, addRedo),
  on(UndoRedoActions.removeAllRedos, removeAllRedos),
  on(UndoRedoActions.moveLatestUndoToRedo, moveLatestUndoToRedo),
  on(UndoRedoActions.moveLatestRedoToUndo, moveLatestRedoToUndo),
);

export function reducer(state: State | undefined, action: Action): any {
  return undoRedoReducers(state, action);
}
