import { PositionDefinition, ScaleTransformation, SizeDefinition, ViewBox } from '@contrail/documents';
import { Document } from '@contrail/documents';
import { CanvasDocument } from './canvas-document';

export class CanvasDisplay {
  public canvasDisplayElement: HTMLCanvasElement;
  public context;
  public documentSize: SizeDefinition;
  public documentViewBox: ViewBox;
  public canvasSize: SizeDefinition;
  public viewBox: ViewBox;
  private viewScale: ScaleTransformation = { x: 1, y: 1 };

  private SCALE;

  constructor(
    private canvasDocument: CanvasDocument,
    protected documentDefinition: Document,
    private canvasScale?: number,
  ) {
    this.SCALE = this.canvasScale ?? window.devicePixelRatio;
    console.log('Canvas scale (device pixel ratio): ', this.SCALE);
    this.createCanvas(this.canvasDocument.elementId);
  }

  public clear() {
    if (this.canvasDisplayElement) {
      this.canvasDisplayElement.width = this.canvasDisplayElement.height = 0;
      this.canvasDisplayElement?.remove();
    }
    this.canvasDisplayElement = null;
  }

  public clearDisplay() {
    this.context.clearRect(0, 0, this.canvasSize.width * this.SCALE, this.canvasSize.height * this.SCALE);
  }

  public getDocumentSize(): SizeDefinition {
    return this.documentSize;
  }

  public getViewBox(): ViewBox {
    return this.viewBox;
  }

  public getViewScale(): ScaleTransformation {
    return this.viewScale;
  }

  public getDevicePixelRatio(): number {
    return this.SCALE;
  }

  public getCanvasSize(): SizeDefinition {
    return this.canvasSize;
  }

  public getBoundingClientRect(): DOMRect {
    return this.canvasDisplayElement.getBoundingClientRect();
  }

  private createCanvas(elementId) {
    this.canvasDisplayElement = document.createElement('canvas');
    elementId && document.getElementById(elementId).appendChild(this.canvasDisplayElement);
    this.setSize(this.documentDefinition.size);
    this.context = this.canvasDisplayElement.getContext('2d');
  }

  public toDataURL(type = 'image/png', encoderOptions?: number): string {
    return this.canvasDisplayElement?.toDataURL(type, encoderOptions);
  }

  /**
   *
   * @param documentSize - original size of the document
   * @param canvasSize - size of the document relative to browser window
   * @param viewBox - scaled view box size
   */
  public setSize(
    documentSize: SizeDefinition,
    documentViewBox: ViewBox = { width: documentSize.width, height: documentSize.height, x: 0, y: 0 },
    canvasSize: SizeDefinition = { width: documentSize.width, height: documentSize.height },
    viewBox: ViewBox = { width: canvasSize.width, height: canvasSize.height, x: 0, y: 0 },
  ) {
    if (canvasSize.width !== this.canvasSize?.width || canvasSize.height !== this.canvasSize?.height) {
      this.canvasDisplayElement.setAttribute('width', `${canvasSize.width * this.SCALE}`);
      this.canvasDisplayElement.setAttribute('height', `${canvasSize.height * this.SCALE}`);
      this.canvasDisplayElement.style.width = `${canvasSize.width}px`;
      this.canvasDisplayElement.style.height = `${canvasSize.height}px`;
    }
    this.documentViewBox = documentViewBox;
    this.documentSize = documentSize;
    this.canvasSize = canvasSize;
    this.viewBox = viewBox;
    this.viewScale.x = this.canvasSize.width / this.viewBox.width;
    this.viewScale.y = this.canvasSize.height / this.viewBox.height;
  }
}
