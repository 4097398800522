import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { frameTemplatesReducers } from './frame-templates.reducers';
import { EffectsModule } from '@ngrx/effects';
import { FramesTemplatesEffects } from './frame-templates.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('frameTemplates', frameTemplatesReducers),
    EffectsModule.forFeature([FramesTemplatesEffects]),
  ],
})
export class FrameTemplatesStoreModule {}
