import { Injectable } from '@angular/core';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  LineDefinition,
  PositionDefinition,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CoordinateHelper, CoordinateRectangle } from '@contrail/svg';
import { DocumentService } from '../../document.service';
import { CanvasElement } from '../../../canvas/elements/canvas-element';
import { CANVAS_COMPONENT_PADDING_T, CANVAS_COMPONENT_PADDING_X } from '../../../canvas/constants';
import { DocumentItemService } from '../../document-item/document-item.service';

interface AlignTarget {
  position: number;
  element: DocumentElement;
}

@Injectable({
  providedIn: 'root',
})
export class AlignDocumentElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType.startsWith('align')) {
        this.alignAllSelected(request?.sourceEvent.selectedElements, request.actionType);
      }
    });
  }

  public alignAllSelected(selectedElements: Array<DocumentElement>, alignType: string) {
    const selectedFrames = selectedElements.filter((element) => element.type === 'frame') || [];
    const frameElements: Map<string, string> = this.documentService.getFrameElements();
    const alignElementsInFrame =
      selectedFrames.length === 1 &&
      selectedElements.findIndex((element) =>
        element.type === 'frame' || frameElements.get(element.id) ? false : true,
      ) === -1;
    const elements = [];

    selectedElements.forEach((element) => {
      // Select elements for alignment if
      // 1. no frames are selected
      // 2. only 1 frame is selected and no other elements
      // 3. many frames/elements are selected and @element does not belong to a selected frame
      const frameId = frameElements.get(element.id);
      if (
        (!alignElementsInFrame && (!frameId || selectedFrames.findIndex((e) => e.id === frameId) === -1)) ||
        (alignElementsInFrame && element.type !== 'frame')
      ) {
        const clonedElement: DocumentElement = ObjectUtil.cloneDeep(element);
        this.setElementSizeAndPosition(clonedElement);
        elements.push(clonedElement);
      }
    });

    const adjustElementsInFrame = selectedFrames.length > 0 && !alignElementsInFrame;

    if (elements.length === 0) {
      return;
    }

    switch (alignType) {
      case 'align.horizontal_left': {
        this.alignSelectedElements(elements, 'x', 'min', adjustElementsInFrame);
        break;
      }
      case 'align.horizontal_center': {
        this.alignSelectedElements(elements, 'x', 'median', adjustElementsInFrame);
        break;
      }
      case 'align.horizontal_right': {
        this.alignSelectedElements(elements, 'x', 'max', adjustElementsInFrame);
        break;
      }
      case 'align.vertical_top': {
        this.alignSelectedElements(elements, 'y', 'min', adjustElementsInFrame);
        break;
      }
      case 'align.vertical_middle': {
        this.alignSelectedElements(elements, 'y', 'median', adjustElementsInFrame);
        break;
      }
      case 'align.vertical_bottom': {
        this.alignSelectedElements(elements, 'y', 'max', adjustElementsInFrame);
        break;
      }
    }
  }

  alignSelectedElements(
    elements: DocumentElement[],
    alignType: string,
    operation: string,
    adjustElementsInFrame: boolean,
  ) {
    const frames = this.documentService.getFrames();
    let alignTarget: AlignTarget;
    if (operation === 'max') {
      alignTarget = this.getMaxPosition(alignType, elements);
      elements = elements.filter((element) => element.id !== alignTarget.element.id); // do not update element being aligned to
    } else if (operation === 'min') {
      alignTarget = this.getMinPosition(alignType, elements);
      elements = elements.filter((element) => element.id !== alignTarget.element.id); // do not update element being aligned to
    } else if (operation === 'median') {
      alignTarget = this.getMaxPosition(alignType, elements);
      const maxPosition = alignTarget.position + alignTarget.element.size.height;
      alignTarget = this.getMinPosition(alignType, elements);
      const minPosition = alignTarget.position;
      alignTarget.position = Math.round((maxPosition + minPosition) / 2);
    }

    let changedElements = [];
    elements.forEach((element) => {
      const targetPosition: PositionDefinition = { x: element.position.x, y: element.position.y };
      const alignTargetDimension = alignType === 'x' ? alignTarget.element.size.width : alignTarget.element.size.height;
      const elementDimension = alignType === 'x' ? element.size.width : element.size.height;
      targetPosition[alignType] = alignTarget.position;
      if (operation === 'max') {
        targetPosition[alignType] = alignTarget.position + alignTargetDimension - elementDimension;
      } else if (operation === 'median') {
        targetPosition[alignType] = alignTarget.position - elementDimension / 2;
      }

      let newElement: any = { id: element.id, position: targetPosition };
      if (element.type === 'line') {
        // used to adjust line element's coordinates
        const lineAdjustment = { x: 0, y: 0 };
        lineAdjustment[alignType] = targetPosition[alignType] - element.position[alignType];
        newElement = {
          id: element.id,
          lineDefinition: {
            x1: element.lineDefinition.x1 + lineAdjustment.x,
            y1: element.lineDefinition.y1 + lineAdjustment.y,
            x2: element.lineDefinition.x2 + lineAdjustment.x,
            y2: element.lineDefinition.y2 + lineAdjustment.y,
          },
        };
      }

      if (DocumentItemService.isItemComponet(element)) {
        newElement.position.x = newElement.position.x + CANVAS_COMPONENT_PADDING_X;
        newElement.position.y = newElement.position.y + CANVAS_COMPONENT_PADDING_T;
      }

      if (element.type === 'group') {
        const innerElements = this.documentService.getAllElementsInGroup(element.id, true);
        changedElements = changedElements.concat(
          this.adjustInnerElements(innerElements, alignType, targetPosition, element),
        );
      } else {
        if (this.documentService?.documentRenderer?.isMask(element)) {
          const maskMembers: CanvasElement[] = this.documentService?.documentRenderer?.getMaskMembers(element.id);
          changedElements = changedElements.concat(
            this.adjustInnerElements(
              maskMembers?.map((e) => e.elementDefinition),
              alignType,
              targetPosition,
              element,
            ),
          );
        }
        changedElements.push(
          new DocumentAction(
            {
              elementId: newElement.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: newElement,
            },
            {
              elementId: newElement.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: element,
            },
          ),
        );
      }

      // Adjust all elements in frame
      if (element.type === 'frame' && adjustElementsInFrame) {
        const deltaX = newElement.position.x - element.position.x;
        const deltaY = newElement.position.y - element.position.y;
        frames.get(element.id)?.elements?.forEach(({ canvasElement }) => {
          const newElementInFrame: any = { id: canvasElement.id };
          if (canvasElement.elementDefinition.type === 'line') {
            newElementInFrame.lineDefinition.x1 = canvasElement.elementDefinition.lineDefinition.x1 + deltaX;
            newElementInFrame.lineDefinition.y1 = canvasElement.elementDefinition.lineDefinition.y1 + deltaY;
            newElementInFrame.lineDefinition.x2 = canvasElement.elementDefinition.lineDefinition.x2 + deltaX;
            newElementInFrame.lineDefinition.y2 = canvasElement.elementDefinition.lineDefinition.y2 + deltaY;
          } else {
            newElementInFrame.position = {
              x: canvasElement.elementDefinition.position.x + deltaX,
              y: canvasElement.elementDefinition.position.y + deltaY,
            };
          }
          changedElements.push(
            new DocumentAction(
              {
                elementId: newElementInFrame.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: newElementInFrame,
              },
              {
                elementId: newElementInFrame.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: canvasElement.elementDefinition,
              },
            ),
          );
        });
      }
    });
    this.documentService.handleDocumentActions(changedElements);
  }

  private adjustInnerElements(innerElements, alignType, targetPosition, element) {
    const adjustment = { x: 0, y: 0 };
    const actions = [];
    adjustment[alignType] = targetPosition[alignType] - element.position[alignType];
    innerElements?.forEach((innerElement) => {
      const newElement = {
        id: innerElement.id,
        position: {
          x: innerElement.position.x + adjustment.x,
          y: innerElement.position.y + adjustment.y,
        },
      };
      actions.push(
        new DocumentAction(
          {
            elementId: newElement.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: newElement,
          },
          {
            elementId: newElement.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: innerElement,
          },
        ),
      );
    });
    return actions;
  }

  getMaxPosition(alignType, elements: DocumentElement[]): AlignTarget {
    const targetElement = elements.reduce((prev, current) =>
      +prev.position[alignType] > +current.position[alignType] ? prev : current,
    );
    return {
      position: targetElement.position[alignType],
      element: targetElement,
    };
  }

  getMinPosition(alignType, elements: DocumentElement[]): AlignTarget {
    const targetElement = elements.reduce((prev, current) =>
      +prev.position[alignType] < +current.position[alignType] ? prev : current,
    );
    return {
      position: targetElement.position[alignType],
      element: targetElement,
    };
  }

  private setElementSizeAndPosition(element: DocumentElement) {
    const canvasElement: CanvasElement = this.documentService.documentRenderer.getCanvasElementById(element.id);
    const { x, y, width, height } = canvasElement.getBoundingClientRect();
    element.size = {
      width,
      height,
    };
    element.position = {
      x,
      y,
    };
  }
}
