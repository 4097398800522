import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasStarElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }): number[][] {
    return [
      [0.5 * width + x, 0 * height + y],
      [0.625 * width + x, 0.378 * height + y],
      [1 * width + x, 0.378 * height + y],
      [0.708 * width + x, 0.622 * height + y],
      [0.8125 * width + x, 1 * height + y],
      [0.5 * width + x, 0.778 * height + y],
      [0.1875 * width + x, 1 * height + y],
      [0.292 * width + x, 0.622 * height + y],
      [0 * width + x, 0.378 * height + y],
      [0.375 * width + x, 0.378 * height + y],
      [0.5 * width + x, 0 * height + y],
    ];
  }
}
