import { Injectable } from '@angular/core';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { Entities } from '@contrail/sdk';
import { map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ItemData } from '@common/item-data/item-data';
import { ProjectItemService } from '@common/projects/project-item.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { BehaviorSubject } from 'rxjs';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { ItemDocument } from './item';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private sourceAssortmentId: string;
  private currentProjectId: string;
  private sourceAssortmentItemData: ItemData[];
  private currentEntityId = null;
  private itemContextFeatureActive = false;

  public itemChooserCriteria$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private store: Store<RootStoreState.State>,
    private projectItemService: ProjectItemService,
  ) {
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags) => {
          this.itemContextFeatureActive = !!flags.find((x) => x.featureName === Feature.ITEM_CONTEXT);
        }),
      )
      .subscribe();

    this.store
      .select(AssortmentsSelectors.sourceAssortmentId)
      .pipe(
        tap((sourceAssortmentId) => {
          if (!sourceAssortmentId) {
            return;
          }
          this.sourceAssortmentId = sourceAssortmentId;
        }),
      )
      .subscribe();

    this.store
      .select(AssortmentsSelectors.sourceAssortmentItemData)
      .pipe(
        tap((sourceAssortmentItemData) => {
          if (!sourceAssortmentItemData) {
            return;
          }
          this.sourceAssortmentItemData = sourceAssortmentItemData;
        }),
      )
      .subscribe();

    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();
  }

  public checkItemChooserCriteria(id) {
    this.currentEntityId = id;
    const key = `itemChooserCriteria_` + id;
    const chooserCriteria = JSON.parse(localStorage.getItem(key)) || null;
    this.itemChooserCriteria$.next(chooserCriteria);
  }

  public saveItemChooserCriteria(chooserCriteria) {
    this.itemChooserCriteria$.next(chooserCriteria);
    const key = `itemChooserCriteria_` + this.currentEntityId;
    localStorage.setItem(key, JSON.stringify(chooserCriteria));
  }

  /**
   * Set item data for @itemDataArr relative to current source assortment and project
   * @param itemDataArr
   * @returns
   */
  public async setItemDataRelativeToSource(itemDataArr: ItemData[]): Promise<ItemData[]> {
    if (itemDataArr?.length === 0) {
      return itemDataArr;
    }

    const firstItemData = itemDataArr[0];
    if (this.sourceAssortmentId && firstItemData?.assortmentItem?.assortmentId === this.sourceAssortmentId) {
      return itemDataArr;
    }

    const ids = itemDataArr.map((itemData) => itemData.id);
    const inSourceItems = this.getSourceAssortmentItems(ids);
    return await this.createItemDataRelativeToSource(ids, inSourceItems, itemDataArr);
  }

  /**
   * Get item data relative to source for @itemIds
   * If @itemId is in source - use item data from source assortment item data.
   * If @itemId is not in source - query /items?ids= to get item data and upsert missing project items
   * Find which items from @itemIds are in source
   * @param itemIds
   * @returns
   */
  public async getItemDataAndSetRelativeToSource(itemIds): Promise<ItemData[]> {
    const inSourceItems = this.getSourceAssortmentItems(itemIds);
    const notInSourceItemIds = itemIds.filter((itemId) => inSourceItems?.findIndex((s) => s.id === itemId) === -1);
    const notInSourceItems =
      notInSourceItemIds?.length > 0 ? await this.getItems([...new Set(notInSourceItemIds)]) : [];
    return await this.createItemDataRelativeToSource(itemIds, inSourceItems, notInSourceItems);
  }

  /**
   * Create item data relative to source for @itemIds
   * If @itemId is in source - use item data from @inSourceItems (assortment-item entityType)
   * If @itemId is not in source - upsert project items (unless there is already project item
   * for this @itemId in @notInSourceItems ) and create new ItemData() from item data
   * in @notInSourceItems (item entityType)
   * @param itemIds
   * @param inSourceItems - source assortment item data for @itemIds
   * @param notInSourceItems - item data for @itemIds that are not in source assortment
   * @returns
   */
  private async createItemDataRelativeToSource(
    itemIds: string[],
    inSourceItems: ItemData[] = [],
    notInSourceItems: ItemData[] = [],
  ): Promise<ItemData[]> {
    console.log(
      'ItemService.createItemDataRelativeToSource:',
      itemIds,
      inSourceItems,
      notInSourceItems,
      this.currentProjectId,
    );
    let projectItems = [];
    if (!this.itemContextFeatureActive) {
      const missingProjectItemIds = [
        ...new Set(
          itemIds.filter((itemId) => {
            const sourceItemData = inSourceItems.find((s) => s.id === itemId);
            return !(
              sourceItemData?.projectItem ||
              notInSourceItems?.find((item) => item.id === itemId)?.projectItem?.projectId === this.currentProjectId
            );
          }),
        ),
      ];
      console.log('ItemService.createItemDataRelativeToSource: missing project item ids', missingProjectItemIds);

      if (missingProjectItemIds?.length > 0) {
        const batchUpsertProjectItems = await this.projectItemService.batchUpsert(
          missingProjectItemIds.map((itemId) => ({
            id: itemId,
            changes: {},
          })),
        );
        if (batchUpsertProjectItems?.errors?.length === 0 && batchUpsertProjectItems?.updatedProjectItems?.length > 0) {
          projectItems = batchUpsertProjectItems.updatedProjectItems;
        }
      }
      console.log('ItemService.createItemDataRelativeToSource: upserted project items', projectItems);
    }

    const items = [];

    for (let i = 0; i < itemIds.length; i++) {
      const itemId = itemIds[i];
      const sourceItemData = inSourceItems.find((s) => s.id === itemId);
      if (sourceItemData) {
        items.push(sourceItemData);
      } else {
        if (!this.itemContextFeatureActive) {
          let itemData = notInSourceItems.find((n) => n.id === itemId);
          if (itemData) {
            if (itemData?.projectItem?.projectId === this.currentProjectId) {
              itemData.projectItem = {
                ...itemData.projectItem,
              };
            }
            const projectItem = projectItems.find((p) => p.itemId === itemId);
            if (projectItem) {
              itemData.projectItem = projectItem;
            }
            itemData = new ItemData(itemData);
            items.push(itemData);
          }
        }
      }
    }
    console.log('ItemService.createItemDataRelativeToSource: items result', items);
    return items;
  }

  /**
   * Get ItemData for @itemId
   * @param itemId
   * @returns
   */
  public async getItemData(itemId): Promise<ItemData> {
    let itemData = await this.getSourceAssortmentItem(itemId);
    if (!itemData) {
      itemData = await this.getItem(itemId);
      if (itemData) {
        const projectItem = await this.projectItemService.getProjectItem(itemId);
        if (projectItem && !projectItem.isInactive) {
          itemData.projectItem = projectItem;
        }
        itemData = new ItemData(itemData);
      }
    }

    return itemData;
  }

  public async getItem(itemId) {
    return await new Entities().get({ entityName: 'item', id: itemId });
  }

  public async getItems(ids, relations = []) {
    return await new Entities().get({ entityName: 'item', criteria: { ids }, relations });
  }

  public getSourceAssortmentItems(ids: string[]): ItemData[] {
    return this.sourceAssortmentItemData?.filter((itemData) => ids.includes(itemData.id));
  }

  public async getSourceAssortmentItem(itemId): Promise<ItemData> {
    let item;
    this.store
      .select(AssortmentsSelectors.selectSourceItem(itemId))
      .pipe(
        take(1),
        map((i) => (item = i)),
      )
      .subscribe();
    return item;
  }

  public getItemByFamily(itemFamilyId): Promise<ItemData> {
    let item;
    this.store
      .select(AssortmentsSelectors.selectBackingAssortmentItemByFamily(itemFamilyId))
      .pipe(
        take(1),
        map((i) => (item = i)),
      )
      .subscribe();
    return item;
  }

  public async getItemDocuments(itemId: string): Promise<ItemDocument[]> {
    const itemDocuments = await new Entities().get({
      entityName: 'item',
      id: itemId,
      suffix: 'documents',
    });

    if (itemDocuments?.documentsDownloadURL) {
      const response = await fetch(itemDocuments.documentsDownloadURL);
      const documents = await response.json();
      return documents;
    }

    return itemDocuments?.documents || [];
  }
}
