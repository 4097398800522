import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-list-type',
  templateUrl: './property-configurator-list-type.component.html',
  styleUrls: ['./property-configurator-list-type.component.scss'],
})
export class PropertyConfiguratorListTypeComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  public listType = null;
  public multiSelected = false;

  constructor() {}

  ngOnChanges(): void {
    this.listType = this.currentValue;
  }

  setValue(value) {
    let changes = { type: 'listType', value: '' };
    if (this.listType === '' || this.listType !== value) {
      this.listType = value;
      changes = { type: 'listType', value: this.listType };
    } else {
      this.listType = '';
    }
    this.valueChange.emit(changes);
  }
}
