<div class="header flex-between-center">
  <div class="flex justify-start">
    <div class="title mr-1">All Frames</div>
  </div>
  <div class="flex space-x-2">
    <div class="cursor-pointer flex-center" (click)="handleClose()" data-test="frame-side-close">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
<div class="flex-between-center mx-2" *ngIf="(editMode$ | async) === 'EDIT'">
  <div class="flex items-center" data-test="frame-sortby">
    <p class="text-sm mr-2">Sort by:</p>
    <p class="flex-center cursor-pointer" [matMenuTriggerFor]="sortByMenu">
      <span class="text-primary">{{ sortByControl.value?.label }}</span>
      <span class="material-icons">arrow_drop_down</span>
    </p>
  </div>
  <mat-menu #sortByMenu="matMenu" yPosition="below" xPosition="after" class="menu-sm">
    <button
      mat-menu-item
      *ngFor="let option of sortOptions"
      (click)="setSortByControl(option)"
      [attr.data-test]="'frame-sort-' + option.property"
    >
      {{ option?.label }}
    </button>
  </mat-menu>
  <button
    mat-button
    color="primary"
    (click)="applyChanges()"
    [disabled]="keepSortedControl.value"
    data-test="frame-sort-apply"
  >
    Apply
  </button>
</div>
<mat-slide-toggle
  *ngIf="(editMode$ | async) === 'EDIT'"
  labelPosition="before"
  class="mx-2 mb-2"
  color="primary"
  [checked]="keepSortedControl.value"
  [formControl]="keepSortedControl"
>
  <p class="text-sm w-64 mr-2">Keep sorted</p>
</mat-slide-toggle>

<ng-container *ngIf="frames?.length; else noFrames">
  <div
    cdkScrollable
    class="frames px-1"
    [style.height]="(editMode$ | async) === 'EDIT' ? 'calc(100% - 200px)' : 'calc(100% - 130px)'"
  >
    <div class="flex items-center mx-1 mb-4 rounded-md border-2">
      <span class="material-icons cursor-pointer text-black54 font-medium ml-2">search</span>
      <input [(ngModel)]="searchKey" class="p-3 w-full" placeholder="Search frames" />
    </div>

    <div
      class="frames-list mt-3 px-1 narrow-scroll"
      cdkDropList
      [cdkDropListAutoScrollStep]="10"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListDisabled]="keepSortedControl.value"
    >
      <div
        *ngFor="let f of frames | searchKey: searchKey : 'name' : 'frame'; index as idx; trackBy: trackByFrame"
        class="frame-view border rounded-md text-lg mb-2"
        [id]="f?.id"
        [class.border-accent]="(selectedIdx$ | async) === idx"
        [class.bg-blue-50]="selectedFrameIds.includes(f.id)"
        [class.opacity-0]="dragging && selectedFrameIds.includes(f.id)"
        cdkDrag
        [cdkDragDisabled]="(editMode$ | async) !== 'EDIT'"
        (cdkDragStarted)="cdkDragStarted($event, f, idx)"
        (cdkDragEnded)="cdkDragEnded($event)"
        (click)="selectFrame(f, idx, $event)"
      >
        <span class="frame-index" [class.long]="idx < 9999" [class.medium]="idx < 999" [class.short]="idx < 99">
          {{ idx + 1 }}.&nbsp;
        </span>

        <ng-container *ngTemplateOutlet="frameName; context: { frame: f }"></ng-container>
        <div *cdkDragPreview class="dragPreviewContainer">
          <div id="cdkDragPreviewDiv"></div>
          <div *ngIf="selectedFrameIds.length > 1" class="ghost-shadow shadow-one"></div>
          <div *ngIf="selectedFrameIds.length > 2" class="ghost-shadow shadow-two"></div>
        </div>

        <button
          class="btn-32 !ml-auto"
          data-test="frame-list-more"
          *ngIf="(editMode$ | async) === 'EDIT'"
          mat-button
          [disabled]="f?.frame?.isLocked"
          matTooltip="Locked"
          [matTooltipDisabled]="!f?.frame?.isLocked"
          matTooltipPosition="left"
          matTooltipShowDelay="500"
          [matMenuTriggerFor]="contextMenu"
          [matMenuTriggerData]="{ frame: f, index: idx }"
          (click)="$event.stopPropagation()"
        >
          <span class="material-icons-outlined opacity-60"> more_vert </span>
        </button>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="grid grid-cols-2 gap-x-4 items-center justify-around m-4">
    <button mat-raised-button color="primary" data-test="present-button" (click)="presentFrame(frames)">
      <mat-icon class="mr-2">play_circle_outline</mat-icon> PRESENT
    </button>

    <app-board-export [selectedIds]="selectedFrameIds"></app-board-export>
  </div>
</ng-container>

<mat-menu #contextMenu="matMenu" xPosition="before" class="menu-sm">
  <ng-template matMenuContent let-f="frame">
    <button
      mat-menu-item
      (click)="renameFrameMode(f.frame)"
      [disabled]="f?.frame?.isLocked"
      data-test="frame-context-rename"
    >
      <mat-icon>edit</mat-icon> <span>Rename</span>
    </button>
    <button
      mat-menu-item
      (click)="deleteFrame(f.frame)"
      [disabled]="f?.frame?.isLocked"
      data-test="frame-context-delete"
    >
      <mat-icon>delete</mat-icon> <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #frameName let-f="frame">
  <span
    *ngIf="renameForm?.id !== f?.frame?.id"
    class="line-ellipsis"
    appOverflowTooltip
    matTooltip=""
    matTooltipPosition="left"
    data-test="frame-name"
    (dblclick)="renameFrameMode(f.frame)"
  >
    {{ f.frame.name }}
  </span>

  <ng-container *ngIf="renameForm?.id === f?.frame?.id">
    <input
      #renameInput
      class="border-b-[1px] border-accent"
      [(ngModel)]="renameForm.name"
      (blur)="updateName()"
      (keyup)="updateName($event)"
      data-test="frame-rename-input"
    />
  </ng-container>
</ng-template>

<ng-template #noFrames>
  <div class="flex-center h-4/5">
    <app-empty-placeholder [title]="'No frames'" [icon]="'item-placeholder'"></app-empty-placeholder>
  </div>
</ng-template>
