import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { Item } from 'src/app/common/assortments/item';
import { ItemData } from 'src/app/common/item-data/item-data';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { FilterUtil } from 'src/app/common/util/filter-util';
import { RootStoreState } from 'src/app/root-store';
import { BoardsActions, BoardsSelectors } from 'src/app/boards/boards-store';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { DocumentActions } from '../../document/document-store';
import { SideMenuOverlay } from '../../document/document-store/document.state';
import { DocumentElement } from '@contrail/documents';
import { DocumentService } from '../../document/document.service';
import { DocumentItemService } from '../../document/document-item/document-item.service';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class CollectionStatusMessageService {
  messages$: Observable<Array<CollectionStatusMessage>>;
  showSourceAssortmentWarning$: Observable<boolean>;
  private elements: Array<DocumentElement>;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    console.log(`CollectionStatusMessageService called`);
    this.initState();
  }

  private initState() {
    combineLatest([
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(AssortmentsSelectors.backingAssortmentItems),
      this.store.select(AuthSelectors.selectAuthContext),
    ])
      .pipe(
        debounceTime(1000),
        tap(([sourceItemData, backingAssortmentItems, auth]: [Array<ItemData>, Array<Item>, any]) => {
          this.validateVsSourceAssortment(backingAssortmentItems, sourceItemData, auth?.currentOrg?.orgSlug);
        }),
      )
      .subscribe();
    this.documentService.documentElements.subscribe((elements) => (this.elements = elements));
    this.messages$ = this.store.select(BoardsSelectors.collectionStatusMessages) as Observable<
      Array<CollectionStatusMessage>
    >;

    this.showSourceAssortmentWarning$ = this.store.select(
      BoardsSelectors.showSourceAssortmentWarning,
    ) as Observable<boolean>;
  }

  public containsItemOption(sourceAssortmentItemData: Array<ItemData>, entity, orgSlug) {
    const found = sourceAssortmentItemData.find((itemData) => {
      return (
        (entity.item?.roles?.includes('option')
          ? itemData['id']
          : ObjectUtil.getBySlugs(itemData, 'item', 'itemFamilyId')) === entity.itemId
      );
    });
    if (FilterUtil.isDropped(found, orgSlug)) {
      return false;
    }
    return !!found;
  }

  public validateVsSourceAssortment(collection: Array<Item>, sourceItemData: Array<ItemData>, orgSlug = '') {
    this.store.dispatch(BoardsActions.clearStatusMessages());
    if (!sourceItemData) {
      return [];
    }
    collection.forEach((entity: Item) => {
      if (entity.itemId && !this.containsItemOption(sourceItemData, entity, orgSlug)) {
        const entityName = entity.item.name + (entity.item.optionName ? ' / ' + entity.item.optionName : '');
        this.store.dispatch(
          BoardsActions.addStatusMessage({
            message: {
              id: entity.itemId + '_missing_in_source',
              type: 'WARNING',
              message: `Item is missing from this document's source assortment.`,
              collectionElementId: entity.itemId,
              entityName,
            },
          }),
        );
      }
    });
  }

  showAlertMessages() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Warnings';
    overlay.slug = 'messages';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  setStatusMessageElement(message) {
    const selectedElement = this.getDocumentElement(message.statusMessageElement);
    if (selectedElement) {
      this.navigateToSelectedComment(selectedElement);
    }
  }

  toggleShowWarning(showSourceAssortmentWarning) {
    this.store.dispatch(BoardsActions.setShowSourceAssortmentWarning(showSourceAssortmentWarning));
  }

  private navigateToSelectedComment(documentElement?: DocumentElement) {
    this.store.dispatch(
      DocumentActions.navigateToPosition({
        position: {
          x: (documentElement ? documentElement.position.x : 0) + 180,
          y: documentElement ? documentElement.position.y : 0,
        },
      }),
    );
  }

  private getDocumentElement(itemId?: string): DocumentElement {
    const componentElement = this.elements
      .filter(DocumentItemService.isItemComponet)
      .find((element) => element.modelBindings.item === 'item:' + itemId);
    return componentElement;
  }
}
