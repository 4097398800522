import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent implements OnInit {
  @Input() navOptions: any;
  currentRoute: string;
  constructor(private router: Router) {
    this.currentRoute = router.url;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  isSelected(route) {
    if (!this.currentRoute) {
      return false;
    }
    return this.currentRoute.indexOf(route) > -1;
  }
}
