import { Item } from '@contrail/entity-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface DocumentModelEntity {
  id?: string;
  itemFamilyId?: string;
  itemOptionId?: string;
  itemFamily?: Item;
  itemOption?: Item;
  [key: string]: any;
}

export interface DocumentModelEntitiesState extends EntityState<DocumentModelEntity> {}
export const documentModelEntityAdapter: EntityAdapter<DocumentModelEntity> = createEntityAdapter<DocumentModelEntity>(
  {},
);

export const documentModelEntitiesInitialState = documentModelEntityAdapter.getInitialState({});
