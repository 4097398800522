import { CanvasDocument } from '../../canvas-document';

export class MaskBoxRenderer {
  private readonly STROKE_WIDTH = 2;
  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw highlight box for target element
   */
  public draw() {
    const isMaskEditing =
      this.canvasDocument?.interactionHandler?.canvasEventHandlers?.maskElementEventHandler?.isEditingMask;
    if (isMaskEditing) {
      const maskId = isMaskEditing as string;
      const mask = this.canvasDocument.state.maskState.getMask(maskId);
      const maskMembers = this.canvasDocument.state.maskState.getMaskMembers(maskId)?.concat(mask?.element);
      if (!maskMembers?.length || maskMembers?.length < 2) {
        return;
      }
      const { x, y, width, height } = this.canvasDocument.state.getCommonBounds(maskMembers);
      const ctx = this.canvasDocument.canvasDisplay.context;

      ctx.save();
      ctx.beginPath();

      const lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.rect(x - lineWidth * 0.5, y - lineWidth * 0.5, width + lineWidth, height + lineWidth);

      ctx.strokeStyle = '#d3e6fb';
      ctx.lineWidth = lineWidth;
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
  }
}
