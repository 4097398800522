<div class="flex flex-col space-y-3" *ngIf="componentType === 'ITEM' && thumbnail">
  <div *ngIf="!multipleElements && content?.length > 0" class="thumbnail-container">
    <div>
      <mat-icon [ngClass]="firstVisibleIndex !== 0 ? 'slider active' : 'slider'" (click)="slideLeft()">
        chevron_left
      </mat-icon>
    </div>
    <div class="thumbnails">
      <div
        class="item"
        [ngStyle]="{ transform: 'translateX(' + translateValue + 'px)' }"
        [ngClass]="{ active: selectedIndex === i }"
        (click)="chooseContent(i)"
        *ngFor="let contentObj of content; let i = index"
        [attr.data-index]="i"
      >
        <span class="image">
          <span class="m-auto relative">
            <img
              [attr.src]="
                (contentObj ? contentObj?.smallViewableUrl || contentObj?.primaryFileUrl : '') | secureImage | async
              "
              (load)="imageLoaded = true"
              style="width: 50px"
            />
            <span *ngIf="imageLoaded && primaryViewableId === contentObj?.id" class="primary-content-indicator"></span>
          </span>
        </span>
      </div>
    </div>
    <div>
      <mat-icon
        [ngClass]="(content && content.length - firstVisibleIndex) > 3 ? 'slider active' : 'slider'"
        (click)="slideRight()"
      >
        chevron_right
      </mat-icon>
    </div>
  </div>
  <div class="h-8 flex items-center">
    <mat-slide-toggle
      class="fill-image-toggle text-black60"
      color="primary"
      labelPosition="before"
      [checked]="fillImage"
      (change)="setBackgroundSize($event)"
      data-test="fill-image"
    >
      Fill area
    </mat-slide-toggle>
  </div>
  <div class="w-full h-8 flex items-center justify-between text-black60">
    <div>Zoom (%)</div>
    <input
      data-test="thumbnail-zoom-input"
      class="w-16 border rounded-[5px] p-1"
      type="number"
      [(ngModel)]="thumbnailScale"
      (change)="handleImageZoomChange($event)"
    />
  </div>
  <div class="w-full h-8 flex items-center justify-between text-black60">
    <div>Ratio</div>
    <mat-form-field class="w-36">
      <mat-select [value]="thumbnailRatio" (selectionChange)="setThumbnailRatio($event)" data-test="thumbnail-ratio">
        <mat-option *ngFor="let ratio of ratios" [value]="ratio.key">
          {{ ratio.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="slider-container">
    <div class="text-black60 h-8 flex justify-between items-center">
      <div>Item card size</div>
      <div>{{ thumbnail.size.width | number: '1.0-0' }}px</div>
    </div>
    <mat-slider
      color="primary"
      class="slider"
      max="400"
      min="25"
      step="5"
      [value]="thumbnail.size.width"
      (change)="handleImageSizeChange($event)"
    >
    </mat-slider>
  </div>
</div>

<div class="flex flex-col space-y-3" *ngIf="componentType === 'COLOR' && thumbnail">
  <div class="slider-container">
    <div class="text-black60 h-8 flex justify-between items-center">
      <div>Color card size</div>
      <div>{{ thumbnail.size.width | number: '1.0-0' }}px</div>
    </div>
    <mat-slider
      color="primary"
      class="slider"
      max="400"
      min="25"
      step="5"
      [value]="thumbnail.size.width"
      (change)="handleImageSizeChange($event)"
    >
    </mat-slider>
  </div>
</div>
