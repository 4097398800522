import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Assortment } from '../../../assortments/assortments-store/assortments.state';
import { AssortmentHistoryItem } from '@common/assortment-history/assortment-history.interfaces';

@Component({
  selector: 'app-assortment-aggregate-history-chart',
  templateUrl: './assortment-aggregate-history-chart.component.html',
  styleUrls: ['./assortment-aggregate-history-chart.component.scss'],
})
export class AssortmentAggregateHistoryChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() assortment: Assortment;
  @Input() history: Array<AssortmentHistoryItem>;
  @Input() typeProperty: TypeProperty;
  @Input() aggregateValue: string = 'total';
  @ViewChild('chart') chartElement: ElementRef;
  constructor() {}

  public chartOptions;
  public chartType: any = 'LineChart';
  public chartData = [];

  ngAfterViewInit() {
    this.adjustChartDimensions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustChartDimensions();
  }

  public adjustChartDimensions() {
    var width = this.chartElement.nativeElement.offsetWidth;
    var height = this.chartElement.nativeElement.offsetHeight;
    this.chartOptions = null;
    setTimeout(() => {
      this.setChartOptions({ height, width });
    }, 1);
  }

  private setChartOptions(options) {
    // https://developers.google.com/chart/interactive/docs/gallery/areachart
    this.chartOptions = {
      chart: {},
      colors: ['#2085ec', '#72b4eb', '#0a417a', '#8464a0', '#cea9bc'],
      legend: 'none',
      backgroundColor: { fill: 'none' },
      pointSize: 0,
      //pointsVisible: true,
      areaOpacity: 0.8,
      focusTarget: 'datum', // 'datum'
      curveType: 'function',
      animation: {
        easing: 'in',
        startup: true,
        duration: 100,
      },
      chartArea: { width: '85%', height: '90%' },
      lineWidth: 0,
      vAxis: {
        format: 'short',
        //textPosition: 'none',
        gridlines: {
          //color: 'transparent'
        },
        minorGridlines: { count: 0 },
        minValue: 0,
      },
      tooltip: { textStyle: { fontSize: 12 }, showColorCode: true },
      series: {
        0: {
          targetAxisIndex: 0,
          type: 'area',
          areaOpacity: 0.8,
          annotations: {
            textStyle: {
              fontSize: 20,
              color: 'red',
            },
          },
        },
      },
      hAxis: {
        textPosition: 'none',
        gridlines: {
          color: 'transparent',
        },
      },
    };
  }

  ngOnInit(): void {}
  ngOnChanges() {
    this.computeChartData();
  }
  computeChartData() {
    if (!this.history || !this.typeProperty) {
      return;
    }
    console.log('computeChartData: ', this.typeProperty);
    const data = [];
    this.history.forEach((hi) => {
      const summary = hi.summary;
      if (!summary) {
        return;
      }
      const date = new Date(hi.createdOn);
      const aggregate = summary.aggregates[this.typeProperty?.slug];
      if (aggregate) {
        const value = aggregate[this.aggregateValue];
        if (value) {
          data.push([date, value]);
        }
      }
    });
    console.log('chartData: ', data);
    this.chartData = data;
  }
}
