<div #widgetTray class="widget-tray z-9999 px-1" (click)="handleClick($event)">
  <div class="widgets-list p-[8px]" *ngIf="sourceEvent">
    <app-property-configurator-font-size
      matTooltip="Font size"
      (valueChange)="handleDelayedValueChange($event)"
      [currentValue]="size"
      [sizes]="sizes"
      [minValue]="15"
      [maxValue]="40"
    >
    </app-property-configurator-font-size>
  </div>
</div>
