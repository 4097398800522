import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../canvas-document';
import { CanvasElement } from '../elements/canvas-element';
import { CoordinateBox } from '../coordinate-box';
import { CanvasUtil } from '../canvas-util';
import { CanvasFrameElement } from '../elements/frame/canvas-frame-element';
import { CanvasMaskState } from './canvas-mask-state';

export interface Frame {
  id: string;
  box: CoordinateBox;
  index: number;
  element: CanvasFrameElement;
  elements: Array<{
    canvasElement: CanvasElement;
    index: number;
  }>;
}

export class CanvasFrameState {
  public frames: Map<string, Frame> = new Map(); // <frameId, Frame>
  public frameElements: Map<string, string> = new Map(); // <elementId, frameId>

  constructor(public canvasDocument: CanvasDocument) {}

  /**
   * Determine if @svgElements belongs on any frame in @frames and add it to
   * @frames and @frameElements.
   * @svgElement is on frame when it's coordinate box is at least half on top of the
   * frame's coordinate box AND it's @index is higher than frame's index.
   * Additionally, select the element if the frame was selected so
   * it is moved when the frame is moved.
   * @param svgElement
   * @param index
   * @param frames
   * @param frameElements
   * @returns
   */
  public addElementOnFrame(canvasElement: CanvasElement, index: number, frame?: Frame): Frame {
    if (this.frames?.size === 0) {
      return;
    }
    if (canvasElement.isInMask) {
      return;
    } // do not consider mask members since they are not technically visible
    const { x, y, width, height } = canvasElement.getBoundingClientRect();
    const elementBox: CoordinateBox = {
      x,
      y,
      width,
      height,
      left: x + width * 0.5,
      right: x + width * 0.5,
      top: y + height * 0.5,
      bottom: y + height * 0.5,
    };
    const overlappedFrame = frame
      ? frame
      : (
          [...this.frames.values()].filter((frame) => {
            return index > frame.index && CanvasUtil.isInBox(frame.box, elementBox);
          }) || []
        ).pop();

    if (overlappedFrame) {
      // if a group element contains a frame element, the frame might consider that the group is in it due to its proximity
      if (
        canvasElement.elementDefinition.type === 'group' &&
        canvasElement.elementDefinition.elementIds.includes(overlappedFrame.id)
      ) {
        return;
      }
      this.addFrameMember(overlappedFrame.id, canvasElement, index);
    }

    return overlappedFrame;
  }

  public addFrameMember(frameId: string, canvasElement: CanvasElement, index: number) {
    const frame = this.frames.get(frameId);
    if (frame) {
      this.frames.set(frameId, {
        ...frame,
        elements: [
          ...frame.elements,
          {
            canvasElement,
            index,
          },
        ],
      });
      this.frameElements.set(canvasElement.id, frameId);

      const canvasFrameElement = this.canvasDocument.state.getElementById(frameId);
      if (canvasFrameElement && canvasFrameElement.isSelected) {
        this.canvasDocument.interactionHandler.selectionHandler.setSelected(canvasElement);
        canvasElement.isInFrame = frame.id;
      }
      canvasElement.isInFrameMask = canvasFrameElement?.elementDefinition?.clipContent ? frame.id : false;

      if (CanvasMaskState.isMask(canvasElement.elementDefinition)) {
        canvasElement.elementDefinition.elementIds.forEach((elementId) => {
          const maskMember = this.canvasDocument.getCanvasElementById(elementId);
          if (
            maskMember &&
            CanvasMaskState.isValidMaskMember(maskMember?.elementDefinition) &&
            !this.frameElements.get(maskMember.id)
          ) {
            const index = this.canvasDocument?.state?.getElementIndex(maskMember.id);
            this.canvasDocument?.state?.frameState?.addFrameMember(frameId, maskMember, index);
          }
        });
      }
      // console.log('Adding frame member', frameId, canvasElement, index)
    }
  }

  /**
   * Add new frame on frame creation or copy/paste.
   */
  public addNewFrame(frame: CanvasElement, index) {
    const box: CoordinateBox = {
      x: frame.elementDefinition.position.x,
      y: frame.elementDefinition.position.y,
      width: frame.elementDefinition.size.width,
      height: frame.elementDefinition.size.height,
      left: frame.elementDefinition.position.x,
      right: frame.elementDefinition.position.x + frame.elementDefinition.size.width,
      top: frame.elementDefinition.position.y,
      bottom: frame.elementDefinition.position.y + frame.elementDefinition.size.height,
    };
    this.frames.set(frame.id, {
      id: frame.id,
      index,
      box,
      element: frame as CanvasFrameElement,
      elements: [],
    });
    //console.log('Add new frame: ', frame.id, this.frames);
  }

  /**
   * Update frame coordinate box when it's changed.
   * @param documentElement
   */
  public updateFrameBox(documentElement: DocumentElement) {
    const frame = this.frames?.get(documentElement.id);
    if (frame) {
      const box: CoordinateBox = {
        x: documentElement.position.x,
        y: documentElement.position.y,
        width: documentElement.size.width,
        height: documentElement.size.height,
        left: documentElement.position.x,
        right: documentElement.position.x + documentElement.size.width,
        top: documentElement.position.y,
        bottom: documentElement.position.y + documentElement.size.height,
      };
      this.frames.set(frame.id, {
        ...frame,
        box,
      });
      //console.log('Updated frame: ', frame.id, this.frames);
    }
  }

  /**
   * When a frame is resized, recalculate elements that belong on the frame.
   * @param documentElement
   */
  public updateFrameElements(documentElement: DocumentElement) {
    const frame = this.frames?.get(documentElement.id);
    if (frame) {
      const canvasFrameElement = this.canvasDocument.state.getElementById(frame.id);
      if (canvasFrameElement && canvasFrameElement.isSelected) {
        this.canvasDocument.interactionHandler.selectionHandler
          .getSelectedCanvasElements()
          .forEach((selectedElement) => {
            if (frame.elements.findIndex((element) => element.canvasElement.id === selectedElement.id) !== -1) {
              this.canvasDocument.interactionHandler.selectionHandler.setDeselected(selectedElement);
              selectedElement.isInFrameMask = false;
            }
          });
      }

      frame?.elements.forEach((element) => {
        this.frameElements.delete(element.canvasElement.id);
      });
      this.frames.set(frame.id, {
        ...frame,
        elements: [],
      });

      for (const [index, documentElement] of this.canvasDocument.documentDefinition.elements.entries()) {
        if (documentElement.type !== 'frame') {
          const canvasElement = this.canvasDocument.state.getElementById(documentElement.id);
          // if a group element contains a frame element, the frame might consider that the group is in it due to its proximity
          if (documentElement.type === 'group' && documentElement.elementIds.includes(frame.id)) {
            continue;
          }
          if (canvasElement?.isInMask) {
            continue;
          }
          if (canvasElement) {
            const { x, y, width, height } = canvasElement.getBoundingClientRect();
            const elementBox: CoordinateBox = {
              x,
              y,
              width,
              height,
              left: x + width * 0.5,
              right: x + width * 0.5,
              top: y + height * 0.5,
              bottom: y + height * 0.5,
            };
            if (
              index > frame.index &&
              CanvasUtil.isInBox(frame.box, elementBox) &&
              this.frameElements.get(canvasElement.id) == undefined
            ) {
              this.addFrameMember(frame.id, canvasElement, index);
            }
          }
        }
      }
    }
    //console.log('Updated frame elements: ', this.frames, this.frameElements)
  }

  /**
   * Get frame for @element if it belongs on a frame or return frame if @element is a frame itself.
   * @param element
   */
  public getFrameForElement(element: DocumentElement): Frame {
    if (element.type === 'frame') {
      return this.frames.get(element.id) || null;
    }
    const frameId = this.frameElements.get(element.id);
    return frameId ? this.frames.get(frameId) || null : null;
  }

  /**
   * Remove element from frame if it no longer belongs on it.
   * @param documentElement
   */
  public updateElement(documentElement: DocumentElement): Boolean {
    const frameId = this.frameElements.get(documentElement.id);
    let removed = false;
    if (frameId) {
      const frame = this.frames.get(frameId);
      const canvasElement = this.canvasDocument.state.getElementById(documentElement.id);
      if (canvasElement && !canvasElement?.isInMask) {
        const { x, y, width, height } = canvasElement.getBoundingClientRect();
        const elementBox: CoordinateBox = {
          x,
          y,
          width,
          height,
          left: x + width * 0.5,
          right: x + width * 0.5,
          top: y + height * 0.5,
          bottom: y + height * 0.5,
        };
        if (!CanvasUtil.isInBox(frame.box, elementBox)) {
          removed = true;
          this.removeElementFromFrame(canvasElement);
        }
      }
    }
    return removed;
  }

  /**
   * Remove element (frame or not) from frames and frameElements.
   * @param elementId
   * @returns
   */
  public removeElementFromFrame(canvasElement: CanvasElement) {
    const frameId = this.frameElements.get(canvasElement.id);
    if (frameId) {
      const frame = this.frames.get(frameId);
      const canvasFrameElement = this.canvasDocument.state.getElementById(frame.id);
      this.frameElements.delete(canvasElement.id);
      this.frames.set(frameId, {
        ...frame,
        elements: frame.elements.filter((element) => element.canvasElement.id !== canvasElement.id),
      });
      if (canvasFrameElement && canvasFrameElement.isSelected) {
        this.canvasDocument.interactionHandler.selectionHandler.setDeselected(canvasElement);
      }
      canvasElement.isInFrameMask = false;
      // console.log('Removing element: ', canvasElement.id, ', from frame: ', frameId, this.frames);

      if (canvasElement.isMask) {
        canvasElement?.elementDefinition?.elementIds?.forEach((elementId) => {
          const maskMember = this.canvasDocument.getCanvasElementById(elementId);
          if (maskMember && this.frameElements.get(maskMember.id) === frameId) {
            this.removeElementFromFrame(maskMember);
          }
        });
      }
      return;
    }
    const frame = this.frames.get(canvasElement.id);
    if (frame) {
      frame?.elements.forEach((element) => {
        this.frameElements.delete(element.canvasElement.id);
      });
      this.frames.delete(frame.id);
      //console.log('Removing frame: ', canvasElement.id, this.frames);
    }
  }

  /**
   * Update this.frames
   * @param ids
   */
  public removeElements(ids: Array<string>) {
    if (ids?.length === 0) {
      return;
    }
    for (let i = 0; i < ids.length; i++) {
      const canvasElement = this.canvasDocument.state.getElementById(ids[i]);
      if (canvasElement) {
        this.removeElementFromFrame(canvasElement);
      }
    }
  }

  /**
   * Update order indices for frames and elements according to the current order of @elements
   * @param elements
   * @returns
   */
  public reorderElements(elements: Array<DocumentElement>) {
    if (elements?.length === 0 || this.frames?.size === 0) {
      return;
    }
    const elementsOrderMap: Map<string, number> = elements.reduce((map, element, i) => {
      map.set(element.id, i);
      return map;
    }, new Map());
    this.frames.forEach((frame) => {
      const newFrameIndex = elementsOrderMap.get(frame.id);
      if (newFrameIndex != null) {
        this.frames.set(frame.id, {
          ...frame,
          index: newFrameIndex,
          elements: frame.elements.map((element) => {
            const newElementIndex = elementsOrderMap.get(element.canvasElement.id);
            if (newElementIndex != null) {
              element.index = newElementIndex;
            }
            return element;
          }),
        });
      }
    });

    //console.log('Updated order indices for frames and elements', this.frames);
  }

  /**
   * Add @elements on @frameId
   * This function is mostly used by remote user actions.
   * @param frameId
   * @param elements
   * @returns
   */
  public addElementsOnFrame(frameId: string, elements: DocumentElement[]) {
    const frame = this.frames.get(frameId);
    if (!frame) {
      return;
    }
    const index =
      frame.elements?.length > 0
        ? frame.elements.sort((a, b) => a.index - b.index)[frame.elements.length - 1].index
        : frame.index;
    for (const [i, documentElement] of elements.entries()) {
      const canvasElement = this.canvasDocument.state.getElementById(documentElement.id);
      if (canvasElement) {
        this.removeElementFromFrame(canvasElement);
        this.addElementOnFrame(canvasElement, index + 1 + i, frame);
      }
    }
  }
}
