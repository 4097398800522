import { TransformPortable } from '../util/transform-types';

export class ShowroomContentConfig {
  id: string;
  contentId: string;
  contentConfig: ContentConfig;

  constructor() {
    this.contentId = '';
    this.contentConfig = new ContentConfig();
  }
}

export class ContentConfig {
  pivotPosition: string; // center, bottom, top, asset
  uniformScale: number;
  baseUnit: string; // m, mm, cm, km
  slotTypes: Array<string>; // FocusView, Hanged, Surface, Shoe
  offsetTransform: TransformPortable;

  constructor() {
    this.pivotPosition = 'center';
    this.uniformScale = 1;
    this.baseUnit = 'm';
    this.slotTypes = ['FocusView'];
    this.offsetTransform = new TransformPortable();
  }
}
