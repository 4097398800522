<div class="container">
  <span>{{ label }}</span>
  <div class="font-size">
    <div color="white" class="btn-size-small" (click)="dec()">-</div>
    <div class="input-editable">
      <input class="label-small" type="text" [value]="value" (change)="inputResize($event)" />
    </div>
    <div color="white" class="btn-size-small" (click)="inc()">+</div>
  </div>
</div>
