import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentService } from '../document.service';

export interface TextHyperlinkDetails {
  textElement: any;
  url: string;
  mode: string;
  position: any;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentTextService {
  private textHyperlinkDetailsSubject: BehaviorSubject<TextHyperlinkDetails> = new BehaviorSubject(null);
  public textHyperlinkDetailslinkObs: Observable<TextHyperlinkDetails> =
    this.textHyperlinkDetailsSubject.asObservable();

  constructor(private documentService: DocumentService) {
    this.documentService.documentTextElementEvents.subscribe((event) => {
      if (event.textFormat?.link && event.element) {
        this.textHyperlinkDetailsSubject.next({
          textElement: event.element,
          mode: event.textFormat.linkMode,
          url: event.textFormat?.link,
          position: event.textFormat?.position,
        });
      } else {
        this.textHyperlinkDetailsSubject.next(null);
      }
    });
  }

  showHyperlinkOverlay(details: TextHyperlinkDetails) {
    this.textHyperlinkDetailsSubject.next(details);
  }

  updateTextElement(textElement, values) {
    const action = {
      element: textElement,
      textFormat: values,
    };
    this.documentService.handleDocumentTextElementActions(action);
  }
}
