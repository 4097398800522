import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AnnotationToolComponent } from './annotation-tool/annotation-tool.component';

@NgModule({
  declarations: [AnnotationToolComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatTooltipModule],
  exports: [AnnotationToolComponent],
})
export class AnnotationModule {}
