import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class CropElementClickHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (
      this.canvasDocument.state.cropState.isCropping &&
      (!elementTarget?.element || !this.canvasDocument.isCropping(elementTarget.element.id))
    ) {
      this.canvasDocument.submitCrop();
    }
  }

  public handleDblClick(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    if (
      elementTarget?.element?.isCropEnabled &&
      !elementTarget?.element?.elementDefinition?.isLocked &&
      elementTarget?.element?.isSelected &&
      !this.canvasDocument.isCropping(elementTarget.element.id)
    ) {
      this.canvasDocument.state.cropState.startCrop(elementTarget.element);
    }
  }
}
