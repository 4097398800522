import { Injectable } from '@angular/core';
import {
  Document,
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementEvent,
  DocumentNavigationEvent,
  DocumentSVGElementEvent,
  DocumentTextElementEvent,
  PositionDefinition,
  SizeDefinition,
  StyleDefinition,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { BehaviorSubject, concatMap, delay, from, Observable, of, Subject } from 'rxjs';
import { ActionRequest } from '@contrail/actions';
import { PropertyConfiguratorService } from './property-configurator/property-configurator.service';
import { DocumentClipboard } from './document-clipboard/document-clipboard';
import { DocumentFileHandler } from './document-files/document-file-handler';
import { setLoading } from 'src/app/common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerService } from 'src/app/common/types/type-manager.service';
import { AnnotationOption, AnnotatedElement } from './document-annotation/document-annotation-options';
import { AuthService } from '@common/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { DocumentItemService } from './document-item/document-item.service';
import { DocumentColorService } from './document-color/document-color.service';
import { Frame } from '../canvas/state/canvas-frame-state';
import { CanvasElement } from '../canvas/elements/canvas-element';
import { DocumentActions, DocumentSelectors } from './document-store';
import { DevToolsRenderingService } from '../board-dev-tools/dev-tools-rendering/board-dev-rendering.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Group } from '../canvas/state/canvas-group-state';
import { CoordinateBox } from '../canvas/coordinate-box';
import { Mask } from '../canvas/state/canvas-mask-state';
import { nanoid } from 'nanoid';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { CopiedProperties } from '../canvas/canvas-document';
import { DrawOptions } from '../canvas/renderers/canvas-renderer';
import { CanvasUtil } from '../canvas/canvas-util';
import { DocumentDynamicTextService } from './document-text/document-dynamic-text/document-dynamic-text.service';
import { CanvasTableElement } from '../canvas/elements/table/canvas-table-element';
import { chunk } from 'lodash';

export interface DocumentRenderer {
  applyChanges: (changes: any) => void;
  addElements: (elements: Array<DocumentElement>, select?: boolean) => Promise<any>;
  clear: () => void;
  loadDocument: (document: Document) => void;
  getSelectedElements: () => Array<DocumentElement>;
  getSelectedExpandedElements: () => Array<DocumentElement>;
  getSelectedElementsByOrder: () => Array<DocumentElement>;
  getCanvasElementById: (id) => CanvasElement;
  toCanvasElements: (elements: DocumentElement[]) => CanvasElement[];
  getElementIndex: (id) => number;
  getVisibleElements: () => CanvasElement[];
  removeElements: (ids: Array<string>) => void;
  setInteractionMode: (mode: string) => void;
  getInteractionMode: () => string;
  getOldInteractionMode: () => string;
  getImageCacheMetrics: () => any;
  copySelectedElement: () => void;
  activateComponentAndImageInteraction: (allow: boolean) => void;
  activateAssignItemToComponent: (allow: boolean) => void;
  setAssignItemToComponentConditions: (conditions: any) => void;
  applyTextChanges: (changes: DocumentTextElementEvent) => void;
  deselectAll: () => void;
  deselectElements: (elements: DocumentElement[]) => void;
  selectAll: () => void;
  selectElement: (element: DocumentElement) => void;
  selectElements: (elements: DocumentElement[], multiSelect) => void;
  documentGenerationHighlights: (elements: DocumentElement[]) => void;
  removeDocumentGenerationHighlights: (elements: DocumentElement[]) => void;
  getTextFontProperties: () => any;
  updateSizeAndPositionForPropertyElements: (documentElements: any[], componentElement: DocumentElement) => any;
  updateSizeAndPositionForColorElements(documentElements: any[], size?: any): any;
  reorderElements: (elements) => any;
  setAnnotations: (annotatedElements: AnnotatedElement[]) => void;
  getFrameForElement: (element: DocumentElement) => Frame;
  getFrames: () => Map<string, Frame>;
  getFrameElements: () => Map<string, string>;
  getGroupById: (id: string) => Group;
  getGroupByMemberId: (id: string) => Group;
  getAllElementsInGroup: (id: string, includeFrameElements: boolean, includeMaskMembers: boolean) => DocumentElement[];
  editFrameName: (id) => void;
  addElementsOnFrame: (frameId: string, elements: DocumentElement[]) => void;
  removeElementsFromFrame: (elements: DocumentElement[]) => void;
  isOnlyFrameSelected: () => any;
  isOnlyFrameInElements: (elements: DocumentElement[]) => any;
  onlyFramesInElements: (elements: DocumentElement[]) => any;
  getViewBox: () => any;
  highlightElement: (id, color?) => void;
  dehighlightElement: (id) => void;
  outlineElements: (ids: string[], color: string) => void;
  removeOutlineElements: (ids: string[]) => void;
  removeHighlightElement: () => void;
  getHighlightElement: () => DocumentElement;
  elementsAdded$: any;
  getAllCommonBounds: () => CoordinateBox;
  getCommonBounds: (elements: DocumentElement[], options?: DrawOptions) => CoordinateBox;
  isMask: (element: DocumentElement) => boolean;
  isMaskOrMaskMember: (id: string) => Mask;
  getMaskMembers: (id) => CanvasElement[];
  isEditingMask: (id) => boolean;
  getMaskByMemberId: (id) => Mask;
  mode: string;
  setSvgElementSelection: (elementId: string, svgElementSelectionIds: string[]) => SVGElement[];
  setSvgElementHighlights: (elementId: string, svgElementSelectionIds: string[]) => SVGElement[];
  clearSvgElementSelection: (elementId: string) => void;
  clearSvgElementHighlights: (elementId: string) => void;
  copySelectedElementProperties: () => CopiedProperties;
  applyCopiedProperties: (properties: CopiedProperties) => void;
  startCrop: (element: DocumentElement) => void;
  cancelCrop: () => void;
  submitCrop: () => void;
  isEditingCrop: () => boolean;
  combineElementsIntoSvg: (elements: DocumentElement[]) => Promise<string>;
  getSingleSelectedTable: () => CanvasTableElement;
  isSingleTableSelectedAndActive: () => CanvasTableElement;
  getValidRowsToDelete: () => number[];
  getValidColumnsToDelete: () => number[];
  handleTableDelete: (element: CanvasTableElement, direction?: 'column' | 'row', indexes?: number[]) => void;
  moveToNextTableCell: (element: CanvasTableElement, options?: { advanceRow: boolean }) => void;
  moveToPrevTableCell: (element: CanvasTableElement, options?: { advanceRow: boolean }) => void;
  moveToNextTableRow: (element: CanvasTableElement) => void;
  moveToPrevTableRow: (element: CanvasTableElement) => void;
  getTableChildElements: (element: DocumentElement) => DocumentElement[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  public currentDocument: Document;
  public documentRenderer: DocumentRenderer;
  public elementsAdded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public interactionModeChanged$: BehaviorSubject<string> = new BehaviorSubject('select');

  public ownerReference: string;
  public documentClipboard: DocumentClipboard;
  public fileHandler: DocumentFileHandler;
  public elementTypesWithContextMenu = [];

  private documentElementsSubject: BehaviorSubject<Array<DocumentElement>> = new BehaviorSubject(null);
  public documentElements: Observable<Array<DocumentElement>> = this.documentElementsSubject.asObservable();

  // Changes to documents
  private documentActionsSubject: BehaviorSubject<Array<DocumentAction>> = new BehaviorSubject(null);
  public documentActions: Observable<Array<DocumentAction>> = this.documentActionsSubject.asObservable();

  private backgroundUpdateDocumentActionsSubject: BehaviorSubject<Array<DocumentAction>> = new BehaviorSubject(null);
  public backgroundUpdateDocumentActions: Observable<Array<DocumentAction>> =
    this.backgroundUpdateDocumentActionsSubject.asObservable();

  // Document element events, streaming from the edited document
  // Subject is used instead of BehaviorSubject because we don't want the last
  // emitted value when we switch boards.
  private documentElementEventsSubject: Subject<DocumentElementEvent> = new Subject();
  public documentElementEvents: Observable<DocumentElementEvent> = this.documentElementEventsSubject.asObservable();

  // Text element events, streaming from the edited text element
  private documentTextElementEventsSubject: BehaviorSubject<any> = new BehaviorSubject({ element: [], textFormat: {} });
  public documentTextElementEvents: Observable<any> = this.documentTextElementEventsSubject.asObservable();

  // Text element actions, streaming from the toolbar
  private documentTextElementActionsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public documentTextElementActions: Observable<any> = this.documentTextElementActionsSubject.asObservable();

  // SVG element events streaming from the svg-editor
  private documentSVGElementsEventsSubject: Subject<DocumentSVGElementEvent> = new Subject();
  public documentSVGElementEvents: Observable<DocumentSVGElementEvent> =
    this.documentSVGElementsEventsSubject.asObservable();

  // Navigation events (zoom, pan)
  private documentNavigationEventsSubject: Subject<DocumentNavigationEvent> = new Subject();
  public documentNavigationEvents: Observable<DocumentNavigationEvent> =
    this.documentNavigationEventsSubject.asObservable();

  // Actions taken in context menus, property editors, etc... Could be redundant with element changes..
  // Added to handle actions like 'add hot spot', which could be invoked from many locations, but can be
  // decoupled from an sort of 'mega service' or component.
  // This could be a better way of decoupling actions like 'set bold' or 'align' from menus / UI's that
  // host many different forms of actions.
  private actionRequestsSubject: BehaviorSubject<ActionRequest> = new BehaviorSubject(null);
  public actionRequests: Observable<ActionRequest> = this.actionRequestsSubject.asObservable();

  private lastAppliedTextFormatSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public lastAppliedTextFormat$: Observable<any> = this.lastAppliedTextFormatSubject.asObservable();
  public lastAppliedTextFormat = null;

  public lastAppliedStyle: Map<string, StyleDefinition> = new Map();

  public annotationOptions: Array<AnnotationOption>;

  public designMode: boolean;

  constructor(
    private propertyConfiguratorService: PropertyConfiguratorService,
    private typeManagerService: TypeManagerService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private store: Store<RootStoreState.State>,
    public authService: AuthService,
    private webSocketService: WebSocketService,
    public documentItemService: DocumentItemService,
    public documentColorService: DocumentColorService,
    public documentRenderingMetricService: DevToolsRenderingService,
    public confirmationBoxService: ConfirmationBoxService,
    public snackBar: MatSnackBar,
    public featureFlagService: FeatureFlagRegistryService,
    private documentDynamicTextService: DocumentDynamicTextService,
  ) {
    this.documentClipboard = new DocumentClipboard(this, this.authService, documentDynamicTextService);
    this.store?.select(DocumentSelectors.designMode).subscribe((designMode) => (this.designMode = designMode));
  }

  toggleLoading(loading: boolean, message: string) {
    this.store.dispatch(setLoading({ loading, message }));
  }

  init(document: Document, ownerReference: string = null) {
    this.ownerReference = ownerReference;
    this.currentDocument = document;
    this.propertyConfiguratorService.setManagementService(this);
    this.fileHandler = new DocumentFileHandler(this.store, this, this.ownerReference, this.http, this.sanitizer);
    if (this.currentDocument) {
      this.documentElementsSubject.next(this.currentDocument.elements);
    }
  }

  handleBackgroundUpdateDocumentActions(actions: Array<DocumentAction>) {
    this.backgroundUpdateDocumentActionsSubject.next(actions);
  }

  async handleDocumentActions(actions: Array<DocumentAction>, document?: Document) {
    actions.forEach((action) => {
      action.actionType = 'document';
      action.changeDefinition.documentId = document?.id || this.currentDocument.id;
      if (action.undoChangeDefinition) {
        action.undoChangeDefinition.documentId = document?.id || this.currentDocument.id;
      }
    });
    // console.log('DocumentService: handleDocumentActions', actions);
    if (this.documentDynamicTextService.dynamicTextActionHandler.shouldHandleDynamicTexts(actions)) {
      await this.documentDynamicTextService.dynamicTextActionHandler.handleDynamicTexts(actions);
    }
    this.documentActionsSubject.next(actions);
  }

  /**
   * Split @actions into arrays of 15 actions so websockets could handle the payload.
   * This is a simple solution - it does not cover REORDER_ELEMENTS event which is
   * just one action that includes all elements in the new order - websocket
   * won't handle it.
   * @param actions
   * @returns
   */
  public async splitActionsAndSendSessionEvent(actions: DocumentAction[], document?: Document) {
    if (actions?.length === 0) {
      return;
    }

    if (actions[0].changeDefinition.changeType === DocumentChangeType.REORDER_ELEMENT) {
      actions = actions.map((action) => {
        return new DocumentAction({
          changeType: action.changeDefinition.changeType,
          documentId: action.changeDefinition.documentId,
          elementData: (action.changeDefinition.elementData as any)?.map((element) => ({
            id: element.id,
          })),
        });
      });
    }

    if (
      actions.length === 1 &&
      actions[0].changeDefinition.changeType === DocumentChangeType.REORDER_ELEMENT &&
      (actions[0].changeDefinition?.elementData as any).length > 15
    ) {
      const fileName = nanoid(16);
      const ttl = 600000; // 10 minutes
      const jsonString = JSON.stringify({
        documentElements: actions[0].changeDefinition.elementData,
      });
      const fileEntity = await this.fileHandler.fileService.createAndUploadJSONFile(jsonString, fileName, ttl);
      console.log('Saving reordered document elements to S3', fileEntity);
      if (fileEntity?.fileUrl) {
        actions = actions.map((action) => {
          return new DocumentAction({
            changeType: action.changeDefinition.changeType,
            documentId: action.changeDefinition.documentId,
            elementData: fileEntity.fileUrl,
          });
        });
      }
    }
    const array = ObjectUtil.cloneDeep(actions);
    const count = this.getSessionEventBufferCount(actions);
    // Split array of document actions into chunks of 15 so websocket messages are not too large
    // Send messages with interval of @SESSION_EVENT_DELAY ms between each chunk so messages are received
    // in order. Sometimes messages are not received in order. Possibly because are chunks
    // were sent at the same time in a loop but had different sizes which resulted
    // in some chunks getting to remote sessions faster.
    const chunks = chunk(array, count);
    from(chunks)
      .pipe(concatMap((value) => of(value).pipe(delay(this.SESSION_EVENT_DELAY))))
      .subscribe((values: DocumentAction[]) => {
        this.sendSessionEvent(values, document);
      });
  }
  private SESSION_EVENT_BUFFER_COUNT = 15;
  private SESSION_EVENT_BUFFER_COUNT_SMALL = 5;
  private SESSION_EVENT_DELAY = 50;
  private getSessionEventBufferCount(actions) {
    let count = this.SESSION_EVENT_BUFFER_COUNT;
    if (
      actions?.length > 0 &&
      actions[0].changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT &&
      actions[0].changeDefinition?.elementData?.type === 'component' &&
      actions[0].changeDefinition?.elementData?.modelBindings
    ) {
      count = this.SESSION_EVENT_BUFFER_COUNT_SMALL;
    }
    return count;
  }

  /**
   * Get total delay after which all @events will be sent to remote sessions
   * @param eventsLength
   * @returns
   */
  public getTotalSessionEventDelay(actionsLength: number) {
    return Math.ceil(actionsLength / this.SESSION_EVENT_BUFFER_COUNT) * this.SESSION_EVENT_DELAY;
  }

  sendSessionEvent(actions: Array<DocumentAction>, document?: Document) {
    // console.log('sendSessionEvent', actions.length);
    actions.forEach((action) => {
      (action.actionType = 'document'), (action.changeDefinition.documentId = document?.id || this.currentDocument.id);
      if (action.undoChangeDefinition) {
        action.undoChangeDefinition.documentId = document?.id || this.currentDocument.id;
      }
    });

    this.webSocketService.sendSessionEvent({
      eventType: 'DOCUMENT_ELEMENT_CHANGED',
      changes: {
        actions,
      },
    });
  }

  applyDocumentActions(actions: Array<DocumentAction>, skipSelect = false) {
    console.log('DocumentService: applyActions', actions);
    if (!actions?.length) {
      return;
    }
    if (this.documentRenderer) {
      this.handleAdds(actions, skipSelect);
      this.handleModifications(actions);
      this.handleDeletes(actions);
      this.handleReorders(actions);
      this.documentElementsSubject.next(this.currentDocument.elements);
    }
  }
  /** Handle modification changes */
  handleModifications(actions: Array<DocumentAction>, redraw = false) {
    const modifications = actions.filter((action) =>
      [DocumentChangeType.MODIFY_ELEMENT, DocumentChangeType.REBIND_MODEL].includes(action.changeDefinition.changeType),
    );
    if (!modifications.length) {
      return;
    }
    const changes = [];
    modifications.forEach((action) => {
      const elementIndex = this.currentDocument.elements.findIndex((el) => el.id === action.changeDefinition.elementId);
      const element = this.currentDocument.elements[elementIndex];
      if (element) {
        if (!element.isLocked || (element.isLocked && !action.changeDefinition.elementData.isLocked)) {
          this.currentDocument.elements[elementIndex] = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(this.currentDocument.elements[elementIndex]),
            action.changeDefinition.elementData,
          );
          changes.push(action.changeDefinition.elementData);
        }
      }
    });
    this.documentRenderer.applyChanges(changes);
  }

  handleReorders(actions: Array<DocumentAction>) {
    const reorders = actions.filter(
      (action) => action.changeDefinition.changeType === DocumentChangeType.REORDER_ELEMENT,
    );
    if (!reorders.length) {
      return;
    }
    reorders.forEach((action) => {
      const elements: any = action.changeDefinition.elementData;
      this.documentRenderer.reorderElements(elements);
      const elementsOrderMap: Map<string, number> = elements?.reduce((map, element, i) => {
        map.set(element.id, i);
        return map;
      }, new Map());
      const lastIndex = this.currentDocument.elements.length;
      this.currentDocument.elements = ObjectUtil.cloneDeep(
        this.currentDocument.elements.sort((a, b) => {
          const aIdx = elementsOrderMap.get(a.id) ?? lastIndex;
          const bIdx = elementsOrderMap.get(b.id) ?? lastIndex;
          return aIdx - bIdx;
        }),
      );
    });
  }

  handleAdds(actions: Array<DocumentAction>, skipSelect = false) {
    const adds = actions.filter((action) => action.changeDefinition.changeType === DocumentChangeType.ADD_ELEMENT);
    const newElements = adds.map((action) => action.changeDefinition.elementData);
    const groupElement = newElements.find((element) => element.type === 'group');
    const penElement = newElements.find((element) => ['pen', 'highlighter'].indexOf(element.type) !== -1);
    if (groupElement) {
      this.deselectAllElements();
    }
    this.currentDocument.elements = this.currentDocument.elements.concat(newElements);
    this.documentRenderer.addElements(newElements, skipSelect || penElement ? false : true);
    if (adds.length > 0 && !penElement) {
      this.setInteractionMode('select');
    }
  }
  handleDeletes(actions: Array<DocumentAction>) {
    const deletes = actions.filter(
      (action) => action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT,
    );

    // Remove from document
    const ids = deletes.map((action) => action.changeDefinition.elementId);
    if (!ids.length) {
      return;
    }
    this.currentDocument.elements = this.currentDocument.elements.filter((el) => !ids.includes(el.id));
    this.currentDocument.elements.forEach((element) => {
      if (element.elements) {
        // look for hotspots
        element.elements = element.elements.filter((el) => !ids.includes(el.id));
      }
    });
    // Update renderer
    this.documentRenderer.removeElements(ids);
  }

  getSelectedElements(): DocumentElement[] {
    if (this.documentRenderer) {
      return this.documentRenderer.getSelectedElements();
    }
    return null;
  }

  /**
   * Get selected elements, include group and mask members
   * @returns
   */
  public getSelectedExpandedElements() {
    if (this.documentRenderer) {
      return this.documentRenderer.getSelectedExpandedElements();
    }
    return null;
  }

  public getSelectedElementsByOrder() {
    if (this.documentRenderer) {
      return this.documentRenderer.getSelectedElementsByOrder();
    }
    return null;
  }

  /**
   *
   * @returns
   */
  getSelectedGroupElements(): DocumentElement[] {
    if (this.documentRenderer) {
      const selectedElements = this.documentRenderer.getSelectedElements();
      const selectedFrames = selectedElements
        .filter((element) => element.type === 'frame')
        .map((element) => element.id);
      const frameElements = this.getFrameElements();
      const singleFrameIsSelected =
        selectedFrames?.length === 1 &&
        selectedElements.findIndex((element) =>
          element.type === 'frame' || frameElements.get(element.id) ? false : true,
        ) === -1;
      const selectedGroupElements = [];
      if (selectedElements?.length > 0) {
        for (let i = 0; i < selectedElements.length; i++) {
          const selectedElement = selectedElements[i];
          if (selectedElement.type === 'frame') {
            if (!singleFrameIsSelected) {
              selectedGroupElements.push(selectedElement);
            }
          } else {
            const frameId = frameElements.get(selectedElement.id);
            if (singleFrameIsSelected || !frameId || selectedFrames.indexOf(frameId) === -1) {
              selectedGroupElements.push(selectedElement);
            }
          }
        }
      }
      return selectedGroupElements;
    }
    return null;
  }

  setRenderer(renderer: DocumentRenderer) {
    this.documentRenderer = renderer;

    // Subscribe to an Observable that emits true if existing or new elements are added to a document
    this.documentRenderer.elementsAdded$.subscribe((b) => this.elementsAdded$.next(b));
  }

  handleActionRequest(request: ActionRequest) {
    this.actionRequestsSubject.next(request);
  }
  getDocument() {
    return this.currentDocument;
  }

  handleDocumentElementEvent(event: DocumentElementEvent): void {
    this.documentElementEventsSubject.next(event);
  }

  public refresh() {
    this.documentRenderer.loadDocument(this.currentDocument);
  }
  public setInteractionMode(mode) {
    if (this.documentRenderer) {
      this.documentRenderer.setInteractionMode(mode);
    }
    this.interactionModeChanged$.next(mode);
  }

  public getInteractionMode() {
    return this.documentRenderer?.getInteractionMode();
  }

  public getOldInteractionMode() {
    return this.documentRenderer?.getOldInteractionMode();
  }

  public getImageCacheMetrics() {
    return this.documentRenderer?.getImageCacheMetrics();
  }

  public setElementTypesWithContextMenu(elementTypesWithContextMenu: string[]) {
    this.elementTypesWithContextMenu = elementTypesWithContextMenu;
  }

  public activateComponentAndImageInteraction(active: boolean) {
    this.documentRenderer.activateComponentAndImageInteraction(active);
  }

  public activateAssignItemToComponent(active: boolean) {
    this.documentRenderer.activateAssignItemToComponent(active);
  }

  public setAssignItemToComponentConditions(assignItemToComponentConditions: any[]) {
    this.documentRenderer.setAssignItemToComponentConditions(assignItemToComponentConditions);
    this.store.dispatch(DocumentActions.setAssignItemToComponentConditions({ assignItemToComponentConditions }));
  }

  public handleDocumentTextElementEvent(event: DocumentTextElementEvent) {
    this.documentTextElementEventsSubject.next(event);
  }

  public handleDocumentTextElementActions(action: DocumentTextElementEvent) {
    this.documentRenderer.applyTextChanges(action);
  }

  public handleDocumentSvgElementEvent(event: DocumentSVGElementEvent) {
    this.documentSVGElementsEventsSubject.next(event);
  }

  public handleDocumentNavigationEvent(event: DocumentNavigationEvent) {
    this.documentNavigationEventsSubject.next(event);
  }

  /**
   * Set last applied text format (font family, font size) so users
   * do not need to set it every time they create text elements.
   * @param format
   */
  public saveLastAppliedTextFormat(format: any): void {
    this.lastAppliedTextFormat = Object.assign(this.lastAppliedTextFormat || {}, format);
    this.lastAppliedTextFormatSubject.next(format);
  }

  public setLastAppliedStyle(key: string, style: StyleDefinition): void {
    this.lastAppliedStyle.set(key, ObjectUtil.mergeDeep(this.lastAppliedStyle.get(key) || {}, style));
  }

  public getLastAppliedStyle(key: string) {
    const lastAppliedStyle: StyleDefinition = this.lastAppliedStyle.get(key);
    const defaultStyle: StyleDefinition = CanvasUtil.getDefaultStyle(key);
    return ObjectUtil.mergeDeep(defaultStyle, lastAppliedStyle);
  }

  public deselectAllElements() {
    this.documentRenderer.deselectAll();
  }

  public deselectElements(elements: DocumentElement[]) {
    this.documentRenderer?.deselectElements(elements);
  }

  public selectAllElements() {
    this.documentRenderer.selectAll();
  }

  public selectElement(element: DocumentElement) {
    this.documentRenderer?.selectElement(element);
  }

  public selectElements(elements: DocumentElement[], multiSelect = false) {
    this.documentRenderer?.selectElements(elements, multiSelect);
  }

  public documentGenerationHighlights(elements: DocumentElement[]) {
    this.documentRenderer?.documentGenerationHighlights(elements);
  }

  public removeDocumentGenerationHighlights(elements: DocumentElement[]) {
    this.documentRenderer?.removeDocumentGenerationHighlights(elements);
  }

  public getTextFontProperties() {
    return this.propertyConfiguratorService.getTextFontProperties();
  }

  public updateSizeAndPositionForPropertyElements(documentElements: any[], componentElement: DocumentElement): any {
    return this.documentItemService?.updateSizeAndPositionForPropertyElements(documentElements, componentElement);
  }

  public updateSizeAndPositionForColorElements(documentElements: any[], size?: any): any {
    return this.documentColorService?.updateSizeAndPositionForColorElements(documentElements, size);
  }

  public emitTextElementKeyEvent(decoratorType) {
    this.propertyConfiguratorService.emitTextElementKeyEvent(decoratorType);
  }

  public setAnnotations(annotatedElements: AnnotatedElement[]) {
    this.documentRenderer?.setAnnotations(annotatedElements);
  }

  public getAnnotationOptions() {
    return this.annotationOptions;
  }

  public highlightElement(id: string, color?: string) {
    this.documentRenderer?.highlightElement(id, color);
  }

  public dehighlightElement(id: string) {
    this.documentRenderer?.dehighlightElement(id);
  }

  public outlineElements(ids: string[], color: string) {
    this.documentRenderer?.outlineElements(ids, color);
  }

  public removeOutlineElements(ids: string[]) {
    this.documentRenderer?.removeOutlineElements(ids);
  }

  public removeHighlightElement() {
    this.documentRenderer?.removeHighlightElement();
  }

  public getHighlightElement(): DocumentElement {
    return this.documentRenderer?.getHighlightElement();
  }

  /**
   * Get frame for @element if it belongs on a frame
   * @param element
   */
  public getFrameForElement(element: DocumentElement): Frame {
    return this.documentRenderer?.getFrameForElement(element);
  }

  public getFrames(): Map<string, Frame> {
    return this.documentRenderer?.getFrames();
  }

  public getFrameElements(): Map<string, string> {
    return this.documentRenderer?.getFrameElements();
  }

  public getNewFrameNumber(): number {
    const frameNameEndsWithNumber = /\s(\d+)$/;
    const lastFrameNumber =
      (
        ObjectUtil.cloneDeep(this.currentDocument.elements).filter((e) => {
          return e.type === 'frame' && frameNameEndsWithNumber.test(e.name);
        }) || []
      )
        .map((e) => {
          const [match, frameNumber] = frameNameEndsWithNumber.exec(e.name) || [null, '0'];
          return parseInt(frameNumber);
        })
        .sort((a, b) => a - b)
        .pop() || 0;
    return parseInt(lastFrameNumber) + 1;
  }

  public getNewFrameDimensions(frameSize: SizeDefinition): { size: SizeDefinition; position: PositionDefinition } {
    const viewBox = this.documentRenderer?.getViewBox();
    const rightMostFrame = this.getRightMostElement(
      this.documentRenderer
        ?.getVisibleElements()
        ?.filter((e) => e?.elementDefinition?.type === 'frame')
        .map((e) => e.elementDefinition),
    );
    const size = frameSize || rightMostFrame?.size || { width: 1200, height: 675 };
    const position = rightMostFrame?.position
      ? this.getPasteFramePosition(rightMostFrame, false)
      : {
          x: viewBox.x + (viewBox.width - size.width) / 2,
          y: viewBox.y + (viewBox.height - size.height) / 2,
        };

    if (
      rightMostFrame?.position &&
      viewBox &&
      (viewBox.x + viewBox.width <= position.x ||
        viewBox.y + viewBox.height <= position.y ||
        viewBox.x >= position.x + size.width ||
        viewBox.y >= position.y + size.height)
    ) {
      this.store.dispatch(
        DocumentActions.navigateToPosition({
          position: {
            x: position.x + size.width * 0.5,
            y: position.y + size.height * 0.5,
          },
        }),
      );
    }

    return { position, size };
  }

  public editFrameName(id) {
    this.documentRenderer?.editFrameName(id);
  }

  public addElementsOnFrame(frameId: string, elements: DocumentElement[]) {
    this.documentRenderer?.addElementsOnFrame(frameId, elements);
  }

  public removeElementsFromFrame(elements: DocumentElement[]) {
    this.documentRenderer?.removeElementsFromFrame(elements);
  }

  public isOnlyFrameSelected() {
    return this.documentRenderer?.isOnlyFrameSelected();
  }

  public isOnlyFrameInElements(elements) {
    return this.documentRenderer?.isOnlyFrameInElements(elements);
  }

  public onlyFramesInElements(elements) {
    return this.documentRenderer?.onlyFramesInElements(elements);
  }

  /**
   * Paste @elements on a selected frame relative to its position on their current frames.
   * If element is not on a frame - use @firstElementFrame which is first found frame
   * that one of @elements is located on.
   * Do nothing if no element in @elements belong on a frame.
   * @param elements
   * @returns elements to paste with modified positions
   */
  public pasteElementsOnFrame(elements: DocumentElement[]): DocumentElement[] {
    const selectedFrame: CanvasElement = this.isOnlyFrameSelected();
    const elementsToPaste = ObjectUtil.cloneDeep(elements);
    const firstElementFrame = elementsToPaste?.reduce((frame, e) => {
      if (frame) return frame;
      let elementFrame = this.getFrameForElement(e);
      if (elementFrame) return elementFrame;
    }, null);
    if (
      elementsToPaste?.length > 0 &&
      elementsToPaste?.findIndex((e) => e.type === 'frame') === -1 &&
      firstElementFrame &&
      selectedFrame
    ) {
      // Pasting to frame
      return elementsToPaste.map((element) => {
        if (element.type !== 'frame') {
          let elementFrame = this.getFrameForElement(element);
          if (!elementFrame) {
            elementFrame = firstElementFrame;
          }
          if (element.type === 'line') {
            element.lineDefinition.x1 =
              selectedFrame.elementDefinition.position.x +
              (element.lineDefinition.x1 - elementFrame.element.elementDefinition.position.x);
            element.lineDefinition.y1 =
              selectedFrame.elementDefinition.position.y +
              (element.lineDefinition.y1 - elementFrame.element.elementDefinition.position.y);
            element.lineDefinition.x2 =
              selectedFrame.elementDefinition.position.x +
              (element.lineDefinition.x2 - elementFrame.element.elementDefinition.position.x);
            element.lineDefinition.y2 =
              selectedFrame.elementDefinition.position.y +
              (element.lineDefinition.y2 - elementFrame.element.elementDefinition.position.y);
          } else {
            element.position.x =
              selectedFrame.elementDefinition.position.x +
              (element.position.x - elementFrame.element.elementDefinition.position.x);
            element.position.y =
              selectedFrame.elementDefinition.position.y +
              (element.position.y - elementFrame.element.elementDefinition.position.y);
          }
        }
        return element;
      });
    }
    return null;
  }

  /**
   * Calculate position when copy/pasting entire frames.
   * Position is calculated by finding first available spot
   * near the frame horizontally or vertically.
   * @param elements
   * @param vertical
   * @returns
   */
  public pasteFrame(elements: DocumentElement[], vertical = false): PositionDefinition {
    const framesToPaste: DocumentElement[] = elements?.filter((e) => e.type === 'frame');
    if (!framesToPaste || framesToPaste?.length === 0) {
      return null;
    }
    let pastedFrame: DocumentElement = [...framesToPaste].sort(
      (a, b) => a.position[vertical ? 'y' : 'x'] - b.position[vertical ? 'y' : 'x'],
    )[0];
    const selectedFrame: CanvasElement = this.isOnlyFrameSelected();
    if (selectedFrame) {
      pastedFrame = selectedFrame.elementDefinition;
    }
    const pastePosition = this.getPasteFramePosition(pastedFrame, vertical);
    const viewBox = this.documentRenderer?.getViewBox();
    if (
      pastePosition &&
      viewBox &&
      (viewBox.x + viewBox.width <= pastePosition.x ||
        viewBox.y + viewBox.height <= pastePosition.y ||
        viewBox.x >= pastePosition.x + pastedFrame.size.width ||
        viewBox.y >= pastePosition.y + pastedFrame.size.height)
    ) {
      this.store.dispatch(
        DocumentActions.navigateToPosition({
          position: {
            x: pastePosition.x + pastedFrame.size.width * 0.5,
            y: pastePosition.y + pastedFrame.size.height * 0.5,
          },
        }),
      );
    }
    return pastePosition;
  }

  public pasteDuplicate(elements: DocumentElement[], vertical = false): PositionDefinition {
    const element = this.getRightMostElement(elements);
    if (element) {
      const pastePositionDetails = this.getElementSizeAndPosition(element);
      const pastePosition = pastePositionDetails?.position;
      const viewBox = this.documentRenderer?.getViewBox();
      pastePosition.x = pastePosition.x + pastePositionDetails.size.width;
      pastePosition.y = pastePosition.y + pastePositionDetails.size.height * 0.5;
      if (
        pastePosition &&
        viewBox &&
        (viewBox.x + viewBox.width <= pastePosition.x ||
          viewBox.y + viewBox.height <= pastePosition.y ||
          viewBox.x >= pastePosition.x + pastePositionDetails.size.width ||
          viewBox.y >= pastePosition.y + pastePositionDetails.size.height)
      ) {
        this.store.dispatch(
          DocumentActions.navigateToPosition({
            position: {
              x: pastePosition.x + pastePositionDetails.size.width,
              y: pastePosition.y + pastePositionDetails.size.height * 0.5,
            },
          }),
        );
      }
      return pastePosition;
    } else {
      return null;
    }
  }

  public getPasteFramePosition(pasteNearFrame: DocumentElement, vertical = false): PositionDefinition {
    const frames = this.getFrames();
    let closestFrame: DocumentElement;
    [...frames?.values()]
      .sort(
        (a, b) =>
          a.element.elementDefinition.position[vertical ? 'y' : 'x'] -
          b.element.elementDefinition.position[vertical ? 'y' : 'x'],
      )
      .forEach((f) => {
        const frame = f.element.elementDefinition;
        if (vertical) {
          if (
            pasteNearFrame.position.x + pasteNearFrame.size.width >= frame.position.x &&
            pasteNearFrame.position.x <= frame.position.x + frame.size.width &&
            pasteNearFrame.position.y < frame.position.y
          ) {
            // Check if there is enough space to fit the frame between frames
            if (
              (!closestFrame &&
                pasteNearFrame.position.y + 2 * pasteNearFrame.size.height + 2 * 50 > frame.position.y) ||
              (closestFrame &&
                closestFrame.position.y <= frame.position.y &&
                closestFrame.position.y + 2 * pasteNearFrame.size.height + 2 * 50 > frame.position.y)
            ) {
              closestFrame = frame;
            }
          }
        } else {
          if (
            pasteNearFrame.position.y + pasteNearFrame.size.height >= frame.position.y &&
            pasteNearFrame.position.y <= frame.position.y + frame.size.height &&
            pasteNearFrame.position.x < frame.position.x
          ) {
            if (
              (!closestFrame &&
                pasteNearFrame.position.x + 2 * pasteNearFrame.size.width + 2 * 50 > frame.position.x) ||
              (closestFrame &&
                closestFrame.position.x <= frame.position.x &&
                closestFrame.position.x + 2 * pasteNearFrame.size.width + 2 * 50 > frame.position.x)
            ) {
              closestFrame = frame;
            }
          }
        }
      });
    if (!closestFrame) {
      closestFrame = pasteNearFrame;
    }
    const pastePosition = vertical
      ? {
          x: pasteNearFrame.position.x,
          y: closestFrame.position.y + closestFrame.size.height + 50,
        }
      : {
          x: closestFrame.position.x + closestFrame.size.width + 50,
          y: pasteNearFrame.position.y,
        };
    return pastePosition;
  }

  public getLeftMostElement(elements: DocumentElement[]): DocumentElement {
    return elements?.length === 0
      ? null
      : [...elements]?.sort((a, b) => {
          const positionA = this.getElementPosition(a);
          const positionB = this.getElementPosition(b);
          return positionA.x - positionB.x;
        })[0];
  }

  public getRightMostElement(elements: DocumentElement[]): DocumentElement {
    return elements?.length === 0
      ? null
      : [...elements]?.sort((a, b) => {
          const positionA = this.getElementPosition(a);
          const positionB = this.getElementPosition(b);
          return positionB.x - positionA.x;
        })[0];
  }

  public getElementPosition(element: DocumentElement): PositionDefinition {
    return element?.type === 'line'
      ? {
          x: Math.min(element.lineDefinition.x1, element.lineDefinition.x2),
          y: Math.min(element.lineDefinition.y1, element.lineDefinition.y2),
        }
      : element?.position;
  }

  public getElementSizeAndPosition(element: DocumentElement) {
    const canvasElement: CanvasElement = this.documentRenderer.getCanvasElementById(element.id);
    if (canvasElement) {
      let { x, y, width, height } = canvasElement.getBoundingClientRect();
      x = x + canvasElement.PADDING_LEFT;
      y = y + canvasElement.PADDING_TOP;
      width = width - (canvasElement.PADDING_LEFT + canvasElement.PADDING_RIGHT);
      height = height - (canvasElement.PADDING_TOP + canvasElement.PADDING_BOTTOM);
      return { size: { width, height }, position: { x, y } };
    }
  }

  public getCanvasElementById(id: string): CanvasElement {
    return this.documentRenderer?.getCanvasElementById(id);
  }

  public getGroupByMemberId(id: string): DocumentElement {
    const group: Group = this.documentRenderer.getGroupByMemberId(id);
    if (group) {
      return group.element.elementDefinition;
    }
    return null;
  }

  public getGroupById(id: string): DocumentElement {
    const group: Group = this.documentRenderer.getGroupById(id);
    if (group) {
      return group.element.elementDefinition;
    }
    return null;
  }

  public getAllElementsInGroup(
    id: string,
    includeFrameElements = false,
    includeMaskMembers = false,
  ): DocumentElement[] {
    return this.documentRenderer.getAllElementsInGroup(id, includeFrameElements, includeMaskMembers);
  }

  public getAllCommonBounds(): CoordinateBox {
    return this.documentRenderer?.getAllCommonBounds();
  }

  public getCommonBounds(elements: DocumentElement[], options?: DrawOptions): CoordinateBox {
    return this.documentRenderer?.getCommonBounds(elements, options);
  }

  public setSvgElementSelection(elementId: string, svgElementSelectionIds: string[]): SVGElement[] {
    return this.documentRenderer?.setSvgElementSelection(elementId, svgElementSelectionIds);
  }

  public setSvgElementHighlights(elementId: string, svgElementIds: string[]): SVGElement[] {
    return this.documentRenderer?.setSvgElementHighlights(elementId, svgElementIds);
  }

  public clearSvgElementSelection(elementId: string) {
    this.documentRenderer?.clearSvgElementSelection(elementId);
  }

  public clearSvgElementHighlights(elementId: string) {
    this.documentRenderer?.clearSvgElementHighlights(elementId);
  }

  public isMask(element: DocumentElement): boolean {
    return this.documentRenderer?.isMask(element);
  }

  public getMaskMembers(id: string): DocumentElement[] {
    return this.documentRenderer?.getMaskMembers(id)?.map((e) => e.elementDefinition);
  }

  public isMaskAllowed(elements: DocumentElement[]): boolean {
    return (
      elements?.length === 2 &&
      elements.filter((e) => ['rectangle', 'circle'].indexOf(e.type) !== -1 && !e.elementIds)?.length === 1 &&
      elements.filter((e) => ['image', 'svg'].indexOf(e.type) !== -1)?.length === 1
    );
  }

  public isMaskAndMaskMember(elements: DocumentElement[]): boolean {
    const mask = elements.filter((e) => ['rectangle', 'circle'].indexOf(e.type) !== -1 && e.elementIds?.length);
    const maskMember = elements.filter((e) => ['image', 'svg'].indexOf(e.type) !== -1);
    return (
      elements?.length === 2 &&
      mask?.length === 1 &&
      maskMember?.length === 1 &&
      mask[0].elementIds.indexOf(maskMember[0].id) !== -1
    );
  }

  public isRemoveMaskAllowed(elements: DocumentElement[]): boolean {
    return elements?.length === 1 && this.documentRenderer?.isMask(elements[0]);
  }

  public getMaskElement(elements: DocumentElement[]): DocumentElement {
    const shapes = elements.filter((e) => ['rectangle', 'circle'].indexOf(e.type) !== -1);
    return shapes?.length > 0 ? shapes[shapes.length - 1] : null;
  }

  public getElementsToMask(elements: DocumentElement[]): DocumentElement[] {
    const images = elements.filter((e) => ['image', 'svg'].indexOf(e.type) !== -1);
    return images;
  }

  public copySelectedElementProperties(): CopiedProperties {
    return this.documentRenderer?.copySelectedElementProperties();
  }

  public applyCopiedProperties(properties: CopiedProperties) {
    this.documentRenderer?.applyCopiedProperties(properties);
  }

  public startCrop(element: DocumentElement) {
    this.documentRenderer?.startCrop(element);
  }

  public cancelCrop() {
    this.documentRenderer?.cancelCrop();
  }

  public submitCrop() {
    this.documentRenderer?.submitCrop();
  }

  public isEditingCrop(): boolean {
    return this.documentRenderer?.isEditingCrop();
  }

  public isSingleTableSelectedAndActive(): CanvasTableElement {
    return this.documentRenderer?.isSingleTableSelectedAndActive();
  }

  public handleTableDelete(element: CanvasTableElement, direction?: 'column' | 'row', indexes?: number[]): void {
    this.documentRenderer?.handleTableDelete(element, direction, indexes);
  }
}
