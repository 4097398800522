import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { RoutingService } from 'src/app/common/routing/routing.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  navOptions = [
    {
      matIcon: false,
      icon: 'board_icon_black.svg',
      iconActive: 'board_icon_blue.svg',
      routerLink: this.routingService.getRouterLink('/home'),
      label: `All boards`,
    },
    {
      iconActive: '',
      matIcon: true,
      icon: 'dashboard',
      routerLink: this.routingService.getRouterLink('/frame-template-library'),
      label: `Templates`,
    },
  ];
  constructor(
    private routingService: RoutingService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  goHome() {
    this.routingService.go('/home');
  }

  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }

  // createBoard() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = false;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = '700px';
  //   dialogConfig.data = {};
  //   const dialogRef = this.matDialog.open(CreateBoardComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result) => {
  //   });
  // }
}
