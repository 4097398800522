import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-border-radius',
  templateUrl: './property-configurator-border-radius.component.html',
  styleUrls: ['./property-configurator-border-radius.component.scss'],
})
export class PropertyConfiguratorBorderRadiusComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  public defaultValue = 0;
  public radius = this.defaultValue;
  public borderRadius = ['0', '1', '2', '3', '4', '6', '8', '10', '12', '14', '16', '24'];

  constructor() {}

  ngOnChanges(): void {
    this.radius = this.currentValue || this.defaultValue;
  }

  setValue(radius) {
    this.radius = radius;
    const changes = {
      style: {
        border: {
          radius: this.radius,
        },
      },
    };
    this.valueChange.emit(changes);
  }

  isMatch(radius: number) {
    return radius == this.radius ? true : false;
  }
}
