<div class="wrapper flex" [class.flex-col]="viewMode === 'preview'">
  <div class="flex-center relative" *ngIf="viewMode !== 'modal'">
    <canvas id="renderCanvasConfig" [ngStyle]="{ height: size, width: size }"></canvas>

    <div
      class="canvas-buttons flex-center"
      [class.canvas-buttons-preview]="viewMode === 'preview'"
      [class.canvas-buttons-full]="viewMode === 'full'"
    >
      <img src="/assets/images/3d_coordinates.svg" draggable="false" (click)="move()" />
      <img src="/assets/images/3d_rotate.svg" draggable="false" (click)="rotate()" />
      <img
        src="/assets/images/zoom_in.svg"
        draggable="false"
        (mousedown)="startZoomIn()"
        (mouseup)="stopZoom()"
        (mouseout)="stopZoom()"
      />
      <img
        src="/assets/images/zoom_out.svg"
        draggable="false"
        (mousedown)="startZoomOut()"
        (mouseup)="stopZoom()"
        (mouseout)="stopZoom()"
      />
    </div>
  </div>

  <div
    id="configuratorForm"
    class="square-scroll"
    [class.full-form]="viewMode === 'full'"
    [class.modal-form]="viewMode === 'modal'"
    [class.modal-form-content]="contentModal"
  >
    <ng-container *ngIf="viewMode === 'full'">
      <div class="header-section flex items-center h-14 px-3">
        <h6>Asset Configuration</h6>
        <mat-icon class="cursor-pointer ml-auto" (click)="modalClose()">close</mat-icon>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- // TODO : LOCAL ONLY NOW | JUST UI -->
    <mat-expansion-panel class="mat-elevation-z0" expanded *ngIf="!prod">
      <mat-expansion-panel-header>
        <mat-panel-title> Presets </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="view-name">
        <form [formGroup]="form">
          <mat-form-field *ngIf="!editName">
            <mat-select formControlName="selectedViewDefTemplate" (selectionChange)="onSelectedViewDefChange($event)">
              <mat-option *ngFor="let view of []" [value]="view">
                {{ view?.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editName">
            <input
              #viewNameInput
              matInput
              maxlength="100"
              type="text"
              formControlName="viewName"
              id="viewName"
              name="viewName"
              (keydown.enter)="endNameEdit()"
              (blur)="endNameEdit()"
            />
          </mat-form-field>
        </form>

        <div class="flex">
          <button disableRipple="true" [disabled]="true" mat-button matTooltip="Edit name">
            <mat-icon> edit </mat-icon>
          </button>
          <button disableRipple="true" [disabled]="true" mat-button matTooltip="Save preset">
            <mat-icon> save </mat-icon>
          </button>
          <button disableRipple="true" [disabled]="true" mat-button matTooltip="Copy preset">
            <mat-icon> content_copy </mat-icon>
          </button>
          <button disableRipple="true" [disabled]="true" mat-button matTooltip="Delete preset">
            <mat-icon> delete_forever </mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>

    <form [formGroup]="assetForm">
      <mat-expansion-panel class="mat-elevation-z0" expanded *ngIf="!prod">
        <mat-expansion-panel-header>
          <mat-panel-title> Pivot Settings </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Property</mat-label>
          <mat-select formControlName="transform">
            <mat-option value="pos">Position</mat-option>
            <mat-option value="rot">Rotation</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Preview Settings </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Surface</mat-label>
          <mat-select formControlName="slotType">
            <mat-option value="hanged">Hanged</mat-option>
            <mat-option value="surface">Surface</mat-option>
            <mat-option value="focus">Focus View</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Scaling </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline" class="!w-20 !mr-3">
          <mat-label>Unit</mat-label>
          <mat-select formControlName="baseUnit">
            <mat-option value="mm">mm</mat-option>
            <mat-option value="cm">cm</mat-option>
            <mat-option value="m" selected>m</mat-option>
            <mat-option value="km">km</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="!w-28">
          <mat-label>Scale</mat-label>
          <input matInput type="number" formControlName="scale" />
        </mat-form-field>
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Pivot configuration </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex flex-col">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Pivot mode</mat-label>
            <mat-select formControlName="pivot">
              <mat-option value="asset">Asset</mat-option>
              <mat-option value="center">Center</mat-option>
              <mat-option value="bottom">Bottom</mat-option>
              <mat-option value="top">Top</mat-option>
            </mat-select>
          </mat-form-field>

          <p class="text-primary mb-1">Offset position</p>
          <div>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>X</mat-label>
              <input matInput type="number" formControlName="offsetX" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>Y</mat-label>
              <input matInput type="number" formControlName="offsetY" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>Z</mat-label>
              <input matInput type="number" formControlName="offsetZ" />
            </mat-form-field>
          </div>

          <p class="text-primary mb-1">Rotation in degrees</p>
          <div>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>X</mat-label>
              <input matInput type="number" formControlName="rotX" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>Y</mat-label>
              <input matInput type="number" formControlName="rotY" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="!w-20 mr-4">
              <mat-label>Z</mat-label>
              <input matInput type="number" formControlName="rotZ" />
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Compatible Slot Type </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex flex-col">
          <mat-checkbox class="!mb-2" formControlName="compatibleFocusView"> Focus View </mat-checkbox>
          <mat-checkbox class="!mb-2" formControlName="compatibleHanged"> Hanged </mat-checkbox>
          <mat-checkbox class="!mb-2" formControlName="compatibleSurface"> Surface </mat-checkbox>
          <mat-checkbox class="!mb-2" formControlName="compatibleShoe"> Shoe </mat-checkbox>
        </div>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </form>

    <div class="flex-center my-4">
      <button mat-raised-button color="primary" class="!mr-6" (click)="save()">SAVE</button>
      <button mat-raised-button color="primary" (click)="reset()">RESET</button>
    </div>
  </div>
</div>
