import { Component, OnInit, Output, Input, Inject, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-copy-rename-entity-form',
  templateUrl: './copy-rename-entity-form.component.html',
  styleUrls: ['./copy-rename-entity-form.component.scss'],
})
export class CopyRenameEntityFormComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityName: string;
  @Input() entityType: string;
  @Input() rename: boolean = false;
  @Output() renameEntity = new EventEmitter();
  @Output() copyEntity = new EventEmitter();
  @Output() onFormSubmitted = new EventEmitter();

  public isLoading = false;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
  });

  constructor(
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityId = data.entityId;
    this.entityName = data.entityName;
  }

  ngOnInit(): void {
    this.setDefault();
  }

  setDefault() {
    if (this.rename) {
      this.form.controls.name.setValue(this.entityName);
    } else {
      this.form.controls.name.setValue('Copy of ' + this.entityName);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  async onSubmit(data, id) {
    if (this.rename) {
      this.renameEntity.emit({ id, name: data.name });
    } else {
      this.copyEntity.emit({ id, name: data.name });
    }

    this.onFormSubmitted.emit(data);
  }
}
