import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DocumentService } from '../../document/document.service';
import { AggregateHelper } from '@contrail/aggregates';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-dev-tools-summary-panel',
  templateUrl: './dev-tools-summary-panel.component.html',
  styleUrls: ['./dev-tools-summary-panel.component.scss'],
})
export class DevToolsSummaryPanelComponent implements OnInit {
  public elements$;
  public elementTypeCounts: Object;
  constructor(public documentService: DocumentService) {}

  ngOnInit(): void {
    this.elements$ = this.documentService.documentElements;
    this.documentService.documentElements.forEach((el) => {});
    this.computeCounts();
  }

  private computeCounts() {
    this.documentService.documentElements
      .pipe(
        take(1),
        tap((elements) => {
          const elementTypes: Set<string> = new Set();
          elements.forEach((e) => elementTypes.add(e.type));
          this.elementTypeCounts = AggregateHelper.getValueCounts(elements, 'type', [...elementTypes]);
          console.log('counts:', this.elementTypeCounts);
        }),
      )
      .subscribe();
  }
}
