<app-modal [title]="title" [dialogRef]="dialogRef" class="modal relative">
  <div *ngIf="loading" class="absolute top-1/2 left-1/2 mt-[-20px] ml-[-20px]">
    <mat-spinner diameter="80"></mat-spinner>
  </div>
  <div class="mx-[10px]">
    <iframe
      id="frameTemplateEditor"
      style="height: 570px; width: 1150px"
      #iframe
      (load)="onLoad()"
      src="frame-template-editor"
    >
    </iframe>
  </div>
</app-modal>
