import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  HostListener,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { SearchBarComponent } from '@common/components/search-bar/search-bar.component';
import { SortDefinition, SortDirection } from '@common/components/sort/sort-definition';
import { ChooserFilterConfig } from '@common/item-data-chooser/chooser-sources/item-data-chooser-data-source';
import { FilterCriteria } from '@contrail/filters';
import { FilterDefinition, FilterPropertyDefinition } from '@common/types/filters/filter-definition';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { Observable, BehaviorSubject, Subscription, Subject, combineLatest } from 'rxjs';
import { tap, startWith, distinctUntilChanged } from 'rxjs/operators';
import { ChooserDataSource } from './chooser-source/chooser-data-source';
import { DocumentChooserDataSource } from './chooser-source/document-data-source';
import { LibraryChooserDataSource } from './chooser-source/library-chooser-data-source';
import { ChooserSourceOption } from './chooser-source/source-option';
import { LibraryChooserPaginatedDataSource } from './chooser-source/library-chooser-paginated-data-source';
import { LibrariesService } from '@common/workspaces/libraries.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const DEFAULT_SORTS: SortDefinition[] = [
  { direction: SortDirection.ASCENDING, propertySlug: 'name', propertyType: PropertyType.String },
];

@Component({
  selector: 'app-chooser',
  templateUrl: './chooser.component.html',
  styleUrls: ['./chooser.component.scss'],
})
export class ChooserComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() context: { [key: string]: any }; // The context is the contextual object for which you are seaching within (a PPH for example)
  @Input() resultsHeight = '100%';
  @Input() draggable = true;
  @Input() existingIds: Set<string>;
  @Input() showSearchBox = true;
  @Input() title = 'Chooser';
  @Input() showAllControl = true;
  @Input() showCount = true;
  @Input() showHeader = true;
  @Input() showFilter = true;
  @Input() allowAddMultipleEntities = false;
  @Input() criteria: any = {};
  @Input() QUICK_SEARCH_OPTIONS = 'name';
  @Input() filterTemplateName = '';
  @Input() viewTemplateName = '';
  @Input() allowAddEntity = false;
  @Input() allowAddDuplicate = true;
  @Input() allowCreateNew = false;
  @Input() allowSourceChange = true;
  @Input() entityType: string;
  @Input() typePath: string;
  @Input() template?: TemplateRef<any>;
  @Input() enableInfiniteScroll = false;

  @ViewChild('dataPane') dataPane: ElementRef;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(VirtualScrollerComponent) virtualScroller: VirtualScrollerComponent;

  private dataSub: Subscription;
  public data: Array<any>;
  public filteredResults$ = new Subject<Array<any>>();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterDefinition: FilterDefinition;
  private workspaceIdSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  private sortConfigSubject: BehaviorSubject<SortDefinition[]> = new BehaviorSubject(null);
  public dateFilter: FilterDefinition;
  private dateFilterSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  public count = -1;
  public showAllSubject = new BehaviorSubject(true);
  public existingEntityIdsSubject = new BehaviorSubject(new Set());
  public showSelectSource = false;
  public sourceAssortment: any;
  public dataSource: any;
  public dataSourceType: string;
  private chooserSourceSubscription: Subscription;
  private filterConfigSubject: BehaviorSubject<ChooserFilterConfig> = new BehaviorSubject(null);
  public selectedEntities: any[] = [];
  public selectAll = false;
  public numberOfEligibleResults = 0;
  public sortProperties: Array<SortDefinition>;
  public currentSorts: Array<SortDefinition> = [];
  public showAll = true;
  public dateFilterAttribute = {
    label: 'Recently added',
    attribute: 'createdOn',
  };
  public chooserSourceOption$: BehaviorSubject<ChooserSourceOption> = new BehaviorSubject(null);
  public searchBarSubscription: Subscription;
  activeEntityId: string;
  public maximumEntityCount = 2000;

  @Output() entitiesSelected = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() addEntity = new EventEmitter();
  @Output() addEntities = new EventEmitter();
  public breadcrumbs: Array<any> = [];

  constructor(
    private librariesService: LibrariesService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.initResultsObservable();
    const libCriteria = this.librariesService.libChooserCriteria$?.value;
    let chooserCriteria;
    if (this.entityType === 'asset' && libCriteria?.asset) {
      chooserCriteria = libCriteria.asset;
    } else if (this.entityType === 'color' && libCriteria?.color) {
      chooserCriteria = libCriteria.color;
    }

    if (chooserCriteria?.baseCriteria) {
      this.criteria = chooserCriteria?.baseCriteria;
    }
    if (chooserCriteria?.sortCriteria) {
      this.currentSorts = chooserCriteria?.sortCriteria;
    }
    if (chooserCriteria?.filterCriteria) {
      this.filterDefinition = { filterPropertyDefinitions: [], filterCriteria: chooserCriteria?.filterCriteria };
      this.filterDefinitionSubject.next(this.filterDefinition);
    }

    let chooserSourceOption: ChooserSourceOption;
    if (chooserCriteria?.chooserSource) {
      chooserSourceOption = chooserCriteria?.chooserSource;
      this.chooserSourceOption$.next(chooserSourceOption);

      const breadcrumbs = chooserCriteria?.breadcrumbs;
      if (chooserSourceOption.sourceType === 'LIBRARY' && breadcrumbs?.length > 1) {
        this.workspaceIdSubject.next(breadcrumbs[breadcrumbs.length - 1].id);
        this.breadcrumbs = breadcrumbs;
      }
    } else {
      chooserSourceOption = {
        sourceType: 'LIBRARY',
        name: this.entityType.toUpperCase() ?? 'Library',
        icon: this.entityType.toUpperCase() ? `assets/images/${this.entityType}.svg` : 'assets/images/item.svg',
      };
      this.chooserSourceOption$.next(chooserSourceOption);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.existingEntityIdsSubject.next(this.existingIds);
    if (changes.criteria) {
      if (ObjectUtil.compareDeep(changes.criteria.currentValue, changes.criteria.previousValue, '').length > 0) {
        this.initFilterObservable();
      }
    }
  }
  ngAfterViewInit(): void {
    this.initFilterObservable();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  startDrag(event) {
    const id = event.target.id;

    const entity: any = this.data.filter((e) => e.id === id).shift();
    entity.entityType = this.entityType;
    event.dataTransfer.setData('entity', JSON.stringify(entity));
    event.dataTransfer.setData('itemDataId', entity.id);
    event.dataTransfer.setData('dataObject', JSON.stringify(entity));
  }

  @HostListener('document: keydown', ['$event'])
  keyEvent(keyEvent: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(keyEvent.code)) {
      keyEvent.stopPropagation();
      keyEvent.preventDefault();
      let index = -1;
      if (this.data?.length === 0) {
        return;
      }
      if (!this.activeEntityId) {
        this.activeEntityId = this.data[0].id;
      } else {
        index = this.data.findIndex((entity) => entity.id === this.activeEntityId);
      }
      keyEvent.code === 'ArrowDown' ? index++ : index--;

      if (this.data[index]) {
        this.dataPane.nativeElement.focus();
        this.activeEntityId = this.data[index].id;
        this.virtualScroller.scrollToIndex(index);
      } else {
        this.virtualScroller.scrollToIndex(0);
        this.searchBar.focus();
        this.activeEntityId = null;
      }
    } else if (keyEvent.code === 'Enter' && this.activeEntityId) {
      keyEvent.stopPropagation();
      const selectedEntity = this.data.find((entity) => entity.id === this.activeEntityId);
      this.handleEntityClicked(selectedEntity);
    } else if (keyEvent.code === 'Escape') {
      this.handleClose();
    }
  }

  @HostListener('mousewheel', ['$event']) // for window scroll events
  onMouseWheel(event) {
    event.stopPropagation();
  }

  /**
   * Sets up filter definition & sort properties needed for the UI components
   * @param sourceType
   */
  async initFilterDefinition(sourceType: string) {
    this.dataSourceType = sourceType;
    let filterProperties: Array<TypeProperty> = [];
    if (this.entityType) {
      const type = await new Types().getType({ path: this.entityType, root: this.entityType });
      filterProperties = [...type?.typeProperties];
    }

    filterProperties = filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    this.filterDefinition = {
      filterPropertyDefinitions: filterProperties as Array<FilterPropertyDefinition>,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    this.sortProperties = filterProperties.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
  }

  initResultsObservable(): void {
    /**
     * Respond to changes in chooser source.  Create a new data source based on the selection.
     * Each data source is responsible for fetching & filtering data as appropriate based
     * on changes in the choosers 'filter config', which includes the search term and filter definition.
     */
    this.chooserSourceSubscription = this.chooserSourceOption$.subscribe((chooserSourceOption) => {
      if (!chooserSourceOption) {
        return;
      }

      this.dataSource?.cleanUp();
      this.dataSub?.unsubscribe();
      delete this.dataSource;
      this.breadcrumbs = [];
      if (chooserSourceOption.sourceType === 'LIBRARY') {
        const entityName = chooserSourceOption.name.toLowerCase();
        const libraryRootWorkspaceId = this.librariesService.getLibraryWorkspaceId(entityName);
        this.workspaceIdSubject.next(libraryRootWorkspaceId);
        const name = entityName.charAt(0).toUpperCase() + entityName.slice(1);
        this.breadcrumbs = [{ name: name, id: libraryRootWorkspaceId }];
        if (this.enableInfiniteScroll) {
          this.dataSource = new LibraryChooserPaginatedDataSource(
            this.entityType,
            this.typePath,
            this.workspaceIdSubject,
            this.filterConfigSubject,
            this.sortConfigSubject,
            this.existingEntityIdsSubject,
            this.showAllSubject,
            this.context,
          );
        } else {
          this.dataSource = new LibraryChooserDataSource(
            this.entityType,
            this.typePath,
            this.workspaceIdSubject,
            this.filterConfigSubject,
            this.sortConfigSubject,
            this.existingEntityIdsSubject,
            this.showAllSubject,
            this.context,
          );
        }
      } else if (chooserSourceOption.sourceType === 'DOCUMENT') {
        this.dataSource = new DocumentChooserDataSource(
          this.entityType,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingEntityIdsSubject,
          this.showAllSubject,
          chooserSourceOption.entityReference,
        );
      }
      if (this.dataSource) {
        this.dataSub = this.dataSource.results$.subscribe((results) => {
          this.data = results;
          this.numberOfEligibleResults = this.data.filter((entity) => !this.isExistingEntityOnSource(entity)).length;
          this.selectedEntities = [];
          this.activeEntityId = null;
        });
      }
      this.initFilterDefinition(chooserSourceOption.sourceType);
      this.sortData();
    });
  }

  handleScrollEnd(event) {
    const isScrollAtEndOfContent =
      this.data?.length > 0 && event.endIndex > 0 && event.endIndex === this.data.length - 1;
    if (
      this.enableInfiniteScroll &&
      isScrollAtEndOfContent &&
      this.dataSource instanceof LibraryChooserPaginatedDataSource &&
      this.dataSource.nextPageKey
    ) {
      this.dataSource.getMorePaginatedResults();
    }
  }

  initFilterObservable() {
    this.searchBarSubscription?.unsubscribe();
    const filterDefinition = this.filterDefinitionSubject.value;
    this.filterDefinitionSubject.next({
      ...filterDefinition,
      filterCriteria: { propertyCriteria: [] },
    });
    if (this.searchBar) {
      this.searchBarSubscription = combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), distinctUntilChanged()),
        this.filterDefinitionSubject.asObservable(),
      ])
        .pipe(
          tap(async ([searchTerm, filterDefinition]) => {
            const filterConfig = {
              searchTerm,
              filterDefinition,
              baseCriteria: this.criteria,
            };
            this.selectedEntities = [];
            this.filterConfigSubject.next(filterConfig);
          }),
        )
        .subscribe();
    }
  }

  isExistingEntityOnSource(entity) {
    if (this.existingIds) {
      return this.existingIds.has(entity.id);
    }
  }
  handleEntityClicked(entity) {
    if (entity.entityType === 'workspace') {
      this.workspaceIdSubject.next(entity.id);
      const folder = { name: entity.name, id: entity.id };
      this.breadcrumbs.push(folder);
      this.saveChooserCriteria();
      return;
    }
    this.entitiesSelected.emit([entity]);
  }
  goToFolder(folder, idx) {
    this.breadcrumbs = this.breadcrumbs.slice(0, idx + 1);
    this.workspaceIdSubject.next(folder.id);
    this.saveChooserCriteria();
  }

  handleClose() {
    this.close.emit();
  }

  sortData() {
    const sorts = this.currentSorts.length > 0 ? this.currentSorts : DEFAULT_SORTS;
    this.sortConfigSubject.next(sorts);
  }

  trackByFn(index, entity: any) {
    return entity.id;
  }

  toggleSourceSourceSelector(val) {
    this.searchBarSubscription.unsubscribe();
    this.showSelectSource = val;
    setTimeout(() => {
      this.initFilterObservable();
    }, 1);
  }

  handleSourceChange(source) {
    this.chooserSourceOption$.next(source);
    this.selectedEntities = [];
    this.toggleSourceSourceSelector(false);
    this.saveChooserCriteria();
    this.clearSelectedEntities();
  }

  setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition.filterCriteria = filterCriteria;
      this.filterDefinitionSubject.next(this.filterDefinition);
      this.saveChooserCriteria();
    }
  }
  clearFilters() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.filterDefinitionSubject.next(this.filterDefinition);
    this.searchBar.clear();
  }

  performSort(event) {
    this.currentSorts = event.sorts;
    this.sortData();
    this.saveChooserCriteria();
  }
  handleToggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedEntities = [];
      this.data.forEach((entity) => {
        if (!this.isExistingEntityOnSource(entity) && entity.entityType === this.entityType) {
          this.selectedEntities.push(ObjectUtil.cloneDeep(entity));
        }
      });
    } else {
      this.selectedEntities = [];
    }
  }
  toggleShowAll(event) {
    this.showAll = !event.checked;
    this.showAllSubject.next(!event.checked);
  }

  toggleSelection(selection: any) {
    if (!selection.selected) {
      this.selectAll = false;
      this.selectedEntities.splice(
        this.selectedEntities.findIndex((selectedEntity) => selectedEntity.id === selection.entityData.id),
        1,
      );
    } else {
      this.selectedEntities.push(ObjectUtil.cloneDeep(selection.entityData));
    }
  }

  getSelectedEntityIndex(dataObj) {
    if (this.selectedEntities.length === 0) {
      return 0;
    }

    return this.selectedEntities?.findIndex((selectedEntity) => selectedEntity.id === dataObj.id) + 1;
  }

  dateFilterChanged(dateFilter) {
    this.dateFilter = dateFilter;
    this.dateFilterSubject.next(dateFilter);
  }

  private saveChooserCriteria() {
    let libCriteria = this.librariesService.libChooserCriteria$.value;
    const chooserCriteria = {
      baseCriteria: this.criteria,
      filterCriteria: this.filterDefinition.filterCriteria,
      sortCriteria: this.currentSorts,
      chooserSource: this.chooserSourceOption$.value,
      breadcrumbs: this.breadcrumbs,
    };
    if (this.entityType === 'asset') {
      libCriteria = {
        ...libCriteria,
        asset: { ...chooserCriteria },
      };
    } else if (this.entityType === 'color') {
      libCriteria = {
        ...libCriteria,
        color: { ...chooserCriteria },
      };
    } else {
      return;
    }

    this.librariesService.saveLibChooserCriteria(libCriteria);
  }

  private clearSelectedEntities() {
    this.selectedEntities = [];
    this.selectAll = false;
  }

  private async addChooserEntities(entities: Object[]) {
    if (entities.length > this.maximumEntityCount) {
      this.snackBar.open(
        `You can only add up to ${this.maximumEntityCount} colors at a time. Please add a filter and retry.`,
        '',
        { duration: 5000 },
      );
    } else {
      this.addEntities.emit(entities);
      this.clearSelectedEntities();
    }
  }

  async handleAddSelectedEntities() {
    this.addChooserEntities(this.selectedEntities);
  }

  async handleAddSelectedEntity(entity) {
    this.addChooserEntities([entity]);
  }
}
