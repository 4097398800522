import { Subject, Observable } from 'rxjs';

export enum SvgSelectionType {
  Selection,
  Highlight,
}

export class SvgSelectionEvent {
  selectionType: SvgSelectionType;
  affectedElements: SVGElement[];
}

/**
 * Manages group of selected SVGElements
 */
export class SvgElementSelectionManager {
  highlightedElements: SVGElement[];
  selectedElements: SVGElement[];

  private onSelectionStateAddedSubject: Subject<SvgSelectionEvent> = new Subject();
  onSelectionStateAdded: Observable<SvgSelectionEvent> = this.onSelectionStateAddedSubject.asObservable();

  private onSelectionStateRemovedSubject: Subject<SvgSelectionEvent> = new Subject();
  onSelectionStateRemoved: Observable<SvgSelectionEvent> = this.onSelectionStateRemovedSubject.asObservable();

  constructor() {
    this.highlightedElements = new Array<SVGElement>();
    this.selectedElements = new Array<SVGElement>();
  }

  isElementHighlighted(svgElement: SVGElement): boolean {
    return this.highlightedElements.includes(svgElement);
  }

  isElementSelected(svgElement: SVGElement): boolean {
    return this.selectedElements.includes(svgElement);
  }

  addToHighlight(svgElements: SVGElement[]): void {
    this.highlightedElements = this.highlightedElements.concat(svgElements);

    this.onSelectionStateAddedSubject.next({
      selectionType: SvgSelectionType.Highlight,
      affectedElements: svgElements,
    });
  }

  removeFromHighlight(svgElements: SVGElement[]): void {
    this.highlightedElements = this.highlightedElements.filter((e) => {
      return !svgElements.includes(e);
    });

    this.onSelectionStateRemovedSubject.next({
      selectionType: SvgSelectionType.Highlight,
      affectedElements: svgElements,
    });
  }

  replaceHighlight(svgElements: SVGElement[]): void {
    const removedElements = this.highlightedElements;
    this.highlightedElements = svgElements;

    if (removedElements.length) {
      this.onSelectionStateRemovedSubject.next({
        selectionType: SvgSelectionType.Highlight,
        affectedElements: removedElements,
      });
    }

    if (this.highlightedElements.length) {
      this.onSelectionStateAddedSubject.next({
        selectionType: SvgSelectionType.Highlight,
        affectedElements: svgElements,
      });
    }
  }

  clearHighlight(emitEvent: boolean = true): void {
    const removedElements = this.highlightedElements;
    this.highlightedElements = [];

    if (emitEvent) {
      this.onSelectionStateRemovedSubject.next({
        selectionType: SvgSelectionType.Highlight,
        affectedElements: removedElements,
      });
    }
  }

  // Adds element to selection unless already present, in which case removes element from selection
  addOrRemoveFromSelection(svgElement: SVGElement): void {
    if (this.selectedElements.includes(svgElement)) {
      this.removeFromSelection([svgElement]);
    } else {
      this.addToSelection([svgElement]);
    }
  }

  addToSelection(svgElements: SVGElement[]): void {
    this.selectedElements = this.selectedElements.concat(svgElements);

    this.onSelectionStateAddedSubject.next({
      selectionType: SvgSelectionType.Selection,
      affectedElements: svgElements,
    });
  }

  removeFromSelection(svgElements: SVGElement[]): void {
    this.selectedElements = this.selectedElements.filter((e) => {
      return !svgElements.includes(e);
    });

    this.onSelectionStateRemovedSubject.next({
      selectionType: SvgSelectionType.Selection,
      affectedElements: svgElements,
    });
  }

  setSelection(svgElements: SVGElement[]): void {
    this.selectedElements = svgElements;
  }

  replaceSelection(svgElements: SVGElement[]): void {
    const removedElements = this.selectedElements;
    this.selectedElements = svgElements;

    this.onSelectionStateRemovedSubject.next({
      selectionType: SvgSelectionType.Selection,
      affectedElements: removedElements,
    });

    this.onSelectionStateAddedSubject.next({
      selectionType: SvgSelectionType.Selection,
      affectedElements: svgElements,
    });
  }

  clearSelection(emitEvent: boolean = true): void {
    const removedElements = this.selectedElements;
    this.selectedElements = [];

    if (emitEvent) {
      this.onSelectionStateRemovedSubject.next({
        selectionType: SvgSelectionType.Selection,
        affectedElements: removedElements,
      });
    }
  }
}
