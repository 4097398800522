<mat-tab-group #tabGroup="matTabGroup" class="template-tab-groups" (selectedTabChange)="onTabChange($event)">
  <mat-tab *ngFor="let templateType of templateTypes; let i = index">
    <ng-template mat-tab-label>
      <span
        [attr.data-test]="'document-add-frame-template-type-' + templateType.value"
        class="template-label"
        (click)="handleTemplateClick($event); tabGroup.selectedIndex = i"
        >{{ templateType.label }}</span
      >
    </ng-template>
    <div class="h-[50px] p-2" (click)="handleTemplateClick($event)">
      <app-search-bar
        #chooserTemplateSearchInput
        [lgGray]="false"
        [placeholder]="'Search templates'"
        (valueChange)="handleFilterTemplateByName($event, templateType.value)"
      ></app-search-bar>
    </div>
    <mat-selection-list class="template-menu">
      <div
        mat-menu-item
        *ngFor="let frameTemplate of frameTemplates[templateType.value]"
        class="menu-item !pt-2 !flex !flex-col !items-center !justify-center !w-[300px] !h-[165px]"
        mat-menu-item
        (click)="selectTemplate(frameTemplate)"
        [attr.data-test]="'document-add-frame-template-' + frameTemplate.id"
      >
        <img
          class="h-[135px]"
          *ngIf="frameTemplate.previewFile?.downloadUrl"
          [src]="frameTemplate.previewFile?.downloadUrl"
        />
        <div
          class="text-[12px] text-center -mt-[10px] text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap w-full"
        >
          {{ frameTemplate.name }}
        </div>
      </div>
    </mat-selection-list>
  </mat-tab>
</mat-tab-group>
