import { ACCENT_COLOR } from '@contrail/svg';
import { ACCENT_COLOR_25 } from '../../../../constants';
import { Table, TableColumn, TableRow } from '../table/table';
import { TableHoverRenderer } from '../table-hover/table-hover-renderer';
import { TableReorderState } from './table-reoder-state';

export class TableReorderRenderer {
  constructor(
    private table: Table,
    private state: TableReorderState,
  ) {}

  public render(ctx, viewScale, params: { x; y; width; height }) {
    this.renderTargetLine(ctx, viewScale, params);
    this.renderSelectedArea(ctx, viewScale, params);
  }

  private renderTargetLine(ctx, viewScale, params: { x; y; width; height }) {
    if (this.state.targetArea != null) {
      const isColumn = 'x' in this.state.targetArea;
      let x1, y1, x2, y2;
      if (isColumn) {
        const targetColumn = this.state.targetArea as TableColumn;
        const columnWidth = this.state.selectedArea.index < targetColumn.index ? targetColumn.width : 0;
        x1 = params.x + targetColumn.x + columnWidth;
        y1 = params.y;
        x2 = params.x + targetColumn.x + columnWidth;
        y2 = params.y + params.height;
      } else {
        const targetRow = this.state.targetArea as TableRow;
        const rowHeight = this.state.selectedArea.index < targetRow.index ? targetRow.height : 0;
        x1 = params.x;
        y1 = params.y + targetRow.y + rowHeight;
        x2 = params.x + params.width;
        y2 = params.y + targetRow.y + rowHeight;
      }

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.strokeStyle = ACCENT_COLOR;
      ctx.lineWidth = 6;
      ctx.stroke();
      ctx.closePath();
    }
  }

  private renderSelectedArea(ctx, viewScale, params: { x; y; width; height }) {
    if (this.state.targetArea != null) {
      const isColumn = 'x' in this.state.targetArea;
      let x, y, width, height, areaX, areaY;
      if (isColumn) {
        const column = this.state.selectedArea as TableColumn;
        width = column.width;
        height = params.height;
        x = Math.min(
          params.x + params.width - width,
          Math.max(params.x, params.x + this.state.currentMousePosition.x - width * 0.5),
        );
        y = params.y;
        areaX = params.x + column.x;
        areaY = y;

        TableHoverRenderer.renderDragHandle(
          ctx,
          viewScale,
          {
            x: x + width * 0.5,
            y,
          },
          true,
          ACCENT_COLOR,
        );
      } else {
        const row = this.state.selectedArea as TableRow;
        width = params.width;
        height = row.height;
        x = params.x;
        y = Math.min(
          params.y + params.height - height,
          Math.max(params.y, params.y + this.state.currentMousePosition.y - height * 0.5),
        );
        areaX = x;
        areaY = params.y + row.y;

        TableHoverRenderer.renderDragHandle(
          ctx,
          viewScale,
          {
            x,
            y: y + height * 0.5,
          },
          false,
          ACCENT_COLOR,
        );
      }

      // // REDO: render selected range for column/row, temp hide other selected ranges while dragging
      // ctx.beginPath();
      // ctx.rect(areaX, areaY, width, height);
      // ctx.strokeStyle = ACCENT_COLOR;
      // ctx.lineWidth = 1;
      // ctx.stroke();
      // ctx.closePath();

      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.strokeStyle = ACCENT_COLOR;
      ctx.lineWidth = 1;
      ctx.fillStyle = ACCENT_COLOR_25;
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
    }
  }
}
