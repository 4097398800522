import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { FrameDragHandler } from './frame-drag-handler';
import { GuidelineHandler } from './guideline-handler/drag-guideline-handler';

export class ElementCopyDragHandler {
  private startingPosition: PositionDefinition;
  private selectedElement: CanvasElement;
  private selectedElements: CanvasElement[] = [];
  private startingElements: Map<string, DocumentElement> = new Map();

  private frameDragHandler: FrameDragHandler;
  private guidelineHandler: GuidelineHandler;

  constructor(private canvasDocument: CanvasDocument) {
    this.frameDragHandler = new FrameDragHandler(canvasDocument);
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect() || elementTarget?.element?.elementDefinition?.isLocked)
      return;

    const { element, target } = elementTarget;
    if (element && [DRAG_DIRECTIONS.BODY, DRAG_DIRECTIONS.EDIT].indexOf(target) !== -1) {
      this.startingPosition = { x: event.clientX, y: event.clientY };

      let lastFrameNumber;
      this.selectedElements = this.canvasDocument.interactionHandler.selectionHandler
        .getSelectedCanvasElements()
        .map((canvasElement) => {
          const copied = canvasElement.copy();
          if (canvasElement?.elementDefinition?.type === 'frame') {
            lastFrameNumber =
              lastFrameNumber != undefined
                ? lastFrameNumber + 1
                : this.canvasDocument.documentService.getNewFrameNumber();
            copied.elementDefinition.name = `Frame ${lastFrameNumber}`;
          }
          return copied;
        });
      this.selectedElement = this.selectedElements?.find((e) => e.id === element.id);

      this.canvasDocument.canvasRenderer.canvasCopyElementsRenderer.elements = this.selectedElements;

      this.selectedElements.map((canvasElement) => {
        this.startingElements.set(canvasElement.id, ObjectUtil.cloneDeep(canvasElement.elementDefinition));
      });

      this.guidelineHandler = new GuidelineHandler(this.canvasDocument);
      this.guidelineHandler.dragstarted(event, { element: this.selectedElement, target: elementTarget.target });
    }
  }

  public dragged(event) {
    if (this.selectedElements.length === 0) return;

    const scale = this.canvasDocument.getViewScale();

    let distanceX = event.clientX - this.startingPosition.x;
    let distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementsPosition(distanceX / scale.x, distanceY / scale.y);
    this.guidelineHandler?.dragged(event);
    this.frameDragHandler?.handleElementDragged({
      element: this.selectedElement,
      selectedElements: this.selectedElements,
    });
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (this.selectedElements.length === 0) return;

    const scale = this.canvasDocument.getViewScale();

    let distanceX = event.clientX - this.startingPosition.x;
    let distanceY = event.clientY - this.startingPosition.y;

    if (
      this.guidelineHandler?.hasSnapped ||
      this.updateSelectedElementsPosition(distanceX / scale.x, distanceY / scale.y)
    ) {
      const newElements = this.selectedElements.map((canvasElement: CanvasElement) =>
        ObjectUtil.cloneDeep(canvasElement.elementDefinition),
      );
      this.clear();
      this.canvasDocument.deselectAll();
      this.canvasDocument.actionsDispatcher.handleUndoableAdds(newElements);
      this.guidelineHandler?.dragended(event);
      this.frameDragHandler?.clear();
      this.canvasDocument.draw();
    }

    this.clear();
  }

  private clear() {
    this.startingElements.clear();
    this.canvasDocument.canvasRenderer.canvasCopyElementsRenderer.elements = [];
    this.selectedElements = [];
    this.selectedElement = null;
    this.startingPosition = null;
    this.guidelineHandler = null;
  }

  private updateSelectedElementsPosition(dx, dy): boolean {
    for (let i = 0; i < this.selectedElements?.length; i++) {
      const element = this.selectedElements[i];
      const oldElement = this.startingElements.get(element.id);
      if (oldElement) {
        if (element.elementDefinition.type === 'line') {
          if (oldElement.lineDefinition) {
            const lineDefinition = ObjectUtil.cloneDeep(oldElement.lineDefinition);
            (lineDefinition.x1 = oldElement.lineDefinition.x1 + dx),
              (lineDefinition.y1 = oldElement.lineDefinition.y1 + dy),
              (lineDefinition.x2 = oldElement.lineDefinition.x2 + dx),
              (lineDefinition.y2 = oldElement.lineDefinition.y2 + dy);
            element.elementDefinition.lineDefinition = lineDefinition;
          }
        } else {
          const { x, y } = { x: oldElement.position.x + dx, y: oldElement.position.y + dy };
          element.elementDefinition = ObjectUtil.mergeDeep(element.elementDefinition, {
            position: {
              x,
              y,
            },
          });
        }
      }
    }
    return true;
  }
}
