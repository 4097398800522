import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { ActionRequest } from '@contrail/actions';
import { DocumentService } from '../../document.service';
import { AddHotSpotModalComponent } from './add-hot-spot-modal/add-hot-spot-modal.component';

@Injectable({
  providedIn: 'root',
})
export class HotSpotService {
  private actionRequest: ActionRequest;
  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
  ) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'init_hot_spot') {
        this.actionRequest = request;
        this.initAddHotSpot();
      }
    });
  }

  initAddHotSpot() {
    console.log('init add hot spot...');
    const dialogRef = this.dialog.open(AddHotSpotModalComponent, { data: {}, autoFocus: false });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  addHotSpot(entity: any) {
    console.log('HotSpotService: addHotSpot', entity, this.actionRequest.sourceEvent.element);
    const imageElement = this.actionRequest.sourceEvent.element;
    imageElement.elements = imageElement.elements || [];
    imageElement.elements.push({
      type: 'hot_spot',
      modelBindings: { entityReference: 'item-color:' + entity.id },
      position: {
        x: this.actionRequest.sourceEvent.relativeMousePosition.x,
        y: this.actionRequest.sourceEvent.relativeMousePosition.y,
      },
    });

    const action = new DocumentAction({
      changeType: DocumentChangeType.MODIFY_ELEMENT,
      elementId: imageElement.id,
      elementData: {
        id: imageElement.id,
        elements: imageElement.elements,
      },
    });
    this.documentService.handleDocumentActions([action]);
  }
}
