<button [matMenuTriggerFor]="aboveMenu">
  <app-avatar [size]="'40px'" [user]="(authContext$ | async)?.user" data-test="org-avatar-button"></app-avatar>
</button>
<mat-menu #aboveMenu="matMenu" yPosition="below" xPosition="before" class="menu-md">
  <!-- <div class="user-name">{{ (authContext$ | async)?.user?.firstName }} {{ (authContext$ | async)?.user?.lastName }}</div> -->

  <button class="settings-button" mat-menu-item (click)="profileSettings()" data-test="settings-button">
    <span class="material-icons-outlined text-xl"> settings </span> <span class="ml-2">Settings</span>
  </button>
  <button class="org-button" mat-menu-item (click)="logout()" data-test="logout-button">
    <span class="material-icons-outlined text-xl"> logout </span> <span class="ml-2">Logout</span>
  </button>
</mat-menu>
