import { CanvasDocument } from '../../canvas-document';
import { ViewBox } from '@contrail/documents';
import { ACCENT_COLOR } from '../../constants';

export class BlueBoxRenderer {
  public box: ViewBox;

  private readonly STROKE_WIDTH = 1;
  private readonly STROKE_COLOR = ACCENT_COLOR;
  private readonly FILL_COLOR = 'rgba(0, 176, 255, 0.2)';

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw selection boxes for selected elements
   */
  public draw() {
    if (this.box) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const { x, y, width, height } = this.box;
      const size = this.canvasDocument.toDocumentSize(width, height);
      const position = this.canvasDocument.toDocumentPosition(x, y);
      ctx.beginPath();
      ctx.rect(position.x, position.y, size.width, size.height);
      ctx.fillStyle = this.FILL_COLOR;
      ctx.strokeStyle = this.STROKE_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
    }
  }
}
