import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Entities, Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { TypePropertyFormComponent } from '../../types/forms/type-property-form/type-property-form.component';
import { Item } from '../item';
import { ItemPropertiesFormComponent } from '../item-properties-form/item-properties-form.component';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss'],
})
export class CreateItemComponent implements OnInit, AfterViewInit {
  @ViewChild(ItemPropertiesFormComponent) propertyForm: ItemPropertiesFormComponent;
  @Output() createComplete: EventEmitter<Item> = new EventEmitter();
  public type: Type;
  private newObject;
  constructor() {}

  async ngOnInit() {
    this.type = await new Types().getByRootAndPath({ root: 'item', path: 'item' });
    this.newObject = {
      typeId: this.type.id,
      optionGroup: 'color',
    };
  }
  ngAfterViewInit() {
    this.propertyForm.itemChanges.subscribe((formChanges) => this.updateEntityValues(formChanges));
  }
  updateEntityValues(formChange) {
    const change = {};
    change[formChange.propertySlug] = formChange.value;
    Object.assign(this.newObject, change);
    console.log('new Object: ', this.newObject);
  }

  async create() {
    const item = await new Entities().create({ entityName: 'item', object: this.newObject });
    this.createComplete.emit(item);
  }
  cancel() {}
  isValid() {
    return this.newObject?.name && this.newObject?.optionName;
  }
}
