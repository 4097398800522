import { CanvasDocument } from '../../../canvas-document';
import { EditorContainer } from '../../../components/editor/editor-container';
import { CanvasCellElement } from '../canvas-cell-element';
import { tableEditorId } from './table-editor';
import { TableEditorManager } from './table-editor-manager';

export class TableEditorContainer extends EditorContainer {
  constructor(canvasDocument: CanvasDocument, manager: TableEditorManager) {
    super(canvasDocument, tableEditorId, manager);
  }

  public handleKeydownEvent(event) {
    if (event.code === 'Tab') {
      const element = this.manager.element as CanvasCellElement;
      if (event.shiftKey) {
        element.table.moveToPrevTableCell({ advanceRow: true });
      } else {
        element.table.moveToNextTableCell({ advanceRow: true });
      }
      event.preventDefault();
    }
  }

  public handleInputEvent(event) {
    if (!this.manager.element || this.manager.element.elementDefinition.type !== 'cell') {
      return;
    }

    this.autoGrowCellAndRow();
    this.manager.editor.setContentStyle();
  }

  /**
   * Increase or decrease cell element height on input event.
   * Cell element height should always equal actual text height.
   * Update row height if it's less than cell height.
   * @param event
   */
  private autoGrowCellAndRow() {
    const target = this.manager.editorContainer.editorContainer as HTMLElement;
    const tableManager = this.manager as TableEditorManager;
    const text = this.manager.getInnerHTML();
    const adjustedElement = (this.manager.element as CanvasCellElement).autoAdjustSize({ force: true, text });
    const height = adjustedElement?.size?.height;
    if (height !== target.clientHeight) {
      this.manager.editorContainer.currentContainerHeight = height;
      this.manager.editorContainer.editorContainer.style.height = `${height}px`;

      if (height > tableManager.rowElementDefinition.size.height) {
        const cellElement = this.manager.element as CanvasCellElement;
        cellElement.table.resizeRow(cellElement.rowIndex, height);
      }
      this.canvasDocument.draw();
    }
  }
}
