<div *ngIf="!(loaded$ | async)" class="loading-page">
  <img class="logo" src="assets/images/vibeiq_large.jpg" alt="Vibe IQ logo" />
  <mat-spinner diameter="60"></mat-spinner>
  <span>{{ message }}</span>
</div>
<div class="app-component">
  <!-- It is important not to set 'hidden' class here while it's loading because in that case SVG
  is trying to render but not able to find elements and get their bounding box since they
  are hidden with CSS. -->
  <router-outlet></router-outlet>
</div>
