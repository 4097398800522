import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { FilterConditionType } from '@common/types/filters/filter-condition-types';
import { FilterPropertyCriteria } from '@common/types/filters/filter-criteria';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { FilterObjects } from '@contrail/filters';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { DocumentHistoryActions, DocumentHistorySelectors } from '../document-history-store';
import { EntitySnapshotService } from '../entity-snapshot.service';

@Component({
  selector: 'app-document-history-panel',
  templateUrl: './document-history-panel.component.html',
  styleUrls: ['./document-history-panel.component.scss'],
})
export class DocumentHistoryPanelComponent implements OnInit, OnDestroy {
  @Input() document: any;
  @Input() documentReference: any;
  public loadingSubject: Subject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();
  @Input() resultsHeight = '100%';
  public history: Array<any>;
  public filteredHistory: Array<any>;
  public allowEdit: boolean;

  public dateFilterAttribute = {
    label: 'Date range',
    attribute: 'createdOn',
  };
  includeUserPropertyCriteria = false;
  userPropertyCriteria: FilterPropertyCriteria;
  public filter: FilterDefinition;
  currentEntitySnapshot: any;
  subscriptions: Subscription = new Subscription();
  constructor(
    private entitySnapshotService: EntitySnapshotService,
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.subscriptions.add(
      this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
        this.userPropertyCriteria = {
          filterConditionType: FilterConditionType.EQUALS,
          filterPropertyDefinition: {
            slug: 'createdById',
            label: '',
            propertyType: PropertyType.Text,
          },
          criteriaValue: authContext.user.id,
        };
      }),
    );
    this.subscriptions.add(
      this.store.select(DocumentHistorySelectors.currentEntitySnapshot).subscribe((currentEntitySnapshot) => {
        this.currentEntitySnapshot = currentEntitySnapshot;
      }),
    );

    if (this.document) {
      // fetch history
      this.loadingSubject.next(true);
      let history = await this.entitySnapshotService.getEntitySnapshotsByEntityReference(this.documentReference);
      history = history.sort((h1, h2) => (h1.createdOn > h2.createdOn ? -1 : 1));
      this.history = history;
      this.filteredHistory = history;
      this.loadingSubject.next(false);
    }

    this.allowEdit = !this.authService.isOnSharedLink();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async loadHistorySnapshot(snapshot) {
    const id = snapshot.id;
    this.store.dispatch(DocumentHistoryActions.loadCurrentShowcase({ id }));
  }

  handleClick(event) {
    event.stopPropagation();
  }

  toggleMyUpdates(event) {
    this.includeUserPropertyCriteria = event.checked;
    if (event.checked) {
      if (!this.filter) {
        this.filter = {
          filterPropertyDefinitions: [],
          filterCriteria: {
            propertyCriteria: [],
          },
        };
      }
      this.filter.filterCriteria.propertyCriteria.push(this.userPropertyCriteria);
    } else {
      const index = this.filter.filterCriteria.propertyCriteria.findIndex(
        (propertyCriteria) => propertyCriteria.filterPropertyDefinition.slug === 'createdById',
      );
      this.filter.filterCriteria.propertyCriteria.splice(index, 1);
    }
    this.filteredHistory = FilterObjects.filter(this.history, this.filter.filterCriteria);
  }

  dateFilterChanged(dateFilter) {
    this.filter = ObjectUtil.cloneDeep(dateFilter);
    if (this.includeUserPropertyCriteria) {
      this.filter.filterCriteria.propertyCriteria.push(this.userPropertyCriteria);
    }
    this.filteredHistory = FilterObjects.filter(this.history, this.filter?.filterCriteria);
  }

  restoreSnapshot(id: string) {
    this.store.dispatch(DocumentHistoryActions.restoreEntitySnapshot({ id }));
  }
}
