import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR, EDITING_COLOR, GREY_COLOR } from '../../constants';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasImageDrawableElement } from '../../elements/image/canvas-image-drawable-element';
import { CanvasImageElement } from '../../elements/image/canvas-image-element';
import { DrawOptions } from '../canvas-renderer';
import { RotationHelper } from '../rotation-widget-renderer/rotation-helper';

export enum DRAG_DIRECTIONS {
  TOP_LEFT = 'top_left',
  TOP_CENTER = 'top_center',
  TOP_RIGHT = 'top_right',
  MID_RIGHT = 'mid_right',
  BOTTOM_RIGHT = 'bottom_right',
  BOTTOM_CENTER = 'bottom_center',
  BOTTOM_LEFT = 'bottom_left',
  MID_LEFT = 'mid_left',
  TOP_LINE = 'top_line',
  BOTTOM_LINE = 'bottom_line',
  BODY = 'body',
  BORDER = 'border',
  ROTATE = 'rotate',
  EDIT = 'edit',
  CROP_TOP_LEFT = 'crop_top_left',
  CROP_TOP_CENTER = 'crop_top_center',
  CROP_TOP_RIGHT = 'crop_top_right',
  CROP_MID_RIGHT = 'crop_mid_right',
  CROP_BOTTOM_RIGHT = 'crop_bottom_right',
  CROP_BOTTOM_CENTER = 'crop_bottom_center',
  CROP_BOTTOM_LEFT = 'crop_bottom_left',
  CROP_MID_LEFT = 'crop_mid_left',
  CROP_BODY = 'crop_body',
}

export abstract class SelectionWidgetRenderer {
  protected readonly STROKE_WIDTH = 1;
  protected readonly DRAG_HANDLE_WIDTH = 6;
  public DRAG_HANDLES = [
    'top_left',
    'top_center',
    'top_right',
    'center_right',
    'bottom_right',
    'bottom_center',
    'bottom_left',
    'center_left',
  ];

  useEditingColor: boolean = false;

  constructor(
    protected canvasDocument: CanvasDocument,
    protected element?: CanvasElement,
  ) {}

  public getHandleWidth() {
    const viewScale = this.canvasDocument.getViewScale();
    return Math.round(this.DRAG_HANDLE_WIDTH / viewScale.x);
    // return Math.max(this.DRAG_HANDLE_WIDTH, Math.round(this.DRAG_HANDLE_WIDTH / viewScale.x));
  }

  public dragHandleDelta() {
    const scale = this.canvasDocument.getViewScale().x;

    let radius = (this.DRAG_HANDLE_WIDTH * 2) / scale;
    radius = Number.parseFloat(radius.toFixed(1));
    radius = Math.min(Math.max(this.DRAG_HANDLE_WIDTH, radius), this.DRAG_HANDLE_WIDTH * 6);
    return radius;
  }

  public draw(ctx, drawHandles = true) {
    let dragHandles = [...this.DRAG_HANDLES];
    const options: DrawOptions = {
      outerEdgeDimensions: true,
    };
    const isCropping = this.canvasDocument.isCropping(this.element?.elementDefinition?.id);
    if (isCropping) {
      options.uncroppedDimensions = true;
      const imageElement = this.element as CanvasImageDrawableElement;
      const imageSize = imageElement?.imageSize;
      if (!imageElement.isCropped() || !imageSize) {
        dragHandles = [];
      } else {
        if (imageSize) {
          const crop = imageElement.getCropDefinition();
          const filterDragHandles = ['top_center', 'center_right', 'bottom_center', 'center_left'];
          if (!crop?.x1 && !crop?.y1) {
            filterDragHandles.push('top_left');
          }
          if (!crop?.y1 && crop?.x1 + crop?.width === imageSize.width) {
            filterDragHandles.push('top_right');
          }
          if (crop?.x1 + crop?.width === imageSize.width && crop?.y1 + crop?.height === imageSize.height) {
            filterDragHandles.push('bottom_right');
          }
          if (!crop?.x1 && crop?.y1 + crop?.height === imageSize.height) {
            filterDragHandles.push('bottom_left');
          }
          dragHandles = dragHandles.filter((h) => !filterDragHandles.includes(h));
        }
      }
    }
    let { x, y, width, height } = this.element.getDimensions(options);
    if (this.element.isImageError) {
      drawHandles = false;
    }

    ctx.save();

    const locked = this.element?.elementDefinition?.isLocked || false;
    this.drawWidget(ctx, { x, y, width, height }, locked);

    const dragHandleWidth = this.getHandleWidth();
    const dragHandlePositions = [];
    for (let i = 0; i < dragHandles.length; i++) {
      dragHandlePositions.push(this.getDragHandlePosition(dragHandles[i], width, height));
    }

    if (drawHandles) {
      for (let i = 0; i < dragHandlePositions?.length; i++) {
        const dragHandlePosition = dragHandlePositions[i];
        this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
      }
    }

    ctx.restore();
  }

  private getDragHandlePosition(position, x, y) {
    let p;
    switch (position) {
      case 'top_left':
        p = [-x * 0.5, -y * 0.5];
        break;
      case 'top_center':
        p = [0, -y * 0.5];
        break;
      case 'top_right':
        p = [x * 0.5, -y * 0.5];
        break;
      case 'center_right':
        p = [x * 0.5, 0];
        break;
      case 'bottom_right':
        p = [x * 0.5, y * 0.5];
        break;
      case 'bottom_center':
        p = [0, y * 0.5];
        break;
      case 'bottom_left':
        p = [-x * 0.5, y * 0.5];
        break;
      case 'center_left':
        p = [-x * 0.5, 0];
        break;
      default:
        break;
    }
    return p;
  }

  protected drawWidget(ctx, { x, y, width, height }, locked?: boolean) {
    ctx.beginPath();

    ctx.translate(x + width * 0.5, y + height * 0.5);
    ctx.rotate(CanvasUtil.getAngle(this.element?.elementDefinition?.rotate?.angle ?? 0));

    ctx.rect(-width * 0.5, -height * 0.5, width, height);

    ctx.strokeStyle = locked ? GREY_COLOR : this.useEditingColor ? EDITING_COLOR : ACCENT_COLOR;
    ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
    ctx.stroke();
    ctx.closePath();
  }

  protected drawDragHandle(ctx, x, y, r) {
    if (this.element?.elementDefinition?.isLocked) {
      return;
    }

    ctx.beginPath();
    ctx.ellipse(x, y, r, r, 0, 0, 2 * Math.PI);

    ctx.fillStyle = ACCENT_COLOR;
    ctx.fill();
    ctx.closePath();
  }

  protected isOnDragHandle(p1, p2) {
    const dragHandleWidthDelta = this.dragHandleDelta();
    return Math.abs(p1 - p2) < dragHandleWidthDelta * 0.5;
  }

  public getDragHandle(px, py): DRAG_DIRECTIONS {
    let direction;
    const options: DrawOptions = {
      outerEdgeDimensions: true,
    };
    const isCropping = this.canvasDocument.isCropping(this.element?.elementDefinition?.id);
    if (isCropping) {
      options.uncroppedDimensions = true;
    }
    const { x, y, width, height } = this.element.getDimensions(options);
    if (this.element.elementDefinition?.rotate?.angle) {
      const rotatedPosition = RotationHelper.rotate({ x: px, y: py }, -this.element.elementDefinition.rotate.angle, {
        x: x + width * 0.5,
        y: y + height * 0.5,
      });
      px = rotatedPosition.x;
      py = rotatedPosition.y;
    }

    const top = this.isOnDragHandle(py, y);
    const left = this.isOnDragHandle(px, x);
    const right = this.isOnDragHandle(px, x + width);
    const bottom = this.isOnDragHandle(py, y + height);
    const horizontal = x < px && x + width > px;
    const vertical = y < py && y + height > py;
    // const center = this.isOnDragHandle(px, x + width*0.5);
    // const mid = this.isOnDragHandle(py, y + height*0.5);

    switch (true) {
      case left && top:
        direction = DRAG_DIRECTIONS.TOP_LEFT;
        break;
      case right && top:
        direction = DRAG_DIRECTIONS.TOP_RIGHT;
        break;
      case left && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_LEFT;
        break;
      case right && bottom:
        direction = DRAG_DIRECTIONS.BOTTOM_RIGHT;
        break;
      case top && horizontal:
        direction = DRAG_DIRECTIONS.TOP_CENTER;
        break;
      case bottom && horizontal:
        direction = DRAG_DIRECTIONS.BOTTOM_CENTER;
        break;
      case right && vertical:
        direction = DRAG_DIRECTIONS.MID_RIGHT;
        break;
      case left && vertical:
        direction = DRAG_DIRECTIONS.MID_LEFT;
        break;
      // case center && top:
      //   direction = DRAG_DIRECTIONS.TOP_CENTER;
      //   break;
      // case center && bottom:
      //   direction = DRAG_DIRECTIONS.BOTTOM_CENTER;
      //   break;
      // case right && mid:
      //   direction = DRAG_DIRECTIONS.MID_RIGHT;
      //   break;
      // case left && mid:
      //   direction = DRAG_DIRECTIONS.MID_LEFT;
      //   break;
      default:
        break;
    }

    return direction;
  }
}
