import { Store } from '@ngrx/store';
import { DocumentAction, DocumentChangeType, DocumentElement, PositionDefinition } from '@contrail/documents';
import { RootStoreState } from 'src/app/root-store';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { Entities } from '@contrail/sdk';
import { Injectable } from '@angular/core';
import { DocumentService } from '../document/document.service';
import { GenerateDocumentUtil } from './generate-document-util';
import { interval, take } from 'rxjs';
import { DocumentGenerationConfig } from './document-generator.interfaces';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { BoardsActions } from '../../boards-store';
import { BoardsBackingAssortmentService } from '../backing-assortment/boards-backing-assortment-service';
import { DocumentAssortmentHelper } from '../backing-assortment/document-assortment-helper';
import { DocumentSelectors } from '../document/document-store';
import { DocumentManagerSelectors } from '../document-manager/document-manager-store';

@Injectable({
  providedIn: 'root',
})
export class DocumentGeneratorService {
  private assignItemToComponentConditions: any[] = [];
  private documentElements: DocumentElement[] = [];
  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private backingAssortmentService: BoardsBackingAssortmentService,
    private frameTemplatesService: FrameTemplatesService,
  ) {
    this.store
      .select(DocumentSelectors.assignItemToComponentConditions)
      .subscribe((assignItemToComponentConditions) => {
        this.assignItemToComponentConditions = assignItemToComponentConditions;
      });
    this.store.select(DocumentManagerSelectors.selectDocumentElements).subscribe((documentElements) => {
      this.documentElements = documentElements;
    });
  }

  async generateFrames(data: any, startingPosition: PositionDefinition) {
    if (data?.generationOptions) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      const documentGenerationConfig = await new Entities().create({
        entityName: 'document-generation-config',
        object: data.generationConfig,
      });
      data.generationConfig.id = documentGenerationConfig.id;
      this.documentService.deselectAllElements();
      const actions: DocumentAction[] = await this.createFrames(
        data.generationOptions,
        data.generationConfig,
        startingPosition,
      );
      this.documentService.handleDocumentActions(actions);
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
  }

  async regenerateFrames(data: any, skipUndo = false) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Regenerating lineboard...' }));
    let generationOptions = data?.generationOptions;
    if (!generationOptions && data.generationConfig) {
      generationOptions = await GenerateDocumentUtil.generationConfigToOptions(
        data.generationConfig,
        this.store,
        this.frameTemplatesService,
      );
    }
    const primaryGroupings = generationOptions.groupingProperties.filter((g) => !g.isSecondaryGroup);
    const secondaryGroupExists = generationOptions.groupingProperties.findIndex((g) => g.isSecondaryGroup) > -1;
    this.store.dispatch(BoardsActions.updateCurrentDocumentGenerationConfig({ changes: data.generationConfig }));

    interval(1) // wait for 1ms to ensure that the loading indicator is displayed
      .pipe(take(1))
      .subscribe(async () => {
        const generatedElements = this.documentElements.filter(
          (e) => e.documentGenerationConfigId === data.generationConfig.id,
        );
        const frameElements = generatedElements.filter((e) => e.type === 'frame');
        const topLeftMostFrameElement = this.getTopLeftMostElements(frameElements);
        let startingPosition = {
          x: topLeftMostFrameElement.position.x - 120 - (primaryGroupings.length - 1) * 90,
          y: topLeftMostFrameElement.position.y - (secondaryGroupExists ? 110 : 30),
        };
        if (generationOptions.gridLayoutOrientation === 'VERTICAL') {
          startingPosition = {
            x: topLeftMostFrameElement.position.x - (secondaryGroupExists ? 110 : 30),
            y: topLeftMostFrameElement.position.y - 120 - (primaryGroupings.length - 1) * 90,
          };
        }

        let actions: DocumentAction[] = await this.createFrames(
          generationOptions,
          data.generationConfig,
          startingPosition,
        );
        const deleteActions = await this.deleteElements(generatedElements);
        actions = actions.concat(deleteActions);
        if (!skipUndo) {
          const documentGenerationAction: DocumentAction = new DocumentAction(
            {
              changeType: DocumentChangeType.REGENERATE_LINEBOARD,
              documentGenerationConfig: data.generationConfig,
            },
            {
              changeType: DocumentChangeType.REGENERATE_LINEBOARD,
              documentGenerationConfig: data.originalGenerationConfig,
            },
          );
          actions.push(documentGenerationAction);
          this.documentService.handleDocumentActions(actions);
          this.documentService.deselectAllElements();
        }
      });
  }

  private async createFrames(
    generationOptions: any,
    generationConfig: DocumentGenerationConfig,
    startingPosition: PositionDefinition,
  ): Promise<DocumentAction[]> {
    if (this.assignItemToComponentConditions) {
      generationOptions.assignItemToComponentConditions = this.assignItemToComponentConditions;
    }
    const results = await GenerateDocumentUtil.generateDocumentElements(generationOptions, startingPosition);
    const documentElements = results.documentElements;
    const frames = documentElements.filter((de) => de.type === 'frame');
    if (frames.length > 0) {
      documentElements.forEach((d) => {
        d.documentGenerationConfigId = generationConfig.id;
      });
      return this.addNewElements(documentElements);
    }
    return null;
  }

  /** Adds the elements to the board using DocumentActions */
  private addNewElements(elements: DocumentElement[]): Array<DocumentAction> {
    const actions = elements.map((element) => {
      return new DocumentAction(
        {
          elementId: element.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: element,
        },
        {
          elementId: element.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: element,
        },
      );
    });
    return actions;
  }

  private async deleteElements(elements: DocumentElement[]): Promise<Array<DocumentAction>> {
    let actions: Array<DocumentAction> = [];
    const itemIdToElementIdMap = new Map<string, string>();
    const elementIdToBackingAssortmentItemMap = new Map<string, string>();

    elements
      .filter((e) => e.type === 'component')
      .forEach((el) => {
        itemIdToElementIdMap.set(DocumentAssortmentHelper.getItemIdFromElement(el), el.id);
      });
    const itemIds = Array.from(itemIdToElementIdMap.keys());
    const backingAssortmentItems = await this.backingAssortmentService.getAssortmentItemsFromBackingAssortment(itemIds);

    backingAssortmentItems.forEach((b) => {
      elementIdToBackingAssortmentItemMap.set(itemIdToElementIdMap.get(b.itemId), b);
    });

    elements.forEach((el) => {
      const action = new DocumentAction(
        {
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementId: el.id,
        },
        {
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementId: el.id,
          elementData: el,
        },
      );
      // set backing assortment item data for undo
      if (elementIdToBackingAssortmentItemMap.get(el.id)) {
        action.undoChangeDefinition.backingAssortmentItemData = elementIdToBackingAssortmentItemMap.get(el.id);
      }
      actions.push(action);
    });
    return actions;
  }

  private getLeftMostElements(elements: DocumentElement[]): DocumentElement[] {
    return elements?.length === 0
      ? null
      : [...elements]?.sort((a, b) => {
          const positionA = this.documentService.getElementPosition(a);
          const positionB = this.documentService.getElementPosition(b);
          return positionA.x - positionB.x;
        });
  }

  private getTopMostElements(elements: DocumentElement[]): DocumentElement[] {
    return elements?.length === 0
      ? null
      : [...elements]?.sort((a, b) => {
          const positionA = this.documentService.getElementPosition(a);
          const positionB = this.documentService.getElementPosition(b);
          return positionA.y - positionB.y;
        });
  }

  private getTopLeftMostElements(elements: DocumentElement[]): DocumentElement {
    const topMostElements = this.getTopMostElements(elements);
    const leftMostElements = this.getLeftMostElements(elements);

    for (let le of leftMostElements) {
      for (let te of topMostElements) {
        if (le.id === te.id) {
          return te;
        }
      }
    }
    return null;
  }

  async updateDocumentGenerationConfig(generationConfig: DocumentGenerationConfig) {
    return await new Entities().update({
      entityName: 'document-generation-config',
      id: generationConfig.id,
      object: generationConfig,
    });
  }

  async getDocumentGenerationConfigById(id: string): Promise<DocumentGenerationConfig> {
    return await new Entities().get({ entityName: 'document-generation-config', id, relations: ['updatedBy'] });
  }
}
