import * as AssortmentsActions from './assortments.actions';
import * as AssortmentsSelectors from './assortments.selectors';
import * as AssortmentsStoreState from './assortments.state';
import * as BackingAssortmentActions from './backing-assortment/backing-assortment.actions';
import * as BackingAssortmentSelectors from './backing-assortment/backing-assortment.selectors';
export { AssortmentsStoreModule } from './assortments-store.module';

const actions = {
  ...AssortmentsActions,
  ...BackingAssortmentActions,
};

const selectors = {
  ...AssortmentsSelectors,
  ...BackingAssortmentSelectors,
};

export { actions as AssortmentsActions, selectors as AssortmentsSelectors, AssortmentsStoreState };
