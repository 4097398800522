import { Component, OnInit, Input } from '@angular/core';
import { ItemData } from '@common/item-data/item-data';
import { DashboardWidgetConfiguration } from '../dashboard-interfaces';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
})
export class DashboardWidgetComponent implements OnInit {
  @Input() assortmentItemData: Array<ItemData>;
  @Input() dashboardWidgetConfiguration: DashboardWidgetConfiguration;
  @Input() appContext: any;
  @Input() annotationOptions: any[];

  constructor() {}

  ngOnInit(): void {}
}
