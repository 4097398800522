import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SizeDefinition } from '@contrail/documents';
import { IContent } from '../content-holder-details/content-holder-details.component';

@Component({
  selector: 'app-content-details',
  template: `
    <div>
      <div class="info-label-value" *ngIf="showContentFileName">
        <p>File Name</p>
        <p [matTooltip]="contentFileName" matTooltipPosition="above">{{ contentFileName | shorten: 25 }}</p>
      </div>
      <!-- <div class="info-label-value">
        <p>Extension</p> <p>{{ contentExtension | uppercase}}</p>
      </div> -->
      <div class="info-label-value">
        <p>Type</p>
        <p>{{ contentType | uppercase }}</p>
      </div>
      <div class="info-label-value">
        <p>Size</p>
        <p>{{ contentSize | number }} KB</p>
      </div>
      <div class="info-label-value">
        <p>Dimensions</p>
        <p>{{ dimensions || 'N/A' }}</p>
      </div>
      <div class="info-label-value">
        <p>Created on</p>
        <p>{{ contentCreatedOn | date: 'MM/dd/yyyy' }}</p>
      </div>
      <div class="info-label-value" *ngIf="createdByName">
        <p>Created by</p>
        <p>{{ createdByName }}</p>
      </div>
      <!--
      <div class="info-label-value">
        <p>Last updated on</p> <p>{{ contentCreatedOn | date: 'MM/dd/yyyy'}}</p>
      </div>
      -->
    </div>
  `,
  styles: [
    `
      .info-label-value {
        @apply text-black60 text-sm flex;
        p:first-child {
          @apply min-w-[140px] font-medium;
        }
      }
    `,
  ],
})
export class ContentDetailsComponent implements OnChanges {
  public contentFileName: string;
  public contentExtension: string;
  public contentSize: number;
  public contentUpdatedOn: string;
  public contentCreatedOn: string;
  public contentType: string;
  @Input() content: IContent;
  @Input() showContentFileName = false;
  @Input() dimensions?: string;
  @Input() createdByName?: string;

  constructor() {}

  ngOnChanges(): void {
    if (this.content) {
      this.contentFileName = this.content?.primaryFile?.fileName || '';
      this.contentExtension = this.contentFileName?.split('.')?.pop();
      this.contentSize = Math.round(this.content?.primaryFile?.size / 1000);
      this.contentUpdatedOn = this.content?.primaryFile?.updatedOn;
      this.contentCreatedOn = this.content?.primaryFile?.createdOn;
      this.contentType = this.content?.primaryFile?.contentType;
    }
  }
}
