import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';

@Injectable()
export class LoadingIndicatorEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
  ) {}
}
