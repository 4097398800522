import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from '../comments-store';
import { Comment, CommentOwnerInfo, CommentsService } from '../comments.service';
import { DocumentSelectors } from 'src/app/boards/board/document/document-store';

@Component({
  selector: 'app-comment-overlay',
  template: ` <div
    #overlay
    class="overlay"
    [class.right-edge]="rightEdge"
    [class.ml-10]="!rightEdge && bottomEdge !== 0"
    [ngClass]="{ visible: (showCommentOverlay$ | async) }"
    [ngStyle]="{
      top: (commentOverlayPosition$ | async)?.y + 'px',
      left: (commentOverlayPosition$ | async)?.x + 'px',
      marginTop: bottomEdge + 'px'
    }"
  >
    <app-comments-card
      *ngFor="let comment of commentSet$ | async"
      [comment]="comment"
      [accessLevel]="accessLevel$ | async"
      [ownerInfo]="ownerInfo$ | async"
      [disableShowComment]="true"
    >
      {{ comment.text }}
    </app-comments-card>

    <app-comment-form
      *ngIf="(accessLevel$ | async) !== 'VIEW'"
      [ownerInfo]="ownerInfo$ | async"
      isCommentOverlay="true"
    ></app-comment-form>
  </div>`,
  styles: [
    `
      .overlay {
        display: none;
        width: 100%;
        position: fixed;
        width: 400px;
        background-color: white;
        padding: 10px;
        border-radius: 8px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 22px !important;
        z-index: 1000;
      }
      .visible {
        display: block;
      }
      .right-edge {
        margin-left: -400px;
      }
    `,
  ],
})
export class CommentOverlay implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @ViewChild('overlay') overlay: ElementRef;

  showCommentOverlay$: Observable<boolean>;
  commentSet$: Observable<Array<Comment>>;
  ownerInfo$: Observable<CommentOwnerInfo>;
  accessLevel$: Observable<string>;
  commentOverlayPosition$: Observable<{ x: number; y: number }>;
  commentOverlayPosition: { x: number; y: number } = { x: 0, y: 0 };

  public showOverlay = false;
  private showResolvedComments = false;
  private canvasSize = { width: 0, height: 0 };
  public rightEdge: boolean = false;
  public bottomEdge: number = 0;

  constructor(
    private store: Store<RootStoreState.State>,
    private elementRef: ElementRef,
  ) {
    this.store
      .select(DocumentSelectors.viewSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe((viewSize) => {
        this.canvasSize = {
          width: viewSize.boundingClientRect.width,
          height: viewSize.boundingClientRect.height,
        };
      });

    this.commentOverlayPosition$ = store.select(CommentsSelectors.commentOverlayPosition);
    this.commentOverlayPosition$.pipe(takeUntil(this.destroy$)).subscribe((pos) => {
      this.rightEdge = pos?.x + 400 > this.canvasSize.width ? true : false;
      const canvasHeight = this.canvasSize.height;
      if (canvasHeight - pos?.y < -20) {
        this.bottomEdge = pos?.y - canvasHeight + 100;
      } else {
        setTimeout(() => {
          const offsetHeight = this.overlay?.nativeElement.offsetHeight || 0;
          const mt = canvasHeight - (pos?.y + offsetHeight);
          this.bottomEdge = Math.min(0, mt);
        }, 0);
      }
    });

    this.showCommentOverlay$ = store.select(CommentsSelectors.showCommentOverlay);
    this.ownerInfo$ = this.store.select(CommentsSelectors.commentOwnerInfo);
    this.accessLevel$ = this.store.select(CommentsSelectors.commentsAccessLevel);

    this.commentSet$ = combineLatest([
      this.ownerInfo$,
      this.store.select(CommentsSelectors.selectContextComments),
    ]).pipe(
      map(([ownerInfo, comments]) => {
        return comments.filter((comment) => {
          if (!ownerInfo) {
            return [];
          }
          let showComment = true;
          if (!this.showResolvedComments) {
            showComment = !(comment.status === 'closed');
          }
          return showComment && CommentsService.filterByOwnerInfo(comment, ownerInfo);
        });
      }),
      takeUntil(this.destroy$),
    );
  }

  ngOnInit() {
    this.showCommentOverlay$.pipe(takeUntil(this.destroy$)).subscribe((bool) =>
      setTimeout(() => {
        this.showOverlay = bool;
      }, 200),
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    this.checkClose(event);
  }
  @HostListener('document:contextmenu', ['$event'])
  contextmenu(event) {
    this.checkClose(event);
  }

  // BL: Adding ng-star-inserted to try and fix the mentinos drop down list click issue
  private validUXNavElements = [
    'mat-button',
    'mat-option',
    'mat-menu',
    'mat-calendar',
    'comment-color-chip',
    'ng-star-inserted',
  ];
  checkClose(event) {
    const src = event.srcElement;
    // console.log('src.className: ', src.getAttribute('class'));
    // DONT CLOSE IF CLICKING ON A MENU / SELECT

    const validNavs = this.validUXNavElements.filter((ele) => src.getAttribute('class')?.indexOf(ele) > -1);

    if (validNavs?.length) {
      return;
    }
    if (this.showOverlay && !this.elementRef.nativeElement.contains(event.target)) {
      this.store.dispatch(CommentsActions.hideCommentOverlay());
      this.store.dispatch(CommentsActions.selectComment({ comment: null }));
      this.rightEdge = false;
      this.bottomEdge = 0;
    }
  }
}
