import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, OrgConfig } from '@common/auth/auth.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag } from '@common/feature-flags/feature-flag';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

const DOMAIN_MAP = {
  PRODUCTION: '.vibeiq.com',
  DEVELOPMENT: '.dev.vibeiq.com',
  STAGING: '-staging.vibeiq.com',
};

@Component({
  selector: 'app-app-switch',
  templateUrl: './app-switch.component.html',
  styleUrls: ['./app-switch.component.scss'],
})
export class AppSwitchComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  vibeApps = [
    { icon: 'workspace_icon.svg', url: 'hub', name: 'Hub', hostParam: 'hubAppHost' },
    { icon: 'plan_icon.svg', url: 'plan', name: 'Plan', hostParam: 'planAppHost' },
    { icon: 'showcase_icon.svg', url: 'showcase-manager', name: 'Showcase', hostParam: 'showcaseViewerAppHost' },
    { icon: 'board_icon.svg', url: 'boards', name: 'Boards', hostParam: 'boardsAppHost' },
    { icon: 'showroom_icon.svg', url: 'showroom', name: 'Showroom', hostParam: 'showroomAppHost' },
    { icon: 'admin_icon.svg', url: 'admin', name: 'Admin', hostParam: 'adminAppHost' },
  ];

  apps = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.filterApps(flags);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  filterApps(flags: FeatureFlag[]) {
    const appNamesToFilterOut = [];
    const showroomFlag = flags.map((f) => f?.featureName).includes('SHOWROOM');
    if (!showroomFlag) {
      appNamesToFilterOut.push('Showroom');
    }

    const orgConfig: OrgConfig = this.authService.getCurrentOrg()?.orgConfig;
    const isOrgAdmin = this.authService.isAdmin();
    if (orgConfig?.hideAdminConsoleFromNonOrgAdmins && !isOrgAdmin) {
      appNamesToFilterOut.push('Admin');
    }

    this.apps = this.vibeApps.filter((app) => !appNamesToFilterOut.includes(app.name));
  }

  async gotoApp(url, name) {
    const context = await firstValueFrom(this.authService.authContext);
    const suffix = DOMAIN_MAP[environment.name] + '/org/';
    let link = `https://` + url + suffix + context.currentOrg.orgSlug + '/';
    if (environment.name === 'FEATURE_BRANCH') {
      link = environment[this.apps.find((x) => x.name === name)['hostParam']];
      if (!link) {
        console.error('Misconfigured app switcher in feature branch code');
      }
    }
    window.open(link, '_blank');
  }
}
