import { PositionDefinition } from '@contrail/documents';
import { ViewBox } from '../../canvas/viewbox';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import {
  DocumentModelEntitiesState,
  documentModelEntitiesInitialState,
} from '../document-item/document-model-entities/document-model-entities.state';

export interface SideMenuOverlay {
  showChooser?: boolean;
  showClipboard?: boolean;
  hideSource?: boolean;
  label?: string;
  slug?: string;
  icon?: string;
  title?: string;
  targetSourceType?: ChooserSourceOptionTypes;
  element?: any;
}

export interface DocumentViewSize {
  viewBox: ViewBox;
  viewScale: PositionDefinition;
  boundingClientRect: DOMRect;
}

export interface State {
  toggleChooser: SideMenuOverlay;
  lastKnownMousePosition?: PositionDefinition;
  annotatedElements: any[];
  designMode: boolean;
  documentModelEntities: DocumentModelEntitiesState;
  assignItemToComponentConditions: any[];
}

export const documentInitialState = {
  toggleChooser: null,
  annotatedElements: [],
  designMode: false,
  documentModelEntities: documentModelEntitiesInitialState,
  assignItemToComponentConditions: [],
};
