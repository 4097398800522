<button
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="toggleDisplay()"
  mat-icon-button
  [attr.data-test]="'update-' + colorType"
  [ngClass]="{ '!flex items-center justify-center': colorChip }"
>
  <mat-icon *ngIf="!colorChip" [svgIcon]="svgIconType" class="toggle-icon">{{ matIconType }}</mat-icon>
  <div
    *ngIf="colorChip"
    class="w-6 h-6 border border-solid border-neutral-200 rounded-full mx-auto"
    [style.background-color]="color"
  ></div>
</button>

<div tabindex="0" [class.draggable]="draggable">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpened"
    [cdkConnectedOverlayPositions]="overlayPositions"
    [cdkConnectedOverlayPanelClass]="'color-picker-overlay'"
  >
    <!-- [cpDialogDisplay]="'popup'"
      Color picker adds event listeners to document when display is popup:
      1. Esc to cancel color and set it to prev value 
      2. Enter to accept color
      We don't need these event listeners. We save color on change event.
      We especially don't need Esc to set color to prev value. 
    -->
    <span
      [(colorPicker)]="color"
      [style.background]="color"
      autocomplete="off"
      [cpSaveClickOutside]="true"
      [cpToggle]="true"
      [cpPresetColors]="presetColors"
      [cpDialogDisplay]="'inline'"
      [cpEyeDropper]="true"
      (colorPickerChange)="handleChange($event)"
    >
    </span>
  </ng-template>
</div>
