<div class="side-panel">
  <div class="no-content text-black60" *ngIf="!entity">
    <ng-container *ngIf="selectedNum > 1 || trash; else showParent">
      <div class="h-12 border-b px-4 flex items-center">
        <span class="material-icons mr-2" [ngClass]="trash && !selectedNum ? 'text-black60' : 'text-primary'">
          {{ trash && !selectedNum ? 'delete' : 'check_circle' }}
        </span>
        <h6 class="mr-auto">{{ trash && !selectedNum ? 'Trash' : getHeader }}</h6>
        <span class="material-icons cursor-pointer hover:text-black80" (click)="cancel()">close</span>
      </div>
    </ng-container>

    <ng-template #showParent>
      <div class="h-12 border-b px-4 flex items-center">
        <img [src]="iconTypes[pws?.workspaceType]?.icon" class="w-6 h-6 object-contain mr-2" />
        <h6 class="mr-auto">
          {{ pws?.workspaceType === 'LIBRARY' ? (pws?.name | titlecase) : (pws?.name | shorten: 20) }}
        </h6>
      </div>
      <div class="h-56 w-56 m-auto flex-center">
        <img [src]="iconTypes[pws?.workspaceType]?.icon" class="w-32 h-32 object-contain" alt="" />
      </div>
      <mat-divider></mat-divider>
      <ng-container *ngTemplateOutlet="detailTemp; context: { data: pws }"></ng-container>
    </ng-template>
  </div>

  <div class="content" *ngIf="selectedNum === 1 && entity">
    <app-entity-details-side-panel-header
      [entity]="entity"
      [entityReference]="entityReference"
      (close)="cancel()"
      (updateEntity)="entity = $event"
    ></app-entity-details-side-panel-header>

    <ng-container *ngIf="visualSection || colorSection">
      <app-entity-details-side-panel-visualization
        *ngIf="visualSection"
        [entity]="entity"
      ></app-entity-details-side-panel-visualization>
      <app-entity-details-side-panel-colorpicker
        *ngIf="colorSection"
        [entity]="entity"
      ></app-entity-details-side-panel-colorpicker>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngTemplateOutlet="detailTemp; context: { data: entity }"></ng-container>

    <ng-container *ngIf="propertySection">
      <mat-divider></mat-divider>
      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Properties </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="py-2 flex-center flex-col">
          <app-search-bar
            [lgGray]="false"
            [placeholder]="'Properties'"
            class="w-80 px-1"
            (valueChange)="filterType($event)"
          ></app-search-bar>
          <app-entity-properties-form
            [ngClass]="{ 'pointer-events-none opacity-90': trash }"
            [accessLevel]="accessLevel"
            [entity]="entity"
            [type]="filteredType"
          ></app-entity-properties-form>
        </div>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
</div>

<ng-template #detailTemp let-data="data">
  <mat-expansion-panel class="mat-elevation-z0" expanded *ngIf="detailSection">
    <mat-expansion-panel-header>
      <mat-panel-title> Details </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="px-3 pb-2">
      <div class="info-label-value">
        <p>Created on</p>
        <p>{{ data?.createdOn | date: 'MM/dd/yyyy' }}</p>
      </div>
      <div class="info-label-value">
        <p>Created by</p>
        <p>{{ data?.createdBy | userName: 'fullEmail' }}</p>
      </div>
      <div class="info-label-value">
        <p>Updated on</p>
        <p>{{ data?.updatedOn | date: 'MM/dd/yyyy' }}</p>
      </div>
      <div class="info-label-value">
        <p>Updated by</p>
        <p>{{ data?.updatedBy | userName: 'fullEmail' }}</p>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>
