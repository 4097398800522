import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasUtil } from '../../../canvas-util';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class FileDragHandler {
  public targetElement: CanvasElement;
  private targetElements: CanvasElement[] = [];

  private readonly SUPPORTED_ELEMENT_TYPES = ['image', 'svg'];

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event) {
    this.clear();

    if (
      !this.canvasDocument.interactionHandler.isSelect() ||
      !this.canvasDocument?.interactionHandler?.componentAndImageInteractionActive
    )
      return;

    this.extractTargetElements();
  }

  public dragged(event) {
    if (this.targetElements?.length === 0) return;

    let targetElement = null;
    const documentPosition = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    for (let i = this.targetElements?.length - 1; i >= 0; i--) {
      const element: CanvasElement = this.targetElements[i];
      if (element.isPointOnElement(documentPosition.x, documentPosition.y)) {
        targetElement = element;
        break;
      }
    }
    if (targetElement) {
      this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
      this.targetElement = null;
      if (!this.targetElement || this.targetElement.id !== targetElement.id) {
        this.targetElement = targetElement;
        this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = this.targetElement;
      }
    } else {
      this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
      this.targetElement = null;
    }
  }

  public dragended(event) {
    this.clear();
  }

  private clear() {
    this.targetElement = null;
    this.targetElements = [];
    this.canvasDocument.canvasRenderer.highlightBoxRenderer.highlightElement = null;
  }

  private extractTargetElements() {
    const visibleElements = this.canvasDocument.state.getVisibleElements();
    this.targetElements = visibleElements.filter(
      (canvasElement) => !canvasElement.elementDefinition.isLocked && canvasElement.isItemComponent(),
    );
  }
}
