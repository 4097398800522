import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DocumentDynamicTextService } from '../../document-text/document-dynamic-text/document-dynamic-text.service';
import { DocumentDynamicTextUtil } from '../../document-text/document-dynamic-text/document-dynamic-text-util';

@Component({
  selector: 'app-property-configurator-dynamic-text',
  templateUrl: './property-configurator-dynamic-text.component.html',
  styleUrls: ['./property-configurator-dynamic-text.component.scss'],
})
export class PropertyConfiguratorDynamicTextComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() element: any;

  public textType = '';
  private bindingProperties: any;
  private entityTypes: any[];

  constructor(private documentDynamicTextService: DocumentDynamicTextService) {
    this.bindingProperties = this.documentDynamicTextService.dynamicTextState.getFlatBindingProperties();
    this.entityTypes = this.documentDynamicTextService.dynamicTextState.getEntityTypes();
  }

  ngOnChanges(): void {
    this.textType = this.currentValue;
  }

  setValue() {
    this.textType = this.textType === 'text-tool' ? 'text-box' : 'text-tool';
    const changes = { type: 'textType', value: this.textType };
    this.valueChange.emit(changes);
  }

  showDynamicTextEditor() {
    this.documentDynamicTextService.showDynamicTextEditor();
  }

  getTooltipText() {
    if (!this.element.propertyBindings || Object.keys(this.element.propertyBindings).length === 0) {
      return '';
    }
    const propertyBindingsMetaData = this.element.propertyBindingsMetaData;
    const entityType = this.element.propertyBindings.text.split('.')[0];
    const entityTypeDisplay = this.entityTypes.find((t) => t.slug === entityType)?.label;
    const property = this.bindingProperties[this.element.propertyBindings.text]?.label;
    const displayFunction = propertyBindingsMetaData
      ? DocumentDynamicTextUtil.getDisplayFunction(propertyBindingsMetaData.displayFunction).label
      : 'Value';
    return `Entity: ${entityTypeDisplay}\n` + `Property: ${property}\n` + `Display function: ${displayFunction}\n`;
  }
}
