import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AuthSelectors } from '@common/auth/auth-store';
import { TypeFormChange } from '@common/types/forms/type-form-change';
import { Entities, Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs/operators';
import { EntityPropertiesFormComponent } from '../entity-properties-form/entity-properties-form.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ColorUtil } from '@util/color-util';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss'],
})
export class EntityDetailsComponent implements OnChanges, AfterViewInit {
  @ViewChildren(EntityPropertiesFormComponent) entityFormQuery: QueryList<EntityPropertiesFormComponent>;
  private propertyForm: EntityPropertiesFormComponent;

  @Input() entity: any;
  @Input() entityReference: string;
  @Output() cancelView = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() accessLevel = 'EDIT';

  public type: Type;
  public hasStatus: boolean = false;
  public statusClass: string = '';
  public statusValue: string = '';

  public content: any;
  public contentEditable = true;
  public navOptions: Array<any> = [
    { label: 'Details', slug: 'details' },
    // { label: 'Content', slug: 'content' },
    // { label: 'History', slug: 'history' },
    // { label: 'Feedback', slug: 'feedback' },
  ];
  public currentNavOption: any = this.navOptions[0];

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<RootStoreState.State>,
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {}

  async ngOnChanges() {
    if (this.entityReference) {
      await this.loadEntity(this.entityReference);
    }
  }
  async loadEntity(entityReference: string) {
    const entity = await new Entities().get({ reference: entityReference, relations: ['updatedBy', 'createdBy'] });
    entity.name = entity.name ?? entity.fileName;
    this.type = await new Types().getType({ id: entity.typeId });
    this.entity = entity;
    const statusProperty = this.type.typeProperties.find((p) => p?.slug === 'colorStatus' || p?.slug === 'assetStatus'); //TODO: check the admin app types
    this.hasStatus = statusProperty ? true : false;
    const status = this.entity['colorStatus'] || this.entity['assetStatus']; //TODO: Admin app types
    if (statusProperty) {
      if (status === 'released') {
        this.statusClass = 'bg-success-dark';
        this.statusValue = 'Released';
      } else if (status === 'development') {
        this.statusClass = 'bg-accent';
        this.statusValue = 'Development';
      } else {
        // 'concept'
        this.statusClass = 'bg-warn-light';
        this.statusValue = 'Concept';
      }
    }
  }

  ngAfterViewInit() {
    if (this.entityFormQuery.toArray().length) {
      this.subscribeToForm(this.entityFormQuery.toArray()[0]);
    }
    this.entityFormQuery.changes.subscribe((formQuery: QueryList<EntityPropertiesFormComponent>) => {
      const results = formQuery.toArray();
      if (results.length > 0) {
        this.subscribeToForm(results[0]);
      }
    });

    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        take(1),
        tap((context) => {
          // Short term rule for converse. TODO
          // This could be later replaced with something that checks for
          // editability of a 'relationship' property definition on the item type.
          if (context.currentOrg.orgSlug.indexOf('converse') > -1) {
            this.contentEditable = false;
          }
        }),
      )
      .subscribe();
  }
  private subscribeToForm(form: EntityPropertiesFormComponent) {
    this.propertyForm = form;
    this.propertyForm.changes.subscribe((formChanges) => this.updateEntityValues(formChanges));
  }
  cancel() {
    this.cancelView.emit();
  }

  isValid() {}
  async updateEntityValues(formChange: TypeFormChange) {
    console.log('EntityDetailComponent: updateEntityValues: ', formChange);
    let changes = {};
    changes[formChange.propertySlug] = formChange.value || null;
    if (this.entity?.entityType === 'color') {
      const colorSlugs = ['red', 'green', 'blue', 'rgb', 'rgba', 'hexCode', 'hex'];
      if (colorSlugs.includes(formChange.propertySlug)) {
        const originalEntity = ObjectUtil.cloneDeep(this.entity);
        try {
          changes = ColorUtil.syncColorValueProperties(originalEntity, formChange);
        } catch (error) {
          this.entity = Object.assign({}, this.entity, changes);
          this.snackBar.open(`Error - ${error.message}`, '⛌', { duration: 1500 });
          // REVERT CHANGES LOCALLY
          const originalValue = originalEntity[formChange.propertySlug];
          const originalChanges = {};
          originalChanges[formChange.propertySlug] = originalValue;
          setTimeout(() => {
            this.entity = Object.assign({}, this.entity, originalChanges);
          }, 0);
          return;
        }
      }
    }
    console.log('EntityDetailComponent: changes: ', changes);

    // APPLY CHANGES LOCALLY
    this.entity = Object.assign({}, this.entity, changes);
    this.entity.updatedOn = new Date();

    // EMIT CHANGES TO THE SOURCE APP
    const emittedChanges = ObjectUtil.cloneDeep(changes); // to void mutation...
    this.updated.emit({ entity: this.entity, changes: emittedChanges });
    // PERSIST THE CHANGES
    console.log('updating entity: ', JSON.stringify(changes));

    new Entities()
      .update({ reference: this.entityReference, object: changes })
      .then((res) => {})
      .catch((err) => {
        console.error('typeProperty value - updating failed', err);
      });
  }

  handlePrimaryViewableChange(content) {
    console.log('HandlePrimaryViewableChange-----------');
    let changes;
    if (content) {
      changes = {
        mediumViewableDownloadUrl: content.mediumViewable.downloadUrl,
        smallViewableDownloadUrl: content.mediumViewable?.downloadUrl,
      };
    } else {
      changes = {
        mediumViewableDownloadUrl: null,
        smallViewableDownloadUrl: null,
      };
    }
    Object.assign(this.entity, changes);
    this.updated.emit({ object: this.entity, changes });
  }

  selectNavOption(navOption: any) {
    this.currentNavOption = navOption;
  }

  async downloadFile() {
    let transformedUrl = this.entity?.largeViewableDownloadUrl || this.entity?.mediumViewableDownloadUrl;
    if (transformedUrl?.indexOf('s3.amazonaws') > -1) {
      window.open(transformedUrl, '_blank');
      return;
    }

    const devUrl = `https://api.dev.vibeiq.com/dev/api`;
    const prodUrl = `https://api.vibeiq.com/prod/api`;
    const API_BASE_URL = environment.apiBaseUrl;

    if (transformedUrl.includes(devUrl)) {
      transformedUrl = transformedUrl.replace(devUrl, API_BASE_URL);
    }
    if (transformedUrl.includes(prodUrl)) {
      transformedUrl = transformedUrl.replace(prodUrl, API_BASE_URL);
    }
    const blob = await firstValueFrom(this.http.get(transformedUrl, { responseType: 'blob' }));
    const fileName = `download.png`; //TODO: @Brian: Users can edit fileName and we can lose file extension.
    const file = new File([blob], fileName, { type: 'image/png' });
    let fileUrl = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.download = fileName;
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }
}
