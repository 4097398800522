import { Injectable } from '@angular/core';
import { DocumentService } from '../document/document.service';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import {
  AppExtensionMessage,
  AppExtensionMessageHandler,
  BoardCommand,
  BoardDocumentElementChanges,
} from '@contrail/extensions-sdk';

@Injectable({
  providedIn: 'root',
})
export class BoardsWebSDKMessageHandler implements AppExtensionMessageHandler {
  constructor(private documentService: DocumentService) {}

  public async handleMessage(message: AppExtensionMessage) {
    console.log('HERE handleMessage: ', message);
    const command = message.command;
    if (!command || !command.startsWith('board:')) {
      return;
    }

    switch (command) {
      case BoardCommand.ADD_ELEMENTS: {
        const elements: DocumentElement[] = message.data;
        if (!elements?.length) {
          // VALIDATE???
          return;
        }

        this.documentService.deselectAllElements();
        const actions = [];
        for (let element of elements) {
          actions.push(
            new DocumentAction(
              {
                elementId: element.id,
                changeType: DocumentChangeType.ADD_ELEMENT,
                elementData: element,
              },
              {
                elementId: element.id,
                changeType: DocumentChangeType.DELETE_ELEMENT,
                elementData: element,
              },
            ),
          );
        }
        this.documentService.handleDocumentActions(actions);
        break;
      }

      case BoardCommand.DELETE_ELEMENTS: {
        const elementsToDelete: DocumentElement[] = message.data;
        const elementsToDeleteWithIds = elementsToDelete?.filter((element) => !!element.id);
        if (!elementsToDeleteWithIds?.length) {
          return;
        }

        this.documentService.deselectAllElements();
        const actions = [];
        for (let element of elementsToDeleteWithIds) {
          actions.push(
            new DocumentAction(
              {
                elementId: element.id,
                changeType: DocumentChangeType.DELETE_ELEMENT,
                elementData: element,
              },
              {
                elementId: element.id,
                changeType: DocumentChangeType.ADD_ELEMENT,
                elementData: element,
              },
            ),
          );
        }

        this.documentService.handleDocumentActions(actions);
        break;
      }

      case BoardCommand.MODIFY_ELEMENTS: {
        const elementChangeObjects: BoardDocumentElementChanges[] = message.data;
        if (!elementChangeObjects?.length) {
          return;
        }

        this.documentService.deselectAllElements();
        const actions = [];
        for (let elementChangeObject of elementChangeObjects) {
          const componentElement = {
            ...elementChangeObject.changes,
            id: elementChangeObject.id,
          };

          if (componentElement?.elements?.length) {
            const resizedElements = this.documentService.updateSizeAndPositionForPropertyElements(
              componentElement.elements,
              componentElement,
            );
            componentElement.elements = resizedElements;
          }

          actions.push(
            new DocumentAction({
              elementId: elementChangeObject.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: componentElement,
            }),
          );
        }

        this.documentService.handleDocumentActions(actions);
        break;
      }
    }
  }
}
