import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FeatureFlag } from './feature-flag';

interface featureFlagsState extends EntityState<FeatureFlag> {}

export interface State {
  featureFlags: featureFlagsState;
}
export const featureFlagsEntityAdapter: EntityAdapter<FeatureFlag> = createEntityAdapter<FeatureFlag>({});

export const featureFlagsInitialState = {
  featureFlags: featureFlagsEntityAdapter.getInitialState({}),
};
