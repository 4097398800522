import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR } from '../../constants';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasComponentElement } from '../../elements/component/canvas-component-element';

export class HighlightBoxRenderer {
  public highlightElement: CanvasElement;

  private readonly STROKE_WIDTH = 1;

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw highlight box for target element
   */
  public draw() {
    if (this.highlightElement) {
      const ctx = this.canvasDocument.canvasDisplay.context;
      const isComponent = this.highlightElement.elementDefinition.type === 'component';
      const { x, y } = this.highlightElement.getPosition();
      const { width, height } =
        (isComponent && (this.highlightElement as CanvasComponentElement).documentSize) ||
        this.highlightElement.getSize();

      ctx.save();
      ctx.beginPath();

      ctx.translate(x + width * 0.5, y + height * 0.5);
      ctx.rotate(CanvasUtil.getAngle(this.highlightElement.elementDefinition?.rotate?.angle ?? 0));

      ctx.rect(
        -width * 0.5 - this.highlightElement.PADDING_LEFT,
        -height * 0.5 - this.highlightElement.PADDING_TOP,
        width + (this.highlightElement.PADDING_LEFT + this.highlightElement.PADDING_RIGHT),
        height + (this.highlightElement.PADDING_TOP + this.highlightElement.PADDING_BOTTOM),
      );

      ctx.fillStyle = 'rgba(0, 176, 255, 0.2)';
      ctx.strokeStyle = ACCENT_COLOR;
      ctx.lineWidth = this.canvasDocument.getStrokeWidth(this.STROKE_WIDTH);
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
      ctx.restore();
    }
  }

  public removeHighlightElement() {
    if (this.highlightElement) {
      this.highlightElement = null;
      this.canvasDocument.draw();
    }
  }
}
