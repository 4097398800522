import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { PropertyConfiguratorService } from '../property-configurator.service';

@Component({
  selector: 'app-property-configurator-font-family-selector',
  templateUrl: './property-configurator-font-family-selector.component.html',
  styleUrls: ['./property-configurator-font-family-selector.component.scss'],
})
export class PropertyConfiguratorFontFamilySelectorComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  defaultFontFamily;
  fontFamily;
  fontFamilyPickList;

  constructor(private propertyConfiguratorService: PropertyConfiguratorService) {}

  ngOnInit(): void {
    const fontProperties = this.propertyConfiguratorService.getTextFontProperties();
    this.fontFamilyPickList = fontProperties.fontFamilyPickList;
    this.defaultFontFamily = fontProperties.defaultFontFamily;
    if (!this.fontFamily) {
      this.fontFamily = this.defaultFontFamily;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fontFamily = changes.currentValue.currentValue || this.defaultFontFamily;
  }

  setFontFamily(event) {
    if (!event.source.selected) {
      return;
    }
    this.fontFamily = event.source.value;
    this.setValue();
  }
  setValue() {
    const changes = { type: 'fontFamily', value: this.fontFamily };

    this.valueChange.emit(changes);
  }
}
