import { Component, Input, OnInit } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type } from '@contrail/types';

@Component({
  selector: 'app-assortment-history-change-item-details',
  templateUrl: './assortment-history-change-item-details.component.html',
  styleUrls: ['./assortment-history-change-item-details.component.scss'],
})
export class AssortmentHistoryChangeItemDetailsComponent implements OnInit {
  @Input() change;
  @Input() changeType;
  public thumbnailUrl;
  typeDef: Type;
  constructor() {}

  async ngOnInit() {
    this.typeDef = await new Types().getType({ root: 'assortment-item', path: 'assortment-item' });
  }
  ngOnChanges() {
    this.thumbnailUrl = this.change?.item?.smallViewableDownloadUrl;
  }

  getProperty(slug) {
    if (!this.typeDef) {
      return;
    }
    return this.typeDef.typeProperties.find((prop) => prop.slug === slug);
  }
}
