import { ZOOM_STOP_POINTS_AS_PERCENT_ASC } from './zoom-pan-handler.constants';

// =================== Mouse Codes ===================
export enum MOUSE_BUTTON_CODE {
  // written in binary to make the logic of isMouseButtonPressed easier to understand
  //prettier-ignore
  PRIMARY =   0b00001, // 1 = left mouse button
  SECONDARY = 0b00010, // 2 = right mouse button
  AUXILIARY = 0b00100, // 4 = middle mouse button
  //prettier-ignore
  BACK =      0b01000, // 8 = back button
  //prettier-ignore
  FORWARD =   0b10000, // 16 = forward button
}

export function isMouseButtonPressed(event: MouseEvent, button: MOUSE_BUTTON_CODE): boolean {
  const buttonNames: MOUSE_BUTTON_CODE[] = Object.values(MOUSE_BUTTON_CODE).filter(
    (value) => typeof value === 'number',
  ) as MOUSE_BUTTON_CODE[];
  // Use binary `&` with the relevant power of 2 to check if a given button is pressed
  return Boolean(event.buttons & (1 << buttonNames.indexOf(button)));
}

export function isClickToPanButtonDownOnMouseEvent(event: MouseEvent): boolean {
  return (
    isMouseButtonPressed(event, MOUSE_BUTTON_CODE.AUXILIARY) || isMouseButtonPressed(event, MOUSE_BUTTON_CODE.SECONDARY)
  );
}

// =================== Zoom Buttons =================
export function convertZoomFactorToZoomPercentage(zoomFactor: number): number {
  return 100 / zoomFactor;
}
export function convertZoomPercentageToZoomFactor(zoomPercentage: number): number {
  return 100 / zoomPercentage;
}

export enum ZoomDirection {
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
}

export function getNextZoomButtonStopPointAsZoomFactor(currentZoomFactor: number, direction: ZoomDirection) {
  const zoomPercentage = convertZoomFactorToZoomPercentage(currentZoomFactor);
  const nextZoomPercentage = getNextZoomButtonStopPointAsPercentage(zoomPercentage, direction);
  return convertZoomPercentageToZoomFactor(nextZoomPercentage);
}

export function getNextZoomButtonStopPointAsPercentage(
  currenZoomPercentage: number,
  direction: ZoomDirection.ZOOM_IN | ZoomDirection.ZOOM_OUT,
): number {
  const isZoomIn = direction === ZoomDirection.ZOOM_IN;

  const pointsInAscendingOrder = ZOOM_STOP_POINTS_AS_PERCENT_ASC.slice();
  const pointsInDescendingOrder = pointsInAscendingOrder.slice().reverse();
  const orderedPoints = isZoomIn ? pointsInAscendingOrder : pointsInDescendingOrder;

  const isNextZoomPoint = (currentPoint: number, nextPoint: number) =>
    isZoomIn ? currentPoint < nextPoint : currentPoint > nextPoint;

  for (const point of orderedPoints) {
    if (isNextZoomPoint(currenZoomPercentage, point)) {
      return point;
    }
  }
  return isZoomIn ? Math.max(...orderedPoints) : Math.min(...orderedPoints);
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

// ================== Scroll to Zoom ==============
//
// ------------------ Exponential Zoom ------------
/*
 * Exponential zoom allows the amount of zoom to defer based on the size of the scroll event and the current zoom level.
 *
 * The function is essentially a "continuous compound interest" function: every delta of 1 in the scroll position changes the zoom by X%.
 * For a given scroll position, we calculate the zoom percentage by: newZoom = currentZoom * rateOfChange^scrollPosition
 *
 * The rate of change is >1 for zoom in and <1 for zoom out.
 *
 * To find the rate of change, we need to know:
 *
 * - VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE: How much user has to scroll to traverse the entire zoom range.
 * - minimumZoomPercentage: The most zoomed out the user can be.
 * - maximumZoomPercentage: The most zoomed in the user can be.
 *
 * We then choose a rateOfChange such that traversing the entire zoom range is traversed in VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE units,
 * with a smooth rate of change the entire time.
 *
 *
 * Calculate Rate of Change
 * ---------------------------
 * Solving the continuous compound interest function for rateOfChange,
 * we get: rateOfChange = (maxZoomPercentage / minZoomPercentage)^(1 / VERTICAL_SCROLL_DELTA_PER_FULL_ZOOM_RANGE)
 *
 * Note: Y^(1 / X) is the same as taking the Xth root of Y.
 *
 * See more here: https://docs.google.com/spreadsheets/d/1B34vWD0ecm01b9xqqhgMw6dMt0he2JmX1LW8RsnvmxI/edit?gid=513087656#gid=513087656
 */
export const nthRoot = (n: number, x: number) => Math.pow(x, 1 / n);
export type MouseZoomConfig = {
  verticalScrollPerFullZoomRange: number;
  maxZoomAsPercentage: number;
  minZoomAsPercentage: number;
};
export function calculateMouseZoomRateOfChange(config: MouseZoomConfig): number {
  const { verticalScrollPerFullZoomRange, maxZoomAsPercentage, minZoomAsPercentage } = config;
  return nthRoot(verticalScrollPerFullZoomRange, maxZoomAsPercentage / minZoomAsPercentage) - 1;
}

export function calculateNewZoomFactorFromScroll(config: {
  currentZoomFactor: number;
  scrollDelta: number;
  scrollZoomRate: number;
}): number {
  const { currentZoomFactor, scrollDelta, scrollZoomRate } = config;
  const verticalDelta = Math.abs(scrollDelta);

  const isZoomIn = scrollDelta > 0;
  const zoomInRate = 1 + scrollZoomRate;
  const zoomOutRate = 1 - scrollZoomRate; // e.g. if zoom-in rate is 1.02, then zoom out rate is 0.98
  const zoomRate = isZoomIn ? zoomInRate : zoomOutRate;

  return currentZoomFactor * Math.pow(zoomRate, verticalDelta);
}

// ================== Wheel Event Utils ==============
export class WheelEventAccumulator {
  deltaX: number;
  deltaY: number;

  constructor() {
    this.deltaX = 0;
    this.deltaY = 0;
  }

  addWheelEvent(event: WheelEvent) {
    this.deltaX += event.deltaX;
    this.deltaY += event.deltaY;
  }

  getDelta() {
    return { deltaX: this.deltaX, deltaY: this.deltaY };
  }

  getClampedDelta(maxAbsoluteValueOfDelta: number) {
    return {
      deltaX: clamp(this.deltaX, -maxAbsoluteValueOfDelta, maxAbsoluteValueOfDelta),
      deltaY: clamp(this.deltaY, -maxAbsoluteValueOfDelta, maxAbsoluteValueOfDelta),
    };
  }

  reset() {
    this.deltaX = 0;
    this.deltaY = 0;
  }

  hasDelta() {
    return this.deltaX !== 0 || this.deltaY !== 0;
  }
}

export function isWheelEventZoomEvent(event: WheelEvent): boolean {
  return event.ctrlKey || event.altKey || event.metaKey;
}
export function isWheelEventPanEvent(event: WheelEvent): boolean {
  return !isWheelEventZoomEvent(event);
}

export function getAverageEventPosition(events: WheelEvent[]) {
  if (events.length === 0) {
    throw new Error('Cannot get average position of 0 events');
  }

  const aggregate = events.reduce(
    (acc, event) => {
      acc.x += event.clientX;
      acc.y += event.clientY;
      return acc;
    },
    { x: 0, y: 0 },
  );

  return {
    x: aggregate.x / events.length,
    y: aggregate.y / events.length,
  };
}
