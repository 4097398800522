import { CropDefinition, DocumentElement, PositionDefinition, TranslateTransformation } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasImageDrawableElement } from '../../../elements/image/canvas-image-drawable-element';
import { RotationHelper } from '../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { DRAG_DIRECTIONS_TRANSFORM } from '../drag-event-handlers/element-resize-handler';
import { ResizeGuidelineHandler } from '../drag-event-handlers/guideline-handler/resize-guideline-handler';
import { CropElementResizeHandler } from './crop-element-resize-handler';

export const CROP_RESIZE_DRAG_DIRECTIONS = [
  DRAG_DIRECTIONS.CROP_TOP_LEFT,
  DRAG_DIRECTIONS.CROP_TOP_CENTER,
  DRAG_DIRECTIONS.CROP_TOP_RIGHT,
  DRAG_DIRECTIONS.CROP_MID_RIGHT,
  DRAG_DIRECTIONS.CROP_BOTTOM_RIGHT,
  DRAG_DIRECTIONS.CROP_BOTTOM_CENTER,
  DRAG_DIRECTIONS.CROP_BOTTOM_LEFT,
  DRAG_DIRECTIONS.CROP_MID_LEFT,
];

export class CropBoxResizeHandler {
  private startingPosition: PositionDefinition;
  private selectedElement: CanvasImageDrawableElement;
  private startingElement: DocumentElement;
  private startingCropDefinition: CropDefinition;
  private transform: TranslateTransformation;
  private scale: number;
  private startingUncroppedPosition: PositionDefinition;
  private guidelineHandler: ResizeGuidelineHandler;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    const { element, target } = elementTarget;

    if (
      element &&
      elementTarget &&
      CROP_RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1 &&
      element.isCropEnabled &&
      this.canvasDocument.isCropping(element.id)
    ) {
      this.transform = DRAG_DIRECTIONS_TRANSFORM[target];
      const imageElement = element as CanvasImageDrawableElement;
      if (!imageElement.imageSize) {
        console.error('No original image size', imageElement);
        this.clear();
        this.canvasDocument.cancelCrop();
      }
      const viewBox = this.canvasDocument.getViewBox();
      const scale = this.canvasDocument.getViewScale();
      this.startingPosition = { x: event.clientX + viewBox.x * scale.x, y: event.clientY + viewBox.y * scale.y };
      this.selectedElement = imageElement;
      this.startingElement = ObjectUtil.cloneDeep(element.elementDefinition);

      const size = this.selectedElement.getSize();
      this.startingCropDefinition = this.selectedElement.getCropDefinition();
      this.scale = Math.min(
        size.width / (this.startingCropDefinition?.width ?? this.selectedElement.imageSize.width),
        size.height / (this.startingCropDefinition?.height ?? this.selectedElement.imageSize.height),
      );
      this.startingUncroppedPosition = this.startingElement.rotate?.angle
        ? this.selectedElement.getUncroppedRotatedPosition()
        : {
            x: this.startingElement.position.x - (this.startingCropDefinition?.x1 ?? 0) * this.scale,
            y: this.startingElement.position.y - (this.startingCropDefinition?.y1 ?? 0) * this.scale,
          };

      this.guidelineHandler = new ResizeGuidelineHandler(this.canvasDocument, this);
      this.guidelineHandler.dragstarted(event, elementTarget);
    }
  }

  public dragged(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.guidelineHandler.dragged(event);
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    if (
      this.guidelineHandler?.hasSnapped ||
      this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y, event.shiftKey)
    ) {
    }
    this.guidelineHandler.dragended(event);
    this.canvasDocument.draw();
    this.clear();
  }

  private clear() {
    this.startingElement = null;
    this.selectedElement = null;
    this.startingPosition = null;
    this.startingCropDefinition = null;
    this.transform = null;
  }

  private updateSelectedElementCropPosition(dx, dy, shiftKey): boolean {
    const keepAspectRatio = shiftKey;

    const imgWidth = this.selectedElement.imageSize.width;
    const imgHeight = this.selectedElement.imageSize.height;
    const prevX = this.startingCropDefinition?.x1 ?? 0;
    const prevY = this.startingCropDefinition?.y1 ?? 0;
    const prevX2 = this.startingCropDefinition?.x2 ?? prevX + imgWidth;
    const prevY2 = this.startingCropDefinition?.y2 ?? prevY + imgHeight;
    const maxSize = {
      width: (this.transform.x >= 0 ? imgWidth - prevX : prevX2) * this.scale,
      height: (this.transform.y >= 0 ? imgHeight - prevY : prevY2) * this.scale,
    };
    const { position, size } = RotationHelper.translateElement(
      this.startingElement.size,
      this.startingElement.position,
      this.startingElement?.rotate?.angle,
      dx,
      dy,
      this.transform,
      keepAspectRatio,
      maxSize,
    );

    return this.setElementSize(this.selectedElement, this.startingElement, { position, size });
  }

  public setElementSize(
    element: CanvasImageDrawableElement,
    oldElement: DocumentElement,
    { size, position }: DocumentElement,
  ) {
    const halfHandleWidth = this.canvasDocument.canvasRenderer?.cropBoxRenderer?.HANDLE_WIDTH * 0.5;
    if (size?.width < halfHandleWidth || size?.height < halfHandleWidth) {
      return false;
    }

    const newCenter = {
      x: position.x + size.width * 0.5,
      y: position.y + size.height * 0.5,
    };
    const imgWidth = element.imageSize.width;
    const imgHeight = element.imageSize.height;
    const angle = oldElement?.rotate?.angle;

    element.elementDefinition.size = size;
    element.elementDefinition.position = position;

    const newUnrotatedUncroppedPosition = angle
      ? RotationHelper.rotate(this.startingUncroppedPosition, -angle, newCenter)
      : this.startingUncroppedPosition;
    const newCropWidth = size.width / this.scale;
    const newCropHeight = size.height / this.scale;
    const newCropX = (position.x - newUnrotatedUncroppedPosition.x) / this.scale;
    const newCropY = (position.y - newUnrotatedUncroppedPosition.y) / this.scale;
    element.elementDefinition.cropDefinition = CropElementResizeHandler.limitAndGetCrop(
      newCropX,
      newCropY,
      newCropWidth,
      newCropHeight,
      imgWidth,
      imgHeight,
    );
    return true;
  }

  public keepAspectRatio() {
    return false;
  }
}
