import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { BoardService } from '../../board.service';
import { DocumentService } from '../../document/document.service';
import { SelectedElementInfo } from '../board-context-menu.component';

export abstract class ContextMenuAction {
  public actionLabel = '';
  public actionIcon = '';
  public svgActionIcon = '';
  public shortCutKey = '';
  public iconClass = '';
  public testRef = '';

  constructor(
    protected store: Store<RootStoreState.State>,
    protected documentService: DocumentService,
    protected boardService: BoardService,
  ) {}
  canInvoke(editorMode: string, selectedElementInfo: SelectedElementInfo = null): boolean {
    return true;
  }
  async handleAction(sourceAction: any, editorMode: string, selectedElementInfo: SelectedElementInfo = null) {}
}
