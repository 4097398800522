import { Component, Input, OnInit } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';

@Component({
  selector: 'app-source-change-history-event-change-details',
  templateUrl: './source-change-history-event-change-details.component.html',
  styleUrls: ['./source-change-history-event-change-details.component.scss'],
})
export class SourceChangeHistoryEventChangeDetailsComponent implements OnInit {
  @Input() change;
  private formatter = new PropertyValueFormatter();
  constructor() {}

  ngOnInit(): void {}

  formatValue(property, value) {
    if (!property || value === null || value === undefined) {
      return;
    }
    return this.formatter.formatValueForProperty(value, property);
  }
}
