import { CanvasDocument } from '../../../canvas-document';
import { CanvasTableElement } from '../../../elements/table/canvas-table-element';
import { TableRange } from '../../../elements/table/table-manager/table/table-range';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { ElementMouseTarget } from '../../../state/canvas-state';

export class TableClickHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event, elementTarget: ElementMouseTarget) {
    if (
      !elementTarget?.target ||
      !(elementTarget?.element?.elementDefinition?.type === 'table' && elementTarget?.element?.isSelected) ||
      event.button !== 0
    )
      return;
    const tableElement = elementTarget.element as CanvasTableElement;
    if (
      DRAG_DIRECTIONS.CELL === elementTarget.target &&
      !event.metaKey &&
      elementTarget?.index?.x >= 0 &&
      elementTarget?.index?.y >= 0 &&
      tableElement.table.isOnlyCellSelected(
        new TableRange(elementTarget.index.y, elementTarget.index.y, elementTarget.index.x, elementTarget.index.x),
      )
    ) {
      tableElement.table.showEditor(elementTarget.index.y, elementTarget.index.x);
    } else if (
      [DRAG_DIRECTIONS.DRAG_ROW, DRAG_DIRECTIONS.DRAG_COLUMN, DRAG_DIRECTIONS.CELL].indexOf(elementTarget.target) !== -1
    ) {
      tableElement.table.handleSelectOnClick(elementTarget, {
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey || event.metaKey,
      });
      this.canvasDocument.queueDraw();
    } else if ([DRAG_DIRECTIONS.BODY].indexOf(elementTarget.target) !== -1) {
      tableElement.table.handleDeselectOnClick();
      this.canvasDocument.queueDraw();
    } else if ([DRAG_DIRECTIONS.ADD_COLUMN, DRAG_DIRECTIONS.ADD_ROW].indexOf(elementTarget.target) !== -1) {
      if (DRAG_DIRECTIONS.ADD_COLUMN === elementTarget.target && elementTarget?.index?.x >= 0) {
        this.canvasDocument.canvasTableService.add('column', elementTarget.index.x, tableElement);
      } else if (DRAG_DIRECTIONS.ADD_ROW === elementTarget.target && elementTarget?.index?.y >= 0) {
        this.canvasDocument.canvasTableService.add('row', elementTarget.index.y, tableElement);
      }
    }
  }
}
