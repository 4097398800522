import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DocumentElement } from '@contrail/documents';
import { DocumentIframeService } from '../document-iframe.service';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-add-iframe-element-modal',
  templateUrl: './add-iframe-element-modal.component.html',
  styleUrls: ['./add-iframe-element-modal.component.scss'],
})
export class AddIFrameElementModalComponent {
  error = null;
  public validPattern = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
  public urlControl = new UntypedFormControl(null, [Validators.required]);
  createDisabled = false;
  skipPreview = false;

  constructor(
    public dialogRef: MatDialogRef<AddIFrameElementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentIframeService: DocumentIframeService,
  ) {}

  async embed() {
    let url = null;
    this.error = null;
    let dimension = {
      width: 300,
      height: 300,
    };
    if (this.urlControl.value.indexOf('<iframe') > -1) {
      const htmlElement = new DOMParser().parseFromString(this.urlControl.value, 'text/html');
      const iframe = htmlElement.querySelector('iframe');
      if (iframe) {
        if (iframe.width && iframe.height && iframe.width.indexOf('%') === -1 && iframe.height.indexOf('%') === -1) {
          dimension = {
            width: parseInt(iframe.width),
            height: parseInt(iframe.height),
          };
        }
        url = iframe.src;
      } else {
        this.showError();
      }
    } else if (this.urlControl.value.match(this.validPattern)) {
      url = this.urlControl.value;
    } else {
      this.showError();
    }

    if (url) {
      let style;
      if (url.indexOf('vimeo') > -1 || url.indexOf('youtube') > -1) {
        // this is a video
        this.skipPreview = true;
      } else {
        dimension = {
          width: 500,
          height: 400,
        };
        style = {
          border: {
            color: '#bdbdbd',
            width: '1',
          },
        };
      }
      const data: DocumentElement = {
        url: url,
        size: dimension,
        embedInfo: {},
      };
      if (style) {
        data.style = style;
      }
      this.createDisabled = true;
      const entity = await this.getEntityIfApplicable(url); // get vibe entity
      if (entity) {
        // this is a vibe entity
        this.skipPreview = true;
        data.embedInfo = {
          title: entity.name,
          entityType: entity.entityType,
        };
      }
      this.documentIframeService.createIframeElement(
        data,
        this.skipPreview,
        this.urlControl.value.indexOf('<iframe') > -1,
      );
      this.createDisabled = false;
      this.closeModal();
    }
  }

  showError() {
    this.error = 'The iFrame code or URL entered is invalid.';
    this.urlControl.setErrors(this.error);
    return;
  }

  closeModal(obj = {}) {
    this.dialogRef.close(obj);
  }

  handleClose() {
    this.closeModal();
  }

  private async getEntityIfApplicable(url: string) {
    let entity = null;
    if (
      url.indexOf('/board/') > -1 ||
      url.indexOf('/plan/') > -1 ||
      url.indexOf('/showcase-details/') > -1 ||
      url.indexOf('/sc/') > -1
    ) {
      let entityId;
      let entityName;
      if (url.indexOf('/board/') > -1) {
        entityName = 'board';
      } else if (url.indexOf('/plan/') > -1) {
        entityName = 'plan';
      } else {
        entityName = 'showcase';
      }
      entityId = url.substring(url.lastIndexOf('/') + 1);
      if (entityId.indexOf('?') > -1) {
        entityId = entityId.substring(0, entityId.indexOf('?'));
      }
      entity = await new Entities().get({
        entityName,
        id: entityId,
      });
    }
    return entity;
  }
}
