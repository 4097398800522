import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentHistoryComponent } from './document-history.component';
import { SourceChangeHistoryModule } from '@common/source-change-history/source-change-history.module';
import { DocumentHistoryPanelComponent } from './document-history-panel/document-history-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { DocumentHistoryHeaderButtonComponent } from './document-history-header-button/document-history-header-button.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DateFilterModule } from '@common/components/date-filter/date-filter.module';

@NgModule({
  declarations: [DocumentHistoryComponent, DocumentHistoryPanelComponent, DocumentHistoryHeaderButtonComponent],
  imports: [
    CommonModule,
    SourceChangeHistoryModule,
    MatIconModule,
    VirtualScrollerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    DateFilterModule,
  ],
  exports: [DocumentHistoryComponent, DocumentHistoryHeaderButtonComponent],
})
export class DocumentHistoryModule {}
