export interface State {
  searchActive: boolean;
  searchResults: Array<any>;
  activeSearchResultElement: any;
}

export const searchReplaceInitialState = {
  searchActive: false,
  searchResults: [],
  activeSearchResultElement: null,
};
