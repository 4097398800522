import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssortmentItem } from '../assortment-item';
import {
  backingAssortmentItemEntityAdapter,
  BackingAssortmentItemsState,
} from './backing-assortment/backing-assortment.state';

export interface Assortment {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  workspaceId?: string;
  workspace?: any;
  assortmentItems?: AssortmentItem[];
  [key: string]: any;
}

interface AssortmentsState extends EntityState<Assortment> {}

export interface State {
  assortments: AssortmentsState;
  sourceAssortment: Assortment;
  backingAssortmentItems: BackingAssortmentItemsState;
  backingAssortment: Assortment;
}
export const assortmentsEntityAdapter: EntityAdapter<Assortment> = createEntityAdapter<Assortment>({});

export const assortmentsInitialState: State = {
  assortments: assortmentsEntityAdapter.getInitialState({}),
  backingAssortmentItems: backingAssortmentItemEntityAdapter.getInitialState({}),
  backingAssortment: null,
  sourceAssortment: null,
};
