import { DocumentElement, LineDefinition, PositionDefinition, TranslateTransformation } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasTableElement } from '../../../elements/table/canvas-table-element';
import { RotationHelper } from '../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasMaskState } from '../../../state/canvas-mask-state';
import { ResizeGuidelineHandler } from './guideline-handler/resize-guideline-handler';

export const DRAG_DIRECTIONS_TRANSFORM = {
  top_left: { x: -1, y: -1 },
  top_center: { x: 0, y: -1 },
  top_right: { x: 1, y: -1 },
  mid_right: { x: 1, y: 0 },
  bottom_right: { x: 1, y: 1 },
  bottom_center: { x: 0, y: 1 },
  bottom_left: { x: -1, y: 1 },
  mid_left: { x: -1, y: 0 },
  top_line: { x: 1, y: 1 },
  bottom_line: { x: -1, y: -1 },
  crop_top_left: { x: -1, y: -1 },
  crop_top_center: { x: 0, y: -1 },
  crop_top_right: { x: 1, y: -1 },
  crop_mid_right: { x: 1, y: 0 },
  crop_bottom_right: { x: 1, y: 1 },
  crop_bottom_center: { x: 0, y: 1 },
  crop_bottom_left: { x: -1, y: 1 },
  crop_mid_left: { x: -1, y: 0 },
};

export const RESIZE_DRAG_DIRECTIONS = [
  DRAG_DIRECTIONS.TOP_LEFT,
  DRAG_DIRECTIONS.TOP_CENTER,
  DRAG_DIRECTIONS.TOP_RIGHT,
  DRAG_DIRECTIONS.MID_RIGHT,
  DRAG_DIRECTIONS.BOTTOM_RIGHT,
  DRAG_DIRECTIONS.BOTTOM_CENTER,
  DRAG_DIRECTIONS.BOTTOM_LEFT,
  DRAG_DIRECTIONS.MID_LEFT,
  DRAG_DIRECTIONS.TOP_LINE,
  DRAG_DIRECTIONS.BOTTOM_LINE,
];

export class ElementResizeHandler {
  private startingPosition: PositionDefinition;
  private selectedElements: CanvasElement[] = [];
  private startingElements: Map<string, DocumentElement> = new Map();
  private transform: TranslateTransformation;
  private isDragging = false;
  private guidelineHandler: ResizeGuidelineHandler;

  private readonly KEEP_ASPECT_RATIO_ELEMENTS = ['component', 'image', 'svg', 'iframe', 'sticky_note'];

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (
      !this.canvasDocument.interactionHandler.isSelect() ||
      elementTarget?.element?.elementDefinition?.isLocked ||
      elementTarget?.element?.elementDefinition.type === 'group' ||
      (elementTarget?.element?.elementDefinition?.type === 'table' &&
        elementTarget?.target &&
        elementTarget?.element?.selectionWidgetRenderer?.DRAG_HANDLES.indexOf(elementTarget?.target) === -1) ||
      (CanvasMaskState.isMask(elementTarget?.element?.elementDefinition) &&
        !this.canvasDocument.state.maskState.isEditingMask(elementTarget?.element?.elementDefinition?.id))
    ) {
      return;
    }
    const { element, target } = elementTarget;

    if (
      element &&
      !this.canvasDocument.isCropping(element.id) &&
      elementTarget &&
      RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1 &&
      this.canvasDocument.interactionHandler.selectionHandler.getSelectedUnlockedElementsByFrame()?.length === 1
    ) {
      this.isDragging = true;
      // console.log('ElementResizeHandler.dragstarted', event, elementTarget);

      this.transform = DRAG_DIRECTIONS_TRANSFORM[target];
      this.startingPosition = { x: event.clientX, y: event.clientY };
      this.selectedElements =
        this.canvasDocument.interactionHandler.selectionHandler.getSelectedUnlockedElementsByFrame();
      if (
        CanvasMaskState.isMask(element?.elementDefinition) &&
        !this.canvasDocument.state.maskState.isEditingMask(element.elementDefinition.id)
      ) {
        // If we are resizing a single mask also resize its members
        this.selectedElements = this.selectedElements.concat(
          this.canvasDocument.state.maskState.getMaskMembers(element.id),
        );
      }
      this.selectedElements.map((canvasElement) => {
        this.startingElements.set(canvasElement.id, ObjectUtil.cloneDeep(canvasElement.elementDefinition));
        canvasElement.onResizeStart();
      });

      this.guidelineHandler = new ResizeGuidelineHandler(this.canvasDocument, this);
      this.guidelineHandler.dragstarted(event, elementTarget);
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({ element: null, eventType: 'resizeStarted' });
    }
  }

  public dragged(event) {
    if (this.selectedElements.length === 0) {
      return;
    }

    const scale = this.canvasDocument.getViewScale();

    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementsSize(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.guidelineHandler.dragged(event);
    this.canvasDocument.draw({ skipRecalculate: true });
  }

  public dragended(event) {
    this.isDragging = false;
    if (this.selectedElements.length === 0) {
      return;
    }
    //console.log('ElementResizeHandler.dragended');

    const scale = this.canvasDocument.getViewScale();

    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    if (
      this.guidelineHandler?.hasSnapped ||
      this.updateSelectedElementsSize(distanceX / scale.x, distanceY / scale.y, event.shiftKey)
    ) {
      const newElements = this.selectedElements.map((canvasElement: CanvasElement) => {
        if (canvasElement.elementDefinition.type === 'line') {
          return {
            id: canvasElement.id,
            lineDefinition: ObjectUtil.cloneDeep(canvasElement.elementDefinition.lineDefinition),
          };
        } else if (canvasElement.elementDefinition.type === 'component') {
          return {
            id: canvasElement.id,
            position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
            size: ObjectUtil.cloneDeep(canvasElement.elementDefinition.size),
            elements: ObjectUtil.cloneDeep(canvasElement.elementDefinition.elements),
          };
        } else if (canvasElement.elementDefinition.type === 'table') {
          return {
            id: canvasElement.id,
            scale: ObjectUtil.cloneDeep(canvasElement.elementDefinition.scale),
            position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
            tableId: canvasElement.elementDefinition.id,
          };
        } else if (this.shouldScale(canvasElement.elementDefinition)) {
          return {
            id: canvasElement.id,
            scale: ObjectUtil.cloneDeep(canvasElement.elementDefinition.scale),
            position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
          };
        } else {
          return {
            id: canvasElement.id,
            position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
            size: ObjectUtil.cloneDeep(canvasElement.elementDefinition.size),
          };
        }
      });
      const undoElements = [...this.startingElements.values()].map((element: DocumentElement) => {
        if (element?.type === 'line') {
          return {
            id: element.id,
            lineDefinition: element?.lineDefinition,
          };
        } else if (element?.type === 'component') {
          return {
            id: element.id,
            position: element?.position,
            size: element?.size,
            elements: element?.elements,
          };
        } else if (element.type === 'table') {
          return {
            id: element.id,
            scale: element?.scale ?? { x: 1, y: 1 },
            position: element?.position,
            tableId: element.id,
          };
        } else if (this.shouldScale(element)) {
          return {
            id: element.id,
            scale: element?.scale ?? { x: 1, y: 1 },
            position: element?.position,
          };
        } else {
          return {
            id: element.id,
            position: element?.position,
            size: element?.size,
          };
        }
      });

      this.canvasDocument.actionsDispatcher.handleUndoableChanges(newElements, undoElements);

      this.guidelineHandler.dragended(event);

      this.canvasDocument.draw();
    }

    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({ element: null, eventType: 'resizeEnded' });
    this.clear();
  }

  public isResizing() {
    return this.isDragging;
  }

  private clear() {
    this.startingElements.clear();
    this.selectedElements = [];
    this.startingPosition = null;
    this.transform = null;
  }

  private updateSelectedElementsSize(dx, dy, shiftKey): boolean {
    const hasChanged = true;
    for (let i = 0; i < this.selectedElements?.length; i++) {
      const element: CanvasElement = this.selectedElements[i];
      const oldElement: DocumentElement = this.startingElements.get(element.id);
      if (oldElement) {
        const keepAspectRatio = this.keepAspectRatio(element.elementDefinition, shiftKey);
        if (element.elementDefinition.type === 'line') {
          if (oldElement.lineDefinition) {
            let lineDefinition;
            if (keepAspectRatio) {
              const angle = Math.abs((Math.atan2(dy, dx) * 180) / Math.PI);
              if (angle > 45 && angle < 135) {
                lineDefinition = this.calculateNewLineDefinition(oldElement, null, dy);
              } else {
                lineDefinition = this.calculateNewLineDefinition(oldElement, dx, null);
              }
            } else {
              lineDefinition = this.calculateNewLineDefinition(oldElement, dx, dy);
            }
            this.setElementSize(element, oldElement, { lineDefinition });
          }
        } else if (element.elementDefinition.type === 'component') {
          if (element.isItemComponent()) {
            const hiddenImage = oldElement.elements.find((e) => e.isHidden);
            const imageSize = oldElement.elements.find((e) => e.type === 'image').size;
            let componentSize = imageSize;
            let componentPosition = { ...oldElement.position };
            if (hiddenImage) {
              const filterHiddenImage = oldElement.elements.filter((e) => e.type !== 'image');
              if (filterHiddenImage?.length === 0) return false;
              componentSize = {
                width: filterHiddenImage[0].size.width,
                height: filterHiddenImage.reduce((acc, e) => acc + e.size.height, 0),
              };
              componentPosition.y = componentPosition.y + hiddenImage.size.height;
            }
            const { size, position } = RotationHelper.translateElement(
              componentSize,
              componentPosition,
              oldElement?.rotate?.angle,
              dx,
              dy,
              this.transform,
              keepAspectRatio,
            );
            if (hiddenImage) {
              position.y = position.y - hiddenImage.size.height;
            }
            if (size?.width < 0.01 || size?.height < 0.01) {
              return false;
            }
            this.setElementSize(element, oldElement, { position, size });
          } else if (element.isColorComponent()) {
            const colorRectSize = oldElement.elements.find((e) => e.type === 'rectangle' && e?.position?.y === 5)?.size;
            const componentPosition = { ...oldElement.position };
            const { size, position } = RotationHelper.translateElement(
              colorRectSize,
              componentPosition,
              oldElement?.rotate?.angle,
              dx,
              dy,
              this.transform,
              keepAspectRatio,
            );
            if (size?.width < 0.01 || size?.height < 0.01) {
              return false;
            }
            this.setElementSize(element, oldElement, { position, size });
          }
        } else if (this.shouldScale(element.elementDefinition)) {
          const oldSize = { ...oldElement.size };
          if (oldElement.scale?.x != null && oldElement.scale?.y != null) {
            oldSize.width = oldSize.width * oldElement.scale.x;
            oldSize.height = oldSize.height * oldElement.scale.y;
          }
          const { position, size } = RotationHelper.translateElement(
            oldSize,
            oldElement.position,
            oldElement?.rotate?.angle,
            dx,
            dy,
            this.transform,
            keepAspectRatio,
          );
          if (size?.width < 0.01 || size?.height < 0.01) {
            return false;
          }
          this.setElementSize(element, oldElement, { position, size });
        } else {
          const { position, size } = RotationHelper.translateElement(
            oldElement.size,
            oldElement.position,
            oldElement?.rotate?.angle,
            dx,
            dy,
            this.transform,
            keepAspectRatio,
          );
          if (
            ['pen', 'highlighter'].indexOf(element.elementDefinition.type) === -1 &&
            (size?.width < 0.01 || size?.height < 0.01)
          ) {
            return false;
          }
          this.setElementSize(element, oldElement, { position, size });
        }
      }
    }

    return hasChanged;
  }

  public setElementSize(element: CanvasElement, oldElement: DocumentElement, newElement: DocumentElement) {
    if (element.elementDefinition.type === 'line') {
      element.elementDefinition.lineDefinition.x1 = newElement.lineDefinition.x1;
      element.elementDefinition.lineDefinition.y1 = newElement.lineDefinition.y1;
      element.elementDefinition.lineDefinition.x2 = newElement.lineDefinition.x2;
      element.elementDefinition.lineDefinition.y2 = newElement.lineDefinition.y2;
    } else if (element.isItemComponent()) {
      const imageSize = oldElement.elements.find((e) => e.type === 'image').size;
      const aspectRatio = imageSize.width / imageSize.height;
      const width = Math.round(newElement.size.width);
      let height;
      if (aspectRatio === 1) {
        height = width;
      } else if (aspectRatio > 1) {
        height = Math.ceil(width * (3 / 4));
      } else {
        height = Math.ceil(width * (4 / 3));
      }
      let elements = element.elementDefinition.elements.map((e) => {
        const element = ObjectUtil.cloneDeep(e);
        if (element.type === 'image') {
          element.size = { width: width, height: height };
        } else if (element.type === 'text') {
          element.size.width = width;
        }
        return element;
      });
      element.elementDefinition.elements = this.canvasDocument.updateSizeAndPositionForPropertyElements(
        elements,
        element.elementDefinition,
      );
      element.elementDefinition.position = newElement.position;

      for (let i = 0; i < element.innerElements.length; i++) {
        const innerElement = element.innerElements[i];
        const innerChange = element.elementDefinition.elements.find((e) => e.id === innerElement.id);
        if (innerChange) {
          innerElement.elementDefinition = ObjectUtil.mergeDeep(innerElement.elementDefinition, innerChange);
        }
      }
    } else if (element.isColorComponent()) {
      const width = Math.round(newElement.size.width);
      const height = Math.round(newElement.size.height);
      const propertyElements = element.elementDefinition.elements;
      const size = { width, height };

      const updatedElements = this.canvasDocument.documentService.updateSizeAndPositionForColorElements(
        propertyElements,
        size,
      );
      element.elementDefinition.elements = [...updatedElements];
      element.elementDefinition.position = newElement.position;

      for (let i = 0; i < element.innerElements.length; i++) {
        const innerElement = element.innerElements[i];
        const innerChange = element.elementDefinition.elements.find((e) => e.id === innerElement.id);
        if (innerChange) {
          innerElement.elementDefinition = ObjectUtil.mergeDeep(innerElement.elementDefinition, innerChange);
        }
      }
    } else if (this.shouldScale(element.elementDefinition)) {
      const scale =
        Math.round(
          Math.max(newElement.size.width / oldElement.size.width, newElement.size.height / oldElement.size.height) *
            100000,
        ) / 100000;
      element.elementDefinition.scale = {
        x: scale,
        y: scale,
      };
      element.elementDefinition.position = newElement.position;
    } else {
      element.elementDefinition.position = newElement.position;
      element.elementDefinition.size = newElement.size;
    }
    element.onResize();
  }

  public keepAspectRatio(element: DocumentElement, shiftKey: boolean) {
    return (
      shiftKey ||
      this.KEEP_ASPECT_RATIO_ELEMENTS.indexOf(element.type) !== -1 ||
      (element.type === 'text' && element.isTextTool) ||
      element.type === 'table'
    );
  }

  private calculateNewLineDefinition(element: DocumentElement, dx, dy): LineDefinition {
    const lineDefinition: LineDefinition = ObjectUtil.cloneDeep(element.lineDefinition);
    if (this.transform.x === 1) {
      lineDefinition.x1 = lineDefinition.x1 + dx;
      if (dx === null) {
        lineDefinition.x1 = element.lineDefinition.x2;
      }
      lineDefinition.y1 = lineDefinition.y1 + dy;
      if (dy === null) {
        lineDefinition.y1 = element.lineDefinition.y2;
      }
    } else {
      lineDefinition.x2 = lineDefinition.x2 + dx;
      if (dx === null) {
        lineDefinition.x2 = element.lineDefinition.x1;
      }
      lineDefinition.y2 = lineDefinition.y2 + dy;
      if (dy === null) {
        lineDefinition.y2 = element.lineDefinition.y1;
      }
    }

    return lineDefinition;
  }

  private isRegularIframe(element: DocumentElement) {
    return element.type === 'iframe' && !element.embedInfo?.isIframe;
  }

  private shouldScale(element: DocumentElement) {
    return (
      ['sticky_note'].indexOf(element.type) !== -1 ||
      this.isRegularIframe(element) ||
      (element.type === 'text' && element.isTextTool) ||
      element.type === 'table'
    );
  }
}
