import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { Group } from '../../../state/canvas-group-state';

export class ElementContextMenuHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleContextmenu(event, elementTarget: { element: CanvasElement }) {
    let targetElementDefinition = elementTarget.element?.elementDefinition;
    if (elementTarget?.element?.isInGroup) {
      const group: Group = this.canvasDocument.state.groupState.getGroupByMemberElementId(
        elementTarget.element.id,
        true,
      );
      targetElementDefinition = group.element.elementDefinition;
    }
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: targetElementDefinition,
      selectedElements: this.canvasDocument?.getSelectedElements() || [],
      sourceMouseEvent: event,
      eventType: 'contextmenu',
    });
  }
}
