<app-modal [title]="title" [dialogRef]="dialogRef">
  <div>
    <div class="explaination">
      <img *ngIf="currentPage === 0" class="icon min-w-[60px] mr-4" src="assets/images/board_icon.svg" />
      <div *ngIf="currentPage === 0" class="content text-sm">
        A board is a digital space built for design ideation and seamless visual collaboration. Import items and visual
        assets to create mood boards, design briefs, merch boards and more.
      </div>
      <div *ngIf="currentPage === 1" class="content text-sm">
        Connect your board to an assortment to receive change notifications and specify which items are available for
        selection in your document. You can change this later.
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <div>
        <mat-form-field style="width: 100%" appearance="fill" [ngClass]="{ elementHidden: currentPage === 1 }">
          <mat-label>Board name</mat-label>
          <input matInput placeholder="Board Name" formControlName="name" id="name" />
          <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
          <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="currentPage === 1" class="mb-2">
        <mat-radio-group
          data-test="source-assortment-option"
          formControlName="assortmentOption"
          (change)="selectAssortmentOption($event)"
          class="flex flex-col gap-2"
        >
          <mat-radio-button data-test="source-assortment-required-option" value="useAssortment">
            <b>Yes. </b>Set source assortment and get alerted of adds, drops and changes.
          </mat-radio-button>
          <mat-radio-button data-test="source-assortment-not-required-option" value="doNotUseAssortment">
            <b>No. </b>Board is not connected to any source.
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <app-assortment-selector
        *ngIf="sourceAssortmentRequired"
        [ngClass]="{ elementHidden: currentPage === 0 }"
        (selectedAssortmentEvent)="setAssortment($event)"
        [sourceAssortmentRequired]="sourceAssortmentRequired"
      ></app-assortment-selector>

      <div class="actions">
        <div>
          <button
            data-test="create-board-back-button"
            mat-button
            type="button"
            color="primary"
            (click)="back()"
            *ngIf="currentPage === 1"
          >
            BACK
          </button>
        </div>
        <div class="flex gap-2">
          <button mat-stroked-button type="button" (click)="cancel()">CANCEL</button>
          <button
            mat-flat-button
            color="primary"
            data-test="create-board-next-button"
            type="button"
            (click)="next()"
            *ngIf="currentPage === 0"
            [disabled]="!isValid()"
          >
            NEXT
          </button>
          <button
            mat-flat-button
            color="primary"
            type="submit"
            data-test="create-board-submit-button"
            *ngIf="currentPage === 1"
            [disabled]="!isValid()"
          >
            CREATE
          </button>
        </div>
      </div>
    </form>
  </div>
</app-modal>
