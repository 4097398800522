import { State } from './frame-templates.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as FrameTemplatesActions from './frame-templates.actions';
import { frameTemplatesEntityAdapter } from './frame-templates.state';
import { frameTemplatesInitialState } from './frame-templates.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    frameTemplates: frameTemplatesEntityAdapter.setAll(data, state.frameTemplates),
  };
};

const loadFrameTemplateSuccess = (state: State, { id, changes }) => {
  return {
    ...state,
    frameTemplates: frameTemplatesEntityAdapter.updateOne({ id, changes }, state.frameTemplates),
  };
};

const createFrameTemplateSuccess = (state: State, { frameTemplate }) => {
  return {
    ...state,
    frameTemplates: frameTemplatesEntityAdapter.addOne(frameTemplate, state.frameTemplates),
  };
};

const remove = (state: State, { frameTemplate }) => {
  return {
    ...state,
    frameTemplates: frameTemplatesEntityAdapter.removeOne(frameTemplate.id, state.frameTemplates),
  };
};

export const frameTemplatesReducers = createReducer(
  frameTemplatesInitialState,
  on(FrameTemplatesActions.loadFrameTemplatesSuccess, setData),
  on(FrameTemplatesActions.loadFrameTemplateSuccess, loadFrameTemplateSuccess),
  on(FrameTemplatesActions.updateFrameTemplateSuccess, loadFrameTemplateSuccess),
  on(FrameTemplatesActions.createFrameTemplateSuccess, createFrameTemplateSuccess),
  on(FrameTemplatesActions.deleteFrameTemplateSuccess, remove),
);

export function reducer(state: State | undefined, action: Action): any {
  return frameTemplatesReducers(state, action);
}
