<div class="frame">
  <div class="flex">
    <div class="side-panel flex flex-col p-3">
      <div class="nav-option" (click)="view = 'summary'">Summary</div>
      <div class="nav-option" (click)="view = 'images'">Images</div>
      <div class="nav-option" (click)="view = 'rendering'">Rendering</div>
    </div>
    <div class="main-content ml-3 p-5">
      <app-dev-tools-summary-panel *ngIf="view === 'summary'"></app-dev-tools-summary-panel>
      <app-dev-tools-images-panel *ngIf="view === 'images'"></app-dev-tools-images-panel>
      <app-dev-tools-rendering-panel *ngIf="view === 'rendering'"></app-dev-tools-rendering-panel>
    </div>
  </div>
</div>
