import * as EditorModeActions from './editor-mode.actions';
import * as EditorModeSelectors from './editor-mode.selectors';
import * as EditorModeStoreState from './editor-mode.state';

export { EditorModeStoreModule } from './editor-mode-store.module';

const actions = {
  ...EditorModeActions,
};

const selectors = {
  ...EditorModeSelectors,
};

export { actions as EditorModeActions, selectors as EditorModeSelectors, EditorModeStoreState };
