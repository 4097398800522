import { Injectable } from '@angular/core';
import { DocumentService } from '../../document.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { Types } from '@contrail/sdk';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { DocumentElement } from '@contrail/documents';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { DocumentComponentService } from '../../document-component/document-component-service';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { DocumentActions } from '../../document-store';

const NOT_APPLICABLE_PROPERTIES = ['updatedOn', 'createdOn', 'itemStatus', 'itemNumber', 'lifecycleStage'];
const THUMBNAIL_PROPERTIES = [
  'contentType',
  'fileName',
  'largeViewableDownloadUrl',
  'mediumLargeViewableDownloadUrl',
  'mediumViewableDownloadUrl',
  'primaryFileUrl',
  'primaryViewableId',
  'smallViewableDownloadUrl',
  'tinyViewableDownloadUrl',
];

@Injectable({
  providedIn: 'root',
})
export class ItemAssigmentInteractionService {
  backingAssortmentItemData: any[];
  constructor(
    private documentService: DocumentService,
    protected confirmationBoxService: ConfirmationBoxService,
    private documentComponentService: DocumentComponentService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      this.backingAssortmentItemData = backingAssortmentItemData;
    });
  }

  public async handleItemAssignmentOnDocumentElement(targetElement: DocumentElement, sourceElement: DocumentElement) {
    const entityModelsMap = await this.getEntities([targetElement, sourceElement]);
    this.assignPropertiesToElement(
      entityModelsMap.get(targetElement.id),
      entityModelsMap.get(sourceElement.id),
      sourceElement,
    );
  }

  public async assignItemToDocumentElement(sourceData: any, targetElement: any) {
    console.log('ItemAssigmentInteractionService: assignItemToDocumentElement', sourceData, targetElement);
    const entityModelsMap = await this.getEntities([targetElement]);
    this.assignPropertiesToElement(entityModelsMap.get(targetElement.id), sourceData);
  }

  private async assignPropertiesToElement(
    targetElementData: any,
    sourceData: any,
    sourceElement: DocumentElement = null,
  ) {
    const itemType = await new Types().getType({ path: 'item' });
    const projectItemType = await new Types().getType({ path: 'project-item' });
    const targetItem = targetElementData.item;
    const targetProjectItem = targetElementData.projectItem;

    const sourceItem = ObjectUtil.cloneDeep(sourceData.item);
    if (sourceItem.roles.length !== targetItem.roles.length) {
      this.snackBar.open('Item types do not match. Please use the same item types', 'OK');
      return;
    }
    if (sourceItem.itemFamilyId === targetItem.itemFamilyId) {
      this.snackBar.open(
        'Both items belong to the same item family. The source and target item must belong to a different item family.',
        'OK',
      );
      return;
    }
    const sourceProjectItem = ObjectUtil.cloneDeep(sourceData.projectItem);
    const changedData = [];
    let targetItemFamily;
    let targetItemOption;
    if (targetItem.roles.includes('color')) {
      targetItemOption = ObjectUtil.cloneDeep(targetItem);
      targetItemFamily = ObjectUtil.cloneDeep(targetItem.itemFamily);
    } else {
      targetItemFamily = ObjectUtil.cloneDeep(targetItem);
    }
    const targetItemFamilyChanges = {
      changes: {},
      undoChanges: {},
      object: targetItemFamily,
    };
    let targetItemOptionChanges;
    if (targetItemOption) {
      targetItemOptionChanges = {
        changes: {},
        undoChanges: {},
        object: targetItemOption,
      };
    }

    if (sourceItem.primaryFileUrl) {
      this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
      const content = await this.assignImageToElement(sourceItem, targetItem);
      if (targetItemOptionChanges) {
        Object.assign(targetItemOptionChanges.changes, content);
      } else {
        Object.assign(targetItemFamilyChanges.changes, content);
      }
      this.store.dispatch(setLoading({ loading: false, message: '' }));
      if (targetItemOptionChanges) {
        this.addUndoThumbnailProperties(targetItemOptionChanges);
      } else {
        this.addUndoThumbnailProperties(targetItemFamilyChanges);
      }
    }

    itemType.typeProperties.forEach((property) => {
      if (!NOT_APPLICABLE_PROPERTIES.includes(property.slug) && !this.isValueEmpty(sourceItem[property.slug])) {
        if (
          (!property.propertyLevel || ['family', 'overridable'].includes(property.propertyLevel)) &&
          property.slug !== 'optionName'
        ) {
          if (!this.isValueEmpty(sourceItem[property.slug]) || property.slug === 'name') {
            targetItemFamilyChanges.changes[property.slug] = sourceItem[property.slug];
            targetItemFamilyChanges.undoChanges[property.slug] = targetItemFamily[property.slug];
          }
        } else {
          if ((!this.isValueEmpty(sourceItem[property.slug]) || property.slug === 'optionName') && targetItemOption) {
            targetItemOptionChanges.changes[property.slug] = sourceItem[property.slug];
            targetItemOptionChanges.undoChanges[property.slug] = targetItemOption[property.slug];
          }
        }
      }
    });

    let projectItemChanges;
    if (targetProjectItem && sourceProjectItem) {
      projectItemChanges = {
        changes: {},
        undoChanges: {},
        object: ObjectUtil.cloneDeep(targetProjectItem),
      };
      projectItemType.typeProperties.forEach((property) => {
        if (
          !NOT_APPLICABLE_PROPERTIES.includes(property.slug) &&
          !this.isValueEmpty(sourceProjectItem[property.slug])
        ) {
          projectItemChanges.changes[property.slug] = sourceProjectItem[property.slug];
          projectItemChanges.undoChanges[property.slug] = targetProjectItem[property.slug];
        }
      });
    }

    changedData.push(targetItemFamilyChanges);
    if (targetItemOptionChanges) {
      changedData.push(targetItemOptionChanges);
    }
    if (projectItemChanges) {
      changedData.push(projectItemChanges);
    }
    if (Object.keys(changedData).length > 0) {
      const elementsToSync = [];
      const entities = [];
      const changeDefinitions = [];
      this.gatherChangeData(changedData, elementsToSync, entities, changeDefinitions);
      // need to add unchanged entities to the list so that they can be rebound to the components.
      const additionalEntities = await this.documentComponentService.fetchEntitiesExceptFor(elementsToSync, entities);
      entities.push(...additionalEntities);
      await this.documentComponentService.updateValuesForComponentElements(
        this.documentService.getDocument(),
        ObjectUtil.cloneDeep(elementsToSync),
        entities,
        changeDefinitions,
        //sourceElement?.elements,
      );
    }
  }

  private async getEntities(documentElements) {
    const entities = await this.documentComponentService.fetchEntitiesExceptFor(documentElements, []);
    this.store.dispatch(
      DocumentActions.addDocumentModelEntities({
        documentModelEntities: entities,
      }),
    );
    return await this.documentComponentService.buildModelBindingsMapForElements(
      documentElements,
      ObjectUtil.cloneDeep(entities),
      false,
    );
  }

  private async assignImageToElement(item: any, targetItem: any) {
    return await this.documentService.fileHandler.addImageToElementFromFileURL(
      item.primaryFileUrl,
      item.fileName,
      item.contentType,
      targetItem,
    );
  }

  private gatherChangeData(changedData: any[], elementsToSync: any[], entities: any[], changeDefinitions: any[]) {
    const clonedBackingAssortmentItemData = ObjectUtil.cloneDeep(this.backingAssortmentItemData);
    const componentElements = this.documentService
      .getDocument()
      .elements.filter((element) => element.type === 'component');
    changedData.forEach((data) => {
      if (data.object.entityType === 'item') {
        if (data.object.id === data.object.itemFamilyId) {
          // update happened on an item family
          // find all item/options that are affected by changes to item family.
          const filteredBackingAssortmentItems = clonedBackingAssortmentItemData.filter(
            (backingAssortmentItem) => backingAssortmentItem.item.itemFamilyId === data.object.id,
          );
          filteredBackingAssortmentItems
            .map((assortmentItem) => assortmentItem.item)
            .forEach((item) => {
              if (entities.findIndex((entity) => entity.id === item.id) === -1) {
                let dataChanges = ObjectUtil.cloneDeep(data.changes);
                if (item.id !== data.object.id && dataChanges.primaryFileUrl) {
                  // do not update thumbnail properties for item options if item family's thumbnail is changed
                  dataChanges = this.filterOutThumbnailProperties(dataChanges);
                }
                Object.assign(item, dataChanges);
                entities.push(item);
              }
            });
          this.gatherChangeDefs(data, entities, componentElements, elementsToSync, changeDefinitions);
        } else {
          // update happened on an item option
          const itemIndex = entities.findIndex((entity) => entity.id === data.object.id);
          const entity = Object.assign(entities[itemIndex], data.changes);
          entities.splice(itemIndex, 1, entity);
          data.object = entity;
          this.gatherChangeDefs(data, entities, componentElements, elementsToSync, changeDefinitions);
        }
        this.store.dispatch(
          AssortmentsActions.syncBackingAssortmentItems({ id: data.object.id, changes: data.changes }),
        );
      } else if (['project-item'].includes(data.object.entityType)) {
        const entity = Object.assign(data.object, data.changes);
        entities.push(entity);
        this.gatherChangeDefs(data, entities, componentElements, elementsToSync, changeDefinitions);
      }
    });
  }

  private gatherChangeDefs(
    data: any,
    entities: any[],
    componentElements: DocumentElement[],
    elementsToSync: any[],
    changeDefinitions: any[],
  ) {
    componentElements
      .filter(
        (element) =>
          entities.findIndex((entity) => element.modelBindings.item === `${data.object.entityType}:${entity.id}`) >
            -1 && elementsToSync.findIndex((elementToSync) => elementToSync.id === element.id) === -1,
      )
      .forEach((element) => {
        elementsToSync.push(element);
      });
    changeDefinitions.push({
      id: data.object.id,
      entityType: data.object.entityType,
      changes: data.changes,
      undoChanges: data.undoChanges,
    });
  }

  private isValueEmpty(value: any) {
    return value === null || value === undefined || value === '';
  }

  private filterOutThumbnailProperties(properties: any) {
    return Object.keys(properties).reduce((acc, key) => {
      if (!THUMBNAIL_PROPERTIES.includes(key)) {
        acc[key] = properties[key];
      }
      return acc;
    }, {});
  }

  private addUndoThumbnailProperties(targetItem: any) {
    const thumbnailProps = THUMBNAIL_PROPERTIES.reduce((acc, key) => {
      acc[key] = targetItem.object[key];
      return acc;
    }, {});
    Object.assign(targetItem.undoChanges, thumbnailProps);
  }
}
