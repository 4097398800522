import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PropertyConfiguratorService } from '../property-configurator.service';
import { DocumentTextService } from '../../document-text/document-text.service';

@Component({
  selector: 'app-property-configurator-link',
  templateUrl: './property-configurator-link.component.html',
  styleUrls: ['./property-configurator-link.component.scss'],
})
export class PropertyConfiguratorLinkComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() position: any;
  @Input() element: any;

  public link = '';

  constructor(
    private propertyConfiguratorService: PropertyConfiguratorService,
    private documentTextService: DocumentTextService,
    private dialog: MatDialog,
  ) {}

  ngOnChanges(): void {
    this.link = this.currentValue || '';
  }

  openLinkEditor(event) {
    this.documentTextService.showHyperlinkOverlay({
      textElement: this.element,
      mode: 'edit',
      url: this.currentValue,
      position: this.position,
    });
  }

  setValue(url) {
    if (!url) {
      return;
    }
    let changes: any;
    if (url === 'remove') {
      changes = {
        type: 'unlink',
      };
    } else {
      changes = {
        type: 'link',
        value: url,
      };
    }

    this.valueChange.emit(changes);
  }
}
