import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatSpinnerComponent } from '@standalone-components/mat-spinner/mat-spinner.component';

import { WebglViewerComponent } from './webgl-viewer/webgl-viewer.component';
import { WebglContentConfiguratorComponent } from './webgl-content-configurator/webgl-content-configurator.component';
import {
  DragFileDirective,
  WebglOverlayConfiguratorComponent,
} from './webgl-overlay-configurator/webgl-overlay-configurator.component';

@NgModule({
  declarations: [
    WebglViewerComponent,
    WebglContentConfiguratorComponent,
    WebglOverlayConfiguratorComponent,
    DragFileDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSelectModule,

    // standalone components
    MatSpinnerComponent,
  ],
  exports: [WebglViewerComponent, WebglContentConfiguratorComponent, WebglOverlayConfiguratorComponent],
})
export class WebglModule {}
