import { Project } from '@contrail/entity-types';

export interface Item {
  id?: string;
  name?: string;
  roles?: Array<string>;
  optionName?: string;
  optionGroup?: string;
  itemFamilyId?: string;
  [key: string]: any;
}

export interface ItemDocument {
  document: {
    id: string;
    name: string;
    entityType: string;
    updatedOn: Date;
    updatedBy: any;
    createdById: string;
  };
  project: Project;
  itemIds: string[];
}
