import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentElementEvent } from '@contrail/documents';
import { Subject, debounceTime } from 'rxjs';
import { ANNOTATION_IMG_SIZE } from '../../document-annotation/document-annotation-service';
import { DocumentComponentUtils } from '../../document-component/document-component.utils';

@Component({
  selector: 'app-component-editor-annotation',
  templateUrl: './component-editor-annotation.component.html',
  styleUrls: ['./component-editor-annotation.component.scss'],
})
export class ComponentEditorAnnotationComponent implements OnInit, OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() defaultValue = ANNOTATION_IMG_SIZE;
  @ViewChild('widgetTray') widgetElement: ElementRef;

  private subject: Subject<any> = new Subject();
  public sourceEvent: DocumentElementEvent;

  size;
  sizes = [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];

  constructor() {}

  ngOnInit(): void {
    this.size = this.currentValue || this.defaultValue;

    this.subject.pipe(debounceTime(500)).subscribe((fontSize) => {
      if (this.size !== fontSize.value) {
        this.size = fontSize.value;
        this.updateValue(fontSize);
      }
    });
  }

  ngOnChanges() {
    this.size = this.currentValue || this.defaultValue;
  }

  updateValue(values) {
    const changes = DocumentComponentUtils.convertToTextStyle(values);
    this.valueChange.emit(changes);
  }

  handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  handleClick(event) {
    event.stopPropagation();
  }

  show(event: DocumentElementEvent) {
    this.sourceEvent = event;
    if (!this.widgetElement) {
      return;
    }
    const top = event.renderedElementPosition.y - 50;
    const left = event.renderedElementPosition.x - 80;
    this.widgetElement.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.widgetElement.nativeElement.classList.add('visible');
  }

  hide() {
    if (this.widgetElement) {
      this.widgetElement.nativeElement.classList.remove('visible');
    }
  }
}
