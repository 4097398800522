<ng-container *ngIf="fileTypes?.length > 0">
  <button
    mat-button
    mat-raised-button
    [ngClass]="{
      'mat-raised-button': buttonType === 'raised',
      'w-full': true
    }"
    [color]="buttonColor"
    (click)="isOpen = !isOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [disabled]="downloading$ | async"
    [attr.data-test]="'export-menu-button'"
  >
    <mat-icon class="mr-2" *ngIf="buttonType === 'raised'">download</mat-icon><span>{{ label }}</span>
  </button>

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
    <div class="flex flex-col space-y-4 bg-white p-6 mat-elevation-z8 relative w-64 rounded-lg">
      <p class="text-base font-bold">Export</p>
      <div class="cursor-pointer absolute right-4 top-4 !m-0" (click)="isOpen = !isOpen">
        <mat-icon>close</mat-icon>
      </div>
      <div class="flex flex-col space-y-2" *ngIf="!hideFrameOptions">
        <label id="frames-radio-group-label">Frames to export</label>
        <mat-radio-group
          class="flex flex-col space-y-2 mx-2.5"
          aria-labelledby="frames-radio-group-label"
          [(ngModel)]="frameOption"
        >
          <mat-radio-button
            *ngFor="let f of frameOptions"
            [value]="f.value"
            [attr.data-test]="'export-frames-' + f.value"
            >{{ f.label }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="flex flex-col space-y-2">
        <label id="file-radio-group-label">File type</label>
        <mat-radio-group
          class="flex flex-col space-y-2 mx-2.5"
          aria-labelledby="file-radio-group-label"
          [(ngModel)]="fileType"
        >
          <mat-radio-button
            *ngFor="let f of fileTypes"
            [value]="f.value"
            [attr.data-test]="'export-file-type-' + f.value"
            >{{ f.label }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="flex flex-row space-x-2 self-end">
        <button mat-button (click)="isOpen = !isOpen">Cancel</button>
        <button
          mat-button
          mat-raised-button
          color="primary"
          (click)="initDownload(); isOpen = !isOpen"
          [disabled]="!fileType || (!hideFrameOptions && !frameOption) || (downloading$ | async)"
          data-test="export"
        >
          OK
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
