export class ColorUtil {
  public static stringToHslaColor(str, s = 30, l = 60, a = 1) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    // hsla(0, 100%, 50%, 0.2)
    return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + a + ')';
  }

  public static isRgb(color: string): boolean {
    return color.trim().substring(0, 3) == 'rgb';
  }

  public static rgbToHexString(rgbStr: string): string {
    let rgb = rgbStr.replace(/[^\d,]/g, '').split(',');

    return ColorUtil.rgbToHex(rgb[0], rgb[1], rgb[2]);
  }

  public static rgbToHex(r, g, b) {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  public static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  // Converts both colors to hex to do equivalence comparison
  public static areEqualColors(color1: string, color2: string): boolean {
    color1 = ColorUtil.isRgb(color1) ? ColorUtil.rgbToHexString(color1) : color1;
    color2 = ColorUtil.isRgb(color2) ? ColorUtil.rgbToHexString(color2) : color2;

    return color1 == color2;
  }

  public static syncColorValueProperties(entity, formChange) {
    const value = formChange.value;
    const slug = formChange.propertySlug;

    const isValidRgbValue = (val) => Number.isInteger(val) && val >= 0 && val <= 255;
    const isValidHexValue = (val) => /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i.test(val);
    const isValidRgbString = (val) => /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/i.test(val);
    const isValidRgbaString = (val) => /^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(\d(\.\d+)?|1(\.0+)?)\)$/i.test(val);

    switch (slug) {
      case 'red':
      case 'green':
      case 'blue':
        if (!isValidRgbValue(value)) {
          throw new Error(`Invalid value for ${slug}: ${value}`);
        }
        entity[slug] = value;
        entity.rgb = `rgb(${entity.red},${entity.green},${entity.blue})`;
        entity.rgba = `rgba(${entity.red},${entity.green},${entity.blue},1)`;
        entity.hexCode = ColorUtil.rgbToHex(entity.red, entity.green, entity.blue);
        entity.hex = entity.hexCode.slice(1);
        break;
      case 'rgb':
        if (!isValidRgbString(value)) {
          throw new Error(`Invalid RGB value: ${value}`);
        }
        const rgbValues = value.match(/\d+/g);
        entity.red = parseInt(rgbValues[0]);
        entity.green = parseInt(rgbValues[1]);
        entity.blue = parseInt(rgbValues[2]);
        entity.rgb = value;
        entity.rgba = `rgba(${entity.red},${entity.green},${entity.blue},1)`;
        entity.hexCode = ColorUtil.rgbToHex(entity.red, entity.green, entity.blue);
        entity.hex = entity.hexCode.slice(1);
        break;
      case 'rgba':
        if (!isValidRgbaString(value)) {
          throw new Error(`Invalid RGBA value: ${value}`);
        }
        const rgbaValues = value.match(/\d+/g);
        entity.red = parseInt(rgbaValues[0]);
        entity.green = parseInt(rgbaValues[1]);
        entity.blue = parseInt(rgbaValues[2]);
        entity.rgb = `rgb(${entity.red},${entity.green},${entity.blue})`;
        entity.rgba = value;
        entity.hexCode = ColorUtil.rgbToHex(entity.red, entity.green, entity.blue);
        entity.hex = entity.hexCode.slice(1);
        break;
      case 'hexCode':
      case 'hex':
        let hexValue = slug === 'hex' ? `#${value}` : value;
        if (!isValidHexValue(hexValue)) {
          throw new Error(`Invalid hex value: ${hexValue}`);
        }
        if (hexValue.length === 4) {
          hexValue = `#${hexValue[1]}${hexValue[1]}${hexValue[2]}${hexValue[2]}${hexValue[3]}${hexValue[3]}`;
        }
        const rgb = ColorUtil.hexToRgb(hexValue);
        entity.red = rgb.r;
        entity.green = rgb.g;
        entity.blue = rgb.b;
        entity.rgb = `rgb(${entity.red},${entity.green},${entity.blue})`;
        entity.rgba = `rgba(${entity.red},${entity.green},${entity.blue},1)`;
        entity.hexCode = hexValue;
        entity.hex = hexValue.slice(1);
        break;
      default:
        throw new Error(`Unknown property slug: ${slug}`);
    }

    const colorChanges = {
      red: entity.red,
      green: entity.green,
      blue: entity.blue,
      rgb: entity.rgb,
      rgba: entity.rgba,
      hexCode: entity.hexCode,
      hex: entity.hex,
    };
    return colorChanges;
  }
}
