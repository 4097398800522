import { Component, OnDestroy, OnInit } from '@angular/core';
import { SideMenuOverlay } from '../../../document-store/document.state';
import { Store } from '@ngrx/store';
import { DocumentActions } from '../../../document-store';
import { DocumentDynamicTextService } from '../document-dynamic-text.service';
import { DocumentService } from '../../../document.service';
import { Subscription } from 'rxjs';
import { PropertyConfiguratorService } from '../../../property-configurator/property-configurator.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { EntityReference } from '@contrail/sdk';
import { DocumentElement, DynamicTextDisplayFunction } from '@contrail/documents';
import { DocumentDynamicTextUtil } from '../document-dynamic-text-util';

@Component({
  selector: 'app-document-dynamic-text-editor',
  templateUrl: './document-dynamic-text-editor.component.html',
  styleUrls: ['./document-dynamic-text-editor.component.scss'],
})
export class DocumentDynamicTextEditorComponent implements OnInit, OnDestroy {
  itemDisplayFunctionMap: any;
  documentDisplayFunctionMap: any;
  selectedElement: any;
  documentOwnerReference: any;
  documentOwnerType: string;
  documentOwnerProjectReference: any;
  subscriptions = new Subscription();
  bindingPropertiesMap: any = {};
  bindingProperties: any[] = [];
  entityTypes = [];
  selectableEntityTypes = [];
  displayFunctions = [];
  displayFunctionHint = '';
  entityTypeFormControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  displayFunctionFormControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  displayLabelFormControl: UntypedFormControl = new UntypedFormControl({ value: false, disabled: true }, null);
  textToolType = 'text-tool';
  selectedProperty: any = null;

  constructor(
    private store: Store,
    private documentDynamicTextService: DocumentDynamicTextService,
    private documentService: DocumentService,
    private propertyConfigService: PropertyConfiguratorService,
  ) {
    this.itemDisplayFunctionMap = DocumentDynamicTextUtil.getItemDisplayFunctionMap();
    this.documentDisplayFunctionMap = DocumentDynamicTextUtil.getDocumentDisplayFunctionMap();
  }

  async ngOnInit(): Promise<void> {
    this.documentOwnerReference = this.documentService.ownerReference;
    this.documentOwnerProjectReference = this.documentService.getDocument().modelBindings?.project;
    const documentOwnerRef = new EntityReference(this.documentOwnerReference);
    this.documentOwnerType = documentOwnerRef.entityType;
    this.entityTypes = this.documentDynamicTextService.dynamicTextState.getEntityTypes();
    this.selectableEntityTypes = this.entityTypes.filter((entityType) => entityType.selectable);
    this.bindingPropertiesMap = await this.documentDynamicTextService.dynamicTextState.getBindingProperties();
    this.subscribeToPropertyConfigEvent();
    const selectedElements = this.documentService
      .getSelectedElements()
      .filter((element) => DocumentDynamicTextUtil.isDynamicTextElement(element) && !element.isLocked);
    if (selectedElements.length === 1) {
      this.selectedElement = selectedElements[0];
      this.initValuesFromElement();
    } else {
      this.hide();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeToPropertyConfigEvent() {
    this.subscriptions.add(
      this.entityTypeFormControl.valueChanges.subscribe((value) => {
        this.selectedProperty = null;
        this.displayFunctionFormControl.setValue(null);
        this.displayFunctionFormControl.disable();
        this.bindingProperties = this.bindingPropertiesMap[value];
      }),
    );

    this.subscriptions.add(
      this.propertyConfigService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }
        if (event.eventType === 'selected' && event.element) {
          if (
            event.element.isLocked ||
            !DocumentDynamicTextUtil.isDynamicTextElement(event.element) ||
            this.selectedElement?.id === event.element.id
          ) {
            if (event.element.isLocked || !DocumentDynamicTextUtil.isDynamicTextElement(event.element)) {
              this.hide();
            }
            return;
          }
          this.selectedElement = event.element;
          this.initValuesFromElement();
        } else if (DocumentDynamicTextUtil.isDynamicTextElement(event.element) && event.eventType === 'dblclick') {
          this.selectedElement = event.element;
        } else if (['dragStarted', 'rotateStarted', 'resizeStarted', 'deselect'].includes(event.eventType)) {
          this.hide();
        }
      }),
    );
  }

  hide() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = '';
    overlay.showChooser = false;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
    this.documentDynamicTextService.dynamicTextState.clearEntityMap();
  }

  selectDisplayFunction(event) {
    this.handleUpdateText();
  }

  toggleDisplayLabel(event) {
    this.handleUpdateText();
  }

  toggleTextToolType(event) {
    this.textToolType = event.value;
    this.handleUpdateText();
  }

  private initValuesFromElement() {
    const propertyBindings = this.selectedElement.propertyBindings;
    this.textToolType = this.selectedElement.isTextTool ? 'text-tool' : 'text-box';
    if (Object.keys(propertyBindings).length > 0) {
      const entityType = propertyBindings.text.split('.')[0];
      if (['item', 'projectItem', 'assortmentItem'].includes(entityType)) {
        this.entityTypeFormControl.setValue('item');
      } else {
        this.entityTypeFormControl.setValue(entityType);
      }
      if (this.selectedElement.propertyBindings?.text) {
        this.selectedProperty = this.bindingProperties.find(
          (property) => property.slug === this.selectedElement.propertyBindings.text,
        );
        if (this.selectedProperty) {
          this.displayLabelFormControl.enable();
        }
        this.setDisplayFunction(this.selectedProperty.propertyType, false);
      }
      this.displayFunctionFormControl.setValue(
        this.selectedElement.propertyBindingsMetaData?.displayFunction || 'value',
      );
      this.setDisplayFunctionHint(this.displayFunctionFormControl.value);
      this.displayLabelFormControl.setValue(this.selectedElement.propertyBindingsMetaData?.displayLabel || false);
    } else {
      this.entityTypeFormControl.setValue(null);
      this.displayFunctionHint = null;
      this.displayFunctionFormControl.setValue(null);
      this.displayLabelFormControl.setValue(false);
      this.displayLabelFormControl.disable();
    }
    if (this.entityTypeFormControl.value === null) {
      this.displayFunctionFormControl.disable();
    } else {
      this.displayFunctionFormControl.enable();
    }
  }

  handleUpdateText() {
    this.selectedElement = { ...this.documentService.getSelectedElements()[0] };
    const entityType = this.entityTypeFormControl.value;
    const bindingProperty = this.selectedProperty;
    const displayFunction = this.displayFunctionFormControl?.value;
    this.setDisplayFunctionHint(displayFunction);
    let displayLabel = this.displayLabelFormControl.value;
    if (!entityType || !bindingProperty || !displayFunction) {
      return;
    }
    if (
      (this.selectedElement.isTextTool && this.textToolType === 'text-box') ||
      (!this.selectedElement.isTextTool && this.textToolType === 'text-tool')
    ) {
      const action = {
        element: this.selectedElement,
        textFormat: { type: 'textType', value: this.textToolType },
      };
      this.documentService.handleDocumentTextElementActions(action);
      this.selectedElement.isTextTool = this.textToolType === 'text-tool' ? true : false;
      return;
    }
    const frame = this.documentService.getFrameForElement(this.selectedElement);
    if (displayFunction === DynamicTextDisplayFunction.LABEL) {
      this.displayLabelFormControl.setValue(false);
      displayLabel = false;
    }
    const el: DocumentElement = {
      id: this.selectedElement.id,
      text: this.selectedElement.text,
      propertyBindings: { text: bindingProperty.slug },
      propertyBindingsMetaData: {
        displayFunction,
        propertyType: bindingProperty.propertyType,
        typeId: bindingProperty.typeId,
        displayLabel,
      },
      isTextTool: this.textToolType === 'text-tool',
      size: {
        width: this.selectedElement.size.width,
        height: this.selectedElement.size.height,
      },
    };
    this.documentDynamicTextService.updateDynamicTextElements([el], frame);
  }

  selectProperty(event) {
    if (event?.value) {
      this.selectedProperty = event.value;
      if (event.value.propertyType === 'text') {
        this.textToolType = 'text-box';
      }
      this.setDisplayFunction(event.value.propertyType);
      this.displayLabelFormControl.enable();
    } else {
      this.selectedProperty = null;
      this.displayFunctionFormControl.setValue(null);
      this.displayFunctionFormControl.disable();
      this.displayFunctionHint = null;
      this.displayLabelFormControl.disable();
    }
  }

  private setDisplayFunction(propertyType, refresh = true) {
    this.displayFunctionFormControl.enable();
    if (propertyType) {
      if (['board', 'showcase', 'project'].includes(this.entityTypeFormControl.value)) {
        this.displayFunctions = this.documentDisplayFunctionMap[propertyType];
      } else if (this.entityTypeFormControl.value === 'frame') {
        this.displayFunctions = this.documentDisplayFunctionMap['string'];
      } else {
        this.displayFunctions = this.itemDisplayFunctionMap[propertyType] || this.itemDisplayFunctionMap['default'];
      }
      if (!this.displayFunctions.includes(this.displayFunctionFormControl.value)) {
        this.displayFunctionFormControl.setValue('value');
      }
      if (refresh) {
        this.handleUpdateText();
      }
    }
  }

  private setDisplayFunctionHint(displayFunction) {
    const displayFunctionObj = this.displayFunctions.find((f) => f.value === displayFunction);
    if (displayFunctionObj) {
      this.displayFunctionHint = displayFunctionObj.hint;
      if (displayFunctionObj['hint' + this.entityTypeFormControl.value]) {
        this.displayFunctionHint = displayFunctionObj['hint' + this.entityTypeFormControl.value];
      }
    } else {
      this.displayFunctionHint = null;
    }
  }
}
