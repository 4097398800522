import { DocumentElement, SizeDefinition } from '@contrail/documents';
import { CanvasDocument, CANVAS_MODE } from '../../canvas-document';
import { CanvasElement } from '../canvas-element';
import { CanvasImageLoader, ImageLoaderResponse } from '../image/canvas-image-loader';
import { TextMetrics } from '../text/text-metrics';
import { ObjectUtil } from '@contrail/util';
import { TextImageGenerator } from '../text/text-image-generator';
import { TextStyle } from '../text/text-style';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { IframeElementHelper } from './iframe-element-helper';

export class CanvasIframeElement extends CanvasElement {
  protected currentUrl: string = null;
  protected url: string = null;
  protected imageUrl: string = null;
  protected imageElement: HTMLImageElement | HTMLCanvasElement = null;
  protected imageOpacity = null;
  public imageSize: SizeDefinition;
  protected isLoadingInProgress = false;
  private iconList: any;
  private playIconSize = 100;
  public isVideo = false;
  private currentElementDefinition;
  private textImageElement;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.url = elementDefinition.url;
    if (this.url.indexOf('vimeo') > -1 || this.url.indexOf('youtube') > -1) {
      this.isVideo = true;
    }
    this.imageUrl = this.getImageURL() || this.elementDefinition.embedInfo?.thumbnail;
    this.currentUrl = this.imageUrl;
  }

  public draw(ctx, { x, y, width, height }): { height: number; y: number } {
    if (!this.isVisible) {
      return;
    }
    if (this.canvasDocument.mode === CANVAS_MODE.SNAPSHOT) {
      return;
    }
    this.iconList = IframeElementHelper.iconList;
    if (this.imageUrl && (!this.imageElement || (!this.isImageError && this.currentUrl !== this.imageUrl))) {
      if (!this.isLoadingInProgress || this.currentUrl !== this.imageUrl) {
        this.isLoadingInProgress = true;
        this.loadImage();
      }
    } else {
      if (this.isImageError) {
        if (this.canvasDocument.mode !== 'PREVIEW') {
          if (this.imageOpacity != null) {
            ctx.save();
            ctx.globalAlpha = this.imageOpacity;
          }
          ctx.drawImage(
            this.imageElement,
            Math.round(-width / 2),
            Math.round(-height / 2),
            this.imageSize.width,
            this.imageSize.height,
          );
          if (this.imageOpacity != null) {
            ctx.restore();
          }
        }
      } else {
        if (this.elementDefinition.style?.opacity != null) {
          ctx.save();
          ctx.globalAlpha = this.elementDefinition.style?.opacity;
        }
        let thumbnailHeight = height;
        ctx.save();
        ctx.fillStyle = 'white';
        ctx.fillRect(Math.round(-width / 2), Math.round(-height / 2), width, height);
        ctx.restore();
        if (this.imageElement) {
          const aspectRatio = this.imageSize.height / this.imageSize.width;
          if (this.isVideo) {
            ctx.drawImage(this.imageElement, Math.round(-width / 2), Math.round(-height / 2), width, thumbnailHeight);
          } else {
            thumbnailHeight = width * aspectRatio;
            ctx.drawImage(this.imageElement, Math.round(-width / 2), Math.round(-height / 2), width, thumbnailHeight);
          }
        } else {
          // draw icon if image is not available
          thumbnailHeight = (height * 2) / 3;
          let icon = this.iconList['iframe'];
          const entityType = this.elementDefinition.embedInfo?.entityType;
          if (entityType && this.iconList[entityType]) {
            icon = this.iconList[entityType];
          }
          ctx.save();
          ctx.globalAlpha = 0.3;
          ctx.fillStyle = '#E0E0E0';
          ctx.fillRect(Math.round(-width / 2), Math.round(-height / 2), width, (height * 2) / 3); // draw grey background
          ctx.restore();
          ctx.save();
          if (!entityType) {
            ctx.globalAlpha = 0.2; // draw the iframe icon with opacity
          }
          ctx.drawImage(
            icon,
            Math.round(-width / 2) + (width * 3) / 10,
            Math.round(-height / 2) + width / 14,
            (width * 2) / 5,
            (width * 2) / 5,
          );
          ctx.restore();
        }
        this.drawBorderContainer(ctx, Math.round(-width / 2), Math.round(-height / 2), width, height);
        if (this.isVideo) {
          // draw the play icon
          ctx.drawImage(
            this.iconList['play'],
            (-1 * this.playIconSize) / 2,
            (-1 * this.playIconSize) / 2,
            this.playIconSize,
            this.playIconSize,
          );
        }
        if (!this.isVideo) {
          this.drawTitleAndDescription(ctx, { x, y, width, height }, thumbnailHeight);
        }
      }
    }
    return;
  }

  private drawTitleAndDescription(ctx, { x, y, width, height }, startYPos) {
    let titleFont = '500 30px Roboto';

    const textLineWidth = width - 30;
    let text = '';
    let wrappedTitle: string[] = [this.elementDefinition.url];
    ctx.font = titleFont;
    if (this.elementDefinition.embedInfo?.title) {
      wrappedTitle = TextMetrics.wordWrap(ctx, this.elementDefinition.embedInfo?.title, textLineWidth);
      text = '<p><strong><span style="font-size: 30px">' + this.escape(wrappedTitle.join(' ')) + '</span></strong></p>';
    }
    ctx.font = '21px Roboto';
    let wrappedDescription: string[] = [];
    let description = '';
    if (this.elementDefinition.embedInfo?.description) {
      wrappedDescription = TextMetrics.wordWrap(ctx, this.elementDefinition.embedInfo?.description, textLineWidth);
      wrappedDescription.forEach((text, index) => {
        if (index < 3) {
          let ellipsis = wrappedDescription.length > 3 && index === 2 ? '...' : '';
          if (ellipsis !== '') {
            description = description + text.substring(0, text.length - 4) + ellipsis;
          } else {
            description = description + text;
          }
        }
      });
      text =
        text +
        '<p style="padding-top: 10px"></p><p><span style="font-size: 21px">' +
        this.escape(description) +
        '</span></p>';
    }
    if (!this.textImageElement || this.hasChanged()) {
      this.textImageElement = null;
      this.generateTextImage(text, { x, y, width: textLineWidth, height: height / 2 });
    } else {
      if (this.textImageElement) {
        ctx.drawImage(
          this.textImageElement.img,
          Math.round(-width / 2) + 15,
          Math.round(-height / 2) + startYPos + 5,
          this.textImageElement?.img?.width,
          this.textImageElement?.img?.height,
        );
      }
    }
    return;
  }

  private async generateTextImage(text, { x, y, width, height }) {
    const textStyle = new TextStyle({
      elementDefinition: { size: { width }, style: { color: '#000000' } },
      DEFAULT_BORDER_SIZE: 0,
      isPropertyValid: () => {
        return false;
      },
    });
    this.currentElementDefinition = ObjectUtil.cloneDeep(this.elementDefinition);
    this.textImageElement = await TextImageGenerator.getGenerateTextImagePromise(
      text,
      { width, height, padding: 0 },
      textStyle,
      true,
      null,
      false,
    );
    this.canvasDocument.debounceDraw();
    this.isLoadingInProgress = false;
  }

  private async loadImage() {
    this.currentUrl = this.imageUrl;
    if (this.imageUrl) {
      await CanvasImageLoader.loadImage(
        this.imageUrl,
        { url: this.imageUrl, type: '' },
        await this.canvasDocument.getUserContext(),
        null,
        this.canvasDocument.isFirefox,
        false,
      )
        .then((response: ImageLoaderResponse) => {
          this.url = response.imageUrl;
          this.imageElement = response.imageElement;
          this.imageSize = response.imageSize;
          this.isImageError = response.imageError;
          this.imageOpacity = response.imageOpacity;
          this.canvasDocument.debounceDraw(); // redraw canvas after image is done loading
        })
        .catch((error) => {
          console.log('Error loading image', this.elementDefinition);
        });
    }
  }

  public getBoundingClientRect(options?: DrawOptions): { x; y; width; height } {
    const { x, y } = this.getPosition(options);
    const { width, height } = this.isImageError ? this.imageSize : this.getSize(options);
    return {
      x,
      y,
      width,
      height,
    };
  }

  private getImageURL() {
    if (this.elementDefinition.url.indexOf('youtube') > -1) {
      let videoId = this.elementDefinition.url.substring(this.elementDefinition.url.lastIndexOf('/') + 1);
      if (videoId.indexOf('watch?v=') > -1) {
        videoId = videoId.substring(videoId.indexOf('=') + 1);
      }
      if (videoId.indexOf('?') > -1) {
        videoId = videoId.substring(0, videoId.indexOf('?')); // url like this: https://www.youtube.com/embed/3TAZDlpHKKQ?si=QRpj95Jd9Nh6mTnO
      }
      return `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
    } else if (this.elementDefinition.url.indexOf('vimeo') > -1) {
      let videoId = this.elementDefinition.url.substring(
        this.elementDefinition.url.lastIndexOf('/') + 1,
        this.elementDefinition.url.lastIndexOf('?'),
      );
      return `https://vumbnail.com/${videoId}.jpg`;
    }
    return null;
  }

  public isMouseOnClickableArea(event: MouseEvent) {
    const clientRect = this.getDimensions();
    const adjustedPosition = this.toWindowPosition(clientRect);
    const adjustedSize = this.toWindowSize(clientRect);
    const adjustedMargin = this.toWindowSize({
      width: (clientRect.width - this.playIconSize) / 2,
      height: (clientRect.height - this.playIconSize) / 2,
    });

    const leftBoundary = adjustedPosition.x + adjustedMargin.width;
    const topBoundary = adjustedPosition.y + adjustedMargin.height;
    const rightBoundary = adjustedPosition.x + adjustedSize.width - adjustedMargin.width;

    const bottomBoundary = adjustedPosition.y + adjustedSize.height - adjustedMargin.height;
    return event.x < rightBoundary && event.x > leftBoundary && event.y < bottomBoundary && event.y > topBoundary;
  }

  private hasChanged() {
    const oldData = ObjectUtil.cloneDeep(this.currentElementDefinition) || {};
    delete oldData.position; // no need to redraw if position changes
    delete oldData.rotate; // no need to redraw if rotation changes
    delete oldData.scale;
    const newData = ObjectUtil.cloneDeep(this.elementDefinition);
    delete newData.position;
    delete newData.rotate;
    delete newData.scale;
    return Object.keys(oldData).length > 0 && JSON.stringify(oldData) !== JSON.stringify(newData);
  }

  private escape(htmlStr) {
    return htmlStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }
}
