<div #widgetTray class="px-1 widget-tray" (click)="handleClick($event)">
  <app-property-configurator-element-icon
    *ngIf="(!contextualEntityReference && elementType && elementType !== 'iframe') || elements?.length > 1"
    [elementType]="elementType"
    [selectedElements]="elements"
    [resetDrag]="reset"
  ></app-property-configurator-element-icon>

  <ng-container
    *ngIf="elements?.length === 1 && selectedElement?.documentGenerationConfigId && selectedElement?.type === 'frame'"
  >
    <app-property-configurator-lineboard
      matTooltip="Lineboard options"
      [selectedElement]="selectedElement"
      [visible]="visible"
    />
    <mat-divider vertical="true" class="divider"></mat-divider>
  </ng-container>

  <app-property-configurator-entity-details
    *ngIf="contextualEntityReference && elements?.length === 1"
    [selectedElement]="elements[0]"
    [resetDrag]="reset"
    (setEditingInProgress)="setEditingInProgress($event)"
  ></app-property-configurator-entity-details>
  <app-property-configurator-iframe
    *ngIf="elementType === 'iframe'"
    [selectedElement]="elements[0]"
    [resetDrag]="reset"
  ></app-property-configurator-iframe>

  <div class="widgets-list text-black60" *ngIf="sourceEvent">
    <div class="widgets-list" *ngIf="!isLocked">
      <!-- <mat-divider *ngIf="properties.includes('font-family')" vertical='true' class='divider'></mat-divider> -->

      <app-property-configurator-color-picker
        *ngIf="properties.includes('table-background-color') && elementType"
        matTooltip="Edit background"
        matTooltipPosition="above"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (valueChange)="handleDelayedTextValueChange($event)"
        [currentValue]="getTextAttributeValue('backgroundColor')"
        [matIconType]="'format_color_fill'"
        [colorType]="'backgroundColor'"
        [colorChip]="true"
      >
      </app-property-configurator-color-picker>

      <app-property-configurator-sticky-note-background
        matTooltip="Edit background"
        matTooltipPosition="above"
        data-test="sticky-note-background"
        (valueChange)="handleDelayedValueChange($event)"
        [currentValue]="getCurrentValue('style.backgroundColor')"
        [selectedElement]="elements[0]"
        *ngIf="properties.includes('sticky-note-background')"
      >
      </app-property-configurator-sticky-note-background>

      <app-property-configurator-sticky-note-size
        matTooltip="Edit size"
        matTooltipPosition="above"
        data-test="sticky-note-size"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getCurrentValue('size')"
        [selectedElement]="elements[0]"
        *ngIf="properties.includes('sticky-note-size')"
      >
      </app-property-configurator-sticky-note-size>

      <app-property-configurator-edit-name
        matTooltip="Edit name"
        matTooltipPosition="above"
        [selectedElement]="elements[0]"
        *ngIf="properties.includes('edit-name')"
      >
      </app-property-configurator-edit-name>
      <app-property-configurator-dynamic-text
        *ngIf="properties.includes('font-family') && elements.length === 1 && isDynamicTextInSelectedElements()"
        data-test="configure-dynamic-text"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('textType')"
        [element]="selectedElements[0]"
      ></app-property-configurator-dynamic-text>
      <mat-divider
        *ngIf="elements.length === 1 && isDynamicTextInSelectedElements()"
        vertical="true"
        class="divider"
      ></mat-divider>

      <app-property-configurator-text-type
        *ngIf="properties.includes('font-family')"
        data-test="text-type-selector"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('textType')"
      ></app-property-configurator-text-type>

      <mat-divider
        *ngIf="
          properties.includes('font-family') ||
          properties.includes('sticky-note-font-family') ||
          properties.includes('table-background-color')
        "
        vertical="true"
        class="divider"
        [ngClass]="{ '!ml-px': properties.includes('table-background-color') }"
      ></mat-divider>

      <app-property-configurator-font-family-selector
        data-test="font-family-selector"
        matTooltip="Font"
        matTooltipPosition="above"
        *ngIf="properties.includes('sticky-note-font-family')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('fontFamily')"
      >
      </app-property-configurator-font-family-selector>
      <app-property-configurator-font-size
        class="property-configurator-font-size-sticky-note"
        data-test="font-size"
        matTooltip="Font size"
        matTooltipPosition="above"
        *ngIf="properties.includes('sticky-note-font-size')"
        (valueChange)="updateTextElement($event)"
        [defaultValue]="'10'"
        [sizes]="['Auto', '4', '8', '10', '12', '14', '18', '24', '32', '48', '64']"
        [autoSize]="true"
        [autoSizeValue]="getTextAttributeValue('fontSizeMode')"
        [maxValue]="getTextAttributeValue('maxFontSize')"
        [minValue]="4"
        [currentValue]="getTextAttributeValue('fontSize')"
      >
      </app-property-configurator-font-size>

      <app-property-configurator-font-family-selector
        data-test="font-family-selector"
        matTooltip="Font"
        matTooltipPosition="above"
        *ngIf="properties.includes('font-family') || properties.includes('table-font-family')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('fontFamily')"
      >
      </app-property-configurator-font-family-selector>
      <app-property-configurator-font-size
        data-test="font-size"
        matTooltip="Font size"
        matTooltipPosition="above"
        *ngIf="properties.includes('font-size')"
        (valueChange)="updateTextElement($event)"
        [defaultValue]="'11'"
        [currentValue]="getTextAttributeValue('fontSize')"
      >
      </app-property-configurator-font-size>
      <app-property-configurator-color-picker
        data-test="text-color"
        matTooltip="Text color"
        matTooltipPosition="above"
        *ngIf="properties.includes('font-color')"
        (valueChange)="handleDelayedTextValueChange($event)"
        [currentValue]="getTextAttributeValue('textColor')"
        [matIconType]="'format_color_text'"
        [colorType]="'fontColor'"
        [resetPos]="reset"
      >
      </app-property-configurator-color-picker>

      <button
        *ngIf="
          properties.includes('font-bold') ||
          properties.includes('font-italic') ||
          properties.includes('font-underline') ||
          properties.includes('font-strikethrough') ||
          properties.includes('highlight-color') ||
          properties.includes('clear-format')
        "
        mat-icon-button
        [matMenuTriggerFor]="textFormatting"
        matTooltip="Text formatting"
        matTooltipPosition="above"
        data-test="text-format"
        class="tinymce-format-button"
      >
        <mat-icon svgIcon="text-formatting"></mat-icon>
      </button>
      <mat-menu #textFormatting="matMenu" class="text-menu">
        <app-property-configurator-text-decorator
          data-test="text-bold"
          matTooltip="Bold"
          decoratorType="bold"
          [matIconType]="'format_bold'"
          *ngIf="properties.includes('font-bold')"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('bold')"
        >
        </app-property-configurator-text-decorator>
        <app-property-configurator-text-decorator
          data-test="text-italic"
          matTooltip="Italic"
          decoratorType="italic"
          [matIconType]="'format_italic'"
          *ngIf="properties.includes('font-italic')"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('italic')"
        >
        </app-property-configurator-text-decorator>
        <app-property-configurator-text-decorator
          data-test="text-underline"
          matTooltip="Underlined"
          decoratorType="underline"
          [matIconType]="'format_underline'"
          *ngIf="properties.includes('font-underline')"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('underline')"
        >
        </app-property-configurator-text-decorator>
        <app-property-configurator-text-decorator
          data-test="text-strikethrough"
          matTooltip="Strikethrough"
          decoratorType="strikethrough"
          [matIconType]="'strikethrough_s'"
          *ngIf="properties.includes('font-strikethrough')"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('strikethrough')"
        >
        </app-property-configurator-text-decorator>
        <app-property-configurator-color-picker
          data-test="composer-toolbar-highlight-color"
          matTooltip="Highlight text"
          *ngIf="properties.includes('highlight-color')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('textBackgroundColor')"
          [svgIconType]="'highlight'"
          [colorType]="'textBackgroundColor'"
          [resetPos]="reset"
        >
        </app-property-configurator-color-picker>
        <button
          data-test="clear-format"
          class="tinymce-format-button"
          *ngIf="properties.includes('clear-format')"
          (click)="clearFormat($event)"
          matTooltip="Clear format"
          mat-icon-button
        >
          <mat-icon class="toggle-icon text-black60">format_clear</mat-icon>
        </button>
      </mat-menu>

      <button
        *ngIf="
          properties.includes('text-alignment') ||
          properties.includes('list-type') ||
          properties.includes('indentation')
        "
        mat-icon-button
        [matMenuTriggerFor]="paragraph"
        matTooltip="Paragraph"
        matTooltipPosition="above"
        data-test="paragraph"
      >
        <mat-icon svgIcon="format-paragraph"></mat-icon>
      </button>
      <mat-menu #paragraph="matMenu" class="paragraph-menu">
        <app-property-configurator-text-alignment
          matTooltip="Text align"
          data-test="composer-toolbar-text-align"
          *ngIf="properties.includes('text-alignment')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('textAlign')"
        >
        </app-property-configurator-text-alignment>
        <app-property-configurator-text-valignment
          matTooltip="Vertical align"
          data-test="composer-toolbar-text-valign"
          *ngIf="properties.includes('text-valignment')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('textValign')"
        >
        </app-property-configurator-text-valignment>
        <app-property-configurator-list-type
          data-editor="tinymce"
          matTooltip="Bullet lists"
          *ngIf="properties.includes('list-type')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('listType')"
        >
        </app-property-configurator-list-type>
        <app-property-configurator-indentation
          data-editor="tinymce"
          data-test="composer-toolbar-indentation"
          *ngIf="properties.includes('indentation')"
          (valueChange)="updateTextElement($event)"
          [currentValue]="getTextAttributeValue('indentation')"
        >
        </app-property-configurator-indentation>
      </mat-menu>

      <app-property-configurator-link
        matTooltip="Add link"
        matTooltipPosition="above"
        data-editor="tinymce"
        data-test="composer-toolbar-link"
        *ngIf="properties.includes('link') && this.textFormat?.position"
        (valueChange)="updateTextElement($event)"
        [element]="textElement"
        [position]="getTextAttributeValue('position')"
        [currentValue]="getTextAttributeValue('link')"
      >
      </app-property-configurator-link>

      <app-property-configurator-color-picker
        matTooltip="Fill color"
        matTooltipPosition="above"
        *ngIf="
          properties.includes('background-color') || (properties.includes('recolor-svg') && hasSvgRecolorFeatureFlag)
        "
        (valueChange)="handleDelayedValueChange($event)"
        [currentValue]="getCurrentValue('style.backgroundColor')"
        [matIconType]="'format_color_fill'"
        [colorType]="'backgroundColor'"
        [resetPos]="reset"
      >
      </app-property-configurator-color-picker>

      <app-property-configurator-clip-content
        *ngIf="properties.includes('clip-content') && elements?.length > 0"
        [selectedElement]="elements[elements.length - 1]"
      ></app-property-configurator-clip-content>

      <button
        data-test="border-style-menu"
        class="border-style-button"
        *ngIf="
          properties.includes('border-color') ||
          properties.includes('border-size') ||
          properties.includes('border-style')
        "
        mat-icon-button
        [matMenuTriggerFor]="borderStyle"
        matTooltip="Border"
        matTooltipPosition="above"
      >
        <mat-icon>border_style</mat-icon>
      </button>
      <mat-menu #borderStyle="matMenu" class="border-style-menu">
        <app-property-configurator-color-picker
          matTooltip="{{ elementType === 'line' ? 'Line color' : 'Border color' }}"
          matTooltipPosition="above"
          *ngIf="properties.includes('border-color')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="handleDelayedValueChange($event)"
          [currentValue]="getCurrentValue('style.border.color')"
          [matIconType]="'border_color'"
          [colorType]="'borderColor'"
          [resetPos]="reset"
        >
        </app-property-configurator-color-picker>
        <app-property-configurator-border-size
          matTooltip="{{ elementType === 'line' ? 'Line weight' : 'Border weight' }}"
          matTooltipPosition="above"
          *ngIf="properties.includes('border-size')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateValues($event)"
          [currentValue]="getCurrentValue('style.border.width')"
        >
        </app-property-configurator-border-size>
        <app-property-configurator-border-style
          matTooltip="{{ elementType === 'line' ? 'Line style' : 'Border style' }}"
          matTooltipPosition="above"
          data-test="composer-toolbar-border-style"
          *ngIf="properties.includes('border-style')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateValues($event)"
          [currentValue]="getCurrentValue('style.border.style')"
        >
        </app-property-configurator-border-style>
      </mat-menu>

      <app-property-configurator-color-picker
        *ngIf="properties.includes('pen-border-color') && elementType"
        matTooltip="Line color"
        matTooltipPosition="above"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('style.border.color')"
        [matIconType]="'border_color'"
        [colorType]="'borderColor'"
        [closeOnSelect]="true"
        [colorChip]="true"
      >
      </app-property-configurator-color-picker>
      <app-property-configurator-border-size
        *ngIf="properties.includes('pen-border-size') && elementType"
        matTooltip="Line thickness"
        matTooltipPosition="above"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('style.border.width')"
        [borderSizes]="[2, 4, 6, 8, 10, 12, 14, 16, 24, 48, 96]"
      >
      </app-property-configurator-border-size>

      <app-property-configurator-border-radius
        matTooltip="Rounded corners"
        matTooltipPosition="above"
        *ngIf="properties.includes('border-radius')"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('style.border.radius')"
      ></app-property-configurator-border-radius>
      <app-property-configurator-line-marker
        data-test="composer-toolbar-line-marker"
        *ngIf="properties.includes('line-marker')"
        (valueChange)="updateValues($event)"
        [currentMarkerStart]="getCurrentValue('lineDefinition.markerStart')"
        [currentMarkerEnd]="getCurrentValue('lineDefinition.markerEnd')"
      >
      </app-property-configurator-line-marker>
      <app-property-configurator-image-transparency
        matTooltip="Transparency"
        matTooltipPosition="above"
        *ngIf="properties.includes('image-transparency')"
        (valueChange)="handleDelayedValueChange($event)"
        [currentValue]="getCurrentValue('style.opacity')"
      ></app-property-configurator-image-transparency>

      <button
        mat-icon-button
        data-test="crop"
        class="crop-button"
        *ngIf="properties.includes('crop') && !isLocked"
        (click)="cropImage(sourceEvent?.element)"
        matTooltip="Crop"
        matTooltipPosition="above"
      >
        <mat-icon class="cursor-pointer">crop</mat-icon>
      </button>

      <app-property-configurator-group-element
        *ngIf="!isLocked"
        data-test="composer-toolbar-group-element"
        [elements]="selectedElements"
      >
      </app-property-configurator-group-element>

      <app-property-configurator-mask-element
        *ngIf="!isLocked"
        data-test="composer-toolbar-mask-element"
        [elements]="elements"
      >
      </app-property-configurator-mask-element>
    </div>

    <mat-divider
      *ngIf="!isLocked && properties.includes('size') && elements?.length > 0"
      vertical="true"
      class="divider"
    ></mat-divider>
    <app-property-configurator-size-info
      *ngIf="properties.includes('size') && elements?.length > 0"
      [selectedElement]="selectedElement"
    ></app-property-configurator-size-info>
    <mat-divider
      *ngIf="isLocked && properties.includes('size') && elements?.length > 0"
      vertical="true"
      class="divider"
    ></mat-divider>

    <mat-divider *ngIf="!isLocked" vertical="true" class="divider"></mat-divider>

    <button
      *ngIf="properties.includes('recolor-svg') && !isLocked && elements?.length === 1 && hasSvgRecolorFeatureFlag"
      data-test="composer-toolbar-image-options"
      mat-icon-button
      matTooltip="Content Options"
      matTooltipPosition="above"
      (click)="openContentEditor()"
    >
      <mat-icon>tune</mat-icon>
    </button>

    <button
      *ngIf="
        properties.includes('component-editor') &&
        (this.elements[0]?.modelBindings?.item || this.elements[0]?.modelBindings?.color) &&
        !isLocked
      "
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="showComponentConfigurator()"
      mat-icon-button
      matTooltip="Format options"
      matTooltipPosition="above"
      data-test="component-format-options"
    >
      <mat-icon class="toggle-icon">settings</mat-icon>
    </button>

    <app-property-configurator-download
      *ngIf="properties.includes('download')"
      matTooltip="Download"
      matTooltipPosition="above"
      [element]="elements[0]"
    >
    </app-property-configurator-download>

    <app-property-configurator-locked-element
      *ngIf="elementType && isLockingActionAllowed()"
      data-test="composer-toolbar-locked-element"
      (valueChange)="updateValues($event)"
      [currentValue]="getCurrentValue('isLocked')"
      [element]="elements[0]"
    >
    </app-property-configurator-locked-element>

    <button
      *ngIf="this.selectedGroupElements?.length > 1 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="alignment"
      matTooltip="Align"
      matTooltipPosition="above"
    >
      <mat-icon class="cursor-pointer">align_horizontal_left</mat-icon>
    </button>
    <button
      *ngIf="this.selectedGroupElements?.length > 2 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="distribution"
      matTooltip="Distribute"
      matTooltipPosition="above"
    >
      <mat-icon class="cursor-pointer">horizontal_distribute</mat-icon>
    </button>
    <button
      data-test="match-size"
      *ngIf="selectedGroupElementsMatchSizeEligible?.length > 1 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="matchsize"
      matTooltip="Resize images"
      matTooltipPosition="above"
    >
      <mat-icon class="icons material-symbols-outlined">select_all</mat-icon>
    </button>

    <mat-divider
      *ngIf="this.selectedGroupElements?.length > 1 && !isLocked"
      vertical="true"
      class="divider"
    ></mat-divider>

    <button
      mat-icon-button
      data-test="composer-toolbar-context-menu"
      (click)="openContextMenu($event)"
      *ngIf="properties?.length > 0"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <!-- <app-property-configurator-drop-item
      *ngIf="properties.includes('entity-details') && elements?.length === 1"
      [currentValue]="elements[0]">
    </app-property-configurator-drop-item> -->
  </div>
</div>

<mat-menu #alignment="matMenu">
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_left')">
    <mat-icon>align_horizontal_left</mat-icon> Left
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_center')">
    <mat-icon>align_horizontal_center</mat-icon> Center
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_right')">
    <mat-icon>align_horizontal_right</mat-icon> Right
  </button>
  <mat-divider></mat-divider>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_top')">
    <mat-icon>align_vertical_top</mat-icon> Top
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_middle')">
    <mat-icon>align_vertical_center</mat-icon> Middle
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_bottom')">
    <mat-icon>align_vertical_bottom</mat-icon> Bottom
  </button>
</mat-menu>

<mat-menu #distribution="matMenu">
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.horizontal')">
    <mat-icon>horizontal_distribute</mat-icon> Horizontal
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.vertical')">
    <mat-icon>vertical_distribute</mat-icon> Vertical
  </button>
</mat-menu>

<mat-menu #matchsize="matMenu">
  <button
    data-test="match-height"
    class="context-menu-button"
    mat-menu-item
    (click)="emitActionRequest('match.height')"
  >
    Match height
  </button>
  <button data-test="match-width" class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.width')">
    Match width
  </button>
</mat-menu>
