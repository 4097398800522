import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentElementEvent } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DocumentItemService } from '../../document-item/document-item.service';
import { ComponentEditorService } from '../component-editor.service';
import { DocumentComponentUtils } from '../../document-component/document-component.utils';

@Component({
  selector: 'component-editor-property-configurator',
  templateUrl: './component-editor-property-configurator.component.html',
  styleUrls: ['./component-editor-property-configurator.component.scss'],
})
export class ComponentEditorPropertyConfiguratorComponent implements OnInit {
  public sourceEvent: DocumentElementEvent;
  public sizes: string[];

  @ViewChild('widgetTray') widgetElement: ElementRef;
  @Output() valuesChanged = new EventEmitter<any>();

  private subject: Subject<any> = new Subject();

  constructor(private documentItemService: DocumentItemService) {
    this.sizes = ComponentEditorService.getPtOptions();
  }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe((values) => {
      if (values && values?.type === 'fontSize' && values?.value) {
        values.value = ComponentEditorService.toPx(values.value);
      }
      this.updateValues(values);
    });
  }

  show(event: DocumentElementEvent) {
    this.sourceEvent = event;
    if (!this.widgetElement) {
      return;
    }
    const top = event.renderedElementPosition.y - 70;
    const left = event.renderedElementPosition.x - 420;
    this.widgetElement.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.widgetElement.nativeElement.classList.add('visible');
  }

  hide() {
    if (this.widgetElement) {
      this.widgetElement.nativeElement.classList.remove('visible');
    }
  }

  updateValues(values) {
    const changes = DocumentComponentUtils.convertToTextStyle(values);
    this.valuesChanged.emit(changes);
  }

  clearFormat(event) {
    this.valuesChanged.emit({ style: this.documentItemService.getDefaultTextFormat(this.sourceEvent.element) });
  }

  handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  getCurrentValue(index) {
    let value = ObjectUtil.getByPath(this.sourceEvent.element, index);
    if (value && index === 'style.font.size') {
      value = ComponentEditorService.toPt(value);
    }
    return value;
  }

  handleClick(event) {
    event.stopPropagation();
  }
}
