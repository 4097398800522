<button
  class="toggle-icon"
  (click)="toggleLocked()"
  [matTooltip]="isLocked ? 'Unlock' : 'Lock'"
  matTooltipPosition="above"
  mat-icon-button
>
  <mat-icon *ngIf="!isLocked">lock_open</mat-icon>
  <mat-icon *ngIf="isLocked">lock</mat-icon>
</button>
