import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, firstValueFrom } from 'rxjs';
import { BoardService } from '../board.service';
import { BoardsSelectors } from '../../boards-store';
import { DocumentService } from '../document/document.service';
import { ViewBox } from '../canvas/viewbox';
import { Frame } from '../canvas/state/canvas-frame-state';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { cloneDeep } from '@contrail/util/lib/object-util/cloneDeep/cloneDeep';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentSelectors } from '../document/document-store';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BoardPropertyPoliciesService } from '../board-property-policies/board-property-policies.service';
import { ExportsService } from '@common/exports/exports.service';
import { DownloadService } from '@common/exports/download/download.service';

@Injectable({
  providedIn: 'root',
})
export class BoardFrameService {
  private frameSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public frames$: Observable<any> = this.frameSubject.asObservable();
  public selectedFrameIdx$: BehaviorSubject<number> = new BehaviorSubject(-1);
  public selectedFrameIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public presentationMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isBoardSideMenuVisible = false;
  public isFrameSideMenuVisible = false;
  public onFrameSelection$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public sortWarningShowed = false;
  public socketChanges = false;
  public sortFormGroup: UntypedFormGroup = new UntypedFormGroup({
    sortBy: new UntypedFormControl({ label: 'Left to right', property: 'horizontal' }),
    keepSorted: new UntypedFormControl(false),
  });

  constructor(
    private store: Store<RootStoreState.State>,
    private boardService: BoardService,
    private documentService: DocumentService,
    private exportsService: ExportsService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private downloadService: DownloadService,
    private webSocketService: WebSocketService,
  ) {
    this.documentService.documentElements.pipe(debounceTime(1000)).subscribe((elements) => {
      if (elements && elements.length) {
        const frames = elements.filter((i) => i.type === 'frame');
        let frameObj = [];
        frames.forEach((f) => {
          frameObj.push({ id: f.id, frame: f });
        });
        this.frameSubject.next(frameObj);
      } else {
        this.frameSubject.next(null);
      }
    });

    this.store.select(DocumentSelectors.toggleChooser).subscribe((overlay) => {
      this.isBoardSideMenuVisible = !overlay?.showChooser ? false : true;
      this.isFrameSideMenuVisible = overlay?.slug === 'frames' ? true : false;
    });

    this.documentService.documentElementEvents.subscribe((event) => {
      // if user clicks Canvas, arrow keys will focus on element move
      if (event.eventType === 'handleClick' || event.eventType === 'dblclick') {
        this.onFrameSelection$.next(false);
      }
      if (event.eventType === 'selected' || event.eventType === 'deselect') {
        setTimeout(() => {
          const selectedElements = this.documentService.getSelectedElements();
          const selectedFrameIds = selectedElements.filter((e) => e.type === 'frame').map((e) => e.id);
          this.selectedFrameIds$.next(selectedFrameIds);
        }, 0);
      }
    });

    this.documentService.actionRequests.subscribe(async (request) => {
      if (request?.actionType === 'export_frame_ppt' || request?.actionType === 'export_frame_pdf') {
        const type = request.actionType === 'export_frame_ppt' ? 'powerpoint' : 'pdf_snapshots';
        const currentBoard = await firstValueFrom(this.boardService.currentBoard);
        let payload: any = {};
        payload.entityReference = `board:${currentBoard.id}`;
        payload.selectedIds = this.documentService
          .getSelectedElements()
          .filter((e) => e.type === 'frame')
          .map((e) => e.id);
        if (type === 'powerpoint') {
          payload.showWarnings = await firstValueFrom(this.store.select(BoardsSelectors.showSourceAssortmentWarning));
        } else if (type === 'pdf_snapshots') {
          payload.name = currentBoard.name;
          payload.restrictedViewablePropertySlugs = this.boardPropertyPoliciesService.restrictedViewablePropertySlugs;
          const framesMap = this.documentService.getFrames();
          let frames = Array.from(framesMap.values());
          frames.sort((a, b) => a.index - b.index);
          payload.frames = frames.map((frame: Frame) => {
            const frameElement: DocumentElement = cloneDeep(frame.element.elementDefinition);
            frameElement.elements = frame.elements.map((e) => cloneDeep(e.canvasElement.elementDefinition));
            return frameElement;
          });
        }

        this.downloadService.downloading$.next(true);
        this.exportsService.initDownloadEntityReference({ reportType: type }, payload);
      } else if (request?.actionType === 'select_frame' && this.onFrameSelection$.value) {
        // const keyboardWhiteList: string[] = ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'];
        let targetId = null;
        if (
          ['ArrowRight', 'ArrowDown'].includes(request.sourceEvent.key) &&
          this.selectedFrameIdx$.value !== this.frameSubject.value.length - 1
        ) {
          // NEXT
          targetId = this.selectedFrameIdx$.value + 1;
        } else if (['ArrowLeft', 'ArrowUp'].includes(request.sourceEvent.key) && this.selectedFrameIdx$.value > 0) {
          // BEFORE
          targetId = this.selectedFrameIdx$.value - 1;
        }

        if (targetId !== null) {
          this.selectedFrameIdx$.next(targetId);
          this.updateViewportByFrame(this.frameSubject.value[targetId].frame);
        }
      }
    });
  }

  sendFrameSortSocketMessage(event) {
    this.webSocketService.sendSessionEvent({
      eventType: 'UPDATE_FRAME_SORT',
      changes: {
        sortBy: event?.sortBy,
        keepSorted: event?.keepSorted,
      },
    });
  }

  handleFrameSortSocketMessage(changes) {
    this.socketChanges = true;
    this.sortFormGroup.setValue({
      sortBy: changes.sortBy,
      keepSorted: changes.keepSorted,
    });
  }

  updateOneFrameOrder(prevIdx, currIdx) {
    const undoElements = cloneDeep(this.documentService.getDocument().elements);
    const frames = this.frameSubject.value;

    const selectedId = frames[prevIdx].id;
    let frameEleIds = [selectedId];
    const selectedFrameMap: Frame = this.documentService.getFrames().get(selectedId);
    selectedFrameMap.elements.forEach((e) => {
      frameEleIds.push(e.canvasElement.id);
    });

    let movingElements = [];
    let elements = [];
    undoElements.forEach((e) => {
      if (frameEleIds.includes(e.id)) {
        movingElements.push(e);
      } else {
        elements.push(e);
      }
    });

    let targetIdx;
    if (prevIdx > currIdx) {
      // up
      targetIdx = elements.findIndex((ele) => ele.id === frames[currIdx].id);
    } else {
      // down
      const next = currIdx + 1;
      if (frames?.length === next) {
        targetIdx = elements.length;
      } else {
        targetIdx = elements.findIndex((ele) => ele.id === frames[next].id);
      }
    }

    elements.splice(targetIdx, 0, ...movingElements);
    // const ele = elements.map(e => {return {id: e.id, type: e.type}})

    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: elements,
      },
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: undoElements,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }

  updateMultiFramesOrder(selectedIds, sortOrderIds) {
    const undoElements = cloneDeep(this.documentService.getDocument().elements);

    let frameEleIds = [];
    selectedIds.forEach((selectedId) => {
      frameEleIds.push(selectedId);
      const selectedFrameMap: Frame = this.documentService.getFrames().get(selectedId);
      selectedFrameMap.elements.forEach((e) => {
        frameEleIds.push(e.canvasElement.id);
      });
    });

    let movingElements = [];
    let elements = [];
    undoElements.forEach((e) => {
      if (frameEleIds.includes(e.id)) {
        movingElements.push(e);
      } else {
        elements.push(e);
      }
    });

    const lastId = selectedIds[selectedIds.length - 1];
    const targetIdx = sortOrderIds.indexOf(lastId);
    const targetId = sortOrderIds[targetIdx + 1];
    if (targetId) {
      const spliceIdx = elements.findIndex((ele) => ele.id === targetId);
      elements.splice(spliceIdx, 0, ...movingElements);
    } else {
      elements.push(movingElements);
    }

    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: elements,
      },
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: undoElements,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }

  updateViewportByFrame(frame) {
    const frameSize = frame?.size;
    const framePos = frame?.position;
    const zoomPan = this.boardService.zoomPanHandler;

    // const margin = 430; // 430px DocumentWidgetTrayComponent + BoardSideMenuComponent + left/right margin;
    let margin;
    let padding;
    let left = 72; // 72px = DocumentWidgetTrayComponent + left margin

    if (
      (!this.boardService.fullScreen$.value && this.isBoardSideMenuVisible) ||
      (this.boardService.fullScreen$.value && !this.presentationMode$.value)
    ) {
      margin = 430;
      padding = 50;
    } else {
      const frameRatio = 0.5625;
      const screenRatio = zoomPan.canvasSize.height / zoomPan.canvasSize.width;
      padding = screenRatio > frameRatio ? 100 : 230;
      margin = 0;
      left = 0;
    }

    const canvasWidth = zoomPan.canvasSize.width - margin - padding * 2;
    this.boardService.zoomPanHandler.setZoomFactor(frameSize.width / canvasWidth);

    // zoomPanHandler updated
    const zoomFactor = this.boardService.zoomPanHandler.zoomFactor;
    const py = zoomPan.canvasSize.height - frameSize.height / zoomFactor; // padding top-bottom

    const newViewBox: ViewBox = {
      width: zoomPan.canvasSize.width * zoomFactor,
      height: zoomPan.canvasSize.height * zoomFactor,
      x: framePos.x - (left + padding) * zoomFactor,
      y: framePos.y - (py / 2) * zoomFactor,
    };

    this.boardService.zoomPanHandler.setViewPort(newViewBox, false);
  }
}
