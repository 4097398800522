import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { SessionMessage, SessionMessageHandler } from '../web-socket/session-message';
import { WebSocketMessageDispatcher } from '../web-socket/web-socket-mesage-dispatcher';
import { CommentsActions } from './comments-store';

@Injectable({
  providedIn: 'root',
})
export class CommentsMessageHandler implements SessionMessageHandler {
  public someProp = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
  ) {
    this.init();
  }

  private init() {
    this.websocketMessageDispatcher.registerHandler('ADD_COMMENT', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_COMMENT', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_COMMENTS', this);
    this.websocketMessageDispatcher.registerHandler('DELETE_COMMENT', this);
  }

  public handleMessage(message: SessionMessage) {
    switch (message.event?.eventType) {
      case 'ADD_COMMENT': {
        this.store.dispatch(CommentsActions.addComment({ comment: message.event.changes }));
        break;
      }
      case 'UPDATE_COMMENT': {
        this.store.dispatch(CommentsActions.modifyComment({ comment: message.event.changes }));
        break;
      }
      case 'UPDATE_COMMENTS': {
        this.store.dispatch(CommentsActions.modifyComments({ comments: message.event.changes }));
        break;
      }
      case 'DELETE_COMMENT': {
        this.store.dispatch(CommentsActions.removeComment({ comment: message.event.changes }));
        break;
      }
    }
  }
}
