<div class="widgets-list">
  <button mat-icon-button [matMenuTriggerFor]="imageTransparencyMenu"><mat-icon>opacity</mat-icon></button>
  <mat-menu #imageTransparencyMenu="matMenu">
    <div class="opacity-widget">
      <label class="opacityValue">{{ opacity * 100 }}%</label>
      <mat-slider
        (click)="$event.stopPropagation()"
        class="slider"
        vertical="true"
        max="1"
        min="0"
        step="0.1"
        [value]="opacity"
        (input)="handleImageTransparencyChange($event)"
      ></mat-slider>
    </div>
  </mat-menu>
</div>
