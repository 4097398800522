import { DocumentElement } from '@contrail/documents';
import { CanvasImageLoader } from '../elements/image/canvas-image-loader';
import { Cache } from './cache';

export class ImageElementCache extends Cache {
  constructor() {
    super();
  }

  /**
   * Check if an image response for given parameters exists in cache and returns it.
   * Generates new image and sets it to cache if it doesn't exist.
   * If there is no existing promise, sets unresolved promise to cache
   * so other simultaneous calls to loadFromCache did not generate new image and
   * used existing promise.
   * @param urlToLoad
   * @param elementDefinition
   * @param userContext
   * @param isPartOfComponent
   * @param isFirefox
   * @param drawToOffscreenCanvas
   * @returns
   */
  public static async loadFromCache(
    urlToLoad: string,
    elementDefinition: DocumentElement,
    userContext,
    componentElement: DocumentElement,
    isFirefox: boolean,
    drawToOffscreenCanvas = false,
    isAnonymous = false,
  ) {
    let cacheKey = `${urlToLoad}_${componentElement ? (componentElement?.entityData?.roles || [true])?.join('') : false}_${drawToOffscreenCanvas}_${isAnonymous}`;
    if (isFirefox && elementDefinition.type === 'svg') {
      cacheKey += `_${elementDefinition?.size?.height}_${elementDefinition?.size?.width}`;
    }
    const existingPromise = this.get(cacheKey);
    if (existingPromise) {
      if (existingPromise.promise) {
        return await existingPromise.promise;
      }
      return existingPromise.resolvedPromise;
    }

    const promise = CanvasImageLoader.getLoadImagePromise(
      urlToLoad,
      elementDefinition,
      userContext,
      componentElement,
      isFirefox,
      drawToOffscreenCanvas,
      isAnonymous,
    );
    this.set(cacheKey, {
      promise,
    });
    const imgLoaderResponse = await promise;
    this.set(cacheKey, { resolvedPromise: imgLoaderResponse, promise: null });
    return imgLoaderResponse;
  }

  public static clear() {
    for (let value of this.cacheMap?.values()) {
      if (value.resolvedPromise) {
        value.resolvedPromise.imageElement = null;
        value.resolvedPromise.canvasImageElement = null;
        value.resolvedPromise.imageSize = null;
        value.resolvedPromise = null;
      }
    }
    super.clear();
  }

  public static getCacheMetrics() {
    let loadedCachedImageCount = 0;
    let loadedCachedImagePixelSize = 0;
    for (let value of this.cacheMap?.values()) {
      if (value.resolvedPromise) {
        loadedCachedImageCount++;
        let img = value.resolvedPromise.imageElement;
        if (img) {
          const pixels = img.naturalHeight * img.naturalWidth;
          console.log('ImageCache: Image: ', img.naturalHeight, img.naturalWidth, pixels);
          loadedCachedImagePixelSize += pixels;
        }

        //loadedCachedImageSize += value.resolvedPromise.imageSize;
      }
    }
    const metrics = `
      ---- Count: ${loadedCachedImageCount}
      ---- Pixel Total:  ${Math.round(loadedCachedImagePixelSize / 1000).toLocaleString()}K  Pixels
    `;
    return metrics;
  }
}
