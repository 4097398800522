import { CanvasDocument } from '../../canvas-document';
import { InputElement } from '../../components/input-element/input-element';
import { CanvasFrameElement } from './canvas-frame-element';

export class FrameInputElement {
  public inputElement: InputElement;
  private readonly HEIGHT = 20;

  constructor(
    private element: CanvasFrameElement,
    private canvasDocument: CanvasDocument,
  ) {
    this.inputElement = new InputElement(canvasDocument);
    this.inputElement.on('hide', this.onInputElementHide.bind(this));
    this.inputElement.on('save', this.onInputElementSave.bind(this));
  }

  public show() {
    if (this.element.elementDefinition.isLocked || !this.element.interactable) {
      return;
    }
    this.inputElement.show(this.element.elementDefinition.name, {
      height: this.HEIGHT,
      top: -this.HEIGHT,
      left: -2,
      position: this.element.getPosition(),
    });

    this.element.frameNameVisible = false;
    this.canvasDocument.draw();
  }

  public setPosition() {
    this.inputElement.setPosition(this.element.getPosition());
  }

  private onInputElementHide(frameName: string) {
    this.element.elementDefinition.name = frameName;
    this.element.frameNameVisible = true;
    this.canvasDocument.draw();
  }

  private onInputElementSave(frameName: string) {
    if (frameName && frameName?.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        [
          {
            id: this.element.elementDefinition.id,
            name: frameName,
          },
        ],
        [
          {
            id: this.element.elementDefinition.id,
            name: this.element.elementDefinition.name,
          },
        ],
      );
    }
  }
}
