import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentChangeType, DocumentElement, DocumentElementEvent, DocumentAction } from '@contrail/documents';
import { DocumentManagerService } from '../document-manager-service.interface';
import { FONTS_FAMILY_PICKLIST } from './property-configurator-font-family-selector/property-configurator-font-family-selector-options';
import { AuthService } from '../../../../common/auth/auth.service';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';

export interface FontInformation {
  defaultFontFamily: string;
  fontFamilyPickList: any[];
}

@Injectable({
  providedIn: 'root',
})
export class PropertyConfiguratorService {
  defaultFontFamily = 'Roboto';
  fontFamilyPickList = [];

  private documentElementSubscription: Subscription = new Subscription();
  public documentElementEventSubject: Subject<DocumentElementEvent> = new BehaviorSubject(null);
  public documentElementEvents: Observable<any> = this.documentElementEventSubject.asObservable();
  // Text element events, streaming from the key handler
  private documentTextElementKeyEventsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public documentTextElementKeyEvents: Observable<any> = this.documentTextElementKeyEventsSubject.asObservable();

  private documentManagerService: DocumentManagerService;
  constructor(
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
  ) {}

  setManagementService(service: DocumentManagerService) {
    this.documentElementSubscription.unsubscribe(); // unubscribe to avoid subscribing to document events multiple times
    this.documentManagerService = service;
    this.documentElementSubscription = this.documentManagerService.documentElementEvents.subscribe((event) => {
      this.documentElementEventSubject.next(event);
    });

    this.store
      .select(CustomFontsSelectors.customFontFamilies)
      .pipe(
        take(1),
        tap((customFontFamilies) => {
          if (customFontFamilies?.length > 0) {
            this.fontFamilyPickList = [...customFontFamilies].concat(FONTS_FAMILY_PICKLIST).sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            });
          } else {
            this.fontFamilyPickList = FONTS_FAMILY_PICKLIST;
          }
        }),
      )
      .subscribe();
  }
  handleElementChanges(elements: Array<DocumentElement>, undoElements?: Array<DocumentElement>) {
    // console.log("PropertyConfiguratorService: handleElementChanges")
    const actions: Array<DocumentAction> = [];
    elements.forEach((el, i) => {
      actions.push(
        new DocumentAction(
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: el,
            elementId: el.id,
          },
          undoElements
            ? {
                elementId: el.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: undoElements[i],
              }
            : null,
        ),
      );
    });
    this.documentManagerService.handleDocumentActions(actions);
  }

  getTextFontProperties(): FontInformation {
    return {
      defaultFontFamily: this.defaultFontFamily,
      fontFamilyPickList: this.fontFamilyPickList,
    };
  }

  public emitTextElementKeyEvent(textFormat) {
    console.log(textFormat);
    this.documentTextElementKeyEventsSubject.next(textFormat);
  }
}
