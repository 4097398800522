import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { tap, take } from 'rxjs';
import { Entities } from '@contrail/sdk';
import { TypePropertyUserList } from './user-list-store/user-list.state';
import { TypePropertyUserListsActions, TypePropertyUserListsSelectors } from '@common/user-list/user-list-store';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyUserListService {
  constructor(private store: Store<RootStoreState.State>) {}
  async getTypePropertyUserList(typePropertyUserListId: string) {
    let userList: any[] = [];
    let typePropertyUserList: TypePropertyUserList;
    this.store
      .select(TypePropertyUserListsSelectors.typePropertyUserLists)
      .pipe(
        take(1),
        tap((userLists) => {
          typePropertyUserList = userLists.find((obj) => obj.id === typePropertyUserListId);
        }),
      )
      .subscribe();

    if (!typePropertyUserList && typePropertyUserListId) {
      typePropertyUserList = await new Entities().get({ entityName: 'user-list', id: typePropertyUserListId });
      userList = typePropertyUserList?.userList?.length
        ? typePropertyUserList.userList.sort((v1, v2) => (v1.display > v2.display ? 1 : -1))
        : [];

      typePropertyUserList.userList = userList;
      this.store.dispatch(TypePropertyUserListsActions.addTypePropertyUserList({ typePropertyUserList }));
    } else if (!typePropertyUserList) {
      userList = [];
    } else {
      userList = typePropertyUserList.userList;
    }

    return ObjectUtil.cloneDeep(userList);
  }

  async getTypePropertyUserByEMail(typePropertyUserListId, userEmail) {
    const userList: any[] = await this.getTypePropertyUserList(typePropertyUserListId);
    const user = userList.find((user) => (user.email = userEmail));
    return user;
  }
}
