import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-assign-source-assortment-modal',
  templateUrl: './assign-source-assortment-modal.component.html',
  styleUrls: ['./assign-source-assortment-modal.component.scss'],
})
export class AssignSourceAssortmentModalComponent implements OnInit {
  defaultSourceAssortmentId: string;
  defaultCurrentWorkspaceId: string;
  selectedAssortmentId: string;
  constructor(
    public dialogRef: MatDialogRef<AssignSourceAssortmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.defaultSourceAssortmentId = data.defaultSourceAssortmentId;
    this.defaultCurrentWorkspaceId = data.defaultCurrentWorkspaceId;
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  setAssortment(selectedAssortmentId) {
    this.selectedAssortmentId = selectedAssortmentId;
  }

  assignSourceAssortment() {
    this.dialogRef.close({ sourceAssortmentId: this.selectedAssortmentId });
  }
}
