<div class="flex flex-col space-y-4 text-black60">
  <div class="flex justify-between">
    <app-property-configurator-font-family-selector
      matTooltip="Font"
      matTooltipPosition="above"
      [currentValue]="getCurrentValue('style.font.family')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-font-family-selector>

    <app-property-configurator-font-size
      matTooltip="Font size"
      matTooltipPosition="above"
      [defaultValue]="'8'"
      [currentValue]="getCurrentValue('style.font.size')"
      [sizes]="sizes"
      [minValue]="4"
      [maxValue]="72"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-font-size>
  </div>

  <div class="flex justify-between">
    <app-property-configurator-color-picker
      matTooltip="Text color"
      [matIconType]="'format_color_text'"
      [colorType]="'fontColor'"
      [currentValue]="getCurrentValue('style.color')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-color-picker>

    <app-property-configurator-text-decorator
      data-test="text-bold"
      matTooltip="Bold"
      decoratorType="bold"
      [matIconType]="'format_bold'"
      [currentValue]="getCurrentValue('style.font.weight')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-text-decorator>

    <app-property-configurator-text-decorator
      data-test="text-italic"
      matTooltip="Italic"
      decoratorType="italic"
      [matIconType]="'format_italic'"
      [currentValue]="getCurrentValue('style.font.style')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-text-decorator>

    <app-property-configurator-text-decorator
      data-test="text-underline"
      matTooltip="Underlined"
      decoratorType="underline"
      [matIconType]="'format_underline'"
      [currentValue]="getCurrentValue('style.text.decoration')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-text-decorator>

    <app-property-configurator-color-picker
      data-test="composer-toolbar-highlight-color"
      matTooltip="Highlight text"
      [svgIconType]="'highlight'"
      [colorType]="'textBackgroundColor'"
      [currentValue]="getCurrentValue('style.backgroundColor')"
      (valueChange)="handleDelayedValueChange($event)"
    ></app-property-configurator-color-picker>

    <button mat-icon-button data-test="text-clear-format" matTooltip="Clear format" (click)="clearFormat($event)">
      <mat-icon class="toggle-icon">format_clear</mat-icon>
    </button>
  </div>
</div>
