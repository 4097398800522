export interface CacheValue {
  promise?: Promise<any>;
  resolvedPromise?: any;
}
export class Cache {
  public static cacheMap: Map<any, CacheValue> = new Map();
  constructor() {}

  public static clear() {
    this.cacheMap.clear();
    this.cacheMap = null;
    this.cacheMap = new Map();
  }

  public static get(key) {
    return this.cacheMap.get(key);
  }

  public static set(key, value: CacheValue) {
    return this.cacheMap.set(key, value);
  }
}
