import { Component, Input, OnInit } from '@angular/core';
import { PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { ChangeSuggestion } from '../../comments.service';

@Component({
  selector: 'app-comment-suggested-changes-display',
  template: `
    <div *ngIf="suggestedChanges?.changeDetails" class="suggestContent">
      <div class="slug-label" *ngIf="typeProperty?.label">{{ typeProperty.label }}</div>
      <div class="current">
        <div class="label">From</div>
        <div class="pad">:</div>
        <div class="currentValue">{{ getDisplayValue(suggestedChanges.changeDetails.oldValue) }}</div>
      </div>
      <div class="suggested">
        <div class="label">To</div>
        <div class="pad">:</div>
        <div class="suggestedValue">{{ getDisplayValue(suggestedChanges.changeDetails.newValue) }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        margin-left: 4px;
      }

      .suggestContent {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .pad {
        padding-left: 2px;
        padding-right: 2px;
      }
      .current {
        display: flex;
      }
      .suggested {
        display: flex;
      }
      .slug-label {
        display: flex;
        padding-top: 4px;
        font-weight: 600;
        padding-bottom: 4px;
      }
      .label {
        display: flex;
        font-weight: 600;
      }
      .currentValue {
        font-weight: 400;
      }
      .suggestedValue {
        font-weight: 400;
        color: #ffc107;
      }
    `,
  ],
})
export class CommentSuggestedChangesDisplayComponent implements OnInit {
  @Input() suggestedChanges: ChangeSuggestion;
  @Input() typeProperty: TypeProperty;
  private formatter = new PropertyValueFormatter();
  constructor() {}

  ngOnInit(): void {}

  getDisplayValue(value) {
    if (this.typeProperty) {
      return this.formatter.formatValueForProperty(value, this.typeProperty);
    }
    return value;
  }
}
