import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { initialState, State } from './auth.state';

const setAuthContext = (state: State, { authContext }) => {
  return {
    ...state,
    authContext: { ...authContext },
  };
};
const setSharedLink = (state: State, { sharedLink }) => {
  return {
    ...state,
    sharedLink,
  };
};
export const authReducer = createReducer(
  initialState,
  on(AuthActions.setAuthContext, setAuthContext),
  on(AuthActions.setSharedLink, setSharedLink),
);

export function reducer(state: State | undefined, action: Action): any {
  return authReducer(state, action);
}
