import { CanvasDocument } from '../canvas-document';
import { DraggingBoxRenderer } from './dragging-box-renderer/dragging-box-renderer';
import { CanvasElementsRenderer } from './element-renderer/canvas-elements-renderer';
import { CanvasUtil } from '../canvas-util';
import { HighlightBoxRenderer } from './highlight-box-renderer/highlight-box-renderer';
import { GuidelinesRenderer } from './guidelines-renderer/guidelines-renderer';
import { debounceTime, Subject } from 'rxjs';
import { MultipleElementsSelectionWidgetRenderer } from './selection-widget-renderer/multiple-elements-selection-widget-renderer';
import { CanvasCopyElementsRenderer } from './element-renderer/canvas-copy-elements-renderer';
import { MaskBoxRenderer } from './mask-box-renderer/mask-box-renderer';
import { CropBoxRenderer } from './crop-box-renderer/crop-box-renderer';
import { FreeDrawRenderer } from './free-draw-renderer/free-draw-renderer';
import { BlueBoxRenderer } from './blue-box-renderer/blue-box-renderer';

export interface DrawOptions {
  skipRecalculate?: boolean;
  skipScale?: boolean;
  maskedDimensions?: boolean;
  uncroppedDimensions?: boolean;
  outerEdgeDimensions?: boolean; // include stroke width
  forceOuterEdgeDimensions?: boolean;
  drawUncropped?: boolean;
  calcSize?: boolean;
  loadOriginal?: boolean;
  scaleBy?: number; // scale size, position, border width by this value for all elements
}

export interface DrawParams {
  x: number;
  y: number;
  width: number;
  height: number;
  imageOpacity?: number;
}

export class CanvasRenderer {
  public canvasElementsRenderer: CanvasElementsRenderer;
  public canvasCopyElementsRenderer: CanvasCopyElementsRenderer;
  public multipleElementsSelectionWidgetRenderer: MultipleElementsSelectionWidgetRenderer;
  public draggingBoxRenderer: DraggingBoxRenderer;
  public highlightBoxRenderer: HighlightBoxRenderer;
  public guidelinesRenderer: GuidelinesRenderer;
  public maskBoxRenderer: MaskBoxRenderer;
  public cropBoxRenderer: CropBoxRenderer;
  public freeDrawRenderer: FreeDrawRenderer;
  public blueBoxRenderer: BlueBoxRenderer;

  private isWaitingForDraw = false;

  private subject: Subject<string> = new Subject();

  constructor(private canvasDocument: CanvasDocument) {
    this.subject.pipe(debounceTime(200)).subscribe(() => this.draw());
    this.canvasElementsRenderer = new CanvasElementsRenderer(this.canvasDocument);
    this.canvasCopyElementsRenderer = new CanvasCopyElementsRenderer(this.canvasDocument);
    this.multipleElementsSelectionWidgetRenderer = new MultipleElementsSelectionWidgetRenderer(this.canvasDocument);
    this.draggingBoxRenderer = new DraggingBoxRenderer(this.canvasDocument);
    this.highlightBoxRenderer = new HighlightBoxRenderer(this.canvasDocument);
    this.guidelinesRenderer = new GuidelinesRenderer(this.canvasDocument);
    this.maskBoxRenderer = new MaskBoxRenderer(this.canvasDocument);
    this.cropBoxRenderer = new CropBoxRenderer(this.canvasDocument);
    this.freeDrawRenderer = new FreeDrawRenderer(this.canvasDocument);
    this.blueBoxRenderer = new BlueBoxRenderer(this.canvasDocument);

    // requestAnimationFrame(() => { this.animate(); });
  }

  private animate() {
    this.draw();
    requestAnimationFrame(() => {
      this.animate();
    });
  }

  private static counter = 0;
  public draw(options?: DrawOptions) {
    const start = new Date();

    const ctx = this.canvasDocument.canvasDisplay.context;
    const scale = this.canvasDocument.getDevicePixelRatio();
    const viewScale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();

    this.canvasDocument.canvasDisplay.clearDisplay();

    ctx.save();

    ctx.scale(viewScale.x * scale, viewScale.y * scale);
    ctx.translate(-viewBox.x, -viewBox.y);

    this.canvasDocument.state?.background?.drawElement(ctx);

    const visibleElements = this.canvasElementsRenderer.draw(options);
    if (this.canvasDocument.mode === 'EDIT') {
      this.canvasCopyElementsRenderer.draw();
      this.multipleElementsSelectionWidgetRenderer.draw();
      this.draggingBoxRenderer.draw();
      this.highlightBoxRenderer.draw();
      this.guidelinesRenderer.draw();
      this.freeDrawRenderer.draw();
      this.blueBoxRenderer.draw();
    }

    ctx.restore();
    const end = new Date();
    const time = end.getTime() - start.getTime();
    const metrics = {
      start,
      time,
      visibleElementCount: visibleElements.length,
    };
    if (this.canvasDocument?.documentService?.documentRenderingMetricService) {
      this.canvasDocument.documentService.documentRenderingMetricService.recordMetrics(metrics);
    }
  }

  public debounceDraw() {
    this.subject.next('');
  }

  public queueDraw() {
    if (!this.isWaitingForDraw) {
      this.isWaitingForDraw = true;
      CanvasUtil.requestAnimFrame(() => {
        this.draw();
        this.isWaitingForDraw = false;
      });
    }
    return this;
  }
}
