import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { AuthContext, AuthService } from 'src/app/common/auth/auth.service';
import { RootStoreState } from 'src/app/root-store';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { UserProfileComponent } from '@common/user-profile/user-profile.component';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.scss'],
})
export class HeaderUserMenuComponent implements OnInit {
  authContext$: Observable<AuthContext>;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.authContext$ = this.store.select(AuthSelectors.selectAuthContext);
  }

  logout() {
    this.authService.logout();
  }

  async profileSettings() {
    const authContext = await firstValueFrom(this.authService.authContext);
    const subscriberRef = `user:${authContext.user.id}`;
    const subscriptions = await new Entities().get({ entityName: 'subscription', criteria: { subscriberRef } });
    const config: MatDialogConfig = {
      width: '600px',
      height: '680px',
      maxWidth: '90vw',
      maxHeight: '85vh',
      panelClass: 'profile-setting-dialog',
      disableClose: true,
      autoFocus: false,
      data: { authContext, subscriptions },
    };
    this.dialog.open(UserProfileComponent, config);
  }
}
