import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from '../../item';

@Component({
  selector: 'app-item-family',
  templateUrl: './item-family.component.html',
  styleUrls: ['./item-family.component.scss'],
})
export class ItemFamilyComponent implements OnInit {
  @Input() selectedItem: Item;
  @Input() itemFamily: Item;
  @Input() itemOptions: any;
  @Output() itemClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  selectItem(item) {
    this.itemClicked.emit(item);
  }
}
