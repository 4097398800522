import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from '../../document.service';
import { CanvasElement } from '../../../canvas/elements/canvas-element';
import { CANVAS_COMPONENT_PADDING_X, CANVAS_COMPONENT_PADDING_T } from '../../../canvas/constants';
import { DocumentItemService } from '../../document-item/document-item.service';

@Injectable({
  providedIn: 'root',
})
export class DistributeDocumentElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType.startsWith('distribute')) {
        this.distributeAllSelected(request?.sourceEvent.selectedElements, request.actionType);
      }
    });
  }

  public distributeAllSelected(selectedElements: Array<DocumentElement>, alignType: string) {
    switch (alignType) {
      case 'distribute.horizontal': {
        this.distributeSelectedElements(selectedElements, 'x');
        break;
      }
      case 'distribute.vertical': {
        this.distributeSelectedElements(selectedElements, 'y');
        break;
      }
    }
  }

  distributeSelectedElements(documentElements: DocumentElement[], direction: string) {
    const selectedFrames = documentElements.filter((element) => element.type === 'frame') || [];
    const frameElements: Map<string, string> = this.documentService.getFrameElements();
    const distributeElementsInFrame =
      selectedFrames.length === 1 &&
      documentElements.findIndex((element) =>
        element.type === 'frame' || frameElements.get(element.id) ? false : true,
      ) === -1;
    const adjustElementsInFrame = selectedFrames.length > 0 && !distributeElementsInFrame;

    const elements = [];
    // clone elements for manipulation
    documentElements.forEach((element) => {
      const frameId = frameElements.get(element.id);
      if (
        (!distributeElementsInFrame && (!frameId || selectedFrames.findIndex((e) => e.id === frameId) === -1)) ||
        (distributeElementsInFrame && element.type !== 'frame')
      ) {
        const clonedElement: DocumentElement = ObjectUtil.cloneDeep(element);
        this.setElementSizeAndPosition(clonedElement);
        elements.push(clonedElement);
      }
    });
    const sortedElements = this.sortElements(elements, direction); // sort element by the position-x or position-y

    // minPositionAdjustment is used to calculate the left edge of a left-most or top edge of the bottom-most element
    const firstElement = sortedElements[0];
    const lastElement = sortedElements[sortedElements.length - 1];
    const minPositionAdjustment = direction === 'x' ? firstElement.size.width : firstElement.size.height;
    const maxPosition = lastElement.position[direction];
    const minPosition = firstElement.position[direction] + minPositionAdjustment;

    // total space in between the top-most and bottom most elements or between left-most and right-most elements
    let totalDistance = 0;
    for (let i = 1; i < sortedElements.length - 1; i++) {
      totalDistance += direction === 'x' ? sortedElements[i].size.width : sortedElements[i].size.height;
    }
    // get the spaces in between elements
    const spaceBetweenElements = Math.round((maxPosition - minPosition - totalDistance) / (sortedElements.length - 1));
    const changedElements = this.applyDistributedPositions(
      direction,
      sortedElements,
      spaceBetweenElements,
      adjustElementsInFrame,
    );
    this.documentService.handleDocumentActions(changedElements);
  }

  private applyDistributedPositions(
    direction: string,
    elements: DocumentElement[],
    spaceBetweenElements: number,
    adjustElementsInFrame: boolean,
  ): DocumentAction[] {
    let changedElements: DocumentAction[] = [];
    const frames = this.documentService.getFrames();
    for (let i = 1; i < elements.length - 1; i++) {
      const currentElement = ObjectUtil.cloneDeep(elements[i]);
      const precedingElement = elements[i - 1];
      const positionAdjustment = direction === 'x' ? precedingElement.size.width : precedingElement.size.height;
      const newCoordinates = { x: currentElement.position.x, y: currentElement.position.y };
      // position of an element is calculated based on the position of the preceding element
      const newPosition = precedingElement.position[direction] + spaceBetweenElements + positionAdjustment;

      // used to adjust line element's coordinates
      const lineAdjustment = { x: 0, y: 0 };
      lineAdjustment[direction] = newPosition - currentElement.position[direction];
      elements[i].position[direction] = newPosition;
      newCoordinates[direction] = newPosition;
      let newElement: any = { id: currentElement.id, position: newCoordinates };
      if (currentElement.type === 'line') {
        // line has to be processed differently because only the coordinates are stored.
        newElement = {
          id: currentElement.id,
          lineDefinition: {
            x1: currentElement.lineDefinition.x1 + lineAdjustment[direction],
            y1: currentElement.lineDefinition.y1 + lineAdjustment[direction],
            x2: currentElement.lineDefinition.x2 + lineAdjustment[direction],
            y2: currentElement.lineDefinition.y2 + lineAdjustment[direction],
          },
        };
      }
      if (DocumentItemService.isItemComponet(currentElement)) {
        newElement.position.x = newElement.position.x + CANVAS_COMPONENT_PADDING_X;
        newElement.position.y = newElement.position.y + CANVAS_COMPONENT_PADDING_T;
      }

      if (currentElement.type === 'group') {
        const innerElements = this.documentService.getAllElementsInGroup(currentElement.id);
        changedElements = changedElements.concat(this.adjustInnerElements(innerElements, direction, lineAdjustment));
      } else {
        if (this.documentService?.documentRenderer?.isMask(currentElement)) {
          const maskMembers: CanvasElement[] = this.documentService?.documentRenderer?.getMaskMembers(
            currentElement.id,
          );
          changedElements = changedElements.concat(
            this.adjustInnerElements(
              maskMembers?.map((e) => e.elementDefinition),
              direction,
              lineAdjustment,
            ),
          );
        }
        changedElements.push(
          new DocumentAction(
            {
              elementId: newElement.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: newElement,
            },
            {
              elementId: newElement.id,
              changeType: DocumentChangeType.MODIFY_ELEMENT,
              elementData: currentElement,
            },
          ),
        );
      }

      if (currentElement.type === 'frame' && adjustElementsInFrame) {
        const deltaX = newElement.position.x - currentElement.position.x;
        const deltaY = newElement.position.y - currentElement.position.y;
        frames.get(currentElement.id)?.elements?.forEach(({ canvasElement }) => {
          const newElementInFrame: any = { id: canvasElement.id };
          if (canvasElement.elementDefinition.type === 'line') {
            newElementInFrame.lineDefinition.x1 = canvasElement.elementDefinition.lineDefinition.x1 + deltaX;
            newElementInFrame.lineDefinition.y1 = canvasElement.elementDefinition.lineDefinition.y1 + deltaY;
            newElementInFrame.lineDefinition.x2 = canvasElement.elementDefinition.lineDefinition.x2 + deltaX;
            newElementInFrame.lineDefinition.y2 = canvasElement.elementDefinition.lineDefinition.y2 + deltaY;
          } else {
            newElementInFrame.position = {
              x: canvasElement.elementDefinition.position.x + deltaX,
              y: canvasElement.elementDefinition.position.y + deltaY,
            };
          }
          changedElements.push(
            new DocumentAction(
              {
                elementId: newElementInFrame.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: newElementInFrame,
              },
              {
                elementId: newElementInFrame.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: canvasElement.elementDefinition,
              },
            ),
          );
        });
      }
    }
    return changedElements;
  }

  private adjustInnerElements(elements, direction, adjustment) {
    const actions = [];
    elements?.forEach((innerElement) => {
      const newElement = {
        id: innerElement.id,
        position: {
          x: innerElement.position.x,
          y: innerElement.position.y,
        },
      };
      newElement.position[direction] = newElement.position[direction] + adjustment[direction];
      actions.push(
        new DocumentAction(
          {
            elementId: newElement.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: newElement,
          },
          {
            elementId: newElement.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: innerElement,
          },
        ),
      );
    });
    return actions;
  }

  private sortElements(elements: DocumentElement[], direction: string) {
    return elements.sort((a, b) => a.position[direction] - b.position[direction]);
  }

  // This method is used to populate the size+position of a line element and size of a component element because they are not stored.
  private setElementSizeAndPosition(element: DocumentElement) {
    const canvasElement: CanvasElement = this.documentService.documentRenderer.getCanvasElementById(element.id);
    const { x, y, width, height } = canvasElement.getBoundingClientRect();
    element.size = {
      width,
      height,
    };
    element.position = {
      x,
      y,
    };
  }
}
