<button
  *ngIf="isUngroupingAllowed || isGroupingAllowed"
  mat-icon-button
  class="toggle-icon"
  (click)="toggleGroup()"
  [matTooltip]="isGrouped ? 'Ungroup' : 'Group'"
  [attr.data-test]="isGrouped ? 'ungroup-elements-button' : 'group-elements-button'"
>
  <mat-icon *ngIf="isUngroupingAllowed" svgIcon="ungroup-objects"></mat-icon>
  <mat-icon *ngIf="isGroupingAllowed" svgIcon="group-objects"></mat-icon>
</button>
