<div
  *ngIf="visible"
  [style.top.px]="top"
  [style.left.px]="left"
  [style.width.px]="width"
  [style.height.px]="height"
  #annotationDiv
  #tooltip="matTooltip"
  [matTooltip]="annotationMessage"
  class="annotation"
></div>
