import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SizeDefinition } from '@contrail/documents';

@Component({
  selector: 'app-property-configurator-sticky-note-size',
  templateUrl: './property-configurator-sticky-note-size.component.html',
  styleUrls: ['./property-configurator-sticky-note-size.component.scss'],
})
export class PropertyConfiguratorStickyNoteSizeComponent implements OnChanges {
  @Input() selectedElement: any;
  @Input() currentValue: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  public sizeOptions = [
    { value: 'square', label: 'Square', icon: 'crop_square' },
    { value: 'rectangle', label: 'Rectangle', icon: 'crop_landscape' },
  ];
  public sizeOption: string;
  private size: SizeDefinition;
  private defaultValue: string = 'square';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentValue?.width && this.currentValue?.height) {
      const aspectRatio = this.currentValue.width / this.currentValue.height;
      this.sizeOption = aspectRatio === 1 ? 'square' : 'rectangle';
    } else {
      this.sizeOption = this.defaultValue;
    }
  }

  public updateSize(event, sizeOption) {
    this.sizeOption = sizeOption;
    this.size = this.sizeOption === 'square' ? { width: 200, height: 200 } : { width: 300, height: 200 };
    const changes = { size: this.size };
    this.valueChange.emit(changes);
  }
}
