import { Component, Input } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { DocumentService } from '../../document.service';

@Component({
  selector: 'app-property-configurator-edit-name',
  template: `<div class="flex flex-row items-center content-center">
    <button mat-icon-button (click)="editName()"><mat-icon>edit</mat-icon></button>
  </div>`,
})
export class PropertyConfiguratorEditNameComponent {
  @Input() selectedElement: any;

  constructor(public documentService: DocumentService) {}

  editName() {
    this.documentService.handleActionRequest(
      new ActionRequest('edit_name', {
        element: this.selectedElement,
      }),
    );
  }
}
