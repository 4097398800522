import { AssortmentUtil } from '@common/assortments/assortment-util';
import { createSelector } from '@ngrx/store';
import { AssortmentItem } from './backing-assortment.state';

const backingAssortmentItemsMap = (state) => state.assortments.backingAssortmentItems.entities;
export const backingAssortmentItems = createSelector(backingAssortmentItemsMap, (state) => Object.values(state));

export const selectBackingAssortmentItem = (id: string) =>
  createSelector(backingAssortmentItemsMap, (map) => {
    return map[id];
  });

export const selectItemFromBackingAssortment = (itemId: string) =>
  createSelector(backingAssortmentItemsMap, (map) => {
    const assortmentItems: Array<AssortmentItem> = Object.values(map);
    const assortmentItem = assortmentItems.find((ai: AssortmentItem) => ai.itemId === itemId);
    if (assortmentItem) {
      return assortmentItem.item;
    }
  });

export const backingAssortmentItemData = createSelector(backingAssortmentItems, (assortmentItems) => {
  if (assortmentItems) {
    const items = AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);
    return items.sort((i1, i2) => (i1.item?.name > i2.item?.name ? 1 : -1));
  }

  return null;
});

export const backingAssortmentItemDataHierarchy = createSelector(backingAssortmentItems, (assortmentItems) => {
  if (assortmentItems) {
    return AssortmentUtil.convertAssortmentItemsFamilyItemData(assortmentItems);
  }
  return null;
});

export const selectBackingAssortmentItemByFamily = (itemId: string) =>
  createSelector(backingAssortmentItemDataHierarchy, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.find((itemData) => itemData.item.itemFamilyId === itemId);
    }
    return null;
  });
