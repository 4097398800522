import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { ScaleTransformation, SizeDefinition, StyleDefinition } from '@contrail/documents';
import { TypeProperty } from '@contrail/types';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PropertyCreateViewDefinitionComponent } from './create-view-definition/create-view-definition.component';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { AuthService } from '@common/auth/auth.service';
import { ViewManagerService } from '@common/views/view-manager.service';

export interface ViewPropertyDefinition {
  enabled: boolean;
  slug?: string;
  style?: StyleDefinition;
  typeRootSlug?: string;
  propertyDefinition?: TypeProperty;
  includeLabel?: boolean;
  size?: SizeDefinition;
  scale?: ScaleTransformation;
  isHidden?: boolean;
}

@Component({
  selector: 'app-property-view-definition-builder',
  templateUrl: './property-view-definition-builder.component.html',
  styleUrls: ['./property-view-definition-builder.component.scss'],
})
export class PropertyViewDefinitionBuilderComponent implements OnInit, OnChanges {
  @Input() public viewDefinitionApplicationViewSlug: string;
  @Input() public availableViewProperties: Array<any>;

  public availableViewDefinitions: Array<ViewDefinition>;
  public properties: Array<ViewPropertyDefinition>;
  @Input() selectedProperties: Array<any>;
  @Input() componentStyle: any;
  @Input() viewId: string;
  @ViewChild('viewNameInput') viewNameInput: ElementRef;
  selectedView: ViewDefinition;
  editNameActive = false;
  isOrgAdmin = false;
  changesDetected = false;

  @Output() propertiesChangedEvent = new EventEmitter();
  form: UntypedFormGroup = new UntypedFormGroup({
    viewName: new UntypedFormControl('', [Validators.required]),
    selectedView: new UntypedFormControl('', []),
  });

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private confirmationBoxService: ConfirmationBoxService,
    private viewsService: ViewManagerService,
  ) {}

  ngOnInit() {
    this.isOrgAdmin = this.authService.isAdmin();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDefinitionApplicationViewSlug) {
      const views = await this.viewsService.getViewDefinitions({
        applicationViewSlug: this.viewDefinitionApplicationViewSlug, //'common:frame_panel'
        includePrivate: true,
      });

      this.availableViewDefinitions = views;
      this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
    }

    // Determine the selected preset
    if (!changes.viewId) {
      // only do it if view is not changed
      this.determineSelectedView();
    }
    if (this.compareViewWithLocalProperties(this.selectedView)) {
      this.changesDetected = true;
    } else {
      this.changesDetected = false;
    }
  }

  private determineSelectedView() {
    if (!this.availableViewDefinitions) {
      return;
    }
    for (let viewDef of this.availableViewDefinitions) {
      if (!this.compareViewWithLocalProperties(viewDef)) {
        this.selectedView = viewDef;
        this.form.get('selectedView').setValue(viewDef);
        break;
      } else {
        this.selectedView = null;
        this.form.get('selectedView').setValue('');
        continue;
      }
    }
  }

  selectView(change) {
    this.changesDetected = false;
    const viewDefinition = change.value;
    this.selectedView = viewDefinition;
    if (viewDefinition) {
      this.properties = viewDefinition.properties;
    } else {
      this.properties = [];
    }
    this.propertiesChangedEvent.emit(viewDefinition);
  }

  editName() {
    this.editNameActive = true;
    this.form.get('viewName').setValue(this.selectedView.label);
    setTimeout(() => {
      this.viewNameInput.nativeElement.focus();
    }, 100);
  }

  async endNameEdit() {
    this.editNameActive = false;
    const updatedViewName = this.form.controls.viewName.value;
    const index = this.availableViewDefinitions.findIndex(
      (viewDefinition) => viewDefinition.id === this.selectedView.id,
    );
    this.availableViewDefinitions[index].label = updatedViewName;
    this.updateView(updatedViewName);
  }

  async createView(newViewData: any) {
    this.changesDetected = false;
    this.formatProperties();
    const viewDefinition: ViewDefinition = {
      label: newViewData.label,
      properties: this.properties,
      applicationViewSlug: this.viewDefinitionApplicationViewSlug,
      viewType: 'properties_list',
    };
    if (this.componentStyle) {
      viewDefinition.style = this.componentStyle;
    }
    if (newViewData.admin) {
      viewDefinition.admin = true;
    } else {
      viewDefinition.private = true;
    }

    const newView = await new Entities().create({ entityName: 'view-definition', object: viewDefinition });
    this.selectedView = newView;
    this.properties = ObjectUtil.cloneDeep(this.properties);
    this.selectedView.properties = this.properties;
    this.availableViewDefinitions.push(this.selectedView);
    this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
    this.form.get('selectedView').setValue(this.selectedView);
    this.snackBar.open('View created', '', { duration: 2000 });
  }

  async updateView(label = null) {
    this.changesDetected = false;
    if (!this.selectedView) {
      this.openCreateViewModal();
    } else {
      this.formatProperties();

      const changes: any = {
        properties: this.properties,
      };

      if (label) {
        changes.label = label;
      }
      if (this.componentStyle) {
        changes.style = this.componentStyle;
      }
      const updatedView = await new Entities().update({
        entityName: 'view-definition',
        id: this.selectedView.id,
        object: changes,
      });

      this.selectedView = updatedView;
      this.selectedView.properties = this.properties;
      this.availableViewDefinitions = this.availableViewDefinitions.filter((t) => t.id !== this.selectedView.id);
      this.availableViewDefinitions.push(this.selectedView);
      this.availableViewDefinitions = this.availableViewDefinitions.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
      this.form.get('selectedView').setValue(this.selectedView);
      this.snackBar.open('Preset updated', '', { duration: 2000 });
    }
  }

  async deleteView() {
    this.changesDetected = false;
    if (!this.selectedView) {
      return;
    }
    const confirm = await this.confirmationBoxService.open(`Confirm`, `Are you sure you want to delete this preset?`);
    if (confirm) {
      const deleted = await new Entities().delete({ entityName: 'view-definition', id: this.selectedView.id });
      this.selectedView = null;
      this.properties = [];
      this.availableViewDefinitions = this.availableViewDefinitions.filter((t) => t.id !== deleted.id);
      this.form.get('selectedView').setValue(null);
      const message = 'Preset deleted.';
      this.snackBar.open(message, '', { duration: 2000 });
    }
  }

  async resetView() {
    this.changesDetected = false;
    this.propertiesChangedEvent.emit(this.selectedView);
  }

  openCreateViewModal(name?: string): void {
    const dialogRef = this.dialog.open(PropertyCreateViewDefinitionComponent, {
      width: '350px',
      disableClose: false,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: name,
    });

    dialogRef.afterClosed().subscribe((newView) => {
      if (newView) {
        this.createView(newView);
      }
    });
  }

  handlePropertyViewChange(properties) {
    if (properties?.length) {
      this.properties = properties;
    } else {
      this.properties = [];
    }
    this.propertiesChangedEvent.emit(this.properties);
  }

  private formatProperties() {
    this.properties = [];
    let componentType;
    if (this.viewDefinitionApplicationViewSlug === 'common:item_component') {
      componentType = 'item';
    } else if (this.viewDefinitionApplicationViewSlug === 'common:color_component') {
      componentType = 'color';
    } else {
      console.error('Unknown viewDefinitionApplicationViewSlug');
      return;
    }

    this.selectedProperties?.forEach((prop) => {
      let slug;
      if (prop.type === 'annotation') {
        slug = 'annotation';
      } else if (prop.type === 'thumbnail') {
        slug = 'thumbnail';
      } else if (prop.type === 'rectangle') {
        if (prop?.position?.x === 0 && prop?.position?.y === 0) {
          slug = 'container';
        } else if (prop?.position?.x === 5 && prop?.position?.y === 5) {
          // Why 5 ? ColorComponentBuilder.padding = 5.
          slug = 'colorRect';
        }
      } else {
        slug = prop.propertyDefinition?.slug || 'thumbnail';
      }
      const formattedProp: ViewPropertyDefinition = {
        enabled: prop.enabled,
        includeLabel: prop.hasOwnProperty('label'),
        slug: slug,
        style: prop.style,
        size: prop.size,
        typeRootSlug: prop.propertyBindings?.text?.split('.')[0] || componentType,
      };
      if (prop.propertyDefinition) {
        // not (item thumbnail | annotation) || (color container | colorRect)
        formattedProp.propertyDefinition = ObjectUtil.cloneDeep(prop.propertyDefinition);
      } else if (prop.isHidden) {
        formattedProp.isHidden = prop.isHidden;
      }
      if (prop.scale) {
        formattedProp.scale = prop.scale;
      }
      this.properties.push(formattedProp);
    });
  }

  private compareViewWithLocalProperties(view: ViewDefinition): boolean {
    const viewProperties = view?.properties || [];
    const viewStyle = view?.style || {};
    this.formatProperties();

    let propertiesChanged = viewProperties?.length !== this.properties?.length;
    if (
      this.componentStyle?.border?.color !== viewStyle.border?.color ||
      this.componentStyle?.border?.width !== viewStyle.border?.width
    ) {
      propertiesChanged = true;
    }
    if (propertiesChanged) {
      return propertiesChanged;
    }

    for (let i = 0; i < this.properties.length; i++) {
      const property = this.properties[i];
      const viewProperty = viewProperties[i];

      let checkStyle = true;
      if (this.viewDefinitionApplicationViewSlug === 'common:color_component' && property?.slug === 'colorRect') {
        checkStyle = false;
      }
      if (
        property.slug !== viewProperty.slug ||
        (checkStyle && ObjectUtil.compareDeep(property.style, viewProperty.style, '').length > 0) ||
        property.includeLabel !== viewProperty.includeLabel ||
        property.isHidden !== viewProperty.isHidden ||
        property.size?.height !== viewProperty.size?.height ||
        property.size?.width !== viewProperty.size?.width ||
        (property?.scale?.x || 1) !== (viewProperty?.scale?.x || 1)
      ) {
        propertiesChanged = true;
        break;
      }
    }
    return propertiesChanged;
  }
}
