export interface TextLine {
  text: string;
  x: number;
  y: number;
}

export class TextMetrics {
  public text: string;
  public width: number;
  public height: number;
  public lineHeight: number;
  public lines: Array<string>;

  private static readonly BASELINE_SYMBOL = 'M';
  private static readonly BASELINE_MULTIPLIER = 1.2;

  constructor(text: string, width: number, height: number, lineHeight: number, lines: Array<string>) {
    this.text = text;
    this.width = width;
    this.height = height;
    this.lineHeight = lineHeight;
    this.lines = lines;
  }

  public static getWidth(context, str, fontString): number {
    if (document || context) {
      let ctx = context;
      if (!ctx) {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        ctx = canvas.getContext('2d');
      }
      ctx.font = fontString;
      return Math.ceil(ctx.measureText(str).width);
    }
  }

  /**
   * Truncate @text to fit in @width
   * @param ctx
   * @param text
   * @param width
   * @returns
   */
  public static truncate(
    ctx,
    text: string,
    width: number,
    fontString: string,
  ): { initialText: string; text: string; width: number; textWidth: number; textHeight: number } {
    if (!text || text?.length === 0) {
      return { text, initialText: text, width, textWidth: 0, textHeight: 0 };
    }
    const ellipsis = '...';
    const ellipsisLength = TextMetrics.getWidth(ctx, ellipsis, fontString);
    const textWidth = TextMetrics.getWidth(ctx, text, fontString);
    const baseline = TextMetrics.getWidth(ctx, TextMetrics.BASELINE_SYMBOL, fontString);
    const lineHeight = baseline * TextMetrics.BASELINE_MULTIPLIER;
    let result = text;
    if (textWidth > width) {
      const charLength = Math.floor(textWidth / text.length);
      const truncateWidth = Math.max(1, Math.floor((width - ellipsisLength) / charLength));
      result = text.slice(0, truncateWidth) + ellipsis;
    }
    return {
      initialText: text,
      text: result,
      textWidth,
      textHeight: lineHeight,
      width,
    };
  }

  /**
   * Important to call it after font is set on context.
   * @param ctx
   * @param text
   * @param x
   * @param y
   * @param maxWidth
   * @returns
   */
  public static measureText(
    ctx: CanvasRenderingContext2D,
    text: string,
    lines: Array<string>,
    maxWidth: number,
  ): TextMetrics {
    const baseline = Math.ceil(ctx.measureText(TextMetrics.BASELINE_SYMBOL).width);
    const lineHeight = baseline * TextMetrics.BASELINE_MULTIPLIER;
    const height = lines.length * lineHeight;
    return new TextMetrics(text, maxWidth, height, lineHeight, lines);
  }

  public static breakString(ctx, word, maxWidth, fontString) {
    const characters = word.split('');
    const lines = [];
    let currentLine = '';
    characters.forEach((character, index) => {
      const nextLine = `${currentLine}${character}`;
      const lineWidth = Math.ceil(this.getWidth(ctx, nextLine, fontString));
      if (lineWidth > maxWidth) {
        lines.push(currentLine);
        currentLine = character;
      } else {
        currentLine = nextLine;
      }
    });
    return { strings: lines, remainingWord: currentLine };
  }

  public static wrapLabel(ctx: CanvasRenderingContext2D, label: string, maxWidth: number, fontString: string) {
    const wordsWithDelimiters = label.split(/([\s-])/); // break by space and hyphen and leep delimeter
    const words = []; // [['hello', ' '], ['world', '-'], ['my', ' '], ['name', '-']] 'hello world-my name-'
    wordsWithDelimiters.forEach((s, i) => {
      if (i % 2 === 0) {
        words.push([s, wordsWithDelimiters[i + 1] || '']);
      }
    });
    const completedLines = [];
    let nextLine = '';
    //console.log(label, words);
    words.forEach(([word, d], index) => {
      const wordLength = Math.ceil(this.getWidth(ctx, `${word}${d}`, fontString));
      const nextLineLength = Math.ceil(this.getWidth(ctx, nextLine, fontString));
      if (wordLength > maxWidth) {
        // string does not fit
        const { strings, remainingWord } = this.breakString(ctx, `${word}${d}`, maxWidth, fontString); // break very long strings into lines
        completedLines.push(nextLine, ...strings);
        nextLine = remainingWord;
      } else if (nextLineLength + wordLength >= maxWidth) {
        // current line and string do not fit
        completedLines.push(nextLine);
        nextLine = `${word}${d}`;
      } else {
        // string fits on current line
        nextLine = [nextLine, `${word}${d}`].filter(Boolean).join('');
      }

      const currentWord = index + 1;
      const isLastWord = currentWord === words.length;
      if (isLastWord) {
        completedLines.push(nextLine);
      }
    });
    const result = completedLines.filter((line) => line !== '');
    //console.log(result);
    return result;
  }

  public static wordWrap(ctx: CanvasRenderingContext2D, text: string, maxWidth: number): Array<string> {
    const words = text.split(' ');
    let line = '';
    let lineCount = 0;
    const lines: Array<string> = [];

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
        lineCount++;
      } else {
        line = testLine;
      }
    }

    lines.push(line);
    lineCount++;

    return lines;
  }
}
