<div class="options-panel">
  <mat-form-field>
    <mat-label>Property</mat-label>
    <mat-select (selectionChange)="changeProperty($event)" [value]="chartProperty">
      <mat-option></mat-option>
      <mat-option *ngFor="let prop of properties" [value]="prop">
        {{ prop.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Aggregate</mat-label>
    <mat-select (selectionChange)="changeAggregate($event)" [value]="chartAggregate">
      <mat-option value="total">Total</mat-option>
      <mat-option value="average">Average</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<app-assortment-aggregate-history-chart
  style="margin-top: 80px"
  [history]="history"
  [aggregateValue]="chartAggregate"
  [typeProperty]="chartProperty"
>
</app-assortment-aggregate-history-chart>
