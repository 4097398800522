import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { Mask } from '../../../state/canvas-mask-state';
import { SVGHelper } from '../../../svg-helper';

export class ElementSelectHandler {
  constructor(private canvasDocument: CanvasDocument) {}

  public handleClick(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    console.log('click.select', event, elementTarget);
    if (
      !this.canvasDocument.interactionHandler.isSelect() &&
      !this.canvasDocument.interactionHandler.isAssignItemSelect() &&
      !this.canvasDocument.interactionHandler.isAnnotate() &&
      !this.canvasDocument.interactionHandler.isPaintFormat()
    ) {
      return;
    }

    if (
      !elementTarget?.target &&
      event.buttons === 1 &&
      !event.shiftKey &&
      !(this.canvasDocument.svgEditorHandler.isSvgEditorOpen() && !elementTarget?.target && event.buttons === 2)
    ) {
      // Don't clear selection if svg editor is open and user is trying to pan
      this.canvasDocument?.interactionHandler?.selectionHandler.deselectAll();
      return;
    }

    if (elementTarget?.element) {
      const group = this.canvasDocument.state.groupState.getGroupByMemberElementId(elementTarget?.element.id);
      const maskedBy: Mask = this.canvasDocument.state.maskState.getMaskByMemberId(elementTarget.element.id);
      const mask: Mask = this.canvasDocument.state.maskState.getMask(elementTarget.element.id);
      const isEditingMask =
        (maskedBy || mask) && this.canvasDocument?.state?.maskState?.isEditingMask(maskedBy?.id || mask?.id);
      const isEditingCrop = this.canvasDocument.isCropping(elementTarget?.element?.id);
      if (!elementTarget?.element.isSelected) {
        // Select if not masked by a clip or if the mask is currently being edited
        if (!maskedBy || isEditingMask) {
          if (group && !isEditingMask) {
            if (!group.element.isSelected) {
              this.canvasDocument?.interactionHandler?.selectionHandler.selectElementAndDraw(
                group.element,
                event.shiftKey,
              );
            } else if (group.element.isSelected) {
              if (event.shiftKey) {
                this.deselect(group?.element);
              }
            }
          } else {
            this.canvasDocument?.interactionHandler?.selectionHandler.selectElementAndDraw(
              elementTarget?.element,
              event.shiftKey,
            );
          }
        }
      } else if (elementTarget?.element.isSelected && elementTarget?.element.isInFrame) {
        if (!event.shiftKey) {
          this.canvasDocument.interactionHandler.selectionHandler.setDeselected(elementTarget.element);
          this.canvasDocument?.interactionHandler?.selectionHandler.selectElementAndDraw(
            elementTarget?.element,
            event.shiftKey,
          );
        }
      } else {
        if (event.shiftKey) {
          if (elementTarget?.element?.elementDefinition?.type === 'frame') {
            this.canvasDocument?.interactionHandler?.selectionHandler?.frameSelectionHandler?.deselectFrameMembers(
              elementTarget.element,
            );
          }
          if (!(isEditingCrop && elementTarget?.target?.indexOf('crop_') !== -1)) {
            this.deselect(elementTarget?.element);
          }
        }
      }
    }
  }

  /**
   * Deselect, draw and trigger property config bar to get updated
   * @param element
   */
  private deselect(element: CanvasElement) {
    this.canvasDocument?.interactionHandler?.selectionHandler.deselectAndDraw(element);
    const selectedElements = this.canvasDocument.getSelectedElements();
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: selectedElements?.length > 0 ? selectedElements[0] : null,
      selectedElements,
      eventType: 'selected',
    });
  }
}
