<div class="sort-definition">
  <div data-test="remove-sort-option" (click)="remove()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="add-sort">
    <mat-form-field floatLabel="never">
      <input
        data-test="sort-property-search-input"
        #autoCompleteField
        type="text"
        placeholder="Select property"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="auto"
      />
      <button
        data-test="clear-sort-property-search-input-button"
        *ngIf="formControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearAutoCompleteValue()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="setSortProperty($event, sort)"
      >
        <mat-option
          *ngFor="let property of filteredProperties | async"
          [value]="property"
          [attr.data-test]="'sort-condition-select-' + property?.propertySlug"
        >
          {{ property.propertyLabel }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="sort-direction" *ngIf="sort.propertySlug !== ''">
    <mat-form-field>
      <mat-select data-test="asc-desc-option" [(ngModel)]="sort.direction" (selectionChange)="apply()">
        <mat-option value="ASC" data-test="sort-asc">Ascending</mat-option>
        <mat-option value="DESC" data-test="sort-desc">Descending</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="drag-icon" *ngIf="sort.propertySlug !== ''">
    <mat-icon cdkDragHandle>drag_handle</mat-icon>
  </div>
</div>
