import { documentElementsInitialState, DocumentElementsState } from './document-elements/document-elements.state';
import { DocumentElementSortOrder } from '../document-manager.service';

export interface State {
  documentId: string;
  documentElements: DocumentElementsState;
  documentElementSortOrder: DocumentElementSortOrder;
  documentElementsLoaded: boolean;
}
export const initialState: State = {
  documentId: null,
  documentElements: documentElementsInitialState,
  documentElementSortOrder: null,
  documentElementsLoaded: false,
};
