import { Component, Input, OnInit } from '@angular/core';
import { CommentsActions } from '@common/comments/comments-store';
import { Comment } from '@common/comments/comments.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

export const COMMENT_COLOR_CHIP_OPTIONS = [
  { value: '#ffffff', display: 'White' },
  { value: '#cc0000', display: 'Red' },
  { value: '#e69138', display: 'Orange' },
  { value: '#f1c232', display: 'Yellow' },
  { value: '#6aa84f', display: 'Green' },
  { value: '#3c78d8', display: 'Blue' },
  { value: '#674ea7', display: 'Purple' },
  { value: '#a64d79', display: 'Magenta' },
];

@Component({
  selector: 'app-comment-color-chip',
  templateUrl: './comment-color-chip.component.html',
  styleUrls: ['./comment-color-chip.component.scss'],
})
export class CommentColorChip implements OnInit {
  @Input() comment: Comment;

  public colorOptions = COMMENT_COLOR_CHIP_OPTIONS.map((c) => c.value);
  public currentHex;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.currentHex = this.comment?.colorHexCode || '#ffffff';
  }

  updateComment(event, hex: string): void {
    this.currentHex = hex;
    let updateComment = Object.assign({}, this.comment);
    updateComment.colorHexCode = hex;
    this.store.dispatch(CommentsActions.updateComment({ comment: updateComment }));
  }
}
