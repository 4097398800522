import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-content-modal-header',
  template: `
    <div class="flex w-full h-full px-3 !text-black60">
      <div class="flex items-center grow">
        <mat-icon class="mr-3 !w-[18px] !h-[18px]" svgIcon="app-content-icon"></mat-icon>
        <h6>{{ fileName }}</h6>
      </div>
      <div class="flex items-center grow">
        <div
          *ngFor="let option of navOptions"
          [ngClass]="{ selected: this.selectedNav === option }"
          class="nav-option"
          (click)="setNavOption(option)"
        >
          <mat-icon [svgIcon]="option.svgIcon" *ngIf="option.label === 'COMMENTS'" class="opacity-60"></mat-icon>
          <mat-icon *ngIf="option.label !== 'COMMENTS'">{{ option.icon }}</mat-icon>
          <span>{{ option.label }}</span>
        </div>
      </div>
      <mat-icon class="!flex justify-end cursor-pointer mr-3 my-auto grow" (click)="cancel()">close</mat-icon>
    </div>
  `,
  styles: [
    `
      :host {
        @apply h-[60px] w-full border-b flex;
      }

      .nav-option {
        @apply h-full flex flex-col items-center justify-center px-5 cursor-pointer;
      }
      .nav-option.selected {
        font-weight: bold;
        border-bottom: solid 2px #00a0ff;
      }
    `,
  ],
})
export class ContentModalHeaderComponent implements OnInit {
  @Input() fileName;
  @Input() contentType: 'is3d' | 'isImage' | 'isFile';
  @Output() cancelView = new EventEmitter();
  @Output() navigate = new EventEmitter();

  selectedNav;
  navOptions = [];
  constructor() {}

  ngOnInit(): void {
    if (this.contentType === 'is3d') {
      this.navOptions = [{ label: 'EDITOR', icon: '3d_rotation' }];
    } else {
      this.navOptions = [{ label: 'DETAILS', icon: 'info_outline' }];
    }
    // this.navOptions.push({ label: 'COMMENTS', svgIcon: 'contrail_comment' })
    // this.navOptions.push({ label: 'VERSIONS', svgIcon: 'history' })
    this.selectedNav = this.navOptions[0];
  }

  setNavOption(option) {
    this.selectedNav = option;
    this.navigate.emit(option.label);
  }

  cancel() {
    this.cancelView.emit();
  }
}
