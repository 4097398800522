import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Board } from '../boards-store/boards.state';

@Component({
  selector: 'app-board-card',
  template: `<app-list-card
    *ngIf="board"
    [title]="board.name"
    [imageUrl]="board.previewFile?.downloadUrl"
    [updatedOn]="board.updatedOn"
    (openMenu)="showMenu($event)"
    iconUrl="assets/images/board_icon.svg"
  >
  </app-list-card>`,
  styles: [],
})
export class BoardCardComponent implements OnInit {
  @Input() board: Board;
  @Output() openMenu = new EventEmitter();
  constructor() {}
  ngOnInit(): void {}
  public showMenu($event) {
    this.openMenu.emit($event);
  }
}
