import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assortment-pivot-dashboard-widget',
  templateUrl: './assortment-pivot-dashboard-widget.component.html',
  styleUrls: ['./assortment-pivot-dashboard-widget.component.scss'],
})
export class AssortmentPivotDashboardWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
