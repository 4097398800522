import { createAction, props } from '@ngrx/store';
import { Clipboard } from '../clipboard.interfaces';

export enum ClipboardActionTypes {
  LOAD_CLIPBOARD = '[Clipboard] Load Clipboard',
  LOAD_CLIPBOARD_SUCCESS = '[Clipboard] Load Clipboard Success',
  LOAD_CLIPBOARD_FAILURE = '[Clipboard] Load Clipboard Failure',
}

export const loadClipboard = createAction(ClipboardActionTypes.LOAD_CLIPBOARD);

export const loadClipboardSuccess = createAction(
  ClipboardActionTypes.LOAD_CLIPBOARD_SUCCESS,
  props<{ clipboard: Clipboard }>(),
);

export const loadClipboardFailure = createAction(ClipboardActionTypes.LOAD_CLIPBOARD_FAILURE, props<{ error: any }>());
