import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { RotationHelper } from '../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class ElementRotateHandler {
  private startingPosition: PositionDefinition;
  private selectedElement: CanvasElement;
  private startingElement: DocumentElement;
  å;
  private rotationHandleStartPos = { x: 0, y: 0, angle: 0, initialAngle: 0 };
  private elementRotationCoords = { cx: 0, cy: 0, angle: 0 };

  private readonly RIGHT_ANGLES = [
    0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330, 345, 360,
  ];
  private readonly RIGHT_ANGLE_MARGIN = 15;
  private tempAngle = null;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect() || elementTarget?.element?.elementDefinition?.isLocked)
      return;

    const { element, target } = elementTarget;
    if (element && target === DRAG_DIRECTIONS.ROTATE) {
      //console.log('elementRotate.dragstarted', event, elementTarget)

      const selectedElements =
        this.canvasDocument.interactionHandler.selectionHandler.getSelectedUnlockedCanvasElements();
      if (selectedElements?.length !== 1) return;

      this.startingPosition = { x: event.clientX, y: event.clientY };
      this.selectedElement = selectedElements[0];
      this.selectedElement.rotationWidgetRenderer.angleCoordinates = null;
      this.startingElement = ObjectUtil.cloneDeep(selectedElements[0].elementDefinition);

      const elementCenter = this.selectedElement.getCenter();
      this.elementRotationCoords = {
        angle: this.startingElement.rotate?.angle ?? 0,
        cx: elementCenter.x,
        cy: elementCenter.y,
      };

      const rotationHandleCenter = element.rotationWidgetRenderer.getCenter();
      this.rotationHandleStartPos = {
        angle: this.elementRotationCoords.angle ?? 0,
        x: rotationHandleCenter.x,
        y: rotationHandleCenter.y,
        initialAngle: 0,
      };

      if (this.rotationHandleStartPos.angle > 0) {
        const rotatedHandlePosition = RotationHelper.rotate(
          {
            x: this.rotationHandleStartPos.x,
            y: this.rotationHandleStartPos.y,
          },
          this.rotationHandleStartPos.angle,
          elementCenter,
        );
        this.rotationHandleStartPos.x = rotatedHandlePosition.x;
        this.rotationHandleStartPos.y = rotatedHandlePosition.y;
      }

      this.rotationHandleStartPos.initialAngle = this.calcAngleDegrees(
        this.elementRotationCoords,
        this.rotationHandleStartPos,
      );
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({ element: null, eventType: 'rotateStarted' });
    }
  }

  public dragged(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    let distanceX = event.clientX - this.startingPosition.x;
    let distanceY = event.clientY - this.startingPosition.y;
    this.startingPosition = { x: event.clientX, y: event.clientY };
    if (distanceX === 0 && distanceY === 0) {
      return;
    }
    distanceX = distanceX / scale.x;
    distanceY = distanceY / scale.y;

    this.rotationHandleStartPos.x += distanceX;
    this.rotationHandleStartPos.y += distanceY;

    const angleBetweenElemCenterAndMousePos = this.calcAngleDegrees(
      this.elementRotationCoords,
      this.rotationHandleStartPos,
    );

    let angle =
      this.rotationHandleStartPos.angle + angleBetweenElemCenterAndMousePos - this.rotationHandleStartPos.initialAngle;

    // Convert the angle to stay inside the 360 positive
    angle %= 360;
    if (angle < 0) {
      angle += 360;
    }
    angle = Number.parseFloat(angle.toFixed(1));

    if (event?.shiftKey) {
      angle = this.getClosestRightAngle(angle);
    }
    if (this.tempAngle !== angle) {
      this.rotate(angle);
      this.tempAngle = angle;
      const coords = this.canvasDocument.toDocumentPosition(event.x + 30, event.y + 30);
      this.selectedElement.rotationWidgetRenderer.angleCoordinates = coords;
      this.canvasDocument.draw();
    }
  }

  public dragended(event) {
    if (!this.selectedElement) return;
    //console.log('elementRotate.dragended')

    const hasChanged = this.selectedElement.elementDefinition?.rotate?.angle !== this.startingElement?.rotate?.angle;
    if (hasChanged) {
      this.selectedElement.rotationWidgetRenderer.angleCoordinates = null;
      this.canvasDocument.draw();

      const newElement = {
        id: this.selectedElement.id,
        rotate: ObjectUtil.cloneDeep(this.selectedElement.elementDefinition.rotate),
      };
      const undoElement = {
        id: this.startingElement.id,
        rotate: this.startingElement?.rotate ? this.startingElement.rotate : { angle: 0 },
      };
      this.canvasDocument.actionsDispatcher.handleUndoableChanges([newElement], [undoElement]);
    }

    this.clear();
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: this.selectedElement,
      eventType: 'rotateEnded',
    });
  }

  private clear() {
    this.startingElement = null;
    this.selectedElement = null;
    this.startingPosition = null;
    this.tempAngle = null;
    this.rotationHandleStartPos = { x: 0, y: 0, angle: 0, initialAngle: 0 };
    this.elementRotationCoords = { cx: 0, cy: 0, angle: 0 };
  }

  private rotate(angle: number) {
    this.elementRotationCoords.angle = angle;
    this.selectedElement.elementDefinition.rotate = { angle };
  }

  private getClosestRightAngle(angle: number): number {
    return Math.floor(angle / this.RIGHT_ANGLE_MARGIN) * this.RIGHT_ANGLE_MARGIN;
  }

  // gets the angle in degrees between two points
  private calcAngleDegrees(p1, p2) {
    const x = p2.x - (p1.x ? p1.x : p1.cx);
    const y = p2.y - (p1.y ? p1.y : p1.cy);
    return (Math.atan2(y, x) * 180) / Math.PI;
  }
}
