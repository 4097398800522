import { Action, createReducer, on } from '@ngrx/store';
import { initialState, State } from './document-manager.state';
import { documentElementReducers } from './document-elements/document-elements.reducers';
import * as DocumentManagerActions from './document-manager.actions';

const setDocumentElementsLoaded = (state: State, { loaded }) => {
  return {
    ...state,
    documentElementsLoaded: loaded,
  };
};

const setDocumentElementSortOrder = (state: State, { documentElementSortOrder }) => {
  return {
    ...state,
    documentElementSortOrder,
  };
};

const setDocumentId = (state: State, { documentId }) => {
  return {
    ...state,
    documentId,
  };
};

export const documentManagerReducer = createReducer(
  initialState,
  ...documentElementReducers,
  on(DocumentManagerActions.setDocumentElementsLoaded, setDocumentElementsLoaded),
  on(DocumentManagerActions.setDocumentElementSortOrder, setDocumentElementSortOrder),
  on(DocumentManagerActions.setDocumentId, setDocumentId),
);

export function reducer(state: State | undefined, action: Action): any {
  return documentManagerReducer(state, action);
}
