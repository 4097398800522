import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomFontsActions from './custom-fonts.actions';
import { CustomFontsService } from '../custom-fonts.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class CustomFontsEffects {
  constructor(
    private actions$: Actions,
    private customFontsService: CustomFontsService,
  ) {}

  loadCustomFonts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFontsActions.CustomFontsActionTypes.LOAD_CUSTOM_FONTS),
        tap((action: any) => this.customFontsService.loadCustomFonts()),
      ),
    { dispatch: false },
  );
}
