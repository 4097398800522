import { CanvasDocument } from '../canvas-document';
import { ImageElementCache } from './image-element-cache';
import { TextElementCache } from './text-element-cache';

export class CacheManager {
  constructor(public canvasDocument: CanvasDocument) {}

  public static clear() {
    ImageElementCache.clear();
    TextElementCache.clear();
  }
}
