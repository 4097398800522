import { ObjectUtil } from '@contrail/util';
import { EditorSelection, TinyMCE } from 'tinymce';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { EditorManager } from './editor-manager';
import { EditorFormatter } from './editor-formatter';
import { CanvasUtil } from '../../canvas-util';
import { TextFormat } from '../../elements/text/editor/text-editor';

declare let tinymce: TinyMCE;

export abstract class Editor {
  private contentUpdates$: ReplaySubject<string> = new ReplaySubject(1);
  public isFocused = false; // indicates whether cursor is active in the editor
  private isInitialized = false;

  constructor(
    protected canvasDocument: CanvasDocument,
    private editorId,
    protected manager: EditorManager,
  ) {
    this.initEditor();
    this.subscribe();
  }

  public subscribe() {
    this.contentUpdates$.pipe(debounceTime(500)).subscribe((content) => {
      this.saveData();
    });
  }

  initEditor() {
    const width = 100;
    const height = 100;
    this.isFocused = false;

    // If this text element is new and wasn't initialized but tinymce thinks it exists and is initialized -
    // this means that editor was previously initialized on a different frame and now we want to remove it
    // and re-init.
    // This is a precaution to re-init the element as an editor.
    if (!this.isInitialized && this.getEditor()?.initialized) {
      const ed = this.getEditor();
      ed?.getBody()?.remove();
      ed?.remove();
    }
    if (this.isInitialized) {
      this.emitTextEditorEvent();
      return new Promise((resolve) => resolve(this.getEditor()));
    }

    return tinymce
      .init(
        Object.assign(
          {
            base_url: '/tinymce', // Root for resources
            browser_spellcheck: true,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); }",
            contextmenu: '', // disables context menu on selected text
            // convert_newlines_to_brs : true,
            custom_ui_selector: '.tinymce-format-button',
            entity_encoding: 'raw',
            format_empty_lines: true,
            indent: false,
            menubar: false,

            // pad_empty_with_br: true,
            // remove_trailing_brs: true,

            paste_data_images: false,
            paste_preprocess: (editor, args) => {
              // Preprocess li elements on paste to editor
              // Add font size to the li tag itself so the bullet icon is the same size as text
              if (args?.content?.indexOf('li') !== -1) {
                let shouldUpdate = false;
                let div = document.createElement('div');
                div.innerHTML = args.content;
                const lis = Array.from(div.getElementsByTagName('li'));
                for (const li of lis) {
                  if (li.outerHTML?.indexOf('font-size') !== -1) {
                    const fontSize = CanvasUtil.getFirstFontSize(li.outerHTML, false);
                    if (fontSize) {
                      li.style.fontSize = fontSize;
                      shouldUpdate = true;
                    }
                  }
                }
                if (shouldUpdate) {
                  args.content = div.innerHTML;
                }
                div = null;
              }
            },
            placeholder: '',
            plugins: ['lists', 'link'],
            selector: '#' + this.editorId,
            statusbar: false,
            toolbar: false,
            inline: true,

            invalid_elements: 'br',
            //valid_elements: '@[style],p,span,div,ul,ol,li,a[!href],s,em,strong',
            extended_valid_elements: 'a[!href|style]',
            valid_styles:
              'list-style-type,color,font-size,font-family,font-weight,text-decoration,text-align,line-height,overflow,background-color,width,height,position,margin,padding,padding-left',
            width: `${width - 40}px`,
            height: `${height - 40}px`,
            // This is to make sure that Roboto Italic will look the same as the text image
            init_instance_callback: this.onInstanceInit.bind(this),
          },
          this.manager?.editorOptions,
        ),
      )
      .then((editors) => {
        this.isInitialized = true;
        return editors;
      });
  }

  private onInstanceInit(editor) {
    editor.on('click', (e) => {
      if (!e.shiftKey) {
        // selecting multiple elements
        const rng = this.getEditor().selection.getRng();
        if (rng.collapsed) {
          this.emitTextEditorEvent();
        }
        this.isFocused = true;
        e.stopPropagation();
      }
    });
    editor.on('blur', (e) => {
      if (this.manager.element) {
        const range = this.getEditor().selection.getRng();
        if (range.collapsed) {
          // this is needed to ensure that focus is returned to the editor when selecting a font size from the dropdown.
          this.getEditor().selection.getBookmark();
        }
      }
    });
    editor.on('mouseup', (e) => {
      // listens to mouseup when a user clicks on an editable area of the editor.
      if (!this.getEditor().hasFocus()) {
        this.getEditor().focus();
        const currentRng = tinymce.dom.RangeUtils.getCaretRangeFromPoint(e.x, e.y, window.document); // set the cursor based on mouse click position
        this.getEditor().selection.setRng(currentRng);
        this.emitTextEditorEvent();
        this.isFocused = true;
      } else {
        const rng = this.getEditor().selection.getRng();
        if (!rng.collapsed) {
          setTimeout(() => {
            this.emitTextEditorEvent();
          }, 10);
          this.isFocused = true;
        }
      }
    });

    editor.on('input', (e) => {
      this.applyLastAppliedTextFormat();
      this.manager.editorContainer.handleInputEvent(e);
    });

    editor.on('keydown', (e) => {
      this.manager.editorContainer.handleKeydownEvent(e);
    });

    editor.on('keyup', (e) => {
      if (e.code === 'Escape') {
        // Commenting out removeFocus() on Escape. It didnt't actually remove focus. Focus stayed and container stayed visible.
        // this.removeFocus();
      } else {
        this.contentUpdates$.next(this.getEditor().getContent());
        if (e.code === 'Backspace' || e.code === 'Delete') {
          // This helps with a bug that resets to default font settings on text delete.
          // Reset happens because there is a root tag <p> that has inner elements <span>.
          // Spans have all the styles so when it's deleted only <p> is left which
          // doesn't have current styles.
          const rng = editor.selection.getRng(true);
          const txt = rng.startContainer.textContent;
          const emptyLine =
            txt.substring(rng.startOffset - 1, rng.startOffset) == '' &&
            txt.substring(rng.startOffset, rng.startOffset + 1) == '' &&
            rng.startContainer.outerHTML?.indexOf('<li') === -1;
          if (this.getEditor().getContent() === '') {
            this.applyLastAppliedTextFormat();
          } else if (emptyLine) {
            console.log(
              'Editor on empty line',
              this.getEditor().getContent() === '',
              txt,
              rng.startContainer.outerHTML,
              `'${JSON.stringify(txt.substring(rng.startOffset - 1, rng.startOffset))}'`,
              `'${JSON.stringify(txt.substring(rng.startOffset, rng.startOffset + 1))}'`,
            );
            this.applyLastAppliedTextFormat(emptyLine);
          }
        }
        this.emitTextEditorEvent();
      }
    });

    editor.on('BeforeAddUndo', (e) => {
      if (!this.isFocused) {
        return false;
      }
    });

    editor.on('paste', async function (e) {
      const items = e.clipboardData.items;
      let filesPaste = false;
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          filesPaste = true;
          break;
        }
      }

      // If pasting text from AI we don't want to paste it as image
      if (filesPaste) {
        e.preventDefault();
        const promises = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const type = item.type;
          if (item.kind === 'string') {
            promises.push(
              new Promise((res) =>
                item.getAsString((data) => {
                  res({ data, type });
                }),
              ),
            );
          } else if (item.kind === 'file') {
            promises.push(new Promise((res) => res({ data: item.getAsFile(), type })));
          }
        }
        const contentData = await Promise.all(promises);

        // No need to insert it - just parsing as text allows
        // to paste text from AI, MS, etc.

        // contentData.forEach(({ data, type }) => {
        // if (type === 'text/plain') {
        // editor.insertContent(data);
        // }
        // });
      }
    });
  }

  setElement(focus = false) {
    if (focus) {
      tinymce.get(this.editorId)?.focus();

      // Focus again to handle cases when it doesn't get focused (for empty sticky notes)
      setTimeout(() => {
        tinymce.get(this.editorId)?.focus();
      }, 0);
    }
    this.setContentStyle();
    this.applyLastAppliedTextFormat();
    this.emitTextEditorEvent();
  }

  applyLastAppliedTextFormat(emptyLine = false) {
    if (this.manager.options.setLastAppliedTextFormat) {
      const editor = this.getEditor();
      let lastAppliedFormats = this.canvasDocument?.documentService?.lastAppliedTextFormat;
      if (editor && (this.getEditor().getContent({ format: 'text' }) === '' || emptyLine) && lastAppliedFormats) {
        for (const format in lastAppliedFormats) {
          // Only setting these formats because tinymce is acting strange when setting bold, text align etc.
          if (format === 'fontFamily') {
            editor.execCommand('FontName', false, lastAppliedFormats[format]);
          } else if (format === 'fontSize' && !this.manager.element?.isPropertyValid('style.font.size')) {
            editor.execCommand('FontSize', false, lastAppliedFormats[format] + 'pt');
          } else if (format === 'textColor') {
            const f: any = 'forecolor';
            editor.execCommand('mceApplyTextcolor', f, lastAppliedFormats[format]);
          }
        }
      }
    }
  }

  focus() {
    this.isFocused = true;
  }

  public removeFocus(save = false) {
    if (save) {
      this.saveData();
    }
    this.isFocused = false; // set isFocused = false to allow to apply style to the entire element
    const editor = this.getEditor();
    if (!editor) {
      return;
    }
  }

  /**
   * Set content @text to the editor if it's different
   * Used on undo/redo
   * @param text
   */
  // setContent(changedElemDef: CanvasTextElement) {
  //   this.elementDefinition = changedElemDef;
  //   const editor = this.getEditor();
  //   if (editor && (editor?.getContent() !== changedElemDef?.elementDefinition.text)) {
  //     editor?.setContent(changedElemDef.elementDefinition.text);
  //   }
  // }

  getSaveData() {
    if (!this.manager.elementDefinition) {
      return null;
    }
    const latestElementDefinition = this.canvasDocument.state.getElementById(
      this.manager.elementDefinition.id,
    )?.elementDefinition;
    const undoChangeElement = ObjectUtil.cloneDeep(latestElementDefinition);
    const editor = this.getEditor();
    if (!editor) {
      return;
    }
    const content = editor?.getContent(); // replace all br tags;
    if (latestElementDefinition && content !== latestElementDefinition.text) {
      // only saves when content is different
      this.manager.elementDefinition = ObjectUtil.cloneDeep(latestElementDefinition);
      this.manager.elementDefinition.text = content;
      return {
        changes: this.manager.elementDefinition,
        undoChanges: undoChangeElement,
      };
    }
    return null;
  }

  saveData() {
    const data = this.getSaveData();
    if (data) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges([data.changes], [data.undoChanges]);
    }
  }

  execCommand(action: any) {
    const editor = this.getEditor();
    if (!editor) {
      return;
    }
    let { command, format, value } = EditorFormatter.getCommand(action);
    // Select is true if the editor is not focused (user clicked on the selection box)

    if (command === 'FontSize' && this.manager.element?.isPropertyValid('style.font.size')) {
      // Do not apply inline font size to text tool because it uses one font size for all content from style.font.size
      return;
    }

    const selectAll = !this.isFocused;
    let currentState = null;
    if (selectAll) {
      this.getEditor().selection.select(this.getEditor().getBody(), true);
    }

    // Focus in the beginning of content to get current format state.
    // Focus will be removed later using .collapse()
    // This is especially needed when applying same format to multiple elements
    if (['Bold', 'Italic', 'Underline', 'Strikethrough'].includes(command)) {
      // editor.selection.setRng(editor.selection.getRng());
      currentState = editor.queryCommandState(command);
    }

    if (selectAll) {
      // Set selection to be all content so all text is formatted
      // editor.execCommand('SelectAll', false, value);
      if (command === 'FontName') {
        if (this.manager.elementDefinition.style.font) {
          this.manager.elementDefinition.style.font.family = value;
        } else {
          this.manager.elementDefinition.style = {
            font: {
              family: value,
            },
          };
        }
      }
    }
    if (format) {
      editor.execCommand(command, format, value);
    } else {
      if (['Bold', 'Italic', 'Underline', 'Strikethrough'].includes(command)) {
        // Need to check if those commands are currently turned on/off.
        // If check is not done, those commands could be toggled in the wrong way.
        if (currentState == null || currentState !== value) {
          if (command === 'Strikethrough' || command === 'Underline') {
            editor.execCommand(command);
            if (!selectAll) {
              // need this to workaround strikethrough issue where font size and text color needs to be set again to ensure that
              // the text-decoration and font-size appear in the same tag.
              // This still doesn't work if all text is selected.
              const fontSize = editor.queryCommandValue('fontSize');
              const textColor = this.getTextOrBgColor(editor.selection.getNode(), 'color');
              const forecolor: any = 'forecolor';
              fontSize &&
                !this.manager.element?.isPropertyValid('style.font.size') &&
                editor.execCommand('fontSize', false, fontSize);
              textColor && editor.execCommand('mceApplyTextcolor', forecolor, textColor);
            }
          } else {
            editor.execCommand(command);
          }
        }
      } else if (value === null) {
        editor.execCommand(command);
      } else {
        let bm = null;
        if (command === 'CreateLink') {
          const range = this.getEditor().selection.getRng();
          if (range.collapsed) {
            bm = this.getEditor().selection.getBookmark();
            this.getEditor().selection.select(this.getEditor().selection.getNode());
          }
        } else if (command === 'FontSize') {
          // There is an issue with setting the font size with strike through.
          // To work around the issue, strikethrough needs to be removed and readded.
          const isStrikethrough = editor.queryCommandState('Strikethrough');
          if (isStrikethrough) {
            editor.execCommand('Strikethrough');
            editor.execCommand('Strikethrough');
          }
        }
        editor.execCommand(command, false, value);
        // doing this to make sure bullets have the same color as the text
        if (command === 'InsertUnorderedList' || command === 'InsertOrderedList') {
          const textColor = this.getTextOrBgColor(editor.selection.getNode(), 'color');
          const fontSize = editor.queryCommandValue('FontSize');
          if (fontSize) {
            editor.execCommand('mceListUpdate', false, {
              styles: { 'font-size': fontSize },
            });
          }
          if (textColor) {
            editor.execCommand('mceListUpdate', false, {
              styles: { color: textColor },
            });
          }
        }
        if (bm) {
          this.getEditor().selection.moveToBookmark(bm);
        }
      }
    }
    const data = this.getSaveData();

    if (selectAll) {
      // Visually collapse selection - visually deselect all content
      editor.selection.collapse(false);
      this.removeFocus();
    }

    this.emitTextEditorEvent(); // Need to emit current text editor styling so it's consistent with what is displayed in the toolbar

    return data;
  }

  private tempSetElement(element: CanvasElement) {
    const editor = this.getEditor();
    if (editor) {
      const editorContainerVisibility = this.manager.editorContainer.tempSetVisible();
      this.manager.editorContainer.editor.innerHTML = element.elementDefinition.text;
      editor?.selection?.select(editor.getBody(), true);
      return editorContainerVisibility;
    }
    return null;
  }

  private tempRemoveElement(editorContainerVisibility) {
    console.log('Editor.tempRemoveElement', editorContainerVisibility);
    const editor = this.getEditor();
    if (editor) {
      editor?.selection?.collapse(false);
      this.manager.editorContainer.tempSetHidden(editorContainerVisibility);
    }
  }

  public getCurrentStyle(element: CanvasElement) {
    const editor = this.getEditor();
    if (editor) {
      const editorContainerVisibility = this.tempSetElement(element);
      this.emitTextEditorEvent(element);
      this.tempRemoveElement(editorContainerVisibility);
    }
  }

  public copySelectedTextElementStyle(element: CanvasElement): TextFormat {
    let currentTextFormat;
    if (this.isFocused) {
      currentTextFormat = this.getCurrentTextFormat();
    } else {
      const editor = this.getEditor();
      if (editor && element) {
        const editorContainerVisibility = this.tempSetElement(element);
        currentTextFormat = this.getCurrentTextFormat(element);
        this.tempRemoveElement(editorContainerVisibility);
      }
    }
    console.log('Editor.copySelectedTextElementStyle', element, this.isFocused, currentTextFormat);
    return currentTextFormat;
  }

  public getCurrentTextFormat(element?): TextFormat {
    return;
  }
  public emitTextEditorEvent(element?) {}

  public toPt(fontSize, precision = 0) {
    if (CanvasUtil.isPx(fontSize)) {
      return CanvasUtil.toPt(fontSize, precision) + 'pt';
    }
    return fontSize;
  }

  protected getTextOrBgColor(node, styleAtt) {
    // loop through the parents to get the text color or background color
    const parents = tinymce.DOM.getParents(node);
    for (let i = 0; i < parents.length; i++) {
      const parentNode = parents[i] as HTMLElement;
      const color =
        styleAtt === 'color' ? tinymce.DOM.getStyle(parentNode, styleAtt, true) : parentNode.style[styleAtt];
      if (parentNode.getAttribute('id') === this.editorId) {
        return '';
      }
      if (color != '' && color != 'undefined' && color != null) {
        return color;
      }
    }
    return '';
  }

  protected getLink(selection: EditorSelection) {
    if (selection.getNode().hasAttribute('href')) {
      return selection.getNode().getAttribute('href');
    } else if (selection.getNode().parentElement?.hasAttribute('href')) {
      // if link is not found, try its parent
      return selection.getNode().parentElement.getAttribute('href');
    }

    return null;
  }

  public setContentStyle() {}

  public getActiveEditor() {
    return tinymce.activeEditor;
  }

  public getEditor() {
    return tinymce.get(this.editorId);
  }

  public isEmptyPlainText() {
    const editor = this.getEditor();
    if (editor) {
      const innerHtml = this.manager.getInnerHTML();
      const plainText = editor.getContent({ format: 'text' });
      const isEmpty = CanvasUtil.isEmptyTextContent(innerHtml, plainText);
      // console.log('isEmptyPlainText', isEmpty, JSON.stringify({ innerHtml }), JSON.stringify({ plainText }))
      return isEmpty;
    }
    return false;
  }
}
