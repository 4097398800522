import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ItemData } from '@common/item-data/item-data';

@Component({
  selector: 'app-assortment-summary-dashboard-widget',
  templateUrl: './assortment-summary-dashboard-widget.component.html',
  styleUrls: ['./assortment-summary-dashboard-widget.component.scss'],
})
export class AssortmentSummaryDashboardWidgetComponent implements OnInit, OnChanges {
  @Input() assortmentItemData: Array<ItemData>;
  public total;
  public dropped;
  public active;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.computeCount();
  }

  computeCount() {
    if (!this.assortmentItemData) {
      return;
    }
    this.total = this.assortmentItemData.length;
    const active = this.assortmentItemData.filter((ai) => ai.assortmentItem.isDropped !== true);
    this.active = active.length;

    const dropped = this.assortmentItemData.filter((ai) => ai.assortmentItem.isDropped === true);
    this.dropped = dropped.length;
  }
}
