import { createAction, props } from '@ngrx/store';

export enum UndoRedoActionTypes {
  ADD_UNDO = '[UndoRedo] Add Undo',
  REMOVE_LATEST_UNDO = '[UndoRedo] Remove Latest Undo',
  ADD_REDO = '[UndoRedo] Add Redo',
  REMOVE_LATEST_REDO = '[UndoRedo] Remove Latest Redo',
  MOVE_LATEST_UNDO_TO_REDO = '[UndoRedo] Move Latest Undo to Redo',
  MOVE_LATEST_REDO_TO_UNDO = '[UndoRedo] Move Latest Redo to Undo',
  REMOVE_ALL_REDOS = '[UndoRedo] Remove All Redos',
}

export const addUndo = createAction(UndoRedoActionTypes.ADD_UNDO, props<{ changeDefinition: any }>());

export const removeLatestUndo = createAction(UndoRedoActionTypes.REMOVE_LATEST_UNDO);

export const moveLatestUndoToRedo = createAction(UndoRedoActionTypes.MOVE_LATEST_UNDO_TO_REDO);

export const addRedo = createAction(UndoRedoActionTypes.ADD_REDO, props<{ changeDefinition: any }>());

export const removeLatestRedo = createAction(UndoRedoActionTypes.REMOVE_LATEST_REDO);

export const moveLatestRedoToUndo = createAction(UndoRedoActionTypes.MOVE_LATEST_REDO_TO_UNDO);

export const removeAllRedos = createAction(UndoRedoActionTypes.REMOVE_ALL_REDOS);
