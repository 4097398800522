import '@babylonjs/loaders/glTF';
import { UniversalCamera } from '@babylonjs/core/Cameras/universalCamera';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { Engine } from '@babylonjs/core/Engines/engine';
import { Scene } from '@babylonjs/core/scene';
import { Scalar } from '@babylonjs/core/Maths/math.scalar';
import { Angle, Axis } from '@babylonjs/core/Maths/math';
import { Color3, Color4 } from '@babylonjs/core/Maths/math';
import { Vector3, Quaternion } from '@babylonjs/core/Maths/math';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { PointerEventTypes } from '../input/pointer-event-types';
import {
  ArcRotateCamera,
  ArcRotateCameraPointersInput,
  AssetContainer,
  CubeTexture,
  DirectionalLight,
  HDRCubeTexture,
  ImageProcessingConfiguration,
  Tools,
} from '@babylonjs/core';
import { AssetWrapper } from '../asset/assetwrapper';
import { DecalManager } from '../decal/decal-manager';
import { InputHandlerInterface } from '../input/input-handler';
import { PointerInfo, Observable } from '@babylonjs/core';
import { DEFAULT_ENV_TEXTURE_URL } from '../webgl-resources';

export class WebGLViewer implements InputHandlerInterface {
  canvas: HTMLCanvasElement;
  engine: Engine;
  scene: Scene;
  camera: ArcRotateCamera;
  assetWrapper: AssetWrapper;
  PointerEventTypes: PointerEventTypes;
  currentAssetUrl: string;
  decalManager: DecalManager;

  hasExclusiveControl: boolean = false;

  inputHandlers: Array<InputHandlerInterface>;

  pointersInput: ArcRotateCameraPointersInput;

  pointerConfig = {
    pointerButtons: [0, 1, 2],
  };

  // Values set by the component buttons
  currentZoomControl = 0;
  currentRotateControl = 0;

  public onAssetLoaded = new Observable();

  public setupScene(canvas: HTMLCanvasElement): void {
    this.canvas = canvas;

    this.canvas.oncontextmenu = function (e) {
      e.preventDefault();
    };

    this.engine = new Engine(this.canvas, true);
    this.scene = this.createScene();

    this.engine.runRenderLoop(() => {
      if (this.currentZoomControl != 0) {
        this.zoomCamera(this.currentZoomControl);
      }

      if (this.currentRotateControl != 0) {
        this.rotateAroundY(this.currentRotateControl);
      }

      if (this.decalManager) {
        this.decalManager.updateLoop();
      }

      this.scene.render();
    });

    const _this = this;
    this.canvas.onresize = () => {
      _this.engine.resize();
    };
    window.onresize = () => {
      _this.engine.resize();
    };

    this.inputHandlers = new Array<InputHandlerInterface>();
    this.inputHandlers.push(this);
  }

  private createScene(): Scene {
    const scene = new Scene(this.engine);

    const hdrTexture = new CubeTexture(DEFAULT_ENV_TEXTURE_URL, this.scene, null, true, null, () => {
      scene.environmentTexture = hdrTexture;
    });

    scene.clearColor = Color4.FromHexString('#f6f6f6');

    this.camera = new ArcRotateCamera('camera', Math.PI / 2, Math.PI / 2, 1, Vector3.Zero(), scene);
    this.camera.minZ = 0.01;
    this.camera.maxZ = 0; // no far clipping plane
    this.camera.attachControl(this.canvas, false);

    // Default value based on an assetSize length of 1.  Adjusted for assetSize in setupAsset
    this.camera.panningSensibility = 2000;
    this.camera.panningInertia = 0;

    // Set up default lights
    const light_front = new DirectionalLight('light_front', new Vector3(0, 0, -1), scene);
    const light_top_right = new DirectionalLight('light_top_right', new Vector3(1, -1, 0.3), scene);
    const light_top_left = new DirectionalLight('light_top_left', new Vector3(-1, -1, -0.3), scene);

    // Enable ACES Tonemapping
    scene.imageProcessingConfiguration.isEnabled = true;
    scene.imageProcessingConfiguration.toneMappingEnabled = true;
    scene.imageProcessingConfiguration.toneMappingType = ImageProcessingConfiguration.TONEMAPPING_ACES;

    // Set default exposure
    scene.imageProcessingConfiguration.exposure = 1.25;

    this.pointersInput = this.camera.inputs.attached.pointers as ArcRotateCameraPointersInput;

    // Remove default controls for mouse wheel and keyboard
    this.camera.inputs.removeByType('ArcRotateCameraMouseWheelInput');
    this.camera.inputs.removeByType('ArcRotateCameraKeyboardMoveInput');

    // Remove inputs for now, they will be activated in handlePointerInput
    this.pointersInput.detachControl();

    scene.onPointerObservable.add(
      this.handlePointerObservable,
      PointerEventTypes.POINTERDOWN |
        PointerEventTypes.POINTERUP |
        PointerEventTypes.POINTERMOVE |
        PointerEventTypes.POINTERWHEEL,
      true,
      this,
    );

    return scene;
  }

  async setupAsset(assetUrl: string): Promise<void> {
    this.assetWrapper?.dispose(false, true);
    this.assetWrapper = null;

    this.currentAssetUrl = assetUrl;

    let assetContainer: AssetContainer;
    await SceneLoader.LoadAssetContainerAsync(assetUrl, '', this.scene).then((loadedContainer) => {
      // When load finishes, make sure it's still the current URL and not a duplicate load
      if (assetUrl != this.currentAssetUrl || this.assetWrapper != null) {
        return;
      }

      assetContainer = loadedContainer;

      this.onAssetLoaded.notifyObservers({});
    });

    if (!assetContainer) {
      return;
    }

    this.assetWrapper = AssetWrapper.WrapAssetContainer(this.scene, assetContainer);

    this.camera.panningSensibility /= this.assetWrapper.getAssetSize().length();

    this.resetAssetTransform();
  }

  resetAssetTransform(): void {
    if (!this.assetWrapper) {
      return;
    }

    this.assetWrapper.setPosition(Vector3.Zero());
    this.assetWrapper.setRotation(Vector3.Zero());

    this.camera.target = Vector3.Zero();
    this.camera.radius = this.assetWrapper.getAssetSize().length() * 1.25;
    this.camera.alpha = Math.PI / 2;
    this.camera.beta = Math.PI / 2;
  }

  private handlePointerObservable(pointerInfo: PointerInfo): void {
    for (let i = 0; i < this.inputHandlers.length; i++) {
      if (this.inputHandlers[i] && this.inputHandlers[i].hasExclusiveControl) {
        this.inputHandlers[i].handlePointerInput(pointerInfo);
        return;
      }
    }

    // Reverse loop to place webgl viewer at lowest input priority
    for (let i = this.inputHandlers.length - 1; i >= 0; i--) {
      if (this.inputHandlers[i].handlePointerInput(pointerInfo)) {
        return;
      }
    }
  }

  handlePointerInput(pointerInfo: PointerInfo): boolean {
    const event = pointerInfo.event;
    const pointerConfig = this.pointerConfig;

    if (!this.assetWrapper) {
      return;
    }

    // Pointer down/up event for an unhandled button
    if (
      pointerInfo.type !== PointerEventTypes.POINTERMOVE &&
      pointerConfig.pointerButtons.indexOf(event.button) === -1
    ) {
      return;
    }

    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN: {
        try {
          const pointerEvent = event as PointerEvent;
          event.srcElement.setPointerCapture(pointerEvent.pointerId);
        } catch (e) {}

        this.hasExclusiveControl = true;

        this.camera.inputs.attachInput(this.pointersInput);

        event.preventDefault();
        this.canvas.focus();

        break;
      }
      case PointerEventTypes.POINTERUP: {
        try {
          const pointerEvent = event as PointerEvent;
          event.srcElement.releasePointerCapture(pointerEvent.pointerId);
        } catch (e) {}

        this.hasExclusiveControl = false;

        this.pointersInput.detachControl();

        event.preventDefault();

        break;
      }
      case PointerEventTypes.POINTERWHEEL: {
        const wheelEvent = event as WheelEvent;

        this.zoomCamera(wheelEvent.deltaY);

        event.preventDefault();

        break;
      }
    }

    // For now can always return true because it's last in input list, may want to change this
    return true;
  }

  private zoomCamera(zoomDelta: number) {
    const radiusDelta = this.assetWrapper.getAssetSize().length() * 0.002 * zoomDelta;

    this.camera.radius += radiusDelta;

    const minRadius = this.assetWrapper.getAssetSize().length() * 0.35 + this.camera.minZ;
    const maxRadius = this.assetWrapper.getAssetSize().length() * 2;

    this.camera.radius = Scalar.Clamp(this.camera.radius, minRadius, maxRadius);
  }

  private rotateAroundY(rotateDelta: number) {
    const rotationRate = 0.00175; // .001 radians
    this.camera.alpha -= rotateDelta * rotationRate * this.scene.deltaTime;
  }

  recolorAsset(colorHex: string): void {
    if (this.assetWrapper) {
      this.assetWrapper.recolorAsset(colorHex);
    }
  }

  resetAssetTextures(): void {
    if (this.assetWrapper) {
      this.assetWrapper.resetTextures();
    }
  }

  setAssetTexture(textureData: string): void {
    if (this.assetWrapper) {
      this.assetWrapper.setTexture(textureData);
    }
  }

  setAssetTextureTransform(textureTransform): void {
    if (this.assetWrapper) {
      this.assetWrapper.setTextureTransform(textureTransform);
    }
  }

  async takeScreenshot(): Promise<string> {
    const data: string = await Tools.CreateScreenshotUsingRenderTargetAsync(
      this.engine,
      this.camera,
      { width: 800, height: 800 },
      'image/png',
      1,
      true,
    );
    return data;
  }

  saveScreenshot() {
    Tools.CreateScreenshotUsingRenderTarget(
      this.engine,
      this.camera,
      { width: 800, height: 800 },
      null,
      'image/png',
      1,
      true,
      'download.png',
    );
  }

  async startDecalPlacement(textureData: string) {
    if (!this.decalManager) {
      this.decalManager = new DecalManager();
      await this.decalManager.init(this.scene, this.assetWrapper);
      this.inputHandlers.push(this.decalManager);
    }

    if (this.decalManager) {
      this.decalManager.addDecal(textureData);
    }
  }

  deleteDecal(): void {
    if (this.decalManager) {
      this.decalManager.deleteDecal();
    }
  }

  updateDecalAngle(angleInDegrees: number): void {
    if (this.decalManager) {
      this.decalManager.updateDecalAngle(angleInDegrees);
    }
  }

  updateDecalScale(scale: number): void {
    if (this.decalManager) {
      this.decalManager.updateDecalScale(scale);
    }
  }
}
