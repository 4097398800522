<div class="header flex-between-center">
  <div class="flex justify-start">
    <div class="title mr-1">Dashboard</div>
  </div>
  <div class="flex space-x-2">
    <div class="cursor-pointer flex-center" (click)="handleClose()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
<app-dashboard-panel
  [assortmentItemData]="selectedAssortmentItemData || (assortmentItemData$ | async)"
  [appContext]="appContext"
  [annotationOptions]="annotationOptions"
  (panelClosed)="handleClose()"
>
</app-dashboard-panel>
