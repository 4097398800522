import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-no-connection-overlay',
  imports: [CommonModule, MatButtonModule],
  template: `<div *ngIf="showWarningMessage" class="overlay">
    <div class="mat-dialog-container">
      <div class="flex justify-between">
        <h6 data-test="app-confirmation-box-title">Session Disconnected</h6>
      </div>
      <div class="flex items-center my-5">
        <span class="material-icons text-warn-light text-6xl mr-5">warning</span>
        <div data-test="app-confirmation-box-message" class="text-sm">{{ message }}</div>
      </div>
      <div class="flex items-center justify-end">
        <button
          data-test="app-confirmation-box-ok-button"
          mat-raised-button
          color="primary"
          (click)="reload()"
          cdkFocusInitial
        >
          REFRESH
        </button>
      </div>
    </div>
  </div>`,
  styles: [
    `
      .overlay {
        @apply h-screen w-full top-0 left-0 fixed flex flex-col;
        background-color: rgba(0, 0, 0, 0.286);
        z-index: 10000;
      }
      .mat-dialog-container {
        @apply absolute w-[500px] p-6 rounded-md top-1/2 left-1/2;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        max-height: 250px;
      }
    `,
  ],
})
export class NoConnectionOverlayComponent implements OnInit, OnDestroy {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions = new Subscription();
  public showWarningMessage = false;
  private lastTime = new Date().getTime();
  public message = '';

  constructor(private webSocketService: WebSocketService) {}

  ngOnInit(): void {
    this.subscribeToOfflineEvent();
    this.setAliveMonitor();
  }

  private subscribeToOfflineEvent() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    // Listen to the websocket 'closed' event which can fire when
    // user puts machine to sleep, etc.
    this.webSocketService.connectionLost$.subscribe((val) => {
      if (val === true) {
        this.message = 'Reload your browser to ensure accuracy of your session.';
        this.showWarningMessage = true;
      }
    });

    this.subscriptions.add(
      this.onlineEvent.subscribe((e) => {
        //this.showWarningMessage = false;
      }),
    );

    this.subscriptions.add(
      this.offlineEvent.subscribe((e) => {
        this.message =
          'A connection with the server could not be established.  Check your internet connection and refresh your browser to resume your work.';
        this.showWarningMessage = true;
      }),
    );
  }

  /** Detects if the computer has been alseep, inactive, and forces refresh */
  private setAliveMonitor() {
    setInterval(function () {
      var currentTime = new Date().getTime();
      if (currentTime > this.lastTime + 2000 * 2) {
        // ignore small delays
        // Computer was asleep
        this.message = 'Looks like your computer fell asleep. Refresh your browser to resume your work.';
        this.showWarningMessage = true;
      }
      this.lastTime = currentTime;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  reload() {
    location.reload();
  }
}
