import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class ClipContentService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'toggle_clip_content') {
        const selected = this.documentService.getSelectedElements()?.filter((e) => e.type === 'frame');
        if (selected?.length > 0) {
          const element = request.sourceEvent?.element || selected[selected.length - 1];
          const clipContent = !!element.clipContent;
          const actions: Array<DocumentAction> = [];
          selected.forEach((el) => {
            actions.push(
              new DocumentAction(
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    clipContent: !clipContent,
                  },
                },
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: el.id,
                  elementData: {
                    id: el.id,
                    clipContent: el.clipContent,
                  },
                },
              ),
            );
          });
          this.documentService.handleDocumentActions(actions);
        }
      }
    });
  }
}
