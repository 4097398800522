import * as BoardsActions from './boards.actions';
import * as BoardsSelectors from './boards.selectors';
import * as BoardsStoreState from './boards.state';

import * as CollectionStatusMessagesActions from './collection-status-messages/collection-status-messages.actions';
import * as CollectionStatusMessagesSelectors from './collection-status-messages/collection-status-messages.selectors';

export { BoardsStoreModule } from './boards-store.module';

const actions = {
  ...BoardsActions,
  ...CollectionStatusMessagesActions,
};

const selectors = {
  ...BoardsSelectors,
  ...CollectionStatusMessagesSelectors,
};

export { actions as BoardsActions, selectors as BoardsSelectors, BoardsStoreState };
