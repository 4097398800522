import { Injectable } from '@angular/core';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class EditDocumentElementNameService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'edit_name') {
        this.documentService.editFrameName(request?.sourceEvent?.element?.id);
      }
    });
  }
}
