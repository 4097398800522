import { ViewBox } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { CanvasMaskState } from '../../state/canvas-mask-state';
import { FrameSelectionHandler } from './frame-selection-handler';

export class SelectionHandler {
  public frameSelectionHandler: FrameSelectionHandler;
  public selectedElementsByOrder: string[] = [];

  constructor(private canvasDocument: CanvasDocument) {
    this.frameSelectionHandler = new FrameSelectionHandler(canvasDocument);
  }

  public getSelectedCanvasElements() {
    return this.canvasDocument.state.canvasElements.filter((element) => element.isSelected);
  }

  public getSelectedUnlockedCanvasElements() {
    return this.canvasDocument.state.canvasElements.filter(
      (element) => element.isSelected && !element.elementDefinition.isLocked,
    );
  }
  public getSelectedLockedCanvasElements() {
    return this.canvasDocument.state.canvasElements.filter(
      (element) => element.isSelected && element.elementDefinition.isLocked,
    );
  }

  public selectAllUnlockedElements() {
    const canvasElements = this.canvasDocument.state.canvasElements.filter(
      (element) => !element.elementDefinition.isLocked && !element.isInMask && !element.isInGroup,
    );
    const multiSelect = canvasElements.length > 1 ? true : false;
    this.selectElements(canvasElements, multiSelect);
  }

  public deselectAll() {
    this.getSelectedCanvasElements().map((e) => this.deselect(e, false));
    this.selectedElementsByOrder = [];
    this.canvasDocument?.state?.maskState?.hideEditingMask();
    this.canvasDocument.draw();

    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: null,
      eventType: 'deselect',
    });
  }

  public deselectAndDraw(element: CanvasElement) {
    this.deselect(element);
    this.canvasDocument?.state?.maskState?.hideEditingMask();
    this.canvasDocument.draw();
  }

  public deselect(element: CanvasElement, removeFromSelectedByOrder = true) {
    this.setDeselected(element, removeFromSelectedByOrder);
    element.onDeselect();
  }

  public selectElementAndDraw(canvasElement: CanvasElement, multiSelect = false) {
    this.selectElement(canvasElement, multiSelect);
    this.canvasDocument.draw();
  }

  public selectElement(canvasElement: CanvasElement, multiSelect = false) {
    const selectedElements = this.getSelectedCanvasElements();
    if (selectedElements.length === 0) {
      multiSelect = false;
    }
    // if (multiSelect && (selectedElements?.findIndex((e) => e.elementDefinition.isLocked) !== -1 || (selectedElements?.length > 0 && canvasElement.elementDefinition.isLocked))) { return }

    this.selectElements([canvasElement], multiSelect, true);

    let selectedElement = canvasElement?.elementDefinition;
    // On copy/paste of frame, we selectElement one by one, so we want to send selected element event for frame
    if (canvasElement.isInFrame && selectedElements.findIndex((e) => e.id === canvasElement.isInFrame) !== -1) {
      selectedElement = this.canvasDocument.state.getElementById(canvasElement.isInFrame)?.elementDefinition;
    }
    this.sendDocumentElementEvent(selectedElement);
  }

  public selectElements(canvasElements: CanvasElement[], multiSelect = false, skipHandler = false) {
    if (!canvasElements?.length) {
      return;
    }

    const selectedElements = canvasElements;

    if (this.frameSelectionHandler) {
      const selectedElementsInFrame = this.frameSelectionHandler.handleElementSelected({
        selectedElements,
      });
      if (selectedElementsInFrame?.length > 0) {
        selectedElements.push(...selectedElementsInFrame);
      }
    }

    selectedElements[selectedElements.length - 1].onSelect();
    selectedElements
      .filter((element) => !element.isSelected)
      .map((element) => {
        this.setSelected(element);
      });

    if (!multiSelect) {
      this.handleDeselectExceptSelected(selectedElements);
    }

    this.canvasDocument?.state?.maskState?.hideEditingMask();

    if (!skipHandler) {
      this.sendDocumentElementEvent();
    }
  }

  public setSelected(element: CanvasElement) {
    element.isSelected = true;
    const index = this.selectedElementsByOrder.indexOf(element.id);
    if (index === -1) {
      this.selectedElementsByOrder.push(element.id);
    }
  }

  public setDeselected(element: CanvasElement, remove = true) {
    element.isSelected = false;
    element.isInFrame = false;
    if (remove) {
      const index = this.selectedElementsByOrder.indexOf(element.id);
      if (index !== -1) {
        this.selectedElementsByOrder.splice(index, 1);
      }
    }
  }

  public handleDeselectExceptSelected(selectedElements) {
    this.getSelectedCanvasElements()
      .filter((element) => selectedElements.findIndex((e) => e.id === element.id) === -1)
      .map((e) => this.deselect(e));
    this.canvasDocument.draw();
  }

  /**
   * Send 'selected' document element event
   * @element is not null during when the element is clicked on
   * and is null when selecting multiple elements by dragging.
   * @param element
   */
  public sendDocumentElementEvent(element = null) {
    const selectedSvgElements = this.getSelectedCanvasElements();
    const selectedElements = [];
    for (let i = 0; i < selectedSvgElements.length; i++) {
      const svgElement = selectedSvgElements[i];
      if (svgElement.elementDefinition.type === 'frame' || !svgElement.isInFrame) {
        selectedElements.push(svgElement.elementDefinition);
      }
    }
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element,
      selectedElements,
      eventType: 'selected',
    });
  }

  public selectElementsInBox(box: ViewBox, multiSelect = false) {
    if (!multiSelect) {
      this.deselectAll();
    }
    const selectedElements = this.canvasDocument.state.getElementsInBox(box);
    this.selectElements(selectedElements, multiSelect || selectedElements?.length > 1 ? true : false);
  }

  /**
   * Get unlocked selected elements.
   * Include locked elements if they are on selected unlocked frame.
   * @returns
   */
  public getSelectedElementsForUpdate(): CanvasElement[] {
    let selectedCanvasElements: CanvasElement[] =
      this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
    const selectedGroupElements: CanvasElement[] =
      selectedCanvasElements.filter((element) => element.elementDefinition.type === 'group' && !element.isInFrame) ||
      [];
    let selectedFrames: CanvasElement[] =
      selectedCanvasElements.filter((element) => element.elementDefinition.type === 'frame') || [];
    selectedGroupElements.forEach((groupElement) => {
      selectedCanvasElements = selectedCanvasElements.concat(
        this.canvasDocument.state.groupState.getAllElementsInGroup(groupElement.id),
      );
      // if frame is in a group, get its members
      selectedCanvasElements
        .filter((element) => element.elementDefinition.type === 'frame')
        .forEach((frame) => {
          this.canvasDocument.state.frameState
            .getFrameForElement(frame.elementDefinition)
            .elements.map((frameInnerElement) => frameInnerElement.canvasElement)
            .forEach((element) => {
              if (!selectedCanvasElements.find((canvasElement) => canvasElement.id === element.id)) {
                selectedCanvasElements.push(element);
              }
            });
        });
    });

    // After group and frame members were added check if any of them are masks and add mask members
    const selectedMasks: CanvasElement[] =
      selectedCanvasElements.filter((element) => CanvasMaskState.isMask(element.elementDefinition)) || [];
    selectedMasks.forEach((mask) => {
      // Select mask members if the mask is currently not being edited
      if (!this.canvasDocument?.state?.maskState?.isEditingMask(mask.id)) {
        selectedCanvasElements.push(
          ...this.canvasDocument.state.maskState
            .getMaskMembers(mask.id)
            ?.filter((m) => selectedCanvasElements?.findIndex((e) => e.id === m.id) === -1),
        );
      }
    });
    if (selectedFrames.length === 0) {
      return selectedCanvasElements.filter((e) => !e.elementDefinition.isLocked);
    }
    const frameElements: Map<string, string> = this.canvasDocument?.state?.frameState?.frameElements;
    return selectedCanvasElements.filter((canvasElement) => {
      const isInSelectedFrame = selectedFrames.find((e) => e.id === frameElements?.get(canvasElement.id));
      return isInSelectedFrame
        ? !isInSelectedFrame.elementDefinition.isLocked
        : !canvasElement.elementDefinition.isLocked;
    });
  }

  /**
   * Get selected elements by frame. If a frame is selected exclude inner frame elements.
   * @returns
   */
  public getSelectedElementsByFrame(): CanvasElement[] {
    const selectedCanvasElements: CanvasElement[] =
      this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedCanvasElements();
    const selectedFrames: CanvasElement[] =
      selectedCanvasElements.filter((element) => element.elementDefinition.type === 'frame') || [];
    if (selectedFrames.length === 0) {
      return selectedCanvasElements;
    }
    const frameElements: Map<string, string> = this.canvasDocument?.state?.frameState?.frameElements;
    return selectedCanvasElements.filter(
      (canvasElement) =>
        canvasElement.elementDefinition.type === 'frame' ||
        selectedFrames.findIndex((e) => e.id === frameElements?.get(canvasElement.id)) === -1,
    );
  }

  public getSelectedUnlockedElementsByFrame(): CanvasElement[] {
    return this.getSelectedElementsByFrame()?.filter((element) => !element.elementDefinition.isLocked);
  }
}
