import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[appOverflowTooltip]',
})
export class OverflowTooltipDirective {
  @Input() toolTipElement: string = null; // span, div, .class-name, #element-id, etc...

  constructor(
    private el: ElementRef,
    private tooltip: MatTooltip,
  ) {}

  @HostListener('mouseover') showTooltip() {
    const el: HTMLElement = this.el.nativeElement;
    let targetEl = el;

    if (this.toolTipElement) {
      const tooltipEle: HTMLElement = el.querySelector(this.toolTipElement);
      targetEl = tooltipEle;
    }

    if (targetEl.offsetWidth < targetEl.scrollWidth) {
      this.tooltip.message = targetEl.innerText;
      this.tooltip.show();
    }
  }

  @HostListener('mouseleave') hideTooltip() {
    this.tooltip.hide();
  }
}
