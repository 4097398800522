import { DocumentElement, SizeDefinition } from '@contrail/documents';
import { ImageElement } from '../../components/image-element/image-element';
import { ImageElementCache } from '../../cache/image-element-cache';
import { FileDownloader } from '../../file-downloader';

export const PLACEHOLDER_ITEM_FAMILY_IMAGE = '/assets/images/image_item_family_placeholder.svg';
export const PLACEHOLDER_ITEM_OPTION_IMAGE = '/assets/images/image_item_option_placeholder.svg';
export const PLACEHOLDER_IMAGE = '/assets/images/image_placeholder.svg';
export const UNASSIGNED_PLACEHOLDER_IMAGE = '/assets/images/unassigned-placeholder.svg';
export const PLACEHOLDER_ITEM_IMAGE = '/assets/images/item-icon-black.png';
export const PLACEHOLDER_BROKEN_IMAGE = 'assets/images/broken_image.svg';
export const LOADING_IMAGE = '/assets/images/file_upload.svg';
export const IMAGE_WIDTH = 100;
export const IMAGE_HEIGHT = 100;
export const WARNING_OPACITY = 0.6;
export const IMAGE_OPACITY = 0.05;

export interface ImageLoaderResponse {
  imageBlob?: Blob;
  imageUrl?: string;
  imageElement?: HTMLImageElement;
  canvasImageElement?: HTMLCanvasElement | HTMLImageElement;
  imageSize?: SizeDefinition;
  imageError?: boolean;
  imageOpacity?: number;
  status?: string;
  isLocalBlob?: boolean;
}

export const IMAGE_FORMATS = {
  svg: 'image/svg+xml',
  image: 'image/png',
};

export class CanvasImageLoader {
  public static loadingImage: ImageLoaderResponse;
  constructor() {}

  static {
    this.loadingImage = this.getLoadingImageResponse();
  }

  /**
   * Returns a resolved promise for loading an image, leveraging cachine of promises to ensure the same
   * image is not loaded multiple times over and over again.
   */
  public static async loadImage(
    urlToLoad: string,
    elementDefinition: DocumentElement,
    fileDownloader: FileDownloader,
    componentElement: DocumentElement,
    isFirefox: boolean,
    drawToOffscreenCanvas = false,
    isAnonymous = false,
  ): Promise<ImageLoaderResponse> {
    return ImageElementCache.loadFromCache(
      urlToLoad,
      elementDefinition,
      fileDownloader,
      componentElement,
      isFirefox,
      drawToOffscreenCanvas,
      isAnonymous,
    );
  }

  /** Returns a promise for loading an image. */
  public static async getLoadImagePromise(
    urlToLoad: string,
    elementDefinition: DocumentElement,
    fileDownloader: FileDownloader,
    componentElement: DocumentElement,
    isFirefox: boolean,
    drawToOffscreenCanvas = false,
    isAnonymous = false,
  ): Promise<ImageLoaderResponse> {
    if (!urlToLoad) {
      urlToLoad = '';
    }

    return new Promise(async (resolve, reject) => {
      if (componentElement && urlToLoad === '') {
        resolve(this.getPlaceholderItemImageResponse(componentElement));
      }
      let url = urlToLoad;
      let blob;
      if (urlToLoad.indexOf('api.vibeiq.com') > -1 || urlToLoad.indexOf('api.dev.vibeiq.com') > -1) {
        await this.fetchUrl(urlToLoad, elementDefinition, fileDownloader, componentElement, isFirefox)
          .then((blobResponse) => {
            blob = blobResponse;
            url = URL.createObjectURL(blob);
          })
          .catch((error) => {
            resolve(this.getBrokenImageResponse());
          });
      } else if (isAnonymous) {
        // Try to fetch URL to avoid Tainted Canvas error which happens
        // when images with different origin src are drawn to canvas
        await fetch(url, {
          cache: 'no-cache',
        }).then(async (response) => {
          blob = await response.blob();
          url = URL.createObjectURL(blob);
        });
      }

      const isLocalBlob = this.isLocalBlob(urlToLoad);
      const imageElement = drawToOffscreenCanvas
        ? ImageElement.toCanvasBlobImage(url, IMAGE_FORMATS[elementDefinition.type], 1, 8, !isLocalBlob)
        : ImageElement.fromURL(url, !isLocalBlob);
      imageElement
        .then((img) => {
          resolve({
            imageBlob: blob,
            imageUrl: url,
            imageElement: img.img,
            canvasImageElement: img.canvasImg,
            imageSize: {
              width: img.img.width,
              height: img.img.height,
            },
            imageError: false,
            isLocalBlob,
          });
        })
        .catch((error) => {
          if (!componentElement) {
            if (isLocalBlob) {
              resolve(this.getLoadingImageResponse());
            } else {
              resolve(this.getBrokenImageResponse());
            }
          } else {
            resolve(this.getPlaceholderItemImageResponse(componentElement));
            // reject(error);
          }
        });
    });
  }

  private static async fetchUrl(
    urlToLoad,
    elementDefinition,
    fileDownloader: FileDownloader,
    componentElement: DocumentElement,
    isFirefox,
  ): Promise<Blob> {
    let blob = await fileDownloader.downloadFileAsBlob(urlToLoad);
    if ((isFirefox && elementDefinition.type === 'svg') || (componentElement && urlToLoad?.indexOf('.svg') !== -1)) {
      // Firefox does not render svg to canvas that do not have width and height attribites
      // https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage
      const parser = new DOMParser();
      const result = parser.parseFromString(await blob.text(), 'text/xml');
      const inlineSVG = result.getElementsByTagName('svg')[0];

      const viewBox = inlineSVG
        .getAttribute('viewBox')
        ?.replace(/, /gi, ',')
        ?.split(/[ ,]+?/g);
      const width = inlineSVG.getAttribute('width');
      const height = inlineSVG.getAttribute('width');
      console.log('Setting width and height to SVG', viewBox, width, height);
      // add the attributes Firefox needs. These should be absolute values, not relative
      if (!width || width === '100%') {
        inlineSVG.setAttribute(
          'width',
          `${(viewBox?.length && viewBox[2]) || elementDefinition?.size?.width || 300}px`,
        );
      }
      if (!height || height === '100%') {
        inlineSVG.setAttribute(
          'height',
          `${(viewBox?.length && viewBox[3]) || elementDefinition?.size?.height || 300}px`,
        );
      }
      blob = new Blob([new XMLSerializer().serializeToString(inlineSVG)], { type: 'image/svg+xml' });
    }
    return blob;
  }

  private static isLocalBlob(url): boolean {
    return url?.indexOf('blob:') !== -1;
  }

  private static getBrokenImageResponse() {
    const img = document.createElement('img');
    img.src = PLACEHOLDER_BROKEN_IMAGE;
    return {
      imageUrl: PLACEHOLDER_BROKEN_IMAGE,
      imageElement: img,
      imageSize: {
        width: 168,
        height: 168,
      },
      imageError: true,
    };
  }

  private static getLoadingImageResponse() {
    const img = document.createElement('img');
    img.src = LOADING_IMAGE;
    return {
      imageUrl: LOADING_IMAGE,
      imageElement: img,
      imageSize: {
        width: 175,
        height: 215,
      },
      imageError: true,
    };
  }

  private static getPlaceholderItemImageResponse(componentElement: DocumentElement) {
    let imageUrl = this.isUnassignedItemComponentElement(componentElement)
      ? UNASSIGNED_PLACEHOLDER_IMAGE
      : PLACEHOLDER_IMAGE;
    const roles = componentElement?.entityData?.roles || [];
    if (roles?.indexOf('family') !== -1) {
      imageUrl = PLACEHOLDER_ITEM_FAMILY_IMAGE;
    } else if (roles?.indexOf('option') !== -1) {
      imageUrl = PLACEHOLDER_ITEM_OPTION_IMAGE;
    }

    const img = document.createElement('img');
    img.src = imageUrl;
    return {
      imageUrl,
      imageElement: img,
      imageSize: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
      },
      imageError: false,
    };
  }

  private static isUnassignedItemComponentElement(elementDefinition: DocumentElement) {
    return !elementDefinition?.modelBindings || Object.keys(elementDefinition.modelBindings).length === 0;
  }
}
