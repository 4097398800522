import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyValueFormatter } from '@contrail/types';

@Component({
  selector: 'app-entity-property-chooser',
  templateUrl: './entity-property-chooser.component.html',
  styleUrls: ['./entity-property-chooser.component.scss'],
})
export class EntityPropertyChooserComponent implements OnChanges {
  @Input() entities: Array<any>;
  @Output() panelClosed = new EventEmitter();
  @Input() resultsHeight = '100%';
  public properties: Array<any> = [];
  constructor() {}

  async ngOnChanges() {
    console.log('EntityPropertyChooserComponent: init: ', this.entities);
    if (this.entities) {
      this.initProperties();
    }
  }

  private async initProperties() {
    const propertyValueFormatter = new PropertyValueFormatter();
    for (let entity of this.entities) {
      if (!entity.typeId) {
        continue;
      }
      const type = await new Types().getType({ id: entity.typeId });
      for (let property of type.typeProperties) {
        const prop = {
          id: property.id,
          label: property.label,
          slug: property.slug,
          value: entity[property.slug],
          display: propertyValueFormatter.getDisplayValue(entity, property),
          modelBindings: {},
          propertyBindings: { text: `${entity.entityType}.${property.slug}` },
        };
        prop.modelBindings[entity.entityType] = `${entity.entityType}:${entity.id}`;
        this.properties.push(prop);
      }
    }
    this.properties.sort((p1, p2) => (p1.label > p2.label ? 1 : -1));
    console.log('initProperties: ', this.properties);
  }

  startDrag(event, property) {
    const id = event.target.id;
    console.log('dragging: ', event, id, property);
    property.entityType = 'content';

    const data: any = {
      entityType: 'bound_text',
      text: property.display,
    };

    data.modelBindings = property.modelBindings;
    data.propertyBindings = property.propertyBindings;
    event.dataTransfer.setData('entity', JSON.stringify(data));
    event.dataTransfer.setData('dataObject', JSON.stringify(data));
  }

  handleClose() {
    this.panelClosed.emit();
  }

  handleEntityClicked(event) {}
}
