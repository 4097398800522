import { createAction, props } from '@ngrx/store';
import { Assortment } from './assortments.state';

export enum AssortmentsActionTypes {
  LOAD_ASSORTMENTS = '[Assortments] Load Assortments',
  LOAD_ASSORTMENTS_SUCCESS = '[Assortments] Load Assortments Success',
  LOAD_ASSORTMENTS_FAILURE = '[Assortments] Load Assortments Failure',

  LOAD_SOURCE_ASSORTMENT = '[Assortments] Load Source Assortment',
  LOAD_SOURCE_ASSORTMENT_SUCCESS = '[Assortments] Load Source Assortment Success',
  LOAD_SOURCE_ASSORTMENT_FAILURE = '[Assortments] Load Source Assortment Failure',

  LOAD_SOURCE_ASSORTMENT_INFO = '[Assortments] Load Source Assortment Info',
  LOAD_SOURCE_ASSORTMENT_INFO_SUCCESS = '[Assortments] Load Source Assortment Info Success',
  LOAD_SOURCE_ASSORTMENT_INFO_FAILURE = '[Assortments] Load Source Assortment Info Failure',

  CLEAR_SOURCE_ASSORTMENT = '[Assortments] Load Source Assortment Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadAssortments = createAction(AssortmentsActionTypes.LOAD_ASSORTMENTS);

export const loadAssortmentsSuccess = createAction(
  AssortmentsActionTypes.LOAD_ASSORTMENTS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadAssortmentsFailure = createAction(
  AssortmentsActionTypes.LOAD_ASSORTMENTS_FAILURE,
  props<{ error: any }>(),
);

export const loadSourceAssortment = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT,
  props<{ sourceAssortmentId: string }>(),
);

export const loadSourceAssortmentSuccess = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_SUCCESS,
  props<{ sourceAssortment: Assortment }>(),
);

export const loadSourceAssortmentFailure = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const loadSourceAssortmentInfo = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_INFO,
  props<{ sourceAssortmentId: string }>(),
);

export const loadSourceAssortmentInfoSuccess = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_INFO_SUCCESS,
  props<{ sourceAssortment: Assortment }>(),
);

export const loadSourceAssortmentInfoFailure = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_INFO_FAILURE,
  props<{ error: any }>(),
);

export const clearSourceAssortment = createAction(AssortmentsActionTypes.CLEAR_SOURCE_ASSORTMENT);
