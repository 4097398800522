import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

const MIN_VAL = 5;
const MAX_VAL = 200;

@Component({
  selector: 'app-property-configurator-selector-size',
  templateUrl: './property-configurator-selector-size.component.html',
  styleUrls: ['./property-configurator-selector-size.component.scss'],
})
export class PropertyConfiguratorSelectorSizeComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() dimensionType: any;
  @Input() label: any;
  @Input() increment: any;

  defaultValue = 5;
  value = this.defaultValue;

  constructor() {}

  ngOnChanges() {
    console.log('size: current value: ', this.currentValue);
    this.value = this.currentValue || this.defaultValue;
  }

  dec() {
    this.resize(-this.increment);
  }
  inc() {
    this.resize(+this.increment);
  }

  resize(delta: number) {
    this.value = Math.min(MAX_VAL, Math.max(MIN_VAL, +this.value + delta));
    this.setSize(this.value);
  }

  inputResize(event) {
    this.value = +event.target.value;
    this.setSize(this.value);
  }

  setSize(value) {
    console.log('value: ', this.value);
    this.value = value;
    this.updateAllSelected();
  }

  updateAllSelected() {
    const size = {};
    size[this.dimensionType] = this.value;
    const changes = {
      size,
    };
    this.valueChange.emit(changes);
  }
}
