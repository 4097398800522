export enum EditorMode {
  EDIT = 'EDIT',
  COMMENT = 'COMMENT',
  VIEW = 'VIEW',
}
export interface State {
  editorMode: EditorMode;
}

export const editorModeInitialState: State = {
  editorMode: EditorMode.EDIT,
};
