<!-- main canvas -->
<div
  class="board-svg"
  id="canvas-container"
  style="position: fixed; top: 0; left: 0; width: 100%; height: 100%"
  (dragenter)="dragEnter($event)"
  (dragover)="dragOver($event)"
  (dragleave)="dragLeave($event)"
  (drop)="drop($event)"
  (mousedown)="handleMouseDown($event)"
  (mouseup)="handleMouseUp($event)"
>
  <div style="background-color: rgba(0, 0, 0, 0.05)" width="100%" height="100%" id="mainCanvas"></div>
</div>

<!-- canvas overlay -->
<app-board-mouse-tracker
  *ngIf="zoomPanHandler"
  [viewBox]="zoomPanHandler.viewBox"
  [zoomFactor]="zoomPanHandler.zoomFactor"
  class="!absolute"
></app-board-mouse-tracker>
<app-board-pinned-comments *ngIf="zoomPanHandler && editorMode !== 'VIEW'"></app-board-pinned-comments>
<app-comment-overlay *ngIf="currentBoard"></app-comment-overlay>

<!-- top bar -->
<app-board-title-bar
  *ngIf="currentBoard"
  class="floating-menu"
  [class.hidden-component]="(isFullScreen$ | async) && (framePresentation$ | async)"
></app-board-title-bar>
<app-board-add-entities-bar
  *ngIf="editorMode === 'EDIT' && !isSharedLinkUser"
  class="floating-menu"
  [class.hidden-component]="(isFullScreen$ | async) && (framePresentation$ | async)"
></app-board-add-entities-bar>
<app-board-general-tools-bar
  *ngIf="currentBoard"
  [editorMode]="editorMode"
  class="floating-menu"
  [class.hidden-component]="(isFullScreen$ | async) && (framePresentation$ | async)"
></app-board-general-tools-bar>
<app-search-replace
  *ngIf="searchActive$ | async"
  class="search-replace"
  [searchReplaceElements]="searchResults$ | async"
  [currentElementIndex]="activeSearchResultElementIndex"
  (searchHandler)="searchForElements($event)"
  (setActiveElement)="setSearchActiveElement($event)"
  (closePanel)="closeSearchPanel()"
></app-search-replace>
<!-- left bar -->
<app-board-context-bar
  *ngIf="currentBoard?.primaryContext && editorMode === 'EDIT'"
  class="floating-menu"
></app-board-context-bar>
<app-document-widget-tray
  *ngIf="editorMode === 'EDIT'"
  (addImageElementFromFile)="addImageElementFromFile($event)"
  class="floating-menu"
  [class.hidden-component]="(isFullScreen$ | async) && (framePresentation$ | async)"
></app-document-widget-tray>
<!-- bottom bar -->
<app-board-dev-tools></app-board-dev-tools>
<app-board-navigation-bar (zoomIn)="zoomIn()" (zoomOut)="zoomOut()" class="floating-menu"></app-board-navigation-bar>
<app-board-minimap *ngIf="currentBoard && (showMinimap$ | async)"></app-board-minimap>
<!-- right bar -->
<app-side-menu
  *ngIf="currentBoard"
  [class.hidden-component]="(isFullScreen$ | async) && (framePresentation$ | async)"
></app-side-menu>

<!-- element bar -->
<app-property-configurator-bar></app-property-configurator-bar>
<app-board-context-menu *ngIf="currentBoard"></app-board-context-menu>
<!-- element overlay -->
<app-text-hyperlink *ngIf="currentBoard"></app-text-hyperlink>
<document-status-message></document-status-message>

<app-no-connection-overlay></app-no-connection-overlay>

<!-- download loading spinner -->
<app-download-content-snack class="fixed bottom-6 left-6 z-10"></app-download-content-snack>
<app-create-items [show]="editorMode === 'EDIT' && (showPlaceholderCreation$ | async)" class="z-10" />
