import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Vector3 } from '@babylonjs/core/Maths/math';

export class BabylonHelpers {
  // Recursively computes a bounding box that encompasses a mesh and all child meshes
  // Initial min/max should be passed using min = Number.MAX_VALUE, and max = Number.MIN_VALUE
  public static calcBoundForMeshAndChildren(mesh: Mesh, min: Vector3, max: Vector3): void {
    // Compute mesh bounding box
    mesh.computeWorldMatrix(true);
    const bi = mesh.getBoundingInfo();
    const localMin = bi.boundingBox.minimumWorld;
    const localMax = bi.boundingBox.maximumWorld;

    // localMin == localMax means there's no bounding box
    if (!localMin.equals(localMax)) {
      // Expand our min/max by the local bounding box
      if (localMin.x < min.x) {
        min.x = localMin.x;
      }
      if (localMin.y < min.y) {
        min.y = localMin.y;
      }
      if (localMin.z < min.z) {
        min.z = localMin.z;
      }

      if (localMax.x > max.x) {
        max.x = localMax.x;
      }
      if (localMax.y > max.y) {
        max.y = localMax.y;
      }
      if (localMax.z > max.z) {
        max.z = localMax.z;
      }
    }

    // Repeat for all child meshes
    const children = mesh.getChildMeshes();

    for (let i = 0; i < children.length; i++) {
      BabylonHelpers.calcBoundForMeshAndChildren(children[i] as Mesh, min, max);
    }
  }
}
