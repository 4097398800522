<div>
  <!-- Header -->
  <div class="flex justify-between items-center">
    <app-comment-color-chip [comment]="comment"></app-comment-color-chip>
    <div
      class="text-xs tracking-wide text-black54 flex-1 truncate"
      *ngIf="comment.ownedBy?.name && comment.ownedByReference !== comment.contextReference"
    >
      {{ ownedByName }}
    </div>
    <div class="flex items-center" *ngIf="(canUpdate || canDelete) && !editingComment">
      <mat-icon
        *ngIf="allowApproveComment"
        title="Approve"
        class="cursor-pointer text-success-dark"
        (click)="approveComment()"
        data-test="approve-comment"
        >done</mat-icon
      >
      <mat-icon
        *ngIf="allowRejectComment"
        title="Reject"
        class="cursor-pointer text-error-light"
        (click)="rejectComment()"
        >close</mat-icon
      >

      <div *ngIf="markedAsResolved" class="text-xs tracking-wide text-black54 italic" data-test="resolved-status">
        Resolved
      </div>
      <mat-icon class="!w-2.5 cursor-pointer mr-2.5" [matMenuTriggerFor]="contextMenu" data-test="comment-more-options"
        >more_vert</mat-icon
      >
    </div>
  </div>

  <!-- Comment Body / Content -->
  <div
    (click)="handleClick($event)"
    [ngClass]="{ 'cursor-pointer': !this.disableShowComment && this.comment.status !== 'closed' }"
    class="mx-1.5 mt-1.5"
  >
    <app-comment-user-header [user]="comment.createdBy" [date]="comment.createdOn"></app-comment-user-header>

    <app-comment-suggested-changes-display
      [suggestedChanges]="comment.changeSuggestion"
      [typeProperty]="changeSuggestionSlugProperty"
    ></app-comment-suggested-changes-display>

    <div
      class="text-sm text-black60 whitespace-pre-wrap p-1 mt-1"
      style="overflow-wrap: anywhere"
      *ngIf="!editingComment"
      [innerHTML]="formattedText | safe: 'html'"
    ></div>
  </div>

  <div class="new-comment" *ngIf="editingComment">
    <app-comment-form
      data-test="update-comment-form"
      [ownerInfo]="ownerInfo"
      [comment]="comment"
      (emitCancelUpdate)="cancelUpdate()"
    ></app-comment-form>
  </div>

  <div class="py-2" *ngIf="deletingComment">
    <div class="py-2">Are you sure you want to delete this comment?</div>
    <div class="flex gap-2">
      <button mat-stroked-button color="basic" type="button" class="cancel" (click)="deletingComment = false">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="delete()">Delete</button>
    </div>
  </div>

  <mat-menu #contextMenu="matMenu" xPosition="before" data-test="comment-action-menu">
    <ng-template matMenuContent let-target="target">
      <button data-test="menu-reopen" *ngIf="canUpdate && markedAsResolved" mat-menu-item (click)="reopen()">
        Re-open
      </button>
      <button data-test="menu-update" *ngIf="canUpdate" mat-menu-item (click)="updateComment()">Update</button>
      <button data-test="menu-delete" *ngIf="canDelete" mat-menu-item (click)="startDelete()">Delete</button>
    </ng-template>
  </mat-menu>
</div>
