<div class="search-replace">
  <div class="form-field">
    <input
      data-test="app-search-replace-input"
      [placeholder]="inputPlaceholder"
      matInput
      #inputField
      [formControl]="searchInput"
    />
    <div class="cursor-pointer flex mx-1" (click)="close()" data-test="app-search-replace-close-button">
      <mat-icon>clear</mat-icon>
    </div>
  </div>
  <div class="result-count">
    <div class="flex items-center">
      <span data-test="app-search-replace-count-detail" *ngIf="searchInput.value">
        {{ currentElementIndex + 1 }} of {{ searchReplaceElements.length }}
      </span>
      <div class="min-w-[40px]">
        <ng-container *ngIf="searchReplaceElements.length > 0">
          <button mat-button (click)="previousElement()" data-test="app-search-replace-previous-button">
            <mat-icon>expand_less</mat-icon>
          </button>
          <button mat-button (click)="nextElement()" data-test="app-search-replace-next-button">
            <mat-icon>expand_more</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
