<div class="w-full h-[534px] flex">
  <div class="w-[310px]">
    <div>
      <mat-form-field class="mr-3 min-w-[300px]">
        <input
          id="template-name-input"
          #templateNameInput
          type="text"
          placeholder="Template Name"
          matInput
          [formControl]="templateNameControl"
        />
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-radio-group class="flex flex-col" [formControl]="templateTypeControl">
        <mat-radio-button value="private">
          <div class="flex">
            <mat-icon class="mx-[5px] text-[20px]">person</mat-icon>
            <span class="font-medium">Private</span>
          </div>
        </mat-radio-button>
        <mat-radio-button class="mt-1" [disabled]="!isOrgAdmin" value="admin">
          <div class="flex">
            <mat-icon class="mx-[5px] text-[20px]">group</mat-icon>
            <span class="font-medium">Organization</span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="lineboard-toggle">
      <mat-slide-toggle
        id="save-as-frame-template-lineboard-toggle"
        [formControl]="lineboardOptionControl"
        class="justify-between"
        color="primary"
        labelPosition="before"
      >
        <div>Enabled for Lineboard</div>
      </mat-slide-toggle>
    </div>
    <div class="w-[98%] text-[12px] pl-1 mt-1" *ngIf="lineboardOptionControl.value">
      Adjust the grid space on your template to indicate the area that will contain items when generating a Lineboard.
    </div>
  </div>
  <div class="flex w-full justify-center mt-9">
    <app-frame-template-canvas
      #frameTemplateEditor
      *ngIf="document"
      [document]="document"
      [ownerReference]="ownerReference"
      [authContext]="authContext"
      (gridSpaceDefinitionSet)="setGridSpaceDefinition($event)"
    ></app-frame-template-canvas>
  </div>
</div>

<div class="gap-2 flex justify-end items-end w-full" *ngIf="isEditAllowed">
  <button mat-stroked-button type="button" id="save-as-frame-template-cancel-button" (click)="cancel()">CANCEL</button>
  <button
    class="h-[36px]"
    mat-flat-button
    color="primary"
    id="save-as-frame-template-submit-button"
    (click)="submit()"
    [disabled]="!isValid() || loading"
  >
    <div *ngIf="!loading">SAVE</div>
    <div *ngIf="loading" class="w-[40px] flex justify-center text-white">
      <mat-spinner diameter="20"> </mat-spinner>
    </div>
  </button>
</div>
