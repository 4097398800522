import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { FilterDetailsComponent } from './filter-panel/filter-details/filter-details.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TypesModule } from 'src/app/common/types/types.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [FilterPanelComponent, FilterDetailsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    MatFormFieldModule,
    MatSelectModule,
    TypesModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSlideToggleModule,
    DirectivesModule,
  ],
  exports: [FilterPanelComponent, FilterDetailsComponent],
})
export class FilterModule {}
