import * as ClipboardActions from './clipboard.actions';
import * as ClipboardSelectors from './clipboard.selectors';
import * as ClipboardStoreState from './clipboard.state';
import * as ClipboardItemsActions from './clipboard-items/clipboard-items.actions';
import * as ClipboardItemsSelectors from './clipboard-items/clipboard-items.selectors';
export { ClipboardStoreModule } from './clipboard-store.module';

const actions = {
  ...ClipboardActions,
  ...ClipboardItemsActions,
};

const selectors = {
  ...ClipboardSelectors,
  ...ClipboardItemsSelectors,
};

export { actions as ClipboardActions, selectors as ClipboardSelectors, ClipboardStoreState };
