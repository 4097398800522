import { PositionDefinition, ViewBox } from '@contrail/documents';
import { CanvasDocument } from '../../../../canvas-document';
import { CanvasElement } from '../../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { Frame } from '../../../../state/canvas-frame-state';
import { DistanceGuidelineHandler } from './distance-guideline-handler';
import { GuidelineCoordinate, GuidelineHandlerService } from './guideline-handler.service';

export class GuidelineHandler {
  public distanceGuidelineHandler: DistanceGuidelineHandler;
  private guidelineService: GuidelineHandlerService;
  private selectedElements: CanvasElement[];
  private selectedElementsByFrame: CanvasElement[];
  private otherElementCoordinatesX: GuidelineCoordinate[] = [];
  private otherElementCoordinatesY: GuidelineCoordinate[] = [];
  private snapPosition: any;
  private documentSize: ViewBox;
  private frames: Map<string, Frame>;
  private deltaPositions: Map<string, PositionDefinition> = new Map();
  public hasSnapped = false;
  private readonly supportedCombinations = [
    'top:top',
    'middle:middle',
    'bottom:bottom',
    'top:bottom',
    'bottom:top',
    'left:left',
    'left:right',
    'right:left',
    'right:right',
    'center:center',
  ];

  constructor(private canvasDocument: CanvasDocument) {
    this.guidelineService = new GuidelineHandlerService(this.canvasDocument);
    this.distanceGuidelineHandler = new DistanceGuidelineHandler(this.canvasDocument);
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect()) return;

    this.distanceGuidelineHandler.dragstarted(event, elementTarget);

    const { element, target } = elementTarget;
    if (element && [DRAG_DIRECTIONS.BODY, DRAG_DIRECTIONS.EDIT].indexOf(target) !== -1) {
      this.documentSize = this.canvasDocument.canvasDisplay.documentViewBox;
      this.otherElementCoordinatesX = [
        { id: 'viewport', position: 'left', x: this.documentSize.x, y: this.documentSize.y },
        { id: 'viewport', position: 'right', x: this.documentSize.x + this.documentSize.width, y: this.documentSize.y },
        {
          id: 'viewport',
          position: 'center',
          x: this.documentSize.x + this.documentSize.width * 0.5,
          y: this.documentSize.y,
        },
      ];
      this.otherElementCoordinatesY = [
        { id: 'viewport', position: 'top', x: this.documentSize.x, y: this.documentSize.y },
        {
          id: 'viewport',
          position: 'bottom',
          x: this.documentSize.x,
          y: this.documentSize.y + this.documentSize.height,
        },
        {
          id: 'viewport',
          position: 'middle',
          x: this.documentSize.x,
          y: this.documentSize.y + this.documentSize.height * 0.5,
        },
      ];

      const selectedElements = this.canvasDocument.interactionHandler?.selectionHandler?.getSelectedElementsForUpdate();
      if (selectedElements?.length > 0) {
        this.selectedElements = selectedElements;
        this.selectedElementsByFrame = this.canvasDocument.interactionHandler?.selectionHandler
          ?.getSelectedElementsByFrame()
          ?.filter((e) => !e.elementDefinition.isLocked);
        this.frames = this.canvasDocument.state.frameState.frames;
        this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines = [];
        const { otherElementCoordinatesX, otherElementCoordinatesY } =
          this.guidelineService.extractOtherElementCoordinates(this.selectedElements);
        this.otherElementCoordinatesX = this.otherElementCoordinatesX.concat(otherElementCoordinatesX);
        this.otherElementCoordinatesY = this.otherElementCoordinatesY.concat(otherElementCoordinatesY);
        const box = this.getBox();
        this.selectedElements?.forEach((element) => {
          const position = element.getPosition();
          this.deltaPositions.set(element.elementDefinition.id, {
            x: position.x - box.x - element.PADDING_LEFT,
            y: position.y - box.y - element.PADDING_TOP,
          });
        });
      }
    }
  }

  public dragged(event) {
    this.distanceGuidelineHandler.dragged(event);
    if (!this.selectedElements?.length) return;
    this.hasSnapped = false;
    this.addGuidelines();
  }

  private addGuidelines() {
    this.snapPosition = null;
    this.addGuidelineByDirection('x');
    this.addGuidelineByDirection('y');
  }

  private getBox() {
    return this.selectedElementsByFrame.length === 1
      ? this.selectedElementsByFrame[0].getBoundingClientRect({ outerEdgeDimensions: true })
      : this.canvasDocument.state.getCommonBounds(this.selectedElementsByFrame, { outerEdgeDimensions: true });
  }

  private getBoxRotated() {
    return this.selectedElementsByFrame.length === 1
      ? this.selectedElementsByFrame[0].getBoundingClientRectRotated({ outerEdgeDimensions: true })
      : this.canvasDocument.state.getCommonBounds(this.selectedElementsByFrame, { outerEdgeDimensions: true });
  }

  private addGuidelineByDirection(direction) {
    const elementCoordinates = this.guidelineService.getBoxCoordinates(
      this.selectedElements[0].elementDefinition.id,
      this.getBoxRotated(),
      direction,
    );
    const elementDimensions = this.getBox();

    // We check either x coords or y coords for the other elements based on the direction being passed.
    const otherElementCoordinates = direction === 'x' ? this.otherElementCoordinatesX : this.otherElementCoordinatesY;

    // Outer loop is for each of top/bottom/middle or right/left/center for the element being dragged.
    for (let i = 0; i < elementCoordinates?.length; i++) {
      for (let j = 0; j < otherElementCoordinates?.length; j++) {
        this.drawOrRemoveGuidelinesBetweenElements(
          elementDimensions,
          elementCoordinates[i],
          otherElementCoordinates[j],
          direction,
        );
      }
    }
  }

  private drawOrRemoveGuidelinesBetweenElements(
    draggingElementDimensions,
    draggingElementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
    direction: 'x' | 'y',
  ) {
    const currentCombination = `${draggingElementCoordinates.position}:${otherElementCoordinates.position}`;
    if (!this.supportedCombinations.includes(currentCombination)) {
      return;
    }

    const id = this.guidelineService.generateId('line', draggingElementCoordinates, otherElementCoordinates, null);
    if (!this.guidelineService.isInRange(otherElementCoordinates, draggingElementCoordinates, direction)) {
      this.removeGuideline(id);
      return;
    }

    this.snapPosition = this.guidelineService.calculateSnapPosition(
      this.selectedElements?.length === 1 ? this.selectedElements[0].elementDefinition?.rotate?.angle : null,
      draggingElementDimensions,
      draggingElementCoordinates,
      otherElementCoordinates,
      direction,
    );

    if (this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.findIndex((g) => g.id === id) === -1) {
      this.snapToPosition(this.snapPosition);
      this.drawGuideline(id, direction, draggingElementCoordinates, otherElementCoordinates);
    } else {
      this.snapToPosition(this.snapPosition);
    }

    // Remove all other guidelines on the same position as guideline with id
    this.removeGuidelineByPosition(id, [draggingElementCoordinates.position, otherElementCoordinates.position]);
  }

  private removeGuideline(id: string) {
    const index = this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.findIndex((g) => g.id === id);
    if (index > -1) {
      this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.splice(index, 1);
    }
  }

  /**
   * Remove guidelines by @positions except for guideline with @id
   * @param id
   * @param positions
   */
  private removeGuidelineByPosition(id: string, positions: string[]) {
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.forEach((guideline) => {
      positions.forEach((position) => {
        if (
          guideline.id !== id &&
          (guideline.id.includes(position) ||
            (position === 'top' && guideline.id.includes('middle')) ||
            (position === 'left' && guideline.id.includes('center')))
        ) {
          this.removeGuideline(guideline.id);
        }
      });
    });
  }

  private drawGuideline(
    id: string,
    direction: string,
    elementCoordinates: GuidelineCoordinate,
    otherElementCoordinates: GuidelineCoordinate,
  ) {
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines.push({
      id,
      lineDefinition: this.guidelineService.calculateGuidelineDefinition(
        id,
        direction,
        elementCoordinates,
        otherElementCoordinates,
        this.documentSize,
      ),
    });
  }

  public snapToPosition(snapPosition) {
    this.hasSnapped = true;
    this.guidelineService.setPositions(snapPosition, this.selectedElements, this.deltaPositions, this.frames);
  }

  public dragended(event) {
    this.distanceGuidelineHandler.dragended(event);
    if (!this.selectedElements?.length) return;
    this.clear();
  }

  private clear() {
    this.selectedElements = [];
    this.selectedElementsByFrame = [];
    this.frames = null;
    this.deltaPositions = new Map();
    this.otherElementCoordinatesX = [];
    this.otherElementCoordinatesY = [];
    this.canvasDocument.canvasRenderer.guidelinesRenderer.guidelines = [];
    this.snapPosition = null;
    this.hasSnapped = false;
  }
}
