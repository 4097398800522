import { State } from './boards.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as BoardsActions from './boards.actions';
import { boardsEntityAdapter } from './boards.state';
import { boardsInitialState } from './boards.state';
import { RemoteMouseTracker } from '../board/board-mouse-tracker/board-mouse-tracker.component';
import { collectionStatusMessagesReducers } from './collection-status-messages/collection-status-messages.reducers';

const setData = (state: State, { data }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.setAll(data, state.boards),
    boardsLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    boards: boardsEntityAdapter.removeAll(state.boards),
    boardsLoaded: false,
    itemsUsedInBoard: [],
  };
};
const add = (state: State, { board }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.addOne(board, state.boards),
  };
};
const update = (state: State, { id, changes }) => {
  let currentBoard = state.currentBoard;
  if (state.currentBoard?.id === id) {
    currentBoard = { ...state.currentBoard, ...changes };
  }
  return {
    ...state,
    boards: boardsEntityAdapter.updateOne({ id, changes }, state.boards),
    currentBoard,
  };
};
const remove = (state: State, { board }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.removeOne(board.id, state.boards),
  };
};
const setCurrentBoard = (state: State, { board }) => {
  return {
    ...state,
    currentBoard: board,
  };
};

const remoteUserMouseMoved = (state: State, { data }) => {
  const remoteMouseData: RemoteMouseTracker = {
    x: data.event.mousePosition.x,
    y: data.event.mousePosition.y,
    clientId: data.context.user.clientId,
    firstName: data.context.user.firstName,
    lastName: data.context.user.lastName,
    email: data.context.user.email,
  };
  const remoteMouseObj: { string?: RemoteMouseTracker } = {};
  remoteMouseObj[data.context.user.clientId] = { ...remoteMouseData };
  const remoteUserMouseTracker = { ...state.remoteUserMouseTracker, ...remoteMouseObj };
  return {
    ...state,
    remoteUserMouseTracker,
  };
};

const removeRemoteUserFromMouseSession = (state: State, { userSession }) => {
  const clientId = userSession.user.clientId;
  const { [clientId]: value, ...remoteUserMouseTrackerFiltered } = state.remoteUserMouseTracker;
  return {
    ...state,
    remoteUserMouseTracker: remoteUserMouseTrackerFiltered,
  };
};

const setItemsUsedInBoard = (state: State, { itemsUsedInBoard }) => {
  //console.log(`itemsUsedInBoard ${JSON.stringify(itemsUsedInBoard)}`);

  return {
    ...state,
    itemsUsedInBoard,
  };
};

const clearItemsUsedInBoard = (state: State) => {
  return {
    ...state,
    itemsUsedInBoard: null,
  };
};

const setShowSourceAssortmentWarning = (state: State, { showSourceAssortmentWarning }) => {
  localStorage.setItem('_showSourceAssortmentWarnings', JSON.stringify(showSourceAssortmentWarning));
  return {
    ...state,
    showSourceAssortmentWarning,
  };
};

const setCurrentDocumentGenerationConfig = (state: State, { documentGenerationConfig }) => {
  return {
    ...state,
    currentDocumentGenerationConfig: documentGenerationConfig,
  };
};

const clearCurrentDocumentGenerationConfig = (state: State) => {
  return {
    ...state,
    currentDocumentGenerationConfig: null,
  };
};

export const boardsReducers = createReducer(
  boardsInitialState,
  ...collectionStatusMessagesReducers,
  on(BoardsActions.loadBoardsSuccess, setData),
  on(BoardsActions.clearBoards, clearData),
  on(BoardsActions.createBoardSuccess, add),
  on(BoardsActions.copyBoardSuccess, add),
  on(BoardsActions.deleteBoardSuccess, remove),
  on(BoardsActions.updateBoardSuccess, update),
  on(BoardsActions.loadCurrentBoardSuccess, setCurrentBoard),
  on(BoardsActions.remoteUserMouseMoved, remoteUserMouseMoved),
  on(BoardsActions.removeRemoteUserFromMouseSession, removeRemoteUserFromMouseSession),
  on(BoardsActions.setItemsUsedInBoard, setItemsUsedInBoard),
  on(BoardsActions.clearItemsUsedInBoard, clearItemsUsedInBoard),
  on(BoardsActions.setShowSourceAssortmentWarning, setShowSourceAssortmentWarning),
  on(BoardsActions.loadCurrentDocumentGenerationConfigSuccess, setCurrentDocumentGenerationConfig),
  on(BoardsActions.clearCurrentDocumentGenerationConfig, clearCurrentDocumentGenerationConfig),
  on(BoardsActions.updateCurrentDocumentGenerationConfigSuccess, setCurrentDocumentGenerationConfig),
);

export function reducer(state: State | undefined, action: Action): any {
  return boardsReducers(state, action);
}
