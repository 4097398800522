import { Component, Input, OnInit } from '@angular/core';
import { ImageElementCache } from '../../canvas/cache/image-element-cache';
import { Observable, take, tap } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { DocumentElement } from '@contrail/documents';
import { DocumentActions, DocumentSelectors } from '../../document/document-store';
import { DocumentService } from '../../document/document.service';
import { info } from 'console';
import { Entities, EntityReference } from '@contrail/sdk';

interface ImageElementInformation {
  element: DocumentElement;
  bindingType?: 'file' | 'content' | 'viewable';
  sourceFileSize?: number;
  thumbnailUrl?: string;
  primaryFileUrl?: string;
  fileReference?: string;
  contentReference?: string;
  viewableReference?: string;
  primaryReference?: string;
}

@Component({
  selector: 'app-dev-tools-images-panel',
  templateUrl: './dev-tools-images-panel.component.html',
  styleUrls: ['./dev-tools-images-panel.component.scss'],
})
export class DevToolsImagesPanelComponent implements OnInit {
  public cacheMetrics;
  public imageElements;
  public componentElements;
  public svgElements;
  public imageInfo: Array<ImageElementInformation>;
  constructor(
    public store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.cacheMetrics = this.documentService.getImageCacheMetrics();
    this.initElementAnalysis();
  }

  private async initElementAnalysis() {
    let elements: Array<DocumentElement>;
    this.documentService.documentElements
      .pipe(
        take(1),
        tap((e) => (elements = e)),
      )
      .subscribe();

    this.imageElements = elements.filter((e) => e.type === 'image');
    this.svgElements = elements.filter((e) => e.type === 'svg');
    this.componentElements = elements.filter((e) => e.type === 'component');

    const allImageElements = [...this.imageElements, ...this.svgElements, ...this.componentElements];
    this.imageInfo = await this.collectImageInfo(allImageElements);
  }

  private async collectImageInfo(elements: Array<DocumentElement>): Promise<Array<ImageElementInformation>> {
    let infoArray: Array<ImageElementInformation> = [];
    const entityReferences = [];
    for (let element of elements) {
      const imageInfo: ImageElementInformation = { element };
      console.log('collectImageInfo: modelBindings ', element.modelBindings);
      if (element.modelBindings?.content) {
        imageInfo.bindingType = 'content';
        imageInfo.contentReference = element.modelBindings.content;
      } else if (element.modelBindings?.image?.indexOf('file') > -1) {
        imageInfo.bindingType = 'file';
        imageInfo.fileReference = element.modelBindings.image;
      } else if (element.modelBindings?.viewable) {
        // imageInfo.bindingType = 'content';
        // imageInfo.viewableReference = element.modelBindings.viewable;
      }
      imageInfo.primaryReference = imageInfo.fileReference || imageInfo.contentReference || imageInfo.viewableReference;
      if (imageInfo.primaryReference) {
        infoArray.push(imageInfo);
        if (imageInfo.primaryReference.indexOf('file') > -1 || imageInfo.primaryReference.indexOf('content') > -1) {
          entityReferences.push(new EntityReference(imageInfo.primaryReference));
        }
      }
    }
    const entityMap: Map<string, any> = await new Entities().getAllReferences(entityReferences, []);
    console.log('entityMap: ', entityMap);
    for (let info of infoArray) {
      const entity = entityMap.get(info.primaryReference);
      if (!entity) {
        continue;
      }
      info.sourceFileSize = entity.size || entity.fileSize;
      info.thumbnailUrl = entity.fileUrl || entity.mediumLargeViewableUrl;
      info.primaryFileUrl = entity.primaryFileUrl || entity.fileUrl;
    }

    console.log('infoArray: ', infoArray);
    infoArray = infoArray.sort((i1, i2) => (i1.sourceFileSize > i2.sourceFileSize ? -1 : 1));
    return infoArray;
  }

  goToElement(imageInfo: ImageElementInformation) {
    const documentElement = imageInfo.element;
    this.store.dispatch(
      DocumentActions.navigateToPosition({
        position: {
          x: (documentElement ? documentElement.position.x : 0) + 180,
          y: documentElement ? documentElement.position.y : 0,
        },
      }),
    );
  }
}
