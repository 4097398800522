import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { featureFlagsReducers } from './feature-flags.reducers';
import { FeatureFlagsEffects } from './feature-flags.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('featureFlags', featureFlagsReducers),
    EffectsModule.forFeature([FeatureFlagsEffects]),
  ],
})
export class FeatureFlagsStoreModule {}
