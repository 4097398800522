<button
  *ngIf="types.length === 1"
  mat-button
  mat-raised-button
  class="w-full"
  color="primary"
  [matMenuTriggerFor]="exportMenu"
  [disabled]="downloading$ | async"
  [attr.data-test]="'export-menu-button'"
>
  <mat-icon class="mr-2">download</mat-icon><span>{{ label }}</span>
</button>

<mat-menu #exportMenu="matMenu" class="menu-md">
  <button
    mat-menu-item
    (click)="initDownload(types[0], false)"
    [disabled]="!selectedIds?.length"
    [attr.data-test]="'export-selected-button-' + types[0]"
  >
    Selected frames
  </button>
  <button mat-menu-item (click)="initDownload(types[0])" [attr.data-test]="'export-all-button-' + types[0]">
    All frames
  </button>
</mat-menu>
