import { Injectable } from '@angular/core';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { BoardsSelectors } from '../../boards-store';
import { Board } from '../board.service';
import { DocumentManagerSelectors } from '../document-manager/document-manager-store';
import { DocumentComponentService } from '../document/document-component/document-component-service';
import { DocumentService } from '../document/document.service';
import { ComponentEditorService } from '../document/component-editor/component-editor.service';
import { DocumentItemService } from '../document/document-item/document-item.service';
import { ItemService } from '@common/items/item.service';
import { ProjectItemService } from '@common/projects/project-item.service';
import { Entities, Types } from '@contrail/sdk';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class BoardItemService {
  private board: Board;
  private documentElements: DocumentElement[];
  backingAssortmentItemData: any[];
  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private documentComponentService: DocumentComponentService,
    private componentEditorService: ComponentEditorService,
    private documentItemService: DocumentItemService,
    private projectItemService: ProjectItemService,
    private itemService: ItemService,
    private confirmationBoxService: ConfirmationBoxService,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(BoardsSelectors.currentBoard).subscribe((board) => {
      if (!board) {
        return;
      }
      this.board = board;
    });
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      this.backingAssortmentItemData = backingAssortmentItemData;
    });
    this.store.select(DocumentManagerSelectors.selectDocumentElements).subscribe((documentElements) => {
      this.documentElements = documentElements;
    });
  }

  public async promoteItems() {
    this.store.dispatch(setLoading({ loading: true }));
    const selectedElements = ObjectUtil.cloneDeep(this.documentService.getSelectedElements());
    this.documentService.deselectAllElements();
    await this.documentComponentService.promoteItems(selectedElements);
    this.store.dispatch(setLoading({ loading: false }));
  }

  public async syncElements(data: any) {
    this.store.dispatch(setLoading({ loading: true }));
    // get all frames in the showcase to find affected component elements
    const componentElements = this.documentElements.filter((element) => element.type === 'component');
    let elementsToSync: DocumentElement[] = [];
    let entities: any[]; // item option or item family entity that is in data

    if (data.object.id === data.object.itemFamilyId) {
      // update happened on an item family
      // find all item/options that are affected by changes to item family.
      const filteredBackingAssortmentItems = this.backingAssortmentItemData.filter(
        (backingAssortmentItem) => backingAssortmentItem.item.itemFamilyId === data.object.id,
      );
      entities = ObjectUtil.cloneDeep(filteredBackingAssortmentItems).map((assortmentItem) => {
        if (assortmentItem.id === data.object.id) {
          // item family
          return Object.assign(assortmentItem.item, data.changes);
        } else {
          // do not update option images if family image was updated
          const changes = { ...data.changes };
          const filteredChanges = Object.fromEntries(
            Object.entries(changes).filter(
              ([key]) =>
                [
                  'contentType',
                  'fileName',
                  'largeViewableDownloadUrl',
                  'mediumLargeViewableDownloadUrl',
                  'mediumViewableDownloadUrl',
                  'primaryFileUrl',
                  'primaryViewableId',
                  'smallViewableDownloadUrl',
                  'tinyViewableDownloadUrl',
                ].indexOf(key) === -1,
            ),
          );
          return Object.assign(assortmentItem.item, filteredChanges);
        }
      });
      elementsToSync = componentElements.filter(
        (element) => entities.findIndex((entity) => element.modelBindings.item === `item:${entity.id}`) > -1,
      );
    } else {
      // update happened on an item option
      entities = [data.object];
      elementsToSync = componentElements.filter((element) => element.modelBindings.item === `item:${data.object.id}`);
    }
    // update items that in the backingAssortment
    this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: data.object.id, changes: data.changes }));
    entities = entities.concat(await this.documentComponentService.fetchEntitiesExceptFor(elementsToSync, entities));
    await this.documentComponentService.updateValuesForComponentElements(
      this.board.document,
      ObjectUtil.cloneDeep(elementsToSync),
      entities,
    );
    this.store.dispatch(setLoading({ loading: false }));
  }

  async addItemsToProject() {
    let currentProject;
    const selectedElements = this.documentService.getSelectedElements();
    const projectItemsToCarryover = [];
    const selectedComponentElements = selectedElements.filter((e) => e.type === 'component');
    const elementsForChange = selectedComponentElements.filter(
      (e) => !e.modelBindings.projectItem?.includes(this.projectItemService.currentProjectId),
    );
    if (elementsForChange.length === 0) {
      this.snackBar.open('All selected items are already in the current project.', '', { duration: 4000 });
      return;
    }
    let itemIds = elementsForChange.map((e) => e.modelBindings.item.split('item:')[1]);
    itemIds = [...new Set(itemIds)];

    let existingProjectItemIds = elementsForChange
      .filter((e) => e.modelBindings.projectItem)
      .map((e) => e.modelBindings.projectItem.split('project-item:')[1]);
    existingProjectItemIds = [...new Set(existingProjectItemIds)];
    let existingProjectItems = [];
    if (existingProjectItemIds.length > 0) {
      existingProjectItems = await this.projectItemService.getByIds(existingProjectItemIds, ['project']);
    }
    const projectItemsInCurrentProject = (await this.projectItemService.getCurrentProjectItems(itemIds)).filter(
      (p) => !p.isInactive,
    );
    if (projectItemsInCurrentProject?.length > 0) {
      const projectItemWithCurrentProject = projectItemsInCurrentProject.find(
        (p) => p.projectId === this.projectItemService.currentProjectId,
      );
      if (projectItemWithCurrentProject) {
        currentProject = projectItemWithCurrentProject.project;
      }
    }
    const itemIdsNotInAnyProject = itemIds.filter((itemId) => !existingProjectItems.find((p) => p.itemId === itemId));
    const itemIdsNotInCurrentProject = itemIds.filter(
      (itemId) => !projectItemsInCurrentProject.find((p) => p.itemId === itemId),
    );
    if (itemIdsNotInAnyProject.length > 0) {
      const itemsNotInAnyProject = await this.itemService.getItems(itemIdsNotInAnyProject);
      // Look for inactive project items for reactivation
      const inactiveProjectItems = (
        await this.projectItemService.getCurrentProjectItems(itemIdsNotInAnyProject)
      ).filter((p) => p.isInactive);
      itemsNotInAnyProject.forEach((item) => {
        const pi: any = {
          itemId: item.id,
          roles: item.roles,
        };
        if (inactiveProjectItems.find((p) => p.itemId === item.id)) {
          pi.isInactive = false;
        }
        projectItemsToCarryover.push(pi);
      });
    }

    itemIdsNotInCurrentProject.forEach((itemId) => {
      const existingProjectItem = existingProjectItems.find((p) => p.itemId === itemId);
      if (existingProjectItem) {
        projectItemsToCarryover.push(existingProjectItem);
      }
    });
    if (!currentProject) {
      currentProject = await new Entities().get({
        entityName: 'project',
        id: this.projectItemService.currentProjectId,
      });
    }
    let confirm = true;
    if (selectedComponentElements.length > 1) {
      if (projectItemsToCarryover.length > 0) {
        confirm = await this.confirmationBoxService.open(
          'Switch / add to project',
          'This will switch the context to the current project for all selected items. ' +
            projectItemsToCarryover.length +
            ' items will be added to ' +
            currentProject.name +
            '. Are you sure you want to proceed?',
          'Cancel',
          'OK',
          true,
        );
      } else {
        confirm = await this.confirmationBoxService.open(
          'Switch / add to project',
          'This will switch the context to the current project for all selected items. Are you sure you want to proceed?',
          'Cancel',
          'OK',
          true,
        );
      }
    }
    if (confirm) {
      if (projectItemsToCarryover.length > 0) {
        await this.projectItemService.carryoverProjectItems(projectItemsToCarryover);
      }
      const changes = [];
      for (let i = 0; i < elementsForChange.length; i++) {
        const documentElement = ObjectUtil.cloneDeep(elementsForChange[i]);
        documentElement.modelBindings.projectItem = `project-item:${this.projectItemService.currentProjectId}:${documentElement.modelBindings.item.split('item:')[1]}`;
        if (documentElement.modelBindings.assortment) {
          documentElement.modelBindings.assortment = null;
        }
        if (documentElement.modelBindings.assortmentItem) {
          documentElement.modelBindings.assortmentItem = null;
        }
        const bindingChanges = documentElement.modelBindings;
        changes.push({ documentElement: documentElement, bindingChanges });
      }
      this.componentEditorService.batchUpdateComponentElements(changes);
      return true;
    } else {
      return false;
    }
  }
}
