import { Injectable } from '@angular/core';
import { Intercom } from './ng-intercom';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AnalyticsEvent, EventService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService implements EventService {
  constructor(
    private intercom: Intercom,
    private authService: AuthService,
  ) {
    this.init();
  }

  public async init() {
    this.intercom.boot({
      app_id: environment.intercomAppId,
      email: (await this.authService.getCurrentUser()).email,
      user_id: (await this.authService.getCurrentUser()).id,
      name: (await this.authService.getCurrentUser()).firstName,
      // Supports all optional configuration.
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20,
      widget: {
        activator: '#intercom',
      },
    });
    this.setActiveUser();
  }

  public async setActiveUser() {
    const user = await this.authService.getCurrentUser();
    this.intercom.update({
      email: user.email,
      user_id: user.id,
      name: user.firstName,
    });
  }

  public async hide() {
    console.log('intercom hide');
    this.intercom.update({
      hide_default_launcher: true,
    });
  }

  public emitEvent(event: AnalyticsEvent) {
    // IMPLEMENT LATER
  }
}
