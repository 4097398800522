import { DocumentElement, DocumentElementFactory, PositionDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasElementFactory } from '../../../elements/canvas-element-factory';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { ElementCopyDragHandler } from './element-copy-drag-handler';
import { ElementHighlightHandler } from './element-highlight-handler';
import { FrameDragHandler } from './frame-drag-handler';
import { GuidelineHandler } from './guideline-handler/drag-guideline-handler';

export class ElementDragHandler {
  private isAltKey = false;

  private startingPosition: PositionDefinition;
  private selectedElement: CanvasElement;
  private selectedElements: CanvasElement[] = [];
  private startingElements: Map<string, DocumentElement> = new Map();

  private elementCopyDragHandler: ElementCopyDragHandler;
  private elementHighlightHandler: ElementHighlightHandler;
  private frameDragHandler: FrameDragHandler;
  private guidelineHandler: GuidelineHandler;

  private keepPosition = { x: false, y: false };

  constructor(private canvasDocument: CanvasDocument) {
    this.frameDragHandler = new FrameDragHandler(canvasDocument);
  }

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();
    if (
      (!this.canvasDocument.interactionHandler.isSelect() &&
        !this.canvasDocument.interactionHandler.isAssignItemSelect()) ||
      (!elementTarget?.element?.isItemComponent() && this.canvasDocument.interactionHandler.isAssignItemSelect()) ||
      (elementTarget?.element?.elementDefinition?.isLocked &&
        !this.canvasDocument.interactionHandler.isAssignItemSelect())
    )
      return;

    const { element, target } = elementTarget;
    if (element && [DRAG_DIRECTIONS.BODY, DRAG_DIRECTIONS.EDIT].indexOf(target) !== -1) {
      // console.log('elementDrag.dragstarted', event, elementTarget)
      if (event.altKey) {
        this.elementCopyDragHandler = new ElementCopyDragHandler(this.canvasDocument);
        this.elementCopyDragHandler.dragstarted(event, elementTarget);
        return;
      }

      const viewBox = this.canvasDocument.getViewBox();
      const scale = this.canvasDocument.getViewScale();
      this.startingPosition = { x: event.clientX + viewBox.x * scale.x, y: event.clientY + viewBox.y * scale.y };
      this.selectedElement = element;
      this.selectedElements = this.canvasDocument.interactionHandler.selectionHandler.getSelectedElementsForUpdate();
      this.selectedElements.map((canvasElement) => {
        this.startingElements.set(canvasElement.id, ObjectUtil.cloneDeep(canvasElement.elementDefinition));
      });

      if (!this.isAltKey) {
        this.elementHighlightHandler = new ElementHighlightHandler(this.canvasDocument);
        this.elementHighlightHandler.dragstarted(event, elementTarget);
      }

      this.guidelineHandler = new GuidelineHandler(this.canvasDocument);
      this.guidelineHandler.dragstarted(event, elementTarget);

      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: element.elementDefinition,
        selectedElements: this.selectedElements.map((e) => e.elementDefinition),
        eventType: 'dragStarted',
      });
    }
  }

  public dragged(event) {
    if (this.elementCopyDragHandler) {
      this.elementCopyDragHandler.dragged(event);
      return;
    }

    if (this.selectedElements.length === 0) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    if (event.shiftKey) {
      if (Math.abs(distanceX) >= Math.abs(distanceY)) {
        this.keepPosition.y = true;
        this.keepPosition.x = false;
      } else {
        this.keepPosition.x = true;
        this.keepPosition.y = false;
      }
    } else {
      this.keepPosition = { x: false, y: false };
    }

    const hasChanged = this.updateSelectedElementsPosition(distanceX / scale.x, distanceY / scale.y);
    if (hasChanged) {
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: this.selectedElement.elementDefinition,
        eventType: 'dragged',
        relativeMousePosition: { x: event.clientX, y: event.clientY },
      });

      this.elementHighlightHandler?.dragged(event);
      this.guidelineHandler?.dragged(event);

      this.frameDragHandler?.handleElementDragged({
        element: this.selectedElement,
        selectedElements: this.selectedElements,
      });

      this.canvasDocument.draw();
    }
  }

  public dragended(event) {
    if (this.elementCopyDragHandler) {
      this.elementCopyDragHandler.dragended(event);
      return;
    }
    if (this.selectedElements.length === 0) return;
    const viewBox = this.canvasDocument.getViewBox();
    const scale = this.canvasDocument.getViewScale();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX !== 0 || distanceY !== 0) {
      if (this.canvasDocument.canvasRenderer?.highlightBoxRenderer?.highlightElement) {
        this.guidelineHandler?.dragended(event);
        this.elementHighlightHandler?.dragended(event);

        const oldElement = this.startingElements.get(this.selectedElement.id);
        if (this.selectedElement.elementDefinition.type === 'line') {
          this.selectedElement.elementDefinition.lineDefinition = oldElement.lineDefinition;
        } else {
          this.selectedElement.elementDefinition.position = oldElement.position;
        }
        for (let i = 0; i < this.selectedElements?.length; i++) {
          const element = this.selectedElements[i];
          const oldElement = this.startingElements.get(element.id);
          if (element.elementDefinition.type === 'line') {
            element.elementDefinition.lineDefinition = oldElement.lineDefinition;
          } else {
            element.elementDefinition.position = oldElement.position;
          }
        }
      } else {
        // If element was snapped to position do not update position again with distanceX and distanceY
        if (
          this.guidelineHandler?.hasSnapped ||
          this.guidelineHandler?.distanceGuidelineHandler?.hasSnapped ||
          this.updateSelectedElementsPosition(distanceX / scale.x, distanceY / scale.y)
        ) {
          // If selected elements contain frame element, do not update frame element & its members
          if (this.selectedElements.filter((element) => element.elementDefinition.type === 'frame').length === 0) {
            this.frameDragHandler?.handleElementDragEnded({
              element: this.selectedElement,
              selectedElements: this.selectedElements,
            });
          }
          const newElements = this.selectedElements.map((canvasElement: CanvasElement) => {
            if (canvasElement.elementDefinition.type === 'line') {
              return {
                id: canvasElement.id,
                lineDefinition: ObjectUtil.cloneDeep(canvasElement.elementDefinition.lineDefinition),
              };
            } else {
              return {
                id: canvasElement.id,
                position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
              };
            }
          });
          const undoElements = [...this.startingElements.values()].map((element: DocumentElement) => {
            if (element?.type === 'line') {
              return { id: element?.id, lineDefinition: element?.lineDefinition };
            } else {
              return { id: element?.id, position: element?.position };
            }
          });
          this.canvasDocument.actionsDispatcher.handleUndoableChanges(newElements, undoElements);
        }
      }
    }

    this.guidelineHandler?.dragended(event);

    this.canvasDocument.draw();

    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
      element: this.selectedElement.elementDefinition,
      eventType: 'dragEnded',
    });

    this.clear();
  }

  private clear() {
    this.startingElements.clear();
    this.selectedElements = [];
    this.selectedElement = null;
    this.startingPosition = null;
    this.elementHighlightHandler = null;
    this.guidelineHandler = null;
    this.elementCopyDragHandler = null;
    this.keepPosition = { x: false, y: false };
  }

  private updateSelectedElementsPosition(dx, dy): boolean {
    let hasChanged = false;
    for (let i = 0; i < this.selectedElements?.length; i++) {
      const element = this.selectedElements[i];
      const oldElement = this.startingElements.get(element.id);
      if (oldElement) {
        if (element.elementDefinition.type === 'line') {
          if (oldElement.lineDefinition) {
            const lineDefinition = ObjectUtil.cloneDeep(oldElement.lineDefinition);
            (lineDefinition.x1 = oldElement.lineDefinition.x1 + dx),
              (lineDefinition.y1 = oldElement.lineDefinition.y1 + dy),
              (lineDefinition.x2 = oldElement.lineDefinition.x2 + dx),
              (lineDefinition.y2 = oldElement.lineDefinition.y2 + dy);
            if (
              oldElement.lineDefinition.x1 !== lineDefinition.x1 ||
              oldElement.lineDefinition.y1 !== lineDefinition.y1 ||
              oldElement.lineDefinition.x2 !== lineDefinition.x2 ||
              oldElement.lineDefinition.y2 !== lineDefinition.y2
            ) {
              hasChanged = true;
              element.elementDefinition.lineDefinition = lineDefinition;
            }
          }
        } else {
          let { x, y } = {
            x: this.keepPosition?.x ? oldElement.position.x : oldElement.position.x + dx,
            y: this.keepPosition?.y ? oldElement.position.y : oldElement.position.y + dy,
          };
          if (oldElement.position.x !== x || oldElement.position.y !== y) {
            hasChanged = true;
            element.elementDefinition = ObjectUtil.mergeDeep(element.elementDefinition, {
              position: {
                x,
                y,
              },
            });
          }
        }
      }
    }
    return hasChanged;
  }
}
