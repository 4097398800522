import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AuthService } from '@common/auth/auth.service';
import { ViewDefinition } from '@contrail/client-views';

@Component({
  selector: 'app-property-create-view-definition',
  templateUrl: './create-view-definition.component.html',
  styleUrls: ['./create-view-definition.component.scss'],
})
export class PropertyCreateViewDefinitionComponent implements OnInit {
  public nameFormControl = new UntypedFormControl(null, Validators.required);
  public viewTypeFormControl = new UntypedFormControl(null, Validators.required);

  @Input() typeProperties: any[] = [];
  public isAlreadySubmitted = false;
  isOrgAdmin = false;
  title = 'New preset';

  constructor(
    public dialogRef: MatDialogRef<PropertyCreateViewDefinitionComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public label: string,
  ) {}

  ngOnInit(): void {
    this.isOrgAdmin = this.authService.isAdmin();
    if (this.label) {
      this.nameFormControl.setValue(this.label);
      this.title = 'Save as preset';
    }
    this.viewTypeFormControl.setValue('private');
  }

  async create() {
    this.isAlreadySubmitted = true;
    let newViewTemplate: ViewDefinition = {
      label: this.nameFormControl.value,
    };
    if (this.viewTypeFormControl.value === 'private') {
      newViewTemplate.private = true;
    } else {
      newViewTemplate.admin = true;
    }
    this.dialogRef.close(newViewTemplate);
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.nameFormControl.value) {
      return false;
    }
    return true;
  }
}
