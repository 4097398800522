import { DocumentElement, DocumentTextElementEvent } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { EditorManager } from '../../../components/editor/editor-manager';
import { CanvasElement } from '../../canvas-element';
import { CanvasCellElement } from '../canvas-cell-element';
import { CanvasTableElement } from '../canvas-table-element';
import { TableEditor } from './table-editor';
import { TableEditorContainer } from './table-editor-container';

export class TableEditorManager extends EditorManager {
  public tableElementDefinition: DocumentElement;
  public rowElementDefinition: DocumentElement;
  constructor(canvasDocument: CanvasDocument) {
    super(canvasDocument, {}, {});
    this.editorContainer = new TableEditorContainer(canvasDocument, this);
    this.editor = new TableEditor(canvasDocument, this);
  }

  public showEditor(element: CanvasCellElement) {
    super.showEditor(element);
    const cellElement = element as CanvasCellElement;
    const tableElement: CanvasTableElement = cellElement.table.element;
    this.tableElementDefinition = ObjectUtil.cloneDeep(tableElement.elementDefinition);
    this.rowElementDefinition = ObjectUtil.cloneDeep(tableElement.getRowElement(cellElement.rowIndex));
  }

  public hideEditor(save = true) {
    super.hideEditor(save);
    this.tableElementDefinition = null;
    this.rowElementDefinition = null;
  }

  public tempSetElement(element: CanvasElement): string {
    const editorContainerVisibility = super.tempSetElement(element);
    const cellElement = element as CanvasCellElement;
    const tableElement: CanvasTableElement = cellElement.table.element;
    this.tableElementDefinition = ObjectUtil.cloneDeep(tableElement.elementDefinition);
    this.rowElementDefinition = ObjectUtil.cloneDeep(tableElement.getRowElement(cellElement.rowIndex));
    return editorContainerVisibility;
  }

  public tempRemoveElement(editorContainerVisibility) {
    super.tempRemoveElement(editorContainerVisibility);
    if (editorContainerVisibility === 'hidden') {
      this.tableElementDefinition = null;
      this.rowElementDefinition = null;
    }
  }

  public applyDocumentTextElementEvent(tableElement: CanvasTableElement, change: DocumentTextElementEvent) {
    const elementChanges = {
      textChanges: [],
      textUndoChanges: [],
    };
    const entireTableIsSelected = tableElement.table.tableSelectionState.selectedRanges.length === 0;
    const selectedCells: CanvasCellElement[] = entireTableIsSelected
      ? tableElement.table.getAllCells()
      : tableElement.table.getSelectedCellElements();

    const tableUndo: DocumentElement = ObjectUtil.cloneDeep(tableElement.elementDefinition);
    const rowChanges: Map<number, DocumentElement> = new Map();
    const isFocused = change.element && selectedCells.length === 1;
    for (let i = 0; i < selectedCells?.length; i++) {
      const cell: CanvasCellElement = selectedCells[i];
      const undoElement: DocumentElement = ObjectUtil.cloneDeep(cell.elementDefinition);
      const updatedElement = this.assignTextEventChanges(cell, [change], isFocused);
      if (updatedElement) {
        elementChanges.textChanges.push(
          ObjectUtil.mergeDeep(
            {
              id: cell.elementDefinition.id,
              type: cell.elementDefinition.type,
            },
            updatedElement,
          ),
        );
        elementChanges.textUndoChanges.push(undoElement);
      }

      // Increase row height if any of cell height is greater than current row height
      if (rowChanges.get(cell.rowIndex) == null) {
        rowChanges.set(cell.rowIndex, { size: { height: tableElement.table.rows[cell.rowIndex].height, width: 1 } });
      }
      rowChanges.set(cell.rowIndex, {
        size: {
          height: Math.max(rowChanges.get(cell.rowIndex).size.height, cell.elementDefinition.size.height),
          width: 1,
        },
      });
    }

    const shouldApplyStyleCommand = selectedCells[0].EDITOR_STYLE_COMMANDS.indexOf(change.textFormat.type) !== -1;
    if (shouldApplyStyleCommand) {
      const properties = this.textFormatToDocumentElementStyle(change);

      const selectedRowIndexes: number[] = entireTableIsSelected
        ? tableElement.table.getAllRowIndexes()
        : tableElement.table.getSelectedRowIndexes();
      selectedRowIndexes.forEach((rowIndex) => {
        rowChanges.set(
          rowIndex,
          ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(rowChanges.get(rowIndex) ?? {}), properties),
        );
      });

      const selectedColumnIndexes: number[] = entireTableIsSelected
        ? tableElement.table.getAllColumnIndexes()
        : tableElement.table.getSelectedColumnIndexes();
      selectedColumnIndexes.forEach((columnIndex) => {
        const column = tableElement.getColumnElement(columnIndex);
        elementChanges.textUndoChanges.push(ObjectUtil.cloneDeep(column));
        elementChanges.textChanges.push({
          id: column.id,
          type: column.type,
          ...properties,
        });
      });

      if (entireTableIsSelected) {
        tableElement.elementDefinition = ObjectUtil.mergeDeep(
          ObjectUtil.cloneDeep(tableElement.elementDefinition),
          properties,
        );
      }
    }

    rowChanges.forEach((rowChange, rowIndex) => {
      const row = tableElement.getRowElement(rowIndex);

      const rowStyleChange = rowChange?.style != null;
      const rowHeightChange =
        rowChange?.size?.height != null && rowChange.size.height !== tableElement.table.rows[rowIndex].height;
      if (rowStyleChange || rowHeightChange) {
        const rowUpdate: DocumentElement = {
          id: row.id,
          type: row.type,
          tableId: row.tableId,
        };
        elementChanges.textUndoChanges.push(ObjectUtil.cloneDeep(row));
        if (rowHeightChange) {
          tableElement.table.resizeRow(rowIndex, rowChange.size.height);
          rowUpdate.size = { ...rowChange.size };
        }
        if (rowStyleChange) {
          rowUpdate.style = { ...rowChange.style };
        }
        elementChanges.textChanges.push(rowUpdate);
      }
    });

    if (shouldApplyStyleCommand || tableUndo.size.height !== tableElement.elementDefinition.size.height) {
      elementChanges.textUndoChanges.push(tableUndo);
      elementChanges.textChanges.push({
        id: tableElement.elementDefinition.id,
        type: tableElement.elementDefinition.type,
        tableId: tableElement.elementDefinition.tableId,
        size: { ...tableElement.elementDefinition.size },
        style: { ...tableElement.elementDefinition.style },
      });
    }

    if (elementChanges?.textChanges.length > 0) {
      this.canvasDocument.actionsDispatcher.handleUndoableChanges(
        elementChanges.textChanges,
        elementChanges.textUndoChanges,
      );
    }
  }
}
