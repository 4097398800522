import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentActions } from '../document/document-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { Feature } from '@common/feature-flags/feature-flag';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-board-add-entities-bar',
  templateUrl: './board-add-entities-bar.component.html',
  styleUrls: ['./board-add-entities-bar.component.scss'],
})
export class BoardAddEntitiesBarComponent implements OnInit {
  public showAssetLibrary$: Observable<boolean>;

  constructor(
    private store: Store<RootStoreState.State>,
    private featureFlagService: FeatureFlagRegistryService,
  ) {}

  ngOnInit(): void {
    this.showAssetLibrary$ = this.featureFlagService.getFeatureObservable(Feature.ASSET_LIBRARY);
  }

  showColorChooser() {
    this.showChooser('addColor', 'Color Chooser');
  }
  showItemChooser(itemLib) {
    this.showChooser('addItem', 'Item Chooser', itemLib);
  }
  showAssetChooser() {
    this.showChooser('addAsset', 'Asset Chooser');
  }
  showChooser(slug, label, itemLib = false) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = label;
    overlay.slug = slug;
    overlay.showChooser = true;
    overlay.targetSourceType = itemLib ? ChooserSourceOptionTypes.ITEM_LIBRARY : null;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }
}
