import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-property-configurator-size-info',
  template: `<div *ngIf="selectedElement?.size" class="ml-[11px] mr-[6px] whitespace-nowrap">
    {{ width }} x {{ height }}
  </div>`,
})
export class PropertyConfiguratorSizeInfoComponent implements OnChanges {
  @Input() selectedElement: any;
  public width;
  public height;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedElement?.size) {
      this.width = Math.round(this.selectedElement.size.width);
      this.height = Math.round(this.selectedElement.size.height);
    }
  }
}
