import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnalyticsEvent, EventService } from './analytics.service';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

export enum EVENT_CATEGORY {
  ACCESS = 'ACCESS',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
  EXPORT = 'EXPORT',
  ARCHIVE = 'ARCHIVE',
  OTHER = 'OTHER',
}

export interface UserAnalyticsEvent {
  eventType: string; // ACCESS, VIEW, IMPRESSION, CLICK
  eventName: string; // ACCESS_SHOWCASE, VIEW_ITEM
  targetReference: string; // which entity was clicked on, deleted, ETC
  targetName?: string; // Name of target object (for ease of viewing data). Describes what entity was acted upon by the user.
  contextReference?: string; // showcase:123122, plan:123123
  contextWorkspaceId?: string; // Workspace of the context? (Not the target)
  contextProjectId?: string; // Which project was this in?
  eventApplication?: string; // SHOWCASE_VIEWER, BOARD, PLAN, SHOWCASE_MANAGER
}

@Injectable({
  providedIn: 'root',
})
export class UserAnalyticsService implements EventService {
  eventApplication: string;

  constructor(private store: Store<RootStoreState.State>) {
    this.init();
  }

  public emitEvent(event: AnalyticsEvent) {
    if (environment.apiBaseUrl.indexOf('localhost') > -1) {
      return;
    }

    if (!event.eventCategory || !event.eventName) {
      console.warn(
        "An event could not be emitted to user analytics because it's missing a property:",
        JSON.stringify(event),
      );
      return;
    }

    const userAnalyticsEvent: UserAnalyticsEvent = {
      eventType: event.eventCategory,
      eventName: event.eventName,
      targetReference: event.eventTarget,
      targetName: event.eventLabel,
      contextProjectId: event.eventContextProjectId,
      contextWorkspaceId: event.eventContextWorkspaceId,
      contextReference: event.eventContext,
      eventApplication: this.eventApplication,
    };
    new Entities().create({ entityName: 'user-analytic', object: userAnalyticsEvent });
  }
  public async init() {
    this.listenForRouteChanges();
  }
  public async setActiveUser() {}

  public setEventApplication(eventApplication: string) {
    this.eventApplication = eventApplication;
  }

  private listenForRouteChanges() {}
}
