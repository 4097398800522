import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  PositionDefinition,
  SizeDefinition,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument, CANVAS_MODE } from '../../canvas-document';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';
import { TextMeasureService } from './text-measure';
import { TextMetrics } from './text-metrics';
import { TextStyle } from './text-style';
import { CanvasUtil } from '../../canvas-util';
import { ImageElement } from '../../components/image-element/image-element';
import { TextElementCache } from '../../cache/text-element-cache';
import {
  DEFAULT_TEXT_BORDER_SIZE,
  DEFAULT_TEXT_TOOL_BORDER_SIZE,
  TEXT_BOX_PADDING,
  TEXT_TOOL_PADDING,
  TEXT_TOOL_PLACEHOLDER,
} from './editor/text-editor';
import { TextImageGeneratorOptions } from './text-image-generator';

export class CanvasTextElement extends CanvasElement {
  private readonly DEFAULT_COLOR = '#000';

  public readonly INNER_ELEMENT_MARGIN: number = 3;
  private readonly SNAPSHOT_SCALE = 2;

  private textStyle: TextStyle;
  public textMetrics: TextMetrics;
  private textImageElement: ImageElement = null;
  private currentElementDefinition: DocumentElement;
  private isResizing = false;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    if (elementDefinition.isTextTool) {
      elementDefinition.text = CanvasUtil.removeFontSize(elementDefinition.text);
    }

    super(elementDefinition, canvasDocument, interactable);
    this.isTextEditable = true;
    this.isAsync = true;
  }

  public setDefaultValues(): void {
    if (!this.isPartOfComponent) {
      this.TEXT_PADDING = this.elementDefinition.isTextTool ? TEXT_TOOL_PADDING : TEXT_BOX_PADDING;
      this.TEXT_PLACEHOLDER = this.elementDefinition.isTextTool ? TEXT_TOOL_PLACEHOLDER : '';
      if (this.elementDefinition.isTextTool) {
        this.VALID_PROPERTIES = ['style.backgroundColor', 'style.font.size'];
        this.DEFAULT_BORDER_SIZE = DEFAULT_TEXT_TOOL_BORDER_SIZE;
        if (this.selectionWidgetRenderer) {
          this.selectionWidgetRenderer.DRAG_HANDLES = ['top_left', 'top_right', 'bottom_right', 'bottom_left'];
        }
        this.elementDefinition.style = ObjectUtil.mergeDeep(this.elementDefinition?.style || {}, {
          border: {
            width: DEFAULT_TEXT_TOOL_BORDER_SIZE,
          },
        });
      } else {
        this.VALID_PROPERTIES = ['style.backgroundColor', 'style.border', 'style.text.valign'];
        this.DEFAULT_BORDER_SIZE = DEFAULT_TEXT_BORDER_SIZE;
        this.DEFAULT_BORDER_COLOR = 'rgba(0,0,0,0)';
        if (this.selectionWidgetRenderer) {
          this.selectionWidgetRenderer.DRAG_HANDLES = [
            'top_left',
            'top_center',
            'top_right',
            'center_right',
            'bottom_right',
            'bottom_center',
            'bottom_left',
            'center_left',
          ];
        }
      }
    }
  }

  public getRenderedHeight(ctx, width?, options?: DrawOptions): number {
    const textStyle = new TextStyle(this);
    const fontString = textStyle.toFontString();
    const text = TextMeasureService.getText(this.elementDefinition);
    // Perfomance optimization: recalculate text measure and word wrap only if one of the following is different:
    // text, font style, width
    if (
      !options?.skipRecalculate &&
      (!this.textMetrics ||
        !this.textStyle ||
        text !== this.textMetrics.text ||
        width !== this.textMetrics?.width ||
        fontString !== this.textStyle?.toFontString())
    ) {
      this.textMetrics = TextMeasureService.getRenderedTextMetrics(this, width, ctx);
    }
    this.textStyle = textStyle;

    const totalHeight = this.textMetrics.height + this.INNER_ELEMENT_MARGIN;
    return totalHeight;
  }

  public draw(ctx: CanvasRenderingContext2D, { x, y, width, height }, options?: DrawOptions): { height: number } {
    if (!this.isVisible) {
      return;
    }

    // Determine how much space the given element is taking up... decide if we want to render text, or a placeholder.
    const coveredArea = this.getCoveredArea();
    let drawPlaceholder = false;
    if (this.isPartOfComponent && coveredArea < 0.008) {
      drawPlaceholder = true;
    } else if (!this.isPartOfComponent && coveredArea < 0.02) {
      drawPlaceholder = true;
    }
    if (drawPlaceholder) {
      return this.drawTextPlaceholder(ctx, { x, y, width, height }, options);
    }

    return this.isPartOfComponent
      ? this.drawNonEditableText(ctx, { x, y, width, height }, options)
      : this.drawEditableText(ctx, { x, y, width, height }, options);
  }

  private drawTextPlaceholder(ctx: CanvasRenderingContext2D, { x, y, width, height }, options?: DrawOptions) {
    //const DEFAULT_COLOR = "rgba(237, 237, 237,1)";
    const DEFAULT_COLOR = 'rgba(0, 0, 0,.05)';
    let rectX;
    let rectY;
    let rectWidth;
    let rectHeight;
    const size = this.getSize();
    if (this.isPartOfComponent) {
      const totalHeight = this.getRenderedHeight(ctx, width - 4, options);
      rectX = x;
      rectY = y;
      rectWidth = width;
      rectHeight = totalHeight;
    } else {
      rectX = x - this.elementDefinition.position.x - size.width * 0.5;
      rectY = y - this.elementDefinition.position.y - size.height * 0.5;
      rectWidth = width;
      rectHeight = height;
    }
    ctx.beginPath();
    ctx.rect(rectX, rectY, rectWidth, rectHeight);
    const backgroundColor = this.elementDefinition.style?.backgroundColor;
    ctx.fillStyle = backgroundColor && backgroundColor !== 'rgba(0,0,0,0)' ? backgroundColor : DEFAULT_COLOR;
    ctx.fill();
    ctx.closePath();
    return { height: rectHeight };
  }

  private drawNonEditableText(
    ctx: CanvasRenderingContext2D,
    { x, y, width, height },
    options?: DrawOptions,
  ): { height: number } {
    const totalHeight = this.getRenderedHeight(ctx, width - 4, options);

    this.drawContainer(ctx, x, y, width, totalHeight);

    ctx.font = this.textStyle.toFontString();
    ctx.fillStyle = this.elementDefinition?.style?.color || this.DEFAULT_COLOR;

    for (let i = 0; i < this.textMetrics.lines.length; i++) {
      const text = this.textMetrics.lines[i];
      y += this.textMetrics.lineHeight;
      const xPos = this.alignTextAndUnderline(x, width, ctx, text, y);
      ctx.fillText(text, xPos, y);
    }
    return { height: totalHeight };
  }

  private alignTextAndUnderline(x: any, width: any, ctx: CanvasRenderingContext2D, text: string, y: any) {
    // Calculating width of each line on render because sometimes measureText returns wrong value in wordWrap
    const textLineWidth = TextMetrics.getWidth(ctx, text, this.textStyle?.toFontString());
    let underlineStartPos = x;
    if (this.textStyle.textAlign === 'center') {
      x = x + width * 0.5;
      ctx.textAlign = 'center';
      underlineStartPos = x - textLineWidth * 0.5;
    } else if (this.textStyle.textAlign === 'right') {
      x = x + width;
      ctx.textAlign = 'right';
      underlineStartPos = x - textLineWidth;
    } else {
      ctx.textAlign = 'left';
    }

    // Draw underline if applicable
    if (this.textStyle.fontDecoration === 'underline' && text?.trim() !== '') {
      ctx.fillRect(underlineStartPos, y + 1, textLineWidth, 1);
    }

    return x;
  }

  private drawEditableText(
    ctx: CanvasRenderingContext2D,
    { x, y, width, height },
    options?: DrawOptions,
  ): { height: number } {
    if (width === 0 || height === 0) {
      return;
    }
    this.textStyle = new TextStyle(this);
    const oldData = ObjectUtil.cloneDeep(this.currentElementDefinition) || {};
    const newData = ObjectUtil.cloneDeep(this.elementDefinition);
    const dataChanged = this.hasChanged();
    // console.log(`drawEditableText: isResizing=${this.isResizing}, canvasIsResizing=${this.canvasDocument.interactionHandler.isResizing()}, dataChanged=${dataChanged}`)
    // Generate new image after resizing is completed
    if (
      (this.isResizing && !this.canvasDocument.interactionHandler.isResizing()) ||
      (!this.canvasDocument.interactionHandler.isResizing() && dataChanged)
    ) {
      this.canvasDocument?.editorHandler?.calculateTextChildElemPositions(this, this.textStyle);
      // console.log(
      //   'Resizing is done, data is different',
      //   this.isResizing,
      //   this.canvasDocument.interactionHandler.isResizing(),
      //   dataChanged,
      // );
      this.drawTextImage(ctx, { width, height }, true, options);
      // Generate image if no image has been generated before or if scale changed
    } else if (!this.canvasDocument.interactionHandler.isResizing() && this.canvasDocument.mode !== 'SNAPSHOT') {
      if (!this.textImageElement) {
        this.canvasDocument?.editorHandler?.calculateTextChildElemPositions(this, this.textStyle);
      }
      // console.log(
      //   `Resizing is done, data is same but check if zoom is different, width=${width}, height=${height}, imgW=${this.textImageElement?.img?.width}, imgH=${this.textImageElement?.img?.height}`,
      // );
      this.drawTextImage(ctx, { width, height }, false, options);
      // Use existing image while resizing
    } else {
      if (this.textImageElement) {
        // console.log('here 3', options, width, height, 'scale', oldData.scale?.x, this.elementDefinition.scale?.x, this.textImageElement?.img?.width, this.textImageElement?.img?.height)
        const scale = newData?.scale?.x != null ? newData.scale?.x / (oldData.scale?.x || 1) : 1;
        let imgW = this.textImageElement?.img?.width * scale;
        let imgH = this.textImageElement?.img?.height * scale;
        if (options?.scaleBy) {
          imgW = imgW / options.scaleBy;
          imgH = imgH / options.scaleBy;
        }
        ctx.drawImage(this.textImageElement.canvasImg, -width * 0.5, -height * 0.5, imgW, imgH);
      } else {
        console.log('Text image was never generated.', this.elementDefinition);
      }
    }
    this.isResizing =
      this.canvasDocument.interactionHandler.isResizing() &&
      (newData.size.width !== oldData?.size?.width ||
        newData.size.height !== oldData?.size?.height ||
        newData?.scale?.x != oldData?.scale?.x);

    return { height: 0 };
  }

  private hasChanged() {
    const oldData = ObjectUtil.cloneDeep(this.currentElementDefinition) || {};
    delete oldData.position; // no need to redraw if position changes
    delete oldData.rotate; // no need to redraw if rotation changes
    delete oldData.isLocked;
    // delete oldData.scale;
    const newData = ObjectUtil.cloneDeep(this.elementDefinition);
    delete newData.position;
    delete newData.rotate;
    delete newData.isLocked;
    // delete newData.scale;
    const dataChanged = Object.keys(oldData).length > 0 && JSON.stringify(oldData) !== JSON.stringify(newData);
    return dataChanged;
  }

  /**
   * Create an image element from html text and add the element object to the canvas
   * @param ctx
   * @param width
   * @param height
   */
  private async drawTextImage(ctx, { width, height }, redraw = false, options?: DrawOptions) {
    if (width === 0 || height === 0) {
      return;
    }
    this.currentElementDefinition = ObjectUtil.cloneDeep(this.elementDefinition);
    const floor = CanvasUtil.floor;
    let scale = this.getScaleFactor(width, height);
    // Check if image is in cache so it can synchronously be drawn in the correct order
    const cacheKey = TextElementCache.getCacheKey(
      this.elementDefinition.text,
      width,
      height,
      this.elementDefinition.isTextTool,
      this.textStyle,
      true,
      scale,
    );
    const cacheValue = TextElementCache.get(cacheKey);
    const textImgCache: ImageElement = cacheValue?.resolvedPromise;
    let img = textImgCache;
    // console.log(
    //   `drawTextImage, id=${this.elementDefinition.id}, zoom=${scale}, scale=${this.elementDefinition.scale?.x}, width=${width}, height=${height}, cacheKey=${cacheKey}`,
    //   cacheValue,
    //   TextElementCache.cacheMap.keys(),
    //   TextElementCache.cacheMap.values(),
    // );
    // Generate new image if image is not in cache
    if (!textImgCache?.canvasImg) {
      if (cacheValue?.promise) {
        // If cache value already has an awaiting promise - await for it and do not generate new image.
        img = await cacheValue.promise;
      } else {
        // Generate new text image and set promise to cache so other same text elements were awaiting for it
        // instead of creating new text images.
        const options: TextImageGeneratorOptions = {
          width,
          height,
          padding: this.TEXT_PADDING,
        };
        if (this.elementDefinition.scale?.x != null) {
          const sizeNotScaled = this.getSize({ skipScale: true });
          options.originalWidth = sizeNotScaled.width;
          options.originalHeight = sizeNotScaled.height;
          options.scale = this.elementDefinition.scale.x;
        }
        const promise = TextElementCache.generateTextImageByCacheKey(
          this.elementDefinition.text,
          cacheKey,
          options,
          this.textStyle,
          true,
          scale,
        );
        TextElementCache.set(cacheKey, { promise });
        img = await promise;
      }
    }

    // Resize finished, draw new image
    if (redraw) {
      if (this.canvasDocument.mode === 'SNAPSHOT') {
        console.log('Warning 1: generating text image while making snapshot', this);
      }

      this.textImageElement = img;
      this.canvasDocument.debounceDraw();

      // Text image was found in cache - draw it. This adds image in the correct order
    } else if (textImgCache?.canvasImg) {
      this.textImageElement = textImgCache;
      ctx.drawImage(
        this.textImageElement.canvasImg,
        -width * 0.5,
        -height * 0.5,
        this.textImageElement?.img?.width,
        this.textImageElement?.img?.height,
      );
    } else if (img?.canvasImg) {
      // Doing this seems faster for initial load with a large number of text elements when compared with canvasDocument.redraw()
      // However, this will affect order of elements... PLEASE REVISIT
      // Need to change the ctx again because this is an async

      if (this.canvasDocument.mode === 'SNAPSHOT') {
        console.log('Warning 2: generating text image while making snapshot', this);
      }

      const scale = this.canvasDocument.getDevicePixelRatio();
      const viewScale = this.canvasDocument.getViewScale();
      const viewBox = this.canvasDocument.getViewBox();
      ctx.save();
      ctx.scale(viewScale.x * scale, viewScale.y * scale);
      ctx.translate(-viewBox.x, -viewBox.y);
      this.transform(ctx, this.getDimensions());
      ctx.drawImage(img.canvasImg, -floor(width * 0.5), -floor(height * 0.5), width, height);
      ctx.restore();
      this.textImageElement = img;
    }
  }

  /** Returns the % of the screen covered by the text element */
  private getScaleFactor(width, height) {
    if (this.canvasDocument.mode === CANVAS_MODE.SNAPSHOT) {
      return this.SNAPSHOT_SCALE;
    }
    let fontSize = this.elementDefinition.style?.font?.size || 12;
    const testSize = this.canvasDocument.toWindowSize(fontSize, fontSize);

    const percentage = CanvasUtil.getCoveredAreaPercentage(testSize, this.canvasDocument.getCanvasSize());

    const maxScaledWidth = 5000;
    // Do not scale large text elements because they can take up a lot of memory
    if (percentage > 1 && width * 8 < maxScaledWidth && height * 8 < maxScaledWidth) {
      return 8;
    }
    if (percentage > 0.5 && width * 5 < maxScaledWidth && height * 5 < maxScaledWidth) {
      return 5;
    }
    if (percentage > 0.01 && width * 4 < maxScaledWidth && height * 4 < maxScaledWidth) {
      return 4;
    }
    if (percentage > 0.001 && width * 2 < maxScaledWidth && height * 2 < maxScaledWidth) {
      return 2;
    }
    return 1;
  }

  public isMouseOnEditableArea(event: MouseEvent) {
    const position = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    return this.isPointOnElement(position.x, position.y, this.TEXT_PADDING === 0 ? 2 : this.TEXT_PADDING); // includes margin when determining editable area
  }

  public isMouseOnHyperlink(event: MouseEvent) {
    const childElements = this.canvasDocument.state.getTextLinkElementChildren(this.elementDefinition.id);
    const mouseEvent: MouseEvent = event as MouseEvent;
    const elementDimensions: any = this.getDimensions();
    for (let i = 0; i < childElements?.length; i++) {
      if (this.isWithinChildElem({ x: mouseEvent.x, y: mouseEvent.y }, elementDimensions, childElements[i].domRect)) {
        return childElements[i];
      }
    }
    return null;
  }

  public onSelect() {
    if (!this.isSelected) {
      this.canvasDocument?.editorHandler?.getCurrentStyle(this);
    }
  }

  public onDeselect(): void {
    if (
      (this.elementDefinition.text === '' ||
        this.canvasDocument?.editorHandler?.editorCalc?.isEmptyTextContent(this.elementDefinition.text)) &&
      !this.elementDefinition.documentGenerationConfigId // do not delete empty text elements generated by lineboard
    ) {
      console.log('Deleting empty text element', this.elementDefinition);
      this.canvasDocument.actionsDispatcher.handleDocumentActions([
        new DocumentAction({
          elementId: this.elementDefinition.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: this.elementDefinition,
        }),
      ]);
    }
  }

  private drawContainer(ctx, x, y, width, height) {
    const backgroundColor = this.elementDefinition.style?.backgroundColor;
    if (backgroundColor != null && backgroundColor !== 'rgba(0,0,0,0)') {
      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.fillStyle = this.elementDefinition.style?.backgroundColor || 'rgba(0,0,0,0)';
      ctx.fill();
      ctx.closePath();
    }
    this.stroke(ctx);
  }

  private isWithinChildElem(mousePosition: PositionDefinition, textElemDimension, domRect: DOMRect): boolean {
    const viewScale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    const boundaries = {
      top: (textElemDimension.y + domRect.top - viewBox.y) * viewScale.y,
      left: (textElemDimension.x + domRect.left - viewBox.x) * viewScale.x,
      bottom: (textElemDimension.y + domRect.top + domRect.height - viewBox.y) * viewScale.y,
      right: (textElemDimension.x + domRect.left + domRect.width - viewBox.x) * viewScale.x,
    };
    if (
      mousePosition.x < boundaries.right &&
      mousePosition.x > boundaries.left &&
      mousePosition.y < boundaries.bottom &&
      mousePosition.y > boundaries.top
    ) {
      return true;
    }
    return false;
  }

  public clear() {
    if (this.textImageElement) {
      this.textImageElement?.canvasImg && (this.textImageElement.canvasImg = null);
      this.textImageElement?.img && (this.textImageElement.img = null);
    }
  }

  public async preload(options?: DrawOptions) {
    const { width, height } = this.getSize(Object.assign({}, options, { skipScale: true }));
    const scale = this.SNAPSHOT_SCALE;
    this.textStyle = new TextStyle(this);
    this.textImageElement = await TextElementCache.generateTextImage(
      this.elementDefinition.text,
      { width, height, padding: this.TEXT_PADDING },
      this.elementDefinition.isTextTool,
      this.textStyle,
      true,
      scale,
    );
    return this.textImageElement;
  }

  public async toImageElement(options?: DrawOptions): Promise<ImageElement> {
    return await this.preload(options);
  }

  public toSVG({ x, y, width, height, href }): HTMLElement {
    const element = document.createElement('image');
    element.setAttributeNS('w3.org/1999/xlink', 'xlink:href', href);
    element.setAttribute('x', `${x}`);
    element.setAttribute('y', `${y}`);
    element.setAttribute('width', `${width}`);
    element.setAttribute('height', `${height}`);
    return element;
  }

  public async loadAsSVG({ x, y, width, height }): Promise<HTMLElement> {
    const textImageElement = await this.preload();
    const dpr = this.canvasDocument.getDevicePixelRatio();
    const base64 = await ImageElement.toCanvasDataUrlFromImageElement(textImageElement, 'image/png', 1.0, dpr);
    return this.toSVG({
      x,
      y,
      width,
      height,
      href: base64,
    });
  }
}
