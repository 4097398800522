import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, filter, retryWhen, switchMap, tap } from 'rxjs/operators';
import { SessionEvent, SessionMessage, SessionMessageHandler } from './session-message';
import { AuthContext, AuthService } from '../auth/auth.service';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';
const WS_URL = environment.websocketService;

@Injectable({
  providedIn: 'root',
})
export class WebSocketService implements OnDestroy {
  public messages: Subject<SessionMessage> = new Subject<SessionMessage>();
  private connectionLostSubject = new Subject();
  public connectionLost$ = this.connectionLostSubject.asObservable();
  private connection$: WebSocketSubject<any>;
  private currentSessionId: string;
  authContext: AuthContext;
  private apiToken: string;
  private clientId = uuid.v4();
  private connected = false;
  private lastHeartbeatTime;
  RETRY_SECONDS = 10;
  constructor(public authService: AuthService) {
    //
    this.authService.authContext.subscribe(async (authContext) => {
      console.log('WebSocketService: authContext: ', authContext);
      this.authContext = authContext;
      if (this.authContext) {
        this.setupConnection();
      }
      if (!this.lastHeartbeatTime) {
        this.heartbeat();
      }
    });
  }

  async setupConnection() {
    console.log('WebSocketService: setupConnection');
    this.apiToken = await this.authService.loadToken();
    const con: Observable<any> = this.connect();
    con.subscribe(
      (msg) => {
        this.messages.next(msg);
      },
      (error) => {
        console.log('WebSocketService: Error in web socket...');
      },
      () => {
        this.connection$ = null;

        console.log('WebSocketService: Websocket closing...');
        this.setupConnection();
      },
    );

    if (this.currentSessionId) {
      this.joinSession(this.currentSessionId);
    }
  }
  connect(): Observable<any> {
    return of(WS_URL).pipe(
      filter((apiUrl) => !!apiUrl),
      switchMap((wsUrl) => {
        if (this.connection$) {
          console.log('WebSocketService: connect: returning existing connection');
          return this.connection$;
        } else {
          this.checkLastHeartbeat(); // Before connecting, ensure we weren't dead.
          console.log('WebSocketService: connect: attempting new connection');
          const connectString = `${WS_URL}?apiKey=${this.apiToken}&apiOrg=${this.authContext.currentOrg.orgSlug}&clientId=${this.clientId}`;
          this.connection$ = webSocket(connectString);
          if (this.currentSessionId) {
            this.joinSession(this.currentSessionId);
          }
          console.log('WebSocketService: connection successfull : setting connected = true');
          this.connected = true;
          return this.connection$;
        }
      }),
      retryWhen((errors) => {
        return errors.pipe(
          tap((errors) => {
            this.connection$ = null;
            if (errors.type === 'close') {
              console.log('WebSocketService: Connection closed. ', errors);

              this.handleLostConnection();
            } else {
              console.log('WebSocketService: Errors in retryWhen setting up WebSocket: ', errors);
            }
          }),
          delay(this.RETRY_SECONDS),
        );
      }),
    );
  }

  private handleLostConnection() {
    console.log('WebSocketService: handleLostConnection : setting connected = false');
    this.connected = false;
    setTimeout(() => {
      console.log('WebSocketService: handleLostConnection : after timeout: connected = ', this.connected);
      if (!this.connected) {
        console.log('WebSocketService: handleLostConnection : broadcasting disconnected subject');
        //this.connectionLostSubject.next(true);
      }
    }, 5000);
  }

  private heartbeat() {
    if (!this.lastHeartbeatTime) {
      this.lastHeartbeatTime = new Date();
    }
    this.checkLastHeartbeat();
    if (this.connected) {
      this.lastHeartbeatTime = new Date();
    }
    setTimeout(() => {
      this.heartbeat();
    }, 1000);
  }

  private checkLastHeartbeat() {
    if (!this.lastHeartbeatTime) {
      return;
    }
    const newHearbeatTime = new Date();
    const timeSinceLastHeartbeat = (newHearbeatTime.getTime() - this.lastHeartbeatTime.getTime()) / 1000;
    //console.log("WebSocketService: timeSinceLastHeartbeat: ", timeSinceLastHeartbeat);
    if (timeSinceLastHeartbeat > 5) {
      console.log('WebSocketService: last heartbeat seen over 5 seconds ago: broadcasting disconnect subject');
      //this.connectionLostSubject.next(true);
    }
  }

  sendSessionEvent(event: SessionEvent) {
    this.sendMessage({
      sessionId: this.currentSessionId,
      timestamp: new Date().getTime(),
      action: 'SESSION_EVENT',
      event,
    });
  }
  sendMessage(message: SessionMessage) {
    if (this.connection$) {
      this.connection$.next(message);
    } else {
      console.error(`WebSocketService: Did not send data, open a connection first'`, message);
    }
  }
  closeConnection() {
    console.log('WebSocketService: closeConnection');
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }
  async joinSession(sessionId: string) {
    console.log('WebSocketService: joinSession:', sessionId);
    this.currentSessionId = sessionId;
    this.sendMessage({
      action: 'JOIN_SESSION',
      sessionId,
    });
  }
  ngOnDestroy() {
    this.closeConnection();
  }

  public getCurrentSessionId() {
    return this.currentSessionId;
  }
  public getClientId() {
    return this.clientId;
  }
}
