import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyValueFormatter } from '@contrail/types';

@Injectable({
  providedIn: 'root',
})
export class TypeManagerService {
  private propertyValueFormatter: PropertyValueFormatter;

  constructor() {
    this.propertyValueFormatter = new PropertyValueFormatter();
  }

  async getTypeInfoById(id: string) {
    return await new Types().getType({ id, relations: ['typeProperties'] });
  }

  async formatObjectByType(object) {
    const typeDefinition = await this.getTypeInfoById(object.typeId);
    typeDefinition.typeProperties.forEach((property) => {
      object[property.slug] = this.propertyValueFormatter.getDisplayValue(object, property);
    });
  }
}
