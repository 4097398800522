import { ViewBox } from '@contrail/documents';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasTableElement } from '../../../elements/table/canvas-table-element';
import { TableRange } from '../../../elements/table/table-manager/table/table-range';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';

export class TableSelectHandler {
  private selectedTable: CanvasTableElement;
  private startingDraggingBox: ViewBox;
  private startingRange: TableRange;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS; index? }) {
    if (
      elementTarget?.element?.elementDefinition?.type === 'table' &&
      elementTarget?.element?.isSelected &&
      [DRAG_DIRECTIONS.CELL].indexOf(elementTarget?.target) !== -1 &&
      elementTarget?.index != null
    ) {
      this.startingDraggingBox = { x: event.clientX, y: event.clientY, width: 0, height: 0 };
      this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = {
        x: event.clientX,
        y: event.clientY,
        width: 0,
        height: 0,
      };

      this.selectedTable = elementTarget.element as CanvasTableElement;
      this.updateCellSelection(event);
    }
  }

  public dragged(event) {
    if (!this.startingDraggingBox) {
      return;
    }

    const distanceX = event.clientX - this.startingDraggingBox.x;
    const distanceY = event.clientY - this.startingDraggingBox.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    const width = Math.abs(this.startingDraggingBox.width + distanceX);
    const height = Math.abs(this.startingDraggingBox.height + distanceY);
    let x = this.startingDraggingBox.x;
    let y = this.startingDraggingBox.y;

    if (distanceX < 0) {
      x = this.startingDraggingBox.x + distanceX;
    }
    if (distanceY < 0) {
      y = this.startingDraggingBox.y + distanceY;
    }

    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = {
      width,
      height,
      x,
      y,
    };

    this.updateCellSelection(event);
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.startingDraggingBox) {
      return;
    }

    this.selectedTable.table.emitSelectedAreaStyle();
    this.clear();
    this.canvasDocument.draw();
  }

  private updateCellSelection(event) {
    const { x, y } = this.canvasDocument.toDocumentPosition(event.clientX, event.clientY);
    const { cell } = this.selectedTable.getCellAtAndHandleHoverState(x, y);
    const row = Math.max(0, cell.rowIndex);
    const col = Math.max(0, cell.columnIndex);
    if (!this.startingRange) {
      this.startingRange = new TableRange(row, row, col, col);
    } else {
      this.selectedTable.table.handleSelectOnDrag(this.startingRange, new TableRange(row, row, col, col));
    }
  }

  private clear() {
    this.selectedTable = null;
    this.startingRange = null;
    this.startingDraggingBox = null;
    this.canvasDocument.canvasRenderer.draggingBoxRenderer.draggingBox = null;
  }
}
