import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AuthService, User } from '../auth/auth.service';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  // public currentOrg;
  // public orgs;
  public currentUser: User;
  public userForm!: UntypedFormGroup;

  public subscriptionChannels = ['EMAIL'];
  public subscriptions: any = {};
  // public subscriptionForm!: UntypedFormGroup;
  public boardForm!: UntypedFormGroup;
  public planForm!: UntypedFormGroup;
  public showcaseForm!: UntypedFormGroup;

  public apps = [];

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // this.currentOrg = data.authContext.currentOrg;
    // this.orgs = data.authContext.user?.orgs;
    this.currentUser = data.authContext.user;

    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.currentUser?.firstName, [
        Validators.required,
        Validators.pattern('[\\w\\-\\s\\/]+'),
        Validators.maxLength(50),
      ]),
      lastName: new UntypedFormControl(this.currentUser?.lastName, [
        Validators.required,
        Validators.pattern('[\\w\\-\\s\\/]+'),
        Validators.maxLength(50),
      ]),
      // profilePhoto: new UntypedFormControl(this.currentUser?.profilePhoto),
      // email: new UntypedFormControl(this.currentUser?.email, [Validators.required, Validators.email]),
    });
    this.data.subscriptions.map((sub) => {
      const app = sub.subscribedToRef.slice(0, -4);
      this.subscriptions[app] = sub;
      return sub;
    });

    this.initForm();
  }

  ngOnInit() {
    this.boardForm.valueChanges.pipe(debounceTime(1500), takeUntil(this.destroy$)).subscribe(async (val) => {
      const id = this.subscriptions.board.id;
      const boardMatrix = this.subscriptions.board.subscriptionMatrix;
      await this.updateMatrix(id, boardMatrix, val);
    });
    this.planForm.valueChanges.pipe(debounceTime(1500), takeUntil(this.destroy$)).subscribe(async (val) => {
      const id = this.subscriptions.plan.id;
      const planMatrix = this.subscriptions.plan.subscriptionMatrix;
      await this.updateMatrix(id, planMatrix, val);
    });
    this.showcaseForm.valueChanges.pipe(debounceTime(1500), takeUntil(this.destroy$)).subscribe(async (val) => {
      const id = this.subscriptions.showcase.id;
      const showcaseMatrix = this.subscriptions.showcase.subscriptionMatrix;
      await this.updateMatrix(id, showcaseMatrix, val);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  initForm() {
    const boardMatrix = this.subscriptions.board.subscriptionMatrix;
    this.boardForm = new UntypedFormGroup({
      MENTION: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(boardMatrix.MENTION.includes('EMAIL')),
      }),
      COMMENT_ON_OWNED: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(boardMatrix.COMMENT_ON_OWNED.includes('EMAIL')),
      }),
    });

    const planMatrix = this.subscriptions.plan.subscriptionMatrix;
    this.planForm = new UntypedFormGroup({
      MENTION: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(planMatrix.MENTION.includes('EMAIL')),
      }),
      COMMENT_ON_OWNED: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(planMatrix.COMMENT_ON_OWNED.includes('EMAIL')),
      }),
    });

    const showcaseMatrix = this.subscriptions.showcase.subscriptionMatrix;
    this.showcaseForm = new UntypedFormGroup({
      MENTION: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(showcaseMatrix.MENTION.includes('EMAIL')),
        // SLACK: new UntypedFormControl(showcaseMatrix.MENTION.includes('SLACK')),
        // TEAMS: new UntypedFormControl(showcaseMatrix.MENTION.includes('TEAMS')),
      }),
      COMMENT_ON_OWNED: new UntypedFormGroup({
        EMAIL: new UntypedFormControl(showcaseMatrix.COMMENT_ON_OWNED.includes('EMAIL')),
        // SLACK: new UntypedFormControl(showcaseMatrix.COMMENT_ON_OWNED.includes('SLACK')),
        // TEAMS: new UntypedFormControl(showcaseMatrix.COMMENT_ON_OWNED.includes('TEAMS')),
      }),
    });

    this.apps = [
      { name: 'board', form: this.boardForm, icon: 'assets/images/board_icon.svg' },
      { name: 'plan', form: this.planForm, icon: 'assets/images/plan_icon.svg' },
      { name: 'showcase', form: this.showcaseForm, icon: 'assets/images/showcase_icon.svg' },
    ];
  }

  async updateProfile() {
    const id = this.currentUser.id;
    const changes = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
    };
    const user = await new Entities().update({ entityName: 'user', id, object: changes });
    this.currentUser = {
      ...this.currentUser,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    this.authService.updateUser(this.currentUser);
  }

  async updateMatrix(id, matrix, formValue) {
    let subscriptionMatrix = matrix;
    const channels = [
      'EMAIL',
      // 'SLACK',
      // 'TEAMS',
    ]; // TODO: SLACK, TEAMS later

    Object.keys(formValue).forEach((subscriptionType) => {
      channels.forEach((channel) => {
        const type = formValue[subscriptionType];

        if (type[channel] && !subscriptionMatrix[subscriptionType].includes(channel)) {
          subscriptionMatrix[subscriptionType].push(channel);
        } else if (!type[channel] && subscriptionMatrix[subscriptionType].includes(channel)) {
          const idx = subscriptionMatrix[subscriptionType].findIndex((v) => v === channel);
          subscriptionMatrix[subscriptionType].splice(idx, 1);
        }
      });
    });

    const res = await new Entities().update({ entityName: 'subscription', id, object: { subscriptionMatrix } });
  }

  // async selectOrg(org) {
  //   this.currentOrg = org;
  // }
}
