import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsActions } from '.';
import { AssortmentsService } from '../assortments.service';

@Injectable()
export class AssortmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private assortmentService: AssortmentsService,
  ) {}

  loadAssortments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.LOAD_ASSORTMENTS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.assortmentService.getAllAssortmentsBatched(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => AssortmentsActions.loadAssortmentsSuccess({ data })),
          catchError((error) => observableOf(AssortmentsActions.loadAssortmentsFailure({ error }))),
        );
      }),
    ),
  );

  loadSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        console.log('loadSourceAssortment!!!!!');
        return from(AssortmentsService.getAssortment(action.sourceAssortmentId)).pipe(
          map((sourceAssortment) => AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment })),
          catchError((error) => observableOf(AssortmentsActions.loadAssortmentsFailure({ error }))),
        );
      }),
    ),
  );
}
