import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { WorkspacePrincipal } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class WorkspacePrincipalService {
  constructor() {}

  async getWorkspacePrincipals(workspaceId: string): Promise<WorkspacePrincipal[]> {
    return new Entities().get({
      entityName: 'workspace-principal',
      criteria: { workspaceId },
      relations: ['principal'],
    });
  }
}
