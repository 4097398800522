import { Document } from '@contrail/documents';
import { createAction, props } from '@ngrx/store';
import { DocumentElementSortOrder } from '../document-manager.service';

export enum DocumentManagerActionTypes {
  UPDATE_DOCUMENT = '[DocumentManager] Update Document',

  SET_DOCUMENT_ELEMENTS_LOADED = '[DocumentManager] Set Document Elements Loaded',
  SET_DOCUMENT_ELEMENT_SORT_ORDER = '[DocumentManager] Set Document Element Sort Order',
  SET_DOCUMENT_ID = '[DocumentManager] Set Document ID',
}

export const setDocumentElementsLoaded = createAction(
  DocumentManagerActionTypes.SET_DOCUMENT_ELEMENTS_LOADED,
  props<{ loaded: boolean }>(),
);

export const setDocumentElementSortOrder = createAction(
  DocumentManagerActionTypes.SET_DOCUMENT_ELEMENT_SORT_ORDER,
  props<{ documentElementSortOrder: DocumentElementSortOrder }>(),
);

export const setDocumentId = createAction(DocumentManagerActionTypes.SET_DOCUMENT_ID, props<{ documentId: string }>());

export const updateDocument = createAction(
  DocumentManagerActionTypes.UPDATE_DOCUMENT,
  props<{ id: string; document: Document; message?: string }>(),
);
