<button
  mat-icon-button
  [matMenuTriggerFor]="textTypes"
  [matTooltip]="textType === 'text-tool' ? 'Text tool' : 'Text box'"
  matTooltipPosition="above"
  data-test="change-text-type"
>
  <mat-icon [svgIcon]="textType === 'text-tool' ? 'app-text-tool-create' : 'app-text-box-create'"></mat-icon>
</button>
<mat-menu #textTypes="matMenu" class="text-menu !min-w-10 !max-w-10">
  <button
    mat-icon-button
    (click)="setValue()"
    [matTooltip]="textType === 'text-tool' ? 'Text box' : 'Text tool'"
    [attr.data-test]="textType === 'text-tool' ? 'change-text-type-to-text-box' : 'change-text-type-to-text-tool'"
  >
    <mat-icon
      class="mat-icon"
      [svgIcon]="textType === 'text-tool' ? 'app-text-box-create' : 'app-text-tool-create'"
    ></mat-icon>
  </button>
</mat-menu>
