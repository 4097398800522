import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasTriangleElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }): number[][] {
    return [
      [x + width * 0.5, y],
      [x + width, y + height],
      [x, y + height],
    ];
  }
}
