import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ItemData } from '@common/item-data/item-data';

@Component({
  selector: 'app-assortment-annotation-dashboard-widget',
  templateUrl: './assortment-annotation-dashboard-widget.component.html',
  styleUrls: ['./assortment-annotation-dashboard-widget.component.scss'],
})
export class AssortmentAnnotationDashboardWidgetComponent implements OnInit, OnChanges {
  @Input() assortmentItemData: Array<ItemData>;
  @Input() annotationOptions: any[];
  @Input() appContext: any;
  @Input() widgetName: string;

  annotationData: any = {};
  annotationGroups: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getStoredAnnotationOptions();
    this.setAnnotationGroups();
    if (changes.assortmentItemData) {
      this.setAnnotationData();
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.annotationOptions, event.previousIndex, event.currentIndex);
    this.setAnnotationGroups();
    this.storeAnnotationOptions();
  }

  trackByFn(index, option) {
    return option?.property;
  }

  toggleAnnotation() {
    this.setAnnotationGroups();
    this.storeAnnotationOptions();
  }

  storeAnnotationOptions() {
    const key = `${this.widgetName}_${this.appContext.ownerReferenceId}`;
    localStorage.setItem(key, JSON.stringify(this.annotationOptions));
  }

  private getStoredAnnotationOptions() {
    this.annotationOptions?.forEach((annotationOption) => (annotationOption.enabled = true));
    const key = `${this.widgetName}_${this.appContext.ownerReferenceId}`;
    const storedData = localStorage.getItem(key);
    if (storedData) {
      const storedAnnotationOptions = JSON.parse(storedData);
      if (this.annotationOptions?.length === storedAnnotationOptions?.length) {
        this.annotationOptions = storedAnnotationOptions;
      }
    }
  }

  private setAnnotationGroups() {
    this.annotationGroups = [];
    const activeAnnotationOptions = this.annotationOptions.filter((option) => option.enabled);
    const groupCount = Math.ceil(activeAnnotationOptions.length / 3);
    for (let i = 0; i < groupCount; i++) {
      this.annotationGroups.push(activeAnnotationOptions.slice(i * 3, i * 3 + 3));
    }
  }

  private setAnnotationData() {
    this.annotationOptions.forEach((annotationOption) => {
      this.annotationData[annotationOption.property] = this.assortmentItemData.filter(
        (itemData) => itemData.assortmentItem[annotationOption.property],
      ).length;
    });
  }
}
