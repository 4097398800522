import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsEvent, EventService } from './analytics.service';
declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements EventService {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.init();
  }

  public emitEvent(event: AnalyticsEvent) {
    gtag('event', event.eventName, {
      event_category: event.eventCategory,
      event_label: event.eventLabel,
      value: event.eventValue,
    });
  }
  public async init() {
    this.listenForRouteChanges();
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML =
        `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
        environment.googleAnalyticsKey +
        `');`;
      document.head.appendChild(script2);

      this.setActiveUser();
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
  public async setActiveUser() {
    const user = await this.authService.getCurrentUser();
    if (user) {
      console.log('setting google analytics user: ', user);
    } else {
      console.log('user is null in google analytics');
    }
    gtag('config', environment.googleAnalyticsKey, {
      user_id: user.id,
    });
  }
  private listenForRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
        });
        console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
      }
    });
  }
}
