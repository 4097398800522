import { Injectable } from '@angular/core';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { RootStoreState } from '@rootstore';
import { Document, DocumentAction, DocumentChangeType } from '@contrail/documents';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { buffer, debounceTime, take, tap } from 'rxjs/operators';
import { BoardsActions } from '../../boards-store';
import { Board } from '../board.service';
import { DocumentAssortmentHelper } from './document-assortment-helper';
import { DocumentAnnotationService } from '../document/document-annotation/document-annotation-service';
import { ObjectUtil } from '@contrail/util';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { DocumentItemService } from '../document/document-item/document-item.service';
import { DocumentService } from '../document/document.service';
import { AssortmentItem } from '@common/assortments/assortment-item';
import { Subject } from 'rxjs';

interface ActionData {
  actions: Array<DocumentAction>;
  document: Document;
}

@Injectable({
  providedIn: 'root',
})
export class BoardsBackingAssortmentService {
  private synchBackingAssortmentSubject = new Subject<ActionData>();

  constructor(
    private store: Store<RootStoreState.State>,
    private documentAnnotationService: DocumentAnnotationService,
    private documentService: DocumentService,
    private undoRedoService: UndoRedoService,
  ) {
    this.documentAnnotationService.annotationEvents.subscribe((annotationEvent) => {
      this.handleAnnotationEvent(annotationEvent);
    });

    /**Accumulates actions for 1000ms before calling API */
    const debounceSynchBackingAssortment$ = this.synchBackingAssortmentSubject.pipe(debounceTime(1000));
    const synchBackingAssortment$ = this.synchBackingAssortmentSubject.pipe(buffer(debounceSynchBackingAssortment$));
    synchBackingAssortment$.subscribe({
      complete: () => {},
      error: (err: any) => {
        console.log(err);
      },
      next: (data: ActionData[]) => {
        const actions = data.reduce((array, actionData) => array.concat(actionData.actions), []); // add all actions into a common array
        this.processDocumentActions(actions, [data[0].document]);
      },
    });
  }

  debounceProcessDocumentActions(actionData: { actions: Array<DocumentAction>; document: Document }) {
    this.synchBackingAssortmentSubject.next(actionData);
  }

  /** Handles document actions that may alter the backing assortment for this document */
  public async processDocumentActions(actions: Array<DocumentAction>, currentDocuments: Array<Document>) {
    if (!actions) {
      return;
    }

    let doDiff = false;
    // Get action with backingAssortmentItemData to restore values from deleted backingAssortmentItems during undo/redo.
    const backingAssortmentItemChanges = actions
      .filter((a) => a.changeDefinition.backingAssortmentItemData)
      .map((a) => a.changeDefinition.backingAssortmentItemData);

    for (const action of actions) {
      if (['ADD_ELEMENT', 'DELETE_ELEMENT', 'REBIND_MODEL'].includes(action.changeDefinition?.changeType)) {
        doDiff = true;
      }
    }

    if (doDiff) {
      const documentItemIds = DocumentAssortmentHelper.getItemIdsFromDocuments(currentDocuments);
      this.store
        .select(AssortmentsSelectors.backingAssortmentItems)
        .pipe(
          take(1),
          tap((data) => {
            const ais = data as AssortmentItem[];
            const itemIds = ais.map((ai) => ai.itemId);
            const adds = documentItemIds.filter((id) => !itemIds.includes(id));
            const removes = ais.filter((ai) => !documentItemIds.includes(ai.itemId)).map((ai) => ai.id);
            console.log('Adds: ', adds);
            console.log('removes: ', removes);
            if (adds?.length) {
              this.store.dispatch(
                AssortmentsActions.addItemsToBackingAssortment({
                  itemIds: adds,
                  changes: backingAssortmentItemChanges,
                }),
              );
            }
            if (removes?.length) {
              this.store.dispatch(AssortmentsActions.removeItemsFromBackingAssortment({ itemIds: removes }));
            }
          }),
        )
        .subscribe();
    }
  }

  public async initBackingAssortment(board: Board) {
    if (!board) {
      return;
    }
    let backingAssortmentId = board.backingAssortmentId;
    if (!backingAssortmentId) {
      const assortment = await this.createBackingAssortment(board);
      backingAssortmentId = assortment.id;
    }

    // Load store with backing assortment
    this.store.dispatch(AssortmentsActions.loadBackingAssortment({ assortmentId: backingAssortmentId }));
  }

  /** Handles init of backing assortment when there isn't one already. */
  private async createBackingAssortment(board: Board) {
    if (board.backingAssortmentId) {
      return;
    }
    let backingAssortment: any = { name: 'Board Backing Assortment: ' + board.id, workspaceId: board.workspaceId };
    backingAssortment = await new Entities().create({ entityName: 'assortment', object: backingAssortment });
    this.store.dispatch(
      BoardsActions.updateBoard({ id: board.id, changes: { backingAssortmentId: backingAssortment.id } }),
    );
    return backingAssortment;
  }

  private async handleAnnotationEvent(annotationEvent: any) {
    const annotationOption = this.documentAnnotationService
      .getAnnotationOptions()
      ?.find((option) => option.type === annotationEvent.eventType);
    let assortmentItems = annotationEvent.selectedElements?.filter(DocumentItemService.isItemComponet);
    if (assortmentItems?.length > 0 && annotationOption) {
      assortmentItems = assortmentItems.map((element) => element.modelBindings.item.split(':')[1]);
      await this.updateAssortmentItemsByProperty(assortmentItems, annotationOption.property);
      this.adjustComponentSize(annotationEvent);
    }
  }

  private adjustComponentSize(annotationEvent: any) {
    const actions: DocumentAction[] = [];
    for (let element of annotationEvent.selectedElements) {
      const undoChangeElement = ObjectUtil.cloneDeep(element);
      const newElements = this.documentService.updateSizeAndPositionForPropertyElements(element.elements, element);
      element.elements = newElements;
      const action = new DocumentAction(
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: element.id,
          elementData: element,
        },
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: element.id,
          elementData: undoChangeElement,
        },
      );
      actions.push(action);
    }
    if (actions.length > 0) {
      this.documentService.handleDocumentActions(actions);
    }
  }

  private async updateAssortmentItemsByProperty(itemIds: string[], property) {
    const backingItems = await this.getItemsFromBackingAssortment();
    const updatingItems = ObjectUtil.cloneDeep(
      backingItems.filter((backingItem) => itemIds.includes(backingItem.itemId)),
    );
    const changes = [];
    updatingItems.forEach((updatingItem) => {
      const item = {};
      if (!updatingItem.hasOwnProperty(property)) {
        item[property] = true;
      } else {
        item[property] = !updatingItem[property];
      }

      changes.push({ id: updatingItem.id, changes: item });
    });
    const originalData = updatingItems.map((updatingItem) => {
      return { id: updatingItem.id, changes: updatingItem };
    });
    this.undoRedoService.addUndo([
      {
        actionType: 'backing-assortment',
        changeDefinition: changes,
        undoChangeDefinition: originalData,
      },
    ]);
    this.updateAssortmentItems(changes);
  }

  private updateAssortmentItems(changes) {
    this.store.dispatch(AssortmentsActions.updateBackingAssortmentItems({ changes }));
  }

  private async getItemsFromBackingAssortment(): Promise<Array<any>> {
    const items: Set<any> = new Set();
    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .pipe(
        take(1),
        tap((ais) => {
          ais.forEach((ai: any) => {
            items.add(ai);
          });
        }),
      )
      .subscribe();
    return [...items];
  }

  public async getAssortmentItemsFromBackingAssortment(itemIds: string[]): Promise<Array<any>> {
    let backingAssortmentItems: any[] = [];
    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .pipe(
        take(1),
        tap((ais) => {
          backingAssortmentItems = ais.filter((ai: any) => itemIds.includes(ai.itemId));
        }),
      )
      .subscribe();
    return backingAssortmentItems;
  }

  handleAssortmentItemChanges(changes) {
    this.store.dispatch(
      AssortmentsActions.updateBackingAssortmentItemsSuccess({ changes: ObjectUtil.cloneDeep(changes) }),
    );
  }

  handleAssortmentAddItems(changes) {
    this.store.dispatch(
      AssortmentsActions.addItemsToBackingAssortmentSuccess({ assortmentItems: ObjectUtil.cloneDeep(changes) }),
    );
  }

  handleAssortmentRemoveItems(changes) {
    this.store.dispatch(
      AssortmentsActions.removeItemsFromBackingAssortmentSuccess({ ids: ObjectUtil.cloneDeep(changes) }),
    );
  }
}
