export interface State {
  customFonts: string;
  customFontFamilies: Array<String>;
  loaded: boolean;
}
export const initialState: State = {
  customFonts: null,
  customFontFamilies: [],
  loaded: false,
};
