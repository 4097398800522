import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { Content } from '@contrail/sdk';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { ContentHolderService } from '../content-holder.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-content-upload',
  template: `
    <app-mat-spinner [overlay]="true" [loading]="uploading$ | async" [message]="message"></app-mat-spinner>
    <div class="upload-container">
      <div class="drop-zone">
        <div class="flex flex-col justify-center items-center pt-5">
          <mat-icon class="upload-icon"> file_upload </mat-icon>
          <div class="message py-2" *ngIf="editable">
            Drag files here
            <span class="underline cursor-pointer" (click)="fileUpload.click()">or select.</span>
          </div>
          <input #fileUpload type="file" style="display:none" (change)="handleFileSelection($event)" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply flex items-center justify-center w-full;
      }
      .upload-container {
        @apply flex flex-col text-blue-600;
      }
      .upload-icon {
        @apply w-8 h-8 text-[32px];
      }

      .drop-zone {
        @apply h-[100px] w-[274px] text-blue-600 rounded-none;
        border: 2px dashed #1e88e5;
      }
      ::ng-deep .drop-zone input:focus + ngx-dropzone-label {
        outline: none !important;
      }
    `,
  ],
})
export class ContentUploadComponent implements OnInit {
  message = `Uploading your file. Please wait.`;
  uploadInProgress = false;

  @Input() itemId: string;
  @Input() editable = true;
  @Output() contentUploaded = new EventEmitter();
  public uploading$: Observable<boolean>;

  constructor(private contentHolderService: ContentHolderService) {
    this.uploading$ = this.contentHolderService.contentUploading$;
  }

  ngOnInit() {}
  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      this.contentHolderService.addContentToContentHolder(files);
    }
  }

  handleFileSelection($event: any) {
    const files = $event?.target?.files;
    this.contentHolderService.addContentToContentHolder(files);
  }
}
