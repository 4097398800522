import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { CanvasDocument } from 'src/app/boards/board/canvas/canvas-document';

export enum PDF_ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export interface PdfExportData {
  imageData: string;
  width: number;
  height: number;
  document?: CanvasDocument;
}

@Injectable({
  providedIn: 'root',
})
export class PdfExport {
  private document;
  constructor() {}

  public dataUrlsToBlob(data: PdfExportData[]): Blob {
    this.document = null;
    data.forEach((d) => {
      this.addPageToDoc(d.imageData, d.width, d.height);
    });
    return this.document.output('blob');
  }

  private addPageToDoc(imageData, width, height) {
    const format = [height, width];
    const orientation = width >= height ? PDF_ORIENTATION.LANDSCAPE : PDF_ORIENTATION.PORTRAIT;
    this.document
      ? this.document.addPage(format, orientation)
      : (this.document = new jsPDF({
          orientation,
          format,
          unit: 'px',
        }));
    this.document.addImage(imageData, 'JPEG', 0, 0, width, height);
  }
}
