export class FeatureFlag {
  id: string;

  createdOn?: Date;
  updatedOn?: Date;

  createdById?: string;
  updatedById?: string;

  createdBy?: any;
  updatedBy?: any;

  orgId?: string;

  featureName: string;
}

export enum Feature {
  EXTENSIONS = 'EXTENSIONS',
  ASSET_LIBRARY = 'ASSET_LIBRARY',
  COLOR_LIBRARY = 'BOARD_COLORS',
  SHOWROOM = 'SHOWROOM',
  DEV_TABS = 'DEV_TABS',
  ITEM_BOARDS = 'ITEM_BOARDS',
  DOCUMENT_GENERATION = 'DOCUMENT_GENERATION',
  DASHBOARD = 'DASHBOARD',
  ITEM_CONTEXT = 'ITEM_CONTEXT',
  ITEM_CREATION = 'ITEM_CREATION',
  ITEM_CHOOSER_LEVEL_SELECTION = 'ITEM_CHOOSER_LEVEL_SELECTION',
  SVG_RECOLORING = 'SVG_RECOLORING',
  EDIT_ENTITIES = 'EDIT_ENTITIES',
  ASSIGN_ITEM_TO_COMPONENT = 'ASSIGN_ITEM_TO_COMPONENT',
}
