import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AssortmentHistoryDetail, AssortmentHistoryItem } from './assortment-history.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AssortmentHistoryService {
  constructor() {}

  public async getAssortmentHistoryItems(
    assortmentId: string,
    relations?: Array<string>,
  ): Promise<AssortmentHistoryItem[]> {
    return new Entities().get({
      entityName: 'assortment',
      id: assortmentId,
      suffix: 'history',
      relations: relations?.length ? relations : [],
    });
  }

  public async getAssortmentHistoryItem(
    assortmentId: string,
    historyItemId: string,
    relations?: Array<string>,
  ): Promise<AssortmentHistoryItem> {
    const historyItem: AssortmentHistoryItem = await new Entities().get({
      entityName: 'assortment',
      id: assortmentId,
      suffix: `history/${historyItemId}`,
      relations: relations?.length ? relations : [],
    });

    if (historyItem.detail) {
      const { adds, updates, deletes, familyItemsRemoved } = await this.getHistoryAddUpdateDeleteDetails(
        historyItem.detail,
      );
      historyItem.detail.adds = adds;
      historyItem.detail.updates = updates;
      historyItem.detail.deletes = deletes;
      historyItem.detail.familyItemsRemoved = familyItemsRemoved;
    }

    return historyItem;
  }

  private async getHistoryAddUpdateDeleteDetails(
    history: AssortmentHistoryDetail,
  ): Promise<{ adds: any[]; updates: any[]; deletes: any[]; familyItemsRemoved: any[] }> {
    const addsPromise = history.addsDownloadURL
      ? this.getDataFromDownloadUrl(history.addsDownloadURL)
      : Promise.resolve(history.adds ?? []);

    const updatesPromise = history.updatesDownloadURL
      ? this.getDataFromDownloadUrl(history.updatesDownloadURL)
      : Promise.resolve(history.updates ?? []);

    const deletesPromise = history.deletesDownloadURL
      ? this.getDataFromDownloadUrl(history.deletesDownloadURL)
      : Promise.resolve(history.deletes ?? []);

    const familyItemsPromise = history.familyItemsRemovedDownloadURL
      ? this.getDataFromDownloadUrl(history.familyItemsRemovedDownloadURL)
      : Promise.resolve(history.familyItemsRemoved ?? []);

    const [adds, updates, deletes, familyItemsRemoved] = await Promise.all([
      addsPromise,
      updatesPromise,
      deletesPromise,
      familyItemsPromise,
    ]);
    return { adds, updates, deletes, familyItemsRemoved };
  }

  private async getDataFromDownloadUrl(url: string) {
    const response = await fetch(url);
    return await response.json();
  }
}
