import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-modal-comments',
  templateUrl: './content-modal-comments.component.html',
  styleUrls: ['./content-modal-comments.component.scss'],
})
export class ContentModalCommentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
