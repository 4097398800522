import { EditorCalc } from '../../components/editor/editor-calc';
import { ImageElement } from '../../components/image-element/image-element';
import { DEFAULT_LETTER_SPACING, DEFAULT_LINE_HEIGHT } from './editor/text-editor';
import { FontFaces } from './font-faces';
import { TextStyle } from './text-style';

export interface TextImageGeneratorOptions {
  width: number;
  height: number;
  originalWidth?: number;
  originalHeight?: number;
  scale?: number;
  padding: number;
  wordWrap?: 'normal' | 'break-word' | 'initial' | 'inherit';
}

export class TextImageGenerator {
  static fontMap = {};
  static fontFaces = ''; // This contains font-face data with base64 encoding that is then embedded in the svg
  static customFontFaces = '';
  static customFontFacesMap: Map<string, string> = new Map();
  static defaultFontFacesMap: Map<string, string> = new Map();
  static commonStyle = `
    p{margin-top:0px;margin-bottom:0px}ol,ul{margin-block-start:0rem;margin-block-end:0rem;padding-inline-start:0px;}ul{list-style:disc;}ol{list-style: decimal;}.url-link {text-decoration:underline;color:#3578ff}ul li, ol li{padding-left: 1.5em;list-style-type: none;}ul li::before, ol li::before{margin-left: -1em;float:left;text-align:center}ul li::before{content: "\\2022";width:0.6em;}strong{font-weight:700;}
    ol, ol > li > ol > li > ol > li > ol, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {counter-reset: item}
    ol > li > ol, ol > li > ol > li > ol > li > ol > li > ol, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol { counter-reset: letter; }
    ol > li > ol > li > ol, ol > li > ol > li > ol > li > ol > li > ol > li > ol, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol { counter-reset: roman; }
    ol > li::before, ol > li > ol > li > ol > li > ol li::before, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before {content: counter(item, decimal) ". "; counter-increment: item;}
    ol > li > ol li::before, ol > li > ol > li > ol > li > ol > li > ol li::before, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before { content: counter(letter, lower-alpha) ". "; counter-increment: letter;}
    ol > li > ol > li > ol li::before, ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before, ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before { content: counter(roman, lower-roman) ". "; counter-increment: roman;}
    `;

  static getFontFaces(href) {
    return fetch(href)
      .then((res) => res.text())
      .then((fontFaces) => {
        const fontFacesArray = [];
        let index = fontFaces.indexOf('/* latin */'); // get the first latin font-face for font-weight 400 (normal)
        let endIndex = fontFaces.indexOf('}', index);
        let fontFace = fontFaces.substring(index, endIndex + 1);
        fontFacesArray.push(fontFace);
        index = fontFaces.indexOf('/* latin */', endIndex); // get the second latin font-face for font-weight 700 (bold)
        endIndex = fontFaces.indexOf('}', index);
        fontFace = fontFaces.substring(index, endIndex + 1);
        fontFacesArray.push(fontFace);
        let formattedFontFaces = fontFacesArray.join('');
        return Promise.all(
          formattedFontFaces.match(/https:\/\/[^)]+/g).map(
            (location) =>
              new Promise((resolve, reject) => {
                fetch(location)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', ({ target: { result } }) => resolve([location, result]));
                    reader.readAsDataURL(blob);
                  })
                  .catch(reject);
              }),
          ),
        ).then((replacements) => {
          replacements.forEach((replacement) => {
            formattedFontFaces = formattedFontFaces.replace(replacement[0], replacement[1]);
          });
          return formattedFontFaces;
        });
      });
  }

  static writeToBlob(text: any) {
    var textFile;
    var data = new Blob([text], { type: 'text/plain' });
    textFile = window.URL.createObjectURL(data);
    console.log('Open this file in the new tab to get fonts in base64:', textFile);
  }

  /**
   * Fetch local file @href , get all font URLs, fetch them
   * and convert to base64 data URI.
   * @param href
   * @returns
   */
  static readCustomFontsAsBase64(href) {
    return fetch(href)
      .then((res) => res.text())
      .then((fontFaces) => {
        let formattedFontFaces = fontFaces;
        return Promise.all(
          formattedFontFaces.match(/\/assets\/[^"]+/g).map(
            (location) =>
              new Promise((resolve, reject) => {
                fetch(location)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', ({ target: { result } }) => resolve([location, result]));
                    reader.readAsDataURL(blob);
                  })
                  .catch(reject);
              }),
          ),
        ).then((replacements) => {
          replacements.forEach((replacement) => {
            formattedFontFaces = formattedFontFaces.replace(replacement[0], replacement[1]);
          });
          return formattedFontFaces;
        });
      });
  }

  static fetchTextFile(href) {
    return fetch(href).then((res) => res?.text());
  }

  /**
   *
   */
  // static getCustomFonts(customFontFaces) {
  //   try {
  //     const test = new RegExp(/org\/(.*?)\//).exec(window.location.pathname);
  //     const org = test?.length ? test[1] : null;
  //     if (org) {
  //       const customFontFileName = `${org}.css`;
  //       this.fetchTextFile(customFontFileName).then(fontFaces => {
  //         if (fontFaces && (typeof fontFaces == 'string')) {
  //           this.customFontFaces = customFontFaces;
  //           this.customFontFacesMap = this.extractFontFaces(customFontFaces);
  //           if (this.customFontFacesMap?.size > 0) {
  //             console.log('Custom font families found:', [...this.customFontFacesMap?.keys()])
  //           }
  //         }
  //       });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }

  // }

  static {
    // this.fontFaces = FontFaces.Roboto;
    this.defaultFontFacesMap.set('Roboto', FontFaces.Roboto);
    this.defaultFontFacesMap.set('Avenir', FontFaces.Avenir);
  }

  static init(customFonts: string) {
    // this.getFontFaces('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap').then(fontFaces => {
    //   this.fontFaces = fontFaces;
    //   this.writeToBlob(fontFaces)
    // });

    // this.readCustomFontsAsBase64('yulia-zone-1.css').then(fontFaces => {
    //   this.writeToBlob(fontFaces)
    // });
    if (customFonts && this.customFontFacesMap?.size === 0) {
      this.customFontFaces = customFonts;
      this.customFontFacesMap = this.extractFontFaces(customFonts);
      if (this.customFontFacesMap?.size > 0) {
        console.log('Custom font families found:', [...this.customFontFacesMap?.keys()]);
      }
    }
  }

  /**
   * Finds all font families used in @fontFaces and
   * creates a Map of <font family name>: <font face CSS string>.
   * @param fontFaces
   * @returns
   */
  static extractFontFaces(fontFaces: string): Map<string, string> {
    try {
      const regex = /@font-face\s*{([\s\S]*?)}/g;

      let temp;
      const fontFacesMap = new Map();

      while ((temp = regex.exec(fontFaces))) {
        const fontFaceRule = temp[0];
        const fontFamily = this.getFontFamilies(fontFaceRule)[0];
        if (!fontFacesMap.get(fontFamily)) {
          fontFacesMap.set(fontFamily, fontFaceRule);
        } else {
          fontFacesMap.set(fontFamily, fontFacesMap.get(fontFamily) + '\n' + fontFaceRule);
        }
      }
      return fontFacesMap;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Returns a list of unique font family names found in @text
   * @param text - string with inline CSS with font-family styles, eg <span style="font-family: Roboto; font-size: 24px;">Hello</span><span style="font-family: 'Times New Roman';">World</span>
   * @returns - a list of unique font family names, eg ['Roboto', 'Timew New Roman']
   */
  static getFontFamilies(text: string): Array<string> {
    if (!text || text === '') {
      return [];
    }
    try {
      const regex = /font-family:\s*([^;]*)/g;
      let temp;
      let matches = [];

      while ((temp = regex.exec(text))) {
        matches.push(temp[1]?.replace(/('|")*/g, ''));
      }
      return [...new Set(matches)];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /**
   * Get font-face rules for font names in @fontFamilies
   * @param fontFamilies
   * @returns
   */
  static getCustomFontFaces(fontFamilies: Array<string>): string {
    let customFontFaces = '';
    for (let i = 0; i < fontFamilies?.length; i++) {
      const fontFamily = fontFamilies[i];
      const fontFaceRule = this.customFontFacesMap.get(fontFamily) || this.defaultFontFacesMap.get(fontFamily);
      if (fontFaceRule) {
        customFontFaces = customFontFaces + '\n' + fontFaceRule;
      }
    }
    return customFontFaces;
  }

  public static async getGenerateTextImagePromise(
    text: string,
    options: TextImageGeneratorOptions,
    textStyle: TextStyle,
    editable = false,
    scale = 0,
    toCanvas = true,
  ): Promise<ImageElement> {
    const fontFamilies = this.getFontFamilies(text); // get font family names used in text
    if (
      textStyle?.fontFamily &&
      !Array.isArray(textStyle.fontFamily) &&
      fontFamilies.indexOf(textStyle.fontFamily) === -1
    ) {
      fontFamilies.push(textStyle?.fontFamily);
    }
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
    const padding = options.padding;
    const width = options.width;
    const height = options.height;
    const customFontFaces = this.getCustomFontFaces(fontFamilies); // get custom font-face rules for fontFamilies
    let svgStyleString = '';
    let foreignObjStyleString = '';
    let divStyleString = ''; // inline block prevents margin collapsing between div and inner elements. For example, lists.
    let firefoxDivStyleString = '';
    let styleString =
      textStyle.toStyleString() +
      `font-kerning:auto;line-height:${DEFAULT_LINE_HEIGHT};letter-spacing:${DEFAULT_LETTER_SPACING}px;overflow-wrap:break-word${options?.wordWrap ? ';word-wrap:' + options.wordWrap : ''};`;
    if (editable) {
      if (isFirefox) {
        divStyleString =
          styleString +
          `padding:${padding}px;width:${width - options.padding * 2 - textStyle.numericBorderWidth * 2}px;height:${height - options.padding * 2 - textStyle.numericBorderWidth * 2}px;`;
      } else {
        foreignObjStyleString = styleString + `padding:${padding}px;width:${width}px;height:${height}px;`;
      }
    } else {
      foreignObjStyleString =
        textStyle.toStyleString() + ` text-overflow:ellipsis; overflow:hidden; width:${width}px; height:${height}px;`;
    }

    let transform = [];
    if (editable && (textStyle?.textVAlign === 'bottom' || textStyle?.textVAlign === 'middle')) {
      const size = EditorCalc.getSize(
        text,
        styleString +
          `width:${options.originalWidth ?? width - (options.originalWidth ? 0 : padding * 2) - textStyle.numericBorderWidth * 2}px;`,
      );
      const valign = textStyle.textVAlign;
      const borderWidth = textStyle.numericBorderWidth;
      transform.push(
        EditorCalc.getVerticalTransform(
          valign,
          options.originalHeight ?? height,
          options.originalHeight ? 0 : padding,
          borderWidth,
          size.height,
        ),
      );
    }

    if (options.scale && options.originalWidth && options.originalHeight) {
      transform.push(`scale(${options.scale})`);
      divStyleString = divStyleString + `width: ${options.originalWidth}px; height: ${options.originalHeight}px;`;
    }

    if (transform.length > 0) {
      if (isFirefox) {
        firefoxDivStyleString =
          firefoxDivStyleString + ` transform: ${transform.join(' ')}; transform-origin: left top;`;
      } else {
        divStyleString = divStyleString + ` transform: ${transform.join(' ')}; transform-origin: left top;`;
      }
    }

    // https://www.w3.org/TR/xml/#syntax
    // The ampersand character (&) and the left angle bracket (<) must not appear in their literal form
    // and should be escaped using either numeric character references or the strings " &amp; " and " &lt; " respectively.
    // The right angle bracket (>) may be represented using the string " &gt; "
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(text, 'text/html');
    // let body = doc.body || document.createElement('body');
    // const cleanHTML = body.innerHTML;
    // body = null;

    // Escape ampersand
    // Use negative lookahead to ignore already escaped characters
    const cleanHTML = text?.replace(/&(?!(?:apos|quot|[gl]t|amp);|#)/g, '&amp;');

    return new Promise(async (resolve, reject) => {
      const regex0 = /class="(.*?)"/g;
      const regex1 = /<a/g;
      const regex2 = /href="(.*?)"/g;
      const regex3 = /<\/a>/g;
      const regex4 = /<br\s*\/?>/gi; // <br> tag
      const style = `<style>${this.commonStyle}${customFontFaces}</style>`;
      let rawData =
        `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" style="${svgStyleString}">` +
        `<foreignObject width="100%" height="100%" style="${foreignObjStyleString}">` +
        style +
        `<div xmlns="http://www.w3.org/1999/xhtml" style="${divStyleString}">` +
        (isFirefox ? `<div xmlns="http://www.w3.org/1999/xhtml" style="${firefoxDivStyleString}">` : '') +
        cleanHTML +
        (isFirefox ? `</div>` : '') +
        '</div>' +
        '</foreignObject>' +
        '</svg>';

      // Replace all <a> tag with <span> and remove all class tags as well as they are not useful for image generation
      // Insert a new css class called url-link that a blue highlighted text for hyperlinks.
      rawData = rawData
        .replace(regex0, '')
        .replace(regex1, '<span')
        .replace(regex2, 'class="url-link"')
        .replace(regex3, '</span>')
        .replace(regex4, '<p> </p>')
        .replace(regex4, '<br/>');
      const data = 'data:image/svg+xml,' + encodeURIComponent(rawData);
      (toCanvas ? ImageElement.toCanvas(data, scale) : ImageElement.fromURL(data))
        .then((img) => {
          resolve(img);
        })
        .catch((error) => {
          console.log('TextImageGenerator.getGenerateTextImagePromise: error generating text image', error, text);
          resolve(null);
        });
    });
  }
}
