import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UndoRedoActions } from './undo-redo-store';
import { finalize, last, map, take } from 'rxjs/operators';
import { selectLatestRedoAction, selectLatestUndoAction } from './undo-redo-store/undo-redo.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UndoRedoService {
  constructor(private store: Store) {}

  addUndo(changeDefinition: any) {
    this.store.dispatch(UndoRedoActions.removeAllRedos());
    this.store.dispatch(UndoRedoActions.addUndo({ changeDefinition }));
  }

  getNextUndo(): Observable<any> {
    return this.store.pipe(
      select(selectLatestUndoAction),
      map((undoAction) => {
        return undoAction;
      }),
      take(1),
      finalize(() => {
        return this.store.dispatch(UndoRedoActions.moveLatestUndoToRedo());
      }),
    );
  }

  getNextRedo(): Observable<any> {
    return this.store.pipe(
      select(selectLatestRedoAction),
      map((redoAction) => {
        return redoAction;
      }),
      take(1),
      finalize(() => {
        return this.store.dispatch(UndoRedoActions.moveLatestRedoToUndo());
      }),
    );
  }
}
