<div class="object-details w-full" (debounceClick)="handleClick()" appDebounceClick="100">
  <div class="selector-checkbox" *ngIf="allowAddMultiple && entity.entityType === this.entityType">
    <mat-checkbox
      color="primary"
      [attr.data-test]="'chooser-checkbox-' + entity.id"
      (click)="$event.stopPropagation()"
      (change)="toggleCheckbox($event)"
      [checked]="selectedEntityIndex > 0"
    ></mat-checkbox>
  </div>
  <div class="image-placeholder" *ngIf="!image && !entity.hex">
    <mat-icon class="!w-full !h-full" svgIcon="placeholder-item-option-image"></mat-icon>
  </div>

  <div *ngIf="image" class="image-holder">
    <img [attr.src]="image | secureImage | async" class="w-full h-full object-contain" />
  </div>
  <div *ngIf="!entity.thumbnail && entity.hex" class="image-holder">
    <div class="color-card" [style.backgroundColor]="'#' + entity.hex"></div>
  </div>

  <div class="flex flex-col ml-3">
    <div class="line-ellipsis max-w-[220px]">{{ name | shorten: 30 }}</div>
    <ng-container *ngIf="entity?.entityType === 'color'">
      <span class="hex meta">Hex: #{{ entity?.hex | uppercase }}</span>
      <span class="rgb meta">RGB: {{ entity?.red }}-{{ entity?.green }}-{{ entity?.blue }}</span>
    </ng-container>
    <ng-container *ngIf="entity?.entityType === 'asset'">
      <span class="type meta">Type: {{ entity?.contentType }}</span>
    </ng-container>
  </div>

  <div class="actions ml-auto mr-2 flex-center">
    <span class="material-icons" *ngIf="entity?.entityType === 'workspace'"> chevron_right </span>
    <span class="material-icons" *ngIf="showDetailIcon" (click)="handleDetailsClicked()">info</span>

    <button
      mat-button
      [attr.data-test]="'add-single-item-button-' + entity.id"
      *ngIf="allowAddMultiple && selectedEntityIndex === 0 && entity.entityType === this.entityType"
      (click)="addEntityData(entity)"
    >
      <mat-icon>{{ 'add_circle_outline' }}</mat-icon>
    </button>
    <div [attr.data-test]="'selected-item-index' + entity.id" class="selected-item" *ngIf="selectedEntityIndex > 0">
      {{ selectedEntityIndex }}
    </div>
  </div>
</div>
