import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { SearchReplaceActions, SearchReplaceSelectors } from '@common/search-replace/search-replace-store';
import { DocumentService } from '../document/document.service';
import { DocumentElement } from '@contrail/documents';
import { DocumentActions } from '../document/document-store';

@Injectable({
  providedIn: 'root',
})
export class BoardSearchService {
  private elements: Array<DocumentElement>;
  ACTIVE_ELEMENT = '#BBDEFB';
  currentFrameId: string;
  showcase: any;
  sourceAssortmentItemData: any[];
  backingAssortmentItemData: any[];
  searchResults: string[] = [];
  activeSearchResultElement: string;
  searchActive = false;
  searchKeyword = '';
  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    this.store.select(SearchReplaceSelectors.searchActive).subscribe((searchActive) => {
      if (this.searchActive && !searchActive) {
        this.removeAllElementHighlights();
      }
      this.searchActive = searchActive;
    });
    this.store.select(AssortmentsSelectors.sourceAssortmentItemData).subscribe((sourceAssortmentItemData) => {
      this.sourceAssortmentItemData = sourceAssortmentItemData;
    });
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      this.backingAssortmentItemData = backingAssortmentItemData;
    });
    this.documentService.documentElements.subscribe((elements) => (this.elements = elements));

    this.store.select(SearchReplaceSelectors.activeSearchResultElement).subscribe((activeSearchResultElement) => {
      if (activeSearchResultElement) {
        if (this.activeSearchResultElement) {
          this.documentService.highlightElement(this.activeSearchResultElement); // reset to regular
        }
        setTimeout(() => {
          this.documentService.highlightElement(activeSearchResultElement, this.ACTIVE_ELEMENT);
          this.navigateToSelectedElement(activeSearchResultElement);
        }, 100);
      } else {
        if (this.activeSearchResultElement) {
          if (this.searchResults.includes(this.activeSearchResultElement)) {
            this.documentService.highlightElement(this.activeSearchResultElement); // reset to regular
          } else {
            this.documentService.dehighlightElement(this.activeSearchResultElement); // remove highlight
          }
        }
      }
      this.activeSearchResultElement = activeSearchResultElement;
    });
  }

  public async search(keyword: string) {
    this.searchKeyword = keyword;
    const searchResults: string[] = [];
    if (keyword.length > 0) {
      this.searchSVG(keyword, searchResults);
    }
    // remove current highlights
    if (searchResults.length > 0) {
      this.store.dispatch(
        SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: searchResults[0] }),
      );
      this.navigateToSelectedElement(searchResults[0]);

      this.searchResults.forEach((searchResultItem) => {
        this.documentService.dehighlightElement(searchResultItem);
      });
      searchResults.forEach((searchResultItem) => {
        this.documentService.highlightElement(searchResultItem);
      });
    }
    if (searchResults.length === 0) {
      this.removeAllElementHighlights();
      this.store.dispatch(SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: null }));
    }
    this.searchResults = searchResults;
    this.store.dispatch(SearchReplaceActions.setSearchResults({ searchResults }));
  }

  private removeAllElementHighlights() {
    this.searchResults.forEach((searchResultItem) => {
      this.documentService.dehighlightElement(searchResultItem);
    });
    this.searchResults = [];
  }

  private searchSVG(keyword: string, searchResults: string[]) {
    const results = [];
    const componentElements = this.elements?.filter((element) => element.type === 'component');
    const textElements = this.elements?.filter((element) => element.type === 'text' || element.type === 'sticky_note');
    componentElements.forEach((componentElement) => {
      const filteredElems = componentElement.elements.filter(
        (element) => (element.text + '').toLowerCase().indexOf(keyword.toLowerCase()) > -1,
      );
      if (filteredElems.length > 0) {
        results.push(componentElement.id);
      }
    });
    textElements.forEach((textElement) => {
      const text = new DOMParser().parseFromString(textElement.text, 'text/html').body.textContent;
      if (text?.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
        results.push(textElement.id);
      }
    });
    if (results.length > 0) {
      searchResults.push(...results);
    }
  }

  private navigateToSelectedElement(documentElementId?: any) {
    const selectedElement = this.elements.find((element) => element.id === documentElementId);
    this.store.dispatch(
      DocumentActions.navigateToPosition({
        position: {
          x: (selectedElement ? selectedElement.position.x : 0) + 180,
          y: selectedElement ? selectedElement.position.y : 0,
        },
      }),
    );
  }
}
