<div class="'filter-panel">
  <div class="filters">
    <h6>Filters</h6>
    <div class="flex flex-col space-y-4 m-2" *ngIf="filterDefinition?.filterPropertyDefinitions">
      <div
        class="flex flex-row items-center"
        *ngFor="let filterPropertyDefinition of filterDefinition.filterPropertyDefinitions"
      >
        <app-type-property-form-field
          *ngIf="filterPropertyDefinition.slug !== 'createdOn'"
          #formField
          appearance=""
          [propertyFormConfiguration]="{ typeProperty: filterPropertyDefinition, isFilter: true }"
          [value]="criteriaValues[filterPropertyDefinition.slug]"
          (valueChange)="handleValueChange($event, filterPropertyDefinition)"
        >
        </app-type-property-form-field>
        <div class="min-w-[24px] text-right" *ngIf="filterPropertyDefinition.slug !== 'createdOn'">
          <mat-icon
            *ngIf="criteriaValues[filterPropertyDefinition.slug]"
            class="!text-[16px] !leading-[16px] !h-4 !w-4 cursor-pointer"
            (click)="removeFilter(filterPropertyDefinition)"
            >close</mat-icon
          >
        </div>
      </div>
      <app-date-filter
        #dateFilter
        [dateFilterAttribute]="dateFilterAttribute"
        (dateFilterChanged)="dateFilterChanged($event)"
      >
      </app-date-filter>
    </div>

    <div class="flex item-center justify-end min-h-[16px]">
      <div *ngIf="!emptyCriteriaValues">
        <div class="cursor-pointer" (click)="clearAllFilterProperties()">Clear all</div>
      </div>
    </div>
  </div>
</div>
