import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentsModule } from '@common/components/components.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorWidgetComponent } from './color-widget/color-widget.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ChooserModule } from '@common/chooser/chooser.module';
import { EntityDetailsModule } from '@common/entity-details/entity-details.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorDetailComponent } from './color-detail/color-detail.component';

@NgModule({
  declarations: [ColorWidgetComponent, ColorDetailComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    OverlayModule,
    ColorPickerModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    EntityDetailsModule,
    MatDividerModule,
    ComponentsModule,
    PipesModule,
    DragDropModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    ChooserModule,
  ],
  exports: [ColorWidgetComponent, ColorDetailComponent],
})
export class ColorModule {}
