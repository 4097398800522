import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { DocumentElement } from '@contrail/documents';
import pLimit from 'p-limit';
const limit = pLimit(10);

export interface DocumentElementEntity extends DocumentElement {}
export interface DocumentElementSortOrder {
  id: string;
  sortOrder: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentManagerService {
  constructor() {}

  async updateDocument(id: string, document: any) {
    return new Entities().update({ entityName: 'document', id, object: document });
  }

  async getDocument(id: string) {
    const document = await new Entities().get({
      entityName: 'document',
      id,
      relations: ['elements', 'documentElementSortOrder'],
    });

    if (document.elementsDownloadURL) {
      const response = await fetch(document.elementsDownloadURL);
      const elements = await response.json();
      document.elements = elements;
    }
    return document;
  }

  async updateDocumentElement(id: string, documentElement: any) {
    return new Entities().update({ entityName: 'document-element', id, object: documentElement, suffix: 'async' });
  }

  async updateDocumentElements(documentElements: any) {
    return new Entities().batchUpdate({ entityName: 'document-element', objects: documentElements, suffix: 'async' });
  }

  async deleteDocumentElement(id: string) {
    await new Entities().delete({ entityName: 'document-element', id, suffix: 'async' });
    return id;
  }

  async deleteDocumentElements(ids: Array<string>) {
    const chunks = this.chunkRequest(ids);
    const promises: Array<Promise<string>> = [];
    for (const chunk of chunks) {
      promises.push(new Entities().batchDelete({ entityName: 'document-element', ids: chunk, suffix: 'async' }));
    }
    return Promise.all(promises).then(() => ids);
  }

  async syncDeleteDocumentElements(ids: Array<string>) {
    const chunks = this.chunkRequest(ids);
    const promises: Array<Promise<string>> = [];
    for (const chunk of chunks) {
      const promise = limit(async () => {
        return await new Entities().batchDelete({ entityName: 'document-element', ids: chunk });
      });
      promises.push(promise);
    }
    return Promise.all(promises).then(() => ids);
  }

  async createDocumentElement(documentElement: any) {
    return new Entities().create({ entityName: 'document-element', object: documentElement, suffix: 'async' });
  }

  async createDocumentElements(documentElements: any) {
    const chunks = this.chunkRequest(documentElements);
    const promises: Array<Promise<any>> = [];
    for (const chunk of chunks) {
      promises.push(new Entities().batchCreate({ entityName: 'document-element', objects: chunk, suffix: 'async' }));
    }
    return Promise.all(promises);
  }

  async syncCreateDocumentElements(documentElements: any) {
    const chunks = this.chunkRequest(documentElements);
    const promises: Array<Promise<any>> = [];
    for (const chunk of chunks) {
      const promise = limit(async () => {
        return await new Entities().batchCreate({ entityName: 'document-element', objects: chunk });
      });
      promises.push(promise);
    }
    return Promise.all(promises);
  }

  async getDocumentElementSortOrder(id: string) {
    return new Entities().get({
      entityName: 'document-element-sort-order',
      id,
    });
  }

  async updateDocumentElementSortOrder(id: string, sortIds: Array<any>, targetSortIndex) {
    return new Entities().update({
      entityName: 'document-element-sort-order',
      id,
      object: {
        sortIds,
        targetSortIndex,
      },
    });
  }

  async batchUpdateDocumentElementSortOrder(id: string, sorts: Array<any>) {
    return new Entities().update({
      entityName: 'document-element-sort-order',
      id,
      object: sorts,
    });
  }

  private chunkRequest(objects: Array<any>) {
    const numberPerChunk = 200; // chunk request payload into chunk of 200
    const chunkedArray = objects.reduce((resultArray, obj, index) => {
      const chunkIndex = Math.floor(index / numberPerChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(obj);
      return resultArray;
    }, []);
    return chunkedArray;
  }
}
