import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { documentManagerReducer } from './document-manager.reducers';
import { EffectsModule } from '@ngrx/effects';
import { DocumentManagerEffects } from './document-manager.effects';
import { DocumentElementEffects } from './document-elements/document-element.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('documentManager', documentManagerReducer),
    EffectsModule.forFeature([DocumentManagerEffects, DocumentElementEffects]),
  ],
})
export class DocumentManagerStoreModule {
  constructor() {}
}
