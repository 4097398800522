import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { ImageElement } from '../../components/image-element/image-element';
import { FileDownloader } from '../../file-downloader';
import { SVGHelper } from '../../svg-helper';
import { CanvasElement } from '../canvas-element';

export class SVGCombiner {
  private static readonly ELIGIBLE_ELEMENT_TYPES = ['svg', 'image', 'group'];

  constructor(private canvasDocument: CanvasDocument) {}

  // Currently require an svg and support embedding svg and image elements
  public static areElementsEligibleForCombine(elements: DocumentElement[]): boolean {
    return elements?.findIndex((e) => e.type === 'svg') !== -1;
    // let areElementTypesEligible = elements?.length > 1;
    // let containsAnSvg = false;
    // for (let i = 0; i < elements?.length; i++) {
    //   if (SVGCombiner.ELIGIBLE_ELEMENT_TYPES.indexOf(elements[i].type) === -1) {
    //     areElementTypesEligible = false;
    //   }

    //   if (elements[i].type == 'svg') containsAnSvg = true;
    // }

    // return areElementTypesEligible && containsAnSvg;
  }

  public async combineElementsIntoSvg(elements: CanvasElement[]): Promise<string> {
    const { x, y, width, height } = this.canvasDocument.state.getCommonBounds(elements, {
      forceOuterEdgeDimensions: true,
    });

    const parentSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    parentSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    parentSvg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    parentSvg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    parentSvg.setAttribute('width', `${width}`);
    parentSvg.setAttribute('height', `${height}`);

    let childElements = [...elements];
    for (let i = 0; i < childElements.length; i++) {
      // Parent embedded element in a <g> element because otherwise transform attribute may not work
      const embedGroup = document.createElement('g');
      const element = childElements[i];
      const childDimensions = element.getDimensions();
      const localX = childDimensions.x - x;
      const localY = childDimensions.y - y;
      const localWidth = childDimensions.width;
      const localHeight = childDimensions.height;

      let childElement, borderElement;
      if (element.isAsync) {
        childElement = await element.loadAsSVG({ x: localX, y: localY, width: localWidth, height: localHeight });
      } else {
        if (element.elementDefinition.type === 'line') {
          const line = element.elementDefinition.lineDefinition;
          childElement = element.toSVG({ x1: line.x1 - x, y1: line.y1 - y, x2: line.x2 - x, y2: line.y2 - y });
        } else {
          childElement = element.toSVG({ x: localX, y: localY, width: localWidth, height: localHeight });
        }
      }

      if (childElement == null) continue;

      borderElement = element.createSVGBorderContainer({
        x: localX,
        y: localY,
        width: localWidth,
        height: localHeight,
      });
      if (element.isInMask) {
        embedGroup.setAttribute('clip-path', `url(#mask_${element.isInMask})`);
      }

      // Apply transform attribute to parent <g> element
      if (
        typeof element.elementDefinition.rotate?.angle !== 'undefined' &&
        element.elementDefinition.rotate?.angle != 0
      ) {
        let rotationOriginX = localX + localWidth / 2;
        let rotationOriginY = localY + localHeight / 2;
        const transform = `rotate(${element.elementDefinition.rotate?.angle}, ${rotationOriginX}, ${rotationOriginY})`;
        embedGroup.setAttribute('transform', transform);
      }

      embedGroup.appendChild(childElement);
      if (borderElement) {
        embedGroup.appendChild(borderElement);
      }
      parentSvg.appendChild(embedGroup);
    }

    return parentSvg.outerHTML;
  }

  static elementFromHTML(html, trim = true) {
    // Process the HTML string.
    html = trim ? html.trim() : html;
    if (!html) return null;

    // Then set up a new template element.
    const template = document.createElement('template');
    template.innerHTML = html;
    const result = template.content.children;

    return result.length >= 1 ? (result[0] as HTMLElement) : null;
  }

  static getElementArea(element: DocumentElement) {
    return element?.size?.width * element?.size?.height;
  }

  static getViewboxSize(viewbox: string): { x: number; y: number } {
    const terms = viewbox.split(' ');

    if (terms.length == 4) {
      return { x: parseFloat(terms[2]), y: parseFloat(terms[3]) };
    }

    return { x: 0, y: 0 };
  }

  static async fileToBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}
