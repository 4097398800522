import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { documentReducers } from './document.reducers';
import { EffectsModule } from '@ngrx/effects';
import { DocumentModelEntitiesEffects } from '../document-item/document-model-entities/document-model-entities.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('document', documentReducers),
    EffectsModule.forFeature([DocumentModelEntitiesEffects]),
  ],
})
export class DocumentStoreModule {}
