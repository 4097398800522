import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SizeRangeCellComponent } from './size-range-cell/size-range-cell.component';
import { SizeRangeEditorComponent } from './size-range-editor.component';

@NgModule({
  declarations: [SizeRangeEditorComponent, SizeRangeCellComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [SizeRangeEditorComponent, SizeRangeCellComponent],
})
export class SizeRangeEditorModule {
  constructor() {}
}
