<mat-form-field class="!w-full" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input matInput [matAutocomplete]="autoList" [formControl]="listFormControl" (keydown.enter)="onEnter()" />
  <div
    *ngIf="listFormControl.value"
    data-test="app-autoComplete-clear-value"
    class="absolute cursor-pointer opacity-30 top-[-6px] right-[-9px]"
    (click)="clearValue()"
  >
    <mat-icon class="w-[20px] text-[20px]">close</mat-icon>
  </div>
  <mat-autocomplete
    #autoList="matAutocomplete"
    (optionSelected)="handleOptionSelected($event)"
    [displayWith]="displayFn.bind(this)"
  >
    <mat-option *ngFor="let o of filteredOptions" [value]="o.option">
      {{ o.displayValue }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
