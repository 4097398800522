<div #chart class="chart">
  <google-chart
    *ngIf="chartData.length && chartOptions"
    class="chart"
    [type]="chartType"
    [data]="chartData"
    [options]="chartOptions"
    data-test="assortment-history-chart"
    dynamicResize="true"
  >
    >
  </google-chart>
  <div *ngIf="chartData && !chartData.length" class="no-data">Not enough data available.</div>
</div>
<div class="title" *ngIf="chartData.length && chartOptions">{{ typeProperty.label }} / Time</div>
