import { DocumentElement, DocumentElementEvent, SizeDefinition } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CANVAS_COMPONENT_PADDING_B, CANVAS_COMPONENT_PADDING_T, CANVAS_COMPONENT_PADDING_X } from '../../constants';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';

export class CanvasComponentElement extends CanvasElement {
  private warningAnnotation: any;
  private showingWarningMessagePopup = false;

  public documentSize: SizeDefinition;

  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    if (elementDefinition.modelBindings.item || this.isUnassignedItemComponent()) {
      this.componentType = 'item';
      this.PADDING_LEFT = CANVAS_COMPONENT_PADDING_X;
      this.PADDING_RIGHT = CANVAS_COMPONENT_PADDING_X;
      this.PADDING_TOP = CANVAS_COMPONENT_PADDING_T;
      this.PADDING_BOTTOM = CANVAS_COMPONENT_PADDING_B;
    } else if (elementDefinition.modelBindings.color) {
      this.componentType = 'color';
    }
    this.isAsync = true;
  }

  public draw(ctx: CanvasRenderingContext2D, { x, y, width, height }): { height: number; y: number } {
    this.documentSize = { width, height };
    this.drawBorderContainer(
      ctx,
      -width * 0.5 - this.PADDING_LEFT,
      -height * 0.5 - this.PADDING_TOP,
      width + (this.PADDING_LEFT + this.PADDING_RIGHT),
      height + (this.PADDING_TOP + this.PADDING_BOTTOM),
    );
    this.drawWarningAnnotation(ctx, width, height);
    return;
  }

  public getSize(options?: DrawOptions): SizeDefinition {
    let width = 0,
      height = 0,
      lastY = null;
    const hiddenImage = this.innerElements?.find(
      (e) => e.elementDefinition.isHidden && e.elementDefinition.type === 'image',
    );
    for (let i = 0; i < this.innerElements?.length; i++) {
      const element = this.innerElements[i];
      if (element.elementDefinition.type !== 'text') {
        width = Math.max(width, (element.elementDefinition?.position?.x || 0) + element.elementDefinition.size.width);
      }
      const renderedHeight = element.getRenderedHeight(
        null,
        element.elementDefinition.type === 'text'
          ? element.elementDefinition.size.width - 4
          : element.elementDefinition.size.width,
        options,
      );
      const y = lastY === null ? element.elementDefinition?.position?.y || 0 : lastY;
      height = Math.max(height, y + renderedHeight);
      if (['text', 'annotation'].includes(element.elementDefinition.type) || lastY != null) {
        if (element.elementDefinition.type === 'annotation' && i === 0) {
          lastY = null;
        } else {
          lastY = y + renderedHeight;
        }
      }
    }
    if (hiddenImage) {
      height = height - hiddenImage.elementDefinition.size.height;
    }
    if (this.componentType === 'color') {
      const containerRect = this.innerElements[0];
      if (containerRect.elementDefinition?.size?.height !== height) {
        height = height + 5;
      }
    }
    const size = { width, height };
    return this.getMaskedSize(options, size);
  }

  public getBoundingClientRect(options?: DrawOptions) {
    const position = this.getPosition(options);
    let size = options?.calcSize ? this.getSize(options) : this.documentSize || this.getSize(options);
    size = this.getMaskedSize(options, size);

    const x = position.x - this.PADDING_LEFT;
    const y = position.y - this.PADDING_TOP;
    const width = size.width + (this.PADDING_LEFT + this.PADDING_RIGHT);
    const height = size.height + (this.PADDING_TOP + this.PADDING_BOTTOM);

    return {
      x,
      y,
      width,
      height,
    };
  }

  public handleMouseoverWarning(event: MouseEvent) {
    if (!this.warningAnnotation || !this.elementDefinition.modelBindings?.item) {
      return;
    }
    const clientRect = this.getDimensions();
    const position = this.toWindowPosition(clientRect);
    const size = this.toWindowSize(clientRect);
    const warningImgWidth = size.width / 4;
    const warningImgHeight = size.height / 4;
    const leftBoundary = position.x + size.width * 0.5 - warningImgWidth * 0.5;
    const topBoundary = position.y + size.height * 0.5 - warningImgHeight;
    const rightBoundary = leftBoundary + warningImgWidth;
    const bottomBoundary = topBoundary + warningImgHeight;

    if (leftBoundary < event.x && rightBoundary > event.x && topBoundary < event.y && bottomBoundary > event.y) {
      if (!this.showingWarningMessagePopup) {
        this.showingWarningMessagePopup = true;
        const elementEvent: DocumentElementEvent = {
          element: { id: `${this.elementDefinition.modelBindings.item.split(':')[1]}_missing_in_source` },
          eventType: 'showAnnotation',
          renderedElementPosition: { x: leftBoundary, y: topBoundary },
          renderedElementSize: { width: warningImgWidth, height: warningImgHeight },
        };
        this.canvasDocument.actionsDispatcher.handleDocumentElementEvent(elementEvent);
      }
    } else if (this.showingWarningMessagePopup) {
      this.showingWarningMessagePopup = false;
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({
        element: { id: `${this.elementDefinition.modelBindings.item.split(':')[1]}_missing_in_source` },
        eventType: 'hideAnnotation',
      });
    }
  }

  private drawWarningAnnotation(ctx: CanvasRenderingContext2D, width: number, height: number) {
    this.warningAnnotation = null;
    if (this.annotations) {
      const warningAnnotations = this.annotations?.filter((annotation) => annotation.type === 'WARNING');
      const hiddenImage = this.innerElements?.find((e) => e.elementDefinition.isHidden);
      if (warningAnnotations.length > 0 && !hiddenImage) {
        this.warningAnnotation = warningAnnotations[0];
        const warningImgWidth = width / 4;
        const warningImgHeight = height / 4;
        // tslint:disable-next-line: no-string-literal
        const img = this.canvasDocument.annotationHandler.annotationImages['WARNING'];
        if (img) {
          ctx.save();
          ctx.globalAlpha = 0.9;
          ctx.drawImage(img, 0 - warningImgWidth * 0.5, 0 - warningImgHeight, warningImgWidth, warningImgWidth);
          ctx.restore();
        }
      }
    }
  }

  public copyCanvasElementProperties(canvasElement: CanvasElement) {
    if (this.elementDefinition?.elements?.length > 0 && canvasElement?.elementDefinition?.type === 'component') {
      canvasElement.innerElements = this.canvasDocument.state.registerInnerElements(this.elementDefinition);
      const oldImageElement = this?.innerElements.find((e) => e.elementDefinition.type === 'image');
      const imageElement = canvasElement.innerElements.find((e) => e.elementDefinition.type === 'image');
      if (imageElement && oldImageElement) {
        oldImageElement.copyCanvasElementProperties(imageElement);
      }
    }
  }

  public async preload(options?: DrawOptions) {
    const imageElement = this.innerElements?.find((e) => e.elementDefinition.type === 'image');
    if (imageElement && !imageElement.elementDefinition.isHidden) {
      return await imageElement.preload(options);
    }
    return;
  }

  public onDeselect(): void {
    const imageElement = this.innerElements.find((e) => e.elementDefinition.type === 'image');
    if (imageElement && imageElement.elementDefinition?.alternateUrls?.originalFile?.endsWith('.svg')) {
      this.canvasDocument.actionsDispatcher.handleDocumentSvgElementEvent({
        element: this.elementDefinition,
        eventType: 'svgEditorExit',
      });
    }
  }

  private isUnassignedItemComponent() {
    return (
      this.elementDefinition.type === 'component' &&
      (!this.elementDefinition.modelBindings || Object.keys(this.elementDefinition.modelBindings).length === 0)
    );
  }
}
