<div *ngIf="availableProperties" cdkDropList class="flex flex-col space-y-[5px]" (cdkDropListDropped)="drop($event)">
  <ng-template ngFor let-element [ngForOf]="selectedProperties" [ngForTrackBy]="trackByFn">
    <div
      cdkDrag
      [cdkDragDisabled]="!element.displayName"
      [ngClass]="element.displayName ? 'list-value' : 'list-value-hidden'"
    >
      <div class="flex items-center w-full text-black/60" *ngIf="element.displayName">
        <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
        {{ element.displayName }}
        <div class="flex items-center justify-end grow" *ngIf="element.displayName">
          <mat-icon
            class="icon mr-0"
            *ngIf="element.type === 'text'"
            (click)="toggleLabel(element)"
            [attr.data-test]="'component-editor-property-label-' + element.displayName"
          >
            {{ element.label ? 'label' : 'label_outline' }}
          </mat-icon>
          <div *ngIf="element.type === 'text'" (click)="toggleWidget(element, $event, 'property')">
            <component-editor-property-configurator #propertyWidget (valuesChanged)="updateTextProperties($event)" />
            <mat-icon
              class="icon mr-0"
              [color]="getActiveColor(element)"
              data-test="component-editor-property-font-settings"
            >
              text_fields
            </mat-icon>
          </div>
          <div *ngIf="element.type === 'annotation'" (click)="toggleWidget(element, $event, 'annotation')">
            <app-component-editor-annotation
              #annotationWidgetElement
              (valueChange)="updateTextProperties($event)"
              [currentValue]="element.style?.font?.size"
            />
            <mat-icon
              class="icon mr-0"
              [color]="getActiveColor(element)"
              data-test="component-editor-property-annotation-settings"
            >
              text_fields
            </mat-icon>
          </div>
          <div [ngClass]="{ 'text-gray-400': element.isHidden }">
            <mat-icon
              class="icon"
              *ngIf="element.type === 'text'"
              (click)="removeElement(element)"
              [attr.data-test]="'component-editor-property-delete-' + element.displayName"
            >
              delete
            </mat-icon>
            <mat-icon
              class="icon"
              *ngIf="element.type === 'image'"
              (click)="hideElement(element)"
              data-test="component-editor-hide-thumbnail"
            >
              {{ element.isHidden ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <mat-icon
              class="icon"
              *ngIf="element.type === 'annotation'"
              (click)="hideElement(element)"
              data-test="component-editor-hide-annotation"
            >
              {{ element.isHidden ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-2">
    <form class="flex items-center w-full h-full">
      <mat-icon class="text-black24 !w-6 !h-6 !text-2xl !leading-6 cursor-pointer" (click)="openPanel($event)">
        add_box
      </mat-icon>
      <input
        #propertyInput
        type="text"
        [formControl]="control"
        placeholder="Add property"
        [matAutocomplete]="auto"
        size="30"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addElement($event.option.value)">
        <mat-option *ngFor="let element of filteredProperties | async" [value]="element">
          {{ element.displayName }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>
