import { DocumentElement, PositionDefinition, TranslateTransformation } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CoordinateBox } from '../../../coordinate-box';
import { CanvasElement } from '../../../elements/canvas-element';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CanvasMaskState } from '../../../state/canvas-mask-state';
import { DRAG_DIRECTIONS_TRANSFORM, RESIZE_DRAG_DIRECTIONS } from './element-resize-handler';
import { ResizeGuidelineHandler } from './guideline-handler/resize-guideline-handler';

export class MultipleElementsResizeHandler {
  private startingCommonBounds: CoordinateBox;
  private startingPosition: PositionDefinition;
  private selectedElements: CanvasElement[] = [];
  private startingElements: Map<string, DocumentElement> = new Map();
  private transform: TranslateTransformation;
  private isDragging = false;
  private guidelineHandler: ResizeGuidelineHandler;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();

    if (!this.canvasDocument.interactionHandler.isSelect()) {
      return;
    }
    let commonBounds = this.canvasDocument.canvasRenderer.multipleElementsSelectionWidgetRenderer.commonBounds;

    const { element, target } = elementTarget;
    const isMask =
      CanvasMaskState.isMask(elementTarget?.element?.elementDefinition) &&
      !this.canvasDocument.state.maskState.isEditingMask(elementTarget?.element?.elementDefinition?.id);
    if (element?.elementDefinition.type === 'group') {
      const includeMaskMembers = true;
      const includeFrameElements = false;
      this.selectedElements = this.canvasDocument.state.groupState
        .getAllElementsInGroup(element.id, true, includeFrameElements, includeMaskMembers)
        ?.filter((e) => !e.elementDefinition.isLocked);
      commonBounds = this.canvasDocument.state.getCommonBounds(this.selectedElements);
    } else if (isMask) {
      commonBounds = this.canvasDocument.state.getCommonBounds([elementTarget.element]);
    }

    if (
      commonBounds &&
      (!element || element.elementDefinition.type === 'group' || isMask) &&
      elementTarget &&
      RESIZE_DRAG_DIRECTIONS.indexOf(target) !== -1
    ) {
      this.isDragging = true;
      // console.log('MultiElementResizeHandler.dragstarted', event, elementTarget);

      this.transform = DRAG_DIRECTIONS_TRANSFORM[target];
      this.startingPosition = { x: event.clientX, y: event.clientY };
      if (element?.elementDefinition.type !== 'group') {
        this.selectedElements = this.canvasDocument.interactionHandler.selectionHandler.getSelectedElementsForUpdate();
      }
      this.selectedElements.map((canvasElement) => {
        this.startingElements.set(canvasElement.id, ObjectUtil.cloneDeep(canvasElement.elementDefinition));
      });
      this.startingCommonBounds = ObjectUtil.cloneDeep(commonBounds);
      this.guidelineHandler = new ResizeGuidelineHandler(this.canvasDocument, this);
      this.guidelineHandler.dragstarted(event, elementTarget);
      this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({ element: null, eventType: 'resizeStarted' });
    }
  }

  public dragged(event) {
    if (this.selectedElements.length === 0 || !this.startingPosition) {
      return;
    }

    const scale = this.canvasDocument.getViewScale();

    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementsSize(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.guidelineHandler.dragged(event);
    this.canvasDocument.draw({ skipRecalculate: true });
  }

  public dragended(event) {
    this.isDragging = false;
    if (this.selectedElements.length === 0 || !this.startingPosition) {
      return;
    }
    //console.log('MultiElementResizeHandler.dragended');

    const scale = this.canvasDocument.getViewScale();

    const distanceX = event.clientX - this.startingPosition.x;
    const distanceY = event.clientY - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.guidelineHandler?.hasSnapped ||
      this.updateSelectedElementsSize(distanceX / scale.x, distanceY / scale.y, event.shiftKey);
    this.guidelineHandler.dragended(event);
    this.canvasDocument.draw();

    const newElements = this.selectedElements.map((canvasElement: CanvasElement) => {
      if (canvasElement.elementDefinition.type === 'line') {
        return {
          id: canvasElement.id,
          lineDefinition: ObjectUtil.cloneDeep(canvasElement.elementDefinition.lineDefinition),
        };
      } else if (canvasElement.elementDefinition.type === 'component') {
        return {
          id: canvasElement.id,
          position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
          size: ObjectUtil.cloneDeep(canvasElement.elementDefinition.size),
          elements: ObjectUtil.cloneDeep(canvasElement.elementDefinition.elements),
        };
      } else if (this.shouldScale(canvasElement.elementDefinition)) {
        return {
          id: canvasElement.id,
          scale: ObjectUtil.cloneDeep(canvasElement.elementDefinition.scale),
          position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
        };
      } else {
        return {
          id: canvasElement.id,
          position: ObjectUtil.cloneDeep(canvasElement.elementDefinition.position),
          size: ObjectUtil.cloneDeep(canvasElement.elementDefinition.size),
        };
      }
    });
    const undoElements = [...this.startingElements.values()].map((element: DocumentElement) => {
      if (element?.type === 'line') {
        return {
          id: element.id,
          lineDefinition: element?.lineDefinition,
        };
      } else if (element?.type === 'component') {
        return {
          id: element.id,
          position: element?.position,
          size: element?.size,
          elements: element?.elements,
        };
      } else if (this.shouldScale(element)) {
        return {
          id: element.id,
          scale: element?.scale ?? { x: 1, y: 1 },
          position: element?.position,
        };
      } else {
        return {
          id: element.id,
          position: element?.position,
          size: element?.size,
        };
      }
    });

    this.canvasDocument.actionsDispatcher.handleUndoableChanges(newElements, undoElements);
    this.clear();
    this.canvasDocument.actionsDispatcher.handleDocumentElementEvent({ element: null, eventType: 'resizeEnded' });
  }

  public isResizing() {
    return this.isDragging;
  }

  private clear() {
    this.startingElements.clear();
    this.selectedElements = [];
    this.startingPosition = null;
    this.transform = null;
    this.startingCommonBounds = null;
  }

  private updateSelectedElementsSize(dx, dy, shiftKey): boolean {
    let hasChanged = true;

    let scaleX = (this.startingCommonBounds.width + this.transform.x * dx) / this.startingCommonBounds.width;
    let scaleY = (this.startingCommonBounds.height + this.transform.y * dy) / this.startingCommonBounds.height;

    if (true) {
      // shiftKey
      const scale = Math.max(scaleX, scaleY);
      scaleX = scale;
      scaleY = scale;
    }

    if (scaleX < 0.01 || scaleY < 0.01) {
      return;
    }

    let anchorX = this.startingCommonBounds.x + (this.transform.x === -1 ? this.startingCommonBounds.width : 0);
    let anchorY = this.startingCommonBounds.y + (this.transform.y === -1 ? this.startingCommonBounds.height : 0);

    for (let i = 0; i < this.selectedElements?.length; i++) {
      const element: CanvasElement = this.selectedElements[i];
      const oldElement: DocumentElement = this.startingElements.get(element.id);
      this.setElementSize(element, oldElement, anchorX, anchorY, scaleX, scaleY);
    }
    return hasChanged;
  }

  public setElementSize(element: CanvasElement, oldElement: DocumentElement, anchorX, anchorY, scaleX, scaleY) {
    if (element.elementDefinition.type === 'group') {
      // not resizing group as group size is derived
      return;
    }
    if (element.elementDefinition.type === 'line') {
      if (oldElement.lineDefinition && element.elementDefinition.lineDefinition) {
        element.elementDefinition.lineDefinition.x1 = anchorX + (oldElement.lineDefinition.x1 - anchorX) * scaleX;
        element.elementDefinition.lineDefinition.y1 = anchorY + (oldElement.lineDefinition.y1 - anchorY) * scaleY;
        element.elementDefinition.lineDefinition.x2 = anchorX + (oldElement.lineDefinition.x2 - anchorX) * scaleX;
        element.elementDefinition.lineDefinition.y2 = anchorY + (oldElement.lineDefinition.y2 - anchorY) * scaleY;
      }
    } else if (element.isItemComponent()) {
      const imageSize = oldElement.elements.find((e) => e.type === 'image').size;
      const position = {
        x: anchorX + (oldElement.position.x - element.PADDING_LEFT - anchorX) * scaleX + element.PADDING_LEFT,
        y: anchorY + (oldElement.position.y - element.PADDING_TOP - anchorY) * scaleY + element.PADDING_TOP,
      };
      const width =
        (imageSize.width + element.PADDING_LEFT + element.PADDING_RIGHT) * scaleX -
        (element.PADDING_LEFT + element.PADDING_RIGHT);
      const aspectRatio = imageSize.width / imageSize.height;
      let height;
      if (aspectRatio === 1) {
        height = width;
      } else if (aspectRatio > 1) {
        height = Math.ceil(width * (3 / 4));
      } else {
        height = Math.ceil(width * (4 / 3));
      }
      const size = {
        width,
        height,
      };
      let elements = element.elementDefinition.elements.map((e) => {
        const element = ObjectUtil.cloneDeep(e);
        if (element.type === 'image') {
          element.size = { width: size.width, height: size.height };
        } else if (element.type === 'text') {
          element.size.width = size.width;
        }
        return element;
      });
      element.elementDefinition.elements = this.canvasDocument.updateSizeAndPositionForPropertyElements(
        elements,
        element.elementDefinition,
      );
      element.elementDefinition.position = position;
      for (let i = 0; i < element.innerElements.length; i++) {
        const innerElement = element.innerElements[i];
        const innerChange = element.elementDefinition.elements.find((e) => e.id === innerElement.id);
        if (innerChange) {
          innerElement.elementDefinition = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(innerElement.elementDefinition),
            innerChange,
          );
        }
      }
    } else if (element.isColorComponent()) {
      const position = {
        x: anchorX + (oldElement.position.x - element.PADDING_LEFT - anchorX) * scaleX + element.PADDING_LEFT,
        y: anchorY + (oldElement.position.y - element.PADDING_TOP - anchorY) * scaleY + element.PADDING_TOP,
      };
      const colorRectSize = oldElement.elements.find((e) => e.type === 'rectangle' && e?.propertyBindings).size;
      const width = Math.round(colorRectSize.width * scaleX);
      const height = Math.round(colorRectSize.height * scaleY);
      const size = { width, height };
      const propertyElements = element.elementDefinition.elements;
      const updatedElements = this.canvasDocument.documentService.updateSizeAndPositionForColorElements(
        propertyElements,
        size,
      );
      element.elementDefinition.elements = [...updatedElements];
      element.elementDefinition.position = position;
      for (let i = 0; i < element.innerElements.length; i++) {
        const innerElement = element.innerElements[i];
        const innerChange = element.elementDefinition.elements.find((e) => e.id === innerElement.id);
        if (innerChange) {
          innerElement.elementDefinition = ObjectUtil.mergeDeep(
            ObjectUtil.cloneDeep(innerElement.elementDefinition),
            innerChange,
          );
        }
      }
    } else if (this.shouldScale(element.elementDefinition)) {
      const position = {
        x: anchorX + (oldElement.position.x - anchorX) * scaleX,
        y: anchorY + (oldElement.position.y - anchorY) * scaleY,
      };
      element.elementDefinition.position = position;
      element.elementDefinition.scale = {
        x: (oldElement.scale?.x ?? 1) * scaleX,
        y: (oldElement.scale?.y ?? 1) * scaleY,
      };
    } else {
      const position = {
        x: anchorX + (oldElement.position.x - anchorX) * scaleX,
        y: anchorY + (oldElement.position.y - anchorY) * scaleY,
      };
      const size = {
        width: oldElement.size.width * scaleX,
        height: oldElement.size.height * scaleY,
      };

      element.elementDefinition.position = position;
      element.elementDefinition.size = size;
    }
    element.onChange();
  }

  private isRegularIframe(element: DocumentElement) {
    return element.type === 'iframe' && !element.embedInfo?.isIframe;
  }

  private shouldScale(element: DocumentElement) {
    return (
      element.type === 'sticky_note' || this.isRegularIframe(element) || (element.type === 'text' && element.isTextTool)
    );
  }

  public keepAspectRatio() {
    return true;
  }
}
