import { Workspace, WorkspaceEntity } from './workspaces-store/workspaces.state';

export class WorkspaceEntityHelper {
  public static postProcessWorkspaceEntities(entities: Array<WorkspaceEntity>, workspace: Workspace = null) {
    for (let ent of entities) {
      if (ent.entity) {
        ent.name = ent.entity?.name;
        ent.createdBy = ent.entity?.createdBy;
        ent.updatedBy = ent.entity?.updatedBy;
        ent.createdOn = ent.entity?.createdOn;
        ent.updatedOn = ent.entity?.updatedOn;
        ent.thumbnailUrl = this.getThumbnail(ent);
        ent.workspace = workspace;
      }
    }
  }

  public static getThumbnail(ent: WorkspaceEntity) {
    let thumb = ent?.entity?.previewFile?.downloadUrl;
    return thumb;
  }
}
