import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemData } from '@common/item-data/item-data';
import { DashboardDefinition, DashboardWidgetConfiguration } from '../dashboard-interfaces';
import {
  ASSORTMENT_SUMMARY_CHART_DASHBOARD_WIDGET,
  ASSORTMENT_SUMMARY_DASHBOARD_WIDGET,
  ASSORTMENT_ANNOTATION_DASHBOARD_WIDGET,
} from '../dashboard-widget-registry';
import { AppExtensionType } from '@contrail/entity-types';

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss'],
})
export class DashboardPanelComponent implements OnInit {
  constructor() {}
  @Output() panelClosed = new EventEmitter();
  @Input() assortmentItemData: Array<ItemData>;
  @Input() appContext: any;
  @Input() annotationOptions: any[];

  public dashboardDefinitions: Array<DashboardDefinition> = [
    {
      name: 'Dashboard 1',
      dashboardWidgetConfigurations: [
        {
          widgetIdentifier: 'assortment_summary',
          widget: ASSORTMENT_SUMMARY_DASHBOARD_WIDGET,
        },
        {
          widgetIdentifier: 'assortment_annotation',
          widget: ASSORTMENT_ANNOTATION_DASHBOARD_WIDGET,
        },
        {
          widgetIdentifier: 'assortment_summary_pie_chart',
          widget: ASSORTMENT_SUMMARY_CHART_DASHBOARD_WIDGET,
        },
        {
          widgetIdentifier: 'assortment_summary_bar_chart',
          widget: ASSORTMENT_SUMMARY_CHART_DASHBOARD_WIDGET,
        },

        //,{
        //   widgetIdentifier: 'extension_1',
        //   widget: {
        //     identifier: 'converse_annotations_dashboard',
        //     name: "Annotations",
        //     extensionDefinition: {
        //       id: '123123',
        //       name: 'Extension 1',
        //       identifier: '123123123',
        //       appId: '123123',
        //       appVersionId: '12313',
        //       extensionType: AppExtensionType.DASHBOARD_WIDGET,
        //       iframeUrl: 'http://localhost:5500',
        //       widgetHeight: "250px",
        //     }
        //   }
        // }
      ],
    },
  ];

  public currentDashboardDefinition: DashboardDefinition;

  ngOnInit(): void {
    this.currentDashboardDefinition = this.dashboardDefinitions[0];
  }

  handleClose() {
    this.panelClosed.emit();
  }
}
