import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FrameTemplate } from '../frame-template';

interface FrameTemplatesState extends EntityState<FrameTemplate> {}

export interface State {
  frameTemplates: FrameTemplatesState;
}
export const frameTemplatesEntityAdapter: EntityAdapter<FrameTemplate> = createEntityAdapter<FrameTemplate>({});

export const frameTemplatesInitialState: State = {
  frameTemplates: frameTemplatesEntityAdapter.getInitialState({}),
};
