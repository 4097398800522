import { Injectable } from '@angular/core';
import { DocumentService } from '../../document.service';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { ActionRequest } from '@contrail/actions';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Injectable({
  providedIn: 'root',
})
export class MaskElementService {
  constructor(
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
  ) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'toggle_mask_elements') {
        const selectedGroupElements = this.documentService.getSelectedElements();
        if (selectedGroupElements.length > 1 && this.documentService.isMaskAllowed(selectedGroupElements)) {
          this.createMask(selectedGroupElements);
        } else if (
          selectedGroupElements.length === 1 &&
          this.documentService.isRemoveMaskAllowed(selectedGroupElements)
        ) {
          this.removeMask(selectedGroupElements);
        }
      }
    });
  }

  public createMask(elements: DocumentElement[]) {
    const maskElement = this.documentService.getMaskElement(elements);
    const imageElements = this.documentService.getElementsToMask(elements);
    if (maskElement && imageElements?.length > 0) {
      const undoElement = ObjectUtil.cloneDeep(maskElement);
      this.documentService.handleDocumentActions([
        new DocumentAction(
          {
            elementId: maskElement.id,
            elementData: {
              id: maskElement.id,
              elementIds: imageElements.map((e) => e.id),
            },
            changeType: DocumentChangeType.MODIFY_ELEMENT,
          },
          {
            elementId: maskElement.id,
            elementData: undoElement,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
          },
        ),
      ]);

      const elementIndex = this.documentService?.documentRenderer?.getElementIndex(maskElement.id);
      const elementsIndices = imageElements.map((e) => this.documentService?.documentRenderer?.getElementIndex(e.id));
      const elementsOnTop = elementsIndices.filter((index) => index >= elementIndex);
      if (elementsOnTop?.length > 0) {
        this.documentService.handleActionRequest(
          new ActionRequest('order.to_index', {
            selectedElements: [maskElement],
            index: elementsOnTop[0],
            undoable: false,
          }),
        );
      }
    }
  }

  public removeMask(elements: DocumentElement[]) {
    const maskElement = this.documentService.getMaskElement(elements);
    if (maskElement) {
      this.documentService.handleDocumentActions([this.getRemoveMaskAction(maskElement)]);
    }
  }

  public getRemoveMaskAction(maskElement: DocumentElement): DocumentAction {
    const undoElement = ObjectUtil.cloneDeep(maskElement);
    return new DocumentAction(
      {
        elementId: maskElement.id,
        elementData: {
          id: maskElement.id,
          elementIds: null,
        },
        changeType: DocumentChangeType.MODIFY_ELEMENT,
      },
      {
        elementId: maskElement.id,
        elementData: undoElement,
        changeType: DocumentChangeType.MODIFY_ELEMENT,
      },
    );
  }
}
