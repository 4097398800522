<div class="flex items-center gap-1">
  <app-user-sessions-avatars
    *ngIf="editorMode === 'EDIT'"
    [clickableAvatar]="true"
    (handleClick)="this.navigateToUserLocation($event)"
  ></app-user-sessions-avatars>
  <div [matTooltip]="'Frames Panel'">
    <button
      mat-button
      class="menu-button mat-button mat-button-base"
      (click)="toggleFrames()"
      data-test="app-frames-button"
    >
      <mat-icon svgIcon="app-frames-icon" class="!w-5 !h-5"></mat-icon>
    </button>
  </div>
  <div data-test="board-dashboard-button" [matTooltip]="'Dashboard'" *ngIf="dashboardFeatureFlag">
    <button mat-button class="menu-button mat-button mat-button-base" (click)="toggleDashboard()">
      <mat-icon [attr.data-test]="'dashboard-button'" [svgIcon]="'dashboard_icon'" class="side-panel-option"></mat-icon>
    </button>
  </div>
  <div [matTooltip]="'Search (Ctrl-F)'">
    <button
      mat-button
      class="menu-button mat-button mat-button-base"
      (click)="toggleSearch()"
      data-test="app-search-button"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <app-header-clipboard
    *ngIf="editorMode === 'EDIT'"
    data-test="clipboard-button"
    matTooltip="Clipboard"
    (showClipboard)="onShowClipboard()"
  ></app-header-clipboard>
  <app-status-messages-indicator
    data-test="status-messages-button"
    [matTooltip]="'Alerts'"
    [messages]="messages$ | async"
    (onShowAlertMessages)="onShowAlertMessages($event)"
  ></app-status-messages-indicator>
  <app-header-comments
    data-test="comments-button"
    [allowToggleSideBar]="true"
    matTooltip="Comments"
    *ngIf="editorMode !== 'VIEW'"
  ></app-header-comments>
  <!-- <app-help-selector></app-help-selector> -->
  <app-header-user-menu class="px-1"></app-header-user-menu>
  <div *ngIf="allowShare && editorMode === 'EDIT'">
    <button mat-raised-button color="primary" (click)="share()" data-test="share-button">
      <mat-icon class="mr-2">share</mat-icon>Share
    </button>
  </div>
</div>
