<div class="header">
  <div class="mb-2 flex items-center text-lg font-medium">Select source</div>
  <app-source-selector-breadcrumb *ngIf="step !== 'OPTION_TYPE'" [step]="step" (optionType)="gotoOptionType()" />
</div>

<ng-container *ngIf="showSourceSelector">
  <div *ngIf="step === 'OPTION_TYPE'" class="source-type-selector">
    <div
      *ngFor="let option of optionTypesList; index as idx"
      class="source-type-option"
      [class.active]="option.active"
      [ngClass]="{ disabled: option.disabled }"
      [attr.data-test]="'source-option-' + option.sourceType"
      (debounceClick)="activeSourceOptionType(option, idx)"
      appDebounceClick="100"
    >
      <img [src]="option.img" class="w-10 h-10 object-contain my-auto mx-2 {{ option.class || '' }}" />
      <div class="option-description">
        <div class="name">{{ option.name }}</div>
        <div class="description">{{ option.description }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="step === 'ASSORTMENT_SELECT'" class="source-selector">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Source Project</mat-label>
      <mat-select
        [formControl]="sourceWorkspaceControl"
        (ngModelChange)="handleWorkspaceChange($event)"
        data-test="select-source-workspace"
      >
        <mat-option *ngFor="let workspace of sourceWorkspaces" [value]="workspace">
          {{ workspace.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <mat-form-field *ngIf="(assortments$ | async)?.length" class="w-full" appearance="fill">
        <mat-label>Assortment</mat-label>
        <mat-select
          [formControl]="assortmentFormControl"
          (ngModelChange)="handleAssortmentChange()"
          data-test="select-source-assortment"
        >
          <mat-select-trigger
            class="assortment-name-trigger"
            appOverflowTooltip
            matTooltip=""
            [matTooltipShowDelay]="300"
            matTooltipPosition="left"
          >
            {{ assortmentFormControl.value?.name }}
          </mat-select-trigger>
          <ng-container *ngFor="let assortment of assortments$ | async">
            <mat-option
              [value]="assortment"
              appOverflowTooltip
              [toolTipElement]="'.mat-option-text'"
              matTooltip=""
              matTooltipPosition="left"
            >
              {{ assortment.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="warning" *ngIf="(assortments$ | async)?.length === 0">
        The selected project does not contain an eligible assortment.
      </div>
    </div>
  </div>

  <div *ngIf="step === 'WORKSPACE_SELECT'" class="source-selector">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Project</mat-label>
      <mat-select
        [formControl]="sourceWorkspaceControl"
        (ngModelChange)="handleWorkspaceChange($event)"
        data-test="item-data-source-selector-update-workspace"
      >
        <mat-option *ngFor="let workspace of sourceWorkspaces" [value]="workspace">
          {{ workspace.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="step === 'DOCUMENT_SELECT'" class="source-selector h-full overflow-auto narrow-scroll">
    <div class="flex-center w-full h-96" *ngIf="loading$ | async; else loaded">
      <app-mat-spinner [message]="'Loading Document'" [overlay]="false" [loading]="true"></app-mat-spinner>
    </div>

    <ng-template #loaded>
      <div class="entity-container" data-test="data-source-selector-document">
        <div
          *ngFor="let item of transformedEntities$ | async"
          class="entity-item flex items-center"
          [class.selected]="selectedEntityId === item?.id"
          (click)="selectEntity(item)"
        >
          <mat-icon class="!text-blue-300" *ngIf="item.matIcon">{{ item.matIcon }}</mat-icon>
          <img *ngIf="!item.matIcon" [src]="item.icon" class="w-6 h-6 object-contain" />
          <span [matTooltip]="item?.name" [matTooltipDisabled]="item?.name.length < 30">
            {{ item.name | shorten: 30 }}
          </span>
        </div>

        <ng-container *ngIf="!(transformedEntities$ | async)?.length">
          <span class="py-10 w-full flex-center">No Entities</span>
        </ng-container>
      </div>
    </ng-template>
  </div>

  <div *ngIf="step === 'RECENT_DOCUMENT'" class="source-selector h-full overflow-auto narrow-scroll">
    <div class="flex-center w-full h-96" *ngIf="loadingRecent; else loadRecent">
      <app-mat-spinner [message]="'Loading Recent'" [overlay]="false" [loading]="true"></app-mat-spinner>
    </div>

    <ng-template #loadRecent>
      <div class="entity-container" data-test="data-source-selector-recent">
        <div
          *ngFor="let item of transformedEntities$ | async"
          class="entity-item flex items-center"
          [class.selected]="selectedEntityId === item?.id"
          (click)="selectEntity(item)"
        >
          <img [src]="item?.icon" class="w-6 h-6 object-contain" />
          <span [matTooltip]="item?.entity?.name" [matTooltipDisabled]="item?.entity?.name?.length < 30">
            {{ item?.entity?.name | shorten: 30 }}
          </span>
        </div>

        <ng-container *ngIf="!(transformedEntities$ | async)?.length">
          <span class="py-10 w-full flex-center">No Entities</span>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-container>

<div *ngIf="!showSourceSelector" class="source-wrapper">
  <div class="mb-2 text-lg font-medium">Selected source</div>
  <div class="mb-5">
    Generate a frame layout automatically from an existing assortment. The generated layout can be edited afterwards.
  </div>
  <div class="flex items-center">
    <img
      class="source-icon {{ (chooserSourceOption$ | async)?.class || '' }}"
      [src]="(chooserSourceOption$ | async)?.icon"
      *ngIf="(chooserSourceOption$ | async)?.icon"
    />
    <span
      class="font-bold text-base"
      [matTooltip]="(chooserSourceOption$ | async)?.name"
      [matTooltipDisabled]="(chooserSourceOption$ | async)?.name.length < 30"
    >
      {{ (chooserSourceOption$ | async)?.name | shorten: 30 }}
    </span>
    <div class="cursor-pointer text-accent ml-auto" (click)="toggleSourceSelector(true)">Change</div>
  </div>
</div>
