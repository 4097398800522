import { CanvasDocument } from '../../canvas-document';
import { FileDownloader } from '../../file-downloader';
import { SVGHelper } from '../../svg-helper';
import * as d3 from 'd3';
import { SvgElementSelectionManager, SvgSelectionType } from './svg-element-selection-manager';
import { SvgElementOverlayManager } from './svg-element-overlay-manager';
import { CanvasElement } from '../../elements/canvas-element';

export const svgEditorContainerId = 'svg-editor-container';

export class SvgEditorContainer {
  svgCanvasElement: CanvasElement;
  svgEditorContainerElement: HTMLElement;
  borderContainer: HTMLElement;
  svgContainer: HTMLElement;
  svgRootElement: SVGElement;

  selectionManager: SvgElementSelectionManager;
  overlayManager: SvgElementOverlayManager;

  // Opacity value to set on the canvas element so when it's shown during pan/zoom it appears 'greyed out'
  private CANVAS_ELEMENT_OPACITY: number = 0.1;

  constructor(private canvasDocument: CanvasDocument) {
    this.svgEditorContainerElement = document.createElement('div');
    this.svgEditorContainerElement.id = svgEditorContainerId;
    this.svgEditorContainerElement.style.userSelect = 'none';
    this.svgEditorContainerElement.style.position = 'absolute';
    this.svgEditorContainerElement.style.width = '100px';
    this.svgEditorContainerElement.style.height = '100px';
    this.svgEditorContainerElement.style.left = '0px';
    this.svgEditorContainerElement.style.top = '0px';
    this.svgEditorContainerElement.style.visibility = 'hidden';
    this.svgEditorContainerElement.style.background = 'rgba(0, 0, 0, 0)';
    this.svgEditorContainerElement.style.zIndex = '5';

    this.borderContainer = document.createElement('div');
    this.borderContainer.id = 'svg-editor-border';
    this.borderContainer.style.width = '100%';
    this.borderContainer.style.height = '100%';
    this.borderContainer.style.padding = '0';
    this.svgEditorContainerElement.appendChild(this.borderContainer);

    this.svgContainer = document.createElement('div');
    this.svgContainer.id = 'svg-element';
    this.svgContainer.style.width = '100%';
    this.svgContainer.style.height = '100%';
    this.svgContainer.style.margin = '0';
    this.borderContainer.appendChild(this.svgContainer);

    document.getElementById(this.canvasDocument.elementId).appendChild(this.svgEditorContainerElement);

    this.selectionManager = new SvgElementSelectionManager();
    const _this = this;
    this.selectionManager.onSelectionStateAdded.subscribe((selectionEvent) => {
      if (selectionEvent.selectionType == SvgSelectionType.Selection) {
        _this.handleSelectionUpdated();
      }
    });

    this.selectionManager.onSelectionStateRemoved.subscribe((selectionEvent) => {
      if (selectionEvent.selectionType == SvgSelectionType.Selection) {
        _this.handleSelectionUpdated();
      }
    });

    this.overlayManager = new SvgElementOverlayManager(this.selectionManager);
  }

  async showSvgEditor(canvasElement: CanvasElement): Promise<SVGElement> {
    this.svgCanvasElement = canvasElement;

    this.svgEditorContainerElement.style.visibility = 'visible';
    this.updateEditorTransform();

    this.borderContainer.style.borderWidth =
      `${this.svgCanvasElement.elementDefinition.style?.border?.width}px` || '0px';
    this.borderContainer.style.borderColor =
      this.svgCanvasElement.elementDefinition.style?.border?.color || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.borderStyle = this.svgCanvasElement.elementDefinition.style?.border?.style || 'solid';
    this.borderContainer.style.background =
      this.svgCanvasElement.elementDefinition.style?.backgroundColor || 'rgba(0, 0, 0, 0)';
    this.borderContainer.style.transform = `rotate(${this.svgCanvasElement.elementDefinition.rotate?.angle || 0}deg)`;

    this.borderContainer.style.paddingBottom = `${this.svgCanvasElement.PADDING_BOTTOM}px`;
    this.borderContainer.style.paddingLeft = `${this.svgCanvasElement.PADDING_LEFT}px`;
    this.borderContainer.style.paddingRight = `${this.svgCanvasElement.PADDING_RIGHT}px`;
    this.borderContainer.style.paddingTop = `${this.svgCanvasElement.PADDING_TOP}px`;

    const imageElement = this.getImageCanvasElement(this.svgCanvasElement);
    imageElement.isVisible = false;

    this.svgCanvasElement.isRotationEnabled = false;
    this.svgCanvasElement.isResizeEnabled = false;
    this.svgCanvasElement.isDragEnabled = false;

    this.canvasDocument.draw();

    const authContext = await this.canvasDocument.getUserContext();
    const fileDownloader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      this.canvasDocument.appContext,
    );

    const svgString = await SVGHelper.getSVGTextFromDocumentElement(canvasElement.elementDefinition, fileDownloader);
    this.svgContainer.innerHTML = svgString;

    let heightDiff = 0;
    // If canvasElement is of type 'component', add additional padding to compensate for other non-image innerElements
    if (this.svgCanvasElement.elementDefinition.type == 'component') {
      const elementDimensions = this.svgCanvasElement.getDimensions({ skipScale: true });
      const imageDimensions = imageElement.getDimensions({ skipScale: true });
      heightDiff = elementDimensions.height - imageDimensions.height;
    }

    this.svgContainer.style.paddingBottom = `${heightDiff}px`;

    let svgNode;
    for (let i = 0; i < this.svgContainer.childNodes.length; i++) {
      const childNode = this.svgContainer.childNodes[i];
      if (childNode.nodeName === 'svg') {
        svgNode = childNode as SVGElement;
        break;
      }
    }

    if (!svgNode) {
      return;
    }
    this.svgRootElement = svgNode;
    this.svgRootElement.id = 'svg-editor-element';

    // Make sure svg fills the container so it matches the canvas element size
    d3.select(this.svgRootElement).attr('width', '100%');
    d3.select(this.svgRootElement).attr('height', '100%');

    this.bindEventsToSvg(this.svgRootElement);
    this.overlayManager.setOverlayParent(this.svgRootElement);

    return this.svgRootElement;
  }

  async hideSvgEditor() {
    if (this.svgCanvasElement) {
      this.overlayManager.clearOverlayElements();

      this.svgEditorContainerElement.style.visibility = 'hidden';

      const imageElement = this.getImageCanvasElement(this.svgCanvasElement);
      imageElement.isVisible = true;

      this.svgCanvasElement.isRotationEnabled = true;
      this.svgCanvasElement.isResizeEnabled = true;
      this.svgCanvasElement.isDragEnabled = true;

      this.canvasDocument.draw();
      this.svgCanvasElement = null;

      this.svgContainer.innerHTML = '';
    }

    this.overlayManager.clearOverlayElements();
  }

  /**
   * Updates the editor to match new element size and position.  Called after redrawing canvas, such as following pan or zoom
   */
  updateEditorTransform() {
    if (!this.svgCanvasElement) {
      return;
    }

    const dimensions = this.svgCanvasElement.getDimensions({ skipScale: true });
    const elementPosition = {
      x: dimensions.x - this.svgCanvasElement.PADDING_LEFT,
      y: dimensions.y - this.svgCanvasElement.PADDING_TOP,
    };
    const adjustedPositions = this.svgCanvasElement.toWindowPosition(elementPosition, false);
    const scale = this.canvasDocument.getViewScale();
    this.svgEditorContainerElement.style.width = `${dimensions.width + this.svgCanvasElement.PADDING_LEFT + this.svgCanvasElement.PADDING_RIGHT}px`;
    this.svgEditorContainerElement.style.height = `${dimensions.height + this.svgCanvasElement.PADDING_TOP + this.svgCanvasElement.PADDING_BOTTOM}px`;
    this.svgEditorContainerElement.style.left = `${adjustedPositions.x}px`;
    this.svgEditorContainerElement.style.top = `${adjustedPositions.y}px`;
    this.svgEditorContainerElement.style.transform = `scale(${scale.x})`;
    this.svgEditorContainerElement.style.transformOrigin = 'top left';
  }

  clear() {
    this.svgEditorContainerElement?.remove();
    this.svgEditorContainerElement = null;
    this.borderContainer = null;
    this.svgContainer = null;
    this.selectionManager = null;
    this.overlayManager = null;
  }

  private handleSelectionUpdated() {
    this.canvasDocument.actionsDispatcher.handleDocumentSvgElementEvent({
      element: this.svgCanvasElement.elementDefinition,
      selectedComponents: this.selectionManager.selectedElements,
      eventType: 'selectionUpdated',
    });
  }

  resetSvgFromString(svgHtmlString: string) {
    this.selectionManager.clearSelection(false);
    this.selectionManager.clearHighlight(false);
    this.overlayManager.clearOverlayElements();

    this.svgContainer.innerHTML = svgHtmlString;
    this.svgRootElement = this.svgContainer.childNodes[0] as SVGElement;
    this.svgRootElement.id = 'svg-editor-element';

    this.bindEventsToSvg(this.svgRootElement);
    this.overlayManager.setOverlayParent(this.svgRootElement);
  }

  bindEventsToSvg(svgRootElement: SVGElement) {
    const svgComponentTypes = ['path', 'line', 'polygon', 'rect', 'image', 'circle', 'ellipse', 'polyline'];
    const selectionManager = this.selectionManager;

    d3.select(svgRootElement).on('click', function (d) {
      // if the svg is the target then we didn't click on any element, so we should clear selection
      if (d.target?.nodeName == 'svg') {
        selectionManager.clearSelection();
      }
    });

    d3.select('#' + svgRootElement.id)
      .selectAll(svgComponentTypes.join(', '))
      .filter(function (d) {
        // Only handle elements that are not children of a pattern node
        return !SVGHelper.isNodeChildOfType(this, 'pattern');
      })
      .each(function (d, i) {
        // Ensure all elements have an id attribute, but don't overwrite existing ids
        if (!this.id.length) {
          this.id = `${this.nodeName}-${i}`;
        }

        const selection = d3.select(this);
        selection.on('mouseover', function (d) {
          selectionManager.replaceHighlight([this]);
        });

        selection.on('mouseout', function (d) {
          selectionManager.removeFromHighlight([this]);
        });

        selection.on('click', function (d) {
          if (d.shiftKey) {
            selectionManager.addOrRemoveFromSelection(this);
          } else {
            selectionManager.replaceSelection([this]);
          }
        });
      });
  }

  findSvgElementsIds(ids: string[]): SVGElement[] {
    if (!ids?.length) {
      return [];
    }
    const idsString = ids.map((id) => `#${this.escapeSpecialChars(id)}`).join(', ');
    const svgElements = [];
    d3.select('#' + this.svgRootElement.id)
      .selectAll(idsString)
      .each(function (d, i) {
        svgElements.push(this);
      });
    return svgElements;
  }

  escapeSpecialChars(str): string {
    return str.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
  }

  // If canvasElement is of type 'component', returns innerElement of type 'image'
  getImageCanvasElement(canvasElement: CanvasElement) {
    if (canvasElement.elementDefinition.type == 'component') {
      const imageElement = canvasElement.innerElements.find((e) => e.elementDefinition.type === 'image');
      if (imageElement) return imageElement;
    }

    return canvasElement;
  }
}
