import { ANNOTATION_IMG_SIZE, ANNOTATION_PADDING_Y } from '../../constants';
import { DrawOptions } from '../../renderers/canvas-renderer';
import { CanvasElement } from '../canvas-element';

export class CanvasAnnotationElement extends CanvasElement {
  public draw(ctx: any, opts: any): { height: number } {
    const width = opts.width;
    const y = opts.y;
    let yPosition = null;
    const imgSize = this.elementDefinition.style?.font?.size || ANNOTATION_IMG_SIZE;
    let height = 0;

    if (this.annotations?.length > 0) {
      height = imgSize + ANNOTATION_PADDING_Y;
      let xPos = -width * 0.5;
      if (this.annotations?.length > 0) {
        yPosition = y + ANNOTATION_PADDING_Y / 2;
      }
      let count = 0;
      this.annotations?.forEach((annotation) => {
        const img = this.canvasDocument?.annotationHandler?.annotationImages[annotation.type];
        if (img) {
          if ((count + 1) * imgSize * 1.25 > width) {
            count = 0;
            xPos = -width * 0.5;
            yPosition = yPosition + imgSize + ANNOTATION_PADDING_Y;
            height = height + imgSize + ANNOTATION_PADDING_Y;
          }
          ctx.drawImage(img, xPos, yPosition, imgSize, imgSize);
          xPos = xPos + imgSize * 1.25;
          count++;
        }
      });
    }
    return { height };
  }

  public getRenderedHeight(ctx?, width?, options?: DrawOptions): number {
    const imgSize = this.elementDefinition.style?.font?.size || ANNOTATION_IMG_SIZE;
    let height = 0;
    if (this.annotations?.length > 0 && !this.elementDefinition.isHidden) {
      height = imgSize + ANNOTATION_PADDING_Y;
      let xPos = -width * 0.5;
      let count = 0;
      this.annotations?.forEach((annotation) => {
        const img = this.canvasDocument?.annotationHandler?.annotationImages[annotation.type];
        if (img) {
          if ((count + 1) * imgSize * 1.25 > width) {
            count = 0;
            xPos = -width * 0.5;
            height = height + imgSize + ANNOTATION_PADDING_Y;
          }
          xPos = xPos + imgSize * 1.25;
          count++;
        }
      });
    }
    return height;
  }
}
