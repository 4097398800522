<div>
  <div class="menu">
    <app-menu [icon]="'settings'" position="left">
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="overflow-auto h-[300px] narrow-scroll">
        <ng-template ngFor let-annotationOption [ngForOf]="annotationOptions" [ngForTrackBy]="trackByFn">
          <div class="mx-1 flex items-center justify-between" cdkDrag>
            <div class="flex items-center">
              <mat-slide-toggle
                class="scale-75"
                color="primary"
                [(ngModel)]="annotationOption.enabled"
                (change)="toggleAnnotation()"
              >
              </mat-slide-toggle>
              <mat-icon class="ml-2 !w-5 !h-5" [svgIcon]="annotationOption.svgIcon"></mat-icon>
            </div>
            <div>
              <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
            </div>
          </div>
        </ng-template>
      </div>
    </app-menu>
  </div>
  <div class="flex flex-col items-center text-[15px] font-bold mb-5">Annotations</div>
  <div class="flex justify-center" *ngFor="let annotationGroup of annotationGroups">
    <ng-container *ngFor="let annotation of annotationGroup">
      <div class="annotation" *ngIf="annotation.enabled">
        <mat-icon [matTooltip]="annotation.label" matTooltipPosition="above" [svgIcon]="annotation.svgIcon"></mat-icon>
        <span class="ml-1" [attr.data-test]="'dashboard-annotation-' + annotation.property + '-count'">{{
          annotationData[annotation.property]
        }}</span>
      </div>
    </ng-container>
  </div>
</div>
