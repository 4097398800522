import { Component, Input } from '@angular/core';
import { ExportsService } from '../exports.service';
import { BehaviorSubject } from 'rxjs';
import { DownloadService } from '../download/download.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  @Input() types: any[];
  @Input() entityReference: string;
  @Input() label = 'Export';
  @Input() options = {};
  @Input() selectedIds: string[];

  public downloading$: BehaviorSubject<boolean> = this.downloadService.downloading$;
  constructor(
    private service: ExportsService,
    private downloadService: DownloadService,
  ) {}

  initDownload(reportType, all = true) {
    this.downloadService.downloading$.next(true);
    let payload: any = {
      entityReference: this.entityReference,
      ...this.options,
    };
    if (!all && this.selectedIds?.length) {
      payload.selectedIds = this.selectedIds;
    }

    this.service.initDownloadEntityReference(
      {
        reportType,
      },
      payload,
    );
  }
}
