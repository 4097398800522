import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { SourceAssortmentComponent } from './source-assortment.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ComponentsModule } from '@components/components.module';
import { AssortmentsModule } from '@common/assortments/assortments.module';
import { AssignSourceAssortmentModalComponent } from './assign-source-assortment-modal/assign-source-assortment-modal.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [SourceAssortmentComponent, AssignSourceAssortmentModalComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    ComponentsModule,
    AssortmentsModule,
  ],
  exports: [SourceAssortmentComponent],
})
export class SourceAssortmentModule {}
