import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { boardsReducers } from './boards.reducers';
import { EffectsModule } from '@ngrx/effects';
import { BoardsEffects } from './boards.effects';
import { CollectionStatusMessagesEffects } from './collection-status-messages/collection-status-messages.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('boards', boardsReducers),
    EffectsModule.forFeature([BoardsEffects, CollectionStatusMessagesEffects]),
  ],
})
export class BoardsStoreModule {}
