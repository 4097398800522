<div class="flex flex-row items-center content-center">
  <button
    mat-icon-button
    [matMenuTriggerFor]="borderSizeMenu"
    (menuOpened)="menuOpened()"
    data-test="border-size"
    #borderSizeMenuTrigger="matMenuTrigger"
  >
    <mat-icon>line_weight</mat-icon>
  </button>
</div>

<mat-menu #borderSizeMenu="matMenu" class="border-size-menu-panel">
  <div class="relative h-48 bg-white border-none">
    <ng-container *ngFor="let sizeValue of borderSizes">
      <button
        class="!text-black60"
        mat-menu-item
        (click)="setValue(sizeValue, $event)"
        [attr.data-test]="'border-size-' + sizeValue"
        [attr.id]="sizeValue == borderSize ? 'border-size-selected' : null"
      >
        {{ sizeValue }}px <mat-icon class="!mr-1.5" *ngIf="sizeValue == borderSize">done</mat-icon>
      </button>
    </ng-container>
  </div>
</mat-menu>
