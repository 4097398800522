import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalizationService } from 'src/app/common/localization/localization.service';
import { PropertyValueFormatter, TypeProperty } from '@contrail/types';
@Component({
  selector: 'app-property-value',
  templateUrl: './property-value.component.html',
  styleUrls: ['./property-value.component.scss'],
})
export class PropertyValueComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: any;
  @Input() propertyType: string;
  @Input() property: TypeProperty;
  public display = '';
  private propertyValueFormatter: PropertyValueFormatter;
  private subscriptions: Array<Subscription> = [];
  constructor(private localizationService: LocalizationService) {
    this.subscriptions.push(
      this.localizationService.localizationConfig.subscribe((config) => {
        this.propertyValueFormatter = new PropertyValueFormatter();
        this.propertyValueFormatter.setLocalConfig({
          currencyCode: config.currencyCode || 'USD',
          locale: config.locale,
        });
        this.setDisplay();
      }),
    );
  }
  ngOnInit(): void {
    this.setDisplay();
  }
  ngOnChanges() {
    this.setDisplay();
  }
  setDisplay() {
    if (!this.propertyValueFormatter) {
      return;
    }
    if (this.property?.options) {
      this.display = this.propertyValueFormatter.formatOptionValue(this.value, this.property);
    } else if (this.property) {
      this.display = this.propertyValueFormatter.formatValue(this.value, this.property.propertyType);
    } else {
      this.display = this.propertyValueFormatter.formatValue(this.value, this.propertyType);
    }
  }
  ngOnDestroy() {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }
}
