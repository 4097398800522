import { createAction, props } from '@ngrx/store';
import { FeatureFlag } from './feature-flag';

export enum FeatureFlagsActionTypes {
  LOAD_FEATURE_FLAGS = '[Feature Flags] Load Feature Flags',
  LOAD_FEATURE_FLAGS_SUCCESS = '[Feature Flags] Load Feature Flags Success',
  LOAD_FEATURE_FLAGS_FAILURE = '[Feature Flags] Load Feature Flags Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadFeatureFlags = createAction(FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS);

export const loadFeatureFlagsSuccess = createAction(
  FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS_SUCCESS,
  props<{ data: Array<FeatureFlag> }>(),
);

export const loadFeatureFlagsFailure = createAction(
  FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS_FAILURE,
  props<{ error: any }>(),
);
