import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';

export class EditorCalc {
  public container: HTMLElement;

  constructor(protected canvasDocument: CanvasDocument) {
    if (this.canvasDocument.mode === 'EDIT') {
      this.container = document.createElement('div');
      this.container.className = `text-calc`;
      document.getElementById(this.canvasDocument.elementId).appendChild(this.container);
    }
  }

  public getTextSize(text: string, fontSize: string = '', fontFamily: string = '') {
    const styleString = `width: auto; height: auto; font-family: ${fontFamily}; font-size: ${fontSize};`;
    return this.measure(text, styleString);
  }

  public measure(text: string, style: string) {
    this.container.setAttribute('style', style);
    this.container.innerHTML = text;
    return {
      width: this.container.clientWidth,
      height: this.container.clientHeight,
    };
  }

  public getTextContent(innerHtml: string) {
    this.container.innerHTML = innerHtml;
    return this.container.textContent?.replace(/\uFEFF/g, '')?.replace(/\u00A0/g, '');
  }

  public isEmptyTextContent(innerHtml: string) {
    const isEmptyInnerHtml = CanvasUtil.isEmptyTextContent(innerHtml, ''); // Check if innerHtml is a list of has indent first before getting textContent from HTML
    // console.log('isEmptyTextContent isEmptyInnerHtml', isEmptyInnerHtml, JSON.stringify({ innerHtml }))
    if (!isEmptyInnerHtml) {
      return false;
    }
    const plainText = this.getTextContent(innerHtml);
    const isEmpty = CanvasUtil.isEmptyTextContent(innerHtml, plainText);
    // console.log('isEmptyTextContent', isEmpty, JSON.stringify({ innerHtml }), JSON.stringify({ plainText }))
    return isEmpty;
  }

  public remove() {
    this.container?.remove();
    this.container = null;
  }

  public static getSize(text: string, style: string) {
    let div = document.createElement('div');
    div.innerHTML = text;
    div.setAttribute('class', 'text-calc');
    div.setAttribute('style', style);
    document.body.appendChild(div);
    const width = div.clientWidth;
    const height = div.clientHeight;
    document.body.removeChild(div);
    div = null;
    return { width, height };
  }

  public static getVerticalTransform(
    valign: string,
    containerHeight: number,
    padding: number,
    borderWidth: number,
    elementHeight: number,
  ) {
    let transform;
    const height = containerHeight - padding * 2 - borderWidth * 2;
    switch (valign) {
      case 'middle':
        transform = `translate(0px, ${Math.round(height * 0.5 - elementHeight * 0.5)}px)`;
        break;
      case 'bottom':
        transform = `translate(0px, ${Math.round(height - elementHeight)}px)`;
        break;
      default:
        transform = `translate(0px, 0px)`;
        break;
    }
    return transform;
  }
}
