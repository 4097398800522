import { Tools } from '@babylonjs/core';

export class TextureTransform {
  uOffset: number;
  vOffset: number;
  uScale: number;
  vScale: number;
  wAngle: number;

  constructor(uOffset: number, vOffset: number, wAngle: number, scale: number) {
    this.uOffset = uOffset;
    this.vOffset = vOffset;
    this.wAngle = Tools.ToRadians(wAngle); // Assume angle always passed in as degrees
    this.uScale = scale;
    this.vScale = scale;
  }
}
