import { Component, Input, OnDestroy } from '@angular/core';
import { ExportDownloadOptions } from '@common/exports/export-widget/export-widget.component';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Subject, tap, takeUntil, debounceTime } from 'rxjs';
import { BoardsSelectors } from '../../boards-store';
import { BoardPropertyPoliciesService } from '../board-property-policies/board-property-policies.service';
import { BoardService } from '../board.service';
import { Frame } from '../canvas/state/canvas-frame-state';
import { DocumentService } from '../document/document.service';

@Component({
  selector: 'app-board-export',
  template: ` <app-export-widget label="EXPORT" [options]="options" [selectedIds]="selectedIds"></app-export-widget> `,
  styleUrls: ['./board-export.component.scss'],
})
export class BoardExportComponent implements OnDestroy {
  @Input() selectedIds: string[];
  @Input() entityReference: string;
  public options: ExportDownloadOptions = {};
  private destroy$ = new Subject();

  constructor(
    private store: Store<RootStoreState.State>,
    private boardService: BoardService,
    private boardPropertyPoliciesService: BoardPropertyPoliciesService,
    private documentService: DocumentService,
  ) {
    this.store
      .select(BoardsSelectors.showSourceAssortmentWarning)
      .pipe(
        tap((showSourceAssortmentWarning) => {
          this.setOption('powerpoint', 'showWarnings', showSourceAssortmentWarning);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.boardService.currentBoard.pipe(takeUntil(this.destroy$)).subscribe((currentBoard) => {
      if (currentBoard) {
        this.options.pdf_snapshots = Object.assign(this.options.pdf_snapshots || {}, {
          name: currentBoard.name,
        });
        this.setOption('pdf_snapshots', 'entityReference', `board:${currentBoard.id}`);
        this.setOption(
          'pdf_snapshots',
          'restrictedViewablePropertySlugs',
          this.boardPropertyPoliciesService.restrictedViewablePropertySlugs,
        );

        this.setOption('powerpoint', 'entityReference', `board:${currentBoard.id}`);
      }
    });

    if (this.documentService.documentRenderer) {
      this.documentService.documentElements.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe((elements) => {
        if (elements && elements.length) {
          const framesMap = this.documentService.getFrames();
          let frames = Array.from(framesMap.values());
          frames.sort((a, b) => a.index - b.index);
          this.setOption(
            'pdf_snapshots',
            'frames',
            frames.map((frame: Frame) => {
              const frameElement: DocumentElement = ObjectUtil.cloneDeep(frame.element.elementDefinition);
              frameElement.elements = frame.elements.map((e) =>
                ObjectUtil.cloneDeep(e.canvasElement.elementDefinition),
              );
              return frameElement;
            }),
          );
        }
      });
    }
  }

  private setOption(type, key, value) {
    if (!this.options[type]) {
      this.options[type] = {};
    }
    this.options[type][key] = value;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
