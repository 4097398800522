import { PositionDefinition } from '@contrail/documents';
import * as EventEmitter from 'events';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CanvasDocument } from '../../canvas-document';
import { CanvasUtil } from '../../canvas-util';
import { ACCENT_COLOR } from '../../constants';

export interface InputElementOptions {
  width?: number;
  height: number;
  top?: number;
  left?: number;
  position: PositionDefinition;
}

export class InputElement extends EventEmitter {
  public isEditing = false;
  private container: HTMLElement;
  private span: HTMLElement;
  private input: HTMLInputElement;
  private valueUpdates$: ReplaySubject<string> = new ReplaySubject(1);
  private value: string;

  private readonly BORDER_WIDTH = 2;

  constructor(private canvasDocument: CanvasDocument) {
    super();
    this.valueUpdates$.pipe(debounceTime(500)).subscribe((value) => {
      this.emit('save', value);
    });
  }

  public show(value: string, options: InputElementOptions) {
    if (this.isEditing) return;

    this.isEditing = true;
    this.value = value;

    this.container = document.createElement('div');
    this.container.style.lineHeight = 'normal';
    this.container.style.position = 'fixed';
    this.container.style.height = '0';
    this.container.style.transformOrigin = 'top left';

    this.span = document.createElement('span');
    this.span.style.opacity = '0';
    this.span.style.position = 'absolute';
    this.span.style.pointerEvents = 'none';
    this.span.style.whiteSpace = 'pre';
    this.span.style.fontFamily = 'Roboto';
    this.span.style.fontSize = '14px';
    this.span.style.letterSpacing = '0.5px';
    this.span.innerHTML = value;
    this.container.append(this.span);

    this.input = document.createElement('input');
    this.input.style.position = 'relative';
    this.input.style.display = 'flex';
    this.input.style.borderColor = ACCENT_COLOR;
    this.input.style.borderStyle = 'solid';
    this.input.style.borderWidth = `${this.BORDER_WIDTH}px`;
    this.input.style.backgroundColor = '#fff';
    this.input.style.fontFamily = 'Roboto';
    this.input.style.fontSize = '14px';
    this.input.style.letterSpacing = '0.5px';
    this.input.style.overflow = 'hidden';
    this.input.style.resize = 'none';
    this.input.style.lineHeight = 'normal';
    this.input.style.cursor = 'text';
    this.input.setAttribute('autocorrect', 'off');
    this.input.setAttribute('autocomplete', 'off');
    this.input.setAttribute('autocapitalize', 'off');
    this.input.style.height = `${options.height}px`;
    if (options.top) {
      this.input.style.top = `${options.top}px`;
    }
    if (options.left) {
      this.input.style.left = `${options.left}px`;
    }
    this.input.value = value;
    this.container.append(this.input);

    this.addEventListeners();
    this.setPosition(options.position);

    document.getElementById(this.canvasDocument.elementId).appendChild(this.container);

    this.input.style.width = options.width ? `${options.width}` : `${this.getValueWidth(value)}px`;
    this.input.select();
  }

  private addEventListeners() {
    this.input.addEventListener('keydown', (event) => {
      if (event.code === 'Enter') {
        this.hideAndSave();
      }
    });
    this.input.addEventListener('keyup', (event) => {
      const value = this.input.value;
      this.valueUpdates$.next(value);
      if (this.getInputWidth() > this.getValueWidth(value)) {
        this.growInputWidth(value);
      }
    });

    this.input.addEventListener('scroll', (event) => {
      const value = this.input.value;
      this.growInputWidth(value);
    });

    this.input.addEventListener('blur', (event) => {
      if (this.isEditing) {
        this.hideAndSave();
      }
    });
  }

  private getInputWidth() {
    return this.input.offsetWidth;
  }

  private getValueWidth(value) {
    this.span.innerHTML = value;
    return Math.ceil(this.span.clientWidth) + this.BORDER_WIDTH * 2;
  }

  private growInputWidth(value?: string) {
    const width = this.getValueWidth(value);
    this.input.style.width = `${width}px`;
  }

  public setPosition(position: PositionDefinition) {
    const windowPosition = this.canvasDocument.toWindowPosition(position.x, position.y);
    this.container.style.top = `${windowPosition.y}px`;
    this.container.style.left = `${windowPosition.x}px`;
  }

  private hideAndSave() {
    const value = this.input.value;
    if (value && value != this.value) {
      this.valueUpdates$.next(value);
    }
    if (this.container) {
      try {
        this.container.remove();
      } catch (e) {}
      this.container = null;
      this.input = null;
      this.span = null;
    }
    this.isEditing = false;
    this.value = null;
    this.emit('save', value);
    this.emit('hide', value);
  }
}
