import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ClipboardService } from '../clipboard.service';
import { ClipboardActions } from '.';

@Injectable()
export class ClipboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private clipboardService: ClipboardService,
  ) {}

  loadClipboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClipboardActions.ClipboardActionTypes.LOAD_CLIPBOARD),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.clipboardService.getClipboard()).pipe(
          map((clipboard) => ClipboardActions.loadClipboardSuccess({ clipboard })),
          catchError((error) => observableOf(ClipboardActions.loadClipboardFailure({ error }))),
        );
      }),
    ),
  );
}
