<div class="container">
  <div class="header">
    <div class="title">Create Item</div>
    <div class="actions">
      <button mat-raised-button (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="!isValid()" (click)="create()">Create</button>
    </div>
  </div>
  <div class="body">
    <div class="left-column">
      <app-item-type-selector></app-item-type-selector>
      <div class="divider"></div>
      <app-item-family></app-item-family>
    </div>
    <div class="center-column">
      <app-item-properties-form [item]="{}" [type]="type"></app-item-properties-form>
    </div>
  </div>
</div>
