import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { escapeRegExp } from 'lodash';
import { ObjectUtil } from '@contrail/util';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, startWith, Subscription } from 'rxjs';
import {
  CollectionStatusMessage,
  CollectionStatusMessageTypes,
} from '@common/collection-status-message/collection-status-message';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';

@Component({
  selector: 'app-status-messages-list',
  templateUrl: './status-messages-list.component.html',
  styleUrls: ['./status-messages-list.component.scss'],
})
export class StatusMessagesListComponent implements OnChanges, AfterViewInit, OnDestroy {
  public title = 'Nothing to report!';
  public icon = 'warning-placeholder';
  public footer = 'There are no exceptions at this time';
  public actionLabel = '';
  private allAlertsSubject = new BehaviorSubject<Array<CollectionStatusMessage>>(null);
  private filteredAlertsSubject = new BehaviorSubject<Array<CollectionStatusMessage>>(null);
  public filteredAlerts$ = this.filteredAlertsSubject.asObservable();
  private subscriptions: Subscription = new Subscription();
  public readonly warningTypes = [
    CollectionStatusMessageTypes.WARNING,
    CollectionStatusMessageTypes.NOT_IN_SOURCE_ASSORTMENT,
  ];
  private readonly searchableProperties = ['message', 'entityName'];

  @Output() onClose = new EventEmitter();
  @Output() onMessageClick = new EventEmitter();
  @Output() onToggleShowWarning = new EventEmitter();

  @Input() showWarning = true;
  @Input() messages: CollectionStatusMessage[];
  @Input() showWarningToggle = true;

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  constructor() {}

  ngOnChanges(changes: { [input: string]: SimpleChange }): void {
    if (changes.messages) {
      this.allAlertsSubject.next(this.messages);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), debounceTime(200), distinctUntilChanged()),
        this.allAlertsSubject.asObservable(),
      ]).subscribe(([searchTerm, alerts]) => {
        const filteredAlerts = this.filterAlertsBySearchTerm(searchTerm, alerts);
        this.filteredAlertsSubject.next(filteredAlerts);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleClose() {
    this.onClose.emit();
  }

  handleOnClick(message) {
    this.onMessageClick.emit({ statusMessageElement: message.collectionElementId });
  }

  toggleShowWarning(event) {
    this.showWarning = event.checked;
    this.onToggleShowWarning.emit({ showSourceAssortmentWarning: this.showWarning });
  }

  private filterAlertsBySearchTerm(searchTerm: string, alerts: CollectionStatusMessage[]): CollectionStatusMessage[] {
    const filteredAlerts = alerts.filter((prop) => {
      return (
        !searchTerm?.length ||
        this.searchableProperties.some((key) =>
          new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(prop, key.trim())),
        )
      );
    });

    return filteredAlerts;
  }
}
