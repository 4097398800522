import { createAction, props } from '@ngrx/store';
import { EntitySnapshot } from './document-history.state';

export enum DocumentHistoryActionTypes {
  LOAD_CURRENT_ENTITY_SNAPSHOT = '[Document History] Load Current Entity Snapshot',
  LOAD_CURRENT_ENTITY_SNAPSHOT_SUCCESS = '[Document History] Load Current Entity Snapshot Success',
  LOAD_CURRENT_ENTITY_SNAPSHOT_FAILURE = '[Document History] Load Current Entity Snapshot Failure',

  SET_CURRENT_ENTITY_SNAPSHOT = '[Document History] Set Current Entity Snapshot',
  CLEAR_CURRENT_ENTITY_SNAPSHOT = '[Document History] Clear Current Entity Snapshot',

  RESTORE_ENTITY_SNAPSHOT = '[Document History] Restore Entity Snapshot',
}

////////////////////////////////////////////////////////////////////////////////
export const setCurrentEntitySnapshot = createAction(
  DocumentHistoryActionTypes.SET_CURRENT_ENTITY_SNAPSHOT,
  props<{ entitySnapshot: EntitySnapshot }>(),
);

export const clearCurrentEntitySnapshot = createAction(DocumentHistoryActionTypes.CLEAR_CURRENT_ENTITY_SNAPSHOT);

export const loadCurrentShowcase = createAction(
  DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT,
  props<{ id: string }>(),
);

export const loadCurrentShowcaseSuccess = createAction(
  DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT_SUCCESS,
  props<{ entitySnapshot: EntitySnapshot }>(),
);

export const loadCurrentShowcaseFailure = createAction(
  DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT_FAILURE,
  props<{ error: any }>(),
);

export const restoreEntitySnapshot = createAction(
  DocumentHistoryActionTypes.RESTORE_ENTITY_SNAPSHOT,
  props<{ id: string }>(),
);
