import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { DRAG_DIRECTIONS, SelectionWidgetRenderer } from './selection-widget-renderer';

export class FrameSelectionWidgetRenderer extends SelectionWidgetRenderer {
  constructor(
    protected canvasDocument: CanvasDocument,
    protected element: CanvasElement,
  ) {
    super(canvasDocument, element);
  }

  public draw(ctx, drawHandles = true) {
    const { x, y, width, height } = this.element.getDimensions();

    ctx.save();

    this.drawWidget(ctx, { x, y, width, height });

    if (drawHandles) {
      const dragHandleWidth = this.getHandleWidth();
      const dragHandlePositions = [
        [-width * 0.5, -height * 0.5],
        [width * 0.5, -height * 0.5],
        [width * 0.5, height * 0.5],
        [-width * 0.5, height * 0.5],
      ];
      for (let i = 0; i < dragHandlePositions?.length; i++) {
        const dragHandlePosition = dragHandlePositions[i];
        this.drawDragHandle(ctx, dragHandlePosition[0], dragHandlePosition[1], dragHandleWidth * 0.5);
      }
    }

    ctx.restore();
  }
}
