import * as DocumentHistoryActions from './document-history.actions';
import * as DocumentHistorySelectors from './document-history.selectors';
import * as DocumentHistoryStoreState from './document-history.state';

export { DocumentHistoryStoreModule } from './document-history-store.module';

const actions = {
  ...DocumentHistoryActions,
};

const selectors = {
  ...DocumentHistorySelectors,
};

export { actions as DocumentHistoryActions, selectors as DocumentHistorySelectors, DocumentHistoryStoreState };
