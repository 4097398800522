import { Injectable } from '@angular/core';
import { Feature } from '@common/feature-flags/feature-flag';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { Entities } from '@contrail/sdk';
import { tap } from 'rxjs';
import { FrameTemplate } from './frame-template';
import { FrameTemplatesSelectors, FrameTemplatesActions } from './frame-templates-store';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FrameTemplatesService {
  private frameTemplatesLoaded = false;
  constructor(
    private featureFlagService: FeatureFlagRegistryService,
    private store: Store<RootStoreState.State>,
  ) {}

  public initialize(templateTypes: string[]) {
    this.store
      .select(FrameTemplatesSelectors.frameTemplates)
      .pipe(
        tap((frameTemplates: FrameTemplate[]) => {
          if (frameTemplates.length === 0 && !this.frameTemplatesLoaded) {
            this.store.dispatch(FrameTemplatesActions.loadFrameTemplates({ templateTypes }));
            this.frameTemplatesLoaded = true;
          }
        }),
      )
      .subscribe();
  }

  public async getFrameTemplates(templateTypes = ['FRAME']) {
    let frameTemplates = [];
    for (let i = 0; i < templateTypes.length; i++) {
      const criteria = { templateType: templateTypes[i], includePrivate: true };
      const templates = await new Entities().get({
        entityName: 'document-template',
        criteria,
        relations: ['previewFile', 'document', 'updatedBy', 'createdBy'],
      });
      if (templates) {
        frameTemplates = frameTemplates.concat(templates);
      }
    }
    frameTemplates.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    return frameTemplates;
  }

  public async getFrameTemplateById(id: string) {
    const frameTemplate = await new Entities().get({
      entityName: 'document-template',
      id,
      relations: ['document', 'document.elements', 'document.documentElementSortOrder'],
    });
    if (frameTemplate.document.elementsDownloadURL) {
      const response = await fetch(frameTemplate.document.elementsDownloadURL);
      const elements = await response.json();
      frameTemplate.document.elements = elements;
    }
    return frameTemplate;
  }

  public async createFrameTemplate(templateData: FrameTemplate) {
    let frameTemplate = await new Entities().create({ entityName: 'document-template', object: templateData });
    frameTemplate = await new Entities().get({
      entityName: 'document-template',
      id: frameTemplate.id,
      relations: ['previewFile', 'document', 'document.elements', 'updatedBy', 'createdBy'],
    });
    return frameTemplate;
  }

  public async updateFrameTemplate(id: string, changes: any) {
    return new Entities().update({ entityName: 'document-template', id, object: changes });
  }

  public async deleteFrameTemplate(template: FrameTemplate) {
    await new Entities().delete({ entityName: 'document-template', id: template.id });
    return template;
  }
}
