import { CanvasDocument } from '../../canvas-document';
import { PenDrawHandler } from '../../interactions/canvas-event-handlers/drag-event-handlers/pen-draw-handler';

export class FreeDrawRenderer {
  public points: Array<[number, number]> = [];
  public lineWidth: number;
  public strokeStyle: string;
  public opacity: number;

  constructor(private canvasDocument: CanvasDocument) {}

  /**
   * Draw highlight box for target element
   */
  public draw() {
    if (this.points?.length > 0) {
      const ctx = this.canvasDocument.canvasDisplay.context;

      const svgPathData = PenDrawHandler.getSvgPathFromPoints(this.points);
      const path = new Path2D(svgPathData);

      ctx.save();

      if (this.opacity) {
        ctx.globalAlpha = this.opacity;
      }

      ctx.lineDashOffset = 0;
      ctx.setLineDash([]);
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      ctx.lineWidth = this.lineWidth;
      ctx.strokeStyle = this.strokeStyle;
      ctx.stroke(path);

      ctx.restore();
    }
  }
}
