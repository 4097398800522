import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-boolean',
  template: `
    <mat-slide-toggle
      color="primary"
      *ngIf="formControl"
      [attr.data-test]="propertyFormConfiguration.typeProperty.slug + '-slide-toggle'"
      [formControl]="formControl"
      [disabled]="formControl.disabled"
    >
      {{ propertyFormConfiguration.typeProperty.label }}
    </mat-slide-toggle>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .input {
        margin-bottom: 8px;
      }
      mat-label {
        margin-right: 10px;
      }
    `,
  ],
})
export class TypePropertyFormFieldBooleanComponent extends TypePropertyFormFieldBaseComponent {
  async initFormControl() {
    const disabled = await this.isDisabled();
    this.formControl = new UntypedFormControl({ value: this.value, disabled });
    this.initFormControlListener();
  }
}
