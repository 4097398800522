import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

export interface Color {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;

  name?: string;

  createdById?: string;
  updatedById?: string;

  createdBy?: any;
  updatedBy?: any;

  typeId?: string;

  orgId?: string;
  workspaceId?: string;
  isTrashed?: boolean;

  federatedId?: string;

  hex?: string;
  hexCode?: string;
  red?: any;
  green?: any;
  blue?: any;
  rgb?: any;
  rgba?: any;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private static colorsCache: Map<string, Color> = new Map();

  constructor() {}

  public static async getColor(color: string): Promise<Color> {
    const hexCode = this.toHex(color);
    if (!hexCode) {
      return null;
    }

    const cachedColorEntity = this.colorsCache.get(hexCode);
    if (cachedColorEntity || cachedColorEntity === null) {
      return cachedColorEntity;
    }

    const getConfig = {
      entityName: 'color',
      criteria: {
        hexCode,
      },
    };
    const data = await new Entities().get(getConfig);
    const colorEntity = data?.length > 0 ? data[0] : null;
    this.colorsCache.set(hexCode, colorEntity);
    return colorEntity;
  }

  public static rgbaToHex = (red, green, blue, alpha) =>
    (blue | (green << 8) | (red << 16) | (1 << 24)).toString(16).slice(1) + alpha;

  public static toHex(color: string): string {
    const hex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.exec(color);
    if (hex) {
      return `#${this.normalizeHex(hex[1])}`;
    }

    const rgba =
      /^(rgb)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/.exec(color);
    if (rgba) {
      const r = parseInt(rgba[3]);
      const g = parseInt(rgba[4]);
      const b = parseInt(rgba[5]);
      let a: any = rgba[6];
      if (a) {
        a = Math.round(255 * parseFloat(a));
        a = (a | (1 << 8)).toString(16).slice(1);
      }
      return `#${this.rgbaToHex(r, g, b, a)}`;
    }

    return null;
  }

  private static normalizeHex = (hex) => (hex.length > 3 ? hex : hex.replaceAll(/(.{1})/g, '$1$1'));
}
