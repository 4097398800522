import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  sideVisible = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const urlSnippets = evt?.urlAfterRedirects.split('/');
        this.sideVisible =
          urlSnippets.includes('home') || urlSnippets.includes('frame-template-library') ? true : false;
      }
    });
  }

  ngOnInit(): void {}
}
