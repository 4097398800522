import { Action, createReducer, on } from '@ngrx/store';
import { CustomFontsActions } from '.';
import { initialState, State } from './custom-fonts.state';

const setCustomFonts = (state: State, { customFonts, customFontFamilies }) => {
  return {
    ...state,
    customFonts,
    customFontFamilies,
    loaded: true,
  };
};

export const customFontsReducer = createReducer(initialState, on(CustomFontsActions.setCustomFonts, setCustomFonts));

export function reducer(state: State | undefined, action: Action): any {
  return customFontsReducer(state, action);
}
