import { LineDefinition, ViewBox } from '@contrail/documents';
import { CanvasUtil } from '../../../../canvas-util';

export interface GuidelineDimension {
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  distance?: number;
  lineDefinition?: LineDefinition;
}

export class DistanceGuidelineHandlerService {
  constructor() {}

  /**
   * Get element coordinates on the same horizontal or vertical plane as @targetElementDimensions
   * Split elements on elements that are positioned before and after @targetElementDimensions
   * @param targetElementDimensions
   * @param otherElementDimensions
   * @param direction
   * @returns
   */
  public static getSameAxisElementCoordinates(
    targetElementDimensions: ViewBox,
    otherElementDimensions: GuidelineDimension[],
    direction: 'x' | 'y',
  ): { beforeElements: GuidelineDimension[]; afterElements: GuidelineDimension[] } {
    const beforeElements: GuidelineDimension[] = [];
    const afterElements: GuidelineDimension[] = [];
    for (let i = 0; i < otherElementDimensions?.length; i++) {
      const element = otherElementDimensions[i];
      if (direction === 'x') {
        if (
          element.y < targetElementDimensions.y + targetElementDimensions.height &&
          element.y + element.height > targetElementDimensions.y
        ) {
          if (element.x + element.width < targetElementDimensions.x) {
            beforeElements.push({ ...element });
          } else if (element.x > targetElementDimensions.x + targetElementDimensions.width) {
            afterElements.push({ ...element });
          }
        }
      } else {
        if (
          element.x < targetElementDimensions.x + targetElementDimensions.width &&
          element.x + element.width > targetElementDimensions.x
        ) {
          if (element.y + element.height < targetElementDimensions.y) {
            beforeElements.push({ ...element });
          } else if (element.y > targetElementDimensions.y + targetElementDimensions.height) {
            afterElements.push({ ...element });
          }
        }
      }
    }
    return {
      beforeElements: this.filterOverlapElements(
        beforeElements.sort((a, b) =>
          direction === 'x' ? b.x + b.width - (a.x + a.width) : b.y + b.height - (a.y + a.height),
        ),
        direction,
        'before',
      ),
      afterElements: this.filterOverlapElements(
        afterElements.sort((a, b) => (direction === 'x' ? a.x - b.x : a.y - b.y)),
        direction,
        'after',
      ),
    };
  }

  /**
   * Filter out elements that overlap and calculate distance to each element on the same plane.
   * @param elements
   * @param direction
   * @param group
   * @returns
   */
  public static filterOverlapElements(
    elements: GuidelineDimension[],
    direction: 'x' | 'y',
    group: 'before' | 'after',
  ): GuidelineDimension[] {
    let overlapElements: GuidelineDimension[] = [];
    elements.forEach((e) => {
      const lastElement = overlapElements[overlapElements.length - 1];
      if (
        !lastElement ||
        (!CanvasUtil.overlap(e, lastElement) &&
          (direction === 'x'
            ? group === 'before'
              ? lastElement.x + lastElement.width > e.x + e.width
              : lastElement.x + lastElement.width < e.x + e.width
            : group === 'before'
              ? lastElement.y + lastElement.height > e.y + e.height
              : lastElement.y + lastElement.height < e.y + e.height))
      ) {
        overlapElements.push(e);
      }
    });

    overlapElements = overlapElements.map((e, i) => {
      const nextElement = overlapElements[i + 1];
      if (nextElement) {
        e.distance = this.getDistance(e, nextElement, direction);
        e.lineDefinition = this.getDistanceLineDefinition(nextElement, e, e.distance, direction, group);
      }
      return e;
    });

    return overlapElements;
  }

  /**
   * Calculate lineDefinition between @element and @targetElement
   * given horizontal or vertical @distance between them.
   * @param element
   * @param targetElement
   * @param distance
   * @param direction
   * @param group
   * @returns
   */
  public static getDistanceLineDefinition(
    element: GuidelineDimension,
    targetElement: GuidelineDimension,
    distance: number,
    direction: 'x' | 'y',
    group: 'before' | 'after',
  ): LineDefinition {
    const distanceRect =
      direction === 'x'
        ? {
            x: group === 'before' ? element.x + element.width : element.x - distance,
            y: Math.max(element.y, targetElement.y),
            width: distance,
            height:
              Math.min(element.y + element.height, targetElement.y + targetElement.height) -
              Math.max(element.y, targetElement.y),
          }
        : {
            x: Math.max(element.x, targetElement.x),
            y: group === 'before' ? element.y + element.height : element.y - distance,
            width:
              Math.min(element.x + element.width, targetElement.x + targetElement.width) -
              Math.max(element.x, targetElement.x),
            height: distance,
          };
    return direction === 'x'
      ? {
          x1: distanceRect.x,
          y1: distanceRect.y + distanceRect.height * 0.5,
          x2: distanceRect.x + distanceRect.width,
          y2: distanceRect.y + distanceRect.height * 0.5,
        }
      : {
          x1: distanceRect.x + distanceRect.width * 0.5,
          y1: distanceRect.y,
          x2: distanceRect.x + distanceRect.width * 0.5,
          y2: distanceRect.y + distanceRect.height,
        };
  }

  /**
   * Calculate horizontal or vertical distance between @coordRectA and @coordRectB given two
   * rectangles do not overlap.
   * @param coordRectA
   * @param coordRectB
   * @param direction
   * @returns
   */
  public static getDistance(coordRectA: ViewBox, coordRectB: ViewBox, direction: 'x' | 'y') {
    return direction === 'x'
      ? Math.floor(
          coordRectA.x < coordRectB.x
            ? coordRectB.x - (coordRectA.x + coordRectA.width)
            : coordRectA.x - (coordRectB.x + coordRectB.width),
        )
      : Math.floor(
          coordRectA.y < coordRectB.y
            ? coordRectB.y - (coordRectA.y + coordRectA.height)
            : coordRectA.y - (coordRectB.y + coordRectB.height),
        );
  }
}
