export class ColorUtil {
  public static stringToHslaColor(str, s = 30, l = 60, a = 1) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    // hsla(0, 100%, 50%, 0.2)
    return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + a + ')';
  }

  public static isRgb(color: string): boolean {
    return color.trim().substring(0, 3) == 'rgb';
  }

  public static rgbToHexString(rgbStr: string): string {
    let rgb = rgbStr.replace(/[^\d,]/g, '').split(',');

    return ColorUtil.rgbToHex(rgb[0], rgb[1], rgb[2]);
  }

  public static rgbToHex(r, g, b) {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  public static hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  // Converts both colors to hex to do equivalence comparison
  public static areEqualColors(color1: string, color2: string): boolean {
    color1 = ColorUtil.isRgb(color1) ? ColorUtil.rgbToHexString(color1) : color1;
    color2 = ColorUtil.isRgb(color2) ? ColorUtil.rgbToHexString(color2) : color2;

    return color1 == color2;
  }
}
