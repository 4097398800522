import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { DocumentElementEvent } from '@contrail/documents';
import { RootStoreState } from 'src/app/root-store';
import { SelectedElementInfo } from '../board-context-menu.component';
import { DocumentService } from '../../document/document.service';
import { BoardService } from '../../board.service';
import { DocumentAssetService } from '../../document/document-asset/document-asset.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { DocumentGeneratorConfigComponent } from '../../document-generator/document-generator-config/document-generator-config.component';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { CanvasDocument } from '../../canvas/canvas-document';
import { CanvasUtil } from '../../canvas/canvas-util';

export class GenerateDocumentMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected documentService: DocumentService,
    protected boardService: BoardService,
    protected documentAssetService: DocumentAssetService,
    protected matDialog: MatDialog,
    protected confirmationBoxService: ConfirmationBoxService,
  ) {
    super(store, documentService, boardService);
    this.actionIcon = '';
    this.actionLabel = 'Lineboard';
    this.iconClass = '';
    this.testRef = 'app-menu-generate-document';
    this.shortCutKey = '';
    this.init();
  }
  async init() {}

  async handleAction(sourceEvent: DocumentElementEvent, editorMode: string, selectedElementsInfo: SelectedElementInfo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '735px';
    dialogConfig.maxHeight = '700px';
    dialogConfig.panelClass = 'generate-document-panel';
    dialogConfig.data = {};
    dialogConfig.disableClose = true;

    const dialogRef = this.matDialog.open(DocumentGeneratorConfigComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (result) => {
      const canvasDocument = this.documentService.documentRenderer as CanvasDocument;
      const viewBox = canvasDocument.getViewBox();
      const viewScale = canvasDocument.getViewScale();
      const boundingClientRect = canvasDocument.getBoundingClientRect();
      const coords = CanvasUtil.toDocumentPosition(
        sourceEvent.sourceMouseEvent.x,
        sourceEvent.sourceMouseEvent.y,
        viewBox,
        viewScale,
        boundingClientRect,
      );
      this.boardService.generateFrames(result, coords);
    });
  }

  canInvoke(editorMode: string, selectedElementsInfo: SelectedElementInfo = null) {
    return editorMode === EditorMode.EDIT && selectedElementsInfo.documentElements.length === 0;
  }
}
