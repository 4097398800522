export class EditorFormatter {
  public static FORMATS = [
    'textColor',
    'textBackgroundColor',
    'fontFamily',
    'fontSize',
    'textAlign',
    'listType',
    'indent',
    'outdent',
    'clearFormat',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'unlink',
  ];
  constructor() {}

  public static getCommand(action): { command?: string; value?: string; format?: any } {
    let command;
    let value;
    let format;
    switch (action.type) {
      case 'textColor':
        command = 'mceApplyTextcolor';
        format = 'forecolor';
        value = action.value;
        break;
      case 'textBackgroundColor':
        command = 'mceApplyTextcolor';
        format = 'hilitecolor';
        value = action.value;
        break;
      case 'fontFamily':
        command = 'FontName';
        value = action.value;
        break;
      case 'fontSize':
        command = 'FontSize';
        value = action.value + 'pt';
        break;
      case 'textAlign':
        command = 'JustifyLeft';
        if (action.value === 'center') {
          command = 'JustifyCenter';
        } else if (action.value === 'right') {
          command = 'JustifyRight';
        }
        break;
      case 'listType':
        command = 'InsertUnorderedList';
        value = {
          'list-style-type': '',
        };
        if (action.value === 'decimal') {
          command = 'InsertOrderedList';
        } else if (action.value === '') {
          value = null;
          command = 'RemoveList';
        }
        break;
      case 'indent':
        command = 'indent';
        break;
      case 'outdent':
        command = 'outdent';
        break;
      case 'clearFormat':
        command = 'RemoveFormat';
        break;
      case 'bold':
        command = 'Bold';
        value = action.value;
        break;
      case 'italic':
        command = 'Italic';
        value = action.value;
        break;
      case 'underline':
        command = 'Underline';
        value = action.value;
        break;
      case 'strikethrough':
        command = 'Strikethrough';
        value = action.value;
        break;
      case 'link':
        command = 'CreateLink';
        value = action.value;
        break;
      case 'unlink':
        command = 'Unlink';
        value = null;
        break;
      default:
        command = 'mceToggleFormat';
        value = action.type;
    }
    return { command, format, value };
  }
}
