<div class="context-menu" #contextMenu>
  <div mat-button [matMenuTriggerFor]="menu"></div>

  <mat-menu #menu="matMenu">
    <button
      class="context-menu-button"
      *ngIf="selectedItemElements.length === 1"
      mat-menu-item
      [matMenuTriggerFor]="item"
      data-test="context-menu-item"
    >
      Item
    </button>
    <mat-menu #item="matMenu">
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="launchItemDetails()"
        data-test="context-menu-button-item-view-item"
      >
        View Item
      </button>
      <!--
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="createNewOption()"
        data-test="context-menu-button-item-create-option"
      >
        Create Option
      </button>
    -->
      <button
        *ngIf="itemBoardsFeatureFlag"
        class="context-menu-button"
        mat-menu-item
        (click)="createItemBoard()"
        data-test="context-menu-button-item-view-item"
      >
        Create Board
      </button>
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToClipboard()"
        data-test="context-menu-button-item-add-to-clipboard"
      >
        Add to clipboard
      </button>
      <button
        *ngIf="itemContextFeatureActive && itemCreationFeatureActive"
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToProject()"
        data-test="context-menu-button-item-add-to-project"
      >
        Switch / add to project
      </button>
    </mat-menu>

    <button
      class="context-menu-button"
      *ngIf="selectedItemElements.length > 1"
      mat-menu-item
      [matMenuTriggerFor]="items"
      data-test="context-menu-items"
    >
      Items
    </button>
    <mat-menu #items="matMenu">
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToClipboard()"
        data-test="context-menu-button-item-add-to-clipboard"
      >
        Add to clipboard
      </button>
      <button
        *ngIf="itemContextFeatureActive && itemCreationFeatureActive"
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToProject()"
        data-test="context-menu-button-item-add-to-project"
      >
        Switch / add to project
      </button>
    </mat-menu>

    <!-- <button *ngIf="element?.type === 'image'"
      class="context-menu-button" mat-menu-item
      (click)="emitActionRequest('init_hot_spot')">
      <mat-icon>adjust</mat-icon> Add Hotspot
    </button> -->

    <button
      *ngIf="editorMode === 'EDIT' || editorMode === 'COMMENT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-comment"
      (click)="showComments($event)"
    >
      Comment
      <div class="text-black60">Ctrl-M</div>
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-copy"
      (click)="emitActionRequest('copy_elements')"
    >
      Copy
      <div class="text-black60">Ctrl-C</div>
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT' && element.type === 'text'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-copy-properties"
      [matMenuTriggerFor]="copyProperties"
    >
      Copy/paste as
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-duplicate"
      (click)="emitActionRequest('duplicate_elements')"
    >
      Duplicate
      <div class="text-black60">Ctrl-D</div>
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-lock"
      (click)="emitActionRequest('lock_elements')"
    >
      {{ element?.isLocked ? 'Unlock' : 'Lock' }}
      <div class="text-black60">Ctrl-L</div>
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-delete"
      (click)="emitActionRequest('delete_element')"
    >
      Delete
      <div class="text-black60">Del</div>
    </button>

    <button
      *ngIf="element && selectedGroupElements?.length > 1 && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-group"
      (click)="emitActionRequest('toggle_group_elements')"
    >
      Group
      <div class="text-black60">Ctrl-G</div>
    </button>

    <button
      *ngIf="
        element &&
        selectedGroupElements?.length === 1 &&
        selectedGroupElements[0].type === 'group' &&
        editorMode === 'EDIT'
      "
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-group"
      (click)="emitActionRequest('toggle_group_elements')"
    >
      Ungroup
      <div class="text-black60">Ctrl-G</div>
    </button>

    <button
      *ngIf="element && isMaskAllowed && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-create-mask"
      (click)="emitActionRequest('toggle_mask_elements')"
    >
      Mask
      <div class="text-black60">Ctrl-K</div>
    </button>

    <button
      *ngIf="element && isRemoveMaskAllowed && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-remove-mask"
      (click)="emitActionRequest('toggle_mask_elements')"
    >
      Remove Mask
      <div class="text-black60">Ctrl-K</div>
    </button>

    <button
      *ngIf="element && editorMode === 'EDIT' && selectedFrameElements?.length"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="exportFrame"
      data-test="context-menu-button-export-frame"
    >
      Export {{ selectedFrameElements?.length === 1 ? 'frame' : 'frames' }} as
    </button>
    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="order"
      data-test="context-menu-button-order"
    >
      Order
    </button>
    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="alignment"
      [disabled]="selectedGroupElements?.length <= 1"
      data-test="context-menu-align"
    >
      Align
    </button>
    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="distribution"
      [disabled]="selectedGroupElements?.length <= 2"
      data-test="context-menu-distribute"
    >
      Distribute
    </button>
    <button
      *ngIf="element && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="matchsize"
      [disabled]="selectedGroupElementsMatchSizeEligible?.length <= 1"
      data-test="context-menu-match-size"
    >
      Resize images
    </button>
    <button
      *ngIf="element && elementIsInGroup && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      (click)="removeElementFromGroup()"
      data-test="context-menu-remove-from-group"
    >
      Remove from group
    </button>

    <button
      *ngIf="!element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="set-starting-location"
      (click)="setStartingLocation($event)"
    >
      Set board starting location
    </button>

    <!-- <button *ngIf="!element && editorMode === 'EDIT'"
      class="justify-between context-menu-button" mat-menu-item
      (click)="createNewItem($event)">
      Create new item
    </button> -->

    <button
      *ngIf="!element && editorMode === 'EDIT'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-unlock-all"
      (click)="emitActionRequest('unlock_all_elements')"
    >
      Unlock all
    </button>

    <button
      *ngIf="element?.type === 'frame' && editorMode === 'EDIT'"
      class="context-menu-button"
      mat-menu-item
      (click)="saveAsTemplate()"
      data-test="context-menu-save-as-template"
    >
      Save as template
    </button>

    <!-- New dynamic actions (ala Plan app).. This is how new actions should be added -->
    <div *ngFor="let action of actions">
      <button *ngIf="canInvoke(action)" class="context-menu-button" mat-menu-item (click)="invoke(action)">
        {{ action.actionLabel }}
      </button>
    </div>
    <button
      *ngIf="element && editorMode === 'EDIT' && contextualAppExtensions?.length"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="advanced"
      data-test="context-menu-button-advanced"
    >
      Advanced
    </button>
  </mat-menu>

  <mat-menu #order="matMenu">
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.bring_forward')"
      data-test="context-menu-button-order-forward"
    >
      Bring forward
      <div class="text-black60">Shift-PgUp</div>
    </button>
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.bring_to_front')"
      data-test="context-menu-button-order-front"
    >
      Bring to front
      <div class="text-black60">PgUp</div>
    </button>
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.send_backward')"
      data-test="context-menu-button-order-backward"
    >
      Send backward
      <div class="text-black60">Shift-PgDn</div>
    </button>
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.send_to_back')"
      data-test="context-menu-button-order-back"
    >
      Send to back
      <div class="text-black60">PgDn</div>
    </button>
  </mat-menu>

  <mat-menu #alignment="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_left')">
      <mat-icon>align_horizontal_left</mat-icon> Left
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_center')">
      <mat-icon>align_horizontal_center</mat-icon> Center
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_right')">
      <mat-icon>align_horizontal_right</mat-icon> Right
    </button>
    <mat-divider></mat-divider>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_top')">
      <mat-icon>align_vertical_top</mat-icon> Top
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_middle')">
      <mat-icon>align_vertical_center</mat-icon> Middle
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_bottom')">
      <mat-icon>align_vertical_bottom</mat-icon> Bottom
    </button>
  </mat-menu>

  <mat-menu #distribution="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.horizontal')">
      <mat-icon>horizontal_distribute</mat-icon> Horizontal
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.vertical')">
      <mat-icon>vertical_distribute</mat-icon> Vertical
    </button>
  </mat-menu>

  <mat-menu #matchsize="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.height')">Match height</button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.width')">Match width</button>
  </mat-menu>

  <mat-menu #copyProperties="matMenu">
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('copy_properties')"
      data-test="copy-properties"
    >
      Copy text format
      <div class="text-black60">Ctrl-Alt-C</div>
    </button>
    <button
      class="context-menu-button justify-between"
      [disabled]="!copiedProperties"
      mat-menu-item
      (click)="emitActionRequest('paste_properties')"
      data-test="paste-properties"
    >
      Paste text format
      <div class="text-black60">Ctrl-Alt-V</div>
    </button>
  </mat-menu>

  <mat-menu #advanced="matMenu">
    <button
      class="context-menu-button"
      mat-menu-item
      *ngFor="let appExtension of contextualAppExtensions"
      (click)="launchExtension(appExtension)"
    >
      {{ appExtension.name }}
    </button>
  </mat-menu>

  <mat-menu #exportFrame="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('export_frame_ppt')">
      PowerPoint
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('export_frame_pdf')">PDF</button>
  </mat-menu>
</div>
