import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ScaleTransformation, SizeDefinition, StyleDefinition } from '@contrail/documents';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { PropertyViewBuilderFormatBarComponent } from './property-view-builder-format-bar/property-view-builder-format-bar.component';
import { TypeProperty } from '@contrail/types';

export interface ViewPropertyDefinition {
  enabled: boolean;
  slug?: string;
  style?: StyleDefinition;
  typeRootSlug?: string;
  propertyDefinition?: TypeProperty;
  includeLabel?: boolean;
  size?: SizeDefinition;
  scale?: ScaleTransformation;
  isHidden?: boolean;
}

@Component({
  selector: 'app-property-view-builder',
  templateUrl: './property-view-builder.component.html',
  styleUrls: ['./property-view-builder.component.scss'],
})
export class PropertyViewBuilderComponent implements OnInit, OnChanges {
  @Input() availableProperties: Array<ViewPropertyDefinition> = [];
  @Input() defaultPropertyStyle: StyleDefinition;
  @Input() public selectedPropertiesList: Array<ViewPropertyDefinition> = []; // List of selected properties
  @Input() public viewDefinitionApplicationViewSlug: string;

  public selectedProperty: ViewPropertyDefinition;
  public filteredProperties: Array<ViewPropertyDefinition>; // Pick list for adding properties (filters applied)
  public propertySearchFormControl = new UntypedFormControl();

  @Output() addProp = new EventEmitter();
  @Output() propertiesChangedEvent = new EventEmitter();
  @ViewChild('formatBar') formatBar: PropertyViewBuilderFormatBarComponent;
  @ViewChild('propertyInput') propertyInputElement: ElementRef;
  constructor() {}

  ngOnInit(): void {
    this.selectedPropertiesList = [];
    this.propertySearchFormControl.valueChanges.forEach((value) => {
      if (typeof value === 'string') {
        this.filteredProperties = this.availableProperties.filter((p) =>
          p.propertyDefinition.label?.toLowerCase().includes(value.toLowerCase()),
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedPropertiesList) {
      if (this.viewDefinitionApplicationViewSlug === 'common:item_component') {
        // only include thumbnail for item component
        const thumbnailProp = changes.selectedPropertiesList.currentValue.find((prop) => prop.slug === 'thumbnail');
        if (!thumbnailProp) {
          // add thumbnail to the beginning of selectedPropertiesList array if doesn't exist in template
          this.selectedPropertiesList.unshift({
            enabled: true,
            slug: 'thumbnail',
          });
        }
      }
    }
    if (this.availableProperties) {
      console.log('applying default styles: ', this.defaultPropertyStyle);
      this.availableProperties = ObjectUtil.cloneDeep(this.availableProperties).map((p) => {
        p.style = p.style || ObjectUtil.cloneDeep(this.defaultPropertyStyle) || {};
        return p;
      });
      this.selectedPropertiesList.forEach((prop) => {
        if (!prop.propertyDefinition && !['thumbnail', 'annotation'].includes(prop.slug)) {
          prop.propertyDefinition = this.availableProperties.find(
            (availableProp) => availableProp.slug === prop.slug,
          )?.propertyDefinition;
        }
      });
      this.setFilteredProperties();
    }
  }

  showFormatBar(element, event) {
    this.selectedProperty = element;
    const renderedEvent = {
      element,
      renderedElementPosition: {
        x: event.x,
        y: event.y,
      },
    };
    this.formatBar.show(renderedEvent);
    event.stopPropagation();
  }

  hideFormatBar() {
    this.selectedProperty = null;
    if (this.formatBar) {
      this.formatBar.hide();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedPropertiesList, event.previousIndex, event.currentIndex);
    this.emitPropertiesListUpdated();
  }

  updateFormatting(values) {
    if (values) {
      if (values.style) {
        this.selectedProperty.style = values.style;
      } else {
        ObjectUtil.mergeDeep(this.selectedProperty, values);
      }
      this.emitPropertiesListUpdated();
    }
  }

  private emitPropertiesListUpdated() {
    this.propertiesChangedEvent.emit(this.selectedPropertiesList);
  }

  getActiveColor(element) {
    return element === this.selectedProperty ? 'accent' : '';
  }

  getComponentProperties() {}

  addToPropertiesList(property) {
    this.addProp.emit(true);
    property.enabled = true;
    this.selectedPropertiesList.push(property);
    this.emitPropertiesListUpdated();
    this.propertySearchFormControl.setValue('');
    this.propertyInputElement.nativeElement.blur();
    this.setFilteredProperties();
  }

  removeElement(property: ViewPropertyDefinition) {
    const index = this.selectedPropertiesList.findIndex((p) => p.slug === property.slug);
    property.enabled = false;
    this.selectedPropertiesList.splice(index, 1);
    this.setFilteredProperties();
    this.emitPropertiesListUpdated();
  }

  toggleLabel(property: ViewPropertyDefinition) {
    if (property.includeLabel) {
      delete property.includeLabel;
    } else {
      property.includeLabel = true;
    }
    this.emitPropertiesListUpdated();
  }

  private setFilteredProperties() {
    this.filteredProperties = [
      ...this.availableProperties.filter(
        (property) => !this.selectedPropertiesList.find((p) => p.slug === property.slug),
      ),
    ].sort((p1, p2) => (p1.propertyDefinition.label > p2.propertyDefinition.label ? 1 : -1));
  }

  public openPanel(e) {
    e.stopPropagation();
    this.propertyInputElement.nativeElement.focus();
  }

  getStyle(property: ViewPropertyDefinition) {
    let styles = '';
    if (property.slug === 'annotation') {
      return styles;
    }
    if (property.style?.font?.weight === 'bold') {
      styles += 'font-weight: bold; ';
    }
    if (property.style?.font?.size) {
      styles += `font-size: ${property.style.font.size}pt; line-height: ${property.style.font.size}pt;`;
    }
    if (property.style?.font?.family) {
      styles += `font-family: '${property.style.font.family}'; `;
    }
    if (property.style?.text?.decoration) {
      styles += `text-decoration: '${property.style.text.decoration}'; `;
    }
    if (property.style?.text?.align) {
      styles += `text-align: '${property.style.text.align}'; `;
    }
    return styles;
  }

  hideElement(element) {
    element.isHidden = !element.isHidden;
    this.emitPropertiesListUpdated();
  }
}
