<div *ngIf="show" class="create-items" [@slideInLeft]>
  <div class="create-item">
    <button
      [ngClass]="countInput.value === minValue ? 'change-count-button disabled' : 'change-count-button'"
      data-test="decrease-placeholder-button"
      mat-icon-button
      color="primary"
      disableRipple="true"
      [disabled]="countInput.value === minValue"
      (click)="handleDecreaseCount()"
    >
      <mat-icon>arrow_left</mat-icon>
    </button>
    <div [ngClass]="error === true ? 'input error' : 'input'">
      <input
        data-test="add-placeholder-input"
        data-test="create-items-input"
        type="number"
        matInput
        [formControl]="countInput"
      />
    </div>
    <button
      [ngClass]="countInput.value === maxValue ? 'change-count-button disabled' : 'change-count-button'"
      data-test="increase-placeholder-button"
      mat-icon-button
      color="primary"
      disableRipple="true"
      [disabled]="countInput.value === maxValue"
      (click)="handleIncreaseCount()"
    >
      <mat-icon class="!ml-[-12px]">arrow_right</mat-icon>
    </button>
  </div>
</div>
