import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CropDefinition } from '@contrail/documents';
import { CropperPosition, ImageCroppedEvent, LoadedImage } from './lib/interfaces';

@Component({
  selector: 'app-crop-image-element',
  templateUrl: './crop-image-element.component.html',
  styleUrls: ['./crop-image-element.component.scss'],
})
export class CropImageElementComponent implements OnInit, OnChanges {
  // SOURCE CODE: https://github.com/Mawi137/ngx-image-cropper
  // NPM: https://www.npmjs.com/package/ngx-image-cropper

  imageReady = false;
  @Output() cropDefinition$ = new EventEmitter<CropDefinition>();
  @Output() imageReady$ = new EventEmitter<any>();
  cropDefinition: CropDefinition;

  @Input() imageURL: any = '';
  @Input() imageBlob: Blob;
  @Input() initialCrop: CropperPosition;
  @Input() transform: string;
  cropper: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };

  imgHeight: number = null;
  imgWidth: number = null;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {}

  ngOnDestroy() {
    this.imageReady = false;
    this.imgHeight = null;
    this.imgWidth = null;
    this.imageURL = null;
    this.imageBlob = null;
  }

  // 1. Image Loading
  imageLoaded(image: LoadedImage) {
    this.imgHeight = image.original.size.height;
    this.imgWidth = image.original.size.width;
  }
  // 2. Initial CroppedEvent - 4. New Cropping
  imageCropped(event: ImageCroppedEvent) {
    if (this.imageReady && this.imgHeight && this.imgWidth) {
      if (this.imgWidth === event?.width && this.imgHeight === event?.height) {
        this.cropDefinition = { x1: 0, x2: 0, y1: 0, y2: 0, width: 0, height: 0 };
      } else {
        this.cropDefinition = {
          x1: event?.imagePosition.x1,
          x2: event?.imagePosition.x2,
          y1: event?.imagePosition.y1,
          y2: event?.imagePosition.y2,
          width: event?.width,
          height: event?.height,
        };
      }
      this.cropDefinition$.emit(this.cropDefinition);
    }
  }
  // 3. Image Ready
  cropperReady(evt) {
    this.imageReady = true;
    if (this.initialCrop) {
      const scaleW = this.imgWidth / evt.width;
      const scaleH = this.imgHeight / evt.height;
      this.cropper = {
        x1: this.initialCrop.x1 / scaleW,
        x2: this.initialCrop.x2 / scaleW,
        y1: this.initialCrop.y1 / scaleH,
        y2: this.initialCrop.y2 / scaleH,
      };
    } else {
      this.cropper = { x1: 0, x2: evt.width, y1: 0, y2: evt.height };
    }
    this.imageReady$.emit(true);
  }

  loadImageFailed() {
    console.error('Image Loading Failed');
  }
}
