import { CanvasDocument } from '../../canvas-document';
import { CanvasElement } from '../../elements/canvas-element';
import { DrawOptions } from '../canvas-renderer';

export class CanvasCopyElementsRenderer {
  public elements: Array<CanvasElement> = [];

  constructor(private canvasDocument: CanvasDocument) {}

  public draw(options?: DrawOptions) {
    const ctx = this.canvasDocument.canvasDisplay.context;
    for (let i = 0; i < this.elements?.length; i++) {
      const element = this.elements[i];
      element.drawElement(ctx, options);
    }
  }
}
