import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSessionsStoreModule } from '../common/user-sessions/user-sessions-store';
import { AuthStoreModule } from '../common/auth/auth-store';
import { CommentsStoreModule } from '../common/comments/comments-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { BoardsStoreModule } from '../boards/boards-store';
import { DocumentStoreModule } from '../boards/board/document/document-store';
import { UndoRedoStoreModule } from '../common/undo-redo/undo-redo-store';
import { AssortmentsStoreModule } from '../common/assortments/assortments-store';
import { WorkspacesStoreModule } from '@common/workspaces/workspaces-store';
import { DocumentManagerStoreModule } from '../boards/board/document-manager/document-manager-store';
import { DocumentHistoryStoreModule } from '@common/document-history/document-history-store/document-history-store.module';
import { EditorModeStoreModule } from '@common/editor-mode/editor-mode-store';
import { SearchReplaceStoreModule } from '@common/search-replace/search-replace-store';
import { AppExtensionsStoreModule } from '@common/app-extensions/app-extensions-store';
import { FeatureFlagsStoreModule } from '@common/feature-flags';
import { FrameTemplatesStoreModule } from '@common/frame-templates/frame-templates-store';
import { ClipboardStoreModule } from '@common/clipboard/clipboard-store';
import { TypePropertyUserListsStoreModule } from '@common/user-list/user-list-store/user-list-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    UserSessionsStoreModule,
    AuthStoreModule,
    CommentsStoreModule,
    LoadingIndicatorStoreModule,
    BoardsStoreModule,
    DocumentStoreModule,
    UndoRedoStoreModule,
    AssortmentsStoreModule,
    AppExtensionsStoreModule,
    FeatureFlagsStoreModule,
    WorkspacesStoreModule,
    DocumentManagerStoreModule,
    DocumentHistoryStoreModule,
    EditorModeStoreModule,
    SearchReplaceStoreModule,
    FrameTemplatesStoreModule,
    ClipboardStoreModule,
    TypePropertyUserListsStoreModule,
  ],
})
export class RootStoreModule {}
