import { CropDefinition, DocumentElement, PositionDefinition, ScaleTransformation } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../../canvas-document';
import { CanvasElement } from '../../../elements/canvas-element';
import { CanvasImageDrawableElement } from '../../../elements/image/canvas-image-drawable-element';
import { RotationHelper } from '../../../renderers/rotation-widget-renderer/rotation-helper';
import { DRAG_DIRECTIONS } from '../../../renderers/selection-widget-renderer/selection-widget-renderer';
import { CropElementResizeHandler } from './crop-element-resize-handler';

export class CropElementDragHandler {
  private startingPosition: PositionDefinition;
  private startingCropDefinition: CropDefinition;
  private selectedElement: CanvasImageDrawableElement;
  private startingElement: DocumentElement;
  private scale: number;

  constructor(private canvasDocument: CanvasDocument) {}

  public dragstarted(event, elementTarget: { element: CanvasElement; target: DRAG_DIRECTIONS }) {
    this.clear();
    if (!this.canvasDocument.interactionHandler.isSelect() || elementTarget?.element?.elementDefinition?.isLocked)
      return;

    const { element, target } = elementTarget;
    if (
      element &&
      [DRAG_DIRECTIONS.CROP_BODY].indexOf(target) !== -1 &&
      element.isCropEnabled &&
      this.canvasDocument.isCropping(element.id)
    ) {
      const imageElement = element as CanvasImageDrawableElement;
      if (!imageElement.imageSize) {
        console.error('No original image size', imageElement);
        this.clear();
        this.canvasDocument.cancelCrop();
      }
      const viewBox = this.canvasDocument.getViewBox();
      const scale = this.canvasDocument.getViewScale();
      this.startingPosition = { x: event.clientX + viewBox.x * scale.x, y: event.clientY + viewBox.y * scale.y };
      this.selectedElement = imageElement;
      this.startingElement = ObjectUtil.cloneDeep(element.elementDefinition);
      this.startingCropDefinition = this.selectedElement.getCropDefinition();

      const size = this.selectedElement.getSize();
      this.scale = Math.min(
        size.width / (this.startingCropDefinition?.width ?? this.selectedElement.imageSize.width),
        size.height / (this.startingCropDefinition?.height ?? this.selectedElement.imageSize.height),
      );
    }
  }

  public dragged(event) {
    if (!this.selectedElement) return;

    const scale = this.canvasDocument.getViewScale();
    const viewBox = this.canvasDocument.getViewBox();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX === 0 && distanceY === 0) {
      return;
    }

    this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y);
    this.canvasDocument.draw();
  }

  public dragended(event) {
    if (!this.selectedElement) return;

    const viewBox = this.canvasDocument.getViewBox();
    const scale = this.canvasDocument.getViewScale();
    let distanceX = event.clientX + viewBox.x * scale.x - this.startingPosition.x;
    let distanceY = event.clientY + viewBox.y * scale.y - this.startingPosition.y;
    if (distanceX !== 0 || distanceY !== 0) {
      this.updateSelectedElementCropPosition(distanceX / scale.x, distanceY / scale.y);
    }

    this.canvasDocument.draw();
    this.clear();
  }

  private clear() {
    this.startingElement = null;
    this.selectedElement = null;
    this.startingPosition = null;
    this.startingCropDefinition = null;
  }

  private updateSelectedElementCropPosition(dx, dy) {
    if (!this.selectedElement.elementDefinition.cropDefinition) {
      this.selectedElement.elementDefinition.cropDefinition = {};
    }

    dx = dx / this.scale;
    dy = dy / this.scale;

    // Get dx and dy in rotated coordinate system
    const angle = this.startingElement?.rotate?.angle;
    if (angle) {
      const rotatedChangePosition = RotationHelper.rotate(
        {
          x: dx,
          y: dy,
        },
        360 - angle,
      );
      dx = rotatedChangePosition.x;
      dy = rotatedChangePosition.y;
    }

    const imgWidth = this.selectedElement.imageSize.width;
    const imgHeight = this.selectedElement.imageSize.height;
    const x1 = this.startingCropDefinition?.x1 ?? 0;
    const y1 = this.startingCropDefinition?.y1 ?? 0;
    const newCropDefinition = this.selectedElement.getCropDefinition();
    const newCropWidth = newCropDefinition?.width ?? imgWidth;
    const newCropHeight = newCropDefinition?.height ?? imgHeight;
    const newCropX = Math.min(Math.max(0, x1 - dx), imgWidth - newCropWidth);
    const newCropY = Math.min(Math.max(0, y1 - dy), imgHeight - newCropHeight);
    this.selectedElement.elementDefinition.cropDefinition = CropElementResizeHandler.limitAndGetCrop(
      newCropX,
      newCropY,
      newCropWidth,
      newCropHeight,
      imgWidth,
      imgHeight,
    );
    this.selectedElement.uncroppedUnrotatedPosition = this.selectedElement.getUncroppedUnrotatedPosition();
  }
}
