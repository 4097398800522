import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { ActionRequest } from '@contrail/actions';
import { DocumentAction, DocumentElement, DocumentElementEvent, DocumentTextElementEvent } from '@contrail/documents';
import { Document } from '@contrail/documents';
import { Subscription } from 'rxjs';
import { ComponentEditorService } from '../component-editor.service';
import { SVGPreviewContainer } from '../svg/svg-preview-container';

interface ComponentEditorDocumentService {
  handleDocumentElementEvent(event: DocumentElementEvent): void;
  handleDocumentActions(actions: Array<DocumentAction>): void;
  handleDocumentTextElementEvent(event: DocumentTextElementEvent): void;
  handleActionRequest(request: ActionRequest): void;
  getTextFontProperties(): any;
  updateSizeAndPositionForPropertyElements: (documentElements: any[]) => any;
}

@Component({
  selector: 'app-component-editor-preview',
  templateUrl: './component-editor-preview.component.html',
  styleUrls: ['./component-editor-preview.component.scss'],
})
export class ComponentEditorPreviewComponent implements OnInit, OnDestroy {
  document: Document = { id: 'preview', elements: [], size: { width: 500, height: 500 } };
  svgPreviewContainer: SVGPreviewContainer;
  private loadedObjectSub: Subscription;
  constructor(
    private componentEditorService: ComponentEditorService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loadedObjectSub = this.componentEditorService.loadedComponentObjectObs.subscribe(async (documentElement) => {
      if (documentElement) {
        if (!this.svgPreviewContainer) {
          const documentService: ComponentEditorDocumentService = {
            handleDocumentElementEvent(event: DocumentElementEvent): void {},
            handleDocumentActions(actions: Array<DocumentAction>): void {},
            handleDocumentTextElementEvent(event: DocumentTextElementEvent): void {},
            handleActionRequest(request: ActionRequest): void {},
            getTextFontProperties(): any {
              return null;
            },
            updateSizeAndPositionForPropertyElements(documentElements: any[]): any {
              return null;
            },
          };
          this.svgPreviewContainer = new SVGPreviewContainer(
            '#previewCanvas',
            this.document,
            documentService,
            'preview',
            this.authService,
          );
        } else {
          this.svgPreviewContainer.loadDocument(this.document);
        }
        this.svgPreviewContainer.addPreview(JSON.parse(JSON.stringify(documentElement)));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.svgPreviewContainer) {
      this.componentEditorService.clearObjects();
      this.svgPreviewContainer.clear();
      this.svgPreviewContainer = null;
      this.loadedObjectSub.unsubscribe();
    }
  }
}
