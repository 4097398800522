import { DocumentElement } from '@contrail/documents';
import { CanvasDocument } from '../../canvas-document';
import { CanvasShapeElement } from './canvas-shape-element';

export class CanvasHeartElement extends CanvasShapeElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
  }

  public getPoints({ x, y, width, height }): number[][][] {
    return [
      [0.5 * width + x, 0.16 * height + y],
      [
        [0.448 * width + x, 0.066 * height + y], // start control
        [0.362 * width + x, 0.0 * height + y], // end control
        [0.259 * width + x, 0.0 * height + y], // draw curve to this point
      ],
      [
        [0.112 * width + x, 0.0 * height + y], // start control
        [0.0 * width + x, 0.123 * height + y], // end control
        [0.0 * width + x, 0.283 * height + y], // draw curve to this point
      ],
      [
        [0.0 * width + x, 0.594 * height + y],
        [0.155 * width + x, 0.642 * height + y],
        [0.5 * width + x, 1.0 * height + y],
      ],
      [
        [0.845 * width + x, 0.642 * height + y],
        [1.0 * width + x, 0.594 * height + y],
        [1.0 * width + x, 0.283 * height + y],
      ],
      [
        [1.0 * width + x, 0.123 * height + y],
        [0.888 * width + x, 0.0 * height + y],
        [0.741 * width + x, 0.0 * height + y],
      ],
      [
        [0.638 * width + x, 0.0 * height + y],
        [0.552 * width + x, 0.066 * height + y],
        [0.5 * width + x, 0.16 * height + y],
      ],
    ];
  }
}
