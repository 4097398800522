<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayPanelClass]="'color-widget'"
  [cdkConnectedOverlayPositions]="positions"
>
  <div (mouseenter)="handleMouseEnter()" (mouseleave)="handleMouseLeave()">
    <mat-tab-group #tabGroup cdkDrag cdkDragRootElement=".color-widget">
      <mat-tab label="Custom">
        <ng-template matTabContent>
          <span
            [(colorPicker)]="color"
            [style.background]="color"
            autocomplete="off"
            [cpSaveClickOutside]="true"
            [cpToggle]="true"
            [cpPresetColors]="presetColors"
            [cpDialogDisplay]="'inline'"
            [cpEyeDropper]="true"
            (colorPickerChange)="handleChange($event)"
          >
          </span>
        </ng-template>
      </mat-tab>
      <mat-tab label="Library">
        <app-chooser
          class="color-widget-chooser"
          [entityType]="'color'"
          [title]="''"
          [allowSourceChange]="false"
          [showAllControl]="false"
          [showCount]="false"
          [showHeader]="false"
          [showFilter]="false"
          [draggable]="false"
          [template]="colorDetail"
          [enableInfiniteScroll]="true"
        ></app-chooser>
        <ng-template #colorDetail let-entity="entity" let-handleClick="handleClick">
          <app-color-detail
            [folder]="entity.entityType === 'workspace'"
            [name]="entity.name"
            [backgroundColor]="'#' + entity.hex"
            (click)="handleClick(entity)"
            (onClick)="selectColorEntity($event)"
          ></app-color-detail>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <mat-icon (click)="hide()" class="absolute top-2 right-2 !cursor-pointer text-black54 z-10">close</mat-icon>
  </div>
</ng-template>
