import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { Store } from '@ngrx/store';
import { Observable, Subject, pairwise, takeUntil, BehaviorSubject, Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { BoardsSelectors } from '../../boards-store';
import { Board } from '../board.service';
import { DocumentActions, DocumentSelectors } from '../document/document-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { CollectionStatusMessageService } from './status-messages/collection-status-message.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { DocumentService } from '../document/document.service';
import { ItemChooserService } from '../item-chooser/item-chooser.service';
// import { ItemData } from '../../../item-data';

@Component({
  selector: 'app-side-menu',
  templateUrl: './board-side-menu.component.html',
  styleUrls: ['./board-side-menu.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
          'margin-right': 0,
          'z-index': 10,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          'margin-right': -1000,
        }),
      ),
      /*
      transition('open => closed', [
        animate('1s ease-out', style({ opacity: 0.5, 'margin-right': -1000 }))
      ]),
      transition('closed => open', [
        animate('0.6s ease-in-out', style({ 'margin-right': 0 , opacity: 1}))
      ]),*/
    ]),
  ],
})
export class BoardSideMenuComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  subscription = new Subscription();
  toggleOverlay$: Observable<any>;
  board$: Observable<Board>;
  editorMode$: Observable<EditorMode>;
  public editorMode: EditorMode;
  messages$: Observable<Array<CollectionStatusMessage>>;
  showSourceAssortmentWarning$: Observable<boolean>;
  itemSourceChooserLoaded = false;
  itemChooserLoaded = false;
  colorChooserLoaded = false;
  assetChooserLoaded = false;
  dashboardFeatureFlag = false;
  svgRecoloringFeatureFlag = false;
  selectedElement: any;
  existingItemIds: Set<string>;
  existingItemIds$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private store: Store<RootStoreState.State>,
    private collectionMessageStatusService: CollectionStatusMessageService,
    private documentService: DocumentService,
    private itemChooserService: ItemChooserService,
  ) {}

  ngOnInit(): void {
    this.documentService.documentElements.pipe(takeUntil(this.destroy$)).subscribe((elements) => {
      const modelBindings = elements.map((e) => e.modelBindings);
      this.existingItemIds = new Set();
      modelBindings.forEach((m) => {
        if (m?.item) {
          const id = m.item.split(':')[1];
          this.existingItemIds.add(id);
          //console.log('Current Board Existing Ids', this.existingItemIds);
        }
      });
      this.existingItemIds$.next(this.existingItemIds);
    });
    this.toggleOverlay$ = this.store.select(DocumentSelectors.toggleChooser);
    this.toggleOverlay$.pipe(pairwise(), takeUntil(this.destroy$)).subscribe(([prevChooser, currChooser]) => {
      if (currChooser?.slug === 'addItem' && !this.itemChooserLoaded && currChooser.targetSourceType) {
        this.itemChooserLoaded = true;
      }
      if (currChooser?.slug === 'addItem' && !this.itemSourceChooserLoaded && !currChooser.targetSourceType) {
        this.itemSourceChooserLoaded = true;
      }
      if (currChooser?.slug === 'addColor' && !this.colorChooserLoaded) {
        this.colorChooserLoaded = true;
      }
      if (currChooser?.slug === 'addAsset' && !this.assetChooserLoaded) {
        this.assetChooserLoaded = true;
      }

      if (prevChooser?.slug === 'contentEditor' && prevChooser?.slug != currChooser?.slug) {
        const selectedElements = this.documentService.getSelectedElements();

        if (selectedElements.length) {
          this.documentService.handleDocumentSvgElementEvent({
            element: selectedElements[0],
            eventType: 'svgEditorExit',
            closeContentOptions: false,
          });
        }
      }

      if (currChooser?.element) {
        this.selectedElement = currChooser.element;
      }
    });
    this.board$ = this.store.select(BoardsSelectors.currentBoard);
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.subscription.add(this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m)));
    this.messages$ = this.collectionMessageStatusService.messages$;
    this.showSourceAssortmentWarning$ = this.collectionMessageStatusService.showSourceAssortmentWarning$;
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.dashboardFeatureFlag = !!flags.find((x) => x.featureName === Feature.DASHBOARD);
      this.svgRecoloringFeatureFlag = !!flags.find((x) => x.featureName === Feature.SVG_RECOLORING);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleClose() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = '';
    overlay.showChooser = false;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  handleMessageClick(event) {
    this.collectionMessageStatusService.setStatusMessageElement(event);
  }

  handleToggleShowWarning(event) {
    this.collectionMessageStatusService.toggleShowWarning(event);
  }

  handleAddEntities(event) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    this.itemChooserService.addItemsToDocument(event);
  }
}
