import { createSelector } from '@ngrx/store';
import { documentElementEntityAdapter } from './document-elements.state';
import { RootStoreState } from 'src/app/root-store';

export const { selectAll, selectEntities, selectIds, selectTotal } = documentElementEntityAdapter.getSelectors();

const documentManagerEntities = (state: RootStoreState.State) => state.documentManager.documentElements.entities;

export const selectDocumentElements = createSelector(documentManagerEntities, (state) => Object.values(state));
export const selectDocumentElement = (id: string) =>
  createSelector(documentManagerEntities, (map) => {
    return map[id];
  });
