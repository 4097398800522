<div class="status" (click)="showAlertMessage()">
  <button class="menu-button" mat-button>
    <mat-icon
      *ngIf="this.messages?.length"
      matBadgeSize="small"
      [matBadge]="this.messages?.length"
      svgIcon="notification_icon"
    ></mat-icon>
    <mat-icon *ngIf="!this.messages?.length" svgIcon="notification_icon"></mat-icon>
  </button>
</div>
