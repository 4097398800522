import { IReplaceColor } from './document-content-svg-editor-component/document-content-svg-editor.component';

export enum DocumentContentChangeType {
  REPLACE_COLOR_SVG = 'REPLACE_COLOR_SVG',
}

export interface DocumentContentChange {
  changeType: DocumentContentChangeType;
  elementId?: string;
  fills?: IReplaceColor[];
  currentColor?: IReplaceColor;
  svgElementSelectionIds?: string[];
}
