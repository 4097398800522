<div
  class="row"
  [ngClass]="{ 'normal-height': !['is_in_list'].includes(filterPropertyCriteria?.filterConditionType) }"
  *ngIf="filterDefinition"
>
  <!-- <div class="clause">Where</div> -->
  <div>
    <mat-icon
      [attr.data-test]="'filter-property-remove-' + filterPropertyCriteria?.filterPropertyDefinition?.slug"
      class="remove-icon"
      (click)="removeCriteria()"
      >close</mat-icon
    >
  </div>
  <div class="property">
    <mat-form-field floatLabel="never">
      <input
        data-test="filter-property-search-input"
        #autoCompleteField
        type="text"
        placeholder="Select property"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="auto"
      />
      <button
        data-test="clear-filter-property-search-input-button"
        *ngIf="formControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearAutoCompleteValue()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="setFilterPropertyDefinition($event)"
      >
        <mat-option
          *ngFor="let property of filteredProperties | async"
          [value]="property"
          [attr.data-test]="'filter-property-option-' + property?.slug"
        >
          {{ property.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!--
    <mat-form-field>
      <mat-select [attr.data-test]="'filter-property-select-' + filterPropertyCriteria?.filterPropertyDefinition.slug" id="property-select" (selectionChange)="setFilterPropertyDefinition($event)" [value]="filterPropertyCriteria?.filterPropertyDefinition.slug">
        <mat-option [attr.data-test]="'filter-property-select-option-' + property.slug" *ngFor="let property of filterDefinition?.filterPropertyDefinitions" [value]="property.slug">
          {{ property.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  --></div>
  <div class="condition" *ngIf="filterPropertyCriteria?.filterPropertyDefinition?.slug">
    <mat-form-field>
      <mat-select
        [attr.data-test]="'filter-condition-select-' + filterPropertyCriteria?.filterPropertyDefinition?.slug"
        id="condition-select"
        (selectionChange)="setCondition($event)"
        [value]="filterPropertyCriteria?.filterConditionType"
      >
        <mat-option
          *ngFor="let condition of filterConditionTypes"
          [value]="condition"
          data-test="filter-condition-select"
        >
          {{ getFilterConditionTypeMeta(condition)?.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div
    *ngIf="
      filterPropertyCriteria?.filterPropertyDefinition?.slug &&
      filterPropertyCriteria?.filterConditionType !== 'is_empty'
    "
    [attr.data-test]="'filter-value-select-' + filterPropertyCriteria?.filterPropertyDefinition?.slug"
    class="value"
    [ngSwitch]="true"
  >
    <ng-container
      *ngSwitchCase="
        ['choice', 'multi_select'].includes(filterPropertyCriteria?.filterPropertyDefinition?.propertyType)
      "
    >
      <app-type-property-form-field-multi-select
        [showSearchBar]="true"
        [showSelectAll]="true"
        *ngIf="
          ['is_any_of', 'is_none_of'].includes(filterPropertyCriteria?.filterConditionType) ||
          (filterPropertyCriteria?.filterConditionType === 'equals' &&
            filterPropertyCriteria?.filterPropertyDefinition?.propertyType === 'multi_select')
        "
        [propertyFormConfiguration]="{ typeProperty: filterPropertyCriteria?.filterPropertyDefinition, isFilter: true }"
        appearance=""
        (valueChange)="handleValueChange($event)"
        [value]="filterPropertyCriteria?.criteriaValue"
      >
      </app-type-property-form-field-multi-select>

      <app-type-property-form-field-select
        *ngIf="
          ['not_equal_to'].includes(filterPropertyCriteria?.filterConditionType) ||
          (filterPropertyCriteria?.filterConditionType === 'equals' &&
            filterPropertyCriteria?.filterPropertyDefinition?.propertyType === 'choice')
        "
        [propertyFormConfiguration]="{ typeProperty: filterPropertyCriteria?.filterPropertyDefinition, isFilter: true }"
        appearance=""
        (valueChange)="handleValueChange($event)"
        [value]="filterPropertyCriteria?.criteriaValue"
      >
      </app-type-property-form-field-select>
    </ng-container>

    <!-- Special handling for string -->
    <ng-container
      *ngSwitchCase="
        ('string' === filterPropertyCriteria?.filterPropertyDefinition?.propertyType ||
          ['object_reference', 'size_range', 'userList'].includes(
            filterPropertyCriteria?.filterPropertyDefinition?.propertyType
          )) &&
        ['is_any_of', 'is_none_of'].includes(filterPropertyCriteria?.filterConditionType)
      "
    >
      <app-type-property-form-field-multi-select
        [showSearchBar]="true"
        [showSelectAll]="true"
        [propertyFormConfiguration]="stringPropertyFormConfiguration"
        appearance=""
        (valueChange)="handleValueChange($event)"
        [value]="filterPropertyCriteria?.criteriaValue"
      >
      </app-type-property-form-field-multi-select>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        'string' === filterPropertyCriteria?.filterPropertyDefinition?.propertyType &&
        ['is_in_list'].includes(filterPropertyCriteria?.filterConditionType)
      "
    >
      <app-type-property-form-field-text-area
        [propertyFormConfiguration]="{
          typeProperty: { slug: filterPropertyCriteria?.filterPropertyDefinition.slug },
          isFilter: true
        }"
        appearance=""
        (valueChange)="handleValueChange($event)"
        [value]="filterPropertyCriteria?.criteriaValue"
      >
      </app-type-property-form-field-text-area>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        ['object_reference', 'size_range', 'userList'].includes(
          filterPropertyCriteria?.filterPropertyDefinition?.propertyType
        ) && !['is_any_of', 'is_none_of'].includes(filterPropertyCriteria?.filterConditionType)
      "
    >
      <app-type-property-form-field-text
        [propertyFormConfiguration]="{
          typeProperty: { slug: filterPropertyCriteria?.filterPropertyDefinition.slug },
          isFilter: true
        }"
        appearance=""
        (valueChange)="handleValueChange($event)"
        [value]="filterPropertyCriteria?.criteriaValue"
      >
      </app-type-property-form-field-text>
    </ng-container>

    <app-type-property-form-field
      *ngSwitchDefault
      appearance=""
      [propertyFormConfiguration]="{ typeProperty: filterPropertyDefinition, isFilter: true }"
      [value]="filterPropertyCriteria?.criteriaValue"
      (valueChange)="handleValueChange($event)"
    >
    </app-type-property-form-field>
  </div>
</div>
