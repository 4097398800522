import { Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DocumentActions } from '../document-store';
import { SideMenuOverlay } from '../document-store/document.state';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-document-tools',
  templateUrl: './document-tools.component.html',
  styleUrls: ['./document-tools.component.scss'],
})
export class DocumentToolsComponent implements AfterViewInit {
  @Output() selectionCompleted = new EventEmitter();
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  appList$: Observable<any>;
  data = [
    [
      {
        icon: 'app-tools-items',
        label: 'Items',
        link: '',
      },
      {
        icon: 'app-tools-material',
        label: 'Material Library',
        link: '',
      },
      {
        icon: 'app-tools-color',
        label: 'Color Library',
        link: '',
      },
      {
        icon: 'app-tools-clo-set',
        label: 'Clo Set',
        link: '',
      },
      {
        icon: '',
        label: 'VNTANA',
        link: '',
      },
      {
        icon: 'app-tools-googleDrive',
        label: 'Google Drive',
        link: '',
      },
      {
        icon: 'app-tools-dropbox',
        label: 'Dropbox',
        link: '',
      },
    ],
  ];

  subscribe() {
    this.appList$ = combineLatest([this.searchBar.valueChange.pipe(startWith('')), from(this.data)]).pipe(
      map(([searchTerm, properties]) => {
        const keys = 'label';
        return properties.filter((item) =>
          keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
        );
      }),
    );
  }

  handleAppToolSelection(app) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addItem';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
    this.selectionCompleted.emit();
  }
  constructor(private store: Store<RootStoreState.State>) {}
  ngAfterViewInit(): void {
    this.subscribe();
  }
}
