import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ItemHistoryEvent } from '../item-history-event';

@Component({
  selector: 'app-source-change-history-list',
  templateUrl: './source-change-history-list.component.html',
  styleUrls: ['./source-change-history-list.component.scss'],
})
export class SourceChangeHistoryListComponent implements OnChanges {
  @Input() resultsHeight = '100%';
  @Input() history: Array<ItemHistoryEvent>;
  constructor() {}

  ngOnChanges(): void {
    console.log('history : ', this.history);
  }
}
