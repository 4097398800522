import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { WorkspacePrincipal } from '@common/share-app/interfaces';
import { ColorUtil } from '@util/color-util';

@Component({
  selector: 'app-workspace-principals',
  templateUrl: './workspace-principals.component.html',
  styleUrls: ['./workspace-principals.component.scss'],
})
export class WorkspacePrincipalsComponent implements OnInit {
  public workspacePrincipals: WorkspacePrincipal[];
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns: string[] = ['name', 'type', 'email', 'role'];

  constructor(
    public dialogRef: MatDialogRef<WorkspacePrincipalsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workspacePrincipals: any[];
    },
  ) {
    this.workspacePrincipals = data.workspacePrincipals;
  }

  ngOnInit(): void {
    const wps = [];
    this.workspacePrincipals.forEach((inputPrincipal) => {
      const principal: any = {};
      principal.role = inputPrincipal?.role;
      if (inputPrincipal?.principal?.email) {
        principal.id = inputPrincipal?.id;
        principal.type = 'User';
        principal.user = {
          firstName: inputPrincipal?.principal?.firstName,
          lastName: inputPrincipal?.principal?.lastName,
          email: inputPrincipal?.principal?.email,
        };
      } else {
        principal.id = inputPrincipal?.id;
        principal.type = 'Group';
        principal.user = {
          email: inputPrincipal?.principal?.name,
        };
      }
      if (principal?.backgroundColor) principal.backgroundColor = this.getBackgroundColor(principal.id);
      wps.push(principal);
    });
    this.dataSource.data = [...wps];
  }

  getBackgroundColor(id) {
    if (id) {
      return ColorUtil.stringToHslaColor(id);
    }
  }
}
