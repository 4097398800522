import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: any = '';
  @Input() options: any[];
  @Input() label: string;
  @Input() displayProperty = 'name';
  @Input() isRequired = false;
  @Input() isDisabled = false;
  @Input() allowAdhocOptions = false;
  @Input() allowEnterKeyToSelectOption = false;
  @Output() valueSelected = new EventEmitter();

  public listFormControl: UntypedFormControl;
  public filteredOptions$: Observable<any[]>;
  private destroy$ = new Subject();

  ngOnInit(): void {
    this.listFormControl = new UntypedFormControl(
      { value: this.value, disabled: this.isDisabled },
      this.isRequired ? Validators.required : null,
    );
    this.filteredOptions$ = this.listFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const queryValue = typeof value === 'string' ? value : ObjectUtil.getByPath(value, this.displayProperty);
        return this.filter(queryValue || '');
      }),
    );

    this.listFormControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchPhrase) => {
        if (searchPhrase === '') {
          this.valueSelected.emit(null);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue === false) {
        this.listFormControl?.enable();
      } else {
        this.listFormControl?.disable();
      }
    }
    if (changes.value) {
      this.listFormControl?.setValue(changes.value.currentValue);
    }
    if (changes.options) {
      const selectedValue = this.value ? ObjectUtil.cloneDeep(this.value) : null;
      this.listFormControl?.reset(selectedValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleOptionSelected(event) {
    this.valueSelected.emit(event.option);
    if (this.allowEnterKeyToSelectOption) {
      this.listFormControl.setValue('');
    }
  }

  displayFn(object: any) {
    if (this.displayProperty) {
      return ObjectUtil.getByPath(object, this.displayProperty);
    }
    return object;
  }

  getOptionDisplay(option) {
    return ObjectUtil.getByPath(option, this.displayProperty);
  }

  clearValue() {
    this.listFormControl.setValue('');
    this.valueSelected.emit(null);
  }

  private filter(value: string) {
    const filterValue = value?.toLowerCase();
    const filteredOptions = this.options?.filter((option) =>
      ObjectUtil.getByPath(option, this.displayProperty).toLowerCase().includes(filterValue),
    );

    if (this.allowAdhocOptions && filteredOptions.length === 0 && value !== '') {
      filteredOptions.push({ [this.displayProperty]: value });
    }

    return filteredOptions;
  }

  onEnter() {
    if (this.allowEnterKeyToSelectOption) {
      const value = this.listFormControl.value;
      if (value) {
        this.valueSelected.emit(value);
        this.listFormControl.setValue('');
      }
    }
  }
}
