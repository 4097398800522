import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { DocumentPropertyDefinition } from '@contrail/document-generation';
import { ViewPropertyDefinition } from '../../../document/component-editor/property-view-definition-builder/property-view-definition-builder.component';

@Component({
  selector: 'app-property-view-builder-preview',
  templateUrl: './property-view-builder-preview.component.html',
  styleUrls: ['./property-view-builder-preview.component.scss'],
})
export class PropertyViewBuilderPreviewComponent {
  @Input() properties: Array<DocumentPropertyDefinition> = [];
  @Input() backgroundColor: string;

  getStyle(property: ViewPropertyDefinition) {
    let styles = '';
    if (property.slug === 'annotation') {
      return styles;
    }
    if (property.style?.font?.weight === 'bold') {
      styles += 'font-weight: bold; ';
    }
    if (property.style?.font?.size) {
      styles += `font-size: ${property.style.font.size}pt; line-height: ${property.style.font.size}pt; `;
    }
    if (property.style?.font?.family) {
      styles += `font-family: '${property.style.font.family}'; `;
    }
    if (property.style?.font?.style) {
      styles += `font-style: ${property.style.font.style}; `;
    }
    if (property.style?.color) {
      styles += `color: ${property.style.color}; `;
    }
    if (property.style?.text?.decoration) {
      styles += `text-decoration: '${property.style.text.decoration}'; `;
    }
    if (property.style?.text?.align) {
      styles += `text-align: ${property.style.text.align}; `;
    }
    return styles;
  }
}
