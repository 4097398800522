import { CanvasDocument } from '../canvas-document';

export class AnnotationHandler {
  private readonly WARNING_IMAGE = '/assets/images/warning_alert.svg';
  public annotationOptionMap = {};
  public annotationImages = {};
  constructor(
    private canvasDocument: CanvasDocument,
    private annotationOptions: any[],
  ) {
    this.initializeAnnotationOptions();
  }

  public setAnnotations(annotatedElements: any[], shouldRedraw = true) {
    let draw = false;
    for (const el of annotatedElements) {
      const element = this.canvasDocument.state.getElementById(el.id);
      if (element) {
        draw = element.setAnnotations(el.annotations) || draw;
      }
    }
    if (shouldRedraw && draw) {
      this.canvasDocument.draw();
    }
  }

  private initializeAnnotationOptions() {
    this.annotationOptionMap = this.annotationOptions?.reduce((map, option) => {
      map[option.type] = option;
      return map;
    }, {});
    Object.keys(this.annotationOptionMap).forEach((annotationType) => {
      const img = document.createElement('img');
      img.onload = () => {
        this.annotationImages[annotationType] = img;
      };
      img.src = this.annotationOptionMap[annotationType]?.image;
    });

    const warningImg = document.createElement('img');
    warningImg.onload = () => {
      // tslint:disable-next-line: no-string-literal
      this.annotationImages['WARNING'] = warningImg;
    };
    warningImg.src = this.WARNING_IMAGE;
  }

  /**
   * All detached HTML elements need to be cleaned up manually
   * so they are not kept in memory
   */
  public clear() {
    if (this.annotationImages) {
      for (const key in this.annotationImages) {
        this.annotationImages[key] = null;
      }
    }
  }
}
