import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentsActions, CommentsSelectors } from '@common/comments/comments-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-pinned-comments-toggle',
  templateUrl: './pinned-comments-toggle.component.html',
  styleUrls: ['./pinned-comments-toggle.component.scss'],
})
export class PinnedCommentsToggleComponent implements OnInit {
  @Input() showToggle = true;
  public showPinnedComments: boolean;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
      this.showPinnedComments = showPinnedComments;
    });
  }

  toggleShowComments(event) {
    this.showPinnedComments = event.checked;
    this.store.dispatch(CommentsActions.setShowPinnedComments({ showPinnedComments: this.showPinnedComments }));
  }
}
