import { Injectable } from '@angular/core';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
import { Entities } from '@contrail/sdk';
import { ClipboardService } from './clipboard.service';
import { ClipboardItem } from './clipboard.interfaces';
const limit = pLimit(3);

@Injectable({
  providedIn: 'root',
})
export class ClipboardItemsService {
  constructor(private clipboardService: ClipboardService) {}

  public async getClipboardItems(): Promise<ClipboardItem[]> {
    const clipboard = await this.clipboardService.getClipboard({ relations: ['clipboardItems'] });

    if (clipboard?.clipboardItemsDownloadURL) {
      const response = await fetch(clipboard.clipboardItemsDownloadURL);
      const clipboardItems = await response.json();
      return clipboardItems;
    }

    return clipboard?.clipboardItems || [];
  }

  public async addItemsToClipboard(clipboardItems: ClipboardItem[]): Promise<ClipboardItem[]> {
    const batchesOfClipboardItems = chunk(clipboardItems, 50);
    const promises: Promise<any>[] = [];

    for (const clipboardItemsToCreate of batchesOfClipboardItems) {
      const promise = limit(async () => {
        return await new Entities().batchCreate({ entityName: 'clipboard-item', objects: clipboardItemsToCreate });
      });
      promises.push(promise);
    }

    const createdClipboardItems = await Promise.all(promises);
    return createdClipboardItems.flat();
  }

  public async removeItemsFromClipboard(ids: string[]): Promise<ClipboardItem[]> {
    const batchesOfIds = chunk(ids, 100);
    const promises: Promise<any>[] = [];

    for (const idsToDelete of batchesOfIds) {
      const promise = limit(async () => {
        return await new Entities().batchDelete({ entityName: 'clipboard-item', ids: idsToDelete });
      });
      promises.push(promise);
    }

    const deletedClipboardItems = await Promise.all(promises);
    return deletedClipboardItems.flat();
  }
}
