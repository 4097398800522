<div class="dev_menu flex" *ngIf="activateDevTool$ | async">
  <button mat-flat-button (click)="openPanel()"><mat-icon>construction</mat-icon></button>
  <div class="ml-5 flex flex-col">
    <div>Rendering</div>
    <div class="flex">
      <div class="mx-2">{{ (latestRenderingMetrics$ | async)?.time }}</div>
      <div class="mx-2">{{ renderCount$ | async }}</div>
    </div>
  </div>
  <div class="ml-5 flex flex-col">
    <div>Elements</div>
    <div>{{ (latestRenderingMetrics$ | async)?.visibleElementCount }}</div>
  </div>
</div>
