import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ListColumnDefinition } from 'src/app/common/components/list/list-column-definition';
import { RootStoreState } from 'src/app/root-store';
import { RoutingService } from 'src/app/routing/routing.service';
import { BoardsActions, BoardsSelectors } from '../boards-store';
import { Board } from '../boards-store/boards.state';

@Component({
  selector: 'app-boards-list',
  templateUrl: './boards-list.component.html',
  styleUrls: ['./boards-list.component.scss'],
})
export class BoardsListComponent implements OnInit {
  @Input() boards: Board[];

  public columnDefinitions: Array<ListColumnDefinition>;

  constructor(
    private routingService: RoutingService,
    private store: Store<RootStoreState.State>,
  ) {
    this.columnDefinitions = [
      { index: 'name', label: 'Name', alignment: 'left', propertyType: 'text' },
      // {
      //   index: 'createdOn',
      //   label: 'Created',
      //   alignment: 'right',
      //   propertyType: 'date',
      // },
      // {
      //   index: 'updatedOn',
      //   label: 'Updated',
      //   alignment: 'right',
      //   propertyType: 'date',
      // }
    ];
  }

  async ngOnInit() {}
  goToBoard(board) {
    this.routingService.go(`board/${board.id}`);
  }
}
