import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-configurator-text-alignment',
  templateUrl: './property-configurator-text-alignment.component.html',
  styleUrls: ['./property-configurator-text-alignment.component.scss'],
})
export class PropertyConfiguratorTextAlignmentComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() layout = 'menu';
  public textAlign = 'left';
  public multiSelected = false;

  constructor() {}

  ngOnChanges(): void {
    this.textAlign = this.currentValue || 'left';
  }

  setValue(value) {
    this.textAlign = value;
    const changes = { type: 'textAlign', value: this.textAlign };

    this.valueChange.emit(changes);
  }
}
