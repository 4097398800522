import { Injectable } from '@angular/core';
import { EditorModeActions } from '@common/editor-mode/editor-mode-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions } from '.';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { Comment, CommentsService, COMMENTS_ACTION_TYPE } from '../comments.service';

@Injectable()
export class CommentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private commentsService: CommentsService,
    private webSocketService: WebSocketService,
  ) {}

  editorModeChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorModeActions.EditorModeActionTypes.SET_EDITOR_MODE),
        tap((action: any) => {
          this.store.dispatch(CommentsActions.setCommentsAccessLevel({ commentsAccessLevel: action.editorMode }));
        }),
      ),
    { dispatch: false },
  );

  loadComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.LOAD_COMMENTS),
      switchMap((action: any) => {
        return from(this.commentsService.loadComments(action.contextReference)).pipe(
          map((data) => CommentsActions.loadCommentsSuccess({ data })),
          catchError((error) => observableOf(CommentsActions.loadCommentsFailure({ error }))),
        );
      }),
    ),
  );

  createComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.CREATE_COMMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.commentsService.addComment(action.text, action.ownerInfo, action.changeSuggestion)).pipe(
          map((comment) => {
            // DONT LOVE WHERE THIS IS...
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'ADD_COMMENT',
                changes: comment,
              },
            });
            return CommentsActions.createCommentSuccess({ comment });
          }),
          catchError((error) => observableOf(CommentsActions.createCommentFailure({ error }))),
        );
      }),
    ),
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.UPDATE_COMMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.commentsService.updateComment(action.comment)).pipe(
          map((comment) => {
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'UPDATE_COMMENT',
                changes: comment,
              },
            });

            return CommentsActions.updateCommentSuccess({ comment });
          }),
          catchError((error) => observableOf(CommentsActions.updateCommentFailure({ error }))),
        );
      }),
    ),
  );
  updateComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.UPDATE_COMMENTS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.commentsService.updateComments(action.comments)).pipe(
          map((comments: Array<Comment>) => {
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'UPDATE_COMMENTS',
                changes: comments,
              },
            });

            return CommentsActions.updateCommentsSuccess({ comments });
          }),
          catchError((error) => {
            return observableOf(CommentsActions.updateCommentsFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.DELETE_COMMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.commentsService.deleteComment(action.comment)).pipe(
          map((comment) => {
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'DELETE_COMMENT',
                changes: comment,
              },
            });

            return CommentsActions.deleteCommentSuccess({ comment });
          }),
          catchError((error) => observableOf(CommentsActions.deleteCommentFailure({ error }))),
        );
      }),
    ),
  );
  acceptComment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommentsActions.CommentsActionTypes.ACCEPT_COMMENT),
        tap((action: any) => {
          const changes: Comment = {
            id: action.comment.id,
            status: 'closed',
            text: action.comment.text,
            createdBy: action.comment.createdBy,
          };
          if (action.comment.changeSuggestion) {
            changes.changeSuggestion = {
              ...action.comment.changeSuggestion,
              changeStatus: 'approved',
            };
          }
          this.store.dispatch(CommentsActions.updateComment({ comment: changes }));
        }),
      ),
    { dispatch: false },
  );
  rejectComment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommentsActions.CommentsActionTypes.REJECT_COMMENT),
        tap((action: any) => {
          const changes: Comment = {
            id: action.comment.id,
            status: 'closed',
            text: action.comment.text,
            createdBy: action.comment.createdBy,
          };
          if (action.comment.changeSuggestion) {
            changes.changeSuggestion = {
              ...action.comment.changeSuggestion,
              changeStatus: 'rejected',
            };
          }
          this.store.dispatch(CommentsActions.updateComment({ comment: changes }));
        }),
      ),
    { dispatch: false },
  );
  reopenComment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommentsActions.CommentsActionTypes.REOPEN_COMMENT),
        tap((action: any) => {
          const changes: Comment = {
            id: action.comment.id,
            status: 'open',
            text: action.comment.text,
            createdBy: action.comment.createdBy,
          };
          if (action.comment.changeSuggestion) {
            changes.changeSuggestion = {
              ...action.comment.changeSuggestion,
              changeStatus: 'pending',
            };
          }
          this.store.dispatch(CommentsActions.updateComment({ comment: changes }));
        }),
      ),
    { dispatch: false },
  );
}
