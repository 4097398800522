<app-mat-spinner [overlay]="true" [loading]="uploadInProgress" [message]="message"></app-mat-spinner>
<div>
  <div class="header">
    <span>Insert Image</span>
    <div class="close-modal">
      <mat-icon (click)="handleClose()" title="Close">close</mat-icon>
    </div>
  </div>

  <div class="upload-container">
    <div *ngIf="uploadError" class="error">Uploaded file is not supported. Please upload image file only!</div>

    <div class="drag-space" *ngIf="!validFileSelected">
      <ngx-dropzone
        class="drop-zone"
        (change)="onSelect($event)"
        [multiple]="true"
        accept="image/jpeg,image/jpg,image/png,image/gif,image/svg+xml"
      >
        <ngx-dropzone-label>
          <div>
            <div>
              <mat-icon class="upload-icon"> file_upload </mat-icon>
            </div>
            <div class="title">Select file to upload</div>
            <div class="subtitle">or drag and drop, copy and paste</div>
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone>
    </div>
  </div>
</div>
