<div class="h-full overflow-hidden flex flex-col">
  <div class="mx-3 mt-2">
    <app-search-bar data-test="component-editor-search-bar" [placeholder]="'Search'" class="text-xs"></app-search-bar>
  </div>

  <ng-container *ngIf="componentType === 'ITEM'">
    <div class="flex items-center justify-between my-2 mx-3 min-h-[44px]">
      <div *ngIf="entities" class="context-type-toggle">
        <mat-button-toggle-group color="primary" [value]="contextTypes" (change)="toggleContextType($event)" multiple>
          <mat-button-toggle
            value="family"
            data-test="component-editor-toggle-family-context"
            [matTooltip]="selectedItemFamily?.name"
          >
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="tag"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle
            *ngIf="selectedItemOption"
            value="option"
            data-test="component-editor-toggle-option-context"
            [matTooltip]="selectedItemOption.optionName"
          >
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="hierarchy"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle
            value="projectItem"
            data-test="component-editor-toggle-project-item-context"
            [matTooltip]="selectedProjectName"
          >
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="app-project"></mat-icon>
          </mat-button-toggle>
          <!--
          <mat-button-toggle *ngIf="selectedAssortmentItem" value="assortmentItem" data-test='component-editor-toggle-assortment-item-context' [matTooltip]="selectedAssortmentName">
            <mat-icon class="!text-[18px] !h-[18px]" svgIcon="app-assortment"></mat-icon>
          </mat-button-toggle>
          -->
        </mat-button-toggle-group>
      </div>
      <mat-slide-toggle
        class="text-xs"
        color="primary"
        labelPosition="before"
        data-test="entity-editor-show-all-properties-toggle"
        [(ngModel)]="showAllProperties"
      >
        <div>Show all</div>
      </mat-slide-toggle>
    </div>
    <mat-divider></mat-divider>
    <div class="overflow-y-auto h-[calc(100%-153px)]">
      <app-component-entity-property-editor
        data-test="component-editor-property-editor-family"
        [style.display]="contextTypes.includes('family') ? 'block' : 'none'"
        (updated)="handleEntityItemUpdate($event)"
        [entity]="selectedItemFamily"
        [selectedPropertySlugs]="!showAllProperties ? selectedPropertySlugs : []"
        [searchTerm]="searchTerm"
        [ignoreAllLevelProps]="contextTypes.includes('option') && selectedItemOption"
        [entities]="allItemFamilies"
      />

      <app-component-entity-property-editor
        data-test="component-editor-property-editor-option"
        class="mt-[-15px]"
        [style.display]="contextTypes.includes('option') ? 'block' : 'none'"
        (updated)="handleEntityItemUpdate($event)"
        [entity]="selectedItemOption"
        [selectedPropertySlugs]="!showAllProperties ? selectedPropertySlugs : []"
        [searchTerm]="searchTerm"
        [entities]="allItemOptions"
      />

      <app-component-entity-property-editor
        class="mt-[-15px]"
        data-test="component-editor-property-editor-project-item"
        [style.display]="contextTypes.includes('projectItem') ? 'block' : 'none'"
        (updated)="handleEntityItemUpdate($event)"
        [entity]="selectedProjectItem"
        [selectedPropertySlugs]="!showAllProperties ? selectedPropertySlugs : []"
        [searchTerm]="searchTerm"
        [entities]="allProjectItems"
      />

      <!--
      <app-component-entity-property-editor
        class="mt-[-15px]"
        data-test="component-editor-property-editor-assortment-item"
        [style.display]="contextTypes.includes('assortmentItem') ? 'block' : 'none'"
        (updated)="handleEntityItemUpdate($event)"
        [entity]="selectedAssortmentItem"
        [selectedPropertySlugs]="(!showAllProperties) ? selectedPropertySlugs : []"
        [searchTerm]="searchTerm"
        [accessLevel]="'VIEW'"
      />
      -->
    </div>
  </ng-container>

  <ng-container *ngIf="componentType === 'COLOR'">
    <div class="overflow-y-auto h-[calc(100%-93px)]">
      <app-entity-properties-form
        #colorPropertyForm
        [accessLevel]="accessLevel"
        [entity]="entities[0]"
        [type]="colorType"
      />
    </div>
  </ng-container>
</div>
