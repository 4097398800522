import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface AssortmentItem {
  id: string;
  itemId: string;
  assortmentId: string;
  createdOn?: Date;
  updatedOn?: Date;
  [key: string]: any;
}

export interface BackingAssortmentItemsState extends EntityState<AssortmentItem> {}
export const backingAssortmentItemEntityAdapter: EntityAdapter<AssortmentItem> = createEntityAdapter<AssortmentItem>(
  {},
);
