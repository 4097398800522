<div data-test="entity-details-header" class="flex flex-col w-full h-full px-3">
  <div class="flex-between-center h-[50px]">
    <div class="header-left">
      <div *ngIf="hasStatus" class="status-circle ml-1" [class]="statusClass"></div>
      <h6 class="text-black mx-2">
        <span class="text-primary mr-2">{{ entity?.entityType | uppercase }}</span>
        {{ entity?.name | shorten: 40 }}
      </h6>
    </div>
    <div class="header-right">
      <mat-icon class="cursor-pointer mr-3" (click)="cancel()">close</mat-icon>
    </div>
  </div>
  <div class="flex h-[35px] -mx-3">
    <div
      [ngClass]="{ selected: this.selectedNavigationOption === option }"
      *ngFor="let option of navOptions"
      class="nav-option"
      (click)="setSelectedNavOption(option)"
    >
      {{ option.label }}
    </div>
  </div>
</div>
