import { ObjectUtil } from '@contrail/util';
import { Types } from '@contrail/sdk';
import { PropertyType } from '@contrail/types';
import { BASE_PROPERTIES, FRAME_PROPERTIES } from './document-dynamic-text-util';

export class DocumentDynamicTextState {
  private bindingProperties: any = {};
  private flatBindingProperties: any = {};
  public entityTypes: any[] = [];
  public typeMap = {};
  public baseEntityMap: any = {};
  public entityMap: Map<string, any> = new Map();
  public itemTypeId: string;

  constructor() {}

  async init(ownerReferenceObj: any) {
    const additionalTypes = ['color']; // this could be an org setup to include more types
    this.baseEntityMap[ownerReferenceObj.entityType] = ownerReferenceObj;
    const ownerRefenceType = await this.getType(ownerReferenceObj.typeId);
    if (ownerReferenceObj.workspace?.project) {
      this.baseEntityMap['project'] = ownerReferenceObj.workspace.project;
    }
    this.entityTypes = [
      {
        slug: ownerReferenceObj.entityType,
        label: ownerReferenceObj.entityType === 'board' ? 'Board' : 'Showcase',
        selectable: true,
      },
      { slug: 'project', label: 'Project', selectable: true },
      { slug: 'projectItem', label: 'Project Item', selectable: false },
      { slug: 'assortmentItem', label: 'Assortment Item', selectable: false },
      { slug: 'frame', label: 'Frame', selectable: true },
      { slug: 'item', label: 'Items', selectable: true },
    ];
    let properties = [];
    const projectType = await new Types().getType({ path: 'project' });
    const itemType = await new Types().getType({ path: 'item' });
    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
    this.itemTypeId = itemType.id; //for legacy dynamic text that doesn't have typeId
    this.typeMap[projectType.id] = projectType;
    this.typeMap[itemType.id] = itemType;
    this.typeMap[projectItemType.id] = projectItemType;
    this.typeMap[assortmentItemType.id] = assortmentItemType;

    properties.push(
      ...itemType.typeProperties.map((p) => {
        return {
          slug: 'item.' + p.slug,
          label: p.label,
          propertyType: p.propertyType,
          typeId: itemType.id,
        };
      }),
    );
    properties.push(
      ...projectItemType.typeProperties
        .filter((p) => !['createdOn', 'updatedOn', 'name'].includes(p.slug))
        .map((p) => {
          return {
            slug: 'projectItem.' + p.slug,
            label: p.label,
            propertyType: p.propertyType,
            typeId: projectItemType.id,
          };
        }),
    );
    properties.push({
      slug: 'projectItem.project.name',
      label: 'Project name',
      propertyType: PropertyType.String,
      typeId: projectItemType.id,
    });
    properties.push(
      ...assortmentItemType.typeProperties
        .filter((p) => !['createdOn', 'updatedOn'].includes(p.slug))
        .map((p) => {
          return {
            slug: 'assortmentItem.' + p.slug,
            label: p.label,
            propertyType: p.propertyType,
            typeId: assortmentItemType.id,
          };
        }),
    );
    properties = properties.sort((p1, p2) => (p1.label > p2.label ? 1 : -1));

    const documentBindingProperties = ObjectUtil.cloneDeep(BASE_PROPERTIES);
    documentBindingProperties.forEach((p) => {
      p.slug = ownerReferenceObj.entityType + '.' + p.slug;
      p.typeId = ownerRefenceType.id;
    });
    const projectBindingProperties = ObjectUtil.cloneDeep(BASE_PROPERTIES);
    projectBindingProperties.splice(3, 2); // remove createdBy and updatedBY for now
    projectBindingProperties.forEach((p) => {
      p.slug = 'project' + '.' + p.slug;
      p.typeId = projectType.id;
    });
    this.bindingProperties = {
      project: projectBindingProperties,
      item: properties,
    };
    this.bindingProperties[ownerReferenceObj.entityType] = documentBindingProperties;
    this.bindingProperties.frame = FRAME_PROPERTIES;
    additionalTypes.forEach(async (typePath) => {
      const type = await new Types().getType({ path: typePath });
      this.entityTypes.push({ slug: type.slug, label: type.label });
      const typeProps = [];
      type.typeProperties.forEach((p) => {
        typeProps.push({
          slug: type.slug + '.' + p.slug,
          label: p.label,
          propertyType: p.propertyType,
          typeId: type.id,
        });
      });
      this.bindingProperties[type.slug] = typeProps;
    });
  }

  public getEntityTypes() {
    return this.entityTypes;
  }

  public getBindingProperties() {
    return this.bindingProperties;
  }

  public getFlatBindingProperties(): any {
    if (Object.keys(this.flatBindingProperties).length === 0) {
      this.flatBindingProperties = Object.values(this.bindingProperties)
        .flat()
        .reduce((a, b: any) => {
          a[b.slug] = b;
          return a;
        }, {});
    }
    return this.flatBindingProperties;
  }

  public async getType(typeId: string) {
    if (this.typeMap[typeId]) {
      return this.typeMap[typeId];
    }
    const type = await new Types().getType({ id: typeId });
    this.typeMap[type.id] = type;
    return type;
  }

  public clearEntityMap() {
    this.entityMap.clear();
  }
}
