import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CopyRenameEntityModalComponent } from './copy-rename-entity-modal/copy-rename-entity-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CopyRenameEntityModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal({
    entityId,
    entityName,
    entityType,
    isRename,
  }: {
    entityId: string;
    entityName: string;
    entityType: string;
    isRename: boolean;
  }): MatDialogRef<CopyRenameEntityModalComponent, any> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = { entityId, entityName, entityType, rename: isRename };
    const dialogRef = this.dialog.open(CopyRenameEntityModalComponent, dialogConfig);
    return dialogRef;
  }
}
