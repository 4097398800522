import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-property-configurator-border-size',
  templateUrl: './property-configurator-border-size.component.html',
  styleUrls: ['./property-configurator-border-size.component.scss'],
})
export class PropertyConfiguratorBorderSizeComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;
  @Input() keepParentMenuOpen = false;
  @Input() borderSizes = ['0', '1', '2', '3', '4', '6', '8', '10', '12', '14', '16', '24'];

  @ViewChild('borderSizeMenuTrigger') borderSizeMenuTrigger: MatMenuTrigger;
  public defaultValue = 1;
  public borderSize = this.defaultValue;

  constructor() {}

  ngOnChanges(): void {
    this.borderSize = this.currentValue ?? this.defaultValue;
  }

  setValue(sizeValue, $event) {
    this.borderSize = sizeValue;
    const changes = {
      style: {
        border: {
          width: this.borderSize,
        },
      },
    };
    this.valueChange.emit(changes);
    if (this.keepParentMenuOpen) {
      $event.stopPropagation();
      this.borderSizeMenuTrigger.closeMenu();
    }
  }

  menuOpened() {
    setTimeout(() => {
      const borderSizeSelected = document.getElementById('border-size-selected');
      const borderSizeMenuPanel = document.getElementsByClassName('border-size-menu-panel');
      if (borderSizeSelected && borderSizeMenuPanel?.length === 1) {
        const top = borderSizeSelected.offsetTop;
        const matMenuPanel = borderSizeMenuPanel[0];
        if (top > matMenuPanel.clientHeight) {
          matMenuPanel.scrollTop = top;
        }
      }
    }, 0);
  }
}
