import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { Assortment } from '../assortments-store/assortments.state';
import { AssortmentsService } from '../assortments.service';
import { AutoCompleteComponent } from '@components/autocomplete/autocomplete.component';
@Component({
  selector: 'app-assortment-selector',
  templateUrl: './assortment-selector.component.html',
  styleUrls: ['./assortment-selector.component.scss'],
})
export class AssortmentSelectorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  @ViewChild('workspaceSelector') workspaceSelector: AutoCompleteComponent;
  @Output() selectedAssortmentEvent = new EventEmitter();
  @Input() spanWorkspaces = false;
  @Input() outputType = 'ID';
  @Input() defaultCurrentWorkspaceId: any;
  @Input() defaultSourceAssortmentId: any;
  @Input() sourceAssortmentRequired: boolean = true;

  public workspaces$: Observable<Array<Workspace>>;
  public selectedWorkspace: Workspace = null;
  private assortmentsSubject: BehaviorSubject<Array<Assortment>> = new BehaviorSubject([]);
  public assortments$: Observable<Array<Assortment>> = this.assortmentsSubject.asObservable();
  public selectedAssortment: Assortment;
  public areAssortmentsLoading = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private assortmentsService: AssortmentsService,
  ) {}

  async ngOnInit() {
    this.workspaces$ = this.store.select(WorkspacesSelectors.workspaces);

    if (!this.spanWorkspaces) {
      const workspace = await firstValueFrom(this.store.select(WorkspacesSelectors.currentWorkspace));
      this.initAssortments(workspace.id);
    } else {
      if (this.defaultCurrentWorkspaceId) {
        const workspaces = await firstValueFrom(this.workspaces$);
        this.selectedWorkspace = workspaces.find((workspace) => workspace.id === this.defaultCurrentWorkspaceId);
        this.initAssortments(this.selectedWorkspace.id);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async initAssortments(workspaceId) {
    this.areAssortmentsLoading = true;
    const assortments = await this.assortmentsService.getAllAssortmentsInWorkspace(workspaceId, {
      relations: ['sourceAssortments'],
    });
    this.assortmentsSubject.next(assortments);
    this.areAssortmentsLoading = false;

    if (this.defaultSourceAssortmentId) {
      const assortments = await firstValueFrom(this.assortments$);
      this.selectedAssortment =
        assortments.find((assortment) => assortment.id === this.defaultSourceAssortmentId) || null;
    } else {
      this.selectedAssortment = null;
    }
  }
  async handleWorkspaceChange(option) {
    const workspace = option?.value || null;
    if (workspace) {
      this.initAssortments(workspace.id);
    } else {
      this.selectedAssortment = null;
      this.assortmentsSubject.next([]);
      this.selectedAssortmentEvent.emit(null);
    }
  }

  public handleAssortmentSelection(option) {
    this.selectedAssortment = option?.value || null;
    const output = this.outputType === 'ID' ? this.selectedAssortment?.id : this.selectedAssortment;
    this.selectedAssortmentEvent.emit(output);
  }
}
