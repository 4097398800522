import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DocumentElement } from '@contrail/documents';
import { ComponentEditorService } from '../component-editor.service';
import { Observable, Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { DocumentItemService } from '../../document-item/document-item.service';
import { ComponentEditorAnnotationComponent } from '../component-editor-annotation/component-editor-annotation.component';
import { ComponentEditorPropertyConfiguratorComponent } from '../component-editor-property-configurator/component-editor-property-configurator.component';
import { cloneDeep } from '@contrail/util/lib/object-util/cloneDeep/cloneDeep';

@Component({
  selector: 'app-component-editor-property-selector',
  templateUrl: './component-editor-property-selector.component.html',
  styleUrls: ['./component-editor-property-selector.component.scss'],
})
export class ComponentEditorPropertySelectorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  control = new UntypedFormControl();
  public filteredProperties: Observable<any[]>; // Pick list for adding properties (filters applied)
  public availableProperties: any[] = []; // All available properties (computed from types of model bindings)
  public selectedProperties: any[] = []; // List of selected properties
  private selectedElement: any;
  private componentObjectDetails: any;
  @Output() addProp = new EventEmitter();
  @Output() propertiesChangedEvent = new EventEmitter();
  @ViewChild('propertyWidget') propertyWidgetElement: ComponentEditorPropertyConfiguratorComponent;
  @ViewChild('annotationWidgetElement') annotationWidgetElement: ComponentEditorAnnotationComponent;
  @ViewChild('propertyInput') propertyInputElement: ElementRef;

  constructor(
    private componentEditorService: ComponentEditorService,
    private documentItemService: DocumentItemService,
  ) {}

  ngOnInit(): void {
    this.subscribeToComponentObjectDetails();
    this.control.valueChanges.forEach((value) => {
      this.filteredProperties = new Observable((observer) => {
        observer.next(this._filter(value));
        observer.complete();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  subscribeToComponentObjectDetails() {
    this.componentEditorService.componentObjectDetailsObs
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(async (componentObjectDetails) => {
        if (componentObjectDetails) {
          this.componentObjectDetails = componentObjectDetails;
          // All property options, computed from the types of all bound model entities
          this.availableProperties = ObjectUtil.cloneDeep(componentObjectDetails.elements);
          // Down select to what is currently selected.
          this.selectedProperties = this.availableProperties.filter((property) => property.enabled);
          this.setFilteredProperties();
        }
      });
  }

  trackByFn(index, property) {
    return property?.displayName;
  }

  getSelectorDisplay(element: DocumentElement) {
    return Object.values(element.propertyBindings)[0];
  }

  toggleWidget(element, event, widgetType) {
    if (this.selectedElement) {
      this.hideWidget();
    } else {
      const widget = widgetType === 'property' ? this.propertyWidgetElement : this.annotationWidgetElement;
      this.showWidget(element, event, widget);
    }
  }

  public hideWidget() {
    this.selectedElement = null;
    this.propertyWidgetElement?.hide();
    this.annotationWidgetElement?.hide();
  }

  private showWidget(element, event, widget) {
    this.selectedElement = element;
    const renderedEvent = {
      element,
      renderedElementPosition: {
        x: event.x,
        y: event.y,
      },
    };
    widget.show(renderedEvent);
    event.stopPropagation();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedProperties, event.previousIndex, event.currentIndex);
    this.updateElements();
  }

  updateTextProperties(values) {
    ObjectUtil.mergeDeep(this.selectedElement, values);
    const idx = this.selectedProperties.findIndex((p) => p.displayName === this.selectedElement.displayName);
    this.selectedProperties[idx] = this.selectedElement;
    this.updateElements();
  }

  private updateElements() {
    const prop = cloneDeep(this.selectedProperties);
    this.propertiesChangedEvent.emit(this.selectedProperties);
  }

  getActiveColor(element) {
    return element === this.selectedElement ? 'accent' : '';
  }

  getComponentProperties() {
    return this.documentItemService.updateSizeAndPositionForPropertyElements(
      this.availableProperties,
      this.componentObjectDetails,
    );
  }

  addElement(element) {
    this.addProp.emit(true);
    element.enabled = true;
    this.selectedProperties.push(element);
    this.updateElements();
    this.control.setValue('');
    this.propertyInputElement.nativeElement.blur();
    this.setFilteredProperties();
  }

  removeElement(element) {
    const index = this.selectedProperties.findIndex(
      (property) => JSON.stringify(property.propertyBindings) === JSON.stringify(element.propertyBindings),
    );
    element.enabled = false;
    this.selectedProperties.splice(index, 1);
    this.setFilteredProperties();
    this.updateElements();
  }

  hideElement(element) {
    const index = this.selectedProperties.findIndex(
      (property) => JSON.stringify(property.propertyBindings) === JSON.stringify(element.propertyBindings),
    );
    this.selectedProperties[index].isHidden = !element.isHidden;
    this.updateElements();
  }

  toggleLabel(element) {
    if (element.label) {
      delete element.label;
    } else {
      element.label = element.displayName;
    }
    this.updateElements();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.availableProperties.filter(
      (property) => property.displayName?.toLowerCase().includes(filterValue) && !property.enabled,
    );
  }

  private setFilteredProperties() {
    this.filteredProperties = new Observable((observer) => {
      observer.next(
        this.availableProperties
          .filter((property) => !property.enabled)
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1;
            } else if (a.displayName > b.displayName) {
              return 1;
            } else {
              return 0;
            }
          }),
      );
      observer.complete();
    });
  }

  public openPanel(e) {
    e.stopPropagation();
    this.propertyInputElement.nativeElement.focus();
  }
}
