<div class="clipboard-button">
  <button class="menu-button mat-button mat-button-base" (click)="openClipboardInSidebar()" mat-button>
    <mat-icon svgIcon="clipboard" *ngIf="!(clipboardItems$ | async).length"></mat-icon>
    <mat-icon
      [matBadgeSize]="'small'"
      svgIcon="clipboard"
      matBadge="{{ (clipboardItems$ | async).length }}"
      *ngIf="(clipboardItems$ | async).length"
    >
    </mat-icon>
  </button>
</div>
