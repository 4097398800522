import { Component, Input } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { DocumentService } from '../../document.service';

@Component({
  selector: 'app-property-configurator-clip-content',
  template: `
    <button
      mat-icon-button
      (click)="toggleClipContent()"
      [matTooltip]="selectedElement.clipContent ? 'Unclip Content' : 'Clip Content'"
      data-test="toggle-clip-content"
    >
      <mat-icon [ngClass]="{ active: selectedElement.clipContent }">content_cut</mat-icon>
    </button>
  `,
  styles: [
    `
      :host {
        ::ng-deep .active {
          @apply text-accent;
        }
      }
    `,
  ],
})
export class PropertyConfiguratorClipContentComponent {
  @Input() selectedElement: any;

  constructor(public documentService: DocumentService) {}
  public toggleClipContent() {
    this.documentService.handleActionRequest(
      new ActionRequest('toggle_clip_content', {
        element: this.selectedElement,
      }),
    );
  }
}
