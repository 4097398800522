<div class="flex items-center px-1" cdkDrag cdkDragRootElement="app-property-configurator-bar">
  <mat-icon
    cdkDragHandle
    *ngIf="['ASSET', 'CONTENT', 'IMAGE'].includes(elementType)"
    class="icons material-icons-outlined element-icon"
  >
    image
  </mat-icon>
  <mat-icon *ngIf="elementType === 'COLOR'" cdkDragHandle class="element-icon"> palette </mat-icon>
  <app-item-details-status
    *ngIf="elementType === 'ITEM' && contextualEntity"
    class="status"
    cdkDragHandle
    [item]="contextualEntity"
    iconOnly="true"
  />

  <mat-spinner *ngIf="!name" diameter="20"></mat-spinner>
  <div
    *ngIf="!editItemFamilyName && name"
    (dblclick)="startEditItemFamilyName()"
    class="px-1 text-base whitespace-nowrap"
    [class.cursor-text]="elementType === 'ITEM' && !itemFamilyNameDisabled"
    [matTooltip]="name"
    matTooltipPosition="above"
    [matTooltipDisabled]="name.length < 15"
    data-test="entity-details-name"
  >
    {{ name | shorten: 15 }}
  </div>
  <div *ngIf="editItemFamilyName" class="pr-2">
    <mat-form-field class="edit-family-name">
      <input
        matInput
        #editItemFamilyNameInput
        spellcheck="false"
        type="input"
        size="15"
        (keydown.enter)="endEditItemFamilyName($event, true)"
        (keydown.esc)="endEditItemFamilyName($event)"
        [formControl]="itemFamilyNameInput"
        (blur)="endEditItemFamilyName($event, true)"
      />
    </mat-form-field>
  </div>

  <button
    *ngIf="canViewDetails"
    (click)="viewDetails()"
    mat-icon-button
    matTooltip="Open"
    matTooltipPosition="above"
    data-test="open-details-modal"
  >
    <mat-icon>launch</mat-icon>
  </button>
  <mat-divider vertical="true" class="divider"></mat-divider>

  <ng-container *ngIf="elementType === 'ITEM'">
    <div *ngIf="!queryEntitiesInProgress" class="flex">
      <button
        *ngIf="itemContextFeatureActive"
        class="border-style-button"
        #optionListMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="optionListMenu"
        (menuOpened)="handleOptionListMenuOpened()"
        mat-icon-button
        matTooltip="Option context"
        matTooltipPosition="above"
        data-test="item-option-context"
      >
        <mat-icon
          class="icons material-icons-outlined"
          [ngClass]="{ 'opacity-30': !contextualEntity?.roles?.includes('color') }"
          svgIcon="hierarchy"
        ></mat-icon>
      </button>
      <button
        *ngIf="itemContextFeatureActive"
        class="border-style-button"
        #assortmentMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="projectMenu"
        (menuOpened)="handleProjectMenuOpened()"
        mat-icon-button
        [matTooltip]="projectItem ? projectItem?.project?.name : ''"
        matTooltipPosition="above"
        data-test="item-project-context"
      >
        <mat-icon
          [ngClass]="{ 'opacity-30': !projectItem }"
          class="icons material-icons-outlined"
          svgIcon="project"
        ></mat-icon>
      </button>
      <!--
      <button class="border-style-button !cursor-default" *ngIf="assortment"
        mat-icon-button [matTooltip]="assortment.name" matTooltipPosition="above" data-test="item-assortment-context">
        <mat-icon class="icons material-icons-outlined" svgIcon="app-assortment"></mat-icon>
      </button>
      -->
      <mat-divider vertical="true" class="divider" *ngIf="itemContextFeatureActive"></mat-divider>
      <button
        *ngIf="itemCreationFeatureActive"
        class="border-style-button"
        [matMenuTriggerFor]="optionMenu"
        data-test="item-option-tool"
        mat-icon-button
        matTooltip="Item tool"
        matTooltipPosition="above"
      >
        <mat-icon class="icons material-icons-outlined" svgIcon="tag"></mat-icon>
      </button>
    </div>
    <div *ngIf="queryEntitiesInProgress" class="ml-3">
      <mat-spinner diameter="20"></mat-spinner>
    </div>

    <mat-menu #optionListMenu="matMenu" class="border-style-menu text-black60 context-menu">
      <div (click)="handleSubMenuClick($event)" class="flex items-center justify-between w-full h-full">
        <div class="flex items-center">
          <mat-icon class="icons material-icons-outlined !text-[22px] mx-1" svgIcon="hierarchy"></mat-icon>
          <div
            *ngIf="!editItemOptionName && contextualEntity?.optionName"
            (dblclick)="startEditItemOptionName($event)"
            class="text-base whitespace-nowrap h-10 flex items-center w-[120px]"
            [class.cursor-text]="!itemOptionNameDisabled && contextualEntity?.optionName"
            data-test="item-option-name"
            [matTooltip]="contextualEntity?.optionName"
            [matTooltipDisabled]="contextualEntity?.optionName?.length < 13"
          >
            {{ contextualEntity?.optionName | shorten: 13 }}
          </div>
          <div
            *ngIf="!contextualEntity?.optionName"
            data-test="item-option-name"
            class="text-base whitespace-nowrap h-10 flex items-center w-[40px]"
          >
            N/A
          </div>
          <div *ngIf="editItemOptionName" class="flex">
            <mat-form-field class="edit-option-name">
              <input
                matInput
                #editItemOptionNameInput
                spellcheck="false"
                type="input"
                size="10"
                (keydown.enter)="endEditItemOptionName($event, true)"
                (keydown.esc)="endEditItemOptionName($event)"
                data-test="edit-option-name-input"
                [formControl]="itemOptionNameInput"
                (blur)="endEditItemOptionName($event, true)"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="ml-4 pr-2 flex text-xs">
          <mat-spinner *ngIf="queryItemOptionsInProgress" diameter="20"></mat-spinner>
          <span
            class="mr-1"
            *ngIf="!queryItemOptionsInProgress && contextualEntity?.optionName"
            [ngClass]="{ 'cursor-pointer text-primary': selectableItemOptions?.length > 0 }"
            [matMenuTriggerFor]="optionAssignMenu"
            data-test="item-component-change-option"
          >
            Change
          </span>
          <span
            *ngIf="!queryItemOptionsInProgress && !contextualEntity?.optionName && selectableItemOptions?.length > 0"
            class="mr-1 cursor-pointer text-primary"
            [matMenuTriggerFor]="optionAssignMenu"
            data-test="item-component-set-option"
          >
            Set option
          </span>
          <span
            *ngIf="!queryItemOptionsInProgress && !contextualEntity?.optionName && selectableItemOptions?.length === 0"
            class="mr-1 cursor-pointer text-primary"
            (click)="addItemOption()"
            data-test="item-component-create-and-set-option"
          >
            Create and set option
          </span>
          <span *ngIf="!queryItemOptionsInProgress && contextualEntity?.optionName">|</span>
          <span
            *ngIf="!queryItemOptionsInProgress && contextualEntity?.optionName"
            class="ml-1"
            [ngClass]="{ 'cursor-pointer text-primary': contextualEntity?.roles?.includes('color') }"
            data-test="item-component-remove-option"
            (click)="removeOption()"
          >
            Remove
          </span>
        </div>
      </div>
    </mat-menu>
    <mat-menu #optionAssignMenu="matMenu" class="text-black60">
      <div
        mat-menu-item
        class="cursor-pointer text-base whitespace-nowrap h-10 flex items-center px-2"
        *ngFor="let option of selectableItemOptions"
        (click)="assignOption(option)"
      >
        {{ option.optionName }}
      </div>
    </mat-menu>
    <mat-menu #optionMenu="matMenu" class="border-style-menu text-black60">
      <div class="cursor-pointer text-base whitespace-nowrap h-10 flex items-center">
        <button
          data-test="item-option-toolbar-create-new-option"
          mat-icon-button
          matTooltip="Add option"
          (click)="addItemOption()"
          [disabled]="!contextualEntity?.itemFamilyId"
        >
          <mat-icon class="icons material-icons-outlined !text-[24px] mx-2" svgIcon="new-item-option"></mat-icon>
        </button>
      </div>
      <div class="cursor-pointer text-base whitespace-nowrap h-10 flex items-center">
        <button
          data-test="item-option-toolbar-copy-item"
          mat-icon-button
          matTooltip="Quick item copy"
          (click)="copyItem()"
        >
          <mat-icon class="icons material-icons-outlined !text-[24px] mx-2">content_copy</mat-icon>
        </button>
      </div>
      <div class="cursor-pointer text-base whitespace-nowrap h-10 flex items-center">
        <button
          data-test="item-option-toolbar-add-to-clipboard"
          mat-icon-button
          matTooltip="Add to clipboard"
          (click)="addToClipboard()"
        >
          <mat-icon class="icons material-icons-outlined !text-[24px] mx-2 opacity-60" svgIcon="clipboard"></mat-icon>
        </button>
      </div>
    </mat-menu>
    <mat-menu #projectMenu="matMenu" class="text-black60 project-menu menu-md">
      <div *ngIf="queryProjectItemsInProgress" class="ml-3">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <ng-container *ngIf="!queryProjectItemsInProgress">
        <div
          *ngFor="let project of selectableProjects"
          mat-menu-item
          [disabled]="project.id === projectItem?.projectId"
          class="cursor-pointer text-base whitespace-nowrap h-10 flex items-center px-2"
          (click)="assignProject(project)"
        >
          {{ project.name }}
        </div>
        <div
          class="text-primary cursor-pointer text-base whitespace-nowrap h-10 flex items-center ml-4"
          *ngIf="addToCurrentProjectActive"
          (click)="assignToCurrentProject()"
        >
          + Add to project
        </div>
      </ng-container>
    </mat-menu>

    <button
      class="border-style-button"
      *ngIf="viewableEntity"
      mat-icon-button
      matTooltip="Content details"
      matTooltipPosition="above"
      [matMenuTriggerFor]="imageConfig"
      data-test="item-content-open-menu"
    >
      <mat-icon class="icons material-icons-outlined">image</mat-icon>
    </button>
    <mat-menu #imageConfig="matMenu" class="entity-details-content-menu border-style-menu text-black60">
      <mat-icon class="icons material-icons-outlined opacity-50 !text-[22px] mx-2">image</mat-icon>
      <div
        *ngIf="viewableEntity?.fileName"
        class="text-base whitespace-nowrap"
        [matTooltip]="viewableEntity?.fileName"
        [matTooltipDisabled]="viewableEntity?.fileName?.length < 15"
      >
        {{ viewableEntity?.fileName | shorten: 15 }}
      </div>
      <mat-divider vertical="true" class="divider" *ngIf="viewableEntity?.fileName"></mat-divider>
      <button data-test="item-content-copy-image" mat-icon-button matTooltip="Copy Content" (click)="copyViewable()">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button
        data-test="composer-toolbar-image-options"
        mat-icon-button
        matTooltip="Content Options"
        (click)="openContentEditor()"
        *ngIf="viewableEntity?.contentType === 'image/svg+xml' && hasSvgRecolorFeatureFlag"
      >
        <mat-icon>tune</mat-icon>
      </button>
      <app-property-configurator-download matTooltip="Download" [element]="selectedElement" />
    </mat-menu>
  </ng-container>
</div>
