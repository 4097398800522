<div
  style="visibility: hidden; position: fixed"
  [matMenuTriggerFor]="contextMenu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
></div>
<mat-menu #contextMenu="matMenu" class="menu-sm">
  <ng-template matMenuContent let-target="target">
    <button *ngFor="let action of actionDefinitions" mat-menu-item (click)="select(action.actionName, target)">
      <mat-icon *ngIf="action?.icon">{{ action.icon }}</mat-icon>
      <span>{{ action.label }}</span>
    </button>
  </ng-template>
</mat-menu>
