import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { ViewDefinition } from '../../views/view-definitions/view-definition';
import { ViewManagerService } from '../../views/view-manager.service';
import { ItemDetailsModalComponent } from '@common/items/item-details-modal/item-details-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-item-data-chooser-card',
  templateUrl: './item-data-chooser-card.component.html',
  styleUrls: ['./item-data-chooser-card.component.scss'],
})
export class ItemDataChooserCardComponent implements OnChanges, OnInit {
  @Output() add = new EventEmitter();
  @Output() toggleSelection = new EventEmitter();
  @Input() dataObj: any;
  @Input() level: string;
  @Input() allowAdd: boolean;
  @Input() allowAddMultiple = false;
  @Input() allowAddDuplicate = true;
  @Input() isExistingItem;
  @Input() isClipboardItem = false;
  @Input() selectedItemIndex: any;
  @Input() allowShowDetails = true;
  @Input() isDisabled;
  @Input() isMaxNumberOfItemsSelected = false;
  public metaViewDefinition: ViewDefinition;
  public itemInCollection = false;
  public justAdded = false;
  constructor(
    private viewsService: ViewManagerService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {}

  async ngOnInit() {
    if (this.dataObj) {
      this.metaViewDefinition = await this.viewsService.getView('common:item_chooser_card', this.dataObj.item.typeId);
    }
  }
  async ngOnChanges() {}

  addItemData(itemData: ItemData) {
    if (!this.allowAddDuplicate && (this.isExistingItem || this.justAdded)) {
      return;
    }
    this.add.emit(itemData);

    if (this.isDisabled) return;

    this.justAdded = this.allowAddDuplicate == true ? false : true;
    if (!this.allowAddDuplicate) {
      setTimeout(() => {
        this.justAdded = false;
      }, 3000);
    }
  }

  isAddIconNotClickable(): boolean {
    return Boolean(!this.allowAddDuplicate && (this.isExistingItem || this.justAdded));
  }

  getAddIcon() {
    return this.isExistingItem || this.justAdded ? 'add_circle' : 'add_circle_outline';
  }

  toggleCheckbox(event) {
    this.toggleSelection.emit({ itemData: this.dataObj, selected: event.checked });
  }

  public launchItemDetails() {
    const itemId = this.dataObj.item.id;
    const config = {
      data: { itemId, accessLevel: 'VIEW' },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    this.matDialog.open(ItemDetailsModalComponent, config);
  }

  get isCheckboxDisabled(): boolean {
    const isItemNotSelected = this.selectedItemIndex <= 0;
    if (isItemNotSelected && this.isMaxNumberOfItemsSelected) {
      return true;
    }

    if (this.isClipboardItem || this.allowAddDuplicate) {
      return false;
    }

    return this.isExistingItem || this.justAdded || this.isDisabled;
  }
}
