import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ListColumnDefinition } from 'src/app/common/components/list/list-column-definition';
import { RootStoreState } from 'src/app/root-store';
import { BoardsActions, BoardsSelectors } from '../boards-store';
import { Board } from '../boards-store/boards.state';
import { CreateBoardComponent } from '../create-board/create-board.component';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import {
  ContextMenuActionDefinition,
  ContextMenuComponent,
} from 'src/app/common/components/context-menu/context-menu.component';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { startWith, map, filter, tap, take } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { SortByOptions } from '@common/util/filter-util';
import { UntypedFormControl } from '@angular/forms';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { WorkspacePrincipalRole } from '@common/workspaces/workspace-principal';
import { Request } from '@contrail/sdk';
import { CopyRenameEntityModalLauncher } from '@components/copy-rename-entity/copy-rename-entity-modal-launcher';

@Component({
  selector: 'app-boards-home',
  templateUrl: './boards-home.component.html',
  styleUrls: ['./boards-home.component.scss'],
})
export class BoardsHomeComponent implements AfterViewInit {
  public title = "Let's get creative!";
  public icon = 'board-placeholder';
  public footer =
    'Visually collaborate on ideas and concepts through digital canvases that can be used for creating connected mood boards and design briefs.';
  public actionLabel = 'create board';

  public viewMode = 'cards';
  public boards$: Observable<Array<Board>>;
  public showEmptyState$: Observable<boolean>;

  public columnDefinitions: Array<ListColumnDefinition>;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
  public contextMenuActions: Array<ContextMenuActionDefinition>;

  sortOptions = SortByOptions;
  private sortDirectionSubject = new BehaviorSubject('desc');
  public sortDirection$ = this.sortDirectionSubject.asObservable();
  public sortByControl = new UntypedFormControl({ label: 'Date Modified', property: 'updatedOn' });
  public myBoardsOnly = new UntypedFormControl(false);

  public allowEdit: boolean;
  constructor(
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private copyRenameEntityModalLauncher: CopyRenameEntityModalLauncher,
    private confirmationBoxService: ConfirmationBoxService,
    private routingService: RoutingService,
    private authService: AuthService,
  ) {
    this.columnDefinitions = [
      {
        constantValue: 'assets/images/board_icon.svg',
        label: '',
        alignment: 'left',
        propertyType: 'icon',
      },
      {
        index: 'name',
        label: 'Name',
        alignment: 'left',
        propertyType: 'text',
      },
      {
        index: 'updatedBy.email',
        label: 'Updated By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'updatedOn',
        label: 'Updated',
        alignment: 'right',
        propertyType: 'date',
      },
      {
        index: 'createdBy.email',
        label: 'Created By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'createdOn',
        label: 'Created',
        alignment: 'right',
        propertyType: 'date',
      },
    ];
    this.allowEdit = !this.authService.isOnSharedLink();
    this.contextMenuActions = [{ actionName: 'open', icon: 'open_in_new', label: 'Open' }];
  }

  subscribe() {
    this.boards$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.myBoardsOnly.valueChanges.pipe(startWith(false)),
      this.sortByControl.valueChanges.pipe(startWith({ label: 'Date Modified', property: 'updatedOn' })),
      this.sortDirection$,
      this.store.select(BoardsSelectors.boards),
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      map(([searchTerm, myBoards, sortByOption, sortDirection, properties, authContext]) => {
        const sortByField = sortByOption?.property;
        const keys = 'name';
        return properties
          .filter((item) =>
            keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
          )
          .filter((sc) => this.testMyBoards(sc, myBoards, authContext))
          .sort((board1, board2) => {
            const value1 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(board1[sortByField]).getTime()
              : board1[sortByField].toLowerCase();
            const value2 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(board2[sortByField]).getTime()
              : board2[sortByField].toLowerCase();

            if (['createdOn', 'updatedOn'].includes(sortByField)) {
              if (sortDirection === 'asc') {
                return value1 - value2;
              } else {
                return value2 - value1;
              }
            } else {
              if (sortDirection === 'asc') {
                return value2 < value1 ? 1 : -1;
              } else {
                return value2 > value1 ? 1 : -1;
              }
            }
          });
      }),
    );

    this.showEmptyState$ = combineLatest([
      this.store.select(BoardsSelectors.boardsLoaded),
      this.store.select(BoardsSelectors.boards),
    ]).pipe(
      map(([loaded, boards]) => {
        return loaded && !boards?.length;
      }),
    );

    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        filter((ws) => ws?.id),
        tap(async (ws) => {
          const userRole = await Request.request(`/workspaces/${ws.id}/callerRole`, {});
          if (userRole.role === WorkspacePrincipalRole.VIEWER) {
            this.allowEdit = false;
          }
          if (this.allowEdit) {
            if (!this.contextMenuActions.some((action) => action.actionName === 'rename')) {
              this.contextMenuActions.push({
                actionName: 'rename',
                icon: 'drive_file_rename_outline',
                label: 'Rename',
              });
            }

            if (!this.contextMenuActions.some((action) => action.actionName === 'copy')) {
              this.contextMenuActions.push({ actionName: 'copy', icon: 'content_copy', label: 'Copy' });
            }
          }
        }),
      )
      .subscribe();
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.subscribe(), 0); // SOLVES STATE CHANGE ERROR
  }

  sortBy(option) {
    this.sortByControl.setValue(option);
  }
  private testMyBoards(obj, myBoards, authContext) {
    if (!myBoards) {
      return true;
    }
    return obj.createdById === authContext.user.id;
  }

  createBoard() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {};
    const dialogRef = this.matDialog.open(CreateBoardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  setViewMode(mode) {
    this.viewMode = mode;
  }
  handleContextMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }
  handleMenuAction($event) {
    switch ($event.action) {
      case 'open':
        this.goToBoard($event.target);
        break;
      case 'rename':
        this.rename($event.target);
        break;
      case 'copy':
        this.copy($event.target);
        break;
    }
  }

  rename(board) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: board.id,
      entityName: board.name,
      entityType: 'Board',
      isRename: true,
    });

    modalRef.componentInstance.renameEntity.subscribe((board) => {
      this.store.dispatch(BoardsActions.updateBoard({ id: board.id, changes: { name: board.name } }));
    });
  }

  copy(board) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: board.id,
      entityName: board.name,
      entityType: 'Board',
      isRename: false,
    });

    modalRef.componentInstance.copyEntity.subscribe(async (board) => {
      this.store.dispatch(BoardsActions.asyncCopyBoard({ sourceId: board.id, name: board.name }));
    });
  }

  async onCopySuccess(data) {
    this.store.dispatch(BoardsActions.asyncCopyBoardSuccess(data));
  }

  goToBoard(board) {
    this.routingService.go(`board/${board.id}`);
  }
  public showMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }

  sortByDirection() {
    this.sortDirection$.pipe(take(1)).subscribe((sortDirection: string) => {
      if (sortDirection === 'asc') {
        this.sortDirectionSubject.next('desc');
      } else {
        this.sortDirectionSubject.next('asc');
      }
    });
  }
}
