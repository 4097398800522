<div class="container" data-test="share-app-container">
  <div class="flex">
    <app-autocomplete
      class="user-auto-complete"
      data-test="add-collaborator"
      label="Add emails, users or groups"
      [options]="suggestions"
      (valueSelected)="addCollaborator($event)"
      displayProperty="name"
      [isRequired]="false"
      [allowAdhocOptions]="true"
      [allowEnterKeyToSelectOption]="true"
    >
    </app-autocomplete>
    <mat-form-field class="ml-3 colloborator-access-level">
      <mat-select
        [value]="colloboratorAccessLevel"
        (selectionChange)="handleColloboratorAccessLevel($event)"
        class="colloborator-access-level-select"
      >
        <mat-option value="VIEW">View</mat-option>
        <mat-option value="COMMENT">Comment</mat-option>
        <mat-option value="EDIT">Edit</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="collaborator-container section-container mt-3">
    <div class="sub-header flex">
      Collaborators <mat-spinner diameter="30" *ngIf="addingCollabs" class="ml-3"></mat-spinner>
    </div>
    <div class="collaborator-body">
      <div class="workspace-body section-body flex">
        <div
          class="workspace-users flex ml-3 cursor-pointer"
          (click)="principalAdmin()"
          data-test="workspace-principals"
        >
          <mat-spinner diameter="40" *ngIf="loadingCollabs"></mat-spinner>
          <ng-container *ngFor="let principal of workspacePrincipals?.slice(0, 4); index as idx">
            <div
              *ngIf="!principal?.principal?.email"
              class="w-8 h-8 rounded-2xl flex-center -ml-3"
              data-test="workspace-principal-intial"
              [ngStyle]="{ 'background-color': principal?.principal?.backgroundColor }"
              [matTooltip]="principal?.principal?.name"
            >
              <span class="material-icons text-white text-xl"> people_alt </span>
            </div>
            <app-avatar
              *ngIf="principal?.principal?.email"
              class="-ml-3 workspace-principal-avatar"
              [ngStyle]="{ 'z-index': 100 - idx }"
              [user]="principal?.principal"
              size="40px"
              data-test="workspace-principal-avatar"
            ></app-avatar>
          </ng-container>
          <div
            *ngIf="workspacePrincipals?.length > 4"
            class="flex-center w-10 h-10 bg-blue-50 font-bold rounded-3xl -ml-3"
          >
            <span class="material-icons"> more_horiz </span>
          </div>
        </div>
        <div class="workspace-principal-text ml-3" *ngIf="!loadingCollabs">
          <div class="workspace-head">Project Collaborators</div>
          <div class="workspace-sub-head">Users with access to the document via project settings</div>
        </div>
      </div>
      <div class="collaborators mt-4">
        <div *ngFor="let collaborator of collaborators" class="flex w-full mt-3" data-test="collaborator">
          <app-avatar size="40px" [user]="collaborator.principal" data-test="collab-avatar-button"></app-avatar>
          <div class="collaborator-name ml-4 mr-4 mt-2">{{ collaborator.principal.displayName }}</div>
          <mat-form-field class="collab-access mr-3">
            <mat-select
              [value]="collaborator.accessLevel"
              (selectionChange)="updateCollaboratorAccess($event, collaborator)"
              data-test="collab-access-select"
            >
              <mat-option value="VIEW" data-test="collaborator-access-option">View</mat-option>
              <mat-option value="COMMENT" data-test="collaborator-access-option">Comment</mat-option>
              <mat-option value="EDIT" data-test="collaborator-access-option">Edit</mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-icon-button
            (click)="removeCollaborator(collaborator)"
            class="btn-remove-collab"
            data-test="remove-collaborator"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="external-access-container section-container mt-5 pt-2" data-test="external-access-body">
    <div class="sub-header">General Access</div>

    <div class="external-access-body">
      <mat-spinner diameter="40" *ngIf="loadingLinks; else loadedLinks"></mat-spinner>
      <ng-template #loadedLinks>
        <div class="content">
          <div class="flex">
            <mat-spinner diameter="25" *ngIf="updatingActiveLink; else accessIcon"></mat-spinner>
            <ng-template #accessIcon>
              <mat-icon class="access-icon" [ngClass]="{ 'error-bg-access-icon': isDocumentSharedPublically }">{{
                accessLevelIcon
              }}</mat-icon>
            </ng-template>
            <div class="access-level-content">
              <mat-select
                [value]="activeLink?.accessLevel"
                (selectionChange)="handleAccessLevelChange($event, activeLink)"
                class="access-level-select"
                data-test="external-access-select"
              >
                <mat-option *ngFor="let accessLevel of accessLevels" [value]="accessLevel">
                  <div class="access-level" data-test="external-access-option">{{ accessLevel | titlecase }}</div>
                </mat-option>
              </mat-select>
              <div
                class="access-level-text"
                data-test="external-access-text"
                [ngClass]="{ 'error-bg': isDocumentSharedPublically }"
              >
                <mat-icon *ngIf="isDocumentSharedPublically" class="text-warning">warning</mat-icon>
                <span>{{ accessLevelText }}</span>
              </div>
            </div>
          </div>
          <div class="flex link-container">
            <div class="link-input-wrapper">
              <input class="link-input" type="text" [value]="activeLink?.shareUrl" readonly />
            </div>
            <button
              mat-flat-button
              color="primary"
              class="btn-copy-link"
              data-test="btn-copy-shared-link"
              [cdkCopyToClipboard]="activeLink?.shareUrl"
              (cdkCopyToClipboardCopied)="showClipboardMessage()"
            >
              Copy Link
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="legacy-links rounded-md p-3 mb-4" *ngIf="links?.length > 1" data-test="legacy-links">
    <div class="sub-header">Legacy Share Links</div>
    <div class="flex content">
      <div class="legacy-link-text">
        This document has multiple legacy share links.<br />Legacy links will be automatically deleted on 11/09/2024.
      </div>
      <button mat-flat-button (click)="manageLinks()" data-test="manage-links">Manage</button>
    </div>
  </div>
</div>
