import { Component, OnInit, Input } from '@angular/core';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { BoardsSelectors } from '../../boards-store';
import { Observable } from 'rxjs';
import { PositionDefinition } from '@contrail/documents';
import { ColorUtil } from 'src/app/common/util/color-util';
import { SVGViewBox } from '@contrail/svg';
import { ViewBox } from '../canvas/viewbox';

export class RemoteMouseTracker {
  x: number;
  y: number;
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-board-mouse-tracker',
  template: `
    <app-board-remote-mouse
      *ngFor="let remoteMouse of remoteUserMouseTracker$ | async; trackBy: trackByFn"
      [remoteMouse]="remoteMouse"
      [viewBox]="viewBox"
      [zoomFactor]="zoomFactor"
    >
    </app-board-remote-mouse>
  `,
})
export class BoardMouseTrackerComponent implements OnInit {
  remoteUserMouseTracker$: Observable<Array<RemoteMouseTracker>>;

  @Input() viewBox: ViewBox;
  @Input() zoomFactor;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.remoteUserMouseTracker$ = this.store.select(BoardsSelectors.remoteUserMouseTracker);
  }

  trackByFn(index, session) {
    return session?.clientId;
  }
}
