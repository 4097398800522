import { Injectable } from '@angular/core';
import { DocumentService } from '../../document.service';
@Injectable({
  providedIn: 'root',
})
export class ClipboardActionsDispatchers {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'copy_elements') {
        this.documentService.documentClipboard.copySelectedElementsToClipboard();
      } else if (request?.actionType === 'duplicate_elements') {
        this.documentService.documentClipboard.copySelectedElementsToClipboard();
        this.documentService.documentClipboard.pasteDocumentElementsFromClipboard({}, null, false, true);
      }
    });
  }
}
