<div class="view-container flex-center" [class.view-container-dash]="!url && false">
  <div *ngIf="url" class="h-full w-full flex-center" [class.thumbnail]="viewMode === 'side'">
    <img
      [src]="url | secureImage | async"
      class="h-[94%] w-[94%]"
      [class.border]="entity?.entityType !== 'workspace'"
      alt=""
    />
  </div>
  <ng-container *ngIf="!url">
    <div
      *ngIf="entity?.entityType === 'color'"
      class="max-square"
      [ngStyle]="{ 'background-color': entity.hexCode }"
    ></div>
    <img
      *ngIf="entity?.entityType !== 'color'"
      [src]="iconTypes[entity?.entityType].icon"
      class="h-56 w-56"
      alt=""
      [class.scale-75]="entity?.entityType === 'plan' || entity?.entityType === 'showroom'"
    />
  </ng-container>

  <div class="drop-zone flex-center" *ngIf="false">
    <div class="flex-center flex-col">
      <mat-icon class="upload-icon"> file_upload </mat-icon>
      <div class="message py-2">
        Drag files here
        <span class="underline cursor-pointer" (click)="fileUpload.click()">or select.</span>
      </div>
      <input #fileUpload type="file" style="display: none" (change)="handleFileSelection($event)" />
    </div>
  </div>
</div>
