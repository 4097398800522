import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { CanvasCellElement } from './canvas-cell-element';
import { CanvasTableElement } from './canvas-table-element';
import { TableService } from './table-manager/table.service';

export class CanvasTableService {
  constructor(private canvasDocument: CanvasDocument) {}

  public add(direction: 'row' | 'column', index, tableElement: CanvasTableElement) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.elementDefinition.id,
      size: { ...tableElement.elementDefinition.size },
      type: tableElement.elementDefinition.type,
      tableId: tableElement.id,
      [key]: [...tableElement.elementDefinition[key]],
    };
    const newElements = isColumn
      ? TableService.addColumn(
          tableElement.elementDefinition,
          index,
          index === 0
            ? tableElement.table.column(index).originalWidth
            : tableElement.table.column(index - 1).originalWidth,
          tableElement.getCellsAtColumn(Math.max(0, index - 1)),
        )
      : TableService.addRow(
          tableElement.elementDefinition,
          index,
          index === 0 ? tableElement.table.row(index).originalHeight : tableElement.table.row(index - 1).originalHeight,
          tableElement.getCellsAtRow(Math.max(0, index - 1)),
        );
    isColumn
      ? tableElement.table.addColumn(index, newElements[0], newElements.slice(1))
      : tableElement.table.addRow(index, newElements[0], newElements.slice(1));

    const actions = newElements.map((el, i) => {
      const action = new DocumentAction(
        {
          elementId: el.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: el,
        },
        {
          elementId: el.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: el,
        },
      );
      return action;
    });
    this.canvasDocument.actionsDispatcher.handleDocumentActions([
      ...actions,
      new DocumentAction(
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.elementDefinition.id,
            size: tableElement.elementDefinition.size,
            type: tableElement.elementDefinition.type,
            tableId: tableElement.id,
            [key]: [...tableElement.elementDefinition[key]],
          },
        },
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
    ]);
  }

  public move(direction: 'column' | 'row', tableElement: CanvasTableElement, from: number, to: number) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.elementDefinition.id,
      type: tableElement.elementDefinition.type,
      tableId: tableElement.id,
      [key]: [...tableElement.elementDefinition[key]],
    };
    TableService.move(key, tableElement.elementDefinition, from, to);
    isColumn ? tableElement.table.moveColumn(from, to) : tableElement.table.moveRow(from, to);
    this.canvasDocument.actionsDispatcher.handleDocumentActions([
      new DocumentAction(
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.elementDefinition.id,
            type: tableElement.elementDefinition.type,
            tableId: tableElement.id,
            [key]: [...tableElement.elementDefinition[key]],
          },
        },
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
    ]);
  }

  public clear(cells: CanvasCellElement[]) {
    const actions = [];
    cells.forEach((cell) => {
      const undoElementData: DocumentElement = {
        id: cell.id,
        text: cell.elementDefinition.text,
        type: cell.elementDefinition.type,
        size: { ...cell.elementDefinition.size },
      };
      cell.clearText();
      actions.push(
        new DocumentAction(
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: {
              id: cell.id,
              text: cell.elementDefinition.text,
              type: cell.elementDefinition.type,
              size: { ...cell.elementDefinition.size },
            },
          },
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: undoElementData,
          },
        ),
      );
    });
    if (actions.length > 0) {
      this.canvasDocument.actionsDispatcher.handleDocumentActions(actions);
    }
  }

  public delete(direction: 'column' | 'row', tableElement: CanvasTableElement, indexes: number[]) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';
    const undoElementData = {
      id: tableElement.elementDefinition.id,
      type: tableElement.elementDefinition.type,
      tableId: tableElement.id,
      size: { ...tableElement.elementDefinition.size },
      [key]: [...tableElement.elementDefinition[key]],
    };
    const elements = isColumn ? tableElement.table.deleteColumns(indexes) : tableElement.table.deleteRows(indexes);
    this.canvasDocument.actionsDispatcher.handleDocumentActions([
      new DocumentAction(
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: {
            id: tableElement.elementDefinition.id,
            type: tableElement.elementDefinition.type,
            tableId: tableElement.id,
            size: { ...tableElement.elementDefinition.size },
            [key]: [...tableElement.elementDefinition[key]],
          },
        },
        {
          elementId: tableElement.elementDefinition.id,
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: undoElementData,
        },
      ),
      ...elements.map(
        (element) =>
          new DocumentAction(
            {
              elementId: element.id,
              changeType: DocumentChangeType.DELETE_ELEMENT,
              elementData: {
                id: element.id,
                type: element.type,
                tableId: element.tableId,
              },
            },
            {
              elementId: element.id,
              changeType: DocumentChangeType.ADD_ELEMENT,
              elementData: ObjectUtil.cloneDeep(element),
            },
          ),
      ),
    ]);
  }

  public deleteTable(tableElement: CanvasTableElement) {
    const actions = [tableElement.elementDefinition, ...tableElement.getTableChildElements()]?.map(
      (el) =>
        new DocumentAction(
          {
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementId: el.id,
          },
          {
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementId: el.id,
            elementData: el,
          },
        ),
    );
    this.canvasDocument.actionsDispatcher.handleDocumentActions(actions);
  }
}
