import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { CanvasDocument } from '../../canvas-document';
import { DEFAULT_HIGHLIGHTER_BORDER_COLOR, DEFAULT_HIGHLIGHTER_BORDER_SIZE } from '../../constants';
import { CanvasPenElement } from './canvas-pen-element';

export class CanvasHighlighterElement extends CanvasPenElement {
  constructor(
    public elementDefinition: DocumentElement,
    protected canvasDocument: CanvasDocument,
    public interactable = false,
  ) {
    super(elementDefinition, canvasDocument, interactable);
    this.onChange();

    const defaultStyle = {
      border: {
        width: DEFAULT_HIGHLIGHTER_BORDER_SIZE,
        color: DEFAULT_HIGHLIGHTER_BORDER_COLOR,
      },
    };
    this.elementDefinition.style = ObjectUtil.mergeDeep(defaultStyle, this.elementDefinition.style || {});
  }
}
