import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth/auth.guard';
import { LoginComponent } from './common/auth/login/login.component';
import { MainComponent } from './main/main.component';
import { NoOrgsRegisteredComponent } from './common/auth/no-orgs-registered/no-orgs-registered.component';
import { SelectOrgComponent } from './main/select-org/select-org.component';
import { UserOrgResolver } from './common/auth/user-org.resolver';
import { BoardComponent } from './boards/board/board.component';
import { DefaultOrgResolver } from './common/auth/default-org.resolver';
import { BoardsHomeComponent } from './boards/boards-home/boards-home.component';
import { CanDeactivateGuard } from '@common/can-deactivate/can-deactivate.guard';
import { ItemTestComponent } from '@common/items/item-test/item-test.component';
import { FrameTemplateEditor } from './boards/board/document-templates/frame-template-editor/frame-template-editor.component';
import { FrameTemplateLibrary } from './boards/board/document-templates/frame-template-library/frame-template-library.component';
import { BoardEditorModeResolver } from './boards/board-editor-mode-resolver';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: { defaultOrg: DefaultOrgResolver },
    children: [
      { path: '', component: SelectOrgComponent },
      {
        path: 'org/:org',
        resolve: { user: UserOrgResolver },
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', component: BoardsHomeComponent },
          { path: 'frame-template-library', component: FrameTemplateLibrary },
          { path: 'item/:itemId', component: ItemTestComponent },
          {
            path: 'board/:boardId',
            resolve: { editorMode: BoardEditorModeResolver },
            component: BoardComponent,
            canDeactivate: [CanDeactivateGuard],
          },
        ],
      },
    ],
  },
  { path: 'frame-template-editor', component: FrameTemplateEditor },
  { path: 'login', component: LoginComponent },
  { path: 'no-org-found', component: NoOrgsRegisteredComponent },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
