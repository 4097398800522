import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-property-configurator-drop-item',
  templateUrl: './property-configurator-drop-item.component.html',
  styleUrls: ['./property-configurator-drop-item.component.scss'],
})
export class PropertyConfiguratorDropItemComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() currentValue: any;

  constructor(private matDialog: MatDialog) {}

  ngOnChanges(): void {
    console.log('PropertyConfiguratorDropItemComponent: ', this.currentValue);
  }
  dropItem() {
    console.log('PropertyConfiguratorDropItemComponent: dropItem', this.currentValue);
  }
}
