<div [style.display]="type?.typeProperties?.length > 0 ? 'block' : 'none'">
  <app-entity-properties-form
    [accessLevel]="accessLevel"
    [entity]="entity"
    [type]="type"
    [hints]="warnings"
    [hintStyle]="warningStyle"
    [hintIcon]="'warning-alert'"
    [hintIconType]="'svg'"
    [errors]="errors"
  >
  </app-entity-properties-form>
</div>
