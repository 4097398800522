import { Injectable } from '@angular/core';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { Entities, EntityReference } from '@contrail/sdk';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { RoutingService } from '@common/routing/routing.service';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace, WorkspaceEntity } from '@common/workspaces/workspaces-store/workspaces.state';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceEntitiesHelperService {
  public rootWorkspace: Workspace; // item-data-source-selector
  constructor(
    private routingService: RoutingService,
    private confirmationBoxService: ConfirmationBoxService,
    private matDialog: MatDialog,
    private store: Store<RootStoreState.State>,
  ) {}

  goToEntity(entity: WorkspaceEntity) {
    const ref = new EntityReference(entity.entityReference);
    if (ref.entityType === 'workspace') {
      const parentFolder = entity.workspace;
      if (parentFolder.id === this.rootWorkspace.id) {
        parentFolder.name = 'Documents';
      }
      this.goToFolder({ id: ref.id, parentId: entity.workspaceId, name: entity.name, parent: parentFolder });
      return false;
    }
  }

  setIcon(entity) {
    const ref = new EntityReference(entity.entityReference);
    if (ref.entityType === 'workspace') {
      entity.matIcon = 'folder_outline';
    } else {
      entity.icon = this.getDocumentIconPath(ref.entityType);
    }
  }

  getDocumentIconPath(entityType: string) {
    if (entityType === 'board') {
      return 'assets/images/board_icon.svg';
    } else if (entityType === 'showcase') {
      return 'assets/images/showcase_icon.svg';
    } else if (entityType === 'plan') {
      return 'assets/images/plan_icon.svg';
    } else if (entityType === 'showroom') {
      return 'assets/images/showroom_icon.svg';
    }
  }

  goToDocumentEntity(entity: EntityReference) {
    switch (entity?.entityType) {
      case 'board':
        this.routingService.openApp('boards', `board/${entity.id}`);
        break;
      case 'showcase':
        this.routingService.openApp('showcase-manager', `showcase-details/${entity.id}`);
        break;
      case 'plan':
        this.routingService.openApp('plan', `plan/${entity.id}`);
        break;
      default:
        break;
    }
  }

  goToFolder(workspace: Workspace) {
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace }));
  }
  goToRootFolder() {
    this.goToFolder(this.rootWorkspace);
  }
}
