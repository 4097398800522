import { NgModule } from '@angular/core';
import { FrameTemplateChooserComponent } from './frame-template-chooser/frame-template-chooser.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ComponentsModule } from '@components/components.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  declarations: [FrameTemplateChooserComponent],
  imports: [CommonModule, ComponentsModule, MatTabsModule, MatSelectModule, MatMenuModule, MatListModule],
  providers: [],
  exports: [FrameTemplateChooserComponent],
})
export class FrameTemplatesModule {}
