import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { CustomFontsStoreModule } from './custom-fonts-store';

@NgModule({
  declarations: [],
  imports: [CommonModule, CustomFontsStoreModule, ComponentsModule],
  exports: [],
})
export class CustomFontsModule {
  constructor() {}
}
